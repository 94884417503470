import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import styled from 'styled-components';

const AutoComplete = styled(Autocomplete)`
  & .MuiInputBase-root {
    padding: 4px 8px;
  }
  & .MuiInputBase-input {
    height: 0px;
    padding: 16px 0px !important;
  }
`;

export { AutoComplete };
export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  list: {
    margin: '16px',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    marginBottom: '32px',
  },
  search: {
    margin: '16px',
  },
  switches: {
    display: 'inline-block',
    width: '100px',
    textAlign: 'center',
  },
  switchBtn: {
    display: 'flex',
    alignItems: 'center',
    '& .checked': {
      backgroundColor: 'red',
    },
    '& label': {
      marginLeft: '0px',
      marginRight: '0px',
      marginBottom: '0',
      fontSize: '14px',
    },
  },
  timeContainer: {
    width: '80px',
    height: '36px',
    backgroundColor: '#ffffff',
    border: '1px solid #304659',
    fontSize: '16px',
    color: '#304659',
    fontWeight: 400,
    fontFamily: 'Lato',
    marginRight: '16px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#304659',
      color: '#ffffff',
    },
  },
}));
