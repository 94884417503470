/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable import/prefer-default-export */
import {
  GET_ALL_EVENTS_REQUEST,
  GET_ALL_EVENTS_BY_ID_REQUEST,
  GET_CLIENT_EVENTS_REQUEST,
  CANCEL_RSVP_REQUEST,
} from '../constant';

export const getEventsAction = () => ({
  type: GET_ALL_EVENTS_REQUEST,
  endpoint: '/api/event',
});

export const getByIdEventsAction = (id) => ({
  type: GET_ALL_EVENTS_BY_ID_REQUEST,
  endpoint: `/api/event/internal/${id}`,
});

export const getClientEvents = (clientId) => ({
  type: GET_CLIENT_EVENTS_REQUEST,
  endpoint: `/api/event/client/${clientId}`,
});

export const cancelRSVP = (id) => ({
  type: CANCEL_RSVP_REQUEST,
  endpoint: `/api/event/cancel-rsvp/${id}`,
});
