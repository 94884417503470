/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { put, takeEvery } from 'redux-saga/effects';
import { post, get, updatePatch } from '../axios';
import {
  GET_PAYMENT_URL_FAILED,
  GET_PAYMENT_URL_SUCCESS,
  GET_PAYMENT_URL_REQUEST,
  GET_PAYMENT_RES_REQUEST,
  GET_PAYMENT_RES_FAILED,
  GET_PAYMENT_RES_SUCCESS,
  PAYPAL_SUCCESS,
  PAYPAL_FAILED,
  PAYPAL_REQUEST,
  eCHECK_SUCCESS,
  eCHECK_FAILED,
  eCHECK_REQUEST,
  GET_UNPAID_PAYMENT_REQUEST,
  GET_UNPAID_PAYMENT_SUCCESS,
  GET_UNPAID_PAYMENT_FAILED,
  GET_PAYMENT_REQUEST,
  GET_PAYMENT_SUCCESS,
  GET_PAYMENT_FAILED,
  MARK_PAYMENT_AS_PAID_REQUEST,
  MARK_PAYMENT_AS_PAID_SUCCESS,
  MARK_PAYMENT_AS_PAID_FAILED,
} from '../constant';

function* getPaymentUrl(action) {
  try {
    const { endpoint, body } = action;
    const response = yield post(endpoint, body);
    yield put({ type: GET_PAYMENT_URL_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_PAYMENT_URL_FAILED, error });
  }
}

function* getPaymentRes(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_PAYMENT_RES_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_PAYMENT_RES_FAILED, error });
  }
}

function* paypal(action) {
  try {
    const { endpoint, body } = action;
    const response = yield get(endpoint);
    yield put({ type: PAYPAL_SUCCESS, response });
  } catch (error) {
    yield put({ type: PAYPAL_FAILED, error });
  }
}

function* eCheck(action) {
  try {
    const { endpoint, body } = action;
    const response = yield updatePatch(endpoint, body);
    yield put({ type: eCHECK_SUCCESS, response });
  } catch (error) {
    yield put({ type: eCHECK_FAILED, error });
  }
}

function* getUnpaidPaymentAction(action) {
  try {
    const { endpoint, body } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_UNPAID_PAYMENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_UNPAID_PAYMENT_FAILED, error });
  }
}

function* getPaymentAction(action) {
  try {
    const { endpoint, body } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_PAYMENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_PAYMENT_FAILED, error });
  }
}

function* markInvoiceAsPaid(action) {
  try {
    console.log(action);
    const { endpoint } = action;
    const response = yield updatePatch(endpoint);
    yield put({ type: MARK_PAYMENT_AS_PAID_SUCCESS, response });
  } catch (error) {
    yield put({ type: MARK_PAYMENT_AS_PAID_FAILED, error });
  }
}

export function* paymentSaga() {
  yield takeEvery(GET_PAYMENT_URL_REQUEST, getPaymentUrl);
  yield takeEvery(GET_PAYMENT_RES_REQUEST, getPaymentRes);
  yield takeEvery(PAYPAL_REQUEST, paypal);
  yield takeEvery(eCHECK_REQUEST, eCheck);
  yield takeEvery(GET_UNPAID_PAYMENT_REQUEST, getUnpaidPaymentAction);
  yield takeEvery(GET_PAYMENT_REQUEST, getPaymentAction);
  yield takeEvery(MARK_PAYMENT_AS_PAID_REQUEST, markInvoiceAsPaid);
}
