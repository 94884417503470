/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import { createStyles, withStyles, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { EditOutlined, DeleteForeverOutlined } from "@material-ui/icons";
import Sidebar from "../common/Header";
import { CustomizedTables, StyledTableCell } from "../common/Table";
import AddPopup from "../common/AddPopup";
import {
  addOnboardingAction,
  getOnboardingAction,
  deleteOnboardingAction,
  getByIdOnboardingAction,
  updateOnboardingAction,
} from "../../../utils/actions/admin.onboarding,action";
import UpdatePopup from "../common/UpdatePopup";
import styles from "./Onboarding.styles";
class Onboarding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdd: false,
      isUpdate: false,
      image: null,
      title: "",
      description: "",
      btn: "",
      id: "",
    };
  }

  componentDidMount = () => {
    this.props.getOnboardingAction();
  };

  componentDidUpdate = (preProps) => {
    if (this.props.deleteData !== preProps.deleteData) {
      this.props.getOnboardingAction();
    }
    if (this.props.addData !== preProps.addData) {
      this.props.getOnboardingAction();
    }
    if (this.props.updateData !== preProps.updateData) {
      this.props.getOnboardingAction();
    }
    if (this.props.getByIdData !== preProps.getByIdData) {
      const data = this.props.getByIdData;
      this.setState({
        isUpdate: true,
        title: data.title,
        description: data.description,
        btn: data.btn,
        id: data._id,
      });
    }
  };

  handleImageChange = (e) => {
    const reg = /(\.jpg|\.jpeg|\.png)$/i;
    if (!reg.exec(e.target.files[0])) {
      this.setState({ image: e.target.files[0] });
    }
  };

  handleSubmit = () => {
    const { image, title, description, btn, isAdd, isUpdate, id } = this.state;
    if (isAdd) {
      const data = new FormData();
      data.set("title", title);
      data.set("description", description);
      data.set("btn", btn);
      data.append("image", image);
      this.props.addOnboardingAction(data);
      this.setState({
        isAdd: false,
        image: null,
        title: "",
        description: "",
        btn: "",
        id: "",
      });
    }
    if (isUpdate) {
      const data = new FormData();
      data.set("title", title);
      data.set("description", description);
      data.set("btn", btn);
      data.append("image", image);
      this.props.updateOnboardingAction(id, data);
      this.setState({
        isUpdate: false,
        image: null,
        title: "",
        description: "",
        btn: "",
        id: "",
      });
    }
  };

  render() {
    const { classes } = this.props;
    

    const inputField = [
      { lable: "Title", value: "title", fieldType: "input" },
      { lable: "Description", value: "description", fieldType: "input" },
      { lable: "Button", value: "btn", fieldType: "input" },
      { lable: "Image", value: "image", fieldType: "file" },
    ];
    const updateInputField = [
      {
        lable: "Title",
        name: "title",
        value: this.state.title,
        fieldType: "input",
      },
      {
        lable: "Description",
        name: "description",
        value: this.state.description,
        fieldType: "input",
      },
      {
        lable: "Button",
        name: "btn",
        value: this.state.btn,
        fieldType: "input",
      },
      {
        lable: "Image",
        name: "image",
        value: this.state.image,
        fieldType: "file",
      },
    ];
    // console.log(this.props.getData);
    const rows = this.props.getData.map((data, index) => (
      <>
        <StyledTableCell>{index + 1}</StyledTableCell>
        <StyledTableCell>{data.title}</StyledTableCell>
        <StyledTableCell>{data.description}</StyledTableCell>
        <StyledTableCell>{data.btn}</StyledTableCell>
        <StyledTableCell>
          <img
            src={`http://localhost:3005/images/uploads/${data.image}`}
            height="10%"
            width="10%"
          />
        </StyledTableCell>
        <StyledTableCell align="left">
          <EditOutlined
            style={{ cursor: "pointer" }}
            onClick={() => this.props.getByIdOnboardingAction(data._id)}
          />
          <DeleteForeverOutlined
            style={{ cursor: "pointer" }}
            onClick={() => this.props.deleteOnboardingAction(data._id)}
          />
        </StyledTableCell>
      </>
    ));
    return (
      <>
        <Sidebar />
        <div className={classes.root}>
          {this.state.isUpdate && (
            <UpdatePopup
              headerTitle="Update Role"
              isOpen={this.state.isUpdate}
              inputField={updateInputField}
              onChangeInput={(e) =>
                this.setState({ [e.target.name]: e.target.value })
              }
              onImageChange={this.handleImageChange}
              onAdd={this.handleSubmit}
              onCancel={() => this.setState({ isUpdate: false })}
            />
          )}
          {this.state.isAdd ? (
            <AddPopup
              headerTitle="Add Onboarding"
              isOpen={this.state.isAdd}
              inputField={inputField}
              onChangeInput={(e) =>
                this.setState({ [e.target.name]: e.target.value })
              }
              onImageChange={this.handleImageChange}
              onAdd={this.handleSubmit}
              onCancel={() => this.setState({ isAdd: false })}
            />
          ) : (
            <Button
              className={classes.addButton}
              onClick={() => this.setState({ isAdd: true })}
            >
              Add Onboarding
            </Button>
          )}
          <CustomizedTables
            headers={[
              "Sr. No.",
              "Title",
              "Description",
              "Button Text",
              "Image",
              "Action",
            ]}
            rows={rows}
            loaded={this.props.getDataLoader}
          />
        </div>
      </>
    );
  }
}

Onboarding.defaultProps = {
  getData: [],
  getDataLoader: true,
};

const connected = connect(
  (state) => ({
    addData: state.addOnboarding,
    getData: state.getOnboarding.getOnboardingData,
    getDataLoader: state.getOnboarding.request,
    deleteData: state.deleteOnboarding.deleteOnboardingData,
    getByIdData: state.getByIdOnboarding.getByIdOnboardingData,
    updateData: state.updateOnboarding.updateOnboardingData,
  }),
  {
    addOnboardingAction,
    getOnboardingAction,
    deleteOnboardingAction,
    getByIdOnboardingAction,
    updateOnboardingAction,
  }
)(Onboarding);

const formed = reduxForm({
  form: "onboarding",
})(connected);


export default withStyles(styles)(formed);
