import { makeStyles} from "@material-ui/core";

export default makeStyles((theme) => ({
    drawerTitle: {
      fontSize: "22px",
      color: "rgba(34,34,34,75%)",
      fontWeight: 700,
      fontFamily: "Lato",
    },
    drawerReferredFont: {
      fontSize: "16px",
      color: "#304659",
      // color: 'rgbd(48,70,89,50%)',
      fontWeight: 400,
      fontFamily: "Lato",
    },
    nameFont: {
      fontSize: "18px",
      color: "#304659",
      fontWeight: 700,
      fontFamily: "Lato",
    },
    refClinicianNameFont: {
      fontSize: "18px",
      color: "#304659",
      fontWeight: 700,
      fontFamily: "Lato",
    },
    buttonText: {
      "&:hover": {
        opacity: "50%",
      },
    },
  }));
  