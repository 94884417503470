/* eslint-disable max-len */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-console */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  createStyles,
  withStyles,
  Divider,
  Button,
  styled,
  Grid,
  Paper,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Select, { components } from "react-select";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import SidebarMenu from "../common/Header";
import {
  addServiceAction,
  getServiceAction,
  updateServiceAction,
  deleteServiceAction,
} from "../../../utils/actions/service.action";
import AlertMsg from "../../../utils/Alert";
import Loader from "../../../components/Loader/Loader";
import styles from "./Service.styles";

class AddService extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      serviceCode: "",
      serviceDes: "",
      serviceRate: "",
      serviceDuration: "",
      isDefaultService: false,
      isAddService: true,
      allService: [],
      isLoaded: null,
    };
  }

  componentDidMount() {
    this.props.getServiceAction();
  }

  componentDidUpdate(preProps) {
    const {
      getAllServiceData,
      addServiceData,
      updateServiceData,
      deleteServiceData,
    } = this.props;
    if (deleteServiceData !== preProps.deleteServiceData) {
      if (deleteServiceData.deleteServiceData === "") {
        AlertMsg("success", "Deleted");
        this.props.getServiceAction();
      }
      if (deleteServiceData.error) {
        AlertMsg("error", "some error occurred in delete");
      }
    }
    if (updateServiceData !== preProps.updateServiceData) {
      if (updateServiceData.updateServiceData) {
        AlertMsg("success", "Updated");
        this.props.getServiceAction();
      }
      if (updateServiceData.error) {
        AlertMsg("error", "some error occurred in update");
      }
    }
    if (getAllServiceData !== preProps.getAllServiceData) {
      this.setState({ isLoaded: getAllServiceData.request });
      const data = getAllServiceData.getServiceData;
      if (data) {
        this.setState({ allService: data });
      }
    }
    if (addServiceData !== preProps.addServiceData) {
      if (addServiceData.addServiceData) {
        AlertMsg("success", "Service Added");
        this.props.getServiceAction();
      }
      if (addServiceData.error) {
        AlertMsg("error", "Some Error Occurred");
      }
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSelectService = (service) => {
    this.setState({ serviceCode: service.code, serviceDes: service.value });
  };

  handleSelectDuration = (duration) => {
    this.setState({ serviceDuration: duration.value });
  };

  handleEditService = (index, value) => {
    const allService = [...this.state.allService];
    allService[index] = { ...this.state.allService[index], status: value };
    this.setState({ allService });
  };

  handleServiceChange = (index) => (e) => {
    const allService = [...this.state.allService];
    allService[index] = {
      ...this.state.allService[index],
      [e.target.name]: e.target.value,
    };
    this.setState({ allService });
  };

  handleServiceDurationChange = (index) => (durationData) => {
    const allService = [...this.state.allService];
    allService[index] = {
      ...this.state.allService[index],
      duration: durationData.value,
    };
    this.setState({ allService });
  };

  handleDeleteService = (item) => {
    this.props.deleteServiceAction(item._id);
  };

  handleUpdateService = (item) => {
    const data = {
      serviceCode: item.serviceCode,
      description: item.description,
      duration: item.duration,
      rate: item.rate,
    };
    this.props.updateServiceAction(item._id, data);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      serviceCode,
      serviceDes,
      serviceRate,
      isDefaultService,
      isAddService,
      allService,
      serviceDuration,
    } = this.state;
    const data = {
      serviceCode,
      description: serviceDes,
      duration: serviceDuration,
      rate: serviceRate,
    };
   

    this.props.addServiceAction(data);
  };

  render() {
    const { classes, addServiceData } = this.props;
    

    const {
      serviceCode,
      serviceDes,
      serviceRate,
      isDefaultService,
      isAddService,
      allService,
      isLoaded,
    } = this.state;
    const serviceOption = [
      {
        code: "90846",
        value: "Family Psychotherapy without patient present",
        label: "90846 - Family Psychotherapy without patient present",
      },
      {
        code: "90847",
        value:
          "Family psychotherapy, conjoint psychotherapy with the patient present",
        label:
          "90847 - Family psychotherapy, conjoint psychotherapy with the patient present",
      },
      {
        code: "90849",
        value: "Multiple-family group psychotherapy",
        label: "90849 - Multiple-family group psychotherapy",
      },
      {
        code: "96103",
        value:
          "Psychological testing by a computer, including time for the psychologists interpretation and reporting",
        label:
          "96103 - Psychological testing by a computer, including time for the psychologists interpretation and reporting",
      },
      {
        code: "96105",
        value: "Assessment of Aphasia",
        label: "96105 - Assessment of Aphasia",
      },
      {
        code: "96111",
        value: "Developmental Testing, Extended",
        label: "96111 - Developmental Testing, Extended",
      },
    ];
    const durationOption = [
      { value: "5", label: "5 Min" },
      { value: "10", label: "10 Min" },
      { value: "15", label: "15 Min" },
      { value: "20", label: "20 Min" },
      { value: "25", label: "25 Min" },
      { value: "30", label: "30 Min" },
      { value: "35", label: "35 Min" },
    ];
    const SingleValue = (props) => (
      <components.SingleValue {...props}>
        {props.data.code}
      </components.SingleValue>
    );
    const customStyle = {
      menu: (styles) => ({
        ...styles,
        width: "480px",
      }),
    };
    return (
      <>
        <SidebarMenu />
        <div className={classes.root}>
          <div className={classes.flexStyle}>
            <Typography className={classes.heading}>
              Click on each Service name to edit. Note: Service Descriptions are
              shown throughout the SimplePractice
            </Typography>
            <Typography className={classes.heading}>
              platform internally, in some client communications and in
              Superbills.
            </Typography>
            <Divider className={classes.divider} />
          </div>

          {isAddService ? (
            <div className={classes.flexStyle}>
              <Button
                className={classes.addServiceButton}
                onClick={() => this.setState({ isAddService: false })}
              >
                Add New Service
              </Button>
            </div>
          ) : (
            <>
              <Typography className={[classes.flexStyle, classes.serviceStyle]}>
                Add New Service
              </Typography>
              <div className={classes.flexStyle}>
                <Grid container className={classes.gridContainer} spacing={2}>
                  <Grid item lg={3}>
                    <Typography className={classes.gridText}>
                      Service
                    </Typography>
                    <Select
                      components={{ SingleValue }}
                      styles={customStyle}
                      options={serviceOption}
                      onChange={this.handleSelectService}
                    />
                  </Grid>
                  <Grid item lg={5}>
                    <Typography className={classes.gridText}>
                      Description
                    </Typography>
                    <input
                      name="serviceDes"
                      value={serviceDes}
                      onChange={this.handleChange}
                      className={classes.divstyle}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <Typography className={classes.gridText}>
                      Rate ($)
                    </Typography>
                    <input
                      name="serviceRate"
                      value={serviceRate}
                      onChange={this.handleChange}
                      className={classes.divstyle}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <Typography className={classes.gridText}>
                      Duration
                    </Typography>
                    <Select
                      className={classes.selectStyle}
                      onChange={this.handleSelectDuration}
                      options={durationOption}
                    />
                  </Grid>
                </Grid>
              </div>
              {/* <div > */}
              <FormControlLabel
                className={classes.flexStyle}
                control={
                  <Checkbox
                    onChange={() =>
                      this.setState({ isDefaultService: !isDefaultService })
                    }
                    checked={this.state.isDefaultService}
                    color="default"
                  />
                }
                label={
                  <Typography className={classes.gridText}>
                    Make this the default service
                  </Typography>
                }
              />
              {/* </div> */}
              <div className={classes.flexStyle}>
                <Grid container className={classes.gridContainer} spacing={10}>
                  <Grid item lg={1}>
                    <Button
                      style={{
                        backgroundColor: "#fff",
                        color: "#1c2022",
                        borderColor: "#1c2022",
                      }}
                      className={classes.addServiceButton}
                      onClick={() => this.setState({ isAddService: true })}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item lg={1}>
                    <Button
                      onClick={this.handleSubmit}
                      className={classes.addServiceButton}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </>
          )}

          {/* service list start */}
          <div style={{ padding: "10px" }}>
            <Typography className={[classes.flexStyle, classes.serviceStyle]}>
              Services
            </Typography>
            <Divider style={{ marginRight: 10, backgroundColor: "#a29061" }} />
            {isLoaded ? (
              <Loader />
            ) : (
              allService.map((item, index) => (
                <>
                  <Grid container spacing={2} style={{ padding: 12 }}>
                    <Grid item lg={2}>
                      <Typography
                        onClick={() =>
                          this.handleEditService(index, !item.status)
                        }
                        className={[
                          classes.serviceListHeaderText,
                          classes.flexServiceList,
                        ]}
                      >
                        {item.serviceCode}
                      </Typography>
                    </Grid>
                    <Grid item lg={9}>
                      <Typography
                        onClick={() =>
                          this.handleEditService(index, !item.status)
                        }
                        className={[
                          classes.serviceListHeaderText,
                          classes.flexServiceList,
                        ]}
                      >
                        {item.description}
                      </Typography>
                      <Typography
                        className={[
                          classes.flexServiceList,
                          classes.serviceListSubHeaderText,
                        ]}
                      >
                        {item.duration} minutes at ${item.rate}
                      </Typography>
                      {item.status && (
                        <>
                          <Grid
                            style={{ width: "90%", marginTop: 10 }}
                            container
                            spacing={2}
                          >
                            <Grid item lg={5}>
                              <Typography className={classes.gridText}>
                                Description
                              </Typography>
                              <input
                                name="description"
                                value={item.description}
                                onChange={this.handleServiceChange(index)}
                                className={classes.divstyle}
                                InputProps={{
                                  classes: {
                                    notchedOutline: classes.notchedOutline,
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item lg={2}>
                              <Typography className={classes.gridText}>
                                Rate ($)
                              </Typography>
                              <input
                                name="rate"
                                value={item.rate}
                                onChange={this.handleServiceChange(index)}
                                className={classes.divstyle}
                                InputProps={{
                                  classes: {
                                    notchedOutline: classes.notchedOutline,
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item lg={2}>
                              <Typography className={classes.gridText}>
                                Duration
                              </Typography>
                              <Select
                                value={durationOption.filter(
                                  (option) =>
                                    option.value === `${item.duration}`
                                )}
                                onChange={this.handleServiceDurationChange(
                                  index
                                )}
                                styles={{ borderColor: "green" }}
                              />
                            </Grid>
                            <Grid item lg={2}>
                              <FormControlLabel
                                style={{ marginTop: 22 }}
                                control={
                                  <Checkbox
                                    // onChange={() => this.setState({ isDefaultService: !isDefaultService })}
                                    checked={item.status}
                                    color="default"
                                  />
                                }
                                label={
                                  <Typography className={classes.gridText}>
                                    Active
                                  </Typography>
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            className={classes.gridContainer}
                            spacing={10}
                          >
                            <Grid item lg={1}>
                              <Button
                                style={{
                                  backgroundColor: "#fff",
                                  color: "#1c2022",
                                  borderColor: "#1c2022",
                                }}
                                className={classes.addServiceButton}
                                onClick={() =>
                                  this.handleEditService(index, !item.status)
                                }
                              >
                                Cancel
                              </Button>
                            </Grid>
                            <Grid item lg={1}>
                              <Button
                                onClick={() => this.handleUpdateService(item)}
                                className={classes.addServiceButton}
                              >
                                Save
                              </Button>
                            </Grid>
                            <Grid item lg={1}>
                              <Button
                                onClick={() => this.handleDeleteService(item)}
                                className={classes.addServiceButton}
                                style={{
                                  display: "flex",
                                  flexDirection: "row-reverse",
                                  backgroundColor: "red",
                                }}
                              >
                                Delete
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Grid item lg={1}>
                      {/* <Paper>1</Paper> */}
                    </Grid>
                  </Grid>
                  <Divider
                    style={{ marginRight: 10, backgroundColor: "#a29061" }}
                  />
                </>
              ))
            )}
          </div>
          {/* service list end */}
        </div>
      </>
    );
  }
}

AddService.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connected = connect(
  (state) => ({
    addServiceData: state.addService,
    getAllServiceData: state.getService,
    updateServiceData: state.updateService,
    deleteServiceData: state.deleteService,
  }),
  {
    addServiceAction,
    getServiceAction,
    updateServiceAction,
    deleteServiceAction,
  }
)(AddService);

const formed = reduxForm({
  form: "addService",
})(connected);



export default withStyles(styles)(formed);
