/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from "react";
import { Popover, Grid, Dialog } from "@material-ui/core";
import dayjs from "dayjs";
import styles from "./Events_style";

export const Events = ({
  event,
  onSelectEvent,
  // slot,
  // handleClickEvent,
  // anchorEl,
  // handleClose,
}) => {
  const classes = styles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = () => {
    onSelectEvent(event)
    //setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Grid
        className={classes.eventContainer}
        onClick={handleClick}
      // onClick={(event) => handleClickEvent(event)}
      >
        {event.event.times.length} Slots Available
      </Grid>
      {/* <Grid > */}
      <Dialog
        className={classes.root}
        open={open}
        fullWidth
        // id={id}
        // marginThreshold={51}
        // anchorEl={anchorEl}
        onClose={handleClose}
      // anchorOrigin={{
      //   vertical: "bottom",
      //   horizontal: "left",
      // }}
      // transformOrigin={{
      //   vertical: "top",
      //   horizontal: "left",
      // }}
      >
        <Grid className={classes.subContainer}>
          <Grid
            alignItems="center"
            justify="flex-start"
            container
            className={classes.date}
          >
            {dayjs(event.event.start).format("MMM DD, YYYY")}
          </Grid>
          <Grid alignItems="center" justify="flex-start" container>
            {event.event.times && event.event.times.map((node) => (
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.time}
                onClick={() => {
                  onSelectEvent(event.event.times, node.time, event.event.start)
                }}
              >
                {node.time}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Dialog>
      {/* </Grid> */}
    </>
  );
};

Events.defaultProps = {
  onSelectEvent: () => { },
};
