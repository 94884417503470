/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import * as React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import styles from "./DrawerImage.styles";

export const DrawerBoxImg = ({ logo, ...props }) => {
  const classes = styles();
  return (
    <Grid>
      <img src={logo} className={classes.root} {...props} />
    </Grid>
  );
};

export const DrawerBoxClinicianImg = ({ style, logo, ...props }) => {
  const classes = styles();
  return (
    <Grid>
      <img src={logo} className={classes.clinician} {...props} />
    </Grid>
  );
};
export const DrawerDotImg = ({ logo, ...props }) => {
  const classes = styles();
  return (
    <Grid>
      <img src={logo} className={classes.dotsty} {...props} />
    </Grid>
  );
};
