import { DialogContent, Typography, Button, Grid } from '@material-ui/core';
import styled from 'styled-components';

const Title = styled(Typography)`
  font-size: 12px;
  padding: 16px;
`;

const Heading = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  font-family: Lato;
`;

export { Title, Heading };
