/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
import { put, takeEvery } from 'redux-saga/effects';
import { get, updatePatch } from '../axios';
import {
  GET_ALL_EVENTS_REQUEST,
  GET_ALL_EVENTS_SUCCESS,
  GET_ALL_EVENTS_FAILED,
  GET_ALL_EVENTS_BY_ID_REQUEST,
  GET_ALL_EVENTS_BY_ID_SUCCESS,
  GET_ALL_EVENTS_BY_ID_FAILED,
  GET_CLIENT_EVENTS_REQUEST,
  GET_CLIENT_EVENTS_FAILED,
  GET_CLIENT_EVENTS_SUCCESS,
  CANCEL_RSVP_REQUEST,
  CANCEL_RSVP_SUCCESS,
  CANCEL_RSVP_FAILED,
} from '../constant';

function* getAllEvents(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_ALL_EVENTS_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_ALL_EVENTS_FAILED, error });
  }
}

function* getAllEventsById(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_ALL_EVENTS_BY_ID_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_ALL_EVENTS_BY_ID_FAILED, error });
  }
}

function* getClientEvents(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_CLIENT_EVENTS_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_CLIENT_EVENTS_FAILED, error });
  }
}

function* cancelRSVP(action) {
  try {
    const { endpoint } = action;
    console.log(endpoint);
    const response = yield updatePatch(endpoint);
    yield put({ type: CANCEL_RSVP_SUCCESS, response });
  } catch (error) {
    yield put({ type: CANCEL_RSVP_FAILED, error });
  }
}

export function* eventsSaga() {
  yield takeEvery(GET_ALL_EVENTS_REQUEST, getAllEvents);
  yield takeEvery(GET_ALL_EVENTS_BY_ID_REQUEST, getAllEventsById);
  yield takeEvery(GET_CLIENT_EVENTS_REQUEST, getClientEvents);
  yield takeEvery(CANCEL_RSVP_REQUEST, cancelRSVP);
}
