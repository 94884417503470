import moment from 'moment';

export const getTimezoneOffset = () => {
  var date = new Date();
  date.setHours(0, 0, 0, 0);
  return date.getTimezoneOffset();
};

export const customDateFormat = (date) => {
  var dateStr =
    ('00' + date.getDate()).slice(-2) +
    '-' +
    ('00' + (date.getMonth() + 1)).slice(-2) +
    '-' +
    date.getFullYear() +
    ' ' +
    ('00' + date.getHours()).slice(-2) +
    ':' +
    ('00' + date.getMinutes()).slice(-2);
  return dateStr;
};

export const calculateAge = (birthday) => {
  // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const adultAge = 18;

export const dateTime = (date, time) => {
  return moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').toDate();
};

export const DATE_FORMAT = 'D/M/yyyy';

export const weekend = [];
