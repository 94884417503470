import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  Heading,
  PlusBtn,
  MessageGrid,
  ActionBtn,
  SortBtn,
  HeadRow,
} from './style';
import { Create } from '@material-ui/icons';
import FormattedTableCell from './TableCell';
import TableAction from './TableAction';
import Loader from '../../Loader/Loader';
const CustomTable = ({
  label,
  data,
  headers,
  showContainerHeading,
  headerActionClick,
  showTableHeaders,
  onEditClick,
  idKey,
  showEdit,
  emptyTableMessage,
  showActionBtns,
  showEditBtn,
  showViewBtn,
  onViewClick,
  isDataLoading,
  history,
  sort,
  editBtnText,
  viewBtnText,
  showIconBtns,
}) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(isDataLoading);
  }, [isDataLoading]);
  return (
    <Grid>
      {showContainerHeading && (
        <Grid container>
          <Grid justify='space-between' container>
            <Heading>{label}</Heading>
            <PlusBtn onClick={headerActionClick}>+</PlusBtn>
          </Grid>
        </Grid>
      )}

      <TableContainer>
        <Table>
          {showTableHeaders && (
            <TableHead>
              <HeadRow>
                {Object.keys(headers).map((h) => (
                  <TableCell key={`lh-th-${headers[h].title}`}>
                    {headers[h].isSortActive ? (
                      <SortBtn onClick={() => sort(h)}>
                        {headers[h].title}
                      </SortBtn>
                    ) : (
                      headers[h].title
                    )}
                  </TableCell>
                ))}
                {showActionBtns && <TableCell>Action</TableCell>}
              </HeadRow>
            </TableHead>
          )}
          <TableBody>
            {data.map((row, i) => (
              <TableRow key={`lh-tr-${i}`}>
                {Object.keys(headers).map((k) => (
                  <FormattedTableCell
                    data={row}
                    headers={headers}
                    keyValue={k}
                    history={history}
                    key={k}
                  />
                ))}
                {showEdit && (
                  <TableCell align='right' padding='none'>
                    <Create
                      onClick={() => onEditClick(idKey ? row[idKey] : row._id)}
                    />
                  </TableCell>
                )}
                <TableAction
                  showActionBtns={showActionBtns}
                  showViewBtn={showViewBtn}
                  idKey={idKey}
                  row={row}
                  viewBtnText={viewBtnText}
                  editBtnText={editBtnText}
                  showEditBtn={showEditBtn}
                  onViewClick={onViewClick}
                  onEditClick={onEditClick}
                  showIconBtns={showIconBtns}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && data && data.length === 0 && (
        <MessageGrid container justify='center'>
          {emptyTableMessage}
        </MessageGrid>
      )}
      {loading && <Loader />}
    </Grid>
  );
};

CustomTable.propTypes = {
  data: PropTypes.array.isRequired,
  headers: PropTypes.object.isRequired,
  label: PropTypes.string,
  headerActionClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onViewClick: PropTypes.func,
  sort: PropTypes.func,
  showEdit: PropTypes.bool,
  showContainerHeading: PropTypes.bool,
  showTableHeaders: PropTypes.bool,
  showActionBtns: PropTypes.bool,
  emptyTableMessage: PropTypes.string,
  idKey: PropTypes.string,
  isDataLoading: PropTypes.bool,
  showEditBtn: PropTypes.bool,
  showViewBtn: PropTypes.bool,
  editBtnText: PropTypes.string,
  viewBtnText: PropTypes.string,
  history: PropTypes.object,
};

CustomTable.defaultProps = {
  isDataLoading: false,
  showEditBtn: true,
  showViewBtn: true,
  editBtnText: 'Edit',
  viewBtnText: 'View',
  showIconBtns: false,
};

export default CustomTable;
