import React, { useEffect, useRef, useState } from 'react';
import { calculateAge } from '../../../utils/timUtils';
import userIcon from '../../../assets/imgs/download.png';
import { DISCIPLINES } from '../../../utils/enums';
import moment from "moment"

const Autocomplete = ({
  options,
  value,
  onChange,
  onSelect,
  isClinician,
  parent,
  selectedValue,
  onCancel,
  multiple,
  editable
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setSelectedOption(selectedValue)
  }, [selectedValue]);

  const [cursor, setCursor] = useState(-1);
  const ref = useRef();

  const select = (option) => {
    onChange('');
    onSelect(option);
    setSelectedOption(null);
    setShowOptions(false);
  };

  const handleChange = (text) => {
    onChange(text);
    setCursor(-1);
    if (!showOptions) {
      setShowOptions(true);
    }
  };

  // const options = options.filter((option) => option.includes(value));

  const moveCursorDown = () => {
    if (cursor < options.length - 1) {
      setCursor((c) => c + 1);
    }
  };

  const moveCursorUp = () => {
    if (cursor > 0) {
      setCursor((c) => c - 1);
    }
  };

  const handleNav = (e) => {
    switch (e.key) {
      case 'ArrowUp':
        moveCursorUp();
        break;
      case 'ArrowDown':
        moveCursorDown();
        break;
      case 'Enter':
        if (cursor >= 0 && cursor < options.length) {
          select(options[cursor]);
        }
        break;
    }
  };

  useEffect(() => {
    const listener = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShowOptions(false);
        setCursor(-1);
      }
    };

    document.addEventListener('click', listener);
    document.addEventListener('focusin', listener);
    return () => {
      document.removeEventListener('click', listener);
      document.removeEventListener('focusin', listener);
    };
  }, []);

  const ageGroup = (option) => {
    return moment(option.dob).fromNow().split(" ")[0] >= 18 ? 'Adult' : 'Minor';
  }
  const handleCancel = () => {
    onSelect({deleted: selectedOption});
    setSelectedOption(null);
  };

  const Item = ({ option, cancel, isParent }) => (
    <div
      className={`flex items-center px-4 py-2 bg-gray-100 w-full ${isParent ? 'mt-2' : ''
        }`}
    >
      <div className='min-w-0 flex-1 flex items-center'>
        <div className='flex-shrink-0'>
          {ageGroup(option) === 'Minor' && !isClinician ? (
            <div className='h-12 w-12 rounded-full bg-indigo-600 text-white text-center'>
              <span
                className='inline-block text-xl'
                style={{
                  lineHeight: '3rem',
                }}
              >
                {`${option?.firstName[0].toUpperCase()} ${option?.lastName[0].toUpperCase()}`}
              </span>
            </div>
          ) : (
            <div class='flex-shrink-0'>
              <img
                class='h-12 w-12 rounded-full'
                src={option?.avatar || userIcon}
                alt={option?._id}
              />
            </div>
          )}
        </div>
        <div className='min-w-0 flex-1 px-4'>
          <div className='w-full'>
            <p className='text-base font-bold text-gray-900 truncate'>
              {`${option.firstName} ${option.lastName}`}
            </p>
            <p className='mt-0.5 text-xs'>
              {isClinician ? (
                <span className='whitespace-nowrap text-gray-400'>
                  {DISCIPLINES[option.discipline]}
                </span>
              ) : (
                <span className='whitespace-nowrap text-gray-400'>
                  Chart #: {option.chartId} | {ageGroup(option)}
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className={`btn_close ${cancel ? '' : 'hidden'}`}>
        <button className='text-lg' onClick={handleCancel}>
          <i className='fas fa-window-close' />
        </button>
      </div>
    </div>
  );

  return (
    <div className='w-full' ref={ref}>
      {selectedOption ? (
        <div>
          <Item option={selectedOption} cancel={editable} />
          {parent && <Item option={parent} cancel={false} isParent />}
        </div>
      ) : (
        <div>
          <input
            type='text'
            className='w-full border-1 px-4 py-2 outline-none rounded-lg'
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            onFocus={() => setShowOptions(true)}
            onKeyDown={handleNav}
            autocomplete={+new Date()}
          />

          <ul
            className={`bg-white absolute w-auto max-h-40 top-10 left-0 overflow-auto z-10 rounded-lg shadow-lg ${!showOptions && 'hidden'
              } select-none`}
          >
            {options.length > 0 ? (
              options.map((option, i, arr) => {
                let className = 'px-4 hover:bg-gray-100 ';

                if (i === 0) className += 'pt-2 pb-1 rounded-t-lg';
                else if (i === arr.length)
                  className += 'pt-1 pb-2 rounded-b-lg';
                else if (i === 0 && arr.length === 1)
                  className += 'py-2 rounded-lg';
                else className += 'py-1';

                if (cursor === i) {
                  className += ' bg-gray-100';
                }

                return (
                  <li
                    className={className}
                    key={option._id}
                    onClick={() => select(option)}
                  >
                    <Item option={option} cancel={false} />
                  </li>
                );
              })
            ) : (
              <li className='px-4 py-2 text-gray-500'>No results</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Autocomplete;
