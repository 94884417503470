import { TableCell, Button, Grid } from '@material-ui/core';
import styled from 'styled-components';

const UnWrappedCell = styled(TableCell)`
  white-space: nowrap; ;
`;

const ActionBtn = styled(Button)`
  background-color: ${(props) =>
    props.color ? props.color : '#003264'} !important;
  color: white !important;
  padding: 0px !important;
  min-width: 0px !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 50% !important;
  &:hover {
    background-color: ${(props) =>
      props.color ? props.color : '#003264'} !important;
  }
`;

const Message = styled(Grid)`
  font-size: 22px;
  margin-top: 20px;
`;

export { UnWrappedCell, ActionBtn, Message };
