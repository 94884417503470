import { Grid, Typography, Button } from '@material-ui/core';
import styled from 'styled-components';

const Label = styled(Typography)`
  text-align: left;
  fontsize: 13px;
  color: grey;
  font-weight: 500;
  margin-bottom: 4px;
`;

const Box = styled(Grid)`
  position: relative;
`;

const DisabledGrid = styled(Grid)`
  height: 105px;
  background-color: #f8f8f8;
  color: #707070;
  border: 1px solid #dddfe1;
  font-size: 22px;
  font-family: Lato;
  font-weight: 700;
`;

const CopyBtn = styled(Button)`
  background-color: #dddfe1;
  color: #6a6a6a;
  padding: 5px 10px;
  position: absolute;
  font-size: 7px;
  border-radius: 0px;
  right: 0;
  bottom: 0;
`;

export { Label, DisabledGrid, CopyBtn, Box };
