import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Layout from '../common/AdminLayout';
import Avatar from '@material-ui/core/Avatar';
import userIcon from '../../../assets/imgs/download.png';
import Container from '@material-ui/core/Container';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import useStyles, { Heading, SecondaryHeading } from './finances_style';
import { connect } from 'react-redux';
import { getSingleInvoiceAction } from '../../../utils/actions/billing.action';
import {
  eCheckAction,
  getPaymentUrlAction,
  getPaymentResAction,
} from '../../../utils/actions/payment.action';
import Loader from '../../../components/Loader/Loader';
import moment from 'moment';
import { APPOINTMENT_TYPES } from '../../../utils/enums';
import PopupWindow from '../../../common/PopupWindow';
import DatePicker from 'react-datepicker';
import deepClone from 'deep-clone';
import axios from 'axios';
import { baseURL } from '../../../utils/axios';
import {
  Dialog,
  Button as ConfirmBtn,
  Spinner,
} from '../../../components/atoms';
import AlertMsg from '../../../utils/Alert';

const ComplexGrid = ({
  billingId,
  getInvoiceInfo,
  billing,
  eCheckAction,
  eCheck,
  getPaymentUrlAction,
  getPaymentResAction,
  getPaymentStatus,
  paymentUrl,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [transFieldType, setTransFieldType] = useState('hidden');
  const [transInfo, setTransInfo] = useState({});
  const [paymentMethod, setPaymentMethod] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [ipAddress, setIpAddress] = useState('');
  const [paymentURL, setPaymentURL] = useState('');
  const [loading, setLoading] = useState(false);
  const statusTimer = useRef(null);
  const [editMode, setEditMode] = useState(false);
  const [modifyingInvocie, setModifyingInvoice] = useState(null);

  useEffect(() => {
    getInvoiceInfo(billingId);
    getIP();
  }, []);

  useEffect(() => {
    if (paymentUrl) {
      setPaymentURL(paymentUrl.url);
      statusTimer.current = setInterval(() => {
        getPaymentResAction(billingId);
      }, 2000);

      setShowConfirmation(false);
    }
  }, [paymentUrl]);

  useEffect(() => {
    if (getPaymentStatus?.status === 'paid') {
      clearInterval(statusTimer.current);
      history.push(`/admin/billing/${billingId}`);
      AlertMsg('success', 'Payment updated successfully!');
      closePaymentPortal();
    }
  }, [getPaymentStatus]);

  useEffect(() => {
    if (billing?.status === 'paid') {
      AlertMsg('info', 'Payment is already paid!');
      history.push(`/admin/billing/${billingId}`);
    }

    setInvoice(billing);
  }, [billing]);

  useEffect(() => {
    if (eCheck?.message === 'success') {
      AlertMsg('success', 'Payment updated successfully!');
      history.push(`/admin/billing/${billingId}`);
      setLoading(false);
    }
  }, [eCheck]);

  const getIP = async () => {
    await fetch('https://geoip-db.com/json/', {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((data) => {
        setIpAddress(data.IPv4);
      });
  };
  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setError(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleNext();
  };

  const clinicAddress = (a) =>
    `${a.address1}, ${a.address2 ? a.address2 + ',' : ''} ${a.city}, ${
      a.country.name
    }`;

  const onInfoChange = (e) =>
    setTransInfo({ ...transInfo, [e.target.name]: e.target.value });

  const onDateChange = (date, field) =>
    setTransInfo({ ...transInfo, [field]: date });

  const handlePayment = (e) => {
    const selectedMethod = e.target.value;
    setPaymentMethod(selectedMethod);
    setTransInfo({});
    if (['banktranser', 'pos', 'cheque'].includes(selectedMethod)) {
      setTransFieldType('text');
    } else {
      setTransFieldType('hidden');
    }
  };

  const submitInvoice = () => {
    const paymentObj = {
      paymentMethod,
      transInfo,
    };

    if (paymentMethod === 'card') {
      getPaymentUrlAction(invoice.appointmentId._id, {
        publicIp: ipAddress,
      });
    } else {
      setShowConfirmation(false);
      setLoading(true);
      eCheckAction(invoice.appointmentId._id, paymentObj);
    }
  };

  const handleNext = () => {
    if (paymentMethod !== 'card') {
      if (paymentMethod === 'pos') {
        if (!transInfo.transactionId || transInfo.transactionId.trim() === '') {
          AlertMsg('error', 'Please enter POS Reference Number!');
          return;
        } else if (
          !transInfo.posReferenceNumberConfirm ||
          transInfo.posReferenceNumberConfirm.trim() === ''
        ) {
          AlertMsg('error', 'Please enter Confirm Reference Number!');
          return;
        } else if (
          transInfo.transactionId.trim() !==
          transInfo.posReferenceNumberConfirm.trim()
        ) {
          AlertMsg('error', 'Reference Numbers do not match!');
          return;
        }
      } else if (paymentMethod === 'cheque') {
        if (!transInfo.accountNumber || transInfo.accountNumber.trim() === '') {
          AlertMsg('error', 'Please enter Account Number!');
          return;
        } else if (!transInfo.checkDate) {
          AlertMsg('error', 'Please enter Check Date!');
          return;
        } else if (!transInfo.bankName || transInfo.bankName.trim() === '') {
          AlertMsg('error', 'Please enter Bank name!');
          return;
        } else if (
          !transInfo.transactionId ||
          transInfo.transactionId.trim() === ''
        ) {
          AlertMsg('error', 'Please enter Check Number!');
          return;
        } else if (
          !transInfo.accountTitle ||
          transInfo.accountTitle.trim() === ''
        ) {
          AlertMsg('error', 'Please enter Account holder\'s name!');
          return;
        }
      } else if (paymentMethod === 'banktranser') {
        if (!transInfo.transactionId || transInfo.transactionId.trim() === '') {
          AlertMsg('error', 'Please enter Transaction ID!');
          return;
        } else if (!transInfo.transactionDate) {
          AlertMsg('error', 'Please enter Transaction Date!');
          return;
        }
      }

      setShowConfirmation(true);
    } else {
      submitInvoice();
    }
  };
  const closePaymentPortal = () => setPaymentURL('');

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <input
      style={{ width: '100%', padding: '10px', border: '1px solid #989898' }}
      onClick={onClick}
      ref={ref}
      value={value}
    />
  ));

  const generateFields = () => {
    if (paymentMethod === 'pos') {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              width: '48%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <label>
              Enter POS Transaction Reference Number
              <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              style={{
                width: '100%',
                padding: '10px',
                border: '1px solid #989898',
              }}
              type={transFieldType}
              value={transInfo.transactionId}
              name='transactionId'
              onChange={onInfoChange}
            />
          </div>
          <div
            style={{
              width: '48%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <label>
              Confirm Reference Number
              <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              style={{
                width: '100%',
                padding: '10px',
                border: '1px solid #989898',
              }}
              type={transFieldType}
              value={transInfo.posReferenceNumberConfirm}
              name='posReferenceNumberConfirm'
              onChange={onInfoChange}
            />
          </div>
        </div>
      );
    } else if (paymentMethod === 'cheque') {
      return (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{
                width: '48%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <label>
                Account Holder Name
                <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                style={{
                  width: '100%',
                  padding: '10px',
                  border: '1px solid #989898',
                }}
                type={transFieldType}
                value={transInfo.accountTitle}
                name='accountTitle'
                onChange={onInfoChange}
              />
            </div>
            <div
              style={{
                width: '48%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <label>
                Cheque Number
                <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                style={{
                  width: '100%',
                  padding: '10px',
                  border: '1px solid #989898',
                }}
                type={transFieldType}
                value={transInfo.transactionId}
                name='transactionId'
                onChange={onInfoChange}
              />
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              marginTop: '20px',
            }}
          >
            <label>
              Issuing Bank
              <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              style={{
                width: '100%',
                padding: '10px',
                border: '1px solid #989898',
              }}
              type={transFieldType}
              value={transInfo.bankName}
              name='bankName'
              onChange={onInfoChange}
            />
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '20px',
            }}
          >
            <div
              style={{
                width: '48%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <label>
                Check Date
                <span style={{ color: 'red' }}>*</span>
              </label>
              <div
                style={{
                  width: '100%',
                }}
              >
                <DatePicker
                  locale='en'
                  dateFormat='MMMM d, yyyy'
                  selected={transInfo.checkDate}
                  showYearDropdown
                  name='checkDate'
                  dropdownMode='select'
                  showMonthDropdown
                  onChange={(date) => onDateChange(date, 'checkDate')}
                  autocomplete={+new Date()}
                  customInput={<CustomInput />}
                />
              </div>
            </div>
            <div
              style={{
                width: '48%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <label>
                Account Number
                <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                style={{
                  width: '100%',
                  padding: '10px',
                  border: '1px solid #989898',
                }}
                type={transFieldType}
                value={transInfo.accountNumber}
                name='accountNumber'
                onChange={onInfoChange}
              />
            </div>
          </div>
        </div>
      );
    } else if (paymentMethod === 'banktranser') {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px',
          }}
        >
          <div
            style={{
              width: '48%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <label>
              Transaction Number
              <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              style={{
                width: '100%',
                padding: '10px',
                border: '1px solid #989898',
              }}
              type={transFieldType}
              value={transInfo.transactionId}
              name='transactionId'
              onChange={onInfoChange}
            />
          </div>
          <div
            style={{
              width: '48%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <label>
              Transaction Date
              <span style={{ color: 'red' }}>*</span>
            </label>

            <div
              style={{
                width: '100%',
              }}
            >
              <DatePicker
                locale='en'
                dateFormat='MMMM d, yyyy'
                selected={transInfo.transactionDate}
                showYearDropdown
                name='transactionDate'
                dropdownMode='select'
                showMonthDropdown
                onChange={(date) => onDateChange(date, 'transactionDate')}
                autocomplete={+new Date()}
                customInput={<CustomInput />}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  const vatAmount = () => {
    if (!invoice.appointmentId?.appointmentType?.vat) {
      return 0;
    }

    return Number(
      (
        (subTotal().toFixed(2) / 100) *
        invoice.appointmentId?.appointmentType?.vat
      ).toFixed(2)
    );
  };

  const discountAmount = () =>
    invoice.discount
      ? Number(((subTotal() / 100) * invoice.discount).toFixed(2))
      : 0;

  const total = () =>
    Number((subTotal() + vatAmount() - discountAmount()).toFixed(2));

  const subTotal = () => {
    let amount = Number(invoice.amount);

    const extraFeesAmount = invoice.extraFees.map((e) => Number(e.amount));
    return [amount, ...extraFeesAmount].reduce((sum, a) => sum + a);
  };

  const toggleToEditMode = () => {
    setModifyingInvoice(deepClone(invoice));
    setEditMode(!editMode);
  };

  const batchId = Math.random().toString(36).slice(2);

  const onEditAmount = (e, amountIndex) => {
    const { name, value } = e.target;
    let change = {
      type: '',
      newAmount: '',
      preValue: '',
      updatedBy: localStorage.getItem('email'),
      updatedAt: new Date(),
      batchId,
    };

    const exceptCurrEntryLogs = modifyingInvocie.logs.filter(
      (mil) => mil.batchId !== batchId && mil.type !== name
    );

    if (name === 'amount' || name === 'discount') {
      change.type = name;
      change.newAmount = Number(value);
      change.preValue = Number(invoice[name]);

      setModifyingInvoice({
        ...modifyingInvocie,
        [name]: Number(value),
        logs: [...exceptCurrEntryLogs, change],
      });
    } else {
      let preExtraFee = modifyingInvocie.extraFees;
      let modifyingField = preExtraFee[amountIndex];
      change.type = modifyingField.type;
      change.newAmount = Number(value);
      change.preValue = Number(invoice.extraFees[amountIndex]?.amount) || null;

      modifyingField.amount = value;
      preExtraFee.splice(amountIndex, 1, modifyingField);

      setModifyingInvoice({
        ...modifyingInvocie,
        extraFees: preExtraFee,
        logs: [...exceptCurrEntryLogs, change],
      });
    }
  };

  const updateAmounts = () => {
    const data = {
      amount: modifyingInvocie.amount,
      extraFees: modifyingInvocie.extraFees,
      discount: modifyingInvocie.discount,
      logs: [...invoice.logs, ...modifyingInvocie.logs],
    };

    setLoading(true);

    axios
      .put(`${baseURL}/api/billing/${invoice._id}`, data)
      .then((res) => {
        setEditMode(false);
        setInvoice(deepClone(modifyingInvocie));
        setModifyingInvoice(null);
        setLoading(false);
        AlertMsg('success', 'Invoice updated successfully!');
      })
      .catch((err) => {
        setLoading(false);
        AlertMsg('error', 'Something went wrong please try to modify again.');
      });
  };

  return (
    <div>
      {loading && <Spinner isOpen />}
      <div
        className='text-left text-2xl font-bold'
        style={{
          color: '#314658',
        }}
      >
        Invoice Details
      </div>
      {invoice ? (
        <div>
          <div className='flex flex-col p-4 bg-white'>
            <div className='flex justify-between'>
              <div className='flex justify-start'>
                <div className='mt-1'>
                  <img
                    src={invoice.clientId.avatar || userIcon}
                    className='w-20 h-20 rounded-full'
                  />
                </div>
                <div className='flex flex-col items-start ml-4 leading-loose'>
                  <div className='text-lg font-bold'>{`${invoice.clientId.firstName} ${invoice.clientId.lastName}`}</div>
                  <div className='flex'>
                    <div className='text-base' style={{ paddingRight: '3px' }}>
                      <CalendarTodayIcon fontSize='inherit' />
                    </div>
                    <div>
                      {moment(invoice.appointmentId.startTime).format(
                        'D/MM/YYYY'
                      )}
                    </div>
                  </div>
                  <div className='flex'>
                    <div className='text-base' style={{ paddingRight: '3px' }}>
                      <AccessTimeIcon fontSize='inherit' />
                    </div>
                    <div>
                      {moment(invoice.appointmentId.startTime).format(
                        'hh:mm a'
                      )}
                      , for {invoice.appointmentId.duration}mins
                    </div>
                  </div>
                  <div className='flex items-baseline'>
                    <div
                      className='text-base -m-1'
                      style={{ paddingRight: '3px' }}
                    >
                      <LocationOnIcon />
                    </div>
                    <div>
                      {invoice.user.address &&
                        clinicAddress(invoice.user.address)}
                    </div>
                  </div>
                  <div className='flex'>
                    <div className='text-base' style={{ paddingRight: '3px' }}>
                      <EnhancedEncryptionIcon fontSize='inherit' />
                    </div>
                    <div>
                      Service Type:{' '}
                      {invoice.appointmentId.appointmentType?.type}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {editMode ? (
                  <button
                    className='bg-blue-500 text-white font-bold py-2 px-4 rounded'
                    onClick={updateAmounts}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className='bg-blue-500 text-white font-bold py-2 px-4 rounded'
                    onClick={toggleToEditMode}
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>
            <div className='flex justify-end'>
              <div className='text-lg font-bold'>
                ID:
                {` INV-${moment(invoice.createdAt).format('YY')}-${
                  invoice.chartId
                }`}
              </div>
            </div>
          </div>

          <div className='bg-white mt-8'>
            <div className='flex'>
              <div
                className='flex justify-center items-center font-bold uppercase h-10 text-white w-2/4'
                style={{
                  backgroundColor: '#003265',
                  borderRight: '1px solid #F6F7FB',
                }}
              >
                Service
              </div>
              <div
                className='flex justify-center items-center font-bold uppercase h-10 text-white w-2/4'
                style={{
                  backgroundColor: '#003265',
                  borderLeft: '1px solid #F6F7FB',
                }}
              >
                Cost
              </div>
            </div>
            <div className='flex'>
              <div
                className='flex justify-start items-center h-10 w-2/4 font-medium py-0 px-5'
                style={{
                  color: '#474747',
                  borderRight: '1px solid #F6F7FB',
                  borderBottom: '2px solid #F6F7FB',
                }}
              >
                {invoice.appointmentId.appointmentType.type}
              </div>
              <div
                className='flex justify-center items-center h-10 w-2/4 font-medium'
                style={{
                  borderLeft: '1px solid #F6F7FB',
                  borderBottom: '2px solid #F6F7FB',
                  color: '#656565',
                }}
              >
                {editMode ? (
                  <input
                    type='text'
                    name='amount'
                    style={{ border: '1px solid #ccc' }}
                    className='w-2/4 rounded py-1.5 px-2.5'
                    value={modifyingInvocie?.amount}
                    onChange={(e) => onEditAmount(e, -1)}
                  />
                ) : (
                  `${invoice.amount} AED`
                )}
              </div>
            </div>
            {invoice.extraFees &&
              invoice.extraFees.map((ef, i) => (
                <div className='flex'>
                  <div
                    className='flex justify-start items-center h-10 w-2/4 font-medium py-0 px-5'
                    style={{
                      color: '#474747',
                      borderBottom: '2px solid #F6F7FB',
                      borderRight: '1px solid #F6F7FB',
                    }}
                  >
                    {ef.type}
                  </div>
                  <div
                    className='flex justify-center items-center h-10 w-2/4 font-medium'
                    style={{
                      color: '#656565',
                      borderBottom: '2px solid #F6F7FB',
                      borderLeft: '1px solid #F6F7FB',
                    }}
                  >
                    {editMode ? (
                      <input
                        type='text'
                        style={{ border: '1px solid #ccc' }}
                        className='w-2/4 rounded py-1.5 px-2.5'
                        value={
                          modifyingInvocie?.extraFees?.length > 0
                            ? modifyingInvocie?.extraFees[i].amount
                            : ''
                        }
                        name={modifyingInvocie?.extraFees[i].type}
                        onChange={(e) => onEditAmount(e, i)}
                      />
                    ) : (
                      `${ef.amount} AED`
                    )}
                  </div>
                </div>
              ))}
            <div className='flex'>
              <div
                className='flex justify-end font-bold items-center h-10 w-2/4 py-0 px-5'
                style={{
                  borderRight: '1px solid #F6F7FB',
                  borderBottom: '2px solid #F6F7FB',
                  color: '#474747',
                }}
              >
                Subtotal:
              </div>
              <div
                className='flex justify-center items-center h-10 w-2/4 font-medium'
                style={{
                  borderLeft: '1px solid #F6F7FB',
                  borderBottom: '2px solid #F6F7FB',
                  color: '#656565',
                }}
              >
                {subTotal()} AED
              </div>
            </div>
            <div className='flex'>
              <div
                className='flex justify-end font-bold items-center h-10 w-2/4 py-0 px-5'
                style={{
                  borderRight: '1px solid #F6F7FB',
                  borderBottom: '2px solid #F6F7FB',
                  color: '#474747',
                }}
              >
                Discount
              </div>
              <div
                className='flex justify-center items-center h-10 w-2/4 font-medium'
                style={{
                  borderLeft: '1px solid #F6F7FB',
                  borderBottom: '2px solid #F6F7FB',
                  color: '#656565',
                }}
              >
                {editMode ? (
                  <div className='relative'>
                    <input
                      type='text'
                      style={{ border: '1px solid #ccc' }}
                      className='w-full rounded py-1.5 px-2.5'
                      value={modifyingInvocie?.discount || 0}
                      name='discount'
                      onChange={(e) => onEditAmount(e, 1)}
                    />
                    <span className='absolute top-2 right-3'>%</span>
                  </div>
                ) : (
                  `${invoice.discount || 0}%`
                )}
              </div>
            </div>
            <div className='flex'>
              <div
                className='flex justify-end font-bold items-center h-10 w-2/4 py-0 px-5'
                style={{
                  borderRight: '1px solid #F6F7FB',
                  borderBottom: '2px solid #F6F7FB',
                  color: '#474747',
                }}
              >
                VAT ({invoice.appointmentId.appointmentType.vat || 0}%)
              </div>
              <div
                className='flex justify-center items-center h-10 w-2/4 font-medium'
                style={{
                  borderLeft: '1px solid #F6F7FB',
                  borderBottom: '2px solid #F6F7FB',
                  color: '#656565',
                }}
              >
                {vatAmount()}
              </div>
            </div>
            <div style={{ display: 'flex', backgroundColor: '#E2E8F1' }}>
              <div
                className='flex justify-end font-bold items-center h-10 w-2/4 py-0 px-5'
                style={{
                  borderRight: '1px solid #F6F7FB',
                  borderBottom: '2px solid #F6F7FB',
                  color: '#474747',
                }}
              >
                Total:
              </div>
              <div
                className='flex font-bold justify-center items-center h-10 w-2/4'
                style={{
                  borderLeft: '1px solid #F6F7FB',
                  borderBottom: '2px solid #F6F7FB',
                  color: '#474747',
                }}
              >
                {total()} AED
              </div>
            </div>
          </div>
          <div style={{ marginTop: '30px' }}>
            <div
              className='text-left text-2xl font-bold'
              style={{
                color: '#314658',
              }}
            >
              Select Payment Method
            </div>
            <div className='flex  justify-start p-10 bg-white mt-1'>
              <FormControl>
                <RadioGroup
                  aria-label='quiz'
                  name='quiz'
                  value={value}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value='card'
                    control={<Radio />}
                    label='Credit card: Visa, MasterCard, Amex'
                    onClick={handlePayment}
                  />
                  <FormControlLabel
                    value='pos'
                    control={<Radio />}
                    label='POS System'
                    onClick={handlePayment}
                  />
                  <FormControlLabel
                    value='cheque'
                    control={<Radio />}
                    label='Cheque'
                    onClick={handlePayment}
                  />
                  <FormControlLabel
                    value='banktranser'
                    control={<Radio />}
                    label='Bank Transfer'
                    onClick={handlePayment}
                  />
                  <FormControlLabel
                    value='cash'
                    control={<Radio />}
                    label='Cash'
                    onClick={handlePayment}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          {transFieldType === 'text' && (
            <div style={{ backgroundColor: '#ECF0F5', padding: '40px 20px' }}>
              {generateFields()}
            </div>
          )}
          <div className='mt-10'>
            <button
              className='rounded-2xl font-bold w-24 p-1 p-0'
              style={{
                backgroundColor: '#C5CBD3',
                color: '#314658',
              }}
            >
              Cancel
            </button>
            <button
              className='rounded-2xl text-white font-bold w-24 ml-4 p-1 p-0'
              style={{
                backgroundColor: '#314658',
              }}
              onClick={handleNext}
              disabled={paymentMethod === ''}
            >
              Next
            </button>
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <Dialog
        handleClose={() => setShowConfirmation(false)}
        open={showConfirmation}
      >
        <Grid
          spacing={3}
          style={{ padding: '50px' }}
          direction='column'
          container
        >
          <Grid item>
            <Typography>Are you sure, you want to continue?</Typography>
          </Grid>
          <Grid item>
            <Grid spacing={2} justify='flex-end' container>
              <Grid item>
                <Button onClick={() => setShowConfirmation(false)}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <ConfirmBtn onClick={submitInvoice} text='Confirm' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>

      {paymentURL && (
        <PopupWindow url={paymentURL} closeWindowPortal={closePaymentPortal} />
      )}
    </div>
  );
};

const mapState = (state) => ({
  billing: state.getSingleInvoice.getSingleInvoiceData,
  eCheck: state.eCheck.eCheckData,
  paymentUrl: state.getPaymentUrl.getPaymentUrlData,
  getPaymentStatus: state.getPaymentRes.getPaymentResData,
});

const mapDispatch = (dispatch) => ({
  getInvoiceInfo: (id) => dispatch(getSingleInvoiceAction(id)),
  eCheckAction: (id, data) => dispatch(eCheckAction(id, data)),
  getPaymentUrlAction: (appointmentId, data) =>
    dispatch(getPaymentUrlAction(appointmentId, data)),
  getPaymentResAction: (billingId) => dispatch(getPaymentResAction(billingId)),
});
export default connect(mapState, mapDispatch)(ComplexGrid);
