import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { Dialog, Spinner } from '../../../../components/atoms';
import { getByIdClinician } from '../../../../utils/actions/client.action';
import {
  addAppointmentAction,
  clearNewlyCreatedAppointment,
} from '../../../../utils/actions/appointment.action';
import { ConfirmAppointment } from '../../../../Patient/components/Appointment Request/confirmAppointment';
import { CustomCalendar } from '../../../../Patient/components/Calendar/CustomCalendar';
import {
  formatClinician,
  SlotGrid,
  DialogContent,
  DialogHeader,
  Name,
  AlternateSlotsGrid,
} from './style';
import arrow from '../../../../assets/imgs/Border.png';
import arrowUp from '../../../../assets/imgs/Border (4).svg';
import { dateFormatter1 } from '../../../../utils/validators';

const BookingDialog = ({
  open,
  bundle,
  handleClose,
  getByIdClinician,
  fetchedClinician,
  addAppointment,
  newlyCreatedAppointment,
  clearNewlyCreatedAppointment,
}) => {
  const [clinician, setClinician] = useState(null);
  const [selectTimeSlot, setTimeSlot] = useState(null);
  const [creatingAppointment, setCreatingAppointment] = useState(false);
  const [isAlternative, setIsAlternate] = useState(false);
  const [nextAvailiblityText, setNextAvailiblityText] = useState('');
  const [docIndex] = useState(0);
  useEffect(() => {
    if (bundle) {
      setCreatingAppointment(true);
      getByIdClinician(bundle.clinicianId[0]._id);
    }
    return () => {
      setClinician(null);
      setTimeSlot(null);
    };
  }, []);

  useEffect(() => {
    if (fetchedClinician) {
      const formattedClinician = formatClinician(fetchedClinician);
      setClinician(formattedClinician);
      setCreatingAppointment(false);
    }
  }, [fetchedClinician]);

  useEffect(() => {
    if (newlyCreatedAppointment) {
      setCreatingAppointment(false);
      handleClose(bundle._id, true, newlyCreatedAppointment._id);
      clearNewlyCreatedAppointment();
    }
  }, [newlyCreatedAppointment]);
  const handleSelectTime = (time) => setTimeSlot(time);
  const handleCancelAppointment = () => setTimeSlot(null);
  const closeSpinner = () => setCreatingAppointment(false);

  const onChange = () => setIsAlternate(!isAlternative);

  const handleAppointmentConfirm = () => {
    const data = {
      clinicianId: bundle.clinicianId[0]._id,
      startTime: selectTimeSlot,
      duration: bundle.duration,
      amount: bundle.amount,
      status: 'confirmed',
      isBundle: true,
      bundleId: bundle._id,
    };
    setCreatingAppointment(true);
    addAppointment(bundle.clientId[0]._id, data);
  };

  const getEvents = (schedule) => {
    var temArr1 = [];
    if (schedule && schedule.length) {
      schedule.map((item, i) => {
        let times = [];
        if (item.slots) {
          item.slots.map((val) => {
            times.push({ time: val });
          });
        }
        let start = new Date(item.date);
        var tomorrow = new Date(item.date);
        tomorrow.setDate(tomorrow.getDate() + 1);
        temArr1.push({
          title: `My event ${i}`,
          times: times,
          allDay: false,
          start: Date.parse(item.date + 'T00:00:00'),
          end: Date.parse(item.date + 'T00:00:00'),
          col: '#ee3559',
        });
      });
    }
    return temArr1;
  };

  const handleSelectCalendarTime = (ind, times, time, date) => {
    setNextAvailiblityText(dateFormatter1(date));
    setIsAlternate(false);
    setTimeSlot(new Date(`${dateFormatter1(date)} ${time}`));
  };

  return (
    <Grid>
      <Spinner isOpen={creatingAppointment} handleClose={() => {}} />
      <Dialog
        open={open && clinician}
        handleClose={() => handleClose(bundle._id, false)}
      >
        <DialogContent
          direction='column'
          container
          style={{ minWidth: '500px' }}
        >
          <DialogHeader
            justify='space-between'
            alignItems='center'
            direction='row'
            item
            container
          >
            <Grid
              xs={7}
              direction='column'
              alignItems='flex-start'
              item
              container
            >
              <Name>{clinician && clinician.name}</Name>
              <Typography>{clinician && clinician.bio}</Typography>
            </Grid>
            <Grid xs={5} justify='flex-end' item container>
              {clinician && (
                <img src={clinician.img} alt='avatar' width='100px' />
              )}
            </Grid>
          </DialogHeader>

          <Grid item container>
            {clinician &&
            clinician.nextAvailable &&
            clinician.nextAvailable.slots.length > 0 ? (
              clinician.nextAvailable.slots.map((time, i) => {
                return (
                  time !== 'NaN:00' && (
                    <SlotGrid
                      alignItems='center'
                      justify='center'
                      key={`lh-sg-${i}`}
                      onClick={() => {
                        handleSelectTime(
                          new Date(`${clinician.nextAvailable.date} ${time}`)
                        );
                      }}
                      item
                      container
                    >
                      {time}
                    </SlotGrid>
                  )
                );
              })
            ) : (
              <Grid>Not Available</Grid>
            )}
          </Grid>

          {nextAvailiblityText != 'N/A' ? (
            <AlternateSlotsGrid
              container
              alignItems='center'
              justify='flex-start'
              onClick={onChange}
            >
              {clinician &&
              clinician.nextAvailable &&
              clinician.nextAvailable.slots &&
              isAlternative ? (
                <Grid
                  spacing={2}
                  alignItems='center'
                  justify='flex-start'
                  container
                >
                  <Grid item>
                    <Typography style={{ fontSize: '18px', cursor: 'pointer' }}>
                      Hide calendar
                    </Typography>
                  </Grid>
                  <Grid item>
                    <img src={arrowUp} style={{ cursor: 'pointer' }} />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  spacing={2}
                  alignItems='center'
                  justify='flex-start'
                  container
                >
                  <Grid item>
                    <Typography style={{ fontSize: '18px', cursor: 'pointer' }}>
                      Alternative slots
                    </Typography>
                  </Grid>
                  <Grid item>
                    <img src={arrow} style={{ cursor: 'pointer' }} />
                  </Grid>
                </Grid>
              )}
            </AlternateSlotsGrid>
          ) : (
            ''
          )}

          {isAlternative ? (
            <CustomCalendar
              events={getEvents(clinician.times)}
              onSelectEvent={(times, time, date) =>
                handleSelectCalendarTime(docIndex, times, time, date)
              }
            />
          ) : null}
        </DialogContent>
        {selectTimeSlot && (
          <ConfirmAppointment
            confirmAppointment={handleAppointmentConfirm}
            cancelAppointmment={handleCancelAppointment}
            fullWidth
          />
        )}
      </Dialog>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  fetchedClinician: state.getByIdClinician.getByIdClinicianData,
  newlyCreatedAppointment: state.addAppointment.addAppointmentData,
});

const mapDispatchToProps = (dispatch) => ({
  getByIdClinician: (id) => dispatch(getByIdClinician(id)),
  addAppointment: (clientId, data) =>
    dispatch(addAppointmentAction(clientId, data)),
  clearNewlyCreatedAppointment: () => dispatch(clearNewlyCreatedAppointment()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingDialog);
