/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from "react";
import {
  withStyles,
  CssBaseline,
  Container,
  Typography,
  Grid,
  Paper,
  FormControlLabel,
  Button,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import grid_1 from "../assets/imgs/maxresdefault.jpg";
import hipaa from "../assets/imgs/hipaa-compliant.png";
import telehealth from "../assets/imgs/secure-telehealth-provider.png";
import styles from "./Home_style";

class Home extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <>
        <CssBaseline />
        <Container maxWidth="lg" component="main" className={classes.root}>
          <Typography className={classes.heading}>
            #1 Rated EHR for Private Practice
          </Typography>
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <img src={grid_1} className={classes.gridimage} />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.gridsub}>
                <Typography className={classes.gridheading}>
                  Trusted by over 60,000 Clinicians for:
                </Typography>
                <div>
                  <FormControlLabel
                    className={classes.boxtext}
                    control={
                      <CheckIcon
                        value="remember"
                        className={classes.checkicon}
                      />
                    }
                    label="Telehealth & Teletherapy"
                  />
                </div>
                <div>
                  <FormControlLabel
                    className={classes.boxtext}
                    control={
                      <CheckIcon
                        value="remember"
                        className={classes.checkicon}
                      />
                    }
                    label="Paperless notes and documentation"
                  />
                </div>
                <div>
                  <FormControlLabel
                    className={classes.boxtext}
                    control={
                      <CheckIcon
                        value="remember"
                        className={classes.checkicon}
                      />
                    }
                    label="Automated billing and insurance processing"
                  />
                </div>
                <div>
                  <FormControlLabel
                    className={classes.boxtext}
                    control={
                      <CheckIcon
                        value="remember"
                        className={classes.checkicon}
                      />
                    }
                    label="Mobile messaging and appointment reminders"
                  />
                </div>
                <div>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={6}>
                      <Button className={classes.button}>
                        Start My Free Trial
                      </Button>
                      <Typography className={classes.bottomtext}>
                        30 day trial, no credit card needed
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <img src={hipaa} className={classes.hipaaimg} />
                    </Grid>
                    {/* <Grid item xs={12} sm={4}>
                                            <img src={telehealth} style={{ width: "50%" }} />
                                        </Grid> */}
                  </Grid>
                  {/* <Button className={classes.button}>
                                        Start My Free Trial
                                    </Button>
                                    <Typography className={classes.bottomtext}>
                                        30 day trial, no credit card needed
                                    </Typography> */}
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </>
    );
  }
}



export default withStyles(styles)(Home);
