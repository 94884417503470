import styled from "styled-components";
import { TextField, Button } from "@material-ui/core";

const PhoneNumber = styled(TextField)`
  margin: 10px;
  width: fit-content;
`;

const Message = styled(TextField)`
  margin: 10px;
  width: 90%;
`;

const SendBtn = styled(Button)`
  margin: 10px;
  background-color: #003265;
  color: #fff;
  width: 200px;
`;

export { PhoneNumber, Message, SendBtn };
