import {
  ADD_APPOINTMENT_REQUEST,
  GET_APPOINTMENT_REQUEST,
  GET_BY_ID_APPOINTMENT_REQUEST,
  UPDATE_APPOINTMENT_REQUEST,
  DELETE_APPOINTMENT_REQUEST,
  GET_SERVICE_REQUEST_USER,
  GET_APPOINTMENT_BY_CLIENT_REQUEST,
  GET_APPOINTMENT_DETAIL_REQUEST,
  GET_PREVIOUS_APPOINTMENT_REQUEST,
  FILTERED_APPOINTMENTS_REQUEST,
  SEEN_STATUS_REQUEST,
  GET_CONSENT_INFO_REQUEST,
  SIGN_CONSENT_REQUEST,
  CLEAR_CREATED_APPOINTMENT,
  CLINICIAN_SIGN_CONSENT_REQUEST,
  APPOINTMENTS_OF_CLIENT_REQUEST,
  APPOINTMENTS_OF_CLIENT_REPORT_REQUEST,
} from '../constant';

export const addAppointmentAction = (id, data) => ({
  type: ADD_APPOINTMENT_REQUEST,
  endpoint: '/api/appointment',
  body: data,
});

export const getAppointmentAction = () => ({
  type: GET_APPOINTMENT_REQUEST,
  endpoint: '/api/appointment',
});

export const getByIdAppointmentAction = (id) => ({
  type: GET_BY_ID_APPOINTMENT_REQUEST,
  endpoint: `/api/appointment/${id}`,
});

export const updateAppointmentAction = (id, data) => ({
  type: UPDATE_APPOINTMENT_REQUEST,
  endpoint: `/api/appointment/${id}`,
  body: data,
});

export const deleteAppointmentAction = (id) => ({
  type: DELETE_APPOINTMENT_REQUEST,
  endpoint: `/api/appointment/${id}/cancel`,
});

export const getServiceAction = () => ({
  type: GET_SERVICE_REQUEST_USER,
  endpoint: '/api/services/admin',
});

export const getAppointmentByClientIdAction = (id) => ({
  type: GET_APPOINTMENT_BY_CLIENT_REQUEST,
  endpoint: `/api/appointment/${id}/client`,
});

export const getAppointmentDetailAction = (id) => ({
  type: GET_APPOINTMENT_DETAIL_REQUEST,
  endpoint: `/api/appointment/${id}/confirmation`,
});

export const getPreviousAppointmnetAction = () => ({
  type: GET_PREVIOUS_APPOINTMENT_REQUEST,
  endpoint: '/api/appointment/completed',
});

export const filterAppointments = (
  filters,
  page,
  sortBy,
  sortType,
  nolimit
) => ({
  type: FILTERED_APPOINTMENTS_REQUEST,
  endpoint: `/api/appointment/filter?filters=${filters}&page=${page}&sortBy=${sortBy}&sortType=${sortType}&nolimit=${nolimit}`,
});

export const updateToSeen = (id) => ({
  type: SEEN_STATUS_REQUEST,
  endpoint: `/api/appointment/update-to-seen/${id}`,
});

export const consentFormDetail = (id) => ({
  type: GET_CONSENT_INFO_REQUEST,
  endpoint: `/api/consent/${id}`,
});

export const signTheConsentForm = (id, relation) => ({
  type: SIGN_CONSENT_REQUEST,
  endpoint: `/api/consent/${id}`,
  body: { relation },
});

export const clinicianSignOnConsentForm = (id) => ({
  type: CLINICIAN_SIGN_CONSENT_REQUEST,
  endpoint: `/api/consent/clinician/${id}`,
});

export const clearNewlyCreatedAppointment = () => ({
  type: CLEAR_CREATED_APPOINTMENT,
});

export const getAllAppointmentByBilling = (
  page = 1,
  billingStatus = '',
  createdAt = ''
) => ({
  type: APPOINTMENTS_OF_CLIENT_REQUEST,
  endpoint: `/api/appointment/getByBilling?page=${page}&billingStatus=${billingStatus}&createdAt=${createdAt}`,
});

export const getAllAppointmentByBillingReport = (
  billingStatus = '',
  createdAt = ''
) => ({
  type: APPOINTMENTS_OF_CLIENT_REPORT_REQUEST,
  endpoint: `/api/appointment/getByBilling?exportReport=1&billingStatus=${billingStatus}&createdAt=${createdAt}`,
});
