import React from 'react';
import {
  Typography,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import useStyles from './Reports_style';

const DailyReports = ({ setReportToDownload }) => {
  const classes = useStyles();
  return (
    <TableContainer elevation={0} component={Paper}>
      <Table
        className={classes.table}
        aria-label='simple table'
        style={{ marginTop: '20px' }}
      >
        <TableHead style={{ backgroundColor: '#003265' }}>
          <TableRow>
            <TableCell align='left' style={{ color: '#ffffff' }}>
              DAILY REPORTS
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.body}>
          <TableRow>
            <TableCell>
              <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                <Grid item>
                  <Typography className={classes.blue}>Client Flow</Typography>
                </Grid>
              </Grid>
            </TableCell>

            <TableCell align='right'>
              <Grid item>
                {/* <VisibilityIcon /> */}
                <Button
                  onClick={() => setReportToDownload('clientFlow')}
                  className={classes.downbtn}
                >
                  Download
                </Button>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                <Grid item>
                  <Typography className={classes.blue}>
                    In person Session Report
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>

            <TableCell align='right'>
              <Grid item>
                {/* <VisibilityIcon /> */}
                <Button
                  className={classes.downbtn}
                  onClick={() => setReportToDownload('getInPersonReport')}
                >
                  Download
                </Button>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                <Grid item>
                  <Typography className={classes.blue}>
                    List of Clients with pending payment with upcoming
                    appointments
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>

            <TableCell align='right'>
              <Grid item>
                <Button
                  className={classes.downbtn}
                  onClick={() => setReportToDownload('pendingPaymentUpcoming')}
                >
                  Download
                </Button>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                <Grid item>
                  <Typography className={classes.blue}>
                    List of New clients booked based on the created appointment
                    date
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>

            <TableCell align='right'>
              <Grid item>
                {/* <VisibilityIcon /> */}
                <Button
                  onClick={() => setReportToDownload('newClient')}
                  className={classes.downbtn}
                >
                  Download
                </Button>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                <Grid item>
                  <Typography className={classes.blue}>
                    Psychiatry client appointments with Valid ID number
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>

            <TableCell align='right'>
              <Grid item>
                {/* <VisibilityIcon /> */}
                <Button
                  onClick={() => setReportToDownload('psychiatryAppointments')}
                  className={classes.downbtn}
                >
                  Download
                </Button>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                <Grid item>
                  <Typography className={classes.blue}>
                    Clients with incomplete Documents with upcoming appointments
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>

            <TableCell align='right'>
              <Grid item>
                {/* <VisibilityIcon /> */}
                <Button
                  onClick={() =>
                    setReportToDownload('incompleteDocUpcomingAppointments')
                  }
                  className={classes.downbtn}
                >
                  Download
                </Button>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                <Grid item>
                  <Typography className={classes.blue}>
                    Daily payment Reconciliation
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>

            <TableCell align='right'>
              <Grid item>
                <Button
                  onClick={() => setReportToDownload('paymentReconciliation')}
                  className={classes.downbtn}
                >
                  Download
                </Button>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DailyReports;
