/* eslint-disable import/no-named-as-default */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from "react";
import { Grid, Typography } from "@material-ui/core";
import line1 from "../../assets/imgs/line1.svg";
import line2 from "../../assets/imgs/line2.svg";
import Loader from "../../user/Loader";
import styles from "./HomeNotification_style";



export const HomeNotification = ({ content, onSelect }) => {
  const classes = styles();
  // console.log(content.length);
  return (
    <>
      {content.map((node, index) => (
        <>
          {node.status === "unseen" && (
            <Grid key={index} className={classes.root}>
              <Grid className={classes.leftRoot} />
              <Grid
                container
                alignItems="center"
                // justify="center"
                className={classes.body}
              >
                <Grid className={classes.titleContainer}>
                  <Typography className={classes.title}>
                    {node.title}
                  </Typography>
                </Grid>
                <Grid className={classes.descContainer}>
                  <Typography className={classes.desc}>{node.desc}</Typography>
                </Grid>
                <Grid className={classes.actionContainer}>
                  <div onClick={() => onSelect(node.id, index)}>
                    <img src={line1} className={classes.line1} />
                    <img src={line2} />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      ))}
    </>
  );
};

HomeNotification.defaultProps = {
  content: [],
};
