/* eslint-disable no-unused-vars */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Header from '../../common/Header';
import onboarding from '../../assets/user_images/onboarding.svg';
import Oval from '../../assets/user_images/Oval 2.svg';
import Rectangle from '../../assets/user_images/Rectangle.svg';
import { CustomNextButtons } from '../../common/CustomButtons';
import { getOnboardingUserAction } from '../../utils/actions/admin.onboarding,action';
import { updateClientStatusAction } from '../../utils/actions/client.action';
import { Loader } from '../Loader';
import { baseURL } from '../../utils/axios';
import styles from './PatientOnboardingStyle';
import PhoneOTPModal from '../../Patient/common/PhoneOTPModal';
// const sliderImage = [
//   { image: onboarding, text: 'First Picture' },
//   { image: onboarding, text: 'Second Picture' },
//   { image: onboarding, text: 'Third Picture' },
// ];

class PatientOnboarding extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sliderIndex: 0,
    };
  }

  componentDidMount() {
    const clientPhoneNumber = localStorage.getItem('clientPhoneNumber');
    const phoneNumberVerified = localStorage.getItem('phoneNumberVerified');
    this.setState({
      clientPhoneNumber,
      phoneNumberVerified,
    });
    this.props.getOnboardingUserAction();
    this.props.updateClientStatusAction(localStorage.getItem('clientUid'), {
      firstLogin: true,
    });
  }

  handleSliderChange = () => {
    if (this.props.getData.length - 1 > this.state.sliderIndex) {
      this.setState({ sliderIndex: this.state.sliderIndex + 1 });
    } else {
      this.props.history.push('/appointment-for');
    }
  };

  render() {
    const { classes } = this.props;
    // console.log(this.props.updateData);
    return (
      <div>
        <Header props={this.props} />
        <div className={classes.root} />
        <Grid container component='div' alignItems='center' justify='center'>
          <Grid item component='div' className={classes.container}>
            <Grid component='div'>
              <Typography className={classes.title}>
                Patient Onboarding
              </Typography>
              {this.props.getData.length > 0 ? (
                <>
                  <Typography className={classes.subtitle}>
                    Welcome {localStorage.getItem('name')}
                  </Typography>

                  <Typography className={classes.text}>
                    {this.props.getData[this.state.sliderIndex].description}
                  </Typography>

                  <Grid
                    className={classes.buttonContainer}
                    container
                    alignItems='center'
                    justify='center'
                  >
                    <CustomNextButtons
                      title={
                        this.props.getData.length - 1 === this.state.sliderIndex
                          ? 'Finish'
                          : this.props.getData[this.state.sliderIndex].btn
                      }
                      width='158px'
                      onPressButton={this.handleSliderChange}
                      style={classes.buttonNext}
                      textStyles={classes.buttonText}
                    />
                  </Grid>

                  <Grid component='div' style={{ marginTop: '37px' }}>
                    <img src={onboarding} className={classes.image} />
                    {/* <Typography>
                  {sliderImage[this.state.sliderIndex].text}
                </Typography> */}
                  </Grid>

                  <Grid component='div' className={classes.pagination}>
                    {this.props.getData.map((data, index) => (
                      <img
                        src={
                          this.state.sliderIndex === index ? Rectangle : Oval
                        }
                        style={{ marginRight: '5px' }}
                      />
                    ))}
                    {/* <img
                      src={this.state.sliderIndex === 1 ? Rectangle : Oval}
                    />
                    <img
                      src={this.state.sliderIndex === 2 ? Rectangle : Oval}
                      style={{ marginLeft: "5px" }}
                    /> */}
                  </Grid>
                </>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    alignContent: 'center',
                    width: '100%',
                    height: '100%',
                    marginTop: '200px',
                  }}
                >
                  <Loader />
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        {this.state.phoneNumberVerified === 'no' && (
          <PhoneOTPModal clientPhoneNumber={this.state.clientPhoneNumber} />
        )}
      </div>
    );
  }
}

PatientOnboarding.defaultProps = {
  getData: [],
};

const connected = connect(
  (state) => ({
    getData: state.getOnboardingUser.getOnboardingUserData,
    updateData: state.updateClient.updateClientData,
  }),
  {
    getOnboardingUserAction,
    updateClientStatusAction,
  }
)(PatientOnboarding);

const formed = reduxForm({
  form: 'onboarding',
})(connected);

export default withStyles(styles)(formed);
