import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Grid, Typography, Drawer } from '@material-ui/core';
import { TraditionalTable, Tabs } from '../../../components/atoms';
import { getClientBundles } from '../../../utils/actions/bundle.action';
import { getInactiveAppointmentsOfClientBundle } from '../../../utils/actions/client.action';
import { Container, Content, Img, PaginationGrid } from './style';
import NoBundlePage from './no_bundle';
import PaymentStatusCell from './payment_status_cell';
import AppointmentStatusCell from './appointment_status_cell';
import BookingDialog from './booking_dialog';
import AlertMsg from '../../../utils/Alert';
import { getPageCount } from '../../../utils/MathUtil';
import PaymentOption from '../../../Patient/components/PaymentOption';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { Pagination } from '@material-ui/lab';
import Loader from '../../../components/Loader/Loader';
const headers = {
  logo: {
    title: 'Clinician',
    concat: true,
    concatOp: ' ',
    prefix: 'clinicianId',
    fields: ['firstName', 'lastName'],
    isImageWithText: true,
  },
  createdAt: { title: 'Date Purchased', isSortActive: true },
  avatar: {
    title: 'Patients',
    concat: true,
    concatOp: ' ',
    prefix: 'clientId',
    fields: ['firstName', 'lastName'],
    isImageWithText: true,
  },

  numberOfSessions: {
    title: 'Sessions Count',
    isSortActive: true,
  },
  numberOfUsedSessionPercentage: {
    title: 'Usage',
    isSortActive: true,
    default: 0,
  },

  paymentStatus: {
    title: 'Payment Status',
    component: PaymentStatusCell,
    conditionalComponent: true,
    propFields: [{ status: 'paymentStatus' }],
    hidden: false,
  },
  actions: {
    title: 'Actions',
  },
};

const appHeaders = {
  logo: {
    title: 'Clinician',
    concat: true,
    concatOp: ' ',
    prefix: 'clinicianId',
    fields: ['firstName', 'lastName'],
    isImageWithText: true,
  },
  startTime: { title: 'Date', isSortActive: true },
  avatar: {
    title: 'Patients',
    prefix: 'clientId',
    isImage: true,
  },

  status: {
    title: 'Appointment Status',
    component: AppointmentStatusCell,
    conditionalComponent: true,
    propFields: [{ status: 'status', date: 'startTime' }],
    hidden: false,
  },
};

const tabHeader = [
  {
    key: 'TraditionalTable-1',
    title: 'Your Purchases',
  },
  {
    key: 'TraditionalTable-2',
    title: 'Usage History',
  },
];

const PAYMENT_ENUM = { paid: 'Paid', init: 'Unpaid', unpaid: 'Unpaid' };

const formatUser = (users) => {
  const formattedUser = users.map((user) => ({
    ...user,
    name: `${user.firstName} ${user.lastName}`,
  }));
  return formattedUser[0];
};

const formatClinician = (users) => {
  const formattedUser = users.map((user) => ({
    ...user,
    logo: user.avatar,
    name: `${user.firstName} ${user.lastName}`,
  }));
  return formattedUser[0];
};

const calculatePercentage = (b) =>
  `${parseFloat((b.numberOfUsedSession / b.numberOfSessions) * 100).toFixed(
    1
  )} %`;

const ServiceBundles = ({
  fetchedBundles,
  totalCount,
  getClientBundles,
  getInactiveAppointmentsOfClientBundle,
  appointments,
  appontmentCounts,
  history,
}) => {
  const initiateBookingProcess = (b) => {
    setOpenBookingDialog(true);
    setBookingBundle(b);
  };

  const handleBookingClose = (bundleId, isSuccess, appointmentId) => {
    if (isSuccess) {
      const bundlesWithUpdatedSessions = bundles.map((b) => {
        if (b._id === bundleId) {
          const updatedBundle = {
            ...b,
            numberOfUsedSession: b.numberOfUsedSession + 1,
          };
          return {
            ...updatedBundle,
            numberOfUsedSessionPercentage: calculatePercentage({
              ...updatedBundle,
            }),
          };
        }
        return b;
      });
      setBundles([...bundlesWithUpdatedSessions]);

      history.push(`/confirmation/${appointmentId}`);
      AlertMsg('success', 'Appointment Booked!');
    }

    setOpenBookingDialog(false);
    setBookingBundle(null);
  };

  const generateBtn = (b) => {
    let btn = {
      _id: b._id,
      text: 'Book Appointment',
      variant: 'contained',
      bgcolor: '#003264',
      color: '#fff',
      onClick: () => initiateBookingProcess(b),
    };

    if (b.billingId[0].status !== 'paid') {
      btn = {
        _id: b._id,
        text: 'Submit Payment',
        variant: 'contained',
        bgcolor: '#da1a18',
        color: '#fff',
        onClick: () => initiatePaymentProcess(b),
      };
    }
    if (b.numberOfSessions === b.numberOfUsedSession) {
      btn = {
        _id: b._id,
        text: 'No Action needed',
        variant: 'outlined',
        bgcolor: '#fff',
        color: '#607d9f',
        onClick: () => {},
      };
    }
    if (b.status === 'refunded') {
      btn = {
        _id: b._id,
        text: 'No Action needed',
        variant: 'outlined',
        bgcolor: '#fff',
        color: '#607d9f',
        onClick: () => {},
      };
    }

    return btn;
  };

  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('desc');
  const [loading, setLoading] = useState(false);
  const [bundles, setBundles] = useState([]);
  const [openBookingDialog, setOpenBookingDialog] = useState(false);
  const [bookingBundle, setBookingBundle] = useState(null);
  const [prevAppointments, setPreviousAppointments] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [componentToRender, setComponentToRender] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [isPaymentOpen, setPaymentOpen] = useState(false);
  const [bundleIdForPayment, setBundleIdForPayment] = useState(null);
  useEffect(() => {
    setLoading(true);
    getClientBundles(page, sortType, sortBy);
  }, []);

  useEffect(() => {
    setLoading(true);
    getInactiveAppointmentsOfClientBundle(page);
  }, [page]);

  useEffect(() => {
    if (fetchedBundles) {
      const formattedBundles = fetchedBundles.map((bundle) => ({
        ...bundle,
        clientId: formatUser(bundle.clientId),
        clinicianId: formatClinician(bundle.clinicianId),
        cancellingUser: formatUser(bundle.cancellingUser),
        billingId: bundle.billingId[0],
        createdAt: moment(new Date(bundle.createdAt)).format('LL'),
        paymentStatus:
          bundle.status === 'refunded'
            ? bundle.status
            : bundle.billingId &&
              bundle.billingId[0] &&
              PAYMENT_ENUM[bundle.billingId[0].status],
        status: bundle.status,
        numberOfUsedSessionPercentage: calculatePercentage(bundle),
        actions: [generateBtn(bundle)],
      }));

      setBundles(formattedBundles);
      handleTabChange(null, 0);
      setLoading(false);
    }
  }, [fetchedBundles]);

  useEffect(() => {
    if (appointments) {
      const formattedAppointments = appointments.map((app) => ({
        ...app,
        clientId: formatUser(app.clientId),
        clinicianId: formatClinician(app.clinicianId),
        startTime: moment(new Date(app.startTime)).format('LL'),
      }));
      setLoading(false);
      setPreviousAppointments(formattedAppointments);
      setTotalPages(appontmentCounts);
    }
  }, [appointments]);

  useEffect(() => {
    if (tabIndex === 0) {
      setComponentToRender(
        <TraditionalTable
          headers={headers}
          data={bundles}
          history={history}
          showActionBtns
        />
      );
    } else if (tabIndex === 1) {
      setComponentToRender(
        <Grid>
          <TraditionalTable
            headers={appHeaders}
            data={prevAppointments}
            loading={loading}
            emptyTableText='No appointment history found!'
          />

          {prevAppointments.length > 0 && (
            <PaginationGrid justify='flex-end' container>
              <Pagination
                count={getPageCount(totalPages)}
                page={page}
                onChange={handlePageChange}
                color='primary'
                shape='rounded'
              />
            </PaginationGrid>
          )}
        </Grid>
      );
    }
  }, [tabIndex, bundles, prevAppointments]);

  const handlePageChange = (e, value) => {
    setPreviousAppointments([]);
    setPage(value);
  };

  const handleTabChange = (e, value) => setTabIndex(value);
  const handlePaymentDrawerClose = () => {
    setBundleIdForPayment(null);
    setPaymentOpen(false);
  };

  const initiatePaymentProcess = (b) => {
    history.push(`/verifyinfo/${b._id}?billingType=bundle`);
  };

  return (
    <Grid>
      {bundles.length > 0 ? (
        <Tabs
          headers={tabHeader}
          handleChange={handleTabChange}
          componentToRender={componentToRender}
          value={tabIndex}
        />
      ) : fetchedBundles ? (
        <NoBundlePage />
      ) : (
        <Loader />
      )}
      {openBookingDialog && (
        <BookingDialog
          open={openBookingDialog}
          handleClose={handleBookingClose}
          bundle={bookingBundle}
        />
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  fetchedBundles: state.getClientBundles.bundles,
  totalCount: state.getClientBundles.count,
  appointments: state.getInactiveAppointmentsOfClientBundle.appointments,
  appontmentCounts: state.getInactiveAppointmentsOfClientBundle.count,
});

const mapStateToDispatch = (dispatch) => ({
  getClientBundles: (page, sortType, sortBy) =>
    dispatch(getClientBundles(page, sortType, sortBy)),
  getInactiveAppointmentsOfClientBundle: (page) =>
    dispatch(getInactiveAppointmentsOfClientBundle(page)),
});

export default connect(mapStateToProps, mapStateToDispatch)(ServiceBundles);
