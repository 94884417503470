/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
import { takeEvery, put } from "redux-saga/effects";
import { post, get, updatePatch, delet } from "../axios";
import {
  ADD_PERMISSION_SUCCESS,
  ADD_PERMISSION_FAILED,
  GET_PERMISSION_SUCCESS,
  GET_PERMISSION_FAILED,
  GET_PERMISSION_BY_ID_SUCCESS,
  GET_PERMISSION_BY_ID_FAILED,
  UPDATE_PERMISSION_SUCCESS,
  UPDATE_PERMISSION_FAILED,
  DELETE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_FAILED,
  ADD_PERMISSION_REQUEST,
  DELETE_PERMISSION_REQUEST,
  UPDATE_PERMISSION_REQUEST,
  GET_PERMISSION_BY_ID_REQUEST,
  GET_PERMISSION_REQUEST,
  GET_PERMISSION_BY_ROLE_SUCCESS,
  GET_PERMISSION_BY_ROLE_FAILED,
  GET_PERMISSION_BY_ROLE_REQUEST,
  UPDATE_PERMISSION_BY_ROLE_REQUEST,
  UPDATE_PERMISSION_BY_ROLE_FAILED,
  UPDATE_PERMISSION_BY_ROLE_SUCCESS,
} from "../AdminConstent";
import AlertMsg from "../Alert";

function* addPermission(action) {
  try {
    const { endpoint, body } = action;
    const response = yield post(endpoint, body);
    AlertMsg("success", "Permission Added");
    yield put({ type: ADD_PERMISSION_SUCCESS, response });
  } catch (error) {
    AlertMsg("error", "Failed");
    yield put({ type: ADD_PERMISSION_FAILED, error });
  }
}

function* getPermission(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_PERMISSION_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_PERMISSION_FAILED, error });
  }
}

function* getByIdPermission(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_PERMISSION_BY_ID_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_PERMISSION_BY_ID_FAILED, error });
  }
}

function* updatePermission(action) {
  try {
    const { endpoint, body } = action;
    const response = yield updatePatch(endpoint, body);
    AlertMsg("success", "Permission Updated");
    yield put({ type: UPDATE_PERMISSION_SUCCESS, response });
  } catch (error) {
    AlertMsg("error", "Failed");
    yield put({ type: UPDATE_PERMISSION_FAILED, error });
  }
}

function* deletePermission(action) {
  try {
    const { endpoint } = action;
    const response = yield delet(endpoint);
    yield put({ type: DELETE_PERMISSION_SUCCESS, response });
  } catch (error) {
    yield put({ type: DELETE_PERMISSION_FAILED, error });
  }
}

function* updatePermissionByRole(action) {
  try {
    const { endpoint, body } = action;
    const response = yield updatePatch(endpoint, body);
    AlertMsg("success", "Permission Updated");
    yield put({ type: UPDATE_PERMISSION_BY_ROLE_SUCCESS, response });
  } catch (error) {
    AlertMsg("error", "Failed");
    yield put({ type: UPDATE_PERMISSION_BY_ROLE_FAILED, error });
  }
}

function* getPermissionByRole(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_PERMISSION_BY_ROLE_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_PERMISSION_BY_ROLE_FAILED, error });
  }
}

export function* permissionSaga() {
  yield takeEvery(ADD_PERMISSION_REQUEST, addPermission);
  yield takeEvery(GET_PERMISSION_REQUEST, getPermission);
  yield takeEvery(GET_PERMISSION_BY_ID_REQUEST, getByIdPermission);
  yield takeEvery(UPDATE_PERMISSION_REQUEST, updatePermission);
  yield takeEvery(DELETE_PERMISSION_REQUEST, deletePermission);
  yield takeEvery(UPDATE_PERMISSION_BY_ROLE_REQUEST, updatePermissionByRole);
  yield takeEvery(GET_PERMISSION_BY_ROLE_REQUEST, getPermissionByRole);
}
