import React,{component} from 'react';
import '../../assets/css/normalize.css';
import '../../assets/css/fontawesome.min.css';
// import '../../assets/css/style.css';
import logo from '../../assets/imgs/tla-logo-horizontal.svg';

class home extends React.Component{
    render(){
        return(
            <div>

<nav class="top flexbox flex-dir-row flex-nowrap flex-justify-content">
    <div class="item logo"><img src={logo} alt=""/></div>
    <div class="item support" style={{marginLeft: "295px",
    marginRight:" 10px",}}>Frequent Asked Questions  |  Call:  <a href="tel:+97143802088">+97143802088</a></div>
    {/* <?php include "./components/nav-user-avatar.php"; ?> */}
</nav>

<nav className="drawer">
    <ul>
        <li class="active"><a href="home.php"><i class="fas fa-home solid"></i> Home</a></li>
        <li><a href="appointments.php"><i class="far fa-calendar-alt"></i> Appointments</a></li>
        <li><a href="bundled-services.php"><i class="fas fa-layer-group solid"></i> Bundled Services</a></li>
        <li><a href="doctors.php"><i class="fas fa-user-md solid"></i> Doctors</a></li>
        <li><a href="events.php"><i class="fas fa-calendar-check solid"></i> Events & Seminars</a></li>
        <li><a href="invoices.php"><i class="fas fa-file-invoice-dollar solid"></i> Invoices & Payments</a></li>
        <li><a href="profile.php"><i class="fas fa-user-circle solid"></i> Profile</a></li>
        <li><a href="_login.php"><i class="fas fa-power-off solid"></i> Logout</a></li>
    </ul>
</nav>

<section class="content">

    {/* <!-- error-msg --> */}
    <div className="error-msg" style={{marginBottom:'20px', textAlign:"left"}}>
        <p style={{marginBottom: "0"}}><strong>Invoice Alert</strong></p>
        <p id="alert">You have outstanding invoices. Kindly go to <a href="invoices.php">Invoices & Payments</a> to get your account to good standing.</p>
    </div>

    <div class="hero flexbox flex-dir-row flex-wrap flex-justify-content">
        {/* <!-- mental health --> */}
        <div class="item mental-health bg-white padding-20">
            <div class="inner-container">
                <h2 style={{margin: "0 0 5px", textAlign: "center"}}>Mental Health</h2>
                <h3 style={{margin: "0 0 15px", textAlign: "center" ,opacity: ".25"}}>Adults & Children</h3>
                <p style={{ textAlign: "left"}}><strong>Concerned about your mental health?</strong></p>
                <p style={{ textAlign: "left"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc consectetur dui vitae bibendum mollis.</p>
                <a id="create-appointment" className="btn1" href="javascript:void(0);">Schedule Appointment</a>
            </div>
        </div>

        {/* <!-- mental health --> */}
        <div class="item mental-health bg-white padding-20">
            <div class="inner-container">
                <h2 style={{margin: "0 0 5px", textAlign:" center"}}>Your TO DOs</h2>
                <h3 style={{margin:" 0 0 15px", textAlign: "center", opacity: ".25"}}>Complete Account Setup</h3>
                <p><strong>Why is this so important?</strong></p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc consectetur dui vitae bibendum mollis.</p>

                <table className="to-dos margin-bottom-20">
                    <tr class="completed"  >
                        <td class="todo">Upload a recent photo.</td>
                        <td class="icon"  ><i class="fas fa-check-circle solid"></i></td>
                </tr>
                    <tr class="completed">
                        <td class="todo">Upload a digital copy of your government issued ID or passport.</td>
                        <td class="icon"><i class="fas fa-check-circle solid"></i></td>
                    </tr>
                    <tr class="">
                        <td class="todo">Upload a digital copy of your tenant agreement, or a utility bill.</td>
                        <td class="icon"><i class="fas fa-exclamation-circle solid"></i></td>
                    </tr>
                </table>

                <a id="create-appointment" className="btn2" style={{btnAppointment:" large"}} href="javascript:void(0);">Go To Your Profile</a>
            </div>
        </div>
    </div>



    {/* <!-- Upcoming Appointments --> */}
    <h3 className='name' style={{margin:" 0 0 5px", opacity: ".35;", textAlign:"left",  display: 'block',
    fontSize: '1.17em',
    marginBlockStart: '1em',
    marginBlockEnd: '1em',
    marginInlineStart: '0px',
    marginInlineEnd: '0px',
    fontWeight:' bold',
    

    }}>Upcoming Appointments</h3>
    
    <table  id="appointments">
        <thead>
            <tr style={{backgroundColor:" #333", color:" #FFF"}}>
                <th>Date & Time</th>
                <th>Client(s)</th>
                <th>Clinician</th>
                <th>Status</th>
                <th>Location</th>
                <th>Requested</th>
                <th>Payment Status</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td scope="row" data-label="Date & Time">
                    <span class="date font-bold">15-06-2021</span>
                    <span class="time font-small opaque-50">09:00AM - 09:30AM</span>
                </td>
                <td data-label="Client(s)">
                    <div class="flexbox flex-dir-row flex-nowrap">
                        <div class="item"><img src="./img/avatar-patient.png" width="35" height="auto"/></div>
                        <div class="item">
                            <span class="name font-bold display-block">Christina Mohammed</span>
                            <span class="name font-small opaque-50">Single Adult</span>
                        </div>
                    </div>
                </td>
                <td data-label="Clinician">
                    <div class="flexbox flex-dir-row flex-nowrap">
                        <div class="item"><img src=".assets/img/avatar-clinician.png" width="35" height="auto"/></div>
                        <div class="item">
                            <span class="name font-bold display-block">Maria Mercedes Ortiz</span>
                            <span class="name font-small opaque-50">Psychiatric, MD</span>
                        </div>
                    </div>
                </td>
                <td data-label="Status"><span className="tag font-small confirmed">Confirmed</span></td>
                <td data-label="Location"><span class="">Online</span></td>
                <td data-label="Requested">
                    <span className="date font-bold">03-05-2021</span>
                    <span className="time font-small opaque-50">11:53AM</span>
                </td>
                <td data-label="Payment Status"><span className="tag font-small success">Paid</span></td>
                <td data-label="Actions">
                    <button class="with-tooltip"><i class="fas fa-file-invoice-dollar font-bold"></i><span class="tooltip">Make Payment</span></button> 
                    <button class="with-tooltip"><i class="far fa-calendar-alt"></i><span class="tooltip">Reschedule</span></button>
                    <button class="with-tooltip"><i class="fas fa-window-close font-bold"></i><span class="tooltip">Cancel</span></button>
                    <button class="with-tooltip"><i class="fas fa-eye font-bold"></i><span class="tooltip">View Details</span></button>
                </td>
            </tr>
            </tbody>
    </table>
</section>

            </div>
        )
    }
}
export default home