import styled from 'styled-components';

const Rectangle = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: #000;
`;

const Img = styled.img`
  max-width: ${(props) => props.width};
  max-height: ${(props) => props.height};
`;

export { Rectangle, Img };
