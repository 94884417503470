import {makeStyles} from "@material-ui/core";

export default makeStyles({
    root: {
      "&:nth-of-type(even)": {
        backgroundColor: "#ffffff",
      },
    },
    container: {
      display: "flex",
      marginTop: "20px",
    },
  });
  