import { Grid } from '@material-ui/core';
import styled from 'styled-components';

const Img = styled.img`
  width: 400px;
  height: 100px;
  background-color: gray;
  border: 1px solid #ccc;
`;

const ImgGrid = styled(Grid)`
  margin-bottom: 20px;
`;

const FilterGrid = styled(Grid)`
  margin-bottom: 30px;
`;

export { Img, ImgGrid, FilterGrid };
