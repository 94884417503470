/* eslint-disable import/prefer-default-export */
import {
  GET_ALL_EVENTS_REQUEST,
  GET_ALL_EVENTS_SUCCESS,
  GET_ALL_EVENTS_FAILED,
  GET_ALL_EVENTS_BY_ID_REQUEST,
  GET_ALL_EVENTS_BY_ID_SUCCESS,
  GET_ALL_EVENTS_BY_ID_FAILED,
  GET_CLIENT_EVENTS_REQUEST,
  GET_CLIENT_EVENTS_FAILED,
  GET_CLIENT_EVENTS_SUCCESS,
  CANCEL_RSVP_REQUEST,
  CANCEL_RSVP_SUCCESS,
  CANCEL_RSVP_FAILED,
} from '../constant';

export const getAllEvents = (state = [], action) => {
  switch (action.type) {
    case GET_ALL_EVENTS_REQUEST:
      return {
        request: true,
      };
    case GET_ALL_EVENTS_SUCCESS:
      return {
        request: false,
        getAllEventsData: action.response,
      };
    case GET_ALL_EVENTS_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getAllEventsById = (state = [], action) => {
  switch (action.type) {
    case GET_ALL_EVENTS_BY_ID_REQUEST:
      return {
        request: true,
      };
    case GET_ALL_EVENTS_BY_ID_SUCCESS:
      return {
        request: false,
        getAllEventsByIdData: action.response,
      };
    case GET_ALL_EVENTS_BY_ID_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getClientEvents = (state = [], action) => {
  switch (action.type) {
    case GET_CLIENT_EVENTS_REQUEST:
      return {
        request: true,
      };
    case GET_CLIENT_EVENTS_SUCCESS:
      return {
        request: false,
        clientEvents: action.response,
      };
    case GET_CLIENT_EVENTS_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const cancelRSVP = (state = [], action) => {
  switch (action.type) {
    case CANCEL_RSVP_REQUEST:
      return {
        request: true,
      };
    case CANCEL_RSVP_SUCCESS:
      return {
        request: false,
        cancelledRSVP: action.response,
      };
    case CANCEL_RSVP_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};
