import React from 'react';
import PropTypes from 'prop-types';
import { Circle } from './style';

const StatusIndicator = ({ status }) => <Circle status={status} />;

StatusIndicator.propTypes = {
  status: PropTypes.string,
};

export default StatusIndicator;
