import React, { useEffect, useState } from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Axios from 'axios';
import { baseURL } from '../../utils/axios';
import FilterImage from '../../assets/clinician_image/Group 351@2x.png';
import Loader from '../../components/Loader/Loader';
import FilterDrawer from './FilterDrawer';
import PatientDetailClinician from './patientDetail';
import { Grid, Button } from '@material-ui/core';
import '../clinician.scss';
import userIcon from '../../assets/imgs/download.png';
import Layout from '../Layout';
// import { TextFormat } from "@material-ui/icons";
import Pagination from '@material-ui/lab/Pagination';
import { useHistory, useLocation } from 'react-router-dom';
import useStyles from './Patients.styles';
import { getPageCount } from '../../utils/MathUtil';
import PropTypes from 'prop-types';

AppointmentClinician.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default function AppointmentClinician(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  // const [currency, setCurrency] = React.useState('EUR');
  // const [detailDrawer, setDetailDrawer] = React.useState(false)
  const [filterDrawer, setFilterDrawer] = React.useState(false);
  const [patientDrawer, setPatientDrawer] = React.useState(false);
  // const [patientrDrawer, setPatientrDrawer] = React.useState(false);
  const [patientId, setPatientId] = React.useState('');
  // const [appointmentId, setAppointmentId] = React.useState('');
  // const [appointmentList, setAppointmentList] = React.useState([]);
  const [patientFilterId, setFilterId] = React.useState('');
  const [isLoaded, setIsloaded] = React.useState(false);
  const [patientLists, setPatientLists] = useState([]);
  const [page, setPage] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(0);
  const [searchText, setSearchTxt] = useState('');
  const [sortType, setSortType] = useState('desc');

  useEffect(() => {
    if (localStorage.getItem('startedSessionId')) {
      props.history.push(
        `/clinician/appointments/appointment-details/${localStorage.getItem(
          'startedSessionId'
        )}`
      );
    }
    setIsloaded(true);
    // getAppointments(filterId);
    getPatientLists('');
  }, []);

  useEffect(() => {
    handleSearch();
  }, [searchText]);

  function handleSearch() {
    setPage(1);
    getPatientLists(searchText);
    setIsloaded(true);
  }

  function handleSearchChange(event) {
    setSearchTxt(event.target.value);
  }

  const getPatientLists = (term) => {
    let uid = localStorage.getItem('clinicianUID');
    Axios.get(
      `${baseURL}/api/client/getPatientByClinician/${uid}?page=${page}&term=${term}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        setIsloaded(false);
        if (res.data) {
          setPatientLists(res.data.data);
          setTotalCount(res.data.count);
        }
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setFilterDrawer(open);
  };

  const togglePatientDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setPatientDrawer(open);
  };

  const handleSubmitRequest = (params) => {
    let axiosConfig = {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    };
    Axios.post(`${baseURL}/api/filter`, params, axiosConfig)
      .then((res) => {
        setFilterDrawer(false);
        setFilterId(res.data._id);
        getPatients(res.data._id);
        // setAppointmentList(res.data)
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const getDate = (date) => {
    const event = new Date(date);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return event.toLocaleDateString('en-US', options);
  };
  const applyFilters = (params) => {
    setFilterDrawer(false);
    setFilterId('');
    console.log(params);
    // setAppliedFilters(params);
  };

  const getPatients = (filterId, sortBy, sortType) => {
    setIsloaded(true);
    var uid = localStorage.getItem('clinicianUID');
    Axios.get(
      `${baseURL}/api/client/getPatientByClinician/${uid}?page=${page}&filterId=${filterId}&sortBy=${sortBy}&sortType=${sortType}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        setPatientLists(res.data.data);
        setTimeout(() => {
          setIsloaded(false);
        }, 500);
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const filterPatient = (id) => {
    getPatients(id);
  };

  const handlePageChange = (event, value) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', value);
    history.push(window.location.pathname + '?' + currentUrlParams.toString());
    setPage(value);
    setIsloaded(true);
  };

  useEffect(() => {
    // action on update of movies
    getPatientLists(searchText);
  }, [page]);

  const sort = (key) => {
    const toggleType = sortType === 'asc' ? 'desc' : 'asc';
    setSortType(toggleType);
    getPatients(searchText, key, toggleType);
  };

  return (
    <Layout
      patientFilterId={patientFilterId}
      filterPatient={filterPatient}
      patients={patientLists}
    >
      <Grid container>
        <Grid xs={6}>
          <OutlinedInput
            className={classes.inputstyle}
            style={{ float: 'left' }}
            placeholder='Search'
            id='outlined-adornment-password'
            onChange={handleSearchChange}
            value={searchText}
          />
        </Grid>
        <Grid xs={6}>
          <div style={{ float: 'right' }}>
            <Button
              variant='contained'
              style={{
                height: '49px',
                overflow: 'hidden',
                maxWidth: '47px',
                minWidth: 'unset',
              }}
              onClick={() => setFilterDrawer(true)}
              className={classes.btnNewApp}
            >
              {/* <HorizontalSplitIcon /> */}
              <img src={FilterImage} width='50' />
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <TableContainer>
          <Table className={classes.table} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>CHART NUMBER</TableCell>
                <TableCell>IMAGE</TableCell>
                <TableCell>
                  <Button onClick={() => sort('name')}>NAME</Button>
                </TableCell>
                <TableCell>
                  <Button onClick={() => sort('date')}>Date</Button>
                </TableCell>
                <TableCell>
                  <Button onClick={() => sort('status')}>CLIENT STATUS</Button>
                </TableCell>
                <TableCell align='right'>ACTION</TableCell>
              </TableRow>
            </TableHead>
            <TableBody component={Paper}>
              {isLoaded && (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                    <Loader />
                  </TableCell>
                </TableRow>
              )}
              {!isLoaded &&
                patientLists.length > 0 &&
                patientLists.map((val, i) => (
                  <TableRow key={1}>
                    <TableCell component='th' scope='row'>
                      {val.chartId}
                    </TableCell>
                    <TableCell>
                      <img
                        src={
                          val.clientId && val.clientId.avatar
                            ? val.clientId.avatar
                            : userIcon
                        }
                        style={{ borderRadius: '50%' }}
                        width='50'
                      />
                    </TableCell>
                    <TableCell>
                      <TableCell>
                        {val.clientId
                          ? val.clientId.firstName + ' ' + val.clientId.lastName
                          : '--'}
                      </TableCell>
                    </TableCell>
                    <TableCell>
                      {val.startTime ? getDate(val.startTime) : ''}
                    </TableCell>
                    <TableCell>
                      {val.clientStatus ? val.clientStatus : ''}
                    </TableCell>
                    <TableCell align='right'>
                      <Button
                        variant='contained'
                        onClick={() => {
                          setPatientDrawer(true);
                          setPatientId(val.clientId._id);
                        }}
                        className={classes.viewBtn}
                      >
                        View
                      </Button>
                      {/* <Link to={`/clinician/patient-detail/${val.clientId._id}`}>
                            <Button variant="contained" className={classes.viewBtn}>View</Button>
                          </Link> */}
                    </TableCell>
                  </TableRow>
                ))}
              {!isLoaded
                ? patientLists.length === 0 && (
                    <TableRow key={1}>
                      <TableCell
                        component='th'
                        style={{ textAlign: 'center' }}
                        colSpan={7}
                        scope='row'
                      >
                        No Data Found
                      </TableCell>
                    </TableRow>
                  )
                : null}
            </TableBody>
          </Table>
          {patientLists && patientLists.length > 0 && (
            <div className={classes.paginationRoot}>
              <Pagination
                count={getPageCount(totalCount)}
                page={page}
                onChange={handlePageChange}
                color='primary'
                shape='rounded'
              />
            </div>
          )}
        </TableContainer>
      </Grid>
      <FilterDrawer
        toggleDrawer={toggleDrawer}
        filterDrawer={filterDrawer}
        handleSubmitRequest={handleSubmitRequest}
        applyFilters={applyFilters}
      />
      <PatientDetailClinician
        togglePatientDrawer={togglePatientDrawer}
        patientDrawer={patientDrawer}
        patientId={patientId}
      />
      {/* <FilterModalBox handleDetailDrawer={handleDetailDrawer} appointmentId={appointmentId} detailDrawer={detailDrawer} handleSubmitRequest={handleSubmitRequest} /> */}
    </Layout>
  );
}
