import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import { CustomCalendar } from "../../Patient/components/Calendar/CustomCalendar";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import '../../clinician/clinician.scss';
import { Typography } from "@material-ui/core";
import {
  DrawerNameFont,
  DrawerReferredFont,
} from "../../common/DrawerContent/DrawerFont";
import arrowUp from "../../assets/imgs/Border (4).svg";
import { CustomNextButtons } from "../../common/CustomButtons";
import arrow from "../../assets/imgs/Border.png";
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from "./ScheduleModal.style";


export default function ScheduleModal({clinicianDetails, open, handleClose, handleCalendarTimeSelect, handleCancelAppointment, handleAppointmentConfirm, handleTimeSelect, handleAlternativeCalendar, confirmBtnLoading}) {
  const classes = useStyles();
  const getEvents = (schedule)=> {    
    var temArr1 = []
    if(schedule && schedule.length){
      // var keys = Object.keys(schedule)
      schedule.map((item, i) => {
        let times = [];
        if(item.slots){
          item.slots.map((val) => {
            times.push({time: val});
          })
        }
        temArr1.push({
          title:`My event ${i}`,
          times: times,
          allDay: false,
          start: new Date(item.date), // 10.00 AM
          end: new Date(item.date), // 2.00 PM
          col: "#ee3559",
        });
      })
    }
    return temArr1 ;
  }

  return (
    <div>   
      <Dialog open={open} onClose={handleClose} maxWidth={"md"} aria-labelledby="form-dialog-title">
        <DialogContent>
          {clinicianDetails && 
            <>
              {clinicianDetails.selectTimeSlot !== null && (
                <Grid
                  alignItems="center"
                  justify="center"
                  container
                  className={classes.confAppRoot}
                >
                  <Grid
                    justify="center"
                    alignItems="center"
                    container
                    className={classes.appointmentCancelButton}
                    onClick={() => handleCancelAppointment()}
                  >
                    X
                  </Grid>

                  <Grid
                    justify="center"
                    alignItems="center"
                    container
                    className={classes.confAppbody}
                    onClick={() => (!confirmBtnLoading) ? handleAppointmentConfirm() : null}
                  >
                    {(!confirmBtnLoading) ? "Confirm your appointment" : <CircularProgress color="secondary" />}
                  </Grid>
                </Grid>
              )}
              <Grid className={classes.root1}>
                <Grid
                  container
                  alignItems="center"
                  className={classes.bodyHeaderContainer}
                >
                  <Grid>
                    <DrawerNameFont
                      text={clinicianDetails.name}
                      style={{ fontSize: "18px", textAlign: "left" }}
                    />
                    <DrawerReferredFont
                      text={clinicianDetails.speciality}
                      style={{ fontSize: "16px", textAlign: "left" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  component="div"
                  className={classes.availablityCostContainerMobile}
                >
                  {/* {clinicianDetails.cost &&
                    <Grid style={{ marginTop: "10px" }}>
                      <CustomNextButtons
                        title={`Cost: ${clinicianDetails.cost}`}
                        width="229px"
                        style={classes.costContainer}
                      />
                    </Grid>
                  } */}
                  <Typography className={classes.nextAvailabilityText}>
                    {clinicianDetails.availabilityTime === "not available"
                      ? "No Availability"
                      : `Next Availability (${clinicianDetails.nextAvailiblityText})`}
                  </Typography>
                </Grid>
                <Grid className={classes.timeListContainer}>
                {clinicianDetails.nextAvailable && clinicianDetails.nextAvailable.slots.length > 0 ? clinicianDetails.nextAvailable.slots.map((time, i) => {
                  return (
                  <Grid
                    key={i}
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.timeContainer}
                    onClick={() => handleTimeSelect(new Date(`${clinicianDetails.nextAvailable.date} ${time}`))}
                    style={{
                      backgroundColor: clinicianDetails.slot === new Date(`${clinicianDetails.nextAvailable.date} ${time}`) ? "#304659" : "",
                    }}
                  >
                    <Typography
                      className={classes.timeText}
                      style={{
                        color: clinicianDetails.slot === new Date(`${clinicianDetails.nextAvailable.date} ${time}`) ? "#ffffff" : "",
                      }}
                    >
                      {time}
                    </Typography>
                  </Grid>)
                }) : <Grid>Appointments slots not available!</Grid>}
                </Grid> 
                {clinicianDetails.times.length > 0 && clinicianDetails.nextAvailable && clinicianDetails.nextAvailable.slots.length > 0 && 
                  <Grid
                    container
                    alignItems="center"
                    justify="flex-start"
                  >
                    {clinicianDetails.isAlternative ? (
                      <>
                        <DrawerNameFont
                          text="Hide calendar"
                          style={{ fontSize: "18px", cursor: "pointer" }}
                          onClick={() => handleAlternativeCalendar()}
                        />
                        <img
                          src={arrowUp}
                          style={{ marginLeft: "30px", cursor: "pointer" }}
                          onClick={() => handleAlternativeCalendar()}
                        />
                      </>
                    ) : (
                      <>
                        <DrawerNameFont
                          text="Alternative slots"
                          style={{ fontSize: "18px", cursor: "pointer" }}
                          onClick={() => handleAlternativeCalendar()}
                        />
                        <img
                          src={arrow}
                          onClick={() => handleAlternativeCalendar()}
                          style={{ marginLeft: "30px", cursor: "pointer" }}
                        />
                      </>
                    )}
                  </Grid>
                }
                {clinicianDetails.isAlternative ? (
                  <CustomCalendar
                    events= {getEvents(clinicianDetails.times)}
                    onSelectEvent={(times, time, date) => handleCalendarTimeSelect(times, time, date)}
                  />
                ) : null}
              </Grid>
            </>
          }
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="default">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
