import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { DialogContent } from '@material-ui/core';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { baseURL } from '../../../../utils/axios';
import axios from 'axios';
import AlertMsg from '../../../../utils/Alert';
import { userLogin } from '../../../../utils/actions/auth.action';
import { Spinner } from '../../../../components/atoms';
import { connect } from 'react-redux';
import EmailDailog from './EmailDialog';
import VerificationDialog from './VerificationDialog';
import ResetPassword from './ResetPasswordDialog';
import CongratulationDialog from './CongratulationDialog';

import useStyles from './style';

const SimpleDialogDemo = ({
  open,
  onClose,
  signingEmail,
  verifyOTP,
  userLogin,
  history,
}) => {
  const [openReset, setOpenReset] = useState(false);
  const [congratsOpen, setCongratsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [otp, setOTP] = useState('');
  const [otpOpen, setOtpOpen] = useState(false);

  const classes = useStyles();

  const onEmailClose = ({ otpSent }) => {
    if (!email || !otpSent) {
      onClose();
    } else {
      onClose();
      setOtpOpen(true);
    }
  };

  const onVerificationClose = (successfullyVerified) => {
    verifyOTP ? onClose() : setOtpOpen(false);

    if (!successfullyVerified || !otp) {
      return;
    }

    verifyOTP ? setCongratsOpen(true) : setOpenReset(true);
  };

  const closeResetPasswordDialog = ({ updated }) => {
    if (updated) {
      setOpenReset(false);
      setCongratsOpen(true);
    } else {
      setOpenReset(false);
      AlertMsg('error', 'Process aborted');
    }
  };

  return (
    <div>
      <Grid>
        {verifyOTP ? (
          <VerificationDialog
            open={open}
            onClose={onVerificationClose}
            email={signingEmail}
            userLogin={userLogin}
            history={history}
            otp={otp}
            setOTP={(val) => setOTP(val)}
            classes={classes}
            verifyOTP
          />
        ) : (
          <EmailDailog
            open={open}
            onClose={onEmailClose}
            classes={classes}
            email={email}
            setEmail={(val) => setEmail(val)}
          />
        )}
      </Grid>

      <VerificationDialog
        open={otpOpen}
        email={email}
        classes={classes}
        otp={otp}
        setOTP={(val) => setOTP(val)}
        onClose={onVerificationClose}
      />

      <ResetPassword
        open={openReset}
        onClose={closeResetPasswordDialog}
        email={email}
        otp={otp}
        classes={classes}
      />

      <CongratulationDialog
        open={congratsOpen}
        onClose={() => setCongratsOpen(false)}
        verifyOTP={verifyOTP}
        userLogin={userLogin}
        history={history}
        classes={classes}
      />
    </div>
  );
};

export default connect(null, { userLogin })(SimpleDialogDemo);
