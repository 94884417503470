import React from 'react'

function getCompleted(last=false) {
    return <li className={`relative ${!last ? "pr-4 lg:pr-12" : ""}`}>
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="h-0.5 w-full bg-brand-gold-dark"></div>
        </div>
        <a href="#" class="relative w-8 h-8 flex items-center justify-center bg-brand-gold-dark rounded-full hover:bg-indigo-900">
            <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Step 1</span>
        </a>
    </li>
}

function getStep(active, last = false) {
    return !active ? <li className={`relative ${!last ? "pr-4 lg:pr-12" : ""}`}>
        { }
        <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
        >
            <div className="h-0.5 w-full bg-gray-200" />
        </div>
        <a
            href="#"
            className="group relative w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full hover:border-gray-400"
        >
            <span
                className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"
                aria-hidden="true"
            />
            <span className="sr-only">Step 2</span>
        </a>
    </li> : <li className="relative pr-4 lg:pr-12">
        { }
        <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
        >
            <div className="h-0.5 w-full bg-gray-200" />
        </div>
        <a
            href="#"
            className="relative w-8 h-8 flex items-center justify-center bg-white border-2 border-brand-gold-dark rounded-full"
            aria-current="step"
        >
            <span
                className="h-2.5 w-2.5 bg-brand-gold-dark rounded-full"
                aria-hidden="true"
            />
            <span className="sr-only">Step 1</span>
        </a>
    </li>
}

function getSteppers(totalSteps, step) {
    let out = []
    // out.push(getStep(true))
    console.log(step)
    for (var i = 0; i < totalSteps; i++) {
        console.log(i)
        if (i <= step - 1 || step + 1 == totalSteps) {
            out.push(getCompleted(i == totalSteps - 1))
            console.log(i, "completed")
        } else if (i == step) {
            out.push(getStep(true))
            console.log(i, "equal")
        } else {
            out.push(getStep(false, i == totalSteps - 1))
            console.log(i, "last")
        }
    }

    return out
}

export default function Stepper(props) {
    return (
        <div className="flex justify-center mx-auto pb-8">
            <nav aria-label="Progress">
                <ol role="list" className="flex items-center mx-auto">
                    {getSteppers(props.totalSteps, props.step)}
                </ol>
            </nav>
        </div>
    )
}
