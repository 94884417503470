/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
import React from "react";
import Sidebar from "../components/Sidebar";
import AccountTab from "../sub_components/account_activity/Tabs_AccountActivity";

class AccountActivity extends React.Component {
  render() {
    return (
      <>
        <Sidebar />
        <AccountTab />
      </>
    );
  }
}

export default AccountActivity;
