/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/order */
/* eslint-disable no-undef */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import {
  withStyles,

  Typography,
  Grid,
  Button,
  Divider,
} from '@material-ui/core';
import Select from 'react-select';
import { Editor } from 'react-draft-wysiwyg';
import Sidebar from '../../components/Sidebar';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './AddNotesStyle';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

class AddNotes extends React.Component {
  state = {
    selectedOption: null,
    editorState: EditorState.createEmpty(),
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  onEditorStateChange: Function = (editorState) => {
    // console.log(editorState)
    this.setState({
      editorState,
    });
  };

  render() {
    const { classes } = this.props;
    const { selectedOption, editorState } = this.state;

    return (
      <div className={classes.root}>
        <Sidebar />
        <div style={{ textAlign: 'left' }}>
          <Typography display="inline" className={classes.title}>
            Session with{' '}
          </Typography>
          <Typography display="inline" className={classes.namesty}>
            Jamie D. Appleseed
          </Typography>
        </div>

        <div>
          <Grid container spacing={0} className={classes.gridsty}>
            <Grid item xs={6} sm={2}>
              <Typography className={classes.heading}>Service</Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography className={classes.subheading}>
                90834 Psychotherapy, 45 min
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={0} className={classes.gridsty}>
            <Grid item xs={6} sm={2}>
              <Typography className={classes.heading}>Billing</Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography className={classes.subheading}>Self-Pay</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={0} className={classes.gridsty}>
            <Grid item xs={6} sm={2}>
              <Typography className={classes.heading}>Duration</Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography className={classes.subheading}>50 minutes</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={0} className={classes.gridsty}>
            <Grid item xs={6} sm={2}>
              <Typography className={classes.heading}>Total Fee</Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography className={classes.subheading}>$100.00</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={0} className={classes.gridsty}>
            <Grid item xs={6} sm={2}>
              <Typography className={classes.heading}>Diagnosis</Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography className={classes.subheading}>
                Add diagnosis
              </Typography>
            </Grid>
          </Grid>
        </div>

        <div>
          <Typography className={classes.progressSty}>Progress Note</Typography>
          <Grid container className={classes.gridsty}>
            <Grid item xs={6} sm={6}>
              <Select
                value={selectedOption}
                onChange={this.handleChange}
                options={options}
                className={classes.selectSty}
              />
            </Grid>
          </Grid>

          <Grid container className={classes.gridsty}>
            <Grid item xs={8} sm={8} className={classes.editorSty}>
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={this.onEditorStateChange}
                placeholder="Begin typing here..."
                className={classes.editorText}
              />
            </Grid>
          </Grid>
          <div className={classes.buttonDiv}>
            <Button
              variant="outlined"
              size="small"
              className={classes.cancelButton}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              className={classes.saveButton}
            >
              Save
            </Button>
          </div>
        </div>

        <Divider className={classes.dividerSty} />

        <div>
          <Typography className={classes.progressSty}>
            Psychotherapy Note
          </Typography>
          <Typography className={classes.physubText}>
            (kept separate from the client record)
          </Typography>
          <Grid container className={classes.gridsty}>
            <Grid item xs={8} sm={8} className={classes.editorSty}>
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={this.onEditorStateChange}
                placeholder="Begin typing here..."
                className={classes.editorText}
              />
            </Grid>
          </Grid>
          <div className={classes.buttonDiv}>
            <Button
              variant="outlined"
              size="small"
              className={classes.cancelButton}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              className={classes.saveButton}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AddNotes);
