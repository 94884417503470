import React from 'react';

const InvoiceLeftColFilter = ({ onChange }) => (
  <select
    name
    id
    className='py-2 px-4 w-36 text-sm rounded-md border border-gray-200 focus:outline-none'
    onChange={onChange}
  >
    <option value='' selected>
      All Invoices
    </option>
    <optgroup label='Filter By Client Type'>
      <option value='regular'>Personal</option>
      <option value='bundle' disabled>Bundles</option>
      <option value='corp_clients' disabled>
        Corporate Client
      </option>
      <option value='corp_direct' disabled>
        Corporation
      </option>
      <option value='corp_clients' disabled>
        Events
      </option>
    </optgroup>
    <optgroup label='Filter By Status'>
      <option value='paid'>Paid</option>
      <option value='unpaid'>Unpaid</option>
      <option value='pending'>Pending</option>
      <option value='partial-unpaid'>Partial</option>
      <option value='split'>Split</option>
      <option value='refunded'>Refunded</option>
      <option value='voided'>Void</option>
    </optgroup>
  </select>
);

export default InvoiceLeftColFilter;
