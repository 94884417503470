import React, { useEffect, useState } from 'react';
import Layout from '../common/AdminLayout';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import userIcon from '../../../assets/imgs/download.png';
import {
  Drawer,
  Grid,
  Typography,
  Button,
  OutlinedInput,
  InputAdornment,
  MenuItem,
  IconButton,
  FormControl,
  Select,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import '../../../clinician/clinician.scss';
import EventDetail from './../../../Patient/components/Events/EventDetail';
import Axios from 'axios';
import { baseURL } from '../../../utils/axios';
import useStyles, { CellBtn } from './Events.styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import moment from 'moment';
import FilterImage from '../../../assets/clinician_image/Group 351@2x.png';
import { toTitleCase } from '../../../utils/validators';
import { getPageCount } from '../../../utils/MathUtil';
import Pagination from '@material-ui/lab/Pagination';
import RSPV from './RSPV';
import AlertMsg from '../../../utils/Alert';
import Loader from '../../../components/Loader';
import ReplicateEventDrawer from './ReplicateEventDrawer';
import 'moment-timezone';
import { useLocation } from "react-router-dom";

export default function AdminEvents() {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [eventId, setEventId] = useState('');
  const [isLoaded, setIsloaded] = React.useState(false);
  const [eventLists, setEventLists] = useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [status, setStatus] = React.useState('upcoming');
  const [rspvOpen, setRspvOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [searchText, setSearchText] = useState('');
  const [sortType, setSortType] = useState('desc');
  const [sortBy, setSortBy] = useState('');
  const search = useLocation().search;
  const statusQuery = new URLSearchParams(search).get('status');
  
  useEffect(() => {
    console.log('statusQuery', statusQuery)
    if (statusQuery) {
      setStatus(statusQuery)
    }
  }, [statusQuery]);

  useEffect(() => {
    setIsloaded(true);
    getEventLists();
  }, []);

  useEffect(() => {
    setIsloaded(true);
    getEventLists();
  }, [page, sortType, sortBy, status, searchText]);

  const handleChange = (event, value) => {
    let val = value.props.value;
    setStatus(val);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getEventLists = () => {
    Axios.get(
      `${baseURL}/api/event?page=${page}&term=${searchText}&status=${status}&sortType=${sortType}&sortBy=${sortBy}&showAll=true`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        setIsloaded(false);
        if (res.data) {
          console.log('data.events', res.data.events);
          setEventLists(res.data.events);
          setTotalCount(res.data.total);
        }
      })
      .catch((e) => {
        AlertMsg('error', e.toString());
      });
  };

  const sort = (key) => {
    const toggleType = sortType === 'asc' ? 'desc' : 'asc';
    setSortType(toggleType);
    setSortBy(key);
  };

  const [openReplicationDrawer, setOpenReplicationDrawer] = useState(false);
  const replicate = (eventId) => {
    setOpenReplicationDrawer(true);
    const selectedEvent = eventLists.filter((ev) => ev._id === eventId);
    setSelectedEvent(selectedEvent[0]);
    console.log('selectedEvent[0]', selectedEvent[0]);
  };

  const cancelReplication = () => {
    setOpenReplicationDrawer(false);
    setSelectedEvent('');
  };

  const replicated = () => {
    setOpenReplicationDrawer(false);
    setSelectedEvent('');
    getEventLists();
  };
  return (
    <>
      <Grid container>
        <Grid xs={6}>
          <OutlinedInput
            className={classes.inputstyle}
            style={{ float: 'left' }}
            placeholder='Search'
            onChange={(evt) => {
              setSearchText(evt.target.value);
            }}
            id='outlined-adornment-password'
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => {
                    setPage(1);
                    getEventLists();
                  }}
                  aria-label='toggle password visibility'
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          {/* <FormControl
            variant='outlined'
            className={classes.formControl}
            style={{
              width: '200px',
            }}
          >
            <Select
              className={classes.inputstyle}
              defaultValue='upcoming'
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value='upcoming'>Upcoming</MenuItem>
              <MenuItem value='previous'>Previous</MenuItem>
            </Select>
          </FormControl> */}
        </Grid>
        <Grid xs={6}>
          <div style={{ float: 'right' }}>
            <Button
              variant='contained'
              href='/admin/addevents'
              className={classes.btnNewApp}
              style={{ height: '49px' }}
            >
              <AddCircleOutlineIcon style={{ marginRight: 5 }} />
              New Event
            </Button>
            <Button
              variant='contained'
              style={{
                height: '49px',
                overflow: 'hidden',
                maxWidth: '47px',
                minWidth: 'unset',
                marginLeft: '12px',
              }}
              onClick={() => {
                //setFilterDrawer(true)
              }}
              className={classes.btnNewApp}
            >
              {/* <HorizontalSplitIcon /> */}
              <img src={FilterImage} width='50' />
            </Button>
          </div>
        </Grid>
      </Grid>
      {/* ///// */}
      <Grid container>
        <TableContainer>
          <Table className={classes.table} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell style={{ textAlign: 'left' }}>
                  <Button onClick={() => sort('title')}> TOPIC</Button>
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  <Button onClick={() => sort('startTime')}> DATE</Button>
                </TableCell>
                <TableCell style={{ marginLeft: '100', textAlign: 'right' }}>
                  <Button onClick={() => sort('isWebinar')}> VENUE</Button>
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  <Button onClick={() => sort('clinician')}> CLINICIAN</Button>
                </TableCell>
                <TableCell style={{ textAlign: 'left' }}>
                  <Button onClick={() => sort('totalSeats')}>SEATS</Button>
                </TableCell>
                <TableCell style={{ textAlign: 'left' }}>
                  <Button onClick={() => sort('event')}>EVENT TYPE</Button>
                </TableCell>
                <TableCell style={{ textAlign: 'left' }}>
                  <Button >COST</Button>
                </TableCell>
                <TableCell style={{ textAlign: 'left' }}>
                  <Button onClick={() => sort('registrationStillOpen')}>
                    Status
                  </Button>
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <Button onClick={() => sort('action')}>ACTION</Button>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Grid>
      {/* ////// */}
      <Grid
        container
        style={{ marginTop: '16px' }}
        className='patientDetailEventTable'
      >
        <TableContainer>
          <Table
            className={classes.table}
            stickyHeader
            aria-label='sticky table'
          >
            {isLoaded ? (
              <Loader />
            ) : (
              <TableBody component={Paper}>
                {eventLists.length > 0 ? (
                  eventLists.map((data, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>
                          <CellBtn
                            component={'p'}
                            className='headNames'
                            onClick={() => sort('title')}
                          ></CellBtn>

                          <Typography component={'p'} className='topicDetail'>
                            {data.title}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <CellBtn
                            component={'p'}
                            className='headNames'
                            onClick={() => sort('startTime')}
                          ></CellBtn>
                          <Typography component={'h4'}>
                            {moment(data.date).format('D/MM/YYYY')}
                          </Typography>
                          <Typography
                            style={{ textAlign: 'left' }}
                            component={'p'}
                            className=''
                          >
                            {moment
                              .tz(data.startTime, 'Asia/Dubai')
                              .format('h:mm a')}{' '}
                            -{' '}
                            {moment
                              .tz(data.endTime, 'Asia/Dubai')
                              .format('h:mm a')}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <CellBtn
                            component={'p'}
                            className='headNames'
                            onClick={() => sort('isWebinar')}
                          ></CellBtn>
                          <Typography component={'h4'}>
                            {data.location && data.location.type == 'PHYSICAL'
                              ? 'Physical'
                              : 'Online'}
                          </Typography>
                          {data.location && data.location.type == 'PHYSICAL' ? (
                            <Typography component={'p'} className=''>
                              {data.location.address}
                            </Typography>
                          ) : (
                            <Link to={data.location.address}>
                              {' '}
                              <Typography component={'p'} className=''>
                                {data.location.address}
                              </Typography>
                            </Link>
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography
                            component={'p'}
                            className='headNames'
                          ></Typography>
                          <Grid className={'images'}>
                            <img src={userIcon} />
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Typography component={'p'} className='headNames'>
                            <CellBtn
                              component={'p'}
                              className='headNames'
                              onClick={() => sort('totalSeats')}
                            ></CellBtn>
                          </Typography>
                          <Typography component={'h4'} className={'seatsAvail'}>
                            {data.totalSeats}
                          </Typography>
                          <Button
                            style={{ padding: '0px', minWidth: '0px' }}
                            onClick={() => {
                              setRspvOpen(true);
                              setSelectedEvent(data);
                            }}
                          >
                            <Typography
                              component={'p'}
                              className={'seatsAvail'}
                            >
                              {data.numberOfAttendees} RSVP&apos;s
                            </Typography>
                          </Button>
                        </TableCell>
                        <TableCell>
                          <CellBtn
                            component={'p'}
                            className='headNames'
                            onClick={() => sort('type')}
                          ></CellBtn>
                          <Typography component={'h4'}>
                            {toTitleCase(data.type)}
                          </Typography>
                          <Typography component={'p'} className=''>
                            {data.category.title}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'h4'}>
                            {data.fee}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {data.registrationStillOpen ? 'Open' : 'Close'}
                        </TableCell>
                        <TableCell align='right'>
                          <div className='flex justify-between'>
                            <Button
                              variant='contained'
                              href={'/admin/addevents/' + data._id}
                              className={classes.viewBtn}
                            >
                              Edit
                            </Button>
                            <Button
                              variant='contained'
                              onClick={() => {
                                setIsOpen(true);
                                setEventId(data._id);
                              }}
                              className={classes.viewBtn}
                              style={{ marginLeft: '16px' }}
                            >
                              View
                            </Button>
                            <Button
                              variant='contained'
                              onClick={() => replicate(data._id)}
                              className={classes.viewBtn}
                              style={{ marginLeft: '16px' }}
                            >
                              Duplicate
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow key={1}>
                    <TableCell
                      component='th'
                      style={{ textAlign: 'center' }}
                      colSpan={6}
                      scope='row'
                    >
                      No Data Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid>
      {!isLoaded && eventLists && eventLists.length > 0 && (
        <div className={classes.paginationRoot}>
          <Pagination
            count={getPageCount(totalCount)}
            page={page}
            onChange={handlePageChange}
            color='primary'
            shape='rounded'
          />
        </div>
      )}
      <Drawer
        anchor='right'
        open={isOpen}
        // {...this.props}
        onClose={() => setIsOpen(false)}
      >
        <EventDetail clinician={true} eventId={eventId} />
      </Drawer>
      <RSPV
        open={rspvOpen}
        event={selectedEvent}
        handleClose={() => {
          setRspvOpen(false);
        }}
      />
      {openReplicationDrawer && (
        <ReplicateEventDrawer
          open={openReplicationDrawer}
          cancelReplication={cancelReplication}
          event={selectedEvent._id}
          replicated={replicated}
        />
      )}
    </>
  );
}
