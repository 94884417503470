/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from 'react';
import { withStyles, Typography, Grid } from '@material-ui/core';
import styles from './HomeStyle';

class Home extends PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.flexStyle}>
          <Typography className={classes.title}>Home</Typography>
        </div>

        <div className={classes.boxsty}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={4}>
              <Typography className={classes.heading}>
                Projected income for April 2020
              </Typography>
              <Typography className={classes.numtext}>$700</Typography>
              <Typography className={classes.heading}>
                40 Appointments (6 Clients)
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography className={classes.heading}>
                Income from March 2020
              </Typography>
              <Typography className={classes.numtext}>--</Typography>
              <Typography className={classes.heading}>
                1 Appointment (1 Client)
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography className={classes.heading}>
                2020 income year-to-date
              </Typography>
              <Typography className={classes.numtext}>$300</Typography>
              <Typography className={classes.heading}>
                37 Appointments (6 Clients)
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Home);
