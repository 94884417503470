/* eslint-disable operator-linebreak */
import Axios from 'axios';


export const baseURL = process.env.REACT_APP_BACKEND_URL
// process.env.REACT_APP_ENV === 'stage'
//   ? 'https://lta-backend-dev.herokuapp.com'
//   : process.env.REACT_APP_ENV === 'development'
//   ? 'http://localhost:3005'
//   : 'https://lighthousearabia-backend.herokuapp.com';

export const get = async (endpoint) => {
  const token =
    // eslint-disable-next-line quotes
    localStorage.getItem('ACCESS_TOKEN_PATH') ||
    localStorage.getItem('ACCESS_TOKEN_PATH');
  const res = await Axios.get(baseURL + endpoint, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token,
    },
  });
  return res.data;
};

export const post = async (endpoint, data) => {
  const token =
    localStorage.getItem('ACCESS_TOKEN_PATH') ||
    localStorage.getItem('ACCESS_TOKEN_PATH');
  const res = Axios.post(baseURL + endpoint, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token,
    },
  });
  return (await res).data;
};

export const postFormData = async (endpoint, data) => {
  const token =
    localStorage.getItem('ACCESS_TOKEN_PATH') ||
    localStorage.getItem('ACCESS_TOKEN_PATH');
  const res = Axios.post(baseURL + endpoint, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: token,
    },
  });
  return (await res).data;
};

export const delet = async (endpoint) => {
  const token =
    localStorage.getItem('ACCESS_TOKEN_PATH') ||
    localStorage.getItem('ACCESS_TOKEN_PATH');
  const res = await Axios.delete(baseURL + endpoint, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token,
    },
  });
  return res.data;
};

export const update = async (endpoint, data) => {
  const token =
    localStorage.getItem('ACCESS_TOKEN_PATH') ||
    localStorage.getItem('ACCESS_TOKEN_PATH');
  const res = Axios.put(baseURL + endpoint, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token,
    },
  });
  return (await res).data;
};

export const updatePatch = async (endpoint, data) => {
  const token =
    localStorage.getItem('ACCESS_TOKEN_PATH') ||
    localStorage.getItem('ACCESS_TOKEN_PATH');
  const res = await Axios.patch(baseURL + endpoint, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token,
    },
  });
  return res.data;
};

export const updatePatchForm = async (endpoint, data) => {
  const token =
    localStorage.getItem('ACCESS_TOKEN_PATH') ||
    localStorage.getItem('ACCESS_TOKEN_PATH');
  const res = Axios.patch(baseURL + endpoint, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: token,
    },
  });
  return (await res).data;
};

export const getPDF = async (endpoint) => {
  const token = localStorage.getItem('ACCESS_TOKEN_PATH');
  const res = await Axios.get(baseURL + endpoint, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: token,
    },
    responseType: 'blob',
  });
  return res.data;
};
