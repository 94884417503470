/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from "react";
import { Grid } from "@material-ui/core";
import styles from "./Header_style";

export const Header = ({ label }) => {
  const classes = styles();
  return (
    <>
      <Grid
        container
        alignItems="center"
        justify="center"
        className={classes.root}
      >
        {label}
      </Grid>
    </>
  );
};
