/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { baseURL } from '../utils/axios';
import AlertMsg from '../utils/Alert';
import Axios from 'axios';
import styles from './CreateNewAppointment.styles';

export default function DoctorSearchView(props) {
  const classes = styles();
  const [searchText, setSearchText] = useState('');
  const [diagnosis, setDiagnosis] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  const [initial, setInitial] = useState(true);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    getDiagnosis();
  }, [searchText]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(selectedDoctor.map((data) => data._id));
    }
  }, [selectedDoctor]);

  useEffect(() => {
    if (props.selectAll) {
      handleSelectAll();
    }
  }, [props.selectAll]);

  const handleSelectAll = async () => {
    await setSearchText('');
    await getDiagnosis();
    if (props.selectAll != null) {
      var temp = [];
      if (!props.selectAll) {
        temp = [];
      } else {
        temp = diagnosis;
      }

      setSelectedDoctor(temp);
      if (props.onChange) {
        props.onChange(temp.map((data) => data._id));
      }
    }
  };

  const getDiagnosis = () => {
    Axios.get(`${baseURL}/api/user/clinicians?term=${searchText}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          if (props.isClinician && props.clinicianId) {
            const loggedInClinician = res.data.filter(
              (c) => c._id === props.clinicianId
            );

            setDiagnosis(loggedInClinician);
            setSelectedDoctor(loggedInClinician);
          } else {
            const sortedList = res.data.sort(function (a, b) {
              if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
                return -1;
              }
              if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
                return 1;
              }
              return 0;
            });

            setDiagnosis(sortedList);

            // if (initial) {
            //   setSelectedDoctor(sortedList);
            //   setInitial(false);
            // }
          }
        }
      })
      .catch((e) => {
        AlertMsg('error', e.toString());
      });
  };

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <div>
      <Autocomplete
        multiple
        style={{
          display: 'block',
          width: '100%',
          background: '#FFFF',
          borderStyle: 'groove',
        }}
        id='tags-standard'
        options={diagnosis}
        onChange={(event, newValue) => {
          setSelectedDoctor(newValue);
          if (props.onChange) {
            props.onChange(newValue.map((data) => data._id));
          }
        }}
        getOptionLabel={(option) =>
          (option.prefix ? option.prefix + ' ' : '') +
          option.firstName +
          ' ' +
          option.lastName +
          (option.suffix ? ', ' + option.suffix : '')
        }
        getOptionSelected={(option, value) => option._id === value._id}
        value={selectedDoctor}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={handleChange}
            placeholder='Select Doctors'
            variant='standard'
          />
        )}
        disabled={props.isClinician}
      />
    </div>
  );
}
