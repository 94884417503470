import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { DialogContent } from '@material-ui/core';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { baseURL } from '../../../../utils/axios';
import axios from 'axios';
import AlertMsg from '../../../../utils/Alert';
import { userLogin } from '../../../../utils/actions/auth.action';
import { Spinner } from '../../../../components/atoms';
import { connect } from 'react-redux';
function ResetPassword(props) {
  // const classes = useStyles();
  const { open, onClose, email, otp, classes, setCongratsOpen } = props;

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleResetPassword = () => {
    if (password.trim() === '') {
      AlertMsg('error', 'Password can not be empty');
    } else if (confirmPassword.trim() === '') {
      AlertMsg('error', 'Confirm Password can not be empty');
    } else if (password !== confirmPassword) {
      AlertMsg('error', 'Passwords does not match');
      return;
    } else if (password.length < 6) {
      AlertMsg('error', 'Password must be at least 6 characters. ');
      return;
    }

    let data = {
      email,
      otp,
      password,
    };
    setLoading(true);
    axios
      .post(`${baseURL}/api/client/resetPassword`, data, {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      })
      .then((res) => {
        setLoading(false);
        AlertMsg('success', 'Password Updated');
        onClose({ updated: true });
      })
      .catch((error) => {
        setLoading(false);
        AlertMsg('success', 'Something went wrong');
      });
  };

  return (
    <div>
      <Spinner isOpen={loading} />
      <Dialog
        aria-labelledby='simple-dialog-title'
        open={open}
        onClose={onClose}
      >
        <DialogContent>
          <Grid style={{ width: '300px' }}>
            <Typography className={classes.txt}>Enter new password</Typography>
            <Grid className={classes.inputTxt}>
              <TextField
                type='password'
                variant='outlined'
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid className={classes.inputTxt}>
              <TextField
                type='password'
                variant='outlined'
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Grid>
            <Grid
              container
              alignItems='center'
              justify='center'
              onClick={handleResetPassword}
              className={classes.buttonOtp}
            >
              <Typography className={classes.buttonText}>
                Reset Password
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ResetPassword;
//
