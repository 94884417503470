export default {
  "Welcome": `<p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">Dear {{client_name}},</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">Thank you for recently creating your account with The LightHouse Arabia.</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">Please follow the instructions applicable to you, in order to book a session.&nbsp;</span></p>
  <p><br></p>
  <p class="ql-align-justify"><em style="background-color: transparent; color: rgb(0, 0, 0);">If you are an adult (above 18 years of age), seeking support for yourself:</em></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">Kindly proceed with completing </span><strong style="background-color: transparent; color: rgb(0, 0, 0);"><em>your </em></strong><span style="background-color: transparent; color: rgb(0, 0, 0);">registration here {Include User Interface Link}.&nbsp;</span></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">Following registration, continue onto </span><strong style="background-color: transparent; color: rgb(0, 0, 0);"><em>your </em></strong><span style="background-color: transparent; color: rgb(0, 0, 0);">first session screening.</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 255);">{Include verbiage on what screening is.}</span></p>
  <p><br></p>
  <p class="ql-align-justify"><em style="background-color: transparent; color: rgb(0, 0, 0);">If you are a parent/guardian of a minor, seeking support for your minor (under 18 years of age):</em></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">Kindly proceed with completing </span><strong style="background-color: transparent; color: rgb(0, 0, 0);"><em>your </em></strong><span style="background-color: transparent; color: rgb(0, 0, 0);">registration here {Include User Interface Link}.&nbsp;</span></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">Following registration, please click “Add Your Child” and fill accordingly. Continue onto </span><strong style="background-color: transparent; color: rgb(0, 0, 0);"><em>their </em></strong><span style="background-color: transparent; color: rgb(0, 0, 0);">first session screening.</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 255);">{Include verbiage on what screening is.}</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">If you have any questions and/or concerns, please contact us at </span><a href="mailto:reception@lighthousearabia.com" rel="noopener noreferrer" target="_blank" style="background-color: transparent; color: rgb(17, 85, 204);">reception@lighthousearabia.com</a><span style="background-color: transparent; color: rgb(0, 0, 0);"> or on T. (0)4 380 2088.</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">Kind regards,</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">The LightHouse Arabia Team</span></p>
  <p><br></p>`
}