import React, { Component } from "react";
import { Grid, withStyles, Typography, Button } from "@material-ui/core";
import DrawerMenu from "../common/Header/DrawerMenu";
import { GridContainer, GridItem } from "../common/BodyContainer";
import { PrescriptionTable } from "../components/Prescriptions/PrescriptionTable";
import Drawer from "@material-ui/core/Drawer";
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import userIcon from "../../assets/imgs/download.png";
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Loader from "../../user/Loader";
import {getPrescriptionsAction,getByIdPrescriptionsAction} from '../../utils/actions/prescription.action';
import '../patients.scss';
import moment from "moment";
import styles from "./prescription_style";

class Prescriptions extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isOpen: false,
        prescriptionList:[],
        isPrescriptionsLoaded: true,
        isDataLoaded:false,
        prescriptionData:{},
      };
    }
  
    componentDidMount() {
      this.props.getPrescriptionsAction(localStorage.getItem('clientId'));
      // this.props.getPrescriptionsAction('5f7086f245a452001cf8944e');
    }
    viewDetail(id){
      this.setState({ isOpen: true, appointmentId: id,isDataLoaded: true });
      this.props.getByIdPrescriptionsAction(id);
    }

    getDate (date){
      var today = new Date(date);
      var d = (today.getDate() < 10) ? "0"+today.getDate():today.getDate();
      var m = (today.getMonth() < 10) ? "0"+today.getMonth():today.getMonth();
      var date = d+"/"+m+"/"+today.getFullYear();
      return date;
    }


    componentDidUpdate(preProps) {
      if (this.props.getPrescriptionsData !== preProps.getPrescriptionsData) {
        const data = this.props.getPrescriptionsData;
        if (data) {
          const list = data.map((node) => ({
            prescribed: node.medication ? node.medication : 'NA',
            dateWritter: node.createdAt ? moment(new Date(node.updatedAt)).format('LL') : 'NA',
            id: node._id ? node._id : 'NA' ,
            clinician: node.clinicianId && node.clinicianId.firstName + " " + node.clinicianId.lastName,
            dosage:`${node.dose} daily`,
            insurance: node.insurance ? node.insurance : "NA"
          }));
          this.setState({
            prescriptionList: list,
            isPrescriptionsLoaded: false,
          });
        }
      }

      if (this.props.getPrescriptionsByIdData !== preProps.getPrescriptionsByIdData) {
        const data = this.props.getPrescriptionsByIdData;
        if (data) {
          this.setState({
            prescriptionData: data,
            isDataLoaded: false,
          });
        }
      }
    }
  
    render() {
      const { classes } = this.props;
      return (
        <div>
            <DrawerMenu {...this.props} />
            <GridContainer />
            <GridItem>
              <Grid style={{ marginBottom: '44px' }}>
              {this.state.isPrescriptionsLoaded ? (
                <Grid container alignItems="center" justify="center">
                  <Loader />
                </Grid>
              ) : (
              <PrescriptionTable
                data={this.state.prescriptionList}
                isLoader={this.state.isUnpaidLoaded}
                onRowSelect={(id) => this.viewDetail(id)}
                style={{ opacity: this.state.isOpen ? '40%' : '' }}
                {...this.props}
              />
              )}
              </Grid>
            </GridItem>
            <Drawer
              anchor="right"
              open={this.state.isOpen}
              className={classes.Backdrop, 'sideDrawerPrescription'}
              onClose={() => this.setState({ isOpen: false })}
              {...this.props}
            >
              <div className={classes.drawerHeader} style={{textAlign:'right'}}>
                <IconButton aria-label="close" onClick={evt => this.setState({isOpen: false})}>
                  <CancelIcon className={classes.closeIcon}/>
                </IconButton>
              </div>
              <div style={{width:'750px'}}>
              {this.state.isDataLoaded ? (
                <Grid container alignItems="center" justify="center">
                  <Loader />
                </Grid>
              ) : (<>
              <Grid className={classes.userDetail} container>
                <Grid lg={6} className={'userInfo'}>
                  <Grid className={'imageOuter'}>
                    <img src={userIcon} />
                  </Grid>
                  <Grid className={'detail'}>
                    <Typography variant="h4" component="h4">
                      Dr. {this.state.prescriptionData.clinicianId && this.state.prescriptionData.clinicianId.firstName ? this.state.prescriptionData.clinicianId.firstName : ' '} {this.state.prescriptionData.clinicianId && this.state.prescriptionData.clinicianId.lastName ? this.state.prescriptionData.clinicianId.lastName : ' '}
                    </Typography>
                    <ul>
                      <li>{this.state.prescriptionData.clinicianId && this.state.prescriptionData.createdAt ? this.getDate(this.state.prescriptionData.createdAt) : 'NA'}</li>
                      {console.log(this.state.prescriptionData)}
                      <li>10:30 AM, for 30mins</li>
                      <li>Villa 821, AI Wasi Rd, AI Wasi Rd, AI</li>
                    </ul>
                  </Grid>
                </Grid>
                <Grid lg={6} className="btns">
                  <Button
                    variant="contained" color="primary"
                  >
                    View Appointment
                  </Button>
                  <Button
                    variant="contained" color="primary"
                  >
                    Request Refill
                  </Button>
                </Grid>
              </Grid>
              <Grid className={'medicationDetail'}>
                <Typography component="h3">
                {this.state.prescriptionData.medication ? this.state.prescriptionData.medication:'NA'}
                </Typography>
                <Typography component="h5">
                  Medication Details
                </Typography>
                <table>
                  <tr>
                    <td>
                      Prescription Rx:
                    </td>
                    <td>
                    {this.state.prescriptionData.medication ? this.state.prescriptionData.medication:'NA'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Refills Allowed
                    </td>
                    <td>
                    {this.state.prescriptionData && this.state.prescriptionData.refillAllowed ? this.state.prescriptionData.refillAllowed : 0} refill
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Dose Unit
                    </td>
                    <td>
                    {this.state.prescriptionData && this.state.prescriptionData.dose ? this.state.prescriptionData.dose : 'NA'}
                    </td>
                  </tr>
                </table>
              </Grid>
              <Grid className={'directionText'}>
                <Typography component="h5">
                  Directions
                </Typography>
                <Typography component="p">
                {this.state.prescriptionData && this.state.prescriptionData.direction ? this.state.prescriptionData.direction : 'NA'}
                </Typography>
                <Typography component="h5">
                Notes from Prescriber
                </Typography>
                <Typography component="p">
                {this.state.prescriptionData && this.state.prescriptionData.notes ? this.state.prescriptionData.notes : ' '}
                </Typography>
              </Grid>
              <Grid className={'clinicDetail'}>
                <Grid className={'iconLabel'}></Grid>
                <Typography component="h5">
                  Dubai Life Pharmacy  
                </Typography>
                <ul>
                  <li>Sheikh Zayed Rd - Trade CentreTrade Centre 2, Dubai, UAE</li>
                  <li>+971 4 329 1162</li>
                </ul>
              </Grid></>
              )}
              </div>
            </Drawer>
        </div>
      );
    }
}

  const connected = connect(
    (state) => ({
      getPrescriptionsData: state.getAllPrescriptions.getAllPrescriptionsData,
      getPrescriptionsByIdData: state.getAllPrescriptionsById.getAllPrescriptionsByIdData,
    }),
    {
      getPrescriptionsAction,
      getByIdPrescriptionsAction
    },
  )(Prescriptions);
  
  const formed = reduxForm({
    form: 'appointment',
  })(connected);
  
export default withStyles(styles)(formed);