import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
    toolbar: {
      width: '100%',
      height: '100px',
      backgroundColor: '#003265',
    },
    prescriptionCard:{
      backgroundColor:'#F9F8F5',
      boxShadow:'none'
    },
    customBtn: {
      backgroundColor: '#8c9cf7',
      color: '#fff',
      borderRadius: '25px',
      width: '75%',
      padding:'10px',
      textTransform: 'capitalize',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
    customBtn2: {
      backgroundColor: '#003265',
      color: '#fff',
      borderRadius: '25px',
      width: '25%',
      padding:'10px',
      textTransform: 'capitalize',
      marginTop: '24px',
    },
    root: {
      maxWidth: 255,
    },
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
    inputstyle: {
      backgroundColor: '#fff',
    },
    table: {
      minWidth: 650,
    },
    imgTable: {
      width: '40px',
      height: '40px',
      objectFit: 'contain',
      borderRadius: '50px',
    },
    viewBtn: {
      borderRadius: '15px',
      width: '100px',
      padding: '4px',
      boxShadow: 'none',
      textTransform: 'capitalize',
      // '&:hover': {
      //   backgroundColor: '#003265',
      // },
    },
    btnViewPatientRecord: {
      backgroundColor: '#9c8455',
      color: '#fff',
      padding: '10px',
      marginTop: '15px',
      marginBottom: 15,
      fontWeight: 400,
      margin: '0 auto',
      textTransform: 'capitalize',
      width: '90%',
      '&:hover': {
        backgroundColor: '#bb9956',
      },
    },
    sliderContainer: {
      height: '15px',
      backgroundImage:
            'linear-gradient(90deg, #26a4ba 0%, #85c169 25%, #cbcb2f 50%, #d0aa21 75%, #da7910 100%)',
    },
    sliderPointContainer2: {
      position: 'absolute',
      marginTop: '-18px',
      marginLeft: '260px',
    },
    sliderPointText: {
      fontSize: '12px',
      color: '#ffffff',
      fontFamily: 'Lato',
      fontWeight: 700,
      textAlign: 'center',
      marginTop: '-29px',
      marginLeft: '-6px',
  
    },
    sliderPointLine: {
      height: '16px',
      marginLeft: '1px',
    },
    sliderPoint: {
      width: '18px',
      marginLeft: '-7px',
    },
    customSelect: {
      width: '100%',
      textAlign: 'left',
      border: '1px solid #c4c4c4',
      padding: '10px',
      borderRadius: '3px',
      backgroundColor: '#fff',
    },
    btnNewApp: {
      backgroundColor: '#003265',
      color: '#fff',
      marginLeft: '50px',
      '&:hover': {
        backgroundColor: '#003265',
      },
    },
  });