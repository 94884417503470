import { makeStyles} from "@material-ui/core";
export default makeStyles((theme) => ({
    root: {
      height: "80px",
      backgroundColor: "rgba(219,25,25,18%)",
      width: "100%",
      display: "flex",
      marginBottom: "20px",
    },
    leftRoot: {
      width: "9px",
      height: "100%",
      backgroundColor: "#db1919",
    },
    body: {
      paddingLeft: "29px",
      paddingRight: "22px",
      display: "flex",
    },
    titleContainer: {
      width: "100px",
      marginRight: "51px",
    },
    title: {
      fontSize: "18px",
      color: "#304659",
      fontWeight: 700,
      fontFamily: "Lato",
      textAlign: "left",
      textTransform: "capitalize",
    },
    descContainer: {
      width: "700px",
    },
    desc: {
      fontSize: "18px",
      color: "#304659",
      fontWeight: 400,
      fontFamily: "Lato",
      textAlign: "left",
    },
    actionContainer: {
      position: "absolute",
      right: "72px",
      cursor: "pointer",
      "&:hover": {
        opacity: "70%",
      },
    },
    line1: {
      position: "absolute",
    },
  }));
