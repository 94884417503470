import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { PatientList } from '../../../components/molecules';

const Patients = ({ match }) => {
  const [corporationId, setCorporationId] = useState(null);

  useEffect(() => {
    setCorporationId(match.params.corporationId);
  }, []);

  return (
    <Grid>
      <PatientList corporationId={corporationId} isCorporate />
    </Grid>
  );
};

export default Patients;
