/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';

export default function AlertMsg(type, message) {
  ReactDOM.render(
    <p className={type} style={{ zIndex: 9999999 }}>
      <i
        className={
          type === 'error' ? 'fas fa-exclamation' : 'far fa-check-circle'
        }
      />{' '}
      {message}
    </p>,
    document.getElementById('alertid')
  );
  setTimeout(() => {
    ReactDOM.render('', document.getElementById('alertid'));
  }, 3000);
}
