/* eslint-disable react/jsx-indent */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/state-in-constructor */
/* eslint-disable no-dupe-keys */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from 'react';
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
// import en from 'date-fns/locale/en';
import Sidebar from '../../../components/Sidebar';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './BillingDocumentStyle';

// registerLocale('en', en);

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

class BillingDocument extends PureComponent {
  state = {
    checked: true,
    selectedOption: null,
    startDate: new Date(),
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleDateChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  render() {
    const { classes } = this.props;
    const { selectedOption } = this.state;

    return (
      <>
        <Sidebar />
        <div className={classes.root}>
          <div className={classes.flexStyle}>
            <Typography className={classes.title}>
              Export Billing DocumenIts
            </Typography>
            <Divider className={classes.divider} />
          </div>

          <Grid item xs={10} className={classes.divstyle}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={2}>
                <Typography className={classes.showSty}>Show me:</Typography>
              </Grid>
              <Grid item xs={6} sm={2}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={this.state.checked}
                      // onChange={handleChange}
                      name="checked"
                      color="default"
                      size="small"
                    />
                  )}
                  label="Statements"
                  className={classes.checkboxsty}
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <FormControlLabel
                  className={classes.checkboxsty}
                  control={(
                    <Checkbox
                      checked={this.state.checked}
                      // onChange={handleChange}
                      name="checked"
                      color="default"
                      size="small"
                    />
                  )}
                  label="Superbills"
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <FormControlLabel
                  className={classes.checkboxsty}
                  control={(
                    <Checkbox
                      checked={this.state.checked}
                      // onChange={handleChange}
                      name="checked"
                      color="default"
                      size="small"
                    />
                  )}
                  label="Invoices"
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <FormControlLabel
                  className={classes.checkboxsty}
                  control={(
                    <Checkbox
                      checked={this.state.checked}
                      // onChange={handleChange}
                      name="checked"
                      color="default"
                      size="small"
                    />
                  )}
                  label="Manual preference Only"
                />
              </Grid>
            </Grid>

            <div className={classes.gridsty}>
              <Typography className={classes.showSty}>
                Dates Between:
              </Typography>

              <DatePicker
                locale='en'
                selected={this.state.startDate}
                onChange={this.handleDateChange}
                className={classes.date}
              />

              <Select
                value={selectedOption}
                onChange={this.handleChange}
                options={options}
                className={classes.selectsty}
              />

              <Button variant="outlined" className={classes.margin}>
                Apply Filters
              </Button>
            </div>
          </Grid>
          <div style={{ marginTop: '20px', width: '83%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3} className={classes.gridsty}>
                <Checkbox defaultChecked color="default" size="small" />
                <Typography className={classes.tableHeading}>Name</Typography>
              </Grid>
              <Grid item xs={12} lg={1}>
                <Typography className={classes.tableHeading}>Type</Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Typography className={classes.tableHeading}>
                  Delivery Method
                </Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Typography className={classes.tableHeading}>Status</Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Typography className={classes.tableHeading}>Date</Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Typography className={classes.tableHeading}>Time</Typography>
              </Grid>
            </Grid>

            <Divider />

            <Grid container spacing={2}>
              <Grid item xs={12} lg={3} className={classes.gridsty}>
                <Checkbox defaultChecked color="default" size="small" />
                <Typography className={classes.tableName}>
                  Chandresh Patidar
                </Typography>
              </Grid>
              <Grid item xs={12} lg={1}>
                <Typography className={classes.tablesubtext}>
                  Invoice
                </Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Typography className={classes.tablesubtext}>None</Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Typography className={classes.statusSty}>EXPORTED</Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Typography className={classes.tablesubtext}>
                  04/24/2020
                </Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Typography className={classes.tablesubtext}>
                  12:25am
                </Typography>
              </Grid>
            </Grid>

            <Divider />

            <Grid container spacing={2}>
              <Grid item xs={12} lg={3} className={classes.gridsty}>
                <Checkbox defaultChecked color="default" size="small" />
                <Typography className={classes.tableName}>
                  Chandresh Patidar
                </Typography>
              </Grid>
              <Grid item xs={12} lg={1}>
                <Typography className={classes.tablesubtext}>
                  Invoice
                </Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Typography className={classes.tablesubtext}>None</Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Typography className={classes.statusSty}>EXPORTED</Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Typography className={classes.tablesubtext}>
                  04/24/2020
                </Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Typography className={classes.tablesubtext}>
                  12:25am
                </Typography>
              </Grid>
            </Grid>

            <Divider />

            <Grid container spacing={2}>
              <Grid item xs={12} lg={3} className={classes.gridsty}>
                <Checkbox defaultChecked color="default" size="small" />

                <Typography className={classes.tableName}>
                  Chandresh Patidar
                </Typography>
              </Grid>
              <Grid item xs={12} lg={1}>
                <Typography className={classes.tablesubtext}>
                  Invoice
                </Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Typography className={classes.tablesubtext}>None</Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Typography className={classes.statusSty}>EXPORTED</Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Typography className={classes.tablesubtext}>
                  04/24/2020
                </Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Typography className={classes.tablesubtext}>
                  12:25am
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(BillingDocument);
