import React from 'react';
import CheckoutInvoiceTotals from './CheckoutInvoiceTotals';

const CheckoutInvoiceDetails = ({ invoice, services, total }) => {
  const fetchService = (serviceId) => services.find((s) => s._id === serviceId);
  const subtotal = (record) => {
    const amount = Number(record.amount);
    const vatPercentage =
      invoice?.servicesData.find((service) => service._id === record.serviceId)?.vat || 0;

    const discountedAmount = record.discount
      ? Number((amount - (record.discount / 100) * amount).toFixed(2))
      : amount;

    const vatAmount = vatPercentage
      ? Number(((discountedAmount / 100) * vatPercentage).toFixed(2))
      : 0;


    return Number(discountedAmount + vatAmount).toFixed(2);
  };

  return (
    <div className='block mx-auto max-w-3xl mb-8'>
      <table
        id='rate-card-table'
        className='w-full border-separate mt-8 bg-white'
        cellPadding={0}
        cellSpacing={0}
      >
        <thead>
          <tr className='bg-brand-blue text-white text-sm text-left'>
            <th className='p-4'>Service Type</th>
            <th className='p-4'>Duration</th>
            <th className='p-4'>Cost</th>
            <th className='p-4'>Discount %</th>
            <th className='p-4'>VAT %</th>
            <th className='p-4'>
              Subtotal <span className='text-xs'>(AED)</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {invoice?.services &&
            invoice?.services.map((ef, i) => (
              <tr key={`exFee-${i}`} className='text-sm'>
                <td className='p-4 border-b border-gray-200'>
                  {fetchService(ef.serviceId)?.type || ''}
                </td>
                <td className='p-4 border-b border-gray-200'>
                  {ef.duration} mins
                </td>
                <td className='p-4 border-b border-gray-200'>{Number(ef.amount).toFixed(2)}</td>
                <td className='p-4 border-b border-gray-200'>{ef.discount ?? "N/A"}</td>
                <td className='p-4 border-b border-gray-200'>
                  {fetchService(ef.serviceId)?.vat || ''}
                </td>
                <td className='p-4 border-b border-gray-200'>{subtotal(ef)}</td>
              </tr>
            ))}
          {invoice?.extraFees &&
            invoice?.extraFees.map((ef, i) => (
              <tr key={`exFee-${i}`} className='text-sm'>
                <td className='p-4 border-b border-gray-200'>{ef.type}</td>
                <td className='p-4 border-b border-gray-200'>N/A</td>
                <td className='p-4 border-b border-gray-200'>{Number(ef.amount).toFixed(2)}</td>
                <td className='p-4 border-b border-gray-200'>N/A</td>
                <td className='p-4 border-b border-gray-200'>N/A</td>
                <td className='p-4 border-b border-gray-200'>{Number(ef.amount).toFixed(2)}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default CheckoutInvoiceDetails;
