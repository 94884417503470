import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import dayGridPlugin from '@fullcalendar/daygrid';
import list from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import Layout from '../Layout';
import { Avatar } from '@material-ui/core';
import { baseURL } from '../../utils/axios';
import { getTimezoneOffset } from '../../utils/timUtils';
import Axios from 'axios';
import CalenderCreateApointment from '../../common/CalenderCreateNewAppointments';
import AppointmentDetailsView from '../AppointmentDetailsView';
import { Spinner } from '../../components/atoms';
import moment from 'moment';
import styled from 'styled-components';
import { getEventColor } from '../../common/calendar/slots';
import Popover from '../../admin/components/Calender/Popover';
import tippy, { followCursor } from 'tippy.js';
import ReactDOMServer from 'react-dom/server';
import 'tippy.js/themes/light.css';

const CalendarWrapper = styled.div`
  .fc-timegrid-event-harness-inset {
    inset-inline-start: 0% !important;
    inset-inline-end: 0% !important;
  }

  .fc-timegrid-event-harness {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
`;
function DoctorHeader(resource) {
  return (
    <>
      <Avatar
        style={{ margin: '8px auto', height: '32px', width: '32px' }}
        alt={resource.title}
        src={resource.resource._resource.extendedProps.imageUrl}
      />
      <div style={{ marginTop: '8px', textAlign: 'center' }}>
        {resource.resource._resource.title}
      </div>
    </>
  );
}

function App() {
  const calendarComponentRef = React.createRef();
  const [loader, setLoader] = useState(false);
  const [resources, setResources] = useState([]);
  const [events, setEvents] = useState([]);
  const [appointmentId, setAppointmentId] = useState('');
  const [openAppointmentDetails, setOpenAppointmentDetails] = useState(false);
  const [openCreateAppointment, setOpenCreateAppointment] = useState(false);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [selectedTime, setSelectedTime] = useState({});
  const [selectedDoctor, setDelectedDoctor] = useState();
  const [appointmentType, setAppointmentType] = useState('');
  const [patientType, setPatientType] = useState('');

  useEffect(() => {
    getShedules();
  }, []);

  const getShedules = () => {
    setLoader(true);
    var uid = localStorage.getItem('clinicianUID');

    const dated = moment().format('L');

    Axios.get(
      `${baseURL}/api/schedule/get?id=${[
        uid,
      ]}&timezoneOffset=${getTimezoneOffset()}&start=${dated}&end=${dated}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        if (res.data && res.data.length) {
          const resourcesList = [];
          let eventsList = [];
          res.data.forEach((item, itemIndex) => {
            const resource = {
              id: itemIndex,
              title: item.firstName + ' ' + item.lastName,
              imageUrl: item.avatar,
            };
            item.events.forEach((event, index) => {
              let evtData = {
                resourceId: itemIndex,
                title: event.title,
                start: event.start,
                end: event.end,
                index: `evt-${index}`,
                clinicianId: item._id,
                id: event._id,
                color: getEventColor(event.type),
                type: event.type,
                textColor:
                  event.type === 'Appointment' || event.type === 'Blocked'
                    ? '#fff'
                    : '#000',
                client: event.client,
              };
              eventsList.push(evtData);
            });
            resourcesList.push(resource);
          });
          setResources(resourcesList);
          setEvents(eventsList);
        }
        setLoader(false);
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const getCalendarData = async (
    fetchInfo,
    successCallback,
    failureCallback
  ) => {
    try {
      let start = moment(fetchInfo.start).format('MM/DD/YYYY');
      let end = moment(fetchInfo.end).format('MM/DD/YYYY');
      const LoggedInUser = localStorage.getItem('clinicianUID');

      var url = `${baseURL}/api/schedule/get?id=${[
        LoggedInUser,
      ]}&timezoneOffset=${getTimezoneOffset()}`;

      if (start) {
        url = url + `&start=${start}`;
      }

      if (end) {
        url = url + `&end=${end}`;
      }

      let res = await Axios.get(url, {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      });

      if (!res.data) {
        failureCallback([]);
        return;
      }

      const sortedList = res.data.sort(function (a, b) {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
          return -1;
        }
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const resourcesList = [];
      let eventsList = [];
      sortedList.forEach((item, itemIndex) => {
        const resource = {
          id: itemIndex,
          title: item.firstName + ' ' + item.lastName,
          imageUrl: item.avatar,
          clinicianId: item._id,
        };
        item.events.forEach((event, index) => {
          let evtData = {
            resourceId: itemIndex,
            title: event.title,
            start: event.start,
            end: event.end,
            index: `evt-${index}`,
            clinicianId: item._id,
            id: event._id,
            color: getEventColor(event.type),
            type: event.type,
            client: event.client,
            textColor:
              event.type === 'Appointment' || event.type === 'Blocked'
                ? '#fff'
                : '#000',
          };
          eventsList.push(evtData);
        });
        resourcesList.push(resource);
      });

      successCallback(eventsList);
    } catch (error) {
      console.log(error);
      failureCallback([]);
    }
  };

  const eventClicked = (eventClickInfo, event) => {
    setAppointmentType({
      calenderSlotId: eventClickInfo.event.id,
      type: eventClickInfo.event._def.extendedProps.type,
      clinicianId: eventClickInfo.event._def.extendedProps.clinicianId,
    });
    setDelectedDoctor(eventClickInfo.event._def.extendedProps.clinicianId);
    setSelectedTime({
      start: eventClickInfo.event.start,
      end: eventClickInfo.event.end,
    });

    if (
      eventClickInfo.event.title == 'Current-Patient' ||
      eventClickInfo.event.title == 'New-Patient' ||
      eventClickInfo.event.title == 'All-Patient'
    ) {
      setPatientType(eventClickInfo.event.title);
      setOpenCreateAppointment(true);
    }

    if (eventClickInfo.event._def.extendedProps.type == 'Appointment') {
      setOpenAppointmentDetails(true);
      setAppointmentId(eventClickInfo.event.id);
    }
  };

  const handleCreateClose = () => {
    setOpenCreateAppointment(false);
  };

  var instance = null;
  const handleMouseEnter = (arg) => {
    if (arg.event._def.extendedProps.type == 'Appointment') {
      if (instance) {
        instance.destroy();
      }

      instance = tippy(arg.el, {
        content: ReactDOMServer.renderToStaticMarkup(
          <Popover
            {...arg.event._def.extendedProps}
            start={arg.event.start}
            end={arg.event.end}
          />
        ),
        strategy: 'fixed',
        allowHTML: true,
        placement: 'left',
        interactive: true,
        theme: 'light',
        zIndex: 999999999,
        followCursor: true,
        plugins: [followCursor],
      });
    }
  };

  return (
    <Layout>
      {loader && (
        <div className='calendarLoader'>
          <Spinner isOpen={true} />
        </div>
      )}
      <CalendarWrapper>
        <FullCalendar
          schedulerLicenseKey={process.env.REACT_APP_FULL_CALENDAR_KEY}
          defaultView='timeGridWeek'
          initialView='timeGridWeek'
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          dateIncrement={(date) => console.log(date)}
          rerenderDelay={0}
          eventDurationEditable
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            list,
            interactionPlugin,
            timelinePlugin,
            resourceTimeGridPlugin,
          ]}
          resources={resources}
          resourceLabelText={'Doctors'}
          events={getCalendarData}
          resourceLabelContent={DoctorHeader}
          ref={calendarComponentRef}
          slotMinTime='06:00:00'
          slotMaxTime='22:00:00'
          disableResizing={true}
          draggable={false}
          disableDragging={true}
          contentHeight='auto'
          selectable
          eventOverlap={false}
          eventClick={eventClicked}
          allDaySlot={false}
          eventMouseEnter={handleMouseEnter}
          nowIndicator
        />
      </CalendarWrapper>
      {openAppointmentDetails && (
        <AppointmentDetailsView
          appointmentId={appointmentId}
          handleClose={() => setOpenAppointmentDetails(false)}
          open={openAppointmentDetails}
          handleInitReferralClick={() => {}}
          handleEditRecord={() => {}}
          refresh={() => {
            getShedules();
          }}
        />
      )}
      {openCreateAppointment && (
        <CalenderCreateApointment
          selectedTime={selectedTime}
          key={new Date().getTime()}
          open={openCreateAppointment}
          appointmentDetail={appointmentType}
          handleClose={handleCreateClose}
          patientType={patientType}
          handleSubmit={() => {
            handleCreateClose();
            getShedules();
          }}
        />
      )}
    </Layout>
  );
}

export default App;
