import { createStyles} from "@material-ui/core";

export default (theme) =>
createStyles({
  imageContainer: {
    height: "100%",
    width: "calc(100% - 35%)",
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  bkgimg: {
    opacity: "100%",
    height: "auto",
    width: "100%",
    position: "relative",
    overflow: "hidden",
  },
  bg_container: {
    position: "absolute",
    // margin: 0,
    // padding: 0,
    top: "71px",
    left: "100px",
    [theme.breakpoints.down("md")]: {
      left: "40px",
    },
  },
  bg_title: {
    fontSize: "70px",
    width: "653px",
    height: "234px",
    fontWeight: 800,
    filter: "drop-shadow(2.828px 2.828px 0.5px rgba(0,0,0,0.16))",
    color: "#ffffff",
    textAlign: "left",
    display: "flex",
    justifyContent: "left",
    lineHeight: "75px",
    fontFamily: "Lato",
    marginTop: "36px",
    [theme.breakpoints.down("md")]: {
      fontSize: "60px",
    },
  },
  bg_divider: {
    backgroundColor: "#ffffff",
    textAlign: "left",
    marginTop: "43px",
    display: "flex",
  },
  bg_subtitle: {
    width: "360px",
    height: "75px",
    color: "#ffffff",
    fontSize: "17px",
    fontWeight: "400",
    fontFamily: "Lato",
    textAlign: "left",
    lineHeight: "25px",
    marginTop: "13px",
  },
  lightlogo: {
    width: "30px",
    height: "40px",
    // width: "28px",
    // height: "37px",
    color: "#ffffff",
    display: "flex",
    textAlign: "start",
  },
  tagline: {
    width: "360px",
    height: "20px",
    fontSize: "13px",
    letterSpacing: "1px",
    lineHeight: "20px",
    textAlign: "left",
    fontWeight: "800",
    fontFamily: "Lato",
    color: "#ffffff",
    marginTop: "157px",
  },
});
