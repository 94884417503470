import {makeStyles} from "@material-ui/core";
export default makeStyles((theme) => ({
    root: {
      width: "38px",
      height: "38px",
      borderRadius: "19px",
      border: "2px solid #ffffff",
    },
    dots: {
      width: "16px",
      height: "16px",
      borderRadius: "8px",
      backgroundColor: "#db1919",
      // backgroundColor: '#00bc07',
    },
  }));
  