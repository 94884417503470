/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Grid, withStyles, Typography, Button } from '@material-ui/core';
import MainImg from '../../../assets/imgs/eventDetail.png';
import { EventDetailParticipateImageContainer } from './common/EventImageContainers';
import drImage from '../../../assets/imgs/download.png';
import { connect } from 'react-redux';
import Loader from '../../../components/Loader/Loader';
import { getByIdEventsAction } from '../../../utils/actions/events.action';
import Axios from 'axios';
import { baseURL } from '../../../utils/axios';
import AlertMsg from '../../../utils/Alert';
import styles from './EventDetail_style';
import HTMLParser from 'html-react-parser';
import { Dialog, Button as PrimaryBtn } from '../../../components/atoms';
import moment from 'moment';

class EventDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      eventId: '',
      eventsData: '',
      isEventLoaded: true,
      isClinician: false,
      alreadyRSVPed: false,
      openCompletionDialog: false,
    };
  }

  componentDidMount() {
    const eventId = this.props.eventId;
    if (this.props && this.props.clinician) {
      this.setState({ isClinician: true });
    }
    this.props.getByIdEventsAction(eventId);
    this.getRSVPList();
  }

  getRSVPList() {
    if (!this.props.eventId) return;
    const clientId = localStorage.getItem('clientId');

    Axios.get(
      `${baseURL}/api/event/is-rsvped/${this.props.eventId}/${clientId}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        if (res.data.message === 'exists') {
          this.setState({ alreadyRSVPed: true });
        }
      })
      .catch((e) => { });
  }
  getDate(date) {
    var today = new Date(date);
    var d = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var m = today.getMonth() < 10 ? '0' + today.getMonth() : today.getMonth();
    var date = d + '/' + m + '/' + today.getFullYear();
    return date;
  }

  getTime(time) {
    var date = new Date(time);
    var hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    var am_pm = date.getHours() >= 12 ? 'PM' : 'AM';
    hours = hours < 10 ? '0' + hours : hours;
    var minutes =
      date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    var seconds =
      date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    var time = hours + ':' + minutes + '' + am_pm;
    return time;
  }

  componentDidUpdate(preProps) {
    if (this.props.getEventsDataById !== preProps.getEventsDataById) {
      const data = this.props.getEventsDataById;
      if (data) {
        this.setState({
          eventsData: data,
          isEventLoaded: false,
        });
      }
    }
  }

  handleClickRsvpEvent = () => {
    const { isClinician, eventsData, alreadyRSVPed } = this.state;
    const clientId = localStorage.getItem('clientId');
    const phoneNumber = localStorage.getItem('clientPhoneNumber');

    if (!phoneNumber) {
      this.setState({
        openCompletionDialog: true,
      });
      return;
    }
    if (alreadyRSVPed) {
      return;
    }
    if (eventsData.totalSeats - eventsData.numberOfAttendees === 0) {
      AlertMsg('error', 'All seats are already booked for this event!');
      return;
    }

    window
      .open(
        `/eventregistration/${eventsData._id}?clientId=${clientId}`,
        '_blank'
      )
      .focus();

    // if (!isClinician && eventsData) {
    //   let body = { client: uId, event: eventsData._id, status: 'confirmed' };
    //   Axios.post(`${baseURL}/api/rsvp`, body, {
    //     headers: {
    //       Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
    //     },
    //   })
    //     .then((res) => {
    //       let newState = { ...eventsData };
    //       newState.isRSVP = true;
    //       this.setState({ eventsData: newState });
    //
    //       AlertMsg('success', 'Event RSVP successfully.');
    //
    //       this.props.onClose();
    //     })
    //     .catch((err) => {
    //       AlertMsg('error', err.response?.data?.reason);
    //       this.props.onClose();
    //     });
    // } else {
    //   return null;
    // }
  };

  truncate = (input) => {
    if (input.length > 20) {
      return input.substring(0, 20) + '...';
    }
    return input;
  };

  closeCompletionDialog = () => {
    this.setState({
      openCompletionDialog: false,
    });
  };

  render() {
    const { classes } = this.props;
    const { isClinician } = this.state;
    const clinicians =
      this.state.eventsData && this.state.eventsData
        ? this.state.eventsData.clinicians
        : [];

    return (
      <Grid style={this.state.isEventLoaded ? { width: '50%', marginBottom: "64px" } : null}>
        {this.state.isEventLoaded ? (
          <Grid style={{ textAlign: 'center' }}>
            <Loader />
          </Grid>
        ) : (
          <div>
            {console.log(this.state.eventsData?.poster)}
            <Grid>
              <img className='object-cover' style={{
                height: '375px',
                width: "100%",
                objectFit: "cover"
              }} src={this.state.eventsData?.poster || MainImg}></img>
              <Grid >
                <Grid>
                  <p className='ml-10 mt-10 font-bold'>
                    {this.state.eventsData &&
                      this.state.eventsData.location &&
                      this.state.eventsData.location.type
                      ? this.state.eventsData.location.type
                      : 'NA'}
                  </p>
                  <p className='ml-10 mt-2 font-bold text-2xl'>
                    {this.state.eventsData && this.state.eventsData.title
                      ? this.state.eventsData.title
                      : 'NA'}
                  </p>
                </Grid>
              </Grid>
              <Grid
                container
                justify='flex-start'
                style={{ marginTop: '32px' }}
              >
                <Grid
                  item
                  xs={7}
                  style={{
                    width: '446px',
                    marginRight: '50px',
                    paddingLeft: 40,
                  }}
                >
                  <Grid>
                    <Typography className={classes.overviewText}>
                      Overview
                    </Typography>
                    <Typography
                      className={classes.overviewDescText}
                    ></Typography>
                    <div>
                      {this.state.eventsData.description?.trim()
                        ? HTMLParser(this.state.eventsData.description)
                        : 'No description provided'}
                    </div>
                  </Grid>

                  {clinicians.length > 0 ? (
                    <Grid style={{ marginTop: '36px' }}>
                      <Typography className={classes.overviewText}>
                        {' '}
                        Lead Clinician{' '}
                      </Typography>
                      <Grid style={{ margin: 'auto', display: 'flex' }}>
                        <Grid>
                          <img
                            src={
                              clinicians[0].avatar
                                ? clinicians[0].avatar
                                : drImage
                            }
                            className={classes.drImageContainer}
                          />
                        </Grid>
                        <Grid style={{ marginLeft: '15px' }}>
                          <Typography className={classes.drName}>
                            {(clinicians[0].prefix
                              ? clinicians[0].prefix + ' '
                              : '') +
                              clinicians[0].firstName +
                              ' ' +
                              clinicians[0].lastName +
                              (clinicians[0].suffix
                                ? ', ' + clinicians[0].suffix
                                : '')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {clinicians.length > 1 && (
                    <Grid style={{ marginTop: '30px', marginBottom: "30px" }}>
                      <Typography className={classes.participatingText}>
                        Participating Clinicians
                      </Typography>
                      {console.log('clinicians', clinicians)}
                      {clinicians.map((clincian, index) => {
                        if (index !== 0)
                          return (
                            <Grid spacing={3} container>
                              <Grid item>
                                <img
                                  src={clincian.avatar || drImage}
                                  width='40px'
                                  className='rounded-full'
                                  alt='drImage'
                                />
                              </Grid>
                              <Grid item>
                                {(clincian.prefix
                                  ? clincian.prefix + ' '
                                  : '') +
                                  clincian.firstName +
                                  ' ' +
                                  clincian.lastName +
                                  (clincian.suffix
                                    ? ', ' + clincian.suffix
                                    : '')}
                              </Grid>
                            </Grid>
                          );
                      })}
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={4}>
                  <Grid style={{ marginBottom: '35px' }}>
                    {!isClinician ? (
                      <Grid
                        container
                        alignItems='center'
                        justify='center'
                        className={classes.buttonContainer}
                        style={
                          this.state.eventsData.isRSVP
                            ? { backgroundColor: 'gray', cursor: 'not-allowed' }
                            : null
                        }
                        onClick={this.handleClickRsvpEvent}
                      >
                        {this.state.eventsData.totalSeats -
                          this.state.eventsData.numberOfAttendees !==
                          0
                          ? this.state.alreadyRSVPed
                            ? 'Already Registered'
                            : 'RSVP Now!'
                          : 'All seats are already booked for this event!'}
                      </Grid>
                    ) : null}
                  </Grid>

                  <Grid>
                    <Typography className={classes.overviewText}>
                      Details
                    </Typography>
                    <Grid style={{ marginTop: '15px' }}>
                      <Grid style={{ display: 'flex' }}>
                        <Typography className={classes.textTitle}>
                          Date:
                        </Typography>
                        <Typography className={classes.textDesc}>
                          {'  '}
                          {this.state.eventsData && this.state.eventsData.date
                            ? moment(this.state.eventsData.date).format("DD/MM/YYYY")
                            : 'NA'}
                        </Typography>
                      </Grid>
                      <Grid style={{ display: 'flex' }}>
                        <Typography className={classes.textTitle}>
                          Time:
                        </Typography>
                        <Typography className={classes.textDesc}>
                          {'  '}
                          {this.state.eventsData &&
                            this.state.eventsData.startTime
                            ? this.getTime(this.state.eventsData.startTime)
                            : 'NA'}{' '}
                          -{' '}
                          {this.state.eventsData &&
                            this.state.eventsData.endTime
                            ? this.getTime(this.state.eventsData.endTime)
                            : 'NA'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {this.state.eventsData?.displayAddress && (
                    <Grid style={{ marginTop: '30px' }}>
                      <Typography className={classes.overviewText}>
                        Location
                      </Typography>
                      <Grid style={{ marginTop: '15px' }}>
                        <Typography className={classes.textDesc}>
                          {this.state.eventsData &&
                            this.state.eventsData.location &&
                            this.state.eventsData.location.address
                            ? this.truncate(
                              this.state.eventsData.location.address
                            )
                            : 'NA'}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  <Grid style={{ marginTop: '30px' }}>
                    <Typography className={classes.overviewText}>
                      Organizer
                    </Typography>
                    <Grid style={{ marginTop: '15px' }}>
                      <Typography className={classes.textDesc}>
                        <span className='font-bold'>Name: </span>
                        {this.state.eventsData &&
                          this.state.eventsData.corporationId?.name}
                      </Typography>
                      <Typography className={classes.textDesc}>
                        <span className='font-bold'>Email: </span>
                        {this.state.eventsData && (
                          <a
                            href={`mailto:${this.state.eventsData.corporationId?.email || ''
                              }`}
                          >
                            {this.state.eventsData.corporationId?.email}
                          </a>
                        )}
                      </Typography>
                      <Typography className={classes.textDesc}>
                        <span className='font-bold'>Phone Number: </span>
                        {this.state.eventsData && (
                          <a
                            href={`tel:${this.state.eventsData.corporationId
                              ?.phoneNumber || ''
                              }`}
                          >
                            {this.state.eventsData.corporationId?.phoneNumber}
                          </a>
                        )}
                      </Typography>
                      <Typography className={classes.textDesc}>
                        <span className='font-bold'>Registration link: </span>
                        <a
                            href={"https://events.lighthousearabia.com/eventregistration/" + this.state.eventsData._id}
                          >
                        {"https://events.lighthousearabia.com/eventregistration/" + this.state.eventsData._id}
                          </a>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
        <Dialog
          handleClose={this.closeCompletionDialog}
          open={this.state.openCompletionDialog}
        >
          <Grid
            spacing={3}
            style={{ padding: '50px' }}
            direction='column'
            container
          >
            <Grid item>
              <Typography>
                In order to register an event your profile needs to be complete.
                Please click 'Go to setting' button and enter any missing
                information.
              </Typography>
            </Grid>
            <Grid item>
              <Grid spacing={2} justify='flex-end' container>
                <Grid item>
                  <Button onClick={this.closeCompletionDialog}>Cancel</Button>
                </Grid>
                <Grid item>
                  <PrimaryBtn
                    onClick={() => this.props.history.push('/setting')}
                    text='Go to settings'
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      </Grid>
    );
  }
}

const connected = connect(
  (state) => ({
    getEventsDataById: state.getAllEventsById.getAllEventsByIdData,
  }),
  {
    getByIdEventsAction,
  }
)(EventDetail);

export default withStyles(styles)(connected);
