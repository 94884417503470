import React from 'react';
import { Grid, TableCell, Icon } from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  Create as CreateIcon,
} from '@material-ui/icons';
import { IconBtn, ActionBtn } from './style';

const TableAction = ({
  showActionBtns,
  showViewBtn,
  idKey,
  row,
  viewBtnText,
  showEditBtn,
  editBtnText,
  onViewClick,
  onEditClick,
  showIconBtns,
}) => {
  const textBtns = () => {
    return showActionBtns ? (
      <TableCell align='left' padding='none'>
        <Grid spacing={2} wrap='nowrap' container>
          {showViewBtn && (
            <Grid item>
              <ActionBtn
                onClick={() => onViewClick(idKey ? row[idKey] : row._id)}
              >
                {viewBtnText}
              </ActionBtn>
            </Grid>
          )}
          {showEditBtn && (
            <Grid item>
              <ActionBtn
                onClick={() => onEditClick(idKey ? row[idKey] : row._id)}
              >
                {editBtnText}
              </ActionBtn>
            </Grid>
          )}
        </Grid>
      </TableCell>
    ) : (
      <TableCell align='left' padding='none'></TableCell>
    );
  };

  const iconBtns = () => {
    return showActionBtns ? (
      <TableCell align='left' padding='none'>
        <Grid wrap='nowrap' justify='space-around' container>
          {showViewBtn && (
            <Grid item>
              <IconBtn
                onClick={() => onViewClick(idKey ? row[idKey] : row._id)}
              >
                <Icon component={VisibilityIcon} />
              </IconBtn>
            </Grid>
          )}
          {showEditBtn && (
            <Grid item>
              <IconBtn
                onClick={() => onEditClick(idKey ? row[idKey] : row._id)}
              >
                <Icon component={CreateIcon} />
              </IconBtn>
            </Grid>
          )}
        </Grid>
      </TableCell>
    ) : (
      <TableCell align='left' padding='none'></TableCell>
    );
  };
  return showIconBtns ? iconBtns() : textBtns();
};

export default TableAction;
