import { createStyles} from "@material-ui/core";

export default (theme) =>
  createStyles({
    root: {
      backgroundColor: "#cfd9df",
      width: "100%",
      height: "100%",
      opacity: "20%",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    container: {
      position: "absolute",
      top: "150px",
      zIndex: 1,
      [theme.breakpoints.up("xs")]: {
        width: "300px",
        top: "120px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "500px",
        top: "120px",
      },
      [theme.breakpoints.up("md")]: {
        width: "500px",
        top: "150px",
      },
    },
    title: {
      height: "13px",
      fontSize: "16px",
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: 400,
      textAlign: "center",
      textTransform: "uppercase",
    },
    subtitle: {
      fontSize: "22px",
      color: "#384984",
      fontFamily: "Lato",
      fontWeight: 700,
      textAlign: "center",
      marginTop: "29px",
    },
    text: {
      height: "80px",
      fontSize: "18px",
      lineHeight: "42px",
      color: "#4e5779",
      fontWeight: 400,
      textAlign: "center",
      fontFamily: "Lato",
      opacity: "29%",
    },
    buttonNext: {
      // width: "158px",
      height: "52px",
      borderRadius: "26px",
      // backgroundColor: "#304659",
      // fontSize: "15px",
      // color: "#ffffff",
      // fontWeight: "bold",
      // fontFamily: "Lato",
      // "&:hover": { backgroundColor: "#304659", opacity: "50%" },
      // justifyContent: "center",
      // cursor: "pointer",
      // textTransform: "capitalize",
      // marginTop: "48px",
    },
    buttonContainer: {
      [theme.breakpoints.up("xs")]: {
        marginTop: "100px",
      },
      [theme.breakpoints.up("sm")]: {
        marginTop: "48px",
      },
    },
    pagination: {
      display: "flex",
      marginTop: "41px",
      justifyContent: "center",
      marginBottom: "100px",
    },
    image: {
      [theme.breakpoints.up("xs")]: {
        width: "300px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "500px",
      },
    },
    buttonText: { marginTop: "5px" },
  });