import React, { useEffect, useState, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { getPageCount } from '../../../../utils/MathUtil';
import InvoiceLeftColHeader from './InvoiceLeftColHeader';
import InvoiceList from './InvoiceList';
import InvoiceListPagination from './InvoiceListPagination';
import EmptyContentView from './EmptyContentView';
import Loader from '../../../../components/Loader/Loader';
import '../index.css'
import Axios from 'axios';
import { useLocation } from "react-router-dom";
import { baseURL } from '../../../../utils/axios';
const CreateInvoice = lazy(() =>
  import('../../CreateInvoice/InvoiceNew'))
const InvoiceDetailsView = lazy(() =>
  import('../InvoicesDetails/InvoiceDetailsView')
);


const Invoices = ({ history, billingData }) => {
  const [billings, setBillings] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [appointmentId, setAppointmentId] = useState(null);
  const [openAppointment, setOpenAppointment] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [createNewInvoice, setCreateNewInvoice] = useState(false);
  const [services, setServices] = useState([]);
  const [openInvoiceDetailView, setOpenInvoiceDetailView] = useState(false);
  const [openCheckoutPanel, setOpenCheckoutPanel] = useState(false);
  const [editingInvoice, setEditingInvoice] = useState(false);

  const search = useLocation().search;
  const editId = new URLSearchParams(search).get('editId');

  useEffect(() => {
    console.log('editId', editId)
    if (editId) {
      const invoice = billings.find((b) => b._id === editId);
      setSelectedInvoice(invoice)
      setEditingInvoice(true)
      setCreateNewInvoice(false)
    }
  }, [editId]);

  useEffect(() => {
    getServices();
  }, []);

  const getAllBillings = (page, selectedFilter) => {
    setLoading(true);

    let listing = [];
    Axios.get(`${baseURL}/api/billing/getAll?page=${page}&status=${selectedFilter}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          let data = res.data[0]
          data.data.forEach((bill) => {
            const id = bill.appointmentClinicAddresses[0]?.id ? "-" + bill.appointmentClinicAddresses[0]?.id : ""
            listing.push({
              ...bill,
              aptId: bill.appointmentId.length > 0 ?  `APT${id}-${moment(bill.appointmentId[0].createdAt).format('YY')}-${bill.appointmentId[0].chartId}` : null,
              chartId: `INV${id}-${moment(bill.createdAt).format('YY')}-${bill.chartId}`,
              createdAt: moment(bill.createdAt).format('LL'),
              clients: bill.clients.sort((a, b) => new Date(b.dob) - new Date(a.dob))
            });
            if (selectedInvoiceId == bill._id) {
              setSelectedInvoice(listing[listing.length - 1]);
            } else if (!selectedInvoice) {
              setSelectedInvoice(listing[0]);
            }
          });

          setBillings(listing);

          if (data?.data?.length === 0) {
            setLoading(false);
            setTotalRecords(0);
            setTotalPages(0);
            setBillings([]);
          } else {
            const countPages = getPageCount(data.count);
            setTotalPages(countPages);
            setTotalRecords(data.count);
            setLoading(false);
          }
        }
      })
      .catch((e) => {setLoading(false) });
  }

  useEffect(() => {
    getAllBillings(page, selectedFilter)
  }, [page, selectedFilter]);

  const handlePageChange = (value) => {
    setPage(value);
  };

  const getServices = () => {
    Axios.get(`${baseURL}/admin/service`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        setServices(res.data);
      })
      .catch((e) => { });
  };

  const filterInvoices = (e) => setSelectedFilter(e.target.value);

  const onInvoiceClickHandler = (invoiceId) => {
    const invoice = billings.find((b) => b._id === invoiceId);
    setCreateNewInvoice(false);
    setEditingInvoice(false)
    setOpenInvoiceDetailView(true);
    setOpenCheckoutPanel(false);
    setSelectedInvoice(invoice);
    const queryParams = new URLSearchParams(search)
    queryParams.delete('editId')
    history.replace({
      search: queryParams.toString(),
    })
  };

  const createPersonalInvoice = () => {
    console.log("personal new")
    setSelectedInvoice(null);
    setOpenInvoiceDetailView(false);
    setEditingInvoice(false)
    setOpenCheckoutPanel(false);
    setCreateNewInvoice(true);
  };

  const createBundleInvoice = () => console.log('createBundleInvoice');
  const corporateClientInvoice = () => console.log('corporateClientInvoice');
  const corporateInvoice = () => console.log('corporateInvoice');

  const refresh = (selectedId) => {
    getAllBillings(page, selectedFilter)
    setSelectedInvoiceId(selectedId);
  };

  const closeNewInvoiceView = () => {
    setEditingInvoice(false)
    setCreateNewInvoice(false);
    getAllBillings(page, selectedFilter)
  };

  const checkoutHandler = (invoiceId) => {
    history.push(`/admin/billing/checkout/${invoiceId}`);
  };


  const total = (inv) => {
    console.log(inv.amount)
    if (!inv) {
      return 0;
    }

    const servicesVatAmmount = inv.services
      ? inv.services.map((record, i) => {
        const amount = Number(record.amount);
        const vatPercentage =
          inv?.servicesData.find((service) => service._id === record.serviceId)?.vat || 0;

        const discountedAmount = record.discount
          ? Number((amount - (record.discount / 100) * amount).toFixed(2))
          : amount;

        const vatAmount = vatPercentage
          ? Number(((discountedAmount / 100) * vatPercentage).toFixed(2))
          : 0;


        return Number(discountedAmount + vatAmount).toFixed(2);
      })
      : [0]

    const extraFeesAmount = inv.extraFees
      ? inv.extraFees.map((e) => Number(e.amount))
      : [0];

    console.log(servicesVatAmmount, extraFeesAmount);
    return Number([...extraFeesAmount, ...servicesVatAmmount, 0].reduce((sum, a) => Number(sum) + Number(a))).toFixed(2);
  };

  const renderView = () => {
    console.log("renderView")
    let view = <EmptyContentView />;
    if (openInvoiceDetailView && !editingInvoice) {
      view = (
        <InvoiceDetailsView
          mainInvoice={selectedInvoice}
          refresh={refresh}
          services={services}
          onCheckoutClick={checkoutHandler}
          total={total}
          handleEdit={setEditingInvoice}
        />
      );
    } else if (createNewInvoice || editingInvoice) {
      console.log(createNewInvoice, editingInvoice)
      view = (
        <CreateInvoice
          closeNewInvoiceView={closeNewInvoiceView}
          invoice={selectedInvoice}
          services={services}
          createNewInvoice
        />
      );
    }

    return view;
  };

  return (
    <div
      className='item'
      style={{
        height: 'calc(100vh - 88px - 64px)',
      }}
    >
      {isLoading ?
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center" style={{ zIndex: 10000 }}>
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <h2 className="text-center text-white text-xl font-semibold">Loading...</h2>
          <p className="w-1/3 text-center text-white">This may take a few seconds, please don't close this page.</p>
        </div> : <></>}

      <div className='application-wrapper h-full '>
        <div className='flex flex-row flex-nowrap w-full h-full gap-1'>
          <div
            className='item col-left h-full w-3/12 bg-white'
            style={{
              minWidth: '385px',
            }}
          >
            <div className='flex flex-col h-full flex-nowrap '>
              <InvoiceLeftColHeader
                createPersonalInvoice={createPersonalInvoice}
                createBundleInvoice={createBundleInvoice}
                corporateClientInvoice={corporateClientInvoice}
                corporateInvoice={corporateInvoice}
                filterInvoices={filterInvoices}
              />

              <div className='item col-list flex-grow overflow-y-auto bg-white'>
                {billings?.length > 0 && (
                  <InvoiceList
                    selectedInvoice={selectedInvoice}
                    invoices={billings}
                    total={total}
                    onClick={(invoiceId) => onInvoiceClickHandler(invoiceId)}
                  />
                )}
              </div>

              <div
                className='item col-footer flex-grow-0 flex-shrink-0'
                style={{
                  height: '64px',
                }}
              >
                <InvoiceListPagination
                  totalPages={totalPages}
                  page={page}
                  totalRecords={totalRecords}
                  handlePageChange={handlePageChange}
                />
              </div>
            </div>
          </div>

          <Suspense fallback={<Loader />}>{renderView()}</Suspense>
        </div>
      </div>
    </div>
  );
};

Invoices.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};



export default connect()(Invoices);
