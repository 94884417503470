import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  root: {
    fontSize: 25,
    color: '#003d79',
  },
  crossIcon: {
    marginTop: 20,
    textAlign: 'left',
    color: '#ffffff',
    background: '#a29061',
    backgroundColor: '#a29061',
  },
  divstyle: {
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#eceff1',
    height: 40,
    borderRadius: 5,
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: '#003d79',
  },
  righttextinput: {
    textAlign: 'left',
    marginTop: theme.spacing(4),
  },
  formHeading: {
    position: 'static',
    width: 'auto',
    textAlign: 'left',
    fontSize: 18,
    fontWeight: 400,
    color: '#003d79',
    fontFamily: 'Open Sans,Helvetica,Arial,sans-serif',
  },
  bottomtext: {
    fontSize: 13,
    color: '#777',
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#1976d2 !important',
  },
  date: {
    marginTop: theme.spacing(1.4),
    width: '100%',
    height: 38,
    borderRadius: 3,
    borderColor: '#cfd8dc',
    borderWidth: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'inline-block',
    color: '#003d79',
  },
  select: {
    marginTop: theme.spacing(1.2),
  },
});
