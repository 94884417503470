/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/state-in-constructor */
import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {

  withStyles,
  Typography,
  Divider,
  Box,
} from '@material-ui/core';
import Client_Info_Form from './edit_client_components/Client_Info_Form';
import SidebarMenu from '../../../components/Sidebar';
import Contacts from './edit_client_components/Contacts';
import BillingInsurance from './edit_client_components/BillingInsurance';
import ClientPortal from './edit_client_components/ClientPortal';
import styles from './Tabs_EditClientStyle';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class EditClientTabs extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <SidebarMenu />
        <div style={{ textAlign: 'left' }}>
          <Typography display="inline" className={classes.title}>
            Edit client
            {' '}
          </Typography>
          <Typography display="inline" className={classes.namesty}>
            Jamie D. Appleseed
          </Typography>
        </div>
        <Tabs
          value={this.state.value}
          indicatorColor="primary"
          textColor="#444"
          onChange={this.handleChange}
          aria-label="disabled tabs example"
        >
          <Tab value={0} label="Client Info" className={classes.initabcolor} />
          <Tab
            value={1}
            label="Billing and Insurance"
            className={classes.initabcolor}
          />
          <Tab value={2} label="Contacts" className={classes.initabcolor} />
          <Tab
            value={3}
            label="Client Portal"
            className={classes.initabcolor}
          />
        </Tabs>
        <Divider className={classes.dividersty} />
        <TabPanel
          value={this.state.value}
          index={0}
          className={classes.panelsty}
        >
          <Client_Info_Form props={this.props} />
        </TabPanel>
        <TabPanel
          value={this.state.value}
          index={1}
          className={classes.panelsty}
        >
          <BillingInsurance props={this.props} />
        </TabPanel>

        <TabPanel
          value={this.state.value}
          index={2}
          className={classes.panelsty}
        >
          <Contacts props={this.props} />
        </TabPanel>
        <TabPanel
          value={this.state.value}
          index={3}
          className={classes.panelsty}
        >
          <ClientPortal props={this.props} />
        </TabPanel>
      </div>
    );
  }
}

export default withStyles(styles)(EditClientTabs);
