/* eslint-disable react/state-in-constructor */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import {

  withStyles,
  Typography,
  Grid,
  TextareaAutosize,
  Button,
} from '@material-ui/core';
import Select from 'react-select';
import styles from './ClientPortalStyle';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

class ClientInfo extends React.Component {
  state = {
    selectedOption: null,
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  render() {
    const { classes } = this.props;
    const { selectedOption } = this.state;

    return (
      <div className={classes.root}>
        <Typography className={classes.title}>Client Portal Access</Typography>
        <Typography className={classes.subtitle}>
          Clients will create their own accounts to access your Client Portal.
        </Typography>

        <Typography className={classes.heading}>Email</Typography>

        <Grid item xs={6}>
          <Select
            value={selectedOption}
            onChange={this.handleChange}
            options={options}
            className={classes.selectsty}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography className={classes.subtitle}>
            The following invitation email will be sent to the client with a
            unique access link. This access link will allow the client to set a
            password and login to the Client Portal.
          </Typography>
        </Grid>

        <Typography className={classes.heading}>subject</Typography>

        <Grid item xs={6}>
          <input
            name="emName"
            // value={emName}
            // onChange={this.handleChange}
            placeholder="[Activation Required] Welcome to my Client Portal"
            className={classes.divstyle}
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
        </Grid>

        <Typography className={classes.heading}>Body</Typography>
        <Grid item xs={6}>
          <TextareaAutosize
            rowsMax={4}
            aria-label="maximum height"
            placeholder="Maximum 4 rows"
            defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit,
            sed do eiusmod tempor incididunt
            ut labore et dolore magna aliqua."
            className={classes.textareasty}
          />
        </Grid>

        <Button variant="contained" size="small" className={classes.saveButton}>
          send Email
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(ClientInfo);
