/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import { createStyles, withStyles, Button } from "@material-ui/core";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { EditOutlined } from "@material-ui/icons";
import Sidebar from "../common/Header";
import { CustomizedTables, StyledTableCell } from "../common/Table";
import AddPopup from "../common/AddPopup";
import {
  addMhaAction,
  getMhaAction,
  updateMhaAction,
  getByIdMhaAction,
} from "../../../utils/actions/admin.mha.action";
import UpdatePopup from "../common/UpdatePopup";
import styles from "./MentalHealthAssessment.styles";

class MentalHealthAssessment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdd: false,
      isUpdate: false,
      title: "",
      id: "",
    };
  }

  componentDidMount() {
    this.props.getMhaAction();
  }

  componentDidUpdate(preProps) {
    if (this.props.addData !== preProps.addData) {
      this.props.getMhaAction();
    }
    if (this.props.updateData !== preProps.updateData) {
      this.props.getMhaAction();
    }
    if (this.props.getByIdData !== preProps.getByIdData) {
      const data = this.props.getByIdData;
      this.setState({
        isUpdate: true,
        title: data.mentalHealthAssessment,
        id: data._id,
      });
    }
  }

  handleSubmit = () => {
    const { title, isAdd, isUpdate, id } = this.state;
    if (isAdd) {
      const data = {
        mentalHealthAssessment: title,
        for: "mentalHealthAssessment",
      };
      this.props.addMhaAction(data);
      this.setState({ isAdd: false, title: "", id: "" });
    }
    if (isUpdate) {
      const data = {
        mentalHealthAssessment: title,
        for: "mentalHealthAssessment",
      };
      this.props.updateMhaAction(id, data);
      this.setState({ isUpdate: false, title: "", id: "" });
    }
  };

  render() {
    const { classes } = this.props;
    

    const inputField = [
      { lable: "Title", value: "title", fieldType: "editor" },
    ];
    const updateInputField = [
      {
        lable: "Title",
        name: "title",
        value: this.state.title,
        fieldType: "editor",
      },
    ];
    const rows = this.props.getData.map((data, index) => {
      if (data.mentalHealthAssessment) {
        return (
          <>
            <StyledTableCell>{index + 1}</StyledTableCell>
            <StyledTableCell>
              <p
                dangerouslySetInnerHTML={{
                  __html: data.mentalHealthAssessment,
                }}
              />
            </StyledTableCell>
            <StyledTableCell align="left">
              <EditOutlined
                style={{ cursor: "pointer" }}
                onClick={() => this.props.getByIdMhaAction(data._id)}
              />
              <Button
                color={data.status === "active" ? "primary" : "secondary"}
                onClick={() =>
                  this.props.updateMhaAction(data._id, { status: "active" })
                }
              >
                Active
              </Button>
              <Button
                color={data.status === "deactive" ? "primary" : "secondary"}
                onClick={() =>
                  this.props.updateMhaAction(data._id, { status: "deactive" })
                }
              >
                Deactive
              </Button>
            </StyledTableCell>
          </>
        );
      }
    });
    return (
      <>
        <Sidebar />
        <div className={classes.root}>
          {this.state.isUpdate && (
            <UpdatePopup
              headerTitle="Update Mental Health Assessment"
              isOpen={this.state.isUpdate}
              inputField={updateInputField}
              onEditorTextChange={(data) => this.setState({ title: data })}
              onAdd={this.handleSubmit}
              onCancel={() => this.setState({ isUpdate: false })}
            />
          )}
          {this.state.isAdd ? (
            <AddPopup
              headerTitle="Add Mental Health Assessment"
              isOpen={this.state.isAdd}
              inputField={inputField}
              onEditorTextChange={(data) => this.setState({ title: data })}
              onAdd={this.handleSubmit}
              onCancel={() => this.setState({ isAdd: false })}
            />
          ) : (
            <Button
              className={classes.addButton}
              onClick={() => this.setState({ isAdd: true })}
            >
              Add Mental Health Assessment
            </Button>
          )}
          <CustomizedTables
            headers={["Sr. No.", "Title", "Action"]}
            rows={rows}
            loaded={this.props.getDataLoader}
          />
        </div>
      </>
    );
  }
}

MentalHealthAssessment.defaultProps = {
  getData: [],
  getDataLoader: true,
};

const connected = connect(
  (state) => ({
    addData: state.addMha,
    getData: state.getMha.getMhaData,
    getDataLoader: state.getMha.request,
    updateData: state.updateMha.updateMhaData,
    getByIdData: state.getByIdMha.getByIdMhaData,
  }),
  {
    addMhaAction,
    getMhaAction,
    updateMhaAction,
    getByIdMhaAction,
  }
)(MentalHealthAssessment);

const formed = reduxForm({
  form: "mha",
})(connected);


export default withStyles(styles)(formed);
