/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import {
  Typography,
  Container,
  CssBaseline,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import validator from "validator";
import { loginAction } from "../../utils/actions/auth.action";
import AlertMsg from "../../utils/Alert";
import styles from "./loginStyles";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      password: '',
    };
  }

  componentDidMount() {
    localStorage.removeItem('ACCESS_TOKEN_PATH');
    localStorage.removeItem('__r');
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  formVerification = () => {
    const user = validator.isEmpty(this.state.userName, {
      ignore_whitespace: true,
    });
    const password = validator.isEmpty(this.state.password, {
      ignore_whitespace: true,
    });
    if (user || password) {
      AlertMsg('error', 'All field must be filled');
      return false;
    }
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { userName, password } = this.state;
    const isVerify = this.formVerification();
    if (isVerify) {
      const data = {
        userName,
        password,
      };
      const { history } = this.props;
      this.props.loginAction(data, history);
    }
  };

  render() {
    const { userName, password } = this.state;
    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Typography className={classes.heading}>
          Welcome back to Light House.
        </Typography>
        <div className={classes.paper}>
          <form className={classes.form}>
            <Typography className={classes.title}>Username</Typography>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="userName"
              value={userName}
              onChange={this.handleChange}
              autoFocus
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
            <Typography className={classes.title}>Password</Typography>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              type="password"
              value={password}
              onChange={this.handleChange}
              autoComplete="current-password"
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
            <div style={{ textAlign: 'left' }}>
              <FormControlLabel
                control={<Checkbox value="remember" color="#1976d2" />}
                label="Remember me"
              />
            </div>
            <Button
              fullWidth
              type="submit"
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            {/* <Grid container>
              <Grid item xs style={{ textAlign: "left" }}>
                <Link href="#" variant="body2" className={classes.linksty}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link to="/sign-up" variant="body2" className={classes.linksty}>
                  Don't have an account? Sign Up
                </Link>
              </Grid>
            </Grid> */}
          </form>
        </div>
      </Container>
    );
  }
}

const connected = connect(null, { loginAction })(Login);
const formed = reduxForm({
  form: 'login',
})(connected);



export default withStyles(styles)(formed);
