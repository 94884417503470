import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Typography, Button as CancelButton } from '@material-ui/core';
import {
  getSingleInvoiceAction,
  clearInvoice,
} from '../../../utils/actions/billing.action';
import { useHistory } from 'react-router-dom';
import {
  markInvoiceAsPaid,
  clearUpdatedInvoice,
} from '../../../utils/actions/payment.action';
import { LoaderGrid, PDFButton, InvoiceStatus, PendingInvoice } from './style';
import { Dialog, Spinner, Button } from '../../../components/atoms';

import Unpaid from './Unpaid';
import Paid from './Paid';

const Receipt = ({
  match,
  getInvoiceAction,
  clearInvoice,
  invoiceData,
  markInvoiceAsPaid,
  updatedInvoice,
  clearUpdatedInvoice,
}) => {
  const [invoice, setInvoice] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    getInvoiceAction(match.params.invoiceId);
  }, []);

  useEffect(() => {
    if (invoiceData) {
      setInvoice(invoiceData);
      clearInvoice();
    }
  }, [invoiceData]);

  useEffect(() => {
    if (updatedInvoice) {
      setInvoice({ ...invoice, status: updatedInvoice.status });
      setShowConfirmation(false);
      clearUpdatedInvoice();
      setLoading(false);
    }
  }, [updatedInvoice]);

  const clinicAddress = (a) => {
    if (!a || !a.address1) {
      return '';
    }

    return (
      <Grid direction='column' alignItems='flex-start' container>
        <Grid item>{`${a.address1} ${a.address2}, ${a.city},`}</Grid>
        <Grid item>
          {a.state && a.state.name && `${a.state.name}, `}
          {a.zip && `${a.zip}, `}
        </Grid>
        <Grid item>{a.country.name}</Grid>
      </Grid>
    );
  };

  const clientAddress = (address) => {
    if (address && address.address1) {
      let formattedAddress = address.address1;
      formattedAddress = address.address2
        ? `${formattedAddress}, ${address.address2}`
        : formattedAddress;
      formattedAddress =
        address.state && address.state.name
          ? `${formattedAddress}, ${address.state && address.state.name}`
          : formattedAddress;
      formattedAddress = `${formattedAddress}, ${address.country}`;
      return formattedAddress;
    } else {
      return 'No Address added!';
    }
  };

  const printDiv = () => {
    for (const element of document.getElementsByClassName('invoice-actions')) {
      element.style.display = 'none';
    }
    window.print();
    for (const element of document.getElementsByClassName('invoice-actions')) {
      element.style.display = 'block';
    }

    return false;
  };

  const renderReceipt = () =>
    invoice.status === 'unpaid' ? (
      <Unpaid
        invoice={invoice}
        clinicAddress={clinicAddress}
        clientAddress={clientAddress}
      />
    ) : (
      <Paid
        invoice={invoice}
        clinicAddress={clinicAddress}
        clientAddress={clientAddress}
      />
    );

  const markPaymentAsComplete = () => {
    setLoading(true);
    markInvoiceAsPaid(invoice._id);
  };
  console.log('invvoice', invoice);
  return invoice ? (
    <Grid>
      {loading && <Spinner isOpen />}
      <Grid justify='flex-end' container>
        <PDFButton
          justify='flex-end'
          spacing={3}
          xs={3}
          wrap='nowrap'
          item
          container
        >
          <Grid item>
            {invoice.status === 'unpaid' ? (
              <Button
                bgcolor='red'
                text='Submit Payment'
                className='invoice-actions'
                onClick={() =>
                  history.push(`/admin/generate-invoice/${invoice._id}`)
                }
              />
            ) : invoice.status === 'pending' ? (
              <Button
                className='invoice-actions'
                bgcolor='#555'
                text='Pending Payment'
                onClick={() => setShowConfirmation(true)}
              />
            ) : (
              <InvoiceStatus className='invoice-actions'>Paid</InvoiceStatus>
            )}
          </Grid>
          <Grid item>
            <Button
              text='Download PDF'
              onClick={printDiv}
              className='invoice-actions'
            />
          </Grid>
        </PDFButton>
      </Grid>

      <Grid id='receipt' container>
        {renderReceipt()}
      </Grid>
      <Dialog
        handleClose={() => setShowConfirmation(false)}
        open={showConfirmation}
      >
        <Grid
          spacing={3}
          style={{ padding: '50px' }}
          direction='column'
          container
        >
          <Grid item>
            <Typography>
              This action will change the status of this invoice from pending to
              paid. Do you want to continue?
            </Typography>
          </Grid>
          <Grid item>
            <Grid spacing={2} justify='flex-end' container>
              <Grid item>
                <CancelButton onClick={() => setShowConfirmation(false)}>
                  Cancel
                </CancelButton>
              </Grid>
              <Grid item>
                <Button onClick={markPaymentAsComplete} text='Confirm' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  ) : (
    <LoaderGrid justify='center' alignItems='center' container>
      <Spinner isOpen={true} />
    </LoaderGrid>
  );
};

Receipt.propTypes = {
  getInvoiceAction: PropTypes.func.isRequired,
  invoiceData: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      invoiceId: PropTypes.string.isRequired,
    }),
  }),
  showAsPDF: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  invoiceData: state.getSingleInvoice.getSingleInvoiceData,
  updatedInvoice: state.markInvoiceAsPaid.data,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceAction: (id) => dispatch(getSingleInvoiceAction(id)),
  clearInvoice: () => dispatch(clearInvoice()),
  markInvoiceAsPaid: (id) => dispatch(markInvoiceAsPaid(id)),
  clearUpdatedInvoice: () => dispatch(clearUpdatedInvoice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Receipt);
