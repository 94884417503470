import React from 'react';
import { Avatar, TableCell, Button } from '@material-ui/core';
import { FlexCell, ImgText } from './style';

const FormattedTableCell = ({ data, headers, keyValue, history }) => {
  const produceCell = (datalist, headersList, keyName) => {
    if (
      headersList[keyName].isImageWithText &&
      headersList[keyName].concat &&
      headersList[keyName].prefix
    ) {
      const { prefix } = headersList[keyName];

      return (
        <TableCell align='left' key={`lh-cd-${keyName}`}>
          <FlexCell>
            <Avatar src={datalist[prefix][keyName]} alt={keyName} />
            <ImgText>
              {headersList[keyName].fields
                .map((f) => `${datalist[prefix][f]}`)
                .join(headersList[keyName].concatOp)}
            </ImgText>
          </FlexCell>
        </TableCell>
      );
    }
    if (headersList[keyName].isImageWithText && headersList[keyName].concat) {
      return (
        <FlexCell align='left' key={`lh-cd-${keyName}`}>
          <Avatar src={datalist[keyName]} alt={keyName} />
          <ImgText>
            {headersList[keyName].fields
              .map((f) => `${datalist[f]}`)
              .join(headersList[keyName].concatOp)}
          </ImgText>
        </FlexCell>
      );
    } else if (headersList[keyName].isImageWithText) {
      return (
        <FlexCell align='left' key={`lh-cd-${keyName}`}>
          <Avatar src={datalist[keyName]} alt={keyName} />
          <ImgText>{datalist[headersList[keyName].text]}</ImgText>
        </FlexCell>
      );
    } else if (headersList[keyName].isImage) {
      let src = datalist[keyName];

      if (headersList[keyName].prefix) {
        src = datalist[headersList[keyName].prefix][keyName];
      }
      return (
        <TableCell align='left' key={`lh-cd-${keyName}`}>
          <Avatar src={src} alt={keyName} />
        </TableCell>
      );
    } else if (headersList[keyName].concat) {
      return (
        <TableCell align='left' key={`lh-cd-${keyName}`}>
          {headersList[keyName].fields
            .map((f) => `${datalist[f]}`)
            .join(headersList[keyName].concatOp)}
        </TableCell>
      );
    } else if (headersList[keyName].conditionalComponent) {
      let componentProps = {};
      if (
        headersList[keyName].propFields &&
        headersList[keyName].propFields.length > 0
      ) {
        const propKeyName = headersList[keyName].propFields.map(
          (p) => Object.keys(p)[0]
        )[0];
        componentProps = { [propKeyName]: datalist[keyName] };
      }

      return (
        <TableCell align='left' key={`lh-cd-${keyName}`}>
          {React.createElement(
            headersList[keyName].component,
            {
              ...componentProps,
            },
            null
          )}
        </TableCell>
      );
    } else if (headersList[keyName].isLink) {
      let url = headersList[keyName].url;
      if (headersList[keyName].dynamicLink) {
        url = Object.keys(headersList[keyName].urlParams).map((o) =>
          url.replace(`:${o}`, datalist[headersList[keyName].urlParams[o]])
        )[0];
      }

      return (
        <TableCell align='left' key={`lh-bt-${keyName}`}>
          <Button onClick={() => history.push(url)}>{datalist[keyName]}</Button>
        </TableCell>
      );
    } else if (headersList[keyName].enumMap) {
      return (
        <TableCell align='left' key={`lh-bt-${keyName}`}>
          {headersList[keyName].enumList[datalist[keyName]]}
        </TableCell>
      );
    } else {
      return (
        <TableCell
          align='left'
          key={`lh-cd-${keyName}`}
          style={{ whiteSpace: 'nowrap' }}
        >
          {datalist[keyName] ? datalist[keyName] : headersList[keyName].default}
        </TableCell>
      );
    }
  };

  return produceCell(data, headers, keyValue);
};

export default FormattedTableCell;
