import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { Drawer } from '@material-ui/core';
import { AddressForm } from '../../../../components/molecules';
import { Button } from '../../../../components/atoms';
import { Container, Heading, Row, BottomBtn } from './style';
import CountryData from '../../../../utils/countries_data';
import { uid } from '../../../../utils/validators';

const AddressFormDrawer = ({
  isOpen,
  toggleAddressDrawer,
  addAddress,
  updateAddress,
  editingAddress,
  removeAddress,
}) => {
  const [address, setAddress] = useState({
    _id: uid(),
    country: '',
    state: '',
    city: '',
    zip: '',
    address1: '',
    address2: '',
    phoneNumber: '',
    email: '',
  });

  const [errors, setErrors] = useState({
    country: { title: 'Country', message: '' },
    address1: { title: 'Address Line 1', message: '' },
    city: { title: 'City', message: '' },
    phoneNumber: { title: 'Phone Number', message: '' },
    email: { title: 'Email', message: '' },
  });

  useEffect(() => {
    if (editingAddress) {
      setAddress({
        ...editingAddress,
      });

      if (editingAddress.country) {
        const country = CountryData.filter(
          (c) => c.code2 === editingAddress.country
        );
        setStatesList([...country[0].states]);
      }
    }
  }, [editingAddress]);
  const [statesList, setStatesList] = useState([]);

  useEffect(() => {
    if (isOpen && !editingAddress) {
      setAddress({
        _id: uid(),
        country: '',
        state: '',
        city: '',
        zip: '',
        address1: '',
        address2: '',
        phoneNumber: '',
        email: '',
      });
    }
  }, [isOpen]);
  const onChange = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
    if (e.target.name === 'country') {
      const country = CountryData.filter((c) => c.code2 === e.target.value);
      setStatesList([...country[0].states]);
    }

    if (errors[e.target.name]) {
      setErrors({
        ...errors,
        [e.target.name]: {
          ...errors[e.target.name],
          message: '',
        },
      });
    }
  };

  const resetAndToggle = () => {
    setAddress({
      _id: uid(),
      country: '',
      state: '',
      city: '',
      zip: '',
      address1: '',
      address2: '',
      phoneNumber: '',
      email: '',
    });
    toggleAddressDrawer();
  };

  const onRemoveClick = () => {
    resetAndToggle();
    removeAddress(address._id);
  };

  const handleSubmit = () => {
    let err = {};
    Object.keys(errors).forEach((f) => {
      let message;
      let invalidEmail;

      if (f === 'email' && address.email.trim()) {
        const isEmail = validator.isEmail(address.email.trim());
        if (!isEmail) {
          invalidEmail = true;
          message = 'Email is not valid!';
        }
      }

      err = {
        ...err,
        [f]: {
          ...errors[f],
          message:
            !address || !address[f].trim() || invalidEmail
              ? message
                ? message
                : `${errors[f]['title']} can not be empty!`
              : '',
        },
      };
    });

    setErrors({ ...err });

    const isError = Object.keys(err).some((e) => err[e].message);

    if (isError) {
      return;
    }

    resetAndToggle();
    editingAddress ? updateAddress(address) : addAddress(address);
  };

  return (
    <Drawer anchor='right' open={isOpen} onClose={toggleAddressDrawer}>
      <Container>
        <Heading> Add New Company Address</Heading>
        <AddressForm
          address={address}
          onChange={onChange}
          statesList={statesList}
          CountryData={CountryData}
          errors={errors}
        />
        <Row direction='column' container item>
          {editingAddress ? (
            <Button text='Update Address' onClick={handleSubmit} />
          ) : (
            <Button text='Add New Address' onClick={handleSubmit} />
          )}
          <BottomBtn container>
            <Button
              text='Remove Address'
              bgcolor='#db1919'
              onClick={onRemoveClick}
            />
          </BottomBtn>
        </Row>
      </Container>
    </Drawer>
  );
};

AddressFormDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleAddressDrawer: PropTypes.func.isRequired,
  addAddress: PropTypes.func.isRequired,
  updateAddress: PropTypes.func.isRequired,
  removeAddress: PropTypes.func.isRequired,
  editingAddress: PropTypes.object,
};

export default AddressFormDrawer;
