import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { Drawer, Select } from '@material-ui/core';

const Container = styled(Grid)`
  padding: 20px;
`;

const Heading = styled(Typography)`
  font-size: 22px;
  font-weight: 700;
  font-family: Lato;
`;

const Row = styled(Grid)`
  margin-top: 10px;
`;

const BottomBtn = styled(Grid)`
  margin-top: 10px;
`;

const DrawerWindow = styled(Drawer)`
  & .MuiDrawer-paper {
    min-width: 400px !important;
  }
`;

const Label = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  font-family: Lato;
  line-height: 3.38;
`;

const SelectBox = styled(Select)`
  width: 100%;
`;

const Required = styled.span`
  color: red;
`;

const Text = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  font-family: Lato;
  color: rgba(112, 112, 112, 0.5);
`;

const Error = styled.span`
  color: red;
  font-family: Lato;
  font-weight: 400;
  font-size: 16px;
`;

export {
  Container,
  Heading,
  Row,
  BottomBtn,
  DrawerWindow,
  Label,
  SelectBox,
  Required,
  Text,
  Error,
};
