import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import AlertMsg from '../utils/Alert';

const PaypalPaymentGeneric = ({ amount, onSuccessfulPayment }) => {
  console.log(process.env)
  return (
    <div>
      {console.log(amount)}
      {amount && (
        <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_ENV === 'stage' || process.env.REACT_APP_ENV === 'development' ? process.env.REACT_APP_PAYPAL_SANDBOX_ID : process.env.REACT_APP_PAYPAL_PRODUCTION_APP_ID }}>
          <PayPalButtons
            fundingSource="paypal"
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: Number((amount / 3.67).toFixed(2)),
                    },
                  },
                ],
              });
            }}
            onApprove={(data, actions) => {
              return actions.order.capture().then((details) => {
                if (details.status == "COMPLETED") {
                  const data = {
                    status: 'success',
                    paymentId: details?.purchase_units[0]?.payments?.captures[0]?.id,
                    metadata: details,
                    mode: 'paypal',
                  };

                  onSuccessfulPayment(data);
                } else {
                  AlertMsg("")
                }
              });
            }}
          />
        </PayPalScriptProvider>
      )}
    </div>
  );
};

export default PaypalPaymentGeneric;
