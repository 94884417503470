import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    list: {
      width: 900,
      padding: 30
    },
    fullList: {
      width: 'auto',
    },
    btnNewApp: {
      backgroundColor: "#003265",
      color: '#fff',
      // marginLeft: "50px",
      '&:hover': {
        backgroundColor: '#003265',
      },
    },
    'MuiDrawer-paperAnchorRight': {
      padding: '12px'
    },
    textColor: {
      color: "#003265",
      marginLeft: '10px',
      fontSize: '17px',
      fontWeight: 500,
    },
    topHeader: {
      marginTop: 30,
      marginBottom: 30
    },
    checkedInBtn: {
      borderRadius: '15px',
        width: '100px',
        padding: '4px',
        boxShadow: 'none',
        textTransform: 'capitalize',
      backgroundColor: '#00b300',
      '&:hover': {
          backgroundColor: '#00b300',
        },
    }, 
    seenBtn: {
      borderRadius: '15px',
        width: '100px',
        padding: '4px',
        boxShadow: 'none',
        textTransform: 'capitalize',
      backgroundColor: '#FFAB0B',
      '&:hover': {
          backgroundColor: '#FFAB0B',
        },
    }, 
    labelText: {
      color: "#003265",
      fontSize: '15px',
      fontWeight: 500,
    },
    formControl: {
      width: '100%',
      marginTop: 10
    },
    sortSelect: {
      padding: 11
    },
    btnViewPatientRecord: {
      backgroundColor: "#003265",
      color: '#fff',
      padding: '10px',
      marginTop: '15px',
      marginBottom: 15,
      fontWeight: 400,
      margin: '0 auto',
      textTransform: 'capitalize',
      width: '90%',
      '&:hover': {
        backgroundColor: '#003265',
      },
    },
    textDetail: {
      fontSize: 15
    },
    styleContentBottom: {
      marginBottom: '10px'
    },
    assesmentType: {
      backgroundColor: '#f4f4f4',
      // marginTop: 30,
      marginBottom: 30,
      padding: 10
    },
    rootCard: {
      backgroundColor: "#f0ede6"
    },
    marksText: {
      color: '#304659',
      fontWeight: 600,
      fontSize: 15,
  
    },
    scoreText: {
      fontSize: 12,
      color: '#70707080',
    },
    inSession: {
      color: '#003265',
      fontWeight: 600,
      marginTop: 25
    },
    borderBottomCustom: {
      border: '1px solid #7070701a',
      marginTop: 10,
      marginBottom: 10
    },
    CheckIconCustom: {
      color: '#00bc07',
      border: '2px solid #00bc07',
      borderRadius: '50px',
      marginLeft: 5
    },
    sliderContainer: {
      height: '15px',
      backgroundImage:
        "linear-gradient(90deg, #26a4ba 0%, #85c169 25%, #cbcb2f 50%, #d0aa21 75%, #da7910 100%)",
    },
    sliderPointContainer2: {
      position: "absolute",
      marginTop: '-18px',
    },
    sliderPointText: {
      fontSize: "12px",
      color: "#ffffff",
      fontFamily: "Lato",
      fontWeight: 700,
      textAlign: "center",
      marginTop: '-29px',
      marginLeft: '-6px',
  
    },
    sliderPointLine: {
      height: '16px',
      marginLeft: "1px",
    },
    sliderPoint: {
      width: '18px',
      marginLeft: '-7px',
      // },
    },
    root1: {
      //   width: "100%",
      paddingTop: "47px",
      paddingBottom: "47px",
      paddingLeft: "42px",
      paddingRight: "42px",
      backgroundColor: "#ffffff",
      marginBottom: "24px",
      // backgroundColor: "rgba(192,208,222,18%)",
    },
    nextAvailable: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between"
    },
    headerContainer: {
      marginBottom: "20px",
      height: "38px",
      display: "flex",
      flexDirection: "row",
    },
    headerSubContainer: {
      marginLeft: "10px",
      display: "inline",
    },
    bodyHeaderContainer: {
      marginBottom: "18px",
    },
    bodyImageContainer: {
      position: "absolute",
      right: "92px",
    },
    line: {
      border: "1px solid rgba(112,112,112,50%)",
      marginBottom: "34px",
      marginTop: "40px",
    },
    timeContainer: {
      marginBottom: "12px",
      width: "137px",
      height: "36px",
      backgroundColor: "#ffffff",
      border: "1px solid #304659",
      fontSize: "18px",
      color: "#304659",
      fontWeight: 400,
      fontFamily: "Lato",
      marginRight: "20px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#304659",
        color: "#ffffff",
      },
    },
    confAppRoot: {
      height: "300px",
      position: "absolute",
      zIndex: 1,
      width: "calc(100% - 50px)",
      backgroundColor: "rgba(0,50,101,80%)",
    },
    confAppbody: {
      width: "370px",
      height: "42px",
      borderRadius: "21px",
      backgroundColor: "#ffffff",
      fontSize: "18px",
      color: "#003265",
      fontWeight: 700,
      fontFamily: "Lato",
      textAlign: "center",
      cursor: "pointer",
    },
    appointmentCancelButton: {
      backgroundColor: "#fff",
      // zIndex: 3,
      width: "30px",
      height: "30px",
      borderRadius: "50px",
      position: "absolute",
      right: "5px",
      top: "5px",
      // marginTop: "5px",
      cursor: "pointer",
      color: "red",
      fontFamily: "Lato",
      fontWeight: 700,
      fontSize: "17px",
    },
    timeListContainer: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: "15px",
      textAlign: "left"
    },
    alternativeTextContainer: {
      display: "flex",
      marginTop: "30px",
      // [theme.breakpoints.up('xs')]: {
      //   marginTop: '10px',
      // },
      // [theme.breakpoints.up('sm')]: {
      //   marginTop: '30px',
      // },
      // [theme.breakpoints.up('md')]: {
      //   marginTop: '30px',
      // },
    },
    availablityCostContainerMobile: {
      marginTop: "20px",
      [theme.breakpoints.up("xs")]: {
        display: "inline",
        // flexDirection : 'row-reverse',
      },
      [theme.breakpoints.up("sm")]: {
        display: "inline-block",
      },
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  }));