/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import {
  CssBaseline,
  Grid,
  Typography,
  ButtonGroup,
  Button,
  withStyles,

} from '@material-ui/core';
import MessageIcon from '@material-ui/icons/Message';
import ShareIcon from '@material-ui/icons/Share';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import SidebarMenu from '../../components/Sidebar_Menu';
import { getByIdClientAction } from '../../utils/actions/client.action';
import styles from './SingleClientHeaderStyle';

class SingleClientHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientData: {},
    };
  }

  componentDidMount() {
    this.props.getByIdClientAction(this.props.props.match.params.id);
  }

  componentDidUpdate(preProps) {
    const { getClientData } = this.props;
    if (getClientData !== preProps.getClientData) {
      if (getClientData.getByIdClientData) {
        this.setState({ clientData: getClientData.getByIdClientData });
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { clientData } = this.state;
    return (
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Typography className={classes.namesty}>{clientData.name}</Typography>
        </Grid>
        <Grid item xs={8} className={classes.buttonGrid}>
          <ButtonGroup aria-label="outlined primary button group">
            <Button startIcon={<ShareIcon />} className={classes.buttongroup}>
              Share
            </Button>
            <Button className={classes.buttongroup}>Upload</Button>
          </ButtonGroup>
          <Button
            variant="outlined"
            className={classes.messagebtn}
            startIcon={<MessageIcon />}
          >
            Message
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const connected = connect(
  (state) => ({
    getClientData: state.getByIdClient,
  }),
  {
    getByIdClientAction,
  },
)(SingleClientHeader);

const formed = reduxForm({
  form: 'getClient',
})(connected);

export default withStyles(styles)(formed);
