import { put, takeEvery } from 'redux-saga/effects';
import { post, get, updatePatch } from '../axios';
import {
  CREATE_BUNDLE_REQUEST,
  CREATE_BUNDLE_FAILED,
  CREATE_BUNDLE_SUCCESS,
  GET_BUNDLES_REQUEST,
  GET_BUNDLES_FAILED,
  GET_BUNDLES_SUCCESS,
  UPDATE_BUNDLE_REQUEST,
  UPDATE_BUNDLE_FAILED,
  UPDATE_BUNDLE_SUCCESS,
  CANCEL_BUNDLE_REQUEST,
  CANCEL_BUNDLE_FAILED,
  CANCEL_BUNDLE_SUCCESS,
  GET_CLIENT_BUNDLES_REQUEST,
  GET_CLIENT_BUNDLES_FAILED,
  GET_CLIENT_BUNDLES_SUCCESS,
} from '../constant';

function* modifyBundles(action) {
  try {
    const { endpoint, body } = action;
    const response = yield updatePatch(endpoint, body);
    yield put({ type: UPDATE_BUNDLE_SUCCESS, response });
  } catch (error) {
    yield put({ type: UPDATE_BUNDLE_FAILED, error });
  }
}

function* addBundle(action) {
  try {
    const { endpoint, body } = action;
    const response = yield post(endpoint, body);
    console.log('response', response);
    yield put({ type: CREATE_BUNDLE_SUCCESS, response });
  } catch (error) {
    yield put({ type: CREATE_BUNDLE_FAILED, error });
  }
}

function* getBundles(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_BUNDLES_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_BUNDLES_FAILED, error });
  }
}

function* getClientBundles(action) {
  try {
    const { endpoint } = action;
    console.log('endpoint', endpoint);
    const response = yield get(endpoint);
    yield put({ type: GET_CLIENT_BUNDLES_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_CLIENT_BUNDLES_FAILED, error });
  }
}

function* cancellingBundle(action) {
  try {
    const { endpoint, body } = action;
    const response = yield updatePatch(endpoint, body);
    yield put({ type: CANCEL_BUNDLE_SUCCESS, response });
  } catch (error) {
    yield put({ type: CANCEL_BUNDLE_FAILED, error });
  }
}

export function* bundleSaga() {
  yield takeEvery(CREATE_BUNDLE_REQUEST, addBundle);
  yield takeEvery(GET_BUNDLES_REQUEST, getBundles);
  yield takeEvery(UPDATE_BUNDLE_REQUEST, modifyBundles);
  yield takeEvery(CANCEL_BUNDLE_REQUEST, cancellingBundle);
  yield takeEvery(GET_CLIENT_BUNDLES_REQUEST, getClientBundles);
}
