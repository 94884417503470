/* eslint-disable camelcase */
export const SIGNUP_USER_REQUEST = 'SIGNUP_USER_REQUEST';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILED = 'SIGNUP_USER_FAILED';

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';

export const ADD_CLIENT_REQUEST = 'ADD_CLIENT_REQUEST';
export const ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS';
export const ADD_CLIENT_FAILED = 'ADD_CLIENT_FAILED';

export const GET_CLIENT_REQUEST = 'GET_CLIENT_REQUEST';
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAILED = 'GET_CLIENT_FAILED';

export const GET_BY_ID_CLIENT_REQUEST = 'GET_BY_ID_CLIENT_REQUEST';
export const GET_BY_ID_CLIENT_SUCCESS = 'GET_BY_ID_CLIENT_SUCCESS';
export const GET_BY_ID_CLIENT_FAILED = 'GET_BY_ID_CLIENT_FAILED';

export const UPDATE_CLIENT_REQUEST = 'UPDATE_CLIENT_REQUEST';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAILED = 'UPDATE_CLIENT_FAILED';

export const DELETE_CLIENT_REQUEST = 'DELETE_CLIENT_REQUEST';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAILED = 'DELETE_CLIENT_FAILED';

export const ADD_CLIENT_NOTE_REQUEST = 'ADD_CLIENT_NOTE_REQUEST';
export const ADD_CLIENT_NOTE_SUCCESS = 'ADD_CLIENT_NOTE_SUCCESS';
export const ADD_CLIENT_NOTE_FAILED = 'ADD_CLIENT_NOTE_FAILED';

export const ADD_APPOINTMENT_REQUEST = 'ADD_APPOINTMENT_REQUEST';
export const ADD_APPOINTMENT_SUCCESS = 'ADD_APPOINTMENT_SUCCESS';
export const ADD_APPOINTMENT_FAILED = 'ADD_APPOINTMENT_FAILED';

export const GET_APPOINTMENT_REQUEST = 'GET_APPOINTMENT_REQUEST';
export const GET_APPOINTMENT_SUCCESS = 'GET_APPOINTMENT_SUCCESS';
export const GET_APPOINTMENT_FAILED = 'GET_APPOINTMENT_FAILED';

export const GET_BY_ID_APPOINTMENT_REQUEST = 'GET_BY_ID_APPOINTMENT_REQUEST';
export const GET_BY_ID_APPOINTMENT_SUCCESS = 'GET_BY_ID_APPOINTMENT_SUCCESS';
export const GET_BY_ID_APPOINTMENT_FAILED = 'GET_BY_ID_APPOINTMENT_FAILED';

export const UPDATE_APPOINTMENT_REQUEST = 'UPDATE_APPOINTMENT_REQUEST';
export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS';
export const UPDATE_APPOINTMENT_FAILED = 'UPDATE_APPOINTMENT_FAILED';

export const DELETE_APPOINTMENT_REQUEST = 'DELETE_APPOINTMENT_REQUEST';
export const DELETE_APPOINTMENT_SUCCESS = 'DELETE_APPOINTMENT_SUCCESS';
export const DELETE_APPOINTMENT_FAILED = 'DELETE_APPOINTMENT_FAILED';

export const GET_SERVICE_REQUEST_USER = 'GET_SERVICE_REQUEST_USER';
export const GET_SERVICE_SUCCESS_USER = 'GET_SERVICE_SUCCESS_USER';
export const GET_SERVICE_FAILED_USER = 'GET_SERVICE_FAILED_USER';

export const GET_APPOINTMENT_BY_CLIENT_REQUEST =
  'GET_APPOINTMENT_BY_CLIENT_REQUEST';
export const GET_APPOINTMENT_BY_CLIENT_SUCCESS =
  'GET_APPOINTMENT_BY_CLIENT_SUCCESS';
export const GET_APPOINTMENT_BY_CLIENT_FAILED =
  'GET_APPOINTMENT_BY_CLIENT_FAILED';

export const ADD_SERVICE_REQUEST = 'ADD_SERVICE_REQUEST';
export const ADD_SERVICE_SUCCESS = 'ADD_SERVICE_SUCCESS';
export const ADD_SERVICE_FAILED = 'ADD_SERVICE_FAILED';

export const GET_SERVICE_REQUEST = 'GET_SERVICE_REQUEST';
export const GET_SERVICE_SUCCESS = 'GET_SERVICE_SUCCESS';
export const GET_SERVICE_FAILED = 'GET_SERVICE_FAILED';

export const GET_BY_ID_SERVICE_REQUEST = 'GET_BY_ID_SERVICE_REQUEST';
export const GET_BY_ID_SERVICE_SUCCESS = 'GET_BY_ID_SERVICE_SUCCESS';
export const GET_BY_ID_SERVICE_FAILED = 'GET_BY_ID_SERVICE_FAILED';

export const UPDATE_SERVICE_REQUEST = 'UPDATE_SERVICE_REQUEST';
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';
export const UPDATE_SERVICE_FAILED = 'UPDATE_SERVICE_FAILED';

export const DELETE_SERVICE_REQUEST = 'DELETE_SERVICE_REQUEST';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAILED = 'DELETE_SERVICE_FAILED';

export const GET_ALL_BILLING_DATA_REQUEST = 'GET_ALL_BILLING_DATA_REQUEST';
export const GET_ALL_BILLING_DATA_SUCCESS = 'GET_ALL_BILLING_DATA_SUCCESS';
export const GET_ALL_BILLING_DATA_FAILED = 'GET_ALL_BILLING_DATA_FAILED';

export const GET_ALL_INVOICE_BY_CLIENT_ID_REQUEST =
  'GET_ALL_INVOICE_BY_CLIENT_ID_REQUEST';
export const GET_ALL_INVOICE_BY_CLIENT_ID_SUCCESS =
  'GET_ALL_INVOICE_BY_CLIENT_ID_SUCCESS';
export const GET_ALL_INVOICE_BY_CLIENT_ID_FAILED =
  'GET_ALL_INVOICE_BY_CLIENT_ID_FAILED';

export const GET_SINGLE_INVOICE_REQUEST = 'GET_SINGLE_INVOICE_REQUEST';
export const GET_SINGLE_INVOICE_SUCCESS = 'GET_SINGLE_INVOICE_SUCCESS';
export const GET_SINGLE_INVOICE_FAILED = 'GET_SINGLE_INVOICE_FAILED';
export const CLEAR_SINGLE_INVOICE = 'CLEAR_SINGLE_INVOICE';

export const CLINICAL_SIGNUP_REQUEST = 'CLINICAL_SIGNUP_REQUEST';
export const CLINICAL_SIGNUP_SUCCESS = 'CLINICAL_SIGNUP_SUCCESS';
export const CLINICAL_SIGNUP_FAILED = 'CLINICAL_SIGNUP_FAILED';

export const USER_RCAD_SUBMIT_REQUEST = 'USER_RCAD_SUBMIT_REQUEST';
export const USER_RCAD_SUBMIT_SUCCESS = 'USER_RCAD_SUBMIT_SUCCESS';
export const USER_RCAD_SUBMIT_FAILED = 'USER_RCAD_SUBMIT_FAILED';

export const CLIENT_MAIL_REQUEST = 'CLIENT_MAIL_REQUEST';

export const GET_CLINICIAN_APPOINTMENT_REQUEST =
  'GET_CLINICIAN_APPOINTMENT_REQUEST';
export const GET_CLINICIAN_APPOINTMENT_SUCCESS =
  'GET_CLINICIAN_APPOINTMENT_SUCCESS';
export const GET_CLINICIAN_APPOINTMENT_FAILED =
  'GET_CLINICIAN_APPOINTMENT_FAILED';
export const CLIENT_MAIL_SUCCESS = 'CLIENT_MAIL_SUCCESS';
export const CLIENT_MAIL_FAILED = 'CLIENT_MAIL_FAILED';

export const GET_CLINICIAN_REQUEST = 'GET_CLINICIAN_REQUEST';
export const GET_CLINICIAN_SUCCESS = 'GET_CLINICIAN_SUCCESS';
export const GET_CLINICIAN_FAILED = 'GET_CLINICIAN_FAILED';

export const GET_BY_ID_CLINICIAN_REQUEST = 'GET_BY_ID_CLINICIAN_REQUEST';
export const GET_BY_ID_CLINICIAN_SUCCESS = 'GET_BY_ID_CLINICIAN_SUCCESS';
export const GET_BY_ID_CLINICIAN_FAILED = 'GET_BY_ID_CLINICIAN_FAILED';

export const GET_PAYMENT_URL_REQUEST = 'GET_PAYMENT_URL_REQUEST';
export const GET_PAYMENT_URL_SUCCESS = 'GET_PAYMENT_URL_SUCCESS';
export const GET_PAYMENT_URL_FAILED = 'GET_PAYMENT_URL_FAILED';

export const GET_PAYMENT_RES_REQUEST = 'GET_PAYMENT_RES_REQUEST';
export const GET_PAYMENT_RES_SUCCESS = 'GET_PAYMENT_RES_SUCCESS';
export const GET_PAYMENT_RES_FAILED = 'GET_PAYMENT_RES_FAILED';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_SIGNUP = 'USER_SIGNUP';

export const GET_APPOINTMENT_DETAIL_REQUEST = 'GET_APPOINTMENT_DETAIL_REQUEST';
export const GET_APPOINTMENT_DETAIL_SUCCESS = 'GET_APPOINTMENT_DETAIL_SUCCESS';
export const GET_APPOINTMENT_DETAIL_FAILED = 'GET_APPOINTMENT_DETAIL_FAILED';

export const PAYPAL_REQUEST = 'PAYPAL_REQUEST';
export const PAYPAL_SUCCESS = 'PAYPAL_SUCCESS';
export const PAYPAL_FAILED = 'PAYPAL_FAILED';

export const eCHECK_REQUEST = 'eCHECK_REQUEST';
export const eCHECK_SUCCESS = 'eCHECK_SUCCESS';
export const eCHECK_FAILED = 'eCHECK_FAILED';

export const GET_UNPAID_APPOINTMENT_REQUEST = 'GET_UNPAID_APPOINTMENT_REQUEST';
export const GET_UNPAID_APPOINTMENT_SUCCESS = 'GET_UNPAID_APPOINTMENT_SUCCESS';
export const GET_UNPAID_APPOINTMENT_FAILED = 'GET_UNPAID_APPOINTMENT_FAILED';

export const GET_UPCOMING_APPOINTMENT_REQUEST =
  'GET_UPCOMING_APPOINTMENT_REQUEST';
export const GET_UPCOMING_APPOINTMENT_SUCCESS =
  'GET_UPCOMING_APPOINTMENT_SUCCESS';
export const GET_UPCOMING_APPOINTMENT_FAILED =
  'GET_UPCOMING_APPOINTMENT_FAILED';

export const UPDATE_CLIENT_STATUS_REQUEST = 'UPDATE_CLIENT_STATUS_REQUEST';
export const UPDATE_CLIENT_STATUS_SUCCESS = 'UPDATE_CLIENT_STATUS_SUCCESS';
export const UPDATE_CLIENT_STATUS_FAILED = 'UPDATE_CLIENT_STATUS_FAILED';

export const GET_PREVIOUS_APPOINTMENT_REQUEST =
  'GET_PREVIOUS_APPOINTMENT_REQUEST';
export const GET_PREVIOUS_APPOINTMENT_SUCCESS =
  'GET_PREVIOUS_APPOINTMENT_SUCCESS';
export const GET_PREVIOUS_APPOINTMENT_FAILED =
  'GET_PREVIOUS_APPOINTMENT_FAILED';

export const GET_USER_NOTIFICATION_REQUEST = 'GET_USER_NOTIFICATION_REQUEST';
export const GET_USER_NOTIFICATION_SUCCESS = 'GET_USER_NOTIFICATION_SUCCESS';
export const GET_USER_NOTIFICATION_FAILED = 'GET_USER_NOTIFICATION_FAILED';

export const UPDATE_USER_NOTIFICATION_REQUEST =
  'UPDATE_USER_NOTIFICATION_REQUEST';
export const UPDATE_USER_NOTIFICATION_SUCCESS =
  'UPDATE_USER_NOTIFICATION_SUCCESS';
export const UPDATE_USER_NOTIFICATION_FAILED =
  'UPDATE_USER_NOTIFICATION_FAILED';

export const ADD_CHILD_REQ = 'ADD_CHILD_REQ';
export const ADD_CHILD_SUCCESS = 'ADD_CHILD_SUCCESS';
export const ADD_CHILD_FAILED = 'ADD_CHILD_FAILED';

export const ADD_ADULT_REQ = 'ADD_ADULT_REQ';
export const ADD_ADULT_SUCCESS = 'ADD_ADULT_SUCCESS';
export const ADD_ADULT_FAILED = 'ADD_ADULT_FAILED';

export const GET_CHILD_REQ = 'GET_CHILD_REQ';
export const GET_CHILD_SUCCESS = 'GET_CHILD_SUCCESS';
export const GET_CHILD_FAILED = 'GET_CHILD_FAILED';

export const GET_CHILD_BY_ID_REQ = 'GET_CHILD_BY_ID_REQ';
export const GET_CHILD_BY_ID_SUCCESS = 'GET_CHILD_BY_ID_SUCCESS';
export const GET_CHILD_BY_ID_FAILED = 'GET_CHILD_BY_ID_FAILED';

export const GET_UNPAID_PAYMENT_REQUEST = 'GET_UNPAID_PAYMENT_REQUEST';
export const GET_UNPAID_PAYMENT_SUCCESS = 'GET_UNPAID_PAYMENT_SUCCESS';
export const GET_UNPAID_PAYMENT_FAILED = 'GET_UNPAID_PAYMENT_FAILED';

export const GET_PAYMENT_REQUEST = 'GET_PAYMENT_REQUEST';
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS';
export const GET_PAYMENT_FAILED = 'GET_PAYMENT_FAILED';

export const UPDATE_CHILD_REQ = 'UPDATE_CHILD_REQ';
export const UPDATE_CHILD_SUCCESS = 'UPDATE_CHILD_SUCCESS';
export const UPDATE_CHILD_FAILED = 'UPDATE_CHILD_FAILED';

export const GET_ALL_EVENTS_REQUEST = 'GET_ALL_EVENTS_REQUEST';
export const GET_ALL_EVENTS_SUCCESS = 'GET_ALL_EVENTS_SUCCESS';
export const GET_ALL_EVENTS_FAILED = 'GET_ALL_EVENTS_FAILED';

export const GET_ALL_EVENTS_BY_ID_REQUEST = 'GET_ALL_EVENTS_BY_ID_REQUEST';
export const GET_ALL_EVENTS_BY_ID_SUCCESS = 'GET_ALL_EVENTS_BY_ID_SUCCESS';
export const GET_ALL_EVENTS_BY_ID_FAILED = 'GET_ALL_EVENTS_BY_ID_FAILED';

export const GET_ALL_PRESCRIPTIONS_REQUEST = 'GET_ALL_PRESCRIPTIONS_REQUEST';
export const GET_ALL_PRESCRIPTIONS_SUCCESS = 'GET_ALL_PRESCRIPTIONS_SUCCESS';
export const GET_ALL_PRESCRIPTIONS_FAILED = 'GET_ALL_PRESCRIPTIONS_FAILED';

export const GET_ALL_PRESCRIPTIONS_BY_ID_REQUEST =
  'GET_ALL_PRESCRIPTIONS_BY_ID_REQUEST';
export const GET_ALL_PRESCRIPTIONS_BY_ID_SUCCESS =
  'GET_ALL_PRESCRIPTIONS_BY_ID_SUCCESS';
export const GET_ALL_PRESCRIPTIONS_BY_ID_FAILED =
  'GET_ALL_PRESCRIPTIONS_BY_ID_FAILED';

export const UPLOAD_CLIENT_IMAGE_REQUEST = 'UPLOAD_CLIENT_IMAGE_REQUEST';
export const UPLOAD_CLIENT_IMAGE_SUCCESS = 'UPLOAD_CLIENT_IMAGE_SUCCESS';
export const UPLOAD_CLIENT_IMAGE_FAILED = 'UPLOAD_CLIENT_IMAGE_FAILED';

export const GET_EMPLOYER_REQUEST = 'GET_EMPLOYER_REQUEST';
export const GET_EMPLOYER_SUCCESS = 'GET_EMPLOYER_SUCCESS';
export const GET_EMPLOYER_FAILED = 'GET_EMPLOYER_FAILED';

export const UPDATE_PHARMACY_REQUEST = 'UPDATE_PHARMACY_REQUEST';
export const UPDATE_PHARMACY_SUCCESS = 'UPDATE_PHARMACY_SUCCESS';
export const UPDATE_PHARMACY_FAILED = 'UPDATE_PHARMACY_FAILED';

export const GET_PHARMACY_REQUEST = 'GET_PHARMACY_REQUEST';
export const GET_PHARMACY_SUCCESS = 'GET_PHARMACY_SUCCESS';
export const GET_PHARMACY_FAILED = 'GET_PHARMACY_FAILED';

export const SET_EMPLOYER_ID_REQUEST = 'SET_EMPLOYER_ID_REQUEST';
export const SET_EMPLOYER_ID_SUCCESS = 'SET_EMPLOYER_ID_SUCCESS';
export const SET_EMPLOYER_ID_FAILED = 'SET_EMPLOYER_ID_FAILED';

export const UPLOAD_DOC_FILE_REQUEST = 'UPLOAD_DOC_FILE_REQUEST';
export const UPLOAD_DOC_FILE_SUCCESS = 'UPLOAD_DOC_FILE_SUCCESS';
export const UPLOAD_DOC_FILE_FAILED = 'UPLOAD_DOC_FILE_FAILED';

export const GET_CLIENT_DATA_REQUEST = 'GET_CLIENT_DATA_REQUEST';
export const GET_CLIENT_DATA_SUCCESS = 'GET_CLIENT_DATA_SUCCESS';
export const GET_CLIENT_DATA_FAILED = 'GET_CLIENT_DATA_FAILED';

export const GET_ADDRESS_REQUEST = 'GET_ADDRESS_REQUEST';
export const GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS';
export const GET_ADDRESS_FAILED = 'GET_ADDRESS_FAILED';

export const ADD_ADDRESS_REQUEST = 'ADD_ADDRESS_REQUEST';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_FAILED = 'ADD_ADDRESS_FAILED';

export const UPDATE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_FAILED = 'UPDATE_ADDRESS_FAILED';

export const SEND_SMS_REQUEST = 'SEND_SMS_REQUEST';
export const SEND_SMS_SUCCESS = 'SEND_SMS_SUCCESS';
export const SEND_SMS_FAILED = 'SEND_SMS_FAILED';

export const FILTERED_APPOINTMENTS_REQUEST = 'FILTERED_APPOINTMENTS_REQUEST';
export const FILTERED_APPOINTMENTS_SUCCESS = 'FILTERED_APPOINTMENTS_SUCCESS';
export const FILTERED_APPOINTMENTS_FAILED = 'FILTERED_APPOINTMENTS_FAILED';

export const SEEN_STATUS_REQUEST = 'SEEN_STATUS_REQUEST';
export const SEEN_STATUS_SUCCESS = 'SEEN_STATUS_SUCCESS';
export const SEEN_STATUS_FAILED = 'SEEN_STATUS_FAILED';

export const CREATE_COMPANY_REQUEST = 'CREATE_COMPANY_REQUEST';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAILED = 'CREATE_COMPANY_FAILED';

export const GET_ALL_COMPANIES_REQUEST = 'GET_ALL_COMPANIES_REQUEST';
export const GET_ALL_COMPANIES_SUCCESS = 'GET_ALL_COMPANIES_SUCCESS';
export const GET_ALL_COMPANIES_FAILED = 'GET_ALL_COMPANIES_FAILED';

export const GET_COMPANIES_WITH_ASSOCIATIONS_REQUEST =
  'GET_COMPANIES_WITH_ASSOCIATIONS_REQUEST';
export const GET_COMPANIES_WITH_ASSOCIATIONS_SUCCESS =
  'GET_COMPANIES_WITH_ASSOCIATIONS_SUCCESS';
export const GET_COMPANIES_WITH_ASSOCIATIONS_FAILED =
  'GET_COMPANIES_WITH_ASSOCIATIONS_FAILED';

export const GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAILED = 'GET_COMPANY_FAILED';

export const GET_BY_IDS_CLINICIANS_REQUEST = 'GET_BY_IDS_CLINICIANS_REQUEST';
export const GET_BY_IDS_CLINICIANS_SUCCESS = 'GET_BY_IDS_CLINICIANS_SUCCESS';
export const GET_BY_IDS_CLINICIANS_FAILED = 'GET_BY_IDS_CLINICIANS_FAILED';

export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAILED = 'UPDATE_COMPANY_FAILED';

export const GET_ALL_BILLING_FOR_ADMIN_REQUEST =
  'GET_ALL_BILLING_FOR_ADMIN_REQUEST';
export const GET_ALL_BILLING_FOR_ADMIN_SUCCESS =
  'GET_ALL_BILLING_FOR_ADMIN_SUCCESS';
export const GET_ALL_BILLING_FOR_ADMIN_FAILED =
  'GET_ALL_BILLING_FOR_ADMIN_FAILED';

export const GET_CLIENTS_REQUEST = 'GET_CLIENTS_REQUEST';
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_FAILED = 'GET_CLIENTS_FAILED';

export const GET_CLIENTS_REPORT_REQUEST = 'GET_CLIENTS_REPORT_REQUEST';
export const GET_CLIENTS_REPORT_SUCCESS = 'GET_CLIENTS_REPORT_SUCCESS';
export const GET_CLIENTS_REPORT_FAILED = 'GET_CLIENTS_REPORT_FAILED';

export const CREATE_BUNDLE_REQUEST = 'CREATE_BUNDLE_REQUEST';
export const CREATE_BUNDLE_FAILED = 'CREATE_BUNDLE_FAILED';
export const CREATE_BUNDLE_SUCCESS = 'CREATE_BUNDLE_SUCCESS';

export const GET_BUNDLES_REQUEST = 'GET_BUNDLES_REQUEST';
export const GET_BUNDLES_FAILED = 'GET_BUNDLES_FAILED';
export const GET_BUNDLES_SUCCESS = 'GET_BUNDLES_SUCCESS';

export const GET_CLIENT_BUNDLES_REQUEST = 'GET_CLIENT_BUNDLES_REQUEST';
export const GET_CLIENT_BUNDLES_FAILED = 'GET_CLIENT_BUNDLES_FAILED';
export const GET_CLIENT_BUNDLES_SUCCESS = 'GET_CLIENT_BUNDLES_SUCCESS';

export const UPDATE_BUNDLE_REQUEST = 'UPDATE_BUNDLE_REQUEST';
export const UPDATE_BUNDLE_FAILED = 'UPDATE_BUNDLE_FAILED';
export const UPDATE_BUNDLE_SUCCESS = 'UPDATE_BUNDLE_SUCCESS';

export const CANCEL_BUNDLE_REQUEST = 'CANCEL_BUNDLE_REQUEST';
export const CANCEL_BUNDLE_FAILED = 'CANCEL_BUNDLE_FAILED';
export const CANCEL_BUNDLE_SUCCESS = 'CANCEL_BUNDLE_SUCCESS';

export const GET_CLIENT_EVENTS_REQUEST = 'GET_CLIENT_EVENTS_REQUEST';
export const GET_CLIENT_EVENTS_FAILED = 'GET_CLIENT_EVENTS_FAILED';
export const GET_CLIENT_EVENTS_SUCCESS = 'GET_CLIENT_EVENTS_SUCCESS';

export const SAVE_CLIENT_SIGNATURE_REQUEST = 'SAVE_CLIENT_SIGNATURE_REQUEST';
export const SAVE_CLIENT_SIGNATURE_SUCCESS = 'SAVE_CLIENT_SIGNATURE_SUCCESS';
export const SAVE_CLIENT_SIGNATURE_FAILED = 'SAVE_CLIENT_SIGNATURE_FAILED';

export const GET_CONSENT_INFO_REQUEST = 'GET_CONSENT_INFO_REQUEST';
export const GET_CONSENT_INFO_SUCCESS = 'GET_CONSENT_INFO_SUCCESS';
export const GET_CONSENT_INFO_FAILED = 'GET_CONSENT_INFO_FAILED';

export const SIGN_CONSENT_REQUEST = 'SIGN_CONSENT_REQUEST';
export const SIGN_CONSENT_SUCCESS = 'SIGN_CONSENT_SUCCESS';
export const SIGN_CONSENT_FAILED = 'SIGN_CONSENT_FAILED';

export const GET_BUNDLE_APPOINTMENT_REQUEST = 'GET_BUNDLE_APPOINTMENT_REQUEST';
export const GET_BUNDLE_APPOINTMENT_SUCCESS = 'GET_BUNDLE_APPOINTMENT_SUCCESS';
export const GET_BUNDLE_APPOINTMENT_FAILED = 'GET_BUNDLE_APPOINTMENT_FAILED';

export const GET_CONSENT_FORMS_REQUEST = 'GET_CONSENT_FORMS_REQUEST';
export const GET_CONSENT_FORMS_SUCCESS = 'GET_CONSENT_FORMS_SUCCESS';
export const GET_CONSENT_FORMS_FAILED = 'GET_CONSENT_FORMS_FAILED';

export const SAVE_CLINICIAN_SIGNATURE_REQUEST =
  'SAVE_CLINICIAN_SIGNATURE_REQUEST';
export const SAVE_CLINICIAN_SIGNATURE_SUCCESS =
  'SAVE_CLINICIAN_SIGNATURE_SUCCESS';
export const SAVE_CLINICIAN_SIGNATURE_FAILED =
  'SAVE_CLINICIAN_SIGNATURE_FAILED';

export const CLINICIAN_SIGN_CONSENT_REQUEST = 'CLINICIAN_SIGN_CONSENT_REQUEST';
export const CLINICIAN_SIGN_CONSENT_SUCCESS = 'CLINICIAN_SIGN_CONSENT_SUCCESS';
export const CLINICIAN_SIGN_CONSENT_FAILED = 'CLINICIAN_SIGN_CONSENT_FAILED';

export const LOGGEDIN_CLINICIAN_INFO_REQUEST =
  'LOGGEDIN_CLINICIAN_INFO_REQUEST';
export const LOGGEDIN_CLINICIAN_INFO_SUCCESS =
  'LOGGEDIN_CLINICIAN_INFO_SUCCESS';
export const LOGGEDIN_CLINICIAN_INFO_FAILED = 'LOGGEDIN_CLINICIAN_INFO_FAILED';

export const CLIENT_CONSENT_FORMS_REQUEST = 'CLIENT_CONSENT_FORMS_REQUEST';
export const CLIENT_CONSENT_FORMS_SUCCESS = 'CLIENT_CONSENT_FORMS_SUCCESS';
export const CLIENT_CONSENT_FORMS_FAILED = 'CLIENT_CONSENT_FORMS_FAILED';

export const DOWNLOAD_CONSENT_REQUEST = 'DOWNLOAD_CONSENT_REQUEST';
export const DOWNLOAD_CONSENT_SUCCESS = 'DOWNLOAD_CONSENT_SUCCESS';
export const DOWNLOAD_CONSENT_FAILED = 'DOWNLOAD_CONSENT_FAILED';
export const DOWNLOAD_CONSENT_CLEAR = 'DOWNLOAD_CONSENT_CLEAR';

export const CANCEL_RSVP_REQUEST = 'CANCEL_RSVP_REQUEST';
export const CANCEL_RSVP_SUCCESS = 'CANCEL_RSVP_SUCCESS';
export const CANCEL_RSVP_FAILED = 'CANCEL_RSVP_FAILED';

export const APPOINTMENTS_OF_CLIENT_REQUEST = 'APPOINTMENTS_OF_CLIENT_REQUEST';
export const APPOINTMENTS_OF_CLIENT_SUCCESS = 'APPOINTMENTS_OF_CLIENT_SUCCESS';
export const APPOINTMENTS_OF_CLIENT_FAILED = 'APPOINTMENTS_OF_CLIENT_FAILED';

export const APPOINTMENTS_OF_CLIENT_REPORT_REQUEST =
  'APPOINTMENTS_OF_CLIENT_REPORT_REQUEST';
export const APPOINTMENTS_OF_CLIENT_REPORT_SUCCESS =
  'APPOINTMENTS_OF_CLIENT_REPORT_SUCCESS';
export const APPOINTMENTS_OF_CLIENT_REPORT_FAILED =
  'APPOINTMENTS_OF_CLIENT_REPORT_FAILED';

export const MARK_PAYMENT_AS_PAID_REQUEST = 'MARK_PAYMENT_AS_PAID_REQUEST';
export const MARK_PAYMENT_AS_PAID_SUCCESS = 'MARK_PAYMENT_AS_PAID_SUCCESS';
export const MARK_PAYMENT_AS_PAID_FAILED = 'MARK_PAYMENT_AS_PAID_FAILED';
export const MARK_PAYMENT_AS_PAID_CLEAR = 'MARK_PAYMENT_AS_PAID_CLEAR';

export const OTP_NOT_VERIFIED = 'OTP_NOT_VERIFIED';
export const CLOSE_OTP_MODAL = 'CLOSE_OTP_MODAL';

export const CLEAR_CREATED_APPOINTMENT = 'CLEAR_CREATED_APPOINTMENT';
export function getCurrentUrl(location) {
  return location.pathname.split(/[?#]/)[0];
}

export function checkIsActive(location, url) {
  const current = getCurrentUrl(location);
  if (!current || !url) {
    return false;
  }

  if (current === url) {
    return true;
  }

  if (current.indexOf(url) > -1) {
    return true;
  }

  return false;
}
