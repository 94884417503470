/* eslint-disable no-dupe-keys */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from "react";
import {

  withStyles,
  Typography,
  Paper,
  //   Button,
} from "@material-ui/core";
import styles from "./AppointmentRescheduleSuccessStyle";

class AppointmentRescheduleSuccess extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <div
        style={{
          backgroundColor: "#F8F8F8",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
        }}
      >
        <Paper className={classes.root}>
          <Typography className={classes.heading} style={{ color: "green" }}>
            Appointment Reschedule Successfully !
          </Typography>

          <Typography className={classes.subHeading}>
            We are delighted to inform you that your appointment is successfully
            Rescheduled...
          </Typography>

          <Typography
            // className={classes.heading}
            style={{
              color: "green",
              fontSize: "20px",
              color: "#003d79",
              lineHeight: "30px",
              fontWeight: 600,
            }}
          >
            Thank You !
          </Typography>
          {/* {this.state.status ? null : (
          // <Link to={this.props.history.goBack()}>
            <Button variant="contained" className={classes.button}>
              Back
            </Button>
            // </Link>
          )} */}
        </Paper>
      </div>
    );
  }
}


export default withStyles(styles)(AppointmentRescheduleSuccess);
