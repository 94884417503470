/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import useStyles from "./CustomButton.styles";

export const DrawerColorButtons = ({
  onPressButton,
  width,
  title,
  style,
  textStyles,
  ...props
}) => {
  const classes = styles();
  return (
    <Grid
      style={{ width }}
      className={[classes.nextButton, style]}
      onClick={() => onPressButton()}
      {...props}
    >
      <Typography className={[classes.buttonText, textStyles]}>
        {title}
      </Typography>
    </Grid>
  );
};

export const DrawerSimpleButtons = ({
  onPressButton,
  width,
  title,
  style,
  textStyles,
  ...props
}) => {
  const classes = styles();
  return (
    <Grid
      container
      component="div"
      alignItems="center"
      justify="center"
      style={{ width }}
      className={[classes.cancelButton, style]}
      //   style={{ display: 'flex' }}
      onClick={() => onPressButton()}
      {...props}
    >
      <Typography className={[classes.cancelButtonText, textStyles]}>
        {title}
      </Typography>
    </Grid>
  );
};

export const ClinicianButton = ({ style, text, ...props }) => {
  const classes = styles();
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={classes.clinicianButton}
      {...props}
    >
      {text}
    </Grid>
  );
};

DrawerColorButtons.defaultProps = {
  onPressButton: () => {},
  width: "100px",
  title: "Click",
};

DrawerSimpleButtons.defaultProps = {
  onPressButton: () => {},
  width: "100px",
  title: "Click",
};

export default { DrawerColorButtons };
