import React from 'react';

const OutPatientReport = ({ data }) => {
  // console.log(data);
  return (
    <table id='out-patients'>
      <tbody>
        <tr>
          <td style={{ width: '200px', backgroundColor: 'yellow' }}>
            Source Name:
          </td>
          <td>THE LIGHTHOUSE ARABIA.</td>
        </tr>
        <tr>
          <td>Report Name:</td>
          <td colSpan='6'>
            02 A - Out- Patients New Visits by Nationality, Sex, Age Groups &
            Diagnosis
          </td>
        </tr>
        <tr />
        <tr>
          <td>Data Write:</td>
          <td>Append to Previous Data</td>
        </tr>
        <tr>
          <td>Download Date:</td>
          <td>19/04/2021 13:16</td>
        </tr>
        <tr>
          <td>SR Id:</td>
          <td>99931</td>
        </tr>
        <tr>
          <td>Data Period:</td>
          <td> 2021/3</td>
        </tr>
        <tr>
          <td>Report Period: </td>
          <td>Monthly </td>
        </tr>
        <tr>
          <td>UAE-Expat 571:</td>
          <td>Emirati</td>
        </tr>
        <tr>
          <td>
            <table>
              <tbody>
                <tr>
                  <td></td>
                  <td colSpan='2'>Less than 1 Year</td>
                  <td colSpan='2'>{'1-4'}</td>
                  <td colSpan='2'>{'5-9'}</td>
                  <td colSpan='2'>{'10-14'}</td>
                  <td colSpan='2'>{'15-19'}</td>
                  <td colSpan='2'>{'20-24'}</td>
                  <td colSpan='2'>{'25-44'}</td>
                  <td colSpan='2'>{'45-64'}</td>
                  <td colSpan='2'>{'65+'}</td>
                </tr>
                <tr>
                  <td>Diagnosis</td>
                  <td>Male</td> <td>Female</td>
                  <td>Male</td> <td>Female</td>
                  <td>Male</td> <td>Female</td>
                  <td>Male</td> <td>Female</td>
                  <td>Male</td> <td>Female</td>
                  <td>Male</td> <td>Female</td>
                  <td>Male</td> <td>Female</td>
                  <td>Male</td> <td>Female</td>
                  <td>Male</td> <td>Female</td>
                </tr>
                {Object.keys(data).map((d) => (
                  <tr>
                    <td>{d}</td>
                    <td>{data[d]?.newBorn?.male}</td>
                    <td>{data[d]?.newBorn?.female}</td>
                    <td>{data[d]?.tween1?.male}</td>{' '}
                    <td>{data[d]?.tween1?.female}</td>
                    <td>{data[d]?.tween2?.male}</td>{' '}
                    <td>{data[d]?.tween2?.female}</td>
                    <td>{data[d]?.tween3?.male}</td>
                    <td>{data[d]?.tween3?.female}</td>
                    <td>{data[d]?.teen1?.male}</td>
                    <td>{data[d]?.teen1?.female}</td>
                    <td>{data[d]?.teen2?.male}</td>
                    <td>{data[d]?.teen2?.female}</td>
                    <td>{data[d]?.adult1?.male}</td>
                    <td>{data[d]?.adult1?.female}</td>
                    <td>{data[d]?.adult2?.male}</td>
                    <td>{data[d]?.adult2?.female}</td>
                    <td>{data[d]?.elderly?.male}</td>
                    <td>{data[d]?.elderly?.female}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default OutPatientReport;
