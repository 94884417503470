import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    root: {
      padding: "20px",
      marginLeft: 250,
      marginTop: 70,
    },
    flexStyle: {
      display: "flex",
      flexWrap: "wrap",
    },
    heading: {
      color: "#003d79",
      fontWeight: 550,
      padding: "2px",
      lineHeight: "25px",
      fontSize: "14px",
      fontFamily: ["Open Sans", "Helvetica", "Arial", "sans-serif"].join(","),
    },
    divider: {
      width: "98%",
      marginTop: "15px",
      marginBottom: "15px",
      marginRight: "15px",
      backgroundColor: "#a29061",
    },
    addServiceButton: {
      float: "left",
      padding: "2px",
      backgroundColor: "#003d79",
      color: "#fff",
      textTransform: "capitalize",
      marginTop: "10px",
      marginBottom: "10px",
    },
    gridContainer: {
      width: "70%",
    },
    gridText: {
      color: "#a29061",
      fontWeight: 550,
      textAlign: "left",
      fontSize: "13px",
      margin: "3px",
    },
    notchedOutline: {
      borderWidth: "1px",
      borderColor: "#1976d2 !important",
    },
    divstyle: {
      width: "100%",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "#cccccc",
      height: 37.5,
      borderRadius: 5,
      // marginTop: theme.spacing(1),
      marginLeft: 2,
      paddingLeft: 10,
      padding: 2,
      // paddingRight: theme.spacing(1),
    },
    serviceStyle: {
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: 580,
      color: "#003d79",
      margin: "0 0 5px",
    },
    flexServiceList: {
      display: "flex",
      flexDirection: "row",
    },
    serviceListHeaderText: {
      // float: 'left',
      color: "#003d79",
      fontSize: "13px",
      lineHeight: "18px",
      cursor: "pointer",
    },
    serviceListSubHeaderText: {
      // float: 'left',
      color: "#a29061",
      fontSize: "13px",
      lineHeight: "18px",
    },
    selectStyle: {},
  });