import { createStyles} from "@material-ui/core";

export default (theme) =>
  createStyles({
    // root: {
    //   flexGrow: 1,
    //   display: "flex",
    // },
    root: {
      padding: "30px",
      //   marginLeft: 250,
      //   marginTop: 70,
      //   display: "flex",
    },
    flexStyle: {
      display: "flex",
      flexWrap: "wrap",
    },
    calenderSty: {
      width: "100%",
      height: "calc(100vh - 10vh)",
      // marginTop: theme.spacing(10),
      // paddingLeft: theme.spacing(30),
      position: "relative",
      // right: 100,
      left: 0,
      top: 0,
      bottom: 0,
      // zIndex: "tooltip"
    },
  });