import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import list from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'; // needed for import "./styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../clinician.scss';
import Axios from 'axios';
import Switch from '@material-ui/core/Switch';
import { baseURL } from '../../utils/axios';
import AlertMsg from '../../utils/Alert';
import Loader from '../../components/Loader/Loader';
import moment from 'moment';
import { getTimezoneOffset, weekend } from '../../utils/timUtils';
import ReplicateView from '../../common/ReplicateView';
import { slotsWithoutBlock, getEventColor } from '../../common/calendar/slots';
import CalendarSlotSelectionView from '../../common/calendar/CalendarSlotSelectionView';

export default class FullCalendarComponent extends React.Component {
  calendarComponentRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      current_date: new Date(),
      calendarWeekends: true,
      calendarEvents: [],
      eventColor: 'green',
      events: slotsWithoutBlock,
      closed_update: false,
      closed: false,
      selectionInfo: {},
      event_id: '',
      dataLoader: true,
      newEvents: [],
      showDialog: false,
      seletedSlot: '',
      acceptNewP: localStorage.getItem('accecpt_new') == 'true',
      acceptCurrP: localStorage.getItem('accecpt_curr') == 'true',
      weeklyLimit: localStorage.getItem('weeklyLimit')
        ? localStorage.getItem('weeklyLimit')
        : 0,
      weeklyLimitForCurrentPatients: localStorage.getItem(
        'weeklyLimitForCurrentPatients'
      )
        ? localStorage.getItem('weeklyLimitForCurrentPatients')
        : 0,
      selectedSlot: null,
      slotSelectionDialogOpen: false,
    };
  }

  handleClickOpen = () => {
    this.setState({ showDialog: true });
  };

  handleClose = () => {
    this.setState({ showDialog: false });
  };

  componentDidMount() {
    const draggableEl = document.getElementById('external-events');
    new Draggable(draggableEl, {
      itemSelector: '.fc-event1',
      eventData(eventEl) {
        const title = eventEl.getAttribute('title');
        const id = eventEl.getAttribute('data');
        return {
          title,
          id,
          color: getEventColor(title),
          type: title,
          textColor: '#000',
        };
      },
    });
    this.getShedules();
  }

  getShedules = () => {
    const dated = moment().format('L');

    Axios.get(
      `${baseURL}/api/schedule${
        this.props.id
          ? `?id=${this.props.id}&timezoneOffset=${getTimezoneOffset()}`
          : `?timezoneOffset=${getTimezoneOffset()}`
      }`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        if (res.data && res.data.length) {
          const scheduleList = {};
          for (const item of res.data) {
            const start = new Date(item.startTime);
            const end = new Date(item.endTime);

            // console.log("item.type ======", item.type);
            const obj = {
              start,
              end,
              title: item.type,
              type: item.type,
              id: item._id,
              color: getEventColor(item.type),
              textColor:
                item.type === 'Appointment' || item.type === 'Blocked'
                  ? '#fff'
                  : '#000',
            };
            scheduleList[obj.id] = obj;
          }
          console.log(scheduleList);
          this.setState({ calendarEvents: scheduleList });
        }
        this.setState({ dataLoader: false });
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  addUpdateNewSchedules = (newEvents) => {
    let uid = localStorage.getItem('clinicianUID');
    this.setState({ dataLoader: true });
    const data = newEvents.map((item) => {
      let end;
      if (item.end === null) {
        const today = new Date(item.start);
        today.setHours(today.getHours() + 1);
        end = new Date(today);
      } else {
        end = new Date(item.end);
      }
      return {
        color: getEventColor(item.type),
        type: item.type,
        description: '1oct schedule',
        startTime: new Date(item.start), // moment(item.start).tz("UTC").toISOString(true),
        endTime: end, // moment(item.end).tz("UTC").toISOString(true),
        appointmentDuration: 60,
        soloDate: new Date(item.start),
        id: item.id,
        clinicianId: uid,
      };
    });
    // return false;
    Axios.post(
      `${baseURL}/api/schedule/updateMultipleSchedules${
        uid ? `?id=${uid}` : ''
      }`,
      [...data],
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        // this.getShedules();

        if (res.data && res.data.length) {
          let temp = { ...this.state.calendarEvents };
          for (const item of res.data) {
            const start = new Date(item.startTime);
            const end = new Date(item.endTime);

            const obj = {
              start,
              end,
              title: item.type,
              type: item.type,
              id: item._id,
              color: getEventColor(item.type),
              textColor:
                item.type === 'Appointment' || item.type === 'Blocked'
                  ? '#fff'
                  : '#000',
            };

            const newEvent = Object.values(this.state.calendarEvents).filter(
              (event) => {
                if (event.id === obj.id) {
                  return event;
                }
              }
            );

            if (newEvent && newEvent.length === 0) {
              temp[obj.id] = { ...obj, color: getEventColor(obj.type) };
            } else {
              newEvent.forEach((newEvent) => {
                temp[newEvent.id] = {
                  ...this.state.calendarEvents[newEvent.id],
                  start: obj.start,
                  end: obj.end,
                  id: obj.id,
                  type: obj.type,
                  color: getEventColor(obj.type),
                };
              });
            }
          }

          this.setState({ calendarEvents: temp });
        }
        this.setState({ dataLoader: false });
      })
      .catch((e) => {
        this.setState({ dataLoader: false });
        if (e.response.data.reason) {
          AlertMsg('error', e.response.data.reason);
        }
      });
  };

  handleClicked = (eventClickInfo, e) => {
    if (eventClickInfo.event.title != 'Blocked') {
      const block = this.state.calendarEvents[eventClickInfo.event.id];

      const formattedSlot = {
        calenderSlotId: block.id,
        clinicianId: block.clinicianId,
        startTime: block.start,
        endTime: block.end,
        type: block.type,
      };

      this.setState({
        showDialog: true,
        seletedSlot: formattedSlot,
      });
    } else {
      AlertMsg('error', 'can not delete this block');
    }
  };

  handleeventResize = (eventDropInfo) => {
    this.handleeventDrop(eventDropInfo);
  };

  validateTime = (startTime, endTime) => {
    if (
      (moment(startTime).utcOffset(240) >= moment('00:00:00', 'hh:mm:ss') &&
        moment(startTime).utcOffset(240) < moment('06:00:00', 'hh:mm:ss')) ||
      (moment(startTime).utcOffset(240) >= moment('22:00:00', 'hh:mm:ss') &&
        moment(startTime).utcOffset(240) < moment('23:59:59', 'hh:mm:ss'))
    ) {
      return false;
    }

    if (
      moment(endTime).utcOffset(240) >= moment('22:00:00', 'hh:mm:ss') &&
      moment(endTime).utcOffset(240) < moment('23:59:59', 'hh:mm:ss')
    ) {
      return false;
    }

    return true;
  };

  handleeventDrop = (eventDropInfo) => {
    var newEvents = [];

    const isTimeValid = this.validateTime(
      eventDropInfo.event.start,
      eventDropInfo.event.end
    );

    if (!isTimeValid) {
      AlertMsg(
        'error',
        'Start time can not lie between 12am - 6am and 10pm - 12am'
      );
      eventDropInfo.event.remove();
      return;
    }

    if (weekend.includes(moment(eventDropInfo.event.start).format('dddd'))) {
      AlertMsg('error', 'Cannot be booked for weekend');
      eventDropInfo.event.remove();
      return;
    }

    const tomorrow = new Date(eventDropInfo.event.start);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const eventInfo = {
      start: eventDropInfo.event.start,
      end: eventDropInfo.event.allDay ? tomorrow : eventDropInfo.event.end,
      id: eventDropInfo.event.id,
      type: eventDropInfo.event.title,
      color: getEventColor(eventDropInfo.event.title),
    };
    eventDropInfo.event.remove();
    const newEvent = Object.values(this.state.calendarEvents).filter(
      (event) => {
        if (event.id === eventInfo.id) {
          return event;
        }
      }
    );

    if (newEvent && newEvent.length === 0) {
      newEvents.push({ ...eventInfo });
    } else {
      newEvents = newEvent.map((event) => {
        const evnt =
          event.id === eventInfo.id
            ? {
                ...event,
                start: eventInfo.start,
                end: eventInfo.end,
                id: eventInfo.id,
                type: eventInfo.type,
                color: eventInfo.color,
                textColor:
                  eventInfo.type === 'Appointment' ||
                  eventInfo.type === 'Blocked'
                    ? '#fff'
                    : '#000',
              }
            : event;
        return evnt;
      });
    }

    if (eventInfo.type == 'Other' && newEvents.length > 0) {
      this.setState({
        selectedSlot: newEvents[0],
        slotSelectionDialogOpen: true,
      });
      return;
    }

    this.addUpdateNewSchedules(newEvents);
  };

  handleSelect = (selectionInfo, e) => {};

  handleeventRecieve = (info) => {
    this.handleeventDrop(info);
  };

  handleDateClick = (evt) => {};

  setWeeklyLimit = (e) => {
    const value = e.target.value;
    this.setState({ weeklyLimit: value });
  };

  saveWeeklyLimit = (e) => {
    const { weeklyLimit } = this.state;

    this.setState({ dataLoader: true });
    Axios.put(
      `${baseURL}/api/user${this.props.id ? `?id=${this.props.id}` : ''}`,
      { weeklyLimit },
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    ).then((res) => {
      localStorage.setItem('weeklyLimit', weeklyLimit);
      this.setState({ dataLoader: false });
      AlertMsg('success', 'Weekly Limit updated successfully!');
    });
  };

  handleAcceptNewPatients = (evt) => {
    const fieldName = evt.target.name;
    const status = evt.target.checked;
    Axios.put(
      `${baseURL}/api/user${this.props.id ? `?id=${this.props.id}` : ''}`,
      { [evt.target.name]: status },
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    ).then((res) => {
      if (fieldName === 'currPatientToggle') {
        localStorage.removeItem('accecpt_curr');
        localStorage.setItem('accecpt_curr', status ? 'true' : 'false');
        this.setState({ acceptCurrP: status });
      } else {
        localStorage.removeItem('accecpt_new');
        localStorage.setItem('accecpt_new', status ? 'true' : 'false');
        this.setState({ acceptNewP: status });
      }

      AlertMsg('success', 'Status updated successfully!');
    });
  };

  getCalendarData = async (fetchInfo, successCallback, failureCallback) => {
    try {
      let start = moment(fetchInfo.start).format('MM/DD/YYYY');
      let end = moment(fetchInfo.end).format('MM/DD/YYYY');
      const LoggedInUser = localStorage.getItem('clinicianUID');

      var url = `${baseURL}/api/schedule/get?id=${[
        LoggedInUser,
      ]}&timezoneOffset=${getTimezoneOffset()}`;

      if (start) {
        url = url + `&start=${start}`;
      }

      if (end) {
        url = url + `&end=${end}`;
      }

      let res = await Axios.get(url, {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      });

      if (!res.data) {
        failureCallback([]);
        return;
      }

      const sortedList = res.data.sort(function (a, b) {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
          return -1;
        }
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      let eventsList = [];
      sortedList.forEach((item, itemIndex) => {
        item.events.forEach((event, index) => {
          let evtData = {
            resourceId: itemIndex,
            title: event.title,
            start: event.start,
            end: event.end,
            index: `evt-${index}`,
            clinicianId: item._id,
            id: event._id,
            color: getEventColor(event.type),
            type: event.type,
            textColor:
              event.type === 'Appointment' || event.type === 'Blocked'
                ? '#fff'
                : '#000',
          };
          eventsList.push(evtData);
        });
      });

      successCallback(eventsList);
    } catch (error) {
      console.log(error);
      failureCallback([]);
    }
  };

  render() {
    const { dataLoader, acceptNewP, acceptCurrP } = this.state;
    return (
      <>
        <div className='calendarMain flex-col'>
          {dataLoader && (
            <div className='calendarLoader'>
              <Loader cstmStyle={{ top: '35%' }} />
            </div>
          )}

          <div
            id='external-events'
            className='calendarBtns w-max'
            style={{ display: 'flex' }}
          >
            <div className='shadows legends'>
              <h5>Calendar Slot Legends</h5>
              <br />
              <ul className='vertical'>
                {this.state.events.map((event) => (
                  <li
                    className='fc-event1'
                    title={event.title}
                    data={event.id}
                    key={event.id}
                  >
                    {event.title}
                  </li>
                ))}
              </ul>
            </div>

            <div className='acceptNewPatient ml-5'>
              <h4>Accept New Patients?</h4>
              <div>
                <span>No</span>
                <Switch
                  name='acceptNewPatients'
                  checked={acceptNewP}
                  onChange={this.handleAcceptNewPatients}
                  color='primary'
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <span>Yes</span>
              </div>
            </div>

            <div className='shadows limits flex flex-col ml-5'>
              <h4>Weekly Appointment Limits</h4>
              <input
                type='text'
                value={this.state.weeklyLimit}
                name='weeklyLimit'
                onChange={(env) => {
                  this.setWeeklyLimit(env);
                }}
              />
              <button
                className='bg-blue-500 -white font-bold my-2 py-2 px-4 border border-blue-700 text-white rounded'
                onClick={this.saveWeeklyLimit}
              >
                Save
              </button>
            </div>
          </div>

          <div className='calendarOuter'>
            <div className='demo-app-calendar'>
              <FullCalendar
                schedulerLicenseKey={process.env.REACT_APP_FULL_CALENDAR_KEY}
                eventResize={this.handleeventResize}
                eventDrop={this.handleeventDrop}
                select={this.handleSelect}
                eventReceive={this.handleeventRecieve}
                initialView='timeGridWeek'
                headerToolbar={{
                  left: 'prev,next',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay',
                }}
                rerenderDelay={0}
                eventDurationEditable
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  list,
                  interactionPlugin,
                ]}
                ref={this.calendarComponentRef}
                weekends={this.state.calendarWeekends}
                events={Object.values(this.state.calendarEvents)}
                defaultDate={this.state.current_date}
                slotMinTime='06:00:00'
                slotMaxTime='22:00:00'
                contentHeight='auto'
                editable
                selectable
                eventOverlap={false}
                eventClick={this.handleClicked}
                draggable
                droppable
                allDaySlot={false}
                nowIndicator
              />
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        {this.state.showDialog && (
          <ReplicateView
            handleSubmit={() => {
              this.handleClose();
              this.getShedules();
            }}
            selectedBlock={this.state.seletedSlot}
            open={this.state.showDialog}
            close={this.handleClose}
            getShedules={() => this.getShedules()}
            clinicianId={localStorage.getItem('clinicianUID')}
            isClinician
          />
        )}
        <CalendarSlotSelectionView
          open={this.state.slotSelectionDialogOpen}
          handleClose={() => this.setState({ slotSelectionDialogOpen: false })}
          selectedSlot={this.state.selectedSlot && this.state.selectedSlot}
          handleSubmit={(data) => {
            console.log(data);
            this.addUpdateNewSchedules([data]);
            this.setState({ slotSelectionDialogOpen: false });
          }}
        />
      </>
    );
  }
}
