/* eslint-disable import/prefer-default-export */
import { takeEvery, put } from "redux-saga/effects";
import { get } from "../axios";
import {
  GET_UNPAID_APPOINTMENT_SUCCESS,
  GET_UNPAID_APPOINTMENT_FAILED,
  GET_UNPAID_APPOINTMENT_REQUEST,
  GET_UPCOMING_APPOINTMENT_SUCCESS,
  GET_UPCOMING_APPOINTMENT_FAILED,
  GET_UPCOMING_APPOINTMENT_REQUEST,
  GET_USER_NOTIFICATION_SUCCESS,
  GET_USER_NOTIFICATION_FAILED,
  GET_USER_NOTIFICATION_REQUEST,
  UPDATE_USER_NOTIFICATION_SUCCESS,
  UPDATE_USER_NOTIFICATION_FAILED,
  UPDATE_USER_NOTIFICATION_REQUEST,
} from "../constant";

function* getUnpaid(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_UNPAID_APPOINTMENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_UNPAID_APPOINTMENT_FAILED, error });
  }
}

function* getUpcoming(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_UPCOMING_APPOINTMENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_UPCOMING_APPOINTMENT_FAILED, error });
  }
}

function* getNotification(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_USER_NOTIFICATION_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_USER_NOTIFICATION_FAILED, error });
  }
}

function* updateNotification(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: UPDATE_USER_NOTIFICATION_SUCCESS, response });
  } catch (error) {
    yield put({ type: UPDATE_USER_NOTIFICATION_FAILED, error });
  }
}

export function* dashbordSaga() {
  yield takeEvery(GET_UNPAID_APPOINTMENT_REQUEST, getUnpaid);
  yield takeEvery(GET_UPCOMING_APPOINTMENT_REQUEST, getUpcoming);
  yield takeEvery(GET_USER_NOTIFICATION_REQUEST, getNotification);
  yield takeEvery(UPDATE_USER_NOTIFICATION_REQUEST, updateNotification);
}
