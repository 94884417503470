import { makeStyles} from "@material-ui/core";

export default  makeStyles((theme) => ({
    root: {
      height: "85px",
      backgroundColor: "#ffffff",
    },
    arrowContainer: {
      height: "35px",
      width: "77px",
      backgroundColor: "#C0D0DE",
      borderRadius: "5px",
      cursor: "pointer",
      "&:hover": {
        opacity: "70%",
      },
    },
    text: {
      fontSize: "25px",
      color: "#304659",
      fontWeight: 800,
      fontFamily: "Lato",
      textAlign: "center",
    },
  }));