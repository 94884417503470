import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { TextField, Select } from '../../atoms';
import { Row, Error } from './style';

const OPTIONS = [
  { key: 'mobile', value: 'Mobile' },
  { key: 'phone', value: 'Phone' },
];

const BasicContactForm = ({ contact, onChange, errors }) => {
  return (
    <Grid direction='column' container>
      <Row direction='row' spacing={2} item container>
        <Grid xs={6} item>
          <TextField
            value={contact.firstName}
            onChange={onChange}
            name='firstName'
            label='First Name'
            error=''
            isRequired
          />
          <Error>{errors.firstName && errors.firstName.message}</Error>
        </Grid>
        <Grid xs={6} item>
          <TextField
            value={contact.lastName}
            onChange={onChange}
            name='lastName'
            label='Last Name'
            error=''
            isRequired
          />
          <Error>{errors.lastName && errors.lastName.message}</Error>
        </Grid>
      </Row>

      <Row direction='row' spacing={2} item container>
        <Grid xs={6} item>
          <TextField
            value={contact.email}
            onChange={onChange}
            placeholder='user@company.ae'
            name='email'
            label='Email'
            error=''
            isRequired
          />
          <Error>{errors.email && errors.email.message}</Error>
        </Grid>
        <Grid xs={6} item>
          <TextField
            value={contact.confirmEmail}
            onChange={onChange}
            name='confirmEmail'
            label='Confirm Email'
            error=''
            isRequired
          />
          <Error>{errors.confirmEmail && errors.confirmEmail.message}</Error>
        </Grid>
      </Row>

      <Row direction='row' spacing={2} item container>
        <Grid xs={6} item>
          <TextField
            value={contact.phoneNumber}
            onChange={onChange}
            name='phoneNumber'
            label='Phone Number'
            type='number'
            error=''
            isRequired
          />
          <Error>{errors.phoneNumber && errors.phoneNumber.message}</Error>
        </Grid>
        <Grid xs={6} item>
          <Select
            value={contact.phoneType}
            label='Phone Type'
            options={OPTIONS}
            name='phoneType'
            onChange={onChange}
            isRequired
          />
          <Error>{errors.phoneType && errors.phoneType.message}</Error>
        </Grid>
      </Row>
      <Row direction='row' spacing={2} item container>
        <Grid xs={12} item>
          <TextField
            value={contact.designation}
            onChange={onChange}
            name='designation'
            label='Designation'
            error=''
            isRequired
          />
          <Error>{errors.designation && errors.designation.message}</Error>
        </Grid>
      </Row>
    </Grid>
  );
};

BasicContactForm.propTypes = {
  contact: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    confirmEmail: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    phoneType: PropTypes.string.isRequired,
    designation: PropTypes.string.isRequired,
  }).isRequired,
  errors: PropTypes.shape({
    firstName: PropTypes.object.isRequired,
    lastName: PropTypes.object.isRequired,
    email: PropTypes.object.isRequired,
    confirmEmail: PropTypes.object.isRequired,
    phoneNumber: PropTypes.object.isRequired,
    phoneType: PropTypes.object.isRequired,
    designation: PropTypes.object.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
};
export default BasicContactForm;
