import React, { useEffect, useState } from 'react';
import moment from 'moment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Axios from 'axios';
import { baseURL } from '../../../../utils/axios';
import FilterImage from '../../../../assets/clinician_image/Group 351@2x.png';
import Loader from '../../../../components/Loader/Loader';
import FilterDrawer from '../../../../clinician/Patients/FilterDrawer';
import PatientDetailClinician from '../../../../clinician/Patients/patientDetail';
import { Grid, Button } from '@material-ui/core';
import '../../../../clinician/clinician.scss';
import userIcon from '../../../../assets/imgs/download.png';
import Pagination from '@material-ui/lab/Pagination';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useStyles, { CellBtn, TblCell } from './Patients.styles';
import { getPageCount } from '../../../../utils/MathUtil';
import { calculateAge } from '../../../../utils/timUtils';
import { dynamicSort } from '../../../../utils/sort';

const PatientList = ({ corporationId, isCorporate }) => {
  const classes = useStyles();
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [patientDrawer, setPatientDrawer] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [filterId, setFilterId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [patientLists, setPatientLists] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchTxt] = useState('');
  const [sortType, setSortType] = useState('desc');
  const [sortBy, setSortBy] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [appliedFilters, setAppliedFilters] = useState('');

  useEffect(() => {
    setIsLoading(true);
    if (!isCorporate) {
      getPatientLists();
    }

    if (corporationId) {
      setCompanyId(corporationId);
    }
  }, [corporationId]);

  useEffect(() => {
    getPatientLists();
  }, [companyId, page, searchText, sortType, sortBy, filterId, appliedFilters]);

  function handleSearch(event) {
    setPage(1);
    getPatientLists();
    setIsLoading(true);
  }

  const getPatientLists = () => {
    if (isCorporate && !companyId) {
      return false;
    }

    Axios.get(
      `${baseURL}/api/client/getAll?page=${page}&term=${searchText}&filterId=${filterId}&orderBy=${sortBy}&sortType=${sortType}&corporationId=${companyId}&appliedFilters=${JSON.stringify(
        appliedFilters
      )}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        setIsLoading(false);

        if (res.data) {
          console.log(res.data)
          setPatientLists(res.data.data);
          setTotalCount(res.data.count );
        }
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setFilterDrawer(open);
  };

  const togglePatientDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setPatientDrawer(open);
  };

  const handleSubmitRequest = (params) => {
    let axiosConfig = {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    };
    Axios.post(`${baseURL}/api/filter`, params, axiosConfig)
      .then((res) => {
        setFilterDrawer(false);
        setFilterId(res.data._id);
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const getDate = (date) => {
    const event = new Date(date);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return event.toLocaleDateString('en-US', options);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    setIsLoading(true);
  };

  const sort = (key) => {
    const toggleType = sortType === 'asc' ? 'desc' : 'asc';
    setSortType(toggleType);
    setSortBy(key);
  };

  const checkPersonalInfo = (record) =>
    !!record.avatar && !!record.dob && !!record.email;
  const checkAddressDetail = (record) =>
    record.address &&
    !!record.address.address1 &&
    !!record.address.city &&
    !!record.address.country;
  const checkEmergencyContact = (record) =>
    !!record.emergencyContact &&
    !!record.emergencyContact.emEmail &&
    !!record.emergencyContact.emPhoneNumber;
  const checkDocument = (record) =>
    record.documents &&
    record.documents.some(
      (d) => d.type === 'proofOfAddress' || d.type === 'proofOfIdentity'
    );

  const isProfileComplete = (record) => {
    let hasAddress = false;
    let hasEmergencyContact = false;
    let hasDocuments = false;
    let hasPersonalInfo = false;
    let status = 'Incomplete';

    hasPersonalInfo = checkPersonalInfo(record);
    hasAddress = checkAddressDetail(record);
    hasEmergencyContact = checkEmergencyContact(record);
    hasDocuments = checkDocument(record);

    if (hasPersonalInfo && hasAddress && hasEmergencyContact && hasDocuments) {
      status = 'Complete';
    }
    return status;
  };

  const applyFilters = (params) => {
    setFilterDrawer(false);
    setIsLoading(true);
    setFilterId('');
    console.log(params);
    setAppliedFilters(params);
  };

  return (
    <Grid>
      <Grid container>
        <Grid xs={6}>
          <OutlinedInput
            className={classes.inputstyle}
            style={{ float: 'left' }}
            placeholder='Search'
            id='outlined-adornment-password'
            onChange={(evt) => {
              setPage(1);
              setSearchTxt(evt.target.value);
            }}
            value={searchText}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  onClick={(evt) => {
                    handleSearch();
                  }}
                  aria-label='toggle password visibility'
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            // labelWidth={70}
          />
        </Grid>

        <Grid xs={6}>
          <div style={{ float: 'right' }}>
            {!corporationId && (
              <Link to='/admin/patients/add-patient'>
                <Button
                  variant='contained'
                  className={classes.btnNewApp}
                  style={{ height: '49px' }}
                >
                  <AddCircleOutlineIcon style={{ marginRight: 5 }} />
                  Create New Patient
                </Button>
              </Link>
            )}
            <Button
              variant='contained'
              style={{
                height: '49px',
                overflow: 'hidden',
                maxWidth: '47px',
                minWidth: 'unset',
              }}
              onClick={() => setFilterDrawer(true)}
              className={classes.btnNewApp}
            >
              <img src={FilterImage} width='50' />
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <TableContainer>
          <Table className={classes.table} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TblCell align='left'>Chart Number</TblCell>
                <TableCell align='left'>Image</TableCell>
                <TableCell align='left'>
                  <CellBtn onClick={() => sort('name')}>Name</CellBtn>
                </TableCell>
                <TableCell align='left'>
                  <CellBtn onClick={() => sort('dob')}>Age</CellBtn>
                </TableCell>
                <TableCell align='left'>
                  <CellBtn onClick={() => sort('date')}>Date</CellBtn>
                </TableCell>
                <TableCell align='left'>
                  <CellBtn onClick={() => sort('status')}>
                    Client Status
                  </CellBtn>
                </TableCell>
                <TableCell align='left'>
                  <CellBtn onClick={() => sort('profile')}>
                    Profile Status
                  </CellBtn>
                </TableCell>
                <TableCell align='right'>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody component={Paper}>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                    <Loader />
                  </TableCell>
                </TableRow>
              )}
              {!isLoading &&
                patientLists.length > 0 &&
                patientLists.map((val, i) => (
                  <TableRow key={i}>
                    <TableCell component='th' scope='row'>
                      {val.chartId}
                    </TableCell>
                    <TableCell>
                      <img
                        src={val.avatar ? val.avatar : userIcon}
                        style={{ borderRadius: '50%' }}
                        width='50'
                        height='50'
                      />
                    </TableCell>
                    <TableCell>
                      {val ? val.firstName + ' ' + val.lastName : ''}
                    </TableCell>
                    <TableCell>{calculateAge(new Date(val.dob))}</TableCell>
                    <TableCell>
                      {val.createdAt ? getDate(val.createdAt) : ''}
                    </TableCell>
                    <TableCell>{val.status ? val.status : ''}</TableCell>
                    <TableCell>{isProfileComplete(val)}</TableCell>
                    <TableCell>
                      <Button
                        variant='contained'
                        onClick={() => {
                          setPatientDrawer(true);
                          setPatientId(val._id);
                        }}
                        className={classes.viewBtn}
                      >
                        View
                      </Button>
                    </TableCell>
                    <TableCell align='right'>
                      <Link to={`/admin/patients/edit-patient/${val._id}`}>
                        <Button variant='contained' className={classes.viewBtn}>
                          Edit
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              {!isLoading
                ? patientLists.length === 0 && (
                    <TableRow>
                      <TableCell component='th' colSpan={8} scope='row'>
                        No Data Found
                      </TableCell>
                    </TableRow>
                  )
                : null}
            </TableBody>
          </Table>
          {patientLists && patientLists.length > 0 && (
            <div className={classes.paginationRoot}>
              <Pagination
                count={getPageCount(totalCount)}
                page={page}
                onChange={handlePageChange}
                color='primary'
                shape='rounded'
              />
            </div>
          )}
        </TableContainer>
      </Grid>
      <FilterDrawer
        toggleDrawer={toggleDrawer}
        filterDrawer={filterDrawer}
        handleSubmitRequest={handleSubmitRequest}
        applyFilters={applyFilters}
      />
      <PatientDetailClinician
        togglePatientDrawer={togglePatientDrawer}
        patientDrawer={patientDrawer}
        patientId={patientId}
      />
    </Grid>
  );
};

export default PatientList;
