/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';
import { Grid, Typography, withStyles, Dialog } from '@material-ui/core';
import AppleIcon from '@material-ui/icons/Apple';
import google from '../../../assets/user_images/google.svg';
import firebase, {
  googleSignIn,
  appleSignIn,
  googleSignOut,
} from '../../../utils/firebase';
import AlertMsg from '../../../utils/Alert';
import { baseURL } from '../../../utils/axios';
import logo from '../../../assets/imgs/download.png';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { getAgeByDob } from '../../../utils/validators';
import styles from './GooglrOptionsStyle';
import { adultAge } from '../../../utils/timUtils';

class SocialAuthFirebase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      dob: null,
      errors: {
        dob: null,
      },
    };
  }

  handleSignInWithGoogle = async () => {
    await googleSignIn()
      .then(() => {
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(async (id) => {
            localStorage.setItem('ACCESS_TOKEN_PATH', id);
            await fetch(`${baseURL}/api/client/sign_up`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: id
                  ? id
                  : localStorage.getItem('ACCESS_TOKEN_PATH'),
              },
              body: JSON.stringify({ data: 'data' }),
            })
              .then((res) => res.json())
              .then((data) => {
                localStorage.setItem('clientUid', data.uid);
                if (data.dob) {
                  this.setState({ dob: new Date(data.dob) });
                  this.handleSubmit();
                } else {
                  this.setState({ open: true });
                }
              })
              .catch((err) => console.log('sfsffsff', err));
          });
      })
      .catch((err) => AlertMsg('error', 'There is some issue occured'));
  };

  handleSignInWithApple = async () => {
    await appleSignIn()
      .then(() => {
        AlertMsg('success', 'You are logged in');
        this.props.props.history.push('/onboarding');
      })
      .catch((err) => AlertMsg('error', 'There is some issue occured'));
  };

  handleSubmit = async () => {
    const errors = { ...this.state.errors };
    errors.dob =
      this.state.dob === null ? 'Please select you Date of birth!' : null;
    this.setState({ errors });
    if (this.state.dob !== null) {
      if (getAgeByDob(this.state.dob) < adultAge) {
        errors.dob = `Date of birth must be more than ${adultAge} years!`;
        this.setState({ errors });
        return null;
      }
      await fetch(`${baseURL}/api/client`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
        body: JSON.stringify({ dob: this.state.dob }),
      })
        .then((res) => res.json())
        .then(async (udata) => {
          await localStorage.setItem(
            'avatar',
            udata.avatar ? udata.avatar : logo
          );
          await localStorage.setItem(
            'name',
            udata.firstName ? udata.firstName : ''
          );
          await localStorage.setItem('createdAt', udata.createdAt);
          await localStorage.setItem('clientEmail', udata.email);
          await localStorage.setItem('clientUid', udata.uid);
          await localStorage.setItem('clientId', udata._id);
          AlertMsg('success', 'You are logged in');

          if (udata.firstLogin) {
            this.props.props.history.push('/dashboard');
          } else {
            this.props.props.history.push('/onboarding');
          }
        })
        .catch((err) => console.log(err));
    }
  };

  handleCancel = () => {
    googleSignOut().then(() => {
      localStorage.removeItem('ACCESS_TOKEN_PATH');
      this.setState({ dob: null, open: false });
    });
  };

  render() {
    const { classes, title } = this.props;
    return (
      <div>
        <Grid
          item
          className={classes.google}
          onClick={this.handleSignInWithGoogle}
        >
          <img src={google} className={classes.googleIcon} />
          <Typography className={classes.googleText}>
            Sign {title} with Google
          </Typography>
        </Grid>

        <Grid
          item
          className={classes.apple}
          onClick={this.handleSignInWithApple}
        >
          <AppleIcon className={classes.appleicon} />
          <Typography className={classes.googleText}>
            Sign
            {title} with Apple
          </Typography>
        </Grid>

        <Dialog
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
        >
          <DialogContent>
            <Grid style={{ padding: '16px' }}>
              <Typography
                style={{
                  fontSize: '22px',
                  fontFamily: 'Lato',
                  color: '#304659',
                  fontWeight: 700,
                  marginBottom: '46px',
                  textAlign: 'center',
                }}
              >
                Update Date of Birth
              </Typography>
              <Grid
                style={{ display: 'inline' }}
                container
                justify='center'
                alignItems='center'
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin='normal'
                    maxDate={moment().subtract(adultAge, 'years').toDate()}
                    id='date-picker-dialog'
                    label='Date Of Birth'
                    format='MM/dd/yyyy'
                    value={this.state.dob}
                    onChange={(date) => {
                      const errors = { ...this.state.errors };
                      errors.dob = null;
                      this.setState({ errors, dob: date });
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
                {this.state.errors.dob && (
                  <Typography className={classes.alertFont}>
                    {this.state.errors.dob}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              onClick={this.handleCancel}
              color={'primary'}
            >
              Cancel
            </Button>
            <Button
              disabled={!this.state.dob}
              variant='contained'
              onClick={() => this.handleSubmit()}
              color='primary'
              style={{ backgroundColor: '#304659' }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(SocialAuthFirebase);
