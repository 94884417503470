import React from 'react';
import {
  Button,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import moment from 'moment';
import Loader from '../../../components/Loader/Loader';
import { STATUSES } from '../../../utils/enums';

const ClientAppointments = ({
  loading,
  unpaidAppointments,
  downloadUnpaidAppointmentData,
  classes,
  formatUser,
  setAppointmentId,
  setAppointmentDetails,
  setDetailDrawer,
  openInvoiceDrawer,
  renderSessionTime,
}) => (
  <div>
    <TableContainer elevation={0}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead style={{ backgroundColor: '#F6F7FB' }}>
          <TableRow>
            <TableCell style={{ color: '#C7C7C9' }}>CHART NUMBER</TableCell>
            <TableCell align='left' style={{ color: '#C7C7C9' }}>
              PATIENT(S)
            </TableCell>
            <TableCell align='left' style={{ color: '#C7C7C9' }}>
              CLINICIAN
            </TableCell>
            <TableCell align='left' style={{ color: '#C7C7C9' }}>
              DATE TIME
            </TableCell>
            <TableCell align='left' style={{ color: '#C7C7C9' }}>
              CLIENT STATUS
            </TableCell>
            <TableCell align='left' style={{ color: '#C7C7C9' }}>
              APPOINTMENT STATUS
            </TableCell>
            <TableCell style={{ color: '#C7C7C9' }}></TableCell>
            <TableCell align='left' style={{ color: '#C7C7C9' }}>
              ACTIONS
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody className={classes.body}>
          {loading ? (
            <Loader />
          ) : unpaidAppointments && unpaidAppointments.length > 0 ? (
            unpaidAppointments.map((unp) => (
              <TableRow key={`unpaid-${unp._id}`}>
                <TableCell align='left'>
                  <Grid item>
                    <Typography className={classes.blue}>
                      {unp.clientId[0].chartId}
                    </Typography>
                  </Grid>
                </TableCell>
                <TableCell align='left'>
                  {formatUser(unp.clientId[0])}
                </TableCell>
                <TableCell align='left' className={classes.userImages}>
                  {formatUser(unp.clinicianId[0])}
                </TableCell>
                <TableCell align='left'>
                  <Grid item>
                    <Typography className={classes.blue}>
                      {moment(unp.startTime).format('L')}
                    </Typography>
                    <Typography className={classes.blue}>
                      {renderSessionTime(unp)}
                    </Typography>
                  </Grid>
                </TableCell>
                <TableCell align='left'>
                  <Typography className={`${classes.blue} capitalize`}>
                    {unp.clientStatus}
                  </Typography>
                </TableCell>
                <TableCell align='left'>
                  <Grid item>
                    <Typography className={classes.blue}>
                      {STATUSES[unp.status]}
                    </Typography>
                  </Grid>
                </TableCell>
                <TableCell align='right'>
                  <Button
                    variant='contained'
                    className={classes.payBtn}
                    onClick={() => openInvoiceDrawer(unp.billingId[0]._id)}
                  >
                    pay
                  </Button>
                </TableCell>
                <TableCell align='right'>
                  <Button
                    variant='contained'
                    className={classes.viewBtn}
                    onClick={() => {
                      setDetailDrawer(true);
                      setAppointmentId(unp._id);
                      setAppointmentDetails(unp);
                    }}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <Grid justify='center' container>
              <Grid item>No upcoming appointments available!</Grid>
            </Grid>
          )}
        </TableBody>
      </Table>
    </TableContainer>

    <Button
      variant='contained'
      className={classes.btn2}
      style={{
        marginTop: '20px',
        color: '#FFFFFF',
        backgroundColor: '#003265',
      }}
      onClick={downloadUnpaidAppointmentData}
    >
      Download Report
    </Button>
  </div>
);

export default ClientAppointments;
