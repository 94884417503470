/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable react/state-in-constructor */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import {
  Grid,
  Paper,

  withStyles,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Divider,
  Radio,
} from '@material-ui/core';
import Select from 'react-select';
import styles from './Contact_TabStyle';

const sortoptions = [
  { value: 'work', label: 'Work' },
  { value: 'home', label: 'Home' },
];

const numoptions = [
  { value: 'work', label: 'Work' },
  { value: 'mob', label: 'Mobile' },
  { value: 'home', label: 'Home' },
  { value: 'fax', label: 'Fax' },
];

const relationoptions = [
  { value: 'parent', label: 'Parent' },
  { value: 'guardian', label: 'Legal Guardian' },
  { value: 'member', label: 'Family Member' },
  { value: 'other', label: 'Other' },
];

class Contact extends React.Component {
  state = {
    selectedSortOption: null,
    selectedNumOption: null,
    selectedRelationOption: null,
    checked: true,
  };

  handleSortChange = (selectedSortOption) => {
    this.setState({ selectedSortOption });
  };

  handleNumChange = (selectedNumOption) => {
    this.setState({ selectedNumOption });
  };

  handleRelationChange = (selectedRelationOption) => {
    this.setState({ selectedRelationOption });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const { classes } = this.props;
    const { selectedSortOption } = this.state;
    const { selectedNumOption } = this.state;
    const { selectedRelationOption } = this.state;
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.formHeading}>
              Contact's First Name
            </Typography>
            <input
              name="firstName"
              //   value={firstName}
              onChange={this.handleChange}
              className={classes.divstyle}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
            <Typography className={classes.firstnameLink}>
              Add Existing Client or Contact
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.formHeading}>
              Contact's Last Name
            </Typography>
            <input
              name="firstName"
              //   value={firstName}
              onChange={this.handleChange}
              className={classes.divstyle}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Typography className={classes.formHeading}>
              Contact's Email Address
            </Typography>
            <input
              name="firstName"
              //   value={firstName}
              onChange={this.handleChange}
              className={classes.divstyle}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
            <Typography className={classes.bottomtext}>
              To grant Client Portal access, enter an email address
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Select
              value={selectedSortOption}
              onChange={this.handleSortChange}
              className={classes.righttextinput}
              options={sortoptions}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Typography className={classes.formHeading}>
              Contact's Phone Number
            </Typography>
            <input
              name="firstName"
              //   value={firstName}
              onChange={this.handleChange}
              className={classes.divstyle}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
            <Typography className={classes.bottomtext}>
              To grant Client Portal access, enter an email address
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Select
              value={selectedNumOption}
              onChange={this.handleNumChange}
              options={numoptions}
              className={classes.righttextinput}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Typography className={classes.formHeading}>
              Contact's relationship to Client
            </Typography>
            <Select
              value={selectedRelationOption}
              onChange={this.handleRelationChange}
              options={relationoptions}
              // className={classes.righttextinput}
            />
          </Grid>
        </Grid>

        <FormControlLabel
          className={classes.checkedsty}
          control={(
            <Checkbox
              checked={this.state.checked}
              onChange={this.handleChange}
              name="checked"
              color="primary"
            />
          )}
          label="Responsible for billing"
        />

        <div>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            className={classes.button}
          >
            + Contact
          </Button>
        </div>

        <Divider style={{ marginTop: 20 }} />

        <div style={{ marginTop: 20 }}>
          <Typography className={classes.formHeading}>Billing Type</Typography>
          <div style={{ display: 'inline-block', marginTop: 20 }}>
            <FormControlLabel
              value="end"
              control={<Radio color="primary" />}
              label="Self-Pay"
              style={{ color: '#003d79' }}
            />

            <FormControlLabel
              value="end"
              control={<Radio color="primary" />}
              label="Insurance"
              style={{ color: '#003d79' }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Contact);
