import React from 'react';
import PropTypes from 'prop-types';
import { Rectangle, Img } from './style';

const RectangledLogo = ({ src, width, height }) => {
  return src && src !== '' ? (
    <Img src={src} width={width} height={height} />
  ) : (
    <Rectangle width={width} height={height} />
  );
};

RectangledLogo.defaultProps = {
  width: '75px',
  height: '20px',
};

RectangledLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  src: PropTypes.string,
};

export default RectangledLogo;
