import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
    list: {
      width: 480,
    },
    fullList: {
      width: 'auto',
    },
    btnNewApp: {
      backgroundColor: "#003265",
      color: '#fff',
      // marginLeft: "50px",
      '&:hover': {
        backgroundColor: '#003265',
      },
    },
    'MuiOutlinedInput-inputMarginDense' : {
      padding: '12px'
    },
    textColor: {
      color: "#003265",
      marginLeft: '10px',
      fontSize: '17px',
      fontWeight: 500,
      verticalAlign: 'middle',
          display: 'inline-block',
          height: '100%',
    },
    topHeader:{
      marginBottom: 15
    },
    labelText: {
      color: "#003265",
      fontSize: '15px',
      fontWeight: 500,
    },
    formControl:{
      width: '100%',
      marginTop: 10
    },
    sortSelect: {
      padding:11
    },
    btnSaveFilter: {
      backgroundColor: "#003265",
      color: '#fff',
      padding: '10px',
      marginTop: '10px',
      textTransform: 'capitalize',
      width: '100%',
      '&:hover': {
        backgroundColor: '#003265',
      },
    },
    selectEmpty:{
      backgroundColor:"#f2f6f8",
      padding:"8px",
      borderRadius:"3px",
      '&:before':{
        content:"none"
      },
    },
  
  });