/* eslint-disable import/prefer-default-export */
import {
  ADD_SERVICE_REQUEST,
  GET_SERVICE_REQUEST,
  GET_BY_ID_SERVICE_REQUEST,
  UPDATE_SERVICE_REQUEST,
  DELETE_SERVICE_REQUEST,
} from "../constant";

export const addServiceAction = (data) => ({
  type: ADD_SERVICE_REQUEST,
  endpoint: "/admin/services",
  body: data,
});

export const getServiceAction = () => ({
  type: GET_SERVICE_REQUEST,
  endpoint: "/admin/services",
});

export const getByIdServiceAction = (id) => ({
  type: GET_BY_ID_SERVICE_REQUEST,
  endpoint: `/admin/services/${id}`,
});

export const updateServiceAction = (id, data) => ({
  type: UPDATE_SERVICE_REQUEST,
  endpoint: `/admin/services/${id}`,
  body: data,
});

export const deleteServiceAction = (id) => ({
  type: DELETE_SERVICE_REQUEST,
  endpoint: `/admin/services/${id}`,
});
