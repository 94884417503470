import { makeStyles} from "@material-ui/core";
export default makeStyles((theme) => ({
    root: {
      "&:nth-of-type(even)": {
        backgroundColor: "#ffffff",
      },
      "&:nth-of-type(odd)": {
        backgroundColor: "rgba(48,70,89,5%)",
      },
      // width: "1025px",
      height: "70px",
      // cursor: 'pointer',
    },
    container: {
      display: "flex",
    },
    imgTable: {
      width: "38px",
      height: "38px",
      objectFit: 'contain',
      borderRadius: "19px",
      border: "2px solid #ffffff",
  },
  userImages:{
    '& img' : {
      marginLeft:"-15px",
      position:"relative",
      '& firstchild' : {
        marginLeft:0
      }
    }
  },
  }));