import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function NewPatientsReferralModal({open, handleClose, handleSubmit, actionType, userList, openAutoComp, setOpenAutoComp, loading, handleSearchAutocomplete}) {
  const [userId, setUserId] = React.useState(null);
  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth={"md"} aria-labelledby="max-width-dialog-title">
        <DialogTitle id="form-dialog-title">{actionType === "patients" ? "New Appointment" : "Select clinician"}</DialogTitle>
        <DialogContent>
          <Grid style={{marginBottom: 20 }}>
            <DialogContentText>
              {actionType === "patients" ? "Select patient for appointment booking." : "Select clinician."}
            </DialogContentText>
            {/* <Autocomplete
              id="size-small-outlined-multi"
              size="small"
              options={userList ? userList : []}
              getOptionLabel={(option) => option.name}
              style={{ width: 400 }}
              value={userId}
              onChange={(event, newValue) => {
                setUserId(newValue);
              }}
              renderInput={(params) => <TextField {...params} label={actionType === "patients" ? "Select patient" : "Select clinician"} variant="outlined" />}
            /> */}
            <Autocomplete
              id="asynchronous-demo"
              style={{ width: 300 }}
              open={openAutoComp}
              onOpen={() => {
                setOpenAutoComp(true);
              }}
              onClose={() => {
                setOpenAutoComp(false);
              }}
              onChange={(event, newValue) => {
               
                setUserId(newValue);
              }}
              getOptionSelected={(option, value) => option === value}
              getOptionLabel={(option) => option.name}
              options={userList}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={actionType === "patients" ? "Select patient" : "Select clinician"}
                  variant="outlined"
                  onChange={handleSearchAutocomplete}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="default">
            Cancel
          </Button>
          <Button disabled={!userId} variant="contained" onClick={evt => handleSubmit(userId)} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
