/* eslint-disable no-underscore-dangle */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from 'react';
import {
  withStyles,

  Typography,
  Grid,
} from '@material-ui/core';
// import ChildField from './ChildField';
// import MultipleUserField from './MultipleUserField';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import Header from '../common/Header';

import { getChildAction } from '../utils/actions/client.action';
import styles from './AppointmentForStyle';
import { adultAge } from '../utils/timUtils';

class AppointmentFor extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      getDataLoader: true,
      childrens: [],
    };
  }

  componentDidMount() {
    this.props.getChildAction();
    localStorage.removeItem('multipleAdults');
    localStorage.removeItem('appFor');
    localStorage.removeItem('appChildDetails');
    localStorage.removeItem('childDetails');
  }

  componentDidUpdate(preProps) {
    if (this.props.getChild !== preProps.getChild) {
      if (this.props.getChild && this.props.getChild.length) {
        const data = this.props.getChild;
        const childList = [];
        for (const item of data) {
          if (item.relation === 'Child') {
            childList.push(item.client);
          }
        }
        this.setState({
          childrens: childList,
          getDataLoader: false,
        });
      }
    }
  }

  handleSelf = (appFor, data) => {
    if (appFor === 'child') {
      localStorage.setItem('appFor', 'child');
      localStorage.setItem('appChildDetails', JSON.stringify(data));
    }
    if (appFor === 'self') {
      localStorage.setItem('appFor', 'self');
    }

    // this.props.history.push({
    //   pathname: '/clinician',
    //   search: '?type=recommended',
    //   state: { matchedClinicians: [], riskScore: 0 }
    // })
    this.props.history.push(`/assessment-que?type=${appFor}`);
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Header props={this.props} />
        <div className={classes.root} />
        <Grid container justify="center" alignItems="center" component="div">
          <Grid item className={classes.mainBody}>
            {/* <Grid className={classes.titleContainer} component="div"> */}
            <Typography className={classes.title}>
              Who is this visit for?
            </Typography>
            {/* </Grid> */}
            <Grid component="div" className={classes.bodyContainer}>
              <Grid
                item
                component="div"
                className={classes.bodyListContainer}
                onClick={() => this.handleSelf('self', localStorage.getItem('clientId'))}
                style={{
                  marginBottom: '11px',
                  marginTop: '11px',
                  cursor: 'pointer',
                }}
              >
                <Grid component="div" className={classes.avatarContainer}>
                  {localStorage.getItem('avatar') ? (
                    <img src={localStorage.getItem('avatar')} className={classes.avatar} />
                  ) : (
                    <Grid
                      container
                      justify="center"
                      style={{
                        background: 'rgba(255, 11, 217, 56%)',
                        width: '50px',
                        height: '50px',
                        borderRadius: '25px',
                        border: '5px solid #ffffff',
                        fontSize: '30px',
                        color: '#ffffff',
                        fontWeight: 900,
                        fontFamily: 'Lato',
                        textAlign: 'center',
                      }}
                    >
                      {localStorage.getItem('name') ? localStorage.getItem('name').charAt(0) : ''}
                    </Grid>
                  )}
                  {/* <Grid className={classes.avatar}> */}
                </Grid>
                <Grid component="div" className={classes.nameContainer}>
                  <Typography display="inline" className={classes.name}>
                    {`${localStorage.getItem('name')}`}
                  </Typography>
                  <Typography className={classes.subName}>You</Typography>
                </Grid>
              </Grid>
              {this.state.childrens.map((child, index) => (
                <Grid
                  item
                  component="div"
                  className={classes.bodyListContainer}
                  onClick={() => this.handleSelf('child', child)}
                  style={{
                    marginBottom: '11px',
                    marginTop: '11px',
                    cursor: 'pointer',
                  }}
                >
                  <Grid component="div" className={classes.avatarContainer}>
                    {child.avatar ? (
                      <img src={child.avatar} className={classes.avatar} />
                    ) : (
                      <Grid
                        container
                        justify="center"
                        style={{
                          background: 'rgba(255, 11, 217, 56%)',
                          width: '50px',
                          height: '50px',
                          borderRadius: '25px',
                          border: '5px solid #ffffff',
                          fontSize: '30px',
                          color: '#ffffff',
                          fontWeight: 900,
                          fontFamily: 'Lato',
                          textAlign: 'center',
                        }}
                      >
                        {child.firstName.charAt(0)}
                      </Grid>
                    )}

                    {/* <Grid className={classes.avatar}> */}
                  </Grid>
                  <Grid component="div" className={classes.nameContainer}>
                    <Typography display="inline" className={classes.name}>
                      {`${child.firstName}`}
                    </Typography>
                    {child.type === 'self' ? (
                      <Typography className={classes.subName}>You</Typography>
                    ) : (
                      <Typography className={classes.subName}>
                        {parseInt(dayjs(new Date()).format('YYYY'))
                          - parseInt(dayjs(child.dob).format('YYYY'))}
                        {' '}
                        years old
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              ))}
              <Grid
                container
                component="div"
                justify="center"
                alignItems="center"
                className={classes.list}
              >
                <Grid
                  item
                  component="div"
                  className={classes.bodyListContainer}
                  style={{ backgroundColor: '#ffffff', cursor: 'pointer' }}
                  onClick={() => this.props.history.push('/add-child')}
                >
                  <Grid component="div" className={classes.avatarContainer}>
                    <Grid
                      component="div"
                      className={classes.avatar}
                      style={{ backgroundColor: '#304659', opacity: '20%' }}
                    >
                      <Typography className={classes.addIcon}>+</Typography>
                    </Grid>
                  </Grid>
                  <Grid component="div" className={classes.nameContainer}>
                    <Typography
                      className={classes.name}
                      style={{ color: '#304659' }}
                    >
                      Add Child?
                    </Typography>
                    <Typography
                      className={classes.subName}
                      style={{ color: '#304659' }}
                    >
                      Must be under {adultAge} years old
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                component="div"
                justify="center"
                alignItems="center"
                className={classes.list}
              >
                <Grid
                  item
                  component="div"
                  className={classes.bodyListContainer}
                  style={{ backgroundColor: '#ffffff', cursor: 'pointer' }}
                  onClick={() =>
                    // this.props.history.push(`/user/client/${4545}/adults`)
                    this.props.history.push(`/user/client/${4545}/multiple-person`)}
                >
                  <Grid component="div" className={classes.avatarContainer}>
                    <Grid
                      component="div"
                      className={classes.avatar}
                      style={{ backgroundColor: '#304659', opacity: '20%' }}
                    >
                      <Typography className={classes.addIcon}>?</Typography>
                    </Grid>
                  </Grid>
                  <Grid component="div" className={classes.nameContainer}>
                    <Typography
                      className={classes.name}
                      style={{ color: '#304659' }}
                    >
                      Multiple people?
                    </Typography>
                    <Typography
                      className={classes.subName}
                      style={{ color: '#304659' }}
                    >
                      Click to learn more
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                component="div"
                container
                alignItems="center"
                justify="center"
                className={classes.footerContainer}
              >
                <Grid item>
                  <Typography className={classes.footerTitle}>
                    Crisis / Emergency?
                  </Typography>

                  <Typography className={classes.footerDescription}>
                    If you are experiencing a crisis or emergency, please dial
                    911. You may also access the free, 24hr National Suicide
                    Prevention Lifeline at 1800.888.8888.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

AppointmentFor.defaultProps = {
  getChild: [],
};

const connected = connect(
  (state) => ({
    getChild: state.getChild.getChildData,
  }),
  {
    getChildAction,
  },
)(AppointmentFor);

export default withStyles(styles)(connected);
