export const DISCIPLINES = {
  CLPSY: 'Clinical Psychologist',
  COPSY: 'Counseling Psychologist',
  PS: 'Psychologist',
  PSY: 'Specialist Psychiatrist',
  CONSPSY: 'Consultant Psychiatrist',
  PSYA: 'Psychology Assistant',
  CSW: 'Clinical Social Worker',
  MMP: 'Mindfulness & Meditation Practitioner',
  OT: 'Occupational Therapist',
  SOT: 'Sr. Occupational Therapist',
  SLT: 'Speech & Language Therapist',
  AS: 'Assessment Specialist',
};

export const LOCATION = {
  online: 'Online',
  clinic: 'Clinic',
  home: 'Home visit',
  school: 'School visit',
};

export const STATUSES = {
  confirmed: 'Confirmed',
  unconfirmed: 'Unconfirm',
  completed: 'Completed',
  cancelled: 'Cancelled',
  waiting: 'Waiting',
  reffered: 'Refferred',
  'checked-in': 'Checked In',
  'reffered-waiting': 'Reffered - Waiting',
  missed: 'No Show',
  seen: 'Seen',
  ended: 'Incomplete',
};

export const APPOINTMENT_TYPES = {
  psychiatry: 'Psychiatry',
  'prescription-refill': 'Prescription Refill',
  'individual-psychotherapy': 'Individual Psychotherapy',
  'couples-therapy': 'Couples Therapy',
  'family-therapy': 'Family Therapy',
  'psychoeducational-intake-assessment': 'Psychoeducational Intake Assessment',
  'psychoeducational-assessment-test': 'Psychoeducational Assessment Test',
  'psychoeducational-assessment-feedback':
    'Psychoeducational Assessment Feedback',
  'occupational-therapy': 'Occupational Therapy',
  'occupational-therapy-screening': 'Occupational Therapy Screening',
  'speech-and-languange-therapy': 'Speech & Languange Therapy',
  'speech-and-language-therapy-screening':
    'Speech & Language Therapy Screening',
  'wellbeing-check-up': 'Wellbeing Check-UP',
  'grief-consultation': 'Grief Consultation',
  'adoption-intake-and-assessment': 'Adoption Intake & Assessment',
  'adoption-home-visit': 'Adoption Home Visit',
  'adoption-feedback-report-and-home-study':
    'Adoption Feedback Report & Home Study',
};

export const JOB_DESCRIPTION = {
  ACCOUNTANT: 'ACCOUNTANT',
  ACTOR: 'ACTOR',
  'ADMINISTRATIVE STAFF': 'ADMINISTRATIVE STAFF',
  'ADVERTISING REPRESENTATIVE': 'ADVERTISING REPRESENTATIVE',
  ADVISOR: 'ADVISOR',
  'AGRICULTURAL WORKER': 'AGRICULTURAL WORKER',
  AMBASSADOR: 'AMBASSADOR',
  ANALYST: 'ANALYST',
  ANTHROPOLOGIST: 'ANTHROPOLOGIST',
  'ART CRITIC': 'ART CRITIC',
  ARTIST: 'ARTIST',
  ASTRONOMER: 'ASTRONOMER',
  AUDITOR: 'AUDITOR',
  'BABY SITTER': 'BABY SITTER',
  BANKER: 'BANKER',
  BARBER: 'BARBER',
  BARMAN: 'BARMAN',
  BEAUTICIAN: 'BEAUTICIAN',
  BEEKEEPER: 'BEEKEEPER',
  'BODY MASSAGER': 'BODY MASSAGER',
  'BOOKBINDING WORKER': 'BOOKBINDING WORKER',
  'BOXES MACHINE WORKER': 'BOXES MACHINE WORKER',
  'BROKER AND AUCTIONEER': 'BROKER AND AUCTIONEER',
  BUSINESSMAN: 'BUSINESSMAN',
  BUSINESSWOMAN: 'BUSINESSWOMAN',
  BUTCHER: 'BUTCHER',
  'CABIN CREW': 'CABIN CREW',
  'CAR WASHING WORKER': 'CAR WASHING WORKER',
  'CARGO WORKER': 'CARGO WORKER',
  CARPENTER: 'CARPENTER',
  'CARTONS COLLECTION': 'CARTONS COLLECTION',
  'CEILING FIXER': 'CEILING FIXER',
  'CEMENT AND CERAMIC WORKER': 'CEMENT AND CERAMIC WORKER',
  'CHEMICAL INDUS WORKER': 'CHEMICAL INDUS WORKER',
  CHILD: 'CHILD (NOT STUDENT)',
  'CIRCUS WORKERS': 'CIRCUS WORKERS',
  'CIVIL SERVANT': 'CIVIL SERVANT',
  'CLAIMS ADJUSTOR': 'CLAIMS ADJUSTOR',
  CLEANER: 'CLEANER',
  'CLIENTS SERVICE REPRESENTATIVE': 'CLIENTS SERVICE REPRESENTATIVE',
  COACH: 'COACH',
  'COMMERCIAL EMPLOYEE': 'COMMERCIAL EMPLOYEE',
  'CONSTRUCTION WORKER': 'CONSTRUCTION WORKER',
  'CRANE OPERATOR': 'CRANE OPERATOR',
  'CURTAINS INSTALLATION WORKER': 'CURTAINS INSTALLATION WORKER',
  'CUSTOMS STAFF': 'CUSTOMS STAFF',
  'CUTTING MACHINE WORKER': 'CUTTING MACHINE WORKER',
  DECORATOR: 'DECORATOR',
  DESIGNER: 'DESIGNER',
  DIETITIAN: 'DIETITIAN',
  'DIPLOMATIC MISSION EMPLOYEE': 'DIPLOMATIC MISSION EMPLOYEE',
  'DISHWASHING WORKER': 'DISHWASHING WORKER',
  'DOMESTIC SANITARY INSTALLATION WORKER':
    'DOMESTIC SANITARY INSTALLATION WORKER',
  DRAUGHTSMAN: 'DRAUGHTSMAN',
  'DRESS MODELS MAKER': 'DRESS MODELS MAKER',
  'DRILLING WORKER': 'DRILLING WORKER',
  'DRINK FERMENTATION MAN': 'DRINK FERMENTATION MAN',
  DRIVER: 'DRIVER',
  'DYES PREPARATION WORKER': 'DYES PREPARATION WORKER',
  'EDUCATIONAL STAFF': 'EDUCATIONAL STAFF',
  ELECTRICIAN: 'ELECTRICIAN',
  ENGINEER: 'ENGINEER',
  'FARM WORKER': 'FARM WORKER',
  'FASHION MODEL EXHIBITOR': 'FASHION MODEL EXHIBITOR',
  FATHER: 'FATHER',
  FINANCE: 'FINANCE',
  'FINISHING WORKER': 'FINISHING WORKER',
  FIREMAN: 'FIREMAN',
  'FISHING WORKER': 'FISHING WORKER',
  'FIXING WORKER': 'FIXING WORKER',
  'FLOWER MAKER': 'FLOWER MAKER',
  'FOOD AND BEVERAGE SERVICES': 'FOOD AND BEVERAGE SERVICES',
  'FURNITURE WORKER': 'FURNITURE WORKER',
  GARDENER: 'GARDENER',
  'GAS METERS READER': 'GAS METERS READER',
  GEOGRAPHER: 'GEOGRAPHER',
  GEOLOGIST: 'GEOLOGIST',
  'GLASS INDUSTRY WORKER': 'GLASS INDUSTRY WORKER',
  'GRAVE DIGGER': 'GRAVE DIGGER',
  GROCER: 'GROCER',
  GUARD: 'GUARD',
  GUIDE: 'GUIDE',
  GUNSMITH: 'GUNSMITH',
  'HAND BAGS MAKER': 'HAND BAGS MAKER',
  'HEALTHCARE WORKER': 'HEALTHCARE WORKER',
  HELPER: 'HELPER',
  HISTORIAN: 'HISTORIAN',
  'HOLY QURAN READER': 'HOLY QURAN READER',
  'HOTEL STAFF': 'HOTEL STAFF',
  HOUSEKEEPER: 'HOUSEKEEPER',
  HOUSEMAID: 'HOUSEMAID',
  INSPECTOR: 'INSPECTOR',
  'INSULATION WORKER': 'INSULATION WORKER',
  INTERPRETER: 'INTERPRETER',
  INVESTOR: 'INVESTOR',
  IRONSMITH: 'IRONSMITH',
  IT: 'IT',
  'JEWELER MAKER': 'JEWELER MAKER',
  JOURNALIST: 'JOURNALIST',
  'KEY MAKER': 'KEY MAKER',
  'LAUNDRY WORKER': 'LAUNDRY WORKER',
  'LEATHER INDUSTRIES WORKER': 'LEATHER INDUSTRIES WORKER',
  'LIVESTOCK AND POULTRY FARMER': 'LIVESTOCK AND POULTRY FARMER',
  'LOADING AND UNLOADING WORKER': 'LOADING AND UNLOADING WORKER',
  MAGICIAN: 'MAGICIAN',
  'MAINTENANCE WORKER': 'MAINTENANCE WORKER',
  'MANUAL KNITTING WORKER': 'MANUAL KNITTING WORKER',
  'MANUFACTURING ASSEMBLER WORKER': 'MANUFACTURING ASSEMBLER WORKER',
  'MARITIME SERVICES WORKER': 'MARITIME SERVICES WORKER',
  MARKETING: 'MARKETING',
  MECHANIC: 'MECHANIC',
  METEOROLOGIST: 'METEOROLOGIST',
  'MILLING WORKER': 'MILLING WORKER',
  'MINING WORKER': 'MINING WORKER',
  'NEW BORN': 'NEW BORN',
  'NEWS PRESENTER': 'NEWS PRESENTER',
  'OIL EXTRACT PROCESS WORKER': 'OIL EXTRACT PROCESS WORKER',
  'OIL TANKER FILLING WORKER': 'OIL TANKER FILLING WORKER',
  OTHER: 'OTHER',
  'OYSTERS FARMER': 'OYSTERS FARMER',
  'PACKING PACKAGING WORKER': 'PACKING PACKAGING WORKER',
  PAINTER: 'PAINTER',
  'PAPER INDUSTRY WORKER': 'PAPER INDUSTRY WORKER',
  PARENT: 'PARENT',
  'PENCILS MANUFACTURER': 'PENCILS MANUFACTURER',
  'PESTICIDES WORKER': 'PESTICIDES WORKER',
  PHOTOGRAPHER: 'PHOTOGRAPHER',
  'PLASTIC AND RUBBER INDUS WORKER': 'PLASTIC AND RUBBER INDUS WORKER',
  PLAYER: 'PLAYER',
  PLUMBER: 'PLUMBER',
  POLICEMAN: 'POLICEMAN',
  'PORT STAFF': 'PORT STAFF',
  PORTER: 'PORTER',
  POSTMAN: 'POSTMAN',
  'POTTERY AND CERAMICS WORKER': 'POTTERY AND CERAMICS WORKER',
  'PROPELLING AND STEERING MECHANIC WORKER':
    'PROPELLING AND STEERING MECHANIC WORKER',
  PROSTHETIST: 'PROSTHETIST',
  'PUBLIC RELATION REPRESENTATIVE': 'PUBLIC RELATION REPRESENTATIVE',
  'QUALITY STAFF': 'QUALITY STAFF',
  'RAILWAY WORKER': 'RAILWAY WORKER',
  'RELIGION MAN': 'RELIGION MAN',
  'RESCUE WORKER': 'RESCUE WORKER',
  RESEARCHER: 'RESEARCHER',
  RETIRED: 'RETIRED',
  SALES: 'SALES',
  'SAMPLE COLLECTOR': 'SAMPLE COLLECTOR',
  'SAND CORE MAKER': 'SAND CORE MAKER',
  'SANDAL MAKER': 'SANDAL MAKER',
  'SCAFFOLDER WORKER': 'SCAFFOLDER WORKER',
  SCULPTOR: 'SCULPTOR',
  SECURITY: 'SECURITY',
  'SEWAGE WORKER': 'SEWAGE WORKER',
  'SHOES INDUSTRY WORKER': 'SHOES INDUSTRY WORKER',
  'SHOP WORKER': 'SHOP WORKER',
  'SILK PRINTER': 'SILK PRINTER',
  SOCIOLOGIST: 'SOCIOLOGIST',
  SOLDIER: 'SOLDIER',
  'SPINNING WORKER': 'SPINNING WORKER',
  'SPONGE MAKER': 'SPONGE MAKER',
  SPORT: 'SPORT',
  'STAMPS MAKER': 'STAMPS MAKER',
  STATISTICIAN: 'STATISTICIAN',
  'STEAM PRESSING WORKER': 'STEAM PRESSING WORKER',
  'STORE WORKER': 'STORE WORKER',
  STUDENT: 'STUDENT',
  SUPERVISOR: 'SUPERVISOR',
  SUPPLIER: 'SUPPLIER',
  SURVEYOR: 'SURVEYOR',
  TAILER: 'TAILER',
  TECHNICIAN: 'TECHNICIAN',
  'TEXTILE WORKER': 'TEXTILE WORKER',
  'TICKET COLLECTOR': 'TICKET COLLECTOR',
  'TIN AND METAL SHEETS WORKER': 'TIN AND METAL SHEETS WORKER',
  'TOBACCO WORKER': 'TOBACCO WORKER',
  TRANSLATOR: 'TRANSLATOR',
  UNEMPLOYED: 'UNEMPLOYED',
  UPHOLSTERER: 'UPHOLSTERER',
  'WASTE TREATMENT WORKER': 'WASTE TREATMENT WORKER',
  WATCHMAN: 'WATCHMAN',
  WELDER: 'WELDER',
  WIDOW: 'WIDOW',
  WIFE: 'WIFE',
  'WOOD AND MATERIAL MANUFACTURER WORKER':
    'WOOD AND MATERIAL MANUFACTURER WORKER',
  WRITER: 'WRITER',
  ZOOLOGIST: 'ZOOLOGIST',
};

export const BUNDLE_STATUSES = {
  active: 'Active',
  inactive: 'Inactive',
  completed: 'Completed',
  refunded: 'Refund Issued',
};

export const APPOINTMENT_DURATION = {
  20: '20',
  30: '30',
  60: '60',
  90: '90',
  120: '120',
};

export const EVENT_CATEGORIES = {
  'workshops-and-seminars': 'Workshops & Seminars',
  'training-and-courses': 'Trainings & Courses',
  'grief-support-groups': 'Grief Support Groups',
  'support-groups': 'Support Groups',
};

export const EVENTS_AGE_CATEOGORIES = {
  '0-9': 'Children (0 - 9 Yrs)',
  '0-11': 'Children (0 - 11 Yrs)',
  '7-9': 'Children (7 - 9 Yrs)',
  '9-12': 'Teens (9 - 12 Yrs)',
  '13-15': 'Teens (13 - 15 Yrs)',
  '15-18': 'Teens (15 - 18 Yrs)',
  '13-16': 'Teens (13 - 16 Yrs)',
  '13-18': 'Teens (13 - 18 Yrs)',
  '16-18': 'Teens (16 - 18 Yrs)',
  '18-99': 'Adults (18 Yrs+)',
  '21-99': 'Adults (21 Yrs+)',
  '25-99': 'Adults (25 Yrs+)',
  '21-101': 'Women (21 Yrs+)',
  '40-99': 'Women (40 Yrs+)',
  '18-100': 'Men (18 Yrs+)',
  '21-100': 'Men (21 Yrs+)',
  '18-101': 'Women (18 Yrs+)',
  '18-102': 'Parents',
  '0-99': 'All ages',
};

export const USER_ROLES = {
  admin: 'Admin',
  finance: 'Finance',
  clinician: 'Clinician',
  frontdesk: 'Frontdesk',
  event: 'Event',
};

export const CLIENT_DOCUMENT_TYPES = {
  uae_id: 'UAE ID',
  Uae_license: "UAE Driver's License",
  gcc: 'GCC',
  id: 'ID',
  passport: 'Passport',
};
