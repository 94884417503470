import {
  createStyles,
  Grid,
  Select,
  Typography,
  Chip,
  Button,
  Container,
} from '@material-ui/core';
import styled from 'styled-components';

const FormStyle = createStyles({
  button1: {
    height: '50px',
    borderRadius: '5px',
    backgroundColor: '#A16207',
    marginTop: '16px',
    marginBottom: '10px',
    cursor: 'pointer',
    '&:hover': {
      opacity: '70%',
    },
  },
  button2Text: {
    fontSize: '18px',
    color: '#ffffff',
    fontFamily: 'Lato',
    fontWeight: 700,
    textAlign: 'center',
  },
  title: {
    fontFamily: 'Lato',
    fontSize: 14,
    textAlign: 'left',
    color: 'rgb(0, 58, 112)',
    fontWeight: '800',
  },
  pos: {
    fontFamily: 'Lato',
    textAlign: 'left',
    color: 'rgb(0, 58, 112)',
    fontWeight: '600',
  },
  inputText: {
    fontSize: '22px',
    lineHeight: '28px',
    marginBottom: '15px',
    color: '#003265',
    padding: '5px',
    paddingLeft: 0,
    textAlign: 'left',
  },
  paper: {
    padding: '8px',
  },
  inputTitle: {
    textAlign: 'left',
    fontSize: '13px',
    color: 'grey',
    fontWeight: '500',
    marginBottom: '4px',
  },
  inputsty: {
    border: '1px solid #e5e5e5',
    borderRadius: '0',
    height: '45px',
    width: '100%',
  },
  imgBox: {
    backgroundColor: '#003265',
    padding: '25px',
    width: '170',
    height: '170',
  },
  img: {
    width: '60%',
  },
  formControl: {
    minWidth: 120,
    backgroundColor: '#fff',
  },
  drag: {
    width: '90%',
    height: '100%',
    borderStyle: 'dashed',
    borderWidth: 2,
    borderColor: '#ccc',
    textAlign: 'center',
    marginLeft: 20,
    color: '#afc2df',
  },
  italicTitle: {
    fontStyle: 'italic',
    fontSize: '13px',
    textAlign: 'left',
    color: '#807c7c',
  },
  tile: {
    padding: '20px',
    backgroundColor: '#f1f4f8',
    textAlign: 'left',
  },
  upload: {
    padding: '25px',
    backgroundColor: '#003265',
    color: '#fff',
    textAlign: 'left',
  },
  errorText: {
    color: 'red',
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: 16,
  },
  button2: {
    height: '50px',
    borderRadius: '5px',
    backgroundColor: 'rgb(0, 58, 112)',
    marginBottom: '10px',
    cursor: 'pointer',
    '&:hover': {
      opacity: '70%',
    },
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
  imageContainer: {
    width: '100%',
    backgroundColor: 'rgba(34,34,34)',
    position: 'relative',
  },
  imageUpperContainer: {
    display: 'block',
    marginleft: 'auto',
    marginright: 'auto',
    width: '50%',
  },
  imageTextContainer: {
    position: 'absolute',
    textAlign: 'center',
    top: '340px',
  },
  image: {
    width: '100%',
    objectFit: 'cover',
  },
  drImageContainer: {
    width: '100px',
    height: '100px',
    borderRadius: '50px',
    border: '2px solid #ffffff',
  },
  drName: {
    fontSize: '18px',
    lineHeight: '22px',
    color: 'rgb(0, 58, 112)',
    fontWeight: 700,
    fontFamily: 'Lato',
  },
  drDesc: {
    fontSize: '16px',
    lineHeight: '18px',
    color: '#A16207',
    fontWeight: 400,
    fontFamily: 'Lato',
  },
  buttonContainer: {
    width: '222px',
    height: '35px',
    borderRadius: '5px',
    backgroundColor: '#A16207',
    fontSize: '18px',
    color: '#ffffff',
    fontWeight: '400',
    fontFamily: 'lato',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      opacity: '70%',
    },
  },
  textName: {
    fontSize: '22px',
    lineHeight: '22px',
    color: 'rgb(0, 58, 112)',
    fontWeight: 700,
    fontFamily: 'Lato',
    marginTop: '10px',
  },
  textTitle: {
    fontSize: '16px',
    lineHeight: '20px',
    color: '#A16207',
    fontWeight: 700,
    fontFamily: 'Lato',
    marginTop: '5px',
  },
  textDesc: {
    fontSize: '16px',
    lineHeight: '20px',
    color: 'rgb(0, 58, 112)',
    fontWeight: 400,
    fontFamily: 'Lato',
    marginTop: '5px',
  },
  profileImage: {
    width: '37.5px',
    height: '37.5px',
    bottom: '-18px',
    right: '46%',
    position: 'absolute',
  },
  poster: {
    position: 'relative',
  },
  thankYou: {
    color: '#003265',
    fontSize: '24px',
  },
  smallBox: {
    width: '50%',
    paddingLeft: '15px',
  },
});

const FormContainer = styled(Container)`
  max-width: 1000px;
  min-height: 100vh;
  height: auto;
  margin-top: 20px;
`;

const SelectBox = styled(Select)`
  color: #b3b3b3;
  border-radius: 0px;
  width: 100px;
  background-color: white;
  &:focus {
    background-color: white;
  }
`;

const MarkRequired = styled.span`
  color: rgb(0, 58, 112);
  margin-left: 2px;
`;

const Title = styled(Typography)`
  color: rgb(0, 58, 112);
  font-family: Lato;
  font-weight: 700;
  font-style: italic;
  font-size: 24px;
`;

const Experties = styled(Typography)`
  color: rgb(163, 145, 97);
  font-family: Lato;
  font-size: 20px;
`;

const Tag = styled(Chip)`
  height: auto;
  border-radius: 16px !important;
  background-color: rgb(163, 145, 97) !important;
  color: #f0ede6 !important;
  margin-right: 5px !important;
  & .MuiChip-label {
    font-family: Lato;
    font-weight: 700;
    font-size: 14px;
    padding: 3px 12px;
  }
`;

const Subject = styled(Typography)`
  color: rgb(0, 58, 112);
  font-family: Lato;
  font-weight: 700;
  font-size: 24px;
  margin-top: 15px;
`;
const Key = styled(Typography)`
  color: rgb(163, 145, 97);
  font-family: Lato;
  font-size: 14px;
`;

const Value = styled(Typography)`
  color: rgb(0, 58, 112);
  font-family: Lato;
  font-size: 14px;
  margin-left: 7px;
  text-transform: ${(p) =>
    p.case === 'lowercase' ? 'lowercase' : 'capitalize'};
`;

const PosterGrid = styled(Grid)`
  height: max-content;
  padding-bottom: 20px;
  margin-top: 15px;
  background-color: #fff;
  width: 48%;
  @media (max-width: 1280px) {
    width: 100%;
  }
`;
const TagGrid = styled(Grid)`
  margin-top: 15px;
`;

const MainGrid = styled(Grid)`
  margin-top: 15px;
`;

const VenueGrid = styled(Grid)`
  margin-top: 15px;
`;

const Heading = styled(Typography)`
  color: rgb(163, 145, 97);
  font-family: Lato;
  font-weight: 700;
  font-size: 20px;
`;

const BackButton = styled(Button)`
  padding: 0px;
  font-size: 12px;
  &:hover {
    background-color: transparent;
  }
  &:focus {
    background-color: transparent;
  }
`;

const CardsGrid = styled(Grid)`
  padding: 15px;
`;

const Card = styled(Grid)`
  width: 100%;
  margin: 8px 0 10px;
  background-color: #f8f7f3;
  padding: 10px;
`;

const CardTitle = styled(Typography)`
  color: rgb(0, 58, 112);
  font-family: Lato;
  font-weight: 700;
  font-size: 22px;
`;

const InfoText = styled(Typography)`
  color: rgb(0, 58, 112);
  font-family: Lato;
  font-size: 16px;
`;

const CardInfo = styled(Grid)`
  margin-top: 10px;
`;

const FormGrid = styled(Grid)`
  background-color: #f0ede6;
  position: relative;
  margin-top: 20px;
`;

const Discription = styled(Typography)`
  color: rgb(163, 145, 97);
  font-family: Lato;
  font-weight: 300;
  font-size: 20px;
  text-transform: uppercase;
`;

const Form = styled(Grid)`
  width: 48%;
  @media (max-width: 1280px) {
    width: 100%;
  }
`;

const TotalAmount = styled(Typography)`
  margin: 0px 15px;
  height: 50px;
  border-radius: 2px;
  font-weight: bold;
`;

const Content = styled(Grid)`
  @media (max-width: 1280px) {
    flex-direction: column-reverse;
  }
`;

export {
  FormStyle,
  SelectBox,
  MarkRequired,
  Title,
  Experties,
  Tag,
  Subject,
  Key,
  Value,
  PosterGrid,
  TagGrid,
  VenueGrid,
  Heading,
  BackButton,
  CardsGrid,
  CardTitle,
  Card,
  CardInfo,
  InfoText,
  MainGrid,
  FormGrid,
  FormContainer,
  Discription,
  Form,
  TotalAmount,
  Content,
};
