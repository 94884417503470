import { AppBar, Tab } from '@material-ui/core';
import styled from 'styled-components';

const StyledAppBar = styled(AppBar)`
  background-color: transparent;
  color: #003265;
  box-shadow: none;
  border-bottom: 1px solid #ddd;
`;
const StyledTab = styled(Tab)`
  text-transform: none;
  color: #003265;
  font-weight: bold;
  font-size: 16px;
`;

export { StyledTab, StyledAppBar };
