import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FilterDrawer from './FilterDrawer';
import FilterModalBox from './FilterModalBox';
import AppointmentDetailsView from './AppointmentDetailsView';
import Axios from 'axios';
import { baseURL } from '../utils/axios';
import FilterImage from '../assets/clinician_image//Group 351@2x.png';
import Loader from '../components/Loader/Loader';
import PatientDetailClinician from './Patients/patientDetail';
import {
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import './clinician.scss';
import userIcon from '../assets/imgs/download.png';
import Layout from './Layout';
import NewPatientsReferral from './components/NewPatientsReferralModal';
import ScheduleModal from './components/ScheduleModal';
import AlertMsg from '../utils/Alert';
import clinician1 from '../assets/imgs/clinician1.png';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory, useLocation } from 'react-router-dom';
import NewAppointmentView from '../common/NewAppointment';
import moment from 'moment';
import useStyles from './AppointmentClinician.styles';
import { getPageCount } from '../utils/MathUtil';
import LocationModal from './components/LocationModal';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  filterAppointments,
  updateToSeen,
} from '../utils/actions/appointment.action';
import CalenderCreateApointment from '../common/CalenderCreateNewAppointments';
import { getTimezoneOffset, calculateAge } from '../utils/timUtils';
import { STATUSES, LOCATION, APPOINTMENT_TYPES } from '../utils/enums';
import {
  Dialog,
  Button as ConfirmBtn,
  MultipleProfilesDisplay,
} from '../components/atoms';

const SortBtn = styled(Button)`
  padding: 0px;
  text-transform: none;
  width: max-content;
  &:hover {
    background-color: transparent;
  }
`;

const UnWrappedCell = styled(TableCell)`
  white-space: nowrap; ;
`;

const AppointmentClinician = (props) => {
  const classes = useStyles();
  const [detailDrawer, setDetailDrawer] = useState(false);
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [appointmentId, setAppointmentId] = useState('');
  const [appointmentList, setAppointmentList] = useState([]);
  const [filterId, setFilterId] = useState('');
  const [isLoaded, setIsloaded] = useState(true);
  const [patientDrawer, setPatientDrawer] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [newReferralModalOpen, setNewReferralModalOpen] = useState(false);
  const [modalActionType, setModalActionType] = useState('');
  const [patientList, setPatientList] = useState([]);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [clinicianDetails, setClinicianDetails] = useState(null);
  const [newAppointmentPatient, setNewAppointmentPatient] = useState('');
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [open, setOpen] = useState(false);
  const loading = open && patientList.length === 0;
  const [searchPText, setSearchPText] = useState('');
  const [confirmBtnLoading, setConfirmBtnLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [detailsOnPatient, setDetailsOnPatient] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [cost, setCost] = useState(100);
  const [duration, setDuration] = useState(60);
  const [selectedFilter, setSelectedFilter] = useState('upcoming');
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [appointmentLocation, setAppointmentLocation] = useState('online');
  const [sortType, setSortType] = useState('asc');
  const [sortBy, setSortBy] = useState('');
  const [multiFilter, setMultiFilter] = useState(false);
  const [filters, setFilters] = useState({});
  const [appointmentType, setAppointmentType] = useState('');
  const [openAppointmentDetails, setOpenAppointmentDetails] = useState(false);
  const [resources, setResources] = useState([]);
  const [events, setEvents] = useState([]);
  const [openCreateAppointment, setOpenCreateAppointment] = useState(false);
  const [openStartSessionConfirmation, setStartSessionConfirmation] =
    useState(false);
  // useEffect(() => {
  //   if (localStorage.getItem('startedSessionId')) {
  //     props.history.push(
  //       `/clinician/appointments/appointment-details/${localStorage.getItem(
  //         'startedSessionId'
  //       )}`
  //     );
  //   }
  //   getAppointments();
  // }, []);

  useEffect(() => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    let page = currentUrlParams.get('page');
    setPage(page ? parseInt(page) : 1);
  }, [location]);

  useEffect(() => {
    if (modalActionType === 'patients') {
      let active = true;
      if (!loading) {
        return undefined;
      }
      getClinicianPatientList(searchPText);
      return () => {
        active = false;
      };
    }
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setPatientList([]);
    }
  }, [open]);

  useEffect(() => {
    if (appointmentId) {
      console.log(appointmentId);
      if (!props.loading && !props.error) {
        history.push(
          '/clinician/appointments/appointment-details/' + appointmentId
        );
      } else {
        if (props.message) {
          AlertMsg('error', props.message);
        }
      }
    }
  }, [props.loading, props.error]);

  useEffect(() => {
    getAppointments();
  }, [page, filterId, searchText, sortType, sortBy]);

  useEffect(() => {
    if (!props.applyingFilters) {
      setAppointmentList(props.filteredAppointments);
      setTotalCount(props.count);
      console.log("\n\n\n\n", props.filteredAppointments)
    }

    if (props.filteringErr) {
      AlertMsg('error', 'Something went wrong');
    }

    setIsloaded(false);
  }, [props.applyingFilters, props.filteredAppointments]);

  const handleSearchAutocomplete = (event) => {
    setSearchPText(event.target.value);
    getClinicianPatientList(searchPText);
  };

  const getAppointments = () => {
    if (multiFilter) {
      applyMultipleFilters(filters, sortBy, sortType);
      return;
    }

    setIsloaded(true);
    var uid = localStorage.getItem('clinicianUID');
    Axios.get(
      `${baseURL}/api/appointment/user/${uid}?filterId=${filterId}&page=${page}&term=${searchText}&status=${[
        selectedFilter,
      ]}&sortBy=${sortBy}&sortType=${sortType}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        if (res.data) {
          setAppointmentList(res.data.data);
          setTotalCount(res.data.count);
        }
        setIsloaded(false);
      })
      .catch((e) => {
        setIsloaded(false);
        console.log('eeeeeeee', e);
      });
  };

  const getClinicianPatientList = (search) => {
    var uid = localStorage.getItem('clinicianUID');
    Axios.get(
      `${baseURL}/api/appointment/searchUser?q=${search ? search : ''}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        if (res.data.length) {
          let users = res.data.map((item) => {
            return { id: item._id, name: item.firstName + ' ' + item.lastName };
          });
          users = users.filter(
            (thing, i, arr) => arr.findIndex((t) => t.id === thing.id) === i
          );

          setPatientList(users);
          getClinicianDetails(uid);
        }
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const getClinicianDetails = (id) => {
    Axios.get(`${baseURL}/api/user/${id}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        let clinicianData = getFormatedClinicianData(res.data);
        setClinicianDetails(clinicianData);
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
        setClinicianDetails(null);
      });
  };

  const getAllClinicians = () => {
    Axios.get(`${baseURL}/api/user/clinicians/`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        let clinicians = res.data.filter((item) => {
          if (item._id !== localStorage.getItem('clinicianUID')) {
            let tmpObj = item;
            tmpObj.id = item._id;
            tmpObj.name = item.firstName + ' ' + item.lastName;
            return tmpObj;
          }
        });
        setPatientList(clinicians);
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
        setPatientList(null);
      });
  };

  const getFormatedClinicianData = (uData) => {
    let nextAvailable = null;
    let clinician = uData;
    let schedules = uData.schedule ? uData.schedule : [];
    if (schedules && schedules.length) {
      schedules = schedules.map((sch) => {
        if (sch.slots.length) {
          sch.slots = sch.slots.map((s) => {
            if (s) {
              let timeArr = s.startTime.split(':');
              let hrs = '00',
                min = '00';
              if (timeArr.length) {
                hrs = timeArr[0];
                min = timeArr[1];
              }
              if (timeArr[0].length === 1) {
                hrs = `0${timeArr[0]}`;
              }
              if (timeArr[1].length === 1) {
                min = `0${timeArr[1]}`;
              }
              let time = `${hrs}:${min}`;
              let lclDate = new Date(`${sch.date}T${time}:00Z`);
              let lclHrs = lclDate.getHours().toString();
              if (lclHrs.length === 1) {
                lclHrs = `0${lclHrs}`;
              }
              return `${lclHrs}:${
                lclDate.getMinutes() > 0 ? lclDate.getMinutes() : '00'
              }`;
            } else {
              return s;
            }
          });
        }
        return sch;
      });
      for (let sch of schedules) {
        if (sch.slots.length) {
          nextAvailable = sch;
          break;
        }
      }
    }
    const data = {
      id: clinician._id,
      name: `Dr. ${clinician.firstName} ${clinician.lastName}`,
      study: '',
      speciality: 'Specialist Psychiatrist',
      times: schedules,
      img: clinician.avatar ? clinician.avatar : clinician1,
      cost: null,
      appointmenSlot: clinician.appointmentSlotUnit,
      amount: clinician.costPerUnit,
      selectTimeSlot: null,
      isAlternative: false,
      nextAvailable,
      nextAvailiblityText: getNextAvailibility(schedules),
    };
    if (clinician._id !== localStorage.getItem('clinicianUID')) {
      data.cost = `${clinician.costPerUnit} Dhs / ${clinician.appointmentSlotUnit}min`;
    }
    return data;
  };

  const getDate = (date) => {
    var today = new Date(date);
    var d = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var m = today.getMonth() < 10 ? '0' + today.getMonth() : today.getMonth();
    var modifiedDate = d + '/' + m + '/' + today.getFullYear();
    return modifiedDate;
  };

  const getNextAvailibility = (schedules) => {
    let result = '';
    if (schedules.length) {
      let day = new Date();
      day.setDate(day.getDate() + 1);
      let nextDay = getDate(day);
      if (schedules[0].date == nextDay) {
        result = 'Tomorrow';
      } else if (new Date(schedules[0].date) == day) {
        result = 'Today';
      } else {
        result = schedules[0].date;
      }
    } else {
      result = 'Not Available';
    }
    return result;
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setFilterDrawer(open);
  };

  const handleCircle = (status) => {
    var color = 'orange';
    if (status == 'paid') {
      color = 'green';
    } else if (status == 'unpaid') {
      color = 'red';
    }
    return (
      <div
        style={{
          width: '15px',
          height: '15px',
          backgroundColor: color,
          borderRadius: '50%',
        }}
      ></div>
    );
  };

  const handleDetailDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDetailDrawer(open);
    // setState({ ...state, ["ri"]: open });
  };

  const filterAppointment = (id) => setFilterId(id);

  const handleSubmitRequest = (params) => {
    let axiosConfig = {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    };
    Axios.post(`${baseURL}/api/filter`, params, axiosConfig)
      .then((res) => {
        setFilterDrawer(false);
        setFilterId(res.data._id);
        // getAppointments(res.data._id, searchText);
        // setAppointmentList(res.data)
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const togglePatientDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setPatientDrawer(open);
    // setState({ ...state, ["ri"]: open });
  };

  const openPatientDrawer = (id) => {
    setPatientDrawer(true);
    setDetailsOnPatient(true);
    setPatientId(id);
  };

  const handleAlternativeCalendar = () => {
    setClinicianDetails({
      ...clinicianDetails,
      isAlternative: !clinicianDetails.isAlternative,
    });
  };

  const handleTimeSelect = (value) => {
    setClinicianDetails({
      ...clinicianDetails,
      status: 'selected',
      selectTimeSlot: value.target.value,
      isAlternative: false,
    });
  };

  const formatDateYMD = (x, y) => {
    var z = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds(),
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
      return ((v.length > 1 ? '0' : '') + eval('z.' + v.slice(-1))).slice(-2);
    });

    return y.replace(/(y+)/g, function (v) {
      return x.getFullYear().toString().slice(-v.length);
    });
  };

  const handleCalendarTimeSelect = (times, value, date) => {
    let newDate = formatDateYMD(date, 'yyyy-MM-dd');
    setClinicianDetails({
      ...clinicianDetails,
      status: 'selected',
      selectTimeSlot: new Date(`${newDate} ${value}`),
      isAlternative: false,
    });
  };
  const handleCloseLocation = () => {
    setModalActionType('');
    setOpenLocationModal(false);
  };

  const handleNewORreferralClick = (pData) => {
    if (pData) {
      if (modalActionType === 'patients') {
        setNewAppointmentPatient(pData);
        setOpenLocationModal(true);
        setNewReferralModalOpen(false);
        setModalActionType('location');
      } else if (modalActionType === 'location') {
        setAppointmentLocation(pData);
        setOpenLocationModal(false);
        setOpenScheduleModal(true);
      } else if (modalActionType === 'init_referral') {
        let clinicianData = getFormatedClinicianData(pData);
        setClinicianDetails(clinicianData);
      }
    }
  };

  const handleCloseSchedule = () => {
    setOpenScheduleModal(false);
  };

  const handleCancelAppointment = (index) => {
    let tmpClinicianDetails = { ...clinicianDetails };
    tmpClinicianDetails = {
      ...tmpClinicianDetails,
      selectTimeSlot: null,
    };
    setClinicianDetails({ ...tmpClinicianDetails });
  };

  const handleAppointmentConfirm = () => {
    setMultiFilter(false);
    if (newAppointmentPatient) {
      setConfirmBtnLoading(true);
      let data;
      if (modalActionType === 'location') {
        data = {
          clinicianId: localStorage.getItem('clinicianUID'),
          startTime: moment(clinicianDetails.selectTimeSlot).utc(),
          duration: duration != 0 ? duration : clinicianDetails.appointmenSlot,
          amount: cost != 0 ? cost : clinicianDetails.amount,
          clientId: [newAppointmentPatient.id],
          status: 'confirmed',
          appointmentLocation,
        };
      } else {
        if (!appointmentDetails) {
          return false;
        }
        let clientIds = appointmentDetails.clientId.map((item) => {
          return item._id;
        });
        data = {
          clinicianId: localStorage.getItem('clinicianUID'),
          startTime: moment(clinicianDetails.selectTimeSlot).utc(),
          duration: duration != 0 ? duration : clinicianDetails.appointmenSlot,
          amount: clinicianDetails.amount,
          bookedBy: localStorage.getItem('clinicianUID'),
          clientId: clientIds,
          status: 'confirmed',
          appointmentLocation,
        };
      }

      Axios.post(`${baseURL}/api/appointment/followUpAppointment`, data, {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      })
        .then((res) => {
          if (res.data) {
            //setClinicianDetails(null);
            setOpenScheduleModal(false);
            setNewReferralModalOpen(false);
            setNewAppointmentPatient('');
            setModalActionType('');
            setConfirmBtnLoading(false);
            AlertMsg('success', 'Appointment successfully booked.');
            getAppointments();
          }
        })
        .catch((e) => {
          console.log('eeeeeeee', e.response.data.reason);
          if (e.response.data.reason) {
            AlertMsg('error', e.response.data.reason);
          }
          setConfirmBtnLoading(false);
        });
    }
  };

  const handleInitReferralClick = () => {
    getAllClinicians();
    setModalActionType('init_referral');
    setNewReferralModalOpen(true);
  };

  const handleEditRecord = () => {
    props.history.push(
      `/clinician/appointments/editPatientRecord/${appointmentDetails._id}`
    );
  };

  const handlePageChange = (event, value) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', value);
    history.push(window.location.pathname + '?' + currentUrlParams.toString());
    setPage(value);
  };

  const handleReschedule = () => {
    getAppointments();
    setDetailDrawer(false);
    setAppointmentId(appointmentId);
  };

  const handleChange = (event, value) => {
    let val = value.props.value;
    setIsloaded(true);
    setMultiFilter(false);
    setSelectedFilter(val);

    const status = [val];

    var uid = localStorage.getItem('clinicianUID');
    Axios.get(
      `${baseURL}/api/appointment/user/${uid}?filterId=${filterId}&page=${page}&term=${searchText}&status=${status}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        if (res.data) {
          setAppointmentList(res.data.data);
          setTotalCount(res.data.count);
        }
        setIsloaded(false);
      })
      .catch((e) => {
        setIsloaded(false);
        console.log('eeeeeeee', e);
      });
  };

  const handleCheckIn = (id) => {
    let data = {
      checkedIn: true,
    };

    Axios.patch(`${baseURL}/api/appointment/checkin/${id}`, data, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res) {
          const { status, checkedIn } = res.data;
          const newList = appointmentList.map((item) => {
            if (item._id === id) {
              return {
                ...item,
                status,
                checkedIn,
              };
            } else {
              return item;
            }
          });
          setAppointmentList(newList);
          if (status === 'checked-in') {
            AlertMsg('success', 'Succesfully checked in Appointment');
          } else {
            AlertMsg('success', 'Succesfully reverted checked in status');
          }
        }
      })
      .catch((e) => {
        AlertMsg('error', 'Something went wrong!');
        console.log('eeeeeeee', e);
      });
  };

  const sort = (key) => {
    const toggleType = sortType === 'asc' ? 'desc' : 'asc';
    setSortType(toggleType);
    setSortBy(key);
  };

  const applyMultipleFilters = (appliedFilters, sortBy, sortType) => {
    setMultiFilter(true);
    setFilterDrawer(false);
    setFilters(appliedFilters);
    setIsloaded(true);
    const stringifyFilters = JSON.stringify(appliedFilters);
    props.filterAppointments(stringifyFilters, page, sortBy, sortType);
  };

  const closeCreateAppointmentDrawer = () => {
    setOpenCreateAppointment(false);
    setDetailDrawer(false);
    getAppointments();
  };

  const openCreateAppointmentDrawer = () => {
    setAppointmentDetails({
      type: 'All-Patient',
      clinicianId: localStorage.getItem('clinicianUID'),
    });
    setModalActionType('patients');
    setOpenCreateAppointment(true);
  };

  const produceCurrStatus = (status) => {
    let statusText = 'Check in';

    if (
      status === 'waiting' ||
      status === 'reffered-waiting' ||
      status === 'reffered' ||
      status === 'unconfirmed'
    ) {
      statusText = 'Booking';
    } else if (status === 'checked-in') {
      statusText = 'Start session';
    } else if (status === 'seen') {
      statusText = 'In Session';
    } else if (status === 'ended' || status === 'completed') {
      statusText = 'Completed';
    } else if (status === 'cancelled') {
      statusText = 'Cancelled';
    }
    return statusText;
  };

  const updateToCheckIn = (row) => {
    if (row.status === 'confirmed') {
      handleCheckIn(row._id);
    } else if (row.status === 'checked-in') {
      setStartSessionConfirmation(true);
      setAppointmentId(row._id);
    } else if (
      row.status === 'waiting' ||
      row.status === 'reffered-waiting' ||
      row.status === 'reffered' ||
      row.status === 'unconfirmed'
    ) {
      handleBook(row);
    }
  };

  const handleAppointmentBooking = (data) => {
    handleBook(data);
  };

  const handleBook = (data) => {
    setAppointmentDetails(data);
    setAppointmentType({
      clinicianId: localStorage.getItem('clinicianUID'),
    });
    setModalActionType('patients');
    setOpenCreateAppointment(true);
  };

  const showActionBtn = (record) => {
    if (selectedFilter === 'archived') {
      return false;
    }
    return (
      record.status === 'waiting' ||
      record.status === 'confirmed' ||
      record.status === 'checked-in' ||
      record.status === 'reffered' ||
      record.status === 'reffered-waiting'
    );
  };

  const renderAge = (record) => {
    if (record.clientId[record.clientId.length - 1]) {
      return calculateAge(
        new Date(record.clientId[record.clientId.length - 1].dob)
      );
    }
  };

  const handleSessionStart = () => {
    setStartSessionConfirmation(false);
    props.updateToSeen(appointmentId);
  };

  const handleSessionCancel = () => {
    setAppointmentId('');
    setStartSessionConfirmation(false);
  };

  return (
    <Layout
      filterId={filterId}
      filterAppointment={filterAppointment}
      appointments={appointmentList}
    >
      <Grid container>
        <Grid xs={6}>
          <OutlinedInput
            value={searchText}
            className={classes.inputstyle}
            style={{ float: 'left' }}
            placeholder='Search'
            onChange={(evt) => {
              setPage(1);
              //getAppointments(filterId);
              setSearchText(evt.target.value);
            }}
            id='outlined-adornment-password'
            endAdornment={
              <InputAdornment position='end'>
                <IconButton aria-label='toggle password visibility'>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          <FormControl
            variant='outlined'
            className={classes.formControl}
            style={{
              width: '200px',
            }}
          >
            <Select
              className={`${classes.inputstyle} ${classes.selectEmpty}`}
              defaultValue={selectedFilter}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value='upcoming'>Upcoming</MenuItem>
              <MenuItem value='archived'>Archived</MenuItem>
              <MenuItem value='reffered'>Referred</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={6}>
          <div style={{ float: 'right' }}>
            <Button
              variant='contained'
              onClick={openCreateAppointmentDrawer}
              className={classes.btnNewApp}
              style={{ height: '49px' }}
            >
              <AddCircleOutlineIcon style={{ marginRight: 5 }} />
              New Appointment
            </Button>
            <Button
              variant='contained'
              style={{
                height: '49px',
                overflow: 'hidden',
                maxWidth: '47px',
                minWidth: 'unset',
                marginLeft: '12px',
              }}
              onClick={() => setFilterDrawer(true)}
              className={classes.btnNewApp}
            >
              {/* <HorizontalSplitIcon /> */}
              <img src={FilterImage} width='50' />
            </Button>
          </div>
        </Grid>
      </Grid>

      <Grid container>
        <TableContainer>
          <Table className={classes.table} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <UnWrappedCell>
                  <SortBtn onClick={() => sort('chartId')}>
                    Chart Number
                  </SortBtn>
                </UnWrappedCell>
                <UnWrappedCell>
                  <SortBtn onClick={() => sort('name')}>Patient Name</SortBtn>
                </UnWrappedCell>

                <UnWrappedCell>Age</UnWrappedCell>
                <UnWrappedCell>
                  <SortBtn onClick={() => sort('date')}>Date and time</SortBtn>
                </UnWrappedCell>
                <UnWrappedCell>Appointment type</UnWrappedCell>
                <UnWrappedCell>Appointment Status</UnWrappedCell>
                <UnWrappedCell>
                  <SortBtn onClick={() => sort('duration')}>Duration</SortBtn>
                </UnWrappedCell>
                <UnWrappedCell>
                  <SortBtn onClick={() => sort('location')}>Location</SortBtn>
                </UnWrappedCell>
                <UnWrappedCell>Payment Status</UnWrappedCell>
                <UnWrappedCell align='right'>Action (view)</UnWrappedCell>
              </TableRow>
            </TableHead>
            <TableBody component={Paper}>
              {isLoaded ? (
                <TableRow>
                  <TableCell colSpan={12} style={{ textAlign: 'center' }}>
                    <Loader />
                  </TableCell>
                </TableRow>
              ) : appointmentList && appointmentList.length > 0 ? (
                appointmentList.map((row, i) => {
                  let zIndexCnt =
                    row.clientId && row.clientId.length
                      ? row.clientId.length
                      : 0;
                  return (
                    <TableRow key={i}>
                      <TableCell component='th' scope='row'>
                        {row.clientId.length > 0
                          ? row.clientId[0].chartId
                          : row.clientId.chartId}
                      </TableCell>

                      <TableCell className={classes.userImages}>
                        {row.clientId[0] && (
                          <MultipleProfilesDisplay profiles={row.clientId} />
                        )}
                      </TableCell>

                      <UnWrappedCell>{renderAge(row)}</UnWrappedCell>
                      <TableCell>
                        {row.startTime
                          ? moment(new Date(row.startTime)).format('LLL')
                          : 'NA'}
                      </TableCell>
                      <TableCell>{row?.appointmentType?.type}</TableCell>
                      <TableCell
                        style={
                          STATUSES[row.status] == 'Missed' ||
                          STATUSES[row.status] == 'Cancelled'
                            ? { color: 'red' }
                            : {}
                        }
                      >
                        {STATUSES[row.status]}
                      </TableCell>
                      <TableCell>
                        {row.duration ? row.duration : 0} Minutes
                      </TableCell>
                      <TableCell>
                        {row.appointmentLocation
                          ? LOCATION[row.appointmentLocation]
                          : ''}
                      </TableCell>

                      <TableCell>
                        {handleCircle(row.billingId.status)}
                      </TableCell>
                      <TableCell>
                        {showActionBtn(row) && (
                          <Button
                            variant='contained'
                            className={
                              row.status === 'checked-in'
                                ? classes.checkedInBtn
                                : row.status == 'seen'
                                ? classes.seenBtn
                                : classes.viewBtn
                            }
                            onClick={() => updateToCheckIn(row)}
                          >
                            {produceCurrStatus(row.status)}
                          </Button>
                        )}
                      </TableCell>
                      <TableCell align='right'>
                        <Button
                          variant='contained'
                          className={classes.viewBtn}
                          onClick={() => {
                            setDetailsOnPatient(false);
                            setDetailDrawer(true);
                            setAppointmentId(row._id);
                            setAppointmentDetails(row);
                          }}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={12}>
                    <Typography style={{ textAlign: 'center' }}>
                      No Data Found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!isLoaded && appointmentList && appointmentList.length > 0 && (
          <Grid justify='flex-end' container>
            <div className={classes.paginationRoot}>
              <Pagination
                count={getPageCount(totalCount)}
                page={page}
                onChange={handlePageChange}
                color='primary'
                shape='rounded'
              />
            </div>
          </Grid>
        )}
      </Grid>
      <FilterDrawer
        toggleDrawer={toggleDrawer}
        filterDrawer={filterDrawer}
        handleSubmitRequest={handleSubmitRequest}
        applyMultipleFilters={applyMultipleFilters}
        isClinician
      />
      {/* <FilterModalBox
        handleDetailDrawer={handleDetailDrawer}
        appointmentId={appointmentId}
        detailDrawer={detailDrawer}
        handleSubmitRequest={handleSubmitRequest}
        setDetailDrawer={setDetailDrawer}
        detailsOnPatient={false}
        openPatientDrawer={openPatientDrawer}
        handleInitReferralClick={handleInitReferralClick}
        handleEditRecord={handleEditRecord}
        refresh={handleReschedule}
      /> */}

      <AppointmentDetailsView
        appointmentId={appointmentId}
        handleClose={() => setDetailDrawer(false)}
        open={detailDrawer}
        handleInitReferralClick={() => {}}
        handleEditRecord={() => {}}
        handleAppointmentBooking={handleAppointmentBooking}
        refresh={() => {
          getAppointments();
        }}
      />
      <PatientDetailClinician
        togglePatientDrawer={togglePatientDrawer}
        patientDrawer={patientDrawer}
        patientId={patientId}
      />
      {modalActionType && (
        <NewPatientsReferral
          actionType={modalActionType}
          open={newReferralModalOpen}
          handleClose={(evt) => {
            setModalActionType('');
            setNewReferralModalOpen(false);
            setPatientList([]);
          }}
          handleSubmit={handleNewORreferralClick}
          userList={patientList}
          loading={loading}
          openAutoComp={open}
          setOpenAutoComp={setOpen}
          handleSearchAutocomplete={handleSearchAutocomplete}
        />
      )}
      <NewAppointmentView
        clinicianDetails={clinicianDetails}
        open={openScheduleModal}
        handleClose={handleCloseSchedule}
        handleCalendarTimeSelect={handleCalendarTimeSelect}
        handleCancelAppointment={handleCancelAppointment}
        handleAppointmentConfirm={handleAppointmentConfirm}
        handleTimeSelect={handleTimeSelect}
        handleAlternativeCalendar={handleAlternativeCalendar}
        confirmBtnLoading={confirmBtnLoading}
        setCost={setCost}
        setDuration={setDuration}
      />
      <LocationModal
        open={openLocationModal}
        handleClose={handleCloseLocation}
        handleSubmit={handleNewORreferralClick}
        classes={classes}
      />
      <CalenderCreateApointment
        key={new Date().getTime()}
        open={openCreateAppointment}
        clinician={
          appointmentDetails
            ? appointmentDetails.type !== 'init-refferal'
            : true
        }
        appointmentDetail={appointmentDetails}
        handleClose={() => setOpenCreateAppointment(false)}
        handleSubmit={closeCreateAppointmentDrawer}
      />
      <Dialog
        handleClose={handleSessionCancel}
        open={openStartSessionConfirmation}
      >
        <Grid
          spacing={3}
          style={{ padding: '50px' }}
          direction='column'
          container
        >
          <Grid item>
            <Typography>Are you sure you want to start the session?</Typography>
          </Grid>
          <Grid item>
            <Grid spacing={2} justify='flex-end' container>
              <Grid item>
                <Button onClick={handleSessionCancel}>Cancel</Button>
              </Grid>
              <Grid item>
                <ConfirmBtn
                  onClick={handleSessionStart}
                  text='Yes, Start Session'
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </Layout>
  );
};

AppointmentClinician.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  filterAppointments: PropTypes.func.isRequired,
  updateToSeen: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  message: PropTypes.string,
  filteredAppointments: PropTypes.array.isRequired,
  applyingFilters: PropTypes.bool.isRequired,
  filteringErr: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
};

const mapPropsToState = (state) => ({
  filteredAppointments: state.filterAppointments.appointments,
  applyingFilters: state.filterAppointments.applyingFilters,
  filteringErr: state.filterAppointments.error,
  count: state.filterAppointments.count,
  error: state.updateToSeen.error,
  loading: state.updateToSeen.loading,
  message: state.updateToSeen.message,
});

export default connect(mapPropsToState, { filterAppointments, updateToSeen })(
  AppointmentClinician
);
