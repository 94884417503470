/* eslint-disable no-console */
/* eslint-disable no-sequences */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-fallthrough */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import {
  withStyles,
  Typography,
  Grid,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import DatePicker, { registerLocale } from 'react-datepicker';
// import en from 'date-fns/locale/en';
import Select from "react-select";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import Header from "../../common/Header";
import countries_list from '../../utils/countries_data';
import CustomRadio from "../../common/RadioButton";
import {
  CustomCancelButtons,
  CustomNextButtons,
} from "../../common/CustomButtons";
import { FormInput, InputTitle } from "./forms/AddChildForm";
import { getAgeByDob } from "../../utils/validators";
import { addChildAction } from "../../utils/actions/client.action";
import styles from "./AddChildStyle";
import { adultAge } from "../../utils/timUtils";

// registerLocale('en', en);

class AddChild extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      middleName: "",
      lastName: "",
      dob: null,
      gender: "",
      nationality: [],
      errors: {
        firstName: "",
        middleName: "",
        lastName: "",
        dob: null,
        gender: "",
        nationality: [],
      },
    };
  }

  componentDidUpdate(preProps) {
    if (this.props.addChild !== preProps.addChild) {
      this.props.history.goBack();
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    const { errors } = this.state;
    switch (name) {
      case "firstName":
        errors.firstName = !value ? "Please enter firstname" : "";
        break;
      case "lastName":
        errors.lastName = !value ? "Please enter lastname" : "";
        break;
      case "gender":
        errors.gender = !value ? "Please choose sex" : "";
        break;
      default:
        break;
    }
  };

  handleSelectChangeValid = (lable) => {
    const { nationality } = this.state;
    const errors = { ...this.state.errors };
    switch (lable) {
      case "nationality":
        errors.nationality =
          nationality.length > 0 ? null : "Field is required";
        this.setState({ errors });
        break;
      default:
        break;
    }
  };

  handleVerify = () => {
    const { firstName, lastName, dob, nationality } = this.state;
    const errors = { ...this.state.errors };
    errors.firstName = !firstName ? "First name is Required!" : ""
    errors.lastName = !lastName ? "Last name is Required" : "";
    errors.dob = dob === null ? "Date of birth required!" : null;
    errors.nationality = nationality.length > 0 ? null : "Please select nationality!";
    if(dob){
      if(getAgeByDob(dob) > adultAge){
        errors.dob = `Date of birth must be less than ${adultAge} years!`;
      }
    }
    this.setState({ errors });
    if(!errors.firstName &&
      !errors.lastName &&
      !errors.dob &&
      !errors.nationality){
        return true;
    }else{
      return false;
    }
  };

  handleSubmit = () => {
    const {
      errors,
      firstName,
      middleName,
      dob,
      gender,
      lastName,
      nationality,
    } = this.state;
    let isVerify = this.handleVerify();

    if (isVerify) {
      const data = {
        email:"",
        firstName,
        middleName,
        lastName,
        dob,
        gender,
        nationality,
      };
      this.props.addChildAction(data);
    }
  };

  render() {
    const { classes } = this.props;
    const {
      dob,
      firstName,
      gender,
      lastName,
      middleName,
      nationality,
      errors,
    } = this.state;
    const customStyle = {
      control: (styles) => ({
        ...styles,
        borderRadius: "0px",
        border: "1px solid #707070",
        "&:focus": {
          border: "1px solid #707070",
        },
        "&:hover": {
          border: "1px solid #707070",
        },
        boxShadow: "none",
        backgroundColor: "#ffffff",
      }),
      multiValue: (styles) => ({
        ...styles,
        backgroundColor: "rgba(192, 208,222, 0.2)",
        height: "37px",
      }),
      multiValueLabel: (styles) => ({
        ...styles,
        fontSize: "20px",
        color: "#304659",
        fontFamily: "Lato",
        fontWeight: 400,
        zIndex: 1,
        whiteSpace: "nowrap",
      }),
      multiValueRemove: (styles) => ({
        ...styles,
        width: "16px",
        height: "16px",
        margin: "5px",
        marginTop: "10px",
        backgroundColor: "#304659",
        color: "#ffffff",
        ":hover": {
          backgroundColor: "#304659",
          color: "white",
        },
      }),
    };

    
    let countriesList = countries_list.map((item) => {
      return { label: item.name };
    });

    return (
      <div>
        <Header props={this.props} />
        <div className={classes.root} />
        <Grid container justify="center" alignItems="center" component="div">
          <Grid className={classes.titleContainer}>
            <Grid
              container
              alignItems="center"
              justify="center"
            >
              <Grid item className={classes.container}>
                <Grid>
                  <Typography className={classes.title} style={{fontSize:'32px'}}>
                    Add Your Child
                  </Typography>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.bodyContainer}
                  >
                    <Grid
                      container
                      className={classes.gridcolor}
                    >
                      <Grid className={classes.fieldContainer}>
                        <InputTitle text="First Name" />
                        <FormInput
                          type="text"
                          placeholder="Mary"
                          name="firstName"
                          value={firstName}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.firstName && errors.firstName}
                      </span>
                    </Grid>

                    <Grid
                      container
                      className={classes.gridwhite}
                    >
                      <Grid className={classes.fieldContainer}>
                        <InputTitle text="Middle Name" />
                        <FormInput
                          type="text"
                          placeholder="Alexander"
                          name="middleName"
                          value={middleName}
                          onChange={this.handleChange}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      className={classes.gridcolor}
                    >
                      <Grid className={classes.fieldContainer}>
                        <InputTitle text="last Name" />
                        <FormInput
                          type="text"
                          placeholder="Kage"
                          name="lastName"
                          value={lastName}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      {errors.lastName &&
                        <span className={classes.errorText}>
                          {errors.lastName}
                        </span>
                      }
                    </Grid>

                    <Grid
                      container
                      className={classes.gridwhite}
                    >
                      <Grid className={classes.fieldContainer}>
                        <InputTitle text="date of birth" />
                        <DatePicker
                          locale='en'
                          className={classes.dateInput}
                          dateFormat="MMMM d, yyyy "
                          selected={dob}
                          showYearDropdown
                          dropdownMode="select"
                          showMonthDropdown
                          onChange={(date) => {
                            const errors = { ...this.state.errors };
                            errors.dob = null;
                            this.setState({ errors, dob: date });
                          }}
                          onBlur={() => {
                            const errors = { ...this.state.errors };
                            errors.dob =
                              this.state.dob === null
                                ? "Date of birth is required!"
                                : null;
                            this.setState({ errors });
                          }}
                          // onChange={(date) => (
                          //   this.setState({ dob: date }) (dob = null)
                          // )}
                        />
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.dob && errors.dob}
                      </span>
                    </Grid>

                    <Grid
                      container
                      className={classes.gridcolor}
                    >
                      <Grid className={classes.fieldContainer}>
                        <InputTitle text="sex" />
                        <RadioGroup
                          style={{ display: "flex", flexDirection: "row" }}
                          name="gender"
                          value={gender}
                          onChange={this.handleChange}
                        >
                          <FormControlLabel
                            value="male"
                            checked={gender === "male" && true}
                            control={<CustomRadio />}
                            label={
                              <Typography className={classes.labletext}>
                                Male
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            style={{ marginLeft: "35px" }}
                            value="female"
                            checked={gender === "female" && true}
                            control={<CustomRadio />}
                            label={
                              <Typography className={classes.labletext}>
                                Female
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.gender && errors.gender}
                      </span>
                    </Grid>

                    <Grid
                      container
                      className={classes.gridwhite}
                    >
                      <Grid className={classes.fieldContainer}>
                        <InputTitle text="Nationality" />
                        <div className={classes.customNationality}>
                          <Select
                            onBlur={() =>
                              this.handleSelectChangeValid("nationality")
                            }
                            isMulti
                            isSearchable
                            className="react-select-container"
                            classNamePrefix="react-select"
                            // className={classes.nationalitySelect}
                            styles={customStyle}
                            value={nationality}
                            onChange={(nationality) => (
                              this.setState({ nationality }),
                              this.handleSelectChangeValid("nationality")
                            )}
                            options={countriesList}
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                          />
                        </div>
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.nationality && errors.nationality}
                      </span>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justify="center"
                    style={{ marginTop: "30px", marginBottom: "50px" }}
                  >
                    <CustomCancelButtons
                      title="Cancel"
                      width="170px"
                      style={classes.cancelButton}
                      // textStyles={classes.cancelText}
                      // onPressButton={() => this.setState({ isOpen: true })}
                      onPressButton={() => this.props.history.goBack()}
                    />
                    <CustomNextButtons
                      width="170px"
                      title="Next"
                      style={classes.nextButton}
                      onPressButton={this.handleSubmit}
                      // onPressButton={() => this.props.history.push(
                      //   '/user/appointment/insuranceinfo',
                      // )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const connected = connect(
  (state) => ({
    addChild: state.addChild.addChildData,
  }),
  {
    addChildAction,
  }
)(AddChild);

const formed = reduxForm({
  form: "addChild",
})(connected);



export default withStyles(styles)(formed);
