import React from "react";
import Logo from "../assets/imgs/logo-v1.svg";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import validator from "validator";
import { loginAction } from "../utils/actions/auth.action";
import AlertMsg from "../utils/Alert";

class AdminLogin extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      userName: "",
      password: "",
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  formVerification = () => {
    const user = validator.isEmpty(this.state.userName, {
      ignore_whitespace: true,
    });
    const password = validator.isEmpty(this.state.password, {
      ignore_whitespace: true,
    });
    if (user || password) {
      AlertMsg("error", "All field must be filled");
      return false;
    }
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { userName, password } = this.state;
    const isVerify = this.formVerification();
    if (isVerify) {
      const data = {
        userName,
        password,
      };
      const { history } = this.props;
      this.props.loginAction(data, history);
    }
  };

  render() {
    const { userName, password } = this.state;
    
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-28 w-auto"
            src={Logo}
            alt="Workflow"
          />
        </div>
        <div className="mt-8 sm:mx-auto sm:w-11/12 sm:max-w-sm">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="email"
                  className="block text-xs font-normal text-left text-gray-400"
                >
                  Email address
                </label>
                <div className="mt-1">
                <input
                    id="userName"
                    name="userName"
                    type="userName"
                    autoComplete="userName"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={userName}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-xs font-normal text-left text-gray-400"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={password}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="pt-2">
                <button
                  type="submit"
                  onClick={this.handleSubmit}
                  className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
                >
                  Sign in
                </button>
                <p className="mt-6 text-xs text-gray-400 text-center">
                  Unable to sign-in? Contact your administrator directly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const connected = connect(null, { loginAction })(AdminLogin);
const formed = reduxForm({
  form: "login",
})(connected);

export default (formed);
