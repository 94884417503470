import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { DialogContent } from '@material-ui/core';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { baseURL } from '../../../../utils/axios';
import axios from 'axios';
import AlertMsg from '../../../../utils/Alert';
import { userLogin } from '../../../../utils/actions/auth.action';
import { Spinner } from '../../../../components/atoms';
import { connect } from 'react-redux';

function EmailDailog(props) {
  const { open, onClose, classes, email, setEmail } = props;
  const [loading, setLoading] = useState(false);

  const verifyEmail = () => {
    setLoading(true);
    axios
      .post(`${baseURL}/api/client/resetPasswordOtp`, { email })
      .then((res) => {
        setLoading(false);
        AlertMsg('success', 'Check your email to verify OTP');
        onClose({ otpSent: true });
      })
      .catch((err) => {
        setLoading(false);
        AlertMsg('error', err.response.data.reason);
      });
  };

  return (
    <div>
      <Spinner isOpen={loading} />
      <Dialog
        aria-labelledby='simple-dialog-title'
        open={open}
        onClose={onClose}
      >
        <DialogContent>
          <Grid style={{ width: '300px' }}>
            <Typography className={classes.headTxt}>
              Enter Your Email
            </Typography>
            <Typography className={classes.txt}>
              Please enter your email address below to verify your identity.
            </Typography>
            <Grid className={classes.inputTxt}>
              <TextField
                id='outlined-basic'
                variant='outlined'
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid
              container
              alignItems='center'
              justify='center'
              className={classes.button}
            >
              <Button className={classes.buttonText} onClick={verifyEmail}>
                Lookup Account Email
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

EmailDailog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default EmailDailog;
