/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import { takeEvery, put } from "redux-saga/effects";
import { post, get, updatePatch, delet } from "../axios";
import {
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAILED,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAILED,
  GET_ROLE_BY_ID_SUCCESS,
  GET_ROLE_BY_ID_FAILED,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILED,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILED,
  ADD_ROLE_REQUEST,
  GET_ROLE_BY_ID_REQUEST,
  DELETE_ROLE_REQUEST,
  UPDATE_ROLE_REQUEST,
  GET_ROLE_REQUEST,
} from "../AdminConstent";
import AlertMsg from "../Alert";

function* addRole(action) {
  try {
    const { endpoint, body } = action;
    const response = yield post(endpoint, body);
    AlertMsg("success", "Added");
    yield put({ type: ADD_ROLE_SUCCESS, response });
  } catch (error) {
    AlertMsg("error", "Failed");
    yield put({ type: ADD_ROLE_FAILED, error });
  }
}

function* getRoleSaga(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_ROLE_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_ROLE_FAILED, error });
  }
}

function* getByIdRole(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_ROLE_BY_ID_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_ROLE_BY_ID_FAILED, error });
  }
}

function* updateRole(action) {
  try {
    const { endpoint, body } = action;
    AlertMsg("success", "Updated");
    const response = yield updatePatch(endpoint, body);
    yield put({ type: UPDATE_ROLE_SUCCESS, response });
  } catch (error) {
    AlertMsg("error", "Failed");
    yield put({ type: UPDATE_ROLE_FAILED, error });
  }
}

function* deleteRole(action) {
  try {
    const { endpoint } = action;
    const response = yield delet(endpoint);
    AlertMsg("success", "Deleted");
    yield put({ type: DELETE_ROLE_SUCCESS, response });
  } catch (error) {
    AlertMsg("error", "Failed");
    yield put({ type: DELETE_ROLE_FAILED, error });
  }
}

export function* roleSaga() {
  yield takeEvery(ADD_ROLE_REQUEST, addRole);
  yield takeEvery(GET_ROLE_REQUEST, getRoleSaga);
  yield takeEvery(GET_ROLE_BY_ID_REQUEST, getByIdRole);
  yield takeEvery(UPDATE_ROLE_REQUEST, updateRole);
  yield takeEvery(DELETE_ROLE_REQUEST, deleteRole);
}
