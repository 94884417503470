import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import styled from 'styled-components';

export default makeStyles({
  toolbar: {
    width: '100%',
    height: '100px',
    backgroundColor: '#003265',
  },
  root: {
    maxWidth: 255,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  inputstyle: {
    backgroundColor: '#fff',
  },
  table: {
    minWidth: 650,
  },
  imgTable: {
    width: '40px',
    height: '40px',
    objectFit: 'contain',
    borderRadius: '50px',
  },
  viewBtn: {
    borderRadius: '15px',
    width: '100px',
    padding: '4px',
    boxShadow: 'none',
    textTransform: 'capitalize',
  },
  btnNewApp: {
    backgroundColor: '#003265',
    color: '#fff',
    marginLeft: '50px',
    '&:hover': {
      backgroundColor: '#003265',
    },
  },
  paginationRoot: {
    marginTop: 15,
    float: 'right',
    '& > * + *': {
      marginTop: '16px',
    },
  },
});

const Required = styled.span`
  color: red;
`;

const CellBtn = styled(Button)`
  white-space: nowrap;
  text-transform: none;
  padding: 0px;
  justify-content: flex-start;
  &:hover {
    background-color: transparent;
  }
`;

export { Required, CellBtn };
