/* eslint-disable no-sequences */
/* eslint-disable no-fallthrough */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import {
  Typography,
  withStyles,
  Grid,
  RadioGroup,
  FormControlLabel,
  Paper,
} from '@material-ui/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import Select from 'react-select';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import validator from 'validator';
import { Redirect } from 'react-router-dom';
import Header from '../../../common/Header';
import {
  CustomNextButtons,
  CustomCancelButtons,
} from '../../../common/CustomButtons';
import divider from '../../../assets/user_images/Line 13.svg';
import Stepper from '../../../common/Stepper';
import CustomRadio from '../../../common/RadioButton';
import '../../../assets/css/select.css';
import {
  getClientAction,
  updateClientAction,
  getByIdClientAction,
} from '../../../utils/actions/client.action';
import {
  getAppointmentDetailAction,
  consentFormDetail,
} from '../../../utils/actions/appointment.action';
import '../../../assets/css/date-picker.css';
import { characters, isEmail } from '../../../utils/validators';
// import DateRangeIcon from '@material-ui/icons/DateRange';
// import calendar from '../../../assets/user_images/calendar.png';
// import { Link } from 'react-router-dom';
import DocumentsUploadsView from '../../../common/documentsUploadsView';
import UpdateChild from './UpdateChild';
import GoogleMaps from './GoogleMaps';
import countries_list from '../../../utils/countries_data';
import nationalities_list from '../../../nationalities.json';
import styles from './VerifyInfoStyle';
import ProofOfIdentity from '../../../common/proofOfIdentity';

import { AutoComplete } from '../../../components/atoms';
import { JOB_DESCRIPTION } from '../../../utils/enums';

const JOB_LIST = Object.keys(JOB_DESCRIPTION).map((job, i) => ({
  _id: job,
  name: JOB_DESCRIPTION[job],
}));

const formattedDescription = (description) => {
  const reformattedStr = description.split('-').join(' ').toUpperCase();
  return { _id: reformattedStr, name: JOB_DESCRIPTION[reformattedStr] };
};

var nationalityList = [
  { value: 'india', label: 'India' },
  { value: 'sa', label: 'South Africa' },
  { value: 'uae', label: 'United Arab Emirates' },
];

const stateList = [
  { value: 'Abu Dhabi', label: 'Abu Dhabi' },
  { value: 'Dubai', label: 'Dubai' },
  { value: 'Sharjah', label: 'Sharjah' },
  { value: 'Ajman', label: 'Ajman' },
  { value: 'Umm Al Quwain', label: 'Umm Al Quwain' },
  { value: 'Ras Al Khaima', label: 'Ras Al Khaima' },
  { value: 'Al Fujairah', label: 'Al Fujairah' },
];

const photoIdList = [
  { value: 'Emirates ID', label: 'Emirates ID' },
  { value: 'Passport', label: 'Passport' },
  { value: 'Driving License', label: 'Driving License' },
  { value: 'GCC ID', label: 'GCC ID' },
];

const phonetype = [
  { value: 'mobile', label: 'Mobile' },
  { value: 'phone', label: 'TelePhone' },
];

class VerifyInformation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      uid: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      phoneType: '',
      gender: '',
      nationality: [],
      dob: null,
      jobDescription: '',
      uaeResident: 'yes',
      validPhotoIdentificationValue: 'no',
      validPhotoIdentification: '',
      idCopy: '',
      address1: '',
      address2: '',
      country: '',
      state: '',
      city: '',
      zip: '',
      emName: '',
      emEmail: '',
      emPhoneNumber: '',
      emRelation: '',
      isSelfUpdate: false,
      isChildUpdate: false,
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        phoneType: '',
        gender: '',
        nationality: [],
        dob: this.dob === null ? 'Field is required!' : null,
        uaeResident: 'yes',
        validPhotoIdentificationValue: 'no',
        validPhotoIdentification: '',
        idCopy: '',
        address1: '',
        address2: '',
        country: '',
        state: '',
        city: '',
        zip: '',
        emName: '',
        emEmail: '',
        emPhoneNumber: '',
        emRelation: '',
        billingType: '',
      },
      loading: false,
      childAppointment: false,
      childDetails: null,
      multipleAdultsAppointment: false,
      multipleAdults: [],
    };
  }

  componentDidMount() {
    this.props.getClientAction(true);
    this.props.getByIdClientAction();
    this.setState({ loading: true });
    const billingType =
      new URLSearchParams(this.props.location.search).get('billingType') ||
      'regular';
    this.setState({
      email: localStorage.getItem('clientEmail'),
      uid: localStorage.getItem('clientUid'),
      firstName: localStorage.getItem('name'),
      billingType,
    });
  }

  componentDidUpdate(preProps) {
    if (this.props.updateData !== preProps.updateData) {
      if (this.props.consentInfo || this.state.billingType === 'bundle') {
        this.props.history.push(
          `/paymentInfo/${this.props.match.params.id}?billingType=${this.state.billingType}`
        );
      } else if (this.props.updateData.updateClientData) {
        this.props.history.push(`/consentform/${this.props.match.params.id}`);
      }
    }
    if (this.props.getClientData !== preProps.getClientData) {
      if (
        this.props.getClientData &&
        this.props.getClientData.getByIdClientData
      ) {
        const data = this.props.getClientData.getByIdClientData;

        this.setState({
          firstName: data.firstName ? this.getFirstName(data.firstName) : '',
          lastName: data.lastName
            ? data.lastName
            : this.getLastName(data.firstName),
          email: data.email ? data.email : '',
          phoneNumber: data.phoneNumber ? data.phoneNumber : '',
          phoneType: data.phoneType ? data.phoneType : '',
          gender: data.gender ? data.gender : '',
          dob: data.dob ? new Date(data.dob) : null,
          uaeResident: data.uaeResident ? 'yes' : 'no',
          validPhotoIdentificationValue:
            data.validPhotoIdentification === undefined
              ? 'no'
              : data.validPhotoIdentification === null
              ? 'no'
              : 'yes',
          validPhotoIdentification: data.validPhotoIdentification,
          nationality: data.address ? data.address.nationality : '',
          address1: data.address ? data.address.address1 : '',
          address2: data.address ? data.address.address2 : '',
          country: data.address ? data.address.country : '',
          state: data.address ? data.address.state : '',
          city: data.address ? data.address.city : '',
          zip: data.address ? data.address.zip : '',
          emEmail: data.emergencyContact ? data.emergencyContact.emEmail : '',
          emName: data.emergencyContact ? data.emergencyContact.emName : '',
          emPhoneNumber: data.emergencyContact
            ? data.emergencyContact.emPhoneNumber
            : '',
          emRelation: data.emergencyContact
            ? data.emergencyContact.emRelation
            : '',
          idCopy: data.idCopy === 'null' ? null : data.idCopy,
          jobDescription: data.jobDescription
            ? formattedDescription(data.jobDescription)
            : '',
        });

        if (
          localStorage.getItem('appFor') &&
          localStorage.getItem('appFor') === 'child' &&
          localStorage.getItem('appChildDetails')
        ) {
          this.setState({
            childAppointment: true,
            childDetails: JSON.parse(localStorage.getItem('appChildDetails')),
          });
        } else if (
          localStorage.getItem('appFor') &&
          localStorage.getItem('appFor') === 'multiple' &&
          localStorage.getItem('multipleAdults')
        ) {
          this.setState({
            multipleAdultsAppointment: true,
            multipleAdults: JSON.parse(localStorage.getItem('multipleAdults')),
          });
        }
      }
      this.setState({ loading: false });
    }
  }

  getFirstName = (name) => {
    const res = name.split(' ');
    return res[0] ? res[0] : '';
  };

  getLastName = (name) => {
    const res = name.split(' ');
    return res[1] ? res[1] : '';
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    const { name, value } = e.target;
    const { errors } = this.state;
    switch (name) {
      case 'firstName':
        errors.firstName = !value ? 'Please enter your first name' : '';
        break;
      case 'lastName':
        errors.lastName = !value ? 'Please enter your last name' : '';
        break;
      case 'address1':
        errors.address1 = !value ? 'Address is required!' : '';
        break;
      case 'city':
        errors.city = !value ? 'Please enter your city' : '';
        break;
      case 'zip':
        errors.zip = !value ? 'Please enter your zip code' : '';
        break;
      case 'phoneNumber':
        errors.phoneNumber = !value ? 'Please enter your phone number!' : '';
        break;
      case 'emName':
        errors.emName = !value ? 'Please enter your full name!' : '';
        break;
      case 'emEmail':
        errors.emEmail = (
          !value ? 'Please enter your email address' : isEmail.test(value)
        )
          ? ''
          : 'Please enter valid email address';
        break;
      case 'emPhoneNumber':
        errors.emPhoneNumber =
          value.length > 0 ? null : 'Please enter your phone number!';
        break;
      case 'emRelation':
        errors.emRelation = !value ? 'Please enter your relation!' : '';
        break;
      case 'gender':
        errors.gender = !value ? 'Please select your gender!' : '';
        break;
      default:
        break;
    }
  };

  handleSelectChangeValid = (lable) => {
    const { nationality, country, state, phoneType } = this.state;
    const errors = { ...this.state.errors };
    console.log(lable, nationality, country, state, phoneType);
    switch (lable) {
      case 'nationality':
        errors.nationality =
          nationality.length > 0 ? null : 'Nationality is required';
        this.setState({ errors });
        break;

      case 'country':
        errors.country = country != null ? null : 'Country is Required!';
        this.setState({ errors });
        break;

      case 'city':
        errors.state = state.length > 0 ? null : 'City is Required!';
        this.setState({ errors });
        break;

      case 'address1':
        errors.state = state.length > 0 ? null : 'City is Required!';
        this.setState({ errors });
        break;

      case 'phoneType':
        errors.phoneType =
          phoneType.length > 0 ? null : 'Phone Type is Required!';
        this.setState({ errors });
        break;

      default:
        break;
    }
  };

  handleSelectChange = (label) => (e) => {
    this.setState({ [label]: e.value });
  };

  handleVarify = () => {
    const firstName = validator.isEmpty(this.state.firstName, {
      ignore_whitespace: true,
    });
    const lastName = validator.isEmpty(this.state.lastName, {
      ignore_whitespace: true,
    });
    const email = validator.isEmpty(this.state.email, {
      ignore_whitespace: true,
    });
    const phoneNumber = validator.isEmpty(this.state.phoneNumber, {
      ignore_whitespace: true,
    });
    const phoneType = validator.isEmpty(this.state.phoneType, {
      ignore_whitespace: true,
    });
    const gender = validator.isEmpty(this.state.gender, {
      ignore_whitespace: true,
    });
    // const nationality = validator.isEmpty(this.state.nationality, { ignore_whitespace: true });
    const uaeResident = validator.isEmpty(this.state.uaeResident, {
      ignore_whitespace: true,
    });
    const validPhotoIdentificationValue = validator.isEmpty(
      this.state.validPhotoIdentificationValue,
      { ignore_whitespace: true }
    );
    // idCopy,
    // DO NOT REMOVE MAY NEED THIS

    // const address1 = validator.isEmpty(this.state.address1, {
    //   ignore_whitespace: true,
    // });
    // const country = validator.isEmpty(this.state.country, {
    //   ignore_whitespace: true,
    // });
    // const state = validator.isEmpty(this.state.state, {
    //   ignore_whitespace: true,
    // });
    // const city = validator.isEmpty(this.state.city, {
    //   ignore_whitespace: true,
    // });
    // const zip = validator.isEmpty(this.state.zip, { ignore_whitespace: true });

    const emEmail = validator.isEmpty(this.state.emEmail, {
      ignore_whitespace: true,
    });
    const emName = validator.isEmpty(this.state.emName, {
      ignore_whitespace: true,
    });
    const emPhoneNumber = validator.isEmpty(this.state.emPhoneNumber, {
      ignore_whitespace: true,
    });
    const emRelation = validator.isEmpty(this.state.emRelation, {
      ignore_whitespace: true,
    });
    const errors = { ...this.state.errors };
    if (
      firstName ||
      lastName ||
      phoneNumber ||
      phoneType ||
      gender ||
      this.state.dob === null ||
      // || uaeResident
      validPhotoIdentificationValue ||
      // address1 ||
      // country ||
      // || state
      // city ||
      // zip ||
      emEmail ||
      emName ||
      emPhoneNumber ||
      emRelation
      // || !this.state.nationality.length
    ) {
      errors.firstName = !this.state.firstName
        ? 'Please enter your first name!'
        : '';
      errors.lastName = !this.state.lastName
        ? 'Please enter your last name!'
        : '';
      errors.dob = !this.state.dob ? 'Please choose your date of birth!' : '';
      errors.gender = !this.state.gender ? 'Please select your gender!' : '';
      // errors.nationality = (this.state.nationality.length === 0) ? "Please select your nationality!" : '';
      // errors.uaeResident = this.state.uaeResident.length > 0 ? "Please select your nationality!" : '';
      errors.address1 = !this.state.address1
        ? 'Please enter your address!'
        : '';
      // errors.country = this.state.country.length > 0 ? null : 'Field is required!';
      // errors.city = !this.state.city ? 'Please enter your city name!' : '';
      // errors.state = (!this.state.state) ? "Please select your state" : "";
      errors.zip = !this.state.zip ? 'Please enter your zip name!' : '';
      errors.phoneNumber = !this.state.phoneNumber
        ? 'Please enter your phone number!'
        : '';
      errors.phoneType = !this.state.phoneType
        ? 'Please select phone type!'
        : '';
      errors.emName = !this.state.emName
        ? 'Please enter your emergency name!'
        : '';
      errors.emEmail = !this.state.emEmail
        ? 'Please enter your emergency email address!'
        : '';
      errors.emPhoneNumber = !this.state.emPhoneNumber
        ? 'Please enter your emergency phone number!'
        : '';
      errors.emRelation = !this.state.emRelation
        ? 'Please enter your relation name!'
        : '';
      this.setState({ errors });
      return false;
    }
    return true;
  };

  handleSubmit = () => {
    const {
      uid,
      firstName,
      lastName,
      email,
      phoneNumber,
      phoneType,
      gender,
      nationality,
      dob,
      uaeResident,
      validPhotoIdentificationValue,
      validPhotoIdentification,
      address1,
      address2,
      country,
      state,
      city,
      zip,
      emEmail,
      emName,
      emPhoneNumber,
      emRelation,
      idCopy,
      errors,
      jobDescription,
    } = this.state;
    const isVerify = this.handleVarify();
    if (
      isVerify &&
      errors.firstName === '' &&
      errors.lastName === '' &&
      errors.emName === '' &&
      errors.emEmail === '' &&
      errors.emRelation === ''
    ) {
      if (localStorage.getItem('appFor') === 'child') {
        this.childRef.handleSubmit();
      }

      const data = {
        uid,
        firstName,
        lastName,
        email,
        phoneNumber,
        phoneType,
        gender,
        jobDescription,
        dob,
        uaeResident: uaeResident === 'yes',
        validPhotoIdentification:
          validPhotoIdentificationValue === 'yes'
            ? validPhotoIdentification
            : null,
        address: {
          nationality,
          address1,
          address2,
          country,
          state,
          city,
          zip,
        },
        emergencyContact: {
          emEmail,
          emName,
          emPhoneNumber,
          emRelation,
        },
        type: 'json',
        step: 1,
      };
      this.props.updateClientAction(localStorage.getItem('clientUid'), data);
      this.props.getAppointmentDetailAction(this.props.match.params.id);
      this.props.consentFormDetail(this.props.match.params.id);
    }
  };

  handleFileUpload = (e) => {
    const img = e.target.files[0];
    const data = new FormData();
    data.append('type', 'form');
    data.append(
      'idCopy',
      this.state.validPhotoIdentificationValue === 'yes' ? img : null
    );
    this.props.updateClientAction(localStorage.getItem('clientUid'), data);
  };

  filterJobs = (text) => console.log(text);

  setJobDescription = (val) => {
    if (val) {
      const id = val._id.split(' ').join('-').toLowerCase();
      this.setState({ jobDescription: id });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      phoneType,
      gender,
      nationality,
      dob,
      uaeResident,
      validPhotoIdentificationValue,
      validPhotoIdentification,
      address1,
      address2,
      country,
      state,
      city,
      zip,
      emEmail,
      emName,
      emPhoneNumber,
      emRelation,
      idCopy,
      errors,
      childAppointment,
      childDetails,
      multipleAdults,
      multipleAdultsAppointment,
    } = this.state;

    let countriesList = countries_list.map((item) => {
      return { label: item.name };
    });

    const customStylePhoneType = {
      control: (styles) => ({
        ...styles,
        width: '176px',
        // height: "55px",
        borderRadius: '0px',
        fontSize: '20px',
        color: '#ffffff',
        border: '1px solid #707070',
        '&:focus': {
          border: '1px solid #707070',
        },
        '&:hover': {
          border: '1px solid #707070',
        },
        boxShadow: 'none',
        backgroundColor: 'rgba(192, 208,222, 0.2)',
      }),
      // label: () => ({
      //   fontSize: 30,
      // }),
    };
    const customStyleCountry = {
      control: (styles) => ({
        ...styles,
        borderRadius: '0px',
        fontSize: '20px',
        color: '#ffffff',
        border: '1px solid #707070',
        '&:focus': {
          border: '1px solid #707070',
        },
        '&:hover': {
          border: '1px solid #707070',
        },
        boxShadow: 'none',
        backgroundColor: 'rgba(192, 208,222, 0.2)',
      }),
      // label: () => ({
      //   fontSize: 30,
      // }),
    };
    const customStyle = {
      control: (styles, state) => ({
        ...styles,
        borderRadius: '0px',
        border: '1px solid #707070',
        '&:focus': {
          border: '1px solid #707070',
        },
        '&:hover': {
          border: '1px solid #707070',
        },
        boxShadow: 'none',
        backgroundColor: '#ffffff',
      }),
      multiValue: (styles) => ({
        ...styles,
        backgroundColor: 'rgba(192, 208,222, 0.2)',
        height: '37px',
      }),
      multiValueLabel: (styles) => ({
        ...styles,
        fontSize: '20px',
        color: '#304659',
        fontFamily: 'Lato',
        fontWeight: 400,
        zIndex: 1,
        whiteSpace: 'nowrap',
      }),
      multiValueRemove: (styles) => ({
        ...styles,
        width: '16px',
        height: '16px',
        margin: '5px',
        marginTop: '10px',
        backgroundColor: '#304659',
        color: '#ffffff',
        ':hover': {
          backgroundColor: '#304659',
          color: 'white',
        },
      }),
    };
    const customStyleMobile = {
      control: (styles) => ({
        ...styles,
        width: '100%',
        borderRadius: '0px',
        border: '1px solid #707070',
        '&:focus': {
          border: '1px solid #707070',
        },
        '&:hover': {
          border: '1px solid #707070',
        },
        boxShadow: 'none',
        backgroundColor: '#ffffff',
      }),
      multiValue: (styles) => ({
        ...styles,
        backgroundColor: 'rgba(192, 208,222, 0.2)',
        height: '15px',
      }),
      multiValueLabel: (styles) => ({
        ...styles,
        fontSize: '13px',
        color: '#304659',
        fontFamily: 'Lato',
        fontWeight: 400,
        zIndex: 1,
        whiteSpace: 'nowrap',
      }),
      multiValueRemove: (styles) => ({
        ...styles,
        width: '15px',
        height: '15px',
        margin: '3px',
        marginTop: '2px',
        backgroundColor: '#304659',
        color: '#ffffff',
        ':hover': {
          backgroundColor: '#304659',
          color: 'white',
        },
      }),
    };

    if (localStorage.getItem('aapFor') === 'child') {
      if (this.state.isChildUpdate && this.state.isSelfUpdate) {
        return (
          <Redirect
            push
            to={`/paymentInfo/${this.props.match.params.id}?billingType=${this.state.billingType}`}
          />
        );
      }
    }

    return (
      <div>
        <Header props={this.props} />
        <div className={classes.root} />
        <Grid container justify='center' alignItems='center' component='div'>
          <Grid className={classes.titleContainer} component='div'>
            <Stepper tab={0} />
            <Grid
              container
              component='div'
              alignItems='center'
              justify='center'
            >
              <Grid item component='div' className={classes.container}>
                <Grid component='div'>
                  <Typography className={classes.title}>
                    Your appointment details
                  </Typography>
                  <Grid
                    container
                    component='div'
                    justify='center'
                    alignItems='center'
                    className={classes.bodyContainer}
                  >
                    <img src={divider} className={classes.dividerLine} />
                    <Grid
                      container
                      component='div'
                      className={classes.gridcolor}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          First Name
                        </Typography>
                        <input
                          type='text'
                          onChange={this.handleChange}
                          name='firstName'
                          value={firstName}
                          className={classes.text}
                        />
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.firstName && errors.firstName}
                      </span>
                    </Grid>

                    <Grid
                      container
                      component='div'
                      className={classes.gridcolor}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          last Name
                        </Typography>
                        <input
                          type='text'
                          name='lastName'
                          value={lastName}
                          onChange={this.handleChange}
                          className={classes.text}
                        />
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.lastName && errors.lastName}
                      </span>
                    </Grid>

                    <Grid
                      container
                      component='div'
                      className={classes.gridwhite}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          Date of birth
                        </Typography>
                        <Grid>
                          <DatePicker
                            className={classes.dateInput}
                            dateFormat='MMMM d, yyyy'
                            selected={this.state.dob}
                            showYearDropdown
                            dropdownMode='select'
                            showMonthDropdown
                            onChange={(date) => (
                              this.setState({ dob: date }), (errors.dob = null)
                            )}
                          />
                        </Grid>
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.dob && errors.dob}
                      </span>
                    </Grid>

                    <Grid
                      container
                      component='div'
                      className={classes.gridcolor}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          Sex
                        </Typography>
                        <RadioGroup
                          style={{ display: 'flex', flexDirection: 'row' }}
                          name='gender'
                          value={gender}
                          onChange={this.handleChange}
                        >
                          <FormControlLabel
                            value='male'
                            checked={gender === 'male' && true}
                            control={<CustomRadio />}
                            label={
                              <Typography className={classes.labletext}>
                                Male
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            style={{ marginLeft: '35px' }}
                            value='female'
                            checked={gender === 'female' && true}
                            control={<CustomRadio />}
                            label={
                              <Typography className={classes.labletext}>
                                Female
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.gender && errors.gender}
                      </span>
                    </Grid>

                    <Grid
                      container
                      component='div'
                      className={classes.gridwhite}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          Nationality
                        </Typography>
                        <div className={classes.customNationality}>
                          <Select
                            onBlur={() =>
                              this.handleSelectChangeValid('nationality')
                            }
                            isMulti
                            isSearchable
                            className='react-select-container'
                            classNamePrefix='react-select'
                            // className={classes.nationalitySelect}
                            styles={customStyle}
                            value={nationality}
                            onChange={(nationality) =>
                              this.setState({ nationality })
                            }
                            options={countriesList}
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                          />
                        </div>
                        <div className={classes.customNationalityMobile}>
                          <Select
                            isMulti
                            onBlur={() =>
                              this.handleSelectChangeValid('nationality')
                            }
                            className='react-select-container'
                            classNamePrefix='react-select'
                            // className={classes.nationalitySelect}
                            styles={customStyleMobile}
                            value={nationality}
                            onChange={(nationality) =>
                              this.setState({ nationality })
                            }
                            options={countriesList}
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                          />
                        </div>
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.nationality && errors.nationality}
                      </span>
                    </Grid>

                    <Grid
                      container
                      component='div'
                      className={classes.gridcolor}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          UAE Resident
                        </Typography>
                        <RadioGroup
                          style={{ display: 'flex', flexDirection: 'row' }}
                          name='uaeResident'
                          value={uaeResident}
                          onChange={this.handleChange}
                        >
                          <FormControlLabel
                            value='yes'
                            checked={uaeResident === 'yes' && true}
                            control={<CustomRadio />}
                            label={
                              <Typography className={classes.labletext}>
                                Yes
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            style={{ marginLeft: '35px' }}
                            checked={uaeResident === 'no' && true}
                            value='no'
                            control={<CustomRadio />}
                            label={
                              <Typography className={classes.labletext}>
                                No
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>

                    {/* DO NOT DELTE NEEDS REVIEWED */}
                    {/* <Grid
                      container
                      component='div'
                      className={classes.gridcolor}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          Address 1
                        </Typography>
                        <input
                          name='address1'
                          value={address1}
                          onChange={this.handleChange}
                          className={classes.text}
                        />
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.address1 && errors.address1}
                      </span>
                    </Grid>

                    <Grid
                      container
                      component='div'
                      className={classes.gridwhite}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          address 2
                        </Typography>
                        <input
                          onChange={this.handleChange}
                          name='address2'
                          value={address2}
                          className={classes.text}
                        />
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.address2 && errors.address2}
                      </span>
                    </Grid>
                    <Grid
                      container
                      component='div'
                      className={classes.gridwhite}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          city
                        </Typography>
                        <input
                          onChange={this.handleChange}
                          name='city'
                          value={city}
                          className={classes.text}
                        />
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.city && errors.city}
                      </span>
                    </Grid>

                    <Grid
                      container
                      component='div'
                      className={classes.gridwhite}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          State
                        </Typography>
                        <Select
                          onBlur={() => this.handleSelectChangeValid('state')}
                          // className={classes.countrySelect}
                          className='react-select-container'
                          classNamePrefix='react-select'
                          value={stateList.filter(
                            (type) => type.value === state
                          )}
                          styles={customStyleCountry}
                          components={{ IndicatorSeparator: () => null }}
                          options={stateList}
                          onChange={this.handleSelectChange('state')}
                        />
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.state && errors.state}
                      </span>
                    </Grid>

                    <Grid
                      container
                      component='div'
                      className={classes.gridwhite}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          zip
                        </Typography>
                        <input
                          name='zip'
                          type='number'
                          value={zip}
                          onChange={this.handleChange}
                          className={classes.text}
                          placeholder='00000-0000'
                        />
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.zip && errors.zip}
                      </span>
                    </Grid>

                    <Grid
                      container
                      component='div'
                      className={classes.gridcolor}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          country
                        </Typography>
                        <Select
                          onBlur={() => this.handleSelectChangeValid('country')}
                          // className={classes.countrySelect}
                          className='react-select-container'
                          classNamePrefix='react-select'
                          styles={customStyleCountry}
                          value={countriesList.filter(
                            (type) => type.label === country
                          )}
                          components={{ IndicatorSeparator: () => null }}
                          options={countriesList}
                          onChange={(country) =>
                            this.setState({ country: country.label })
                          }
                          {...this.props}
                        />
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.country && errors.country}
                      </span>
                    </Grid> */}

                    {/* <Grid
                      container
                      component="div"
                      className={classes.gridcolor}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          Valid Photo ID
                        </Typography>
                        <RadioGroup
                          style={{ display: 'flex', flexDirection: 'row' }}
                          onChange={this.handleChange}
                          name="validPhotoIdentificationValue"
                          value={validPhotoIdentificationValue}
                        >
                          <FormControlLabel
                            value="yes"
                            checked={
                              validPhotoIdentificationValue === 'yes' && true
                            }
                            control={<CustomRadio />}
                            label={(
                              <Typography className={classes.labletext}>
                                Yes
                              </Typography>
                            )}
                          />
                          <FormControlLabel
                            style={{ marginLeft: '35px' }}
                            checked={
                              validPhotoIdentificationValue === 'no' && true
                            }
                            value="no"
                            control={<CustomRadio />}
                            label={(
                              <Typography className={classes.labletext}>
                                No
                              </Typography>
                            )}
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    {validPhotoIdentificationValue === 'yes' && (
                      <>
                        <Grid
                          container
                          component="div"
                          className={classes.gridwhite}
                        >
                          <Grid className={classes.fieldContainer}>
                            <Typography className={classes.infoTitle}>
                              Valid Id Document
                            </Typography>
                            <Select
                              // className={classes.countrySelect}
                              className="react-select-container"
                              classNamePrefix="react-select"
                              styles={customStyleCountry}
                              components={{ IndicatorSeparator: () => null }}
                              value={photoIdList.filter(
                                (type) => type.value === validPhotoIdentification,
                              )}
                              options={photoIdList}
                              onChange={this.handleSelectChange(
                                'validPhotoIdentification',
                              )}
                            />
                          </Grid>
                          <span className={classes.errorText}>
                            {errors.validPhotoIdentification
                              && errors.validPhotoIdentification}
                          </span>
                        </Grid>

                        <Grid
                          container
                          component="div"
                          className={classes.gridcolor}
                        >
                          <Grid className={classes.fieldContainer}>
                            <Typography className={classes.infoTitle}>
                              Upload ID Document
                            </Typography>
                            <input
                              style={{ marginTop: 10 }}
                              type="file"
                              name="idCopy"
                              // value={idCopy}
                              onChange={this.handleFileUpload}
                              // className={classes.text}
                              // placeholder="00000-0000"
                            />
                          </Grid>
                          <span className={classes.errorText}>
                            {errors.idCopy && errors.idCopy}
                          </span>
                        </Grid>
                      </>
                    )} */}
                    {/* TODO FOR LATER */}
                    {/* <DocumentsUploadsView
                      titleText="Proof of Identity"
                      fileType="image/png"
                      docType="proofOfIdentity"
                      url={
                        this.state.proofOfIdentity && this.state.proofOfIdentity.url
                      }
                      onUpload={(document) => {
                        this.setState({ proofOfIdentity: document });
                      }}
                      isPatient
                    /> */}
                    <Grid item xs={12}>
                      <Paper className={classes.paper}>
                        <Typography className={classes.inputText}>
                          Document Uploads
                        </Typography>

                        <ProofOfIdentity
                          clientId={localStorage.getItem('clientId')}
                          isPatient
                        />
                      </Paper>
                    </Grid>

                    {/* <DocumentsUpload /> */}

                    {/* <Grid
                      container
                      component="div"
                      className={classes.gridcolor}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          Have insurance
                        </Typography>
                        <RadioGroup
                          style={{ display: 'flex', flexDirection: 'row' }}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<CustomRadio />}
                            label={(
                              <Typography className={classes.labletext}>
                                Yes
                              </Typography>
                            )}
                          />
                          <FormControlLabel
                            style={{ marginLeft: '35px' }}
                            value="no"
                            control={<CustomRadio />}
                            label={(
                              <Typography className={classes.labletext}>
                                No
                              </Typography>
                            )}
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      component="div"
                      className={classes.gridwhite}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          Insurance Policy number
                        </Typography>
                        <input
                          className={classes.text}
                          placeholder="00000-0000"
                        />
                      </Grid>
                    </Grid> */}

                    <Grid
                      container
                      component='div'
                      className={[classes.gridcolor, classes.bottomcontainer]}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          email ID
                        </Typography>
                        <Typography className={classes.email}>
                          {email}
                        </Typography>
                      </Grid>
                    </Grid>

                    {/* <Grid
                      container
                      component="div"
                      className={classes.gridwhite}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          Account Password
                        </Typography>
                        <input
                          type="password"
                          className={classes.text}
                          placeholder="*******C"
                        />
                      </Grid>
                    </Grid> */}

                    <Grid
                      container
                      component='div'
                      className={classes.gridcolor}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          Phone
                        </Typography>
                        <input
                          type='number'
                          name='phoneNumber'
                          value={phoneNumber}
                          className={classes.text}
                          onChange={this.handleChange}
                          placeholder='+971 50 888 8888'
                        />
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.phoneNumber && errors.phoneNumber}
                      </span>
                    </Grid>

                    <Grid
                      container
                      component='div'
                      className={classes.gridwhite}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          Phone type
                        </Typography>
                        <Select
                          onBlur={() =>
                            this.handleSelectChangeValid('phoneType')
                          }
                          styles={customStylePhoneType}
                          className='react-select-container'
                          classNamePrefix='react-select'
                          // classNamePrefix="react-select__control"
                          // classNamePrefix={classes.phoneTypeContainer}
                          components={{ IndicatorSeparator: () => null }}
                          value={phonetype.filter(
                            (type) => type.value === phoneType
                          )}
                          options={phonetype}
                          onChange={this.handleSelectChange('phoneType')}
                        />
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.phoneType && errors.phoneType}
                      </span>
                    </Grid>

                    <Grid
                      container
                      component='div'
                      className={classes.gridwhite}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          Job Description
                        </Typography>
                        <Grid style={{ width: '500px' }} item>
                          <AutoComplete
                            value={{ _id: 'ACTOR', name: 'ACTOR' }}
                            onChange={this.setJobDescription}
                            renderingFunction={this.filterJobs}
                            placeholder='Select Job description'
                            options={JOB_LIST}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Typography
                      style={{ marginBottom: '10px' }}
                      className={[classes.title, classes.bottomcontainer]}
                    >
                      Emergency Contact details
                    </Typography>
                    <Grid
                      container
                      component='div'
                      className={classes.gridcolor}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          Full Name
                        </Typography>
                        <input
                          type='text'
                          name='emName'
                          value={emName}
                          onChange={this.handleChange}
                          className={classes.text}
                        />
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.emName && errors.emName}
                      </span>
                    </Grid>

                    <Grid
                      container
                      component='div'
                      className={classes.gridwhite}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          Email
                        </Typography>
                        <input
                          type='email'
                          name='emEmail'
                          value={emEmail}
                          onChange={this.handleChange}
                          className={classes.text}
                        />
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.emEmail && errors.emEmail}
                      </span>
                    </Grid>

                    <Grid
                      container
                      component='div'
                      className={classes.gridcolor}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          Phone Number
                        </Typography>
                        <input
                          type='number'
                          name='emPhoneNumber'
                          value={emPhoneNumber}
                          onChange={this.handleChange}
                          className={classes.text}
                        />
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.emPhoneNumber && errors.emPhoneNumber}
                      </span>
                    </Grid>

                    <Grid
                      container
                      component='div'
                      className={classes.gridwhite}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.infoTitle}>
                          Relation
                        </Typography>
                        <input
                          type='text'
                          name='emRelation'
                          onChange={this.handleChange}
                          value={emRelation}
                          className={classes.text}
                          placeholder='...'
                        />
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.emRelation && errors.emRelation}
                      </span>
                    </Grid>
                  </Grid>

                  {childAppointment && childDetails && (
                    <UpdateChild
                      onRef={(ref) => (this.childRef = ref)}
                      successUpdate={() =>
                        this.setState({ isChildUpdate: true })
                      }
                      props={this.props}
                    />
                  )}

                  {multipleAdultsAppointment && multipleAdults.length > 0 && (
                    <Grid>
                      <Typography
                        style={{ marginBottom: '10px' }}
                        className={[classes.title, classes.bottomcontainer]}
                      >
                        Adult details
                      </Typography>
                      {multipleAdults.map((item, i) => (
                        <Grid key={`lh-${i}`} style={{ marginBottom: '20px' }}>
                          <Grid
                            container
                            component='div'
                            className={classes.gridcolor}
                          >
                            <Grid className={classes.fieldContainer}>
                              <Typography className={classes.infoTitle}>
                                First Name
                              </Typography>
                              <input
                                type='text'
                                name='mulFName'
                                value={item.firstName}
                                onChange={this.handleChange}
                                className={classes.text}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            component='div'
                            className={classes.gridcolor}
                          >
                            <Grid className={classes.fieldContainer}>
                              <Typography className={classes.infoTitle}>
                                Last Name
                              </Typography>
                              <input
                                type='text'
                                name='mulLName'
                                value={item.lastName}
                                onChange={this.handleChange}
                                className={classes.text}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            component='div'
                            className={classes.gridcolor}
                          >
                            <Grid className={classes.fieldContainer}>
                              <Typography className={classes.infoTitle}>
                                Email
                              </Typography>
                              <input
                                type='text'
                                disabled
                                name='mulName'
                                value={item.email}
                                onChange={this.handleChange}
                                className={classes.text}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                  <Grid
                    container
                    justify='center'
                    style={{ marginTop: '76px', marginBottom: '100px' }}
                  >
                    <CustomCancelButtons
                      width='170px'
                      title='Cancel'
                      style={classes.cancelButton}
                      onPressButton={() =>
                        this.props.history.push('/dashboard')
                      }
                    />
                    <CustomNextButtons
                      width='170px'
                      title='Next'
                      style={classes.nextButton}
                      onPressButton={this.handleSubmit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

VerifyInformation.defaultProps = {
  getClientData: {},
  getAppointmentData: {},
};

const connected = connect(
  (state) => ({
    updateData: state.updateClient,
    getClientData: state.getByIdClient,
    getAppointmentData: state.getAppointmentDetail.getAppointmentDetailData,
    consentInfo: state.consent.info,
  }),
  {
    getClientAction,
    updateClientAction,
    getAppointmentDetailAction,
    getByIdClientAction,
    consentFormDetail,
  }
)(VerifyInformation);

const formed = reduxForm({
  form: 'updateClient',
})(connected);

export default withStyles(styles)(formed);
