import React from 'react';
import moment from 'moment';
import userIcon from '../../../assets/imgs/download.png';

const Popover = (props) => {
  console.log('props', props);
  const duration = moment.duration(moment(props.end).diff(moment(props.start)));
  const minutes = duration.asMinutes();
  return (
    <div className='w-max top'>
      <div className='flex p-3 items-stretch'>
        <img
          className='flex-initial inline object-cover w-12 h-12 mr-2 rounded-full'
          src={(props.client && props.client.avatar) || userIcon}
          alt='Profile image'
        />
        <div className='flex-initial self-center'>
          <h4 className='text-xl font-semibold text-gray-800'>
            {props.client &&
              props.client.firstName + ' ' + props.client.lastName}
          </h4>
          <div className='flex items-center  text-blue-600'>
            <i className='far fa-folder-open'></i>
            <button className='ml-1.5'>View Patient Record</button>
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        {props.client && props.client.phoneNumber && (
          <div className='flex items-center px-3 py-1.5'>
            <i className='fas fa-phone-alt'></i>
            <a
              className='hover:no-underline ml-1.5'
              href={`tel:${props.client.phoneNumber}`}
            >
              {props.client.phoneNumber}
            </a>
          </div>
        )}

        {props.client && props.client.email && (
          <div className='flex items-center px-3 py-1.5'>
            <i className='far fa-envelope'></i>
            <a
              className='hover:no-underline ml-1.5'
              href={`mailto:${props.client.email}`}
            >
              {props.client.email}
            </a>
          </div>
        )}
        <div
          style={{ backgroundColor: '#F0EDE6' }}
          className='flex justify-start items-center px-3 h-16'
        >
          <i className='far fa-clock'></i>
          <p className='ml-1.5'>
            {moment(props.start).format('hh:mm a')} for {minutes} Minutes
          </p>
        </div>
      </div>
    </div>
  );
};

export default Popover;
