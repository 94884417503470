import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';

export default function ConfirmCancelAppointment({handleClose, open, handleCancelAppointmentConfirm, message, loadingConfApp}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {/* <DialogTitle id="responsive-dialog-title">{data}</DialogTitle> */}
        <DialogContent>
          <DialogContentText>
            <Typography style={{color: "#000"}} variant="subtitle1">{message}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined" size="small">
            Cancel
          </Button>
          <Button disabled={loadingConfApp} onClick={handleCancelAppointmentConfirm} color="primary" variant="outlined" size="small">
            {loadingConfApp ? <CircularProgress style={{padding: 4, height: 24, width: 24}} color="secondary" /> : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
