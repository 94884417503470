import { Grid, Typography, TableCell } from '@material-ui/core';
import styled from 'styled-components';

const Img = styled.img`
  width: 380px;
  height: 100px;
`;

const Container = styled(Grid)`
  background-color: #fff;
  padding: 20px 30px;
`;

const HR = styled.hr`
  border-top: 3px solid #777;
  width: 100%;
`;

const GridWithBottomBorder = styled(Grid)`
  border-bottom: 5px solid #000000;
  font-size: 24px;
  font-weight: bold;
`;

const CapitalizedHeading = styled(Grid)`
  font-size: 19px;
  font-weight: bold;
  text-transform: uppercase;
`;

const Heading = styled(Grid)`
  font-size: 19px;
  font-weight: bold;
  margin: 16px 0px;
`;

const CellDef = styled.td`
  text-align: start;
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
`;

const Cell = styled.td`
  text-align: start;
  padding-left: 30px;
  font-size: 14px;
`;

const BorderedCell = styled(TableCell)`
  &.MuiTableCell-root {
    border: 2px solid grey;
    font-size: 14px;
  }
`;

const BorderedTD = styled(TableCell)`
  &.MuiTableCell-root {
    border: 2px solid grey;
    font-size: 14px;
    font-weight: bold;
  }
`;

const TotalGrid = styled(Grid)`
  padding: 0px 10px;
  font-size: 14px;
`;

const Row = styled(Grid)`
  margin: 20px 0;
`;

const TotalHeading = styled(Grid)`
  font-size: 16px;
  font-weight: bold;
  padding: 0px 10px;
`;

const BoldHR = styled.hr`
  border-top: 5px solid #000;
  width: 100%;
`;

const Footer = styled(Typography)`
  font-size: 12px;
  margin-bottom: -20px;
`;
const ThankYou = styled(Typography)`
  font-size: 20px;
  margin-top: -20px;
`;

const LoaderGrid = styled(Grid)`
  height: 100%;
`;

const PDFButton = styled(Grid)`
  position: absolute;
  margin-top: 70px;
  margin-right: 40px;
`;

const LogoGrid = styled(Grid)`
  margin-left: -30px;
`;

const InvoiceStatus = styled(Typography)`
  background-color: #01bc05;
  color: white;
  padding: 8px 30px;
  border-radius: 4px;
`;

export {
  Img,
  Container,
  HR,
  GridWithBottomBorder,
  CapitalizedHeading,
  Row,
  Heading,
  TotalGrid,
  Cell,
  CellDef,
  BorderedCell,
  BorderedTD,
  TotalHeading,
  BoldHR,
  Footer,
  ThankYou,
  LoaderGrid,
  PDFButton,
  LogoGrid,
  InvoiceStatus,
};
