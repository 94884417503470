import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(8),
  },
  boxsty: {
    border: '1px solid #d7d7d7',
    borderRadius: '3px',
  },
  heading: {
    fontSize: '14px',
    lineHeight: '28px',
    fontWeight: 600,
    textAlign: 'left',
    textTransform: 'capitalize',
    color: '#003d79',
    paddingBottom: '15px',
    paddingTop: '15px',
    paddingLeft: '20px',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  manageSty: {
    fontWeight: 600,
    cursor: 'pointer',
    textAlign: 'right',
    paddingBottom: '15px',
    paddingTop: '15px',
    paddingRight: '8px',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  gridsty: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  text: {
    position: 'relative',
    textAlign: 'left',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 400,
    marginBottom: '5px',
    color: '#a29061',
    textTransform: 'capitalize',
    marginTop: '20px',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  divsty: {
    paddingBottom: '15px',
    paddingTop: '8px',
    paddingLeft: '20px',
  },
  contantsty: {
    position: 'relative',
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'left',
    textTransform: 'none',
    marginBottom: '5px',
    color: '#003d79',
    marginTop: '20px',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  iconSty: {
    width: '20px',
    color: '#ea644c',
    fontSize: '18px',
    lineHeight: 'normal',
    marginLeft: '5px',
  },
  subtext: {
    position: 'relative',
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'left',
    textTransform: 'none',
  },
});
