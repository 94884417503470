/* eslint-disable import/order */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Grid, withStyles, Checkbox } from '@material-ui/core';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import DrawerMenu from '../Patient/common/Header/DrawerMenu';
import { GridContainer, GridItem } from './BodyContainer';
import { TableTitleFont } from './TableFont';
import { DrawerNameFont, DrawerReferredFont } from './DrawerContent/DrawerFont';
import { DrawerBoxClinicianImg } from './DrawerContent/DrawerImage';
import { ClinicianButton } from './DrawerContent/CustomButton';
import arrow from '../assets/imgs/Border.png';
import arrowUp from '../assets/imgs/Border (4).svg';
import { CustomCalendar } from '../Patient/components/Calendar/CustomCalendar';
import clinician1 from '../assets/imgs/download.png';
import AlertMsg from '../utils/Alert';
import Loader from '../user/Loader';
import dayjs from 'dayjs';
import { dateFormatterHHMM, dateFormatter1 } from '../utils/validators';
import moment from 'moment';
import Axios from 'axios';
import { baseURL } from '../utils/axios';
import { dateTime } from '../utils/timUtils';
import styles from './SchedulePicker_style';
import Drawer from './Drawer';

class Doctor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorList: [],
      availableSlots: [],
      selectedIndex: null,
      loading: true,
      doctor: {},
      waitlist: false,
      paddingForRoot: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.clientId !== this.props.clientId ||
      prevProps.duration !== this.props.duration ||
      prevProps.clinicianId !== this.props.clinicianId ||
      prevProps.patientType !== this.props.patientType
    ) {
      this.setState({ doctor: [] });
      this.getClinicians();
    }
  }

  componentDidMount() {
    this.getClinicians();
    if (this.props.showJustSlots) {
      this.setState({
        paddingForRoot: { paddingTop: '0px' },
      });
    }
  }

  getClinicians = () => {
    if (!this.props.clinicianId) {
      this.setState({ loading: false });
      return;
    }
    this.setState({ loading: true });
    Axios.get(
      `${baseURL}/api/user/${this.props.clinicianId}?clientId=${
        this.props.clientId
      }&duration=${
        this.props.duration ? this.props.duration : 60
      }&patientType=${this.props.patientType}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        if (!res.data) return
        if (this.props.onClinician) {
          this.props.onClinician(res.data)
        }
        this.setState({ loading: false });
        const clinician = res.data;
        let nextAvailable = null;
        let schedules = clinician.schedule;
        console.log('schedules', schedules);
        schedules = schedules.map((sch) => {
          if (sch.slots.length) {
            sch.slots = sch.slots.map((s) => {
              let startTime = moment(new Date(s.startTime));
              let slot = startTime.hours() + ':' + startTime.minutes();
              let timeArr = slot.split(':');
              let hrs = '00',
                min = '00';
              if (timeArr.length) {
                hrs = timeArr[0];
                min = timeArr[1];
              }
              if (timeArr[0].length === 1) {
                hrs = `0${timeArr[0]}`;
              }
              if (timeArr[1].length === 1) {
                min = `0${timeArr[1]}`;
              }
              let time = `${hrs}:${min}`;
              let lclDate = new Date(`${sch.date}T${time}:00`);
              let lclHrs = lclDate.getHours().toString();
              if (lclHrs.length === 1) {
                lclHrs = `0${lclHrs}`;
              }
              return `${lclHrs}:${
                lclDate.getMinutes() > 0 ? lclDate.getMinutes() : '00'
              }`;
            });
          }
          return sch;
        });

        for (let sch of schedules) {
          if (sch.slots.length && sch.slots.length > 0) {
            nextAvailable = sch;
            break;
          }
        }
        // }
        let data = {
          id: clinician._id,
          name: `Dr. ${clinician.firstName} ${clinician.lastName}`,
          study: '',
          speciality: 'Specialist Psychiatrist',
          times: schedules,
          nextAvailable,
          img: clinician1,
          cost: `${clinician.costPerUnit} Dhs / ${clinician.appointmentSlotUnit}min`,
          appointmenSlot: clinician.appointmentSlotUnit,
          amount: clinician.costPerUnit,
          selectTimeSlot: null,
          isAlternative: false,
          nextAvailiblityText: `Next Availability (${this.getNextAvailibility(
            clinician.schedule
          )})`,
        };

        this.setState({ doctor: data });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  getNextAvailibility = (schedules) => {
    let result = '';
    if (schedules.length) {
      let day = new Date();
      day.setDate(day.getDate() + 1);
      let today = this.getDate(new Date());
      let nextDay = this.getDate(day);
      if (schedules[0].date == today) {
        result = 'Today';
      } else if (schedules[0].date == nextDay) {
        result = 'Tomorrow';
      } else {
        result = schedules[0].date;
      }
    } else {
      result = 'Not Available';
    }
    return result;
  };

  getDate = (date) => {
    var today = new Date(date);
    var d = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var m =
      today.getMonth() + 1 < 10
        ? '0' + (today.getMonth() + 1)
        : today.getMonth() + 1;
    var date = today.getFullYear() + '-' + m + '-' + d;
    return date;
  };

  getEvents = (schedule) => {
    var temArr1 = [];
    if (schedule && schedule.length) {
      // var keys = Object.keys(schedule)
      schedule.map((item, i) => {
        let times = [];
        if (item.slots) {
          item.slots.map((val) => {
            times.push({ time: val });
          });
        }
        let start = new Date(item.date);
        var tomorrow = new Date(item.date);
        tomorrow.setDate(tomorrow.getDate() + 1);
        temArr1.push({
          title: `My event ${i}`,
          times: times,
          allDay: false,
          start: Date.parse(item.date + 'T00:00:00'), // 10.00 AM
          end: Date.parse(item.date + 'T00:00:00'), // 2.00 PM
          col: '#ee3559',
        });
      });
    }
    return temArr1;
  };

  getFirstTimeSlot = (time) => {
    var result = [];
    if (time) {
      var keys = Object.keys(time);
      var values = Object.values(time);
      var day = new Date();
      day.setDate(day.getDate() + 1);
      var nextDay = this.getDate(day);
      result = values[0];
    }
    return result;
  };

  getTimeSlot = (time) => {
    var result = [];
    if (time && time.default) {
      var keys = Object.keys(time);
      var values = Object.values(time);
      var day = new Date();
      day.setDate(day.getDate() + 1);
      var nextDay = this.getDate(day);
      if (keys.length > 1 && keys.indexOf('default') != -1) {
        keys.map((key, index) => {
          if (key == nextDay) {
            values[0].map((val, i) => {
              if (values[index].indexOf(val) == -1) {
                result.push(val);
              }
            });
          } else if (key != 'default') {
            values[0].map((val, i) => {
              if (values[index].indexOf(val) == -1) {
                result.push(val);
              }
            });
          }
        });
      } else {
        result = time.default;
      }
    }
    const data = {
      availableSlots: result ? result : [],
      default: time.default ? time.default : [],
    };
    return data;
  };

  onChange = () => {
    const doctor = {
      ...this.state.doctor,
      isAlternative: !this.state.doctor.isAlternative,
    };
    this.setState({ doctor });
  };

  handleSelectCalendarTime = (times, time, date) => {
    try {
      let date_ = new Date(date);
      let timeSplits = time.split(':');
      date_.setHours(timeSplits[0], timeSplits[1]);
      this.setState({ selectedIndex: null });
      if (this.props.getTime) {
        this.props.getTime(date_);
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleSelectTime = (time) => {
    if (this.props.getTime) {
      this.props.getTime(time);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div style={{ minWidth: '600px', background: '#ffff' }}>
        {this.state.loading ? (
          <Grid container alignItems='center' justify='center'>
            <Loader />
          </Grid>
        ) : (
          <div>
            <Grid
              key={0}
              className={classes.root1}
              style={this.state.paddingForRoot}
            >
              {!this.props.showJustSlots && (
                <Grid
                  container
                  alignItems='center'
                  className={classes.bodyHeaderContainer}
                >
                  <Grid>
                    <DrawerNameFont
                      text={this.state.doctor.name}
                      style={{ fontSize: '18px', textAlign: 'left' }}
                    />
                    <DrawerReferredFont
                      text={this.state.doctor.speciality}
                      style={{ fontSize: '16px', textAlign: 'left' }}
                    />
                  </Grid>
                  <Grid
                    container
                    alignItems='center'
                    justify='flex-end'
                    className={classes.bodyImageContainer}
                  >
                    <DrawerBoxClinicianImg logo={this.state.doctor.img} />
                  </Grid>
                </Grid>
              )}
              {!this.props.showJustSlots && <Grid className={classes.line} />}
              <Grid
                container
                alignItems='center'
                justify='flex-start'
                style={{ marginBottom: '14px' }}
              >
                <div className={classes.nextAvailable}>
                  <DrawerNameFont
                    text={
                      this.state.doctor.nextAvailiblityText
                        ? `${this.state.doctor.nextAvailiblityText}`
                        : 'No Availability'
                    }
                    style={{ fontSize: '18px', color: 'rgba(34,34,34,50%)' }}
                  />
                  {/* <ClinicianButton
                        text={`Cost: ${node.cost}`}
                        style={{ position: "absolute", right: "92px" }}
                      /> */}
                </div>
              </Grid>

              <Grid
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  marginBottom: '40px',
                }}
              >
                {this.state.doctor.nextAvailable &&
                this.state.doctor.nextAvailable.slots.length > 0 ? (
                  this.state.doctor.nextAvailable.slots.map((time, i) => {
                    return (
                      <Grid
                        key={i}
                        container
                        alignItems='center'
                        justify='center'
                        style={
                          i === this.state.selectedIndex
                            ? {
                                backgroundColor: '#304659',
                                color: '#ffffff',
                                marginBottom: '7px',
                              }
                            : { marginBottom: '7px' }
                        }
                        className={classes.timeContainer}
                        onClick={() => {
                          this.setState({ selectedIndex: i });
                          console.log(i, this.state.selectedIndex);
                          this.handleSelectTime(
                            dateTime(this.state.doctor.nextAvailable.date, time)
                          );
                        }}
                      >
                        {time}
                      </Grid>
                    );
                  })
                ) : this.props.hideWaitListOption ? (
                  <></>
                ) : (
                  <Grid>
                    Not Available, Add to waiting list
                    <Checkbox
                      checked={this.state.waitlist}
                      onChange={(event) => {
                        if (this.props.setIsWaiting) {
                          this.props.setIsWaiting(event.target.checked);
                        }
                        this.setState({ waitlist: event.target.checked });
                      }}
                      color='primary'
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Grid>
                )}
              </Grid>
              {this.state.doctor.nextAvailiblityText != 'N/A' ? (
                <Grid
                  container
                  alignItems='center'
                  justify='flex-start'
                  onClick={() => this.onChange(0)}
                >
                  {this.state.doctor.nextAvailable &&
                  this.state.doctor.nextAvailable.slots &&
                  this.state.doctor.isAlternative ? (
                    <>
                      <DrawerNameFont
                        text='Hide calendar'
                        style={{ fontSize: '18px', cursor: 'pointer' }}
                      />
                      <img
                        src={arrowUp}
                        style={{ marginLeft: '30px', cursor: 'pointer' }}
                      />
                    </>
                  ) : (
                    <>
                      <DrawerNameFont
                        text='Alternative slots'
                        style={{ fontSize: '18px', cursor: 'pointer' }}
                      />
                      <img
                        src={arrow}
                        style={{ marginLeft: '30px', cursor: 'pointer' }}
                      />
                    </>
                  )}
                </Grid>
              ) : (
                ''
              )}
            </Grid>
            {this.state.doctor.isAlternative ? (
              <CustomCalendar
                events={this.getEvents(this.state.doctor.times)}
                onSelectEvent={(onSelectEvent) => {
                  console.log(onSelectEvent);
                  const doctor = {
                    ...this.state.doctor,
                    nextAvailable: {
                      slots: onSelectEvent.event.times.map(
                        (times) => times.time
                      ),
                      date: dayjs(onSelectEvent.event.start).format(
                        'YYYY-MM-DD'
                      ),
                    },
                    nextAvailiblityText: `Availability (${dayjs(
                      onSelectEvent.event.start
                    ).format('MMM DD, YYYY')})`,
                    isAlternative: false,
                  };
                  console.log(doctor);
                  this.setState({ doctor });
                }}
              />
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Doctor);
