/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from "react";
import {
  Dialog,
  Typography,
  Input,
  Button,
  createStyles,
  withStyles,
  Select,
  MenuItem,
} from "@material-ui/core";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import "../../../assets/css/select.css";
import styles from "./UpdatePopup.styles";
class AddPopup extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      //   isOpen: true,
      //   isUpdate: false,
    };
  }

  render() {
    const {
      classes,
      inputField,
      onChangeInput,
      onCancel,
      onAdd,
      isOpen,
      headerTitle,
      onEditorTextChange,
      onImageChange,
    } = this.props;
    return (
      <Dialog open={isOpen} onClose={() => onCancel()}>
        <div className={classes.root}>
          <Typography className={classes.textHead}>{headerTitle}</Typography>
          <div>
            {inputField.map((val, i) => (
              <>
                {val.fieldType === "input" && (
                  <>
                    <Typography className={classes.subText}>
                      {val.lable}
                    </Typography>
                    <Input
                      placeholder={val.lable}
                      disableUnderline
                      name={val.name}
                      value={val.value}
                      className={classes.input}
                      onChange={(e) => onChangeInput(e)}
                    />
                  </>
                )}
                {val.fieldType === "file" && (
                  <>
                    <Typography className={classes.subText}>
                      {val.lable}
                    </Typography>
                    <Input
                      type="file"
                      placeholder={val.lable}
                      disableUnderline
                      name={val.name}
                      value={val.value}
                      className={classes.input}
                      onChange={(e) => onImageChange(e)}
                    />
                  </>
                )}
                {val.fieldType === "select" && (
                  <>
                    <Typography className={classes.subText}>
                      {val.lable}
                    </Typography>
                    <Select
                      className={classes.input}
                      value={val.value}
                      name={val.name}
                      onChange={(e) => onChangeInput(e)}
                    >
                      {val.selectData.map((val, i) => (
                        <MenuItem key={i} value={val._id}>
                          {val.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {val.fieldType === "editor" && (
                  <>
                    <Typography className={classes.subText}>
                      {val.lable}
                    </Typography>
                    <CKEditor
                      editor={ClassicEditor}
                      data={val.value}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        onEditorTextChange(data);
                      }}
                    />
                  </>
                )}
              </>
            ))}
          </div>
          <Button onClick={() => onAdd()} className={classes.addButton}>
            Update
          </Button>
          <Button
            style={{ backgroundColor: "gray" }}
            className={classes.addButton}
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
        </div>
      </Dialog>
    );
  }
}

AddPopup.defaultProps = {
  inputField: [],
  onChangeInput: (val) => val,
};



export default withStyles(styles)(AddPopup);
