import React, { useState, useEffect } from 'react';
import { Text } from './style';

const PaymentStatusCell = ({ status }) => {
  const setColor = () => {
    if (
      status &&
      (status.toLowerCase() === 'unpaid' || status.toLowerCase() === 'refunded')
    ) {
      return 'red';
    } else if (status && status.toLowerCase() === 'completed') {
      return 'green';
    }
    return 'black';
  };
  return <Text color={setColor(status)}>{status}</Text>;
};

export default PaymentStatusCell;
