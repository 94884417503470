import { Grid } from '@material-ui/core';
import styled from 'styled-components';

const Row = styled(Grid)`
  margin-top: 10px;
`;

const Error = styled.span`
  color: red;
  font-size: 12px;
`;
export { Row, Error };
