import React from 'react';
import PropTypes from 'prop-types';
import Slim from '../../../utils/codecanyon/slim/slim.react';
import { Uploader } from './style';

// Test Points for uploader:
// 1- patient setting -> document upload
// 2- patient setting -> avatar upload
// 3- patient -> checkout process - verify info page upload docs
// 4- clinician settiing -> avatar upload
// 5- admin setting -> avatar upload
// 6- admin staff -> avatar upload
// 7- admin patients -> document upload

const ImageUploader = ({ src, uploadedImage, ratio, isLoading }) => {
  const slimService = (formdata, progress, success, failure, slim) => {
    success();
    uploadedImage(formdata);
  };

  return (
    <Uploader>
      <Slim
        ratio={ratio}
        initialImage={src}
        service={slimService}
        serviceFormat='file'
        labelLoading={isLoading}
        instantEdit
        push
      >
        <input type='file' name='image-uploader' />
      </Slim>
    </Uploader>
  );
};

ImageUploader.propTypes = {
  src: PropTypes.string,
  ratio: PropTypes.string,
  uploadedImage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

ImageUploader.defaultProps = {
  ratio: '1:1',
  isLoading: false,
};
export default ImageUploader;
