/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from "react";
import Home from "../dashboard/Home";
import Institude_banner from "../dashboard/Institude_banner";
import Calendarcomponets from "../sub_components/calender/Calender";
import Sidebar from "../components/Sidebar";

class Calender extends React.Component {
  render() {
    return (
      <div>
        <Sidebar />
        <Calendarcomponets />
      </div>
    );
  }
}

export default Calender;
