import React from 'react';
import moment from 'moment';

const CheckoutInvoiceTotals = ({ total, outstandingBalance, invoice }) => (
  <div className='item w-2/3 py-4 ml-auto mr-0'>
    <div className='total py-3 bg-white mb-1 whitespace-nowrap'>
      <table className='w-full border-0'>
        <tbody>
          <tr className='text-lg font-bold'>
            <td className='px-5'>Invoice Amount</td>
            <td className='px-5 text-right'>{total} AED</td>
          </tr>
          <tr className='text-sm font-normal'>
            <td className='px-5'>Due Date</td>
            <td className='px-5 text-right'>{moment(invoice.dueDate).format('YYYY-MM-DD')}</td>
          </tr>
        </tbody>
      </table>
    </div>
    { }
    <div className='total py-3 bg-white whitespace-nowrap'>
      <table className='w-full border-0'>
        <tbody>
          <tr className='text-sm font-normal'>
            <td className='px-5'>Amount Paid</td>
            <td className='px-5 text-right'>{`${Number(invoice.totalPaid ?? 0).toFixed(2)} AED`}</td>
          </tr>
          <tr className='text-sm font-normal'>
            <td className='px-5'>Outstanding Balance</td>
            <td className='px-5 text-right'>{(Number(total) - Number(invoice.totalPaid ?? 0)).toFixed(2)} AED</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

export default CheckoutInvoiceTotals;
