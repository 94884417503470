import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = '18%';

export default makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      zIndex: 1,
      overflow: 'hidden',
      display: 'flex',
      position: 'relative',
      width: '100%',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: '#fff',
      color: '#003d79',
      // backgroundColor: '#1976d2',
      maxHeight: '100px',
      '& .MuiToolbar-gutters': {
        height: '100px',
      },
    },
    title: {
      marginLeft: theme.spacing(5),
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
      fontSize: 20,
      fontWeight: 600,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      border: 'none',
    },
  }));
  