import React, { useState, useEffect } from 'react';
import { Dialog, Spinner } from '../../../components/atoms';
import { baseURL } from '../../../utils/axios';
import axios from 'axios';
import AlertMsg from '../../../utils/Alert';
import { CircularProgress } from '@material-ui/core';

const PhoneOTPModal = ({ clientPhoneNumber }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOTP] = useState('');
  const [showOTPDialog, setShowOTPDialog] = useState(false);
  const [showPhoneDialog, setShowPhoneDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setShowPhoneDialog(true);
  }, []);

  useEffect(() => {
    console.log('asdf', clientPhoneNumber);
    if (clientPhoneNumber) {
      setPhoneNumber(clientPhoneNumber);
    }
  }, [clientPhoneNumber]);

  const onClose = () => {};
  const updatePhoneNumber = () => {
    setLoading(true);
    const clientEmail = localStorage.getItem('clientEmail');

    axios
      .patch(
        `${baseURL}/api/client/`,
        { phoneNumber },
        {
          headers: {
            Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        localStorage.setItem('clientPhoneNumber', res.data.phoneNumber);
        setShowPhoneDialog(false);
        setShowOTPDialog(true);
        AlertMsg('success', 'Phone number added Successfully!');
      })
      .catch((err) => {
        setLoading(false);
        AlertMsg('error', err.response.data.reason);
      });
  };

  const verifyOTPForPhone = () => {
    setLoading(true);
    axios
      .put(`${baseURL}/api/client/verify_otp`, { phoneNumber, otp })
      .then((res) => {
        if (res.data.phoneNumberVerified) {
          localStorage.setItem(
            'phoneNumberVerified',
            res.data.phoneNumberVerified
          );
        }
        setLoading(false);
        closeOTPDialog();
        AlertMsg('success', 'Phone number verified Successfully!');
      })
      .catch((err) => {
        setLoading(false);
        AlertMsg('error', err.response.data.reason);
      });
  };

  const closeOTPDialog = () => setShowOTPDialog(false);

  return (
    <div>
      <Dialog open={showPhoneDialog} onClose={onClose}>
        <div className='px-5 py-3'>
          <p>Please Enter your phone number</p>
          <div>
            <input
              type='number'
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className='mt-3 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none'
            />
          </div>
          <div className='mt-3 flex justify-end'>
            <button
              className={`bg-blue-500 text-white font-bold py-2 px-4 rounded ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={updatePhoneNumber}
            >
              {loading ? <CircularProgress color='inherit' size={20} /> : 'Add'}
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog open={showOTPDialog} onClose={closeOTPDialog}>
        <div className='px-5 py-3'>
          <p>Please Verify The OTP we sent you on your phone number</p>
          <div>
            <input
              type='text'
              value={otp}
              onChange={(e) => setOTP(e.target.value)}
              className='mt-3 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none'
            />
          </div>
          <div className='mt-3 flex justify-end'>
            <button
              className={`bg-blue-500 text-white font-bold py-2 px-4 rounded ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={verifyOTPForPhone}
            >
              {loading ? (
                <CircularProgress color='inherit' size={20} />
              ) : (
                'Verify'
              )}
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default PhoneOTPModal;
