import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  title: {
    fontSize: 17,
    fontWeight: 600,
    color: '#003d79',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    marginBottom: 15,
    marginTop: 15,
  },
  preferrednamesty: {
    fontSize: 15,
    color: '#a29061',
    fontFamily: 'inherit',
  },
  radiosty: {
    paddingTop: theme.spacing(3),
    color: '#003d79',
  },
  dividersty: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: '98%',
  },
  paymentButton: {
    backgroundColor: '#a29061',
    borderColor: '#a29061',
    fontSize: 12,
    fontWeight: 600,
    border: '1px solid',
    cursor: 'pointer',
    color: '#ffffff',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    textTransform: 'capitalize',
    textAlign: 'center',
  },
  insuranceButton: {
    cursor: 'pointer',
    fontSize: 13,
    fontWeight: 600,
    color: '#003d79',
    border: '1px solid #003d79',
    textTransform: 'capitalize',
  },
  heading: {
    fontWeight: 600,
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontSize: 13,
    color: '#003d79',
    textTransform: 'capitalize',
    textAlign: 'left',
  },
  selectsty: {
    marginTop: theme.spacing(1.2),
  },
  cancelButton: {
    color: '#003d79',
    borderColor: '#003d79',
    backgroundColor: '#ffffff',
    fontSize: 12,
    fontWeight: 600,
    cursor: 'pointer',
    textAlign: 'center',
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
  },
  saveButton: {
    backgroundColor: '#a29061',
    borderColor: '#a29061',
    minWidth: '70px',
    width: 'auto',
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 600,
    color: '#ffffff',
    textAlign: 'center',
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    marginLeft: theme.spacing(3),
  },
});
