/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from "react";
import {
  Container,
  
  withStyles,
  CssBaseline,
  Typography,
  Grid,
  FormControlLabel,
  Radio,
  Input,
  Button,
} from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Link } from "react-router-dom";
import AlertMsg from "../../utils/Alert";
import styles from "./InsuranceFormStyle";

const planOption = [
  { value: "medical", label: "Medical" },
  { value: "rx", label: "Rx" },
];

const lengthRegex = /^(\w{11,11})$/;

class InsuranceForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      insuranceId: "",
      groupId: "",
      planType: "",
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { classes } = this.props;
    const { insuranceId, groupId } = this.state;
    const selectStyle = {
      ...style,
      control: (base) => ({
        ...base,
        "&:hover": { borderColor: "#003d79" }, // border style on hover
        border: "1px solid #003d79", // default border color
        boxShadow: "none", // no box-shadow
      }),
      singleValue: (base) => ({
        ...base,
        color: "#a29061",
      }),
      input: (base) => ({
        ...base,
        color: "#a29061",
      }),
    };
    return (
      <>
        <CssBaseline />
        <Container maxWidth="sm">
          <div className={classes.containerSty}>
            <Typography className={classes.headingStyle}>
              Insurance Form
            </Typography>
            <div className={classes.subContainer}>
              <Grid container>
                <Grid item lg={12} xs={12}>
                  <Typography className={classes.inputTextLableStyle}>
                    Insurance ID(should be of 11 character)
                  </Typography>
                  <Input
                    disableUnderline
                    className={classes.inputStyle}
                    onChange={this.handleChange}
                    value={insuranceId}
                    name="insuranceId"
                    onBlur={() =>
                      !lengthRegex.test(insuranceId) &&
                      AlertMsg(
                        "error",
                        "Insurance Id should be of 11 characters"
                      )
                    }
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <Typography className={classes.inputTextLableStyle}>
                    Group ID
                  </Typography>
                  <Input
                    disableUnderline
                    name="groupId"
                    value={groupId}
                    onChange={this.handleChange}
                    className={classes.inputStyle}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <Typography className={classes.inputTextLableStyle}>
                    Plan Type
                  </Typography>
                  <Select
                    styles={selectStyle}
                    options={planOption}
                    onChange={(data) => this.setState({ planType: data.value })}
                    placeholder="Select Plans..."
                  />
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignContent: "center",
                // marginTop: -20,
                marginBottom: 10,
              }}
            >
              <Link to="/client/profile" style={{ textDecoration: "none" }}>
                <Button
                  style={{
                    backgroundColor: "#003d79",
                    color: "#ffffff",
                    // padding: 0,
                    textTransform: "capitalize",
                    width: 200,
                  }}
                >
                  Back
                </Button>
              </Link>
              <Link
                to="/client/payment"
                style={{ textDecoration: "none", marginLeft: "10px" }}
              >
                <Button
                  style={{
                    backgroundColor: "#003d79",
                    color: "#ffffff",
                    // padding: 0,
                    textTransform: "capitalize",
                    width: 200,
                  }}
                >
                  Next
                </Button>
              </Link>
            </div>
          </div>
        </Container>
      </>
    );
  }
}


export default withStyles(styles)(InsuranceForm);
