/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import {
  addClient,
  getByIdClient,
  getClient,
  updateClient,
  deleteClient,
  addClientNote,
  sendMail,
  addChild,
  addAdult,
  getChild,
  getChildById,
  updateChild,
  uploadClientImage,
  getEmployer,
  updatePharmacy,
  getPharmacy,
  getEmployerId,
  uploadDocFile,
  getClientDataById,
  getClients,
  saveClientSignature,
  getInactiveAppointmentsOfClientBundle,
  getClientConsentForms,
  getClientsReport,
} from './client.reducer';
import {
  addAppointment,
  getAppointment,
  getByIdAppointment,
  updateAppointment,
  deleteAppointment,
  getServiceUser,
  getAppointmentByClientId,
  getAppointmentDetail,
  getPreviousAppointment,
  filterAppointments,
  updateToSeen,
  consent,
  signConsent,
  clinicianSign,
  getAllAppointmentByBilling,
  billingReportForAllAppointments,
} from './appointment.reducer';
import {
  addService,
  getService,
  getByIdService,
  updateService,
  deleteService,
} from './service.reducer';
import {
  getAllBilling,
  getAllInvoice,
  getSingleInvoice,
  getBillingsForAdmin,
} from './billing.reducer';
import {
  addRole,
  deleteRole,
  getByIdRole,
  getRole,
  updateRole,
} from './role.reducer';
import {
  addPermission,
  getByIdPermission,
  getPermission,
  updatePermission,
  deletePermission,
  getPermissionByRole,
  updatePermissionByRole,
} from './permission.reducer';
import {
  addUser,
  getUser,
  getByIdUser,
  updateUser,
  deleteUser,
} from './admin.user.reducer';
import {
  addRcad,
  getRcad,
  getByIdRcad,
  updateRcad,
  deleteRcad,
  submitUserRcad,
} from './rcad.reducer';
import {
  getClinician,
  getClinicianAppointment,
  getByIdClinician,
  getByIdsClinicians,
  saveClinicianSignature,
  loggedInUserInfo,
} from './clinician.reducer';
import {
  getPaymentUrl,
  getPaymentRes,
  paypal,
  eCheck,
  getUnpaidPaymentAction,
  getPaymentAction,
  markInvoiceAsPaid,
} from './payment.reducer';
import {
  addOnboarding,
  deleteOnboarding,
  getByIdOnboarding,
  getOnboarding,
  updateOnboarding,
  getOnboardingUser,
} from './admin.onboarding.reducer';
import {
  addMha,
  getByIdMha,
  getMha,
  updateMha,
  getMhaUser,
} from './admin.mha.reducer';
import {
  addP1of2,
  getByIdP1of2,
  getP1of2,
  updateP1of2,
  getP1of2User,
} from './admin.p1of2.reducer';
import {
  getUnpaidAppointment,
  getUpcomingAppointment,
  getNotification,
  UpdateNotification,
} from './dashbord.reducer';
import {
  getAllEvents,
  getAllEventsById,
  getClientEvents,
  cancelRSVP,
} from './events.reducer';
import {
  getAllPrescriptions,
  getAllPrescriptionsById,
} from './prescription.reducer';
import { userLogin, userSignup } from './auth.reducer';
import ClinicianAddressReducer from './clinician_address.reducer';
import SMSReducer from './sms.reducer';
import CompanyReducer from './company.reducer';
import {
  createBundle,
  getBundles,
  modifyBundles,
  cancellingBundle,
  getClientBundles,
} from './bundle.reducer';
import { getConsentForms, downloadConsetForm } from './consent.reducer';

const rootReducer = combineReducers({
  form,
  addClient,
  getClient,
  getByIdClient,
  updateClient,
  deleteClient,
  addClientNote,
  sendMail,
  addAppointment,
  getAppointment,
  getByIdAppointment,
  updateAppointment,
  deleteAppointment,
  getAppointmentDetail,
  getPreviousAppointment,
  getServiceUser,
  getAppointmentByClientId,
  addService,
  getService,
  getByIdService,
  updateService,
  deleteService,
  getAllBilling,
  getAllInvoice,
  getSingleInvoice,
  getBillingsForAdmin,
  addRole,
  getRole,
  getByIdRole,
  updateRole,
  deleteRole,
  addPermission,
  getPermission,
  getByIdPermission,
  updatePermission,
  deletePermission,
  getPermissionByRole,
  updatePermissionByRole,
  addUser,
  getUser,
  getByIdUser,
  updateUser,
  deleteUser,
  addRcad,
  getRcad,
  getByIdRcad,
  updateRcad,
  deleteRcad,
  submitUserRcad,
  getClinician,
  getClinicianAppointment,
  getByIdClinician,
  getPaymentUrl,
  getPaymentRes,
  paypal,
  eCheck,
  getUnpaidPaymentAction,
  getPaymentAction,
  addOnboarding,
  deleteOnboarding,
  getByIdOnboarding,
  getOnboarding,
  updateOnboarding,
  getOnboardingUser,
  addMha,
  getByIdMha,
  getMha,
  updateMha,
  getMhaUser,
  addP1of2,
  getByIdP1of2,
  getP1of2,
  updateP1of2,
  getP1of2User,
  getUnpaidAppointment,
  getUpcomingAppointment,
  getNotification,
  UpdateNotification,
  userLogin,
  userSignup,
  addChild,
  addAdult,
  getChild,
  getChildById,
  updateChild,
  uploadClientImage,
  getEmployer,
  updatePharmacy,
  getPharmacy,
  getEmployerId,
  uploadDocFile,
  getClientDataById,
  getAllEvents,
  getAllEventsById,
  getAllPrescriptions,
  getAllPrescriptionsById,
  ClinicianAddressReducer,
  SMSReducer,
  filterAppointments,
  updateToSeen,
  CompanyReducer,
  getByIdsClinicians,
  getClients,
  createBundle,
  getBundles,
  modifyBundles,
  cancellingBundle,
  getClientEvents,
  saveClientSignature,
  consent,
  signConsent,
  getClientBundles,
  getInactiveAppointmentsOfClientBundle,
  getConsentForms,
  saveClinicianSignature,
  clinicianSign,
  loggedInUserInfo,
  getClientConsentForms,
  downloadConsetForm,
  cancelRSVP,
  getAllAppointmentByBilling,
  getClientsReport,
  billingReportForAllAppointments,
  markInvoiceAsPaid,
});

export default rootReducer;
