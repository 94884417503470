import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {useRef, useState, useEffect} from 'react'

const useStyles = makeStyles({
  list: {
    width: '50%',
  },
  fullList: {
    width: '50%',
  },
});

export default function Drawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    // if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //   return;
    // }

    setState({ ...state, [anchor]: open });
  };

  return (
    <ClickAwayListener style={{width:"100%"}} onClickAway={() => toggleDrawer('right', false)}>
          <SwipeableDrawer
            onRequestChange={() => toggleDrawer('right', false) }
            className="custom-swipable-drawer"
            anchor={'right'}
            open={props.open}
            onClose={toggleDrawer('right', false)}
            onOpen={toggleDrawer('right', true)}
          >
            {props.children && props.children}
          </SwipeableDrawer>
      </ClickAwayListener>
  );
}