import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import Dashboard from '../../admin/components/Dashboard/Dashboard';
import AppMainNavigation from '../../common/Navigation/AppMainNavigation';
import Appointments from '../../admin/components/Appointment';
import Invoices from '../billing/billing_list/InvoiceList/Invoices';
import Checkout from '../billing/Checkout';
import Calender from '../../admin/components/Calender/Calender';
import Patients from '../../admin/components/Patients';
import TimeSlots from '../../admin/components/Calender/AdminTimeSlots';
import StaffMembers from '../../admin/components/Staff';
import Report from '../../admin/components/Reports/Reports';
import ServiceBundles from '../../containers/service_bundles';
import Corporation from '../../containers/corporation';
import Consent from '../../admin/components/Consent';
import ServiceTypeVAT from '../../admin/components/ServiceType/serviceType';
import SMS from '../../admin/components/SMS';
import ClinicAddresses from '../../admin/components/common/ClinicAddresses';
import Events from '../../admin/components/Events';
import AddEvents from '../../admin/components/Events/addEvents';
import { AddStaffMember } from '../../admin/components/Staff/AddStaffMember';
import AddPatient from '../../admin/components/Patients/AddPatient';
import SingleClinicianCalender from '../../admin/components/Calender/SingleClinicianCalender';
import SignleClinicianTimeSlots from '../../admin/components/Calender/SignleClinicianTimeSlots';
import EventsCategories from "../../admin/components/Events/EventsCategories"
import EventsTriggers from '../../admin/components/Events/EventsTriggers';
import Triggers from '../../admin/components/Triggers/EventsTriggers';

const Admin = (props) => {
    const ROUTES = [
        { path: '/admin/dashboard', component: Dashboard },
        { path: '/admin/appointments', component: Appointments },
        { path: '/admin/billing/', component: Invoices },
        { path: '/admin/billing/:invoiceId', component: Invoices },
        { path: '/admin/billing/checkout/:invoiceId', component: Checkout },
        { path: '/admin/calendar', component: Calender },
        { path: '/admin/calendar/:id', component: SingleClinicianCalender },
        { path: '/admin/patients', component: Patients },
        { path: '/admin/time-slots', component: TimeSlots },
        { path: '/admin/time-slots/:id', component: SignleClinicianTimeSlots },
        { path: '/admin/staff-members', component: StaffMembers },
        { path: '/admin/reports', component: Report },
        { path: '/admin/service-bundles', component: ServiceBundles },
        { path: '/admin/corporation', component: Corporation },
        { path: "/admin/corporation/add-new", component: Corporation },
        { path: "/admin/corporation/edit/:corporationId", component: Corporation,},
        { path: "/admin/corporation/events/:corporationId", component: Corporation },
        { path: "/admin/corporation/patients/:corporationId", component: Corporation },
        { path: '/admin/consent-forms', component: Consent },
        { path: '/admin/service-type', component: ServiceTypeVAT },
        { path: '/admin/clinic-addresses', component: ClinicAddresses },
        { path: '/admin/send-sms', component: SMS },
        { path: '/admin/events', component: Events },
        { path: '/admin/addevents', component: AddEvents },
        { path: '/admin/addevents/:id', component: AddEvents },
        { path: '/admin/staff/add-staff', component: AddStaffMember },
        { path: '/admin/staff/edit-staff/:id', component: AddStaffMember },
        { path: '/admin/staff/edit-staff', component: AddStaffMember },
        { path: '/admin/patients/edit-patient/:id', component: AddPatient },
        { path: '/admin/patients/add-patient', component: AddPatient },
        { path: '/admin/events/categories', component: EventsCategories },
        { path: '/admin/events/triggers', component: EventsTriggers },
        { path: '/admin/triggers', component: Triggers },
    ];

    return (
        <Router>
            {console.log("asd asd as d")}
            <AppMainNavigation />
            <div className="p-8">
                <Switch>
                    {ROUTES.map((route, i) => (
                        <Route
                            key={`corporation-route-${i}`}
                            path={route.path}
                            render={(routerProps) =>
                                React.createElement(route.component, {
                                    ...props,
                                    ...routerProps,
                                })
                            }
                            exact
                        />
                    ))}
                </Switch>
            </div>
        </Router>
    );
};

export default Admin;
