import {makeStyles} from "@material-ui/core";

// eslint-disable-next-line no-unused-vars
export default makeStyles((theme) => ({
    root: {
      // width: '1025px',
      // height: "40px",
      backgroundColor: "rgba(34,34,34, 60%)",
      color: "#ffffff",
      alignItems: 'center',
      justifyContent: 'space-around',
      flexDirection: 'row',
      display: 'flex',
      [theme.breakpoints.up('xs')]: {
        flexDirection: 'column',
        display: 'flex',
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        display: 'flex',
        height: "40px",
      },
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
        display: 'flex',
        height: "40px",
      },
      // width: '100%'
    },
    font: {
      fontSize: "15px",
      color: "#ffffff",
      fontWeight: 700,
      fontFamily: "Lato",
    },
  }));