import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CompanyInfoForm from './company_info_form';
import { Grid } from '@material-ui/core';
import { Button, Table, Spinner } from '../../../components/atoms';
import { CliniciansModal } from '../../../components/molecules';
import { PermIdentitySharp, DateRangeSharp } from '@material-ui/icons';
import { Content, LeftBtn, ActionGrid } from './style';
import AddressForm from './address_drawer';
import ContactForm from './contact_drawer';
import AddressRelatedContactsModal from './address_related_contacts_modal';
import { generateCompanyCode } from '../../../utils/validators';
import {
  createCompany,
  getCompanyById,
  updateCompany,
} from '../../../utils/actions/company.action';
import { getCliniciansByIds } from '../../../utils/actions/clinician.action';
import AlertMsg from '../../../utils/Alert';
import { DISCIPLINES } from '../../../utils/enums';

const AddressHeaders = {
  address: {
    title: 'Address',
    concat: true,
    fields: ['address1', 'address2', 'city', 'state', 'country'],
    concatOp: ' - ',
  },
  phoneNumber: { title: 'Phone Number' },
  email: { title: 'Email' },
};

const ContactHeaders = {
  name: {
    title: 'Name',
    concat: true,
    fields: ['firstName', 'lastName'],
    concatOp: ' ',
  },
  designation: { title: 'Designation' },
  phoneNumber: { title: 'Phone Number' },
  email: { title: 'Email' },
};

const CliniciansHeaders = {
  avatar: {
    title: 'avatar',
    concat: true,
    concatOp: ' ',
    fields: ['firstName', 'lastName'],
    isImageWithText: true,
  },
  discipline: { title: 'discipline', enumMap: true, enumList: DISCIPLINES },
};

const AddCorporation = ({
  history,
  match,
  getCompanyById,
  createCompany,
  updateCompany,
  loading,
  fetchError,
  updated,
  fetchedCompany,
  createdNewCompany,
  getCliniciansByIds,
  cliniciansDetails,
  clinicianRequest,
}) => {
  const [openRelatedContactsModal, setOpenRelatedContactsModal] =
    useState(false);
  const [contactsRelatedToAddress, setContactsRelatedToAddress] = useState([]);
  const [removingAddressId, setRemovingAddressId] = useState(null);
  const [editingAddress, setEditingAddress] = useState(null);
  const [editingContact, setEditingContact] = useState(null);
  const [openAddressDrawer, setOpenAddressDrawer] = useState(false);
  const [openContactDrawer, setOpenContactDrawer] = useState(false);
  const [openClinicianModal, setOpenClinicianModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [companyLogo, setCompanyLogo] = useState('');
  const [company, setCompany] = useState({
    info: {
      name: '',
      logo: null,
      description: '',
      code: generateCompanyCode(),
      companyType: '',
      email: '',
      phoneNumber: '',
    },
    addresses: [],
    contacts: [],
    clinicians: [],
    removeAddresses: [],
    removeContacts: [],
    removeClinicians: [],
  });

  useEffect(() => {
    if (match.params.corporationId) {
      getCompanyById(match.params.corporationId);
      setIsEditing(true);
    }
  }, []);

  useEffect(() => {
    if (!loading && !fetchError && fetchedCompany && !createdNewCompany) {
      const clonedAddresses = fetchedCompany.addresses.map((ad) =>
        JSON.parse(JSON.stringify(ad))
      );
      const clonedContacts = fetchedCompany.contacts.map((ad) =>
        JSON.parse(JSON.stringify(ad))
      );

      setCompanyLogo(fetchedCompany.logo);
      setCompany({
        info: {
          _id: fetchedCompany._id,
          name: fetchedCompany.name,
          logo: fetchedCompany.logo,
          description: fetchedCompany.description,
          code: fetchedCompany.code,
          companyType: fetchedCompany.companyType,
          email: fetchedCompany.email,
          phoneNumber: fetchedCompany.phoneNumber,
        },
        addresses: [...clonedAddresses],
        contacts: [...clonedContacts],
        clinicians: [],
        removeAddresses: [],
        removeContacts: [],
        removeClinicians: [],
      });

      if (fetchedCompany.clinicians && fetchedCompany.clinicians.length > 0) {
        getCliniciansByIds(
          fetchedCompany.clinicians.map((d) => d.clinicianId).join(',')
        );
      }
    } else if (!loading && fetchError && !fetchedCompany) {
      AlertMsg('error', 'Something went wrong!');
    }
  }, [loading, fetchError, fetchedCompany, createdNewCompany]);

  useEffect(() => {
    if (updated || createdNewCompany) {
      setCompany({
        info: {
          name: '',
          logo: null,
          description: '',
          code: generateCompanyCode(),
          companyType: '',
          email: '',
          phoneNumber: '',
        },
        addresses: [],
        contacts: [],
        clinicians: [],
        removeAddresses: [],
        removeContacts: [],
        removeClinicians: [],
      });
      history.push('/admin/corporation');
    }
  }, [updated, createdNewCompany]);

  useEffect(() => {
    if (!clinicianRequest && cliniciansDetails && fetchedCompany) {
      setCompany({ ...company, clinicians: [...cliniciansDetails] });
    }
  }, [cliniciansDetails, clinicianRequest]);

  const toggleClinicianModal = () => setOpenClinicianModal(!openClinicianModal);
  const toggleAddressDrawer = () => {
    if (openAddressDrawer) {
      setEditingAddress(null);
    }
    setOpenAddressDrawer(!openAddressDrawer);
  };
  const toggleContactDrawer = () => {
    if (company.addresses.length < 1) {
      AlertMsg('error', 'Please add addresses first before creating contacts!');
      return;
    }
    if (openContactDrawer) {
      setEditingContact(null);
    }
    setOpenContactDrawer(!openContactDrawer);
  };

  const handleCompanyInfo = (e) => {
    setCompany({
      ...company,
      info: { ...company.info, [e.target.name]: e.target.value },
    });
  };

  const readFile = (file) => {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();

      reader.onload = function (event) {
        var _event$target;

        resolve(
          event === null || event === void 0
            ? void 0
            : (_event$target = event.target) === null ||
              _event$target === void 0
            ? void 0
            : _event$target.result
        );
      };

      reader.onerror = function (event) {
        reader.abort();
        reject(event);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleImageUpload = async (logo) => {
    const result = await readFile(logo[0]);
    setCompanyLogo(result);
    setCompany({
      ...company,
      info: { ...company.info, logo: logo[0] },
    });
  };

  const handleAddressInfo = (newAddress) => {
    setCompany({ ...company, addresses: [...company.addresses, newAddress] });
    toggleAddressDrawer();
  };

  const handleAddContact = (newContact) => {
    setCompany({ ...company, contacts: [...company.contacts, newContact] });
    toggleContactDrawer();
  };

  const handleClinicianList = (cliniciansList) => {
    setCompany({ ...company, clinicians: [...cliniciansList] });
    toggleClinicianModal();
  };

  const handleAddressEdit = (addressId) => {
    const foundAddress = company.addresses.find(
      (address) => address._id === addressId
    );

    setEditingAddress({ ...foundAddress });
    setOpenAddressDrawer(true);
  };

  const handleAddressUpdate = (updatedAddress) => {
    let companyAddresses = [...company.addresses];
    const modifiedAddressIndex = companyAddresses.findIndex(
      (address) => address._id === updatedAddress._id
    );

    companyAddresses.splice(modifiedAddressIndex, 1, updatedAddress);

    setCompany({ ...company, addresses: [...companyAddresses] });
    setEditingAddress(null);
    setOpenAddressDrawer(false);
  };

  const handleRemoveAddress = (addressId) => {
    const contactsWithSameAddr = company.contacts.filter(
      (c) => c.addressId === addressId
    );

    if (contactsWithSameAddr.length > 0) {
      setOpenRelatedContactsModal(true);
      setContactsRelatedToAddress(contactsWithSameAddr);
      setRemovingAddressId(addressId);
    } else {
      let companyAddresses = [...company.addresses];
      const modifiedAddresses = companyAddresses.filter(
        (address) => address._id !== addressId
      );

      setCompany({
        ...company,
        addresses: [...modifiedAddresses],
      });

      if (isEditing) {
        company.removeAddresses.push(addressId);
      }

      setOpenAddressDrawer(false);
    }
  };

  const closeRelatedContactsModal = () => {
    setOpenRelatedContactsModal(false);
    setContactsRelatedToAddress([]);
  };

  const handleContactEdit = (contactId) => {
    const foundContact = company.contacts.find(
      (contact) => contact._id === contactId
    );

    setEditingContact({ ...foundContact });
    setOpenContactDrawer(true);
  };

  const handleContactUpdate = (updatedContact) => {
    let companyContactes = [...company.contacts];
    const modifiedContactIndex = companyContactes.findIndex(
      (contact) => contact._id === updatedContact._id
    );

    companyContactes.splice(modifiedContactIndex, 1, updatedContact);

    setCompany({ ...company, contacts: [...companyContactes] });
    setEditingContact(null);
    setOpenContactDrawer(false);
  };

  const handleRemoveContact = (contactId) => {
    let companyContacts = [...company.contacts];
    const modifiedContacts = companyContacts.filter(
      (contact) => contact._id !== contactId
    );

    setCompany({
      ...company,
      contacts: [...modifiedContacts],
    });

    if (isEditing) {
      company.removeContacts.push(contactId);
    }

    setOpenContactDrawer(false);
  };

  const handleSubmit = () => {
    let error;
    if (company.info.name.trim() === '') {
      error = 'Company Name can not be empty!';
    } else if (company.info.companyType.trim() === '') {
      error = 'Company Type can not be empty!';
    } else if (!company.info.logo) {
      error = 'Company Logo can not be empty!';
    } else if (!company.info.email) {
      error = 'Company email can not be empty!';
    } else if (!company.info.phoneNumber) {
      error = 'Company phone number can not be empty!';
    }
    if (error) {
      AlertMsg('error', error);
      return;
    }

    const formData = new FormData();
    formData.append('logo', company.info.logo);
    formData.append('corporate', JSON.stringify(company));
    isEditing ? updateCompany(formData) : createCompany(formData);
  };

  return (
    <Grid>
      <Spinner isOpen={loading} />
      {fetchedCompany && (
        <Grid justify='flex-end' container>
          <LeftBtn xs={2} item>
            <Button
              text='View Events'
              icon={<DateRangeSharp />}
              bgcolor='#9c8657'
              onClick={() =>
                history.push(`/admin/corporation/events/${fetchedCompany._id}`)
              }
            />
          </LeftBtn>
          <Grid xs={2} item>
            <Button
              text='View Patients'
              icon={<PermIdentitySharp />}
              onClick={() =>
                history.push(
                  `/admin/corporation/patients/${fetchedCompany._id}`
                )
              }
            />
          </Grid>
        </Grid>
      )}
      <Content>
        <CompanyInfoForm
          company={company.info}
          onChange={handleCompanyInfo}
          handleImageUpload={handleImageUpload}
          companyLogo={companyLogo}
        />
      </Content>

      <Content>
        <Table
          label='Company Addresses'
          data={company.addresses}
          headers={AddressHeaders}
          headerActionClick={toggleAddressDrawer}
          onEditClick={handleAddressEdit}
          // idKey={isEditing ? null : 'uid'}
          emptyTableMessage='No address added yet!'
          showEdit
          showContainerHeading
        />
      </Content>

      <Content>
        <Table
          label='Contacts'
          data={company.contacts}
          headers={ContactHeaders}
          headerActionClick={toggleContactDrawer}
          emptyTableMessage='No Contacts added yet!'
          onEditClick={handleContactEdit}
          // idKey={isEditing ? null : 'uid'}
          showEdit
          showContainerHeading
        />
      </Content>

      <Content>
        <Table
          label='Clinicians'
          data={company.clinicians}
          headers={CliniciansHeaders}
          headerActionClick={toggleClinicianModal}
          emptyTableMessage='No Clinicians added yet!'
          showContainerHeading
        />
      </Content>

      <ActionGrid justify='flex-end' container>
        <LeftBtn xs={2} item>
          <Button
            onClick={() => history.push('/admin/corporation')}
            text='Cancel'
            bgcolor='#9c8657'
          />
        </LeftBtn>
        <Grid xs={2} item>
          <Button onClick={handleSubmit} text='Save' />
        </Grid>
      </ActionGrid>

      <AddressForm
        isOpen={openAddressDrawer}
        toggleAddressDrawer={toggleAddressDrawer}
        addAddress={handleAddressInfo}
        editingAddress={editingAddress}
        updateAddress={handleAddressUpdate}
        removeAddress={handleRemoveAddress}
      />
      <ContactForm
        isOpen={openContactDrawer}
        toggleContactDrawer={toggleContactDrawer}
        addresses={company.addresses}
        addContact={handleAddContact}
        editingContact={editingContact}
        updateContact={handleContactUpdate}
        removeContact={handleRemoveContact}
      />
      <CliniciansModal
        isOpen={openClinicianModal}
        toggleClinicianModal={toggleClinicianModal}
        sendClinicianList={handleClinicianList}
        clinicians={company.clinicians}
      />
      <AddressRelatedContactsModal
        open={openRelatedContactsModal}
        contacts={contactsRelatedToAddress}
        addressId={removingAddressId}
        handleCancel={closeRelatedContactsModal}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  loading: state.CompanyReducer.loading,
  fetchedCompany: state.CompanyReducer.company,
  fetchError: state.CompanyReducer.error,
  cliniciansDetails: state.getByIdsClinicians.clinicians,
  clinicianRequest: state.getByIdsClinicians.request,
  updated: state.CompanyReducer.updated,
  createdNewCompany: state.CompanyReducer.createdNewCompany,
});

const mapDispatchToProps = (dispatch) => ({
  getCliniciansByIds: (ids) => dispatch(getCliniciansByIds(ids)),
  createCompany: (company) => dispatch(createCompany(company)),
  updateCompany: (company) => dispatch(updateCompany(company)),
  getCompanyById: (id) => dispatch(getCompanyById(id)),
});

AddCorporation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      corporationId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  getCompanyById: PropTypes.func.isRequired,
  createCompany: PropTypes.func.isRequired,
  updateCompany: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  getCliniciansByIds: PropTypes.func.isRequired,
  clinicianRequest: PropTypes.bool,
  fetchError: PropTypes.any,
  updated: PropTypes.bool,
  fetchedCompany: PropTypes.object,
  createdNewCompany: PropTypes.bool,
  cliniciansDetails: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCorporation);
