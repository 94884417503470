/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/state-in-constructor */
import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {

  withStyles,
  Typography,
  Divider,
  Box,
} from '@material-ui/core';
import History from './tab_activitys/History';
import SignInEvents from './tab_activitys/SignInEvents';
import AuditLog from './tab_activitys/AuditLog';
import styles from './Tabs_AccountActivityStyle';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class AccountActivityTabs extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography className={classes.title}>Account Activity</Typography>
        <Tabs
          value={this.state.value}
          indicatorColor="primary"
          textColor="#444"
          onChange={this.handleChange}
          aria-label="disabled tabs example"
        >
          <Tab value={0} label="History" className={classes.initabcolor} />
          <Tab
            value={1}
            label="Sign In Events"
            className={classes.initabcolor}
          />
          <Tab value={2} label="Audit Log" className={classes.initabcolor} />
        </Tabs>

        <Divider />

        <TabPanel
          value={this.state.value}
          index={0}
          className={classes.panelsty}
        >
          <History props={this.props} />
        </TabPanel>

        <TabPanel
          value={this.state.value}
          index={1}
          className={classes.panelsty}
        >
          <SignInEvents props={this.props} />
        </TabPanel>

        <TabPanel
          value={this.state.value}
          index={2}
          className={classes.panelsty}
        >
          <AuditLog props={this.props} />
        </TabPanel>
      </div>
    );
  }
}

export default withStyles(styles)(AccountActivityTabs);
