/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import {
  withStyles,
  Dialog,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import DatePicker, { registerLocale } from 'react-datepicker';
// import en from 'date-fns/locale/en';
import Paper from '@material-ui/core/Paper';
import { DropzoneArea } from 'material-ui-dropzone';
import style from '../Patient/pages/Setting_style';
import moment from 'moment';
import { characters } from '../utils/validators';
import countries_list from '../utils/countries_data';
import { ImageUploader } from '../components/atoms';
import { CLIENT_DOCUMENT_TYPES } from '../utils/enums';
// registerLocale('en', en);

const emptyState = {
  _id: null,
  firstName: '',
  lastName: '',
  dob: '',
  gender: 'select',
  maritalStatus: 'select',
  nationality: 'select',
  documentType: 'select',
  issueDate: '',
  fileData: '',
  file: '',
  expiryDate: '',
  idNumber: '',
  backsideImage: '',
  backsideImageBase64: '',
  errors: {
    firstName: '',
    lastName: '',
    dob: '',
    gender: '',
    maritalStatus: '',
    nationality: '',
    documentType: '',
    issueDate: '',
    expiryDate: '',
    file: '',
  },
};
class ProofOfIdentityModel extends Component {
  constructor(props) {
    super(props);
    this.state = { ...emptyState };
  }

  componentDidMount() {
    if (this.props.value) {
      const data = { ...this.props.value };
      if (data && data != {} && data.documentId) {
        console.log('data.documentId', data);
        const { dob, issueDate, expiryDate, documentId } = data;
        this.setState({
          ...this.props.value,
          fileData: documentId ? documentId.url : '',
          dob: new Date(dob),
          issueDate: new Date(issueDate),
          expiryDate: new Date(expiryDate),
          backsideImageBase64:
            documentId && documentId.documentType === 'uae_id'
              ? documentId.backsideImage
              : '',
        });
      } else {
        this.setState(emptyState);
      }
    }
  }

  readFile(file) {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();

      reader.onload = function (event) {
        var _event$target;

        resolve(
          event === null || event === void 0
            ? void 0
            : (_event$target = event.target) === null ||
              _event$target === void 0
            ? void 0
            : _event$target.result
        );
      };

      reader.onerror = function (event) {
        reader.abort();
        reject(event);
      };

      reader.readAsDataURL(file);
    });
  }

  async handleImageUpload(files) {
    if (files.length > 0) {
      var result = await this.readFile(files[0]);
      this.setState({
        file: files[0],
        fileData: result,
      });
    }
  }

  async handleBackImageUpload(files) {
    if (files.length > 0) {
      var result = await this.readFile(files[0]);
      this.setState({
        backsideImage: files[0],
        backsideImageBase64: result,
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    const { name, value } = e.target;
    const { errors } = this.state;
    switch (name) {
      case 'firstName':
        errors.firstName = characters.test(value)
          ? value.length === 0
            ? 'Please Enter First Name'
            : ''
          : 'Fill First Name';
        break;

      case 'lastName':
        errors.lastName = value.length === 0 ? 'Please Enter Last Name' : '';
        break;

      case 'dob':
        errors.address1 = value ? null : 'Date Of Birth is required!';
        break;

      case 'issueDate':
        errors.issueDate = value ? null : 'Issue Date is required!';
        break;

      case 'gender':
        errors.gender = characters.test(value)
          ? value.length === 0
            ? 'Please Select Gender'
            : ''
          : 'Gender is required!';
        break;
      case 'idNumber':
        errors.idNumber = value ? null : 'ID Number is required!';
        break;
      default:
        break;
    }
  };

  handleSubmit() {
    var validate = true;
    let data = { ...this.state };

    if (this.state.idNumber?.trim() === '') {
      const idNumber = 'ID Number is required!';
      this.setState({ errors: { ...this.state.errors, idNumber } });
      validate = false;
    }

    if (this.state.documentType == 'select') {
      let documentType = 'Document Type is required!';
      this.setState({ errors: { ...this.state.errors, documentType } });
      validate = false;
    }

    if (this.state.gender == 'select') {
      let gender = 'Gender is required!';
      this.setState({ errors: { ...this.state.errors, gender } });
      validate = false;
    }

    if (this.state.maritalStatus == 'select') {
      let maritalStatus = 'Marital Status is required!';
      this.setState({ errors: { ...this.state.errors, maritalStatus } });
      validate = false;
    }

    if (this.state.nationality == 'select') {
      let nationality = 'Nationality is required!';
      this.setState({ errors: { ...this.state.errors, nationality } });
      validate = false;
    }

    if (this.state.file == '' && !this.state._id) {
      this.setState({
        errors: { ...this.state.errors, file: 'Select a file' },
      });
      validate = false;
    }

    if (
      this.state.documentType === 'uae_id' &&
      !this.state.backsideImageBase64
    ) {
      this.setState({
        errors: { ...this.state.errors, file: 'Upload the backside of file' },
      });
      validate = false;
    } else if (this.state.documentType !== 'uae_id') {
      delete data.backsideImage;
      delete data.backsideImageBase64;
    }

    if (validate && this.props.handleSubmit) {
      if (data._id === null || data._id === '') delete data._id;
      this.props.handleSubmit(data);
    }
  }

  render() {
    const { classes } = this.props;
    const {
      firstName,
      lastName,
      dob,
      gender,
      maritalStatus,
      errors,
      nationality,
      documentType,
      issueDate,
      expiryDate,
      fileData,
      idNumber,
      backsideImageBase64,
    } = this.state;

    let countriesList = countries_list.map((item) => {
      return { label: item.name };
    });

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        PaperProps={{ style: { maxWidth: '720px' } }}
      >
        <Grid
          style={{ height: '100%', margin: 'auto auto', width: '700px' }}
          justify='center'
          alignItems='center'
          container
        >
          <Grid
            style={{
              backgroundColor: 'white',
              padding: '8px',
              borderRadius: '4px',
              width: '100%',
            }}
          >
            <div>
              <Grid className={classes.modalDisplay}>
                <Grid container style={{ marginBottom: '15px' }}>
                  <Grid item xs={12} sm={6} style={{ padding: '16px' }}>
                    <div className='h-48'>
                      <ImageUploader
                        src={fileData ? fileData.toString() : ''}
                        uploadedImage={this.handleImageUpload.bind(this)}
                        ratio='free'
                      />
                    </div>
                    {documentType === 'uae_id' && (
                      <div className='mt-10 h-48'>
                        <ImageUploader
                          src={
                            backsideImageBase64
                              ? backsideImageBase64.toString()
                              : ''
                          }
                          uploadedImage={this.handleBackImageUpload.bind(this)}
                          ratio='free'
                        />
                      </div>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Grid item xs={12} style={{ marginBottom: '15px' }}>
                      <Typography className={classes.inputTitle}>
                        Document Type*
                      </Typography>
                      <FormControl className={classes.inputsty}>
                        <Select
                          value={documentType}
                          onChange={this.handleChange}
                          name='documentType'
                        >
                          <MenuItem value={'select'}>Select</MenuItem>
                          {Object.keys(CLIENT_DOCUMENT_TYPES).map((cdt) => (
                            <MenuItem value={cdt}>
                              {CLIENT_DOCUMENT_TYPES[cdt]}
                            </MenuItem>
                          ))}
                        </Select>
                        <span className={classes.errorText}>
                          {errors.documentType && errors.documentType}
                        </span>
                      </FormControl>
                    </Grid>
                    <Grid style={{ marginBottom: '15px' }} container>
                      <Typography className={classes.inputTitle}>
                        ID Number*
                      </Typography>
                      <input
                        className={classes.inputsty}
                        value={idNumber}
                        onChange={this.handleChange}
                        type='text'
                        name='idNumber'
                      />
                      <span className={classes.errorText}>
                        {errors.idNumber && errors.idNumber}
                      </span>
                    </Grid>
                    <Grid container style={{ marginBottom: '15px' }}>
                      <Grid item xs={6} style={{ paddingRight: '10px' }}>
                        <Typography className={classes.inputTitle}>
                          First Name*
                        </Typography>
                        <input
                          className={classes.inputsty}
                          value={firstName}
                          onChange={this.handleChange}
                          type='text'
                          name='firstName'
                        />
                        <span className={classes.errorText}>
                          {errors.firstName && errors.firstName}
                        </span>
                      </Grid>
                      <Grid item xs={6} style={{ paddingLeft: '10px' }}>
                        <Typography className={classes.inputTitle}>
                          Last Name*
                        </Typography>
                        <input
                          className={classes.inputsty}
                          value={lastName}
                          onChange={this.handleChange}
                          type='text'
                          name='lastName'
                        />
                        <span className={classes.errorText}>
                          {errors.lastName && errors.lastName}
                        </span>
                      </Grid>
                    </Grid>

                    <Grid style={{ marginBottom: '15px' }}>
                      <Typography className={classes.inputTitle}>
                        Date Of Birth*
                      </Typography>
                      <DatePicker
                        locale='en'
                        className={classes.inputsty}
                        dateFormat='MMMM d, yyyy'
                        selected={dob}
                        showYearDropdown
                        name='dob'
                        dropdownMode='select'
                        showMonthDropdown
                        onChange={(date) => (
                          this.setState({ dob: date }), (errors.dob = null)
                        )}
                      />
                      <span className={classes.errorText}>
                        {errors.dob && errors.dob}
                      </span>
                    </Grid>

                    <Grid container style={{ marginBottom: '15px' }}>
                      <Grid item xs={6} style={{ paddingRight: '10px' }}>
                        <Typography className={classes.inputTitle}>
                          Gender*
                        </Typography>
                        <FormControl className={classes.inputsty}>
                          <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={gender}
                            name='gender'
                            onChange={this.handleChange}
                          >
                            <MenuItem value={'select'}>Select</MenuItem>
                            <MenuItem value={'male'}>Male</MenuItem>
                            <MenuItem value={'female'}>Female</MenuItem>
                          </Select>
                          <span className={classes.errorText}>
                            {errors.gender && errors.gender}
                          </span>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6} style={{ paddingLeft: '10px' }}>
                        <Typography className={classes.inputTitle}>
                          Marital Status*
                        </Typography>
                        <FormControl className={classes.inputsty}>
                          <Select
                            value={maritalStatus}
                            onChange={this.handleChange}
                            name='maritalStatus'
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                          >
                            <MenuItem value={'select'}>Select</MenuItem>
                            <MenuItem value={'married'}>Married</MenuItem>
                            <MenuItem value={'single'}>Single</MenuItem>
                            <MenuItem value={'divorced'}>Divorced</MenuItem>
                            <MenuItem value={'widowed'}>Widowed</MenuItem>
                            <MenuItem value={'seperated'}>Seperated</MenuItem>
                          </Select>
                          <span className={classes.errorText}>
                            {errors.maritalStatus && errors.maritalStatus}
                          </span>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container style={{ marginBottom: '15px' }}>
                      <Grid item xs={6} style={{ paddingRight: '10px' }}>
                        <Typography className={classes.inputTitle}>
                          Issue Date*
                        </Typography>
                        <DatePicker
                          locale='en'
                          className={classes.inputsty}
                          dateFormat='MMMM d, yyyy'
                          selected={issueDate}
                          showYearDropdown
                          name='issueDate'
                          dropdownMode='select'
                          showMonthDropdown
                          onChange={(date) => (
                            this.setState({ issueDate: date }),
                            (errors.issueDate = null)
                          )}
                        />
                        <span className={classes.errorText}>
                          {errors.issueDate && errors.issueDate}
                        </span>
                      </Grid>

                      <Grid item xs={6} style={{ paddingLeft: '10px' }}>
                        <Typography className={classes.inputTitle}>
                          Expiry Date*
                        </Typography>
                        <DatePicker
                          locale='en'
                          className={classes.inputsty}
                          dateFormat='MMMM d, yyyy'
                          selected={expiryDate}
                          showYearDropdown
                          name='expiryDate'
                          dropdownMode='select'
                          showMonthDropdown
                          onChange={(date) => (
                            this.setState({ expiryDate: date }),
                            (errors.expiryDate = null)
                          )}
                        />
                        <span className={classes.errorText}>
                          {errors.expiryDate && errors.expiryDate}
                        </span>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} style={{ marginBottom: '15px' }}>
                      <Typography className={classes.inputTitle}>
                        Nationality*
                      </Typography>
                      <FormControl className={classes.inputsty}>
                        <Select
                          value={nationality}
                          onChange={this.handleChange}
                          name='nationality'
                        >
                          {countries_list.map((item) => (
                            <MenuItem key={item.name} value={item.name}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <span className={classes.errorText}>
                          {errors.nationality && errors.nationality}
                        </span>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={{ padding: '16px' }} justify='flex-end' container>
                <Grid item xs={12} lg={6}>
                  <Button
                    style={{ width: '100%' }}
                    onClick={this.props.handleClose && this.props.handleClose}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button
                    variant='contained'
                    color='secondary'
                    style={{ width: '100%' }}
                    onClick={this.handleSubmit.bind(this)}
                  >
                    {this.state._id ? 'Update' : 'Create'}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

export default withStyles(style)(ProofOfIdentityModel);
