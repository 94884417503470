import {
  CREATE_COMPANY_REQUEST,
  GET_COMPANIES_WITH_ASSOCIATIONS_REQUEST,
  GET_COMPANY_REQUEST,
  UPDATE_COMPANY_REQUEST,
  GET_ALL_COMPANIES_REQUEST,
} from '../constant';

export const createCompany = (body) => ({
  type: CREATE_COMPANY_REQUEST,
  endpoint: '/api/corporations',
  body,
});

export const getCompaniesWithAssociations = (
  page,
  sortType,
  sortBy,
  searchText
) => ({
  type: GET_COMPANIES_WITH_ASSOCIATIONS_REQUEST,
  endpoint: `/api/corporations?page=${page}&sortType=${sortType}&sortBy=${sortBy}&searchText=${searchText}`,
});

export const getCompanyById = (id) => ({
  type: GET_COMPANY_REQUEST,
  endpoint: `/api/corporations/${id}`,
});

export const updateCompany = (body) => ({
  type: UPDATE_COMPANY_REQUEST,
  endpoint: '/api/corporations',
  body,
});

export const getAllCompanies = (page, textToSearch) => ({
  type: GET_ALL_COMPANIES_REQUEST,
  endpoint: `/api/corporations/search?page=${page}&text=${textToSearch}`,
});
