import React, { useEffect, useState } from 'react';
import { baseURL } from '../../../utils/axios';
import axios from 'axios';
import AlertMsg from '../../../utils/Alert';
import { Drawer } from '@material-ui/core';
import { Spinner } from '../../../components/atoms';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';

const ReplicateEventDrawer = ({
  open,
  cancelReplication,
  event,
  replicated,
}) => {
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState([
    { date: new Date(), startTime: new Date(), endTime: new Date() },
  ]);

  const [numberOfDuplication, setNumberOfDuplication] = useState(1);

  const handleDateChange = (name, date, index) => {
    let mDate = moment(date);
    mDate.set({ second: 0, millisecond: 0 });

    let updatingDates = [...dates];
    let prevDay = updatingDates[index];

    updatingDates[index] = { ...prevDay, [name]: mDate };
    setDates(updatingDates);
  };

  useEffect(() => {
    if (numberOfDuplication > dates.length) {
      const newDates = Array.from(
        { length: numberOfDuplication - dates.length },
        () => {
          let mDate = moment();
          mDate.set({ second: 0, millisecond: 0 });
          return { date: mDate, startTime: mDate, endTime: mDate };
        }
      );

      setDates([...dates, ...newDates]);
    } else {
      setDates(dates.slice(0, numberOfDuplication));
    }
  }, [numberOfDuplication]);

  const createDuplicateEvent = () => {
    const updatedDates = dates.map((d) => {
      let start = moment(d.startTime);
      let end = moment(d.endTime);

      d.startTime = moment(d.date).set({
        hour: start.get('hour'),
        minute: start.get('minutes'),
      });

      d.endTime = moment(d.date).set({
        hour: end.get('hour'),
        minute: end.get('minutes'),
      });

      return d;
    });
    setLoading(true);
    const data = {
      dates: updatedDates,
      replicatingEventId: event,
    };

    axios
      .post(`${baseURL}/api/event/createDuplicate`, data)
      .then((res) => {
        setLoading(false);
        replicated();
        AlertMsg('success', 'Successfully created!');
      })
      .catch((err) => {
        setLoading(false);
        AlertMsg('error', err.message);
      });
  };

  return (
    <div>
      {loading && <Spinner isOpen />}
      <Drawer anchor={'right'} open={open} onClose={cancelReplication}>
        <div style={{ width: '700px', padding: '40px 25px' }}>
          <div className='flex flex-col '>
            <label htmlFor='numberOfDuplication'>Number of Duplication</label>
            <input
              type='number'
              value={numberOfDuplication}
              min='1'
              onChange={(e) => setNumberOfDuplication(e.target.value)}
              style={{ border: '1px solid #ccc', padding: '6px 9px' }}
            />
          </div>

          {dates.map((day, i) => (
            <div className='flex justify-between mt-6' key={`date_${i}`}>
              <div className='w-4/12'>
                <label>Date</label>
                <div className='border rounded w-9/12'>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      style={{ width: '100%', paddingLeft: '12px' }}
                      disableToolbar
                      variant='inline'
                      format='MM/dd/yyyy'
                      margin='normal'
                      minDate={new Date()}
                      value={day.date}
                      onChange={(e) => handleDateChange('date', e, i)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>

              <div className='w-4/12'>
                <label>Start time</label>
                <div className='border rounded w-9/12'>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      margin='normal'
                      style={{ width: '100%', paddingLeft: '12px' }}
                      value={day.startTime}
                      onChange={(e) => handleDateChange('startTime', e, i)}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className='w-4/12'>
                <label>End time</label>
                <div className='border rounded w-9/12'>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      margin='normal'
                      style={{ width: '100%', paddingLeft: '12px' }}
                      value={day.endTime}
                      onChange={(e) => handleDateChange('endTime', e, i)}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className='w-full flex justify-end px-6 py-10'>
          <button
            className='bg-transparent hover:bg-blue-500 text-blue-700 font-semibold py-2 px-4 border border-blue-500 rounded'
            onClick={cancelReplication}
          >
            cancel
          </button>
          <button
            className='bg-blue-500 text-white font-bold py-2 px-4 rounded ml-3'
            onClick={createDuplicateEvent}
          >
            Duplicate
          </button>
        </div>
      </Drawer>
    </div>
  );
};

export default ReplicateEventDrawer;
