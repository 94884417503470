/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable import/prefer-default-export */
import {
  ADD_PERMISSION_REQUEST,
  GET_PERMISSION_REQUEST,
  GET_PERMISSION_BY_ID_REQUEST,
  UPDATE_PERMISSION_REQUEST,
  DELETE_PERMISSION_REQUEST,
  GET_PERMISSION_BY_ROLE_REQUEST,
  UPDATE_PERMISSION_BY_ROLE_REQUEST,
} from "../AdminConstent";

export const addPermissionction = (data) => ({
  type: ADD_PERMISSION_REQUEST,
  endpoint: "/admin/scope",
  body: data,
});

export const getPermissionAction = () => ({
  type: GET_PERMISSION_REQUEST,
  endpoint: "/admin/scope",
});

export const getByIdPermissionAction = (id) => ({
  type: GET_PERMISSION_BY_ID_REQUEST,
  endpoint: `/admin/scope/${id}`,
});

export const updatePermissionAction = (id, data) => ({
  type: UPDATE_PERMISSION_REQUEST,
  endpoint: `/admin/scope/${id}`,
  body: data,
});

export const deletePermissionAction = (id) => ({
  type: DELETE_PERMISSION_REQUEST,
  endpoint: `/admin/scope/${id}`,
});

export const getPermissionByRoleAction = (id) => ({
  type: GET_PERMISSION_BY_ROLE_REQUEST,
  endpoint: `/admin/permission/${id}/role`,
});

export const updatePermissionByRoleAction = (id, data) => ({
  type: UPDATE_PERMISSION_BY_ROLE_REQUEST,
  endpoint: `/admin/role/${id}/scopes`,
  body: data,
});
