import React from "react";
import CommentsInputView from "./CommentsInputView";
import CommentsListItemView from "./CommentsListItemView";
import Axios from 'axios';
import { baseURL } from '../../../../utils/axios';
import Dialog from '../../../../components/atoms/TailwindDialog'
import BtnLinkStandard from "../../CreateInvoice/BtnLinkStandard";

const CommentsView = (props) => {
  const [notes, setNotes] = React.useState([]);
  const [note, setNote] = React.useState({});
  const [deletingNote, setDeletingNote] = React.useState({});
  const [dialog, setDialog] = React.useState(false);

  React.useEffect(() => {
    setNotes([]);
    getNotes();
  }, [props.appointmentId, props.clientId, props.billingId]);

  const postNote = (note) => {
    let data = { ...note };

    if (props.appointmentId != undefined) {
      data.formData.append('appointmentId', props.appointmentId)
    } else if (props.clientId != undefined) {
      data.formData.append('clientId', props.clientId)
    } else if (props.billingId != undefined) {
      data.formData.append('billingId', props.billingId)
    }

    if (data._id == undefined) {
      createNote(note.formData)
    } else {
      patchNote(note.formData)
    }
  };

  const createNote = (data) => {
    Axios.post(`${baseURL}/admin/notes`, data, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        getNotes();
        if (props.onSubmit) {
          props.onSubmit()
        }
        setNote({ note: '' });
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  }

  const patchNote = (data) => {
    Axios.patch(`${baseURL}/admin/notes/${note._id}`, data, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        getNotes();
        if (props.onSubmit) {
          props.onSubmit()
        }
        setNote({ note: '' });
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  }

  const getNotes = () => {
    var url = `${baseURL}/admin/notes`;

    if (props.appointmentId != undefined) {
      url += `?appointmentId=${props.appointmentId}`;
    } else if (props.clientId != undefined) {
      url += `?clientId=${props.clientId}`;
    } else if (props.billingId != undefined) {
      url += `?billingId=${props.billingId}`;
    }

    Axios.get(url, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        setNotes(res.data);
        console.log(res.data);
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const onSubmit = (note) => {
    postNote(note)
  }

  const deleteNotes = (note) => {
    if (!note._id) return;
    Axios.delete(`${baseURL}/admin/notes/${note._id}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        getNotes();
        if (props.onSubmit) {
          props.onSubmit()
        }
        setDialog(false)
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const onEdit = (note) => {
    console.log(note)
    setNote(note)
  }

  const onDelete = (note) => {
    deleteNotes(note)
  }

  return (
    <>
      <div className="mt-8">
        <h3 className="title text-lg font-bold mb-2">Comments</h3>
        <div className="bg-brand-blue-light border">
          <CommentsInputView note={note} onSubmit={onSubmit} onCancel={() => setNote({})} />
          { }
          {notes.map((note) =>
            <CommentsListItemView isEditing={true} note={note} onEdit={onEdit} onDelete={(note) => {
              setDialog(true)
              setDeletingNote(note)
            }} />
          )}
        </div>
      </div>
      <Dialog
        open={dialog}
        onClose={() => {
          setDialog(false);
        }}
      >
        <div class='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
          <div class='bg-white px-4 pt-5 pb-0 sm:p-6'>
            <div class='flex flex-col items-start'>
              <div class='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                <svg
                  class='h-6 w-6 text-red-600'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  aria-hidden='true'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                  />
                </svg>
              </div>
              <div class='mt-2 text-left'>
                <h3
                  class='modal-title text-lg leading-6 font-medium text-gray-900 text-center sm:text-left'
                  id='modal-title'
                >
                  Delete Note
                </h3>
                <div class='mt-2'>
                  <p class='modal-message text-sm text-gray-500'>
                    You are about to delete this note
                    < span className='text-red-500' >
                      This action cannot be undone.
                    </span>
                    click cancel to abort.
                  </p>
                </div>
              </div>
            </div>
          </div>


          <form className="pt-4" onSubmit={(e) => {
            e.preventDefault()
            deleteNotes(deletingNote)
            setDeletingNote(null)
          }}>
            <div class='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse items-center'>
              <button
                type='submit'
                class='h-full w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
              >
                Delete
              </button>
              <BtnLinkStandard onClick={() => setDialog(false)} >Cancel</BtnLinkStandard>
            </div>
          </form>
        </div>
      </Dialog>
    </>)
};

export default CommentsView;
