/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React, { PureComponent } from 'react';
import {
  withStyles,
  Grid,
} from '@material-ui/core';
import {
  DrawerTitleFont,
} from '../../common/DrawerContent/DrawerFont';
import { DrawerBox } from '../../common/DrawerContent/DrawerBox';
import styles from "./UpcomingVisits_style";

class UpcomingVisits extends PureComponent {
  
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, data } = this.props;
    return (
      <div>
        <Grid className={classes.container}>
          <DrawerTitleFont text="Upcoming Visit" />
          {/* <CustomDrawer data={upcomingVisits} /> */}
          <Grid className={classes.boxes}>
            <DrawerBox handleCancelAppointment={this.props.handleCancelAppointment} handleRescheduleAppointment={this.props.handleRescheduleAppointment} data={data} />
          </Grid>
        </Grid>
      </div>
    );
  }
}



export default withStyles(styles)(UpcomingVisits);
