import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import Paper from '@material-ui/core/Paper';
import { DropzoneArea } from 'material-ui-dropzone';
import profile from '../../../assets/imgs/ananyaBig.png';
import AlertMsg from '../../../utils/Alert';
import '../../../Patient/pages/main.css';
import Loader from '../../../components/Loader/Loader';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  uploadClientImageAction,
  uploadPharmacyAction,
  uploadDocFileAction,
  getClientDetailAction,
} from '../../../utils/actions/client.action';
import countries from '../../../utils/countries_data';
import Layout from '../common/AdminLayout';
import Axios from 'axios';
import { baseURL } from '../../../utils/axios';
import { getAllCompanies } from '../../../utils/actions/company.action';
import AddressView from '../common/ClinicAddresses/AddressView';
import styles from './AddPatient.styles';
import {
  AutoComplete,
  ImageUploader,
  Spinner,
} from '../../../components/atoms';
// import en from 'date-fns/locale/en';
import { JOB_DESCRIPTION } from '../../../utils/enums';

const JOB_LIST = Object.keys(JOB_DESCRIPTION).map((job, i) => ({
  _id: job,
  name: JOB_DESCRIPTION[job],
}));

const formattedDescription = (description) => {
  const reformattedStr = description.split('-').join(' ').toUpperCase();
  return { _id: reformattedStr, name: JOB_DESCRIPTION[reformattedStr] };
};

// registerLocale('en', en);

class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      isSocialLogin: false,
      dob: '',
      gender: '',
      maritalStatus: '',
      corporationId: '',
      phoneNumber: '',
      phoneType: '',
      address1: '',
      address2: '',
      city: '',
      zip: '',
      state: '',
      country: '',
      email: '',
      secondaryEmail: '',
      password: '',
      cpassword: '',
      avatar: '',
      errors: {
        firstName: { label: 'First Name', text: '' },
        lastName: { label: 'Last Name', text: '' },
        dob: { label: 'Date of birth', text: '' },
        gender: { label: 'Gender', text: '' },
        maritalStatus: { label: 'Martial Status', text: '' },
        phoneNumber: { label: 'Phone Number', text: '' },
        phoneType: { label: 'Phone Type', text: '' },
        address1: { label: 'Address1', text: '' },
        city: { label: 'City', text: '' },
        country: { label: 'Country', text: '' },
        email: { label: 'Email', text: '' },
        password: { label: 'Password', text: '' },
        cpassword: { label: 'Verify Password', text: '' },
      },
      profileImage: profile,
      loading: false,
      imageUrls: [],
      emloyerData: [],
      p_name: '',
      p_address: '',
      p_addressOptional: '',
      p_city: '',
      p_zip: '',
      p_country: '',
      p_phoneNumber: '',
      p_clientId: '',
      employerToggle: true,
      eId: '',
      doc_file: '',
      is_doc_file: false,
      btnLoading: false,
      isEdit: false,
      ClientId: undefined,
    };
  }

  componentDidMount() {
    var currentURL = window.location.href;
    var id = currentURL.substring(currentURL.lastIndexOf('/') + 1);
    if (id !== 'add-patient') {
      this.setState({ isEdit: true });
      this.props.getClientDetailAction(id);
      this.setState({ ClientId: id });
      this.setState({ loading: true });
    }
  }

  componentDidUpdate(preProps) {
    let urls = [];
    if (this.props.uploadClientImageData !== preProps.uploadClientImageData) {
      urls =
        this.props.uploadClientImageData &&
          this.props.uploadClientImageData.uploadClientImageData &&
          this.props.uploadClientImageData.uploadClientImageData.urls
          ? this.props.uploadClientImageData.uploadClientImageData.urls
          : [];
      this.setState({ imageUrls: urls });
      this.setState({ avatar: urls[0] });
    }

    if (this.props.uploadDocFileData !== preProps.uploadDocFileData) {
      urls =
        this.props.uploadDocFileData &&
          this.props.uploadDocFileData.uploadDocFileData &&
          this.props.uploadDocFileData.uploadDocFileData.urls
          ? this.props.uploadDocFileData.uploadDocFileData.urls
          : [];
      this.setState(
        { is_doc_file: urls.length > 0 ? true : false },
        this.setState({ doc_file: urls[0] })
      );
    }

    if (this.props.getClientDataByIdData !== preProps.getClientDataByIdData) {
      var data =
        this.props.getClientDataByIdData &&
          this.props.getClientDataByIdData.getClientDataByIdData
          ? this.props.getClientDataByIdData.getClientDataByIdData
          : false;
      let country = countries.filter(
        (itm) => data && data.address && itm.name === data.address.country
      );
      if (data) {
        this.setState({
          firstName: data.firstName ? this.getFirstName(data.firstName) : '',
          lastName: data.lastName
            ? data.lastName
            : this.getLastName(data.firstName),
          isSocialLogin: data.isSocialLogin,
          email: data.email ? data.email : '',
          phoneNumber: data.phoneNumber ? data.phoneNumber : '',
          phoneType: data.phoneType ? data.phoneType : '',
          gender: data.gender ? data.gender : '',
          dob: data.dob ? new Date(data.dob) : null,
          maritalStatus: data.maritalStatus ? data.maritalStatus : '',
          corporationId: data.corporationId ? data.corporationId : '',
          address1: data.address ? data.address.address1 : '',
          address2: data.address ? data.address.address2 : '',
          country: country.length > 0 ? country[0] : null,
          state: data.address ? data.address.state : '',
          city: data.address ? data.address.city : '',
          zip: data.address ? data.address.zip : '',
          avatar: data.avatar ? data.avatar : '',
          profileImage: data.avatar ? data.avatar : profile,
          doc_file: data.idCopy ? data.idCopy : '',
          is_doc_file: data.idCopy ? true : false,
          jobDescription: data.jobDescription
            ? formattedDescription(data.jobDescription)
            : '',
          loading: false,
        });
        this.setState({ loading: false });
      }
    }
  }

  getFirstName = (name) => {
    const res = name.split(' ');
    return res[0] ? res[0] : '';
  };

  getLastName = (name) => {
    const res = name.split(' ');
    return res[1] ? res[1] : '';
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const error =
      value === undefined || value.trim() === ''
        ? `${this.state.errors[name].label} is required!`
        : '';

    this.setState((prevState) => ({
      [name]: value,
      errors: {
        ...prevState.errors,
        [name]: { ...prevState.errors[name], text: error },
      },
    }));
  };

  readFile(file) {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();

      reader.onload = function (event) {
        var _event$target;

        resolve(
          event === null || event === void 0
            ? void 0
            : (_event$target = event.target) === null ||
              _event$target === void 0
              ? void 0
              : _event$target.result
        );
      };

      reader.onerror = function (event) {
        reader.abort();
        reject(event);
      };

      reader.readAsDataURL(file);
    });
  }

  handleCreateAddress = (address) => {
    this.setState({
      address1: address.address1,
      address2: address.address2,
      state: address.state,
      city: address.city,
      country: address.country,
      zip: address.zip,
    });
  };

  async documentUpload(selectedFiles) {
    this.setState({ btnLoading: true });
    const formData = new FormData();
    formData.append('document', selectedFiles);
    console.log(formData);
    if (this.state.ClientId) {
      Axios({
        method: 'post',
        url: `${baseURL}/api/user/uploadClient/${this.state.ClientId}?type=profileImage`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }).then((res) => this.setState({ btnLoading: false }));
    }
  }

  async docFileUpload(selectedFiles) {
    const formData = new FormData();
    formData.append('document', selectedFiles);
    await this.props.uploadDocFileAction(formData, '');
  }

  async handleImageUpload(files) {
    if (files.length > 0) {
      var result = await this.readFile(files[0]);
      await this.documentUpload(files[0]);
      this.setState({
        profileImage: result,
      });
    }
  }

  async handleDocUpload(e) {
    await this.docFileUpload(e.target.files[0]);
  }

  triggerFile() {
    document.getElementsByName('doc_file')[0].click();
  }

  setError(fieldName) {
    AlertMsg('error', `${this.state.errors[fieldName].label} is required!`);

    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [fieldName]: {
          ...prevState.errors[fieldName],
          text: `${prevState.errors[fieldName].label} is required`,
        },
      },
    }));
  }

  handleSubmit = async () => {
    this.setState({ btnLoading: true });
    const {
      firstName,
      lastName,
      dob,
      gender,
      maritalStatus,
      corporationId,
      jobDescription,
      phoneNumber,
      phoneType,
      address1,
      address2,
      city,
      country,
      state,
      zip,
      email,
      avatar,
      doc_file,
      //pharmecy state data
      secondaryEmail,
      p_name,
      p_address,
      p_addressOptional,
      p_city,
      p_zip,
      p_country,
      p_phoneNumber,
      eId,
      password,
      cpassword,
      isEdit,
    } = this.state;

    let fieldName;

    if (!firstName.trim()) {
      fieldName = 'firstName';
    } else if (!lastName.trim()) {
      fieldName = 'lastName';
    } else if (!dob) {
      fieldName = 'dob';
    } else if (!gender) {
      fieldName = 'gender';
    } else if (!maritalStatus) {
      fieldName = 'maritalStatus';
    } else if (!phoneNumber) {
      fieldName = 'phoneNumber';
    } else if (!phoneType) {
      fieldName = 'phoneType';
    } else if (!address1) {
      fieldName = 'address1';
    } else if (!city.trim()) {
      fieldName = 'city';
    } else if (!country || !country.name) {
      fieldName = 'country';
    }

    if (fieldName) {
      this.setError(fieldName);
      this.setState({ btnLoading: false });
      return false;
    }

    if (
      !isEdit &&
      (password === '' || password.length < 5 || password !== cpassword)
    ) {
      AlertMsg(
        'error',
        'Please make sure the password matches and atleast 6 characters'
      );
      this.setState({ btnLoading: false });
      return;
    }

    let data = {
      firstName,
      lastName,
      dob,
      gender,
      maritalStatus,
      corporationId,
      phoneNumber,
      jobDescription,
      phoneType,
      email,
      type: 'json',
      avatar,
      secondaryEmail,
      password,
      idCopy: doc_file,
      address: {
        address1,
        address2,
        city,
        state,
        country: country && country.name ? country.name : '',
        zip,
      },
      pharmacy: {
        name: p_name,
        address: p_address,
        addressOptional: p_addressOptional,
        city: p_city,
        zip: p_zip,
        country: p_country && p_country.name ? p_country.name : '',
        phoneNumber: p_phoneNumber,
      },
    };

    if (eId) {
      data.employer = eId;
    }

    if (data.corporationId === '') {
      delete data.corporationId;
    }

    if (this.state.isEdit) {
      delete data.password;
      Axios.put(
        `${baseURL}/api/client/updateClient/${this.state.ClientId}`,
        data,
        {
          headers: {
            Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
          },
        }
      )
        .then(() => {
          AlertMsg('success', 'Patient added successfully.');
          this.setState({ btnLoading: false });
          this.props.history.push('/admin/patients');
        })
        .catch((e) => {
          this.setState({ btnLoading: false });
          AlertMsg('error', e.reason);
        });
    } else {
      Axios.post(`${baseURL}/api/client/createbyAdmin`, data, {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      })
        .then(() => {
          AlertMsg('success', 'Patient added successfully.');
          this.setState({ btnLoading: false });
          this.props.history.push('/admin/patients');
        })
        .catch((e) => {
          this.setState({ btnLoading: false });
          AlertMsg('error', e.response?.data?.reason);
        });
    }
  };

  renderingFunction = (text) => {
    this.props.getAllCompanies(1, encodeURI(text));
  };

  onCompanySelect = (val) => {
    if (val) {
      this.setState({
        corporationId: val._id,
      });
    }
  };

  filterJobs = (text) => console.log(text);

  setJobDescription = (val) => {
    if (val) {
      const id = val._id.split(' ').join('-').toLowerCase();
      this.setState({ jobDescription: id });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      firstName,
      lastName,
      dob,
      gender,
      maritalStatus,
      corporationId,
      phoneNumber,
      phoneType,
      address1,
      address2,
      city,
      country,
      zip,
      email,
      errors,
      btnLoading,
      state,
      profileImage,
      jobDescription,
      secondaryEmail,
    } = this.state;

    return (
      <>
        <Spinner isOpen={btnLoading} />
        {this.state.loading ? (
          <Grid container justify='center'>
            <Loader />
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Typography className={classes.inputText}>
                  Personal Information
                </Typography>
                <Typography className={classes.inputTitle}>
                  Profile Image*
                </Typography>
                <Grid
                  container
                  style={{ marginBottom: '15px', width: '150px' }}
                >
                  <ImageUploader
                    src={profileImage}
                    uploadedImage={this.handleImageUpload.bind(this)}
                  />
                </Grid>
                <Grid
                  container
                  style={{ marginBottom: '15px' }}
                  alignItems='flex-start'
                >
                  <Grid
                    xs={6}
                    style={{ paddingRight: '10px' }}
                    justify='flex-start'
                    container
                    item
                  >
                    <Typography className={classes.inputTitle}>
                      First Name*
                    </Typography>
                    <input
                      className={classes.inputsty}
                      value={firstName}
                      onChange={this.handleChange}
                      type='text'
                      data-text='First Name'
                      name='firstName'
                    />
                    <span className={classes.errorText}>
                      {errors.firstName && errors.firstName.text}
                    </span>
                  </Grid>
                  <Grid
                    xs={6}
                    style={{ paddingLeft: '10px' }}
                    justify='flex-start'
                    container
                    item
                  >
                    <Typography className={classes.inputTitle}>
                      Last Name*
                    </Typography>
                    <input
                      className={classes.inputsty}
                      value={lastName}
                      onChange={this.handleChange}
                      type='text'
                      name='lastName'
                      data-text='Last Name'
                    />
                    <span className={classes.errorText}>
                      {errors.lastName && errors.lastName.text}
                    </span>
                  </Grid>
                </Grid>
                <Grid
                  style={{ marginBottom: '15px' }}
                  justify='flex-start'
                  alignItems='flex-start'
                  container
                >
                  <Typography className={classes.inputTitle}>
                    Date Of Birth*
                  </Typography>
                  <DatePicker
                    locale='en'
                    className={classes.inputsty}
                    dateFormat='MMMM d, yyyy'
                    selected={dob}
                    showYearDropdown
                    name='dob'
                    data-text='Date of Birth'
                    dropdownMode='select'
                    showMonthDropdown
                    onChange={(date) => {
                      this.setState((prevState) => ({
                        dob: date,
                        errors: {
                          ...prevState.errors,
                          dob: { label: 'Date of birth', ...prevState.dob },
                        },
                      }));
                    }}
                  />
                  <span className={classes.errorText}>
                    {errors.dob && errors.dob.text}
                  </span>
                </Grid>
                <Grid
                  container
                  style={{ marginBottom: '15px' }}
                  alignItems='flex-start'
                >
                  <Grid
                    xs={6}
                    style={{ paddingRight: '10px' }}
                    justify='flex-start'
                    container
                    item
                  >
                    <Typography className={classes.inputTitle}>
                      Gender*
                    </Typography>
                    <FormControl className={classes.inputsty}>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={gender}
                        name='gender'
                        data-text='Gender'
                        onChange={this.handleChange}
                      >
                        <MenuItem value={'male'}>Male</MenuItem>
                        <MenuItem value={'female'}>Female</MenuItem>
                      </Select>
                    </FormControl>
                    <span className={classes.errorText}>
                      {errors.gender && errors.gender.text}
                    </span>
                  </Grid>
                  <Grid
                    xs={6}
                    style={{ paddingLeft: '10px' }}
                    justify='flex-start'
                    container
                    item
                  >
                    <Typography className={classes.inputTitle}>
                      Marital Status*
                    </Typography>
                    <FormControl className={classes.inputsty}>
                      <Select
                        data-text='Martial Status'
                        value={maritalStatus}
                        onChange={this.handleChange}
                        name='maritalStatus'
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                      >
                        <MenuItem value={''}>Select</MenuItem>
                        <MenuItem value={'married'}>Married</MenuItem>
                        <MenuItem value={'single'}>Single</MenuItem>
                        <MenuItem value={'divorced'}>Divorced</MenuItem>
                        <MenuItem value={'widowed'}>Widowed</MenuItem>
                        <MenuItem value={'seperated'}>Seperated</MenuItem>
                      </Select>
                    </FormControl>
                    <span className={classes.errorText}>
                      {errors.maritalStatus && errors.maritalStatus.text}
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  container
                  style={{ marginBottom: '15px' }}
                  alignItems='flex-start'
                >
                  <Typography className={classes.inputTitle}>
                    Company
                  </Typography>
                  <AutoComplete
                    value={corporationId}
                    renderingFunction={this.renderingFunction}
                    onChange={this.onCompanySelect}
                    placeholder='Select company'
                    options={this.props.companies || []}
                    optionToDisplay={{ keys: ['name'], separator: '' }}
                    loading={this.props.loadingCompanies}
                  />
                </Grid>

                <Grid
                  container
                  style={{ marginBottom: '15px' }}
                  alignItems='flex-start'
                >
                  <Typography className={classes.inputTitle}>
                    Job Desription
                  </Typography>
                  <AutoComplete
                    value={jobDescription}
                    onChange={this.setJobDescription}
                    renderingFunction={this.filterJobs}
                    placeholder='Select Job description'
                    options={JOB_LIST}
                  />
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Typography className={classes.inputText}>
                  Contact Information
                </Typography>
                <Grid
                  container
                  style={{ marginBottom: '15px' }}
                  alignItems='flex-start'
                >
                  <Grid
                    xs={6}
                    style={{ paddingRight: '10px' }}
                    justify='flex-start'
                    container
                    item
                  >
                    <Typography className={classes.inputTitle}>
                      Phone Number*
                    </Typography>
                    <input
                      data-text='Phone Number'
                      className={classes.inputsty}
                      value={phoneNumber}
                      type='number'
                      onChange={this.handleChange}
                      name='phoneNumber'
                    />
                    <span className={classes.errorText}>
                      {errors.phoneNumber && errors.phoneNumber.text}
                    </span>
                  </Grid>
                  <Grid
                    xs={6}
                    style={{ paddingLeft: '10px' }}
                    justify='flex-start'
                    container
                    item
                  >
                    <Typography className={classes.inputTitle}>
                      Type*
                    </Typography>
                    <FormControl className={classes.inputsty}>
                      <Select
                        data-text='Phone Type'
                        value={phoneType}
                        onChange={this.handleChange}
                        name='phoneType'
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                      >
                        <MenuItem value={'Mobile'}>Mobile</MenuItem>
                        <MenuItem value={'TelePhone'}>TelePhone</MenuItem>
                      </Select>
                    </FormControl>
                    <span className={classes.errorText}>
                      {errors.phoneType && errors.phoneType.text}
                    </span>
                  </Grid>
                </Grid>
                <Grid xs={12} item>
                  <AddressView
                    handleChange={this.handleCreateAddress}
                    editingAddress={{
                      address1,
                      address2,
                      state,
                      city,
                      country,
                      zip,
                    }}
                  />
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography className={classes.inputText}>
                  Account Details
                </Typography>
                <Grid style={{ marginBottom: '15px' }}>
                  <Typography className={classes.inputTitle}>
                    Primary Email
                  </Typography>
                  <input
                    className={classes.inputsty}
                    value={email}
                    type='email'
                    onChange={this.handleChange}
                    name='email'
                    disabled={this.state.isEdit}
                  />
                  <span className={classes.errorText}>
                    {errors.email && errors.email.text}
                  </span>
                </Grid>
                <Grid style={{ marginBottom: '15px' }}>
                  <Typography className={classes.inputTitle}>
                    Secondary Email
                  </Typography>
                  <input
                    className={classes.inputsty}
                    value={secondaryEmail}
                    type='secondaryEmail'
                    onChange={this.handleChange}
                    name='secondaryEmail'
                  />
                  <span className={classes.errorText}>
                    {errors.secondaryEmail && errors.email.secondaryEmail}
                  </span>
                </Grid>
                {!this.state.isEdit ? (
                  <Grid container style={{ marginBottom: '15px' }}>
                    <Grid item xs={6} style={{ paddingRight: '10px' }}>
                      <Typography className={classes.inputTitle}>
                        Password
                      </Typography>
                      <input
                        className={classes.inputsty}
                        onChange={this.handleChange}
                        // value={email}
                        type='password'
                        name='password'
                      />
                      <span className={classes.errorText}>
                        {errors.password && errors.password.text}
                      </span>
                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: '10px' }}>
                      <Typography className={classes.inputTitle}>
                        Verify Password
                      </Typography>
                      <input
                        className={classes.inputsty}
                        // value={email}
                        type='password'
                        onChange={this.handleChange}
                        name='cpassword'
                      />
                      <span className={classes.errorText}>
                        {errors.cpassword && errors.cpassword.text}
                      </span>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <Typography className={classes.italicTitle}>
                      {/* This account used either Google or Apple service when registering.
                                    To update your password, you must first change it with your email provider. */}
                    </Typography>
                  </Grid>
                )}
              </Paper>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: '15px' }}>
              {this.state.isEdit ? (
                <Button
                  onClick={this.handleSubmit}
                  variant='contained'
                  style={{
                    backgroundColor: '#1b63eb',
                    fontWeight: 100,
                    fontSize: 16,
                    marginTop: '12px',
                    color: '#fff',
                    textTransform: 'capitalize',
                  }}
                  disabled={btnLoading}
                >
                  {btnLoading ? (
                    <CircularProgress size={20} color={'white'} />
                  ) : (
                    'Update'
                  )}
                </Button>
              ) : (
                <Button
                  onClick={this.handleSubmit}
                  variant='contained'
                  style={{
                    backgroundColor: '#1b63eb',
                    fontWeight: 100,
                    fontSize: 16,
                    marginTop: '12px',
                    color: '#fff',
                    textTransform: 'capitalize',
                  }}
                  disabled={btnLoading}
                >
                  {btnLoading ? (
                    <CircularProgress size={20} color={'white'} />
                  ) : (
                    'Submit'
                  )}
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

Setting.defaultProps = {
  getClient: {},
};

Setting.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  uploadDocFileAction: PropTypes.shape({}),
  getClientDetailAction: PropTypes.func.isRequired,
  uploadClientImageData: PropTypes.shape({
    uploadClientImageData: PropTypes.shape({
      urls: PropTypes.array,
    }),
  }).isRequired,
  uploadDocFileData: PropTypes.shape({
    uploadDocFileData: PropTypes.shape({
      urls: PropTypes.array,
    }),
  }).isRequired,
  getClientDataByIdData: PropTypes.shape({
    getClientDataByIdData: PropTypes.any,
  }),
  classes: {
    paper: PropTypes.string.isRequired,
    inputText: PropTypes.string.isRequired,
    inputTitle: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    imgBox: PropTypes.string.isRequired,
    inputsty: PropTypes.string.isRequired,
    errorText: PropTypes.string.isRequired,
    autoComCountry: PropTypes.string.isRequired,
  },
};

const connected = connect(
  (state) => ({
    companies: state.CompanyReducer.companies,
    loadingCompanies: state.CompanyReducer.loading,
    updateClientData: state.updateClient,
    uploadClientImageData: state.uploadClientImage,
    uploadDocFileData: state.uploadDocFile,
    getClientDataByIdData: state.getClientDataById,
  }),
  {
    uploadClientImageAction,
    uploadPharmacyAction,
    uploadDocFileAction,
    getClientDetailAction,
    getAllCompanies,
  }
)(Setting);

const formed = reduxForm({
  form: 'updateClient',
})(connected);

export default withStyles(styles)(formed);
