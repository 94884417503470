/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable camelcase */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute, ClinicianPrivateRoute } from '../PrivateRoutes';
import Clients from '../sidebar_components/Clients';
import Calender from '../sidebar_components/Calender';
// import Billing from '../sidebar_components/Billing';
import Reports from '../sidebar_components/Reports';
import Account_Activity from '../sidebar_components/Account_Activity';
import Reminders from '../sidebar_components/Reminders';
import AdminLogin from './adminLogin';
import SignUp from './SignUp';
import EditClient from '../sub_components/clients/edit_clients/Tabs_EditClient';
import AddNotes from '../sub_components/calender/AddNotes';
import ClientOverview from '../sub_components/clients/ClientOverview';
import AddService from '../sub_components/service/AddService';
import SingleInvoice from '../sub_components/billing/SingleInvoice';
import BillingDocument from '../sub_components/billing/buttons/BillingDocument';
import RcardComponent from '../rcads/Rcads';
import RcadClinic from '../user/pages/clinician/Clinicians';
import RcadSignup from '../user/pages/auth/SignUpForm';
// import Verification from '../user/pages/auth/OtpPopUp';
import UserCalander from '../user/pages/UserCalander';
import SendEmail from './SendMail';
import AppointmentFor from '../user/AppointmentFor';
import ClientProfile from '../user/pages/ProfileList';
import PaymentClient from '../user/pages/Payment';
import AppointmentReminder from '../user/reschedule/AppointmentReminder';
import AppointmentRescheduleSuccess from '../user/reschedule/AppointmentRescheduleSuccess';
import AddChild from '../user/pages/AddChild';
import Part1Of2Complete from '../user/pages/Part1Of2Complete';
import MentalHealthAssessment from '../user/pages/MentalHealthAssessment';
import YourResults from '../user/pages/YourResults';
import AppointmentConfirmation from '../user/pages/profileInformations/AppointmentConfirmation';
import InsuranceInfo from '../user/pages/profileInformations/InsuranceInfo';
import PaymentInfo from '../user/pages/profileInformations/PaymentInfo';
import VerifyInfo from '../user/pages/profileInformations/VerifyInfo';
import PatientOnboarding from '../user/pages/PatientOnboarding';
import LoginForm from '../user/pages/auth/LoginForm';
import Home from '../Patient/pages/Home';
import { PageNotFound } from '../common/PageNotFound';
import Doctor from '../Patient/pages/Doctor';
import Appointments from '../Patient/pages/Appointments';
import PaymentsHistory from '../Patient/pages/PaymentsHistory';
import Setting from '../Patient/pages/Setting';
import home from '../user/pages/home';
import AppMainNavigation from '../common/Navigation/AppMainNavigation';
// admin route start
import PrivateRouteAdmin from '../admin/routes/PrivateRoute';
import Service from '../admin/components/sidebar/Service';
import Role from '../admin/components/sidebar/Role';
import Permission from '../admin/components/sidebar/Permission';
import User from '../admin/components/sidebar/User';
import AddUser from '../admin/components/sidebar/pages/AddUser';
import VerifyUser from '../admin/components/sidebar/pages/VerifyUser';
import Rcad from '../admin/components/sidebar/Rcad';
import Onboarding from '../admin/components/sidebar/Onboarding';
import MentalHealthAssessmentAdmin from '../admin/components/sidebar/MentalHealthAssessment';
import Part1of2complete from '../admin/components/sidebar/Part1of2complete';
import AppointmentAdmin from '../admin/components/Appointment';
import serviceTypeVAT from '../admin/components/ServiceType/serviceType';

//Import clinician Component
import Events from '../Patient/pages/Events';
import Prescriptions from '../Patient/pages/prescription';
import AddAdults from '../user/pages/AddMultipleAdults';
import DashboardClinician from '../clinician/DashboardClinician';
import AppointmentClinician from '../clinician/AppointmentClinician';
import AppointmentDetails from '../clinician/AppointmentDetails';
import LoginClinician from '../clinician/Login';
import MultiplePerson from '../user/pages/MultiplePerson';
import ClinicianTimeSlots from '../clinician/Calander';
import ClinicianCalender from '../clinician/components/Calender';
import ClinicianPatients from '../clinician/Patients';
import ClinicianConsent from '../clinician/Consent/ConsentForms';
import AdminConsent from '../admin/components/Consent';
import ClinicianPatientDetail from '../clinician/Patients/patientDetail';
import ClinicianSettings from '../clinician/setting';
import ClinicianEvents from '../clinician/events';
import StaffMembers from '../admin/components/Staff';
import ReportCard from '../admin/components/Reports/Reports';
import MonthlyReportCard from '../admin/components/Reports/monthlyReports';
import WeekelyReportCard from '../admin/components/Reports/WeekelyReport';
import { AddStaffMember } from '../admin/components/Staff/AddStaffMember';
import EditPatientRecord from '../clinician/EditPatientRecord';
import AdminEditPatientRecord from '../clinician/AdminEditPatientRecord';
import AdminEvents from '../admin/components/Events';
import AddEvents from '../admin/components/Events/addEvents';
import PatientsAdmin from '../admin/components/Patients';
import SettingAdmin from '../admin/components/setting';
import AddPatient from '../admin/components/Patients/AddPatient';
import ForgotPasswordModel from '../common/ForgotPassword';
import ClinicAddresses from '../admin/components/common/ClinicAddresses';
import EventRegistrationFirst from '../Event registration/EventRegistrationForm';
import AdminCalendar from '../admin/components/Calender/Calender';
import SingleClinicianCalender from '../admin/components/Calender/SingleClinicianCalender';
import SignleClinicianTimeSlots from '../admin/components/Calender/SignleClinicianTimeSlots';
import AdminTimeSlots from '../admin/components/Calender/AdminTimeSlots';
import EventDashboard from '../admin/components/Events';
// corporation
import Corporation from '../containers/corporation';

// Billing
import Billing from '../containers/billing';
import Invoice from '../admin/components/Dashboard/Invoice';
// SMS
import SMS from '../admin/components/SMS';
import ConsentForm from '../user/pages/profileInformations/ConsentForm';

import ServiceBundles from '../containers/service_bundles';
import Client from '../containers/client';
import PatientConsentForms from '../Patient/pages/consentForms';
import PatientInvoicePDF from '../Patient/pages/billing';
import OtpPopUp from '../user/pages/auth/OtpPopUp';
import Dashboard from '../admin/components/Dashboard/Dashboard';
import EventReports from '../admin/components/Events/EventReports';
import Admin from '../containers/admin';
import EventUser from '../containers/event-user';

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        {/* admin route start */}
        {/* <PrivateRouteAdmin
                    exact
                    path="/admin/dashboard"
                    component={Dashboard}
                  /> */}
        {/* <PrivateRouteAdmin
          exact
          path='/admin/setting'
          component={SettingAdmin}
          />
          <PrivateRouteAdmin exact path='/admin/events' component={AdminEvents} />
          <PrivateRouteAdmin
          exact
          path='/admin/addevents'
          component={AddEvents}
          />
          <PrivateRouteAdmin
          exact
          path='/admin/addevents/:id'
          component={AddEvents}
        /> */}
        {/* <PrivateRouteAdmin
          exact={true}
          component={AddStaffMember}
          path='/admin/staff/add-staff'
        />
        <PrivateRouteAdmin
          exact={true}
          component={AddStaffMember}
          path='/admin/staff/edit-staff/:id'
        /> */}
                {/* <PrivateRouteAdmin
          exact
          path='/admin/patients/add-patient'
          component={AddPatient}
        />
        <PrivateRouteAdmin
          exact
          path='/admin/patients/edit-patient/:id'
          component={AddPatient}
        /> */}
        <Route exact path='/admin/login' component={AdminLogin} />
        <PrivateRouteAdmin path='/event' component={EventUser} />
        <PrivateRouteAdmin path='/admin' component={Admin} />

        <PrivateRouteAdmin
          exact
          path='/frontdesk/appointments'
          component={AppointmentAdmin}
        />
        <PrivateRouteAdmin
          exact
          path='/frontdesk/setting'
          component={SettingAdmin}
        />
        <PrivateRouteAdmin
          exact
          path='/event/setting'
          component={SettingAdmin}
        />
        {/* <PrivateRouteAdmin
          exact
          path='/frontdesk/patients'
          component={PatientsAdmin}
        /> */}
        <Route exact path='/frontdesk/login' component={AdminLogin} />
        {/* <PrivateRouteAdmin
          exact
          path='/admin/appointments'
          component={AppointmentAdmin}
        /> */}
        {/* <PrivateRouteAdmin
          exact
          path='/admin/dashboard'
          component={Dashboard}
        /> */}
        <PrivateRouteAdmin
          exact
          path='/frontdesk/dashboard'
          component={Dashboard}
        />
        {/* <PrivateRouteAdmin
          exact
          path='/event-user/dashboard'
          component={EventDashboard}
        /> */}

        <PrivateRouteAdmin
          exact
          path='/admin/patients'
          component={PatientsAdmin}
        />
        <PrivateRouteAdmin
          exact
          path='/admin/clinicAddresses'
          component={ClinicAddresses}
        />
        <PrivateRouteAdmin
          exact
          path='/admin/serviceType'
          component={serviceTypeVAT}
        />
        <PrivateRouteAdmin
          exact
          path='/admin/calender'
          component={AdminCalendar}
        />
        <PrivateRouteAdmin
          exact
          path='/admin/calender/:id'
          component={SingleClinicianCalender}
        />
        <PrivateRouteAdmin
          exact
          path='/admin/time-slots/:id'
          component={SignleClinicianTimeSlots}
        />
        {/* <PrivateRouteAdmin
          exact
          path='/admin/time-slots'
          component={AdminTimeSlots}
        /> */}

        <PrivateRouteAdmin
          exact
          path='/admin/staff-members'
          component={StaffMembers}
        />
        <PrivateRouteAdmin exact path='/admin/Reports' component={ReportCard} />
        <PrivateRouteAdmin
          exact
          path='/admin/WeekelyReport'
          component={WeekelyReportCard}
        />
        <PrivateRouteAdmin
          exact
          path='/admin/monthlyReports'
          component={MonthlyReportCard}
        />
        <PrivateRouteAdmin
          exact
          path='/admin/appointments/editPatientRecord/:id'
          component={AdminEditPatientRecord}
        />
        <PrivateRouteAdmin exact path='/admin/service' component={Service} />
        <PrivateRouteAdmin exact path='/admin/role' component={Role} />
        <PrivateRouteAdmin
          exact
          path='/admin/permission'
          component={Permission}
        />
        <PrivateRouteAdmin exact path='/admin/user' component={User} />
        <PrivateRouteAdmin exact path='/admin/add_user' component={AddUser} />
        <PrivateRouteAdmin exact path='/admin/rcad' component={Rcad} />
        <PrivateRouteAdmin
          exact
          path='/admin/onboarding'
          component={Onboarding}
        />
        <PrivateRouteAdmin
          exact
          path='/admin/mha'
          component={MentalHealthAssessmentAdmin}
        />
        <PrivateRouteAdmin
          exact
          path='/admin/p1of2'
          component={Part1of2complete}
        />

        <PrivateRouteAdmin path='/admin/send-sms' component={SMS} exact />
        <Route path='/verify/:article' component={VerifyUser} />
        {/* admin route end */}
        {/* <Route exact path="/" component={SignIn} /> */}
        <Route exact path='/sign-up' component={SignUp} />
        <PrivateRoute exact path='/calender' component={Calender} />
        <PrivateRoute exact path='/clients' component={Clients} />
        {/* <PrivateRouteAdmin exact path='/billing' component={Billing} /> */}
        <PrivateRouteAdmin
          exact
          path='/event/reports'
          component={EventReports}
        />
        <PrivateRouteAdmin
          component={AdminConsent}
          path='/admin/consent-forms'
        />
        <PrivateRoute exact path='/reports' component={Reports} />
        <PrivateRoute
          exact
          path='/account-activity'
          component={Account_Activity}
        />
        <PrivateRoute exact path='/reminders' component={Reminders} />
        <PrivateRoute exact path='/edit_client/:id' component={EditClient} />
        <PrivateRoute exact path='/add_notes/:id' component={AddNotes} />
        <PrivateRoute
          exact
          path='/client_overview/:id'
          component={ClientOverview}
        />
        {/* <PrivateRoute path="/single_client_overview" component={SingleClientHeader} /> */}
        <PrivateRoute exact path='/service' component={AddService} />
        <PrivateRoute
          exact
          path='/single_invoice/:id'
          component={SingleInvoice}
        />
        <PrivateRoute
          exact
          path='/billing_document'
          component={BillingDocument}
        />
        <PrivateRoute exact path='/clinician/email' component={SendEmail} />
        <Route
          exact
          path='/'
          component={() => <Redirect push to='/signup' />}
        />
        {/* <Route exact path="/clinician/doctors" component={DoctorsClinician} />
                <Route exact path="/clinician/doctors/view" component={DoctorsClinicianView} /> */}
        <Route exact path='/clinician/login' component={AdminLogin} />
        <ClinicianPrivateRoute
          exact
          path='/clinician/dashboard'
          component={DashboardClinician}
        />
        <ClinicianPrivateRoute
          exact
          path='/clinician/appointments'
          component={AppointmentClinician}
        />
        <ClinicianPrivateRoute
          exact
          path='/clinician/appointments/appointment-details/:id'
          component={AppointmentDetails}
        />
        <ClinicianPrivateRoute
          exact
          path='/clinician/appointments/editPatientRecord/:id'
          component={EditPatientRecord}
        />
        <ClinicianPrivateRoute
          component={ClinicianEvents}
          path='/clinician/events'
        />
        <ClinicianPrivateRoute
          component={ClinicianTimeSlots}
          path='/clinician/time-slots'
        />
        <ClinicianPrivateRoute
          component={ClinicianCalender}
          path='/clinician/calendar'
        />
        <ClinicianPrivateRoute
          component={ClinicianPatients}
          path='/clinician/patients'
        />
        <ClinicianPrivateRoute
          component={ClinicianPatientDetail}
          path='/clinician/patient-detail/:id'
        />
        <ClinicianPrivateRoute
          component={ClinicianConsent}
          path='/clinician/consent-forms'
        />
        <ClinicianPrivateRoute
          component={ClinicianSettings}
          path='/clinician/settings/'
        />
        <Route exact path='/signup' component={RcadSignup} />

        <Route
          path='/appointment/user/:id1/clinician/:id2'
          component={UserCalander}
        />
        <Route
          path='/user/checklist/:id1/appointment/:id2'
          component={ClientProfile}
        />
        <Route
          path='/user/payment/:id1/appointment/:id2'
          component={PaymentClient}
        />
        <Route
          path='/user/appointment/reminder/:id'
          component={AppointmentReminder}
        />
        <Route path='/user/appointment/reschedule/:id' component={RcadClinic} />
        <Route
          path='/user/appointment/:id1/clinician/:id2'
          component={UserCalander}
        />
        <Route
          path='/user/appointment_reschedule/success'
          component={AppointmentRescheduleSuccess}
        />
        <PrivateRoute exact path='/assessment-que' component={RcardComponent} />
        <PrivateRoute exact path='/clinician' component={RcadClinic} />
        <PrivateRoute exact path='/add-child' component={AddChild} />
        <PrivateRoute
          exact
          path='/part1of2complete'
          component={Part1Of2Complete}
        />
        <PrivateRoute exact path='/mha' component={MentalHealthAssessment} />
        <PrivateRoute exact path='/your-result' component={YourResults} />
        <PrivateRoute
          exact
          path='/user/client/:id/adults'
          component={AddAdults}
        />
        <PrivateRoute
          exact
          path='/user/client/:id/multiple-person'
          component={MultiplePerson}
        />
        <PrivateRoute
          exact
          path='/confirmation/:id'
          component={AppointmentConfirmation}
        />
        <Route exact path='/onboarding' component={PatientOnboarding} />
        <Route exact path='/login' component={LoginForm} />
        <PrivateRoute
          exact
          path='/insuranceinfo/:id'
          component={InsuranceInfo}
        />
        <PrivateRoute path='/paymentinfo/:id' component={PaymentInfo} />
        <PrivateRoute path='/consentForm/:id' component={ConsentForm} />
        <PrivateRoute exact path='/verifyinfo/:id' component={VerifyInfo} />
        <PrivateRoute
          exact
          path='/appointment-for'
          component={AppointmentFor}
        />
        <PrivateRoute exact path='/dashboard' component={Home} />
        <PrivateRoute exact path='/home' component={home} />
        <PrivateRoute exact path='/menu' component={AppMainNavigation} />
        <PrivateRoute exact path='/appointments' component={Appointments} />
        <PrivateRoute path='/client' component={Client} />
        <PrivateRoute exact path='/doctor' component={Doctor} />
        <PrivateRoute exact path='/events' component={Events} />
        <PrivateRoute exact path='/otp' component={OtpPopUp} />
        <Route
          exact
          path='/eventregistration/:id/:billingId'
          component={EventRegistrationFirst}
        />
        <Route
          exact
          path='/eventregistration/:id'
          component={EventRegistrationFirst}
        />
        <PrivateRoute exact path='/prescriptions' component={Prescriptions} />
        <PrivateRoute
          exact
          path='/consent-forms'
          component={PatientConsentForms}
        />
        <PrivateRoute
          exact
          path='/payments-history'
          component={PaymentsHistory}
        />
        <PrivateRoute
          exact
          path='/lh/invoice/:invoiceId'
          component={PatientInvoicePDF}
        />
        <PrivateRoute exact path='/setting' component={Setting} />
        <Route exact path='/reset-password' component={ForgotPasswordModel} />
        <PrivateRouteAdmin path='/admin/corporation' component={Corporation} />
        <PrivateRouteAdmin
          path='/admin/service-bundles'
          component={ServiceBundles}
        />
        {/* <PrivateRouteAdmin path='/admin/billing' component={Billing} /> */}

        <PrivateRouteAdmin
          path='/admin/generate-invoice/:billingId'
          component={Invoice}
        />
        <Route path='/page_not_found' component={PageNotFound} />
        <Route path='*' component={() => <Redirect to='/page_not_found' />} />

      </Switch>
    );
  }
}

export default Routes;
