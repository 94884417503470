import { createStyles} from "@material-ui/core";

export default (theme) =>
createStyles({
    heading: {
      // marginTop: '50px',
      fontSize: "25px",
      lineHeight: "30px",
      fontWeight: 600,
      textAlign: "center",
      color: "#003d79",
    },
    subHeading: {
      // textAlign: 'left',
      // width: '190px',
      // height: "100%",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#a29061",
      paddingLeft: "28px",
      // marginLeft: '150px',
      // marginTop: '10px',
    },
    button: {
      textTransform: "capitalize",
      backgroundColor: "#003d79",
      color: "#ffffff",
      width: "100%",
    },
  });
  
