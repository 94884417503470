import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
    root: {
      height: "600px",
      ".rbc-day-bg.rbc-today": {
        backgroundColor: "green",
      },
      width: "100%",
      marginBottom: "30px",
    },
  }));