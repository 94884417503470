import React, { useState, useEffect } from 'react';
import { Drawer } from '@material-ui/core';
import { DrawerDiv } from './Dashbord_style';
import InvoiceDetail from '../Finances/finances';
import Layout from '../common/AdminLayout';

const InvoiceDrawer = ({ match }) => {
  const [billingId, setBillingId] = useState('');
  useEffect(() => {
    setBillingId(match.params.billingId);
  }, []);
  return (
    <Layout>{billingId && <InvoiceDetail billingId={billingId} />}</Layout>
  );
};

export default InvoiceDrawer;
