/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-shadow */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-undef */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/state-in-constructor */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {

  withStyles,
  DialogTitle,
  Typography,
  Grid,
  Button,
  Divider,
  DialogContent,
  Radio,
  FormControlLabel,
  MenuItem,
  Tabs,
  Tab,
  Paper,
  SwipeableDrawer,
  // Select
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import DatePicker, { registerLocale } from 'react-datepicker';
// import en from 'date-fns/locale/en';
import { addClientAction } from '../../utils/actions/client.action';
import Contact from './Contact_Tab';
import AlertMsg from '../../utils/Alert';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './AddClients_ComponentStyle';

// registerLocale('en', en);

const numoptions = [
  { value: 'work', label: 'Work' },
  { value: 'mob', label: 'Mobile' },
  { value: 'home', label: 'Home' },
  { value: 'fax', label: 'Fax' },
];

class AddClientComponents extends React.Component {
  state = {
    selectedSortOption: null,
    selectedNumOption: null,
    clientType: 'adult',
    billingType: '',
    firstName: '',
    lastName: '',
    email: '',
    emailType: '',
    phone: '',
    phoneType: '',
    minorType: 'client',
    dob: new Date(),
    emName: '',
    emPhone: '',
    emRelation: '',
  };

  componentDidUpdate(preProps) {
    const { addClientData } = this.props;
    if (addClientData !== preProps.addClientData) {
      if (addClientData.addClientData) {
        this.props.onCancel();
        AlertMsg('success', 'Client Created');
      }
      if (addClientData.error) {
        this.props.onCancel();
        AlertMsg('error', ' Failed');
      }
    }
  }

  handleSortChange = (selectedSortOption) => {
    this.setState({ selectedSortOption, phoneType: selectedSortOption.value });
  };

  handleGenderChange = (selectedSortOption) => {
    this.setState({ selectedSortOption, gender: selectedSortOption.value });
  };

  handleNumChange = (selectedNumOption) => {
    this.setState({ selectedNumOption, phoneType: selectedNumOption.value });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    const {
      selectedSortOption,
      selectedNumOption,
      clientType,
      billingType,
      firstName,
      lastName,
      email,
      phone,
      phoneType,
      emailType,
      gender,
      nationality,
      dob,
      state,
      residency,
      emName,
      emPhone,
      emRelation,
    } = this.state;
    const data = {
      clientType,
      name: `${firstName} ${lastName}`,
      phoneNumber: phone,
      email,
      gender,
      nationality,
      dob,
      state,
      residency: phoneType,
      emergencyContact: {
        name: emName,
        phoneNumber: emPhone,
        relationship: emRelation,
      },
    };
    console.log(data);
    this.props.addClientAction(data);
  };

  render() {
    const { classes, addClientData } = this.props;
    const {
      selectedSortOption,
      selectedNumOption,
      emailType,
      phoneType,
      clientType,
      billingType,
      firstName,
      lastName,
      email,
      phone,
      minorType,
      gender,
      nationality,
      dob,
      state,
      residency,
      emName,
      emPhone,
      emRelation,
    } = this.state;
    console.log('============', addClientData);

    const sortoptions = [
      { value: 'work', label: 'Work' },
      { value: 'home', label: 'Home' },
    ];
    const genderoption = [
      { value: 'male', label: 'Male' },
      { value: 'female', label: 'Female' },
    ];
    return (
      <div>
        <SwipeableDrawer anchor="right" open={this.props.isOpen}>
          <DialogTitle>
            <Grid container spacing={3} style={{ textAlign: 'left' }}>
              <Grid item xs={12} sm={1}>
                <ClearIcon
                  onClick={() => this.props.onCancel()}
                  className={classes.crossIcon}
                />
              </Grid>
              <Grid item xs={12} sm={7}>
                <Typography className={classes.root}>
                  {' '}
                  Create Client
                  {' '}
                </Typography>
                <Typography style={{ color: '#a29061' }}>
                  Appointment: Wednesday, Apr 15, 2020 @ 11:45 PM
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} style={{ textAlign: 'right' }}>
                <Button
                  onClick={this.handleSubmit}
                  variant="contained"
                  color="primary"
                  className={classes.crossIcon}
                >
                  Create & Continue
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>

          <Divider />
          <Divider />

          <div style={{ paddingLeft: 25 }}>
            <DialogContent>
              <Typography style={{ color: '#003d79' }}>Client Type</Typography>
              <div style={{ display: 'inline-block' }}>
                <FormControlLabel
                  value="end"
                  style={{ color: '#003d79' }}
                  control={(
                    <Radio
                      color="primary"
                      checked={clientType === 'adult'}
                      onChange={() => this.setState({ clientType: 'adult' })}
                    />
                  )}
                  label="Adult"
                />
                <FormControlLabel
                  value="end"
                  style={{ color: '#003d79', marginLeft: 20 }}
                  control={(
                    <Radio
                      color="primary"
                      checked={clientType === 'minor'}
                      onChange={() => this.setState({ clientType: 'minor' })}
                    />
                  )}
                  label="Minor"
                />
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  {/* {clientType === "minor" ? "Here minor field" : null} */}
                  {clientType === 'minor' ? (
                    <>
                      <Tabs
                        value={this.value}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        centered
                      >
                        <Tab
                          label="Client"
                          onClick={() => this.setState({ minorType: 'client' })}
                        />
                        <Tab
                          label="Contact"
                          onClick={() => this.setState({ minorType: 'contact' })}
                        />
                      </Tabs>
                      {minorType === 'contact' && <Contact />}
                    </>
                  ) : null}
                </Grid>
              </div>

              {(minorType === 'client' || clientType === 'adult') && (
                <>
                  <div style={{ marginTop: 20 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Typography className={classes.formHeading}>
                          First Name
                        </Typography>
                        <input
                          name="firstName"
                          value={firstName}
                          onChange={this.handleChange}
                          className={classes.divstyle}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography className={classes.formHeading}>
                          Last Name
                        </Typography>
                        <input
                          name="lastName"
                          value={lastName}
                          onChange={this.handleChange}
                          className={classes.divstyle}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={8}>
                        <Typography className={classes.formHeading}>
                          Email Address
                        </Typography>
                        <input
                          name="email"
                          value={email}
                          onChange={this.handleChange}
                          className={classes.divstyle}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                        />
                        <Typography className={classes.bottomtext}>
                          To grant Client Portal access, enter an email address
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>
                        <Typography className={classes.formHeading}>
                          Date of Birth
                        </Typography>
                        <DatePicker
                          locale='en'
                          className={classes.date}
                          selected={dob}
                          onChange={(dob) => this.setState({ dob })}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Typography className={classes.formHeading}>
                          Phone Number
                        </Typography>
                        <input
                          name="phone"
                          value={phone}
                          onChange={this.handleChange}
                          className={classes.divstyle}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography className={classes.formHeading}>
                          Gender
                        </Typography>
                        <Select
                          className={classes.select}
                          options={genderoption}
                          onChange={this.handleGenderChange}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>
                        <Typography className={classes.formHeading}>
                          Residency
                        </Typography>
                        <Select
                          className={classes.select}
                          options={sortoptions}
                          onChange={this.handleSortChange}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography className={classes.formHeading}>
                          State Name
                        </Typography>
                        <input
                          name="state"
                          value={state}
                          onChange={this.handleChange}
                          className={classes.divstyle}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography className={classes.formHeading}>
                          Nationality
                        </Typography>
                        <input
                          name="nationality"
                          value={nationality}
                          onChange={this.handleChange}
                          className={classes.divstyle}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <React.Fragment style={{ marginBottom: 15 }}>
                    <Typography
                      style={{ marginTop: 15 }}
                      className={classes.formHeading}
                    >
                      Emergency Contact
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12}>
                        <Typography className={classes.formHeading}>
                          Name
                        </Typography>
                        <input
                          name="emName"
                          value={emName}
                          onChange={this.handleChange}
                          className={classes.divstyle}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography className={classes.formHeading}>
                          Phone
                        </Typography>
                        <input
                          name="emPhone"
                          value={emPhone}
                          onChange={this.handleChange}
                          className={classes.divstyle}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography className={classes.formHeading}>
                          Relationship
                        </Typography>
                        <input
                          name="emRelation"
                          value={emRelation}
                          onChange={this.handleChange}
                          className={classes.divstyle}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                </>
              )}
            </DialogContent>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

const connected = connect(
  (state) => ({
    addClientData: state.addClient,
  }),
  {
    addClientAction,
  },
)(AddClientComponents);

const formed = reduxForm({
  form: 'addClient',
})(connected);

AddClientComponents.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  addClientData: PropTypes.object,
};

export default withStyles(styles)(formed);
