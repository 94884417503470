/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import {
  withStyles,
  Grid,
  Typography,
  Input,
  Button,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Box,
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
// import en from 'date-fns/locale/en';
import dayjs from 'dayjs';
import Paper from '@material-ui/core/Paper';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { DropzoneArea } from 'material-ui-dropzone';
import DrawerMenu from '../common/Header/DrawerMenu';
import { GridContainer, GridItem } from '../common/BodyContainer';
import profile from '../../assets/imgs/ananyaBig.png';
import AlertMsg from '../../utils/Alert';
import { characters, isEmail } from '../../utils/validators';
import { Loader } from '../../user/Loader';
import './main.css';
import {
  getClientAction,
  updateClientAction,
  uploadClientImageAction,
  getEmployerAction,
  uploadPharmacyAction,
  getPharmacyAction,
  getEmployerIdAction,
  uploadDocFileAction,
} from '../../utils/actions/client.action';
import Autocomplete from '@material-ui/lab/Autocomplete';
import countries from '../../utils/countries_data';
import CircularProgress from '@material-ui/core/CircularProgress';
import logo from '../../assets/imgs/download.png';
import style from './Setting_style';
import moment from 'moment';
import AddressView from '../../admin/components/common/ClinicAddresses/AddressView';
import DocumentsUploadsView from '../../common/documentsUploadsView';
import ProofOfIdentity from '../../common/proofOfIdentity';
import ProofOfIAddress from '../../common/proofOfAddress';
import {
  AutoComplete,
  CanvasDialog,
  ImageUploader,
  Spinner,
} from '../../components/atoms';
import { JOB_DESCRIPTION } from '../../utils/enums';
import { adultAge } from '../../utils/timUtils';
// import SignatureCanvas from 'react-signature-canvas';
// import CanvasDialog from '../components/CanvasDialog';

const JOB_LIST = Object.keys(JOB_DESCRIPTION).map((job, i) => ({
  _id: job,
  name: JOB_DESCRIPTION[job],
}));

const formattedDescription = (description) => {
  const reformattedStr = description.split('-').join(' ').toUpperCase();
  return { _id: reformattedStr, name: JOB_DESCRIPTION[reformattedStr] };
};

// registerLocale('en', en);
const appReq = [];

const genderList = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
];

class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCanvasDialog: false,
      signature: '',
      firstName: '',
      lastName: '',
      isSocialLogin: false,
      dob: '',
      gender: '',
      maritalStatus: '',
      phoneNumber: '',
      phoneType: '',
      address1: '',
      address2: '',
      city: '',
      zip: '',
      country: '',
      state: '',
      email: '',
      sEmail: '',
      password: '',
      cpassword: '',
      avatar: '',
      errors: {
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        maritalStatus: '',
        phoneNumber: '',
        phoneType: '',
        address1: '',
        address2: '',
        city: '',
        zip: '',
        country: '',
        email: '',
        sEmail: '',
        password: '',
        cpassword: '',
      },
      profileImage: profile,
      loading: false,
      imageUrls: [],
      emloyerData: [],
      p_name: '',
      p_address: '',
      p_addressOptional: '',
      p_city: '',
      p_zip: '',
      p_country: '',
      p_phoneNumber: '',
      p_clientId: '',
      employerToggle: false,
      eId: '',
      doc_file: '',
      is_doc_file: false,
      btnLoading: false,
      proofOfIdentity: { url: '', type: '' },
      proofOfAddress: { url: '', type: '' },
      consentForm: { url: '', type: '' },
      emName: '',
      emEmail: '',
      emSecondaryEmail: '',
      emPhoneNumber: '',
      emRelation: '',
      signaturePad: React.createRef(),
    };

    this.setRef = this.setRef.bind(this);
    this.clearSignature = this.clearSignature.bind(this);
    this.modifyUserSignature = this.modifyUserSignature.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.getClientAction(true);
    this.props.getEmployerAction();
    this.props.getPharmacyAction();
  }

  setRef(ref) {
    if (ref && ref.fromDataURL) {
      ref.fromDataURL(this.state.signature);
      this.setState({ signaturePad: ref });
    }

    if (ref && ref.toDataURL) {
      ref.toDataURL();
      this.setState({ signaturePad: ref });
    }

    if (ref && ref.clear) {
      ref.clear();
      this.setState({ signaturePad: ref });
    }
  }

  componentDidUpdate(preProps) {
    if (
      (!this.props.getClient.request &&
        !this.props.updateClientData.request &&
        preProps.updateClientData.updateClientData !==
          this.props.updateClientData.updateClientData) ||
      this.props.getClient !== preProps.getClient
    ) {
      if (
        this.props.updateClientData.updateClientData ||
        (this.props.getClient && this.props.getClient.getClientData)
      ) {
        const data = this.props.updateClientData.updateClientData
          ? this.props.updateClientData.updateClientData
          : this.props.getClient.getClientData;

        localStorage.setItem('name', data.firstName + ' ' + data.lastName);
        localStorage.setItem('avatar', data.avatar ? data.avatar : logo);
        localStorage.setItem('clientPhoneNumber', data.phoneNumber);
        let country = countries.filter(
          (itm) => data.address && itm.name === data.address.country
        );

        this.setState({
          firstName: data.firstName,
          lastName: data.lastName,
          isSocialLogin: false,
          email: data.email ? data.email : '',
          sEmail: data.secondaryEmail ? data.secondaryEmail : '',
          phoneNumber: data.phoneNumber ? data.phoneNumber : '',
          phoneType: data.phoneType ? data.phoneType : '',
          gender: data.gender ? data.gender : '',
          dob: data.dob ? new Date(data.dob) : null,
          maritalStatus: data.maritalStatus ? data.maritalStatus : '',
          address1: data.address ? data.address.address1 : '',
          address2: data.address ? data.address.address2 : '',
          country: country.length > 0 ? country[0] : null,
          state: data.address ? data.address.state : '',
          city: data.address ? data.address.city : '',
          zip: data.address ? data.address.zip : '',
          avatar: data.avatar ? data.avatar : logo,
          corporationId: data.corporationId ? data.corporationId.code : '',
          employerToggle: data.corporationId ? true : false,
          profileImage: data.avatar
            ? data.avatar
            : 'https://lighthouse-media.s3.us-east-2.amazonaws.com/defaultAvatar.png',
          documents: data.documents ? data.documents : [],
          proofOfIdentity: data.documents
            ? data.documents.filter(
                (document) => document.type === 'proofOfIdentity'
              )[0]
            : { url: '', type: '' },
          proofOfAddress: data.documents
            ? data.documents.filter(
                (document) => document.type === 'proofOfAddress'
              )[0]
            : { url: '', type: '' },
          consentForm: data.documents
            ? data.documents.filter(
                (document) => document.type === 'consentForm'
              )[0]
            : { url: '', type: '' },
          emEmail: data.emergencyContact ? data.emergencyContact.emEmail : '',
          emName: data.emergencyContact ? data.emergencyContact.emName : '',
          emPhoneNumber: data.emergencyContact
            ? data.emergencyContact.emPhoneNumber
            : '',
          emRelation: data.emergencyContact
            ? data.emergencyContact.emRelation
            : '',
          jobDescription: data.jobDescription
            ? formattedDescription(data.jobDescription)
            : '',
          signature: data.signature,
        });
      }
      this.setState({ loading: false });
    }

    if (this.props.uploadClientImageData !== preProps.uploadClientImageData) {
      var urls =
        this.props.uploadClientImageData &&
        this.props.uploadClientImageData.uploadClientImageData &&
        this.props.uploadClientImageData.uploadClientImageData.urls
          ? this.props.uploadClientImageData.uploadClientImageData.urls
          : [];
      this.setState({ imageUrls: urls });
      this.setState({ avatar: urls[0] });
    }

    if (this.props.getEmployerData !== preProps.getEmployerData) {
      var data = this.props.getEmployerData.getEmployerData;
      this.setState({ emloyerData: data });
    }

    if (this.props.pharmacyData !== preProps.pharmacyData) {
      let data = this.props.pharmacyData.pharmacyData;
      let country = countries.filter(
        (itm) => data && itm.name === data.country
      );

      this.setState({
        p_name: data && data.name ? data.name : '',
        p_address: data && data.address ? data.address : '',
        p_addressOptional:
          data && data.addressOptional ? data.addressOptional : '',
        p_city: data && data.city ? data.city : '',
        p_zip: data && data.zip ? data.zip : '',
        p_country: country.length > 0 ? country[0] : null,
        p_phoneNumber: data && data.phoneNumber ? data.phoneNumber : '',
        p_clientId: data && data.clientId ? data.clientId : '',
      });
    }

    if (this.props.getEmployerIdData !== preProps.getEmployerIdData) {
      data = this.props.getEmployerIdData.getEmployerIdData;
      if (data && data.message) {
        AlertMsg('success', data.message);
      } else if (
        this.props.getEmployerIdData &&
        this.props.getEmployerIdData.error
      ) {
        AlertMsg('error', 'Employer Id Not Found');
      }
    }

    if (this.props.uploadDocFileData !== preProps.uploadDocFileData) {
      var uploadDocFileData =
        this.props.uploadDocFileData &&
        this.props.uploadDocFileData.uploadDocFileData &&
        this.props.uploadDocFileData.uploadDocFileData
          ? this.props.uploadDocFileData.uploadDocFileData
          : {};

      switch (uploadDocFileData.type) {
        case 'proofOfIdentity':
          this.setState({
            proofOfIdentity: { ...uploadDocFileData },
            btnLoading: false,
          });
          break;
        case 'proofOfAddress':
          this.setState({
            proofOfAddress: { ...uploadDocFileData },
            btnLoading: false,
          });
          break;
        case 'consentForm':
          this.setState({
            consentForm: { ...uploadDocFileData },
            btnLoading: false,
          });
          break;
      }
    }
  }

  handleCreateAddress = (address) => {
    this.setState({
      address1: address.address1,
      address2: address.address2,
      state: address.state,
      city: address.city,
      zip: address.zip,
      country: address.country,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    const { name, value } = e.target;
    // this.validateForm();
  };

  validateForm() {
    let { errors } = this.state;
    let isValid = true;

    if (!this.state.firstName?.trim()) {
      errors.firstName = 'Please Enter First Name';
      isValid = false;
    } else {
      errors.firstName = '';
    }

    if (!this.state.lastName?.trim()) {
      errors.lastName = 'Please Enter Last Name';
      isValid = false;
    } else {
      errors.lastName = '';
    }

    if (!this.state.address1?.trim()) {
      errors.address1 = 'Date Of Birth is required!';
      isValid = false;
    } else {
      errors.address1 = '';
    }

    if (!this.state.phoneNumber?.trim()) {
      errors.phoneNumber = 'Please Enter Phone Number!';
      isValid = false;
    } else {
      errors.phoneNumber = '';
    }

    if (!this.state.phoneType?.trim()) {
      errors.phoneType = 'Phone Type is required!';
      isValid = false;
    } else {
      errors.phoneType = '';
    }

    if (!this.state.country || Object.keys(this.state.country).length === 0) {
      errors.country = 'Country is required!';
      isValid = false;
    } else {
      errors.country = '';
    }

    if (!this.state.state) {
      errors.state = 'State is required!';
      isValid = false;
    } else {
      errors.state = '';
    }

    if (!this.state.city) {
      errors.city = 'City is required!';
      isValid = false;
    } else {
      errors.city = '';
    }

    if (!this.state.address1) {
      errors.address1 = 'Address1 is required!';
      isValid = false;
    } else {
      errors.address1 = '';
    }

    if (!this.state.gender?.trim()) {
      errors.gender = 'Please Select Gender';
      isValid = false;
    } else {
      errors.gender = '';
    }

    if (!this.state.maritalStatus?.trim()) {
      errors.maritalStatus = 'Please Select Marital Status';
      isValid = false;
    } else {
      errors.maritalStatus = '';
    }

    if (!this.state.email?.trim()) {
      errors.email = 'Email is required!';
      isValid = false;
    } else {
      errors.email = '';
    }

    if (this.state.password !== this.state.cpassword) {
      errors.password = 'Password doesnt match';
      isValid = false;
    } else if (this.state.password && this.state.password.length < 6) {
      errors.password = 'Password must be at least 6 characters.';
      isValid = false;
    } else {
      errors.password = '';
    }

    if (this.state.cpassword !== this.state.password) {
      errors.cpassword = 'Password doesnt match';
      isValid = false;
    } else if (this.state.cpassword && this.state.cpassword.length < 6) {
      errors.cpassword = 'Password must be at least 6 characters.';
      isValid = false;
    } else {
      errors.cpassword = '';
    }

    this.setState({ errors });
    return isValid;
  }
  readFile(file) {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();

      reader.onload = function (event) {
        var _event$target;

        resolve(
          event === null || event === void 0
            ? void 0
            : (_event$target = event.target) === null ||
              _event$target === void 0
            ? void 0
            : _event$target.result
        );
      };

      reader.onerror = function (event) {
        reader.abort();
        reject(event);
      };

      reader.readAsDataURL(file);
    });
  }

  async documentUpload(selectedFiles) {
    this.setState({ btnLoading: true });
    const formData = new FormData();
    formData.append('document', selectedFiles);
    await this.props.uploadClientImageAction(formData, 'profileImage');
  }

  async docFileUpload(selectedFiles, type) {
    const formData = new FormData();
    formData.append('document', selectedFiles);
    await this.props.uploadDocFileAction(formData, type);
  }

  async handleImageUpload(files) {
    if (files.length > 0) {
      var result = await this.readFile(files[0]);
      await this.documentUpload(files[0]);
      this.setState({
        profileImage: result,
      });
    }
  }

  async handleEmployerId() {
    var eId = this.state.eId;
    await this.props.getEmployerIdAction(eId);
  }

  async handleDocUpload(e, type) {
    await this.docFileUpload(e.target.files[0], type);
  }

  triggerFile(name) {
    document.getElementsByName(name)[0].click();
  }

  handleSubmit = async (e) => {
    const isValid = this.validateForm();

    if (!isValid) {
      AlertMsg(
        'error',
        'Please fix the above mentioned errors and try again. Thank You!'
      );
      return;
    }

    this.setState({ btnLoading: true });

    const {
      firstName,
      lastName,
      dob,
      gender,
      maritalStatus,
      corporationId,
      phoneNumber,
      phoneType,
      address1,
      address2,
      city,
      country,
      zip,
      email,
      sEmail,
      avatar,
      doc_file,
      isSocialLogin,
      password,
      cpassword,
      emEmail,
      emSecondaryEmail,
      emName,
      state,
      emPhoneNumber,
      jobDescription,
      emRelation,
      signature,
    } = this.state;

    let emergencyContact = {};

    if (emEmail !== '') {
      emergencyContact.emEmail = emEmail;
    }

    if (emName !== '') {
      emergencyContact.emName = emName;
    }

    if (emPhoneNumber !== '') {
      emergencyContact.emPhoneNumber = emPhoneNumber;
    }

    if (emRelation !== '') {
      emergencyContact.emRelation = emRelation;
    }

    const data = {
      firstName,
      lastName,
      dob,
      gender,
      maritalStatus,
      jobDescription,
      phoneNumber,
      phoneType,
      corporationId: this.state.employerToggle ? corporationId : '',
      email,
      secondaryEmail: sEmail,
      type: 'json',
      avatar,
      idCopy: doc_file,
      address: {
        address1,
        address2,
        city,
        country: country && country.name ? country.name : '',
        zip,
        state,
      },
      emergencyContact: emergencyContact,
      signature,
    };

    if (!isSocialLogin) {
      if (password !== cpassword) {
        this.setState({ btnLoading: false });
        AlertMsg('error', 'Password and confirm password must be same!');
        return;
      }

      data.password = password;
    }

    // this.handlePharmacy();
    await this.props.updateClientAction('', data);
    setTimeout(() => {
      this.setState({ btnLoading: false });
    }, 1000);
  };

  async handlePharmacy(params) {
    const {
      p_name,
      p_address,
      p_addressOptional,
      p_city,
      p_zip,
      p_country,
      p_phoneNumber,
      p_clientId,
    } = this.state;
    const data = {
      name: p_name,
      address: p_address,
      addressOptional: p_addressOptional,
      city: p_city,
      zip: p_zip,
      country: p_country && p_country.name ? p_country.name : '',
      phoneNumber: p_phoneNumber,
      clientId: localStorage.getItem('clientId'),
    };
    // await this.props.uploadPharmacyAction(data);
    // await this.props.getPharmacyAction();
  }

  filterJobs = (text) => console.log(text);

  setJobDescription = (val) => {
    if (val) {
      const id = val._id.split(' ').join('-').toLowerCase();
      this.setState({ jobDescription: id });
    }
  };

  openSignatureCanvas = () => this.setState({ openCanvasDialog: true });
  closeCanvas = () =>
    this.setState((prevState) => ({
      signature: prevState.signature,
      openCanvasDialog: false,
    }));

  handleSignatureUpdate = (updatedSignature) =>
    this.setState({ signature: updatedSignature, openCanvasDialog: false });

  clearSignature() {
    if (this.state.signaturePad) {
      this.state.signaturePad.clear();
    }
    this.setState({ signature: null });
  }

  modifyUserSignature() {
    if (this.state.signaturePad) {
      if (this.state.signaturePad.isEmpty()) {
        AlertMsg(
          'error',
          'If you plan to add signature later then click on dark space!'
        );
      } else {
        const base64Img = this.state.signaturePad.toDataURL();
        this.setState({ signature: base64Img, openCanvasDialog: false });
      }
    }
  }
  render() {
    const { classes } = this.props;

    const {
      firstName,
      lastName,
      isSocialLogin,
      dob,
      jobDescription,
      gender,
      maritalStatus,
      phoneNumber,
      phoneType,
      address1,
      address2,
      city,
      country,
      zip,
      email,
      sEmail,
      errors,
      btnLoading,
      emName,
      emEmail,
      emSecondaryEmail,
      emPhoneNumber,
      emRelation,
      state,
      corporationId,
      openCanvasDialog,
      signature,
      profileImage,
    } = this.state;

    return (
      <div>
        <DrawerMenu {...this.props} avatar={profileImage} />
        <GridContainer {...this.props} />
        <GridItem
          {...this.props}
          style={{
            zIndex: this.state.isOpen ? 100 : '',
            backgroundColor: this.state.isOpen ? 'rgba(34,34,34,48%)' : '',
            bottom: 0,
          }}
        >
          {this.state.loading && <Spinner isOpen />}
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Typography className={classes.inputText}>
                  Personal Information
                </Typography>
                <Typography className={classes.inputTitle}>
                  Profile Image*
                </Typography>
                <Grid
                  container
                  style={{ marginBottom: '15px', width: '150px' }}
                >
                  <ImageUploader
                    key={+new Date()}
                    src={profileImage}
                    isLoading={!profileImage}
                    uploadedImage={this.handleImageUpload.bind(this)}
                  />
                </Grid>
                <Grid container style={{ marginBottom: '15px' }}>
                  <Grid item xs={6} style={{ paddingRight: '10px' }}>
                    <Typography className={classes.inputTitle}>
                      First Name*
                    </Typography>
                    <input
                      className={classes.inputsty}
                      value={firstName}
                      onChange={this.handleChange}
                      type='text'
                      name='firstName'
                      // disabled
                    />
                    <span className={classes.errorText}>
                      {errors.firstName && errors.firstName}
                    </span>
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: '10px' }}>
                    <Typography className={classes.inputTitle}>
                      Last Name*
                    </Typography>
                    <input
                      className={classes.inputsty}
                      value={lastName}
                      onChange={this.handleChange}
                      type='text'
                      name='lastName'
                    />
                    <span className={classes.errorText}>
                      {errors.lastName && errors.lastName}
                    </span>
                  </Grid>
                </Grid>
                <Grid style={{ marginBottom: '15px' }}>
                  <Typography className={classes.inputTitle}>
                    Date Of Birth*
                  </Typography>
                  <DatePicker
                    locale='en'
                    className={classes.inputsty}
                    dateFormat='MMMM d, yyyy'
                    selected={dob}
                    showYearDropdown
                    maxDate={moment().subtract(adultAge, 'years').toDate()}
                    name='dob'
                    dropdownMode='select'
                    showMonthDropdown
                    onChange={(date) => (
                      this.setState({ dob: date }), (errors.dob = null)
                    )}
                    disabled
                  />
                  <span className={classes.errorText}>
                    {errors.dob && errors.dob}
                  </span>
                </Grid>
                <Grid container style={{ marginBottom: '15px' }}>
                  <Grid item xs={6} style={{ paddingRight: '10px' }}>
                    <Typography className={classes.inputTitle}>
                      Gender*
                    </Typography>
                    <FormControl className={classes.inputsty}>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={gender}
                        name='gender'
                        onChange={this.handleChange}
                      >
                        <MenuItem value={'male'}>Male</MenuItem>
                        <MenuItem value={'female'}>Female</MenuItem>
                      </Select>
                      <span className={classes.errorText}>
                        {errors.gender && errors.gender}
                      </span>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: '10px' }}>
                    <Typography className={classes.inputTitle}>
                      Marital Status*
                    </Typography>
                    <FormControl className={classes.inputsty}>
                      <Select
                        value={maritalStatus}
                        onChange={this.handleChange}
                        name='maritalStatus'
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                      >
                        <MenuItem value={'select'}>Select</MenuItem>
                        <MenuItem value={'married'}>Married</MenuItem>
                        <MenuItem value={'single'}>Single</MenuItem>
                        <MenuItem value={'divorced'}>Divorced</MenuItem>
                        <MenuItem value={'widowed'}>Widowed</MenuItem>
                        <MenuItem value={'seperated'}>Seperated</MenuItem>
                      </Select>
                      <span className={classes.errorText}>
                        {errors.maritalStatus && errors.maritalStatus}
                      </span>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Typography className={classes.inputText}>
                  Contact Information
                </Typography>
                <Grid container style={{ marginBottom: '15px' }}>
                  <Grid item xs={6} style={{ paddingRight: '10px' }}>
                    <Typography className={classes.inputTitle}>
                      Phone Number*
                    </Typography>
                    <input
                      className={classes.inputsty}
                      value={phoneNumber}
                      type='number'
                      onChange={this.handleChange}
                      name='phoneNumber'
                    />
                    <span className={classes.errorText}>
                      {errors.phoneNumber && errors.phoneNumber}
                    </span>
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: '10px' }}>
                    <Typography className={classes.inputTitle}>
                      Type*
                    </Typography>
                    <FormControl className={classes.inputsty}>
                      <Select
                        value={phoneType}
                        onChange={this.handleChange}
                        name='phoneType'
                      >
                        <MenuItem value={'mobile'}>Mobile</MenuItem>
                        <MenuItem value={'telePhone'}>TelePhone</MenuItem>
                      </Select>
                      <span className={classes.errorText}>
                        {errors.phoneType && errors.phoneType}
                      </span>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid style={{ marginBottom: '15px' }}>
                  <AddressView
                    handleChange={this.handleCreateAddress}
                    editingAddress={{
                      address1,
                      address2,
                      state,
                      city,
                      country,
                      zip,
                    }}
                    errors={{
                      address1: errors.address1,
                      address2: errors.address2,
                      city: errors.city,
                      state: errors.state,
                      zip: errors.zip,
                      country: errors.country,
                    }}
                  />
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography className={classes.inputText}>
                  Account Details
                </Typography>
                <Grid style={{ marginBottom: '15px' }}>
                  <Typography className={classes.inputTitle}>
                    Primary Email
                  </Typography>
                  <input
                    className={classes.inputsty}
                    value={email}
                    type='email'
                    onChange={this.handleChange}
                    name='email'
                    disabled
                  />
                  <span className={classes.errorText}>
                    {errors.email && errors.email}
                  </span>
                </Grid>
                <Grid style={{ marginBottom: '15px' }}>
                  <Typography className={classes.inputTitle}>
                    Secodary Email
                  </Typography>
                  <input
                    className={classes.inputsty}
                    value={sEmail}
                    type='sEmail'
                    name='sEmail'
                    onChange={this.handleChange}
                  />
                  <span className={classes.errorText}>
                    {errors.sEmail && errors.sEmail}
                  </span>
                </Grid>
                {!isSocialLogin ? (
                  <Grid container style={{ marginBottom: '15px' }}>
                    <Grid item xs={6} style={{ paddingRight: '10px' }}>
                      <Typography className={classes.inputTitle}>
                        Password
                      </Typography>
                      <input
                        className={classes.inputsty}
                        onChange={this.handleChange}
                        // value={email}
                        type='password'
                        name='password'
                      />
                      <span className={classes.errorText}>
                        {errors.password && errors.password}
                      </span>
                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: '10px' }}>
                      <Typography className={classes.inputTitle}>
                        Verify Password
                      </Typography>
                      <input
                        className={classes.inputsty}
                        // value={email}
                        type='password'
                        onChange={this.handleChange}
                        name='cpassword'
                        autoComplete='new-password'
                      />
                      <span className={classes.errorText}>
                        {errors.cpassword && errors.cpassword}
                      </span>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <Typography className={classes.italicTitle}>
                      This account used either Google or Apple service when
                      registering. To update your password, you must first
                      change it with your email provider.
                    </Typography>
                  </Grid>
                )}
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography className={classes.inputText}>
                  Document Uploads
                </Typography>

                <ProofOfIdentity
                  clientId={localStorage.getItem('clientId')}
                  isPatient
                />

                {false && (
                  <ProofOfIAddress
                    clientId={localStorage.getItem('clientId')}
                    isPatient
                  />
                )}
              </Paper>
            </Grid>

            <Grid item xs={6} style={{ marginBottom: '15px' }}>
              <Paper className={classes.paper}>
                <Typography className={classes.inputText}>
                  Employer Information
                </Typography>
                <Grid style={{ marginBottom: '15px' }}>
                  <Typography className={classes.inputTitle}>
                    Are you currently employed
                  </Typography>

                  <FormControl className={classes.inputsty}>
                    <Select
                      id='demo-simple-select'
                      onChange={(e) => {
                        this.setState({
                          employerToggle:
                            e.target.value === 'no' ? false : true,
                        });
                      }}
                      value={this.state.employerToggle ? 'yes' : 'no'}
                    >
                      <MenuItem value={'yes'}>Yes</MenuItem>
                      <MenuItem value={'no'}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {this.state.employerToggle && (
                  <Grid style={{ marginBottom: '15px' }}>
                    <Typography className={classes.inputTitle}>
                      Enter Employer ID number*
                      <HelpOutlineIcon
                        style={{ marginBottom: '5px', fontSize: '15px' }}
                      />
                    </Typography>
                    <input
                      className={classes.inputsty}
                      onChange={(e) =>
                        this.setState({ corporationId: e.target.value })
                      }
                      value={corporationId}
                      type='text'
                      name='corporationId'
                    />
                  </Grid>
                )}
                <Grid style={{ marginBottom: '15px' }}>
                  <Typography className={classes.inputTitle}>
                    Job Description
                  </Typography>

                  <AutoComplete
                    value={jobDescription}
                    onChange={this.setJobDescription}
                    renderingFunction={this.filterJobs}
                    placeholder='Select Job description'
                    options={JOB_LIST}
                  />
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={6} style={{ marginBottom: '15px' }}>
              <Paper className={classes.paper}>
                <Typography
                  style={{ marginBottom: '10px' }}
                  className={classes.inputText}
                >
                  Emergency Contact details
                </Typography>
                <Grid
                  item
                  xs={12}
                  component='div'
                  style={{ paddingTop: '16px' }}
                >
                  <Grid className={classes.fieldContainer}>
                    <Typography className={classes.inputTitle}>
                      Full Name
                    </Typography>
                    <input
                      type='text'
                      name='emName'
                      value={emName}
                      onChange={this.handleChange}
                      className={classes.inputsty}
                    />
                  </Grid>
                  <span className={classes.errorText}>
                    {errors.emName && errors.emName}
                  </span>
                </Grid>

                <Grid
                  item
                  xs={12}
                  component='div'
                  style={{ paddingTop: '16px' }}
                >
                  <Grid className={classes.fieldContainer}>
                    <Typography className={classes.inputTitle}>
                      Email
                    </Typography>
                    <input
                      type='email'
                      name='emEmail'
                      value={emEmail}
                      onChange={this.handleChange}
                      className={classes.inputsty}
                    />
                  </Grid>
                  <span className={classes.errorText}>
                    {errors.emEmail && errors.emEmail}
                  </span>
                </Grid>

                <Grid
                  item
                  xs={12}
                  component='div'
                  style={{ paddingTop: '16px' }}
                >
                  <Grid className={classes.fieldContainer}>
                    <Typography className={classes.inputTitle}>
                      Phone Number
                    </Typography>
                    <input
                      type='number'
                      name='emPhoneNumber'
                      value={emPhoneNumber}
                      onChange={this.handleChange}
                      className={classes.inputsty}
                    />
                  </Grid>
                  <span className={classes.errorText}>
                    {errors.emPhoneNumber && errors.emPhoneNumber}
                  </span>
                </Grid>

                <Grid
                  item
                  xs={12}
                  component='div'
                  style={{ paddingTop: '16px' }}
                >
                  <Grid className={classes.fieldContainer}>
                    <Typography className={classes.inputTitle}>
                      Relation
                    </Typography>
                    <input
                      type='text'
                      name='emRelation'
                      onChange={this.handleChange}
                      value={emRelation}
                      className={classes.inputsty}
                      placeholder='...'
                    />
                  </Grid>
                  <span className={classes.errorText}>
                    {errors.emRelation && errors.emRelation}
                  </span>
                </Grid>
              </Paper>
            </Grid>
            {false && (
              <Grid item xs={6} style={{ marginBottom: '15px' }}>
                <Paper className={classes.paper}>
                  <Typography className={classes.inputText}>
                    You Digital Signature
                  </Typography>
                  <Grid container alignItems='flex-start' direction='column'>
                    <Grid style={{ marginBottom: '15px' }} item>
                      <Typography className={classes.inputTitle}>
                        Signature
                      </Typography>
                    </Grid>
                    <Grid
                      style={{
                        border: '1px solid #ccc',
                        padding: '20px',

                        height: '150px',
                      }}
                      justify='center'
                      alignItems='center'
                      container
                      item
                    >
                      {signature && (
                        <img src={signature} width='300px' height='100px' />
                      )}
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={this.openSignatureCanvas}
                        variant={signature ? 'outlined' : 'contained'}
                        style={{
                          backgroundColor: signature ? '#fff' : '#1964EB',
                          color: signature ? '#1964EB' : '#fff',
                          border: signature ? '1px solid #1964EB' : 'none',
                          marginTop: '15px',
                        }}
                        color='primary'
                      >
                        {signature ? 'Edit Signature' : 'Create Signature'}
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
            <Grid item xs={12} style={{ marginBottom: '15px' }}>
              <Button
                onClick={this.handleSubmit}
                variant='contained'
                style={{
                  backgroundColor: '#1b63eb',
                  fontWeight: 100,
                  fontSize: 16,
                  marginTop: '12px',
                  color: '#fff',
                  textTransform: 'capitalize',
                }}
                disabled={btnLoading}
              >
                {btnLoading ? (
                  <CircularProgress size={20} color={'white'} />
                ) : (
                  'Update'
                )}
              </Button>
            </Grid>
          </Grid>
        </GridItem>
        <CanvasDialog
          open={openCanvasDialog}
          onClose={this.closeCanvas}
          ref={this.state.signaturePad}
          signature={signature}
          setRef={this.setRef}
          clear={this.clearSignature}
          onSignatureUpdate={this.handleSignatureUpdate}
          modifyUserSignature={this.modifyUserSignature}
        />
      </div>
    );
  }
}

Setting.defaultProps = {
  getClient: {},
};

const connected = connect(
  (state) => ({
    getClient: state.getClient,
    updateClientData: state.updateClient,
    uploadClientImageData: state.uploadClientImage,
    getEmployerData: state.getEmployer,
    getPharmacyData: state.updatePharmacy,
    pharmacyData: state.getPharmacy,
    getEmployerIdData: state.getEmployerId,
    uploadDocFileData: state.uploadDocFile,
  }),
  {
    getClientAction,
    updateClientAction,
    uploadClientImageAction,
    getEmployerAction,
    uploadPharmacyAction,
    getPharmacyAction,
    getEmployerIdAction,
    uploadDocFileAction,
  }
)(Setting);

const formed = reduxForm({
  form: 'updateClient',
})(connected);

export default withStyles(style)(formed);
