/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import { takeEvery, put } from "redux-saga/effects";
import { post, get, updatePatch, delet } from "../axios";
import {
  ADD_USER_SUCCESS,
  ADD_USER_FAILED,
  GET_USER_SUCCESS,
  GET_USER_FAILED,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAILED,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  ADD_USER_REQUEST,
  GET_USER_BY_ID_REQUEST,
  DELETE_USER_REQUEST,
  UPDATE_USER_REQUEST,
  GET_USER_REQUEST,
} from "../AdminConstent";
import AlertMsg from "../Alert";

function* addUser(action) {
  try {
    const { endpoint, body } = action;
    const response = yield post(endpoint, body);
    AlertMsg("success", "Added");
    yield put({ type: ADD_USER_SUCCESS, response });
  } catch (error) {
    AlertMsg("error", "Failed");
    yield put({ type: ADD_USER_FAILED, error });
  }
}

function* getUserSaga(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_USER_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_USER_FAILED, error });
  }
}

function* getByIdUser(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_USER_BY_ID_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_USER_BY_ID_FAILED, error });
  }
}

function* updateUser(action) {
  try {
    const { endpoint, body } = action;
    AlertMsg("success", "Updated");
    const response = yield updatePatch(endpoint, body);
    yield put({ type: UPDATE_USER_SUCCESS, response });
  } catch (error) {
    AlertMsg("error", "Failed");
    yield put({ type: UPDATE_USER_FAILED, error });
  }
}

function* deleteUser(action) {
  try {
    const { endpoint } = action;
    const response = yield delet(endpoint);
    AlertMsg("success", "Deleted");
    yield put({ type: DELETE_USER_SUCCESS, response });
  } catch (error) {
    AlertMsg("error", "Failed");
    yield put({ type: DELETE_USER_FAILED, error });
  }
}

export function* userSaga() {
  yield takeEvery(ADD_USER_REQUEST, addUser);
  yield takeEvery(GET_USER_REQUEST, getUserSaga);
  yield takeEvery(GET_USER_BY_ID_REQUEST, getByIdUser);
  yield takeEvery(UPDATE_USER_REQUEST, updateUser);
  yield takeEvery(DELETE_USER_REQUEST, deleteUser);
}
