/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { withStyles, Grid, Typography } from '@material-ui/core';
import { CustomNextButtons } from '../common/CustomButtons';
import DividerLine from '../assets/imgs/divider.svg';
import SliderPointIcon from '../assets/imgs/slider point.svg';
import SliderPointLine from '../assets/imgs/slider verticle line.svg';
import styles from './AssessmentResult_style';

export class assessmentResult extends Component {
  componentDidMount = () => {
    const { data } = this.props;
    console.log('data 123', data);
    this.props.history.push({
      pathname: '/clinician',
      search: '?type=recommended',
      state: {
        matchedClinicians: data.clinicians ? data.clinicians : [],
        preferredClinician: data.preferredClinician,
        riskScore: data.risk,
      },
    });
  };

  render() {
    const { classes, data } = this.props;
    return (
      <React.Fragment>
        <Typography className={classes.headerText}>Your Results</Typography>
        <img src={DividerLine} className={classes.dividerLine} />
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '25px',
            opacity: '50%',
            paddingBottom: '10px',
          }}
        >
          <Typography className={classes.sliderTitleText}>
            Risk Scale
          </Typography>
          <Grid className={classes.queMarkIconContainer}>
            <Typography className={classes.queMarkText}>?</Typography>
          </Grid>
          <Typography className={classes.scaleCountNumber}>
            {data.totalRiskScore}
          </Typography>
        </Grid>
        <Grid
          container
          justify='center'
          alignItems='center'
          className={classes.sliderContainer}
        >
          <Grid className={classes.sliderTextContainer}>
            <Typography className={classes.sliderText}>Minimal</Typography>
          </Grid>
          <Grid className={classes.sliderTextContainer}>
            <Typography className={classes.sliderText}>Mild</Typography>
          </Grid>
          <Grid className={classes.sliderTextContainer}>
            <Typography className={classes.sliderText}>Moderate</Typography>
          </Grid>
          <Grid
            container
            className={classes.sliderTextContainer}
            justify='center'
            alignItems='center'
          >
            <div
              className={classes.sliderPointContainer}
              style={{ left: ((data.riskScore / 33) * 96).toString() + '%' }}
            >
              <img src={SliderPointIcon} className={classes.sliderPoint} />
              <Typography className={classes.sliderPointText}>
                {data.risk ? data.risk : 0}
              </Typography>
              <img src={SliderPointLine} className={classes.sliderPointLine} />
            </div>
            <Typography
              className={classes.sliderText}
              style={{ width: '95px', height: '44px', textAlign: 'center' }}
            >
              Moderate Sever
            </Typography>
          </Grid>
          <Grid className={classes.sliderTextContainer}>
            <Typography className={classes.sliderText}>Severe</Typography>
          </Grid>
        </Grid>

        {/* <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "25px",
                opacity: "50%",
                paddingBottom: "10px",
              }}
            >
              <Typography className={classes.sliderTitleText}>
                Anxiety Scale
              </Typography>
              <Grid className={classes.queMarkIconContainer}>
                <Typography className={classes.queMarkText}>?</Typography>
              </Grid>
              <Typography className={classes.scaleCountNumber}>{data.totalRiskScore}</Typography>
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.sliderContainer}
            >
              <Grid className={classes.sliderTextContainer}>
                <Typography className={classes.sliderText}>Minimal</Typography>
              </Grid>
              <Grid className={classes.sliderTextContainer}>
                <Typography className={classes.sliderText}>Mild</Typography>
              </Grid>
              <Grid className={classes.sliderTextContainer}>
                <Typography className={classes.sliderText}>Moderate</Typography>
              </Grid>
              <Grid
                container
                className={classes.sliderTextContainer}
                justify="center"
                alignItems="center"
              >
                <div className={classes.sliderPointContainer2}>
                  <img src={SliderPointIcon} className={classes.sliderPoint} />
                  <Typography className={classes.sliderPointText}>
                    {data.risk ? data.risk : 0}
                  </Typography>
                  <img
                    src={SliderPointLine}
                    className={classes.sliderPointLine}
                  />
                </div>
                <Typography
                  className={classes.sliderText}
                  style={{ width: "95px", height: "44px", textAlign: "center" }}
                >
                  Moderate Sever
                </Typography>
              </Grid>
              <Grid className={classes.sliderTextContainer}>
                <Typography className={classes.sliderText}>Severe</Typography>
              </Grid>
            </Grid> */}

        <Grid className={classes.footerTextContainer}>
          <Typography>
            Your scores fall in the ranges for
            <b> severe depression</b> and
            <b>severe anxiety</b>
            .
            <br />
            <br />
            We recommend that you schedule an appointment with one of our
            psychologists or psychiatrists for an evaluation and to discuss what
            your results mean. We care about your mental health and are here to
            give you the support you need.
          </Typography>
        </Grid>
        <Grid className={classes.footerButtonContainer}>
          <Typography
            className={classes.skipText}
            onClick={() => this.props.history.push('/dashboard')}
          >
            Skip For Now
          </Typography>
          <CustomNextButtons
            // style={classes.scheduleButton}
            // textStyles={classes.scheduleButtonText}
            title='Schedule Appointment Now'
            width='296px'
            onPressButton={() =>
              this.props.history.push({
                pathname: '/clinician',
                search: '?type=recommended',
                state: {
                  matchedClinicians: data.clinicians ? data.clinicians : [],
                  riskScore: data.risk,
                },
              })
            }
          />
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(assessmentResult);
