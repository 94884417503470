import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  root: {
    padding: '20px',
    marginLeft: 250,
    marginTop: '70px',
    // position: 'relative',
    // padding: theme.spacing(10),
    // paddingLeft: theme.spacing(15),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  namesty: {
    fontSize: 24,
    lineHeight: 'normal',
    fontWeight: 400,
    color: '#003d79',
    textAlign: 'left',
  },
  buttonGrid: {
    textAlign: 'right',
  },
  buttongroup: {
    marginLeft: '-1px',
    fontSizeAdjust: '13px',
    lineHeight: '24px',
    paddingLeft: '12px',
    paddingRight: '12px',
    color: '#003d79',
    borderColor: '#003d79',
    backgroundColor: '#ffffff',
    fontWeight: 600,
    cursor: 'pointer',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  messagebtn: {
    marginLeft: '5px',
    fontSizeAdjust: '13px',
    lineHeight: '24px',
    paddingLeft: '12px',
    paddingRight: '12px',
    color: '#003d79',
    borderColor: '#003d79',
    backgroundColor: '#ffffff',
    fontWeight: 600,
    cursor: 'pointer',
    textAlign: 'center',
    textTransform: 'capitalize',
    marginBottom: '12px',
  },
});
