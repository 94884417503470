import { createStyles} from "@material-ui/core";

export default (theme) =>
  createStyles({
    containerSty: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      borderWidth: 1,
      borderColor: "#003d79",
      border: "solid",
      backgroundColor: "#F7F8F5",
    },
    subContainer: {
      margin: theme.spacing(5),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#ffffff",
    },
    headingStyle: {
      marginTop: "20px",
      fontSize: "25px",
      fontWeight: 600,
      lineHeight: "30px",
      color: "#003d79",
    },
    buttonStyle: {
      padding: "2px",
      textTransform: "capitalize",
      color: "#ffffff",
      width: "350px",
      marginTop: "10px",
    },
  });