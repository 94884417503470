import { createStyles } from "@material-ui/core/styles";

export default createStyles({
    root: {
      padding: "20px",
    },
    addButton: {
      float: "left",
      color: "#ffffff",
      marginTop: "10px",
      width: "calc(50% - 15px)",
      marginRight: "15px",
      backgroundColor: "#003d79",
      padding: "2px",
    },
    input: {
      borderStyle: "solid",
      borderWidth: 1,
      width: "100%",
      borderColor: "#003d79",
      borderRadius: 5,
      paddingLeft: 5,
      paddingRight: 5,
    },
    textHead: {
      color: "#003d79",
      fontSize: "18px",
      lineHeight: "36px",
      fontWeight: 700,
    },
    subText: {
      color: "#a29061",
      fontSize: "14px",
      lineHeight: "24px",
      padding: "3px",
      // fontWeight: 700,
    },
    editorContainer: {
      width: "100%",
    },
  });