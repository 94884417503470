import React from 'react';
import './clinician.scss';
import SidebarClinician from './SidebarClinician';
import Drawer from '@material-ui/core/Drawer';
import SidebarMenu from './SidebarMenuClinician';
import useStyles from './Layout.styles';
import SessionExpireAlert from '../user/SessionExpireAlert';

export default function Layout({ ...props }) {
  const {
    children,
    appointments,
    filterAppointment,
    filterId,
    filterPatient,
    updatedAvatar,
  } = props;
  const classes = useStyles();
  return (
    <>
      {process.env.REACT_APP_ENV === 'production' && <SessionExpireAlert />}
      <SidebarClinician appointments={appointments} />
      <div className='main-content' style={{ minHeight: '100vh' }}>
        <Drawer
          className={classes.drawe}
          variant='permanent'
          classes={{
            paper: `${classes.drawerPaper} sideDrawer`,
          }}
          id='sideBar'
        >
          <SidebarMenu
            updatedAvatar={updatedAvatar}
            filterAppointment={filterAppointment}
            filterPatient={filterPatient}
            filterId={filterId}
          />
        </Drawer>
        {children}
      </div>
    </>
  );
}
