import React from 'react';
import InvoiceListItem from './InvoiceListItem';

const InvoiceList = ({ invoices, onClick, selectedInvoice, total }) => (
  <ul role='list' className>
    {invoices.map((invoice, i) => (
      <InvoiceListItem
        invoice={invoice}
        onClick={onClick}
        total={total(invoice)}
        selectedInvoice={selectedInvoice}
        key={`InvoiceListItem-${i}`}
      />
    ))}
  </ul>
);

export default InvoiceList;
