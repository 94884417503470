import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { StatusIndicator } from '../../../../components/atoms';
import { Text } from './style';

const AppointmentStatusCell = ({ status, date }) => {
  console.log('date', date);
  const generateText = () => {
    if (status === 'ended' || status === 'completed') {
      return status;
    }
    return 'missed';
  };
  return (
    <Grid alignItems='center' spacing={1} container>
      <Grid item>
        <StatusIndicator
          status={
            status === 'completed' || status === 'ended' ? 'active' : 'inactive'
          }
        />
      </Grid>
      <Grid item>
        <Text>{generateText()}</Text>
      </Grid>
    </Grid>
  );
};

export default AppointmentStatusCell;
