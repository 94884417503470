import {makeStyles} from '@material-ui/core/styles';


export default makeStyles((theme) => ({
    root: {
      width: "68px",
      height: "68px",
      borderRadius: "34px",
      border: "2px solid #ffffff",
    },
    clinician: {
      width: "98px",
      height: "98px",
      borderRadius: "49px",
      border: "2px solid #ffffff",
    },
  }));