import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import { useHistory } from 'react-router-dom';
import AlertMsg from '../utils/Alert';
import { baseURL } from '../utils/axios';

export default function PaypalPayment(props) {
  const [appointmentDetail, setAppointmentDetail] = useState(null);
  const [extra, setExtra] = useState(0);
  let history = useHistory();

  useEffect(() => {
    setAppointmentDetail(props.appointmentDetail);
  }, [props.appointmentDetail]);

  const onSuccess = (payment) => {
    // Congratulation, it came here means everything's fine!
    const data = {
      billingId: appointmentDetail.billingId._id,
      appointmentId: appointmentDetail._id,
      status: 'success',
      p_id: payment.id,
      metaData: payment,
      mode: 'paypal',
    };

    Axios.post(`${baseURL}/api/payment/paypal_success`, data, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          AlertMsg('success', 'Appointment booked');
          history.push(`/confirmation/${appointmentDetail._id}`);
        }
      })
      .catch((e) => {
        if (e.response.data.reason) {
          AlertMsg('error', e.response.data.reason);
        }
      });
    // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
  };

  const onCancel = (data) => {
    // User pressed "cancel" or close Paypal's popup!
    console.log('The payment was cancelled!', data);
    // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
  };

  const onError = (err) => {
    // The main Paypal's script cannot be loaded or somethings block the loading of that script!
    console.log('Error!', err);
    // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
    // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
  };

  let env = process.env.REACT_APP_ENV === 'stage' || process.env.REACT_APP_ENV === 'development'? "sandbox" : 'production'; // you can set here to 'production' for production
  let currency = 'USD'; // or you can set this value from your props or state

  const client = {
    sandbox:
      'AWHR0khgT3JtnCSoUhp5gcTTNTvV5zNZqjBDkK4zpFP42r3V6Pqz9lh8dFlPjmjrgY_LaYdGVwGE2qFu',
    production: 'AbsR8UBIy10KUppDVnNvKmbmuaeQrMyWs30YmDVaLLQVpcuyMzijhsCzxsu_rNeh-jo8Pd6OalcvRTNy',
  };
  // In order to get production's app-ID, you will have to send your app to Paypal for approval first
  // For sandbox app-ID (after logging into your developer account, please locate the "REST API apps" section, click "Create App"):
  //   => https://developer.paypal.com/docs/classic/lifecycle/sb_credentials/
  // For production app-ID:
  //   => https://developer.paypal.com/docs/classic/lifecycle/goingLive/

  // NB. You can also have many Paypal express checkout buttons on page, just pass in the correct amount and they will work!

  return (
    <div>
      {props.amount ? (
        <PaypalExpressBtn
          style={{
            size: 'large',
            color: 'gold',
            shape: 'pill',
            label: 'checkout',
            marginTop: '32px',
          }}
          env={env}
          client={client}
          currency={currency}
          total={Number(props.amount / 3.67).toFixed(2)}
          onError={onError}
          onSuccess={onSuccess}
          onCancel={onCancel}
        />
      ) : (
        <> </>
      )}
    </div>
  );
}
