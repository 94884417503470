import {makeStyles} from '@material-ui/core/styles';

export default makeStyles({
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      width: 20,
      height: 20,
      borderRadius: 15,
      border: "2px solid #304659",
      backgroundColor: "#fff",
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,0),hsla(0,0%,100%,0))",
      "$root.Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
      },
      "input:hover ~ &": {
        backgroundColor: "#ebf1f5",
      },
    },
    checkedIcon: {
      width: 18,
      height: 18,
      borderRadius: 10,
      border: "2px solid #fff",
      backgroundColor: "#304659",
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,0),hsla(0,0%,100%,0))",
      "&:before": {
        display: "block",
        width: 16,
        height: 16,
        content: '""',
      },
      "input:hover ~ &": {
        backgroundColor: "#304659",
      },
    },
  });