/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import '../../assets/css/loader.css';

export default function Loader(props) {
  // if (type) {
  return (
    <div className="lds_roller" style={props.cstmStyle ? props.cstmStyle : null}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
  // }
}
