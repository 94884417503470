import { Button } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import styled from 'styled-components';

const FilterBtn = styled(Button)`
  height: 49px;
  overflow: hidden;
  max-width: 47px;
  min-width: unset;
  margin-left: 12px;
`;

const AddCircleOutlineIcon = styled(AddCircleOutline)`
  marginright: 5;
`;

export { FilterBtn, AddCircleOutlineIcon };
