import {
  GET_ADDRESS_REQUEST,
  GET_ADDRESS_FAILED,
  GET_ADDRESS_SUCCESS,
  ADD_ADDRESS_REQUEST,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_FAILED,
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_FAILED,
} from "../constant";

import { put, takeEvery } from "redux-saga/effects";
import { get, post, updatePatch } from "../axios";

function* getAddress(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_ADDRESS_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_ADDRESS_FAILED, error });
  }
}

function* addAddress(action) {
  try {
    const { endpoint, body } = action;
    const response = yield post(endpoint, body);
    yield put({ type: ADD_ADDRESS_SUCCESS, response });
  } catch (e) {
    yield put({ type: ADD_ADDRESS_FAILED, e });
  }
}

function* updateAddress(action) {
  try {
    const { endpoint, body } = action;
    const response = yield updatePatch(endpoint, body);
    yield put({ type: UPDATE_ADDRESS_SUCCESS, response });
  } catch (e) {
    yield put({ type: UPDATE_ADDRESS_FAILED, e });
  }
}

export function* clinicianAddressSaga() {
  yield takeEvery(GET_ADDRESS_REQUEST, getAddress);
  yield takeEvery(ADD_ADDRESS_REQUEST, addAddress);
  yield takeEvery(UPDATE_ADDRESS_REQUEST, updateAddress);
}
