import {makeStyles} from '@material-ui/core/styles';


export default makeStyles((theme) => ({
    progressOuter: {
      width: "190px",
      height: "10px",
      backgroundColor: "#fff",
      border: "1px solid #304659",
      opacity: "10%",
      borderRadius: "25px",
      margin: "5px",
    },
    progressFont: {
      marginTop: "-4px",
      fontSize: "17px",
      lineHeight: "25px",
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: 700,
    },
    progressBarContainer: {
      [theme.breakpoints.up("xs")]: {
        marginLeft: "8px",
        marginTop: "2px",
      },
      [theme.breakpoints.up("sm")]: {
        marginLeft: "205px",
        marginTop: "2px",
      },
      [theme.breakpoints.up("md")]: {
        marginLeft: "490px",
      },
      [theme.breakpoints.up("lg")]: {
        marginLeft: "490px",
      },
    },
  }));