import { createStyles} from "@material-ui/core";

export default (theme) =>
 createStyles({
    root: {
      // display: 'flex',
      // flex: 1,
      // flexDirection: 'column',
      textAlign: "center",
      alignItems: "center",
      backgroundColor: "#ffffff",
      // boxShadow: '3px 2px 3px 2px gray',
      position: "absolute",
      top: "20%",
      bottom: "20%",
      left: "30%",
      right: "30%",
    },
    heading: {
      marginTop: "50px",
      fontSize: "25px",
      lineHeight: "30px",
      fontWeight: 600,
      textAlign: "center",
    },
    subHeading: {
      textAlign: "center",
      // width: '190px',
      height: "100px",
      fontSize: "16px",
      lineHeight: "24px",
      color: "gray",
      padding: "40px",
      // marginLeft: '150px',
      marginTop: "10px",
    },
    button: {
      textTransform: "capitalize",
      backgroundColor: "#003d79",
      color: "#ffffff",
      width: "200px",
    },
  });