import React from 'react';
import { DATE_FORMAT } from '../../../utils/timUtils';
import moment from 'moment';

const CheckoutAppointmentDate = ({ invoice }) => (
  <div className='pt-1'>
    <svg
      className='inline-block mr-1 w-4 h-4'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z'
      />
    </svg>
    <span className='text-gray-900'>Date:</span>{' '}
    {invoice?.appointmentId?.length > 0
      ? moment(invoice?.appointmentId[0].startTime).format(DATE_FORMAT)
      : moment(invoice?.createdAt).format(DATE_FORMAT)}
  </div>
);

export default CheckoutAppointmentDate;
