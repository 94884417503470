import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import dayGridPlugin from '@fullcalendar/daygrid';
import list from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import Layout from '../common/AdminLayout';
import ReactDOM from 'react-dom';
import { Container, Avatar, Badge } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { baseURL } from '../../../utils/axios';
import AlertMsg from '../../../utils/Alert';
import { getTimezoneOffset } from '../../../utils/timUtils';
import Axios from 'axios';
import moment from 'moment';
import FilterModalBox from '../../../clinician/FilterModalBox';
import CalenderCreateApointment from '../../../common/CalenderCreateNewAppointments';
import CancelIcon from '@material-ui/icons/Cancel';
import DoctorSearchView from '../../../common/DoctorSearchView';
import Loader from '../../../components/Loader/Loader';
import LinearProgress from '@material-ui/core/LinearProgress';
import ReplicateView from '../../../common/ReplicateView';
import { weekend } from '../../../utils/timUtils';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { slots, getEventColor } from '../../../common/calendar/slots';
import CalendarSlotSelectionView from '../../../common/calendar/CalendarSlotSelectionView';
import Popover from './Popover';
import tippy, { followCursor } from 'tippy.js';
import ReactDOMServer from 'react-dom/server';
import 'tippy.js/themes/light.css';

const CalendarWrapper = styled.div`
  table {
    width: ${(props) => props.width}px !important;
  }

  .fc-timegrid-body {
    width: ${(props) => props.width}px !important;
  }

  .fc .fc-view-harness-active > .fc-view {
    overflow: scroll !important;
  }

  table.fc-scrollgrid {
    width: 100%;
  }

  .fc-timegrid-event-harness-inset {
    inset-inline-start: 0% !important;
  }

  .fc-view-harness {
    overflow: auto;
  }
`;

function DoctorHeader(resource) {
  return (
    <Link
      to={`/admin/time-slots/${resource.resource._resource.extendedProps.clinicianId}`}
    >
      <Avatar
        style={{ margin: '8px auto', height: '32px', width: '32px' }}
        alt={resource.title}
        src={resource.resource._resource.extendedProps.imageUrl}
      />
      <div style={{ marginTop: '8px', textAlign: 'center' }}>
        {resource.resource._resource.title}
      </div>
    </Link>
  );
}

function App() {
  const calendarComponentRef = React.createRef();
  const [loader, setLoader] = useState(false);
  const [resources, setResources] = useState([]);
  const [events, setEvents] = useState([]);
  const [appointmentId, setAppointmentId] = useState('');
  const [openAppointmentDetails, setOpenAppointmentDetails] = useState(false);
  const [openCreateAppointment, setOpenCreateAppointment] = useState(false);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [selectedTime, setSelectedTime] = useState({});
  const [selectedDoctor, setDelectedDoctor] = useState();
  const [appointmentType, setAppointmentType] = useState('');
  const [openReplicate, setOpenReplicate] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState({});
  const [tableWidth, setTableWidth] = useState(791);
  const [selectAll, setSelectalll] = useState(false);
  const [divWidth, setDivWidth] = useState(791);
  const [legands, setLegands] = useState(slots);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [slotSelectionDialogOpen, setSlotSelectionDialogOpen] = useState(false);

  useEffect(() => {
    const draggableEl = document.getElementById('external-events');
    new Draggable(draggableEl, {
      itemSelector: '.fc-event1',
      eventData(eventEl) {
        const title = eventEl.getAttribute('title');
        const id = eventEl.getAttribute('data');

        return {
          title,
          id,
          color: getEventColor(title),
          type: title,
        };
      },
    });

    setDivWidth(document.getElementById('calendar').offsetWidth);
  }, []);

  useEffect(() => {
    getShedules();
    // setTableWidth(selectedDoctors.length * 150);
  }, [selectedDoctors]);

  const handleeventRecieve = (eventDropInfo) => {
    handleeventDrop(eventDropInfo);
  };

  const validateTime = (startTime, endTime) => {
    if (
      (moment(startTime).utcOffset(240) >= moment('00:00:00', 'hh:mm:ss') &&
        moment(startTime).utcOffset(240) < moment('06:00:00', 'hh:mm:ss')) ||
      (moment(startTime).utcOffset(240) >= moment('22:00:00', 'hh:mm:ss') &&
        moment(startTime).utcOffset(240) < moment('23:59:59', 'hh:mm:ss'))
    ) {
      return false;
    }

    if (
      moment(endTime).utcOffset(240) >= moment('22:00:00', 'hh:mm:ss') &&
      moment(endTime).utcOffset(240) < moment('23:59:59', 'hh:mm:ss')
    ) {
      return false;
    }

    return true;
  };

  const handleeventDrop = (eventDropInfo) => {
    var newEvents = [];

    const isTimeValid = validateTime(
      eventDropInfo.event.start,
      eventDropInfo.event.end
    );

    if (!isTimeValid) {
      AlertMsg(
        'error',
        'Start time can not lie between 12am - 6am and 10pm - 12am'
      );
      eventDropInfo.event.remove();
      return;
    }
    if (weekend.includes(moment(eventDropInfo.event.start).format('dddd'))) {
      AlertMsg('error', 'Cannot be booked for weekend');
      eventDropInfo.event.remove();
      return;
    }

    const eventInfo = {
      start: eventDropInfo.event.start,
      end: eventDropInfo.event.end,
      title: eventDropInfo.event.title,
      resourceId: parseInt(eventDropInfo.event._def.resourceIds[0]),
      type: eventDropInfo.event.title,
      id: eventDropInfo.event.id,
      color: getEventColor(eventDropInfo.event.title),
    };

    eventDropInfo.event.remove();

    const newEvent = Object.values(events).filter((event) => {
      if (event.id === eventInfo.id) {
        return event;
      }
    });

    console.log(newEvent);

    if (newEvent && newEvent.length === 0) {
      newEvents.push({ ...eventInfo });
    } else {
      newEvents = newEvent.map((event) => {
        const evnt =
          event.id === eventInfo.id
            ? {
                ...event,
                start: eventInfo.start,
                end: eventInfo.end,
                id: eventInfo.id,
                type: eventInfo.type,
                color: eventInfo.color,
              }
            : event;
        return evnt;
      });
    }

    if (eventInfo.type == 'Other' && newEvents.length > 0) {
      setSelectedSlot({
        event: newEvents[0],
        id: eventDropInfo.event._def.resourceIds[0],
      });
      setSlotSelectionDialogOpen(true);
      return;
    }

    addUpdateNewSchedules(
      parseInt(eventDropInfo.event._def.resourceIds[0]),
      newEvents
    );
  };

  const handleeventResize = (eventDropInfo) => {
    handleeventDrop(eventDropInfo);
  };

  const addUpdateNewSchedules = (resourceId, newEvents) => {
    let clinicianId = resources[resourceId].clinicianId;
    const data = newEvents.map((item) => {
      let end;
      if (item.end === null) {
        const today = new Date(item.start);
        today.setHours(today.getHours() + 1);
        end = new Date(today);
      } else {
        end = new Date(item.end);
      }
      return {
        type: item.type,
        color: getEventColor(item.type),
        startTime: new Date(item.start), // moment(item.start).tz("UTC").toISOString(true),
        endTime: end, // moment(item.end).tz("UTC").toISOString(true),
        appointmentDuration: 60,
        soloDate: new Date(item.start),
        id: item.id,
        clinicianId: clinicianId,
        client: item.client,
      };
    });
    setLoader(true);
    Axios.post(`${baseURL}/api/schedule/updateMultipleSchedules`, [...data], {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        // this.getShedules();
        let temp = { ...events };
        if (res.data && res.data.length) {
          for (const item of res.data) {
            const start = new Date(item.startTime);
            const end = new Date(item.endTime);

            // console.log("item.type ======", item.type);
            const obj = {
              start,
              end,
              title: item.type,
              type: item.type,
              id: item._id,
              color: getEventColor(item.type),
              clinicianId: clinicianId,
              resourceId: resourceId,
              client: item.client,
            };

            const newEvent = Object.values(events).filter((event) => {
              if (event.id === obj.id) {
                return event;
              }
            });

            if (newEvent && newEvent.length === 0) {
              temp[obj.id] = obj;
            } else {
              newEvent.forEach((newEvent) => {
                temp[newEvent.id] = {
                  ...events[newEvent.id],
                  start: obj.start,
                  clinicianId: clinicianId,
                  end: obj.end,
                  id: obj.id,
                  type: obj.type,
                  resourceId: resourceId,
                  color: getEventColor(obj.type),
                  textColor:
                    obj.type === 'Appointment' || obj.type === 'Blocked'
                      ? '#fff'
                      : '#000',
                  client: obj.client,
                };
              });
            }
          }
          setEvents(temp);
          setLoader(false);
        }
      })
      .catch((e) => {
        setLoader(false);
        if (e.response.data.reason) {
          AlertMsg('error', e.response.data.reason);
        }
      });
  };

  const getShedules = () => {
    if (selectedDoctors.length == 0) {
      setResources([]);
      setEvents([]);
      return;
    }

    const dated = moment().format('L');

    setLoader(true);
    Axios.get(
      `${baseURL}/api/schedule/get?id=${selectedDoctors}&timezoneOffset=${getTimezoneOffset()}&start=${dated}&end=${dated}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        if (res.data && res.data.length) {
          const resourcesList = [];
          let eventsList = {};
          const totalClinicians = res.data.length;

          setTableWidth(totalClinicians * 150);

          res.data.forEach((item, itemIndex) => {
            const resource = {
              id: itemIndex,
              title:
                (item.prefix ? item.prefix + ' ' : '') +
                item.firstName +
                ' ' +
                item.lastName +
                (item.suffix ? ', ' + item.suffix : ''),
              imageUrl: item.avatar,
              clinicianId: item._id,
              width: '200px',
            };
            item.events.forEach((event, index) => {
              let evtData = {
                resourceId: itemIndex,
                title: event.title,
                start: event.start,
                end: event.end,
                //index: `evt-${index}`,
                clinicianId: item._id,
                id: event._id,
                color: getEventColor(event.type),
                type: event.type,
                textColor:
                  event.type === 'Appointment' || event.type === 'Blocked'
                    ? '#fff'
                    : '#000',
                client: event.client,
              };
              // eventsList.push(evtData)
              eventsList[evtData.id] = evtData;
            });
            resourcesList.push(resource);
          });
          setResources(resourcesList);
          setEvents(eventsList);
          // this.setState({ calendarEvents: scheduleList, newEvents: scheduleList });
        }
        setLoader(false);
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const eventClicked = (eventClickInfo, event) => {
    if (eventClickInfo.event._def.extendedProps.type != 'Appointment') {
      setSelectedBlock({
        calenderSlotId: eventClickInfo.event.id,
        clinicianId: eventClickInfo.event._def.extendedProps.clinicianId,
        startTime: eventClickInfo.event.start,
        endTime: eventClickInfo.event.end,
        type: eventClickInfo.event._def.extendedProps.type,
      });
      setOpenReplicate(true);
    }
  };

  const getCalendarData = async (fetchInfo) => {
    try {
      let start = moment(fetchInfo.start).format('MM/DD/YYYY');
      let end = moment(fetchInfo.end).format('MM/DD/YYYY');
      console.log(fetchInfo.start, fetchInfo.end);
      if (!selectedDoctors || selectedDoctors.length === 0) {
        return [];
      }

      var url = `${baseURL}/api/schedule/get?id=${selectedDoctors}&timezoneOffset=${getTimezoneOffset()}`;

      if (start) {
        url = url + `&start=${start}`;
      }

      if (end) {
        url = url + `&end=${end}`;
      }

      let res = await Axios.get(url, {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      });

      if (!res.data) {
        console.log('sadasdasd');
        return;
      }

      const sortedList = res.data.sort(function (a, b) {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
          return -1;
        }
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const resourcesList = [];
      let eventsList = [];
      sortedList.forEach((item, itemIndex) => {
        const resource = {
          id: itemIndex,
          title: item.firstName + ' ' + item.lastName,
          imageUrl: item.avatar,
          clinicianId: item._id,
        };
        item.events.forEach((event, index) => {
          let evtData = {
            resourceId: itemIndex,
            title: event.title,
            start: event.start,
            end: event.end,
            index: `evt-${index}`,
            clinicianId: item._id,
            id: event._id,
            color: getEventColor(event.type),
            type: event.type,
            textColor:
              event.type === 'Appointment' || event.type === 'Blocked'
                ? '#fff'
                : '#000',
            client: event.client,
          };
          eventsList.push(evtData);
        });
        resourcesList.push(resource);
      });
      console.log(eventsList);
      setEvents(eventsList);

      //successCallback(eventsList);
    } catch (error) {
      console.log(error);
      //failureCallback([]);
    }
  };

  const handleCreateClose = () => {
    setOpenCreateAppointment(false);
  };

  const renderEventContent = (info) => {
    return <div>{info.event.title}</div>;
  };

  var instance = null;
  const handleMouseEnter = (arg) => {
    if (
      !['All-Patient', 'New-Patient', 'Current-Patient'].includes(
        arg.event._def.extendedProps.type
      )
    ) {
      if (instance) {
        instance.destroy();
      }

      instance = tippy(arg.el, {
        content: ReactDOMServer.renderToStaticMarkup(
          <Popover
            {...arg.event._def.extendedProps}
            start={arg.event.start}
            end={arg.event.end}
          />
        ),
        strategy: 'fixed',
        allowHTML: true,
        placement: 'left',
        interactive: true,
        theme: 'light',
        zIndex: 999999999,
        followCursor: true,
        plugins: [followCursor],
      });
    }
  };

  return (
    <>
      {loader && <LinearProgress />}
      <DoctorSearchView
        selectAll={selectAll}
        onChange={(data) => {
          setSelectedDoctors(data);
        }}
      />
      <br />
      <div className='calendarMain' style={{ marginBottom: '16px' }}>
        <div id='external-events' className='calendarBtns'>
          <h5>Calendar Slot Legends</h5>
          <br />
          <ul>
            {legands.map((event) => (
              <li
                className='fc-event1'
                title={event.title}
                data={event.id}
                key={event.id}
              >
                {event.title}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div id='calendar'>
        <CalendarWrapper width={divWidth > tableWidth ? divWidth : tableWidth}>
          <FullCalendar
            schedulerLicenseKey={process.env.REACT_APP_FULL_CALENDAR_KEY}
            headerToolbar={{
              left: 'prev,next',
              center: 'title',
              right: 'selectAll',
            }}
            customButtons={{
              selectAll: {
                text: 'All Clinicians',
                click: function () {
                  setSelectalll(true);
                },
              },
            }}
            eventResize={handleeventResize}
            eventReceive={handleeventRecieve}
            eventDrop={handleeventDrop}
            defaultView='resourceTimeGridDay'
            initialView='resourceTimeGridDay'
            rerenderDelay={0}
            eventDurationEditable
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              list,
              interactionPlugin,
              timelinePlugin,
              resourceTimeGridPlugin,
            ]}
            resources={resources}
            resourceLabelText={'Doctors'}
            events={Object.values(events)}
            datesSet={(dateInfo) => {
              // console.log(dateInfo.start) //start of the range the calendar date
              // console.log(dateInfo.end) //end of the range the calendar date
              getCalendarData({
                start: dateInfo.start,
                end: dateInfo.end,
              });
            }}
            resourceLabelContent={DoctorHeader}
            ref={calendarComponentRef}
            editable
            selectable
            eventOverlap={false}
            eventClick={eventClicked}
            slotMinTime='06:00:00'
            slotMaxTime='22:00:00'
            contentHeight='auto'
            eventMouseEnter={handleMouseEnter}
            draggable
            droppable
            allDaySlot={false}
            nowIndicator
          />
        </CalendarWrapper>
      </div>
      {openCreateAppointment && (
        <CalenderCreateApointment
          selectedTime={selectedTime}
          open={openCreateAppointment}
          key={new Date().getTime()}
          appointmentDetail={appointmentType}
          handleClose={handleCreateClose}
          handleSubmit={() => {
            handleCreateClose();
            getShedules();
          }}
        />
      )}
      {openReplicate && (
        <ReplicateView
          handleSubmit={() => {
            setOpenReplicate(false);
            getShedules();
          }}
          selectedBlock={selectedBlock}
          open={openReplicate}
          close={() => {
            setOpenReplicate(false);
          }}
          getShedules={() => getShedules()}
        />
      )}

      <CalendarSlotSelectionView
        open={slotSelectionDialogOpen}
        handleClose={() => setSlotSelectionDialogOpen(false)}
        selectedSlot={selectedSlot && selectedSlot.event}
        handleSubmit={(data) => {
          console.log(data);
          addUpdateNewSchedules(selectedSlot.id, [data]);
          setSlotSelectionDialogOpen(false);
        }}
      />
    </>
  );
}

export default App;
