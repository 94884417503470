import React from 'react';

const BtnLinkStandard = (props) => (
  <button
    onClick={props.onClick}
    type="button"
    className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white-100 hover:bg-brand-blue hover:text-white ${props.className}`}
  >
    {props.children}
  </button>
);

export default BtnLinkStandard;
