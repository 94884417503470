import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import dayGridPlugin from '@fullcalendar/daygrid';
import list from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import Layout from '../common/AdminLayout';
import Loader from '../../../components/Loader/Loader';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ReactDOM } from 'react';
import { Container, Avatar, Badge } from '@material-ui/core';
import { baseURL } from '../../../utils/axios';
import { getTimezoneOffset } from '../../../utils/timUtils';
import Axios from 'axios';
import moment from 'moment';
import AppointmentDetailsView from '../../../clinician/AppointmentDetailsView';
import CalenderCreateApointment from '../../../common/CalenderCreateNewAppointments';
import CancelIcon from '@material-ui/icons/Cancel';
import DoctorSearchView from '../../../common/DoctorSearchView';
import { Link } from 'react-router-dom';
import CalendarWrapper from './style';
import tippy, { followCursor } from 'tippy.js';
import ReactDOMServer from 'react-dom/server';
import userIcon from '../../../assets/imgs/download.png';
import 'tippy.js/themes/light.css';
import './style.css';
import {
  getEventColor,
  startEndTimeCalender,
} from '../../../common/calendar/slots';
import Popover from './Popover';

function DoctorHeader(resource) {
  return (
    <Link
      to={`/admin/calendar/${resource.resource._resource.extendedProps.clinicianId}`}
    >
      <Avatar
        style={{ margin: '8px auto', height: '32px', width: '32px' }}
        alt={resource.title}
        src={resource.resource._resource.extendedProps.imageUrl || userIcon}
      />
      <div style={{ marginTop: '8px', textAlign: 'center' }}>
        {resource.resource._resource.title}
      </div>
    </Link>
  );
}

function App() {
  const calendarComponentRef = React.createRef();
  const [loader, setLoader] = useState(false);
  const [resources, setResources] = useState([]);
  const [events, setEvents] = useState([]);
  const [appointmentId, setAppointmentId] = useState('');
  const [openAppointmentDetails, setOpenAppointmentDetails] = useState(false);
  const [openCreateAppointment, setOpenCreateAppointment] = useState(false);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [selectedTime, setSelectedTime] = useState({});
  const [selectedDoctor, setDelectedDoctor] = useState();
  const [appointmentType, setAppointmentType] = useState('');
  const [selectAll, setSelectalll] = useState(false);
  const [divWidth, setDivWidth] = useState(791);
  const [tableWidth, setTableWidth] = useState(791);
  const [patientType, setPatientType] = useState('');

  useEffect(() => {
    getShedules();
    // setTableWidth(selectedDoctors.length * 150);
  }, [selectedDoctors]);

  useEffect(() => {
    if (document.getElementById('popover-button') != null) {
      document
        .getElementById('popover-button')
        .addEventListener('click', function () {});
    }
    setDivWidth(document.getElementById('calendar').offsetWidth);
  }, []);

  const getShedules = () => {
    if (selectedDoctors.length === 0) {
      setResources([]);
      setEvents([]);
      return;
    }

    const dated = moment().format('L');

    setLoader(true);
    Axios.get(
      `${baseURL}/api/schedule/get?id=${selectedDoctors}&timezoneOffset=${getTimezoneOffset()}&start=${dated}&end=${dated}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        if (res.data && res.data.length) {
          const sortedList = res.data.sort(function (a, b) {
            if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
              return -1;
            }
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
              return 1;
            }
            return 0;
          });

          const resourcesList = [];
          let eventsList = [];
          const totalClinicians = res.data.length;
          setTableWidth(totalClinicians * 150);

          sortedList.forEach((item, itemIndex) => {
            const resource = {
              id: itemIndex,
              title: item.firstName + ' ' + item.lastName,
              imageUrl: item.avatar,
              clinicianId: item._id,
            };
            item.events.forEach((event, index) => {
              let evtData = {
                resourceId: itemIndex,
                title: event.title,
                start: event.start,
                end: event.end,
                index: `evt-${index}`,
                clinicianId: item._id,
                id: event._id,
                color: getEventColor(event.type),
                type: event.type,
                location: event.appointmentLocation,
                textColor:
                  event.type === 'Appointment' || event.type === 'Blocked'
                    ? '#fff'
                    : '#000',
              };
              eventsList.push(evtData);
            });
            resourcesList.push(resource);
          });
          setResources(resourcesList);
          setEvents(eventsList);
          // this.setState({ calendarEvents: scheduleList, newEvents: scheduleList });
        }
        setLoader(false);
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const eventClicked = (eventClickInfo, event) => {
    setAppointmentType({
      type: eventClickInfo.event._def.extendedProps.type,
      clinicianId: eventClickInfo.event._def.extendedProps.clinicianId,
      // calenderSlotId: eventClickInfo.event.id,
    });
    setDelectedDoctor(eventClickInfo.event._def.extendedProps.clinicianId);
    setSelectedTime({
      start: eventClickInfo.event.start,
      end: eventClickInfo.event.end,
    });

    if (
      eventClickInfo.event.title == 'Current-Patient' ||
      eventClickInfo.event.title == 'New-Patient' ||
      eventClickInfo.event.title == 'All-Patient'
    ) {
      setPatientType(eventClickInfo.event.title);
      setOpenCreateAppointment(true);
    }

    if (eventClickInfo.event._def.extendedProps.type == 'Appointment') {
      setOpenAppointmentDetails(true);
      setAppointmentId(eventClickInfo.event.id);
    }
  };

  const getCalendarData = async (
    fetchInfo,
    successCallback,
    failureCallback
  ) => {
    try {
      let start = moment(fetchInfo.start).format('MM/DD/YYYY');
      let end = moment(fetchInfo.end).format('MM/DD/YYYY');

      if (!selectedDoctors || selectedDoctors.length === 0) {
        return [];
      }

      var url = `${baseURL}/api/schedule/get?id=${selectedDoctors}&timezoneOffset=${getTimezoneOffset()}`;

      if (start) {
        url = url + `&start=${start}`;
      }

      if (end) {
        url = url + `&end=${end}`;
      }

      let res = await Axios.get(url, {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      });

      if (!res.data) {
        failureCallback([]);
        return;
      }

      const sortedList = res.data.sort(function (a, b) {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
          return -1;
        }
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const resourcesList = [];
      let eventsList = [];
      sortedList.forEach((item, itemIndex) => {
        const resource = {
          id: itemIndex,
          title: item.firstName + ' ' + item.lastName,
          imageUrl: item.avatar,
          clinicianId: item._id,
        };
        item.events.forEach((event, index) => {
          let evtData = {
            resourceId: itemIndex,
            title: event.title,
            start: event.start,
            end: event.end,
            index: `evt-${index}`,
            clinicianId: item._id,
            id: event._id,
            color: getEventColor(event.type),
            type: event.type,
            location: event.appointmentLocation,
            client: event.client,
            duration: event.duration,
            textColor:
              event.type === 'Appointment' || event.type === 'Blocked'
                ? '#fff'
                : '#000',
          };
          eventsList.push(evtData);
        });
        resourcesList.push(resource);
      });

      successCallback(eventsList);
    } catch (error) {
      console.log(error);
      failureCallback([]);
    }
  };

  const handleCreateClose = () => {
    setOpenCreateAppointment(false);
  };

  var instance = null;
  const handleMouseEnter = (arg) => {
    console.log(
      'arg.event._def.extendedProps.type',
      arg.event._def.extendedProps.type
    );
    if (
      !['All-Patient', 'New-Patient', 'Current-Patient'].includes(
        arg.event._def.extendedProps.type
      )
    ) {
      if (instance) {
        instance.destroy();
      }
      console.log('arg.event.start', moment(arg.event.start).format('hh:mm a'));
      instance = tippy(arg.el, {
        content: ReactDOMServer.renderToStaticMarkup(
          <Popover
            {...arg.event._def.extendedProps}
            start={arg.event.start}
            end={arg.event.end}
          />
        ),
        allowHTML: true,
        placement: 'left',
        interactive: true,
        theme: 'light',
        followCursor: true,
        plugins: [followCursor],
      });
    }
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div>
        <div>{eventInfo.event.title}</div>
        <div></div>
        <div className='flex'>
          {eventInfo.event._def.extendedProps.location && (
            <div className='pr-2'>
              <b>{eventInfo.event._def.extendedProps.location}</b>
            </div>
          )}
          <div>{eventInfo.timeText}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      {loader && <LinearProgress />}
      <DoctorSearchView
        selectAll={selectAll}
        onChange={(data) => {
          setSelectedDoctors(data);
        }}
      />
      <br />
      <div id='calendar'>
        <CalendarWrapper width={divWidth > tableWidth ? divWidth : tableWidth}>
          <FullCalendar
            schedulerLicenseKey={process.env.REACT_APP_FULL_CALENDAR_KEY}
            headerToolbar={{
              left: 'prev,next',
              center: 'title',
              right: 'selectAll',
            }}
            customButtons={{
              selectAll: {
                text: 'All Clinicians',
                click: function () {
                  setSelectalll(true);
                },
              },
            }}
            defaultView='resourceTimeGridDay'
            initialView='resourceTimeGridDay'
            dateIncrement={(date) => console.log(date)}
            rerenderDelay={0}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              list,
              interactionPlugin,
              timelinePlugin,
              resourceTimeGridPlugin,
            ]}
            resources={resources}
            resourceLabelText={'Doctors'}
            events={getCalendarData}
            resourceLabelContent={DoctorHeader}
            ref={calendarComponentRef}
            eventAdd={(event) => console.log(event)}
            selectable
            disableResizing={true}
            draggable={false}
            disableDragging={true}
            eventOverlap={false}
            eventClick={eventClicked}
            allDaySlot={false}
            nowIndicator
            eventContent={renderEventContent}
            eventMouseEnter={handleMouseEnter}
            eventMouseLeave={handleMouseEnter}
            slotMinTime={startEndTimeCalender().start}
            slotMaxTime={startEndTimeCalender().end}
            contentHeight='auto'
          />
        </CalendarWrapper>
      </div>
      {openAppointmentDetails && (
        <AppointmentDetailsView
          appointmentId={appointmentId}
          handleClose={() => setOpenAppointmentDetails(false)}
          open={openAppointmentDetails}
          handleInitReferralClick={() => {}}
          handleEditRecord={() => {}}
          refresh={() => {
            getShedules();
          }}
        />
      )}
      {openCreateAppointment && (
        <CalenderCreateApointment
          selectedTime={selectedTime}
          open={openCreateAppointment}
          key={new Date().getTime()}
          appointmentDetail={appointmentType}
          patientType={patientType}
          handleClose={handleCreateClose}
          handleSubmit={() => {
            handleCreateClose();
            getShedules();
          }}
          hideClinician
        />
      )}
    </>
  );
}

export default App;
