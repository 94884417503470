import {makeStyles, withStyles} from '@material-ui/core/styles';

export default makeStyles({
    toolbar: {
        width: '100%',
        height: '100px',
        backgroundColor: '#003265',
    },
    root: {
        maxWidth: 255,
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    inputstyle: {
        backgroundColor: '#fff',
    },
    table: {
        minWidth: 650,
    },
    imgTable: {
        width: '40px',
        height: '40px',
        objectFit: 'contain',
        borderRadius: '50px',
    },
    viewBtn: {
        borderRadius: '15px',
        width: '100px',
        padding: '4px',
        boxShadow: 'none',
        textTransform: 'capitalize'
    }
});
