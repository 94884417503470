/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import {
  GET_PAYMENT_URL_REQUEST,
  GET_PAYMENT_URL_SUCCESS,
  GET_PAYMENT_URL_FAILED,
  GET_PAYMENT_RES_REQUEST,
  GET_PAYMENT_RES_SUCCESS,
  GET_PAYMENT_RES_FAILED,
  PAYPAL_REQUEST,
  PAYPAL_SUCCESS,
  PAYPAL_FAILED,
  eCHECK_REQUEST,
  eCHECK_SUCCESS,
  eCHECK_FAILED,
  GET_UNPAID_PAYMENT_REQUEST,
  GET_UNPAID_PAYMENT_SUCCESS,
  GET_UNPAID_PAYMENT_FAILED,
  GET_PAYMENT_REQUEST,
  GET_PAYMENT_SUCCESS,
  GET_PAYMENT_FAILED,
  MARK_PAYMENT_AS_PAID_REQUEST,
  MARK_PAYMENT_AS_PAID_SUCCESS,
  MARK_PAYMENT_AS_PAID_FAILED,
  MARK_PAYMENT_AS_PAID_CLEAR,
} from '../constant';

export const getPaymentUrl = (state = [], action) => {
  switch (action.type) {
    case GET_PAYMENT_URL_REQUEST:
      return {
        request: true,
      };

    case GET_PAYMENT_URL_SUCCESS:
      return {
        request: false,
        getPaymentUrlData: action.response,
      };

    case GET_PAYMENT_URL_FAILED:
      return {
        request: false,
        error: true,
      };

    default:
      return state;
  }
};

export const getPaymentRes = (state = [], action) => {
  switch (action.type) {
    case GET_PAYMENT_RES_REQUEST:
      return {
        request: true,
      };

    case GET_PAYMENT_RES_SUCCESS:
      return {
        request: false,
        getPaymentResData: action.response,
      };

    case GET_PAYMENT_RES_FAILED:
      return {
        request: false,
        error: true,
      };

    default:
      return state;
  }
};

export const paypal = (state = [], action) => {
  switch (action.type) {
    case PAYPAL_REQUEST:
      return {
        request: true,
      };

    case PAYPAL_SUCCESS:
      return {
        request: false,
        paypalData: action.response,
      };

    case PAYPAL_FAILED:
      return {
        request: false,
        error: true,
      };

    default:
      return state;
  }
};

export const eCheck = (state = [], action) => {
  switch (action.type) {
    case eCHECK_REQUEST:
      return {
        request: true,
      };

    case eCHECK_SUCCESS:
      return {
        request: false,
        eCheckData: action.response,
      };

    case eCHECK_FAILED:
      return {
        request: false,
        error: true,
      };

    default:
      return state;
  }
};

export const getUnpaidPaymentAction = (state = [], action) => {
  switch (action.type) {
    case GET_UNPAID_PAYMENT_REQUEST:
      return {
        request: true,
      };
    case GET_UNPAID_PAYMENT_SUCCESS:
      return {
        request: false,
        getUnpaidPaymentActionData: action.response,
      };
    case GET_UNPAID_PAYMENT_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getPaymentAction = (state = [], action) => {
  switch (action.type) {
    case GET_PAYMENT_REQUEST:
      return {
        request: true,
      };
    case GET_PAYMENT_SUCCESS:
      return {
        request: false,
        getPaymentActionData: action.response,
      };
    case GET_PAYMENT_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const markInvoiceAsPaid = (state = {}, action) => {
  switch (action.type) {
    case MARK_PAYMENT_AS_PAID_REQUEST:
      return {
        request: true,
      };
    case MARK_PAYMENT_AS_PAID_SUCCESS:
      return {
        request: false,
        data: action.response,
      };
    case MARK_PAYMENT_AS_PAID_FAILED:
      return {
        request: false,
        error: true,
      };
    case MARK_PAYMENT_AS_PAID_CLEAR:
      return {
        data: null,
      };
    default:
      return state;
  }
};
