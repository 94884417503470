import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { DialogContent } from '@material-ui/core';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { baseURL } from '../../../../utils/axios';
import axios from 'axios';
import AlertMsg from '../../../../utils/Alert';
import { userLogin } from '../../../../utils/actions/auth.action';
import { Spinner } from '../../../../components/atoms';
import { connect } from 'react-redux';

function VerificationDialog(props) {
  // const classes = useStyles();
  const {
    open,
    onClose,
    email,
    verifyOTP,
    userLogin,
    history,
    classes,
    otp,
    setOTP,
  } = props;

  const [loading, setLoading] = useState(false);

  const openResetPasswordDialog = () => {
    setLoading(true);
    axios
      .put(`${baseURL}/api/client/verify_otp`, { email, otp })
      .then((res) => {
        setLoading(false);
        if (res.data.verify) {
          onClose(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        AlertMsg('error', err.response.data.reason);
      });
  };

  const resendOTP = () => {
    setLoading(true);
    axios
      .put(`${baseURL}/api/client/resend_otp`, { email })
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        AlertMsg('error', err.response.data.reason);
      });
  };

  return (
    <div>
      <Spinner isOpen={loading} />
      <Dialog
        aria-labelledby='simple-dialog-title'
        open={open}
        onClose={() => onClose(false)}
      >
        <DialogContent>
          <Grid style={{ width: '300px' }}>
            <Typography className={classes.otpTxt}>Verification </Typography>
            <Typography className={classes.txt}>
              Enter the OTP code we sent to your email address.
            </Typography>
            <Grid className={classes.inputTxt}>
              <TextField
                id='outlined-basic'
                variant='outlined'
                onChange={(e) => setOTP(e.target.value)}
              />
            </Grid>
            <Typography className={classes.txt2}>
              Did not get it?
              <Button
                onClick={resendOTP}
                style={{ textDecorationLine: 'underline' }}
              >
                Resend
              </Button>
            </Typography>
            <Grid
              container
              alignItems='center'
              justify='center'
              onClick={openResetPasswordDialog}
              className={classes.buttonOtp}
            >
              <Typography className={classes.buttonText}>
                Verify OTP Now
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default VerificationDialog;
