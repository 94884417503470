import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { DashboardHeader } from '../../../components/molecules';
import { Table, RectangledLogo, Spinner } from '../../../components/atoms';
import { getClinicianAction } from '../../../utils/actions/clinician.action';
import { getClients } from '../../../utils/actions/client.action';
import {
  getAllBundles,
  cancelSubscripton,
} from '../../../utils/actions/bundle.action';
import { Content, PaginationGrid } from './style';
import PaymentStatusCell from './payment_status_cell';
import {
  ShowBundle,
  CreateBundle,
  EditBundle,
  CancelConfirmationDialog,
} from './manage_bundle';
import { getPageCount } from '../../../utils/MathUtil';
import { Pagination } from '@material-ui/lab';

const HEADER_DROPDOWN_OPTIONS = [
  { key: 'active', value: 'Active' },
  { key: 'inactive', value: 'Inactive' },
  { key: 'refunded', value: 'Refunded' },
  { key: 'paid', value: 'Paid' },
  { key: 'unpaid', value: 'Unpaid' },
];

const headers = {
  avatar: {
    title: 'CLIENT',
    concat: true,
    concatOp: ' ',
    prefix: 'clientId',
    fields: ['firstName', 'lastName'],
    isImageWithText: true,
  },
  logo: {
    title: 'CLINICIAN',
    concat: true,
    concatOp: ' ',
    prefix: 'clinicianId',
    fields: ['prefix', 'firstName', 'lastName', 'suffix'],
    isImageWithText: true,
  },
  expirationDate: { title: 'DATE CREATED', isSortActive: true },
  amount: {
    title: 'PRICE(AED)',

    isSortActive: true,
  },
  numberOfSessions: {
    title: '# OF SESSIONS',
    isSortActive: true,
  },
  numberOfUsedSession: {
    title: '# OF USED SESSIONS',
    isSortActive: true,
    default: 0,
  },

  paymentStatus: {
    title: 'Payment Status',
    component: PaymentStatusCell,
    conditionalComponent: true,
    propFields: [{ status: 'paymentStatus' }],
    hidden: false,
  },
};

const PAYMENT_ENUM = { paid: 'Paid', init: 'Unpaid', unpaid: 'Unpaid' };

const formatUser = (users) => {
  const formattedUser = users.map((user) => ({
    ...user,
    name: `${user.firstName} ${user.lastName}`,
    prefix: user.prefix ? user.prefix : '',
    suffix: user.suffix ? ', ' + user.suffix : '',
  }));
  return formattedUser[0];
};
const Listing = ({
  history,
  getClinicians,
  cliniciansList,
  getClients,
  clientsList,
  getAllBundles,
  loading,
  fetchedBundles,
  count,
  error,
  cancelledSuccessfully,
  cancelSubscripton,
  modifiedBundle,
  newlyCreated,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [serviceBundles, setServiceBundles] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy, setSortBy] = useState('');
  const [searchText, setSearchText] = useState('');
  const [sortType, setSortType] = useState('desc');
  const [bundleStatus, setBundleStatus] = useState('active');
  const [showBundleDrawer, setShowBundleDrawer] = useState(false);
  const [createBundleDrawer, setCreateBundleDrawer] = useState(false);
  const [editBundleDrawer, setEditBundleDrawer] = useState(false);
  const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false);
  const [clinicians, setClinicians] = useState([]);
  const [clients, setClients] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [selectedBundle, setSelectedBundle] = useState(null);
  const [cancellingBundle, setCancellingBundle] = useState(null);
  const [tableHeader, setTableHeader] = useState(headers);

  const searchByText = (e) => {
    setPage(1);
    setSearchText(e.target.value);
  };

  useEffect(() => {
    getClinicians('');
    getClients('');
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setBundles([]);
    getAllBundles(page, sortType, sortBy, searchText, bundleStatus);
  }, [page, sortType, sortBy, searchText, bundleStatus]);

  useEffect(() => {
    if (cliniciansList && cliniciansList.length > 0) {
      const formattedClincians = cliniciansList.map((clinician) => ({
        _id: clinician._id,
        name: `${clinician.prefix ? clinician.prefix : ''} ${
          clinician.firstName
        } ${clinician.lastName} ${
          clinician.suffix ? ', ' + clinician.suffix : ''
        }`,
        email: clinician.email,
        avatar: clinician.avatar,
        prefix: clinician.prefix ? clinician.prefix : '',
        suffix: clinician.suffix ? ', ' + clinician.suffix : '',
      }));
      setClinicians([...formattedClincians]);
    }
  }, [cliniciansList]);

  useEffect(() => {
    if (clientsList && clientsList.length > 0) {
      const formattedClients = clientsList.map((client) => ({
        _id: client._id,
        name: `${client.firstName} ${client.lastName}`,
        avatar: client.avatar,
        email: client.email,
      }));

      setClients([...formattedClients]);
    }
  }, [clientsList]);

  useEffect(() => {
    if (fetchedBundles) {
      const formattedBundle = fetchedBundles.map((bundle) => ({
        ...bundle,
        clientId: formatUser(bundle.clientId),
        clinicianId: formatUser(bundle.clinicianId),
        cancellingUser: formatUser(bundle.cancellingUser),
        billingId: bundle.billingId[0],
        expirationDate: moment(new Date(bundle.expirationDate)).format('LL'),
        paymentStatus:
          bundle.billingId &&
          bundle.billingId[0] &&
          PAYMENT_ENUM[bundle.billingId[0].status],
        status: bundle.status,
      }));

      setBundles(formattedBundle);
      setTotalPages(count);
      setIsLoading(false);
    }
  }, [fetchedBundles, loading]);

  useEffect(() => {
    if (cancelledSuccessfully && !loading) {
      const updatedBundles = bundles.filter(
        (bundle) => bundle._id !== cancellingBundle._id
      );
      setBundles([...updatedBundles]);
      setCancellingBundle(null);
      setOpenCancelConfirmation(false);
      setEditBundleDrawer(false);
    }
  }, [cancelledSuccessfully, loading]);

  useEffect(() => {
    if (modifiedBundle) {
      const formattedModifiledBundle = {
        ...modifiedBundle,
        clientId: formatUser(modifiedBundle.clientId),
        clinicianId: formatUser(modifiedBundle.clinicianId),
        cancellingUser: formatUser(modifiedBundle.cancellingUser),
        billingId: modifiedBundle.billingId[0],
        expirationDate: moment(new Date(modifiedBundle.expirationDate)).format(
          'LL'
        ),
        paymentStatus:
          modifiedBundle.billingId &&
          modifiedBundle.billingId[0] &&
          PAYMENT_ENUM[modifiedBundle.billingId[0].status],
      };

      const replacePrev = bundles.map((bundle) =>
        bundle._id === formattedModifiledBundle._id
          ? formattedModifiledBundle
          : bundle
      );

      setBundles(replacePrev);
    }
  }, [modifiedBundle]);

  useEffect(() => {
    if (newlyCreated) {
      const formattedNewBundle = {
        ...newlyCreated,
        clientId: formatUser(newlyCreated.clientId),
        clinicianId: formatUser(newlyCreated.clinicianId),
        cancellingUser: formatUser(newlyCreated.cancellingUser),
        billingId: newlyCreated.billingId[0],
        expirationDate: moment(new Date(newlyCreated.expirationDate)).format(
          'LL'
        ),
        paymentStatus:
          newlyCreated.billingId &&
          newlyCreated.billingId[0] &&
          PAYMENT_ENUM[newlyCreated.billingId[0].status],
      };

      setBundles([formattedNewBundle, ...bundles]);
    }
  }, [newlyCreated]);

  const handlePageChange = (e, value) => {
    window.scrollTo(0, 100);
    setPage(value);
  };

  const createNewBundle = () => setCreateBundleDrawer(true);
  const handleBundleStatusChange = (e) => {
    if (e.target.value === 'active') {
      delete headers['status'];
      headers['paymentStatus'] = {
        title: 'Payment Status',
        component: PaymentStatusCell,
        conditionalComponent: true,
        propFields: [{ status: 'paymentStatus' }],
      };
    } else {
      delete headers['paymentStatus'];
      headers['status'] = {
        title: 'Status',
        component: PaymentStatusCell,
        conditionalComponent: true,
        propFields: [{ status: 'status' }],
      };
    }

    setTableHeader(headers);

    setPage(1);
    setBundleStatus(e.target.value);
  };
  const sort = (keyName) => {
    if (keyName === sortBy) {
      const toggledOrder = sortType === 'desc' ? 'asc' : 'desc';
      setSortType(toggledOrder);
    }

    setSortBy(keyName);
  };

  const openShowBundleDrawer = (id) => {
    const bundle = bundles.filter((b) => b._id === id);
    setSelectedBundle({
      ...bundle[0],
      appointmentType: bundle[0].appointmentType[0]._id,
    });

    setShowBundleDrawer(true);
  };
  const openCreateBundleDrawer = () => setCreateBundleDrawer(true);
  const openEditBundleDrawer = (id) => {
    const bundle = bundles.filter((b) => b._id === id);
    setSelectedBundle({
      ...bundle[0],
      appointmentType: bundle[0].appointmentType[0]._id,
    });
    setEditBundleDrawer(true);
  };

  const closeShowBundleDrawer = () => {
    setSelectedBundle(null);
    setShowBundleDrawer(false);
  };
  const closeCreateBundleDrawer = () => setCreateBundleDrawer(false);
  const closeEditBundleDrawer = () => {
    setSelectedBundle(null);
    setEditBundleDrawer(false);
  };
  const cancelBundleSubscription = (bundle) => {
    setCancellingBundle(bundle);
    setOpenCancelConfirmation(true);
  };

  const handleCancelConfirmationClose = () => {
    setCancellingBundle(null);
    setOpenCancelConfirmation(false);
  };

  const handleCancellingSubscription = (reason) => {
    cancelSubscripton({ bundleId: cancellingBundle._id, reason });
  };

  const filterClients = (searchText) => {
    getClients(searchText);
  };

  const filterClinicians = (searchText) => {
    getClinicians(searchText);
  };
  console.log('bundles', bundles);
  return (
    <Grid>
      <DashboardHeader
        searchByText={searchByText}
        value={searchText}
        onClick={createNewBundle}
        createBtnText='Add New Subscriber'
        dropdownOptions={HEADER_DROPDOWN_OPTIONS}
        dropdownValue={bundleStatus}
        onDropdownChange={handleBundleStatusChange}
        dropdownName='bundleStatus'
        showDropdown
      />
      <Content>
        <Table
          isDataLoading={isLoading}
          data={bundles}
          headers={tableHeader}
          showTableHeaders
          showActionBtns
          showEditBtn={bundleStatus === 'active'}
          onEditClick={openEditBundleDrawer}
          onViewClick={openShowBundleDrawer}
          emptyTableMessage='No service bundles added yet!'
          history={history}
          sort={sort}
          showIconBtns
        />
      </Content>
      <Grid>
        {bundles && bundles.length > 0 && (
          <PaginationGrid justify='flex-end' container>
            <Pagination
              count={getPageCount(totalPages)}
              page={page}
              onChange={handlePageChange}
              color='primary'
              shape='rounded'
            />
          </PaginationGrid>
        )}
      </Grid>
      {selectedBundle && (
        <ShowBundle
          selectedBundle={selectedBundle}
          open={showBundleDrawer}
          onClose={closeShowBundleDrawer}
          clinicians={clinicians}
          clients={clients}
        />
      )}
      <CreateBundle
        filterClients={filterClients}
        filterClinicians={filterClinicians}
        open={createBundleDrawer}
        onClose={closeCreateBundleDrawer}
        clinicians={clinicians}
        clients={clients}
      />

      {selectedBundle && (
        <EditBundle
          filterClients={filterClients}
          filterClinicians={filterClinicians}
          selectedBundle={selectedBundle}
          open={editBundleDrawer}
          onClose={closeEditBundleDrawer}
          onCancelSubscription={cancelBundleSubscription}
          clinicians={clinicians}
          clients={clients}
        />
      )}
      <CancelConfirmationDialog
        open={openCancelConfirmation}
        handleClose={handleCancelConfirmationClose}
        onCancel={handleCancellingSubscription}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  cliniciansList: state.getClinician.getClinicianData,
  clientsList: state.getClients.clients,

  newlyCreated: state.createBundle.newlyCreated,
  modifiedBundle: state.modifyBundles.modifiedBundle,
  cancelledSuccessfully: state.cancellingBundle.cancelledSuccessfully,
  loading: state.getBundles.request,
  fetchedBundles: state.getBundles.bundles,
  count: state.getBundles.count,
  error: state.getBundles.error,
});

const mapDispatchToProps = (dispatch) => ({
  getClinicians: (text) => dispatch(getClinicianAction(text)),
  getClients: (text) => dispatch(getClients(text)),
  getAllBundles: (page, sortType, sortBy, searchText, status) =>
    dispatch(getAllBundles(page, sortType, sortBy, searchText, status)),
  cancelSubscripton: (data) => dispatch(cancelSubscripton(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Listing);
