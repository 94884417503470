import { createStyles} from "@material-ui/core";

export default (theme) =>
  createStyles({
    containerSty: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      borderWidth: 1,
      borderColor: "#003d79",
      border: "solid",
      backgroundColor: "#F7F8F5",
    },
    subContainer: {
      margin: theme.spacing(5),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#ffffff",
    },
    headingStyle: {
      marginTop: "20px",
      fontSize: "25px",
      fontWeight: 600,
      lineHeight: "30px",
      color: "#003d79",
    },
    optionStyle: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-arround",
    },
    optionTextStyle: {
      color: "#003d79",
      fontSize: "16px",
      lineHeight: "24px",
    },
    subHeadingStyle: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#003d79",
      padding: "5px",
      paddingLeft: "10px",
      textTransform: "capitalize",
    },
    subHeadingAnsStyle: {
      fontSize: "16px",
      lineHeight: "24px",
      color: "#a29061",
      textTransform: "capitalize",
      paddingRight: "10px",
      padding: "5px",
    },
    gridItemStyle: {
      display: "flex",
      flexDirection: "row",
    },
  });