import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { CreateBtnWithFilter } from '../';
import { SearchBox, Select } from '../../atoms';

const DashboardHeader = ({
  searchByText,
  onClick,
  onClick2,
  onFilterClick,
  createBtnText,
  createBtnText2,
  value,
  showFilters,
  onDropdownChange,
  options,
  dropdownLabel,
  dropdownName,
  dropdownPlaceholder,
  dropdownOptions,
  dropdownValue,
  showDropdown,
}) => {
  return (
    <Grid justify='space-between' container>
      <Grid xs={7} spacing={2} justify='flex-start' item container>
        <Grid xs={7} justify='flex-start' item container>
          <SearchBox
            name='search-box'
            value={value}
            onClick={searchByText}
            onChange={searchByText}
          />
        </Grid>
        {showDropdown && (
          <Grid xs={5} item>
            <Select
              label={dropdownLabel}
              options={dropdownOptions}
              name={dropdownName}
              placeholder={dropdownPlaceholder}
              onChange={onDropdownChange}
              value={dropdownValue}
              borderRadius='5px'
              height='50px'
            />
          </Grid>
        )}
      </Grid>

      <Grid xs={5} item>
        <CreateBtnWithFilter
          onClick={onClick}
          onClick2={onClick2}
          onFilterClick={onFilterClick}
          createBtnText={createBtnText}
          createBtnText2={createBtnText2}
          showFilters={showFilters}
        />
      </Grid>
    </Grid>
  );
};

DashboardHeader.defaultProps = {
  showFilters: false,
  showDropdown: false,
};
DashboardHeader.propTypes = {
  searchByText: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  createBtnText: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default DashboardHeader;
