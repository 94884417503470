export default {
	"OTP": `
  <p style="color: #003265; font-size: 16px; font-style: italic;">The LightHouse is a community mental health and wellness clinic providing quality psychological and psychiatric care to children,
  adults, couples and families.</p>
<p>An invitation to attend an appointment was sent to you by {{client_name}}.</p>
<p>You are kindly requested to click on the link below and fill out the account completion process to confirm the appointment.</p>
<p>{{otp}}</p>
<p>We're excited to have you a part of our community!</p>
<p style="margin-top: 40px !important;">Management</p>
  `
}