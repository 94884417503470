import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Container, Content, Img } from './style';
import BundleTeam from '../../../assets/banner_images/bundle-team.png';

const NoBundlePage = () => {
  return (
    <Container>
      <Grid direction='column' container>
        <Grid item>
          <Typography variant='h4'>
            You do not have any bundled services
          </Typography>
        </Grid>
        <Content direction='row' justify='space-between' item container>
          <Grid xs={5} item>
            <Typography align='justify'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Typography>
          </Grid>
          <Grid xs={5} item>
            <Typography align='left'>
              call uus today to +971 4 123 1234 or enquire via email to
              services@lighthousearabia.com
            </Typography>
          </Grid>
        </Content>
      </Grid>
      <Grid>
        <Img src={BundleTeam} alt='BundleTeam' />
      </Grid>
    </Container>
  );
};

export default NoBundlePage;
