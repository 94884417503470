import {createStyles} from "@material-ui/core";

export default (theme) =>
createStyles({
  secondContainer: {
    [theme.breakpoints.up("xs")]: {
      marginTop: "30px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "40px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "68px",
    },
  },
  radio: {
    width: "20px",
    height: "20px",
    borderRadius: "10px",
    border: "border: 2px solid #ffffff",
    backgroundColor: "#304659",
    opacity: "100%",
    marginRight: "20px",
    float: "left",
    display: "flex",
    justifyContent: "flex-start",
    position: "relative",
  },
  radionotselected: {
    width: "20px",
    height: "20px",
    borderRadius: "11px",
    backgroundColor: "#ffffff",
    border: "2px solid #304659",
    float: "left",
    display: "flex",
    justifyContent: "flex-start",
    position: "relative",
    marginRight: "20px",
  },
  cardtext: {
    // fontSize: '18px',
    color: "#304659",
    fontFamily: "Lato",
    fontWeight: 400,
    [theme.breakpoints.up("xs")]: {
      fontSize: "14px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
  },
  paypal: {
    marginTop: "12px",
    [theme.breakpoints.up("xs")]: {
      height: "16px",
    },
  },

  buttonContainer: {
    marginBottom: "100px",
    [theme.breakpoints.up("xs")]: {
      marginTop: "50px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "60px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "107px",
    },
  },
  cancelButton: {
    opacity: "25%",
    width: "140px",
    marginRight:"10px"
  },
  nextButton: {
    width: "140px",
    marginLeft:"10px"
  },
  cancelText: {
    position: "relative",
    // left: "-78px",
  },
});
