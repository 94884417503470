import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DatePicker } from './style';

const CalenderField = ({ minDate, value, name, onChange, disabled }) => {
  const onSelect = (date) => onChange({ target: { name: name, value: date } });
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        name={name}
        disableToolbar
        variant='inline'
        format='MM/dd/yyyy'
        margin='normal'
        minDate={minDate}
        value={value}
        onChange={onSelect}
        disabled={disabled}
      />
    </MuiPickersUtilsProvider>
  );
};

CalenderField.defaultProps = {
  disabled: false,
};
export default CalenderField;
