import { createStyles } from '@material-ui/core';

export default (theme) =>
  createStyles({
    root: {
      backgroundColor: '#cfd9df',
      width: '100%',
      height: '100%',
      opacity: '20%',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    titleContainer: {
      position: 'absolute',
      zIndex: 1,
      [theme.breakpoints.up('xs')]: {
        width: '300px',
        top: '150px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '500px',
        top: '150px',
      },
      [theme.breakpoints.up('md')]: {
        width: '800px',
        top: '202px',
      },
    },
    container: {
      position: 'absolute',
      // width: '800px',
      [theme.breakpoints.up('xs')]: {
        width: '300px',
        top: '80px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '500px',
        top: '90px',
      },
      [theme.breakpoints.up('md')]: {
        width: '800px',
        top: '132px',
      },
      zIndex: 1,
    },
    bodyContainer: {
      marginTop: '22px',
      justifyContent: 'center',
      alignSelf: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      // fontSize: "32px",
      color: '#304659',
      fontFamily: 'Lato',
      fontWeight: '700',
      textAlign: 'flex-start',
      textAlign: 'left',
      [theme.breakpoints.up('xs')]: {
        fontSize: '20px',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '25px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '32px',
      },
    },
    boxContainer: {
      marginTop: '23px',
      height: '137px',
      backgroundColor: '#ffffff',
    },
    gridContainer: {
      display: 'flex',
    },
    gridflex: {
      display: 'flex',
      float: 'left',
      justify: 'left',
      justifyContent: 'flex-start',
    },
    datetext: {
      // fontSize: '18px',
      color: '#304659',
      fontFamily: 'Lato',
      fontWeight: 400,
      textAlign: 'center',
      marginLeft: '10px',
      [theme.breakpoints.up('xs')]: {
        fontSize: '12px',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '15px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
      },
    },
    dateicon: {
      // width: '18px',
      // height: '18px',
      [theme.breakpoints.up('xs')]: {
        width: '15px',
        height: '15px',
      },
      [theme.breakpoints.up('md')]: {
        width: '18px',
        height: '18px',
        marginTop: '5px',
      },
    },
    mapicon: {
      width: '12px',
      height: '20px',
    },
    maptext: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      [theme.breakpoints.up('xs')]: {
        width: '100px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '150px',
      },
      [theme.breakpoints.up('md')]: {
        width: '300px',
      },
    },
    clockicon: {
      [theme.breakpoints.up('xs')]: {
        width: '15px',
        height: '15px',
      },
      [theme.breakpoints.up('md')]: {
        width: '18px',
        height: '18px',
        marginTop: '5px',
      },
    },
    clocktext: {
      color: '#304659',
      fontFamily: 'Lato',
      fontWeight: 400,
      marginLeft: '10px',
      [theme.breakpoints.up('xs')]: {
        marginLeft: '6px',
        width: '80px',
        fontSize: '12px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      [theme.breakpoints.up('md')]: {
        width: '180px',
        fontSize: '18px',
      },
    },
    peopleicon: {
      width: '20px',
      height: '17px',
    },
    peopletext: {
      width: '118px',
      height: '15px',
      fontSize: '18px',
      color: '#304659',
      fontFamily: 'Lato',
      fontWeight: 400,
      textAlign: 'center',
      marginLeft: '10px',
    },
    boxsubtext: {
      marginTop: '20px',
      fontSize: '15px',
      color: '#304659',
      fontFamily: 'Lato',
      fontWeight: 400,
      textAlign: 'start',
      opacity: '50%',
    },
    cardContainer: {
      marginTop: '21px',
      maxHeight: '150px',
      height: 'auto',
      backgroundColor: '#ffffff',
    },
    secondContainer: {
      [theme.breakpoints.up('xs')]: {
        marginTop: '30px',
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: '40px',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '68px',
      },
    },
    radio: {
      width: '20px',
      height: '20px',
      borderRadius: '10px',
      border: 'border: 2px solid #ffffff',
      backgroundColor: '#304659',
      opacity: '100%',
      marginRight: '20px',
      float: 'left',
      display: 'flex',
      justifyContent: 'flex-start',
      position: 'relative',
    },
    radionotselected: {
      width: '20px',
      height: '20px',
      borderRadius: '11px',
      backgroundColor: '#ffffff',
      border: '2px solid #304659',
      float: 'left',
      display: 'flex',
      justifyContent: 'flex-start',
      position: 'relative',
      marginRight: '20px',
    },
    cardtext: {
      // fontSize: '18px',
      color: '#304659',
      fontFamily: 'Lato',
      fontWeight: 400,
      [theme.breakpoints.up('xs')]: {
        fontSize: '14px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
      },
    },
    paypal: {
      marginTop: '12px',
      [theme.breakpoints.up('xs')]: {
        height: '16px',
      },
    },
    buttonContainer: {
      marginBottom: '100px',
      [theme.breakpoints.up('xs')]: {
        marginTop: '50px',
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: '60px',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '107px',
      },
    },
    cancelButton: {
      // opacity: "25%",
      width: '140px',
    },
    nextButton: {
      marginLeft: 10,
      width: '140px',
    },
    cancelText: {
      position: 'relative',
      // left: "-78px",
    },
  });
