import { makeStyles, createStyles } from "@material-ui/core/styles";
const drawerWidth = 240;

export default makeStyles((theme) =>
  createStyles({
    appMenu: {
      flexGrow: 1,
      height: "100%",
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      // position: 'absolute',
    },

    navList: {
      width: drawerWidth,
    },
    // menuItem: {
    //   width: drawerWidth,
    //   marginTop: theme.spacing(2),
    // },
    menuItemIcon: {
      color: "#003d79",
      paddingLeft: theme.spacing(2),
    },
    menuText: {
      color: "#003d79",
      fontWeight: 600,
      backgroundColor: "##f2f2f2",
      display: "flex",
      alignItems: "center",
      fontSize: 14,
      textDecoration: "none",
    },
    textcolor: {
      color: "#a29061",
      fontWeight: 500,
      textTransform: "capitalize",
    },
  })
);