import { makeStyles} from "@material-ui/core";
export default makeStyles((theme) => ({
    root: {
      width: "100%",
      // backgroundColor: "rgba(48,70,89, 0.05)",
      // height: '100%',
      position: "relative",
      right: 0,
      top: "100px",
      bottom: "0px",
      padding: "50px 50px 50px 300px",
    },
    item: {
      // backgroundColor: "rgba(48,70,89, 50%)",
      minHeight: "calc(100vh - 100px)",
      position: "unset",   
      padding: "50px 30px 50px 345px",
    },
  }));