import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    margin: 10,
    boxShadow: 'none',
  },
  formControl: {
    minWidth: "100%",
    width: "100%"
  },
}));