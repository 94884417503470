import {createStyles} from '@material-ui/core/styles';



export default (theme) =>
createStyles({
  text: {
    width: "76px",
    fontSize: "15px",
    fontFamily: "Lato",
    fontWeight: 400,
    color: "#304659",
    textAlign: "center",
    [theme.breakpoints.up("xs")]: {
      width: "60px",
      fontSize: "12px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "76px",
      fontSize: "15px",
    },
  },
  image: {
    [theme.breakpoints.up("xs")]: {
      width: "300px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "500px",
    },
    [theme.breakpoints.up("md")]: {
      width: "800px",
    },
  },
  dotsContainer: {
    display: "flex",
    [theme.breakpoints.up("xs")]: {
      marginTop: "-15px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "-15px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "-20px",
    },
  },
});
