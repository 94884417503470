import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import utils from 'js-utilities';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {
  Heading,
  BackButton,
  MainGrid,
  CardsGrid,
  Card,
  CardTitle,
  CardInfo,
  InfoText,
  TotalAmount,
} from '../style';
import moment from 'moment'
import AlertMsg from '../../utils/Alert';
import { MarkRequired } from '../style';
import AddressView from '../../admin/components/common/ClinicAddresses/AddressView';
import { PaymentOptions } from '../../components/molecules';
import { useHistory } from "react-router-dom";
import PaypalPaymentGeneric from '../../common/PaypalPaymentGeneric';
import Axios from 'axios';
import { baseURL } from '../../utils/axios';
import { Spinner } from '../../components/atoms';

const SecondStep = ({
  attendies,
  handleNext,
  handleBack,
  eventData,
  attendie,
  entity,
  entityId,
  amount,
  parent,
  onCancel,
  onSubmit
}) => {
  const [contact, setContact] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  });

  const [paymentMethod, setPaymentMethod] = useState("");
  const [ipAddress, setIpAddress] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (attendie) {
      // console.log('attendie', attendie)    /;
      setContact({
        ...contact,
        firstName: attendie.firstName,
        lastName: attendie.lastName,
        phoneNumber: attendie.countryCode + attendie.phoneNumber,
        email: attendie.email,
        address1: attendie.address1,
        address2: attendie.address2,
        city: attendie.city,
        state: attendie.state,
        zip: attendie.zip,
        country: attendie.country,
      });
    }
    getIpAddress()
  }, [attendie]);

  const getIpAddress = async () => {
    await Axios.get('https://ipapi.co/json/')
      .then((res) => {
        console.log(res.data.ip)
        setIpAddress(res.data.ip)
      })
      .catch(e => {
        console.log(e)
      });
  }

  const handleCreateAddress = (address) => {
    console.log(address)
    setContact((currContact) => ({
      ...currContact,
      address1: address.address1,
      address2: address.address2,
      state: address.state,
      city: address.city,
      zip: address.zip,
      country: address.country,
      phoneNumber: address.phoneNumber,
    }));
  };

  const validateBillingAddress = () => {
    let errorMessage = '';
    console.log(contact)
    if (contact.firstName.trim() === '') {
      errorMessage = 'First name can not be empty';
    } else if (contact.lastName.trim() === '') {
      errorMessage = 'Last name can not be empty';
    } else if (contact.phoneNumber.trim() === '') {
      errorMessage = 'Phone number can not be empty';
    } else if (!contact.country) {
      errorMessage = 'Country can not be empty';
    } else if (contact.city.trim() === '') {
      errorMessage = 'City can not be empty';
    } else if (contact.address1.trim() === '') {
      errorMessage = 'Address line 1 can not be empty';
    }

    if (errorMessage !== '') {
      AlertMsg('error', errorMessage);
      return false;
    }

    return true;
  };

  const handleSubmit = (paymentData) => {
    if (onSubmit) {
      onSubmit(paymentData)
    }
  };

  const onSuccessfulPayment = (data) => {
    let postData = {
      ...data,
      entityId,
      amount,
      entity,
      attendies: attendies,
    }

    postData.status = postData.status == "success" ? "paid" : "unpaid"
    onSubmit(postData);
  }

  const handlePaymentSelection = (selection) => {
    setPaymentMethod(selection)
  }

  const handleCredit = () => {

    if (ipAddress == null) {
      getIpAddress()
      return
    }

    if (!validateBillingAddress()) {
      return;
    }
    var newData = parent != null ? [parent, ...attendies] : attendies

    newData.forEach(data => {
      data.phoneNumber = data.countryCode + data.phoneNumber
    })
    setLoading(true)
    Axios.post(`${baseURL}/api/payment/${entityId}/payment-for-event`, {
      publicIp: ipAddress,
      amount,
      entity,
      attendies: newData,
      billingAddress: contact,
    })
      .then((res) => {
        setLoading(false)
        if (res?.data?.url) {
          const newPath = res.data.url;
          const newWindow = window.open(newPath, "_self");

          document.addEventListener("DOMContentLoaded", function () {
            // Your DOM should be fully loaded here
            newWindow.document
              .querySelector("*** SOME IDENTIFIER ***")
              .click();
          });
        }
      })
      .catch(() => {
        setLoading(false)
      });
  }

  return (<div className="form block px-4 pb-4">
    {loading && <Spinner isOpen />}
    <h3 className="text-2xl text-brand-gold-dark uppercase mb-4">
      <BackButton onClick={handleBack}>
        <ChevronLeftIcon />
        Back
      </BackButton>    Confirm Attendees & Checkout
    </h3>

    {parent != null ? <div className="block p-4 mb-4 bg-brand-gold-light">
      <div className="pb-1 border-b border-gray-500 border-opacity-20 text-lg font-bold mb-2 flex justify-between">
        <p>Parent Details</p>
        <p>FREE</p>
      </div>
      <div className="w-full flex flex-wrap justify-between gap-2">
        <div className="w-full flex-grow lg:w-5/12">
          <label className="text-sm text-gray-500" htmlFor>
            Full name
          </label>
          <p className="text-lg">{`${parent.firstName} ${parent.lastName}`}</p>
        </div>
        <div className="w-full flex-grow lg:w-5/12">
          <label className="text-sm text-gray-500" htmlFor>
            Email address
          </label>
          <p className="text-lg">{parent.email}</p>
        </div>
        <div className="w-full flex-grow lg:w-5/12">
          <label className="text-sm text-gray-500" htmlFor>
            Date of birth
          </label>
          <p className="text-lg">{moment(new Date(parent.dob)).format("DD/MM/yyyy")}</p>
        </div>
        <div className="w-full flex-grow lg:w-5/12">
          <label className="text-sm text-gray-500" htmlFor>
            Mobile number
          </label>
          <p className="text-lg">{parent.countryCode + " " + parent.phoneNumber}</p>
        </div>
      </div>
    </div> : <></>}

    {attendies.map((data, index) => {
      console.log(data)
      const age = utils.time.calculateAge(data.dob);
      return (!eventData.isForChildren ? <div className="block p-4 mb-4 bg-brand-gold-light">
        <div className="pb-1 border-b border-gray-500 border-opacity-20 text-lg font-bold mb-2 flex justify-between">
          <p>Parent Details</p>
          <p>{eventData && eventData.isFree ? "FREE" : eventData.fee + " AED"}</p>
        </div>
        <div className="w-full flex flex-wrap justify-between gap-2">
          <div className="w-full flex-grow lg:w-5/12">
            <label className="text-sm text-gray-500" htmlFor>
              Full name
            </label>
            <p className="text-lg">{`${data.firstName} ${data.lastName}`}</p>
          </div>
          <div className="w-full flex-grow lg:w-5/12">
            <label className="text-sm text-gray-500" htmlFor>
              Email address
            </label>
            <p className="text-lg">{data.email}</p>
          </div>
          <div className="w-full flex-grow lg:w-5/12">
            <label className="text-sm text-gray-500" htmlFor>
              Date of birth
            </label>
            <p className="text-lg">{moment(new Date(data.dob)).format("DD/MM/yyyy")}</p>
          </div>
          <div className="w-full flex-grow lg:w-5/12">
            <label className="text-sm text-gray-500" htmlFor>
              Mobile number
            </label>
            <p className="text-lg">{data.countryCode + " " + data.phoneNumber}</p>
          </div>
        </div>
      </div>
        :
        <div className="block p-4 bg-brand-gold-light mb-4">
          <div className="pb-1 border-b border-gray-500 border-opacity-20 text-lg font-bold mb-2 flex justify-between">
            <p>Minor Details</p>
            <p>{eventData && eventData.isFree ? "FREE" : eventData.fee + " AED"}</p>
          </div>
          <div className="w-full flex flex-wrap justify-between gap-1 lg:gap-4">
            { }
            <div className="w-full flex-grow lg:w-5/12">
              <label className="text-sm text-gray-500" htmlFor>
                Full name
              </label>
              <p className="text-lg">{`${data.firstName} ${data.lastName}`}</p>
            </div>
            { }
            <div className="w-full flex-grow lg:w-5/12">
              <label className="text-sm text-gray-500" htmlFor>
                Date of birth
              </label>
              <p className="text-lg">{moment(new Date(data.dob)).format("DD/MM/yyyy")}</p>
            </div>
          </div>
        </div>
      )
    })}

    <div className="price w-full p-4 bg-brand-gold-light mb-8 text-center text-lg font-bold flex justify-between lg:w-2/6 lg:mx-auto lg:mr-0">
      <p>Total:</p>
      {eventData && !eventData.isFree ? <p>
        {attendies.length > 1
          ? ` ${attendies.length} Attendees x AED = ${eventData.fee * attendies.length
          } AED`
          : ` AED ${eventData.fee}`}
      </p> : <p> FREE </p>}
    </div>
    {eventData && !eventData.isFree ?
      <div className="mb-4">
        <PaymentOptions
          entityId={entityId}
          entity={entity}
          amount={amount}
          attendies={parent != null ? [parent, ...attendies] : attendies}
          billingAddress={contact}
          onCancel={onCancel}
          handlePaymentSelection={handlePaymentSelection}
          handleAddressChange={handleCreateAddress}
          validateBillingAddress={validateBillingAddress}
          onSubmit={handleSubmit}
        />
      </div> : <></>}
    {paymentMethod != "paypal" ?
      <button
        onClick={() => eventData.isFree ? handleNext() : handleCredit()}
        className="block w-full p-4 bg-brand-blue text-white">
        <svg
          className="inline-block w-5 h56 mr-1"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
          />
        </svg>
        Checkout and Reserve Now
      </button> :
      <PaypalPaymentGeneric amount={amount}
        onSuccessfulPayment={onSuccessfulPayment} />}
  </div >)
};

SecondStep.propTypes = {
  attendies: PropTypes.array.isRequired,
  eventData: PropTypes.shape({
    isFree: PropTypes.bool,
    fee: PropTypes.number,
  }),
  classes: PropTypes.shape({
    button2: PropTypes.string.isRequired,
    button1: PropTypes.string.isRequired,
    button2Text: PropTypes.string.isRequired,
  }).isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
};

export default SecondStep;
