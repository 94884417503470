/* eslint-disable no-unused-vars */
/* eslint-disable quotes */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
import {
  ADD_P1OF2_REQUEST,
  ADD_P1OF2_SUCCESS,
  ADD_P1OF2_FAILED,
  GET_P1OF2_REQUEST,
  GET_P1OF2_SUCCESS,
  GET_P1OF2_FAILED,
  GET_BY_ID_P1OF2_REQUEST,
  GET_BY_ID_P1OF2_SUCCESS,
  GET_BY_ID_P1OF2_FAILED,
  UPDATE_P1OF2_REQUEST,
  UPDATE_P1OF2_SUCCESS,
  UPDATE_P1OF2_FAILED,
} from "../AdminConstent";

const initialState = {
  request: true,
  error: false,
};

export const addP1of2 = (state = [], action) => {
  switch (action.type) {
    case ADD_P1OF2_REQUEST:
      return {
        request: true,
      };
    case ADD_P1OF2_SUCCESS:
      return {
        request: false,
        addP1of2Data: action.response,
      };
    case ADD_P1OF2_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getP1of2 = (state = [], action) => {
  switch (action.type) {
    case GET_P1OF2_REQUEST:
      return {
        request: true,
      };
    case GET_P1OF2_SUCCESS:
      return {
        request: false,
        getP1of2Data: action.response,
      };
    case GET_P1OF2_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getByIdP1of2 = (state = [], action) => {
  switch (action.type) {
    case GET_BY_ID_P1OF2_REQUEST:
      return {
        request: true,
      };
    case GET_BY_ID_P1OF2_SUCCESS:
      return {
        request: false,
        getByIdP1of2Data: action.response,
      };
    case GET_BY_ID_P1OF2_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const updateP1of2 = (state = [], action) => {
  switch (action.type) {
    case UPDATE_P1OF2_REQUEST:
      return {
        request: true,
      };
    case UPDATE_P1OF2_SUCCESS:
      return {
        request: false,
        updateP1of2Data: action.response,
      };
    case UPDATE_P1OF2_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getP1of2User = (state = [], action) => {
  switch (action.type) {
    case GET_P1OF2_REQUEST:
      return {
        request: true,
      };
    case GET_P1OF2_SUCCESS:
      return {
        request: false,
        getP1of2UserData: action.response,
      };
    case GET_P1OF2_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};
