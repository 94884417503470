/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-global-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-undef */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from 'react';
import {
  Container,
  Typography,
  Grid,
  Input,
  withStyles,
  Button,
} from '@material-ui/core';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
// import en from 'date-fns/locale/en';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import validator from 'validator';
import { stateOption, genderOption } from '../pages/AllSignupOption';
import {
  getByIdClientAction,
  updateClientAction,
} from '../../utils/actions/client.action';
import AlertMsg from '../../utils/Alert';
import styles from './CheckListStyle';

// registerLocale('en', en);

class CheckList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      phoneNumber: '',
      dob: '',
      gender: '',
      state: '',
    };
  }

  componentDidMount() {
    this.props.getByIdClientAction(this.props.match.params.id);
  }

  componentDidUpdate(preProps) {
    const { getClientData } = this.props;
    if (getClientData !== preProps.getClientData) {
      const data = getClientData.getByIdClientData;
      if (data) {
        const date = data.dob.split('/');
        const newDate = new Date();
        newDate.setDate(date[0]);
        newDate.setMonth(date[1] - 1);
        newDate.setFullYear(date[2]);
        this.setState({
          name: data.name,
          phoneNumber: data.phoneNumber,
          email: data.email,
          dob: newDate,
          gender: data.gender,
          state: data.state,
        });
      }
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSortChange = (name) => (data) => {
    this.setState({ [name]: data.value });
  };

  handleVerfy = () => {
    const fName = validator.isEmpty(this.state.name, {
      ignore_whitespace: true,
    });
    const gender = validator.isEmpty(this.state.gender, {
      ignore_whitespace: true,
    });
    // const clientType = validator.isEmpty(this.state.clientType, {
    //   ignore_whitespace: true,
    // });
    const email = validator.isEmpty(this.state.email, {
      ignore_whitespace: true,
    });
    const phoneNumber = validator.isEmpty(this.state.phoneNumber, {
      ignore_whitespace: true,
    });
    const state = validator.isEmpty(this.state.state, {
      ignore_whitespace: true,
    });
    if (
      fName ||
      email ||
      phoneNumber ||
      gender ||
      // clientType ||
      state ||
      this.state.dob === null
    ) {
      AlertMsg('error', 'All Field Must be Filled !');
      return false;
    }
    return true;
  };

  handleSubmit = () => {
    const { name, email, phoneNumber, state, gender, dob } = this.state;
    const isVerify = this.handleVerfy();
    if (isVerify) {
      const data = {
        name,
        email,
        phoneNumber,
        state,
        gender,
        dob: [dob.getDate(), dob.getMonth() + 1, dob.getFullYear()].join('/'),
      };
      this.props.updateClientAction(this.props.match.params.id, data);
    }
  };

  render() {
    const { classes } = this.props;
    const { name, email, phoneNumber, dob, gender, state } = this.state;
    console.log(
      '============client data ===========',
      this.props.getClientData,
      this.props.updateStatus
    );

    const selectStyle = {
      ...styles,
      control: (base, state) => ({
        ...base,
        '&:hover': { borderColor: '#003d79' }, // border style on hover
        border: '1px solid #003d79', // default border color
        boxShadow: 'none', // no box-shadow
      }),
      singleValue: (base, state) => ({
        ...base,
        color: '#a29061',
      }),
      input: (base, state) => ({
        ...base,
        color: '#a29061',
      }),
    };
    return (
      <Container maxWidth='md' className={classes.root}>
        <div className={classes.containerSty}>
          <Typography className={classes.title}>Update Yourself</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
                <Grid item lg={6}>
                  <Typography className={classes.inputText} component='span'>
                    Full Name
                  </Typography>
                  <Input
                    name='name'
                    value={name}
                    disableUnderline
                    placeholder='Full Name'
                    autoFocus
                    fullWidth
                    onChange={this.handleChange}
                    className={classes.marginSty}
                  />
                </Grid>

                <Grid item lg={6}>
                  <Typography className={classes.inputText} component='span'>
                    Email
                  </Typography>
                  <Input
                    name='email'
                    value={email}
                    disableUnderline
                    placeholder='Email ID'
                    fullWidth
                    type='email'
                    onChange={this.handleChange}
                    className={classes.marginSty}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
                <Grid item lg={3}>
                  <Typography className={classes.inputText} component='span'>
                    Phone Number
                  </Typography>
                  <Input
                    name='phoneNumber'
                    value={phoneNumber}
                    fullWidth
                    placeholder='Phone Number'
                    disableUnderline
                    onChange={this.handleChange}
                    className={classes.marginSty}
                  />
                </Grid>
                <Grid item lg={3}>
                  <Typography className={classes.inputText} component='span'>
                    Date of Birth
                  </Typography>
                  <div
                    style={{ display: 'flex', flex: 1, flexDirection: 'row' }}
                  >
                    <DatePicker
                      locale='en'
                      selected={this.state.dob}
                      onChange={(date) => this.setState({ dob: date })}
                      className={[classes.dateInput, classes.marginSty]}
                      dateFormat='dd/MM/yyyy'
                    />
                  </div>
                </Grid>
                <Grid item lg={3}>
                  <Typography className={classes.inputText} component='span'>
                    Gender
                  </Typography>
                  <Select
                    placeholder='Select Gender'
                    value={genderOption.filter((data) => data.value === gender)}
                    onChange={this.handleSortChange('gender')}
                    options={genderOption}
                    styles={selectStyle}
                  />
                </Grid>
                <Grid item lg={3}>
                  <Typography className={classes.inputText} component='span'>
                    State
                  </Typography>
                  <Select
                    placeholder='Select State'
                    value={stateOption.filter((data) => data.value === state)}
                    onChange={this.handleSortChange('state')}
                    options={stateOption}
                    styles={selectStyle}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                onClick={this.handleSubmit}
                className={classes.submit}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    );
  }
}

const connected = connect(
  (state) => ({
    getClientData: state.getByIdClient,
    updateStatus: state.updateClient,
  }),
  {
    getByIdClientAction,
    updateClientAction,
  }
)(CheckList);

const formed = reduxForm({
  form: 'getByIdClient',
})(connected);

export default withStyles(styles)(formed);
