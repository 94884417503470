import {makeStyles} from '@material-ui/core/styles';


export default makeStyles((theme) => ({
    rootName: {
      fontSize: "15px",
      color: "#304659",
      fontWeight: 700,
      fontFamily: "Lato",
    },
    rootDesc: {
      fontSize: "15px",
      color: "#304659",
      fontWeight: 400,
      fontFamily: "Lato",
    },
    rootTitle: {
      fontSize: "18px",
      color: "rgba(48,70,89, 51%)",
      fontWeight: 700,
      fontFamily: "Lato",
      textAlign: "left",
      marginBottom: "10px",
    },
  }));