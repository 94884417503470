import { createStyles} from "@material-ui/core";
import { titleFont } from "../../assets/fontSize";
export default (theme) =>
  createStyles({
    root: {
      backgroundColor: "#cfd9df",
      width: "100%",
      height: "100%",
      opacity: "20%",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    container: {
      position: "absolute",
      zIndex: 1,
      [theme.breakpoints.up("xs")]: {
        width: "300px",
        top: "150px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "500px",
        top: "150px",
      },
      [theme.breakpoints.up("md")]: {
        width: "800px",
        top: "186px",
      },
    },
    backArrow: {
      width: "10px",
      height: "10px",
      margin: "5px",
      marginTop: "8px",
      cursor: "pointer",
    },
    back: {
      cursor: "pointer",
      // fontSize: "17px",
      lineHeight: "25px",
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: 800,
      [theme.breakpoints.up("xs")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "17px",
      },
    },
    headerContainer: {
      display: "flex",
      alignItems: "center",
    },
    headerText: {
      // fontSize: "32px",
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: 700,
      textAlign: "center",
      lineHeight: "30px",
      [theme.breakpoints.up("xs")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "20px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: titleFont,
        // fontSize: "32px",
      },
    },
    rightIconContainer: {
      // width: "26px",
      // height: "26px",
      borderRadius: "13px",
      backgroundColor: "#39b54a",
      // marginLeft: "10px",
      // marginTop: "-12px",
      [theme.breakpoints.up("xs")]: {
        width: "20px",
        height: "20px",
        marginLeft: "10px",
      },
      [theme.breakpoints.up("md")]: {
        width: "26px",
        height: "26px",
        marginLeft: "10px",
      },
    },
    rightIconText: {
      // width: "17px",
      // height: "15px",
      marginTop: "5px",
      // backgroundColor: '#ffffff'
      [theme.breakpoints.up("xs")]: {
        width: "13px",
        height: "10px",
      },
      [theme.breakpoints.up("md")]: {
        width: "17px",
        height: "15px",
      },
    },
    bodyContainer: {
      marginTop: "30px",
      // height: "240px",
      // maxHeight: "240px",
      borderRadius: "10px",
      backgroundColor: "#ffffff",
      border: "1px solid #707070",
    },
    bodySubContainer: {
      width: "732px",
      // height: "144px",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      [theme.breakpoints.up("xs")]: {
        width: "250px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "450px",
      },
      [theme.breakpoints.up("md")]: {
        width: "730px",
      },
    },
    containerText: {
      fontSize: "15px",
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: 400,
      textAlign: "left",
      [theme.breakpoints.up("xs")]: {
        padding: "10px",
      },
      [theme.breakpoints.up("sm")]: {
        padding: "15px",
      },
      [theme.breakpoints.up("md")]: {
        padding: "40px",
      },
    },
    skipText: {
      marginRight: "64px",
      fontSize: "17px",
      color: "#304659",
      fontFamily: "Lato",
      lineHeight: "40px",
      fontWeight: 700,
    },
  });