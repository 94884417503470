/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import { put, takeEvery } from "redux-saga/effects";
import { post, get, updatePatch, delet } from "../axios";
import {
  ADD_SERVICE_SUCCESS,
  ADD_SERVICE_FAILED,
  ADD_SERVICE_REQUEST,
  DELETE_SERVICE_FAILED,
  DELETE_SERVICE_SUCCESS,
  UPDATE_SERVICE_FAILED,
  UPDATE_SERVICE_SUCCESS,
  GET_BY_ID_SERVICE_FAILED,
  GET_BY_ID_SERVICE_SUCCESS,
  GET_SERVICE_FAILED,
  GET_SERVICE_SUCCESS,
  GET_SERVICE_REQUEST,
  GET_BY_ID_SERVICE_REQUEST,
  UPDATE_SERVICE_REQUEST,
  DELETE_SERVICE_REQUEST,
} from "../constant";

function* addService(action) {
  try {
    const { endpoint, body } = action;
    const response = yield post(endpoint, body);
    yield put({ type: ADD_SERVICE_SUCCESS, response });
  } catch (error) {
    yield put({ type: ADD_SERVICE_FAILED, error });
  }
}

function* getService(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_SERVICE_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_SERVICE_FAILED, error });
  }
}

function* getByIdService(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_BY_ID_SERVICE_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_BY_ID_SERVICE_FAILED, error });
  }
}

function* updateService(action) {
  try {
    const { endpoint, body } = action;
    const response = yield updatePatch(endpoint, body);
    yield put({ type: UPDATE_SERVICE_SUCCESS, response });
  } catch (error) {
    yield put({ type: UPDATE_SERVICE_FAILED, error });
  }
}

function* deleteService(action) {
  try {
    const { endpoint } = action;
    const response = yield delet(endpoint);
    yield put({ type: DELETE_SERVICE_SUCCESS, response });
  } catch (error) {
    yield put({ type: DELETE_SERVICE_FAILED, error });
  }
}

export function* serviceSaga() {
  yield takeEvery(ADD_SERVICE_REQUEST, addService);
  yield takeEvery(GET_SERVICE_REQUEST, getService);
  yield takeEvery(GET_BY_ID_SERVICE_REQUEST, getByIdService);
  yield takeEvery(UPDATE_SERVICE_REQUEST, updateService);
  yield takeEvery(DELETE_SERVICE_REQUEST, deleteService);
}
