/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import * as React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import styles from './TableHeader.styles';

// eslint-disable-next-line no-unused-vars

export const TableHeader = ({ ...props }) => {
  const classes = styles();
  return (
    <Grid
      // component="div"
      // container
      // alignItems="center"
      // justify="space-around"
      className={classes.root}
      {...props}
    >
      <Grid
        style={{ width: props.status === 'waiting' ? '14%' : '15%' }}
        container
        justify='flex-start'
      >
        <Typography className={classes.font}>
          {props.status === 'waiting' ? 'Chart Number' : 'Invoices Number'}
        </Typography>
      </Grid>

      <Grid style={{ width: '10%' }} container justify='flex-start'>
        <Typography className={classes.font}>Clinician</Typography>
      </Grid>
      <Grid style={{ width: '10%' }} container justify='flex-start'>
        <Typography className={classes.font}>Patient</Typography>
      </Grid>
      <Grid style={{ width: '5%' }} container justify='flex-start'>
        <Typography className={classes.font}>Age</Typography>
      </Grid>
      <Grid style={{ width: '15%' }} container justify='flex-start'>
        <Typography className={classes.font}>
          {props.status === 'waiting' ? 'Request Date' : 'Invoices Date'}
        </Typography>
      </Grid>

      {props.status !== 'waiting' && (
        <Grid style={{ width: '15%' }} container justify='flex-start'>
          <Typography className={classes.font}>Appointment Type</Typography>
        </Grid>
      )}
      {props.status !== 'waiting' && (
        <Grid style={{ width: '8%' }} container justify='flex-start'>
          <Typography className={classes.font}>Location</Typography>
        </Grid>
      )}
      {props.status !== 'waiting' && (
        <Grid style={{ width: '15%' }} container justify='flex-start'>
          <Typography className={classes.font}>Payment Status</Typography>
        </Grid>
      )}

      <Grid style={{ width: '15%' }} container justify='flex-start'>
        <Typography className={classes.font}>Risk</Typography>
      </Grid>
      <Grid style={{ width: '18%' }} container justify='flex-start'>
        <Typography className={classes.font}>Action</Typography>
      </Grid>
    </Grid>
  );
};
