import { makeStyles} from "@material-ui/core";
export default makeStyles((theme) => ({
    root: {
      backgroundColor: "#ffffff",
      position: "absolute",
      // top: "100px",
      bottom: 0,
      right: 0,
      width: "826px",
      height: "100%",
      border: "1px solid #707070",
      // padding: "41px",
      // paddingLeft: "45px",
      // paddingRight: "50px",
      overflow: "auto",
    },
    Backdrop: {
      "&::-webkit-scrollbar": { display: "none" },
    },
    cancelContainer: {
      backgroundColor: "#304659",
      height: "25px",
      width: "25px",
      position: "absolute",
      cursor: "pointer",
      right: "15px",
      top: "7px",
      fontSize: "18px",
      color: "#ffffff",
      fontFamily: "Lato",
      fontWeight: 700,
      borderRadius: "50px",
      zIndex:99
    },
  }));