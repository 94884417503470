import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { SortBtn, HeadRow, TradTableCell, ZebraRow } from './style';
import { Create } from '@material-ui/icons';
import Loader from '../../Loader/Loader';
import FormattedTableCell from './TableCell';
import TradActionButton from './TradActionButton';

const TraditionalTable = ({
  loading,
  headers,
  data,
  history,
  showActionBtns,
  emptyTableText,
}) => {
  return (
    <TableContainer>
      {loading ? (
        <Loader />
      ) : data.length === 0 && emptyTableText ? (
        <Typography>{emptyTableText}</Typography>
      ) : (
        <Table>
          <TableHead>
            <HeadRow>
              {Object.keys(headers).map((k, i) => (
                <TradTableCell key={k}>{headers[k].title}</TradTableCell>
              ))}
            </HeadRow>
          </TableHead>

          <TableBody>
            {data.map((row, i) => (
              <ZebraRow key={`lh-tr-${i}`}>
                {Object.keys(headers).map((k) => {
                  if (k === 'actions') {
                    return row[k].map((ac) => (
                      <TableCell key={`lh-ac-${ac._id}`}>
                        <TradActionButton {...ac} />
                      </TableCell>
                    ));
                  }
                  return (
                    <FormattedTableCell
                      data={row}
                      headers={headers}
                      keyValue={k}
                      history={history}
                      key={k}
                    />
                  );
                })}
              </ZebraRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default TraditionalTable;
