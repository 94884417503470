import { createStyles} from "@material-ui/core";

export default (theme) =>
createStyles({
  root: {
    flexGrow: 1,
  },
  containerSty: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#003d79",
    border: "solid",
    padding: theme.spacing(5),
  },
  title: {
    fontSize: 25,
    textTransform: "capitalize",
    marginBottom: theme.spacing(2),
    color: "#003d79",
    fontWeight: 600,
  },
  marginSty: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#003d79",
    borderRadius: "5px",
    paddingRight: "5px",
    paddingLeft: "5px",
    height: "37px",
    color: "#a29061",
    backgroundColor: "#ffffff",
  },
  submit: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    backgroundColor: "#003d79",
    fontWeight: 600,
  },
  formHeading: {
    position: "static",
    width: "auto",
    textAlign: "left",
    fontSize: 18,
    fontWeight: 400,
    color: "#003d79",
    fontFamily: "Open Sans,Helvetica,Arial,sans-serif",
  },
  inputText: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#a29061",
    padding: "3px",
    fontWeight: 400,
  },
  optionStyle: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-arround",
  },
  optionTextStyle: {
    color: "#003d79",
    fontSize: "16px",
    lineHeight: "24px",
  },
});