/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import { takeEvery, put } from "redux-saga/effects";
import { postFormData, get, updatePatchForm, delet } from "../axios";
import {
  ADD_ONBOARDING_SUCCESS,
  ADD_ONBOARDING_FAILED,
  GET_ONBOARDING_SUCCESS,
  GET_ONBOARDING_FAILED,
  GET_BY_ID_ONBOARDING_SUCCESS,
  GET_BY_ID_ONBOARDING_FAILED,
  UPDATE_ONBOARDING_SUCCESS,
  UPDATE_ONBOARDING_FAILED,
  DELETE_ONBOARDING_SUCCESS,
  DELETE_ONBOARDING_FAILED,
  ADD_ONBOARDING_REQUEST,
  GET_BY_ID_ONBOARDING_REQUEST,
  DELETE_ONBOARDING_REQUEST,
  UPDATE_ONBOARDING_REQUEST,
  GET_ONBOARDING_REQUEST,
} from "../AdminConstent";
import AlertMsg from "../Alert";

function* addOnboarding(action) {
  try {
    const { endpoint, body } = action;
    const response = yield postFormData(endpoint, body);
    AlertMsg("success", "Added");
    yield put({ type: ADD_ONBOARDING_SUCCESS, response });
  } catch (error) {
    AlertMsg("error", "Failed");
    yield put({ type: ADD_ONBOARDING_FAILED, error });
  }
}

function* getOnboardingSaga(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_ONBOARDING_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_ONBOARDING_FAILED, error });
  }
}

function* getByIdOnboarding(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_BY_ID_ONBOARDING_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_BY_ID_ONBOARDING_FAILED, error });
  }
}

function* updateOnboarding(action) {
  try {
    const { endpoint, body } = action;
    AlertMsg("success", "Updated");
    const response = yield updatePatchForm(endpoint, body);
    yield put({ type: UPDATE_ONBOARDING_SUCCESS, response });
  } catch (error) {
    AlertMsg("error", "Failed");
    yield put({ type: UPDATE_ONBOARDING_FAILED, error });
  }
}

function* deleteOnboarding(action) {
  try {
    const { endpoint } = action;
    const response = yield delet(endpoint);
    AlertMsg("success", "Deleted");
    yield put({ type: DELETE_ONBOARDING_SUCCESS, response });
  } catch (error) {
    AlertMsg("error", "Failed");
    yield put({ type: DELETE_ONBOARDING_FAILED, error });
  }
}

function* getOnboardingUserSaga(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_ONBOARDING_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_ONBOARDING_FAILED, error });
  }
}

export function* onboardingSaga() {
  yield takeEvery(ADD_ONBOARDING_REQUEST, addOnboarding);
  yield takeEvery(GET_ONBOARDING_REQUEST, getOnboardingSaga);
  yield takeEvery(GET_BY_ID_ONBOARDING_REQUEST, getByIdOnboarding);
  yield takeEvery(UPDATE_ONBOARDING_REQUEST, updateOnboarding);
  yield takeEvery(DELETE_ONBOARDING_REQUEST, deleteOnboarding);
  yield takeEvery(GET_ONBOARDING_REQUEST, getOnboardingUserSaga);
}
