/* eslint-disable import/prefer-default-export */
import {
  CLINICAL_SIGNUP_REQUEST,
  CLINICAL_SIGNUP_SUCCESS,
  CLINICAL_SIGNUP_FAILED,
  USER_LOGIN,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  USER_SIGNUP,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILED,
  OTP_NOT_VERIFIED,
  CLOSE_OTP_MODAL,
} from '../constant';

export const clinicalSignup = (state = [], action) => {
  switch (action.type) {
    case CLINICAL_SIGNUP_REQUEST:
      return {
        request: true,
      };
    case CLINICAL_SIGNUP_SUCCESS:
      return {
        request: false,
        clinicalSignupData: action.response,
      };

    case CLINICAL_SIGNUP_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const userLogin = (state = [], action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        request: true,
      };
    case LOGIN_USER_SUCCESS:
      return {
        request: false,
        userloginData: action.response,
      };

    case LOGIN_USER_FAILED:
      return {
        request: false,
        error: true,
      };
    case OTP_NOT_VERIFIED:
      return {
        request: false,
        error: false,
        showOTPModal: true,
        userloginData: action.response,
      };
    case CLOSE_OTP_MODAL:
      return {
        ...state,
        showOTPModal: false,
      };
    default:
      return state;
  }
};

export const userSignup = (state = [], action) => {
  switch (action.type) {
    case USER_SIGNUP:
      return {
        request: true,
      };
    case SIGNUP_USER_SUCCESS:
      return {
        request: false,
        userSignupData: action.response,
      };

    case SIGNUP_USER_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};
