/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from "react";
import { Typography, makeStyles, Grid } from "@material-ui/core";
import { googleSignOut } from "../utils/firebase";
import style from "./PageNotFound.styles";


export const PageNotFound = (props) => {
  const classes = style();
  const handleLogoutWithGoogle = async () => {
    googleSignOut()
      .then(() => {
        localStorage.removeItem("avatar");
        localStorage.removeItem("name");
        localStorage.removeItem("ACCESS_TOKEN_PATH");
        localStorage.removeItem("createdAt");
        localStorage.removeItem("clientEmail");
        localStorage.removeItem("clientUid");
        localStorage.removeItem("clientId");
        // AlertMsg("success", "You have logged out");
        props.history.push("/login");
      })
      .catch(() => props.history.push("/login"));
  };
  return (
    <>
      <Typography className={classes.desc}>404 Error</Typography>
      <Typography className={classes.root}>PAGE NOT FOUND</Typography>
      <Grid container alignItems="center" justify="center">
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.button}
          onClick={handleLogoutWithGoogle}
        >
          Go Back
        </Grid>
      </Grid>
    </>
  );
};
