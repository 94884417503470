import React, { useState } from 'react';
import { Grid, Tabs } from '@material-ui/core';
import TabPanel from './TabPanel';
import { StyledTab, StyledAppBar } from './style';
import Loader from '../../Loader/Loader';
const CustomTabs = ({ headers, handleChange, value, componentToRender }) => {
  const [loading, setLoading] = useState(false);
  const onChange = () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
    handleChange();
  };

  return (
    <Grid>
      <StyledAppBar position='static'>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { backgroundColor: '#4d4d4d' } }}
        >
          {headers.map((c) => (
            <StyledTab label={c.title} key={c.key} />
          ))}
        </Tabs>
      </StyledAppBar>
      <TabPanel value={value} index={value}>
        {loading || !componentToRender ? <Loader /> : componentToRender}
      </TabPanel>
    </Grid>
  );
};

export default CustomTabs;
