import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { CusTextarea, Label, Error, Required } from './style';

const Textarea = ({
  label,
  name,
  value,
  onChange,
  error,
  rows,
  cols,
  isRequired,
  disabled,
}) => {
  return (
    <Grid direction='column' alignItems='flex-start' container>
      <Label>
        {label}
        {isRequired && <Required>*</Required>}
      </Label>
      <CusTextarea
        onChange={onChange}
        name={name}
        rows={rows}
        cols={cols}
        value={value}
        disabled={disabled}
      />
      <Error>{error}</Error>
    </Grid>
  );
};

Textarea.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  rows: PropTypes.number,
  cols: PropTypes.number,
  isRequired: PropTypes.bool,
};

Textarea.defaultProps = {
  value: '',
  rows: 5,
  cols: 30,
  disabled: false,
};

export default Textarea;
