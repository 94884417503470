import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Axios from 'axios';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';
import Loader from '../components/Loader/Loader';
import AlertMsg from '../utils/Alert';
import { Drawer, Grid, Typography, Button } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import moment from 'moment';
import SliderPointLine from '../assets/imgs/slider verticle line.svg';
import SliderPointIcon from '../assets/imgs/slider point.svg';
import SidebarMenu from './SidebarMenuClinician';
import { baseURL } from '../utils/axios';
import FilterModalBox from './FilterModalBox';
import FilterDrawer from './FilterDrawer';
import SidebarClinician from './SidebarClinician';
import './clinician.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import PatientDetailClinician from './Patients/patientDetail';
import DiagnosisSearchView from '../common/DiagnosisSearchView';
import AppointmentUserSession from './AppointmentUserSession';
import PropTypes from 'prop-types';

const isSessionTimeValid = (startTime) =>
  Math.ceil(moment.duration(moment().diff(moment(startTime))).asHours()) < 25;

const useStyles = makeStyles({
  toolbar: {
    width: '100%',
    height: '100px',
    backgroundColor: '#003265',
  },
  prescriptionCard: {
    backgroundColor: '#F9F8F5',
    boxShadow: 'none',
  },
  customBtn: {
    backgroundColor: '#8c9cf7',
    color: '#fff',
    borderRadius: '25px',
    width: '75%',
    padding: '10px',
    textTransform: 'capitalize',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  customBtn2: {
    backgroundColor: '#003265',
    color: '#fff',
    borderRadius: '25px',
    width: '25%',
    padding: '10px',
    textTransform: 'capitalize',
    marginTop: '24px',
  },
  root: {
    maxWidth: 255,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  inputstyle: {
    backgroundColor: '#fff',
  },
  table: {
    minWidth: 650,
  },
  imgTable: {
    width: '40px',
    height: '40px',
    objectFit: 'contain',
    borderRadius: '50px',
  },
  viewBtn: {
    borderRadius: '15px',
    width: '100px',
    padding: '4px',
    boxShadow: 'none',
    textTransform: 'capitalize',
    // '&:hover': {
    //   backgroundColor: '#003265',
    // },
  },
  btnViewPatientRecord: {
    backgroundColor: '#003265',
    color: '#fff',
    padding: '10px',
    marginTop: '15px',
    marginBottom: 15,
    fontWeight: 400,
    margin: '0 auto',
    textTransform: 'capitalize',
    width: '90%',
    '&:hover': {
      backgroundColor: '#003265',
    },
  },
  sliderContainer: {
    height: '15px',
    backgroundImage:
      'linear-gradient(90deg, #26a4ba 0%, #85c169 25%, #cbcb2f 50%, #d0aa21 75%, #da7910 100%)',
  },
  sliderPointContainer2: {
    position: 'absolute',
    marginTop: '-18px',
    marginLeft: '260px',
  },
  sliderPointText: {
    fontSize: '12px',
    color: '#ffffff',
    fontFamily: 'Lato',
    fontWeight: 700,
    textAlign: 'center',
    marginTop: '-29px',
    marginLeft: '-6px',
  },
  sliderPointLine: {
    height: '16px',
    marginLeft: '1px',
  },
  sliderPoint: {
    width: '18px',
    marginLeft: '-7px',
  },
  customSelect: {
    width: '100%',
    textAlign: 'left',
    border: '1px solid #c4c4c4',
    padding: '10px',
    borderRadius: '3px',
    backgroundColor: '#fff',
  },
  btnNewApp: {
    backgroundColor: '#003265',
    color: '#fff',
    marginLeft: '50px',
    '&:hover': {
      backgroundColor: '#003265',
    },
  },
});
const names = ['Medicine Name', 'Medicine'];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

AppointmentDetails.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default function AppointmentDetails(props) {
  const classes = useStyles();
  const [appointmentId, setAppointmentId] = React.useState('');
  const [notes, setNotes] = React.useState('');
  const [clientId, setClientId] = useState('');
  const [appointmentForm, setAppointmentForm] = useState([]);

  //   const [seconds, setSeconds] = useState('');
  const [duration, setDuration] = useState(0);
  const [appointment, setAppointment] = useState('');
  const [assessment, setAssessment] = useState('');
  const [medicines, setMedicines] = useState([]);
  const [selectedMedicines, setSelectedMedicines] = useState([]);
  const [isLoaded, setIsloaded] = React.useState(true);
  const [error, setError] = useState(true);
  const [patientDrawer, setPatientDrawer] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [seconds, setSeconds] = useState(60);
  const [min, setMin] = useState(0);
  const [time, setTime] = useState(new Date().toLocaleTimeString());
  const [isSessionValid, setIsValidateSession] = useState(true);
  const [initialFormData, setInitialFormData] = useState([]);

  const theme = useTheme();

  const togglePatientDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setPatientDrawer(open);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      const date = new Date();
      setTime(date.toLocaleTimeString());
      const newSc = seconds - 1 === 0 ? 60 : seconds - 1;
      setSeconds(newSc);
      setMin((min) => {
        const newMin = seconds - 1 === 0 ? min - 1 : min;
        return newMin;
      });
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [time]);

  useEffect(() => {
    setIsloaded(true);
    const url = window.location.href;
    const id = url.substring(url.lastIndexOf('/') + 1);
    setAppointmentId(id);
    getAppointmentDetails(id);
  }, []);

  const getAppointmentDetails = (id) => {
    Axios.get(`${baseURL}/api/appointment/${id}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        setAppointment(res.data);
        setIsValidateSession(isSessionTimeValid(res.data.startTime));
        var tempData = [];
        res.data.clientId &&
          res.data.clientId.map((val, i) => {
            var data = {
              appointmentId: res.data._id,
              clientId: val._id,
              client: val.clientId,
              notes: '',
              diagnosis: [],
              prescription: [],
              status: isSessionValid ? 'completed' : '',
            };

            tempData.push(data);
          });

        if (res.data.groupType !== 'child') {
          tempData = [
            {
              appointmentId: res.data._id,
              clientId: res.data.clientId[0]._id,
              client: res.data.clientId[0],
              notes: '',
              diagnosis: [],
              prescription: [],
              status: isSessionValid ? 'completed' : '',
            },
          ];
        }

        setInitialFormData(tempData);
        setMin(res.data.duration - 1);
        setSeconds(59);
        setIsloaded(false);
        var totalListForm = [];
        if (res.data.clientId.length > 1) {
          res.data.clientId.map((val, i) => {
            totalListForm.push({
              appointmentId: id,
              notes: '',
              diagnosis: '',
              medication: [],
              clientId: res.data.clientId[i]._id,
            });
          });
        } else {
          totalListForm.push({
            appointmentId: id,
            notes: '',
            diagnosis: '',
            medication: [],
            clientId: res.data.clientId[0]._id,
          });
        }
        setAppointmentForm(totalListForm);
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const finishSession = () => {
    var params = [...initialFormData];

    updateData(params);
  };

  const updateData = (params) => {
    const axiosConfig = {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    };

    Axios.post(`${baseURL}/api/client/addPatientRecord`, params, axiosConfig)
      .then((res) => {
        AlertMsg('success', 'Appointment completed successfully.');
        localStorage.removeItem('startedSessionId');
        props.history.push('/clinician/dashboard');
      })
      .catch((e) => {
        AlertMsg('error', e.message);
      });
  };

  return (
    <>
      <SidebarClinician />
      <div
        className='main-content'
        style={{ paddingLeft: '300px', width: '100%' }}
      >
        <Drawer
          style={{ marginTop: '-30px' }}
          className={classes.drawe}
          variant='permanent'
          classes={{
            paper: `${classes.drawerPaper} sideDrawer`,
          }}
          id='sideBar'
        >
          <SidebarMenu />
        </Drawer>
        {isLoaded ? (
          <Grid container style={{ paddingLeft: '45%', paddingTop: '10%' }}>
            <Loader />
          </Grid>
        ) : (
          <Grid container>
            <div className={classes.toolbar}>
              <Grid
                container
                direction='row'
                style={{ padding: '24px' }}
                justify='space-between'
              >
                <Grid item xs={9} style={{ textAlign: 'left', color: '#fff' }}>
                  <Typography>
                    Appointment ID:
                    <span style={{ color: '#2a70b7' }}>
                      {' '}
                      {appointment._id}
                    </span>{' '}
                    Active Session
                  </Typography>
                  <h2 style={{ margin: 0, fontWeight: '100' }}>
                    {min}
                    min {seconds}
                    secs
                  </h2>
                </Grid>
                <Grid item xs={3} style={{ position: 'relative' }}>
                  <Button
                    variant='contained'
                    className={classes.customBtn}
                    onClick={finishSession}
                  >
                    End Session
                  </Button>
                </Grid>
              </Grid>
            </div>
            <Grid style={{ width: '100%', padding: '12px' }}>
              <AppointmentUserSession
                updateInitialFormData={(data) => setInitialFormData(data)}
                initialFormData={initialFormData}
              />
              <Grid>
                <Button
                  variant='contained'
                  onClick={finishSession}
                  className={classes.customBtn2}
                  disabled={
                    initialFormData[0].notes.trim() === '' &&
                    initialFormData[0].sessionCompleted === 'yes'
                  }
                >
                  End Session
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
}
