import { createStyles} from "@material-ui/core";

export default (theme) =>
  createStyles({
    heading: {
      fontSize: 43,
      fontWeight: 600,
      textAlign: "center",
      color: "#333",
      fontFamily: "Open Sans, Helvetica, Arial, sans-serif !important",
      paddingTop: 35,
      letterSpacing: 1,
    },
    root: {
      flexGrow: 1,
    },
    gridheading: {
      marginTop: theme.spacing(5),
      fontSize: 20,
      color: "#333",
      marginBottom: 12,
      fontWeight: 600,
      lineHeight: 1.25,
      textAlign: "left",
      fontFamily: "Open Sans, Helvetica, Arial, sans-serif !important",
      paddingLeft: theme.spacing(15),
    },
    checkicon: {
      color: "#4DC800",
      marginRight: theme.spacing(2),
      fontFamily: "Marketing",
      fontSize: 25,
      fontWeight: 600,
      marginLeft: theme.spacing(15),
    },
    boxtext: {
      fontSize: 18,
      textTransform: "capitalize",
      color: "#333",
      fontWeight: "normal",
      marginBottom: 6,
      fontFamily: "Open Sans, Helvetica, Arial, sans-serif !important",
    },
    button: {
      backgroundColor: "#FF9900",
      color: "#ffffff",
      fontSize: 17,
      width: 245,
      padding: theme.spacing(1.5),
      borderRadius: 4,
      fontWeight: 600,
      marginTop: 10,
      textDecoration: "normal",
      textTransform: "capitalize",
      marginLeft: theme.spacing(15),
    },
    gridimage: {
      width: "110%",
      marginTop: theme.spacing(5),
    },
    gridsub: {
      textAlign: "left",
      marginTop: theme.spacing(2),
      // display: 'inline-block',
    },
    bottomtext: {
      fontSize: 18,
      padding: theme.spacing(1.5),
      fontWeight: "normal",
      marginTop: 10,
      textDecoration: "normal",
      textTransform: "lowercase",
      marginLeft: theme.spacing(15),
      textAlign: "center",
      width: 245,
      fontFamily: "Open Sans, Helvetica, Arial, sans-serif !important",
      color: "#747474",
    },
    hipaaimg: {
      paddingLeft: theme.spacing(10),
      marginTop: theme.spacing(2),
      width: "100%",
    },
  });
