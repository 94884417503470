import { createStyles } from "@material-ui/core";
import { descFont, nameFont, titleFont } from '../../../assets/fontSize';
export default (theme) =>
  createStyles({
    inputText: {
      fontSize: '22px',
      lineHeight: "28px",
      marginBottom: "15px",
      color: '#003265',
      padding: '5px',
      paddingLeft: 0,
      textAlign: 'left',
    },
    paper: {
      padding: '20px',
      height: '100%',
      boxShadow: 'none',
      borderRadius: 0
    },
    root: {
      backgroundColor: '#cfd9df',
      width: '100%',
      height: '230%',
      opacity: '20%',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    titleContainer: {
      position: 'absolute',
      // width: "800px",
      zIndex: 1,
      [theme.breakpoints.up('xs')]: {
        width: '300px',
        top: '150px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '500px',
        top: '150px',
      },
      [theme.breakpoints.up('md')]: {
        width: '800px',
        top: '202px',
      },
    },
    container: {
      position: 'absolute',
      // width: "800px",
      zIndex: 1,
      [theme.breakpoints.up('xs')]: {
        width: '300px',
        top: '80px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '500px',
        top: '90px',
      },
      [theme.breakpoints.up('md')]: {
        width: '800px',
        top: '132px',
      },
    },
    bodyContainer: {
      // width: "800px",
      marginTop: '36px',
      justifyContent: 'center',
      alignSelf: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('xs')]: {
        width: '300px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '500px',
      },
      [theme.breakpoints.up('md')]: {
        width: '800px',
      },
    },
    title: {
      // fontSize: "32px",
      color: '#304659',
      fontFamily: 'Lato',
      fontWeight: '700',
      [theme.breakpoints.up('xs')]: {
        fontSize: titleFont,
        textAlign: 'center',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: titleFont,
        textAlign: 'center',
      },
    },
    dividerLine: {
      [theme.breakpoints.up('xs')]: {
        width: '300px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '500px',
      },
      [theme.breakpoints.up('md')]: {
        width: '800px',
      },
    },
    // flex: {
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center",
    //   alignSelf: "center",
    //   marginTop: "37px",
    // },
    fieldContainer: {
      [theme.breakpoints.up('xs')]: {
        display: 'inline',
      },
      [theme.breakpoints.up('sm')]: {
        display: 'inline',
      },
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    nationalitySelect: {
      [theme.breakpoints.up('xs')]: {
        width: '261px',
        height: '30px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '440px',
        height: '40px',
      },
      [theme.breakpoints.up('md')]: {
        width: '500px',
        height: '55px',
      },
    },
    countrySelect: {
      [theme.breakpoints.up('xs')]: {
        width: '200px',
        height: '30px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '440px',
        height: '40px',
      },
      [theme.breakpoints.up('md')]: {
        width: '329px',
        height: '55px',
      },
    },
    customNationalityMobile: {
      [theme.breakpoints.up('xs')]: {
        display: 'inline',
        // marginLeft: '100px'
      },
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    customNationality: {
      [theme.breakpoints.up('xs')]: {
        display: 'none',
      },
      [theme.breakpoints.up('md')]: {
        display: 'inline',
        // marginLeft: '10px',
        width: '500px',
      },
    },
    phoneTypeContainer: {
      width: '100px',
      height: '10005px',
      marginTop: '50px',
      // [theme.breakpoints.up('xs')]: {
      //   width: '100px',
      //   height: "30px",
      // },
      // [theme.breakpoints.up('sm')]: {
      //   width: '440px',
      //   height: "40px",
      // },
      // [theme.breakpoints.up('md')]: {
      //   width: "329px",
      //   height: "55px",
      // },
    },
    line: {
      backgroundColor: '#000000',
      border: '1px',
      borderColor: '#707070',
      borderStyle: 'solid',
      // width: "800px",
      opacity: '100%',
      [theme.breakpoints.up('xs')]: {
        width: '300px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '500px',
      },
      [theme.breakpoints.up('md')]: {
        width: '800px',
      },
    },
    formcontainer: {
      flexDirection: 'column',
      marginTop: '9px',
      marginBottom: '9px',
    },
    gridwhite: {
      height: '81px',
      // paddingRight: "32px",
      backgroundColor: '#ffffff',
      opacity: '100%',
      // justifyContent: "center",
      alignItems: 'center',
      [theme.breakpoints.up('xs')]: {
        width: '300px',
        paddingLeft: '20px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '500px',
        paddingLeft: '29px',
      },
      [theme.breakpoints.up('md')]: {
        width: '800px',
        paddingLeft: '29px',
      },
    },
    gridcolor: {
      height: '81px',
      // paddingRight: "32px",
      backgroundColor: '#fafbfb',
      opacity: '100%',
      // justifyContent: "center",
      alignItems: 'center',
      [theme.breakpoints.up('xs')]: {
        width: '300px',
        display: 'flex',
        paddingLeft: '20px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '500px',
        paddingLeft: '29px',
      },
      [theme.breakpoints.up('md')]: {
        width: '800px',
        paddingLeft: '29px',
      },
    },
    infoTitle: {
      textAlign: 'left',
      // fontSize: "17px",
      // fontSize: "25px",
      color: '#304659',
      fontFamily: 'Lato',
      fontWeight: '700',
      textTransform: 'capitalize',
      width: '250px',
      fontSize: nameFont,
      [theme.breakpoints.up('md')]: {
        // fontSize: "25px",
        marginTop: '5px',
      },
    },
    text: {
      [theme.breakpoints.up('xs')]: {
        width: '240px',
        height: '30px',
        // fontSize: descFont,
        // fontSize: "15px",
      },
      [theme.breakpoints.up('sm')]: {
        width: '420px',
        height: '40px',
      },
      [theme.breakpoints.up('md')]: {
        width: '500px',
        height: '50px',
      },
      backgroundColor: '#ffffff',
      border: '1px',
      borderStyle: 'solid',
      borderColor: '#707070',
      cursor: 'text',
      // fontSize: "20px",
      fontSize: descFont,
      color: '#304659',
      fontFamily: 'Lato',
      fontWeight: '400',
      opacity: '100%',
      paddingLeft: '10px',
      paddingRight: '10px',
      outline: 0,
    },
    dateInput: {
      [theme.breakpoints.up('xs')]: {
        width: '100%',
        height: '30px',
        // fontSize: "15px",
      },
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        height: '40px',
      },
      [theme.breakpoints.up('md')]: {
        width: '100%',
        // fontSize: "20px",
        height: '50px',
      },
      backgroundColor: '#ffffff',
      fontSize: descFont,
      border: '1px',
      borderStyle: 'solid',
      borderColor: '#707070',
      cursor: 'text',
      color: '#304659',
      fontFamily: 'Lato',
      fontWeight: '400',
      opacity: '100%',
      display: 'inline-block',
      textAlign: 'center',
      paddingLeft: '23px',
      paddingRight: '14px',
    },
    radio: {
      width: '15px',
      height: '15px',
      border: '10px solid #EFE5CE',
    },
    mlable: {
      marginRight: '90px',
      fontSize: '20px',
      color: '#304659',
      fontFamily: 'Lato',
      fontWeight: 400,
      textTransform: 'capitalize',
    },
    labletext: {
      fontSize: descFont,
      // fontSize: "20px",
      color: '#304659',
      fontFamily: 'Lato',
      fontWeight: 400,
      textTransform: 'capitalize',
    },
    radiogrid: {
      paddingRight: '250px',
    },
    bottomcontainer: {
      marginTop: '66px',
    },
    country: {
      backgroundColor: '#c0d0de',
      width: '329px',
      height: '50px',
      border: '1px solid #707070',
      opacity: '20%',
      paddingLeft: '10px',
      paddingRight: '10px',
      marginRight: '190px',
      fontSize: '20px',
      color: '#304659',
      fontFamily: 'Lato',
      fontWeight: '400',
      outline: 0,
    },
    email: {
      fontSize: descFont,
      // fontSize: "20px",
      color: '#304659',
      fontFamily: 'Lato',
      fontWeight: 400,
      fontStyle: 'italic',
      justifyContent: 'flex-start',
      alignSelf: 'flex-start',
      textAlign: 'start',
      // paddingRight: "310px",
    },
    phonetype: {
      width: '149px',
      height: '50px',
      backgroundColor: '#c0d0de',
      border: '1px solid #707070',
      paddingLeft: '10px',
      paddingRight: '10px',
      opacity: '20%',
      marginRight: '370px',
    },
    cancelButton: {
      // opacity: "25%",
      width: '140px',
    },
    selectStyle: {
      display: 'flex',
    },
    nextButton: {
      marginLeft: 10,
      width: '140px',
    },
    cancelText: {
      position: 'relative',
      left: '-78px',
    },
    errorText: {
      marginLeft: '33%',
      color: 'red',
      fontFamily: 'Lato',
      fontWeight: 400,
      fontSize: 16,
    },
  });