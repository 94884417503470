import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Step, StepLabel } from '@material-ui/core';
import Axios from 'axios';
import validator from 'validator';
import { baseURL } from '../utils/axios';
import logo from '../assets/img/logo-tla-centered-light.svg';
import AlertMsg from '../utils/Alert';
import utils from 'js-utilities';
import { FormStyle, FormContainer, Form, Content } from './style';
import {
  FirstStep,
  SecondStep,
  FourthStep,
  FormForFutureEvents,
} from './Steps';
import OrganiserInfo from './OrganiserInfo';
import { Spinner } from '../components/atoms';
import moment from 'moment';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Stepper from '../components/atoms/Stepper';

const getTime = (time) => {
  const date = new Date(time);
  let hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
  const AmPm = date.getHours() >= 12 ? 'PM' : 'AM';
  hours = hours < 10 ? `0${hours}` : hours;
  const minutes =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const mTime = `${hours}:${minutes}${AmPm}`;
  return mTime;
};

const emptyAttendie = {
  firstName: '',
  lastName: '',
  dob: '',
  suffix: '',
  email: '',
  confirmemail: '',
  countryCode: "",
  phoneNumber: '',
  errors: '',
  hasSeat: true
}

const theme = createMuiTheme({
  overrides: {
    MuiStepIcon: {
      root: {
        color: 'rgb(163, 145, 97)',
        '&$active': {
          color: 'rgb(0, 58, 112)',
        },
        '&$completed': {
          color: 'rgb(0, 58, 112)',
        },
      },
      active: {},
      completed: {},
    },
  },
});
class EventRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpired: false,
      captchaToken: 'asdas',
      remainingSeatCapacity: 0,
      alreadyFilled: 0,
      id: '',
      clientAddress: {},
      attendies: [emptyAttendie],
      parent: null,
      errors: '',
      attendNo: 1,
      step: 1,
      eventData: null,
      loading: false,
      isUserAgree: false,
    };

    this.handleNext = this.handleNext.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleAttane = this.handleAttane.bind(this);
    this.handlePay = this.handlePay.bind(this);
    this.updateBillingAddress = this.updateBillingAddress.bind(this);
    this.onCaptchaChange = this.onCaptchaChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this)
    this.addParent = this.addParent.bind(this)
    this.removeParent = this.removeParent.bind(this)
  }

  componentDidMount() {
    const {
      match: {
        params: { id, billingId },
      },
    } = this.props;
    this.setState({ id, loading: true });

    console.log(billingId);

    if (billingId) {
      Axios.get(`${baseURL}/api/payment/${billingId}/event-payment-status`)
        .then((res) => {
          if (res.data) {
            console.log(res.data)
            this.setState({
              loading: false,
              eventData: res.data.invoice.eventId,
              attendies: res.data.attendies,
              step: 3,
              failed: res?.data?.payment?.status != "paid"
              // failed: true //res?.data?.payment?.status != "paid"
            });
          }
        })
        .catch(() => {
          this.props.history.push('/');
        });
    }

    else {
      this.getClientInfo();
      if (id) {
        Axios.get(`${baseURL}/api/event/internal/${id}`)
          .then((res) => {
            if (res.data) {
              const currTime = moment().toDate();
              const startingTime = moment(res.data.startTime).toDate();
              if (currTime > startingTime) {
                this.setState({
                  isExpired: true,
                });
              }

              var newState = {
                loading: false,
                eventData: res.data,
                remainingSeatCapacity:
                  res.data.totalSeats - res.data.numberOfAttendees,
                alreadyFilled:
                  res.data.totalSeats - res.data.numberOfAttendees <= 0,
              }

              if (!res.data.isParentsIncluded && res.data.isForChildren) {
                newState.parent = {
                  ...emptyAttendie,
                  hasSeat: res.data.isParentsIncluded
                }
              }

              this.setState(newState);
            }
          })
          .catch(() => {
            this.props.history.push('/');
          });
      }
    }
  }

  getClientInfo() {
    const clientId = new URL(window.location.href).searchParams.get('clientId');

    console.log('clientId', clientId);
    if (!clientId) {
      return;
    }

    Axios.get(`${baseURL}/api/client/get_user_info/${clientId}`)
      .then((res) => {
        console.log(res.data);
        this.setState({
          clientAddress: res.data.address || {},
          attendies: [
            {
              clientId: res.data._id,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              dob: new Date(res.data.dob),
              email: res.data.email,
              confirmemail: res.data.email,
              phoneNumber: res.data.phoneNumber ? res.data.phoneNumber : '971',
            },
          ],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  addParent() {
    const { attendies, remainingSeatCapacity, eventData, attendNo } = this.state;
    let value = Number(attendNo) + 1
    if (value > eventData.totalSeats - eventData.numberOfAttendees) {
      AlertMsg(
        'info',
        `Sorry, but there are only ${eventData.totalSeats - eventData.numberOfAttendees
        } number of seats left!`
      );
      return;
    }
    console.log(value)
    this.setState({
      attendNo: value,
      remainingSeatCapacity:
        eventData.totalSeats - eventData.numberOfAttendees - value,
      alreadyFilled: false,
      parent: {
        ...emptyAttendie,
        hasSeat: eventData.isParentsIncluded
      }
    });
  }

  removeParent() {
    const { attendies, remainingSeatCapacity, eventData, attendNo } = this.state;
    let value = Number(attendNo) - 1
    this.setState({
      attendNo: value,
      remainingSeatCapacity:
        eventData.totalSeats - eventData.numberOfAttendees - value,
      alreadyFilled: false,
      parent: null
    });
  }

  handleAttane(e) {
    const { attendies, remainingSeatCapacity, eventData } = this.state;

    var { value } = e.target;
    if (value > eventData.totalSeats - eventData.numberOfAttendees) {
      AlertMsg(
        'info',
        `Sorry, but there are only ${eventData.totalSeats - eventData.numberOfAttendees
        } number of seats left!`
      );
      return;
    }

    let isParent = this.state.parent != null
    var tempValue = isParent ? (eventData.isParentsIncluded ? value - 1 : value) : value
    tempValue = tempValue > 1 ? tempValue : 1;

    this.setState({
      attendNo: isParent && eventData.isParentsIncluded ? Number(tempValue) + 1 : tempValue,
      remainingSeatCapacity:
        eventData.totalSeats - eventData.numberOfAttendees - value,
      alreadyFilled: false,
    });

    if (value > attendies.length) {
      const data = Array.from({ length: tempValue - attendies.length }, () => (emptyAttendie));
      this.setState({ attendies: [...attendies, ...data] });
    } else {
      this.setState({ attendies: attendies.slice(0, tempValue) });
    }
  }

  handleChange(index, target, isParent) {
    const { id, attendies, parent } = this.state;

    if (isParent) {
      const parentTemp = {
        ...parent,
        [target.name]: target.value,
        eventId: id,
      };
      this.setState({ parent: parentTemp })
    } else {
      const attendie = {
        ...attendies[index],
        [target.name]: target.value,
        eventId: id,
      };
      attendies[index] = attendie;
      this.setState({ attendies });
    }
  }

  handleNext() {
    const { step, attendies, eventData, captchaToken, isUserAgree } =
      this.state;

    let error = false;
    var errorMessage = 'Please fill out all the required fields';

    if (step === 1) {
      attendies.forEach((attendie) => {
        error =
          attendie.firstName === '' ||
          attendie.lastName === '' ||
          // attendie.email === '' ||
          // attendie.confirmemail === '' ||
          attendie.dob === '' //||
        // attendie.phoneNumber === '' ||
        // attendie.phoneNumber.length < 4 ||
        // !validator.isEmail(attendie.email) ||
        // attendie.email !== attendie.confirmemail;

        if (attendie.email !== attendie.confirmemail)
          errorMessage = 'Email does not match';
        if (!error) {
          if (
            utils.time.calculateAge(attendie.dob) <
            parseInt(this.state.eventData.ageRange.min) ||
            utils.time.calculateAge(attendie.dob) >
            parseInt(this.state.eventData.ageRange.max)
          ) {
            errorMessage = `Event age range is ${this.state.eventData.ageRange.min +
              '-' +
              this.state.eventData.ageRange.max
              } years`;
            error = true;
          }
        }
      });

      if (error) {
        AlertMsg('error', errorMessage);
        console.log(errorMessage)
        return;
      }

      if (!isUserAgree) {
        AlertMsg(
          'error',
          'User need to agree the terms & condition in order to proceed'
        );
        return;
      }

      if (!captchaToken) {
        AlertMsg(
          'error',
          'Please the captcha to verify that you are not a robot!'
        );
        return;
      }
    }

    if (eventData.isFree && step === 2) {
      this.handlePay({});
    } else {
      this.setState((prevState) => ({ step: prevState.step + 1 }));
    }
  }

  handlePay() {
    const { id, attendies, parent } = this.state;

    var payload = {
      users: parent != null ? [parent, ...attendies] : attendies
    };

    var users = payload.users
    users.forEach(data => {
      data.phoneNumber = data.countryCode + data.phoneNumber
    })

    payload.users = users

    this.setState({ loading: true });
    Axios.post(`${baseURL}/api/event/register/${id}`, payload)
      .then((res) => {
        this.setState({ step: 3, loading: false });
      })
      .catch(() => { });
  }

  handleBack() {
    this.setState((prevState) => ({ step: prevState.step - 1 }));
  }

  onCaptchaChange(value) {
    this.setState({ captchaToken: value });
  }

  updateBillingAddress(data) {
    this.setState({
      billingAddress: data,
      step: 3,
    });
  }

  onSubmit(data) {
    const { parent } = this.state;
    const { history: { push } } = this.props;
    var payload = data
    var users = data.attendies
    users = parent != null ? [parent, ...users] : users
    users.forEach(data => {
      data.phoneNumber = data.countryCode + data.phoneNumber
    })
    console.log(users)
    payload.attendies = users

    Axios.post(`${baseURL}/api/payment/paypal-events`, payload)
      .then((res) => {
        if (res.data) {
          console.log(res.data.billingId, this.state.eventData._id)
          this.setState({
            loading: false,
            eventData: res.data.invoice.eventId,
            attendies: res.data.attendies,
            step: 3,
            failed: res?.data?.payment?.status != "paid"
          });
          push(`/eventregistration/${this.state.eventData._id}/${res.data.invoice._id}`);
        }
      })
      .catch((e) => {
        console.log(e)
        AlertMsg("error", "Something went wrong")
      });
  }

  yieldStep(
    classes,
    attendies,
    step,
    eventData,
    errors,
    attendNo,
    clientAddress
  ) {
    switch (step) {
      case 1:
        return (
          <FirstStep
            classes={classes}
            attendies={attendies}
            addParent={this.addParent}
            removeParent={this.removeParent}
            eventData={eventData}
            handleAttane={this.handleAttane}
            handleChange={this.handleChange}
            handleNext={this.handleNext}
            parent={this.state.parent}
            attendNo={attendNo}
            maxAge={this.state.eventData.ageRange.max}
            onCaptchaChange={this.onCaptchaChange}
            isUserAgree={this.state.isUserAgree}
            togglePolicyAgreement={() =>
              this.setState((prevState) => ({
                isUserAgree: !prevState.isUserAgree,
              }))
            }
          />
        );
      case 2:
        return (
          <SecondStep
            attendies={attendies}
            classes={classes}
            eventData={eventData}
            parent={this.state.parent}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            updateBillingAddress={this.updateBillingAddress}
            attendie={{ ...this.state.attendies[0], ...clientAddress }}
            attendies={attendies}
            entityId={eventData._id}
            entity='event'
            amount={this.state.attendies.length * eventData.fee}
            onSubmit={this.onSubmit}
            onCancel={this.handleBack}
          />
        );
      default:
        return (
          <FourthStep
            classes={classes}
            isFree={eventData.isFree || eventData.fee === 0}
            eventData={eventData}
            attendies={attendies}
            failed={this.state.failed}
          />
        );
    }
  }

  render() {
    const { classes } = this.props;
    const {
      attendies,
      step,
      eventData,
      errors,
      attendNo,
      remainingSeatCapacity,
      alreadyFilled,
      clientAddress,
    } = this.state;

    return (
      <div class="bg-blue-50 px-4 h-screen leading-normal" >
        {this.state.loading && <Spinner isOpen />}
        <div class="flex flex-row flex-wrap gap-4 w-full max-w-7xl my-4 mx-auto lg:flex-nowrap lg:gap-1">
          <div class="item col-left w-full order-2 bg-white lg:w-3/6 lg:order-1">
            <div className="text-center block p-4 py-8">
              <img
                className="inline-block w-8/12 lg:w-4/12"
                src={logo}
                alt
              />
            </div>

            {!this.state.isExpired && (
              <Stepper step={step - 1} totalSteps={3} />
            )}

            {this.state.isExpired ? (
              <Grid>Event Registration date has expired!</Grid>
            ) : alreadyFilled && remainingSeatCapacity <= 0 ? (
              <Grid>
                <FormForFutureEvents
                  history={this.props.history}
                  eventId={this.props.match?.params?.id}
                />
              </Grid>
            ) : this.state.eventData?.registrationStillOpen ? (
              this.yieldStep(
                classes,
                attendies,
                step,
                eventData,
                errors,
                attendNo,
                clientAddress
              )
            ) : (
              <Grid>Event Registration has been closed!</Grid>
            )}
          </div>

          <OrganiserInfo
            classes={classes}
            eventData={eventData}
            errors={errors}
            getTime={getTime}
          />
        </div>
      </div>
    );
  }
}

EventRegistration.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    img: PropTypes.string.isRequired,
  }).isRequired,
};
export default withStyles(FormStyle)(EventRegistration);
