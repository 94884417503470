import { makeStyles } from '@material-ui/core/styles';


export default makeStyles({
    root: {
      //   maxWidth: 255,
      margin: 10,
      boxShadow: 'none',
    },
    media: {
      height: 140,
    },
    imgCardAvtar: {
      width: '70px',
      height: '70px',
    },
    horizontalLine: {
      color: '#dededf',
      border: '1px solid',
    },
    btnViewDetail: {
      margin: '0 auto',
      textTransform: 'capitalize',
      fontSize: '17px',
    },
    paddingCustom: {
      padding: '20px',
    },
    headerTest: {
      color: '#304659',
      marginBottom: '15px',
    },
    dbSummaryInner: {
      padding: '28px',
      background: '#ffffff',
      '& .media': {
        display: 'flex',
        '& img': {
          marginRight: '20px',
          height: '40px',
        },
        '& h5': {
          fontWeight: '400',
          margin: '0px',
          fontSize: '12px',
          lineHeight: '17px',
          width: '100%',
          '& span': {
            display: 'inline-block',
            marginLeft: '10px',
            color: '#ffffff',
            padding: '1px 4px',
            borderRadius: '4px',
          },
        },
        '& .today': {
          display: 'flex',
          alignItems: 'center',
          '& span': {
            fontSize: '30px',
            lineHeight: '34px',
            display: 'inline-block',
          },
          '& .text': {
            fontSize: '14px',
            color: '#B3B3B3',
            marginLeft: '7px',
          },
        },
      },
    },
  });