import {makeStyles, withStyles} from '@material-ui/core/styles';

export default makeStyles({
    toolbar: {
      width: '100%',
      height: '100px',
      backgroundColor: '#003265',
    },
    root: {
      maxWidth: 255,
    },
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
    inputstyle: {
      backgroundColor: '#fff',
    },
    table: {
      minWidth: 650,
    },
    imgTable: {
      width: '40px',
      height: '40px',
      objectFit: 'contain',
      borderRadius: '50px',
    },
    viewBtn: {
      borderRadius: '15px',
      width: '100px',
      padding: '4px',
      marginLeft: '8px',
      boxShadow: 'none',
      textTransform: 'capitalize',
      // '&:hover': {
      //   backgroundColor: '#003265',
      // },
    },
    sliderContainer: {
      height: '50px',
      backgroundImage:
            'linear-gradient(90deg, #26a4ba 0%, #85c169 25%, #cbcb2f 50%, #d0aa21 75%, #da7910 100%)',
    },
    sliderPointContainer2: {
      position: 'absolute',
      marginTop: '-18px',
    },
    sliderPointText: {
      fontSize: '12px',
      color: '#ffffff',
      fontFamily: 'Lato',
      fontWeight: 700,
      textAlign: 'center',
      marginTop: '-22px',
      marginLeft: '-3px',
  
    },
    sliderPointLine: {
      height: '50px',
      marginLeft: '3px',
    },
    sliderPoint: {
      width: '18px',
      marginLeft: '-2px',
    },
});
