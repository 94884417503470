import React, { PureComponent } from 'react';
import {  withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import DatePicker, { registerLocale } from 'react-datepicker';
// import en from 'date-fns/locale/en';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { CustomCancelButtons, CustomNextButtons } from '../../common/CustomButtons';

import defaultSideImage from '../../assets/imgs/clinician1.png';
import CustomRadio from '../../common/RadioButton';
import { FormInput } from './forms/AddChildForm';
import Header from '../../common/Header';
import { Loader } from "../Loader";
import { isEmail, getAgeByDob } from "../../utils/validators";
import { connect } from 'react-redux';
import { reduxForm } from "redux-form";
import { getChildAction , addAdultAction } from '../../utils/actions/client.action';
import AlertMsg from '../../utils/Alert';
import styles from "./MultiplePersonStyle";
import { adultAge } from '../../utils/timUtils';

// registerLocale('en', en);

class MultiplePerson extends PureComponent {

    constructor(props) {
      super(props);

      this.state = {
        leftSelect: false,
        rightSelect: false,
        addPersonModel: false,
        setMultiplePerson: [],
        setPerson: [],
        setLeftPerson: null,
        setRightPerson: null,
        isOpenBox: false,
        getDataLoader: true,
        firstName: "",
        lastName: "",
        dob: null,
        sex: "",
        email: "",
        re_email:"",
        errors: {
          firstName: "",
          lastName: "",
          email: "",
          re_email: "",
          dob: "",
          sex: "",
        },
      };
    }

    componentDidMount() {
      localStorage.removeItem("multipleAdults");
      this.props.getChildAction();
    };

    componentDidUpdate(preProps) {
      if (this.props.getAdult !== preProps.getAdult) {
        if (this.props.getAdult.getChildData) {
          const data = this.props.getAdult.getChildData;
          let adults = [];
          for(let item of data){
            if(item.relation === "Adult"){
              adults.push(item.client);
            }
          }
          this.setState({
            setPerson: adults,
            getDataLoader: false,
          });
        }
      }

      if(this.props.addAdult !== preProps.addAdult){
        if(this.props.addAdultData){
          this.setState({
            isOpenBox: false,
            firstName: "",
            lastName: "",
            dob: null,
            sex: "",
            email: "",
            re_email:"",
            setMultiplePerson: [...this.state.setMultiplePerson, this.props.addAdultData]
          });
        }
      }
    }

    handleVerify = () => {
      const { firstName, lastName, email, re_email, dob, sex } = this.state;
      const errors = { ...this.state.errors };
      errors.firstName = (!firstName) ? "Please enter first name": "";
      errors.lastName = (!lastName) ? "Please enter last name": "";
      errors.email = !email ? "Please enter email address" : !isEmail.test(email) ? "Please enter valid email address" : "";
      errors.re_email = !re_email ? "Please re enter email address" : (re_email !== email) ? "Confirm email must be same as a email!" : "";
      errors.sex = !sex ? "Please choose sex" : "";
      errors.dob = !dob ? "Please select date of birth" : (getAgeByDob(this.state.dob) < adultAge) ? `Date of birth must be more than ${adultAge} years!` : null;
      this.setState({ errors });
      if(!errors.firstName && !errors.lastName &&  !errors.dob && !errors.sex && !errors.email && !errors.re_email){
        return true;
      }else{
        return false
      }
    };

    handleSubmit = (e) => {
      e.preventDefault();
      const {
        firstName,
        lastName,
        email,
        dob,
        sex,
      } = this.state;

      let isVerify = this.handleVerify();
      console.log('isVerify: ', isVerify);

      if (isVerify) {
        const data = {object: [
          {
            email,
            dob,
            sex,
            firstName,
            lastName,
          }
        ]};
        this.props.addAdultAction(data);
      }
    }

    handleInput = (event) => {
      const {name, value} = event.target
      this.setState({
        [name]: value
      });
    }

    handleLeft = (id) => {
      this.setState({
        leftSelect: true,
        rightSelect: false,
        setLeftPerson: id,
      }, () => {
        //   this.handleRight();
      });
    }

    handleRight = (id) => {
      this.setState({
        rightSelect: true,
        leftSelect: false,
        setRightPerson: id,
      });
    }

    handleClickOpen = () => {
      this.setState({
        isOpenBox: true,
      });
    };

    handleClose = () => {
      this.setState({
        isOpenBox: false,
      });
    };

    shiftToRight = () => {
      const t = this.state.setLeftPerson;
      const tempArray = this.state.setMultiplePerson;
      tempArray.push(this.state.setPerson[t]);
      this.state.setPerson.splice(t, 1);
      this.setState({
        setMultiplePerson: tempArray,
        setPerson: this.state.setPerson,
      },() => {
          this.handleRight();
          this.setState({
            leftSelect: false,
            rightSelect: false,
          });
      });
    };

    shiftToLeft = () => {
      const t = this.state.setRightPerson;
      const tempArray = this.state.setPerson;
      tempArray.push(this.state.setMultiplePerson[t]);
      this.state.setMultiplePerson.splice(t, 1);
      this.setState({
        setPerson: tempArray,
        setMultiplePerson: this.state.setMultiplePerson,
      },() => {
          this.handleLeft();
          this.setState({
            rightSelect: false,
            leftSelect: false,
          });
      });
    };

    handleSelf = () => {
      if(!this.state.setMultiplePerson.length){
        AlertMsg("error", "Please select at least one person!");
        return null;
      }
      localStorage.setItem('appFor', 'multiple');
      localStorage.setItem('multipleAdults', JSON.stringify(this.state.setMultiplePerson));
      this.props.history.push('/assessment-que?type=multiple');
    };

    render() {
      const { classes } = this.props;
      const {
        dob,
        firstName,
        sex,
        lastName,
        email,
        re_email,
        errors,
      } = this.state;
      return (
        <div>
          <Header props={this.props} />
          <div className={classes.root} />
          <Grid container justify="center" alignItems="center">
            <Grid className={classes.titleContainer}>
              <Grid
                container
                justify="center"
              >
                <Grid item className={classes.container}>
                  <Grid>
                    <Typography className={classes.title}>
                      Select Multiple Persons
                    </Typography>
                    <Grid
                      container
                      justify="center"
                      className={classes.bodyContainer}
                    >
                      <Grid xs={5}>
                        <Grid container direction="row">
                          <Grid xs={8}>
                            <Typography style={{ textAlign: 'left', lineHeight: '2rem' }}>
                              Previously connected accounts
                            </Typography>
                          </Grid>
                          <Grid xs={4}>
                            <Button variant="contained" className={classes.addBtn} color="primary" onClick={this.handleClickOpen}>
                              <PersonAddIcon style={{ fontSize: '22px', marginRight: '5px' }} />
                              Add New
                            </Button>
                          </Grid>
                        </Grid>
                        <Paper className={classes.paper}>
                          {this.state.getDataLoader ?
                            <div style={{padding: "124px 172px"}}><Loader /></div>
                            :
                            this.state.setPerson.map((person, index) => {
                              return person ? <Grid container direction="row" style={{ backgroundColor: this.state.setLeftPerson == index ? '#F5F5F5' : '' }} className={classes.personBox}>
                                <Button onClick={() => { this.handleLeft(index); }} className={classes.personBtn}>
                                  <img src={person.avatar && person.avatar ? person.avatar : defaultSideImage } className={classes.imgTable} />
                                  <div>
                                    <Typography className={classes.nameTitle}>
                                      {person.firstName} {person.middleName} {person.lastName}
                                    </Typography>
                                  </div>
                                </Button>
                              </Grid> : <></>
                            })
                          }
                        </Paper>
                        <div style={{ textAlign: 'right' }}>
                          <CustomCancelButtons
                            title="Cancel"
                            width="170px"
                            style={classes.cancelButton}
                            onPressButton={() => this.props.history.goBack()}
                          />
                        </div>
                      </Grid>
                      <Grid xs={1} className={classes.middleBox} style={{ position: 'relative' }}>
                        <div className={classes.verticalMiddle}>
                          <div>
                            <Button className={classes.arrowBox} disabled={!this.state.leftSelect} onClick={this.shiftToRight}>
                              <DoubleArrowIcon style={{ fontSize: '22px' }} />
                            </Button>
                          </div>
                          <div>
                            <Button className={classes.arrowRotateBox} disabled={!this.state.rightSelect} onClick={this.shiftToLeft}>
                              <DoubleArrowIcon style={{ fontSize: '22px' }} />
                            </Button>
                          </div>
                        </div>
                      </Grid>
                      <Grid xs={5}>
                        <Grid container direction="row" style={{ minHeight: '36px' }}>
                          <Grid xs={12}>
                            <Typography style={{ textAlign: 'left' }}>
                              Person(s) attached to new appointment
                            </Typography>
                          </Grid>
                        </Grid>
                        <Paper className={classes.paper}>
                          {this.state.setMultiplePerson.map((person, index) => (
                            <Grid container direction="row" style={{ backgroundColor: this.state.setRightPerson == index ? '#D8F3EC' : '#e1fdf6' }} className={classes.personBox}>
                              <Button onClick={() => { this.handleRight(index); }} className={classes.personBtn}>
                                <img src={person.avatar && person.avatar ? person.avatar : defaultSideImage } className={classes.imgTable} />
                                <div>
                                  <Typography className={classes.nameTitle}>
                                    {person.firstName} {person.middleName} {person.lastName}
                                  </Typography>
                                </div>
                              </Button>
                            </Grid>
                          ))}
                        </Paper>
                        <CustomNextButtons
                          width="170px"
                          title="Next"
                          style={classes.nextButton}
                          onClick={() => this.handleSelf()}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <React.Fragment>
            <Dialog
              open={this.state.isOpenBox}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              style={{overflow: "hidden"}}
            >
              <DialogContent>
                <Grid>
                  <Grid>
                    <Typography className={classes.title}>
                      Enter New Person Details
                    </Typography>
                  </Grid>
                  <Grid>
                    <form onSubmit={this.handleSubmit} style={{ marginTop: 30, marginBottom: 30 }}>
                      <Grid
                        container
                      >
                        <Grid>
                          <FormInput
                            style={{
                              border: '1px solid #87ccf5',
                              borderRadius: '6px',
                              marginTop: '16px',
                            }}
                            value={firstName}
                            type="text"
                            name="firstName"
                            onChange={this.handleInput}
                            placeholder="First Name"
                          />
                        </Grid>
                        <span className={classes.errorText}>
                          {errors.firstName && errors.firstName}
                        </span>
                      </Grid>

                      <Grid
                        container
                      >
                        <Grid>
                          <FormInput
                            style={{
                              border: '1px solid #87ccf5',
                              borderRadius: '6px',
                              marginTop: '16px',
                            }}
                            value={lastName}
                            type="text"
                            onChange={this.handleInput}
                            placeholder="Last Name"
                            name="lastName"
                          />
                        </Grid>
                        <span className={classes.errorText}>
                          {errors.lastName && errors.lastName}
                        </span>
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        style={{marginTop:"16px"}}
                        >
                        <Grid xs={5}>
                            <DatePicker
                              locale='en'
                              style={{marginTop:"10px", marginLeft:"10px"}}
                              className={classes.dateInput}
                              placeholderText={"Date of birth"}
                              dateFormat="MMMM d, yyyy "
                              selected={dob}
                              showYearDropdown={true}
                              dropdownMode="select"
                              showMonthDropdown={true}
                              onChange={(date) => (
                                this.setState({ dob: date })
                              )}
                            /><br/>
                            <span className={classes.errorText}>
                              {errors.dob && errors.dob}
                            </span>
                        </Grid>
                        <Grid  xs={6}>
                          <Grid className={classes.fieldContainer} style={{padding:"5px 0 5px 0"}}>
                            <RadioGroup
                              style={{ display: "flex", flexDirection: "row", marginLeft:"10px" }}
                              name="sex"
                              value={sex}
                              name="sex"
                              onChange={this.handleInput}
                            >
                                <FormControlLabel
                                  value="male"
                                  checked={sex === "male" && true}
                                  control={<CustomRadio />}
                                  label={<Typography className={classes.labletext}>Male</Typography>}
                                />
                                <FormControlLabel
                                  style={{ marginLeft: "35px" }}
                                  value="female"
                                  checked={sex === "female" && true}
                                  control={<CustomRadio />}
                                  label={
                                    <Typography className={classes.labletext}>
                                      Female
                                    </Typography>
                                  }
                                />
                            </RadioGroup>
                          </Grid>
                          <span className={classes.errorText}>
                            {errors.sex && errors.sex}
                          </span>
                      </Grid>
                    </Grid>
                      <Grid
                        container
                      >
                        <Grid>
                          <FormInput
                            style={{
                              border: '1px solid #87ccf5',
                              borderRadius: '6px',
                              marginTop: '16px',
                            }}
                            value={email}
                            type="email"
                            name="email"
                            onChange={this.handleInput}
                            placeholder="Email Address"
                          />
                        </Grid>
                        <span className={classes.errorText}>
                          {errors.email && errors.email}
                        </span>
                      </Grid>
                      <Grid
                        container
                      >
                        <Grid>
                          <FormInput
                            style={{
                              border: '1px solid #87ccf5',
                              borderRadius: '6px',
                              marginTop: '16px',
                            }}
                            value={re_email}
                            type="email"
                            name="re_email"
                            onChange={this.handleInput}
                            placeholder="Re-enter Email Address"
                          />
                        </Grid>
                        <span className={classes.errorText}>
                          {errors.re_email && errors.re_email}
                        </span>
                      </Grid>
                      <Grid container direction="row" justify="space-between" style={{ marginTop: '24px' }}>
                        <Grid xs={5}>
                          <Button onClick={this.handleClose} className={classes.formBtn}>
                            Cancel
                          </Button>
                        </Grid>
                        <Grid xs={5}>
                          <Button type="submit" className={classes.formBtn}>
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
          </React.Fragment>

        </div>
      );
    }
}


MultiplePerson.defaultProps = {
  getAdult: [],
};

const connected = connect(
  (state) => ({
    getAdult: state.getChild,
    addAdult: state.addAdult.request,
    addAdultData: state.addAdult.addAdultData
  }),
  {
    getChildAction,
    addAdultAction,
  },
)(MultiplePerson);

const formed = reduxForm({
  form: "addAdult",
})(connected);




export default withStyles(styles)(formed);
