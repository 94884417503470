// /* eslint-disable indent */
// /* eslint-disable react/jsx-filename-extension */
// /* eslint-disable no-unused-vars */
// import React from "react";
// import { makeStyles, createStyles } from "@material-ui/core/styles";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
// import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
// import PeopleIcon from "@material-ui/icons/People";
// import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
// import ListIcon from "@material-ui/icons/List";
// import NotificationsIcon from "@material-ui/icons/Notifications";
// import IconBarChart from "@material-ui/icons/BarChart";
// import { Link } from "react-router-dom";
// import { Typography, Grid } from "@material-ui/core";
// import SettingsIcon from "@material-ui/icons/Settings";

// import HouseIcon from "@material-ui/icons/House";

// const useStyles = makeStyles((theme) =>
//   createStyles({
//     toolbar: {
//       flexGrow: 1,
//       height: "100%",
//       position: "fixed",
//       top: 0,
//       left: 0,
//       bottom: 0,
//     },
//     appMenu: {
//       position: "relative",
//       zIndex: 1,
//       marginTop: "148px",
//       paddingLeft: "24px",
//       paddingRight: "29px",
//     },
//     bodyListContainer: {
//       display: "flex",
//       height: "60px",
//       width: "262px",
//       borderRadius: "5px",
//       marginTop: "9px",
//       marginBottom: "9px",
//       cursor: "pointer",
//       "&:hover": {
//         opacity: "20%",
//         backgroundColor: "#ffffff",
//       },
//     },
//     avatar: {
//       width: "30px",
//       height: "30px",
//       color: "#ffffff",
//     },
//     avatarContainer: {
//       justifyContent: "center",
//       position: "relative",
//       left: "10px",
//       top: "11px",
//     },
//     nameContainer: {
//       position: "relative",
//       left: "25px",
//       top: "15px",
//       overflow: "hidden",
//       textOverflow: "ellipsis",
//       whiteSpace: "nowrap",
//       textAlign: "left",
//     },
//     name: {
//       fontSize: "20px",
//       fontFamily: "Lato",
//       fontWeight: 400,
//       textAlign: "left",
//       color: "#ffffff",
//       textTransform: "capitalize",
//     },
//   })
// );

// function DeshboardMenu() {
//   const classes = useStyles();
//   const [open, setOpen] = React.useState(false);

//   function handleClick() {
//     setOpen(!open);
//   }

//   return (
//     <>
//       {/* <Grid component="div" className={classes.toolbar}> */}
//       {/* <Grid component="div" container justify="center" alignItems="center"> */}
//       {/* <Grid className={classes.appMenu}> */}
//       {/* <Grid item component="div" className={classes.bodyListContainer}>
//             <Grid component="div" className={classes.avatarContainer}>
//               <HouseIcon className={classes.avatar} />
//             </Grid>
//             <Grid component="div" className={classes.nameContainer}>
//               <Typography display="inline" className={classes.name}>
//                 home
//               </Typography>
//             </Grid>
//           </Grid>

//           <Grid item component="div" className={classes.bodyListContainer}>
//             <Grid component="div" className={classes.avatarContainer}>
//               <HouseIcon className={classes.avatar} />
//             </Grid>
//             <Grid component="div" className={classes.nameContainer}>
//               <Typography display="inline" className={classes.name}>
//                 Appointments
//               </Typography>
//             </Grid>
//           </Grid>

//           <Grid item component="div" className={classes.bodyListContainer}>
//             <Grid component="div" className={classes.avatarContainer}>
//               <HouseIcon className={classes.avatar} />
//             </Grid>
//             <Grid component="div" className={classes.nameContainer}>
//               <Typography display="inline" className={classes.name}>
//                 doctors
//               </Typography>
//             </Grid>
//           </Grid>

//           <Grid item component="div" className={classes.bodyListContainer}>
//             <Grid component="div" className={classes.avatarContainer}>
//               <HouseIcon className={classes.avatar} />
//             </Grid>
//             <Grid component="div" className={classes.nameContainer}>
//               <Typography display="inline" className={classes.name}>
//                 Prescriptions
//               </Typography>
//             </Grid>
//           </Grid>

//           <Grid item component="div" className={classes.bodyListContainer}>
//             <Grid component="div" className={classes.avatarContainer}>
//               <HouseIcon className={classes.avatar} />
//             </Grid>
//             <Grid component="div" className={classes.nameContainer}>
//               <Typography display="inline" className={classes.name}>
//                 Events & Seminars
//               </Typography>
//             </Grid>
//           </Grid>

//           <Grid item component="div" className={classes.bodyListContainer}>
//             <Grid component="div" className={classes.avatarContainer}>
//               <HouseIcon className={classes.avatar} />
//             </Grid>
//             <Grid component="div" className={classes.nameContainer}>
//               <Typography display="inline" className={classes.name}>
//                 Payment History
//               </Typography>
//             </Grid>
//           </Grid>

//           <Grid item component="div" className={classes.bodyListContainer}>
//             <Grid component="div" className={classes.avatarContainer}>
//               <HouseIcon className={classes.avatar} />
//             </Grid>
//             <Grid component="div" className={classes.nameContainer}>
//               <Typography display="inline" className={classes.name}>
//                 Settings
//               </Typography>
//             </Grid>
//           </Grid>

//           <Grid item component="div" className={classes.bodyListContainer}>
//             <Grid component="div" className={classes.avatarContainer}>
//               <HouseIcon className={classes.avatar} />
//             </Grid>
//             <Grid component="div" className={classes.nameContainer}>
//               <Typography display="inline" className={classes.name}>
//                 Logout
//               </Typography>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Grid> */}

//       <List component="nav" className={classes.appMenu}>
//         <Link to="/admin/service" className={classes.menuText}>
//           <ListItem button className={classes.menuItem}>
//             <ListItemIcon className={classes.menuItemIcon}>
//               <CalendarTodayIcon />
//             </ListItemIcon>
//             <Typography className={classes.textcolor}>Service</Typography>
//           </ListItem>
//         </Link>

//         <Link to="/admin/role" className={classes.menuText}>
//           <ListItem button className={classes.menuItem}>
//             <ListItemIcon className={classes.menuItemIcon}>
//               <PeopleIcon />
//             </ListItemIcon>
//             <Typography className={classes.textcolor}>Role</Typography>
//           </ListItem>
//         </Link>

//         <Link to="/admin/permission" className={classes.menuText}>
//           <ListItem button className={classes.menuItem}>
//             <ListItemIcon className={classes.menuItemIcon}>
//               <LibraryBooksIcon />
//             </ListItemIcon>
//             <Typography className={classes.textcolor}>Permission</Typography>
//           </ListItem>
//         </Link>

//         <Link to="/admin/user" className={classes.menuText}>
//           <ListItem button className={classes.menuItem}>
//             <ListItemIcon className={classes.menuItemIcon}>
//               <LibraryBooksIcon />
//             </ListItemIcon>
//             <Typography className={classes.textcolor}>User</Typography>
//           </ListItem>
//         </Link>

//         <Link to="/admin/rcad" className={classes.menuText}>
//           <ListItem button className={classes.menuItem}>
//             <ListItemIcon className={classes.menuItemIcon}>
//               <LibraryBooksIcon />
//             </ListItemIcon>
//             <Typography className={classes.textcolor}>Rcad</Typography>
//           </ListItem>
//         </Link>

//         <Link to="/admin/onboarding" className={classes.menuText}>
//           <ListItem button className={classes.menuItem}>
//             <ListItemIcon className={classes.menuItemIcon}>
//               <LibraryBooksIcon />
//             </ListItemIcon>
//             <Typography className={classes.textcolor}>Onboarding</Typography>
//           </ListItem>
//         </Link>

//         <Link to="/admin/mha" className={classes.menuText}>
//           <ListItem button className={classes.menuItem}>
//             <ListItemIcon className={classes.menuItemIcon}>
//               <IconBarChart />
//             </ListItemIcon>
//             <Typography className={classes.textcolor}>
//               Mental Health Assessment
//             </Typography>
//           </ListItem>
//         </Link>

//         <Link to="/admin/p1of2" className={classes.menuText}>
//           <ListItem button className={classes.menuItem}>
//             <ListItemIcon className={classes.menuItemIcon}>
//               <ListIcon />
//             </ListItemIcon>
//             <Typography className={classes.textcolor}>
//               Part 1 of 2 Complete
//             </Typography>
//           </ListItem>
//         </Link>

//         {/* <Link to="/reminders" className={classes.menuText}>
//           <ListItem button className={classes.menuItem}>
//             <ListItemIcon className={classes.menuItemIcon}>
//               <NotificationsIcon />
//             </ListItemIcon>
//             <Typography className={classes.textcolor}>Reminders</Typography>
//           </ListItem>
//         </Link>

//         <Link to="/service" className={classes.menuText}>
//           <ListItem button className={classes.menuItem}>
//             <ListItemIcon className={classes.menuItemIcon}>
//               <SettingsIcon />
//             </ListItemIcon>
//             <Typography className={classes.textcolor}>Services</Typography>
//           </ListItem>
//         </Link> */}
//       </List>
//       {/* </Grid> */}
//     </>
//   );
// }

// export default DeshboardMenu;

/* eslint-disable indent */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-vars */
import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import PeopleIcon from "@material-ui/icons/People";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ListIcon from "@material-ui/icons/List";
import NotificationsIcon from "@material-ui/icons/Notifications";
import IconBarChart from "@material-ui/icons/BarChart";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import useStyles from "./AppMenu.styles";




function DeshboardMenu() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(!open);
  }

  return (
    <div className={classes.toolbar}>
      <List component="nav" className={classes.appMenu}>
        <Link to="/admin/appointment" className={classes.menuText}>
          <ListItem button className={classes.menuItem}>
            <ListItemIcon className={classes.menuItemIcon}>
              <CalendarTodayIcon />
            </ListItemIcon>
            <Typography className={classes.textcolor}>Appointments</Typography>
          </ListItem>
        </Link>

        <Link to="/admin/events" className={classes.menuText}>
          <ListItem button className={classes.menuItem}>
            <ListItemIcon className={classes.menuItemIcon}>
              <PeopleIcon />
            </ListItemIcon>
            <Typography className={classes.textcolor}>Events</Typography>
          </ListItem>
        </Link>

        <Link to="/admin/patients" className={classes.menuText}>
          <ListItem button className={classes.menuItem}>
            <ListItemIcon className={classes.menuItemIcon}>
              <PeopleIcon />
            </ListItemIcon>
            <Typography className={classes.textcolor}>Patients</Typography>
          </ListItem>
        </Link>

        <Link to="/admin/user" className={classes.menuText}>
          <ListItem button className={classes.menuItem}>
            <ListItemIcon className={classes.menuItemIcon}>
              <PeopleIcon />
            </ListItemIcon>
            <Typography className={classes.textcolor}>Staff Member</Typography>
          </ListItem>
        </Link>

        <Link to="/admin/settings" className={classes.menuText}>
          <ListItem button className={classes.menuItem}>
            <ListItemIcon className={classes.menuItemIcon}>
              <LibraryBooksIcon />
            </ListItemIcon>
            <Typography className={classes.textcolor}>Settings</Typography>
          </ListItem>
        </Link>
      </List>
    </div>
  );
}

export default DeshboardMenu;
