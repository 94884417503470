/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Select,
  Button,
  DialogActions,
  FormControl,
  MenuItem,
  DialogTitle,
  TextField,
  LinearProgress,
  Typography,
  FormControlLabel,
  Container,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { baseURL } from '../utils/axios';
import AlertMsg from '../utils/Alert';
import moment from 'moment';
import Axios from 'axios';
import styles from './CreateNewAppointment.styles';
import SchedulePicker from '../common/SchedulePicker';
import Drawer from '@material-ui/core/Drawer';
import { IOSSwitch } from '../admin/components/Staff/AddStaffMember';

const statePatient = 'select-patient';
const stateClinician = 'select-clinician';
const stateSelectTime = 'select-time';

export default function Model(props) {
  const classes = styles();
  const [searchText, setSearchText] = useState('');
  const [clinicianDetails, setClinicianDetails] = useState(null);
  const [patientDetail, setPatientDetail] = useState(null);
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [clinicianList, setClinicianList] = useState([]);
  const [step, setStep] = useState('select-patient');
  const [userId, setUserId] = useState(null);
  const [clinicanId, setClinicanId] = useState(null);
  const [cost, setCost] = useState(200);
  const [duration, setDuration] = useState(60);
  const [selectTimeSlot, setSelectTimeSlot] = useState(null);
  const [title, setTitle] = useState('Create New Appointment');
  const [isWaiting, setIsWaiting] = useState(false);
  const [repeat, setRepeat] = useState(false);
  const [repeatNumber, setRepeatNumber] = useState(1);
  const [send_invoice, setSend_invoice] = useState(false);
  const [repeatInterval, setRepeatInterval] = useState('weekly');
  const [shouldShowRepeat, setShouldShowRepeat] = useState(true);
  const [appointmentLocation, setAppointmentLocation] = useState('online');
  const [appointmentType, setAppointmentType] = useState('psychotherapy');

  useEffect(() => {
    var step_ = statePatient;
    if (props.appointmentDetails) {
      if (
        props.appointmentDetails.clientId != null ??
        props.appointmentDetails.clientId.length
      ) {
        let patient =
          props.appointmentDetails.clientId[
            props.appointmentDetails.clientId.length - 1
          ];
        setPatientDetail(patient);
        setUserId(patient);
        step_ = stateClinician;
      }
      if (props.appointmentDetails.clinicianId) {
        setClinicianDetails(props.appointmentDetails.clinicianId);
        setClinicanId(props.appointmentDetails.clinicianId);
        step_ = stateSelectTime;
      }

      switch (props.appointmentDetails.type) {
        case 'follow-up':
          setTitle('Schedule Follow-up Appointment');
          break;
        case 'reschedule':
          setTitle('Reschedule Appointment');
          setShouldShowRepeat(false);
          break;
        case 'init-refferal':
          setTitle('Initiate Refferal Appointment');
          setClinicanId(null);
          setShouldShowRepeat(false);
          getAllClinicians();
          step_ = stateClinician;
          break;
      }

      setStep(step_);
    }
  }, [props.appointmentDetails]);

  useEffect(() => {
    setStep(statePatient);
    getPatientLists();
  }, []);

  useEffect(() => {
    if (props.open && !props.appointmentDetails) {
      setStep(statePatient);
      getPatientLists();
    }
  }, [props.open]);

  useEffect(() => {
    if (step == statePatient) {
      getPatientLists();
    } else if (step == stateClinician) {
      getAllClinicians();
    }
  }, [searchText]);

  const getPatientLists = () => {
    // places, admin -> patients, admin -> corporate -> patients
    // console.log(
    //   'This function of this file is being call multiple places. this console log is created by ImranNaqvi! donot remove it without consulting him. Thanks!'
    // );
    setLoading(true);
    Axios.get(`${baseURL}/api/client/getAll?term=${searchText}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        //call
        setLoading(false);
        if (res.data) {
          res.data.data[0].data.forEach((r) => {
            r.name = r.firstName + ' ' + r.lastName;
            delete r.firstName;
          });
          setPatientList(res.data.data[0].data);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const getAllClinicians = () => {
    Axios.get(`${baseURL}/api/user/clinicians?term=${searchText}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        let clinicians = res.data.filter((item) => {
          if (item._id !== localStorage.getItem('clinicianUID')) {
            let tmpObj = item;
            tmpObj._id = item._id;
            tmpObj.name = item.firstName + ' ' + item.lastName;
            return tmpObj;
          }
        });
        setClinicianList(clinicians);
      })
      .catch((e) => {
        setClinicianList([]);
      });
  };

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleNext = (event) => {
    if (step == statePatient) {
      setStep(stateClinician);
      getAllClinicians();
    } else if (step == stateClinician) {
      setStep(stateSelectTime);
    } else if (step === stateSelectTime) {
      bookAppointment();
    }
  };

  const bookAppointment = () => {
    if (userId == null) {
      AlertMsg('Failed', 'select client before moving forward');
      setStep(statePatient);
      return;
    }

    if (clinicanId == null) {
      AlertMsg('Failed', 'select clinician before moving forward');
      setStep(stateClinician);
      return;
    }

    if (selectTimeSlot == null && !isWaiting) {
      AlertMsg('Failed', 'select time and date before moving forward');
      setStep(stateSelectTime);
      return;
    }

    switch (
      props.appointmentDetails && props.appointmentDetails.type
        ? props.appointmentDetails.type
        : ''
    ) {
      case 'reschedule':
        var data = {
          clinicianId: clinicanId._id,
          duration: duration,
          amount: cost,
          bookedBy: localStorage.getItem('clinicianUID'),
          clientId: [userId._id],
          status: !isWaiting ? 'confirmed' : 'waiting',
          appointmentLocation,
          appointmentType,
        };

        if (!isWaiting) {
          data.startTime = moment(selectTimeSlot).utc();
        }

        setLoading(true);
        Axios.patch(
          `${baseURL}/api/appointment/reschedule/${props.appointmentDetails._id}`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
            },
          }
        )
          .then((res) => {
            if (res.data) {
              setLoading(false);
              AlertMsg('success', 'Appointment successfully booked.');
              props.handleSubmit();
            }
          })
          .catch((e) => {
            setLoading(false);
          });
        break;
      case 'init-refferal':
        addAppointment({
          refferedBy: localStorage.getItem('clinicianUID'),
          bookedBy: localStorage.getItem('clinicianUID'),
          clientId: [userId._id],
          status: !isWaiting ? 'reffered' : 'reffered-waiting',
        });
        break;
      default: {
        let addData = {
          clinicianId: clinicanId._id,
          duration: duration,
          amount: cost,
          bookedBy: localStorage.getItem('clinicianUID'),
          clientId: [userId._id],
          status: !isWaiting ? 'confirmed' : 'waiting',
          repeat: repeat ? repeatNumber : 1,
          sendInvoice: send_invoice,
          repeatInterval: repeatInterval,
          appointmentLocation,
          appointmentType,
        };

        if (!isWaiting) {
          addData.startTime = moment(selectTimeSlot).utc();
          addMultiAppointment(addData);
        } else {
          addAppointment(addData);
        }

        break;
      }
    }
  };

  const addAppointment = (data) => {
    setLoading(true);
    Axios.post(`${baseURL}/api/appointment/add`, data, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          setLoading(false);
          AlertMsg('success', 'Appointment successfully booked.');
          props.handleSubmit();
        }
      })
      .catch((e) => {
        setLoading(false);
        if (e.response.data.reason) {
          AlertMsg('error', e.response.data.reason);
        }
      });
  };

  const handleCancel = () => {
    setUserId(null);
    setClinicanId(null);
    props.handleClose();
  };

  const addMultiAppointment = (data) => {
    setLoading(true);
    Axios.post(`${baseURL}/api/appointment/addMulti`, data, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          setLoading(false);
          AlertMsg('success', 'Appointment successfully booked.');
          props.handleSubmit();
        }
      })
      .catch((e) => {
        setLoading(false);
        if (e.response.data.reason) {
          AlertMsg('error', e.response.data.reason);
        }
      });
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    if (props.handleClose) {
      props.handleClose();
    }
  };

  return (
    <Drawer anchor={'right'} open={props.open} onClose={toggleDrawer(false)}>
      {loading ?? <LinearProgress />}

      <DialogTitle id='form-dialog-title'>{title}</DialogTitle>

      {step != stateSelectTime ? (
        <Container>
          <Autocomplete
            id='asynchronous-demo'
            style={{ width: 300 }}
            value={step == statePatient ? userId : clinicanId}
            open={autocompleteOpen}
            onOpen={() => {
              setAutocompleteOpen(true);
            }}
            onClose={() => {
              setAutocompleteOpen(false);
            }}
            onChange={(event, newValue) => {
              if (step == statePatient) {
                setUserId(newValue);
              } else if (step == stateClinician) {
                setClinicanId(newValue);
              }
            }}
            getOptionSelected={(option, value) => option === value}
            getOptionLabel={(option) => option.name}
            options={step == statePatient ? patientList : clinicianList}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                id={'textField'}
                label={
                  step == statePatient ? 'Select patient' : 'Select clinician'
                }
                variant='outlined'
                onChange={handleChange}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Container>
      ) : (
        <>
          <SchedulePicker
            clinicianId={clinicanId ? clinicanId._id : ''}
            clientId={userId ? userId._id : ''}
            setIsWaiting={setIsWaiting}
            getTime={(date) => {
              setSelectTimeSlot(date);
            }}
          />

          <TextField
            id='standard-number'
            label='Cost'
            type='number'
            style={{ marginTop: '8px', marginLeft: '32px' }}
            value={cost}
            onChange={(env) => {
              setCost(env.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <FormControl
            variant='outlined'
            className={classes.formControl}
            style={{ width: '50%', padding: '20px' }}
          >
            <Typography style={{ marginTop: '32px' }}>
              Select appointment type
            </Typography>
            <Select
              className={`${classes.inputstyle} ${classes.selectEmpty}`}
              defaultValue={appointmentType}
              onChange={(e) => setAppointmentType(e.target.value)}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value='psychotherapy'>Psychotherapy</MenuItem>
              <MenuItem value='psychiatry'>Psychiatry</MenuItem>
              <MenuItem value='psychoeducational-assessment'>
                Psychoeducational Assessment
              </MenuItem>
              <MenuItem value='occupational-therapy'>
                Occupational Therapy
              </MenuItem>
              <MenuItem value='speech-and-languange-therapy'>
                Speech & Languange Therapy
              </MenuItem>
              <MenuItem value='wellbeing-check-up'>Wellbeing Check up</MenuItem>
              <MenuItem value='couples-therapy'>Couples Therapy</MenuItem>
              <MenuItem value='family-therapy'>Family Therapy</MenuItem>
              <MenuItem value='grief-consultation'>Grief Consultation</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            variant='outlined'
            className={classes.formControl}
            style={{ width: '50%', padding: '20px' }}
          >
            <Typography style={{ marginTop: '32px' }}>
              Select appointment location
            </Typography>
            <Select
              className={`${classes.inputstyle} ${classes.selectEmpty}`}
              defaultValue={appointmentLocation}
              onChange={(e) => setAppointmentLocation(e.target.value)}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value='online'>Online</MenuItem>
              <MenuItem value='clinic'>Clinic</MenuItem>
              <MenuItem value='school'>School visit</MenuItem>
              <MenuItem value='home'>Home visit</MenuItem>
            </Select>
          </FormControl>

          {shouldShowRepeat && selectTimeSlot != null ? (
            <Grid
              style={{ marginTop: '32px', marginLeft: '32px' }}
              item
              xs={12}
            >
              <Typography style={{ marginTop: '32px' }}>
                Send Invoice to client
              </Typography>
              <span className={(classes.switches, classes.switchBtn)}>
                <Typography component='label'>Yes</Typography>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      name='send_invoice'
                      checked={send_invoice}
                      onChange={(evt) => setSend_invoice(evt.target.checked)}
                    />
                  }
                />
                <Typography component='label'>No</Typography>
              </span>

              <Typography style={{ marginTop: '32px' }}>
                Repeat Appointment
              </Typography>
              <span className={(classes.switches, classes.switchBtn)}>
                <Typography component='label'>Yes</Typography>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      name='repeat'
                      checked={repeat}
                      onChange={(evt) => setRepeat(evt.target.checked)}
                    />
                  }
                />
                <Typography component='label'>No</Typography>
              </span>

              {repeat ? (
                <Grid style={{ marginTop: '32px' }} item xs={12}>
                  <Typography className={classes.inputTitle}>
                    Number of Repeat
                  </Typography>
                  <TextField
                    id='standard-number'
                    label=''
                    type='number'
                    name='repeatNumber'
                    style={{ width: '200px' }}
                    defaultValue={0}
                    onChange={(evnt) => setRepeatNumber(evnt.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              ) : (
                <> </>
              )}

              {repeat ? (
                <Grid style={{ marginTop: '32px' }} item xs={12}>
                  <Typography className={classes.inputTitle}>
                    Repeat Interval
                  </Typography>
                  <FormControl className={classes.inputsty}>
                    <Select
                      value={repeatInterval}
                      onChange={(evnt) =>
                        setRepeatInterval(evnt.target.checked)
                      }
                      name='repeatInterval'
                      style={{ width: '200px' }}
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                    >
                      <MenuItem value={'weekly'}>Weekly</MenuItem>
                      <MenuItem value={'biWeekly'}>Bi-Weekly</MenuItem>
                      <MenuItem value={'monthly'}>Monthly</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              ) : (
                <> </>
              )}
            </Grid>
          ) : (
            <> </>
          )}
        </>
      )}

      <DialogActions style={{ marginRight: '32px', marginBottom: '64px' }}>
        <Button onClick={handleCancel} variant='contained' color='default'>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleNext} color='primary'>
          {step == statePatient
            ? 'Next'
            : step == stateClinician
            ? 'Next'
            : 'create'}
        </Button>
      </DialogActions>
    </Drawer>
  );
}
