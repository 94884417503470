import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Label, DisabledGrid, CopyBtn, Box } from './style';

const Clipboard = ({ label, value }) => {
  const copyToClipboard = async (val) =>
    await navigator.clipboard.writeText(val);

  return (
    <Box>
      <Label>{label}</Label>
      <DisabledGrid justify='center' alignItems='center' container>
        {value}
      </DisabledGrid>
      <CopyBtn onClick={() => copyToClipboard(value)}>Copy Code</CopyBtn>
    </Box>
  );
};

Clipboard.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

Clipboard.defaultProps = {
  value: '',
};

export default Clipboard;
