/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { put, takeEvery, call } from 'redux-saga/effects';
import {
  post,
  get,
  delet,
  updatePatchForm,
  postFormData,
  updatePatch,
} from '../axios';
import {
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAILED,
  ADD_CLIENT_REQUEST,
  DELETE_CLIENT_REQUEST,
  UPDATE_CLIENT_REQUEST,
  GET_BY_ID_CLIENT_REQUEST,
  GET_CLIENT_REQUEST,
  DELETE_CLIENT_FAILED,
  DELETE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILED,
  UPDATE_CLIENT_SUCCESS,
  GET_BY_ID_CLIENT_FAILED,
  GET_BY_ID_CLIENT_SUCCESS,
  GET_CLIENT_FAILED,
  GET_CLIENT_SUCCESS,
  ADD_CLIENT_NOTE_SUCCESS,
  ADD_CLIENT_NOTE_FAILED,
  ADD_CLIENT_NOTE_REQUEST,
  CLIENT_MAIL_SUCCESS,
  CLIENT_MAIL_FAILED,
  CLIENT_MAIL_REQUEST,
  UPDATE_CLIENT_STATUS_REQUEST,
  ADD_CHILD_SUCCESS,
  ADD_CHILD_FAILED,
  ADD_CHILD_REQ,
  ADD_ADULT_SUCCESS,
  ADD_ADULT_FAILED,
  ADD_ADULT_REQ,
  GET_CHILD_SUCCESS,
  GET_CHILD_FAILED,
  GET_CHILD_REQ,
  GET_CHILD_BY_ID_SUCCESS,
  GET_CHILD_BY_ID_FAILED,
  GET_CHILD_BY_ID_REQ,
  UPDATE_CHILD_SUCCESS,
  UPDATE_CHILD_FAILED,
  UPDATE_CHILD_REQ,
  UPLOAD_CLIENT_IMAGE_REQUEST,
  UPLOAD_CLIENT_IMAGE_SUCCESS,
  UPLOAD_CLIENT_IMAGE_FAILED,
  GET_EMPLOYER_REQUEST,
  GET_EMPLOYER_SUCCESS,
  GET_EMPLOYER_FAILED,
  UPDATE_PHARMACY_REQUEST,
  UPDATE_PHARMACY_SUCCESS,
  UPDATE_PHARMACY_FAILED,
  GET_PHARMACY_REQUEST,
  GET_PHARMACY_SUCCESS,
  GET_PHARMACY_FAILED,
  SET_EMPLOYER_ID_REQUEST,
  SET_EMPLOYER_ID_SUCCESS,
  SET_EMPLOYER_ID_FAILED,
  UPLOAD_DOC_FILE_REQUEST,
  UPLOAD_DOC_FILE_SUCCESS,
  UPLOAD_DOC_FILE_FAILED,
  GET_CLIENT_DATA_REQUEST,
  GET_CLIENT_DATA_SUCCESS,
  GET_CLIENT_DATA_FAILED,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILED,
  SAVE_CLIENT_SIGNATURE_REQUEST,
  SAVE_CLIENT_SIGNATURE_SUCCESS,
  SAVE_CLIENT_SIGNATURE_FAILED,
  GET_BUNDLE_APPOINTMENT_REQUEST,
  GET_BUNDLE_APPOINTMENT_SUCCESS,
  GET_BUNDLE_APPOINTMENT_FAILED,
  CLIENT_CONSENT_FORMS_REQUEST,
  CLIENT_CONSENT_FORMS_SUCCESS,
  CLIENT_CONSENT_FORMS_FAILED,
  GET_CLIENTS_REPORT_REQUEST,
  GET_CLIENTS_REPORT_SUCCESS,
  GET_CLIENTS_REPORT_FAILED,
} from '../constant';
import AlertMsg from '../Alert';

function* addClient(action) {
  try {
    const { endpoint, body } = action;
    const response = yield post(endpoint, body);
    AlertMsg('success', 'Registeration Success');
    yield put({ type: ADD_CLIENT_SUCCESS, response });
  } catch (error) {
    AlertMsg('error', ' Failed to Register');
    yield put({ type: ADD_CLIENT_FAILED, error });
  }
}

function* getClient(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_CLIENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_CLIENT_FAILED, error });
  }
}

function* getByIdClient(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_BY_ID_CLIENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_BY_ID_CLIENT_FAILED, error });
  }
}

function* updateClient(action) {
  try {
    const { endpoint, body } = action;
    if (body.type === 'json') {
      delete body.type;
      const response = yield updatePatch(endpoint, body);
      yield put({ type: UPDATE_CLIENT_SUCCESS, response });
    } else {
      const response = yield updatePatchForm(endpoint, body);
      // yield put({ type: UPDATE_CLIENT_SUCCESS, response });
    }
  } catch (error) {
    yield put({ type: UPDATE_CLIENT_FAILED, error });
  }
}

function* updateClientStatus(action) {
  try {
    const { endpoint, body } = action;
    const response = yield updatePatch(endpoint, body);
    yield put({ type: UPDATE_CLIENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: UPDATE_CLIENT_FAILED, error });
  }
}

function* deleteClient(action) {
  try {
    const { endpoint } = action;
    const response = yield delet(endpoint);
    yield put({ type: DELETE_CLIENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: DELETE_CLIENT_FAILED, error });
  }
}

function* addClientNote(action) {
  try {
    const { endpoint, body } = action;
    const response = yield post(endpoint, body);
    yield put({ type: ADD_CLIENT_NOTE_SUCCESS, response });
  } catch (error) {
    yield put({ type: ADD_CLIENT_NOTE_FAILED, error });
  }
}

function* sendMail(action) {
  try {
    const { endpoint, body } = action;
    const response = yield post(endpoint, body);
    AlertMsg('success', 'Sent Mail');
    yield put({ type: CLIENT_MAIL_SUCCESS, response });
  } catch (error) {
    AlertMsg('error', ' Failed to Send Mail');
    yield put({ type: CLIENT_MAIL_FAILED, error });
  }
}

function* addChild(action) {
  try {
    const { endpoint, body } = action;
    console.log('=----------------------============', body);
    const response = yield post(endpoint, body);
    AlertMsg('success', 'Child Added');
    yield put({ type: ADD_CHILD_SUCCESS, response });
  } catch (error) {
    AlertMsg('error', ' Failed to Add Child');
    yield put({ type: ADD_CHILD_FAILED, error });
  }
}

function* addAdult(action) {
  try {
    const { endpoint, body } = action;
    const response = yield post(endpoint, body);
    AlertMsg('success', 'Adult Added');
    yield put({ type: ADD_ADULT_SUCCESS, response });
  } catch (error) {
    AlertMsg('error', ' Failed to Add Adult');
    yield put({ type: ADD_ADULT_FAILED, error });
  }
}

function* getChild(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_CHILD_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_CHILD_FAILED, error });
  }
}

function* getChildById(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_CHILD_BY_ID_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_CHILD_BY_ID_FAILED, error });
  }
}

function* updateChild(action) {
  try {
    const { endpoint, body } = action;
    console.log('endpoijtn', body);
    const response = yield updatePatch(endpoint, body);
    console.log('update child saga', response);
    yield put({ type: UPDATE_CHILD_SUCCESS, response });
  } catch (error) {
    yield put({ type: UPDATE_CHILD_FAILED, error });
  }
}

function* uploadClientImage(action) {
  try {
    const { endpoint, body } = action;
    const response = yield postFormData(endpoint, body);
    yield put({ type: UPLOAD_CLIENT_IMAGE_SUCCESS, response });
  } catch (error) {
    yield put({ type: UPLOAD_CLIENT_IMAGE_FAILED, error });
  }
}

function* getEmployer(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_EMPLOYER_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_EMPLOYER_FAILED, error });
  }
}

function* updatePharmacy(action) {
  try {
    const { endpoint, body } = action;
    const response = yield post(endpoint, body);
    yield put({ type: UPDATE_PHARMACY_SUCCESS, response });
  } catch (error) {
    yield put({ type: UPDATE_PHARMACY_FAILED, error });
  }
}

function* getPharmacy(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    // console.log("update image saga", response);
    yield put({ type: GET_PHARMACY_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_PHARMACY_FAILED, error });
  }
}

function* getEmployerId(action) {
  try {
    const { endpoint } = action;
    const response = yield post(endpoint);
    console.log('update image saga', response);
    yield put({ type: SET_EMPLOYER_ID_SUCCESS, response });
  } catch (error) {
    yield put({ type: SET_EMPLOYER_ID_FAILED, error });
  }
}

function* uploadDocFile(action) {
  try {
    const { endpoint, body } = action;
    const response = yield postFormData(endpoint, body);
    yield put({ type: UPLOAD_DOC_FILE_SUCCESS, response });
  } catch (error) {
    yield put({ type: UPLOAD_DOC_FILE_FAILED, error });
  }
}

function* getClientDataById(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_CLIENT_DATA_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_CLIENT_DATA_FAILED, error });
  }
}

function* getClients(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_CLIENTS_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_CLIENTS_FAILED, error });
  }
}

function* getClientsReport(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_CLIENTS_REPORT_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_CLIENTS_REPORT_FAILED, error });
  }
}

function* saveUserSignature(action) {
  try {
    const { endpoint, body } = action;
    const response = yield updatePatchForm(endpoint, body);

    yield put({ type: SAVE_CLIENT_SIGNATURE_SUCCESS, response });
  } catch (error) {
    yield put({ type: SAVE_CLIENT_SIGNATURE_FAILED, error });
  }
}

function* getInactiveAppointmentsOfClientBundle(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_BUNDLE_APPOINTMENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_BUNDLE_APPOINTMENT_FAILED, error });
  }
}

function* getClientConsentForms(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: CLIENT_CONSENT_FORMS_SUCCESS, response });
  } catch (error) {
    yield put({ type: CLIENT_CONSENT_FORMS_FAILED, error });
  }
}
export function* clientSaga() {
  yield takeEvery(ADD_CLIENT_REQUEST, addClient);
  yield takeEvery(GET_CLIENT_REQUEST, getClient);
  yield takeEvery(GET_BY_ID_CLIENT_REQUEST, getByIdClient);
  yield takeEvery(UPDATE_CLIENT_REQUEST, updateClient);
  yield takeEvery(UPDATE_CLIENT_STATUS_REQUEST, updateClientStatus);
  yield takeEvery(DELETE_CLIENT_REQUEST, deleteClient);
  yield takeEvery(ADD_CLIENT_NOTE_REQUEST, addClientNote);
  yield takeEvery(CLIENT_MAIL_REQUEST, sendMail);
  yield takeEvery(ADD_CHILD_REQ, addChild);
  yield takeEvery(ADD_ADULT_REQ, addAdult);
  yield takeEvery(GET_CHILD_REQ, getChild);
  yield takeEvery(GET_CHILD_BY_ID_REQ, getChildById);
  yield takeEvery(UPDATE_CHILD_REQ, updateChild);
  yield takeEvery(UPLOAD_CLIENT_IMAGE_REQUEST, uploadClientImage);
  yield takeEvery(GET_EMPLOYER_REQUEST, getEmployer);
  yield takeEvery(UPDATE_PHARMACY_REQUEST, updatePharmacy);
  yield takeEvery(GET_PHARMACY_REQUEST, getPharmacy);
  yield takeEvery(SET_EMPLOYER_ID_REQUEST, getEmployerId);
  yield takeEvery(UPLOAD_DOC_FILE_REQUEST, uploadDocFile);
  yield takeEvery(GET_CLIENT_DATA_REQUEST, getClientDataById);
  yield takeEvery(GET_CLIENTS_REQUEST, getClients);
  yield takeEvery(GET_CLIENTS_REPORT_REQUEST, getClientsReport);
  yield takeEvery(SAVE_CLIENT_SIGNATURE_REQUEST, saveUserSignature);
  yield takeEvery(
    GET_BUNDLE_APPOINTMENT_REQUEST,
    getInactiveAppointmentsOfClientBundle
  );
  yield takeEvery(CLIENT_CONSENT_FORMS_REQUEST, getClientConsentForms);
}
