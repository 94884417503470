import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

const Heading = styled(Typography)`
  font-size: 32px;
  font-weight: bold;
`;

const SecondaryHeading = styled(Typography)`
  margin-top: 30px;
  font-size: 32px;
  font-weight: bold;
`;
export { Heading, SecondaryHeading };

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  name: {
    margin: ' auto',
    marginRight: '900px',
    opacity: '.85;',
    display: 'block',
  },
  namee: {
    margin: ' auto',
    marginRight: '780px',
    opacity: '.85;',
    display: 'block',
    marginTop: '15px',
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 1200,
    height: 200,
    marginTop: '25px',
  },
  paperr: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 1200,
    minHeight: 300,
    height: 'auto',
    marginTop: '15px',
  },
  img: {
    width: '60px',
    height: '60px',
    float: 'left',
    marginLeft: '15px',
    borderRadius: '50%',
    marginTop: '20px',
  },
  gridList: {
    padding: theme.spacing(2),
    backgroundColor: '#003265',
    borderRadius: 'none',
    marginTop: '15px',
    color: '#ffffff',
  },
  gridLists: {
    padding: theme.spacing(2),
    backgroundColor: 'F9FFFF',
    borderRadius: 'none',
    textAlign: 'left',
    textAlign: 'center',
  },
  gridListss: {
    padding: theme.spacing(2),
    backgroundColor: '#E8FCFD',
    borderRadius: 'none',
    textAlign: 'right',
  },
  btnn: {
    height: '39px',
    borderRadius: '20px',
    backgroundColor: '#003265',
    '&:hover': { backgroundColor: '#003265' },
    justifyContent: 'center',
    cursor: 'pointer',
    fontSize: '17px',
    color: '#ffffff',
    lineHeight: '40px',
    fontFamily: 'Lato',
    fontWeight: 700,
    textAlign: 'center',
    marginTop: '-2px',
    marginTop: '25px',
    marginRight: '32px',
  },
  btn: {
    height: '39px',
    borderRadius: '20px',
    justifyContent: 'center',
    cursor: 'pointer',
    fontSize: '17px',
    lineHeight: '40px',
    fontFamily: 'Lato',
    fontWeight: 700,
    textAlign: 'center',
    marginTop: '-2px',
    marginTop: '25px',
    marginRight: '32px',
  },
  formControl: {
    margin: theme.spacing(3),
    float: 'left',
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
  cname: {
    fontSize: '20px',
    fontWeight: 'bold',
  },

  icon: {
    marginRight: '400px',
    marginTop: '15px',
    textAlign: 'left',
  },
  right: {
    //   float:'right',
    textAlign: 'right',
  },
}));
