import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Grid, Button, MenuItem, Typography, FormControl, Select } from "@material-ui/core";
import AddressView from '../admin/components/common/ClinicAddresses/AddressView';
import { DropzoneArea } from "material-ui-dropzone";
import { makeStyles } from '@material-ui/core/styles';


const addressObj = {
  _id: null,
  firstName: "",
  lastName: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  country: "",
  documentType: '',
  file: "",
  fileData: null,
};

let style = makeStyles({
  inputText: {
    fontSize: '22px',
    lineHeight: "28px",
    marginBottom: "15px",
    color: '#003265',
    padding: '5px',
    paddingLeft: 0,
    textAlign: 'left',
  },
  paper: {
    padding: '20px',
    height: '100%',
    boxShadow: 'none',
    borderRadius: 0
  },
  inputTitle: {
    textAlign: 'left',
    fontSize: "13px",
    color: '#4E525B',
    fontWeight: "500",
    marginBottom: "4px"
  },
  inputsty: {
    border: '1px solid #e5e5e5',
    borderRadius: '0',
    height: '45px',
    width: '100%',
  },
  imgBox: {
    backgroundColor: '#003265',
    padding: '25px',
    width: 170,
    height: 170,
  },
  img: {
    borderRadius: '50%',
    width: '115px',
    height: '115px',
    objectFit: 'cover'
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  drag: {
    width: '90%',
    height: '100%',
    borderStyle: 'dashed',
    borderWidth: 2,
    borderColor: '#ccc',
    textAlign: 'center',
    marginLeft: 20,
    color: '#afc2df',
  },
  italicTitle: {
    fontStyle: 'italic',
    fontSize: '13px',
    textAlign: 'left',
    color: '#807c7c',
  },
  tile: {
    padding: '20px',
    backgroundColor: '#f1f4f8',
    textAlign: 'left',
  },
  upload: {
    padding: '25px',
    backgroundColor: '#003265',
    color: '#fff',
    textAlign: 'left',
  },
  errorText: {
    color: 'red',
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: 16,
  },
  modalDisplay: {
    backgroundColor: 'white',
    padding: '8px',
    borderRadius: '4px',
    width: '100%',
  },
});


const ProofOfAddressModel = (props) => {
  const [errors, setErrors] = useState({ ...addressObj });
  const [address, setAddress] = useState({ ...addressObj });
  const classes = style()

  useEffect(() => {
    const data = { ...props.value }
    if (data && data != {} && data.documentId) { setAddress({ ...props.value, fileData: data.documentId.url }) }
    else { setAddress(addressObj) };
  }, [props.value]);

  useEffect(() => {
    if (props.editingAddress && props.editingAddress.documentId) {
      let address = { ...props.editingAddress }
      address.file = { name: address.documentId.url.split("/").slice(-1)[0] }
      setAddress(address);
    }
  }, [props.editingAddress]);

  const handleSubmit = () => {
    var validate = true

    if (address.documentType == 'select') {
      let documentType = "Expiry Date is required!";
      setErrors({ ...errors, documentType })
      validate = false
    }

    if (address.file == "" && !address._id) {
      setErrors({ ...errors, file: "Select a file" })
      validate = false
    }
    console.log(address)
    if (validate && props.handleSubmit) {
      const data = { ...address }
      if (data._id === null || data._id === "") delete data._id
      props.handleSubmit(data);
    }
  };

  const handleCreateAddress = (data) => {
    setAddress({ ...address,...data });
  }

  const readFile = (file) => {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();

      reader.onload = function (event) {
        var _event$target;

        resolve(
          event === null || event === void 0
            ? void 0
            : (_event$target = event.target) === null ||
              _event$target === void 0
              ? void 0
              : _event$target.result
        );
      };

      reader.onerror = function (event) {
        reader.abort();
        reject(event);
      };

      reader.readAsDataURL(file);
    });
  }

  const handleImageUpload = async (files) => {
    if (files.length > 0) {
      var result = await readFile(files[0]);
      setAddress({
        ...address,
        file: files[0],
        fileData: result,
      });
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value })
    switch (name) {
      case "firstName":
        setErrors({ ...errors, firstName: value.length === 0 ? "Please Enter First Name" : "" })
        break;

      case "lastName":
        setErrors({ ...errors, lastName: value.length === 0 ? "Please Enter Last Name" : "" })
        break;

      case "documentType":
        setErrors({ ...errors, documentType: value.length === 'select' ? "Please Enter Document type" : "" })
        break;
    }

    console.log({ ...address, [name]: value })
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Grid
        style={{ width: "50%", height: "100%", margin: 'auto auto' }}
        justify="center"
        alignItems="center"
        container
      >
        <Grid style={{
          backgroundColor: 'white',
          padding: '8px',
          borderRadius: '4px',
          width: '100%',
        }}>
          <div>
            <Grid className={classes.modalDisplay}>
              <Grid container style={{ marginBottom: "15px" }}>
                <Grid item xs={12} lg={6} style={{ padding: "16px" }}>
                  <Button
                    style={{ width: "100%", height: "40%",border: '1px solid #ccc' }}
                    onClick={() => address.fileData ? window.open(address.fileData.toString() , "_blank"): false}
                  >
                    <img
                      style={{ width: "100%", height: "100%", objectFit: 'cover', border: "" }}
                      src={address.fileData ? address.fileData.toString() : null}
                    />
                  </Button>
                  <div style={{ width: '100%', height: '60%', paddingTop: '32px' }}>
                    <DropzoneArea
                      style={{ margin: 'auto' }}
                      onChange={handleImageUpload}
                      dropzoneClass="customDropzone"
                      dropzoneText="Drop files here or click to upload"
                      acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                      maxFileSize={30000000}
                      filesLimit={1}
                      showPreviews={false}
                      showPreviewsInDropzone={false}
                    />
                    <span className={classes.errorText}>
                      {errors.file && errors.file}
                    </span>
                  </div>
                </Grid>

                <Grid item xs={12} lg={6} >
                  <Grid item xs={12} style={{ marginBottom: "15px" }}>
                    <Typography className={classes.inputTitle}>
                      Document Type*
                    </Typography>
                    <FormControl className={classes.inputsty}>
                      <Select
                        value={address.documentType}
                        onChange={handleChange}
                        name="documentType"
                      >
                        <MenuItem value={"select"}>Select</MenuItem>
                        <MenuItem value={"tenancy_contract"}>Tenancy Contract</MenuItem>
                        <MenuItem value={"utility_bill"}>Utility Bill</MenuItem>
                        <MenuItem value={"bank_statement"}>Bank Statement</MenuItem>
                      </Select>
                      <span className={classes.errorText}>
                        {errors.documentType && errors.documentType}
                      </span>
                    </FormControl>
                  </Grid>
                  <Grid container style={{ marginBottom: "15px" }}>
                    <Grid item xs={6} style={{ paddingRight: "10px" }}>
                      <Typography className={classes.inputTitle}>
                        First Name*
                      </Typography>
                      <input
                        className={classes.inputsty}
                        value={address.firstName}
                        onChange={handleChange}
                        type="text"
                        name="firstName"
                      />
                      <span className={classes.errorText}>
                        {errors.firstName && errors.firstName}
                      </span>
                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: "10px" }}>
                      <Typography className={classes.inputTitle}>
                        Last Name*
                      </Typography>
                      <input
                        className={classes.inputsty}
                        value={address.lastName}
                        onChange={handleChange}
                        type="text"
                        name="lastName"
                      />
                      <span className={classes.errorText}>
                        {errors.lastName && errors.lastName}
                      </span>
                    </Grid>
                  </Grid>
                  <AddressView
                    handleChange={handleCreateAddress}
                    editingAddress={address}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid style={{ padding: "16px" }} justify="flex-end" container>
              <Grid item xs={12} lg={6}>
                <Button style={{ width: "100%" }}
                  onClick={props.handleClose && props.handleClose}
                >Cancel</Button>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ width: "100%" }}
                  onClick={handleSubmit}
                >
                  {address._id ? "Update" : 'Create'}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
};

ProofOfAddressModel.propTypes = {
  editingAddress: PropTypes.object,
  handleCreateAddress: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ProofOfAddressModel;
