import { createStyles} from "@material-ui/core";
import { titleFont, nameFont } from "../../assets/fontSize";
export default (theme) =>
  createStyles({
    root: {
      backgroundColor: "#cfd9df",
      width: "100%",
      height: "100%",
      position: "fixed",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      opacity: "20%",
    },
    container: {
      position: "absolute",
      zIndex: 1,
      [theme.breakpoints.up("xs")]: {
        width: "300px",
        top: "150px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "500px",
        top: "150px",
      },
      [theme.breakpoints.up("md")]: {
        width: "800px",
        top: "212px",
      },
    },
    headerText: {
      // fontSize: "32px",
      fontSize: titleFont,
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: 700,
      textAlign: "left",
    },
    sliderTitleText: {
      color: "#222222",
      fontFamily: "Lato",
      fontWeight: 700,
      [theme.breakpoints.up("xs")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: nameFont,
        // fontSize: "22px",
      },
      [theme.breakpoints.up("md")]: {
        // fontSize: "22px",
        fontSize: nameFont,
      },
    },
    dividerLine: {
      [theme.breakpoints.up("xs")]: {
        width: "300px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "500px",
      },
      [theme.breakpoints.up("md")]: {
        width: "800px",
      },
    },
    queMarkIconContainer: {
      marginLeft: "5px",
      marginTop: "5px",
      borderRadius: "10px",
      backgroundColor: "#ffffff",
      border: "1px solid #222222",
      [theme.breakpoints.up("xs")]: {
        width: "15px",
        height: "15px",
      },
      [theme.breakpoints.up("sm")]: {
        // width: "20px",
        // height: "20px",
      },
      // [theme.breakpoints.up('xs')]: {
      //   width: "20px",
      //   height: "20px",
      // },
    },
    queMarkText: {
      color: "#222222",
      fontFamily: "Lato",
      fontWeight: 700,
      [theme.breakpoints.up("xs")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "11px",
      },
    },
    scaleCountNumber: {
      position: "absolute",
      right: "2px",
      // fontSize: "22px",
      color: "#222222",
      fontFamily: "Lato",
      fontWeight: 700,
      [theme.breakpoints.up("xs")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.up("sm")]: {
        // fontSize: "22px",
        fontSize: nameFont,
      },
      [theme.breakpoints.up("md")]: {
        // fontSize: "22px",
        fontSize: nameFont,
      },
    },
    sliderContainer: {
      [theme.breakpoints.up("xs")]: {
        height: "80px",
      },
      [theme.breakpoints.up("sm")]: {
        height: "100px",
      },
      // [theme.breakpoints.up('xs')]: {
      //   height: "100px",
      // },
      backgroundImage:
        "linear-gradient(90deg, #26a4ba 0%, #85c169 25%, #cbcb2f 50%, #d0aa21 75%, #da7910 100%)",
    },
    sliderTextContainer: {
      [theme.breakpoints.up("xs")]: {
        width: "20%",
      },
      //  [theme.breakpoints.up('xs')]: {
      //   width: "20%",
      //  },
      //  [theme.breakpoints.up('xs')]: {
      //   width: "20%",
      //  },
      // float: 'left',
    },
    sliderText: {
      [theme.breakpoints.up("xs")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "22px",
      },
      color: "#ffffff",
      fontFamily: "Lato",
      fontWeight: 700,
      textAlign: "center",
    },
    sliderPointContainer: {
      position: "absolute",
      [theme.breakpoints.up("xs")]: {
        left: "200px",
        top: "100px",
      },
      [theme.breakpoints.up("sm")]: {
        left: "350px",
        top: "100px",
      },
      [theme.breakpoints.up("md")]: {
        left: "578px",
        top: "100px",
      },
    },
    sliderPointContainer2: {
      position: "absolute",
      [theme.breakpoints.up("xs")]: {
        left: "230px",
        top: "240px",
      },
      [theme.breakpoints.up("sm")]: {
        left: "390px",
        top: "262px",
      },
      [theme.breakpoints.up("md")]: {
        left: "610px",
        top: "262px",
      },
    },
    sliderPoint: {
      [theme.breakpoints.up("xs")]: {
        width: "30px",
        height: "40px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "30px",
        height: "40px",
      },
      [theme.breakpoints.up("md")]: {
        width: "40px",
        height: "50px",
      },
    },
    sliderPointText: {
      fontSize: "16px",
      color: "#ffffff",
      fontFamily: "Lato",
      fontWeight: 700,
      textAlign: "center",
      [theme.breakpoints.up("xs")]: {
        marginTop: "-40px",
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "-45px",
      },
      // [theme.breakpoints.up('xs')]: {
      //   marginTop: "-45px",
      // },
    },
    sliderPointLine: {
      [theme.breakpoints.up("xs")]: {
        marginTop: "4px",
        height: "68px",
      },
      [theme.breakpoints.up("sm")]: {
        marginTop: "3px",
        height: "90px",
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "8px",
        height: "80px",
      },
      marginLeft: "1px",
    },
    footerTextContainer: {
      marginTop: "45px",
      height: "126px",
      fontSize: "20px",
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: 400,
      textAlign: "left",
    },
    skipText: {
      fontSize: "17px",
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: 700,
      textAlign: "center",
      opacity: "50%",
      lineHeight: "40px",
      cursor: "pointer",
      [theme.breakpoints.up("xs")]: {
        marginBottom: "20px",
      },
      [theme.breakpoints.up("sm")]: {
        marginRight: "43px",
      },
      [theme.breakpoints.up("md")]: {
        marginRight: "43px",
      },
    },
    footerButtonContainer: {
      marginBottom: "150px",
      [theme.breakpoints.up("xs")]: {
        marginTop: "170px",
      },
      [theme.breakpoints.up("sm")]: {
        marginTop: "80px",
        float: "right",
        display: "flex",
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "80px",
        float: "right",
        display: "flex",
      },
    },
  });