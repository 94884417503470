import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import {
  Grid,
} from '@material-ui/core';
import SidebarMenu from '../SidebarMenuClinician';
import SidebarClinician from '../SidebarClinician';
import FullCalendarComponent from './FullCalendarComponent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import '../clinician.scss';
import Layout from '../Layout';
import useStyles from "./Calander.styles";

export default function ClinicianCalander() {
  const classes = useStyles();    
  const [eventOpen, setEventOpen] = useState();
  const [title, setTitle] = useState('');
  const [color, setColor] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [description, setDescription] = useState("");
  
  const handleAddNewEventClick = (selectedDate) => {
    if(selectedDate){
      setSelectedDate({start: selectedDate.start, end: selectedDate.end});
      setEventOpen(true);
    }else{
      setSelectedDate(null);
    }
  }

  const handleSubmitNewEvent = () => {

  }

  return (
    <React.Fragment>
      <Layout appointments={[]}>
        <Grid container>
          <FullCalendarComponent handleAddNewEventClick={handleAddNewEventClick} />
        </Grid>
      </Layout>
      <Dialog fullWidth={false} maxWidth={"md"} aria-labelledby="max-width-dialog-title" open={eventOpen} onClose={() => { setEventOpen(false) }} aria-labelledby="form-dialog-title">
        <DialogTitle id="max-width-dialog-title">Add New Schedule</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={title}
                label="Enter Title"
                type="email"
                onChange={(evt) => setTitle(evt.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={color}
                  onChange={(evt) => setColor(evt.target.value)}
                >
                  <MenuItem value={"primary"}>Primary</MenuItem>
                  <MenuItem value={"secondary"}>Secondary</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={2}
                style={{padding:0}}
                defaultValue="Default Value"
                variant="outlined"
                onChange={(evt) => setDescription(evt.target.value)}
                value={description}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEventOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => {
            handleSubmitNewEvent()
          }} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
  