/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import {
  withStyles,
  Grid,
  Typography,
  Input,
  Button,
  createStyles,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,
  TextField,
} from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { baseURL } from '../utils/axios';
import AlertMsg from '../utils/Alert';
import moment from 'moment';
import Axios from 'axios';
import styles, { AutoComplete } from './CreateNewAppointment.styles';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { Title } from '@material-ui/icons';

const statePatient = 'select-patient';
const stateClinician = 'select-clinician';
const stateSelectTime = 'select-time';

export default function PatientSearchView(props) {
  const classes = styles();
  const [searchText, setSearchText] = useState('');
  const [diagnosis, setDiagnosis] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [corporationId, setCorporationId] = useState('');

  useEffect(() => {
    setDiagnosis([]);
    getDiagnosis();
  }, [searchText, corporationId]);

  useEffect(() => {
    if (props.selectedCorporation) {
      setCorporationId(props.selectedCorporation._id);
    }
  }, [props.selectedCorporation]);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: UserType) =>
      option.firstName +
      option.lastName +
      option.email +
      option.phoneNumber +
      option.chartId,
  });

  interface UserType {
    _id: number;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    chartId: string;
  }

  useEffect(() => {
    setSelectedPatients(
      props.appointmentDetail.clientId ? props.appointmentDetail.clientId : []
    );
  }, [props.appointmentDetail.clientId]);

  const getDiagnosis = () => {
    let query = `term=${searchText}`;

    // if (props.isClinician && !props.clinicianId) {
    //   return;
    // }
    // console.log({ corporationId });
    if (props.isCorporate && !corporationId) {
      setDiagnosis([
        {
          _id: 0,
          firstName: 'Please select a corporation first',
          lastName: '',
        },
      ]);
      return;
    } else if (corporationId) {
      query = `${query}&corporationId=${corporationId}`;
    }

    query = `${query}&clinicianId=${props.clinicianId}`;

    Axios.get(
      `${baseURL}/api/client/getAll?${query}&isAdmin=${
        props.isAdmin ? 'yes' : 'no'
      }&patientType=${props.patientType}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        if (res.data) {
          setDiagnosis(res.data.data);
        }
      })
      .catch((e) => {});
  };

  const handleChange = (event) => {
    console.log('event.target.value', event.target.value);
    setSearchText(event.target.value);
  };

  return (
    <div style={{ width: '100%' }}>
      <AutoComplete
        style={{ display: 'block', width: '100%' }}
        multiple
        options={diagnosis}
        getOptionDisabled={() => props.isCorporate && !corporationId}
        filterOptions={filterOptions}
        onChange={(event, newValue) => {
          if (props.onChange) {
            props.onChange(newValue);
          }
          setSelectedPatients(newValue);
        }}
        getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
        getOptionSelected={(option, value) => option._id === value._id}
        value={selectedPatients}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Select Patient'
            onChange={handleChange}
            variant='outlined'
          />
        )}
        disabled={props.disabled || false}
      />
    </div>
  );
}
