import styled from 'styled-components';

const Circle = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.status === 'active' ? '#00bc07' : 'red'};
`;

export { Circle };
