import React, { useEffect, useState } from "react";
import BtnAddRow from "./BtnAddRow";
import Drawer from "../../../common/TailwindDrawer";
import { baseURL, update } from '../../../utils/axios';
import AlertMsg from "../../../utils/Alert";
import Axios from 'axios';
import ReactQuill from 'react-quill';
import eventTemplate from "./Templates/eventTemplate";
import waitlistTemplate from "./Templates/waitlistTemplate";
import appointmentTemplate from "./Templates/appointmentTemplate";
import registrationTemplate from "./Templates/registrationTemplate";
import registrationOTPTemplate from "./Templates/registrationOTPTemplate";

const content = {
    "appointment": {
        type: { after: "After Appointment Booking", before: "Before Appointment" },
        templates: appointmentTemplate,
        status: [
            'confirmed',
            'checked-in',
            'seen',
            'ended',
            'completed',
            'cancelled',
            'free-consultation',
            'missed',
        ],
        messageContent: [{
            name: "Client Name",
            value: "{{client_name}}"
        },
        {
            name: "Clinician Name",
            value: "{{clinician}}"
        },
        {
            name: "Appointment Date",
            value: "{{appointment_date}}"
        },
        {
            name: "Appointment Time",
            value: "{{appointment_time}}"
        }, {
            name: "Clinicians Meeting Id",
            value: "{{clinician_meeting_id}}"
        }, {
            name: "qrcode",
            value: "{{qrcode}}"
        }, {
            name: "pin",
            value: "{{pin}}"
        }
        ]
    },
    "invoice": {
        type: { after: "After Appointment Booking", before: "Before Appointment" },
        templates: eventTemplate,
        messageContent: [{
            name: "Client Name",
            value: "{{client_name}}"
        },
        {
            name: "Appointment ID #",
            value: "{{appointment_id}}"
        },
        {
            name: "Client Name",
            value: "{{client_name}}"
        }
        ]
    },
    "user-registration": {
        templates: registrationTemplate,
        messageContent: [{
            name: "Client Name",
            value: "{{client_name}}"
        },
        {
            name: "Client Email",
            value: "{{client_email}}"
        },
        {
            name: "Date of Registration",
            value: "{{regitration_date}}"
        }
        ]
    },
    "user-registration-otp": {
        templates: registrationOTPTemplate,
        messageContent: [{
            name: "Client Name",
            value: "{{client_name}}"
        },
        {
            name: "Client Email",
            value: "{{client_email}}"
        },
        {
            name: "Date of Registration",
            value: "{{regitration_date}}"
        },
        {
            name: "OTP",
            value: "{{otp}}"
        }
        ]
    },
    "client-profile": {
        templates: eventTemplate,
        type: { after: "After Appointment Booking", before: "Before Appointment" },
        messageContent: [{
            name: "Client Name",
            value: "{{client_name}}"
        },
        {
            name: "Appointment ID #",
            value: "{{appointment_id}}"
        }
        ]
    },
    "client-document-verification": {
        templates: eventTemplate,
        status: [
            "verified",
            "failed"
        ],
        messageContent: [{
            name: "Client Name",
            value: "{{client_name}}"
        },
        {
            name: "Appointment ID #",
            value: "{{appointment_id}}"
        }
        ]
    },
    "client-document-upload": {
        templates: eventTemplate,
        messageContent: [{
            name: "Client Name",
            value: "{{client_name}}"
        },
        {
            name: "Appointment ID #",
            value: "{{appointment_id}}"
        }
        ]
    },
    "waitlist-appointment": {
        templates: waitlistTemplate,
        messageContent: [{
            name: "Client Name",
            value: "{{client_name}}"
        }
        ]
    }
}

let types = {
    "waitlist-appointment": "Waitlist Appointment",
    "client-document-upload": "Client Document Upload",
    "client-document-verification": "Client Document Verification",
    "client-profile": "Client Profile",
    "user-registration": "User Registration",
    "appointment": "Appointment",
    "invoice": "Invoice",
    "user-registration-otp": "User Registration OTP"
}

export default function TriggersDrawer(props) {
    const [categories, setCategories] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [active, setActive] = useState('')
    const [trigers, setTriggers] = useState([])
    const [type, setType] = useState("")//"appointment")
    const [id, setId] = useState(null)
    const [error, setError] = useState("")

    useEffect(() => {
        getServices()
    }, [])

    const getServices = () => {
        Axios.get(`${baseURL}/admin/service`, {
            headers: {
                Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
            },
        })
            .then((res) => {
                if (res.data !== undefined) {
                    setCategories(res.data);
                }
            })
            .catch((e) => {
            });
    };

    useEffect(() => {
        if (props.trigger && props.open) {
            setTriggers(props.trigger.triggers)
            setSelectedCategories(props.trigger.services.map(c => c._id))
            setActive(props.trigger.active)
            setType(props.trigger.type)
            setId(props.trigger._id)
        } else {
            setTriggers([])
            setSelectedCategories([])
            setActive('')
            setId(null)
            setType("")
            setError("")
        }
    }, [props.trigger, props.open])

    const postTrigger = () => {

        if (selectedCategories.length == 0 && type == "appointment") {
            setError("select categories")
            return
        }

        if (trigers.length == 0) {
            setError("add triggers")
            return
        }

        if (active == '') {
            setError("Is trigger active?")
            return
        }

        trigers.forEach(function (v) { delete v._id });
        const data = { active, services: selectedCategories, type: type, triggers: trigers }

        if (!id) {
            Axios.post(`${baseURL}/api/triggers`, data, {
                headers: {
                    Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
                },
            })
                .then(() => {
                    setError("")
                    if (props.refresh) {
                        props.refresh()
                    }
                    if (props.setOpen) {
                        props.setOpen(false)
                    }
                })
                .catch((e) => {
                    setError("Something went wrong! try again")
                });
        } else {
            Axios.patch(`${baseURL}/api/triggers/${id}`, data, {
                headers: {
                    Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
                },
            })
                .then(() => {
                    setError("")
                    if (props.setOpen) {
                        props.setOpen(false)
                    }
                })
                .catch((e) => {
                    setError("Something went wrong! try again")
                });
        }
    }

    return (
        <Drawer isOpen={props.open} setIsOpen={props.setOpen}>
            <div
                className="underlay absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
            />
            <div className="panel fixed inset-y-0 right-0 max-w-full flex">
                { }
                <div className="relative w-screen max-w-2xl">
                    { }
                    <div className="relative panel h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                        { }
                        <div className="px-4 mb-4 sm:px-6">
                            <h2
                                className="text-xl font-medium text-gray-900"
                                id="slide-over-title"
                            >
                                Edit Trigger
                            </h2>
                        </div>

                        <div className="mx-6 mb-4 p-4 text-sm border bg-brand-blue-light">
                            <div className="active-status mb-4">
                                <h2 className="mb-1 mb-3 font-semibold">
                                    Trigger Type
                                </h2>
                                <select
                                    value={type}
                                    onChange={(e) => {
                                        console.log(e.target.value)
                                        setType(e.target.value)
                                    }}
                                    className="py-2 px-4 text-sm rounded-md border border-gray-200 focus:outline-none"
                                >
                                    <option value="" selected>Select</option>
                                    {Object.keys(content).map(type => <option value={type}>{types[type]}</option>)}
                                </select>
                            </div>
                        </div>

                        <div className="mx-6 mb-4 p-4 text-sm border bg-brand-blue-light">
                            { }
                            {type == "appointment" && <div className="assigned-category mb-4">
                                <h2 className="mb-1 font-semibold">
                                    Assigned categories <span className="text-red-500">*</span>
                                </h2>
                                <p className="mb-2 text-xs italic text-gray-500">
                                    An event trigger is always assigned to a category.
                                </p>
                                { }
                                <div className="categories text-sm">
                                    {categories.map(category => <>
                                        <input
                                            type="checkbox"
                                            name="cat1"
                                            onChange={(e) => {
                                                if (e.target.checked && !selectedCategories.includes(category._id)) {
                                                    setSelectedCategories([...selectedCategories, category._id])
                                                } else {
                                                    setSelectedCategories(selectedCategories.filter(t => t != category._id))
                                                }
                                            }}
                                            checked={selectedCategories.includes(category._id)}
                                            className="mr-1 mb-0.5"
                                        />
                                        <label htmlFor="cat1">{category.type}</label>
                                        <br />
                                    </>)}
                                </div>
                            </div>}
                            <div className="active-status mb-4">
                                <h2 className="mb-1 font-semibold">
                                    Is trigger active? <span className="text-red-500">*</span>
                                </h2>
                                <p className="mb-2 text-xs italic text-gray-500">
                                    If you select "No", this trigger will be disabled and it
                                    will not send out messages to your clients.
                                </p>
                                <select
                                    value={active ? "y" : 'n'}
                                    onChange={(e) => {
                                        if (e.target.value == "y") {
                                            setActive(true)
                                        }

                                        if (e.target.value == "n") {
                                            setActive(false)
                                        }
                                    }}
                                    className="py-2 px-4 text-sm rounded-md border border-gray-200 focus:outline-none"
                                >
                                    <option value="" selected>Select</option>
                                    <option value="y">Yes</option>
                                    <option value="n">
                                        No
                                    </option>
                                </select>
                            </div>
                        </div>
                        {trigers.map(trigger => {
                            const index = trigers.findIndex(x => x._id === trigger._id)
                            return <div key={trigger._id} className="mx-6 mb-4 p-4 text-sm border bg-brand-gold-lightest border-gray-200">
                                { }
                                <div className="flex flex-row flex-nowrap justify-between items-center mb-4 -m-4 p-2 bg-brand-gold-light border-b">
                                    <div className="trigger-header text-base font-bold">
                                        Trigger
                                    </div>
                                    { }
                                    <button
                                        onClick={() => setTriggers(trigers.filter(t => t._id != trigger._id))}
                                        id="remove-trigger"
                                        className="p-2 text-xs bg-brand-gold-dark text-white"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="inline-block h-4 w-4"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                { }
                                <div className="delivery-type mb-4">
                                    <h2 className="mb-1 font-semibold">
                                        Delivery type <span className="text-red-500">*</span>
                                    </h2>
                                    <p className="mb-2 text-xs italic text-gray-500">
                                        If you select "No", this trigger will be disabled and it
                                        will not send out messages to your clients.
                                    </p>
                                    <select
                                        name="deliveryType"
                                        id="deliveryType"
                                        className="py-2 px-4 pr-10 text-sm rounded-md border border-gray-200 focus:outline-none"
                                        value={trigger?.deliveryType ?? ""}
                                        onChange={(e) => {
                                            const triggers = [...trigers]
                                            triggers[index].deliveryType = e.target.value
                                            setTriggers(triggers)
                                        }}
                                    >
                                        <option value="" selected>Select</option>
                                        <option value="email">Email</option>
                                        <option value="sms">
                                            SMS
                                        </option>
                                    </select>
                                </div>

                                {(type == "appointment" || type == "client-document-verification") && <div className="delivery-type mb-4">
                                    <h2 className="mb-1 font-semibold">
                                        Status
                                    </h2>
                                    <select
                                        name="status"
                                        id="status"
                                        className="py-2 px-4 pr-10 text-sm rounded-md border border-gray-200 focus:outline-none"
                                        value={trigger?.status ?? ""}
                                        onChange={(e) => {
                                            const triggers = [...trigers]
                                            triggers[index].status = e.target.value
                                            setTriggers(triggers)
                                        }}
                                    >
                                        <option value="" selected>Select</option>
                                        {content[type].status.map(key => <option value={key}>{key}</option>)}
                                    </select>
                                </div>}

                                {(type != "user-registration" && type != "user-registration-otp" && type != "client-document-verification" && type != "client-document-upload" && type != "waitlist-appointment") && <>
                                    <div className="event-type mb-4">
                                        <h2 className="mb-1 font-semibold">
                                            Trigger event type <span className="text-red-500">*</span>
                                        </h2>
                                        <p className="mb-2 text-xs italic text-gray-500">
                                            If you select "No", this trigger will be disabled and it
                                            will not send out messages to your clients.
                                        </p>
                                        <select
                                            name="eventType"
                                            id="eventType"
                                            className="py-2 px-4 pr-10 text-sm rounded-md border border-gray-200 focus:outline-none"
                                            value={trigger?.eventType ?? ""}
                                            onChange={(e) => {
                                                const triggers = [...trigers]
                                                triggers[index].eventType = e.target.value
                                                setTriggers(triggers)
                                            }}
                                        >
                                            <option value="" selected>Select</option>
                                            <option value="after" >
                                                {content[type].type.after}
                                            </option>
                                            <option value="before">
                                                {content[type].type.before}
                                            </option>
                                        </select>
                                    </div>
                                    <div className="trigger-schedule mb-4">
                                        <h2 className="mb-1 font-semibold">
                                            Schedule <span className="text-red-500">*</span>
                                        </h2>
                                        <p className="mb-2 text-xs italic text-gray-500">
                                            If you select "No", this trigger will be disabled and it
                                            will not send out messages to your clients.
                                        </p>
                                        <select
                                            name="schedule"
                                            id="schedule"
                                            className="py-2 px-4 pr-10 text-sm rounded-md border border-gray-200 focus:outline-none"
                                            value={trigger?.schedule ?? ""}
                                            onChange={(e) => {
                                                const triggers = [...trigers]
                                                triggers[index].schedule = e.target.value
                                                setTriggers(triggers)
                                            }}
                                        >
                                            <option selected>Select</option>
                                            <option value={trigger?.eventType == "before" ? 1 : 0}>
                                                {trigger?.eventType == "before" ? 1 : 0} hours
                                            </option>
                                            <option value={24}>24 hours</option>
                                            <option value={48}>48 hours</option>
                                            <option value={72}>72 hours</option>
                                        </select>
                                    </div>
                                </>}
                                <div className="event-type mt-4 mb-4">
                                    <h2 className="mb-1 font-semibold">
                                        Templates
                                    </h2>
                                    <p className="mb-2 text-xs italic text-gray-500">
                                        Predefined templates
                                    </p>
                                    <select
                                        name="eventType"
                                        id="eventType"
                                        value=""
                                        className="py-2 px-4 pr-10 text-sm rounded-md border border-gray-200 focus:outline-none"
                                        onChange={(e) => {
                                            const triggers = [...trigers]
                                            triggers[index].content = content[type].templates[e.target.value]
                                            setTriggers(triggers)
                                        }}

                                    >
                                        <option value="" selected>Select</option>
                                        {Object.keys(content[type].templates).map(key => <option value={key}>{key}</option>)}
                                    </select>
                                </div>

                                <div className="trigger-schedule mb-4">
                                    <h2 className="mb-1 font-semibold">
                                        Message Content <span className="text-red-500">*</span>
                                    </h2>
                                    <p className="mb-2 text-xs italic text-gray-500">
                                        Please create your message content below. Here are your
                                        placeholder helpers:
                                    </p>
                                    <p className="mb-2">
                                        {content[type].messageContent.map(message =>
                                            <button
                                                onClick={() => {
                                                    const triggers = [...trigers]
                                                    if (triggers[index].content == undefined) {
                                                        triggers[index].content = ""
                                                    }
                                                    triggers[index].content += message.value
                                                    setTriggers(triggers)
                                                }}
                                                className="inline-flex items-center my-0.5 px-3 py-1 rounded-full text-xs font-medium bg-gray-200 text-gray-800 hover:bg-gray-900 hover:text-white">
                                                {message.name}
                                            </button>
                                        )}
                                    </p>

                                    <div className="event-type mt-4 mb-4">
                                        <h2 className="mb-1 font-semibold">
                                            Subject
                                        </h2>
                                        <p className="mb-2 text-xs italic text-gray-500">
                                            Predefined templates
                                        </p>
                                        <input
                                            name="subject"
                                            id="subject"
                                            className="py-2 px-2 pr-10 w-full text-sm rounded-md border border-gray-200 focus:outline-none"
                                            value={trigger?.subject ?? ""}
                                            onChange={(e) => {
                                                const triggers = [...trigers]
                                                triggers[index].subject = e.target.value
                                                setTriggers(triggers)
                                            }}
                                        />
                                    </div>

                                    <ReactQuill
                                        name="editor"
                                        id="editor"
                                        className="mt-2 bg-white"
                                        bounds={'.app'}
                                        value={trigger?.content ?? ""}
                                        onChange={(value) => {
                                            console.log(value)
                                            const triggers = [...trigers]
                                            triggers[index].content = value
                                            setTriggers(triggers)
                                        }}
                                    />

                                </div>
                            </div>
                        })}

                        { }
                        <div className="mx-6">
                            <button
                                onClick={() => {
                                    setError("")
                                    if (type != "") {
                                        setTriggers([...trigers, { _id: trigers.length + 1 }])
                                    } else {
                                        setError("Select a trigger type before addign a trigger")
                                    }
                                }}
                                id="add-trigger"
                                className="p-3 text-sm bg-brand-blue-light border border-brand-blue-dark border-opacity-10 text-brand-blue-dark hover:bg-brand-blue hover:text-white"
                            >
                                <svg
                                    className="inline-block h-4 w-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M12 4v16m8-8H4"
                                    />
                                </svg>
                                Add Trigger
                            </button>
                        </div>
                        {error != "" ? <div class="bg-red-100 border border-red-400 text-red-700 m-4 py-3 rounded relative" role="alert">
                            <span class="block sm:inline ml-4">{error}</span>
                        </div> : <></>}
                        <div className="mt-6 px-4 border-t border-gray-200 bg-gray-50 py-5 sm:px-6">
                            <div className="space-x-3 flex justify-end">
                                <button
                                    type="button"
                                    onClick={() => props.setOpen(false)}
                                    className="btn-close-edit-panel bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-blue-mid"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() => postTrigger()}
                                    type="submit"
                                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-blue hover:bg-brand-blue-mid focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-blue-mid"
                                >
                                    Update Trigger
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Drawer>
    )
}
