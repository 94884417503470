import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { modifyBundle } from '../../../../../utils/actions/bundle.action';
import PropTypes from 'prop-types';
import { Drawer, Avatar, Grid, Icon } from '@material-ui/core';
import {
  Button,
  ButtonWithNoPadding,
  Spinner,
} from '../../../../../components/atoms';
import { ServiceBundleForm } from '../';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { Container, Heading, Row, BottomBtn, DrawerWindow } from '../style';
import Avatar1 from '../../../../../assets/imgs/ananya.png';
import Avatar2 from '../../../../../assets/imgs/ananya1.png';

const defaultBundle = {
  _id: null,
  clientId: null,
  clinicianId: null,
  cancellingUser: null,
  appointmentType: '',
  expirationDate: new Date(),
  amount: 0,
  noOfSessions: 0,
  discount: 0,
  duration: '',
};

const EditBundle = ({
  open,
  onClose,
  onCancelSubscription,
  selectedBundle,
  clinicians,
  clients,
  modifyBundle,
  updatedSuccessfully,
  filterClients,
  filterClinicians,
}) => {
  const [bundle, setBundle] = useState({ ...defaultBundle });
  const [wholePageSpinner, setWholePageSpinner] = useState(false);
  const [error, setError] = useState({
    expirationDate: '',
  });

  useEffect(() => {
    setBundle(selectedBundle);
  }, [selectedBundle]);

  useEffect(() => {
    if (updatedSuccessfully && bundle._id) {
      setWholePageSpinner(false);
      setBundle({ ...defaultBundle });
      onClose();
    }
  }, [updatedSuccessfully]);

  const onDropdownChange = (val, name) => setBundle({ ...bundle, [name]: val });
  const onChange = (e) =>
    setBundle({ ...bundle, [e.target.name]: e.target.value });

  const handleSubmit = () => {
    let collectError = {};

    if (!bundle.expirationDate) {
      collectError = {
        ...collectError,
        expirationDate: 'Expiration Date can not be empty!',
      };
    }

    setError({ ...collectError });

    if (Object.keys(collectError).length === 0) {
      setWholePageSpinner(false);
      modifyBundle(bundle);
    }
  };

  return (
    <DrawerWindow anchor='right' open={open} onClose={onClose}>
      <Spinner isOpen={false} />
      <Container>
        <Grid justify='flex-end' container>
          <ButtonWithNoPadding onClick={onClose}>
            <Icon component={CancelOutlinedIcon} />
          </ButtonWithNoPadding>
        </Grid>

        <Heading align='left'>Edit Subscription</Heading>
        <ServiceBundleForm
          filterClients={filterClients}
          clientList={clients}
          filterClinicians={filterClinicians}
          clinicianList={clinicians}
          onChange={onChange}
          onDropdownChange={onDropdownChange}
          bundle={bundle}
          mode='edit'
          error={error}
        />
        <Row direction='column' container item>
          <Button text='Edit Subscription' onClick={handleSubmit} />
          <BottomBtn container>
            <Button
              text='Cancel Subscription'
              bgcolor='#db1919'
              onClick={() => onCancelSubscription(bundle)}
            />
          </BottomBtn>
        </Row>
      </Container>
    </DrawerWindow>
  );
};

const mapStateToProps = (state) => ({
  updatedSuccessfully: state.modifyBundles.updatedSuccessfully,
});
const mapDispatchToProps = (dispatch) => ({
  modifyBundle: (data) => dispatch(modifyBundle(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBundle);
