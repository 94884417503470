import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Grid, Typography, TextField } from '@material-ui/core';
import { CreateBtn, CancelBtn } from './ClinicAddresses.styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import countries from '../../../../utils/countries_data';
import AddressView from './AddressView';

const addressObj = {
  title: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  officeNumber:"",
  id: ""
};

const AddressModal = (props) => {
  const [errors, setErrors] = useState({
    title: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    officeNumber: "",
    id: ""
  });
  const [address, setAddress] = useState({ ...addressObj });

  useEffect(() => {
    if (props.open) {
      setAddress({ ...addressObj });
    }
  }, [props.open]);

  useEffect(() => {
    if (props.editingAddress) {
      setAddress({ ...props.editingAddress });
    }
  }, [props.editingAddress]);

  const handleSubmit = () => {
    let validated = true;
    let formErrors = {};

    if (!address.title?.trim()) {
      formErrors = {
        ...formErrors,
        title: 'Title can not be empty!',
      };
      validated = false;
    }

    if (!address.address1.trim()) {
      formErrors = {
        ...formErrors,
        address1: 'Address can not be empty!',
      };
      validated = false;
    }
    if (!address.city.trim()) {
      formErrors = {
        ...formErrors,
        city: 'City can not be empty!',
      };
      validated = false;
    }

    if (!address.state?.name) {
      formErrors = {
        ...formErrors,
        state: 'State can not be empty!',
      };
      validated = false;
    }
    if (!address.country) {
      formErrors = {
        ...formErrors,
        country: 'Country can not be empty!',
      };
      validated = false;
    }

    setErrors({ ...formErrors });

    if (validated && props.handleCreateAddress) {
      props.handleCreateAddress(address);
      setAddress({ ...addressObj });
    }
  };

  const handleCreateAddress = (data) => {
    setAddress({ ...data });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      PaperProps={{ style: { maxWidth: '720px' } }}
    >
      <Grid
        style={{ width: '500px', height: '100%', margin: 'auto auto' }}
        justify='center'
        alignItems='center'
        container
      >
        <Grid className={props.classes.modalDisplay}>
          <AddressView
            handleChange={handleCreateAddress}
            editingAddress={address}
            errors={errors}
            showTitle
            officeNumber={props?.officeNumber}
            id={props?.id}
          />

          <Grid style={{ marginBottom: '15px' }} justify='flex-end' container>
            <CancelBtn onClick={props.handleClose}>Cancel</CancelBtn>

            <CreateBtn
              variant='contained'
              color='secondary'
              onClick={handleSubmit}
            >
              Create
            </CreateBtn>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

AddressModal.propTypes = {
  editingAddress: PropTypes.object,
  handleCreateAddress: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    modalDisplay: PropTypes.string.isRequired,
    inputTitle: PropTypes.string.isRequired,
    inputsty: PropTypes.string.isRequired,
    errorText: PropTypes.string.isRequired,
    autoComCountry: PropTypes.string.isRequired,
  }).isRequired,
};

export default AddressModal;
