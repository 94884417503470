import React from 'react';
import {
  Typography,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import useStyles from './Reports_style';

export default function MonthlyReportCard({ setReportToDownload }) {
  const classes = useStyles();

  return (
    <Grid>
      <TableContainer elevation={0} component={Paper}>
        <Table
          className={classes.table}
          aria-label='simple table'
          style={{ marginTop: '20px' }}
        >
          <TableHead style={{ backgroundColor: '#003265' }}>
            <TableRow>
              <TableCell align='left' style={{ color: '#ffffff' }}>
                MONTHLY REPORTS
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.body}>
            <TableRow>
              <TableCell>
                <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                  <Grid item>
                    <Typography className={classes.blue}>
                      99931-02 A - Out- Patients New Visits by Nationality, Sex,
                      Age Groups & Diagnosis
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>

              <TableCell align='right'>
                <Grid item>
                  <Button
                    onClick={() => setReportToDownload('outPatients')}
                    className={classes.downbtn}
                  >
                    Download
                  </Button>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                  <Grid item>
                    <Typography className={classes.blue}>
                      167826-Emirati Patients Data
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>

              <TableCell align='right'>
                <Grid item>
                  <Button
                    onClick={() => setReportToDownload('emirati-patients')}
                    className={classes.downbtn}
                  >
                    Download
                  </Button>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                  <Grid item>
                    <Typography className={classes.blue}>
                      169257-39. Resident Patients Data
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>

              <TableCell align='right'>
                <Grid item>
                  <Button
                    onClick={() => setReportToDownload('emirati-residents')}
                    className={classes.downbtn}
                  >
                    Download
                  </Button>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                  <Grid item>
                    <Typography className={classes.blue}>
                      170688-International Patients Data
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>

              <TableCell align='right'>
                <Grid item>
                  <Button
                    onClick={() => setReportToDownload('international')}
                    className={classes.downbtn}
                  >
                    Download
                  </Button>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                  <Grid item>
                    <Typography className={classes.blue}>
                      Monthly payment Reconciliation
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>

              <TableCell align='right'>
                <Grid item>
                  <Button
                    onClick={() =>
                      setReportToDownload('paymentReconciliationMonthly')
                    }
                    className={classes.downbtn}
                  >
                    Download
                  </Button>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                  <Grid item>
                    <Typography className={classes.blue}>
                      Client’s Pending Payment Report with Date Range
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>

              <TableCell align='right'>
                <Grid item>
                  <Button
                    onClick={() =>
                      setReportToDownload('appointmentsWithPendingPayment')
                    }
                    className={classes.downbtn}
                  >
                    Download
                  </Button>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                  <Grid item>
                    <Typography className={classes.blue}>
                      Diagnosis Report
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>

              <TableCell align='right'>
                <Grid item>
                  <Button
                    onClick={() => setReportToDownload('diagnosisReport')}
                    className={classes.downbtn}
                  >
                    Download
                  </Button>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                  <Grid item>
                    <Typography className={classes.blue}>
                      Appointments Clinical Notes
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>

              <TableCell align='right'>
                <Grid item>
                  <Button
                    onClick={() => setReportToDownload('clinicianNotes')}
                    className={classes.downbtn}
                  >
                    Download
                  </Button>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                  <Grid item>
                    <Typography className={classes.blue}>
                      Credit Notes
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>

              <TableCell align='right'>
                <Grid item>
                  <Button
                    onClick={() => setReportToDownload('creditNotes')}
                    className={classes.downbtn}
                  >
                    Download
                  </Button>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
