import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../../admin/components/common/AdminLayout';
import Listing from './listing';

const Dashboard = (props) => {
  const ROUTES = [{ path: '/admin/service-bundles', component: Listing }];

  return (
    <Switch>
      {ROUTES.map((route, i) => (
        <Route
          key={`bundles-route-${i}`}
          path={route.path}
          render={(routerProps) =>
            React.createElement(route.component, {
              ...props,
              ...routerProps,
            })
          }
          exact
        />
      ))}
    </Switch>
  );
};

export default Dashboard;
