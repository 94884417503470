/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable indent */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-vars */
import React from 'react';

import { Typography, Grid, ListItem, Icon } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import home1 from '../../../assets/imgs/home1.svg';
import home2 from '../../../assets/imgs/home2.svg';
import lab from '../../../assets/imgs/Lab.svg';
import folder from '../../../assets/imgs/folder.svg';
import logout from '../../../assets/imgs/logout.svg';
import setting from '../../../assets/imgs/setting.svg';
import doctor from '../../../assets/imgs/Path 100.svg';
import cal1 from '../../../assets/imgs/Path 91.svg';
import cal2 from '../../../assets/imgs/Path 92.svg';
import cal3 from '../../../assets/imgs/Path 93.svg';
import cal4 from '../../../assets/imgs/Path 95.svg';
import cal5 from '../../../assets/imgs/Path 96.svg';
import event1 from '../../../assets/imgs/Path 103.svg';
import event2 from '../../../assets/imgs/Path 104.svg';
import event3 from '../../../assets/imgs/Path 105.svg';
import event4 from '../../../assets/imgs/Path 106.svg';
import event5 from '../../../assets/imgs/Path 107.svg';
import event6 from '../../../assets/imgs/Path 108.svg';
import { googleSignOut } from '../../../utils/firebase';
import AlertMsg from '../../../utils/Alert';
import useStyles from './AppMenu_style';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import SessionExpireAlert from '../SessionExpireAlert';
import PhoneOTPModal from '../PhoneOTPModal';

function DeshboardMenu(props) {
  const classes = useStyles();
  const clientPhoneNumber = localStorage.getItem('clientPhoneNumber');
  const phoneNumberVerified = localStorage.getItem('phoneNumberVerified');

  const handleLogoutWithGoogle = async () => {
    googleSignOut()
      .then(() => {
        localStorage.removeItem('avatar');
        localStorage.removeItem('name');
        localStorage.removeItem('ACCESS_TOKEN_PATH');
        localStorage.removeItem('createdAt');
        localStorage.removeItem('clientEmail');
        localStorage.removeItem('clientUid');
        localStorage.removeItem('clientId');
        AlertMsg('success', 'You have logged out');
        props.history.push('/login');
      })
      .catch((err) => AlertMsg('error', 'There are some issue.'));
  };

  return (
    <Grid component='div' className={classes.toolbar}>
      <SessionExpireAlert />
      <Grid component='div' container justify='center' alignItems='center'>
        <Grid className={classes.appMenu}>
          <ListItem
            button
            component={NavLink}
            exact
            to='/dashboard'
            className={classes.bodyListContainer}
            activeClassName={classes.active}
          >
            <Grid>
              <img src={home1} className={classes.homeIcon} />
              <img src={home2} style={{ marginTop: '10px' }} />
            </Grid>
            <Typography className={classes.title}>home</Typography>
          </ListItem>

          <ListItem
            button
            component={NavLink}
            exact
            to='/appointments'
            className={classes.bodyListContainer}
            activeClassName={classes.active}
          >
            <Grid style={{ marginTop: '3px' }}>
              <img src={cal4} className={classes.appoinmentIcon3} />
              <img src={cal1} className={classes.appoinmentIcon2} />
              <img src={cal2} className={classes.appoinmentIcon1} />
              <img src={cal3} className={classes.appoinmentIcon} />
              <img src={cal5} />
            </Grid>

            <Typography className={classes.title}>Appointments</Typography>
          </ListItem>

          <ListItem
            button
            component={NavLink}
            exact
            to='/client/bundles'
            className={classes.bodyListContainer}
            activeClassName={classes.active}
          >
            <Grid>
              <FilterNoneIcon style={{ color: '#fff' }} />
            </Grid>

            <Typography className={classes.title}>Bundle Services</Typography>
          </ListItem>

          {/* TODO: HIDDEN FOR NOW */}
          {/* <ListItem
            button
            component={NavLink}
            exact
            to='/doctor'
            className={classes.bodyListContainer}
            activeClassName={classes.active}
          >
            <Grid>
              <img src={doctor} />
            </Grid>

            <Typography className={classes.title}>Doctors</Typography>
          </ListItem> */}

          {/* <ListItem
            button
            component={NavLink}
            exact
            to="/events"
            className={classes.bodyListContainer}
            activeClassName={classes.active}
          >
            <Grid>
              <img src={event1} className={classes.event1} />
              <img src={event2} className={classes.event2} />
              <img src={event3} className={classes.event3} />
              <img src={event4} className={classes.event4} />
              <img src={event5} className={classes.event5} />
              <img src={event6} className={classes.event6} />
            </Grid>

            <Typography className={classes.title}>Events & Seminars</Typography>
          </ListItem> */}

          <ListItem
            button
            component={NavLink}
            exact
            to='/events'
            className={classes.bodyListContainer}
            activeClassName={classes.active}
          >
            <Grid>
              <img src={event1} />
            </Grid>

            <Typography className={classes.title}>Events</Typography>
            {/* </Grid> */}
          </ListItem>
          <ListItem
            button
            component={NavLink}
            exact
            to='/consent-forms'
            className={classes.bodyListContainer}
            activeClassName={classes.active}
          >
            <Grid>
              <img src={folder} />
            </Grid>

            <Typography className={classes.title}>Consent Forms</Typography>
            {/* </Grid> */}
          </ListItem>
          <ListItem
            button
            component={NavLink}
            exact
            to='/payments-history'
            className={classes.bodyListContainer}
            activeClassName={classes.active}
          >
            <Grid>
              <img src={folder} />
            </Grid>

            <Typography className={classes.title}>Payment History</Typography>
            {/* </Grid> */}
          </ListItem>

          <ListItem
            button
            component={NavLink}
            exact
            to='/setting'
            className={classes.bodyListContainer}
            activeClassName={classes.active}
          >
            <Grid>
              <img src={setting} />
            </Grid>

            <Typography className={classes.title}>Setting</Typography>
            {/* </Grid> */}
          </ListItem>

          <Grid
            container
            alignItems='center'
            className={classes.bodyListContainer}
            onClick={handleLogoutWithGoogle}
          >
            <Grid>
              <img src={logout} />
            </Grid>

            <Typography className={classes.title}>Logout</Typography>
          </Grid>
        </Grid>
      </Grid>
      {phoneNumberVerified === 'no' && (
        <PhoneOTPModal clientPhoneNumber={clientPhoneNumber} />
      )}
    </Grid>
  );
}

export default DeshboardMenu;
