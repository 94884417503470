import React, { useState, useEffect, forwardRef, useRef } from 'react';
import TitleNav from './TitleNav';
import CheckoutClientData from './CheckoutClientData';
import CheckoutInvoiceInvoiceTableForManualBilling from './CheckoutInvoiceInvoiceTableForManualBilling';
import CheckoutInvoiceInvoiceTableForSystemAppointment from './CheckoutInvoiceInvoiceTableForSystemAppointment';
import CheckoutPartialPaymentForm from './CheckoutPartialPaymentForm';
import CheckoutPaymentMethods from './CheckoutPaymentMethods';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { getSingleInvoiceAction } from '../../../utils/actions/billing.action';
import {
  eCheckAction,
  getPaymentUrlAction,
  getPaymentResAction,
} from '../../../utils/actions/payment.action';
import deepClone from 'deep-clone';
import Axios from 'axios';
import { baseURL } from '../../../utils/axios';
import AlertMsg from '../../../utils/Alert';
import Dialog from '../../../components/atoms/TailwindDialog';
import PopupWindow from '../../../common/PopupWindow';
import CheckoutInvoiceTotals from './CheckoutInvoiceTotals';

const Checkout = ({
  match,
  billing,
  eCheck,
}) => {
  const [invoice, setInvoice] = useState(null);
  const [services, setServices] = useState([]);
  const history = useHistory();
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [transInfo, setTransInfo] = useState({});
  const [paymentMethod, setPaymentMethod] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [ipAddress, setIpAddress] = useState(null);
  const [paymentURL, setPaymentURL] = useState(null);
  const [loading, setLoading] = useState(false);
  const statusTimer = useRef(null);
  const [partialPayment, setPartialPayment] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getInvoice(match.params.invoiceId);
    console.log('MyComponent onlount');
    getIP();

    setPaymentURL(null);

    return () => {
      console.log('MyComponent onUnmount');
      clearInterval(statusTimer.current)
    };
  }, []);

  useEffect(() => {
    if (billing?.status === 'paid') {
      AlertMsg('info', 'Payment is already paid!');
      history.push(`/admin/billing/${invoice._id}`);
    }
  }, [billing]);

  const getIP = async () => {
    await fetch('https://geolocation-db.com/json/', {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((data) => {
        setIpAddress(data.IPv4);
      });
  };

  const onInfoChange = (e) =>
    setTransInfo({ ...transInfo, [e.target.name]: e.target.value });

  const onDateChange = (e, field) => {
    setTransInfo({ ...transInfo, [field]: e.target.value });
  };

  const handlePayment = (e) => {
    const selectedMethod = e.target.value;
    console.log(selectedMethod)

    setPaymentMethod(selectedMethod);
    setTransInfo({});
  };

  const submitInvoice = () => {
    console.log(Number(partialPayment), Number(invoice.amount) - Number(invoice.totalPaid))
    if (partialPayment && Number(partialPayment) > Number(invoice.amount) - Number(invoice.totalPaid)) {
      AlertMsg("error", "partial payment can not be more then outstanding!")
      return
    }

    let amount = Math.max(0, (total(invoice) - Number(invoice.totalPaid ?? 0)).toFixed(2))

    const paymentObj = {
      paymentMethod,
      transInfo,
      amount: amount,
      outstanding: amount
    };

    let paymentBody = {
      publicIp: ipAddress,
    }

    paymentBody.amount = amount

    if (partialPayment && Number(partialPayment) > 0) {
      paymentBody.amount = partialPayment
      paymentObj.amount = partialPayment
    }

    if (paymentMethod === 'card') {
      getPaymentUrlAction(invoice._id, paymentBody);
    } else {
      setShowConfirmation(false);
      setLoading(true);
      eCheckAction(invoice._id, paymentObj);
    }
  };

  const handleNext = () => {
    clearInterval(statusTimer.current)
    setPaymentURL(null)
    console.log(paymentMethod)
    if (paymentMethod !== 'card') {
      if (paymentMethod === 'pos') {
        if (!transInfo.transactionId || transInfo.transactionId.trim() === '') {
          AlertMsg('error', 'Please enter POS Reference Number!');
          return;
        } else if (
          !transInfo.posReferenceNumberConfirm ||
          transInfo.posReferenceNumberConfirm.trim() === ''
        ) {
          AlertMsg('error', 'Please enter Confirm Reference Number!');
          return;
        } else if (
          transInfo.transactionId.trim() !==
          transInfo.posReferenceNumberConfirm.trim()
        ) {
          AlertMsg('error', 'Reference Numbers do not match!');
          return;
        }
      } else if (paymentMethod === 'cheque') {
        if (!transInfo.accountTitle || transInfo.accountTitle.trim() === '') {
          AlertMsg('error', "Please enter Account holder's name!");
          return;
        } else if (
          !transInfo.transactionId ||
          transInfo.transactionId.trim() === ''
        ) {
          AlertMsg('error', 'Please enter Check Number!');
          return;
        } else if (!transInfo.bankName || transInfo.bankName.trim() === '') {
          AlertMsg('error', 'Please enter Bank name!');
          return;
        } else if (!transInfo.checkDate) {
          AlertMsg('error', 'Please enter Check Date!');
          return;
        } else if (!transInfo.iban?.trim()) {
          AlertMsg('error', 'Please enter IBAN!');
          return;
        } else if (
          !transInfo.accountNumber ||
          transInfo.accountNumber.trim() === ''
        ) {
          AlertMsg('error', 'Please enter Account Number!');
          return;
        }
      } else if (paymentMethod === 'banktranser') {
        if (!transInfo.transactionId || transInfo.transactionId.trim() === '') {
          AlertMsg('error', 'Please enter Transaction ID!');
          return;
        } else if (!transInfo.transactionDate) {
          AlertMsg('error', 'Please enter Transaction Date!');
          return;
        }
      }

      setShowConfirmation(true);
    } else {
      submitInvoice();
    }
  };

  const closePaymentPortal = () => {
    clearInterval(statusTimer.current)
  };

  const getStatusUpdate = (invoiceId) => {
    Axios.post(`${baseURL}/api/payment/${invoiceId}/status`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    }, {
      outstanding: Math.max(0, (total(invoice) - Number(invoice.totalPaid ?? 0)).toFixed(2))
    })
      .then((res) => {
        console.log('res.data[0]', res.data);
        if (res.data?.status === 'paid') {
          clearInterval(statusTimer.current);
          history.push(`/admin/billing/${invoice._id}`);
          AlertMsg('success', 'Payment updated successfully!');
          closePaymentPortal();
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }

  const getPaymentUrlAction = (invoiceId, data) => {
    setIsLoading(true)
    console.log(invoiceId, data)
    Axios.post(`${baseURL}/api/payment/${invoiceId}/create`, data, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        setPaymentURL(res.data.url);
        if (res.data.url) {
          statusTimer.current = setInterval(() => {
            getStatusUpdate(invoice._id);
          }, 2000);

          setShowConfirmation(false);
        } else {
          clearInterval(statusTimer.current)
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }

  const eCheckAction = (invoiceId, data) => {
    console.log(invoiceId, data)
    Axios.patch(`${baseURL}/api/payment/${invoiceId}/echeck`, data, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        console.log('res.data[0]', res.data);
        if (res.data?.message === 'success') {
          AlertMsg('success', 'Payment updated successfully!');
          history.push(`/admin/billing/${invoice._id}`);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }

  const getInvoice = (invoiceId) => {
    Axios.get(`${baseURL}/api/billing/getAll?invoiceId=${invoiceId}`)
      .then((res) => {
        setInvoice(res?.data[0]?.data[0]);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };


  const total = (inv) => {
    if (!inv) {
      return 0;
    }

    const servicesVatAmmount = inv.services
      ? inv.services.map((record, i) => {
        const amount = Number(record.amount);
        const vatPercentage =
          inv?.servicesData.find((service) => service._id === record.serviceId)?.vat || 0;

        const discountedAmount = record.discount
          ? Number((amount - (record.discount / 100) * amount).toFixed(2))
          : amount;

        const vatAmount = vatPercentage
          ? Number(((discountedAmount / 100) * vatPercentage).toFixed(2))
          : 0;


        return Number(discountedAmount + vatAmount).toFixed(2);
      })
      : [0]

    const extraFeesAmount = inv.extraFees
      ? inv.extraFees.map((e) => Number(e.amount))
      : [0];

    return Number([...extraFeesAmount, ...servicesVatAmmount].reduce((sum, a) => Number(sum) + Number(a))).toFixed(2);
  };


  console.log('Checkout invoice', invoice);
  return (
    invoice &&
    services && (
      <> {isLoading ?
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <h2 className="text-center text-white text-xl font-semibold">Loading...</h2>
          <p className="w-1/3 text-center text-white">This may take a few seconds, please don't close this page.</p>
        </div> : <></>}
        <div className='bg-brand-blue-light p-8 leading-normal max-w-3xl m-auto'>
          <TitleNav onEdit={() => history.push(`/admin/billing?editId=${invoice._id}`)} />

          <CheckoutClientData invoice={invoice} services={services} />


          <CheckoutInvoiceInvoiceTableForManualBilling
            invoice={invoice}
            services={invoice.servicesData}
            total={total(invoice)}
          />

          <CheckoutInvoiceTotals
            total={total(invoice)}
            invoice={invoice}
            outstandingBalance={invoice.outstandingBalance}
          />

          <CheckoutPartialPaymentForm invoice={invoice} setPatialPayment={setPartialPayment} />
          {invoice.status !== 'paid' && (
            <CheckoutPaymentMethods
              handlePayment={handlePayment}
              handleNext={handleNext}
              paymentMethod={paymentMethod}
              onInfoChange={onInfoChange}
              onDateChange={onDateChange}
              transInfo={transInfo}
              history={history}
            />
          )}

          <Dialog
            open={showConfirmation}
            onClose={() => setShowConfirmation(false)}
          >
            <div className='py-5 px-8 bg-white border border-gray-500 rounded'>
              <div>Are you sure, you want to continue?</div>

              <div className='mt-8'>
                <button
                  className='ml-3 relative inline-flex items-center px-4 py-2 border border-black-500 text-sm font-medium rounded-md text-black bg-white-500 hover:bg-white-600 hover:text-black'
                  onClick={() => setShowConfirmation(false)}
                >
                  Cancel
                </button>

                <button
                  className='ml-3 relative inline-flex items-center px-4 py-2 border border-blue-500 text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 hover:text-white'
                  onClick={submitInvoice}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Dialog>

          {paymentURL && (
            <PopupWindow
              url={paymentURL}
              closeWindowPortal={closePaymentPortal}
            />
          )}
        </div>
      </>
    )
  );
};

const mapState = (state) => ({
  billing: state.getSingleInvoice.getSingleInvoiceData,
  eCheck: state.eCheck.eCheckData,
});

export default connect(mapState, {})(Checkout);
