import {
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_FAILED,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_FAILED,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_FAILED,
} from '../constant';

const initalState = {
  address: null,
  newAddress: null,
  updatedAddress: null,
  error: null,
};

const formattedAddress = (response) => {
  if (!response) {
    return '';
  }

  let address = '';
  if (response.address1.trim() !== '') {
    address = response.address1 + ',';
  }

  if (response.city.trim() !== '') {
    address = address + ` ${response.city}, `;
  }

  if (response.country && response.country.altName) {
    address = address + ` ${response.country.altName}`;
  }

  address = address.trim() === '' ? '--' : address;

  return address;
};

const ClinicianAddressReducer = (state = initalState, action) => {
  switch (action.type) {
    case GET_ADDRESS_SUCCESS:
      return {
        address: formattedAddress(action.response),
      };
    case GET_ADDRESS_FAILED:
      return {
        address: '--',
      };

    case ADD_ADDRESS_SUCCESS:
      return {
        newAddress: action.response,
        error: null,
      };
    case ADD_ADDRESS_FAILED:
      return {
        error: 'Something went wrong. please try again!',
      };
    case UPDATE_ADDRESS_SUCCESS:
      return {
        updatedAddress: action.response,
        error: null,
      };
    case UPDATE_ADDRESS_FAILED:
      return {
        updatedAddress: null,
        error: 'Something went wrong. please try again!',
      };
    default:
      return state;
  }
};

export default ClinicianAddressReducer;
