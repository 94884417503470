import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  gridsty: {
    paddingTop: theme.spacing(2),
  },
  gridLeft: {
    textAlign: 'left',
  },
  gridRight: {
    textAlign: 'right',
  },
  dateInput: {
    width: '100%',
    height: 38,
    borderRadius: 3,
    borderStyle: 'solid',
    borderColor: '#cccccc',
    color: '#003d79',
    borderWidth: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'inline-block',
    textAlign: 'center',
  },
  createButton: {
    paddingTop: '7px',
    paddingBottom: '7px',
    backgroundColor: '#003d79',
    borderColor: '#003d79',
    minWidth: '70px',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    padding: '5px 15px',
    border: '1px solid',
    cursor: 'pointer',
    color: '#ffffff',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  appointmentsty: {
    color: '#a29061',
    fontSize: '16px',
    lineHeight: 'normal',
    marginLeft: '10px',
    fontWeight: 600,
    textAlign: 'left',
    textTransform: 'capitalize',
  },
  progressSty: {
    marginTop: '8px',
    fontWeight: 600,
    color: '#003d79',
    margin: '10px',
    fontSize: '14px',
    lineHeight: 'normal',
    textAlign: 'left',
    textTransform: 'capitalize',
  },
  timeSty: {
    color: '#a29061',
    minWidth: '56px',
    textAlign: 'right',
    fontSize: '14px',
    lineHeight: 'normal',
    marginTop: '10px',
  },
  margin: {
    backgroundColor: '#a29061',
    lineHeight: '16px',
    fontSize: '11px',
    fontWeight: 600,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: '#ffffff',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    marginLeft: '20px',
  },
  dividersty: {
    width: '92%',
    marginTop: '15px',
    marginBottom: '15px',
    marginRight: '15px',
    // backgroundColor: '#a29061',
  },
});
