import { Button, Icon } from '@material-ui/core';
import styled from 'styled-components';

const ColoredBtn = styled(Button)`
  width: 100%;
  max-height: 49px;
  padding: 7px 9px 7px 9.5px;
  border-radius: 5px;
  text-transform: capitalize;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : '#003265')};
  color: ${(props) => (props.color ? props.color : '#fff')};
`;

const IconImg = styled(Icon)`
  height: auto;
  margin-right: 5px;
`;

const TransparentBtn = styled(Button)`
  background: transparent !important;
  min-width: 0px !important;
  padding: 0px !important;
  text-transform: none;
  opacity: ${(props) => (props.opacity ? props.opacity : '1')} !important;
  @media (max-width: 414px) {
    width: ${(props) => (props.fullatmobile ? '100%' : 'auto')} !important;
  }
  &:hover {
    background: transparent !important;
    min-width: 0px !important;
    padding: 0px !important;
  }
`;

export { ColoredBtn, IconImg, TransparentBtn };
