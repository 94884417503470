/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable import/prefer-default-export */
import {
    GET_ALL_PRESCRIPTIONS_REQUEST,
    GET_ALL_PRESCRIPTIONS_BY_ID_REQUEST,
  } from "../constant";
  
  
  export const getPrescriptionsAction = (id) => ({
    type: GET_ALL_PRESCRIPTIONS_REQUEST,
    endpoint: `/api/client/getPrescription?clientId=${id}`,
  });
  
  export const getByIdPrescriptionsAction = (id) => ({
    type: GET_ALL_PRESCRIPTIONS_BY_ID_REQUEST,
    endpoint: `/api/client/prescriptionDetail/${id}`,
  });
  
  