import React from 'react';
import { TransparentBtn } from './style';

const ButtonWithNoPadding = ({ children, onClick, opacity, fullatmobile }) => (
  <TransparentBtn
    opacity={opacity}
    onClick={onClick}
    fullatmobile={fullatmobile}
  >
    {children}
  </TransparentBtn>
);

export default ButtonWithNoPadding;
