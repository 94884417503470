import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  root: {
    padding: '20px',
    marginLeft: 250,
    marginTop: 70,
  },
  flexStyle: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  title: {
    width: 'auto',
    float: 'left',
    fontSize: '20px',
    lineHeight: '37px',
    fontWeight: 400,
    textTransform: 'capitalize',
    color: '#003d79',
  },
  boxsty: {
    border: '1px solid #d7d7d7',
    borderRadius: '3px',
    padding: '40px',
    marginTop: '10px',
  },
  heading: {
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '18px',
    color: '#003d79',
  },
  numtext: {
    fontSize: '24px',
    fontWeight: 600,
    marginTop: '15px',
    marginBottom: '15px',
    textAlign: 'center',
    lineHeight: '18px',
    color: '#a29061',
  },
});
