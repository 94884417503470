import { createStyles} from "@material-ui/core";

export default (theme) =>
 createStyles({
    root: {
      backgroundColor: "#ffffff",
    },
    container: {
      marginTop: "50px",
      textAlign: "left",
      marginBottom: "50px",
      marginLeft: "60px",
    },
    heading: {
      width: "805px",
      height: "154px",
      fontSize: "48px",
      lineHeight: "45px",
      color: "#002060",
      fontFamily: "Effra Light",
      fontWeight: 300,
    },
    subHeading: {
      fontSize: "24px",
      lineHeight: "54px",
      color: "#002060",
      fontFamily: "Effra Light",
      fontWeight: 300,
    },
    firstParaStyle: {
      width: "1002px",
      height: "106px",
      fontSize: "24px",
      lineHeight: "30px",
      color: "#a49260",
      fontFamily: "Effra Medium",
      fontWeight: 500,
    },
    secParaStyle: {
      width: "997px",
      height: "108px",
      fontSize: "24px",
      lineHeight: "29px",
      color: "#002060",
      fontFamily: "Effra Medium",
      fontWeight: 500,
    },
    subHeadingText: {
      fontSize: "22px",
      lineHeight: "50px",
      color: "#003d79",
      fontWeight: 500,
      marginTop: "10px",
    },
    mainParaStyle: {
      fontSize: "22px",
      letterSpacing: 0,
      lineHeight: "30px",
      color: "#003d79",
      fontFamily: "Effra Light",
      fontWeight: 300,
    },
    listContainer: {
      marginLeft: "45px",
    },
    listPoint: {
      color: "#a49260",
      fontFamily: "Symbol",
      fontSize: "22px",
      lineHeight: "28px",
    },
    boxContainer: {
      width: "934px",
      height: "334px",
      backgroundColor: "#dce6f1",
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: "20px",
    },
    tableText: {
      fontSize: "15px",
      lineHeight: "19px",
      color: "#003d79",
      fontFamily: "Effra Medium",
      fontWeight: 500,
    },
    tableHeader: {
      backgroundColor: "#dce6f1",
      height: "28px",
      borderBottom: "0.5px solid #001f5f",
    },
    tableHeaderText: {
      fontSize: "21px",
      lineHeight: "27px",
      color: "#003d79",
      fontFamily: "Effra Light",
      fontWeight: 300,
      marginLeft: "10px",
    },
    tableSubText: {
      fontSize: "22px",
      lineHeight: "27px",
      color: "#003d79",
      fontFamily: "Effra Light",
      fontWeight: 300,
      marginLeft: "10px",
    },
  });