import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

const Label = styled(Typography)`
  text-align: left;
  fontsize: 13px;
  color: grey;
  font-weight: 500;
  margin-bottom: 4px;
`;

const Error = styled.span`
  color: red;
  font-family: Lato;
  font-weight: 400;
  font-size: 16px;
`;

const Required = styled.span`
  color: red;
`;

const InputDiv = styled.div`
  width: 100%;
  input {
    border: 1px solid #e5e5e5;
    border-radius: 0px;
    height: 45px;
    width: 100%;
    padding-left: 5px;
  }
`;

export { Required, Error, Label, InputDiv };
