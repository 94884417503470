/* eslint-disable no-unused-vars */
import { put, takeEvery } from 'redux-saga/effects';
import { post, get, updatePatch, delet } from '../axios';
import {
  ADD_APPOINTMENT_SUCCESS,
  ADD_APPOINTMENT_FAILED,
  ADD_APPOINTMENT_REQUEST,
  DELETE_APPOINTMENT_FAILED,
  DELETE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_FAILED,
  UPDATE_APPOINTMENT_SUCCESS,
  GET_BY_ID_APPOINTMENT_FAILED,
  GET_BY_ID_APPOINTMENT_SUCCESS,
  GET_APPOINTMENT_FAILED,
  GET_APPOINTMENT_SUCCESS,
  GET_APPOINTMENT_REQUEST,
  GET_BY_ID_APPOINTMENT_REQUEST,
  UPDATE_APPOINTMENT_REQUEST,
  DELETE_APPOINTMENT_REQUEST,
  GET_SERVICE_SUCCESS_USER,
  GET_SERVICE_FAILED_USER,
  GET_SERVICE_REQUEST_USER,
  GET_APPOINTMENT_BY_CLIENT_SUCCESS,
  GET_APPOINTMENT_BY_CLIENT_FAILED,
  GET_APPOINTMENT_BY_CLIENT_REQUEST,
  GET_APPOINTMENT_DETAIL_SUCCESS,
  GET_APPOINTMENT_DETAIL_FAILED,
  GET_APPOINTMENT_DETAIL_REQUEST,
  GET_PREVIOUS_APPOINTMENT_SUCCESS,
  GET_PREVIOUS_APPOINTMENT_FAILED,
  GET_PREVIOUS_APPOINTMENT_REQUEST,
  FILTERED_APPOINTMENTS_SUCCESS,
  FILTERED_APPOINTMENTS_FAILED,
  FILTERED_APPOINTMENTS_REQUEST,
  SEEN_STATUS_SUCCESS,
  SEEN_STATUS_FAILED,
  SEEN_STATUS_REQUEST,
  GET_CONSENT_INFO_REQUEST,
  GET_CONSENT_INFO_FAILED,
  GET_CONSENT_INFO_SUCCESS,
  SIGN_CONSENT_REQUEST,
  SIGN_CONSENT_SUCCESS,
  SIGN_CONSENT_FAILED,
  CLINICIAN_SIGN_CONSENT_REQUEST,
  CLINICIAN_SIGN_CONSENT_SUCCESS,
  CLINICIAN_SIGN_CONSENT_FAILED,
  APPOINTMENTS_OF_CLIENT_REQUEST,
  APPOINTMENTS_OF_CLIENT_SUCCESS,
  APPOINTMENTS_OF_CLIENT_FAILED,
  APPOINTMENTS_OF_CLIENT_REPORT_REQUEST,
  APPOINTMENTS_OF_CLIENT_REPORT_SUCCESS,
  APPOINTMENTS_OF_CLIENT_REPORT_FAILED,
} from '../constant';

function* addAppointment(action) {
  try {
    const { endpoint, body } = action;
    const response = yield post(endpoint, body);
    yield put({ type: ADD_APPOINTMENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: ADD_APPOINTMENT_FAILED, error });
  }
}

function* getAppointment(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_APPOINTMENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_APPOINTMENT_FAILED, error });
  }
}

function* getByIdAppointment(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_BY_ID_APPOINTMENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_BY_ID_APPOINTMENT_FAILED, error });
  }
}

function* updateAppointment(action) {
  try {
    const { endpoint, body } = action;
    const response = yield updatePatch(endpoint, body);
    yield put({ type: UPDATE_APPOINTMENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: UPDATE_APPOINTMENT_FAILED, error });
  }
}

function* deleteAppointment(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: DELETE_APPOINTMENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: DELETE_APPOINTMENT_FAILED, error });
  }
}

function* getService(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_SERVICE_SUCCESS_USER, response });
  } catch (error) {
    yield put({ type: GET_SERVICE_FAILED_USER, error });
  }
}

function* getAppointmentByClientId(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_APPOINTMENT_BY_CLIENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_APPOINTMENT_BY_CLIENT_FAILED, error });
  }
}

function* getAppointmentDetail(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_APPOINTMENT_DETAIL_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_APPOINTMENT_DETAIL_FAILED, error });
  }
}

function* getPreviousAppointment(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_PREVIOUS_APPOINTMENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_PREVIOUS_APPOINTMENT_FAILED, error });
  }
}

function* filterAppointments(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: FILTERED_APPOINTMENTS_SUCCESS, response });
  } catch (error) {
    yield put({ type: FILTERED_APPOINTMENTS_FAILED, error });
  }
}

function* updateToSeen(action) {
  try {
    const { endpoint } = action;
    const response = yield updatePatch(endpoint);
    yield put({ type: SEEN_STATUS_SUCCESS, response });
  } catch (error) {
    yield put({ type: SEEN_STATUS_FAILED, error });
  }
}

function* consentInfo(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_CONSENT_INFO_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_CONSENT_INFO_FAILED, error });
  }
}

function* signConsent(action) {
  try {
    const { endpoint, body } = action;
    const response = yield updatePatch(endpoint, body);
    yield put({ type: SIGN_CONSENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: SIGN_CONSENT_FAILED, error });
  }
}

function* clinicianSign(action) {
  try {
    const { endpoint } = action;
    const response = yield updatePatch(endpoint);
    yield put({ type: CLINICIAN_SIGN_CONSENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: CLINICIAN_SIGN_CONSENT_FAILED, error });
  }
}

function* getAllAppointmentOfClient(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: APPOINTMENTS_OF_CLIENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: APPOINTMENTS_OF_CLIENT_FAILED, error });
  }
}

function* getAllAppointmentOfClientForReport(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: APPOINTMENTS_OF_CLIENT_REPORT_SUCCESS, response });
  } catch (error) {
    yield put({ type: APPOINTMENTS_OF_CLIENT_REPORT_FAILED, error });
  }
}

export function* appointmentSaga() {
  yield takeEvery(ADD_APPOINTMENT_REQUEST, addAppointment);
  yield takeEvery(GET_APPOINTMENT_REQUEST, getAppointment);
  yield takeEvery(GET_BY_ID_APPOINTMENT_REQUEST, getByIdAppointment);
  yield takeEvery(UPDATE_APPOINTMENT_REQUEST, updateAppointment);
  yield takeEvery(DELETE_APPOINTMENT_REQUEST, deleteAppointment);
  yield takeEvery(GET_SERVICE_REQUEST_USER, getService);
  yield takeEvery(GET_APPOINTMENT_BY_CLIENT_REQUEST, getAppointmentByClientId);
  yield takeEvery(GET_APPOINTMENT_DETAIL_REQUEST, getAppointmentDetail);
  yield takeEvery(GET_PREVIOUS_APPOINTMENT_REQUEST, getPreviousAppointment);
  yield takeEvery(FILTERED_APPOINTMENTS_REQUEST, filterAppointments);
  yield takeEvery(SEEN_STATUS_REQUEST, updateToSeen);
  yield takeEvery(GET_CONSENT_INFO_REQUEST, consentInfo);
  yield takeEvery(SIGN_CONSENT_REQUEST, signConsent);
  yield takeEvery(CLINICIAN_SIGN_CONSENT_REQUEST, clinicianSign);
  yield takeEvery(APPOINTMENTS_OF_CLIENT_REQUEST, getAllAppointmentOfClient);
  yield takeEvery(
    APPOINTMENTS_OF_CLIENT_REPORT_REQUEST,
    getAllAppointmentOfClientForReport
  );
}
