/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { withStyles, Grid, Typography } from "@material-ui/core";
import {
  CustomNextButtons,
  CustomCancelButtons,
} from "../common/CustomButtons";
import styles from "./AssessmentEntryPage_style";

export class AssessmentEntryPage extends Component {
  
  render() {
    const { classes, assessment, handleEntryPageNext } = this.props;
    
    return (
        <React.Fragment>
            <Typography className={classes.title}>
              {assessment.title}
            </Typography>
            <Grid
              container
              alignItems="center"
              justify="center"
              component="div"
              className={classes.bodyContainer}
            >
              <Grid
                container
                alignItems="center"
                justify="center"
                component="div"
                className={classes.bodySubContainer}
              >
                <Typography className={classes.bodyText}>
                    <p dangerouslySetInnerHTML={{
                        __html: assessment.description,
                      }}
                    />
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justify="center"
              style={{ marginTop: "30px", marginBottom: "100px" }}
            >
              <CustomCancelButtons
                width="170px"
                title="Cancel"
                style={classes.cancelButton}
                onPressButton={() => this.props.history.goBack()}
              />
              <CustomNextButtons
                width="170px"
                title="Next"
                style={classes.nextButton}
                onPressButton={handleEntryPageNext}
              />
            </Grid>
        </React.Fragment>
    );
  }
}

export default withStyles(styles)(AssessmentEntryPage);
