import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: '17px',
    fontWeight: 600,
    lineHeight: '23px',
    marginBottom: '15px',
    display: 'block',
    textTransform: 'capitalize',
    textAlign: 'left',
    color: '#003d79',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  subtitle: {
    color: '#003d79',
    fontSize: '14px',
    lineHeight: '18px',
    margin: '-8px 0 20px',
    textAlign: 'left',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  heading: {
    position: 'static',
    width: 'auto',
    textAlign: 'left',
    fontWeight: 400,
    marginBottom: '5px',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#a29061',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  selectsty: {
    width: '80%',
    textAlign: 'left',
    marginBottom: '20px',
  },
  divstyle: {
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#cfd8dc',
    height: 40,
    borderRadius: 5,
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(8),
    fontSize: 16,
    color: '#555',
    cursor: 'text',
    marginBottom: '20px',
  },
  textareasty: {
    maxWidth: '100%',
    minWidth: '100%',
    // maxHeight: '100%',
    minHeight: '30%',
    display: 'block',
    overflow: 'hidden',
    borderColor: '#cfd8dc',
    fontSize: '14px',
    lineHeight: '18px',
    paddingTop: '5px',
    paddingBottom: '7px',
    padding: '0 10px 1px',
    color: '#555',
    marginBottom: '20px',
  },
  saveButton: {
    backgroundColor: '#a29061',
    borderColor: '#a29061',
    minWidth: '70px',
    width: 'auto',
    cursor: 'pointer',
    fontSize: '12px',
    lineHeight: '16px',
    padding: '5px 15px',
    border: '1px solid',
    fontWeight: 600,
    color: '#ffffff',
    textAlign: 'center',
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    textTransform: 'capitalize',
  },
});
