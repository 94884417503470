import { makeStyles} from "@material-ui/core";

export default makeStyles((theme) => ({
    root: {
      height: "370px",
      position: "absolute",
      zIndex: 1,
      width: "calc(100% - 100px)",
      backgroundColor: "rgba(0,50,101,80%)",
    },
    body: {
      width: "370px",
      height: "42px",
      borderRadius: "21px",
      backgroundColor: "#ffffff",
      fontSize: "18px",
      color: "#003265",
      fontWeight: 700,
      fontFamily: "Lato",
      textAlign: "center",
      cursor: "pointer",
    },
    appointmentCancelButton: {
      backgroundColor: "#fff",
      // zIndex: 3,
      width: "30px",
      height: "30px",
      borderRadius: "50px",
      position: "absolute",
      right: "5px",
      top: "5px",
      // marginTop: "5px",
      cursor: "pointer",
      color: "red",
      fontFamily: "Lato",
      fontWeight: 700,
      fontSize: "17px",
    },
  }));