import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField, CircularProgress } from '@material-ui/core';
import { baseURL } from '../../../utils/axios';
import { getCliniciansByIds } from '../../../utils/actions/clinician.action';
import AlertMsg from '../../../utils/Alert';
import Axios from 'axios';
import { CustomAutoComplete } from './style';
import { withStyles } from '@material-ui/core/styles';

const CustomAutocomplete = withStyles({
  tag: {
    color: 'white',
    position: 'relative',
    '& .MuiChip-deleteIcon': {
      color: 'inherit',
    },
  },
})(CustomAutoComplete);

const DoctorSelectionModal = ({
  onChange,
  selectAll,
  clinicians,
  getCliniciansByIds,
  oldCliniciansList,
}) => {
  const [searchText, setSearchText] = useState('');
  const [diagnosis, setDiagnosis] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectAll && (!clinicians || clinicians.length === 0)) {
      handleSelectAll();
    }
  }, [selectAll, clinicians]);

  useEffect(() => {
    if (searchText) {
      getDiagnosis();
    }
  }, [searchText]);

  useEffect(() => {
    if (clinicians.length > 0) {
      getCliniciansByIds(clinicians.map((c) => c._id).join(','));
    }
  }, []);

  useEffect(() => {
    if (!loading && oldCliniciansList && clinicians.length > 0) {
      setSelectedDoctor(oldCliniciansList);
    }
  }, [loading, oldCliniciansList, clinicians]);

  const handleSelectAll = async () => {
    await setSearchText('');
    await getDiagnosis();
    if (selectAll != null) {
      var temp = [];
      if (!selectAll) {
        temp = [];
      } else {
        temp = diagnosis;
      }

      setSelectedDoctor(temp);
      onChange(temp);
    }
  };

  const getDiagnosis = () => {
    setLoading(true);
    Axios.get(`${baseURL}/api/user/clinicians?term=${searchText}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        setDiagnosis(res.data);
        setLoading(false);
      })
      .catch((e) => {
        AlertMsg('error', e.toString());
        setLoading(false);
      });
  };

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleValueChange = (e, newValue) => {
    setSelectedDoctor(newValue);
    onChange(newValue);
  };

  return (
    <CustomAutocomplete
      multiple
      options={diagnosis}
      onChange={(event, newValue) => handleValueChange(event, newValue)}
      getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
      getOptionSelected={(option, value) => option._id === value._id}
      value={selectedDoctor}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={handleChange}
          placeholder='Select Doctors'
          variant='standard'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

DoctorSelectionModal.propTypes = {
  onChange: PropTypes.func.isRequired,
  getCliniciansByIds: PropTypes.func,
  oldCliniciansList: PropTypes.array,
  clinicians: PropTypes.array,
  selectAll: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  oldCliniciansList: state.getByIdsClinicians.clinicians,
});

export default connect(mapStateToProps, { getCliniciansByIds })(
  DoctorSelectionModal
);
