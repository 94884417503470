import React, { useState, useEffect, createRef } from 'react';
import { connect } from 'react-redux';
import Layout from '../common/AdminLayout';
import useStyles, { ActionBtn } from './Dashbord_style';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import PersonIcon from '@material-ui/icons/Person';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import userImg from '../../../assets/imgs/ananyaBig-1.png';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { CanvasDialog } from '../../../components/atoms';
import {
  getClients,
  getClientsReport,
} from '../../../utils/actions/client.action';
import {
  getAllAppointmentByBillingReport,
  getAllAppointmentByBilling,
  clinicianSignOnConsentForm,
} from '../../../utils/actions/appointment.action';
import { calculateAge } from '../../../utils/timUtils';
import moment from 'moment';
import Loader from '../../../components/Loader/Loader';
import { getPageCount } from '../../../utils/MathUtil';
import Pagination from '@material-ui/lab/Pagination';
import PatientDetailClinician from '../../../clinician/Patients/patientDetail';
import { STATUSES } from '../../../utils/enums';
import AppointmentDetailsView from '../../../clinician/AppointmentDetailsView';
import {
  loggedInUserInfo,
  saveClinicianSignature,
} from '../../../utils/actions/clinician.action';
import { getConsentForms } from '../../../utils/actions/consent.action';
import AlertMsg from '../../../utils/Alert';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import ClientAppointments from './ClientAppointments';
import UnverifiedAccount from './UnverifiedAccount';

const RED = '#DA1A1A';
const GREEN = '#01BC05';

const AntTabs = withStyles({
  root: {
    borderBottom: '2px solid #d1d9e5',
  },
  indicator: {
    backgroundColor: '#003265',
    height: '3px',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '16px',
    marginRight: theme.spacing(3),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#003265',
      opacity: 1,
    },
    '&$selected': {
      color: '#003265',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#003265',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Dashboard = ({
  getClients,
  getClientsReport,
  clients,
  clientCount,
  getUnpaidAppointments,
  unpaidAppointments,
  appointmentCount,
  getConsentForms,
  consent,
  saveClinicianSignature,
  clinicianSignOnConsentForm,
  signatureSaved,
  updatedSignature,
  signedSuccessfully,
  loggedInUserInfo,
  loggedInUser,
  clientsReportData,
  getAllAppointmentByBillingReport,
  unpaidAppointmentsReportData,
  history,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [appliedFilters, setAppliedFilters] = useState({});
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [patientDrawer, setPatientDrawer] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [date, setDate] = useState({ createdAt: 'current_day' });
  const [appointmentId, setAppointmentId] = useState('');
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [detailDrawer, setDetailDrawer] = useState(false);
  const [openCanvasDialog, setCanvasDialog] = useState(false);
  const [signature, setSignature] = useState('');
  const [consentForms, setConsentForms] = useState([]);
  const [billingId, setBillingId] = useState('');
  const [reportFor, setReportFor] = useState('');

  let signaturePad = createRef();

  useEffect(() => {
    if (clientsReportData) {
      if (reportFor === 'new-clients') {
        generateNewClientReport(clientsReportData);
      } else if (reportFor === 'unverified') {
        generateUnverifiedClientReport(clientsReportData);
      }
    }
  }, [clientsReportData]);

  useEffect(() => {
    if (reportFor === 'unpaid-appointments') {
      generateReportForUnpaidAppointments(unpaidAppointmentsReportData);
    }
  }, [unpaidAppointmentsReportData]);
  useEffect(() => {
    if (consent.data) {
      setLoading(false);
      setConsentForms(consent.data);
      setTotalCount(consent.count);
    }
  }, [consent]);

  useEffect(() => {
    if (signatureSaved) {
      setSignature(updatedSignature);
    }
  }, [signatureSaved, updatedSignature]);

  useEffect(() => {
    if (signedSuccessfully) {
      const updatedForms = consentForms.filter(
        (cf) => cf.appointmentId[0]._id !== appointmentId
      );
      setConsentForms(updatedForms);
      setCanvasDialog(false);
      setAppointmentId(null);
    }
  }, [signedSuccessfully]);

  useEffect(() => {
    if (loggedInUser) {
      setSignature(loggedInUser.signature);
    }
  }, [loggedInUser]);

  const setRef = (ref) => {
    if (ref && ref.fromDataURL) {
      ref.fromDataURL(signature);
      signaturePad = ref;
    }

    if (ref && ref.toDataURL) {
      ref.toDataURL();
      signaturePad = ref;
    }

    if (signature && ref && ref.off) {
      ref.off();
      signaturePad = ref;
    }

    if (ref && ref.clear) {
      ref.clear();
      signaturePad = ref;
    }
  };

  const handleDetailDrawer = (appId) => {
    setAppointmentId(appId);
    setDetailDrawer(true);
  };

  const closeDetailDrawer = () => {
    setAppointmentId(null);
    setDetailDrawer(false);
  };

  const openSignatureCanvas = (appId, clinicianSignDate) => {
    if (!clinicianSignDate && loggedInUser?.role?.role !== 'clinician') {
      AlertMsg(
        'error',
        'Witness can only sign if clinician has signed already!'
      );
      return;
    }
    setAppointmentId(appId);
    setCanvasDialog(true);
  };

  const closeCanvas = () => {
    setAppointmentId(null);
    setCanvasDialog(false);
  };

  const handleSignatureUpdate = (modSignature) => {
    setSignature(modSignature);
    setCanvasDialog(false);
  };
  const clearSignature = () => {
    if (signaturePad) {
      signaturePad.clear();
    }
  };

  const modifyUserSignature = () => {
    if (signaturePad) {
      if (signaturePad.isEmpty()) {
        AlertMsg(
          'error',
          'If you plan to add signature later then click on dark space!'
        );
      } else {
        const base64Img = signaturePad.toDataURL();
        saveClinicianSignature({
          signature: base64Img,
        });
      }
    }
  };

  const signConsentForm = () => clinicianSignOnConsentForm(appointmentId);

  const renderSessionTime = (app) =>
    `${moment(app.startTime).format('LT')} - ${moment(app.startTime)
      .add(app.duration, 'minutes')
      .format('LT')}`;

  const checkEmergencyContact = (c) =>
    c.emergencyContact && c.emergencyContact.emEmail;

  const alreadySignedByLoggedInUser = (d) =>
    loggedInUser?.role?.role === 'clinician'
      ? d.clinicianSign.signDate
      : d.witnessSign;

  const handleTabChange = (event, val) => {
    setPage(1);
    setValue(val);
  };

  const handlePageChange = (event, value) => setPage(value);

  useEffect(() => {
    setLoading(true);
    const clinicianId = localStorage.getItem('clinicianUID');

    if (value === 0) {
      getClients(
        searchText,
        JSON.stringify({ clientStatus: 'totallyNew', ...date }),
        page
      );
    } else if (value === 1) {
      getClients(
        searchText,
        JSON.stringify({ clientStatus: 'unverified', ...date }),
        page
      );
    } else if (value === 2) {
      getClients(
        searchText,
        JSON.stringify({
          clientStatus: 'unverified',
          appointmentStatus: 'upcomiing',
          ...date,
        }),
        page
      );
    } else if (value === 3) {
      getUnpaidAppointments(page, 'unpaid', date.createdAt);
    } else if (value === 4) {
      getUnpaidAppointments(page, 'unpaid-upcoming', date.createdAt);
    } else if (value === 5) {
      loggedInUserInfo();
      getConsentForms(clinicianId, {
        searchText: '',
        selectedFilter: 'unsigned',
        ...date,
      });
    }
  }, [page, date, value]);

  useEffect(() => {
    console.log('clients', clients);
    if (clients) {
      setList(clients);
      setTotalCount(clientCount);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [clients]);

  useEffect(() => {
    if (unpaidAppointments) {
      setList(unpaidAppointments);
      setTotalCount(appointmentCount);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [unpaidAppointments]);

  const openPatientDetail = (id) => {
    setPatientDrawer(true);
    setPatientId(id);
  };

  const togglePatientDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setPatientDrawer(open);
    setPatientId('');
  };

  const renderAge = (client) =>
    calculateAge(new Date(client.dob)) < 18 ? 'Child' : 'Adult';

  const formatUser = (user) => (
    <Grid container spacing={2} wrap='nowrap' alignItems='center'>
      <Grid item>
        <Avatar src={user.avatar} />
      </Grid>
      <Grid item>
        <Typography className={classes.light}>{`${
          user.prefix ? user.prefix : ''
        } ${user.firstName} ${user.lastName} ${
          user.suffix ? ', ' + user.suffix : ''
        }`}</Typography>
      </Grid>
    </Grid>
  );

  const openInvoiceDrawer = (billing) =>
    history.push(`/admin/generate-invoice/${billing}`);

  const downloadNewClientsData = () => {
    if (clients.length === 0) {
      AlertMsg('error', 'No Data present to download');
      return;
    }

    setReportFor('new-clients');
    getClientsReport(
      searchText,
      JSON.stringify({ clientStatus: 'totallyNew', export: 1, ...date })
    );
  };

  const downloadUnverifiedClients = () => {
    if (clients.length === 0) {
      AlertMsg('error', 'No Data present to download');
      return;
    }

    setReportFor('unverified');
    getClientsReport(
      searchText,
      JSON.stringify({ clientStatus: 'unverified', export: 1, ...date })
    );
  };

  const downloadUnpaidAppointmentData = () => {
    if (unpaidAppointments.length === 0) {
      AlertMsg('error', 'No Data present to download');
      return;
    }

    setReportFor('unpaid-appointments');
    getAllAppointmentByBillingReport('unpaid', date.createdAt);
  };

  const downloadConsentFormReport = () => {
    let formattedData = [];
    if (consentForms.length === 0) {
      AlertMsg('error', 'No Data present to download');
      return;
    }

    consentForms.forEach((cf) => {
      formattedData.push({
        Client: `${cf.clientSign._id[0].firstName} ${cf.clientSign._id[0].lastName}`,
        'Age Group': renderAge(cf.clientSign._id[0]),
        'Date Created': moment(cf.appointmentId[0].startTime).format('L'),
        'Clinician Sign': cf.clinicianSign?.signDate ? 'Yes' : 'No',
        'Witness Sign': cf.witnessSign?.signDate ? 'Yes' : 'No',
      });
    });

    exportToCSV(formattedData, 'Consent Forms', 'Consent Forms');
  };

  const renderDOB = (record) => {
    if (record) {
      return calculateAge(new Date(record.dob));
    }
  };

  const generateNewClientReport = (data) => {
    let formattedData = [];

    data.forEach((client) => {
      formattedData.push({
        'Chart Number': client.chartId,
        Client: `${client.firstName} ${client.lastName}`,
        Email: client.email,
        'Date of birth': renderDOB(client),
        'Age Group': renderAge(client),
        'Date Created': moment(client.createdAt).format('DD-MM-YYYY'),
      });
    });

    setReportFor('');
    exportToCSV(formattedData, 'New Clients', 'New Clients');
  };

  const generateUnverifiedClientReport = (data) => {
    let formattedData = [];
    data.forEach((client) => {
      formattedData.push({
        'Chart Number': client.chartId,
        Client: `${client.firstName} ${client.lastName}`,
        Email: client.email,
        'Date of birth': renderDOB(client),
        'Age Group': renderAge(client),
        'Date Created': moment(client.createdAt).format('DD-MM-YYYY'),
      });
    });

    setReportFor('');
    exportToCSV(formattedData, 'Unverified Clients', 'Unverified Clients');
  };

  const generateReportForUnpaidAppointments = (data) => {
    let formattedData = [];
    data.forEach((unp) => {
      const client = unp.clientId[unp.clientId.length - 1];
      const clinician = unp.clinicianId[0];

      formattedData.push({
        'Chart Number': client.chartId,
        'Patient(s)': `${client.firstName} ${client.lastName}`,
        Email: client.email,
        Clinician: `${clinician.firstName} ${clinician.lastName}`,
        Date: moment(unp.startTime).format('L'),
        Time: renderSessionTime(unp),
        'Appointment Status': STATUSES[unp.status],
      });
    });

    setReportFor('');
    exportToCSV(formattedData, 'Unpaid Appointments', 'Unpaid Appointments');
  };

  const exportToCSV = (data, fileName, sheetName) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blobData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(blobData, fileName + fileExtension);
  };

  const checkBasicInfo = (c) =>
    c.phoneNumber &&
    c.phoneType &&
    c.maritalStatus &&
    c.gender &&
    c.address &&
    c.address?.address1 &&
    c.address?.country &&
    c.address?.city;

  return (
    <div>
      <>
        <Grid>
          <Grid container>
            <Grid xs={12}>
              <Grid style={{ marginTop: '20px', float: 'right' }}>
                <Button
                  variant='contained'
                  className={classes.btn2}
                  style={{
                    marginRight: '10px',
                    color:
                      date.createdAt === 'current_day' ? '#FFFFFF' : '#003265',
                    backgroundColor:
                      date.createdAt === 'current_day' ? '#003265' : '#D1D9E5',
                  }}
                  onClick={() =>
                    setDate({
                      createdAt: 'current_day',
                    })
                  }
                >
                  Today
                </Button>
                <Button
                  variant='contained'
                  className={classes.btn2}
                  style={{
                    marginRight: '10px',
                    color:
                      date.createdAt === 'last_week' ? '#FFFFFF' : '#003265',
                    backgroundColor:
                      date.createdAt === 'last_week' ? '#003265' : '#D1D9E5',
                  }}
                  onClick={() =>
                    setDate({
                      createdAt: 'last_week',
                    })
                  }
                >
                  Week
                </Button>
                <Button
                  variant='contained'
                  className={classes.btn2}
                  style={{
                    marginRight: '10px',
                    color:
                      date.createdAt === 'last_month' ? '#FFFFFF' : '#003265',
                    backgroundColor:
                      date.createdAt === 'last_month' ? '#003265' : '#D1D9E5',
                  }}
                  onClick={() =>
                    setDate({
                      createdAt: 'last_month',
                    })
                  }
                >
                  Month
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid style={{ marginTop: '40px' }}>
            <Grid>
              <AntTabs
                variant='scrollable'
                value={value}
                onChange={handleTabChange}
              >
                <AntTab label='New Account' {...a11yProps(0)}></AntTab>
                <AntTab label='Unverified Accounts' {...a11yProps(1)} />
                <AntTab
                  label='Upcoming With Unverified Accounts'
                  {...a11yProps(2)}
                />
                <AntTab label='Pending Payments' {...a11yProps(3)} />
                <AntTab
                  label='Upcoming With Pending Payments'
                  {...a11yProps(4)}
                />
                <AntTab label='Consent Requests ' {...a11yProps(5)} />
              </AntTabs>
            </Grid>

            <Grid>
              <TabPanel value={value} index={0}>
                <TableContainer elevation={0}>
                  <Table className={classes.table} aria-label='simple table'>
                    <TableHead style={{ backgroundColor: '#F6F7FB' }}>
                      <TableRow>
                        <TableCell style={{ color: '#C7C7C9' }}>
                          CLIENT
                        </TableCell>
                        <TableCell align='left' style={{ color: '#C7C7C9' }}>
                          AGE GROUP
                        </TableCell>
                        <TableCell align='left' style={{ color: '#C7C7C9' }}>
                          DATE CREATED
                        </TableCell>
                        <TableCell align='right' style={{ color: '#C7C7C9' }}>
                          ACTIONS
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.body}>
                      {loading ? (
                        <Loader />
                      ) : clients && clients.length > 0 ? (
                        clients.map((c) => (
                          <TableRow key={c._id}>
                            <TableCell className={classes.userImages}>
                              <Grid
                                container
                                spacing={2}
                                wrap='nowrap'
                                alignItems='center'
                              >
                                <Grid item>
                                  <Avatar src={c.avatar} />
                                </Grid>
                                <Grid item>
                                  <Typography className={classes.blue}>
                                    {`${c.firstName} ${c.lastName}`}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell align='left'>
                              <Grid item>
                                <Typography className={classes.blue}>
                                  {renderAge(c)}
                                </Typography>
                              </Grid>
                            </TableCell>
                            <TableCell align='left'>
                              <Grid item>
                                <Typography className={classes.blue}>
                                  {moment(c.createdAt).format('DD-MM-YYYY')}
                                </Typography>
                              </Grid>
                            </TableCell>
                            <TableCell align='right'>
                              <Grid item>
                                <Button
                                  onClick={() => openPatientDetail(c._id)}
                                >
                                  <VisibilityIcon />
                                </Button>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <Grid justify='center' container>
                          <Grid item>No new clients available!</Grid>
                        </Grid>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {!loading && (
                  <Button
                    variant='contained'
                    className={classes.btn2}
                    style={{
                      marginTop: '20px',
                      color: '#FFFFFF',
                      backgroundColor: '#003265',
                    }}
                    onClick={downloadNewClientsData}
                  >
                    Download Report
                  </Button>
                )}
              </TabPanel>
            </Grid>

            <TabPanel value={value} index={1}>
              <TableContainer elevation={0} component={Paper}>
                <Table className={classes.table} aria-label='simple table'>
                  <TableHead style={{ backgroundColor: '#F6F7FB' }}>
                    <TableRow>
                      <TableCell style={{ color: '#C7C7C9' }}>CLIENT</TableCell>
                      <TableCell align='left' style={{ color: '#C7C7C9' }}>
                        AGE GROUP
                      </TableCell>
                      <TableCell align='left' style={{ color: '#C7C7C9' }}>
                        DATE CREATED
                      </TableCell>
                      <TableCell align='left' style={{ color: '#C7C7C9' }}>
                        MISSING
                      </TableCell>
                      <TableCell align='right' style={{ color: '#C7C7C9' }}>
                        ACTIONS
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody className={classes.body}>
                    {loading ? (
                      <Loader />
                    ) : clients && clients.length > 0 ? (
                      clients.map((c) => (
                        <TableRow key={`unverified-${c._id}`}>
                          <TableCell className={classes.userImages}>
                            <Grid
                              container
                              spacing={2}
                              wrap='nowrap'
                              alignItems='center'
                            >
                              <Grid item>
                                <Avatar src={c.avatar} />
                              </Grid>
                              <Grid item>
                                <Typography className={classes.blue}>
                                  {`${c.firstName} ${c.lastName}`}
                                </Typography>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell align='left'>
                            <Grid item>
                              <Typography className={classes.blue}>
                                {renderAge(c)}
                              </Typography>
                            </Grid>
                          </TableCell>
                          <TableCell align='left'>
                            <Grid item>
                              <Typography className={classes.blue}>
                                {moment(c.createdAt).format('DD-MM-YYYY')}
                              </Typography>
                            </Grid>
                          </TableCell>
                          <TableCell align='left'>
                            <Grid item>
                              <Tooltip
                                placement='top'
                                title='Proof Of Identity'
                              >
                                <ActionBtn
                                  color={
                                    c.documents.some(
                                      (d) => d.type === 'proofOfIdentity'
                                    )
                                      ? GREEN
                                      : RED
                                  }
                                  onClick={() => {}}
                                >
                                  <i className='fas fa-id-card'></i>
                                </ActionBtn>
                              </Tooltip>
                              <Tooltip placement='top' title='Patient Info'>
                                <ActionBtn
                                  color={checkBasicInfo(c) ? GREEN : RED}
                                  onClick={() => {}}
                                  className='ml-2'
                                >
                                  <i className='fas fa-user'></i>
                                </ActionBtn>
                              </Tooltip>
                            </Grid>
                          </TableCell>
                          <TableCell align='right'>
                            <Grid item>
                              <Button onClick={() => openPatientDetail(c._id)}>
                                <VisibilityIcon />
                              </Button>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <Grid justify='center' container>
                        <Grid item>No unverified clients available!</Grid>
                      </Grid>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                variant='contained'
                className={classes.btn2}
                style={{
                  marginTop: '20px',
                  color: '#FFFFFF',
                  backgroundColor: '#003265',
                }}
                onClick={downloadUnverifiedClients}
              >
                Download Report
              </Button>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <UnverifiedAccount
                classes={classes}
                loading={loading}
                clients={clients}
                renderAge={renderAge}
                checkBasicInfo={checkBasicInfo}
                openPatientDetail={openPatientDetail}
                downloadUnverifiedClients={downloadUnverifiedClients}
                handleDetailDrawer={handleDetailDrawer}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ClientAppointments
                loading={loading}
                unpaidAppointments={unpaidAppointments}
                downloadUnpaidAppointmentData={downloadUnpaidAppointmentData}
                classes={classes}
                formatUser={formatUser}
                setAppointmentId={setAppointmentId}
                setAppointmentDetails={setAppointmentDetails}
                setDetailDrawer={setDetailDrawer}
                openInvoiceDrawer={openInvoiceDrawer}
                renderSessionTime={renderSessionTime}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <ClientAppointments
                loading={loading}
                unpaidAppointments={unpaidAppointments}
                downloadUnpaidAppointmentData={downloadUnpaidAppointmentData}
                classes={classes}
                formatUser={formatUser}
                setAppointmentId={setAppointmentId}
                setAppointmentDetails={setAppointmentDetails}
                setDetailDrawer={setDetailDrawer}
                openInvoiceDrawer={openInvoiceDrawer}
                renderSessionTime={renderSessionTime}
              />
            </TabPanel>

            <TabPanel value={value} index={5}>
              <TableContainer elevation={0} component={Paper}>
                <Table className={classes.table} aria-label='simple table'>
                  <TableHead style={{ backgroundColor: '#F6F7FB' }}>
                    <TableRow>
                      <TableCell style={{ color: '#C7C7C9' }}>CLIENT</TableCell>
                      <TableCell align='left' style={{ color: '#C7C7C9' }}>
                        AGE GROUP
                      </TableCell>
                      <TableCell align='left' style={{ color: '#C7C7C9' }}>
                        DATE CREATED
                      </TableCell>
                      <TableCell align='left' style={{ color: '#C7C7C9' }}>
                        MISSING
                      </TableCell>
                      <TableCell align='right' style={{ color: '#C7C7C9' }}>
                        ACTION
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody className={classes.body}>
                    {loading ? (
                      <Loader />
                    ) : consentForms && consentForms.length > 0 ? (
                      consentForms.map((c) => (
                        <TableRow>
                          <TableCell
                            align='left'
                            className={classes.userImages}
                          >
                            <Grid
                              container
                              spacing={2}
                              wrap='nowrap'
                              alignItems='center'
                            >
                              <Grid item>
                                <Avatar src={c?.clients[0]?.avatar} />
                              </Grid>
                              <Grid item>
                                <Typography className={classes.blue}>
                                  {`${c?.clients[0]?.firstName} ${c.clients[0]?.lastName}`}
                                </Typography>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell align='left'>
                            <Grid item>
                              <Typography className={classes.blue}>
                                {renderAge(c.clients[0])}
                              </Typography>
                            </Grid>
                          </TableCell>
                          <TableCell align='left'>
                            <Grid item>
                              <Typography className={classes.blue}>
                                {moment(c.appointmentId[0].startTime).format(
                                  'L'
                                )}
                              </Typography>
                            </Grid>
                          </TableCell>
                          <TableCell align='left'>
                            <Grid item>
                              <DescriptionSharpIcon />
                            </Grid>
                          </TableCell>
                          <TableCell align='right'>
                            <Grid item>
                              {(loggedInUser?.role?.role === 'admin' ||
                                loggedInUser?.role?.role === 'frontdesk') &&
                                !alreadySignedByLoggedInUser(c) && (
                                  <ActionBtn
                                    onClick={() =>
                                      openSignatureCanvas(
                                        c.appointmentId[0]._id,
                                        c.clinicianSign?.signDate
                                      )
                                    }
                                  >
                                    <MultilineChartIcon />
                                  </ActionBtn>
                                )}
                              <ActionBtn
                                onClick={() =>
                                  handleDetailDrawer(c.appointmentId[0]._id)
                                }
                              >
                                <VisibilityIcon />
                              </ActionBtn>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <Grid justify='center' container>
                        <Grid item>No unsigned consent forms available!</Grid>
                      </Grid>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Button
                variant='contained'
                className={classes.btn2}
                style={{
                  marginTop: '20px',
                  color: '#FFFFFF',
                  backgroundColor: '#003265',
                }}
                onClick={downloadConsentFormReport}
              >
                Download Report
              </Button>
            </TabPanel>
            {!loading && list && list.length > 0 && (
              <Grid justify='flex-end' style={{ marginTop: '70px' }} container>
                <div className={classes.paginationRoot}>
                  <Pagination
                    count={getPageCount(totalCount)}
                    page={page}
                    onChange={handlePageChange}
                    color='primary'
                    shape='rounded'
                  />
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </>

      <PatientDetailClinician
        togglePatientDrawer={togglePatientDrawer}
        patientDrawer={patientDrawer}
        patientId={patientId}
      />
      <AppointmentDetailsView
        appointmentId={appointmentId}
        handleClose={() => {
          setDetailDrawer(false);
          setAppointmentId('');
          setAppointmentDetails(null);
        }}
        open={detailDrawer}
        handleAppointmentBooking={() => {}}
        handleInitReferralClick={() => {}}
        handleEditRecord={() => {}}
        isAdmin
        hideActions
      />
      <CanvasDialog
        open={openCanvasDialog}
        onClose={closeCanvas}
        ref={signaturePad}
        signature={signature}
        setRef={setRef}
        clear={clearSignature}
        onSignatureUpdate={handleSignatureUpdate}
        modifyUserSignature={modifyUserSignature}
        signConsentForm={signConsentForm}
        usage
      />
    </div>
  );
};

const mapState = (state) => ({
  clients: state.getClients.clients,
  clientCount: state.getClients.count,
  unpaidAppointments: state.getAllAppointmentByBilling.data,
  appointmentCount: state.getAllAppointmentByBilling.count,
  consent: state.getConsentForms,
  signatureSaved: state.saveClinicianSignature.signatureSaved,
  updatedSignature: state.saveClinicianSignature.updatedSignature,
  signedSuccessfully: state.clinicianSign.success,
  loggedInUser: state.loggedInUserInfo.user,
  clientsReportData: state.getClientsReport.clients,
  unpaidAppointmentsReportData: state.billingReportForAllAppointments.data,
});

const mapDispatch = (dispatch) => ({
  getClients: (searchText, appliedFilters, page) =>
    dispatch(getClients(searchText, appliedFilters, page)),
  getClientsReport: (searchText, appliedFilters) =>
    dispatch(getClientsReport(searchText, appliedFilters)),
  getUnpaidAppointments: (page, status, createdAt) =>
    dispatch(getAllAppointmentByBilling(page, status, createdAt)),
  getAllAppointmentByBillingReport: (status, createdAt) =>
    dispatch(getAllAppointmentByBillingReport(status, createdAt)),
  getConsentForms: (clinicianId, filters) =>
    dispatch(getConsentForms(clinicianId, filters)),
  saveClinicianSignature: (signature) =>
    dispatch(saveClinicianSignature(signature)),
  clinicianSignOnConsentForm: (appId) =>
    dispatch(clinicianSignOnConsentForm(appId)),
  loggedInUserInfo: () => dispatch(loggedInUserInfo()),
});
export default connect(mapState, mapDispatch)(Dashboard);
