/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { baseURL } from '../utils/axios';
import Axios from 'axios';
import styles from './CreateNewAppointment.styles';

export default function DiagnosisSearchView(props) {
  const classes = styles();
  const [searchText, setSearchText] = useState('');
  const [diagnosis, setDiagnosis] = useState([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    getDiagnosis();
  }, [searchText]);

  useEffect(() => {
    if (props.selectedDiagnosis && props.selectedDiagnosis.length > 0) {
      setSelectedDiagnosis(props.selectedDiagnosis);
    }
  }, [props.selectedDiagnosis]);

  const getDiagnosis = () => {
    Axios.get(`${baseURL}/api/diagnosis?term=${searchText}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          setDiagnosis(res.data);
        }
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <div>
      <Autocomplete
        multiple
        style={{
          display: 'block',
          width: '100%',
          marginTop: '16px',
          background: '#ffffff',
          borderColor: '#C4C4C4',
        }}
        id='tags-standard'
        options={diagnosis}
        onChange={(event, newValue) => {
          console.log(newValue);
          if (props.onChange) {
            props.onChange(newValue.map((data) => data));
          }
          setSelectedDiagnosis(newValue);
          forceUpdate();
        }}
        getOptionLabel={(option) => option.code + ' ' + option.name}
        getOptionSelected={(option, value) => option._id === value._id}
        // getOptionSelected={ e => {console.log(e)} }
        defaultValue={selectedDiagnosis}
        value={selectedDiagnosis}
        renderInput={(params) => (
          <TextField {...params} onChange={handleChange} variant='standard' />
        )}
      />
    </div>
  );
}
