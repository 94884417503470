import React from "react";

const EmptyContentView = () => (
  <div className="item col-right h-full flex-grow bg-white border border-gray-100">
    {}
    <div className="flex flex-col flex-nowrap h-full justify-center text-center">
      <button
        type="button"
        className="relative inline-block mx-auto border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none"
      >
        <svg
          className="mx-auto h-24 w-24 text-gray-200"
          xmlns="http://www.w3.org/2000/svg"
          stroke="none"
          fill="currentColor"
          viewBox="0 0 20 20"
          aria-hidden="true"
        >
          <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
        </svg>
        <span className="mt-2 block text-sm font-medium text-gray-900">
          Please select an invoice.
        </span>
      </button>
    </div>
  </div>
);

export default EmptyContentView;
