import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, TextField } from '@material-ui/core';
import countries from '../../../../utils/countries_data';
import useStyles, { AutoComplete } from './ClinicAddresses.styles';

const addressObj = {
  title: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  officeNumber: "",
  id: ""
};

const AddressView = (props) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({
    title: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    officeNumber: "",
    id: ""
  });
  const [address, setAddress] = useState({ ...addressObj });
  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    // if (props?.editingAddress?.address1) {
    const countryName = props.editingAddress.country?.name
      ? props.editingAddress.country.name
      : props.editingAddress.country;

    let country = countries.find(
      (itm) => props.editingAddress.country && itm.name === countryName
    );

    if (country) {
      setStateList(country.states);
      setAddress({ ...props.editingAddress, country: country });
    }
    // }
  }, [props.editingAddress]);

  useEffect(() => {
    setErrors({ ...props.errors });
  }, [props.errors]);

  const handleChange = (e) => {
    let addressCopy = { ...address, [e.target.name]: e.target.value };
    setAddress(addressCopy);
    if (e.target.value.trim() !== '') {
      setErrors({ ...errors, [e.target.name]: '' });
    }
    sendAddress(addressCopy);
  };

  const selectCountry = (e, value) => {
    e.persist();
    const country = { ...value };
    delete country.states;
    let addressCopy = { ...address, country: country, state: {} };
    setAddress(addressCopy);
    setStateList(value != null ? value.states : []);
    setErrors({ ...errors, country: '' });
    sendAddress(addressCopy);
  };

  const selectState = (e, value) => {
    e.persist();
    const addressCopy = { ...address, state: value };
    setAddress(addressCopy);
    setErrors({ ...errors, state: '' });
    sendAddress(addressCopy);
  };

  const sendAddress = (addressCopy) => {
    if (props.handleChange) {
      props.handleChange(addressCopy);
    }
  };

  return (
    <Grid className={classes.modalDisplay}>
      {props?.showTitle && (
        <Grid container style={{ marginBottom: '15px' }}>
          <Typography className={classes.inputTitle}>Title*</Typography>
          <input
            className={classes.inputsty}
            value={address.title}
            onChange={handleChange}
            type='text'
            name='title'
          />
          <span className={classes.errorText}>{errors.title}</span>
        </Grid>
      )}

      {props?.id && (
        <Grid container style={{ marginBottom: '15px' }}>
          <Typography className={classes.inputTitle}>ID</Typography>
          <input
            className={classes.inputsty}
            value={address.id}
            onChange={handleChange}
            type='text'
            name='id'
          />
          <span className={classes.errorText}>{errors.title}</span>
        </Grid>
      )}

      {props?.officeNumber && (
        <Grid container style={{ marginBottom: '15px' }}>
          <Typography className={classes.inputTitle}>Office Phone Number</Typography>
          <input
            className={classes.inputsty}
            value={address.officeNumber}
            onChange={handleChange}
            type='text'
            name='officeNumber'
          />
          <span className={classes.errorText}>{errors.title}</span>
        </Grid>
      )}


      <Grid style={{ marginBottom: '15px' }}>
        <Typography className={classes.inputTitle}>
          Current Country of Residence*
        </Typography>
        <AutoComplete
          value={address.country}
          style={{ width: '100%' }}
          options={countries}
          className={classes.autoComCountry}
          name='country'
          onChange={(e, value) => selectCountry(e, value)}
          getOptionSelected={(option, value) => option.value === value.value}
          getOptionLabel={(option) => option.name}
          renderOption={(option) => (
            <React.Fragment>{option.name}</React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder='Choose a country'
              variant='outlined'
              style={{ padding: '0px' }}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
          )}
        />
        <span className={classes.errorText}>{errors.country}</span>
      </Grid>
      <Grid style={{ marginBottom: '15px' }}>
        <Typography className={classes.inputTitle}>Address*</Typography>
        <input
          className={classes.inputsty}
          value={address.address1}
          onChange={handleChange}
          type='text'
          name='address1'
        />
        <span className={classes.errorText}>{errors.address1}</span>
      </Grid>
      <Grid container style={{ marginBottom: '15px' }}>
        <Grid item xs={6} style={{ marginBottom: '15px' }}>
          <Typography className={classes.inputTitle}>
            Address (Optional)
          </Typography>
          <input
            className={classes.inputsty}
            value={address.address2}
            onChange={handleChange}
            type='text'
            name='address2'
          />
          <span className={classes.errorText}>{errors.address2}</span>
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: '10px' }}>
          <Typography className={classes.inputTitle}>Zip</Typography>
          <input
            className={classes.inputsty}
            value={address.zip}
            onChange={handleChange}
            type='text'
            name='zip'
          />
          <span className={classes.errorText}>{errors.zip}</span>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: '15px' }}>
        <Grid item xs={6} style={{ paddingRight: '10px' }}>
          <Typography className={classes.inputTitle}>City*</Typography>
          <input
            className={classes.inputsty}
            value={address.city}
            onChange={handleChange}
            type='text'
            name='city'
          />
          <span className={classes.errorText}>{errors.city}</span>
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: '10px' }}>
          <Typography className={classes.inputTitle}>State*</Typography>
          <AutoComplete
            value={address.state}
            style={{ width: '100%' }}
            options={stateList}
            className={classes.autoComCountry}
            name='country'
            onChange={(e, value) => selectState(e, value)}
            getOptionLabel={(option) => (option ? option.name : '')}
            renderOption={(option) => (
              <React.Fragment>{option.name}</React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder='Choose a State'
                variant='outlined'
                style={{ padding: '0px' }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
          <span className={classes.errorText}>{errors.state}</span>
        </Grid>
      </Grid>
    </Grid>
  );
};

AddressView.propTypes = {
  editingAddress: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    modalDisplay: PropTypes.string.isRequired,
    inputTitle: PropTypes.string.isRequired,
    inputsty: PropTypes.string.isRequired,
    errorText: PropTypes.string.isRequired,
    autoComCountry: PropTypes.string.isRequired,
  }).isRequired,
};

export default AddressView;
