import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import { Label, Error, Required, Option, NativeSelect } from './style';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    fontSize: 16,
    padding: '12px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 0,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const Countries = ({
  label,
  name,
  value,
  options,
  placeholder,
  error,
  onChange,
  isRequired,
  isState,
}) => {
  return (
    <Grid direction='column' alignItems='flex-start' container item>
      <Label>
        {label} {isRequired && <Required>*</Required>}
      </Label>
      <NativeSelect
        name={name}
        value={value}
        onChange={onChange}
        input={<BootstrapInput />}
      >
        {placeholder && (
          <MenuItem value=''>
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {options.map((op, i) => (
          <MenuItem
            key={`lh-${isState ? op.code : op.code2}-${i}`}
            value={isState ? op.code : op.code2}
          >
            {op.name}
          </MenuItem>
        ))}
      </NativeSelect>
      {error && <Error>{error}</Error>}
    </Grid>
  );
};

Countries.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  isRequired: PropTypes.bool,
  isState: PropTypes.bool,
};

Countries.defaultProps = {
  value: '',
};

export default Countries;
