/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable import/prefer-default-export */
import {
  ADD_ROLE_REQUEST,
  GET_ROLE_REQUEST,
  GET_ROLE_BY_ID_REQUEST,
  UPDATE_ROLE_REQUEST,
  DELETE_ROLE_REQUEST,
} from "../AdminConstent";

export const addRoleAction = (data) => ({
  type: ADD_ROLE_REQUEST,
  endpoint: "/admin/role",
  body: data,
});

export const getRoleAction = () => ({
  type: GET_ROLE_REQUEST,
  endpoint: "/admin/role",
});

export const getByIdRoleAction = (id) => ({
  type: GET_ROLE_BY_ID_REQUEST,
  endpoint: `/admin/role/${id}`,
});

export const updateRoleAction = (id, data) => ({
  type: UPDATE_ROLE_REQUEST,
  endpoint: `/admin/role/${id}`,
  body: data,
});

export const deleteRoleAction = (id) => ({
  type: DELETE_ROLE_REQUEST,
  endpoint: `/admin/role/${id}`,
});
