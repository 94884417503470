/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import {  withStyles, Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import Header from "../../common/Header";
import {
  CustomNextButtons,
  CustomCancelButtons,
} from "../../common/CustomButtons";

import { getMhaUserAction } from "../../utils/actions/admin.mha.action";
import { Loader } from "../Loader";
import styles from "./MentalHealthAssessmentStyle";

export class MentalHealthAssessment extends Component {
  componentDidMount() {
    this.props.getMhaUserAction();
  }

  render() {
    const { classes } = this.props;
    console.log("====================================");
    console.log(this.props.getData);
    console.log("====================================");
    return (
      <>
        <Header props={this.props} />
        <div className={classes.root} />
        <Grid container component="div" alignItems="center" justify="center">
          <Grid item component="div" className={classes.container}>
            <Typography className={classes.title}>
              Mental Health Assessment
            </Typography>
            <Grid
              container
              alignItems="center"
              justify="center"
              component="div"
              className={classes.bodyContainer}
            >
              <Grid
                container
                alignItems="center"
                justify="center"
                component="div"
                className={classes.bodySubContainer}
              >
                <Typography className={classes.bodyText}>
                  {!this.props.getDataLoader ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.props.getData.mentalHealthAssessment,
                      }}
                    />
                  ) : (
                    <div>
                      <Loader />
                    </div>
                  )}
                  {/* The Mental Health Assessment can help you determine if you
                  might benefit from seeing a mental health professional for
                  anxiety or depression. The assessment consists of two sets of
                  questions, and should take less than 2 minutes to complete. By
                  taking this screening tool, you acknowledge that it is not a
                  diagnostic instrument, is information only, does not
                  constitute medical or treatment advice, and is only to be used
                  if you are 21 years or older. You are encouraged to share your
                  results with a mental health provider. */}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justify="center"
              style={{ marginTop: "30px", marginBottom: "100px" }}
            >
              <CustomCancelButtons
                width="170px"
                title="Cancel"
                style={classes.cancelButton}
                // textStyles={classes.cancelText}
                onPressButton={() => this.props.history.goBack()}
              />
              <CustomNextButtons
                width="170px"
                title="Next"
                style={classes.nextButton}
                onPressButton={() => this.props.history.push("assessment-que")}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

MentalHealthAssessment.defaultProps = {
  getData: {},
  getDataLoader: true,
};

const connected = connect(
  (state) => ({
    getData: state.getMhaUser.getMhaUserData,
    getDataLoader: state.getMhaUser.request,
  }),
  {
    getMhaUserAction,
  }
)(MentalHealthAssessment);

const formed = reduxForm({
  form: "mha",
})(connected);



export default withStyles(styles)(formed);
