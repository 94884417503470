/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  FormControl,
  Select,
  TextField,
  LinearProgress,
  MenuItem,
  Typography,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import { baseURL } from '../utils/axios';
import { connect } from 'react-redux';
import {
  getClinicianAction,
  getByIdClinicianAction,
} from '../utils/actions/clinician.action';
import AlertMsg from '../utils/Alert';
import { APPOINTMENT_TYPES } from '../utils/enums';
import moment from 'moment';
import Axios from 'axios';
import styles, { AutoComplete } from './CreateNewAppointment.styles';
import Alert from '@material-ui/lab/Alert';
import Drawer from '@material-ui/core/Drawer';
import { IOSSwitch } from '../admin/components/Staff/AddStaffMember';
import PatientSearchView from './PatientSearchView';
import SchedulePicker from './SchedulePicker';
import ClinicianSearchView from './ClinicianSearchView';
import { calculateAge, dateTime, adultAge } from '../utils/timUtils';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const CalenderCreateNewAppointments = (props) => {
  const classes = styles();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [clinicianId, setClinicianId] = useState(null);
  const [clinicians, setClinicians] = useState(null);
  const [cost, setCost] = useState(0);
  const [duration, setDuration] = useState(60);
  const [title, setTitle] = useState('New Appointment');
  const [isWaiting, setIsWaiting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [appointmentDetails, setAppointmentDetails] = useState({});
  const [repeat, setRepeat] = useState(false);
  const [repeatNumber, setRepeatNumber] = useState(1);
  const [send_invoice, setSend_invoice] = useState(false);
  const [repeatInterval, setRepeatInterval] = useState('weekly');
  const [scheduleList, setScheduleList] = useState([]);
  const [selectedTimeIndex, setSelectedTimeIndex] = useState(null);
  const [appointmentLocation, setAppointmentLocation] = useState('select');
  const [clinicAddress, setClinicAddress] = useState('select');
  const [appointmentType, setAppointmentType] = useState('select');
  const [selectTimeSlot, setSelectTimeSlot] = useState(null);
  const [shouldShowRepeat, setShouldShowRepeat] = useState(true);
  const [disablePatientSelection, setDisablePatientSelection] = useState(false);
  const [type, setType] = useState(null);
  const [services, setServices] = useState([]);
  const [hideWaitListOption, setHideWaitListOption] = useState(false);
  const [displayOnlyRecommended, setDisplayOnlyRecommended] = useState(false);
  const [open, setOpen] = useState(false);
  const [proceedWithoutPayment, setProceedWithoutPayment] = useState(false);
  const [selectedCorporation, setSelectedCorporation] = useState(null);
  const [loadingCorporation, setLoadingCorporation] = useState(false);
  const [corporates, setCorporates] = useState([]);
  const [patientType, setPatientType] = useState(
    props.patientType || 'All-Patient'
  );
  useEffect(() => {
    getSchedules();
  }, [clinicianId]);

  useEffect(() => {
    setAppointmentType('');
    setAppointmentLocation('');
    setErrorMessage('');
    setSelectTimeSlot(null);
    setUserId(null);
    setClinicianId(null);
  }, [props.open]);

  useEffect(() => {
    if (!props.clinicians) {
      props.getClinicianAction();
    }
    const loggedInId = localStorage.getItem('clinicianUID');
    props.getByIdClinicianAction(loggedInId);
    getServices();
    if (props.isCorporate) {
      setType('corporate-client-appointment');
      getAllCorporations();
    }
  }, []);

  useEffect(() => {
    if (props.clinicians) {
      setClinicians([...props.clinicians]);
    }
  }, [props.clinicians]);

  useEffect(() => {
    if (isWaiting) {
      setShouldShowRepeat(false);
    }
  }, [isWaiting]);

  useEffect(() => {
    if (proceedWithoutPayment) {
      bookAppointment();
    }
  }, [proceedWithoutPayment]);

  const toggleDrawer = () => {
    if (props.handleClose) {
      props.handleClose();
    }
  };

  useEffect(() => {
    if (props.appointmentDetail) {
      const { type, recommendedClinicians } = props.appointmentDetail;
      console.log(recommendedClinicians)
      setHideWaitListOption(true);
      if (props.appointmentDetail.duration) {
        setDuration(props.appointmentDetail.duration);
      }
      if (props.appointmentDetail.clientId) {
        setUserId(props.appointmentDetail.clientId);
      }
      if (props.appointmentDetail.clinicianId) {
        if (Array.isArray(props.appointmentDetail.clinicianId)) {
          setClinicianId(
            props.appointmentDetail.clinicianId[
            props.appointmentDetail.clinicianId.length - 1
            ]
          );
        } else if (
          props.appointmentDetail.clinicianId ===
          Object(props.appointmentDetail.clinicianId)
        ) {
          setClinicianId(props.appointmentDetail.clinicianId);
        } else {
          setClinicianId({ _id: props.appointmentDetail.clinicianId });
        }
      }
      if (props.appointmentDetail.cost) {
        setCost(props.appointmentDetail.cost);
      }
      if (props.appointmentDetail.appointmentLocation) {
        setAppointmentLocation(props.appointmentDetail.appointmentLocation);
      }

      if (props.appointmentDetail.clinicAddress) {
        setAppointmentLocation(props.appointmentDetail.clinicAddress);
      }
      if (props.appointmentDetail.appointmentType) {
        setAppointmentType(props.appointmentDetail.appointmentType);
      }

      let tempRecommendedClinicians = [];

      if (type) {
        setType(type);
        switch (type) {
          case 'follow-up':
            tempRecommendedClinicians = [
              props.appointmentDetail.clinicianId._id,
            ];
            setDisplayOnlyRecommended(true);
            setTitle('Schedule Follow-up Appointment');

            setDisablePatientSelection(true);
            break;
          case 'waitlist':
            setTitle('Book Appointment');
            setDisablePatientSelection(true);
            const preferredClinician =
              props.appointmentDetail.preferredClinician;
            console.log(preferredClinician, props.appointmentDetail)
            if (recommendedClinicians && recommendedClinicians.length > 0) {
              tempRecommendedClinicians = [
                ...recommendedClinicians,
              ];
              setDisplayOnlyRecommended(false);
            } else if (props.appointmentDetail.clinicianId) {
              tempRecommendedClinicians = [
                preferredClinician,
                props.appointmentDetail.clinicianId instanceof Array ? props.appointmentDetail.clinicianId[0]._id : props.appointmentDetail.clinicianId._id,
              ];
              setDisplayOnlyRecommended(false);
            } else {
              setDisplayOnlyRecommended(false);
            }

            break;
          case 'reschedule':
            setTitle('Reschedule Appointment');
            setShouldShowRepeat(false);
            setDisablePatientSelection(true);
            tempRecommendedClinicians = [
              props.appointmentDetail.clinicianId._id,
            ];
            setDisplayOnlyRecommended(true);

            break;
          case 'init-refferal':
            setTitle('Initiate Refferal Appointment');
            setClinicianId(null);
            setShouldShowRepeat(false);
            setDisplayOnlyRecommended(false);
            setDisablePatientSelection(true);
            break;
        }
      }
      setAppointmentDetails({
        ...props.appointmentDetail,
        recommendedClinicians: tempRecommendedClinicians,
      });
      getSchedules();
    }
  }, [props.appointmentDetail]);

  useEffect(() => {
    getSchedules();
  }, [duration]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDailog = () => setOpen(false);

  const handleFreeAppointment = () => {
    setProceedWithoutPayment(true);
    setOpen(false);
  };

  const getAllCorporations = () => {
    setLoadingCorporation(true);
    Axios.get(`${baseURL}/api/corporations/names`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        setCorporates(res.data);
        setLoadingCorporation(false);
      })
      .catch((err) => {
        setLoadingCorporation(false);
        console.log(err);
      });
  };

  const getServices = () => {
    Axios.get(`${baseURL}/admin/service`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data != undefined) {
          setServices(res.data);
        }
      })
      .catch((e) => {
        setLoading(false);
        if (e.response.data.reason) {
          setErrorMessage(e.response.data.reason);
        }
      });
  };

  const getSchedules = () => {
    if (
      appointmentDetails == undefined ||
      appointmentDetails.calenderSlotId == undefined
    )
      return;
    Axios.get(
      `${baseURL}/api/schedule/${appointmentDetails.calenderSlotId}?duration=${duration}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        if (res.data != undefined) {
          let schedules = res.data.map((sch) => {
            if (sch.slots.length) {
              sch.slots = sch.slots.map((s) => {
                let startTime = moment(new Date(s.startTime));
                let slot = startTime.hours() + ':' + startTime.minutes();
                let timeArr = slot.split(':');
                let hrs = '00',
                  min = '00';
                if (timeArr.length) {
                  hrs = timeArr[0];
                  min = timeArr[1];
                }
                if (timeArr[0].length === 1) {
                  hrs = `0${timeArr[0]}`;
                }
                if (timeArr[1].length === 1) {
                  min = `0${timeArr[1]}`;
                }
                let time = `${hrs}:${min}`;
                let lclDate = new Date(`${sch.date}T${time}:00`);
                let lclHrs = lclDate.getHours().toString();
                if (lclHrs.length === 1) {
                  lclHrs = `0${lclHrs}`;
                }
                return `${lclHrs}:${lclDate.getMinutes() > 0 ? lclDate.getMinutes() : '00'
                  }`;
              });
            }
            return sch;
          })[0];
          if (schedules) {
            setScheduleList(schedules);
          }
        }
      })
      .catch((e) => {
        setLoading(false);
        if (e.response.data.reason) {
          setErrorMessage(e.response.data.reason);
        }
      });
  };

  const handleChange = (event) => {
    setErrorMessage('');
    switch (event.target.name) {
      case 'cost':
        setCost(event.target.value);
        break;
      case 'duration':
        setDuration(event.target.value);
        break;
      case 'repeat':
        setRepeat(event.target.checked);
        break;
      case 'send_invoice':
        setSend_invoice(event.target.checked);
        break;
      case 'repeatNumber':
        setRepeatNumber(event.target.value);
        break;
      case 'repeatInterval':
        setRepeatInterval(event.target.value);
        break;
      case 'patientType':
        setPatientType(event.target.value);
      default:
        break;
    }
  };

  const handleNext = () => {
    bookAppointment();
  };

  const bookAppointment = () => {
    let startTime;
    let endTime;
    let newStart;
    let newEnd;
    let selectedDate;

    if (userId == null) {
      setErrorMessage('select client before moving forward');
      return;
    }

    if (clinicianId == null) {
      setErrorMessage('select clinician before moving forward');
      return;
    }

    if (appointmentLocation == 'select') {
      setErrorMessage('select appointment location moving forward');
      return;
    }
    if (clinicAddress == 'select') {
      setErrorMessage('select clinic address moving forward');
      return;
    }
    if (appointmentType == 'select') {
      setErrorMessage('select service type before moving forward');
      return;
    }

    if (!selectTimeSlot && !isWaiting) {
      setErrorMessage('select time and date before moving forward');
      return;
    }

    if (!isWaiting) {
      selectedDate = selectTimeSlot;

      startTime = moment(selectedDate, 'HH:mm');
      endTime = moment(selectedDate, 'HH:mm').add(duration, 'minutes');
      newStart = startTime.clone();
      newEnd = endTime.clone();

      newStart.set({
        hour: startTime.get('hour'),
        minute: startTime.get('minute'),
      });
      newEnd.set({
        hour: endTime.get('hour'),
        minute: endTime.get('minute'),
      });
    }

    if (!proceedWithoutPayment && !Number(cost)) {
      setOpen(true);
      return;
    }

    let addData = {
      clinicianId: clinicianId._id,
      duration,
      amount: cost,
      bookedBy: localStorage.getItem('clinicianUID'),
      clientId: userId.map((user) => user._id),
      status: !isWaiting ? 'confirmed' : 'waiting',
      repeat: repeat ? repeatNumber : 1,
      sendInvoice: send_invoice,
      repeatInterval,
      creatorEmail: props.creator.email,
      appointmentType,
      appointmentLocation,
      clinicAddress,
    };

    var hasChild = false;

    for (var i = 0; i < userId.length; i++) {
      let user = userId[i];
      let age = calculateAge(new Date(user.dob));
      if (age < adultAge) {
        hasChild = true;
        break;
      }
    }

    if (hasChild) {
      addData.groupType = 'child';
    } else if (userId.length > 1) {
      addData.groupType = 'adult';
    }

    if (!isWaiting) {
      addData.startTime = newStart.utc();
    }

    switch (type ? type : '') {
      case 'reschedule':
        if (appointmentDetails && appointmentDetails._id != undefined) {
          reschedule(appointmentDetails._id, addData);
        }
        break;
      case 'init-refferal':
        addData.status = !isWaiting ? 'reffered' : 'reffered-waiting';
        addData.refferedBy = localStorage.getItem('clinicianUID');
        addAppointment(addData);
        break;
      case 'corporate-client-appointment':
        const bundleAppointment = {
          ...addData,
          clinicianId,
          corporateId: selectedCorporation,
          category: 'corporate',
        };

        addMultiAppointment(bundleAppointment);
        break;
      default: {
        if (appointmentDetails && appointmentDetails._id != undefined) {
          patchAppointment(appointmentDetails._id, {
            ...addData,
            category: props.isCorporate ? 'corporate' : 'regular',
          });
        } else {
          addMultiAppointment({
            ...addData,
            category: props.isCorporate ? 'corporate' : 'regular',
          });
        }
        break;
      }
    }
  };

  const reschedule = (id, data) => {
    setLoading(true);
    Axios.patch(`${baseURL}/api/appointment/reschedule/${id}`, data, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          setLoading(false);
          AlertMsg('success', 'Appointment successfully booked.');
          props.handleSubmit();
        }
      })
      .catch((e) => {
        setLoading(false);
        if (e.response.data.reason) {
          setErrorMessage(e.response.data.reason);
        }
      });
  };

  const addAppointment = (data) => {
    setLoading(true);
    Axios.post(`${baseURL}/api/appointment/add`, data, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          setLoading(false);
          AlertMsg('success', 'Appointment successfully booked.');
          props.handleSubmit();
        }
      })
      .catch((e) => {
        setLoading(false);
        if (e.response.data.reason) {
          AlertMsg('error', e.response.data.reason);
        }
      });
  };

  const patchAppointment = (id, data) => {
    setLoading(true);
    Axios.patch(`${baseURL}/api/appointment/${id}`, data, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          setLoading(false);
          AlertMsg('success', 'Appointment successfully booked.');
          if (props.handleSubmit) {
            props.handleSubmit();
          }
        }
      })
      .catch((e) => {
        setLoading(false);
        if (e.response.data.reason) {
          setErrorMessage(e.response.data.reason);
        }
      });
  };

  const addMultiAppointment = (data) => {
    setLoading(true);
    Axios.post(`${baseURL}/api/appointment/addMulti`, data, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          setLoading(false);
          AlertMsg('success', 'Appointment successfully booked.');
          if (props.handleSubmit) {
            props.handleSubmit();
          }
        }
      })
      .catch((e) => {
        setLoading(false);
        if (e.response.data.reason) {
          setErrorMessage(e.response.data.reason);
        }
      });
  };

  const handleWaitingCheckbox = (waitingState) => {
    if (waitingState) {
      setType('waitlist');
      setIsWaiting(true);
    } else {
      setType('');
      setIsWaiting(false);
    }
  };

  const handleValueChange = (e, value) => setSelectedCorporation(value);

  return (
    <Drawer anchor={'right'} open={props.open} onClose={toggleDrawer}>
      {errorMessage && (
        <div className='flex justify-center'>
          <div style={{ width: 600, marginTop: 30 }}>
            <Alert severity='error'>{errorMessage}</Alert>
          </div>
        </div>
      )}

      <div
        style={{
          marginLeft: '32px',
          marginRight: '32px',
          width: '600px',
        }}
        role='presentation'
      >
        {loading ?? <LinearProgress />}
        <Typography variant='h6' style={{ marginTop: '32px' }}>
          {title}
        </Typography>
        {/* TODO DISCUSS THIS */}
        {/* <Typography style={{ marginTop: '32px' }}>
          {props.appointmentDetail && props.appointmentDetail.type}
        </Typography> */}

        {props.isCorporate && (
          <Grid style={{ marginTop: '32px' }} item xs={12}>
            <Typography>Corporation</Typography>
            <AutoComplete
              options={corporates}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => handleValueChange(event, newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  placeholder='Select Corporation'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingCorporation ? (
                          <CircularProgress color='inherit' size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        )}

        {!props.clinician && !props.hideClinician ? (
          <Grid style={{ marginTop: '32px' }} item xs={12}>
            <Typography>Clinicians Name</Typography>
            <ClinicianSearchView
              recommendedClinicians={appointmentDetails.recommendedClinicians}
              displayOnlyRecommended={displayOnlyRecommended}
              preferredClinician={appointmentDetails.preferredClinician}
              clinicians={clinicians}
              appointmentDetail={{
                type: 'Current-Patient',
                clinicianId: clinicianId,
              }}
              onChange={(data) => {
                setErrorMessage('');
                setClinicianId(data);
                getSchedules();
              }}
            />
          </Grid>
        ) : (
          <> </>
        )}

        {!disablePatientSelection ? <Grid justify='center' spacing={3} className={classes.root}>
          <Grid style={{ marginTop: '32px' }} item xs={12}>
            <Typography>Select Timeblock type</Typography>
            <FormControl className={classes.inputsty}>
              <Select
                value={patientType}
                onChange={(evnt) => handleChange(evnt)}
                name='patientType'
                style={{
                  width: '200px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                }}
              >
                <MenuItem value='New-Patient'>New-Patient</MenuItem>
                <MenuItem value='Current-Patient'>Current-Patient</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid> : <></>}

        <Grid justify='center' spacing={3} className={classes.root}>
          <Grid style={{ marginTop: '32px' }} item xs={12}>
            <Typography>Patients Name</Typography>
            <PatientSearchView
              disabled={disablePatientSelection}
              key={+new Date()}
              appointmentDetail={{
                // type: appointmentDetails
                //   ? appointmentDetails.type
                //   : 'Current-Patient',
                clientId: userId,
              }}
              onChange={(data) => {
                setErrorMessage('');
                setUserId(data);
                getSchedules();
              }}
              patientType={patientType}
              clinicianId={clinicianId?._id}
              selectedCorporation={selectedCorporation}
              isClinician={props.clinician}
              isAdmin={props.isAdmin}
              isCorporate={props.isCorporate}
            />
          </Grid>

          <Grid style={{ marginTop: '32px' }} item xs={12}>
            <Typography>Duration</Typography>
            <FormControl className={classes.inputsty}>
              <Select
                value={duration}
                onChange={(evnt) => handleChange(evnt)}
                name='duration'
                style={{ width: '200px' }}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
              >
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={60}>60</MenuItem>
                <MenuItem value={90}>90</MenuItem>
                <MenuItem value={120}>120</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {false && (
            <Grid style={{ marginTop: '32px' }} item xs={12}>
              <Typography>Available Slots</Typography>
              <Grid
                style={{ display: 'flex', flexWrap: 'wrap', marginTop: '16px' }}
              >
                {scheduleList.slots && scheduleList.slots.length > 0
                  ? scheduleList.slots.map((time, i) => {
                    return (
                      <Grid
                        key={i}
                        container
                        alignItems='center'
                        justify='center'
                        style={
                          i === selectedTimeIndex
                            ? {
                              backgroundColor: '#304659',
                              color: '#ffffff',
                              marginBottom: '7px',
                            }
                            : { marginBottom: '7px' }
                        }
                        className={classes.timeContainer}
                        onClick={() => {
                          setSelectedTimeIndex(i);
                          setSelectTimeSlot(
                            dateTime(scheduleList.date, scheduleList.slots[i])
                          );
                        }}
                      >
                        {time}
                      </Grid>
                    );
                  })
                  : appointmentDetails &&
                  appointmentDetails.calenderSlotId && (
                    <Typography>No Slots Available</Typography>
                  )}
              </Grid>
            </Grid>
          )}

          {!appointmentDetails ||
            (appointmentDetails && !appointmentDetails.calenderSlotId) ? (
            <Grid style={{ marginTop: '32px' }} item xs={12}>
              <SchedulePicker
                clinicianId={clinicianId ? clinicianId._id : ''}
                clientId={
                  userId && userId.lenght > 0
                    ? userId[userId.length - 1]._id
                    : ''
                }
                duration={duration}
                setIsWaiting={handleWaitingCheckbox}
                hideWaitListOption={hideWaitListOption}
                getTime={(date) => {
                  setSelectTimeSlot(date);
                }}
                onClinician={(data) => setClinicianId(data)}
                patientType={patientType}
                showJustSlots
              />
            </Grid>
          ) : (
            <> </>
          )}

          <Grid style={{ marginTop: '32px' }} item xs={12}>
            <Typography>Cost</Typography>
            <TextField
              id='standard-number'
              type='number'
              name='cost'
              style={{
                width: '200px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                paddingLeft: '4px',
              }}
              onChange={(evnt) => handleChange(evnt)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid>
            <Dialog
              open={open}
              onClose={handleCloseDailog}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle id='alert-dialog-title'>
                {"Use Google's location service?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                  Are you sure you want to move forward with 0 price?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleFreeAppointment} color='primary'>
                  Yes
                </Button>
                <Button onClick={handleCloseDailog} color='primary' autoFocus>
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>

          <Grid style={{ marginTop: '32px' }} item xs={12}>
            <FormControl
              variant='outlined'
              className={classes.formControl}
              style={{ width: '100%' }}
            >
              <Typography>Select service type</Typography>
              <Select
                className={`${classes.inputstyle} ${classes.selectEmpty}`}
                defaultValue={appointmentType}
                onChange={(e) => setAppointmentType(e.target.value)}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value='select'>Select</MenuItem>
                {services.map((value) => (
                  <MenuItem key={value._id} value={value._id}>
                    {value.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid style={{ marginTop: '32px' }} item xs={12}>
            <FormControl
              variant='outlined'
              className={classes.formControl}
              style={{ width: '100%' }}
            >
              <Typography>Select appointment location</Typography>
              <Select
                className={`${classes.inputstyle} ${classes.selectEmpty}`}
                defaultValue={appointmentLocation}
                onChange={(e) => setAppointmentLocation(e.target.value)}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value='select'>Select</MenuItem>
                <MenuItem value='online'>Online</MenuItem>
                <MenuItem value='clinic'>Clinic</MenuItem>
                <MenuItem value='school'>School visit</MenuItem>
                <MenuItem value='home'>Home visit</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid style={{ marginTop: '32px' }} item xs={12}>
            <FormControl
              variant='outlined'
              className={classes.formControl}
              style={{ width: '100%' }}
            >
              <Typography>Select Clinic Address</Typography>
              <Select
                className={`${classes.inputstyle} ${classes.selectEmpty}`}
                defaultValue={clinicAddress}
                onChange={(e) => setClinicAddress(e.target.value)}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value='select'>Select</MenuItem>
                {console.log(clinicianId)}
                {clinicianId?.address instanceof Array ? clinicianId?.address?.map((address) => (
                  <MenuItem key={address._id} value={address._id}>
                    {address.title ? address.title : address.address1}
                  </MenuItem>
                )) :
                  clinicianId?.address ? <MenuItem key={clinicianId?.address._id} value={clinicianId?.address._id}>
                    {clinicianId?.address.title ? clinicianId?.address.title : clinicianId?.address.address1}
                  </MenuItem> : <></>
                }
              </Select>
            </FormControl>
          </Grid>

          <Grid style={{ marginTop: '32px' }} item xs={12}>
            <Typography style={{ marginTop: '32px' }}>
              Send Invoice to client
            </Typography>
            <span className={(classes.switches, classes.switchBtn)}>
              <Typography component='label'>Yes</Typography>
              <FormControlLabel
                control={
                  <IOSSwitch
                    name='send_invoice'
                    checked={send_invoice}
                    onChange={(evt) => handleChange(evt)}
                  />
                }
              />
              <Typography component='label'>No</Typography>
            </span>

            {shouldShowRepeat ? (
              <>
                {' '}
                <Typography style={{ marginTop: '32px' }}>
                  Repeat Appointment
                </Typography>
                <span className={(classes.switches, classes.switchBtn)}>
                  <Typography component='label'>Yes</Typography>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        name='repeat'
                        checked={repeat}
                        onChange={(evt) => handleChange(evt)}
                      />
                    }
                  />
                  <Typography component='label'>No</Typography>
                </span>
              </>
            ) : (
              <></>
            )}

            {repeat ? (
              <Grid style={{ marginTop: '32px' }} item xs={12}>
                <Typography className={classes.inputTitle}>
                  Number of Repeat
                </Typography>
                <TextField
                  id='standard-number'
                  label=''
                  type='number'
                  name='repeatNumber'
                  style={{ width: '200px' }}
                  defaultValue={0}
                  onChange={(evnt) => handleChange(evnt)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            ) : (
              <> </>
            )}
            {repeat ? (
              <Grid style={{ marginTop: '32px' }} item xs={12}>
                <Typography className={classes.inputTitle}>
                  Repeat Interval
                </Typography>
                <FormControl className={classes.inputsty}>
                  <Select
                    value={repeatInterval}
                    onChange={(evnt) => handleChange(evnt)}
                    name='repeatInterval'
                    style={{ width: '200px' }}
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                  >
                    <MenuItem value={'weekly'}>Weekly</MenuItem>
                    <MenuItem value={'biWeekly'}>Bi-Weekly</MenuItem>
                    <MenuItem value={'monthly'}>Monthly</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              <> </>
            )}
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: '32px', marginBottom: '64px' }}
          container
          xs={12}
        >
          <Grid style={{ padding: '8px' }} item xs={6}>
            <Button
              style={{ width: '100%' }}
              variant='contained'
              onClick={handleNext}
              disabled={loading}
              color='primary'
            >
              Add
            </Button>
          </Grid>
          <Grid style={{ padding: '8px' }} item xs={6}>
            <Button
              style={{ width: '100%' }}
              onClick={toggleDrawer}
              variant='contained'
              color='default'
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  clinicians: state.getClinician.getClinicianData,
  creator: state.getByIdClinician.getByIdClinicianData,
});

const mapDispatchToProps = (dispatch) => ({
  getClinicianAction: () => dispatch(getClinicianAction()),
  getByIdClinicianAction: (id) => dispatch(getByIdClinicianAction(id)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalenderCreateNewAppointments);
