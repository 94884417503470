import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FilterModalBox from '../../../clinician/FilterModalBox';
import AppointmentDetailsView from '../../../clinician/AppointmentDetailsView';
import Axios from 'axios';
import { baseURL } from '../../../utils/axios';
import FilterImage from '../../../assets/clinician_image/Group 351@2x.png';
import Loader from '../../../components/Loader/Loader';
import PatientDetailClinician from '../../../clinician/Patients/patientDetail';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FilterDrawer from '../../../clinician/FilterDrawer';
import {
  Grid,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  Avatar,
} from '@material-ui/core';
import '../../../clinician/clinician.scss';
import userIcon from '../../../assets/imgs/download.png';
import Layout from '../common/AdminLayout';
import AlertMsg from '../../../utils/Alert';
import clinician1 from '../../../assets/imgs/clinician1.png';
import Pagination from '@material-ui/lab/Pagination';
import NewAppointmentView from '../../../common/NewAppointment';
import CreateNewAppointment from '../../../common/CreateNewAppointment';
import CalenderCreateApointment from '../../../common/CalenderCreateNewAppointments';
import NewPatientsReferral from '../../../clinician/components/NewPatientsReferralModal';
import moment from 'moment';
import useStyles from './Appointment.styles';
import { getPageCount } from '../../../utils/MathUtil';
import { calculateAge } from '../../../utils/timUtils';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { filterAppointments } from '../../../utils/actions/appointment.action';
import { LOCATION, STATUSES, APPOINTMENT_TYPES } from '../../../utils/enums';
import { MultipleProfilesDisplay, Spinner } from '../../../components/atoms';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const SortBtn = styled(Button)`
  padding: 0px;
  text-transform: none;
  width: max-content;
  &:hover {
    background-color: transparent;
  }
`;

const UnWrappedCell = styled(TableCell)`
  white-space: nowrap; ;
`;
const AppointmentClinician = (props) => {
  const classes = useStyles();
  const [detailDrawer, setDetailDrawer] = useState(false);
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [appointmentId, setAppointmentId] = useState('');
  const [appointmentList, setAppointmentList] = useState([]);
  const [filterId, setFilterId] = useState('');
  const [isLoaded, setIsloaded] = useState(true);
  const [patientDrawer, setPatientDrawer] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [newReferralModalOpen, setNewReferralModalOpen] = useState(false);
  const [modalActionType, setModalActionType] = useState('');
  const [patientList, setPatientList] = useState([]);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [clinicianDetails, setClinicianDetails] = useState(null);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [open, setOpen] = useState(false);
  const loading = open && patientList.length === 0;
  const [searchPText, setSearchPText] = useState('');
  const [confirmBtnLoading, setConfirmBtnLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('');
  const [cost, setCost] = useState(200);
  const [duration, setDuration] = useState(30);
  const [isCreateAppointment, setIsCreateAppointment] = useState(false);
  const [sortType, setSortType] = useState('asc');
  const [sortBy, setSortBy] = useState('');
  const [multiFilter, setMultiFilter] = useState(false);
  const [filters, setFilters] = useState({});

  const search = useLocation().search;
  const statusQuery = new URLSearchParams(search).get('status');

  useEffect(() => {
    console.log('statusQuery', statusQuery)
    if (statusQuery) {
      setSelectedFilter(statusQuery)
    }
  }, [statusQuery]);
  
  useEffect(() => {
    getAppointments();
  }, [selectedFilter]);

  useEffect(() => {
    getAppointments();
  }, [filterId, searchText, sortBy, sortType, page]);

  useEffect(() => {
    if (!open) {
      setPatientList([]);
    }
  }, [open]);

  useEffect(() => {
    if (modalActionType == 'patients') {
      getPatientLists();
    } else {
      getAllClinicians();
    }
  }, [searchPText]);

  useEffect(() => {
    if (!props.applyingFilters && generateExcelReport) {
      setLoadSpinner(false);
      setGenerateExcelReport(false);
      excelReport(props.filteredAppointments);
    } else if (!props.applyingFilters) {
      setIsloaded(false);
      setAppointmentList(props.filteredAppointments);
      setTotalCount(props.count);
    }

    if (props.filteringErr) {
      AlertMsg('error', 'Something went wrong');
      setIsloaded(false);
    }
  }, [props.applyingFilters, props.filteredAppointments]);

  const handleSearchAutocomplete = (event) =>
    setSearchPText(event.target.value);

  const excelReport = (apps) => {
    let formattedData = [];
    apps.forEach((a) => {
      formattedData.push({
        'Chart Number': a.clientId[a.clientId.length - 1].chartId,
        'Patient Name': `${a.clientId[a.clientId.length - 1].firstName} ${a.clientId[a.clientId.length - 1].lastName
          }`,
        'Clinician Name': a.clinicianId[0]
          ? `${a.clinicianId[0].firstName} ${a.clinicianId[0].lastName}`
          : '',
        Age: renderAge(a),
        'Date and Time': renderTime(a),
        'Appointment type': a?.appointmentType?.type,
        'Appointment Status': STATUSES[a.status],
        Duration: a.duration,
        Location: LOCATION[a.appointmentLocation],
        'Payment Status': a.billingId[0].status
          ? a.billingId[0].status
          : 'unpaid',
      });
    });

    console.log('formaated', formattedData);
    exportToCSV(formattedData, 'Appointments', 'Appointments');
  };

  const exportToCSV = (data, fileName, sheetName) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blobData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(blobData, fileName + fileExtension);
  };

  const getAppointments = () => {
    if (multiFilter) {
      applyMultipleFilters(filters, sortBy, sortType);
      return;
    }
    setIsloaded(true);
    Axios.get(
      `${baseURL}/api/appointment/getAll?filterId=${filterId}&page=${page}&term=${searchText}&status=${[
        selectedFilter,
      ]}&sortBy=${sortBy}&sortType=${sortType}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        if (res.data) {
          setAppointmentList(res.data.data);
          setTotalCount(res.data.count);
        }
        setIsloaded(false);
      })
      .catch((e) => {
        setIsloaded(false);
      });
  };

  const getPatientLists = (term, filterId) => {
    Axios.get(`${baseURL}/api/client/getAll?term=${searchPText}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        setIsloaded(false);
        console.log(res)
        if (res.data) {
          res.data.data[0].data.forEach((r) => {
            r.name = r.firstName + ' ' + r.lastName;
            delete r.firstName;
          });
          setPatientList(res.data.data[0].data);
        }
      })
      .catch((e) => {

      });
  };

  const handleAppointmentSubmit = () => {
    setIsCreateAppointment(false);
    setMultiFilter(false);
    getAppointments();
  };

  const getAllClinicians = () => {
    Axios.get(`${baseURL}/api/user/clinicians?term=${searchPText}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        let clinicians = res.data.filter((item) => {
          if (item._id !== localStorage.getItem('clinicianUID')) {
            let tmpObj = item;
            tmpObj.id = item._id;
            tmpObj.name = item.firstName + ' ' + item.lastName;
            return tmpObj;
          }
        });
        setPatientList(clinicians);
      })
      .catch((e) => {
        setPatientList([]);
      });
  };

  const getFormatedClinicianData = (uData) => {
    let nextAvailable = null;
    let clinician = uData;
    let schedules = uData.schedule ? uData.schedule : [];
    if (schedules && schedules.length) {
      schedules = schedules.map((sch) => {
        if (sch.slots.length) {
          sch.slots = sch.slots.map((s) => {
            if (s) {
              let timeArr = s.split(':');
              let hrs = '00',
                min = '00';
              if (timeArr.length) {
                hrs = timeArr[0];
                min = timeArr[1];
              }
              if (timeArr[0].length === 1) {
                hrs = `0${timeArr[0]}`;
              }
              if (timeArr[1].length === 1) {
                min = `0${timeArr[1]}`;
              }
              let time = `${hrs}:${min}`;
              let lclDate = new Date(`${sch.date}T${time}:00Z`);
              let lclHrs = lclDate.getHours().toString();
              if (lclHrs.length === 1) {
                lclHrs = `0${lclHrs}`;
              }
              return `${lclHrs}:${lclDate.getMinutes() > 0 ? lclDate.getMinutes() : '00'
                }`;
            } else {
              return s;
            }
          });
        }
        return sch;
      });
      for (let sch of schedules) {
        if (sch.slots.length) {
          nextAvailable = sch;
          break;
        }
      }
    }
    const data = {
      id: clinician._id,
      name: `Dr. ${clinician.firstName} ${clinician.lastName}`,
      study: '',
      speciality: 'Specialist Psychiatrist',
      times: schedules,
      img: clinician.avatar ? clinician.avatar : clinician1,
      cost: null,
      appointmenSlot: clinician.appointmentSlotUnit,
      amount: clinician.costPerUnit,
      selectTimeSlot: null,
      isAlternative: false,
      nextAvailable,
      nextAvailiblityText: getNextAvailibility(schedules),
    };
    if (clinician._id !== localStorage.getItem('clinicianUID')) {
      data.cost = `${clinician.costPerUnit} Dhs / ${clinician.appointmentSlotUnit}min`;
    }
    return data;
  };

  const getDate = (date) => {
    var today = new Date(date);
    var d = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var m = today.getMonth() < 10 ? '0' + today.getMonth() : today.getMonth();
    var modifiedDate = d + '/' + m + '/' + today.getFullYear();
    return modifiedDate;
  };

  const getNextAvailibility = (schedules) => {
    let result = '';
    if (schedules.length) {
      let day = new Date();
      day.setDate(day.getDate() + 1);
      let nextDay = getDate(day);
      if (schedules[0].date == nextDay) {
        result = 'Tomorrow';
      } else if (new Date(schedules[0].date) == day) {
        result = 'Today';
      } else {
        result = schedules[0].date;
      }
    } else {
      result = 'Not Available';
    }
    return result;
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setFilterDrawer(open);
  };

  const handleCircle = (status) => {
    var color = 'orange';
    if (status == 'paid') {
      color = 'green';
    } else if (status == 'unpaid') {
      color = 'red';
    }
    return (
      <div
        style={{
          width: '15px',
          height: '15px',
          backgroundColor: color,
          borderRadius: '50%',
        }}
      ></div>
    );
  };

  const handleDetailDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDetailDrawer(open);
    // setState({ ...state, ["ri"]: open });
  };

  const filterAppointment = (id) => {
    setSortBy('');
    setSortType('desc');
    setFilterId(id);
  };

  const handleSubmitRequest = (params) => {
    let axiosConfig = {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    };
    Axios.post(`${baseURL}/api/filter`, params, axiosConfig)
      .then((res) => {
        setFilterDrawer(false);
        setFilterId(res.data._id);
      })
      .catch((e) => { });
  };

  const togglePatientDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setPatientDrawer(open);
    // setState({ ...state, ["ri"]: open });
  };

  const openPatientDrawer = (id) => {
    setPatientDrawer(true);
    setPatientId(id);
  };

  const handleAlternativeCalendar = () => {
    setClinicianDetails({
      ...clinicianDetails,
      isAlternative: !clinicianDetails.isAlternative,
    });
  };

  const handleTimeSelect = (value) => {
    setClinicianDetails({
      ...clinicianDetails,
      status: 'selected',
      selectTimeSlot: value.target.value,
      isAlternative: false,
    });
  };

  const formatDateYMD = (x, y) => {
    var z = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds(),
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
      return ((v.length > 1 ? '0' : '') + eval('z.' + v.slice(-1))).slice(-2);
    });

    return y.replace(/(y+)/g, function (v) {
      return x.getFullYear().toString().slice(-v.length);
    });
  };

  const handleCalendarTimeSelect = (times, value, date) => {
    let newDate = formatDateYMD(date, 'yyyy-MM-dd');
    setClinicianDetails({
      ...clinicianDetails,
      status: 'selected',
      selectTimeSlot: new Date(`${newDate} ${value}`),
      isAlternative: false,
    });
  };

  const handleNewORreferralClick = (pData) => {
    setNewReferralModalOpen(false);
    if (pData) {
      if (modalActionType == 'patients') {
        setModalActionType('clinitians');
        setNewReferralModalOpen(true);
        getAllClinicians();
      } else {
        setOpenScheduleModal(true);
        setClinicianDetails(pData);
      }
      // setOpenScheduleModal(true);
      // setClinicianDetails(pData);
    }
  };

  const handleCloseSchedule = () => {
    setOpenScheduleModal(false);
  };

  const handleCancelAppointment = (index) => {
    let tmpClinicianDetails = { ...clinicianDetails };
    tmpClinicianDetails = {
      ...tmpClinicianDetails,
      selectTimeSlot: null,
    };
    setClinicianDetails({ ...tmpClinicianDetails });
  };

  const handleAppointmentConfirm = () => {
    setConfirmBtnLoading(true);
    let data;

    if (!appointmentDetails) {
      AlertMsg('Error', 'Something went wrong!');
      return false;
    }

    let clientIds = appointmentDetails.clientId.map((item) => {
      return item._id;
    });

    data = {
      clinicianId: clinicianDetails.id,
      startTime: clinicianDetails.selectTimeSlot,
      duration: duration != 0 ? duration : clinicianDetails.appointmenSlot,
      amount: cost != 0 ? cost : clinicianDetails.amount,
      bookedBy: localStorage.getItem('clinicianUID'),
      clientId: clientIds,
      status: 'confirmed',
    };

    Axios.patch(`${baseURL}/api/appointment/${appointmentDetails._id}`, data, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          setClinicianDetails(null);
          setOpenScheduleModal(false);
          setNewReferralModalOpen(false);
          setModalActionType('');
          setConfirmBtnLoading(false);
          AlertMsg('success', 'Appointment successfully booked.');
          getAppointments();
        }
      })
      .catch((e) => {
        setConfirmBtnLoading(false);
      });
  };

  const handleInitReferralClick = () => {
    getAllClinicians();
    setModalActionType('init_referral');
    setNewReferralModalOpen(true);
  };

  const handleEditRecord = () => {
    props.history.push(
      `/admin/appointments/editPatientRecord/${appointmentDetails._id}`
    );
  };

  const handleAppointmentBooking = (data) => {
    handleBook(data);
  };

  const handleBook = (data) => {
    setAppointmentDetails({
      ...data,
      preferredClinician:
        data.preferredClinician?.length > 0 && data.preferredClinician[0],
    });
    setIsCreateAppointment(true);
  };

  const handleCheckIn = (id) => {
    let data = {
      checkedIn: true,
    };

    Axios.patch(`${baseURL}/api/appointment/checkin/${id}`, data, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res) {
          const { status, checkedIn } = res.data;
          const newList = appointmentList.map((item) => {
            if (item._id === id) {
              return {
                ...item,
                status,
                checkedIn,
              };
            } else {
              return item;
            }
          });
          setAppointmentList(newList);
          if (status === 'checked-in') {
            AlertMsg('success', 'Succesfully checked in Appointment');
          } else {
            AlertMsg('success', 'Succesfully reverted checked in status');
          }
        }
      })
      .catch((e) => {
        AlertMsg('error', 'Something went wrong!');
      });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  /*
    handle change function can be removed as it is causing multiple
    calls to same endpoint as getAppointments func
  */
  const handleChange = (event, value) => {
    setSearchText('');
    setFilterId('');
    let val = value.props.value;
    setIsloaded(true);
    setMultiFilter(false);
    setSelectedFilter(val);
    setPage(1);

    const status = [val];

    Axios.get(
      `${baseURL}/api/appointment/getAll?&page=${page}&term=${searchText}&status=${status}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        if (res.data) {
          setAppointmentList(res.data.data);
          setTotalCount(res.data.count);
        }
        setIsloaded(false);
      })
      .catch((e) => {
        setIsloaded(false);
      });
  };

  const setSearchTextEvent = (text) => {
    setSearchPText(text);
  };

  const handleRefresh = () => {
    getAppointments();
    setDetailDrawer(false);
    setAppointmentId(appointmentId);
  };

  const sort = (key) => {
    const toggleType = sortType === 'asc' ? 'desc' : 'asc';
    setSortType(toggleType);
    setSortBy(key);
  };

  const applyMultipleFilters = (
    appliedFilters,
    sortBy,
    sortType,
    newFilter = false
  ) => {
    const pageNum = newFilter ? 1 : page;

    setMultiFilter(true);
    setFilterDrawer(false);
    setFilters(appliedFilters);
    setIsloaded(true);
    setPage(pageNum);
    const stringifyFilters = JSON.stringify(appliedFilters);
    props.filterAppointments(stringifyFilters, pageNum, sortBy, sortType, 0);
  };

  const [generateExcelReport, setGenerateExcelReport] = useState(false);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const handleReportDownload = (params) => {
    setFilterDrawer(false);
    setLoadSpinner(true);
    setGenerateExcelReport(true);
    const stringifyFilters = JSON.stringify(params);
    props.filterAppointments(stringifyFilters, 1, '', '', 1);
  };
  const produceCurrStatus = (status) => {
    let statusText = 'Check in';

    if (
      status === 'waiting' ||
      status === 'reffered-waiting' ||
      status === 'reffered' ||
      status === 'unconfirmed'
    ) {
      statusText = 'Booking';
    } else if (status === 'checked-in') {
      statusText = 'Start session';
    } else if (status === 'seen') {
      statusText = 'In Session';
    } else if (status === 'ended' || status === 'completed') {
      statusText = 'Completed';
    } else if (status === 'cancelled') {
      statusText = 'Cancelled';
    }
    return statusText;
  };

  const updateToCheckIn = (row) => {
    if (['confirmed', 'checked-in'].includes(row.status)) {
      handleCheckIn(row._id);
    } else if (
      row.status === 'waiting' ||
      row.status === 'reffered-waiting' ||
      row.status === 'reffered' ||
      row.status === 'unconfirmed'
    ) {
      handleBook({ ...row, type: 'waitlist' });
    }
  };

  const renderAge = (record) => {
    if (record.clientId[record.clientId.length - 1]) {
      return calculateAge(
        new Date(record.clientId[record.clientId.length - 1].dob)
      );
    }
  };

  const renderTime = (record) => {
    if (
      record.status === 'waiting' ||
      record.status === 'reffered' ||
      record.status === 'reffered-waiting' ||
      !record.startTime
    ) {
      return moment(new Date(record.createdAt)).format('LL');
    } else {
      return moment(new Date(record.startTime)).format('LLL');
    }
  };

  const showActionBtn = (record) => {
    return (
      (record.status === 'waiting' ||
        record.status === 'confirmed' ||
        record.status === 'reffered' ||
        record.status === 'checked-in' ||
        record.status === 'reffered-waiting') &&
      selectedFilter !== 'archived'
    );
  };
  return (
    <>
      {/* <Layout
      filterId={filterId}
      filterAppointment={filterAppointment}
      appointments={appointmentList}
    > */}
      {loadSpinner && <Spinner isOpen />}
      <Grid container>
        <Grid xs={6}>
          <OutlinedInput
            className={classes.inputstyle}
            style={{ float: 'left' }}
            placeholder='Search'
            onChange={(evt) => {
              setSearchText(evt.target.value);
            }}
            id='outlined-adornment-password'
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  onClick={(evt) => setPage(1)}
                  aria-label='toggle password visibility'
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          {/* <FormControl
            variant='outlined'
            className={classes.formControl}
            style={{
              width: '200px',
            }}
          >
            <Select
              className={`${classes.inputstyle} ${classes.selectEmpty}`}
              value={selectedFilter}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value='upcoming'>Upcoming</MenuItem>
              <MenuItem value='cancelled'>Cancelled</MenuItem>
              <MenuItem value='unconfirmed'>Unconfirmed</MenuItem>
              <MenuItem value='archived'>Archived</MenuItem>
              <MenuItem value='waiting'>Waiting List</MenuItem>
              <MenuItem value='missed'>No Show</MenuItem>
              <MenuItem value='ended'>Completed Without Note</MenuItem>
              <MenuItem value='reffered'>Referred</MenuItem>
            </Select>
          </FormControl> */}
        </Grid>
        <Grid xs={6}>
          <div style={{ float: 'right' }}>
            <Button
              variant='contained'
              onClick={(evt) => {
                setAppointmentDetails(null);
                setIsCreateAppointment(true);
              }}
              className={classes.btnNewApp}
              style={{ height: '49px' }}
            >
              <AddCircleOutlineIcon style={{ marginRight: 5 }} />
              New Appointment
            </Button>
            <Button
              variant='contained'
              style={{
                height: '49px',
                overflow: 'hidden',
                maxWidth: '47px',
                minWidth: 'unset',
                marginLeft: '12px',
              }}
              onClick={() => setFilterDrawer(true)}
              className={classes.btnNewApp}
            >
              {/* <HorizontalSplitIcon /> */}
              <img src={FilterImage} width='50' />
            </Button>
          </div>
        </Grid>
      </Grid>

      <Grid container>
        <TableContainer>
          <Table className={classes.table} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <UnWrappedCell>
                  <SortBtn onClick={() => sort('chartId')}>
                    Chart Number
                  </SortBtn>
                </UnWrappedCell>

                <UnWrappedCell>
                  <SortBtn onClick={() => sort('name')}>Patient Name</SortBtn>
                </UnWrappedCell>
                <UnWrappedCell>
                  <SortBtn onClick={() => sort('doc_name')}>
                    Clinician Name
                  </SortBtn>
                </UnWrappedCell>
                <UnWrappedCell>
                  <SortBtn onClick={() => sort('dob')}>Age</SortBtn>
                </UnWrappedCell>
                <UnWrappedCell>
                  <SortBtn onClick={() => sort('date')}>
                    {selectedFilter === 'waiting'
                      ? ' Request Date'
                      : 'Date and time'}
                  </SortBtn>
                </UnWrappedCell>
                {selectedFilter === 'waiting' && (
                  <UnWrappedCell>
                    <SortBtn onClick={() => sort('risk')}>Risk</SortBtn>
                  </UnWrappedCell>
                )}
                {selectedFilter !== 'waiting' && (
                  <UnWrappedCell>Appointment type</UnWrappedCell>
                )}
                {selectedFilter !== 'waiting' && (
                  <UnWrappedCell>Appointment Status</UnWrappedCell>
                )}
                {selectedFilter !== 'waiting' && (
                  <UnWrappedCell>
                    <SortBtn onClick={() => sort('duration')}>Duration</SortBtn>
                  </UnWrappedCell>
                )}
                {selectedFilter !== 'waiting' && (
                  <UnWrappedCell>
                    <SortBtn onClick={() => sort('location')}>Location</SortBtn>
                  </UnWrappedCell>
                )}

                {selectedFilter !== 'waiting' && (
                  <UnWrappedCell>Payment Status</UnWrappedCell>
                )}

                <UnWrappedCell>Action (view)</UnWrappedCell>
              </TableRow>
            </TableHead>
            <TableBody component={Paper}>
              {isLoaded ? (
                <TableRow>
                  <TableCell colSpan={12} style={{ textAlign: 'center' }}>
                    <Loader />
                  </TableCell>
                </TableRow>
              ) : appointmentList && appointmentList.length > 0 ? (
                appointmentList.map((row, i) => {
                  let zIndexCnt =
                    row.clientId && row.clientId.length
                      ? row.clientId.length
                      : 0;
                  return (
                    <TableRow key={i}>
                      <TableCell component='th' scope='row'>
                        {row.clientId.length > 0
                          ? row.clientId[0].chartId
                          : row.clientId.chartId}
                      </TableCell>
                      <TableCell className={classes.userImages}>
                        {row.clientId[0] && (
                          <MultipleProfilesDisplay profiles={row.clientId} />
                        )}
                      </TableCell>
                      <TableCell>
                        <Grid
                          container
                          spacing={2}
                          wrap='nowrap'
                          alignItems='center'
                        >
                          <Grid item>
                            {row.clinicianId && row.clinicianId[0] && (
                              <Avatar
                                src={
                                  row.clinicianId[0].avatar
                                    ? row.clinicianId[0].avatar
                                    : userIcon
                                }
                              />
                            )}
                          </Grid>
                          <Grid item className='whitespace-nowrap'>
                            {row.clinicianId &&
                              row.clinicianId.length > 0 &&
                              `${row.clinicianId[0].prefix
                                ? row.clinicianId[0].prefix
                                : ''
                              } ${row.clinicianId[0].firstName} ${row.clinicianId[0].lastName
                              } ${row.clinicianId[0].suffix
                                ? ', ' + row.clinicianId[0].suffix
                                : ''
                              }`}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <UnWrappedCell>{renderAge(row)}</UnWrappedCell>
                      <TableCell>{renderTime(row)}</TableCell>
                      {selectedFilter === 'waiting' && (
                        <TableCell>{row.riskScore}</TableCell>
                      )}

                      {selectedFilter !== 'waiting' && (
                        <TableCell>{row?.appointmentType[0]?.type}</TableCell>
                      )}
                      {selectedFilter !== 'waiting' && (
                        <TableCell
                          style={
                            STATUSES[row.status] == 'Missed' ||
                              STATUSES[row.status] == 'Cancelled'
                              ? { color: 'red' }
                              : {}
                          }
                        >
                          {STATUSES[row.status]}
                        </TableCell>
                      )}
                      {selectedFilter !== 'waiting' && (
                        <TableCell>{row.duration ? row.duration : 0}</TableCell>
                      )}
                      {selectedFilter !== 'waiting' && (
                        <TableCell>
                          {row.appointmentLocation
                            ? LOCATION[row.appointmentLocation]
                            : ''}
                        </TableCell>
                      )}
                      {selectedFilter !== 'waiting' && (
                        <TableCell>
                          {handleCircle(
                            row.billingId &&
                              row.billingId.length &&
                              row.billingId[0].status
                              ? row.billingId[0].status
                              : 'unpaid'
                          )}
                        </TableCell>
                      )}

                      <TableCell>
                        {showActionBtn(row) && (
                          <Button
                            variant='contained'
                            className={
                              row.status === 'checked-in'
                                ? classes.checkedInBtn
                                : row.status == 'seen'
                                  ? classes.seenBtn
                                  : classes.viewBtn
                            }
                            onClick={() => updateToCheckIn(row)}
                          >
                            {produceCurrStatus(row.status)}
                          </Button>
                        )}
                      </TableCell>

                      <TableCell align='right'>
                        <Button
                          variant='contained'
                          className={classes.viewBtn}
                          onClick={() => {
                            setDetailDrawer(true);
                            setAppointmentId(row._id);
                            setAppointmentDetails(row);
                          }}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={12}>
                    <Typography style={{ textAlign: 'center' }}>
                      No Data Found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!isLoaded && appointmentList && appointmentList.length > 0 && (
          <Grid justify='flex-end' container>
            <div className={classes.paginationRoot}>
              <Pagination
                count={getPageCount(totalCount)}
                page={page}
                onChange={handlePageChange}
                color='primary'
                shape='rounded'
              />
            </div>
          </Grid>
        )}
      </Grid>
      <CalenderCreateApointment
        open={isCreateAppointment}
        appointmentDetail={appointmentDetails}
        key={new Date().getTime()}
        handleClose={() => {
          setIsCreateAppointment(false);
        }}
        handleSubmit={() => {
          getAppointments();
          setIsCreateAppointment(false);
          setDetailDrawer(false);
        }}
        isAdmin
      />
      {modalActionType && (
        <NewPatientsReferral
          actionType={modalActionType}
          open={newReferralModalOpen}
          handleClose={(evt) => {
            setModalActionType('');
            setNewReferralModalOpen(false);
            setPatientList([]);
            setClinicianDetails(null);
          }}
          handleSubmit={handleNewORreferralClick}
          userList={patientList}
          loading={loading}
          openAutoComp={open}
          setOpenAutoComp={setOpen}
          handleSearchAutocomplete={handleSearchAutocomplete}
        />
      )}
      <FilterDrawer
        toggleDrawer={toggleDrawer}
        filterDrawer={filterDrawer}
        handleSubmitRequest={handleSubmitRequest}
        applyMultipleFilters={applyMultipleFilters}
        handleReportDownload={handleReportDownload}
      />

      <AppointmentDetailsView
        appointmentId={appointmentId}
        handleClose={() => {
          setDetailDrawer(false);
          setAppointmentId('');
          setAppointmentDetails(null);
        }}
        open={detailDrawer}
        handleAppointmentBooking={handleAppointmentBooking}
        handleInitReferralClick={() => { }}
        handleEditRecord={() => { }}
        refresh={() => {
          getAppointments();
        }}
        isAdmin
      />
      <PatientDetailClinician
        togglePatientDrawer={togglePatientDrawer}
        patientDrawer={patientDrawer}
        patientId={patientId}
        props={{ isAdmin: true }}
      />
      <NewAppointmentView
        clinicianDetails={clinicianDetails}
        open={openScheduleModal}
        handleClose={handleCloseSchedule}
        handleCalendarTimeSelect={handleCalendarTimeSelect}
        handleCancelAppointment={handleCancelAppointment}
        handleAppointmentConfirm={handleAppointmentConfirm}
        handleTimeSelect={handleTimeSelect}
        handleAlternativeCalendar={handleAlternativeCalendar}
        confirmBtnLoading={confirmBtnLoading}
        setCost={setCost}
        setDuration={setDuration}
      />
      {/* </Layout> */}
    </>
  );
};

AppointmentClinician.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  filterAppointments: PropTypes.object.isRequired,
  filteredAppointments: PropTypes.array.isRequired,
  applyingFilters: PropTypes.bool.isRequired,
  filteringErr: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
};

const mapPropsToState = (state) => ({
  filteredAppointments: state.filterAppointments.appointments,
  applyingFilters: state.filterAppointments.applyingFilters,
  filteringErr: state.filterAppointments.error,
  count: state.filterAppointments.count,
});

export default connect(mapPropsToState, { filterAppointments })(
  AppointmentClinician
);
