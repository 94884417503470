import React, { useState, useEffect } from 'react';
import { Drawer, Grid, MenuItem } from '@material-ui/core';
import {
  AutoComplete,
  Select,
  TextField,
  CalenderField,
  Textarea,
} from '../../../../../components/atoms';
import {
  BUNDLE_STATUSES,
  APPOINTMENT_DURATION,
} from '../../../../../utils/enums';
import {
  Container,
  Heading,
  Row,
  BottomBtn,
  Label,
  Required,
  Error,
} from '../style';
import { baseURL } from '../../../../../utils/axios';
import axios from 'axios';

const FORMATTED_DURATION = Object.keys(APPOINTMENT_DURATION).map((d) => ({
  key: d,
  value: APPOINTMENT_DURATION[d],
}));

const setBgcolor = (status) => {
  let color = 'transparent';
  if (status === 'completed') {
    color = '#00bc07';
  } else if (status === 'refunded') {
    color = '#db1919';
  }
  return color;
};

const disableOnViewAndEdit = (mode) => mode === 'view' || mode === 'edit';

const ServiceBundleForm = ({
  onChange,
  onDropdownChange,
  filterClients,
  clientList,
  filterClinicians,
  clinicianList,
  mode,
  bundle,
  error,
}) => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    getServices();
  }, []);

  const getServices = () => {
    axios
      .get(`${baseURL}/admin/service`, {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      })
      .then((res) => {
        if (res.data) {
          const data = res.data.map((d) => ({
            key: d._id,
            value: d.type,
          }));

          setServices(data);
        }
      })
      .catch((e) => {});
  };

  const onServiceChange = (e) => {
    const serviceId = services.find((d) => d.key === e.target.value).key;
    onChange({
      ...e,
      target: { ...e.target, name: e.target.name, value: serviceId },
    });
  };
  return (
    <Grid>
      <Row direction='column' alignItems='flex-start' container>
        <Label>
          Select Subscriber <Required>*</Required>
        </Label>
        <AutoComplete
          onChange={(e) => onDropdownChange(e, 'clientId')}
          renderingFunction={filterClients}
          options={clientList}
          placeholder='Select a Subscriber'
          name='clientId'
          loading={false}
          value={bundle.clientId}
          disabled={disableOnViewAndEdit(mode)}
        />
        <Error>{error && error.clientId}</Error>
      </Row>

      <Row direction='column' alignItems='flex-start' container>
        <Label>
          Select Clinician <Required>*</Required>
        </Label>
        <AutoComplete
          onChange={(e) => onDropdownChange(e, 'clinicianId')}
          renderingFunction={filterClinicians}
          options={clinicianList}
          placeholder='Select a Clinician'
          name='clinicianId'
          loading={false}
          value={bundle.clinicianId}
          disabled={disableOnViewAndEdit(mode)}
        />
        <Error>{error && error.clinicianId}</Error>
      </Row>
      <Row direction='column' alignItems='flex-start' container>
        <Label>
          Appointment Type <Required>*</Required>
        </Label>
        <Select
          options={services}
          name='appointmentType'
          placeholder='Select an appointment type'
          onChange={onServiceChange}
          value={bundle.appointmentType}
          disabled={disableOnViewAndEdit(mode)}
        />
        <Error>{error && error.appointmentType}</Error>
      </Row>
      <Row direction='column' alignItems='flex-start' container>
        <Label>
          Number of Sessions <Required>*</Required>
        </Label>
        <TextField
          value={bundle.numberOfSessions}
          onChange={onChange}
          name='numberOfSessions'
          error=''
          type='number'
          disabled={disableOnViewAndEdit(mode)}
        />
        <Error>{error && error.numberOfSessions}</Error>
      </Row>
      <Row direction='column' alignItems='flex-start' container>
        <Label>
          Cost Per Session (AED) <Required>*</Required>
        </Label>
        <TextField
          value={bundle.amount}
          onChange={onChange}
          name='amount'
          error=''
          type='number'
          disabled={disableOnViewAndEdit(mode)}
        />
        <Error>{error && error.amount}</Error>
      </Row>
      <Row direction='column' alignItems='flex-start' container>
        <Label>
          Duration <Required>*</Required>
        </Label>
        <Select
          options={FORMATTED_DURATION}
          name='duration'
          placeholder='Select Duration'
          onChange={onChange}
          value={bundle.duration}
          disabled={disableOnViewAndEdit(mode)}
        />
        <Error>{error && error.duration}</Error>
      </Row>
      <Row direction='column' alignItems='flex-start' container>
        <Label>
          Expiration Date <Required>*</Required>
        </Label>
        <CalenderField
          value={bundle.expirationDate}
          onChange={onChange}
          name='expirationDate'
          minDate={new Date()}
          error=''
          disabled={mode === 'view' && mode !== 'edit'}
        />
        <Error>{error && error.expirationDate}</Error>
      </Row>
      <Row direction='column' alignItems='flex-start' container>
        <Label>
          Discount (%) <Required>*</Required>
        </Label>
        <TextField
          value={bundle.discount}
          onChange={onChange}
          name='discount'
          error=''
          type='number'
          disabled={disableOnViewAndEdit(mode)}
        />
        <Error>{error && error.discount}</Error>
      </Row>

      {mode === 'view' && (
        <Row direction='column' alignItems='flex-start' container>
          <Label>Completion Status</Label>
          <TextField
            value={BUNDLE_STATUSES[bundle.status]}
            onChange={onChange}
            name='status'
            bgcolor={setBgcolor(bundle.status)}
            color={setBgcolor(bundle.status) === '#db1919' ? '#fff' : '#000'}
            error=''
            disabled={true}
          />
        </Row>
      )}
      {mode === 'view' && bundle.status === 'refunded' && (
        <Grid>
          <Row direction='column' alignItems='flex-start' container>
            <Label>Number of Used Sessions</Label>
            <TextField
              value={bundle.numberOfUsedSessions}
              onChange={onChange}
              name='numberOfUsedSessions'
              error=''
              type='number'
              disabled={true}
            />
          </Row>

          <Row direction='column' alignItems='flex-start' container>
            <Label>
              Reason for cancelling <Required>*</Required>
            </Label>
            <Textarea
              value={bundle.reasonForCancelling}
              onChange={onChange}
              name='reasonForCancelling'
              error=''
              disabled={true}
            />
          </Row>
          <Row direction='column' alignItems='flex-start' container>
            <Label>User Who Cancelled Subscription</Label>
            <AutoComplete
              onChange={(e) => onDropdownChange(e, 'cancellingUser')}
              renderingFunction={() => {}}
              options={[]}
              loading={false}
              value={bundle.cancellingUser}
              disabled={true}
            />
          </Row>
        </Grid>
      )}
    </Grid>
  );
};

ServiceBundleForm.defaultProps = {
  disableInputs: false,
};
export default ServiceBundleForm;
