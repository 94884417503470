import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Button } from '../../atoms';
import FilterImage from '../../../assets/clinician_image/Group 351@2x.png';
import { FilterBtn, AddCircleOutlineIcon } from './style';

const CreateBtnWithFilter = ({
  onClick,
  onClick2,
  onFilterClick,
  createBtnText,
  createBtnText2,
  showFilters,
}) => {
  return (
    <Grid justify='flex-end' container>
      <Grid container={!!createBtnText2} item wrap='nowrap'>
        {createBtnText2 && (
          <Button
            icon={<AddCircleOutlineIcon />}
            text={createBtnText2}
            onClick={onClick2}
            style={{ marginRight: 20 }}
          />
        )}

        <Button
          icon={<AddCircleOutlineIcon />}
          text={createBtnText}
          onClick={onClick}
        />
      </Grid>
      {showFilters && (
        <Grid item>
          <FilterBtn variant='contained' onClick={onFilterClick}>
            <img src={FilterImage} width='50' />
          </FilterBtn>
        </Grid>
      )}
    </Grid>
  );
};

CreateBtnWithFilter.propTypes = {
  onClick: PropTypes.func.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  createBtnText: PropTypes.string.isRequired,
};

export default CreateBtnWithFilter;
