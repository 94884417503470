import {makeStyles} from '@material-ui/core/styles';


export default makeStyles((theme) => ({
    root: {
      "&:nth-of-type(even)": {
        backgroundColor: "#ffffff",
      },
      "&:nth-of-type(odd)": {
        backgroundColor: "rgba(48,70,89,5%)",
      },
      // width: "1025px",
      height: "70px",
      // cursor: 'pointer',
    },
    container: {
      display: "flex",
    },
  }));