/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from "react";
import { createStyles, withStyles, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { DeleteForeverOutlined } from "@material-ui/icons";
import Sidebar from "../common/Header";
import { CustomizedTables, StyledTableCell } from "../common/Table";
import {
  getUserAction,
  deleteUserAction,
  addUserAction,
  // getByIdUserAction,
  // updateUserAction,
} from "../../../utils/actions/admin.user.action";
import AddPopup from "../common/AddPopup";
// import UpdatePopup from '../common/UpdatePopup';
import { getRoleAction } from "../../../utils/actions/role.action";
import { getServiceAction } from "../../../utils/actions/service.action";
import styles from "./User.styles";
const clinicianObject = [
  {
    email: "chandreshpatidar5@gmail.com",
    firstName: "Chandresh",
    lastName: "Patidar",
    avatar: "",
    phoneNumber: "8085433880",
    phoneType: "Mobile",
    dob: new Date(),
    gender: "male",
    uaeResident: true,
    address: {
      address1: "abcd",
      address2: "",
      city: "demo city",
      state: "Dubai",
    },
    clinicianSegment: "Psychiatry",
    service: "Psychiatric Assessment",
    appointmentSlotUnit: "30 min",
    costPerUnit: "100",
  },
  {
    email: "chandreshpatidar5@gmail.com",
    firstName: "Chandresh",
    lastName: "Patidar",
    avatar: "",
    phoneNumber: "8085433880",
    phoneType: "Mobile",
    dob: new Date(),
    gender: "male",
    uaeResident: true,
    address: {
      address1: "abcd",
      address2: "",
      city: "demo city",
      state: "Dubai",
    },
    clinicianSegment: "Psychiatry",
    service: "Psychiatric Assessment",
    appointmentSlotUnit: "30 min",
    costPerUnit: "100",
  },
];

class User extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAdd: false,
      isUpdate: false,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      role: "",
      services: [],
    };
  }

  componentDidMount() {
    this.props.getUserAction();
    this.props.getRoleAction();
    this.props.getServiceAction();
  }

  componentDidUpdate(preProps) {
    if (this.props.deleteUser !== preProps.deleteUser) {
      if (this.props.deleteUser === "") {
        getUserAction();
      }
    }
    if (this.props.addUserData !== preProps.addUserData) {
      if (this.props.addUserData.addUserData) {
        this.props.getUserAction();
      }
    }
  }

  handleChangeService = (e) => {
    const services = [...this.state.services];
    services.push(e.target.value);
    this.setState({ services });
  };

  handleSubmit = () => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      role,
      services,
    } = this.state;
    const data = {
      firstName,
      lastName,
      email,
      phoneNumber,
      role,
      services,
    };
    this.props.addUserAction(data);
    this.setState({
      isAdd: false,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      role: "",
      services: [],
    });
  };

  render() {
    const { classes } = this.props;
  

    const headers = ["Sr. No", "Name", "Email", "Speciality", "Action"];
    const rows = this.props.getAllUser.map((val, i) => (
      <>
        <StyledTableCell align="left">{i + 1}</StyledTableCell>
        <StyledTableCell align="left">{`${val.firstName} ${val.lastName}`}</StyledTableCell>
        <StyledTableCell align="left">{val.email}</StyledTableCell>
        <StyledTableCell align="left">{val.specialty}</StyledTableCell>
        <StyledTableCell align="left">
          {/* <EditOutlined style={{ cursor: 'pointer' }}  /> */}
          <DeleteForeverOutlined
            style={{ cursor: "pointer" }}
            onClick={() => this.props.deleteUserAction(val._id)}
          />
        </StyledTableCell>
      </>
    ));
    const inputField = [
      { lable: "First Name", value: "firstName", fieldType: "input" },
      { lable: "Last Name", value: "lastName", fieldType: "input" },
      { lable: "Email", value: "email", fieldType: "input" },
      { lable: "Phone", value: "phoneNumber", fieldType: "input" },
      {
        lable: "Role",
        value: "role",
        fieldType: "select",
        selectData: this.props.getAllRole.map((val) => ({
          _id: val._id,
          title: val.role,
        })),
      },
      {
        lable: "Service",
        value: "services",
        fieldType: "select",
        selectData: this.props.getAllService.map((val) => ({
          _id: val._id,
          title: val.description,
        })),
      },
      {
        lable: "Appointment Slot Unit",
        value: "services",
        fieldType: "select",
        selectData: [
          { _id: "30 min", title: "30 Minutes" },
          { _id: "60 min", title: "60 Minutes" },
          { _id: "custom", title: "Custom" },
        ],
      },
      { lable: "Cost per unit", value: "costPerUnit", fieldType: "input" },
    ];
    return (
      <>
        <Sidebar />
        <div className={classes.root}>
          {this.state.isAdd ? (
            <AddPopup
              headerTitle="Add User"
              isOpen={this.state.isAdd}
              inputField={inputField}
              onChangeInput={(e) =>
                this.setState({ [e.target.name]: e.target.value })
              }
              onAdd={this.handleSubmit}
              onCancel={() => this.setState({ isAdd: false })}
            />
          ) : (
            <Button
              className={classes.addButton}
              onClick={() => this.setState({ isAdd: true })}
            >
              Add User
            </Button>
          )}
          <CustomizedTables
            headers={headers}
            rows={rows}
            loaded={this.props.loadData.request}
          />
        </div>
      </>
    );
  }
}

User.defaultProps = {
  getAllUser: [],
  getAllService: [],
  getAllRole: [],
};

const connected = connect(
  (state) => ({
    getAllUser: state.getUser.getUserData,
    loadData: state.getUser,
    deleteUser: state.deleteUser.deleteUserData,
    getAllRole: state.getRole.getRoleData,
    getAllService: state.getService.getServiceData,
    addUserData: state.addUser,
  }),
  {
    getUserAction,
    deleteUserAction,
    getRoleAction,
    getServiceAction,
    addUserAction,
  }
)(User);

const formed = reduxForm({
  form: "getAllUser",
})(connected);


export default withStyles(styles)(formed);
