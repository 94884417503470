import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styled from 'styled-components';

export default makeStyles({
  toolbar: {
    width: '100%',
    height: '100px',
    backgroundColor: '#003265',
  },
  root: {
    maxWidth: 255,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  inputstyle: {
    backgroundColor: '#fff',
  },
  table: {
    minWidth: 650,
  },
  imgTable: {
    width: '40px',
    height: '40px',
    objectFit: 'contain',
    borderRadius: '50px',
  },
  viewBtn: {
    borderRadius: '15px',
    width: '100px',
    padding: '4px',
    boxShadow: 'none',
    textTransform: 'capitalize',
  },
  btnNewApp: {
    backgroundColor: '#003265',
    color: '#fff',
    marginLeft: '50px',
    '&:hover': {
      backgroundColor: '#003265',
    },
  },
  inputTitle: {
    textAlign: 'left',
    fontSize: '13px',
    color: 'grey',
    fontWeight: '500',
    marginBottom: '4px',
  },
  inputsty: {
    border: '1px solid #e5e5e5',
    borderRadius: '0',
    height: '45px',
    width: '100%',
  },
  modalDisplay: {
    backgroundColor: 'white',
    padding: '8px',
    borderRadius: '4px',
    width: '100%',
  },
  errorText: {
    color: 'red',
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: 16,
  },
  paginationRoot: {
    marginTop: 15,
    float: 'right',
    '& > * + *': {
      marginTop: '32px',
    },
  },
});

const CreateBtn = styled(Button)`
  background-color: #003265;
`;
const CancelBtn = styled(Button)`
  margin-right: 15px;
`;

const AutoComplete = styled(Autocomplete)`
  & .MuiInputBase-input {
    height: 0px;
  }
`;

export { CreateBtn, CancelBtn, AutoComplete };
