import * as firebase from "firebase";
import "firebase/storage";
import "firebase/database";

export const firebaseConfiguration = {
  apiKey: "AIzaSyDDl0M__yxFYnecERvQI2L4aY7osEfAjoo",
  authDomain: "lighhousearabia.firebaseapp.com",
  databaseURL: "https://lighhousearabia.firebaseio.com",
  projectId: "lighhousearabia",
  storageBucket: "lighhousearabia.appspot.com",
  messagingSenderId: "607318184016",
  appId: "1:607318184016:web:171c2f3ab9d1253f1d4155",
  measurementId: "G-0HG7ZY9JJT"
};
firebase.initializeApp(firebaseConfiguration);

export const storage = firebase.storage();

export const googleSignIn = () =>
  new Promise((resolve, reject) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("profile");
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(resolve)
      // .then((result) => {
      //   // This gives you a Google Access Token. You can use it to access the Google API.
      //   const token = result.credential.accessToken;
      //   // The signed-in user info.
      //   const { user } = result;
      //   // ...
      //   return resolve;
      // })
      .catch(reject);
  });

export const appleSignIn = () =>
  new Promise((resolve, reject) => {
    const provider = new firebase.auth.OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    firebase
      .auth()
      .signInWithPopup(provider)
      // .then((result) => {
      //   // The signed-in user info.
      //   const { user } = result;
      //   // You can also get the Apple OAuth Access and ID Tokens.
      //   const { accessToken } = result.credential;
      //   const { idToken } = result.credential;

      //   // ...
      // })
      .then(resolve)
      .catch(reject);
  });

export const googleSignOut = async () =>
  new Promise((resolve, reject) => {
    firebase.auth().signOut().then(resolve).catch(reject);
  });

export default firebase;
