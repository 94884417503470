/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import {
  ADD_CLIENT_REQUEST,
  GET_CLIENT_REQUEST,
  UPDATE_CLIENT_REQUEST,
  DELETE_CLIENT_REQUEST,
  GET_BY_ID_CLIENT_REQUEST,
  ADD_CLIENT_NOTE_REQUEST,
  CLIENT_MAIL_REQUEST,
  UPDATE_CLIENT_STATUS_REQUEST,
  ADD_CHILD_REQ,
  ADD_ADULT_REQ,
  GET_CHILD_REQ,
  GET_CHILD_BY_ID_REQ,
  UPDATE_CHILD_REQ,
  UPLOAD_CLIENT_IMAGE_REQUEST,
  GET_EMPLOYER_REQUEST,
  UPDATE_PHARMACY_REQUEST,
  GET_PHARMACY_REQUEST,
  SET_EMPLOYER_ID_REQUEST,
  UPLOAD_DOC_FILE_REQUEST,
  GET_CLIENT_DATA_REQUEST,
  GET_CLIENTS_REQUEST,
  SAVE_CLIENT_SIGNATURE_REQUEST,
  GET_BY_ID_CLINICIAN_REQUEST,
  GET_BUNDLE_APPOINTMENT_REQUEST,
  CLIENT_CONSENT_FORMS_REQUEST,
  GET_CLIENTS_REPORT_REQUEST,
} from '../constant';

export const addClientAction = (data) => ({
  type: ADD_CLIENT_REQUEST,
  endpoint: '/api/client/new',
  body: data,
});

export const getClientAction = (getDocuments) => ({
  type: GET_CLIENT_REQUEST,
  endpoint: `/api/client?documents=${getDocuments}`,
});

export const getByIdClientAction = (id, token) => ({
  type: GET_BY_ID_CLIENT_REQUEST,
  endpoint: '/api/client',
});

export const updateClientAction = (id, data) => ({
  type: UPDATE_CLIENT_REQUEST,
  // endpoint: `/api/client`,
  endpoint: '/api/client',
  body: data,
});

export const updateClientStatusAction = (id, data) => ({
  type: UPDATE_CLIENT_STATUS_REQUEST,
  // endpoint: `/api/client`,
  endpoint: '/api/client/',
  body: data,
});

export const deleteClientAction = (id) => ({
  type: DELETE_CLIENT_REQUEST,
  endpoint: `/api/client/${id}`,
});

export const addClientNoteAction = (id, data) => ({
  type: ADD_CLIENT_NOTE_REQUEST,
  endpoint: `/api/client/note/${id}`,
  body: data,
});

export const sendMailAction = (data) => ({
  type: CLIENT_MAIL_REQUEST,
  endpoint: '/api/client/new_client',
  body: data,
});

export const addChildAction = (data) => ({
  type: ADD_CHILD_REQ,
  endpoint: '/api/child',
  body: data,
});

export const addAdultAction = (data) => ({
  type: ADD_ADULT_REQ,
  endpoint: '/api/client/add_adult',
  body: data,
});

export const getChildAction = () => ({
  type: GET_CHILD_REQ,
  endpoint: '/api/relationship/' + localStorage.getItem('clientId'),
});

export const getChildByIdAction = (id) => ({
  type: GET_CHILD_BY_ID_REQ,
  endpoint: `/api/child/${id}`,
});

export const updateChildAction = (id, data) => ({
  type: UPDATE_CHILD_REQ,
  endpoint: `/api/child/${id}`,
  body: data,
});

export const uploadClientImageAction = (data, type) => ({
  type: UPLOAD_CLIENT_IMAGE_REQUEST,
  endpoint: `/api/client/upload?type=${type}`,
  body: data,
});

export const getEmployerAction = () => ({
  type: GET_EMPLOYER_REQUEST,
  endpoint: '/api/employer',
});

export const uploadPharmacyAction = (data) => ({
  type: UPDATE_PHARMACY_REQUEST,
  endpoint: '/api/client/pharmacy',
  body: data,
});

export const getPharmacyAction = () => ({
  type: GET_PHARMACY_REQUEST,
  endpoint: '/api/client/pharmacy',
});

export const getEmployerIdAction = (id) => ({
  type: SET_EMPLOYER_ID_REQUEST,
  endpoint: `/api/employer/${id}`,
});

export const uploadDocFileAction = (data, type) => ({
  type: UPLOAD_DOC_FILE_REQUEST,
  endpoint: `/api/client/upload?type=${type}`,
  body: data,
});

export const getClientDetailAction = (id) => ({
  type: GET_CLIENT_DATA_REQUEST,
  endpoint: `/api/client/getClientDetail?id=${id}`,
});

export const getClients = (searchText = '', appliedFilters = '', page = 1) => ({
  type: GET_CLIENTS_REQUEST,
  endpoint: `/api/client/getAll?term=${searchText}&appliedFilters=${appliedFilters}&page=${page}`,
});

export const getClientsReport = (searchText = '', appliedFilters = '') => ({
  type: GET_CLIENTS_REPORT_REQUEST,
  endpoint: `/api/client/getAll?term=${searchText}&appliedFilters=${appliedFilters}`,
});

export const saveUserSignature = (img) => ({
  type: SAVE_CLIENT_SIGNATURE_REQUEST,
  endpoint: '/api/client/signature',
  body: img,
});

export const getByIdClinician = (id) => ({
  type: GET_BY_ID_CLINICIAN_REQUEST,
  endpoint: `/api/client/clinician/${id}`,
});

export const getInactiveAppointmentsOfClientBundle = (page) => ({
  type: GET_BUNDLE_APPOINTMENT_REQUEST,
  endpoint: `/api/client/inactive-appointment-of-bundles?page=${page}`,
});

export const getClientConsentForms = (clientId) => ({
  type: CLIENT_CONSENT_FORMS_REQUEST,
  endpoint: `/api/client/consent-forms/${clientId}`,
});
