/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from 'react';
import { withStyles, Typography, Grid, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import validator from 'validator';
import DatePicker, { registerLocale } from 'react-datepicker';
// import en from 'date-fns/locale/en';
import { addClientAction } from '../../../utils/actions/client.action';
import { userSignup, userLogin } from '../../../utils/actions/auth.action';
import AlertMsg from '../../../utils/Alert';
import 'react-datepicker/dist/react-datepicker.css';
import line_2 from '../../../assets/user_images/Line 2.svg';
import BackgroundImage from './BackgroundImage';
import SocialAuthFirebase from './GoogleOptions';
import { ButtonLoader } from '../../Loader';
import Axios from 'axios';
import { baseURL } from '../../../utils/axios';
import { getAgeByDob } from '../../../utils/validators';
import moment from 'moment';
import { ErrorSharp } from '@material-ui/icons';
import styles from './SignUpFormStyle';
import { adultAge } from '../../../utils/timUtils';
import VerficationModal from './OtpPopUp';

// registerLocale('en', en);

class SignUp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openVerficationModals: '',
      id: '',
      fname: '',
      lname: '',
      email: '',
      dob: null,
      password: '',
      cpassword: '',
      errors: {
        fname: '',
        lname: '',
        email: '',
        dob: null,
        password: '',
        cpassword: '',
      },
      nationality: '',
      phoneNumber: '',
      clientType: '',
      gender: '',
      state: '',
      residency: '',
      emName: '',
      emPhone: '',
      emRelation: '',
      UAEResident: true,
      age: '',
      fee: '',
      callerInformation: '',
      cliniciansPreference: '',
      referredBy: '',
      validPhotoIdentification: '',
      insurance: '',
      maritalStatus: '',
      emEmail: '',
      informedConsent: true,
      ReleaseOfInformation: true,
      RequestForPhysical: false,
      riskactor: false,
      address: '',
      isChecked: false,
      isMount: false,
    };
  }

  async componentDidMount() {
    this.setState({ isMount: true });
    localStorage.removeItem('userDetails');
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get('id');
    if (id) {
      let res = await Axios.get(
        `${baseURL}/api/client/getClientDetail?id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      ).catch((e) => {
        return null;
      });
      if (res && res.data) {
        console.log('res.data', res.data);
        if (res.data.verify) {
          AlertMsg(
            'error',
            'This email id alraedy verified! You can login using this email id.'
          );
          this.props.history.push('/login');
        } else {
          const { firstName, lastName, email, _id, dob } = res.data;
          this.setState({
            fname: firstName,
            lname: lastName,
            email: email,
            id: _id,
            dob: dob ? new Date(dob) : null,
          });
        }
      } else {
        AlertMsg('error', 'Invalid request!');
        this.props.history.push('/signup');
      }
    }
  }

  componentDidUpdate(preProps) {
    if (this.props.addClient !== preProps.addClient) {
      if (this.props.addClientData) {
        if (!this.state.id && !this.props.addClientData.verify) {
          localStorage.setItem(
            'userDetails',
            JSON.stringify({
              email: this.state.email,
              password: this.state.password,
            })
          );
          this.setState({ openVerficationModals: true });
          // this.props.history.push("/verification/" + this.props.addClientData._id);
        }
      }
    }
  }

  componentWillUnmount() {
    this.setState({ isMount: false });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    const { name, value } = e.target;
    const { errors } = this.state;
    switch (name) {
      case 'fname':
        errors.name = value.length > 0 ? null : 'Field is required!';
        break;
      case 'lname':
        errors.name = value.length > 0 ? null : 'Field is required!';
        break;

      case 'email':
        errors.email = value.length > 0 ? null : 'Email is required!';
        break;

      case 'dob':
        errors.dob = value.length > 0 ? null : 'Date of Birth is required!';
        break;

      case 'password':
        errors.password = value.length > 0 ? null : 'Password is required!';
        break;

      case 'cpassword':
        errors.cpassword = value.length > 0 ? null : 'Password is required!';
        break;

      default:
        break;
    }
  };

  handleSortChange = (name) => (selectedSortOption) => {
    this.setState({ [name]: selectedSortOption.value, selectedSortOption });
  };

  handleVerfy = () => {
    const fname = validator.isEmpty(this.state.fname, {
      ignore_whitespace: true,
    });
    const lname = validator.isEmpty(this.state.lname, {
      ignore_whitespace: true,
    });
    const email = validator.isEmpty(this.state.email, {
      ignore_whitespace: true,
    });
    const pass = validator.isEmpty(this.state.password, {
      ignore_whitespace: true,
    });
    const cpass = validator.isEmpty(this.state.cpassword, {
      ignore_whitespace: true,
    });
    const matchPass = validator.equals(
      this.state.password,
      this.state.cpassword
    );
    const errors = { ...this.state.errors };
    if (fname || lname || email || pass || cpass || this.state.dob === null) {
      errors.lname =
        this.state.lname.length > 0 ? null : 'Please enter your last name';
      errors.fname =
        this.state.fname.length > 0 ? null : 'Please enter your first name';
      errors.email =
        this.state.email.length > 0 ? null : 'Please enter your email address';
      errors.dob =
        this.state.dob === null
          ? 'Please select your date of birth'
          : getAgeByDob(dob) < adultAge
          ? `Date of birth must be more then ${adultAge} years!`
          : null;
      errors.password = this.state.password
        ? null
        : 'Please enter your password';
      errors.cpassword = !this.state.cpassword
        ? 'Please enter your confirm password'
        : this.state.password !== this.state.cpassword
        ? 'Password and confirm password must be same!'
        : null;
      this.setState({ errors });
      return false;
    }
    if (!matchPass) {
      AlertMsg('Password must be same');
      return false;
    }
    return true;
  };

  handleSubmit = () => {
    const { fname, lname, email, password, dob, id } = this.state;
    const isVerify = this.handleVerfy();
    if (isVerify) {
      const data = {
        props: this.props,
        firstName: fname,
        lastName: lname,
        email,
        dob,
        password,
      };
      if (id) {
        data.id = id;
      }
      this.props.userSignup(data);
    } else {
      return null;
    }
  };

  render() {
    const { classes } = this.props;
    const { lname, fname, email, password, cpassword } = this.state;

    return (
      <>
        <Grid container justify='center' alignItems='center'>
          <Grid style={{ display: 'flex', flexDirection: 'row' }}>
            <BackgroundImage />
            <Grid
              container
              justify='center'
              alignItems='center'
              className={classes.responsiveForm}
            >
              <Grid item>
                <Typography className={classes.spacious}>Sign-up</Typography>

                <Grid component='div' className={classes.avatar}>
                  <Typography className={classes.create}>
                    Create your account or
                  </Typography>
                  <Link to='/login'>
                    <Typography className={classes.signsub}>
                      Sign-in here.
                    </Typography>
                  </Link>
                </Grid>

                <SocialAuthFirebase title='up' props={this.props} />

                <Grid className={classes.divider}>
                  <img src={line_2} className={classes.dividerImage} />
                  <Typography className={classes.divider_text}>OR</Typography>
                  <img src={line_2} className={classes.dividerImage} />
                </Grid>

                {/* <Grid component="div"> */}
                {/* <LoginForm /> */}
                <Grid
                  style={{ display: 'inline' }}
                  container
                  justify='center'
                  alignItems='center'
                >
                  <Typography className={classes.title}>First Name</Typography>
                  <input
                    name='fname'
                    value={fname}
                    disableUnderline
                    fullWidth
                    onChange={this.handleChange}
                    className={classes.inputcontainer}
                  />
                  {this.state.errors.name && (
                    <Typography className={classes.alertFont}>
                      {this.state.errors.name}
                    </Typography>
                  )}
                </Grid>

                <Grid
                  style={{ display: 'inline' }}
                  container
                  justify='center'
                  alignItems='center'
                >
                  <Typography className={classes.title}>Last Name</Typography>
                  <input
                    name='lname'
                    value={lname}
                    disableUnderline
                    fullWidth
                    onChange={this.handleChange}
                    className={classes.inputcontainer}
                  />
                  {this.state.errors.name && (
                    <Typography className={classes.alertFont}>
                      {this.state.errors.name}
                    </Typography>
                  )}
                </Grid>

                <Grid
                  style={{ display: 'inline' }}
                  container
                  justify='center'
                  alignItems='center'
                >
                  <Typography className={classes.title}>Email</Typography>
                  <input
                    name='email'
                    value={email}
                    disableUnderline
                    fullWidth
                    type='email'
                    onChange={this.handleChange}
                    className={
                      // classes.inputtext,
                      classes.inputcontainer
                    }
                  />
                  {this.state.errors.email && (
                    <Typography className={classes.alertFont}>
                      {this.state.errors.email}
                    </Typography>
                  )}
                </Grid>

                <Grid
                  style={{ display: 'inline' }}
                  container
                  justify='center'
                  alignItems='center'
                >
                  <Typography className={classes.title}>
                    Date Of Birth
                  </Typography>
                  <DatePicker
                    locale='en'
                    maxDate={moment().subtract(adultAge, 'years').toDate()}
                    selected={this.state.dob}
                    autoComplete='off'
                    placeholder='MM/DD/YYYY'
                    onChange={(date) => {
                      const errors = { ...this.state.errors };
                      errors.dob = null;
                      this.setState({ errors, dob: date });
                    }}
                    className={classes.inputcontainer}
                    showYearDropdown
                    dropdownMode='select'
                    showMonthDropdown
                    onBlur={() => {
                      const errors = { ...this.state.errors };
                      errors.dob =
                        this.state.dob === null ? 'Please Enter DOB!' : null;
                      this.setState({ errors });
                    }}
                  />
                  {this.state.errors.dob && (
                    <Typography className={classes.alertFont}>
                      {this.state.errors.dob}
                    </Typography>
                  )}
                </Grid>

                <Grid
                  style={{ display: 'inline' }}
                  container
                  justify='center'
                  alignItems='center'
                >
                  <Typography className={classes.title}>Password</Typography>
                  <input
                    name='password'
                    type='password'
                    value={password}
                    autoComplete='off'
                    disableUnderline
                    fullWidth
                    onChange={this.handleChange}
                    className={classes.inputcontainer}
                  />
                  {this.state.errors.password && (
                    <Typography className={classes.alertFont}>
                      {this.state.errors.password}
                    </Typography>
                  )}
                </Grid>

                <Grid
                  style={{ display: 'inline' }}
                  container
                  justify='center'
                  alignItems='center'
                >
                  <Typography className={classes.title}>
                    Confirm Passwod
                  </Typography>
                  <input
                    name='cpassword'
                    type='password'
                    autoComplete='off'
                    value={cpassword}
                    disableUnderline
                    fullWidth
                    onChange={this.handleChange}
                    className={classes.inputcontainer}
                  />
                  {this.state.errors.cpassword && (
                    <Typography className={classes.alertFont}>
                      {this.state.errors.cpassword}
                    </Typography>
                  )}
                </Grid>

                <Grid component='div'>
                  <label className={classes.checkboxTextContainer}>
                    <input
                      type='checkbox'
                      // checked={this.state.isChecked}
                      className={classes.checkboxsty}
                    />
                    <span className={classes.checkedsty}>
                      I agree to LightHouse Arabia{' '}
                      <b style={{ textDecoration: 'underline' }}>
                        Conditions of Use
                      </b>{' '}
                      and{' '}
                      <b style={{ textDecoration: 'underline' }}>
                        {' '}
                        Privacy Notice
                      </b>
                    </span>
                  </label>
                </Grid>
                {this.props.addClient ? (
                  <Grid
                    container
                    alignItems='center'
                    justify='center'
                    className={classes.submit}
                  >
                    <ButtonLoader />
                  </Grid>
                ) : (
                  <Button
                    type='submit'
                    variant='contained'
                    className={classes.submit}
                    onClick={this.handleSubmit}
                  >
                    Create account
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <VerficationModal
          open={this.state.openVerficationModals}
          onClose={() => this.setState({ openVerficationModals: false })}
          signingEmail={this.state.email}
          {...this.props}
          verifyOTP
        />
      </>
    );
  }
}

const connected = connect(
  (state) => ({
    addClient: state.userSignup.request,
    addClientData: state.userSignup.userSignupData,
    loginClient: state.userLogin.request,
  }),
  { addClientAction, userSignup, userLogin }
)(SignUp);
const formed = reduxForm({
  form: 'clinicalSignup',
})(connected);

export default withStyles(styles)(formed);
