import {makeStyles} from '@material-ui/core/styles';


export default makeStyles({
    nextButton: {
      // width: '158px',
      height: "39px",
      borderRadius: "20px",
      backgroundColor: "#304659",
      "&:hover": { backgroundColor: "#304659" },
      justifyContent: "center",
      cursor: "pointer",
    },
    adultbutton: {
      // width: '158px',
      height: "39px",
      borderRadius: "20px",
      opacity: '100%',
      backgroundColor: "rgb(255, 171, 11)",
      "&:hover": { backgroundColor: "#ffab0b" },
      justifyContent: "center",
      alignItems: 'center',
      cursor: "pointer",
    },
    cancelbutton: {
      height: "39px",
      borderRadius: "20px",
      // opacity: '20%',
      backgroundColor: "rgb(48,70,89, 20%)",
      "&:hover": { backgroundColor: "rgb(48,70,89, 20%)" },
      justifyContent: "center",
      alignItems: 'center',
      cursor: "pointer",
    },
    buttonText: {
      fontSize: "17px",
      color: "#ffffff",
      lineHeight: "40px",
      fontFamily: "Lato",
      fontWeight: 700,
      textAlign: "center",
      marginTop: "-2px",
    },
    cancelButtonText: {
      fontSize: '18px',
      color: "#304659",
      fontWeight: 700,
      fontFamily: "Lato",
      textAlign: 'center',
      // position: "relative",
      // left: "-105px",
      // zIndex: 1,
      marginTop: "4px",
      cursor: "pointer",
      justifyContent: 'center'
    },
    adulttext: {
      fontSize: '19px',
      color: '#222222',
      fontWeight: 700,
      fontFamily: 'Lato',
      textAlign: 'center',
      marginTop: "3.5px",
    }
  });