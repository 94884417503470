import { createStyles} from "@material-ui/core";
import { nameFont, descFont } from "../../../assets/fontSize";

export default (theme) =>
createStyles({
  doctorProfileContainer: {
    marginBottom: "25px",
    backgroundColor: "#ffffff",
    display: "flex",
    padding:'32px'
  },
  doctorProfileSubContainer: {
    // padding: '45px',
    [theme.breakpoints.up("xs")]: {
      width: "250px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "417px",
    },
    [theme.breakpoints.up("md")]: {
      width: "717px",
    },
  },
  nameContainer: {
    [theme.breakpoints.up("xs")]: {
      // float: 'left',
      whiteSpace: "nowrap",
      // width: '200px',
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    [theme.breakpoints.up("sm")]: {
      // display: 'flex',
      // width: '500px',
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      // width: '600px',
    },
  },
  doctorName: {
    color: "#304659",
    fontFamily: "Lato",
    fontWeight: 700,
    textAlign: "left",
    // display: 'inline',
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    [theme.breakpoints.up("xs")]: {
      fontSize: nameFont,
      // fontSize: "22px",
      // float: "left",
      width: "200px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: nameFont,
      // fontSize: "28px",
      width: "300px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: nameFont,
      // fontSize: "32px",
      width: "100%",
    },
  },
  doctorSpecialityText: {
    // fontSize: "25px",
    color: "#304659",
    fontFamily: "Lato",
    fontWeight: 400,
    textAlign: "left",
    // float: 'left',
    // display: 'inline',
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    [theme.breakpoints.up("xs")]: {
      fontSize: descFont,
      // fontSize: "17px",
      width: "200px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: descFont,
      // fontSize: "20px",
      // marginBottom: "3px",
      // width: '300px',
    },
    [theme.breakpoints.up("md")]: {
      fontSize: descFont,
      // fontSize: "25px",
      width: "200px",
    },
  },
  studyText: {
    fontWeight: 400,
    fontFamily: "Lato",
    color: "#304659",
    textAlign: "left",
    [theme.breakpoints.up("xs")]: {
      // marginTop: "5px",
      fontSize: "13px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "-2px",
      fontSize: "13px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "3px",
      fontSize: descFont,
      // fontSize: "18px",
    },
  },
  profileImageContainer: {
    position: "absolute",
    display: "flex",
    [theme.breakpoints.up("xs")]: {
      right: "10px",
      marginTop: "-16px",
      height: "60px",
      width: "60px"
    },
    [theme.breakpoints.up("sm")]: {
      right: "20px",
      height: "80px",
      marginTop: "-16px",
      width: "80px"
    },
    [theme.breakpoints.up("md")]: {
      right: "40px",
      height: "80px",
      width: "80px",
      marginTop: "-30px",
    },
    // filter: 'drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16))',
  },
  profileImage: {
    filter: "drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16))",
    borderRadius: "50%",
    border: "2px solid #ffffff",
    objectFit: 'cover',
    height: "100%",
    width: "100%",
    
    // [theme.breakpoints.up("xs")]: {
    //   width: "48px",
    //   height: "48px",
      marginTop: "10px",
    //   marginRight: "10px",
    // },
    // [theme.breakpoints.up("sm")]: {
    //   width: "78px",
    //   height: "78px",
      // marginTop: "-17px",
    //   marginRight: "10px",
    // },
    // [theme.breakpoints.up("md")]: {
    //   width: "98px",
    //   height: "98px",
      // marginTop: "-40px",
    //   marginRight: "10px",
    // },
  },
  dividerLine: {
    [theme.breakpoints.up("xs")]: {
      width: "250px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "425px",
    },
    [theme.breakpoints.up("md")]: {
      width: "717px",
    },
  },
  availablityCostContainer: {
    marginTop: "20px",
    [theme.breakpoints.up("xs")]: {
      display: "none",
      // flexDirection : 'row-reverse',
    },
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  availablityCostContainerMobile: {
    marginTop: "20px",
    [theme.breakpoints.up("xs")]: {
      display: "inline",
      // flexDirection : 'row-reverse',
    },
    [theme.breakpoints.up("sm")]: {
      display: "inline-block",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  nextAvailabilityText: {
    color: "#222222",
    fontFamily: "Lato",
    fontWeight: 700,
    opacity: "50%",
    textAlign: "left",
    marginTop: "5px",
    [theme.breakpoints.up("xs")]: {
      fontSize: "17px",
      display: "inline",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "22px",
    },
  },
  costContainer: {
    height: "35px",
    fontSize: '16px',
    border: "1px solid #707070",
    "&:hover": {
      opacity: "100%",
    },
    cursor: "text",
    [theme.breakpoints.up("xs")]: {
      display: "inline-block",
    },
  },
  timeListContainer: {
    marginTop: "15px",
    textAlign: "left",
    [theme.breakpoints.up("xs")]: {
      display: "inline-block",
    },
    [theme.breakpoints.up("sm")]: {
      // display: "flex",
    },
    [theme.breakpoints.up("md")]: {
      // display: "flex",
    },
  },
  timeContainer: {
    height: "36px",
    backgroundColor: "#ffffff",
    display: "inline-block",
    border: "1px solid #304659",
    "&:hover": { opacity: "50%" },
    cursor: "pointer",
    marginRight: "20px",
    [theme.breakpoints.up("xs")]: {
      width: "250px",
      marginTop: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "122px",
    },
  },
  timeText: {
    fontSize: "18px",
    // marginTop: "5px",
    color: "#304659",
    fontFamily: "Lato",
    fontWeight: 400,
    textAlign: "center",
  },
  alternativeTextContainer: {
    display: "flex",
    marginTop: "30px",
    // [theme.breakpoints.up('xs')]: {
    //   marginTop: '10px',
    // },
    // [theme.breakpoints.up('sm')]: {
    //   marginTop: '30px',
    // },
    // [theme.breakpoints.up('md')]: {
    //   marginTop: '30px',
    // },
  },
  alternativeText: {
    fontSize: "22px",
    color: "#304659",
    fontFamily: "Lato",
    fontWeight: 700,
    marginRight: "8px",
    cursor: "pointer",
  },
  downArrowIcon: {
    width: "14px",
    height: "8px",
    borderRadius: "7px",
    marginTop: "10px",
    cursor: "pointer",
  },
  clinicianSelectIcon: {
    width: "26px",
    height: "26px",
    borderRadius: "13px",
    backgroundColor: "#39b54a",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-25px",
      // marginTop: "10px",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "-30px",
      marginTop: "-20px",
    },
    zIndex: 1,
  },
  rightIconText: {
    width: "17px",
    height: "15px",
  },
  confirmAppointmentContainer: {
    height: "39px",
    borderRadius: "20px",
    backgroundColor: "#ffffff",
    zIndex: 3,
    position: "absolute",
    cursor: "pointer",
    "&:hover": {
      opacity: "80%",
    },
    [theme.breakpoints.up("xs")]: {
      width: "196px",
      marginTop: "220px",
      marginLeft: "50px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "300px",
      marginTop: "159px",
      marginLeft: "100px",
    },
    [theme.breakpoints.up("md")]: {
      width: "296px",
      marginTop: "159px",
      marginLeft: "251px",
    },
  },
  confirmAppointmentText: {
    color: "#003265",
    fontFamily: "Lato",
    fontWeight: 700,
    textAlign: "center",
    [theme.breakpoints.up("xs")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "17px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "17px",
    },
  },
  cancelButtonContainer: {
    width: "179px",
    height: "34px",
    backgroundColor: "#d60000",
    display: "flex",
    "&:hover": { opacity: "50%" },
    cursor: "pointer",
    [theme.breakpoints.up("xs")]: {
      marginLeft: "35px",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "0px",
    },
  },
  waitingList: {
    width: "179px",
    height: "34px",
    backgroundColor: "#314658",
    display: "flex",
    "&:hover": { opacity: "50%" },
    cursor: "pointer",
    [theme.breakpoints.up("xs")]: {
      marginLeft: "35px",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "0px",
    },
  },
  cancelText: {
    fontSize: "16px",
    color: "#ffffff",
    fontFamily: "Lato",
    fontWeight: 400,
    marginRight: "13px",
  },
  xIcon: {
    fontSize: "17px",
    color: "#222222",
    fontFamily: "Lato",
    fontWeight: 400,
    marginTop: "-4px",
  },
  appolizeText: {
    fontSize: "15px",
    color: "#222222",
    fontFamily: "Lato",
    fontWeight: 400,
    height: "33px",
    textAlign: "left",
    [theme.breakpoints.up("xs")]: {
      width: "230px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "350px",
    },
  },
  selectedContainer: {
    backgroundColor: "#003265",
    opacity: "80%",
    position: "absolute",
    zIndex: 2,
    [theme.breakpoints.up("xs")]: {
      width: "300px",
      height: "458px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "500px",
      height: "358px",
    },
    [theme.breakpoints.up("md")]: {
      width: "800px",
      height: "358px",
    },
  },
  appointmentCancelButton: {
    backgroundColor: "#fff",
    zIndex: 3,
    width: "30px",
    height: "30px",
    borderRadius: "50px",
    position: "absolute",
    right: "5px",
    marginTop: "5px",
    cursor: "pointer",
  },
});