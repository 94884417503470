/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import styles from "./TableFont.styles";



export const TableNameFont = ({ text, ...props }) => {
  const classes = styles();
  return (
    <Typography className={classes.rootName} {...props}>
      {text}
    </Typography>
  );
};

export const TableDescFont = ({ text, ...props }) => {
  const classes = styles();
  return (
    <Typography className={classes.rootDesc} {...props}>
      {text}
    </Typography>
  );
};

export const TableTitleFont = ({ text, ...props }) => {
  const classes = styles();
  return (
    <Typography className={classes.rootTitle} {...props}>
      {text}
    </Typography>
  );
};
