import { createStyles} from "@material-ui/core";
export default (theme) =>
  createStyles({
    root: {
      backgroundColor: "#cfd9df",
      width: "100%",
      height: "100%",
      opacity: "20%",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    container: {
      position: "absolute",
      zIndex: 1,
      [theme.breakpoints.up("xs")]: {
        width: "300px",
        top: "150px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "500px",
        top: "150px",
      },
      [theme.breakpoints.up("md")]: {
        width: "800px",
        top: "186px",
      },
    },
    backArrow: {
      width: "10px",
      height: "10px",
      margin: "5px",
      marginTop: "8px",
      cursor: "pointer",
    },
    back: {
      cursor: "pointer",
      fontSize: "17px",
      lineHeight: "25px",
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: 800,
    },
    headerText: {
      fontSize: "17px",
      lineHeight: "25px",
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: 400,
      textAlign: "left",
    },
    boxContainer: {
      marginTop: "20px",
      borderRadius: "10px",
      backgroundColor: "#ffffff",
      border: "1px solid #707070",
      [theme.breakpoints.up("xs")]: {
        height: "auto",
      },
      [theme.breakpoints.up("md")]: {
        height: "auto",
      },
    },
    mobileListContainer: {
      width: "100%",
      textAlign: "left",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      [theme.breakpoints.up("xs")]: {
        padding: "15px",
        height: "auto",
      },
      [theme.breakpoints.up("sm")]: {
        padding: "20px",
      },
      [theme.breakpoints.up("md")]: {
        padding: "40px",
      },
    },
    queText: {
      width: "301px",
      maxWidth: "301px",
      minWidth: "301px",
      height: "217px",
      fontSize: "18px",
      lineHeight: "25px",
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: 400,
      wordWrap: "break-word",
      textAlign: "left",
      [theme.breakpoints.up("xs")]: {
        display: "none",
      },
      [theme.breakpoints.up("md")]: {
        display: "inline",
      },
    },
    queTextMobile: {
      // height: "217px",
      fontSize: "22px",
      lineHeight: "25px",
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: 400,
      wordWrap: "break-word",
      textAlign: "left",
      [theme.breakpoints.up("xs")]: {
        width: "270px",
        marginBottom: "10px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "470px",
        marginBottom: "10px",
      },
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    notAtAllBox: {
      display: "flex",
      height: "39px",

      borderRadius: "20px",
      filter: "drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16))",
      backgroundColor: "#e0f1ff",
      justifyContent: "center",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#c0d0de",
      },
      "&:focus": {
        backgroundColor: "#304659",
      },
      [theme.breakpoints.up("xs")]: {
        width: "263px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "450px",
        // marginLeft: '60px',
      },
      [theme.breakpoints.up("md")]: {
        width: "339px",
      },
    },
    notAtAllText: {
      fontSize: "16px",
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: 700,
      textAlign: "center",
      marginTop: "8px",
    },
    nextButtonStyle: {
      width: 160,
      color: "#fff",
      borderRadius: "20px",
      backgroundColor: "#304659",
      "&:hover": { backgroundColor: "#1f2c38" },
      padding: "8px 8px 7px 8px",
      justifyContent: "center",
      cursor: "pointer",
    }
    // topButtonAndProgressBarContainer: {

    // }
    // root: {
    //   flexGrow: 1,
    // },
    // heading: {
    //   fontSize: 30,
    //   lineHeight: "24px",
    //   fontWeight: 600,
    //   textTransform: "uppercase",
    //   display: "flex",
    //   justifyContent: "flex-start",
    //   marginTop: theme.spacing(5),
    //   marginBottom: theme.spacing(4),
    //   fontStyle: "italic",
    //   color: "#003d79",
    // },
    // nameSty: {
    //   fontSize: 20,
    //   lineHeight: "24px",
    //   textTransform: "capitalize",
    //   display: "flex",
    //   justifyContent: "flex-start",
    //   marginTop: theme.spacing(5),
    //   marginRight: theme.spacing(1),
    //   color: "#003d79",
    // },
    // inputSty: {
    //   display: "flex",
    //   justifyContent: "flex-start",
    //   marginTop: theme.spacing(2),
    //   width: "80%",
    //   color: "#a29061",
    // },
    // textSty: {
    //   fontSize: 20,
    //   fontWeight: 500,
    //   lineHeight: "12px",
    //   textTransform: "none",
    //   display: "flex",
    //   justifyContent: "flex-start",
    //   marginTop: theme.spacing(2),
    //   fontStyle: "italic",
    //   color: "#003d79",
    // },
    // flexSty: {
    //   display: "flex",
    //   justifyContent: "flex-start",
    // },
    // gridSty: {
    //   backgroundColor: "#eceff1",
    //   height: 50,
    // },
    // contentSty: {
    //   fontSize: 20,
    //   fontWeight: 400,
    //   display: "flex",
    //   justifyContent: "flex-start",
    //   textTransform: "none",
    //   color: "#003d79",
    // },
    // subContent: {
    //   fontSize: 20,
    //   fontWeight: 600,
    //   display: "flex",
    //   justifyContent: "center",
    //   textTransform: "capitalize",
    //   marginTop: theme.spacing(-1),
    //   color: "#003d79",
    // },
    // submitRcadButton: {
    //   padding: "2px",
    //   backgroundColor: "#003d79",
    //   color: "#fff",
    //   textTransform: "capitalize",
    //   marginTop: "10px",
    //   marginBottom: "10px",
    // },
  });