import React from 'react';
import PropTypes from 'prop-types';
import DrawerMenu from '../../common/Header/DrawerMenu';
import { GridContainer, GridItem } from '../../common/BodyContainer';
import { Grid } from '@material-ui/core';
import BillingDetail from '../../../containers/billing/receipt';

const Billing = ({ match }) => {
  return <BillingDetail match={match} />;
};

Billing.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Billing;
