import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  root: {
    margin: theme.spacing(4),
  },
  formControl: {
    // margin: theme.spacing(3),
    width: '100%',
    height: 40,
    borderRadius: 3,
    borderColor: '#cfd8dc',
    borderWidth: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'inline-block',
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#1976d2 !important',
  },
  button: {
    display: 'inline-block',
    padding: theme.spacing(0.6),
    color: '#003d79',
    borderColor: '#003d79',
    background: '#ffffff',
  },
  title: {
    fontSize: 18,
    fontWeight: 400,
    color: '#003d79',
    fontFamily: 'inherit',
    marginLeft: theme.spacing(2),
  },
  righttextinput: {
    textAlign: 'left',
    // paddingTop: theme.spacing(6),
  },
  dateInput: {
    width: '100%',
    height: '18px',
    borderRadius: 3,
    borderStyle: 'solid',
    borderColor: '#003d79',
    color: '#003d79',
    borderWidth: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'inline-block',
    textAlign: 'center',
    // marginLeft: theme.spacing(2),
  },
  dividersty: {
    // width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  gridsty: {
    marginTop: 15,
    marginLeft: 15,
  },
  alldaysty: {
    cursor: 'pointer',
    color: '#003d79',
    fontSize: 13,
    fontWeight: 400,
    marginLeft: theme.spacing(1),
  },
  radiosty: {
    marginLeft: theme.spacing(1),
    color: '#003d79',
  },
  repeatsty: {
    textAlign: 'center',
    color: '#003d79',
    fontWeight: 400,
    marginTop: theme.spacing(0.8),
  },
  btngroupsty: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  selected: {
    backgroundColor: '#1290cb',
    color: '#ffffff',
  },
  divstyle: {
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#eceff1',
    height: 38,
    borderRadius: 5,
    // marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1),
  },
  textStyle: {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#003d79',
    fontFamily: 'inherit',
    textAlign: 'left',
  },
});
