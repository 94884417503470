import React, { PureComponent } from 'react';
import {  withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Header from '../../../src/common/Header';
import { InputTitle, FormInput } from './forms/AddChildForm';
import DatePicker, { registerLocale } from 'react-datepicker';
// import en from 'date-fns/locale/en';
import CustomRadio from '../../../src/common/RadioButton';
import { CustomCancelButtons, CustomNextButtons, CustomAdultButtons } from '../../../src/common/CustomButtons';
import styles from "./AddMultipleAdultsStyle";

// registerLocale('en', en);

const fileds = [
    "1", "2"
];

class AddAdults extends PureComponent {
    render() {
        const { classes } = this.props;

        return (
            <div>
                <Header props={this.props} />
                <div className={classes.root} />
                <Grid container justify="center" alignItems="center">
                    <Grid className={classes.titleContainer} >
                        <Grid
                            container
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item className={classes.container}>
                                <Grid >
                                    <Typography className={classes.title}>
                                        Adding Adult(s)
                                    </Typography>

                                    {fileds.map((filed) => (
                                        <Grid
                                            container
                                            justify="center"
                                            alignItems="center"
                                            className={classes.bodyContainer}
                                            key={filed}
                                        >
                                            <Grid
                                                container
                                                className={classes.gridwhite}
                                            >
                                                <Grid className={classes.fieldContainer}>
                                                    <InputTitle text="First Name" />
                                                    <FormInput
                                                        type="text"
                                                        placeholder="" />
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                className={classes.gridcolor}
                                            >
                                                <Grid className={classes.fieldContainer}>
                                                    <InputTitle text="last Name" />
                                                    <FormInput
                                                        type="text"
                                                        placeholder="" />
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                className={classes.gridwhite}
                                            >
                                                <Grid className={classes.fieldContainer}>
                                                    <InputTitle text="date of birth" />
                                                    <DatePicker
                                                        locale='en'
                                                        className={classes.dateInput}
                                                        dateFormat="MMMM d, yyyy "
                                                        // selected={this.state.dob}
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        showMonthDropdown
                                                    // onChange={(date) => (
                                                    //   this.setState({ dob: date }) (dob = null)
                                                    // )}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                className={classes.gridcolor}
                                            >
                                                <Grid className={classes.fieldContainer}>
                                                    <InputTitle text="sex" />
                                                    <RadioGroup
                                                        style={{ display: "flex", flexDirection: "row" }}
                                                        name="gender"
                                                    // value={gender}
                                                    // onChange={this.handleChange}
                                                    >
                                                        <FormControlLabel
                                                            value="male"
                                                            // checked={gender === "male" && true}
                                                            control={<CustomRadio />}
                                                            label={
                                                                <Typography className={classes.labletext}>
                                                                    Male
                                                            </Typography>
                                                            }
                                                        />
                                                        <FormControlLabel
                                                            style={{ marginLeft: "35px" }}
                                                            value="female"
                                                            // checked={gender === "female" && true}
                                                            control={<CustomRadio />}
                                                            label={
                                                                <Typography className={classes.labletext}>
                                                                    Female
                                                            </Typography>
                                                            }
                                                        />
                                                    </RadioGroup>
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                className={classes.gridwhite}
                                            >
                                                <Grid className={classes.fieldContainer}>
                                                    <InputTitle text="Email" />
                                                    <FormInput
                                                        type="email"
                                                        placeholder="" />
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                className={classes.gridcolor}
                                            >
                                                <Grid className={classes.fieldContainer}>
                                                    <InputTitle text="Confirm Email" />
                                                    <FormInput
                                                        type="email"
                                                        placeholder="" />
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                className={classes.gridwhite}
                                            >
                                                <Grid className={classes.fieldContainer}>
                                                    <InputTitle text="Spouse?" />
                                                    <RadioGroup
                                                        style={{ display: "flex", flexDirection: "row" }}
                                                    >
                                                        <FormControlLabel
                                                            value="yes"
                                                            control={<CustomRadio />}
                                                            label={
                                                                <Typography className={classes.labletext}>
                                                                    Yes
                                                        </Typography>
                                                            }
                                                        />
                                                        <FormControlLabel
                                                            style={{ marginLeft: "35px" }}
                                                            value="no"
                                                            control={<CustomRadio />}
                                                            label={
                                                                <Typography className={classes.labletext}>
                                                                    No
                                                        </Typography>
                                                            }
                                                        />
                                                    </RadioGroup>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}


                                    <Grid
                                        container
                                        justify="center"
                                        style={{ marginTop: "76px", marginBottom: "100px" }}
                                    >
                                        <CustomAdultButtons
                                            title="Add another adult?"
                                            width="351px"
                                            style={classes.cancelButton}
                                            // textStyles={classes.cancelText}
                                            // onPressButton={() => this.setState({ isOpen: true })}
                                            onPressButton={() => this.props.history.goBack()}
                                        />

                                        <Grid
                                            container
                                            justify="center"
                                            style={{ marginTop: "10px", marginBottom: "100px" }}
                                        >

                                            <CustomCancelButtons
                                                title="Cancel"
                                                width="170px"
                                                style={classes.cancelButton}
                                                // textStyles={classes.cancelText}
                                                // onPressButton={() => this.setState({ isOpen: true })}
                                                onPressButton={() => this.props.history.goBack()}
                                            />
                                            <CustomNextButtons
                                                width="170px"
                                                title="Next"
                                                style={classes.nextButton}
                                                onPressButton={this.handleSubmit}
                                            // onPressButton={() => this.props.history.push(
                                            //   '/user/appointment/insuranceinfo',
                                            // )}
                                            />
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}


export default withStyles(styles)(AddAdults);
