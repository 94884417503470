import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Avatar } from '@material-ui/core';
import {
  Title,
  Experties,
  Tag,
  Subject,
  Key,
  Value,
  PosterGrid,
  TagGrid,
  VenueGrid,
  Discription,
} from './style';
import {
  DISCIPLINES,
  EVENTS_AGE_CATEOGORIES,
  EVENT_CATEGORIES,
} from '../utils/enums';
import moment from 'moment';
import { DATE_FORMAT } from '../utils/timUtils';
import 'moment-timezone';
import { capitalizeFirstLetter } from '../utils/string';

const OrganiserInfo = ({ classes, eventData, getDate, getTime }) => {
  return (
    <div className="item col-right flex-grow order-1 bg-white lg:order-2 pb-6">
      { }
      <div
        className="event-cover relative w-full h-0"
        style={{
          paddingTop: "56.25%"
        }}
      >
        <img
          className="w-full h-full absolute top-0 left-0 object-cover"
          src={eventData && eventData.poster}
          alt
          style={{
            objectPosition: "center"
          }}
        />
      </div>
      <div className="event-desc relative">
        { }
        <div className="primary-clinician-avatar absolute w-full -top-10 lg:-top-14 text-center mt-2 z-10">
          <img
            className="inline-block rounded-full w-16 h-16 lg:w-20 lg:h-20"
            src={eventData && eventData.clinicians && eventData.clinicians[0].avatar}
            height="auto"
            alt
          />
        </div>
        <div className="primary-clinician-details relative text-center pt-10">
          <h3 className="text-brand-blue text-lg font-bold">
            {eventData && eventData.clinicians && eventData.clinicians[0].name}
            <span className="text-xs">
              {eventData &&
                eventData.clinicians &&
                eventData.clinicians[0].discipline &&
                ", " + DISCIPLINES[eventData.clinicians[0].discipline]}
            </span>
          </h3>
          <div className="extended-profile-desc text-base text-brand-gold-dark">
            {eventData && eventData.title}
            <br />
          </div>
        </div>
        { }
        <table className="w-full border-collapse mt-4 text-base font-normal">
          <tbody>
            <tr>
              <td className="w-3/6 px-2 text-right text-brand-gold-dark">
                Date
              </td>
              <td className="w-3/6 px-2 text-left text-brand-blue">
                {eventData && eventData.date
                  ? moment(eventData.date).tz('Asia/Dubai').format(DATE_FORMAT)
                  : '--'}
              </td>
            </tr>
            <tr>
              <td className="px-2 text-right text-brand-gold-dark">Time</td>
              <td className="px-2 text-left text-brand-blue">
                {eventData && moment(eventData.startTime).tz('Asia/Dubai').format('hh:mmA') + " - " +
                  moment(eventData.endTime).tz('Asia/Dubai').format('hh:mmA')}
              </td>
            </tr>
            <tr>
              <td className="px-2 text-right text-brand-gold-dark">Age</td>
              <td className="px-2 text-left text-brand-blue">
                {eventData &&
                  eventData.ageRange &&
                  EVENTS_AGE_CATEOGORIES[
                  eventData.ageRange.min + '-' + eventData.ageRange.max
                  ]}
              </td>
            </tr>
            <tr>
              <td className="px-2 text-right text-brand-gold-dark">
                Event Category
              </td>
              <td className="px-2 text-left text-brand-blue">
                {eventData?.category?.title}
              </td>
            </tr>
            <tr>
              <td className="px-2 text-right text-brand-gold-dark">
                Event Type
              </td>
              <td className="px-2 text-left text-brand-blue">{eventData && eventData.type &&  capitalizeFirstLetter(eventData.type)}</td>
            </tr>
            <tr>
              <td className="px-2 text-right text-brand-gold-dark">Cost</td>
              <td className="px-2 text-left text-brand-blue"> {eventData && eventData.isFree ? 'Free' : eventData?.fee}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

OrganiserInfo.propTypes = {
  classes: PropTypes.shape({
    poster: PropTypes.string.isRequired,
    profileImage: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
  eventData: PropTypes.shape({
    poster: PropTypes.string,
    title: PropTypes.string,
    isFree: PropTypes.bool,
    fee: PropTypes.number,
    date: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    image: PropTypes.string,
    clinicians: PropTypes.array,
    ageRange: PropTypes.shape({ max: PropTypes.number }),
    type: PropTypes.string,
    age: PropTypes.string,
    category: PropTypes.string,
  }),
  getTime: PropTypes.func.isRequired,
  getDate: PropTypes.func.isRequired,
};

export default OrganiserInfo;
