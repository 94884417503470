import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DatePicker from 'react-datepicker';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FilterImage from '../assets/clinician_image//Group 351@2x.png';
import NativeSelect from '@material-ui/core/NativeSelect';
import Axios from 'axios';
import { baseURL } from '../utils/axios';
import { LOCATION, APPOINTMENT_TYPES } from '../utils/enums';
import closeImg from '../assets/imgs/close.jpeg';
import './clinician.scss';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './FilterDrawer.style';
import moment from 'moment';
import SelectClinicAddress from '../admin/components/common/SelectClinicAddresses';
import ClinicianSearchView from '../common/ClinicianSearchView';
import PatientSearchView from '../common/PatientSearchView';
import { getClinicianAction } from '../utils/actions/clinician.action';

const currentDateStr = new Date().toISOString().split('T')[0];

const FilterDrawer = ({
  filterDrawer,
  toggleDrawer,
  handleSubmitRequest,
  applyMultipleFilters,
  handleReportDownload,
  isClinician,
  clinicians,
}) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState('');

  const [status, setStatus] = useState('');
  const [archivalRange, setArchivalRange] = useState('');
  const [clientIds, setClientIds] = useState([]);
  const [startTime, setStartTime] = useState(
    moment().subtract(1, 'month').format('YYYY-MM-D')
  );
  const [endTime, setEndTime] = useState(currentDateStr);
  const [clientAgeGroup, setClientAgeGroup] = useState('');
  const [clientStatus, setClientStatus] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [appointmentType, setAppointmentType] = useState('');
  const [filterName, setFilterName] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [clientList, setClientList] = useState([]);
  const [clientValue, setClientValue] = useState('');
  const [patientName, setPatientName] = useState('');
  const [clinicianName, setClinicianName] = useState('');
  const [appointmentLocation, setAppointmentLocation] = useState('');
  const [serviceType, setServiceType] = useState('');
  const [address, setAddress] = useState('');
  const [category, setCategory] = useState('');

  useEffect(() => {
    getClient(clientValue);
  }, []);

  const getClient = (value) => {
    Axios.get(`${baseURL}/api/appointment/searchUser?q=${value}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        setClientList(res.data);
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const handleSubmit = (applyFilter, downlaodReport) => {
    const params = {
      type: 'appointment',
      archivalRange: status === 'archived' ? archivalRange : '',
      status,
      clientIds,
      startTime,
      endTime,
      clientAgeGroup,
      clientStatus,
      paymentStatus,
      filterName,
      appointmentType,
      patientName,
      clinicianName,
      appointmentLocation,
      serviceType,
      address,
      category,
    };

    if (applyFilter) {
      applyMultipleFilters(
        {
          archivalRange: status === 'archived' ? archivalRange : '',
          status,
          clientIds,
          startTime,
          endTime,
          clientAgeGroup,
          clientStatus,
          paymentStatus,
          filterName,
          appointmentType,
          patientName,
          clinicianName,
          appointmentLocation,
          serviceType,
          address,
          category,
        },
        '',
        '',
        true
      );
    } else if (downlaodReport) {
      handleReportDownload(params);
    } else {
      handleSubmitRequest(params);
    }

    setOpenModal(false);
    setStatus('');
    setArchivalRange('');
    setClientIds([]);
    setStartTime(currentDateStr);
    setEndTime(currentDateStr);
    setClientAgeGroup('');
    setClientStatus('');
    setPaymentStatus('');
    setAppointmentType('');
    setFilterName('');
    setBtnDisabled(true);
    setClientList([]);
    setClientValue('');
    setPatientName('');
    setClinicianName('');
    setAppointmentLocation('');
    setServiceType('');
    setAddress('');
    setCategory('');
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role='presentation'
      style={{ width: '480px' }}
    >
      <Grid container style={{ padding: 25 }}>
        <Grid container className={classes.topHeader}>
          <Grid xs={6}>
            <img src={FilterImage} width='50' />
            <span className={classes.textColor}>Filters</span>
          </Grid>
          <Grid xs={6}>
            <div
              style={{ float: 'right', color: '#003265', cursor: 'pointer' }}
              onClick={toggleDrawer(false)}
            >
              <span style={{ float: 'left', marginRight: '5px' }}>Close</span>
              <img src={closeImg} />
            </div>
          </Grid>
        </Grid>

        <form style={{ width: '100%' }}>
          <label className={classes.labelText}> Patient Name</label>
          <br />
          <Grid container style={{ marginBottom: '15px' }}>
            <PatientSearchView
              disabled={false}
              appointmentDetail={{
                type: 'Current-Patient',
              }}
              onChange={(data) => {
                setBtnDisabled(false);
                setPatientName(data.map((d) => d.email));
              }}
              isClinician={isClinician}
              isAdmin={!isClinician}
            />
          </Grid>
          {!isClinician && (
            <Grid xs={12} item>
              <label className={classes.labelText}> Clinician Name</label>
              <br />
              <Grid container style={{ marginBottom: '23px' }}>
                <ClinicianSearchView
                  clinicians={clinicians ? clinicians : []}
                  appointmentDetail={{
                    type: 'Current-Patient',
                    clinicianId: null,
                  }}
                  onChange={(data) => {
                    setBtnDisabled(false);
                    setClinicianName(data ? data.email : '');
                  }}
                />
              </Grid>
            </Grid>
          )}

          <Grid direction='column' alignItems='flex-start' container>
            <label className={classes.labelText}>Date Range</label>
            <Grid justify='space-between' container>
              <Grid item>
                <TextField
                  id='startDate'
                  className='date-style-custom'
                  type='date'
                  name='startTime'
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item>
                <TextField
                  id='endDate'
                  type='date'
                  className='date-style-custom'
                  name='endTime'
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <div>
            <label className={classes.labelText}>Client Status</label>
            <br />
            <FormControl
              variant='outlined'
              className={classes.formControl + ' customt-select'}
            >
              <NativeSelect
                displayEmpty
                label={'Select'}
                placeholder='Select'
                value={clientStatus}
                onChange={(e) => {
                  setClientStatus(e.target.value);
                  setBtnDisabled(false);
                }}
                className={classes.selectEmpty}
                inputProps={{
                  name: 'clientAgeGroup',
                }}
              >
                <option value=''>Select</option>
                <option value='new'>New</option>
                <option value='current'>Current</option>
                <option value='old'>Old</option>
              </NativeSelect>
            </FormControl>

            <label className={classes.labelText}>Client Age Group</label>
            <br />

            <FormControl
              variant='outlined'
              className={classes.formControl + ' customt-select'}
            >
              <NativeSelect
                displayEmpty
                label={'Select'}
                placeholder='Select'
                value={clientAgeGroup}
                onChange={(e) => {
                  setClientAgeGroup(e.target.value);
                  setBtnDisabled(false);
                }}
                className={classes.selectEmpty}
                inputProps={{
                  name: 'clientAgeGroup',
                }}
              >
                <option value=''>Select</option>
                <option value='adult'>Adult</option>
                <option value='child'>Child</option>
              </NativeSelect>
            </FormControl>

            <label className={classes.labelText}>Appointment Type</label>
            <br />

            <FormControl
              variant='outlined'
              className={classes.formControl + ' customt-select'}
            >
              <NativeSelect
                displayEmpty
                label={'Select'}
                placeholder='Select'
                value={appointmentType}
                onChange={(e) => {
                  setAppointmentType(e.target.value);
                  setBtnDisabled(false);
                }}
                className={classes.selectEmpty}
                inputProps={{
                  name: 'appoinmentType',
                }}
              >
                <option value=''>Select</option>
                <option value='direct'>Direct</option>
                <option value='referred'>Referred</option>
              </NativeSelect>
            </FormControl>
            <label className={classes.labelText}>Service Type</label>
            <br />

            <FormControl
              variant='outlined'
              className={classes.formControl + ' customt-select'}
            >
              <NativeSelect
                displayEmpty
                label={'Select'}
                placeholder='Select'
                className={classes.selectEmpty}
                onChange={(e) => {
                  setServiceType(e.target.value);
                  setBtnDisabled(false);
                }}
                value={serviceType}
              >
                <option value=''>Select</option>
                {Object.keys(APPOINTMENT_TYPES).map((appType) => (
                  <option value={appType} key={appType}>
                    {APPOINTMENT_TYPES[appType]}
                  </option>
                ))}{' '}
              </NativeSelect>
            </FormControl>
            <label className={classes.labelText}>Payment Status</label>
            <br />

            <FormControl
              variant='outlined'
              className={classes.formControl + ' customt-select'}
            >
              <NativeSelect
                displayEmpty
                label={'Select'}
                placeholder='Select'
                className={classes.selectEmpty}
                value={paymentStatus}
                onChange={(e) => {
                  setPaymentStatus(e.target.value);
                  setBtnDisabled(false);
                }}
                inputProps={{
                  name: 'paymentStatus',
                }}
              >
                <option value=''>Select</option>
                <option value='paid'>Paid</option>
                <option value='unpaid'>Pending</option>
              </NativeSelect>
            </FormControl>
            <label className={classes.labelText}> Payment Group </label>
            <br />
            <FormControl
              variant='outlined'
              className={classes.formControl + ' customt-select'}
            >
              <NativeSelect
                displayEmpty
                label={'Select'}
                placeholder='Select'
                className={classes.selectEmpty}
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                  setBtnDisabled(false);
                }}
                inputProps={{
                  name: 'category',
                }}
              >
                <option value=''>Select</option>
                <option value='regular'>Regular</option>
                <option value='corporate'>Corporate</option>
                <option value='bundle'>Bundle</option>
              </NativeSelect>
            </FormControl>
            <label className={classes.labelText}>Location </label>
            <br />
            <FormControl
              variant='outlined'
              className={classes.formControl + ' customt-select'}
            >
              <NativeSelect
                displayEmpty
                label={'Select'}
                placeholder='Select'
                className={classes.selectEmpty}
                onChange={(e) => {
                  setBtnDisabled(false);
                  setAppointmentLocation(e.target.value);
                }}
              >
                <option value=''>Select</option>
                {Object.keys(LOCATION).map((loc) => (
                  <option value={loc} key={loc}>
                    {LOCATION[loc]}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>

            <Grid>
              <Typography className={classes.inputTitle}>Address*</Typography>
              <SelectClinicAddress
                name='address'
                value={address}
                onSelected={(data) => {
                  setBtnDisabled(false);
                  setAddress(data);
                }}
              />
            </Grid>
          </div>
        </form>
        <Button
          variant='contained'
          disabled={btnDisabled}
          className={classes.btnSaveFilter}
          onClick={() => handleSubmit(false, true)}
        >
          Download Report
        </Button>
        <Button
          variant='contained'
          disabled={btnDisabled}
          className={classes.btnSaveFilter}
          onClick={() => handleSubmit(true, false)}
        >
          Apply Filter
        </Button>
        <Button
          variant='contained'
          disabled={btnDisabled}
          className={classes.btnSaveFilter}
          onClick={() => setOpenModal(true)}
        >
          Save Filter
        </Button>
      </Grid>
    </div>
  );

  return (
    <div>
      <>
        <SwipeableDrawer
          anchor={'right'}
          open={filterDrawer}
          onClose={toggleDrawer(false)}
        >
          {list('right')}
        </SwipeableDrawer>
      </>

      <div>
        <Dialog
          maxWidth={'xs'}
          fullWidth={'md'}
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby='form-dialog-title'
          style={{ borderRadius: '1px' }}
        >
          <DialogTitle
            id='form-dialog-title'
            style={{ textAlign: 'center', color: '#003265' }}
          >
            Save Filter
          </DialogTitle>
          <DialogContent dividers style={{ border: 'none' }}>
            <OutlinedInput
              className={classes.inputstyle}
              placeholder='Filter Name'
              style={{ width: '100%' }}
              onChange={(e) => setFilterName(e.target.value)}
            />
          </DialogContent>
          <DialogActions className='justify-content-center mb-5'>
            <Grid container justify='space-between' style={{ padding: 11 }}>
              <Grid xs={5}>
                <Button
                  variant={'contained'}
                  size={'medium'}
                  onClick={() => setOpenModal(false)}
                  color='secondary'
                  style={{ width: '100%', padding: 10 }}
                  className={`${classes.btnNewApp} ml-5`}
                  ref={(element) => {
                    if (element)
                      element.style.setProperty(
                        'margin-left',
                        '5px',
                        'important'
                      );
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid xs={5}>
                <Button
                  variant='contained'
                  size='medium'
                  onClick={() => handleSubmit(false, false)}
                  style={{ width: '100%', padding: 10 }}
                  className={classes.btnNewApp}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  clinicians: state.getClinician.getClinicianData,
});

const mapDispatchToProps = (dispatch) => ({
  getClinicianAction: () => dispatch(getClinicianAction()),
});

FilterDrawer.propTypes = {
  filterDrawer: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  handleSubmitRequest: PropTypes.func.isRequired,
  applyMultipleFilters: PropTypes.func.isRequired,
  isClinician: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterDrawer);
