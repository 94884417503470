/* eslint-disable no-console */
/* eslint-disable react/sort-comp */
/* eslint-disable no-sequences */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
import React, { PureComponent } from 'react';
import {
  withStyles,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Paper,
  Avatar,
} from '@material-ui/core';
import {
  CalendarToday as CalendarTodayIcon,
  AccessTime as AccessTimeIcon,
  LocationOn as LocationOnIcon,
  EnhancedEncryption as EnhancedEncryptionIcon,
} from '@material-ui/icons';
import moment from 'moment';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import dayjs from 'dayjs';
import clock from '../../../assets/user_images/time.svg';
import date from '../../../assets/user_images/date.svg';
import map from '../../../assets/user_images/map_icon.svg';
import people from '../../../assets/user_images/people.svg';
import paypal from '../../../assets/user_images/paypal.svg';
import {
  CustomNextButtons,
  CustomCancelButtons,
} from '../../../common/CustomButtons';
import Header from '../../../common/Header';
import Stepper from '../../../common/Stepper';
import CustomRadio from '../../../common/RadioButton';
import {
  getPaymentUrlAction,
  getPaymentResAction,
  paypalAction,
  eCheckAction,
} from '../../../utils/actions/payment.action';
import { getAppointmentDetailAction } from '../../../utils/actions/appointment.action';
import { Loader } from '../../Loader';
import PopupWindow from '../../../common/PopupWindow';
import AlertMsg from '../../../utils/Alert';
import styles from './PaymentInfoStyle';
import PaypalPayment from '../../../common/PaypalPayment';
import userIcon from '../../../assets/imgs/download.png';
import axios from 'axios';
import { baseURL } from '../../../utils/axios';

class PaymentInformation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isNext: false,
      ipAddress: null,
      urlName: '',
      isUrlGet: false,
      urlLink: '',
      data: null,
    };
  }

  componentWillMount = async () => {
    const billingType = new URLSearchParams(this.props.location.search).get(
      'billingType'
    );

    this.setState({ billingType });

    if (billingType === 'bundle') {
      this.getBundleData();
    } else {
      this.props.getAppointmentDetailAction(this.props.match.params.id);
    }

    window.addEventListener('beforeunload', () => {
      this.handleClosePortal();
    });
    await fetch('https://geoip-db.com/json/', {
      method: 'GET',
      // headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ ipAddress: data.IPv4 });
      });
    this.setState({
      // ipAddress: await IpAddress.v4(),
      isNext: false,
      urlName: '',
      isUrlGet: false,
      urlLink: '',
      billingType,
    });
  };

  getBundleData = () => {
    axios
      .get(`${baseURL}/admin/bundle/get-by-id/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ data: { ...res.data, clientId: [res.data.clientId] } });
      })
      .catch((err) => console.log('err', err));
  };

  handleClosePortal = () => {
    clearInterval(this.checkStatus),
      this.setState({
        isUrlGet: false,
        urlLink: '',
        isNext: false,
      });
  };

  componentDidUpdate(preProps) {
    if (
      this.state.billingType !== 'bundle' &&
      Object.keys(this.props.getAppointmentData).length > 0
    ) {
      this.setState({ data: this.props.getAppointmentData });
    }

    if (this.props.getResponse !== preProps.getResponse) {
      const { status } = this.props.getResponse;

      if (status !== 'init') {
        if (status === 'paid') {
          clearInterval(this.checkStatus);
          AlertMsg('success', 'Payment Successfull!');
          this.setState({ isUrlGet: false, isNext: false });
          this.props.history.push(
            `/confirmation/${this.props.match.params.id}`
          );
        } else if (status === 'failed') {
          clearInterval(this.checkStatus);
          AlertMsg('error', 'Payment Failed, Please try again!');
          this.setState({ isUrlGet: false, isNext: false });
          this.props.history.push(
            `/user/appointment/paymentInfo${this.props.match.params.id}`
          );
        }
      }
    }

    if (this.props.getUrl !== preProps.getUrl) {
      if (this.props.getUrl.getPaymentUrlData) {
        this.setState({
          urlLink: this.props.getUrl.getPaymentUrlData.url,
          isUrlGet: true,
        });
        this.checkStatus = setInterval(() => {
          this.props.getPaymentResAction(
            this.props.getUrl.getPaymentUrlData?.billingId
          );
        }, 2000);
      }
    }

    if (
      this.props.paypalData !== preProps.paypalData &&
      this.props.paypalData != undefined
    ) {
      if (this.props.paypalData.metaData) {
        const data = this.props.paypalData.metaData.links;
        this.setState({
          urlLink: data[1].href,
          isUrlGet: true,
        });
        this.checkStatus = setInterval(() => {
          this.props.getPaymentResAction(this.props.paypalData.billingId);
        }, 2000);
      }
    }

    if (this.props.eCheckData !== preProps.eCheckData) {
      if (
        this.props.eCheckData &&
        this.props.eCheckData.message === 'success'
      ) {
        this.props.history.push(`/confirmation/${this.props.match.params.id}`);
      }
    }
  }

  componentWillUnmount() {
    this.handleClosePortal();
    clearInterval(this.checkStatus);
  }

  handleSubmit = async (e) => {
    const { urlName } = this.state;
    if (urlName === 'credit card') {
      await this.props.getPaymentUrlAction(this.props.match.params.id, {
        publicIp: this.state.ipAddress,
        billingType: this.state.billingType,
      });
      this.setState({ isNext: true });
    } else if (urlName === 'paypal') {
      console.log('this.state.billingType', this.state.billingType);
      await this.props.paypalAction(this.props.match.params.id, {
        publicIp: this.state.ipAddress,
        billingType: this.state.billingType,
      });
      this.setState({ isNext: true });
    } else if (urlName === 'eCheck') {
      await this.props.eCheckAction(this.props.match.params.id, {
        publicIp: this.state.ipAddress,
      });
      this.setState({ isNext: true });
    }
  };

  clinicAddress(a) {
    if (a.title) {
      return a.title;
    }
    return `${a.address1}, ${a.address2 ? a.address2 + ',' : ''} ${a.city}, ${
      a.country.name
    }`;
  }

  vatAmount() {
    const { data } = this.state;
    if (!data.appointmentType?.vat) {
      return 0;
    }

    return Number(
      ((this.subTotal().toFixed(2) / 100) * data.appointmentType?.vat).toFixed(
        2
      )
    );
  }

  discountAmount() {
    const { data } = this.state;

    return data.billingId.discount
      ? Number(((this.subTotal() / 100) * data.billingId.discount).toFixed(2))
      : 0;
  }

  total() {
    return Number(
      (this.subTotal() + this.vatAmount() - this.discountAmount()).toFixed(2)
    );
  }

  subTotal() {
    const { data } = this.state;
    let amount = data.billingId.amount;
    const extraFeesAmount = data.billingId.extraFees.map((e) =>
      Number(e.amount)
    );
    return Number([amount, ...extraFeesAmount].reduce((sum, a) => sum + a));
  }

  render() {
    const { classes } = this.props;
    const { data } = this.state;

    const client = data?.clientId
      ? data.clientId[data.clientId.length - 1]
      : {};

    return (
      <div>
        <Header props={this.props} />
        <div className={classes.root} />
        <Grid container justify='center' alignItems='center' component='div'>
          <Grid className={classes.titleContainer} component='div'>
            <Stepper tab={2} />
            <Grid
              container
              component='div'
              alignItems='center'
              justify='center'
            >
              <Grid item component='div' className={classes.container}>
                <Grid component='div'>
                  <Typography className={classes.title}>
                    Your appointment details
                  </Typography>
                </Grid>

                <Grid component='div'>
                  <Typography className={`mt-0 mb-3 ${classes.boxsubtext}`}>
                    In order to confirm your appointment, your payment must be
                    processed and confirmed.
                  </Typography>
                </Grid>

                <div className='p-4 bg-white' style={{ height: 232 }}>
                  {data && (
                    <div className='flex flex-col bg-white'>
                      <div className='flex justify-start'>
                        <div className='flex justify-start'>
                          <div className='mt-1'>
                            <img
                              src={client.avatar || userIcon}
                              className='w-20 h-20 rounded-full'
                            />
                          </div>
                          <div className='flex flex-col items-start ml-4 leading-loose'>
                            <div className='text-lg font-bold'>
                              {client.firstName
                                ? `${client.firstName} ${client.lastName}`
                                : ''}
                            </div>
                            <div className='flex items-baseline'>
                              <div
                                className='text-base'
                                style={{ paddingRight: '3px' }}
                              >
                                <CalendarTodayIcon fontSize='inherit' />
                              </div>
                              <div>
                                {data &&
                                  moment(data.startTime).format('D/MM/YYYY')}
                              </div>
                            </div>
                            <div className='flex items-baseline'>
                              <div
                                className='text-base'
                                style={{ paddingRight: '3px' }}
                              >
                                <AccessTimeIcon fontSize='inherit' />
                              </div>
                              <div>
                                {data?.startTime
                                  ? `${moment(data.startTime).format(
                                      'hh:mm a'
                                    )},
                            for ${data && data.duration} mins`
                                  : `${data && data.duration} mins`}
                              </div>
                            </div>
                            <div className='flex items-baseline'>
                              <div
                                className='text-base -m-1'
                                style={{ paddingRight: '3px' }}
                              >
                                <LocationOnIcon />
                              </div>
                              <div>
                                {data?.clinicAddress &&
                                  this.clinicAddress(data.clinicAddress)}
                              </div>
                            </div>
                            <div className='flex items-baseline'>
                              <div
                                className='text-base'
                                style={{ paddingRight: '3px' }}
                              >
                                <EnhancedEncryptionIcon fontSize='inherit' />
                              </div>
                              <div>
                                Service Type:
                                {data && data.appointmentType?.type}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex justify-end'>
                        <div className='text-lg font-bold'>
                          ID:
                          {data?.billingId?._id}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className='bg-white mt-8'>
                  <div className='flex'>
                    <div
                      className='flex justify-center items-center font-bold uppercase h-10 text-white w-2/4'
                      style={{
                        backgroundColor: '#003265',
                        borderRight: '1px solid #F6F7FB',
                      }}
                    >
                      Service
                    </div>
                    <div
                      className='flex justify-center items-center font-bold uppercase h-10 text-white w-2/4'
                      style={{
                        backgroundColor: '#003265',
                        borderLeft: '1px solid #F6F7FB',
                      }}
                    >
                      Cost
                    </div>
                  </div>
                  <div className='flex'>
                    <div
                      className='flex justify-start items-center h-10 w-2/4 font-medium py-0 px-5'
                      style={{
                        color: '#474747',
                        borderRight: '1px solid #F6F7FB',
                        borderBottom: '2px solid #F6F7FB',
                      }}
                    >
                      {data?.appointmentType?.type}
                    </div>
                    <div
                      className='flex justify-center items-center h-10 w-2/4 font-medium'
                      style={{
                        borderLeft: '1px solid #F6F7FB',
                        borderBottom: '2px solid #F6F7FB',
                        color: '#656565',
                      }}
                    >
                      {data?.billingId?.amount} AED
                    </div>
                  </div>
                  {data?.billingId?.extraFees &&
                    data?.billingId?.extraFees.map((ef) => (
                      <div className='flex'>
                        <div
                          className='flex justify-end items-center h-10 w-2/4 font-medium py-0 px-5'
                          style={{
                            color: '#474747',
                            borderBottom: '2px solid #F6F7FB',
                            borderRight: '1px solid #F6F7FB',
                          }}
                        >
                          {ef.type}
                        </div>
                        <div
                          className='flex justify-center items-center h-10 w-2/4 font-medium'
                          style={{
                            color: '#656565',
                            borderBottom: '2px solid #F6F7FB',
                            borderLeft: '1px solid #F6F7FB',
                          }}
                        >
                          {ef.amount} AED
                        </div>
                      </div>
                    ))}
                  <div className='flex'>
                    <div
                      className='flex justify-end font-bold items-center h-10 w-2/4 py-0 px-5'
                      style={{
                        borderRight: '1px solid #F6F7FB',
                        borderBottom: '2px solid #F6F7FB',
                        color: '#474747',
                      }}
                    >
                      Subtotal:
                    </div>
                    <div
                      className='flex justify-center items-center h-10 w-2/4 font-medium'
                      style={{
                        borderLeft: '1px solid #F6F7FB',
                        borderBottom: '2px solid #F6F7FB',
                        color: '#656565',
                      }}
                    >
                      {data?.billingId ? this.subTotal() : 0} AED
                    </div>
                  </div>
                  <div className='flex'>
                    <div
                      className='flex justify-end font-bold items-center h-10 w-2/4 py-0 px-5'
                      style={{
                        borderRight: '1px solid #F6F7FB',
                        borderBottom: '2px solid #F6F7FB',
                        color: '#474747',
                      }}
                    >
                      Discount
                    </div>
                    <div
                      className='flex justify-center items-center h-10 w-2/4 font-medium'
                      style={{
                        borderLeft: '1px solid #F6F7FB',
                        borderBottom: '2px solid #F6F7FB',
                        color: '#656565',
                      }}
                    >
                      {data?.billingId?.discount || 0}%
                    </div>
                  </div>
                  <div className='flex'>
                    <div
                      className='flex justify-end font-bold items-center h-10 w-2/4 py-0 px-5'
                      style={{
                        borderRight: '1px solid #F6F7FB',
                        borderBottom: '2px solid #F6F7FB',
                        color: '#474747',
                      }}
                    >
                      VAT ({data?.appointmentType?.vat || 0}%)
                    </div>
                    <div
                      className='flex justify-center items-center h-10 w-2/4 font-medium'
                      style={{
                        borderLeft: '1px solid #F6F7FB',
                        borderBottom: '2px solid #F6F7FB',
                        color: '#656565',
                      }}
                    >
                      {data?.billingId ? this.vatAmount() : 0}
                    </div>
                  </div>
                  <div style={{ display: 'flex', backgroundColor: '#E2E8F1' }}>
                    <div
                      className='flex justify-end font-bold items-center h-10 w-2/4 py-0 px-5'
                      style={{
                        borderRight: '1px solid #F6F7FB',
                        borderBottom: '2px solid #F6F7FB',
                        color: '#474747',
                      }}
                    >
                      Total:
                    </div>
                    <div
                      className='flex font-bold justify-center items-center h-10 w-2/4'
                      style={{
                        borderLeft: '1px solid #F6F7FB',
                        borderBottom: '2px solid #F6F7FB',
                        color: '#474747',
                      }}
                    >
                      {data?.billingId ? this.total() : 0} AED
                    </div>
                  </div>
                </div>

                <Grid className={classes.secondContainer}>
                  <Typography className={classes.title}>
                    Select Payment Method
                  </Typography>
                  <Grid container className={classes.cardContainer}>
                    <Grid
                      container
                      spacing={3}
                      style={{
                        paddingLeft: '42px',
                        paddingTop: '27px',
                        marginBottom: '25px',
                        // display: 'flex',
                        // flexDirection: 'column',
                      }}
                    >
                      <RadioGroup
                        onChange={(e) =>
                          this.setState({ urlName: e.target.value })
                        }
                      >
                        <FormControlLabel
                          value='credit card'
                          control={<CustomRadio />}
                          label={
                            <Typography className={classes.cardtext}>
                              Credit card: Visa, MasterCard, Amex
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value='paypal'
                          control={<CustomRadio />}
                          label={
                            <img src={paypal} className={classes.paypal} />
                          }
                        />

                        {/* <FormControlLabel
                          // style={{ marginBottom: '20px' }}
                          value="eCheck"
                          control={<CustomRadio />}
                          label={
                            <Typography className={classes.cardtext}>
                              E-check payment
                            </Typography>
                          }
                        /> */}
                      </RadioGroup>
                      {/* <Grid component="div" className={classes.gridflex}>

                        <input type="radio" className={classes.radio} />
                        <Typography className={classes.cardtext}>
                          Credit card: Visa, MasterCard, Amex
                  </Typography>
                      </Grid>

                      <Grid
                        component="div"
                        className={classes.gridflex}
                        style={{ marginTop: "12px" }}
                      >
                        <item className={classes.radionotselected} />
                        <img src={paypal} />
                      </Grid>

                      <Grid
                        component="div"
                        className={classes.gridflex}
                        style={{ marginTop: "12px" }}
                      >
                        <item className={classes.radionotselected} />
                        <Typography className={classes.cardtext}>
                          E-check payment
                  </Typography>
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container justify='center' className='mt-8'>
                  <CustomCancelButtons
                    width='170px'
                    title='Cancel'
                    textStyles={classes.cancelText}
                    onPressButton={() =>
                      this.props.history.push(
                        `/verifyinfo/${this.props.match.params.id}`
                      )
                    }
                  />
                  {this.state.urlName === 'paypal' ? (
                    <div style={{ marginLeft: '16px' }}>
                      <PaypalPayment
                        appointmentDetail={this.state.data}
                        amount={this.total()}
                      />
                    </div>
                  ) : (
                    <CustomNextButtons
                      width='170px'
                      title='Next'
                      style={classes.nextButton}
                      onPressButton={this.handleSubmit}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {this.state.isUrlGet && (
          <PopupWindow
            url={this.state.urlLink}
            closeWindowPortal={this.handleClosePortal}
          />
        )}
      </div>
    );
  }
}

PaymentInformation.defaultProps = {
  getResponse: {},
  getUrl: {},
  getAppointmentData: {},
  appointmentLoader: true,
};

const connected = connect(
  (state) => ({
    getUrl: state.getPaymentUrl,
    getResponse: state.getPaymentRes.getPaymentResData,
    getAppointmentData: state.getAppointmentDetail.getAppointmentDetailData,
    appointmentLoader: state.getAppointmentDetail.request,
    paypalData: state.paypal.paypalData,
    eCheckData: state.eCheck.eCheckData,
  }),
  {
    getPaymentUrlAction,
    getPaymentResAction,
    getAppointmentDetailAction,
    paypalAction,
    eCheckAction,
  }
)(PaymentInformation);

const formed = reduxForm({
  form: 'payment',
})(connected);

export default withStyles(styles)(formed);
