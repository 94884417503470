import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { getClinicianAction } from '../utils/actions/clinician.action';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { withStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import { baseURL } from '../utils/axios';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import Menu from '@material-ui/core/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SliderPointLine from '../assets/imgs/slider verticle line.svg';
import SliderPointIcon from '../assets/imgs/slider point.svg';
import Slider from 'react-slick';
import Loader from '../components/Loader/Loader';
import { Grid, Typography, MenuItem, Container } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import useStyles, {
  StatusGrid,
  ActionGrid,
  Capitalize,
  Title,
  CardGrid,
  BundleSpan,
} from './AppointmentDetailsViewStyle';
import { useHistory } from 'react-router-dom';
import defaultSideImage from '../assets/imgs/clinician1.png';
import AlertMsg from '../utils/Alert';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CreateNewAppointment from '../common/CreateNewAppointment';
import CalenderCreateNewAppointments from '../common/CalenderCreateNewAppointments';
import AlertDialog from '../common/AlertDialog';
import userIcon from '../assets/imgs/download.png';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Collapse from '@material-ui/core/Collapse';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import InputLabel from '@material-ui/core/InputLabel';
import ErrorIcon from '@material-ui/icons/Error';
import Popover from '@material-ui/core/Popover';
import moment from 'moment';
import { APPOINTMENT_TYPES, DISCIPLINES } from '../utils/enums';
import { calculateAge } from '../utils/timUtils';
import PatientDetailClinician from '../clinician/Patients/patientDetail';
import HTMLParser from 'html-react-parser';
import { Button as SimpleBtn } from '../components/atoms';
import NotesView from '../common/Notes/NotesView';
import CommentsView from '../containers/billing/billing_list/InvoicesDetails/CommentsView';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const AppointmentDetailsView = (props) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [patientRecords, setPatientRecords] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sheduleOpen, setSheduleOpen] = useState(false);
  const [clinicianLoader, setClinicianLoader] = useState(false);
  const [clinicianDetails, setClinicianDetails] = useState(null);
  const [clinicians, setClinicians] = useState(null);
  const [openCancel, setOpenCancel] = useState(false);
  const [locationKeys, setLocationKeys] = useState([]);
  const [createAppointmentType, setCreateAppointmentType] = useState([]);
  const [recommendedClinicians, setRecommendedClinicians] = useState([]);
  const [openAssessment, setAssessmentAccordian] = useState(false);
  const [openCancelPopUp, setOpenCancelPopUp] = useState(null);
  const [penalty, setPenalty] = useState('');
  const [reasonForCancel, setReasonForCancel] = useState('');
  const history = useHistory();
  const open = Boolean(openCancelPopUp);
  const id = open ? 'simple-popover' : undefined;
  const [patientDrawer, setPatientDrawer] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [cancellationError, setCancellationError] = useState('');

  useEffect(() => {
    if (!props.clinicians) {
      props.getClinicianAction();
    }
  }, []);

  useEffect(() => {
    if (props.clinicians) {
      setClinicians([...props.clinicians]);
    }
  }, [props.clinicians]);

  useEffect(() => {
    if (
      props.clinicians &&
      appointmentDetails &&
      appointmentDetails.recommendedClinicians
    ) {
      const filterClinicians = props.clinicians.filter((c) =>
        appointmentDetails.recommendedClinicians.includes(c._id)
      );

      setRecommendedClinicians([...filterClinicians]);
    }
  }, [props.clinicians, appointmentDetails]);

  const slides = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const handleCheckIn = (id) => {
    let data = {
      checkedIn: true,
    };

    Axios.patch(`${baseURL}/api/appointment/checkin/${id}`, data, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res) {
          if (props.handleClose) {
            props.handleClose();
          }
        }

        AlertMsg('success', 'Succesfully checked in Appointment');
      })
      .catch((e) => {
        AlertMsg('error', 'Something went wrong!');
        console.log('eeeeeeee', e);
      });
  };

  useEffect(() => {
    setLoader(true);
    const uid = props.appointmentId;
    Axios.get(`${baseURL}/api/appointment/detail/${uid}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.data) {
          setAppointmentDetails(res.data.Appointment);
          setPatientRecords(res.data.patientRecord);
        }
      })
      .catch((e) => {
        setLoader(false);
        // console.log('eeeeeeee', e);
      });
  }, [props.appointmentId]);

  const getAge = (dob) => {
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const getDate = (date) => {
    var today = new Date(date);
    var d = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var m = today.getMonth() < 10 ? '0' + today.getMonth() : today.getMonth();
    var date = d + '/' + m + '/' + today.getFullYear();
    return date;
  };

  const getTime = (date) => {
    var date_ob = new Date(date);
    var hours = ('0' + date_ob.getHours()).slice(-2);
    // minutes as 2 digits (mm)
    var minutes = ('0' + date_ob.getMinutes()).slice(-2);
    var hours2 = date_ob.getHours();
    var hours2 = (hours + 24 - 2) % 24;
    var mid = 'AM';
    if (hours2 == 0) {
      //At 00 hours2 we need to show 12 am
      hours2 = 12;
    } else if (hours2 > 12) {
      hours2 = hours2 % 12;
      mid = 'PM';
    }
    var time = hours + ':' + minutes + ' ' + mid;
    return time;
  };

  const handleClickPopUp = (event) => {
    setOpenCancelPopUp(event.currentTarget);
  };

  const handleClosePopUp = () => {
    setReasonForCancel('');
    setPenalty('');
    setOpenCancelPopUp(null);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    if (props.handleClose) {
      props.handleClose();
    }
  };

  const handleSheduleOpen = () => {
    setSheduleOpen(true);
    props.handleAppointmentType('reschedule');
  };

  const handleCancelAppointment = () => {
    if (!reasonForCancel.trim()) {
      setCancellationError('Please enter reason for cancel!');
      return;
    }

    let newData = {
      status: 'cancelled',
      cancelledBy: localStorage.getItem('clinicianUID'),
      cancellingDate: new Date(),
      reasonForCancel,
      penalty,
    };

    Axios.patch(
      `${baseURL}/api/appointment/${appointmentDetails._id}`,
      newData,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        if (res.data) {
          // setOpenCancel(false);
          // setSheduleOpen(false);
          // setAnchorEl(null);
          if (props.refresh) {
            props.refresh();
          }

          handleClosePopUp();
          if (props.handleClose) {
            props.handleClose();
          }
        }
      })
      .catch((e) => {
        AlertMsg('error', 'Error Canceling Appointment');
      });
  };

  const handleEditRecord = () => {
    let userType = localStorage.getItem('__r');
    if (userType == 'clinician') {
      history.push(
        `/clinician/appointments/editPatientRecord/${appointmentDetails._id}`
      );
    } else {
      history.push(
        `/admin/appointments/editPatientRecord/${appointmentDetails._id}`
      );
    }
  };

  const clinicianAddress = (address) => {
    if (address.title) {
      return address.title;
    } else {
      return `${address.address1} ${address.city} ${
        address.zip && address.zip
      } ${address.country.name}
    `;
    }
  };

  const generateStatus = (status) => {
    let textcolor = '#000';
    let bgcolor = '#FFAB09';
    if (status === 'cancelled' || status === 'missed') {
      textcolor = '#fff';
      bgcolor = '#DA1A1A';
    } else if (status === 'completed') {
      bgcolor = '#01BC05';
    }
    return (
      <StatusGrid color={textcolor} bgcolor={bgcolor}>
        {status}
      </StatusGrid>
    );
  };

  const togglePatientDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setPatientDrawer(open);
  };

  const renderPrescription = (p) => {
    return p.length > 1 ? (
      <ol type='a' className={classes.ol}>
        {p.map((presc, i) => (
          <li>
            <span className={classes.olSpan}>
              {`${presc.packName} - ${presc.manufacturer} (${presc.activeIngredient}), ${presc.strength}  ${presc.strengthUnit}, ${presc.quantityBase}`}
            </span>
          </li>
        ))}
      </ol>
    ) : (
      <ul className={classes.ul}>
        {p.map((presc, i) => (
          <li>
            {`${presc.packName} - ${presc.manufacturer} (${presc.activeIngredient}), ${presc.strength}  ${presc.strengthUnit}, ${presc.quantityBase}`}
          </li>
        ))}
      </ul>
    );
  };

  const rescheduleBtn = () => (
    <SimpleBtn
      style={{ width: 'max-content' }}
      text='Reshedule Appointment'
      onClick={() =>
        props.handleAppointmentBooking({
          ...appointmentDetails,
          type: 'reschedule',
        })
      }
    />
  );

  const cancelBtn = () => (
    <SimpleBtn
      style={{ width: 'max-content' }}
      text='Cancel Appointment'
      onClick={() => {
        setOpenCancelPopUp(true);
      }}
    />
  );

  const renderActionBtns = (status) => {
    if (status === 'confirmed') {
      return (
        <Grid xs={10} spacing={2} justify='flex-end' container item>
          <Grid item>{rescheduleBtn()}</Grid>
          <Grid item>{cancelBtn()}</Grid>
        </Grid>
      );
    } else if (status === 'cancelled' || status === 'missed') {
      return (
        <Grid xs={10} spacing={2} justify='flex-end' container item>
          <Grid item>{rescheduleBtn()}</Grid>
        </Grid>
      );
    } else if (status === 'completed' || status === 'ended') {
      return (
        <Grid xs={10} spacing={2} justify='flex-end' container item>
          <Grid item>
            <SimpleBtn
              style={{ width: 'max-content' }}
              text='Schedule Follow-up'
              onClick={() =>
                props.handleAppointmentBooking({
                  ...appointmentDetails,
                  type: 'follow-up',
                })
              }
            />
          </Grid>
          <Grid item>
            <SimpleBtn
              style={{ width: 'max-content' }}
              text='Refer Patient'
              onClick={() =>
                props.handleAppointmentBooking({
                  ...appointmentDetails,
                  type: 'init-refferal',
                })
              }
            />
          </Grid>
          {!props.isAdmin && (
            <Grid item>
              <SimpleBtn
                style={{ width: 'max-content' }}
                text='Edit/Update'
                onClick={(evt) => handleEditRecord()}
              />
            </Grid>
          )}
        </Grid>
      );
    } else if (status === 'waiting') {
      return (
        <Grid xs={10} spacing={2} justify='flex-end' container item>
          <Grid item>
            <SimpleBtn
              style={{ width: 'max-content' }}
              text='Book Appointment'
              onClick={() =>
                props.handleAppointmentBooking({
                  ...appointmentDetails,
                  type: 'waitlist',
                })
              }
            />
          </Grid>
          <Grid item>{cancelBtn()}</Grid>
        </Grid>
      );
    }
  };

  const renderTitle = () => {
    let title = 'Upcoming';
    const { status, startTime } = appointmentDetails;

    if (new Date(startTime) < new Date()) {
      title = 'Previous';
    }

    if (status === 'waiting') {
      title = 'Waitlist';
    } else if (status === 'reffered' || status === 'reffered-waiting') {
      title = 'Referred';
    }
    return title;
  };

  const renderName = (client) => `${client.firstName} ${client.lastName}`;

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role='presentation'
      style={{ width: '800px' }}
    >
      {loader || !appointmentDetails ? (
        <Grid container style={{ paddingLeft: '45%', paddingTop: '10%' }}>
          <Loader />
        </Grid>
      ) : (
        <Container>
          <Grid container>
            <Grid xs={12}>
              <Grid>
                <Typography variant='h4' className={classes.headtxt}>
                  {renderTitle()} Appointment
                </Typography>
              </Grid>

              <Grid
                container
                justify='space-between'
                alignItems='center'
                style={{ background: '#ffffff' }}
                wrap='nowrap'
              >
                {appointmentDetails.refferedBy && (
                  <Grid item container>
                    <Grid item>
                      <Avatar className={classes.img} src={userIcon} />
                    </Grid>
                    <Grid className={classes.txt} item>
                      <Typography>Referred By</Typography>
                      <Typography className={classes.rname}>
                        {appointmentDetails.refferedBy}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <Grid container justify='flex-end' item>
                  <Grid item>
                    <Typography variant='h5' classname={classes.right}>
                      ID:{' '}
                      {`APT-${moment(appointmentDetails.createdAt).format(
                        'YY'
                      )}-${appointmentDetails.chartId}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {appointmentDetails.clinicianId && (
                <CardGrid container>
                  {appointmentDetails.category === 'bundle' && (
                    <BundleSpan>Bundle</BundleSpan>
                  )}
                  {appointmentDetails.category === 'corporate' && (
                    <BundleSpan>Corporate</BundleSpan>
                  )}
                  <Grid xs={12} container>
                    <Grid>
                      <img
                        className={classes.img2}
                        src={appointmentDetails.clinicianId.avatar || userIcon}
                      />
                    </Grid>

                    <Grid className={classes.txt2}>
                      <Typography className={classes.cname}>
                        {`${appointmentDetails.clinicianId.firstName} ${appointmentDetails.clinicianId.lastName}`}
                      </Typography>
                      <Typography>
                        <CalendarTodayIcon />
                        {moment(appointmentDetails.startTime).format(
                          'YYYY-MM-DD'
                        )}
                      </Typography>
                      <Typography>
                        <AccessTimeIcon />
                        {moment(appointmentDetails.startTime).format('hh:mm a')}
                      </Typography>
                      <Typography>
                        <LocationOnIcon />
                        {appointmentDetails.clinicAddress &&
                          clinicianAddress(appointmentDetails.clinicAddress)}
                      </Typography>
                      <Typography>
                        <AddBoxIcon />
                        Concern Type:{' '}
                        {appointmentDetails?.appointmentType?.type}
                      </Typography>

                      {appointmentDetails.status === 'cancelled' && (
                        <Grid className={classes.txt4}>
                          <Typography>
                            <AccountCircleIcon />
                            Cancelled By:{' '}
                            {appointmentDetails.cancelledBy &&
                              appointmentDetails.cancelledBy.email}
                          </Typography>

                          <Typography>
                            <AccessTimeIcon />
                            TimeStamp:{'  '}
                            {moment(appointmentDetails.cancellingDate).format(
                              'D-MM-YYYY'
                            )}
                          </Typography>

                          <Typography>
                            <EventBusyIcon />
                            Reason For Cancellation:{'  '}{' '}
                            {appointmentDetails.reasonForCancel}
                          </Typography>
                          <Capitalize>
                            <ErrorIcon />
                            Penalty:{'  '}
                            {appointmentDetails.penalty}
                          </Capitalize>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </CardGrid>
              )}

              <ActionGrid justify='space-between' container>
                <Grid xs={2} item>
                  {generateStatus(appointmentDetails.status)}
                </Grid>
                {!props.hideActions &&
                  renderActionBtns(appointmentDetails.status)}
              </ActionGrid>

              <Popover
                open={open}
                openCancelPopUp={openCancelPopUp}
                onClose={handleClosePopUp}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
              >
                <Grid container style={{ margin: '15px', maxWidth: '500px' }}>
                  <Grid>
                    <Grid xs={12}>
                      <Typography className={classes.cancel}>
                        Cancel Appointment
                      </Typography>
                    </Grid>

                    <Grid style={{ marginTop: '20px' }}>
                      <Typography className={classes.cancelTxt}>
                        You're about to cancel an appointment. Please enter the
                        reason for this cancellation below and whether this
                        cancellation is penalized or not. If you feel you've
                        made a mistake, the click on "Abort" to go back to the
                        previous screen without making any changes.
                      </Typography>
                    </Grid>

                    <Grid style={{ marginTop: '10px' }}>
                      <Typography>Reason*</Typography>
                      <Grid>
                        <textarea
                          rows='4'
                          cols='70'
                          onChange={(e) => {
                            setCancellationError('');
                            setReasonForCancel(e.target.value);
                          }}
                          value={reasonForCancel}
                        />
                      </Grid>
                      {cancellationError && (
                        <div className='text-red-400 text-left'>
                          {cancellationError}
                        </div>
                      )}
                    </Grid>

                    <Grid style={{ marginTop: '10px' }}>
                      <Typography>Penalty*</Typography>
                      <Grid>
                        <FormControl className={classes.inputsty}>
                          <InputLabel id='demo-simple-select-label'>
                            Select(Yes,No,Waived)
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={penalty}
                            onChange={(e) => setPenalty(e.target.value)}
                          >
                            <MenuItem value={'yes'}>Yes</MenuItem>
                            <MenuItem value={'no'}>No</MenuItem>
                            <MenuItem value={'waived'}>Waived</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid style={{ marginTop: '10px' }}>
                      <Grid item xs={12}>
                        <Button
                          onClick={handleClosePopUp}
                          className={classes.abort}
                        >
                          Abort
                        </Button>

                        <Button
                          className={classes.conti}
                          onClick={handleCancelAppointment}
                        >
                          Continue
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Popover>

              {/* ////////previous appoitment button */}

              {/* <Grid  container  style={{ background: '#e6f3fa'}}>
                <Grid item xs={12}>
               <Button className={classes.btn}>
                  Confirmed
               </Button>
               <Button className={classes.btn2}>
                 Edit/Update
               </Button>
               <Button className={classes.btn2}>
                  Refer Patient
               </Button>
               <Button className={classes.btn2}>
                  Shedule Follow-up
               </Button>
               </Grid>
         </Grid> */}

              {appointmentDetails.clientId.map((p) => (
                <Grid
                  container
                  key={`${p._id}`}
                  style={{
                    marginTop: '20px',
                    background: 'rgba(156, 134, 87, 0.2)',
                  }}
                >
                  <Grid xs={12}>
                    <Avatar
                      className={classes.img3}
                      src={p.avatar || userIcon}
                    />

                    <Grid className={classes.txt3}>
                      <Typography className={classes.pname}>
                        {`${p.firstName} ${p.lastName}`}
                      </Typography>
                      <Capitalize className={classes.page}>
                        {p.gender ? p.gender : 'age'}:{' '}
                        {calculateAge(new Date(p.dob))}
                      </Capitalize>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    style={{ marginLeft: '15px', marginRight: '15px' }}
                  >
                    <Grid xs={12}>
                      <Button
                        onClick={() => {
                          setPatientDrawer(true);
                          setPatientId(p._id);
                        }}
                        className={classes.btn3}
                      >
                        View Patient Record
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ))}

              {patientRecords.map((p) => (
                <Grid
                  container
                  style={{ marginTop: '15px' }}
                  key={`patientRecords_${p._id}`}
                >
                  <div className={classes.assesmentType}>
                    <Grid spacing={3} direction='column'>
                      <Grid
                        direction='row'
                        alignItems='center'
                        spacing={3}
                        container
                        item
                      >
                        <Typography className={classes.txtName}>
                          {`${p.clientId.firstName} ${p.clientId.lastName}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>

                  <Grid container style={{ textAlign: 'left' }}>
                    <Grid xs={2}>
                      <div
                        style={{
                          borderRight: '2px solid #7070701a',
                          height: '100%',
                          marginRight: 15,
                        }}
                      >
                        <Typography
                          variant='h6'
                          style={{ color: '#003265', lineHeight: 1 }}
                        ></Typography>
                      </div>
                    </Grid>
                    <Grid xs={10}>
                      <div className={classes.styleContentBottom}>
                        <Typography variant='h6' className={classes.textDetail}>
                          DIAGNOSIS:
                        </Typography>
                        {p.diagnosis.length > 1 ? (
                          <ol type='a' className={classes.ol}>
                            {p.diagnosis.map((d) => (
                              <li>
                                <span className={classes.olSpan}>
                                  {`${d.code} ${d.name}`}
                                </span>
                              </li>
                            ))}
                          </ol>
                        ) : (
                          <ul className={classes.ul}>
                            {p.diagnosis.map((d) => (
                              <li>{`${d.code} ${d.name}`}</li>
                            ))}
                          </ul>
                        )}
                      </div>
                      {!props.isAdmin && (
                        <div className={classes.styleContentBottom}>
                          <Typography
                            variant='h6'
                            className={classes.textDetail}
                          >
                            NOTES:
                          </Typography>
                          <Typography variant='subtitle'>
                            {HTMLParser(p.notes)}
                          </Typography>
                        </div>
                      )}
                      <div className={classes.styleContentBottom}>
                        <Typography variant='h6' className={classes.textDetail}>
                          PRESCRIPTION:
                        </Typography>
                        {renderPrescription(p.prescriptions)}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              ))}

              {appointmentDetails.assessmentIds.length > 0 && (
                <Grid>
                  <Grid style={{ marginTop: '20px' }}>
                    <Typography className={classes.headtxt2}>
                      Assessment Answers
                    </Typography>
                  </Grid>

                  <Grid style={{ marginTop: '10px' }}>
                    <List
                      component='nav'
                      aria-labelledby='nested-list-subheader'
                      className={classes.root1}
                    >
                      <ListItem
                        style={{ backgroundColor: '#e1e3e1' }}
                        button
                        onClick={() => setAssessmentAccordian(!openAssessment)}
                      >
                        <Grid
                          justify='space-between'
                          alignItems='center'
                          wrap='nowrap'
                          container
                        >
                          <Grid item>
                            <Typography className={classes.assBtn}>
                              Click To View Assessments
                            </Typography>
                          </Grid>
                          {appointmentDetails.clientId[0] && (
                            <Grid
                              justify='flex-end'
                              alignItems='center'
                              spacing={3}
                              container
                            >
                              <Grid item>
                                {renderName(
                                  appointmentDetails.clientId[
                                    appointmentDetails.clientId.length - 1
                                  ]
                                )}
                              </Grid>
                              <Grid item>
                                <Avatar
                                  className={classes.assimg}
                                  src={
                                    appointmentDetails.clientId[
                                      appointmentDetails.clientId.length - 1
                                    ].avatar || userIcon
                                  }
                                />
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </ListItem>

                      <Collapse
                        in={openAssessment}
                        timeout='auto'
                        unmountOnExit
                      >
                        {appointmentDetails.assessmentIds.map((assessment) => (
                          <Grid>
                            <Title>{assessment.assessmentId.title}</Title>
                            {assessment.answers.map((answer) => (
                              <List
                                component='div'
                                disablePadding
                                key={`${answer._id}`}
                              >
                                <ListItem button className={classes.nested}>
                                  <ListItemText>
                                    <Typography className={classes.que}>
                                      {answer.questionId.question}
                                    </Typography>
                                    <Typography className={classes.ans}>
                                      {answer.text}
                                    </Typography>
                                  </ListItemText>
                                </ListItem>
                              </List>
                            ))}
                          </Grid>
                        ))}
                        {appointmentDetails.assessmentNote && (
                          <Grid>
                            <Title>Note: </Title>
                            <List component='div' disablePadding>
                              <ListItem button className={classes.nested}>
                                <ListItemText>
                                  <Typography className={classes.ans}>
                                    {HTMLParser(
                                      appointmentDetails.assessmentNote
                                    )}
                                  </Typography>
                                </ListItemText>
                              </ListItem>
                            </List>
                          </Grid>
                        )}
                      </Collapse>
                    </List>
                  </Grid>
                </Grid>
              )}

              {(appointmentDetails.recommendedClinicians.length > 0 ||
                appointmentDetails.preferredClinician) && (
                <Grid>
                  <Grid style={{ marginTop: '15px' }}>
                    <Typography className={classes.headtxt2}>
                      Matched Clinicians
                    </Typography>
                  </Grid>

                  <Grid container className={classes.clinicians}>
                    <Grid xs={12}>
                      <List component='div' disablePadding>
                        {appointmentDetails.preferredClinician && (
                          <ListItem
                            key={`${appointmentDetails.preferredClinician._id}`}
                            className={classes.nested}
                          >
                            <Avatar
                              src={
                                appointmentDetails.preferredClinician.avatar ||
                                userIcon
                              }
                            />
                            <ListItemText>
                              <Typography className={classes.cname2}>
                                {`${appointmentDetails.preferredClinician.firstName} ${appointmentDetails.preferredClinician.lastName} (Preferred)`}
                              </Typography>
                            </ListItemText>
                            <ListItemText>
                              <Typography className={classes.cprof}>
                                {
                                  DISCIPLINES[
                                    appointmentDetails.preferredClinician
                                      .discipline
                                  ]
                                }
                              </Typography>
                            </ListItemText>
                          </ListItem>
                        )}

                        {appointmentDetails.recommendedClinicians.map((c) => (
                          <ListItem key={`${c._id}`} className={classes.nested}>
                            <Avatar src={c.avatar || userIcon} />
                            <ListItemText>
                              <Typography className={classes.cname2}>
                                {`${c.firstName} ${c.lastName}`}
                              </Typography>
                            </ListItemText>
                            <ListItemText>
                              <Typography className={classes.cprof}>
                                {DISCIPLINES[c.discipline]}
                              </Typography>
                            </ListItemText>
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid style={{ marginTop: '32px', marginBottom: "32px" }} item xs={12}>
                <Typography style={{ marginTop: '32px' }}>
                  Reception Notes
                </Typography>
                {/* <NotesView appointmentId={appointmentDetails._id} /> */}
                <CommentsView appointmentId={appointmentDetails._id} />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
      <PatientDetailClinician
        togglePatientDrawer={togglePatientDrawer}
        patientDrawer={patientDrawer}
        patientId={patientId}
      />
    </div>
  );

  return (
    <div>
      <Drawer
        anchor={'right'}
        open={props.open}
        onClose={toggleDrawer('right', false)}
      >
        {list('right')}
      </Drawer>

      <AlertDialog
        open={openCancel}
        title='Cancel Appointment'
        description={'You are about to cancel appontment'}
        handleClose={() => setOpenCancel(false)}
        handleSubmit={() => handleCancelAppointment()}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  clinicians: state.getClinician.getClinicianData,
  loading: state.getClinician.request,
});

const mapDispatchToProps = (dispatch) => ({
  getClinicianAction: () => dispatch(getClinicianAction()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentDetailsView);
