/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable import/prefer-default-export */
import {
  ADD_P1OF2_REQUEST,
  GET_P1OF2_REQUEST,
  GET_BY_ID_P1OF2_REQUEST,
  UPDATE_P1OF2_REQUEST,
} from "../AdminConstent";

export const addP1of2Action = (data) => ({
  type: ADD_P1OF2_REQUEST,
  endpoint: "/admin/health_assessment",
  body: data,
});

export const getP1of2Action = () => ({
  type: GET_P1OF2_REQUEST,
  endpoint: "/admin/health_assessment/complete_assessment",
});

export const getByIdP1of2Action = (id) => ({
  type: GET_BY_ID_P1OF2_REQUEST,
  endpoint: `/admin/health_assessment/${id}`,
});
export const updateP1of2Action = (id, data) => ({
  type: UPDATE_P1OF2_REQUEST,
  endpoint: `/admin/health_assessment/${id}`,
  body: data,
});

export const getP1of2UserAction = () => ({
  type: GET_P1OF2_REQUEST,
  endpoint: "/api/health_assessment/complete_assessment",
});
