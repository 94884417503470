import React, { useEffect, useState } from 'react';
import NativeSelect from '@material-ui/core/NativeSelect';
import Axios from 'axios';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';
import Loader from '../components/Loader/Loader';
import AlertMsg from '../utils/Alert';
import { Drawer, Grid, Typography, Button } from '@material-ui/core';
import SliderPointLine from '../assets/imgs/slider verticle line.svg';
import SliderPointIcon from '../assets/imgs/slider point.svg';
import { baseURL } from '../utils/axios';
import Layout from '../admin/components/common/AdminLayout';
import './clinician.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import PatientDetailClinician from './Patients/patientDetail';
import useStyles from './EditPatientRecord.styles';
import AppointmentUserSession from './AppointmentUserSession';
import moment from 'moment';
import PropTypes from 'prop-types';

const isSessionTimeValid = (startTime) =>
  Math.ceil(moment.duration(moment().diff(moment(startTime))).asHours()) < 25;

EditPatientRecord.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default function EditPatientRecord(props) {
  const classes = useStyles();
  const [appointmentId, setAppointmentId] = React.useState('');
  const [clientId, setClientId] = useState('');
  const [appointment, setAppointment] = useState('');
  const [assessment, setAssessment] = useState('');
  const [medicines, setMedicines] = useState([]);
  const [isLoaded, setIsloaded] = React.useState(true);
  const [error, setError] = useState(true);
  const [initialFormData, setInitialFormData] = useState([]);
  const [patientDrawer, setPatientDrawer] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [isSessionValid, setIsValidateSession] = useState(true);

  useEffect(() => {
    setIsloaded(true);
    const url = window.location.href;
    const id = url.substring(url.lastIndexOf('/') + 1);
    setAppointmentId(id);
    getAppointmentDetails(id);
  }, []);

  const getAppointmentDetails = (id) => {
    Axios.get(`${baseURL}/api/appointment/detail/${id}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        var tempData = [];
        if (res.data) {
          console.log(res.data);
          const { Appointment, patientRecord } = res.data;
          setAppointment(Appointment);
          setIsValidateSession(
            isSessionTimeValid(res.data.Appointment.startTime)
          );
          if (patientRecord && patientRecord.length) {
            for (let val of patientRecord) {
              let data = {
                _id: val._id,
                appointmentId: val.appointmentId,
                clientId: val.clientId._id,
                client: val.clientId,
                notes: val.notes,
                diagnosis: val.diagnosis,
                sessionCompleted: val.sessionCompleted,
                originallyComVal: val.sessionCompleted,
                cost: '',
                medication: [],
                status: isSessionTimeValid(res.data.startTime)
                  ? 'completed'
                  : '',
              };
              let prescriptions = [];
              if (val.prescriptions && val.prescriptions.length) {
                for (let presc of val.prescriptions) {
                  if (presc.active) {
                    prescriptions.push(presc);
                    data.medication.push(presc);
                  }
                }
              }
              data.prescription = prescriptions;

              tempData.push(data);
            }
          } else {
            Appointment.clientId &&
              Appointment.clientId.map((val, i) => {
                let data = {
                  appointmentId: Appointment._id,
                  clientId: val._id,
                  client: val,
                  notes: '',
                  diagnosis: '',
                  medication: [],
                  prescription: [],
                  status: isSessionTimeValid(res.data.startTime)
                    ? 'completed'
                    : '',
                };
                tempData.push(data);
              });
          }
        }

        setInitialFormData(tempData);
        setIsloaded(false);
      })
      .catch((e) => {
        setIsloaded(false);
        console.log('Error in :', e);
      });
  };

  const finishSession = () => {
    let params = initialFormData.map((item) => {
      let tempObj = {
        appointmentId: item.appointmentId,
        notes: item.notes,
        diagnosis: item.diagnosis,
        clientId: item.clientId,
        cost: item.cost,
        sessionCompleted: item.sessionCompleted,
        prescription: [],
        status: isSessionValid ? 'completed' : '',
      };
      if (item._id) {
        tempObj._id = item._id;
      }
      tempObj.prescription =
        item.prescription && item.prescription.length > 0
          ? item.prescription.map((prs) => {
              return {
                appointmentId: item.appointmentId,
                clientId: item.clientId,
                medicationId: prs.medicationId,
                medication: prs.medication,
                dose: prs.dose,
                refillRequest: prs.refillRequest,
                refillAllowed: prs.refillAllowed,
                direction: prs.direction,
                active: prs.active,
                _id: prs._id,
              };
            })
          : [];
      return tempObj;
    });

    updateData(params);
  };

  const updateData = (params) => {
    const axiosConfig = {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    };

    if (params[0].notes.trim() === '') {
      AlertMsg('error', 'Notes can not be empty!');
      return;
    }

    Axios.post(`${baseURL}/api/client/addPatientRecord`, params, axiosConfig)
      .then((res) => {
        AlertMsg('success', 'Appointment record updated successfully.');
        //localStorage.removeItem('startedSessionId');
        props.history.push('/admin/appointments');
      })
      .catch((e) => {
        console.log('Error', e);
        AlertMsg('error', 'Something went wrong!');
      });
  };

  return (
    <>
      <Layout appointments={null}>
        <React.Fragment>
          {isLoaded ? (
            <Grid container style={{ paddingLeft: '45%', paddingTop: '10%' }}>
              <Loader />
            </Grid>
          ) : (
            <Grid container>
              <Grid style={{ width: '100%', padding: '12px' }}>
                {/* <div style={{ marginBottom: '24px' }}> */}

                {/* <Grid container> */}
                <AppointmentUserSession
                  appointment={appointment}
                  initialFormData={initialFormData}
                  updateInitialFormData={(data) => setInitialFormData(data)}
                />
                {/* </Grid> */}
                <Grid>
                  <Button
                    variant='contained'
                    onClick={finishSession}
                    className={classes.customBtn2}
                  >
                    {isSessionValid ? 'Complete Session' : 'Save Session'}
                  </Button>
                </Grid>
                {/* </div> */}
              </Grid>
            </Grid>
          )}
        </React.Fragment>
      </Layout>
    </>
  );
}
