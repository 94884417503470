import React from 'react';
import {
  Typography,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import useStyles from './Reports_style';

export default function WeekelyReportCard({ setReportToDownload }) {
  const classes = useStyles();

  return (
    <Grid>
      <TableContainer elevation={0} component={Paper}>
        <Table
          className={classes.table}
          aria-label='simple table'
          style={{ marginTop: '20px' }}
        >
          <TableHead style={{ backgroundColor: '#003265' }}>
            <TableRow>
              <TableCell align='left' style={{ color: '#ffffff' }}>
                WEEKLY REPORTS
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.body}>
            <TableRow>
              <TableCell>
                <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                  <Grid item>
                    <Typography className={classes.blue}>
                      Number of Clinician’s sessions per hour (Saturday to
                      Thursday) based on appointment types and status
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>

              <TableCell align='right'>
                <Grid item>
                  <Button
                    onClick={() =>
                      setReportToDownload('numberOfClinicianSessions')
                    }
                    className={classes.downbtn}
                  >
                    Download
                  </Button>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                  <Grid item>
                    <Typography className={classes.blue}>
                      Client with no diagnosis or missing clinical notes
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>

              <TableCell align='right'>
                <Grid item>
                  {/* <VisibilityIcon /> */}
                  <Button className={classes.downbtn}>Download</Button>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                  <Grid item>
                    <Typography className={classes.blue}>
                      Weekly payment Reconciliation
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>

              <TableCell align='right'>
                <Grid item>
                  <Button
                    onClick={() =>
                      setReportToDownload('paymentReconciliationWeekly')
                    }
                    className={classes.downbtn}
                  >
                    Download
                  </Button>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                  <Grid item>
                    <Typography className={classes.blue}>
                      Client’s Pending Payment Report with Date Range
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align='right'>
                <Grid item>
                  <Button
                    onClick={() =>
                      setReportToDownload('appointmentsWithPendingPayment')
                    }
                    className={classes.downbtn}
                  >
                    Download
                  </Button>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
