import React from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "../../admin/components/common/AdminLayout";
import ManageCorporation from "./manage_corporation";
import Events from "./events";
import Listing from "./listing";
import Patients from "./patients";

const Dashboard = (props) => {
  const ROUTES = [
    { path: "/admin/corporation/add-new", component: ManageCorporation },
    {
      path: "/admin/corporation/edit/:corporationId",
      component: ManageCorporation,
    },
    { path: "/admin/corporation/events/:corporationId", component: Events },
    { path: "/admin/corporation/patients/:corporationId", component: Patients },
    { path: "/admin/corporation", component: Listing },
  ];

  return (
    <Switch>
      {ROUTES.map((route, i) => (
        <Route
          key={`corporation-route-${i}`}
          path={route.path}
          render={(routerProps) =>
            React.createElement(route.component, {
              ...props,
              ...routerProps,
            })
          }
          exact
        />
      ))}
    </Switch>
  );
};

export default Dashboard;
