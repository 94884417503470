import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const StatusGrid = styled(Grid)`
  background-color: ${(props) => props.bgcolor};
  color: ${(props) => props.color};
  width: max-content;
  padding: 5px 20px;
  border-radius: 4px;
  text-transform: capitalize;
`;

const ActionGrid = styled(Grid)`
  background-color: #e6f3fa;
  padding: 20px;
`;

const Capitalize = styled(Typography)`
  text-transform: capitalize;
`;

const Title = styled(Typography)`
  font-weight: bold;
`;

const CardGrid = styled(Grid)`
  background-color: #e6f3fa;
  position: relative;
`;

const BundleSpan = styled(Grid)`
  color: #fff;
  background-color: #a3b3be;
  padding: 5px 20px;
  right: 0;
  position: absolute;
`;

export { StatusGrid, ActionGrid, Capitalize, Title, CardGrid, BundleSpan };

export default makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  root1: {
    width: '100%',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  dropdown: {
    position: 'absolute',
    top: 40,
    right: 10,
    left: 10,
    zIndex: 1,
  },
  img: {
    width: '40px',
    float: 'left',
    marginLeft: '15px',
    marginRight: '15px',
    borderRadius: '50%',
  },
  assimg: {
    width: '40px',
    float: 'right',
    marginRight: '15px',
    borderRadius: '50%',
  },
  headtxt: {
    color: '#615746',
    textAlign: 'left',
    marginBottom: '30px',
    marginTop: '30px',
  },
  txt: {
    display: 'inline',
    textAlign: 'left',
  },
  right: {
    textAlign: 'right',
    marginRight: '15px',
    color: '#615746',
  },
  img2: {
    width: '90px',
    height: '90px',
    float: 'left',
    marginLeft: '25px',
    marginRight: '15px',
    marginTop: '30px',
    color: '#615746',
    borderRadius: '50%',
  },
  img3: {
    width: '60px',
    height: '60px',
    float: 'left',
    marginLeft: '25px',
    marginRight: '25px',
    marginTop: '30px',
    borderRadius: '50%',
  },
  img4: {
    width: '50px',
    float: 'left',
    borderRadius: '50%',
  },
  txt2: {
    marginTop: '30px',
    marginLeft: '15px',
    display: 'inline-block',
    textAlign: 'left',
    color: '#003265',
  },
  txt3: {
    textAlign: 'left',
    marginTop: '30px',
  },
  txt4: {
    color: 'red',
  },
  cancel: {
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#003265',
  },
  cancelTxt: {
    color: '#003265',
    fontSize: '15px',
  },
  inputsty: {
    border: '1px solid #e5e5e5',
    borderRadius: '0',
    height: '45px',
    width: '100%',
  },
  btn: {
    backgroundColor: '#00b300',
    color: '#000',
    float: 'left',
    marginLeft: '25px',
    marginTop: '30px',
    '&:hover': {
      backgroundColor: '#00b300',
    },
  },
  btn2: {
    backgroundColor: '#003265',
    color: '#fff',
    marginBottom: '20px',
    float: 'right',
    marginLeft: '15px',
    marginRight: '20px',
    marginTop: '30px',
    '&:hover': {
      backgroundColor: '#003265',
    },
  },
  abort: {
    backgroundColor: '#003265',
    color: '#fff',
    float: 'left',
    marginLeft: '25px',
    marginTop: '30px',
    '&:hover': {
      backgroundColor: '#003265',
    },
  },
  conti: {
    backgroundColor: '#003265',
    color: '#fff',
    float: 'right',
    marginRight: '20px',
    marginTop: '30px',
    '&:hover': {
      backgroundColor: '#003265',
    },
  },
  btn3: {
    backgroundColor: '#ab9267',
    color: '#fff',
    marginBottom: '20px',
    marginTop: '30px',
    textAlign: 'center',
    width: '100%',
    '&:hover': {
      backgroundColor: '#ab9267',
    },
  },

  assBtn: {
    fontWeight: 'bold',
    fontSize: '15px',
    whiteSpace: 'nowrap',
  },
  que: {
    fontWeight: 'bold',
    fontSize: '15px',
  },
  ans: {
    fontSize: '15px',
    color: '#9da19c',
  },
  qa: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  cname: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  rname: {
    color: '#003265',
    fontSize: '15px',
    fontWeight: 'bold',
  },
  pname: {
    color: '#000',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  page: {
    fontSize: '15px',
    color: '#9da19c',
  },
  headtxt2: {
    color: '#003265',
    fontWeight: 'bold',
    fontSize: '18px',
    textAlign: 'left',
  },
  clinicians: {
    borderStyle: 'solid',
    borderColor: '#e4e8e3',
    marginTop: '15px',
    marginBottom: '20px',
  },
  cname2: {
    color: '#003265',
    fontSize: '18px',
    fontWeight: 'bold',
    marginLeft: '10px',
  },
  cprof: {
    color: '#003265',
    fontSize: '16px',
    textAlign: 'right',
  },
  assesmentType: {
    backgroundColor: '#f4f4f4',
    // marginTop: 30,
    marginTop: '15px',
    padding: 8,
  },
  styleContentBottom: {
    marginBottom: '10px',
  },
  textDetail: {
    fontSize: 15,
  },
  txtName: {
    color: '#003265',
    fontWeight: 'bold',
  },
  ol: {
    fontWeight: 'bold',
  },
  olSpan: {
    fontWeight: 'normal',
  },
  ul: {
    'list-style-type': 'none',
  },
}));
