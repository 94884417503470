import {makeStyles} from '@material-ui/core/styles';


export default makeStyles((theme) => ({
    root: {
      fontSize: "60px",
      fontFamily: "Lato",
      fontWeight: 700,
      color: "#db1919",
      marginTop: "30px",
    },
    desc: {
      fontSize: "30px",
      fontFamily: "Lato",
      fontWeight: 700,
      color: "#db1919",
      marginTop: "200px",
    },
    button: {
      marginTop: "20px",
      width: "200px",
      backgroundColor: "#db1919",
      color: "#fff",
      fontFamily: "Lato",
      fontWeight: 700,
      borderRadius: "25px",
      height: "39px",
      cursor: "pointer",
      "&:hover": {
        opacity: "50%",
      },
    },
  }));