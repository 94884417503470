import React, { useEffect, useState } from 'react';
import Layout from '../common/AdminLayout';
import axios from 'axios';
import { baseURL } from '../../../utils/axios';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { EVENT_CATEGORIES, EVENTS_AGE_CATEOGORIES } from '../../../utils/enums';
import { DATE_FORMAT } from '../../../utils/timUtils';
import { List } from '../../../components/atoms';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const EventReports = () => {
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());

  const downloadRegisteredUsersReport = (rawData) => {
    const data = rawData.map((d) => ({
      'Event ID': d.eventId?._id,
      Title: d.eventId.title,
      Status: d.eventId.registrationStillOpen ? 'Open' : 'Closed',
      Date: moment(d.eventId.date).format(DATE_FORMAT),
      Time: moment(d.eventId.startTime).format('hh:mm a'),
      Category: EVENT_CATEGORIES[d.eventId.category],
      'First Name': d.firstName,
      'Last Name': d.lastName,
      Email: d.email,
      DOB: moment(d.dob).format(DATE_FORMAT),
      'Phone Number': d.phoneNumber,
      'Registration Status': d.status === 'cancelled' ? 'Cancelled' : 'Active',
    }));
    exportToCSV(data, 'Registered Users', 'Registered Users');
  };

  const downloadAllEventsReport = (rawData) => {
    console.log('rawData', rawData);
    const data = rawData.map((d) => ({
      'Event ID': d._id,
      Title: d.title,
      Status: d.registrationStillOpen ? 'Open' : 'Closed',
      Date: moment(d.date).format(DATE_FORMAT),
      Time: moment(d.startTime).format('hh:mm a'),
      Category: EVENT_CATEGORIES[d.category],
      'Number of registered users': d.numberOfAttendees,
      'Number of cancellations': d.numberOfCancelledAttendies,
      'Number of seats available': d.totalSeats,
      IsFree: d.isFree ? 'Free' : 'Paid',
      Fee: d.isFree ? 0 : d.Fee,
      'Age group':
        EVENTS_AGE_CATEOGORIES[`${d.ageRange.min}-${d.ageRange.max}`],
      Location: d.location.type,
    }));
    exportToCSV(data, 'All Events', 'All Events');
  };

  const getData = (reportType) => {
    let queryString = '';
    if (selectedFilter._id === 'date-range') {
      queryString = `startDate=${startDate.toString()}&endDate=${endDate.toString()}`;
    }

    axios
      .get(`${baseURL}/api/event/report?type=${reportType}&${queryString}`)
      .then((res) => {
        console.log('res', res);
        if (reportType === 'all-registered') {
          downloadRegisteredUsersReport(res.data);
        } else if (reportType === 'all-events') {
          downloadAllEventsReport(res.data);
        }
      })
      .catch((err) => console.log('err', err));
  };

  const exportToCSV = (data, fileName, sheetName) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blobData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(blobData, fileName + fileExtension);
  };

  const filterOptions = [
    { _id: 'all-events', name: 'All Events' },
    { _id: 'date-range', name: 'Date Range' },
  ];
  const toggleMenu = () => setOpenFilter(!openFilter);
  const onFilterChange = (val) => setSelectedFilter(val);

  return (
    <Layout>
      <div className='flex justify-start mb-4' style={{ height: 50 }}>
        <div className='w-1/5 bg-white'>
          <List
            toggleMenu={toggleMenu}
            isOpen={openFilter}
            onChange={onFilterChange}
            options={filterOptions}
            value={selectedFilter._id}
            placeholder='Select date range'
          />
        </div>
        {selectedFilter._id === 'date-range' && (
          <div className='flex flex-start'>
            <div className='bg-white ml-4 pl-4'>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={{ width: '100%' }}
                  disableToolbar
                  variant='inline'
                  format='dd/MM/yyyy'
                  margin='normal'
                  value={startDate}
                  onChange={(date) => {
                    let mDate = moment(date).startOf('day');
                    setStartDate(mDate);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div className='bg-white ml-4 pl-4'>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={{ width: '100%' }}
                  disableToolbar
                  variant='inline'
                  format='dd/MM/yyyy'
                  margin='normal'
                  value={endDate}
                  onChange={(date) => {
                    let mDate = moment(date).endOf('day');
                    setEndDate(mDate);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        )}
      </div>
      <div className='bg-white'>
        <div
          className='text-white h-11 flex items-center uppercase text-left pl-3'
          style={{ backgroundColor: '#003265' }}
        >
          Event Reports
        </div>
        <div
          className='h-11 px-3 flex items-center justify-between'
          style={{ borderBottom: '1px solid #ddd' }}
        >
          <div> All registered users for events </div>
          <div>
            <button
              className='font-semibold py-1.5 px-4 border rounded-sm'
              style={{ backgroundColor: '#cedbeb' }}
              onClick={() => getData('all-registered')}
            >
              Download
            </button>
          </div>
        </div>
        <div className='h-11 px-3 flex items-center justify-between'>
          <div> All events </div>
          <div>
            <button
              className='font-semibold py-1.5 px-4 border rounded-sm'
              style={{ backgroundColor: '#cedbeb' }}
              onClick={() => getData('all-events')}
            >
              Download
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EventReports;
