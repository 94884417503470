import {makeStyles} from "@material-ui/core";
export default makeStyles((theme) => ({
    root: {
      [theme.breakpoints.up("xs")]: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '20px',
        paddingBottom: '20px'
        // padding: "24px",
      },
      [theme.breakpoints.up("sm")]: {
        display: 'flex',
        flexDirection: 'column',
        padding: "14px",
      },
      [theme.breakpoints.up("md")]: {
        // width: "493px",
        display: 'flex',
        flexDirection: 'column',
        padding: "34px",
      },
      [theme.breakpoints.up("lg")]: {
        // width: "493px",
        padding: "44px",
        width: "49%",
      },
      // padding: "44px",
      // width: "49%",
      backgroundColor: "#ffffff",
      marginBottom: "44px",
      // marginRight: "40px",
    },
    title: {
      fontSize: "22px",
      color: "#304659",
      fontWeight: 700,
      fontFamily: "Lato",
      textAlign: "center",
      textTransform: "uppercase",
      marginBottom: "25px",
      // display: "inline-block",
    },
    subTitle: {
      fontSize: "18px",
      color: "rgba(48,70,89,40%)",
      fontWeight: 700,
      fontFamily: "Lato",
      textAlign: "center",
      textTransform: "capitalize",
      marginBottom: "57px",
    },
    body: {
      // width: "397px",
      marginBottom: "53px",
      [theme.breakpoints.up("xs")]: {
        width: "200px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "200px",
      },
      [theme.breakpoints.up("md")]: {
        width: "397px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "397px",
      },
    },
    bodyTitle: {
      fontSize: "16px",
      color: "#304659",
      fontWeight: 400,
      fontFamily: "Lato",
      textAlign: "center",
    },
    button1: {
      // width: "399px",
      height: "50px",
      borderRadius: "5px",
      backgroundColor: "#e5ded2",
      marginBottom: "10px",
      cursor: "pointer",
      "&:hover": {
        opacity: "70%",
      },
      [theme.breakpoints.up("xs")]: {
        width: "180px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "250px",
      },
      [theme.breakpoints.up("md")]: {
        width: "399px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "399px",
      },
    },
    button1Text: {
      fontSize: "18px",
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: 700,
      textAlign: "center",
    },
    button2: {
      // width: "399px",
      height: "50px",
      borderRadius: "5px",
      backgroundColor: "#304659",
      marginBottom: "10px",
      cursor: "pointer",
      "&:hover": {
        opacity: "70%",
      },
      [theme.breakpoints.up("xs")]: {
        width: "180px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "250px",
      },
      [theme.breakpoints.up("md")]: {
        width: "399px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "399px",
      },
    },
    button2Text: {
      fontSize: "18px",
      color: "#ffffff",
      fontFamily: "Lato",
      fontWeight: 700,
      textAlign: "center",
    },
  }));
  