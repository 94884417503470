/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { Grid, Typography } from "@material-ui/core";
import styles from "./TableButtons_style";

export const TableButtons = ({ title, bgColor, textColor, ...props }) => {
  const classes = styles();
  return (
    <Grid
      container
      className={classes.root}
      style={{ backgroundColor: bgColor }}
      alignItems="center"
      justify="space-around"
      {...props}
    >
      <Typography style={{ color: textColor }}>{title}</Typography>
    </Grid>
  );
};
