import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  withStyles,
  Grid,
  Typography,
  Input,
  Button,
  createStyles,
  LinearProgress,
} from '@material-ui/core';
import { baseURL } from '../utils/axios';
import { uploadDocFileAction } from '../utils/actions/client.action';
import Axios from 'axios';
import AlertMsg from '../utils/Alert';

function DocumentsUpload(props) {
  const { classes } = props;
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (props.url) {
      setUrl(props.url);
    }
  }, [props.url]);

  useEffect(() => {
    if (props.uploadDocFileData) {
      setIsLoading(false);
      setUrl(props.uploadDocFileData.url);
    }
  }, [props.uploadDocFileData]);

  const handleDocUpload = async (e) => {
    if (props.fileType.includes(e.target.files[0].type)) {
      await docFileUpload(e.target.files[0]);
    }
  };

  const docFileUpload = async (selectedFiles) => {
    const formData = new FormData();
    formData.append('document', selectedFiles);

    if (props.isUploading) {
      props.isUploading(true);
    }
    setIsLoading(true);
    const token = localStorage.getItem('ACCESS_TOKEN_PATH');
    if (props.isPatient) {
      props.uploadDocFileAction(formData, props.docType);
    } else {
      Axios.post(
        baseURL +
          `/api/user/upload?usingFormData=1&model=${props.model}&instanceId=${props.instanceId}&modelKey=${props.modelKey}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: token,
          },
        }
      )
        .then((data) => {
          if (props.isUploading) {
            props.isUploading(false);
          }
          if (props.onUpload) {
            props.onUpload(data.data);
          }
          setUrl(data.data.url);
          setIsLoading(false);
        })
        .catch((e) => {
          AlertMsg('error', e.message);
          setIsLoading(false);
        });
    }
  };

  const removeDoc = async (model, instanceId, modelKey) => {
    if (!model || !instanceId || !modelKey) {
      return;
    }

    if (props.isUploading) {
      props.isUploading(true);
    }
    setIsLoading(true);

    Axios.delete(baseURL + '/api/user/upload', {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
      data: {
        model,
        instanceId,
        modelKey,
        url,
      },
    })
      .then((data) => {
        setUrl('');
        props.onUpload(data.data);
        setIsLoading(false);
      })
      .catch((e) => {
        AlertMsg('error', e.message);
        setIsLoading(false);
      });
  };

  const triggerFile = (name) => {
    document.getElementsByName(name)[0].click();
  };

  return (
    <Grid item xs={12} style={{ marginTop: '32px' }}>
      {isLoading ? <LinearProgress /> : <> </>}
      <Typography className={classes.inputText}>
        {props.titleText ? props.titleText : 'Document Uploads'}
      </Typography>
      <Grid
        container
        style={{ marginBottom: '8px' }}
        className={classes.upload}
      >
        <Grid item xs={12}>
          {url === '' ? (
            <>
              <Typography>
                Upload file
                <Button
                  style={{ marginLeft: '16px' }}
                  onClick={() =>
                    triggerFile(
                      props.titleText ? props.titleText : 'upload_button'
                    )
                  }
                  variant='contained'
                >
                  upload
                </Button>
                <Input
                  accept='image/png, image/jpeg, application/pdf'
                  type='file'
                  name={props.titleText ? props.titleText : 'upload_button'}
                  className='icon-file'
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    handleDocUpload(e);
                  }}
                />
              </Typography>
            </>
          ) : (
            <>
              <Typography>
                Filename: {url ? url.split('/').slice(-1)[0] + '  ' : '  '}[
                <a
                  href={url}
                  rel='noopener noreferrer'
                  target='_blank'
                  variant='contained'
                  style={{
                    fontWeight: 100,
                    fontSize: 14,
                    textDecoration: 'underline',
                    color: '#A8956C',
                    cursor: 'pointer',
                  }}
                >
                  View File
                </a>
                ] [
                <button
                  variant='contained'
                  style={{
                    fontWeight: 100,
                    fontSize: 14,
                    textDecoration: 'underline',
                    color: '#A8956C',
                    cursor: 'pointer',
                    padding: '0px',
                    border: 'none',
                    backgroundColor: 'transparent',
                  }}
                  onClick={() =>
                    removeDoc(props.model, props.instanceId, props.modelKey)
                  }
                >
                  Delete File
                </button>
                ]
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

const style = createStyles({
  inputText: {
    fontSize: '22px',
    lineHeight: '28px',
    marginBottom: '15px',
    color: '#003265',
    padding: '5px',
    paddingLeft: 0,
    textAlign: 'left',
  },
  paper: {
    padding: '20px',
    height: '100%',
    boxShadow: 'none',
    borderRadius: 0,
  },
  inputTitle: {
    textAlign: 'left',
    fontSize: '13px',
    color: 'grey',
    fontWeight: '500',
    marginBottom: '4px',
  },
  inputsty: {
    border: '1px solid #e5e5e5',
    borderRadius: '0',
    height: '45px',
    width: '100%',
  },
  imgBox: {
    backgroundColor: '#003265',
    padding: '25px',
    width: 170,
    height: 170,
  },
  img: {
    borderRadius: '50%',
    width: '125px',
    height: '125px',
    objectFit: 'cover',
  },
  formControl: {
    minWidth: 120,
  },
  drag: {
    width: '90%',
    height: '100%',
    borderStyle: 'dashed',
    borderWidth: 2,
    borderColor: '#ccc',
    textAlign: 'center',
    marginLeft: 20,
    color: '#afc2df',
  },
  italicTitle: {
    fontStyle: 'italic',
    fontSize: '13px',
    textAlign: 'left',
    color: '#807c7c',
  },
  tile: {
    padding: '20px',
    backgroundColor: '#f1f4f8',
    textAlign: 'left',
  },
  upload: {
    padding: '16px',
    display: 'flex',
    border: '2px solid #C4C4C4',
    textAlign: 'left',
  },
  errorText: {
    color: 'red',
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: 16,
  },
});

DocumentsUpload.propTypes = {
  classes: PropTypes.shape({
    inputText: PropTypes.string.isRequired,
    upload: PropTypes.string.isRequired,
  }).isRequired,
  url: PropTypes.string,
  fileType: PropTypes.shape({
    includes: PropTypes.func,
  }),
  isUploading: PropTypes.bool,
  isPatient: PropTypes.bool,
  uploadDocFileAction: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  titleText: PropTypes.string,
  docType: PropTypes.string,
};

const mapStateToProps = (state) => ({
  uploadDocFileData: state.uploadDocFile.uploadDocFileData,
});

export default connect(mapStateToProps, { uploadDocFileAction })(
  withStyles(style)(DocumentsUpload)
);
