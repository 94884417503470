export default {
    "grief-support-groups": `        <p class="strong">Dear {{client_name}},</p>

    <p>
      You have successfully registered for the {{event_title}} on {{event_date}} at {{event_start_date}}
    </p>
    <p>Your Reference ID is: {{ehr_reference}}</p>
              {{#if displayAddress }}
        <p>Zoom link: {{event_vanue}}</p>
        {{else}}
        <p>
          Zoom link: You should receive an email update with the link to the
          live event
        </p>
        {{/if}}
    <h3>Zoom Instructions</h3>
    <p>To join a Zoom meeting on a computer or mobile device:</p>
    <ol>
      <li>
        Download the Zoom app from their Download Center
        <a href="https://zoom.us/download" target="_blank"
          >https://zoom.us/download</a
        >. Otherwise, you will be prompted to download and install Zoom when
        you click a join link.
      </li>
      <li>Create account</li>
      <li>
        To join, click the link that the host provided you or that you
        received in the confirmation page after you registered. If the host
        sent a registration confirmation email, the link can also be found
        there.
      </li>
    </ol>`,

    "info-for-future-interest": `<div class="title">Register your interest</div>
    <table class="info">
        <tr>
            <td class="key">Event:</td>
            <td class="value"><%=eventDetail%></td>
        </tr>
        <tr>
            <td class='key'> First Name: </td>
            <td class="value"> <%=firstName%> </td>
        </tr>
        <tr>
            <td class="key"> Last Name: </td>
            <td class="value"> <%=lastName%> </td>
        </tr>
        <tr>
            <td class="key"> Phone: </td>
            <td class="value"> <%=phoneNumber%> </td>
        </tr>
        <tr>
            <td class="key"> Email: </td>
            <td class="value"> <a href=<%=email%>><%=email%></a> </td>
        </tr>
        <tr>
            <td class="key"> Message: </td>
            <td class="value"> <%=message%> </td>
        </tr>
    </table>`,

    "paid-grief-support-groups": `<p class="strong">Dear {{client_name}},</p>

    <p>
      Thank you for your payment, it has been Approved. Your transaction ID is:{{payment_reference}}. We look forward to your attendance at {{event_title}} on {{event_date}} at {{event_start_date}}
    </p>
    <p>Your Reference ID is: {{ehr_reference}}</p>
    {{#if displayAddress }}
        <p>Zoom link: {{event_vanue}}</p>
        {{else}}
        <p>
          Zoom link: You should receive an email update with the link to the
          live event
        </p>
        {{/if}}
    <h3>Zoom Instructions</h3>
    <p>To join a Zoom meeting on a computer or mobile device:</p>
    <ol>
      <li>
        Download the Zoom app from their Download Center
        <a href="https://zoom.us/download" target="_blank"
          >https://zoom.us/download</a
        >. Otherwise, you will be prompted to download and install Zoom when
        you click a join link.
      </li>
      <li>Create account</li>
      <li>
        To join, click the link that the host provided you or that you
        received in the confirmation page after you registered. If the host
        sent a registration confirmation email, the link can also be found
        there.
      </li>
    </ol>`,

    "paid-support-groups": `<p class="strong">Dear {{client_name}},</p>

    <p>
      Thank you for your payment, it has been Approved. Your transaction ID is:{{payment_reference}}.
      We look forward to your attendance at {{event_title}} on {{event_date}} at {{event_start_date}},
      which will be facilitated by <%= clinician %> via Zoom.
    </p>

    <p>Your Reference ID is: {{ehr_reference}}</p>

    <p> Please find below the link to join the support group at the scheduled time. <p>
    <p><b>Event:</b> {{event_title}} </p>
    <p><b>Date:</b> {{event_date}}</p>
            <p><b>Time:</b> {{event_start_date}}</p>
              {{#if displayAddress }}
        <p>Join Zoom Meeting: {{event_vanue}}</p>
        {{else}}
        <p>
          Join Zoom Meeting: You should receive an email update with the link to the
          live event
        </p>
        {{/if}}
        {{#if showConsentFormMessage }}
      <p>Please ensure that the mandatory Consent Form is reviewed and signed by the parent/guardian
        of the teen in advance of the support group in order for the teen to be admitted into the group. </p>

      <p><b>Please click here to review and complete the Consent Form for your teen.</b></p>
      {{/if}}
    <p><b>*Kindly note, participation and respect for the group and for others is important. Please
      ensure that your video camera is turned on for the full duration of the group, just as you would
      be seeing others face-to-face for an in-person group. We thank you in advance.</b></p>
    <p>Please review the full Guidelines for Online {{event_title}} prior to joining the group.</p>

    <p>In order to make the online sessions process simple, we suggest the following:</p>
    <ol>
      <li>
        Download the Zoom app
        <a href="https://zoom.us/download" target="_blank"
          >https://zoom.us/download</a
        >. Otherwise, you will be prompted to download and install Zoom when
        you click a join link.
      </li>
      <li>Click on the link in your email: Before the session</li>
      <li>
        Tips for making the most of web-based sessions:
        <ul>
          <li>Sit in a confidential space where you will not be distracted </li>
          <li>Turn off all other apps or notifications on your device that may distract you</li>
          <li>Have the camera held in a way where we can make eye contact </li>
          <li>Clean your webcam</li>
          <li>Test your audio and video on Zoom prior to our session </li>
          <li>Make sure you’re in a place that has good Wi-Fi</li>
        </ul>
      </li>
    </ol>`,

    "paid-training-and-courses": ` <p class="strong">Dear {{client_name}},</p>

    <p>
      Thank you for your payment, it has been Approved. Your transaction ID is:{{payment_reference}}. We look forward to your attendance at {{event_title}} on {{event_date}} at {{event_start_date}}
    </p>
    <p>Your Reference ID is: {{ehr_reference}}</p>
    {{#if displayAddress }}
        <p>Zoom link: {{event_vanue}}</p>
        {{else}}
        <p>
          Zoom link: You should receive an email update with the link to the
          live event
        </p>
        {{/if}}
    <h3>Zoom Instructions</h3>
    <p>To join a Zoom meeting on a computer or mobile device:</p>
    <ol>
      <li>
        Download the Zoom app from their Download Center
        <a href="https://zoom.us/download" target="_blank"
          >https://zoom.us/download</a
        >. Otherwise, you will be prompted to download and install Zoom when
        you click a join link.
      </li>
      <li>Create account</li>
      <li>
        To join, click the link that the host provided you or that you
        received in the confirmation page after you registered. If the host
        sent a registration confirmation email, the link can also be found
        there.
      </li>
    </ol>`,

    "paid-workshops-and-seminars": `  <p class="strong">Dear {{client_name}},</p>

    <p>
      Thank you for your payment, it has been Approved. Your transaction ID is:{{payment_reference}}.
      We look forward to your attendance at {{event_title}} on {{event_date}} at {{event_start_date}},
      which will be facilitated by <%= clinician %> via Zoom.
    </p>

    <p>Your Reference ID is: {{ehr_reference}}</p>

    <p> Please find below the link to join the support group at the scheduled time. <p>
    <p><b>Event:</b> {{event_title}} </p>
            <p><b>Date:</b> {{event_date}}</p>
            <p><b>Time:</b> {{event_start_date}}</p>
            {{#if displayAddress }}
        <p>Join Zoom Meeting: {{event_vanue}}</p>
        {{else}}
        <p>
          Join Zoom Meeting: You should receive an email update with the link to the
          live event
        </p>
        {{/if}}
        {{#if showConsentFormMessage }}
      <p>Please ensure that the mandatory Consent Form is reviewed and signed by the parent/guardian
        of the teen in advance of the support group in order for the teen to be admitted into the group. </p>

      <p><b>Please click here to review and complete the Consent Form for your teen.</b></p>
      {{/if}}
    <p><b>*Kindly note, participation and respect for the group and for others is important. Please
      ensure that your video camera is turned on for the full duration of the group, just as you would
      be seeing others face-to-face for an in-person group. We thank you in advance.</b></p>
    <p>Please review the full Guidelines for Online {{event_title}} prior to joining the group.</p>

    <p>In order to make the online sessions process simple, we suggest the following:</p>
    <ol>
      <li>
        Download the Zoom app
        <a href="https://zoom.us/download" target="_blank"
          >https://zoom.us/download</a
        >. Otherwise, you will be prompted to download and install Zoom when
        you click a join link.
      </li>
      <li>Click on the link in your email: Before the session</li>
      <li>
        Tips for making the most of web-based sessions:
        <ul>
          <li>Sit in a confidential space where you will not be distracted </li>
          <li>Turn off all other apps or notifications on your device that may distract you</li>
          <li>Have the camera held in a way where we can make eye contact </li>
          <li>Clean your webcam</li>
          <li>Test your audio and video on Zoom prior to our session </li>
          <li>Make sure you’re in a place that has good Wi-Fi</li>
        </ul>
      </li>
    </ol>`,


    "support-groups": `  <p class="strong">Dear {{client_name}},</p>

    <p>
      You have successfully registered for the {{event_title}} on {{event_date}} at {{event_start_date}},
      which will be facilitated by <%= clinician %> via Zoom.
    </p>

    <p>Your Reference ID is: {{ehr_reference}}</p>

    <p> Please find below the link to join the support group at the scheduled time. <p>
    <p><b>Event:</b> {{event_title}} </p>
            <p><b>Date:</b> {{event_date}}</p>
            <p><b>Time:</b> {{event_start_date}}</p>
            {{#if displayAddress }}
        <p>Join Zoom Meeting: {{event_vanue}} </p>
        {{else}}
        <p>
          Join Zoom Meeting: You should receive an email update with the link to the
          live event
        </p>
        {{/if}}

        {{#if showConsentFormMessage }}
    <p>Please ensure that the mandatory Consent Form is reviewed and signed by the parent/guardian
      of the teen in advance of the support group in order for the teen to be admitted into the group. </p>
    <p><b>Please click here to review and complete the Consent Form for your teen.</b></p>
    {{/if}}

    <p><b>*Kindly note, participation and respect for the group and for others is important. Please
      ensure that your video camera is turned on for the full duration of the group, just as you would
      be seeing others face-to-face for an in-person group. We thank you in advance.</b></p>
    <p>Please review the full Guidelines for Online {{event_title}} prior to
      joining the group.</p>

    <p>In order to make the online sessions process simple, we suggest the following:</p>
    <ol>
      <li>
        Download the Zoom app
        <a href="https://zoom.us/download" target="_blank"
          >https://zoom.us/download</a
        >. Otherwise, you will be prompted to download and install Zoom when
        you click a join link.
      </li>
      <li>Click on the link in your email: Before the session</li>
      <li>
        Tips for making the most of web-based sessions:
        <ul>
          <li>Sit in a confidential space where you will not be distracted </li>
          <li>Turn off all other apps or notifications on your device that may distract you</li>
          <li>Have the camera held in a way where we can make eye contact </li>
          <li>Clean your webcam</li>
          <li>Test your audio and video on Zoom prior to our session </li>
          <li>Make sure you’re in a place that has good Wi-Fi</li>
        </ul>
      </li>
    </ol>`,

    "training-and-courses": `   <p class="strong">Dear {{client_name}},</p>

        <p>
          You have successfully registered for the {{event_title}} on {{event_date}} at {{event_start_date}}
        </p>
        <p>Your Reference ID is: {{ehr_reference}}</p>
				{{#if displayAddress }}
        	<p>Zoom link: {{event_vanue}}</p>
          {{else}}
	        <p>
	          Zoom link: You should receive an email update with the link to the
	          live event
	        </p>
          {{/if}}
        <h3>Zoom Instructions</h3>
        <p>To join a Zoom meeting on a computer or mobile device:</p>
        <ol>
          <li>
            Download the Zoom app from their Download Center
            <a href="https://zoom.us/download" target="_blank"
              >https://zoom.us/download</a
            >. Otherwise, you will be prompted to download and install Zoom when
            you click a join link.
          </li>
          <li>Create account</li>
          <li>
            To join, click the link that the host provided you or that you
            received in the confirmation page after you registered. If the host
            sent a registration confirmation email, the link can also be found
            there.
          </li>
        </ol>`,

    "workshops-and-seminars": ` <p class="strong">Dear {{client_name}},</p>

    <p>
      You have successfully registered for the {{event_title}} on {{event_date}} at {{event_start_date}},
      which will be facilitated by <%= clinician %> via Zoom.
    </p>

    <p>Your Reference ID is: {{ehr_reference}}</p>

    <p> Please find below the link to join the support group at the scheduled time. <p>
    <p><b>Event:</b> {{event_title}} </p>
            <p><b>Date:</b> {{event_date}}</p>
            <p><b>Time:</b> {{event_start_date}}</p>
            {{#if displayAddress }}
        <p>Join Zoom Meeting: {{event_vanue}} </p>
        {{else}}
        <p>
          Join Zoom Meeting: You should receive an email update with the link to the
          live event
        </p>
        {{/if}}

        {{#if showConsentFormMessage }}
    <p>Please ensure that the mandatory Consent Form is reviewed and signed by the parent/guardian
      of the teen in advance of the support group in order for the teen to be admitted into the group. </p>
    <p><b>Please click here to review and complete the Consent Form for your teen.</b></p>
    {{/if}}

    <p><b>*Kindly note, participation and respect for the group and for others is important. Please
      ensure that your video camera is turned on for the full duration of the group, just as you would
      be seeing others face-to-face for an in-person group. We thank you in advance.</b></p>
    <p>Please review the full Guidelines for Online {{event_title}} prior to
      joining the group.</p>

    <p>In order to make the online sessions process simple, we suggest the following:</p>
    <ol>
      <li>
        Download the Zoom app
        <a href="https://zoom.us/download" target="_blank"
          >https://zoom.us/download</a
        >. Otherwise, you will be prompted to download and install Zoom when
        you click a join link.
      </li>
      <li>Click on the link in your email: Before the session</li>
      <li>
        Tips for making the most of web-based sessions:
        <ul>
          <li>Sit in a confidential space where you will not be distracted </li>
          <li>Turn off all other apps or notifications on your device that may distract you</li>
          <li>Have the camera held in a way where we can make eye contact </li>
          <li>Clean your webcam</li>
          <li>Test your audio and video on Zoom prior to our session </li>
          <li>Make sure you’re in a place that has good Wi-Fi</li>
        </ul>
      </li>
    </ol>`
}