import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

const Dropdown = ({ placeholder, actions }) => {
  return (
    <div className='text-right ml-2 top-16'>
      <Menu
        as='div'
        className='relative inline-block text-left'
        style={{ zIndex: 10 }}
      >
        <div>
          <Menu.Button className='py-2 px-4 ml-2 rounded-md text-sm text-white bg-brand-blue whitespace-nowrap'>
            {placeholder}
            <ChevronDownIcon className='inline-block w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100' />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-100 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-100 scale-95'
        >
          <Menu.Items className='absolute right-0 w-56 mt-0.5 origin-top-right bg-brand-blue divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='text-sm whitespace-nowrap border-b border-white border-opacity-10 pb-2 pt-2 '>
              {actions.map((action) => (
                <Menu.Item>
                  <button
                    onClick={action.onClick}
                    className={`block py-2 px-6 w-full ${ action.disabled ? 'text-gray-400' : 'text-white hover:bg-brand-blue-mid '} text-left`}
                    disabled={action.disabled}
                  >
                    {action.label}
                  </button>
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default Dropdown;
