import {
  CREATE_COMPANY_REQUEST,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAILED,
  GET_COMPANIES_WITH_ASSOCIATIONS_REQUEST,
  GET_COMPANIES_WITH_ASSOCIATIONS_SUCCESS,
  GET_COMPANIES_WITH_ASSOCIATIONS_FAILED,
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAILED,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILED,
  GET_ALL_COMPANIES_REQUEST,
  GET_ALL_COMPANIES_SUCCESS,
  GET_ALL_COMPANIES_FAILED,
} from '../constant';

const initialState = {
  company: null,
  error: null,
  loading: false,
  companies: [],
  updated: false,
  createdNewCompany: false,
  count: 0,
};

const CompanyReducer = (state = initialState, action) => {
  try {
    switch (action.type) {
      case CREATE_COMPANY_REQUEST:
        return {
          loading: true,
          company: null,
          error: null,
          createdNewCompany: false,
        };
      case CREATE_COMPANY_SUCCESS:
        return {
          loading: false,
          company: { ...action.response },
          error: null,
          createdNewCompany: true,
        };
      case CREATE_COMPANY_FAILED:
        return {
          loading: false,
          company: null,
          error: action.error.response.data.reason,
          createdNewCompany: false,
        };
      case GET_COMPANIES_WITH_ASSOCIATIONS_REQUEST:
        return {
          loading: true,
          companies: null,
          error: null,
        };
      case GET_COMPANIES_WITH_ASSOCIATIONS_SUCCESS:
        return {
          loading: false,
          companies: [...action.response[0].data],
          count: action.response[0].count[0].count,
          error: null,
        };
      case GET_COMPANIES_WITH_ASSOCIATIONS_FAILED:
        return {
          loading: false,
          companies: null,
          error: action.error.response.data.reason,
        };
      case GET_COMPANY_REQUEST:
        return {
          loading: true,
          company: null,
          error: null,
        };
      case GET_COMPANY_SUCCESS:
        return {
          loading: false,
          company: { ...action.response[0] },
          error: null,
        };
      case GET_COMPANY_FAILED:
        return {
          loading: false,
          company: null,
          error: action.error.response.data.reason,
        };
      case UPDATE_COMPANY_REQUEST:
        return {
          loading: true,
          updated: false,
          error: null,
        };
      case UPDATE_COMPANY_SUCCESS:
        return {
          loading: false,
          updated: true,
          error: null,
        };
      case UPDATE_COMPANY_FAILED:
        return {
          loading: false,
          updated: false,
          error: action.error.response.data.reason,
        };
      case GET_ALL_COMPANIES_REQUEST:
        return {
          loading: true,
          companies: null,
          error: null,
        };
      case GET_ALL_COMPANIES_SUCCESS:
        return {
          loading: false,
          companies: [...action.response],
          error: null,
        };
      case GET_ALL_COMPANIES_FAILED:
        return {
          loading: false,
          companies: null,
          error: action.error.response.data.reason,
        };
      default:
        return state;
    }
  } catch (e) {
    return {
      loading: false,
      error: 'Something went wrong!',
    };
  }
};

export default CompanyReducer;
