/* eslint-disable no-unused-vars */
/* eslint-disable quotes */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
import {
  ADD_MHA_REQUEST,
  ADD_MHA_SUCCESS,
  ADD_MHA_FAILED,
  GET_MHA_REQUEST,
  GET_MHA_SUCCESS,
  GET_MHA_FAILED,
  GET_BY_ID_MHA_REQUEST,
  GET_BY_ID_MHA_SUCCESS,
  GET_BY_ID_MHA_FAILED,
  UPDATE_MHA_REQUEST,
  UPDATE_MHA_SUCCESS,
  UPDATE_MHA_FAILED,
} from "../AdminConstent";

const initialState = {
  request: true,
  error: false,
};

export const addMha = (state = [], action) => {
  switch (action.type) {
    case ADD_MHA_REQUEST:
      return {
        request: true,
      };
    case ADD_MHA_SUCCESS:
      return {
        request: false,
        addMhaData: action.response,
      };
    case ADD_MHA_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getMha = (state = [], action) => {
  switch (action.type) {
    case GET_MHA_REQUEST:
      return {
        request: true,
      };
    case GET_MHA_SUCCESS:
      return {
        request: false,
        getMhaData: action.response,
      };
    case GET_MHA_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getByIdMha = (state = [], action) => {
  switch (action.type) {
    case GET_BY_ID_MHA_REQUEST:
      return {
        request: true,
      };
    case GET_BY_ID_MHA_SUCCESS:
      return {
        request: false,
        getByIdMhaData: action.response,
      };
    case GET_BY_ID_MHA_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const updateMha = (state = [], action) => {
  switch (action.type) {
    case UPDATE_MHA_REQUEST:
      return {
        request: true,
      };
    case UPDATE_MHA_SUCCESS:
      return {
        request: false,
        updateMhaData: action.response,
      };
    case UPDATE_MHA_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getMhaUser = (state = [], action) => {
  switch (action.type) {
    case GET_MHA_REQUEST:
      return {
        request: true,
      };
    case GET_MHA_SUCCESS:
      return {
        request: false,
        getMhaUserData: action.response,
      };
    case GET_MHA_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};
