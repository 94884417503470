import { makeStyles } from '@material-ui/core/styles';

  export default makeStyles((theme) => ({
    headtxt: {
        color: '#003265',
        textAlign: 'left',
        marginBottom: '30px',
        marginTop: '20px',
        fontWeight: 500,
      },
      btnn: {
        width:'150px',
        height: "39px",
        borderRadius: "8px",
        backgroundColor: "#003265",
        "&:hover": { backgroundColor: "#003265" },
        justifyContent: "center",
        cursor: "pointer",
        fontSize: "15px",
        color: "#FFFFFF",
        lineHeight: "40px",
        fontFamily: "Lato",
        fontWeight: 600,
        textAlign: "center",
        float:'right'
      },
      gridList: {
        padding: theme.spacing(2),
        backgroundColor:'#003265',
        borderRadius: "none",
      },
       gridLists:{
        padding: theme.spacing(2),
     backgroundColor:'F9FFFF',
     borderRadius: "none",
       },
      gridListss: {
        padding: theme.spacing(2),
          backgroundColor:'#E8FCFD',
          borderRadius: "none",
        },
     
      
    }));
