import React from 'react';
import moment from 'moment';

const CheckoutAppointmentTimeDuration = ({ invoice }) => {
  const renderTime = () => {
    if (invoice?.appointmentId?.length > 0) {
      return `${moment(invoice.appointmentId[0].startTime).format('hh:mmA')}, ${
        invoice.appointmentId[0].duration
      }min`;
    } else {
      return invoice.services.reduce(
        (totalDuration, { duration }) => totalDuration + duration,
        0
      );
    }
  };

  const renderTitle = () => {
    if (invoice?.appointmentId?.length > 0) {
      return `Time & Duration`;
    } else {
      return 'Duration';
    }
  };

  return (
    <div className='pt-1'>
      <svg
        className='inline-block mr-1 w-4 h-4'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z'
        />
      </svg>
      <span className='text-gray-900'>{renderTitle()}:</span> {renderTime()}
    </div>
  );
};
export default CheckoutAppointmentTimeDuration;
