/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import {
  GET_PAYMENT_URL_REQUEST,
  GET_PAYMENT_RES_REQUEST,
  PAYPAL_REQUEST,
  eCHECK_REQUEST,
  GET_UNPAID_PAYMENT_REQUEST,
  GET_PAYMENT_REQUEST,
  MARK_PAYMENT_AS_PAID_REQUEST,
  MARK_PAYMENT_AS_PAID_CLEAR,
} from '../constant';

export const getPaymentUrlAction = (appointmentId, data) => ({
  type: GET_PAYMENT_URL_REQUEST,
  endpoint: `/api/payment/${appointmentId}/create`,
  body: data,
});

export const getPaymentResAction = (id) => ({
  type: GET_PAYMENT_RES_REQUEST,
  endpoint: `/api/payment/${id}/status`,
  // endpoint: `/api/payment/status/${id}`,
});

export const paypalAction = (id, data) => ({
  type: PAYPAL_REQUEST,
  endpoint: `/api/payment/${id}/paypal`,
  body: data,
});

export const eCheckAction = (id, data) => ({
  type: eCHECK_REQUEST,
  endpoint: `/api/payment/${id}/echeck`,
  body: data,
});

export const getUnpaidPaymentAction = () => ({
  type: GET_UNPAID_PAYMENT_REQUEST,
  endpoint: '/api/billing/unpaid',
});

export const getPaymentAction = () => ({
  type: GET_PAYMENT_REQUEST,
  endpoint: '/api/billing',
});

export const getEventPaymentUrlAction = (eventId, data) => ({
  type: GET_PAYMENT_URL_REQUEST,
  endpoint: `/api/payment/${eventId}/payment-for-event`,
  body: data,
});

export const getEventPaymentResAction = (id) => ({
  type: GET_PAYMENT_RES_REQUEST,
  endpoint: `/api/payment/${id}/event-payment-status`,
});

export const paypalEventAction = (id, data) => ({
  type: PAYPAL_REQUEST,
  endpoint: `/api/payment/${id}/event-paypal-payment`,
  body: data,
});

export const markInvoiceAsPaid = (id) => ({
  type: MARK_PAYMENT_AS_PAID_REQUEST,
  endpoint: `/api/payment/mark-complete/${id}`,
});

export const clearUpdatedInvoice = () => ({
  type: MARK_PAYMENT_AS_PAID_CLEAR,
});
