/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from 'react';
import { withStyles, Typography, Grid, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import validator from 'validator';
import { Link } from 'react-router-dom';
import { userLogin, closeOTPModal } from '../../../utils/actions/auth.action';
import Google from './GoogleOptions';
import line_2 from '../../../assets/user_images/Line 2.svg';
import BackgroundImage from './BackgroundImage';
import { ButtonLoader } from '../../Loader';
import ForgotPasswordModel from '../../../common/ForgotPassword';
import styles from './LoginFormStyle';
import VerficationModal from './OtpPopUp';
import { ButtonWithNoPadding } from '../../../components/atoms';
class SignUp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openVerficationModals: false,
      verifyOTP: true,
      email: '',
      password: '',
      forgotPasswordModel: false,
      errors: {
        email: '',
        password: '',
      },
    };
  }
  componentDidMount() {
    localStorage.removeItem('userDetails-for-otp-redirect');
    localStorage.removeItem('userDetails');
  }

  componentDidUpdate(prevProps) {
    if (this.props.showOTPModal) {
      this.setState({ openVerficationModals: true, verifyOTP: true });
      this.props.closeOTPModal();
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    const { name, value } = e.target;
    const { errors } = this.state;
    switch (name) {
      case 'email':
        errors.email = value.length > 0 ? null : 'Please Enter Email';
        break;

      case 'password':
        errors.password = value.length > 0 ? null : 'Please Enter Password';
        break;

      default:
        break;
    }
  };

  handleVerfy = () => {
    const email = validator.isEmpty(this.state.email, {
      ignore_whitespace: true,
    });
    const pass = validator.isEmpty(this.state.password, {
      ignore_whitespace: true,
    });
    const errors = { ...this.state.errors };
    if (email || pass) {
      errors.email = this.state.email.length > 0 ? null : 'Please Enter Email!';
      errors.password =
        this.state.password.length > 0 ? null : 'Please Enter Password!';
      this.setState({ errors });
      return false;
    }
    return true;
  };

  handleSubmit = () => {
    const { email, password } = this.state;
    const isVerify = this.handleVerfy();
    localStorage.setItem(
      'userDetails-for-otp-redirect',
      JSON.stringify({
        email,
        password,
      })
    );
    if (isVerify) {
      const data = {
        props: this.props,
        email,
        password,
      };
      this.props.userLogin(data);
    }
  };

  render() {
    const { classes } = this.props;
    const { email, password } = this.state;

    return (
      <>
        <Grid container justify='center' alignItems='center'>
          <Grid style={{ display: 'flex', flexDirection: 'row' }}>
            {/* <Grid style={{ position: 'absolute', scale: '96%', width: '100%', height: '100%', overflow: 'hidden'}}> */}
            <BackgroundImage />
            {/* </Grid> */}
            <Grid
              container
              justify='center'
              alignItems='center'
              className={classes.responsiveForm}
            >
              <Grid item>
                <Typography className={classes.spacious}>Sign-in</Typography>

                <Grid component='div' className={classes.avatar}>
                  <Typography className={classes.create}>Sign in or</Typography>
                  <Link to='/'>
                    <Typography className={classes.signsub}>
                      Create account here.
                    </Typography>
                  </Link>
                </Grid>

                <Google title='in' props={this.props} />

                <Grid className={classes.divider}>
                  <img src={line_2} className={classes.dividerImage} />
                  <Typography className={classes.divider_text}>OR</Typography>
                  <img src={line_2} className={classes.dividerImage} />
                </Grid>

                {/* <Grid component="div"> */}
                {/* <LoginForm /> */}

                <Grid
                  style={{ display: 'inline' }}
                  container
                  justify='center'
                  alignItems='center'
                >
                  <Typography className={classes.title}>Email</Typography>
                  <input
                    name='email'
                    value={email}
                    disableUnderline
                    fullWidth
                    type='email'
                    onChange={this.handleChange}
                    className={
                      // classes.inputtext,
                      classes.inputcontainer
                    }
                  />
                  {this.state.errors.email && (
                    <Typography className={classes.alertFont}>
                      {this.state.errors.email}
                    </Typography>
                  )}
                </Grid>

                <Grid
                  style={{ display: 'inline' }}
                  container
                  justify='center'
                  alignItems='center'
                >
                  <Typography className={classes.title}>Password</Typography>
                  <input
                    name='password'
                    type='password'
                    value={password}
                    disableUnderline
                    fullWidth
                    onChange={this.handleChange}
                    className={classes.inputcontainer}
                  />
                  {this.state.errors.password && (
                    <Typography className={classes.alertFont}>
                      {this.state.errors.password}
                    </Typography>
                  )}
                </Grid>

                <Grid className={classes.checkboxTextContainer} component='div'>
                  {/* <input
                    type="checkbox"
                    // checked={this.state.isChecked}
                    className={classes.checkboxsty}
                  />
                  <span className={classes.checkedsty}>
                    I agree to LightHouse Arabia{" "}
                    <b style={{ textDecoration: "underline" }}>
                      Conditions of Use
                    </b>{" "}
                    and{" "}
                    <b style={{ textDecoration: "underline" }}>
                      {" "}
                      Privacy Notice
                    </b>
                  </span> */}
                </Grid>
                {this.props.loginClient ? (
                  <Grid
                    container
                    alignItems='center'
                    justify='center'
                    className={classes.submit}
                  >
                    <ButtonLoader />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs style={{ textAlign: 'left' }}>
                      <ButtonWithNoPadding
                        onClick={() =>
                          this.setState({
                            openVerficationModals: true,
                            verifyOTP: false,
                          })
                        }
                        variant='body2'
                        className={classes.linksty}
                      >
                        Forgot password?
                      </ButtonWithNoPadding>
                    </Grid>
                    <Button
                      type='submit'
                      variant='contained'
                      className={classes.submit}
                      onClick={this.handleSubmit}
                    >
                      Sign in
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <VerficationModal
          open={this.state.openVerficationModals}
          onClose={() => this.setState({ openVerficationModals: false })}
          verifyOTP={this.state.verifyOTP}
          signingEmail={this.props.userloginData?.email}
          {...this.props}
        />
      </>
    );
  }
}

const connected = connect(
  (state) => ({
    loginClient: state.userLogin.request,
    showOTPModal: state.userLogin.showOTPModal,
    userloginData: state.userLogin.userloginData,
  }),
  { userLogin, closeOTPModal }
)(SignUp);
const formed = reduxForm({
  form: 'clinicalSignup',
})(connected);

export default withStyles(styles)(formed);
