import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Drawer, Avatar, Grid, Icon } from '@material-ui/core';
import {
  Button,
  ButtonWithNoPadding,
  Spinner,
} from '../../../../../components/atoms';
import { addBundle } from '../../../../../utils/actions/bundle.action';
import { ServiceBundleForm } from '../';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { Container, Heading, Row, BottomBtn, DrawerWindow } from '../style';

const defaultBundle = {
  clientId: null,
  clinicianId: null,
  cancellingUser: null,
  appointmentType: '',
  expirationDate: new Date(),
  amount: 0,
  numberOfSessions: 0,
  discount: 0,
  duration: '',
};

const CreateBundle = ({
  open,
  onClose,
  clinicians,
  clients,
  addBundle,
  createdSuccessfully,
  filterClients,
  filterClinicians,
}) => {
  const [bundle, setBundle] = useState({ ...defaultBundle });
  const [error, setError] = useState({
    clientId: '',
    clinicianId: '',
    cancellingUser: '',
    appointmentType: '',
    expirationDate: '',
    amount: '',
    numberOfSessions: '',
    discount: '',
    duration: '',
  });

  const [wholePageSpinner, setWholePageSpinner] = useState(false);

  useEffect(() => {
    setBundle({ ...defaultBundle });
  }, [open]);

  useEffect(() => {
    if (createdSuccessfully) {
      setWholePageSpinner(false);
      onClose();
    }
  }, [createdSuccessfully]);

  const onDropdownChange = (val, name) => {
    if (val) {
      setError({ ...error, [name]: '' });
    }

    setBundle({ ...bundle, [name]: val });
  };
  const onChange = (e) => {
    if (
      e.target.value &&
      typeof e.target.value !== 'object' &&
      e.target.value.trim() !== ''
    ) {
      setError({ ...error, [e.target.name]: '' });
    }
    setBundle({ ...bundle, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    let collectError = {};

    if (!bundle.clientId) {
      collectError = {
        ...collectError,
        clientId: 'Subscriber can not be empty!',
      };
    }

    if (!bundle.clinicianId) {
      collectError = {
        ...collectError,
        clinicianId: 'Clinician can not be empty!',
      };
    }

    if (!bundle.appointmentType) {
      collectError = {
        ...collectError,
        appointmentType: 'Appointment Type can not be empty!',
      };
    }

    if (!bundle.expirationDate) {
      collectError = {
        ...collectError,
        expirationDate: 'Expiration Date can not be empty!',
      };
    }

    if (!bundle.amount) {
      collectError = {
        ...collectError,
        amount: 'Price can not be empty!',
      };
    }

    if (!bundle.discount) {
      collectError = {
        ...collectError,
        discount: 'Discount can not be empty!',
      };
    }

    if (!bundle.duration) {
      collectError = {
        ...collectError,
        duration: 'Please select appointment duration!',
      };
    }

    setError({ ...collectError });

    if (Object.keys(collectError).length === 0) {
      setWholePageSpinner(true);
      addBundle(bundle);
    }
  };
  const onCancel = () => {
    onClose();
  };

  return (
    <DrawerWindow anchor='right' open={open} onClose={onClose}>
      <Spinner isOpen={wholePageSpinner} />
      <Container>
        <Grid justify='flex-end' container>
          <ButtonWithNoPadding onClick={onClose}>
            <Icon component={CancelOutlinedIcon} />
          </ButtonWithNoPadding>
        </Grid>

        <Heading align='left'>Add New Subscriber</Heading>
        <ServiceBundleForm
          filterClients={filterClients}
          clientList={clients}
          filterClinicians={filterClinicians}
          clinicianList={clinicians}
          onChange={onChange}
          onDropdownChange={onDropdownChange}
          bundle={bundle}
          error={error}
          mode='add'
        />
        <Row direction='column' container item>
          <Button text='Add New Subscriber' onClick={handleSubmit} />
          <BottomBtn container>
            <Button
              text='Cancel'
              bgcolor='rgba(112, 112, 112, 0.5)'
              color='#003265'
              onClick={onCancel}
            />
          </BottomBtn>
        </Row>
      </Container>
    </DrawerWindow>
  );
};

const mapStateToProps = (state) => ({
  createdSuccessfully: state.createBundle.createdSuccessfully,
});
const mapDispatchToProps = (dispatch) => ({
  addBundle: (data) => dispatch(addBundle(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateBundle);
