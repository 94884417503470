import { createStyles} from "@material-ui/core";

export default (theme) =>
createStyles({
  google: {
    display: "flex",
    // height: '40px',
    // width: '313px',
    // width: "313px",
    borderRadius: 0,
    backgroundColor: "#1b63eb",
    border: "1px solid #d3dce5",
    opacity: "0.5px",
    // justifyContent: "flex-start",
    marginTop: "17px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#1b63eb",
      opacity: "0.5px",
    },
    [theme.breakpoints.up("xs")]: {
      width: "200px",
      height: "30px",
      fontSize: "15px",
      // display: "flex",
      // justifyContent: "flex-start",
      // textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      width: "325px",
      height: "40px",
    },
    [theme.breakpoints.up("md")]: {
      width: "300px",
      height: "40px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "325px",
      height: "40px",
    },
  },
  googleText: {
    textTransform: "none",
    color: "#ffffff",
    // fontSize: '17px',
    fontFamily: "Lato",
    fontWeight: "800",
    textAlign: "center",
    marginTop: "7px",
    [theme.breakpoints.up("xs")]: {
      fontSize: "12px",
      marginLeft: "20px",
      lineHeight: "15px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "15px",
      marginLeft: "50px",
      lineHeight: "25px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "17px",
      marginLeft: "60px",
      lineHeight: "25px",
    },
  },
  googleIcon: {
    // width: "21px",
    // height: "21px",
    textAlign: "start",
    justifyContent: "flex-start",
    display: "flex",
    // marginTop: "11px",
    paddingLeft: "11px",
    [theme.breakpoints.up("xs")]: {
      width: "15px",
      height: "15px",
      marginTop: "9px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "15px",
      height: "15px",
      marginTop: "13px",
    },
    [theme.breakpoints.up("md")]: {
      width: "21px",
      height: "21px",
      marginTop: "10px",
    },
  },
  apple: {
    display: "flex",
    // height: '40px',
    // width: '313px',
    borderRadius: 0,
    backgroundColor: "#222222",
    justifyContent: "flex-start",
    opacity: "0.5px",
    marginTop: "17px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#222222",
      opacity: "0.5px",
    },
    [theme.breakpoints.up("xs")]: {
      width: "200px",
      height: "30px",
      fontSize: "15px",
      display: "flex",
      justifyContent: "flex-start",
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      width: "325px",
      height: "40px",
    },
    [theme.breakpoints.up("md")]: {
      width: "300px",
      height: "40px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "325px",
      height: "40px",
    },
  },
  appleicon: {
    // width: '30px',
    // height: '30px',
    textAlign: "start",
    justifyContent: "flex-start",
    display: "flex",
    // marginTop: "11px",
    paddingLeft: "8px",
    paddingTop: "3px",
    color: "#ffffff",
    [theme.breakpoints.up("xs")]: {
      width: "18px",
      height: "18px",
      marginTop: "3px",
      marginRight: "5px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "20px",
      height: "20px",
      marginTop: "6px",
      marginRight: "5px",
    },
    [theme.breakpoints.up("md")]: {
      width: "30px",
      height: "30px",
      marginTop: "2px",
    },
  },
  title: {
    // textTransform: "inherit",
    lineHeight: "20px",
    fontFamily: "Lato",
    fontWeight: "400",
    color: "#3b5266",
    // width: '313px',
    // marginTop: "30px",
    textAlign: "left",
    [theme.breakpoints.up("xs")]: {
      width: "200px",
      fontSize: "13px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "200px",
      fontSize: "18px",
    },
    [theme.breakpoints.up("md")]: {
      width: "300px",
      fontSize: "18px",
    },
  },
  inputcontainer: {
    backgroundColor: "#e1e9f0",
    border: "none",
    // padding: ('12px', '15px'),
    // width: '280px',
    // maxWidth: '313px',
    // height: "40px",
    marginTop: "8px",
    marginBottom: "12px",
    textTransform: "inherit",
    outline: 0,
    fontSize: "14px",
    fontFamily: "Lato",
    fontWeight: 400,
    color: "#304659",
    [theme.breakpoints.up("xs")]: {
      width: "190px",
      height: "35px",
      paddingLeft: "5px",
      paddingRight: "5px",
      float: "left",
    },
    [theme.breakpoints.up("sm")]: {
      width: "313px",
      height: "41px",
      paddingLeft: "5px",
      paddingRight: "5px",
    },
    [theme.breakpoints.up("md")]: {
      width: "290px",
      height: "41px",
      // paddingLeft: '5px',
      // paddingRight: '5px',
    },
    [theme.breakpoints.up("lg")]: {
      width: "313px",
      height: "41px",
      // paddingLeft: '5px',
      // paddingRight: '5px',
    },
  },
  alertFont: {
    color: "red",
    textAlign: "left",
    marginTop: "-15px",
    fontFamily: "Lato",
    fontWeight: 400,
  },
});