/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { withStyles, Grid, Typography, Input } from '@material-ui/core';
import AutoSuggest from 'react-autosuggest';
import '../../../assets/css/autosuggest.css';
import { baseURL } from '../../../utils/axios';
import Axios from 'axios';
import styles from './SearchClinicianStyle';

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.name;

// Use your imagination to render suggestions.

class SearchClinician extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: [],
      searchStart: false,
    };
  }

  handleChange = () => {
    this.setState({ searchStart: true });
  };

  onChange = (event, { newValue }) => {
    // this.props.onSelectClinician();
    this.setState({
      value: newValue,
      searchStart: true,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    Axios.get(`${baseURL}/api/user/clinicians?term=${value}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        let clinicians = res.data.filter((item) => {
          let tmpObj = item;
          tmpObj._id = item._id;
          tmpObj.avatar = item.avatar;
          tmpObj.name = item.firstName + ' ' + item.lastName;
          return tmpObj;
        });

        const filteredClinicians = clinicians.filter(
          (c) => c._id !== this.props.selectedClinicians
        );
        console.log('filteredClinicians', filteredClinicians);
        this.setState({
          suggestions: filteredClinicians,
        });
      })
      .catch((e) => {
        console.log('e', e);
        this.setState({
          suggestions: [],
        });
      });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  render() {
    const { classes } = this.props;
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Type Your Clinician....',
      value,
      onChange: this.onChange,
    };

    const renderSuggestion = (suggestion) => (
      <Grid
        container
        alignItems='center'
        className={classes.autoSuggestListContainer}
      >
        <img src={suggestion.avatar} className={classes.suggestionImage} />
        <p className={classes.suggestionText}>
          {suggestion.name}
          {suggestion.speciality ? `, ${suggestion.speciality}` : ''}
        </p>
      </Grid>
    );

    const clickSuggestionValue = (
      event,
      { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
    ) => {
      const data = {
        name: suggestion.name,
        avatar: suggestion.avatar,
        study: suggestion.study,
        speciality: suggestion.speciality,
        availabilityTime: suggestion.availabilityTime,
        cost: suggestion.cost,
        status: suggestion.status,
        _id: suggestion._id,
      };
      this.setState({
        value: suggestion.name,
        suggestions: [],
        searchStart: false,
      });
      this.props.onSelectClinician(data);
    };

    return (
      <>
        <Grid
          container
          alignItems='center'
          justify='center'
          className={classes.root}
          // style={{ }}
        >
          <Grid className={classes.container}>
            <Typography className={classes.title}>
              Do you have a specific Clinician in mind?
            </Typography>
            <Grid
              style={{
                // width: "710px",
                marginTop: '30px',
                display: 'flex',
                height:
                  this.state.searchStart && suggestions.length > 1
                    ? '180px'
                    : '100px',
              }}
            >
              <AutoSuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                onSuggestionSelected={clickSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                multiSection={false}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(SearchClinician);
