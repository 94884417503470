import React from 'react';

const TitleNav = (props) => (
  <div className='block mx-auto max-w-3xl mb-2'>
    <div className='flex flex-nowrap flex-row w-full'>
      <div className='item w-3/6'>
        <h3 className='text-2xl font-bold'>Invoice Details</h3>
      </div>
      <div className='item w-3/6 text-right'>
        <button
        onClick={props.onEdit}
          className='btn-secondary rounded-md text-sm bg-gray-200 text-gray-500 p-2 px-4 hover:bg-red-500 hover:text-white'
          type='edit'
        >
          Edit Invoice
        </button>
      </div>
    </div>
  </div>
);

export default TitleNav;
