import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import userIcon from '../../../assets/imgs/download.png';
import {
  Drawer,
  Grid,
  Typography,
  Button,
  OutlinedInput,
  InputAdornment,
  MenuItem,
  IconButton,
  FormControl,
  Select,
  TableHead,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import '../../../clinician/clinician.scss';
import EventDetail from './../../../Patient/components/Events/EventDetail';
import Axios from 'axios';
import { baseURL } from '../../../utils/axios';
import useStyles, { CellBtn } from './Events.styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import moment from 'moment';
import FilterImage from '../../../assets/clinician_image/Group 351@2x.png';
import { toTitleCase } from '../../../utils/validators';
import { getPageCount } from '../../../utils/MathUtil';
import Pagination from '@material-ui/lab/Pagination';
import RSPV from '../../../admin/components/Events/RSPV';
import AlertMsg from '../../../utils/Alert';
import Loader from '../../../components/Loader';

const Events = ({
  handleChange,
  handlePageChange,
  sort,
  eventLists,
  totalCount,
  isLoaded,
  onTextSearch,
  page,
  showEdit,
  showRSVP,
  isClinician,
  history
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [eventId, setEventId] = useState('');
  const [rspvOpen, setRspvOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState('');
  const truncate = (input) => {
    if (input.length > 20) {
      return input.substring(0, 20) + '...';
    }
    return input;
  };

  return (
    <Grid container>
      <Grid
        container
        style={{ marginTop: '16px' }}
        className='patientDetailEventTable'
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell align='left'>
                <CellBtn onClick={() => sort('title')}>Topic</CellBtn>
              </TableCell>
              <TableCell align='left'>
                <CellBtn onClick={() => sort('startTime')}>Date</CellBtn>
              </TableCell>
              <TableCell align='left'>
                <CellBtn onClick={() => sort('location.type')}>Venue</CellBtn>
              </TableCell>
              <TableCell>Clinician(s)</TableCell>
              <TableCell>RSVP status</TableCell>
              <TableCell align='left'>
                <CellBtn onClick={() => sort('totalSeats')}>
                  Seats Available
                </CellBtn>
              </TableCell>
              <TableCell align='left'>
                <CellBtn onClick={() => sort('type')}>Event Type</CellBtn>
              </TableCell>
              <TableCell align='left'></TableCell>
            </TableHead>
            {!isLoaded && (
              <TableBody component={Paper}>
                {eventLists.length > 0 ? (
                  eventLists.map((data, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>
                          <Typography component={'p'} className='topicDetail'>
                            {data.title}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'h4'}>
                            {moment(data.date).format('D/MM/YYYY')}
                          </Typography>
                          <Typography component={'p'} className=''>
                            {moment(data.startTime).format('h:mm a')} -{' '}
                            {moment(data.endTime).format('h:mm a')}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'h4'}>
                            {data.location && data.location.type == 'PHYSICAL'
                              ? 'Physical'
                              : 'Online'}
                          </Typography>
                          {data.location && data.location.type == 'PHYSICAL' ? (
                            <Typography component={'p'} className=''>
                              {data.displayAddress &&
                                truncate(data.location.address)}
                            </Typography>
                          ) : (
                            <Link to={data.location.address}>
                              <Typography component={'p'} className=''>
                                {data.displayAddress &&
                                  truncate(data.location.address)}
                              </Typography>
                            </Link>
                          )}
                        </TableCell>
                        <TableCell>
                          <Grid className={'images'}>
                            <img src={userIcon} />
                          </Grid>
                        </TableCell>
                        <TableCell>
                        {data.isLoggedInUserRegistered ? 'Registered' : 'Not Regitered'}
                        </TableCell>
                        <TableCell>
                          <Typography component={'p'}></Typography>
                          <Typography component={'h4'} className={'seatsAvail'}>
                            {data.totalSeats} Seats
                          </Typography>
                          {showRSVP && (
                            <Button
                              style={{ padding: '0px' }}
                              onClick={() => {
                                setRspvOpen(true);
                                setSelectedEvent(data);
                              }}
                            >
                              <Typography
                                component={'p'}
                                className={'seatsAvail'}
                              >
                                {data.numberOfAttendees} RSVP&apos;s
                              </Typography>
                            </Button>
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography component={'h4'}>
                            {toTitleCase(data.type)}
                          </Typography>
                          <Typography component={'p'} className=''>
                            Support Group
                          </Typography>
                        </TableCell>
                        <TableCell align='right'>
                          {showEdit && (
                            <Button
                              variant='contained'
                              href={'/admin/addevents/' + data._id}
                              className={classes.viewBtn}
                            >
                              Edit
                            </Button>
                          )}
                          <Button
                            variant='contained'
                            onClick={() => {
                              setIsOpen(true);
                              setEventId(data._id);
                            }}
                            className={classes.viewBtn}
                            style={{ marginLeft: '16px' }}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      component='th'
                      style={{ textAlign: 'center' }}
                      colSpan={8}
                      scope='row'
                    >
                      No Data Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {isLoaded && (
          <Grid justify='center' container>
            <Loader />
          </Grid>
        )}
      </Grid>
      {eventLists && eventLists.length > 0 && (
        <Grid justify='flex-end' container>
          <div className={classes.paginationRoot}>
            <Pagination
              count={getPageCount(totalCount)}
              page={page}
              onChange={handlePageChange}
              color='primary'
              shape='rounded'
            />
          </div>
        </Grid>
      )}
      <Drawer anchor='right' open={isOpen} onClose={() => setIsOpen(false)}>
        <EventDetail
          clinician={isClinician}
          eventId={eventId}
          onClose={() => setIsOpen(false)}
          history={history}
        />
      </Drawer>
      <RSPV
        open={rspvOpen}
        event={selectedEvent}
        handleClose={() => {
          setRspvOpen(false);
        }}
      />
    </Grid>
  );
};

Events.defaultProps = {
  showEdit: true,
  showRSVP: true,
  isClinician: true,
};
export default Events;
