/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import { takeEvery, put } from "redux-saga/effects";
import { post, get, updatePatch } from "../axios";
import {
  ADD_MHA_SUCCESS,
  ADD_MHA_FAILED,
  GET_MHA_SUCCESS,
  GET_MHA_FAILED,
  GET_BY_ID_MHA_SUCCESS,
  GET_BY_ID_MHA_FAILED,
  UPDATE_MHA_SUCCESS,
  UPDATE_MHA_FAILED,
  ADD_MHA_REQUEST,
  GET_BY_ID_MHA_REQUEST,
  UPDATE_MHA_REQUEST,
  GET_MHA_REQUEST,
} from "../AdminConstent";
import AlertMsg from "../Alert";

function* addMha(action) {
  try {
    const { endpoint, body } = action;
    const response = yield post(endpoint, body);
    AlertMsg("success", "Added");
    yield put({ type: ADD_MHA_SUCCESS, response });
  } catch (error) {
    AlertMsg("error", "Failed");
    yield put({ type: ADD_MHA_FAILED, error });
  }
}

function* getMhaSaga(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_MHA_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_MHA_FAILED, error });
  }
}

function* getByIdMha(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_BY_ID_MHA_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_BY_ID_MHA_FAILED, error });
  }
}

function* updateMha(action) {
  try {
    const { endpoint, body } = action;
    AlertMsg("success", "Updated");
    const response = yield updatePatch(endpoint, body);
    yield put({ type: UPDATE_MHA_SUCCESS, response });
  } catch (error) {
    AlertMsg("error", "Failed");
    yield put({ type: UPDATE_MHA_FAILED, error });
  }
}

function* getMhaUserSaga(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_MHA_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_MHA_FAILED, error });
  }
}

export function* mhaSaga() {
  yield takeEvery(ADD_MHA_REQUEST, addMha);
  yield takeEvery(GET_MHA_REQUEST, getMhaSaga);
  yield takeEvery(GET_BY_ID_MHA_REQUEST, getByIdMha);
  yield takeEvery(UPDATE_MHA_REQUEST, updateMha);
  yield takeEvery(GET_MHA_REQUEST, getMhaUserSaga);
}
