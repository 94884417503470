import React from 'react';
import CheckoutAppointmentDate from './CheckoutAppointmentDate';
import CheckoutAppointmentTimeDuration from './CheckoutAppointmentTimeDuration';
import CheckoutAppointmentAddress from './CheckoutAppointmentAddress';
import CheckoutAppointmentServiceType from './CheckoutAppointmentServiceType';

const CheckoutAppointmentMeta = ({ invoice }) => (
  <div className='item flex-1 text-sm text-gray-400'>
    <h3 className='block text-xl font-normal text-gray-900'>
      {`${invoice.clients[0].firstName} ${invoice.clients[0].lastName}`}
    </h3>
    <CheckoutAppointmentDate invoice={invoice} />
    <CheckoutAppointmentTimeDuration invoice={invoice} />
    <CheckoutAppointmentAddress invoice={invoice} />
    {invoice.appointmentId?.length > 0 && (
      <CheckoutAppointmentServiceType
        service={
          invoice.servicesData?.find(
            (s) => s._id === invoice.appointmentId[0].appointmentType
          ).type
        }
      />
    )}
  </div>
);

export default CheckoutAppointmentMeta;
