import { Select, Typography } from '@material-ui/core';
import styled from 'styled-components';

const NativeSelect = styled(Select)`
  width: 100%;
  height: ${(props) => (props.height ? props.height : '45px')};
  border: 1px solid #e5e5e5;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '0px'};
  background: #fff;
`;

const Error = styled.span`
  color: red;
  font-family: Lato;
  font-weight: 400;
  font-size: 16px;
`;

const Label = styled(Typography)`
  text-align: left;
  fontsize: 13px;
  color: grey;
  font-weight: 500;
  margin-bottom: 4px;
`;

const Required = styled.span`
  color: red;
`;

const Option = styled.option`
  color: grey;
`;

export { NativeSelect, Label, Error, Required, Option };
