export default {
  "New Client Session Booking": `<p class="ql-align-justify"><span style="color: rgb(0, 0, 0); background-color: transparent;">Dear {{client_name}},</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="color: rgb(0, 0, 0); background-color: transparent;">Thank you for choosing The LightHouse Arabia to support your wellbeing journey.</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="color: rgb(0, 0, 0); background-color: transparent;">A session has been booked as follows:</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="color: rgb(0, 0, 0); background-color: transparent;">FOR: </span><span style="color: rgb(0, 0, 0);">{{client_name}}</span></p>
  <p class="ql-align-justify"><span style="color: rgb(0, 0, 0); background-color: transparent;">CLINICIAN: </span><span style="color: rgb(0, 0, 0);">{{clinician}}</span></p>
  <p class="ql-align-justify"><span style="color: rgb(0, 0, 0); background-color: transparent;">DATE: </span><span style="color: rgb(0, 0, 0);">{{appointment_date}}</span></p>
  <p class="ql-align-justify"><span style="color: rgb(0, 0, 0); background-color: transparent;">TIME: </span><span style="color: rgb(0, 0, 0);">{{appointment_time}}</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="color: rgb(0, 0, 0); background-color: transparent;">*In order to secure your session, please access your account here {Include User Interface Link} to complete the first appointment booking process. You will be required to upload the relevant documents and make the pre-payment within the next 24 hours. You will then receive a booking confirmation that your session will go ahead as scheduled.&nbsp;</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="color: rgb(0, 0, 0); background-color: transparent;">DURATION OF FIRST APPOINTMENT</span></p>
  <p class="ql-align-justify"><span style="color: rgb(0, 0, 0); background-color: transparent;">The first session will be 50-minutes in length (face-time).</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="color: rgb(0, 0, 0); background-color: transparent;">RESCHEDULING</span></p>
  <p class="ql-align-justify"><span style="color: rgb(0, 0, 0); background-color: transparent;">If you are unable to keep this session, please contact us before </span><strong style="color: rgb(0, 0, 0); background-color: transparent;">12:00 PM noon</strong><span style="color: rgb(0, 0, 0); background-color: transparent;"> </span><strong style="color: rgb(0, 0, 0); background-color: transparent;">one day prior</strong><span style="color: rgb(0, 0, 0); background-color: transparent;"> to the session (or before </span><strong style="color: rgb(0, 0, 0); background-color: transparent;">12:00 PM noon</strong><span style="color: rgb(0, 0, 0); background-color: transparent;"> on Thursday for Saturday sessions, as we are closed on Fridays).</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="color: rgb(0, 0, 0); background-color: transparent;">We look forward to welcoming you to The LightHouse Arabia and building a successful relationship.</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="color: rgb(0, 0, 0); background-color: transparent;">If you have any questions and/or concerns, please contact us at </span><a href="mailto:reception@lighthousearabia.com" rel="noopener noreferrer" target="_blank" style="color: rgb(17, 85, 204); background-color: transparent;">reception@lighthousearabia.com</a><span style="color: rgb(0, 0, 0); background-color: transparent;"> or on T. (0)4 380 2088.</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="color: rgb(0, 0, 0); background-color: transparent;">Kind regards,</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="color: rgb(0, 0, 0); background-color: transparent;">The LightHouse Arabia Team</span></p>`,
  "Session Confirmation": `<p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">Dear {{client_name}},</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">Thank you for completing the booking requirements.&nbsp;</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">The session has been confirmed as follows:</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">FOR: </span><span style="color: rgb(0, 0, 0);">{{client_name}}</span></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">CLINICIAN: {{clinician}}</span></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">DATE: {{appointment_date}}</span></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">TIME: {{appointment_time}}</span></p>
  <p><br></p>
  <p class="ql-align-justify"><strong style="background-color: transparent; color: rgb(0, 0, 255);">In-Person / Online Scenarios</strong></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(255, 0, 255);">IN-PERSON SESSIONS</span></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">We are centrally located in Dubai at 821 Al Wasl Road, in Al Safa 2. Please click </span><a href="https://www.google.ae/maps/place/The+LightHouse+Arabia+Centre+for+Wellbeing/@25.1591491,55.2176714,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5f6a14ffffffff:0x85164695ea103105!8m2!3d25.1591491!4d55.2198601" rel="noopener noreferrer" target="_blank" style="background-color: transparent; color: rgb(0, 0, 0);">here</a><span style="background-color: transparent; color: rgb(0, 0, 0);"> {Include Google Maps Link} for our location on Google Maps. Self-parking may be done in any of the public parking spaces in front of the clinic on Al Wasl Road. Alternatively, parking spaces may be found along the back residential street, Street 2B, in front of the main entrance to Villas 2 and 3. Your Clinician can be located in Villa {Villa Number}.</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(255, 0, 255);">ONLINE SESSIONS - ZOOM INSTRUCTIONS</span></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">Please use the link below to join your Zoom session with </span><span style="color: rgb(0, 0, 0);">{{clinician}}</span><span style="background-color: transparent; color: rgb(0, 0, 0);">.</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="color: rgb(0, 0, 0);">{{clinician_meeting_id}}</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">DURATION OF FIRST APPOINTMENT</span></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">The first session will be 50-minutes in length (face-time).</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">RESCHEDULING</span></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">If you are unable to keep this session, please contact us before </span><strong style="background-color: transparent; color: rgb(0, 0, 0);">12:00 PM noon</strong><span style="background-color: transparent; color: rgb(0, 0, 0);"> </span><strong style="background-color: transparent; color: rgb(0, 0, 0);">one day prior</strong><span style="background-color: transparent; color: rgb(0, 0, 0);"> to the session (or before </span><strong style="background-color: transparent; color: rgb(0, 0, 0);">12:00 PM noon</strong><span style="background-color: transparent; color: rgb(0, 0, 0);"> on Thursday for Saturday sessions, as we are closed on Fridays).</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">We look forward to welcoming you to The LightHouse Arabia and building a successful relationship.</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">If you have any questions and/or concerns, please contact us at </span><a href="mailto:reception@lighthousearabia.com" rel="noopener noreferrer" target="_blank" style="background-color: transparent; color: rgb(17, 85, 204);">reception@lighthousearabia.com</a><span style="background-color: transparent; color: rgb(0, 0, 0);"> or on T. (0)4 380 2088.</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">Kind regards,</span></p>
  <p><br></p>
  <p class="ql-align-justify"><span style="background-color: transparent; color: rgb(0, 0, 0);">The LightHouse Arabia Team</span></p>`
}