/* eslint-disable no-unused-vars */
/* eslint-disable import/named */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Select from "react-select";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import validator from "validator";
import { signupAction } from "../utils/actions/auth.action";
import AlertMsg from "../utils/Alert";
import styles from "./SignUp_style";

const options = [
  { value: "clients", label: "Applied Behavior Analysis " },
  { value: "contacts", label: "Behavioral Health Therapist" },
  { value: "clients & contacts", label: "Chiropractor" },
  { value: "recently viewed clients", label: "Dietitian or Nutritionist" },
  { value: "inactive clients", label: "Marriage & Family Therapist(MFT)" },
];

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSortOption: null,
      fName: "",
      lName: "",
      email: "",
      password: "",
      userName: "",
      phone: "",
      specialty: "",
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  formVerification = () => {
    const isEmail = validator.isEmail(this.state.email);
    const fName = validator.isEmpty(this.state.fName, {
      ignore_whitespace: true,
    });
    const lName = validator.isEmpty(this.state.lName, {
      ignore_whitespace: true,
    });
    const pass = validator.isEmpty(this.state.password, {
      ignore_whitespace: true,
    });
    const user = validator.isEmpty(this.state.userName, {
      ignore_whitespace: true,
    });
    const phone = validator.isEmpty(this.state.phone, {
      ignore_whitespace: true,
    });
    const email = validator.isEmpty(this.state.email, {
      ignore_whitespace: true,
    });
    const specialty = validator.isEmpty(this.state.specialty, {
      ignore_whitespace: true,
    });
    if (fName || lName || pass || user || phone || email || specialty) {
      AlertMsg("error", "All Field Must Be Filled");
      return false;
    }
    // if (isEmail) {
    //   AlertMsg("error", "Please Enter Valid Email");
    //   return false;
    // }
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      fName,
      lName,
      password,
      userName,
      phone,
      email,
      specialty,
    } = this.state;
    const { history } = this.props;
    const isVerify = this.formVerification();
    if (isVerify) {
      const data = {
        firstName: fName,
        lastName: lName,
        email,
        password,
        userName,
        phone,
        specialty,
      };
      this.props.signupAction(data, history);
    }
  };

  handleSortChange = (selectedSortOption) => {
    this.setState({ specialty: selectedSortOption.label, selectedSortOption });
  };

  render() {
    const { classes } = this.props;
    const {
      selectedSortOption,
      fName,
      lName,
      password,
      userName,
      phone,
      email,
    } = this.state;
    return (
      <div className={classes.root}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <div className={classes.paper}>
            <Typography className={classes.heading}>
              Welcome to Light House.
            </Typography>
            <form className={classes.form} onSubmit={this.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    style={{}}
                    autoComplete="fname"
                    name="fName"
                    value={fName}
                    onChange={this.handleChange}
                    variant="outlined"
                    fullWidth
                    id="fName"
                    placeholder="First Name"
                    // label="First Name"
                    autoFocus
                    className={classes.root}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="lastName"
                    placeholder="Last Name"
                    name="lName"
                    value={lName}
                    onChange={this.handleChange}
                    autoComplete="lname"
                    className={classes.root}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="email"
                    placeholder="Email Address"
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                    autoComplete="email"
                    className={classes.root}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="username"
                    placeholder="Username"
                    name="userName"
                    value={userName}
                    onChange={this.handleChange}
                    autoComplete="username"
                    className={classes.root}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                  <Typography style={{ textAlign: "left", color: "#13191c" }}>
                    Make it easy for you to remember. Letters and numbers only.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="phone"
                    placeholder="Phone Number"
                    name="phone"
                    value={phone}
                    onChange={this.handleChange}
                    autoComplete="phone"
                    className={classes.root}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="password"
                    value={password}
                    onChange={this.handleChange}
                    placeholder="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    className={classes.root}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ textAlign: "left", color: "#13191c" }}>
                    What best describes your specialty?
                  </Typography>
                  <Select
                    // placeholder=""
                    value={selectedSortOption}
                    onChange={this.handleSortChange}
                    options={options}
                    className={classes.selectStyle}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign Up
              </Button>
              <Grid container justify="flex-end">
                <Grid item>
                  <Link to="/login" variant="body2" className={classes.linksty}>
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    );
  }
}

const connected = connect(null, { signupAction })(SignUp);
const formed = reduxForm({
  form: "signup",
})(connected);


export default withStyles(styles)(formed);
