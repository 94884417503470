/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
import {
  ADD_PERMISSION_REQUEST,
  ADD_PERMISSION_SUCCESS,
  ADD_PERMISSION_FAILED,
  GET_PERMISSION_REQUEST,
  GET_PERMISSION_SUCCESS,
  GET_PERMISSION_FAILED,
  GET_PERMISSION_BY_ID_REQUEST,
  GET_PERMISSION_BY_ID_SUCCESS,
  GET_PERMISSION_BY_ID_FAILED,
  UPDATE_PERMISSION_REQUEST,
  UPDATE_PERMISSION_SUCCESS,
  UPDATE_PERMISSION_FAILED,
  DELETE_PERMISSION_FAILED,
  DELETE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_REQUEST,
  GET_PERMISSION_BY_ROLE_REQUEST,
  GET_PERMISSION_BY_ROLE_SUCCESS,
  GET_PERMISSION_BY_ROLE_FAILED,
  UPDATE_PERMISSION_BY_ROLE_REQUEST,
  UPDATE_PERMISSION_BY_ROLE_SUCCESS,
  UPDATE_PERMISSION_BY_ROLE_FAILED,
} from "../AdminConstent";

const initialState = {
  request: true,
  error: false,
};

export const addPermission = (state = [], action) => {
  switch (action.type) {
    case ADD_PERMISSION_REQUEST:
      return {
        request: true,
      };
    case ADD_PERMISSION_SUCCESS:
      return {
        request: false,
        addPermissionData: action.response,
      };
    case ADD_PERMISSION_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getPermission = (state = [], action) => {
  switch (action.type) {
    case GET_PERMISSION_REQUEST:
      return {
        request: true,
      };
    case GET_PERMISSION_SUCCESS:
      return {
        request: false,
        getPermissionData: action.response,
      };
    case GET_PERMISSION_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getByIdPermission = (state = [], action) => {
  switch (action.type) {
    case GET_PERMISSION_BY_ID_REQUEST:
      return {
        request: true,
      };
    case GET_PERMISSION_BY_ID_SUCCESS:
      return {
        request: false,
        getByIdPermissionData: action.response,
      };
    case GET_PERMISSION_BY_ID_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const updatePermission = (state = [], action) => {
  switch (action.type) {
    case UPDATE_PERMISSION_REQUEST:
      return {
        request: true,
      };
    case UPDATE_PERMISSION_SUCCESS:
      return {
        request: false,
        updatePermissionData: action.response,
      };
    case UPDATE_PERMISSION_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const deletePermission = (state = [], action) => {
  switch (action.type) {
    case DELETE_PERMISSION_REQUEST:
      return {
        request: true,
      };
    case DELETE_PERMISSION_SUCCESS:
      return {
        request: false,
        deletePermissionData: action.response,
      };
    case DELETE_PERMISSION_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getPermissionByRole = (state = [], action) => {
  switch (action.type) {
    case GET_PERMISSION_BY_ROLE_REQUEST:
      return {
        request: true,
      };
    case GET_PERMISSION_BY_ROLE_SUCCESS:
      return {
        request: false,
        getPermissionByRoleData: action.response,
      };
    case GET_PERMISSION_BY_ROLE_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const updatePermissionByRole = (state = [], action) => {
  switch (action.type) {
    case UPDATE_PERMISSION_BY_ROLE_REQUEST:
      return {
        request: true,
      };
    case UPDATE_PERMISSION_BY_ROLE_SUCCESS:
      return {
        request: false,
        updatePermissionByRoleData: action.response,
      };
    case UPDATE_PERMISSION_BY_ROLE_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};
