import { createStyles } from '@material-ui/core';

export default (theme) =>
  createStyles({
    root: {
      backgroundColor: '#cfd9df',
      width: '100%',
      height: '100%',
      opacity: '20%',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    titleContainer: {
      position: 'absolute',
      // width: "800px",
      zIndex: 1,
      [theme.breakpoints.up('xs')]: {
        width: '300px',
        top: '150px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '500px',
        top: '150px',
      },
      [theme.breakpoints.up('md')]: {
        width: '800px',
        top: '202px',
      },
    },
    container: {
      position: 'absolute',
      // width: "800px",
      zIndex: 1,
      [theme.breakpoints.up('xs')]: {
        width: '300px',
        top: '80px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '500px',
        top: '90px',
      },
      [theme.breakpoints.up('md')]: {
        width: '800px',
        top: '132px',
      },
    },
    bodyContainer: {
      marginTop: '22px',
      justifyContent: 'center',
      alignSelf: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      // fontSize: "32px",
      color: '#304659',
      fontFamily: 'Lato',
      fontWeight: 700,
      textAlign: 'left',
      [theme.breakpoints.up('xs')]: {
        fontSize: '20px',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '25px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '32px',
      },
    },
    boxContainer: {
      marginTop: '23px',
      height: '137px',
      backgroundColor: '#ffffff',
      [theme.breakpoints.up('xs')]: {
        width: '300px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '500px',
      },
      [theme.breakpoints.up('md')]: {
        width: '800px',
      },
    },
    gridContainer: {
      display: 'flex',
      // [theme.breakpoints.up("xs")]: {
      //   width: "200px",
      // },
      // [theme.breakpoints.up("sm")]: {
      //   width: "800px",
      // },
      // [theme.breakpoints.up("md")]: {
      //   width: "400px",
      // },
    },
    gridflex: {
      display: 'flex',
      float: 'left',
      justify: 'left',
      justifyContent: 'flex-start',
    },
    datetext: {
      // width: "100px",
      // height: "15px",
      // fontSize: "18px",
      color: '#304659',
      fontFamily: 'Lato',
      fontWeight: 400,
      marginLeft: '10px',
      textAlign: 'center',
      [theme.breakpoints.up('xs')]: {
        fontSize: '12px',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '15px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
        marginTop: '-5px',
      },
    },
    dateicon: {
      // width: "18px",
      // height: "18px",
      [theme.breakpoints.up('xs')]: {
        width: '15px',
        height: '15px',
      },
      [theme.breakpoints.up('md')]: {
        width: '18px',
        height: '18px',
      },
    },
    mapicon: {
      width: '12px',
      height: '20px',
    },
    maptext: {
      textAlign: 'left',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      marginTop: '-5px',
      [theme.breakpoints.up('xs')]: {
        width: '100px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '150px',
      },
      [theme.breakpoints.up('md')]: {
        width: '300px',
      },
    },
    clockicon: {
      [theme.breakpoints.up('xs')]: {
        width: '15px',
        height: '15px',
      },
      [theme.breakpoints.up('md')]: {
        width: '18px',
        height: '18px',
        marginTop: '5px',
      },
    },
    clocktext: {
      color: '#304659',
      fontFamily: 'Lato',
      fontWeight: 400,
      marginLeft: '10px',
      [theme.breakpoints.up('xs')]: {
        marginLeft: '6px',
        width: '80px',
        fontSize: '12px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      [theme.breakpoints.up('md')]: {
        width: '180px',
        fontSize: '18px',
      },
    },
    peopleicon: {
      width: '20px',
      height: '17px',
    },
    peopletext: {
      width: '118px',
      height: '15px',
      fontSize: '18px',
      color: '#304659',
      fontFamily: 'Lato',
      fontWeight: 400,
      textAlign: 'center',
      marginLeft: '10px',
    },
    secondConatiner: {
      [theme.breakpoints.up('xs')]: {
        marginTop: '50px',
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: '60px',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '83px',
      },
    },
    bottomConatiner: {
      // width: "793px",
      [theme.breakpoints.up('xs')]: {
        width: '300px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '500px',
      },
      [theme.breakpoints.up('md')]: {
        width: '793px',
      },
    },
    bottomtext: {
      textAlign: 'left',
      marginTop: '13px',
      // fontSize: "20px",
      color: '#304659',
      fontFamily: 'Lato',
      fontWeight: 400,
      [theme.breakpoints.up('xs')]: {
        fontSize: '15px',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '20px',
      },
    },
  });
