/* eslint-disable no-dupe-keys */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable import/order */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/sort-comp */
/* eslint-disable no-sequences */
/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-unreachable */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable quotes */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
import React from "react";
import PropsTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  useTheme,
  makeStyles,
  withStyles,

} from "@material-ui/core/styles";
import {
  FormControlLabel,
  Radio,
  Divider,
  Checkbox,
  SwipeableDrawer,
  Grid,
  Typography,
  Box,
  ButtonGroup,
  Collapse,
  ListItem,
  ListItemIcon,
  List,
  ListItemText,
} from "@material-ui/core";
import {
  ExpandLess,
  ExpandMore,
  VideoCall,
  StarBorder,
  Phone,
  Email,
  ShareOutlined,
  LinkOff,
  LinkSharp,
  Delete,
} from "@material-ui/icons";
import clsx from "clsx";
import Select from "react-select";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import DatePicker, { registerLocale } from 'react-datepicker';
// import en from 'date-fns/locale/en';
import AddClients_Component from "./AddClients_Component";
import { getClientAction } from "../../utils/actions/client.action";
import {
  addAppointmentAction,
  getServiceAction,
  getByIdAppointmentAction,
} from "../../utils/actions/appointment.action";
import "react-datepicker/dist/react-datepicker.css";
import AlertMsg from "../../utils/Alert";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import styles from "./ShowEventDetailStyle";

// registerLocale('en', en);

const showoptions = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

class ClientAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: true,
      appointmentType: "client",
      right: false,
      clientName: "",
      clientId: "",
      allDay: false,
      dayStartDate: new Date(this.props.selectedDate),
      dayEndDate: new Date(this.props.selectedDate),
      allDayDate: new Date(this.props.selectedDate),
      dayHr: "",
      location: "",
      isIndivisual: "indivisual",
      coupleId: "",
      coupleName: "",
      selectedOption: null,
      services: [],
      selectedShowOption: null,
      expandOpen: false,
      phone: "",
      email: "",
      isShareLink: false,
      allService: [],
      amount: "",
      isLoaded: null,
    };
  }

  async componentDidMount() {
    await this.props.getClientAction();
    await this.props.getServiceAction();
    await this.props.getByIdAppointmentAction(this.props.id);
  }

  componentDidUpdate(preProps) {
    const {
      addAppointmentData,
      getAppointmentData,
      getServiceData,
    } = this.props;
    if (getServiceData !== preProps.getServiceData) {
      if (getServiceData.getServiceDataUser) {
        this.setState({ allService: getServiceData.getServiceDataUser });
      }
    }
    if (addAppointmentData !== preProps.addAppointmentData) {
      if (addAppointmentData.addAppointmentData) {
        AlertMsg("success", "Appointment Added");
      }
      if (addAppointmentData.error) {
        AlertMsg("error", "Failed to add Appointment");
      }
    }
    if (getAppointmentData !== preProps.getAppointmentData) {
      this.setState({ isLoaded: getAppointmentData.request });
      const data = getAppointmentData.getByIdAppointmentData;
      if (data) {
        console.log(data);
        this.setState({
          clientName: data.clientId && data.clientId.name,
          allDayDate: new Date(data.startTime),
          dayHr: data.duration,
          location: data.location,
          services: data.services[0] !== null ? data.services : [],
          amount: data.amount,
        });
      }
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handleSelectClient = (client) => {
    this.setState({ clientId: client._id, clientName: client.name });
  };

  handleSelectCouple = (couple) => {
    this.setState({ coupleId: couple._id, coupleName: couple.name });
  };

  handleSelectHr = (hr) => {
    this.setState({ dayHr: hr.value });
  };

  handleSelectLocation = (location) => {
    this.setState({ location: location.value });
  };

  handleSelectService = (index) => (service) => {
    const services = [...this.state.services];
    services[index] = service;
    this.setState({ services });
    console.log("index===========", index, this.state.services);
  };

  handleShowChange = (selectedShowOption) => {
    this.setState({ selectedShowOption });
  };

  handleDeleteService = (index) => () => {
    const services = [...this.state.services];
    services.splice(index, 1);
    this.setState({ services });
    console.log("sd------------", index, this.state.services);
  };

  handleSubmit = (event) => {
    const {
      appointmentType,
      clientId,
      allDayDate,
      dayHr,
      location,
      isIndivisual,
      repeateDay,
      repeatDay,
      repeateEndType,
      repeateEndDate,
      repeateEndHr,
      services,
    } = this.state;
    const data = {
      type: appointmentType === "client" ? "clientAppointment" : "other",
      clientId,
      appointmentTime: {
        dateTime: allDayDate,
        time: dayHr,
      },
      location,
      repeats: {
        Every: repeateDay,
        days: repeatDay,
        ends: repeateEndType === "after" ? repeateEndHr : repeateEndDate,
      },
      services,
      individual: isIndivisual === "indivisual",
    };
    console.log(data);
    this.props.addAppointmentAction(data);
  };

  toggleDrawer = (anchor, open) => (event) => {
    if (
      event
      && event.type === "keydown"
      && (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ [anchor]: open });
  };

  render() {
    const {
      classes,
      getAllClient,
      addAppointmentData,
      getServiceData,
    } = this.props;
    const { selectedOption } = this.state;
    const {
      allDay,
      dayEndDate,
      dayStartDate,
      allDayDate,
      isIndivisual,
      clientType,
      dayHr,
      selectedShowOption,
      clientName,
      allService,
      amount,
    } = this.state;
    const paymentOption = [
      { value: "cash", label: "Cash" },
      { value: "stripe", label: "Stripe Credit Card" },
      { value: "manualCard", label: "Manual Credit Card" },
      { value: "check", label: "Check" },
    ];
    const hrOption = [
      { value: "5min", label: "5 Min" },
      { value: "10min", label: "10 Min" },
      { value: "15min", label: "15 Min" },
      { value: "20min", label: "20 Min" },
      { value: "25min", label: "25 Min" },
    ];
    const location = [
      { value: "location:unassigned", label: "Location: unassigned" },
      { value: "teleHealth:videoOffice", label: "Telehealth: Video Office" },
    ];

    return (
      <div>
        <Dialog
          fullWidth
          open={this.props.openDialog}
          onClose={this.props.closeDialog}
          aria-labelledby="responsive-dialog-title"
        >
          {this.state.isLoaded ? (
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Loader />
            </div>
          ) : (
            <div className={classes.root}>
              <Grid container>
                <Typography className={classes.title}>{clientName}</Typography>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row-reverse",
                  }}
                >
                  {this.state.expandOpen ? (
                    <ExpandLess
                      onClick={() => this.setState({ expandOpen: !this.state.expandOpen })}
                    />
                  ) : (
                    <ExpandMore
                      onClick={() => this.setState({ expandOpen: !this.state.expandOpen })}
                    />
                  )}
                </div>
              </Grid>
              <Collapse in={this.state.expandOpen} timeout="auto" unmountOnExit>
                <List component="div">
                  <ListItem style={{ height: 10, padding: 10 }}>
                    <ListItemIcon>
                      <Phone style={{ fontSize: 15 }} />
                    </ListItemIcon>
                    <Typography>phone</Typography>
                  </ListItem>
                  <ListItem style={{ height: 10, padding: 10 }}>
                    <ListItemIcon>
                      <Email style={{ fontSize: 15 }} />
                    </ListItemIcon>
                    <Typography>yey</Typography>
                  </ListItem>
                </List>
              </Collapse>
              {this.state.location === "teleHealth:videoOffice" && (
                <Grid style={{ marginTop: 10 }} container spacing={1}>
                  <Grid item xs={6} sm="10">
                    <Button
                      style={{ color: "#fff", backgroundColor: "#59ba9a" }}
                    >
                      <VideoCall />
                      Start Video Session
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm="2">
                    <Typography
                      style={{ color: "#08c", cursor: "pointer", padding: 2 }}
                    >
                      Share link
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid container>
                <Grid item xs={4} sm={4} className={classes.gridsty}>
                  <Select
                    value={selectedShowOption}
                    onChange={this.handleShowChange}
                    options={showoptions}
                  />
                </Grid>
              </Grid>

              <Divider className={classes.dividersty} />

              <div>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={this.state.allDay}
                      onChange={() => this.setState({ allDay: !allDay })}
                      color="primary"
                      className={classes.alldaysty}
                    />
                  )}
                  label="All-day"
                />

                <Grid container spacing={3}>
                  {allDay ? (
                    <>
                      <Grid item xs={10} sm={5}>
                        <DatePicker
                          locale='en'
                          className={classes.dateInput}
                          dateFormat="MMMM d, yyyy "
                          selected={dayStartDate}
                          onChange={(date) => this.setState({ dayStartDate: date })}
                        />
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flex: 1,
                          alignContent: "center",
                          justifyContent: "center",
                        }}
                        item
                        xs={10}
                        sm={2}
                      >
                        <Typography
                          style={{ marginTop: 5 }}
                          className={classes.textStyle}
                        >
                          To
                        </Typography>
                      </Grid>
                      <Grid item xs={10} sm={5}>
                        <DatePicker
                          locale='en'
                          className={classes.dateInput}
                          dateFormat="MMMM d, yyyy "
                          selected={dayEndDate}
                          onChange={(date) => this.setState({ dayEndDate: date })}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={10} sm={7}>
                        <DatePicker
                          locale='en'
                          className={classes.dateInput}
                          dateFormat="MMMM d, yyyy h:mm aa"
                          selected={allDayDate}
                          showTimeSelect
                          onChange={(date) => this.setState({ allDayDate: date })}
                        />
                      </Grid>
                      <Grid item xs={10} sm={5}>
                        <Select
                          options={hrOption}
                          value={hrOption.filter(
                            (option) => option.value === (dayHr && dayHr),
                          )}
                          onChange={this.handleSelectHr}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} className={classes.gridsty}>
                    <Select
                      options={location}
                      value={location.filter(
                        (option) => option.value
                          === (this.state.location && this.state.location),
                      )}
                      onChange={this.handleSelectLocation}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </div>

              <Divider className={classes.dividersty} />

              <div>
                <Grid container>
                  <Grid item xs={12} sm={10}>
                    <Typography style={{ fontWeight: "bold" }}>
                      Notes:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Link to="/add_notes">Add Note</Link>
                  </Grid>
                </Grid>
              </div>
              <Divider className={classes.dividersty} />

              <Grid container>
                {this.state.services.map((service, index) => (
                  <>
                    <Grid item xs={7} sm={7} style={{ marginBottom: 3 }}>
                      <Select
                        value={allService.filter(
                          (option) => option._id === service._id,
                        )}
                        getOptionLabel={(option) => `${option.serviceCode} ${option.description}`}
                        getOptionValue={(option) => `${option.description}`}
                        onChange={this.handleSelectService(index)}
                        options={allService}
                      />
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <input
                        // name="firstName"
                        // value={firstName}
                        // onChange={this.handleChange}
                        className={classes.divstyle}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} sm={1}>
                      <Delete
                        style={{
                          marginLeft: 20,
                          marginTop: 5,
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={this.handleDeleteService(index)}
                      />
                    </Grid>
                  </>
                ))}
                <Button
                  style={{ color: "#08c" }}
                  onClick={() => (
                    this.state.services.push(""), this.setState({})
                  )}
                >
                  Add Services
                </Button>
              </Grid>
              <Divider className={classes.dividersty} />

              <Grid container>
                <Grid item xs={8} sm={10}>
                  <Typography className={classes.textStyle}>
                    Billing Type
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={2}>
                  <Typography>Self-Pay</Typography>
                </Grid>
                <Grid item xs={8} sm={10}>
                  <Typography className={classes.textStyle}>
                    Appointment Total
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={2}>
                  <Typography>
                    $
                    {amount}
                  </Typography>
                </Grid>
              </Grid>

              <Divider className={classes.dividersty} />

              <div>
                <FormControlLabel
                  name="client"
                  value="end"
                  control={(
                    <Radio
                      color="primary"
                      // checked={this.state.appointmentType === "client"}
                      // onChange={() => this.setState({ appointmentType: "client" })}
                      className={classes.radiosty}
                    />
                  )}
                  label="No Previous Balance"
                />
              </div>
              <div>
                <FormControlLabel
                  value="end"
                  name="other"
                  control={(
                    <Radio
                      color="primary"
                      // checked={this.state.appointmentType === "other"}
                      // onChange={() => this.setState({ appointmentType: "other" })}
                      className={classes.radiosty}
                    />
                  )}
                  label="$100: Uninvoiced Session"
                />
              </div>
              <div>
                <FormControlLabel
                  value="end"
                  name="other"
                  control={(
                    <Radio
                      color="primary"
                      // checked={this.state.appointmentType === "other"}
                      // onChange={() => this.setState({ appointmentType: "other" })}
                      className={classes.radiosty}
                    />
                  )}
                  label="Other Amount"
                />
              </div>
              <Grid container>
                <Grid item xs={12} sm={6} className={classes.gridsty}>
                  <Select
                    options={paymentOption}
                    // value={location.filter((option) => option.value === (this.state.location && this.state.location))}
                    // onChange={this.handleSelectLocation}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  style={{
                    color: "#fff",
                    backgroundColor: "#59ba9a",
                    width: "100%",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  Add Payment
                </Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography>
                  Invoice with 1 Session and total amount $100 will be created.
                  Edit Invoice before adding payment?
                </Typography>
              </Grid>

              <Divider className={classes.dividersty} />

              <DialogActions style={{ paddingBottom: 20, paddingRight: 20 }}>
                <Delete
                  style={{
                    color: "red",
                    display: "flex",
                    flex: 1,
                    flexDirection: "column-reverse",
                    cursor: "pointer",
                    marginLeft: -300,
                  }}
                />
                <Button
                  autoFocus
                  onClick={this.props.closeDialog}
                  color="#1371c8"
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.handleSubmit}
                  style={{ backgroundColor: "#1371c8", color: "#ffffff" }}
                  autoFocus
                  variant="contained"
                >
                  Done
                </Button>
              </DialogActions>
            </div>
          )}
        </Dialog>
      </div>
    );
  }
}

ClientAdd.propsTypes = {
  getAllClient: PropsTypes.array,
  getServiceData: PropsTypes.array,
  addAppointmentData: PropsTypes.bool,
};

const connected = connect(
  (state) => ({
    getAllClient: state.getClient,
    addAppointmentData: state.addAppointment,
    getServiceData: state.getServiceUser,
    getAppointmentData: state.getByIdAppointment,
  }),
  {
    getClientAction,
    getServiceAction,
    addAppointmentAction,
    getByIdAppointmentAction,
  },
)(ClientAdd);

const formed = reduxForm({
  form: "getClient",
})(connected);

export default withStyles(styles)(formed);
