import {createStyles} from "@material-ui/core";
export default createStyles({
    inputText: {
        fontSize: '22px',
        lineHeight: "28px",
        marginBottom: "15px",
        color: '#003265',
        padding: '5px',
        paddingLeft: 0,
        textAlign: 'left',
    },
    paper: {
        padding: '20px',
        height: '100%',
        boxShadow: 'none',
        borderRadius: 0
    },
    inputTitle: {
        textAlign: 'left',
        fontSize: "13px",
        color: '#4E525B',
        fontWeight: "500",
        marginBottom: "4px"
    },
    inputsty: {
        border: '1px solid #e5e5e5',
        borderRadius: '0',
        height: '45px',
        width: '100%',
    },
    imgBox: {
        backgroundColor: '#003265',
        padding: '25px',
        width: 170,
        height: 170,
    },
    img: {
        borderRadius: '50%',
        width: '115px',
      height: '115px',
        objectFit: 'cover'
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    drag: {
        width: '90%',
        height: '100%',
        borderStyle: 'dashed',
        borderWidth: 2,
        borderColor: '#ccc',
        textAlign: 'center',
        marginLeft: 20,
        color: '#afc2df',
    },
    italicTitle: {
        fontStyle: 'italic',
        fontSize: '13px',
        textAlign: 'left',
        color: '#807c7c',
    },
    tile: {
        padding: '20px',
        backgroundColor: '#f1f4f8',
        textAlign: 'left',
    },
    upload: {
        padding: '25px',
        backgroundColor: '#003265',
        color: '#fff',
        textAlign: 'left',
    },
    errorText: {
        color: 'red',
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: 16,
    },
    modalDisplay: {
        backgroundColor: 'white',
        padding: '8px',
        borderRadius: '4px',
        width: '100%',
      },
      dropdownDescription: {
        fontSize: '10px',
        color: '#807c7c'
      },
});
