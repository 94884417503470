/* eslint-disable react/sort-comp */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';
import {
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  withStyles,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import IpAddress from 'public-ip';
import CustomRadio from '../../common/RadioButton';
import paypal from '../../assets/user_images/paypal.svg';
import { DrawerTitleFont } from '../common/DrawerContent/DrawerFont';
import {
  CustomCancelButtons,
  CustomNextButtons,
} from '../../common/CustomButtons';
import {
  getPaymentResAction,
  getPaymentUrlAction,
  paypalAction,
  eCheckAction,
} from '../../utils/actions/payment.action';
import AlertMsg from '../../utils/Alert';
import Loader from '../../user/Loader';
import PopupWindow from '../../common/PopupWindow';
import styles from './PaymentOption_style';
import PaypalPayment from '../../common/PaypalPayment';

class PaymentOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNext: false,
      ipAddress: '',
      isUrlGet: false,
      urlName: '',
      urlLink: '',
    };
  }

  componentDidMount = async () => {
    window.addEventListener('beforeunload', () => {
      this.handleClosePortal();
    });
    await fetch('https://geolocation-db.com/json/', {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ ipAddress: data.IPv4 });
      });
    this.setState({
      // ipAddress: await IpAddress.v4(),
      isNext: false,
      urlName: '',
      isUrlGet: false,
      urlLink: '',
    });
  };

  handleClosePortal = () => {
    clearInterval(this.checkStatus);
    this.setState({
      isUrlGet: false,
      urlLink: '',
      isNext: false,
    });
    this.props.onCancel();
  };

  componentDidUpdate(preProps) {
    if (this.props.getResponse !== preProps.getResponse) {
      const { status } = this.props.getResponse;
      if (status !== 'unpaid') {
        if (status === 'success') {
          clearInterval(this.checkStatus);
          AlertMsg('success', 'Payment Successfull!');
          this.setState({ isUrlGet: false, isNext: false });
          this.props.onCancel();
          // this.props.history.push(
          //   `/user/appointment/confirmation/${this.props.match.params.id}`
          // );
        }
        if (status === 'failed') {
          clearInterval(this.checkStatus);
          AlertMsg('error', 'Payment Failed, Please try again!');
          this.setState({ isUrlGet: false, isNext: false });
          this.props.onCancel();
          // this.props.history.push(
          //   `/user/appointment/paymentInfo${this.props.match.params.id}`
          // );
        }
      }
    }
    if (this.props.getUrl !== preProps.getUrl) {
      if (this.props.getUrl.getPaymentUrlData) {
        this.setState({
          urlLink: this.props.getUrl.getPaymentUrlData.url,
          isUrlGet: true,
        });
        this.checkStatus = setInterval(() => {
          this.props.getPaymentResAction(
            this.props.getUrl.getPaymentUrlData.billingId
          );
        }, 2000);
      }
    }

    if (this.props.paypalData !== preProps.paypalData) {
      if (this.props.paypalData.metaData) {
        const data = this.props.paypalData.metaData.links;
        this.setState({
          urlLink: data[1].href,
          isUrlGet: true,
        });
        this.checkStatus = setInterval(() => {
          this.props.getPaymentResAction(this.props.paypalData.billingId);
        }, 2000);
      }
    }
    if (this.props.eCheckData !== preProps.eCheckData) {
      if (this.props.eCheckData.message === 'success') {
        AlertMsg('success', 'Payment Successfull!');
        this.props.onCancel();
        // this.props.history.push(`/confirmation/${this.props.match.params.id}`);
      }
    }
  }

  componentWillUnmount() {
    this.handleClosePortal();
    clearInterval(this.checkStatus);
  }

  handleSubmit = async (e) => {
    if (!this.props.id && !this.props.id._id) {
      AlertMsg('Error', 'Something went wrong!');
      return;
    }

    const { urlName } = this.state;
    console.log('this.props.id', this.props.id);
    if (urlName === 'credit card') {
      await this.props.getPaymentUrlAction(this.props.id._id, {
        publicIp: this.state.ipAddress,
      });
      this.setState({ isNext: true });
    } else if (urlName === 'paypal') {
      await this.props.paypalAction(this.props.id._id, {
        publicIp: this.state.ipAddress,
      });
      this.setState({ isNext: true });
    } else if (urlName === 'eCheck') {
      await this.props.eCheckAction(this.props.id._id, {
        publicIp: this.state.ipAddress,
      });
      this.setState({ isNext: true });
    }
  };

  render() {
    const { classes, onCancel } = this.props;
    return (
      <div>
        <Grid className={classes.secondContainer}>
          <DrawerTitleFont text='' />
          <Grid container className={classes.cardContainer}>
            <Grid
              container
              spacing={3}
              style={{
                paddingLeft: '42px',
                paddingTop: '27px',
                marginBottom: '25px',
                // display: 'flex',
                // flexDirection: 'column',
              }}
            >
              <RadioGroup
                onChange={(e) => this.setState({ urlName: e.target.value })}
              >
                <FormControlLabel
                  value='credit card'
                  control={<CustomRadio />}
                  label={
                    <Typography className={classes.cardtext}>
                      Credit card: Visa, MasterCard, Amex
                    </Typography>
                  }
                />
                {/* <FormControlLabel
                  value="paypal"
                  control={<CustomRadio />}
                  label={<img src={paypal} className={classes.paypal} />}
                /> */}

                {/* <FormControlLabel
                  value="eCheck"
                  control={<CustomRadio />}
                  label={
                    <Typography className={classes.cardtext}>
                      E-check payment
                    </Typography>
                  }
                /> */}
              </RadioGroup>
              <PaypalPayment appointmentDetail={this.props.id} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify='center' className={classes.buttonContainer}>
          <CustomCancelButtons
            // width="167px"
            title='Cancel'
            textStyles={classes.cancelText}
            style={classes.cancelButton}
            onPressButton={() => onCancel()}
            // onPressButton={() =>
            //   this.props.history.push(
            //     `/user/appointment/insuranceinfo/${this.props.match.params.id}`
            //   )
            // }
          />
          {this.state.isNext ? (
            <Loader />
          ) : (
            <CustomNextButtons
              // width="167px"
              title='Next'
              style={classes.nextButton}
              onPressButton={this.handleSubmit}
            />
          )}
        </Grid>
        {this.state.isUrlGet && (
          // <PopupWindow
          //   url={this.state.urlLink}
          //   closeWindowPortal={this.handleClosePortal}
          // >
          //   <iframe style={{height: '100%', width: '100%'}} src={this.state.urlLink} />
          // </PopupWindow>
          <PopupWindow
            url={this.state.urlLink}
            closeWindowPortal={this.handleClosePortal}
          />
        )}
      </div>
    );
  }
}

PaymentOption.defaultProps = {
  getResponse: {},
  getUrl: {},
  getAppointmentData: {},
  appointmentLoader: true,
};

const connected = connect(
  (state) => ({
    getUrl: state.getPaymentUrl,
    getResponse: state.getPaymentRes.getPaymentResData,
    getAppointmentData: state.getAppointmentDetail.getAppointmentDetailData,
    appointmentLoader: state.getAppointmentDetail.request,
    paypalData: state.paypal.paypalData,
    eCheckData: state.eCheck.eCheckData,
  }),
  {
    getPaymentUrlAction,
    getPaymentResAction,
    paypalAction,
    eCheckAction,
  }
)(PaymentOption);

const formed = reduxForm({
  form: 'payment',
})(connected);

export default withStyles(styles)(formed);
