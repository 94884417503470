/* eslint-disable no-unused-vars */
/* eslint-disable quotes */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
import {
  ADD_RCADS_REQUEST,
  ADD_RCADS_SUCCESS,
  ADD_RCADS_FAILED,
  GET_RCADS_REQUEST,
  GET_RCADS_SUCCESS,
  GET_RCADS_FAILED,
  GET_RCADS_BY_ID_REQUEST,
  GET_RCADS_BY_ID_SUCCESS,
  GET_RCADS_BY_ID_FAILED,
  UPDATE_RCADS_REQUEST,
  UPDATE_RCADS_SUCCESS,
  UPDATE_RCADS_FAILED,
  DELETE_RCADS_FAILED,
  DELETE_RCADS_SUCCESS,
  DELETE_RCADS_REQUEST,
} from "../AdminConstent";
import {
  USER_RCAD_SUBMIT_REQUEST,
  USER_RCAD_SUBMIT_SUCCESS,
  USER_RCAD_SUBMIT_FAILED,
} from "../constant";

const initialState = {
  request: true,
  error: false,
};

export const addRcad = (state = [], action) => {
  switch (action.type) {
    case ADD_RCADS_REQUEST:
      return {
        request: true,
      };
    case ADD_RCADS_SUCCESS:
      return {
        request: false,
        addRcadData: action.response,
      };
    case ADD_RCADS_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getRcad = (state = [], action) => {
  switch (action.type) {
    case GET_RCADS_REQUEST:
      return {
        request: true,
      };
    case GET_RCADS_SUCCESS:
      return {
        request: false,
        getRcadData: action.response,
      };
    case GET_RCADS_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getByIdRcad = (state = [], action) => {
  switch (action.type) {
    case GET_RCADS_BY_ID_REQUEST:
      return {
        request: true,
      };
    case GET_RCADS_BY_ID_SUCCESS:
      return {
        request: false,
        getByIdRcadData: action.response,
      };
    case GET_RCADS_BY_ID_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const updateRcad = (state = [], action) => {
  switch (action.type) {
    case UPDATE_RCADS_REQUEST:
      return {
        request: true,
      };
    case UPDATE_RCADS_SUCCESS:
      return {
        request: false,
        updateRcadData: action.response,
      };
    case UPDATE_RCADS_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const deleteRcad = (state = [], action) => {
  switch (action.type) {
    case DELETE_RCADS_REQUEST:
      return {
        request: true,
      };
    case DELETE_RCADS_SUCCESS:
      return {
        request: false,
        deleteRcadData: action.response,
      };
    case DELETE_RCADS_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const submitUserRcad = (state = [], action) => {
  switch (action.type) {
    case USER_RCAD_SUBMIT_REQUEST:
      return {
        request: true,
      };
    case USER_RCAD_SUBMIT_SUCCESS:
      return {
        request: false,
        submitUserRcadData: action.response,
      };
    case USER_RCAD_SUBMIT_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};
