import { Grid } from '@material-ui/core';
import styled from 'styled-components';

const Content = styled(Grid)`
  background-color: #fff;
`;

const Row = styled(Grid)`
  margin-top: 16px;
`;

const Cell = styled(Grid)`
  width: 48%;
`;

const ResetBtnGrid = styled(Grid)`
  margin-right: 10px;
`;

export { Content, Row, Cell, ResetBtnGrid };
