import { createStyles} from "@material-ui/core";
export default createStyles({
    root: {
        // height: "158px",
        backgroundColor: "rgba(192,208,222,15%)",
        padding: "19px",
    },
    cal1: {
        position: "absolute",
        marginTop: "4px",
        marginLeft: "-9px",
    },
    cal2: {
        position: "absolute",
        marginTop: "4px",
        marginLeft: "-4px",
    },
    cal3: {
        position: "absolute",
        marginTop: "12px",
        marginLeft: "-11px",
    },
    watch1: {
        position: "absolute",
        marginTop: "6px",
        marginLeft: "-7px",
    },
    location1: {
        position: "absolute",
        marginTop: "8px",
        marginLeft: "-5px",
    },
    schedule1: {
        position: "absolute",
        marginTop: "3px",
        marginLeft: "-12px",
    },
    schedule2: {
        position: "absolute",
        marginTop: "10px",
        marginLeft: "-15px",
    },
    print1: {
        position: "absolute",
        marginLeft: "-10px",
        marginTop: "13px",
    },
    print2: {
        position: "absolute",
        marginLeft: "-17px",
        marginTop: "3px",
    },
    print3: {
        position: "absolute",
        marginLeft: "-12px",
        marginTop: "3px",
    },
    print4: {
        position: "absolute",
        marginLeft: "-7px",
        marginTop: "3px",
    },
    reciept1: {
        position: "absolute",
        marginTop: "2px",
        marginLeft: "-14px",
    },
    reciept2: {
        position: "absolute",
        marginTop: "4px",
        marginLeft: "-14px",
    },
    reciept3: {
        position: "absolute",
        marginTop: "7px",
        marginLeft: "-14px",
    },
    reciept4: {
        position: "absolute",
        marginTop: "9px",
        marginLeft: "-14px",
    },
    line: {
        border: "1px solid rgba(112,112,112,50%)",
        marginBottom: "34px",
    },
    diagnoTitle: {
        fontSize: "18px",
        color: "#222222",
        fontWeight: 700,
        fontFamily: "Lato",
    },
    orderTitle: {
        height: "22px",
        fontSize: "18px",
        color: "rgba(34,34,34,75%)",
        fontWeight: 700,
        fontFamily: "Lato",
        marginBottom: "17px",
        textTransform: "uppercase",
    },
});