import { put, takeEvery } from 'redux-saga/effects';
import { get, getPDF } from '../axios';
import {
  GET_CONSENT_FORMS_REQUEST,
  GET_CONSENT_FORMS_SUCCESS,
  GET_CONSENT_FORMS_FAILED,
  DOWNLOAD_CONSENT_FAILED,
  DOWNLOAD_CONSENT_SUCCESS,
  DOWNLOAD_CONSENT_REQUEST,
} from '../constant';

function* getConsentForms(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    console.log("response", response)
    yield put({ type: GET_CONSENT_FORMS_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_CONSENT_FORMS_FAILED, error });
  }
}

function* downloadConsetForm(action) {
  try {
    const { endpoint } = action;
    const response = yield getPDF(endpoint);
    yield put({ type: DOWNLOAD_CONSENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: DOWNLOAD_CONSENT_FAILED, error });
  }
}

export function* consentSaga() {
  yield takeEvery(GET_CONSENT_FORMS_REQUEST, getConsentForms);
  yield takeEvery(DOWNLOAD_CONSENT_REQUEST, downloadConsetForm);
}
