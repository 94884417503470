import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useHistory } from 'react-router-dom';

const millisToMinutesAndSeconds = (millis) => {
  let minutes = Math.floor(millis / 60000);
  let seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ' mint ' + (seconds < 10 ? '0' : '') + seconds + ' secs';
};

let timeoutMinutes = 5 * 60 * 1000;

const SessionExpireAlert = (props) => {
  const [open, _setOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [remainingTime, setRemainingTime] = useState(timeoutMinutes);
  const [token, setToken] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [intervalId2, setIntervalId2] = useState(null);
  const stateRef = React.useRef(open);
  const history = useHistory();

  useEffect(() => {
    document.addEventListener('mousemove', resetTimer);
  }, []);

  useEffect(() => {
    if (token) {
      const sessionInterval = setInterval(
        () => setRemainingTime((remainingTime) => remainingTime - 1000),
        1200
      );

      setIntervalId2(sessionInterval);
    } else {
      const storageWatchInterval = setInterval(
        () => setToken(localStorage.getItem('ACCESS_TOKEN_PATH')),
        1000
      );

      setIntervalId2(storageWatchInterval);
    }
  }, [token]);

  useEffect(() => {
    if (remainingTime === 120000) {
      setOpen(true);
    } else if (remainingTime === 0) {
      logout();
    }
  }, [remainingTime]);

  const resetTimer = () => {
    if (!stateRef.current) {
      setRemainingTime(timeoutMinutes);
    }
  };

  const setOpen = (data) => {
    stateRef.current = data;
    _setOpen(data);
  };

  const logout = () => {
    setOpen(false);
    clearInterval(intervalId);
    clearInterval(intervalId2);
    localStorage.removeItem('ACCESS_TOKEN_PATH');
    history.replace('/login');
  };

  const keepWorking = () => {
    setOpen(false);
    setTimeout(() => setRemainingTime(timeoutMinutes), 1500);
  };

  const closeAlert = (e, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={closeAlert}
      BackdropProps={{ style: { backgroundColor: '#003264' } }}
    >
      <div className=''>
        <div
          className='text-center text-white	p-3 font-bold text-lg'
          style={{ backgroundColor: '#DA1A18' }}
        >
          Session Timeout
        </div>
        <div style={{ width: 350, padding: 20 }}>
          <div className='flex flex-col items-center justify-center pb-2'>
            <div className='pb-1' style={{ color: '#DA1A18' }}>
              <i className='far fa-clock'></i>
            </div>
            <div className='text-gray-600 font-bold py-2'>
              Your online session will expire in
            </div>
            <div className='text-4xl'>
              {millisToMinutesAndSeconds(remainingTime)}
            </div>
          </div>
          <div className='flex justify-center'>
            <div className='text-gray-600 font-semibold w-10/12'>
              Please click 'Keep working' to continue or click 'Logout' to end
              it.
            </div>
          </div>

          <div className='flex justify-center mt-3 px-2'>
            <button
              className='bg-blue-500 text-white font-bold py-2 px-4'
              style={{ backgroundColor: '#003264' }}
              onClick={keepWorking}
            >
              Keep Working
            </button>
            <button
              className='bg-transparent font-semibold py-2 px-4 ml-3'
              style={{ color: '#DA1A18', border: '1px solid #DA1A18' }}
              onClick={logout}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SessionExpireAlert;
