import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../AdminLayout';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Grid, Typography, Button as PrimaryBtn } from '@material-ui/core';
import '../../../../clinician/clinician.scss';
import Axios from 'axios';
import { baseURL } from '../../../../utils/axios';
import useStyles from './ClinicAddresses.styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddressModal from './AddressModal';
import Pagination from '@material-ui/lab/Pagination';
import {
  addAddress,
  updateAddress,
} from '../../../../utils/actions/clinician_address.action';
import { getPageCount } from '../../../../utils/MathUtil';
import { Dialog, Button, Spinner } from '../../../../components/atoms';
import AlertMsg from '../../../../utils/Alert';

const ClinicAddresses = ({
  addAddress,
  updatedAddress,
  newAddress,
  updateAddress,
  error,
}) => {
  const classes = useStyles();
  const [addressesList, setAddresses] = useState([]);
  const [editingAddress, setEditingaddress] = useState(null);
  const [isModalOpen, setModalState] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deletingId, setDeletingId] = useState('');

  useEffect(() => {
    getClinicAddresses();
  }, []);

  useEffect(() => {
    if (error) {
      // show error message
    } else {
      if (newAddress) {
        setAddresses([...addressesList, newAddress]);
      }
    }
  }, [newAddress, error]);

  useEffect(() => {
    getClinicAddresses();
  }, [page]);

  useEffect(() => {
    if (error) {
      // show error message
    } else {
      if (updatedAddress) {
        const unmodifedAddresses = addressesList.filter(
          (ad) => ad._id !== updatedAddress._id
        );
        setAddresses([...unmodifedAddresses, updatedAddress]);
      }
    }
    setEditingaddress(null);
  }, [updatedAddress]);

  const handleEdit = (id) => {
    const selectedAddress = addressesList.filter((a) => a._id === id)[0];
    console.log('selectedAddress', selectedAddress);
    setEditingaddress(selectedAddress);
    setModalState(true);
  };

  const getClinicAddresses = () => {
    setLoading(true);
    Axios.get(`${baseURL}/api/clinic_addresses?page=${page}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          setAddresses(res.data.data);
          setTotalCount(res.data.totalCount);
          setLoading(false);
        }
      })
      .catch(() => {
        //show error
        setLoading(false);
      });
  };

  const handleModalClose = () => {
    setEditingaddress(null);
    setModalState(false);
  };

  const openModal = () => setModalState(true);

  const handleCreateAddress = (data) => {
    if (data._id) {
      updateAddress(data, data._id);
    } else {
      addAddress(data);
    }
    setModalState(false);
    getClinicAddresses();
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleDelete = (id) => {
    setDeletingId(id);
    setShowConfirmation(true);
  };

  const deleteAddress = () => {
    setLoading(true);
    Axios.delete(`${baseURL}/api/clinic_addresses/${deletingId}`)
      .then((res) => {
        const updatedAddresses = addressesList.filter(
          (a) => a._id !== deletingId
        );
        setAddresses([...updatedAddresses]);
        setShowConfirmation(false);
        setLoading(false);
        AlertMsg('info', 'Address is deleted');
      })
      .catch((e) => {
        setLoading(false);
        AlertMsg('error', e.message);
      });
  };
  return (
    <>
      {loading && <Spinner isOpen />}
      <div className='main-content' style={{ paddingLeft: '0', width: '100%' }}>
        <Grid container>
          {isModalOpen && (
            <AddressModal
              open={isModalOpen}
              handleClose={handleModalClose}
              handleCreateAddress={handleCreateAddress}
              classes={classes}
              editingAddress={editingAddress}
              officeNumber
              id
            />
          )}
          <Grid xs={6}></Grid>
          <Grid xs={6}>
            <div style={{ float: 'right' }}>
              <PrimaryBtn
                variant='contained'
                onClick={openModal}
                className={classes.btnNewApp}
                style={{ height: '49px' }}
              >
                <AddCircleOutlineIcon style={{ marginRight: 5 }} />
                Add Address
              </PrimaryBtn>
            </div>
          </Grid>
        </Grid>

        <Grid style={{ marginTop: '32px' }} className={'clinicianEvents'}>
          <Grid container className='patientDetailEventTable'>
            <TableContainer>
              <Table
                className={classes.table}
                stickyHeader
                aria-label='sticky table'
              >
                <TableBody component={Paper}>
                  {addressesList ? (
                    addressesList.length > 0 ? (
                      addressesList.map((data, i) => {
                        return (
                          <TableRow key={`lh-${i}`}>
                            <TableCell>{data.title}</TableCell>
                            <TableCell>
                              <Typography component={'p'}>{`${data.address1},
                            ${data.city},
                            ${data.state ? data.state.name + ',' : ''}
                            ${data.country.name}
                            ${data.zip}`}</Typography>
                            </TableCell>
                            <TableCell align='right'>
                              <PrimaryBtn
                                onClick={() => {
                                  handleEdit(data._id);
                                }}
                              >
                                Edit
                              </PrimaryBtn>

                              <PrimaryBtn
                                onClick={() => {
                                  handleDelete(data._id);
                                }}
                              >
                                Delete
                              </PrimaryBtn>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow key={1}>
                        <TableCell
                          component='th'
                          style={{ textAlign: 'center' }}
                          colSpan={6}
                          scope='row'
                        >
                          No Data Found
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <> </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {!loading && addressesList && addressesList.length > 0 && (
            <div className={classes.paginationRoot}>
              <Pagination
                count={getPageCount(totalCount)}
                page={page}
                onChange={handlePageChange}
                color='primary'
                shape='rounded'
              />
            </div>
          )}
        </Grid>
      </div>

      <Dialog
        handleClose={() => setShowConfirmation(false)}
        open={showConfirmation}
      >
        <Grid
          spacing={3}
          style={{ padding: '50px' }}
          direction='column'
          container
        >
          <Grid item>
            <Typography>Are you sure, you want to continue?</Typography>
          </Grid>
          <Grid item>
            <Grid spacing={2} justify='flex-end' container>
              <Grid item>
                <PrimaryBtn onClick={() => setShowConfirmation(false)}>
                  Cancel
                </PrimaryBtn>
              </Grid>
              <Grid item>
                <Button onClick={deleteAddress} text='Confirm' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

ClinicAddresses.propTypes = {
  addAddress: PropTypes.func.isRequired,
  updateAddress: PropTypes.func.isRequired,
  newAddress: PropTypes.any.isRequired,
  updatedAddress: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
  error: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  newAddress: state.ClinicianAddressReducer.newAddress,
  updatedAddress: state.ClinicianAddressReducer.updatedAddress,
  error: state.ClinicianAddressReducer.error,
});

export default connect(mapStateToProps, { addAddress, updateAddress })(
  ClinicAddresses
);
