import React, { useState } from 'react';

const CheckoutPartialPaymentForm = ({ setPatialPayment }) => {

  const [checked, setChecked] = useState(false)

  return (<div className='block mx-auto max-w-3xl mb-8'>
    { }
    <div className='block mx-auto max-w-3xl pb-2'>
      <h3 className='text-lg font-bold'>Partial Payments</h3>
    </div>
    { }
    <div className>
      <div className='partial-checkbox-wrapper cursor-pointer'>
        <input
          type='checkbox'
          name='partial-checkbox'
          id='partial-checkbox'
          className='partial-checkbox inline-block mr-2'
          onChange={e => {
            setChecked(e.target.checked)
          }}
          value={checked}
        />
        <span className>
          Select this if the client opts to make a partial payment instead.
        </span>
      </div>
    </div>
    {checked ?
      <div className='mt-2 p-4 text-sm bg-indigo-50 border border-indigo-200'>
        <form action>
          <span className>Enter partial payment value here:</span>
          <input
            type='number'
            name
            onChange={e => setPatialPayment(e.target.value)}
            id
            className='ml-1 p-2 text-xs bg-white border border-indigo-200'
          />
        </form>
      </div> : <></>}
  </div>)
};

export default CheckoutPartialPaymentForm;
