import {makeStyles} from "@material-ui/core";
export default makeStyles({
    nextButton: {
      height: "35px",
      borderRadius: "5px",
      backgroundColor: "#00bc07",
      "&:hover": { backgroundColor: "rgba(0,188,7, 70%)", color: "#ffffff" },
      justifyContent: "center",
      cursor: "pointer",
      color: "#222222",
    },
    buttonText: {
      fontSize: "15px",
      lineHeight: "30px",
      fontFamily: "Lato",
      fontWeight: 400,
      textAlign: "center",
      textTransform: "capitalize",
    },
    cancelButtonText: {
      fontSize: "15px",
      fontWeight: 400,
      cursor: "pointer",
      textAlign: "center",
      lineHeight: "25px",
      textTransform: "capitalize",
    },
    cancelButton: {
      height: "35px",
      // borderRadius: "5px",
      // backgroundColor: "rgbd(48,70,89,20%)",
      justifyContent: "center",
      color: "#304659",
      cursor: "pointer",
      borderRadius: "5px",
      backgroundColor: "rgba(48,70,89,20%)",
      "&:hover": {
        backgroundColor: "rgba(48,70,89,10%)",
        // color: '#ffffff'
      },
    },
    clinicianButton: {
      width: "229px",
      height: "35px",
      borderRadius: "18px",
      backgroundColor: "#304659",
      border: "1px solid #707070",
      fontSize: "15px",
      color: "#ffffff",
      fontWeight: 400,
      fontFamily: "Lato",
      // cursor: 'pointer',
    },
  });