/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable import/prefer-default-export */
import {
  ADD_ONBOARDING_REQUEST,
  GET_ONBOARDING_REQUEST,
  GET_BY_ID_ONBOARDING_REQUEST,
  UPDATE_ONBOARDING_REQUEST,
  DELETE_ONBOARDING_REQUEST,
} from "../AdminConstent";

export const addOnboardingAction = (data) => ({
  type: ADD_ONBOARDING_REQUEST,
  endpoint: "/admin/onboarding",
  body: data,
});

export const getOnboardingAction = () => ({
  type: GET_ONBOARDING_REQUEST,
  endpoint: "/admin/onboarding?pageNo=1",
});

export const getByIdOnboardingAction = (id) => ({
  type: GET_BY_ID_ONBOARDING_REQUEST,
  endpoint: `/admin/onboarding/${id}`,
});
export const updateOnboardingAction = (id, data) => ({
  type: UPDATE_ONBOARDING_REQUEST,
  endpoint: `/admin/onboarding/${id}`,
  body: data,
});

export const deleteOnboardingAction = (id) => ({
  type: DELETE_ONBOARDING_REQUEST,
  endpoint: `/admin/onboarding/${id}`,
});

export const getOnboardingUserAction = () => ({
  type: GET_ONBOARDING_REQUEST,
  endpoint: "/api/onboarding",
});
