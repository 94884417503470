/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import * as React from 'react';
import { Grid } from '@material-ui/core';
import { TableHeader } from './TableHeader';
import { TableBody } from './TableBody';

import { DrawerBody } from './DrawerContent/DrawerBody';

export const CustomTable = ({ data, onRowSelect, isLoader, ...props }) => (
  <Grid {...props}>
    <TableHeader status={data && data[0] && data[0].status} />
    <TableBody
      data={data}
      onRowSelect={(id) => onRowSelect(id)}
      isLoader={isLoader}
    />
  </Grid>
);

CustomTable.defaultProps = {
  onRowSelect: () => {},
};

export const CustomDrawer = ({ data }) => (
  <>
    <DrawerBody data={data} />
  </>
);
