import { STATUSES, LOCATION } from '../../../utils/enums';
import moment from 'moment';
import { calculateAge } from '../../../utils/timUtils';

const unique = (value, index, self) => self.indexOf(value) === index;

const generateVillaCounts = (addresses, currRow) => {
  let countArr = [];
  let totalCount = 0;
  addresses.forEach((a) => {
    const found = currRow.filter((c) => c.address[0]?.address1 === a);
    countArr.push(found.length);
    totalCount = totalCount + found.length;
  });
  const updatedArr = countArr.map((c) => (c === 0 ? '' : c));
  return [...updatedArr, totalCount];
};

const groupByKey = (list, key) =>
  list.reduce(
    (hash, obj) => ({
      ...hash,
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    }),
    {}
  );

const getInPersonReportFormat = (appointments) => {
  let formattedData = [];
  appointments.forEach((a) => {
    formattedData.push({
      'Time In': moment(a.startTime).format('hh:mm a'),
      'Time Out': moment(a.startTime)
        .add(a.duration, 'minutes')
        .format('hh:mm a'),
      Clinician: `${a.clinicianId[0].firstName} ${a.clinicianId[0].lastName}`,
      'Villa No': a.address[0]?.address1 || '',
    });
  });

  return formattedData;
};
const pendingPaymentUpcomingFormat = (appointments) => {
  let formattedData = [];
  appointments.forEach((a) => {
    formattedData.push({
      'Time In': moment(a.startTime).format('hh:mm a'),
      'Chart #': a.clientId[a.clientId.length - 1].chartId,
      'Patient Name': `${a.clientId[a.clientId.length - 1].firstName} ${
        a.clientId[a.clientId.length - 1].lastName
      }`,
      Type: a.appointmentType[0]?.type,
      'Upcoming Appointment Date': moment(a.startTime).format('L'),
      Doctor: `${a.clinicianId[0].firstName} ${a.clinicianId[0].lastName}`,
      'Pending Amount': a.billingId[0].amount,
    });
  });

  return formattedData;
};

const paymentReconciliationFormat = (appointments) => {
  let formattedData = [];

  appointments.forEach((a) => {
    const visitPerHour = a.billingId[0].amount;
    const numberOfHours = 1;
    const invoice = visitPerHour * numberOfHours;
    const total = a.appointmentType[0]?.vat
      ? a.appointmentType[0].vat + invoice
      : invoice;

    formattedData.push({
      Time: moment(a.startTime).format('hh:mm a'),
      'Chart no': a.clientId[a.clientId.length - 1].chartId,
      'Patient name': `${a.clientId[a.clientId.length - 1].firstName} ${
        a.clientId[a.clientId.length - 1].lastName
      }`,
      'Corporate Clients': a.clientId[a.clientId.length - 1].corporationId
        ? 'Yes'
        : 'no',
      Date: moment(a.startTime).format('L'),
      Status: STATUSES[a.status],
      'Upcoming Appointment Date': moment(a.startTime).format('L'),
      Clinician: `${a.clinicianId[0].firstName} ${a.clinicianId[0].lastName}`,
      Treatment: a.appointmentType[0]?.type || '',
      'Visit per hour': visitPerHour,
      'Number of Hours': numberOfHours,
      'Cancellation by Clinician': '',
      'Cancellation/No Show Hours by client': '',
      Reg: '',
      Rx: '',
      'Report Assessment': '',
      Workshop: '',
      VAT: a.appointmentType[0]?.vat ? a.appointmentType[0].vat : '',
      Invoice: invoice,
      Total: total,
      Payment: a.billingId[0].status === 'paid' ? a.billingId[0].amount : '',
      Balance: a.billingId[0].status === 'unpaid' ? a.billingId[0].amount : '',
      Advance: '',
      'Payment Method': a.payments[0]?.mode ? a.payments[0].mode : '',
      'Payment Date': a.payments[0]?.createdAt ? a.payments[0].createdAt : '',
      'CC Auth Code': '',
      'Visit Note': '',
    });
  });

  return formattedData;
};
const newClientFormat = (appointments) => {
  let formattedData = [];
  let uniqClientsAppointments = [];
  let temp = [];

  appointments.forEach((a) => {
    const adult = a.clientId[a.clientId.length - 1];
    if (!temp.includes(adult.chartId)) {
      temp.push(adult.chartId);

      uniqClientsAppointments.push(a);
    }
  });

  uniqClientsAppointments.forEach((a) => {
    formattedData.push({
      'Created Date': moment(
        a.clientId[a.clientId.length - 1].createdAt
      ).format('L'),
      'Time of appt': moment(a.startTime).format('hh:mm a'),
      'Client Chart No': a.clientId[a.clientId.length - 1].chartId,
      'Client Name': `${a.clientId[a.clientId.length - 1].firstName} ${
        a.clientId[a.clientId.length - 1].lastName
      }`,
      'Date of Appointment': moment(a.startTime).format('L'),
      'Appointment Type': a?.appointmentType[0]?.type || '',
      'Clinician Name': `${a.clinicianId[0].firstName} ${a.clinicianId[0].lastName}`,
    });
  });

  return formattedData;
};
const psychiatryAppointmentsFormat = (appointments) => {
  let formattedData = [];
  appointments.forEach((a) => {
    const passport = a.proofofidentities.filter(
      (p) => p.documentType === 'passport'
    );
    const lisence = a.proofofidentities.filter(
      (p) => p.documentType === 'Uae_license'
    );
    const cnic = a.proofofidentities.filter((p) => p.documentType === 'uae_id');

    formattedData.push({
      Time: moment(a.startTime).format('hh:mm a'),
      'Chart #': a.clientId[a.clientId.length - 1].chartId,
      'Patient Name': `${a.clientId[a.clientId.length - 1].firstName} ${
        a.clientId[a.clientId.length - 1].lastName
      }`,
      'Patient passport': passport.length > 0 ? 'uploaded' : 'not uploaded',
      'National ID': cnic.length > 0 ? 'uploaded' : 'not uploaded',
      'Driver\' lisence number':
        lisence.length > 0 ? 'uploaded' : 'not uploaded',
      Date: moment(a.startTime).format('L'),
      Doctor: `${a.clinicianId[0].firstName} ${a.clinicianId[0].lastName}`,
    });
  });

  return formattedData;
};
const incompleteDocUpcomingAppointmentsFormat = (appointments) => {
  let formattedData = [];
  appointments.forEach((a) => {
    const passport = a.proofofidentities.filter(
      (p) => p.documentType === 'passport'
    );
    const lisence = a.proofofidentities.filter(
      (p) => p.documentType === 'Uae_license'
    );
    const cnic = a.proofofidentities.filter((p) => p.documentType === 'uae_id');

    formattedData.push({
      Time: moment(a.startTime).format('hh:mm a'),
      'Chart #': a.clientId[a.clientId.length - 1].chartId,
      'Patient Name': `${a.clientId[a.clientId.length - 1].firstName} ${
        a.clientId[a.clientId.length - 1].lastName
      }`,
      'Patient passport': passport.length > 0 ? 'uploaded' : 'not uploaded',
      'National ID': cnic.length > 0 ? 'uploaded' : 'not uploaded',
      'Driver\' lisence number':
        lisence.length > 0 ? 'uploaded' : 'not uploaded',
      Date: moment(a.startTime).format('L'),
      Doctor: `${a.clinicianId[0].firstName} ${a.clinicianId[0].lastName}`,
    });
  });
  return formattedData;
};
const clientFlowFormat = (appointments) => {
  const groupedData = groupByKey(appointments, 'startTime');
  let formattedData = [];
  let formattedDataSheet1 = [];
  let uniqAddresses = [];

  appointments.forEach((a) => {
    if (!uniqAddresses.includes(a.address[0]?.address1)) {
      uniqAddresses.push(a.address[0]?.address1);
    }

    const passport = a.proofofidentities.filter(
      (p) => p.documentType === 'passport'
    );
    const lisence = a.proofofidentities.filter(
      (p) => p.documentType === 'Uae_license'
    );
    const cnic = a.proofofidentities.filter((p) => p.documentType === 'uae_id');

    formattedData.push({
      'Created Date': moment(a.startTime).format('L'),
      Time: moment(a.startTime).format('hh:mm a'),
      'Chart #': a.clientId[a.clientId.length - 1].chartId,
      'Patient Name': `${a.clientId[a.clientId.length - 1].firstName} ${
        a.clientId[a.clientId.length - 1].lastName
      }`,
      Clinic: a.address[0]?.address1,
      'Patient passport': passport.length > 0 ? 'uploaded' : 'not uploaded',
      'National ID': cnic.length > 0 ? 'uploaded' : 'not uploaded',
      'Driver\' lisence number':
        lisence.length > 0 ? 'uploaded' : 'not uploaded',
      Email: a.clientId[a.clientId.length - 1].email,
      State: a.address[0]?.state?.name ? a.address[0].state?.name : '',
      'Date of birth': moment(a.clientId[a.clientId.length - 1].dob).format(
        'L'
      ),
      Age: calculateAge(new Date(a.clientId[a.clientId.length - 1].dob)),
      Nationality: '',
      Gender: a.clientId[a.clientId.length - 1]?.gender
        ? a.clientId[a.clientId.length - 1]?.gender
        : '',
      Date: moment().format('L'),
      'Length(min)': a.duration,
      'Patient mobile phone': a.clientId[a.clientId.length - 1].phoneNumber,
      Status: STATUSES[a.status],
      Type: a.appointmentType[0]?.type,
      'Master Type': '',
      Doctor: `${a.clinicianId[0]?.firstName} ${a.clinicianId[0]?.lastName}`,
      'Created By': a.creatorEmail,
      Machine: '',
      'Future Appointment Any Doctor': '',
      'Future Appointment Same Doctor': '',
      Location: LOCATION[a.appointmentLocation],
      'Status Other': a.billingId[0]?.status,
      'UAE Resident': '',
      'School Name for Students': '',
      'Secondary Email Address': '',
      'Old Consent Form': '',
      'Online Consent Form': '',
      'Updated Consent Form': '',
    });
  });

  let grandTotalArr = [];

  Object.keys(groupedData).forEach((gd) => {
    const rowCountArr = generateVillaCounts(uniqAddresses, groupedData[gd]);
    formattedDataSheet1.push([
      moment(groupedData[gd][0].startTime).format('hh:mm a'),
      ...rowCountArr,
    ]);

    grandTotalArr.push(rowCountArr);
  });

  grandTotalArr = grandTotalArr.reduce(function (r, a) {
    a.forEach(function (b, i) {
      r[i] = (parseInt(r[i]) || 0) + b;
    });
    return r;
  }, []);

  return [formattedDataSheet1, grandTotalArr, uniqAddresses, formattedData];
};

const numberOfClinicianSessionsFormat = (clinicians, firstPlus4Days) => {
  let formattedData = [];
  const lastDayOfWeek = moment(firstPlus4Days).add(3, 'days');

  clinicians.forEach((c) => {
    const newClientMissedAppointments = c.appointments.filter(
      (a) =>
        a.clientId[a.clientId.length - 1].clinicalStatus === 'new' &&
        (a.status === 'missed' || a.status === 'cancelled')
    );

    const newClientAttendedAppointments = c.appointments.filter(
      (a) =>
        a.clientId[a.clientId.length - 1].clinicalStatus === 'new' &&
        (a.status === 'completed' || a.status === 'ended')
    );

    formattedData.push([
      `${c._id[0].firstName} ${c._id[0].lastName}`,
      c.appointments.length,
      c.appointments.filter((a) => moment(a.startTime) <= firstPlus4Days)
        .length,
      c.appointments.filter(
        (a) =>
          moment(a.startTime) > firstPlus4Days &&
          moment(a.startTime) <= lastDayOfWeek
      ).length,
      newClientAttendedAppointments.length > 0
        ? ''
        : newClientAttendedAppointments.length,
      newClientMissedAppointments.length > 0
        ? ''
        : newClientMissedAppointments.length,
    ]);
  });

  return formattedData;
};

const paymentReconciliationWeeklyFormat = (appointments) => {
  const formattedData = paymentReconciliationFormat(appointments);
  return formattedData;
};

const appointmentsWithPendingPaymentFormat = (appointments) => {
  let formattedData = [];
  appointments.forEach((a) => {
    formattedData.push({
      Time: moment(a.startTime).format('hh:mm a'),
      'Chart #': a.clientId[a.clientId.length - 1].chartId,
      'Patient Name': `${a.clientId[a.clientId.length - 1].firstName} ${
        a.clientId[a.clientId.length - 1].lastName
      }`,
      'Appointment Date':
        a.status === 'waiting' ? '-' : moment(a.startTime).format('L'),
      Type: a.appointmentType[0]?.type,

      Doctor: `${a.clinicianId[0].firstName} ${a.clinicianId[0].lastName}`,
      'Pending Amount': a.billingId[0].amount,
    });
  });

  return formattedData;
};

const outPatientsFormat = (appointments) => {
  let formattedData = [];
  let diagnosis = [];
  appointments.forEach((a) => {
    const codes = a.diagnoses.map((a) => a.code);
    diagnosis.push(...codes);
  });

  diagnosis = diagnosis.filter(unique);

  diagnosis.forEach((ad) => {
    let selectedAppointment = [];
    appointments.forEach((a) => {
      if (a.diagnoses.some((d) => d.code === ad)) {
        selectedAppointment.push(a);
      }
    });

    selectedAppointment.forEach((sa) => {
      const age = calculateAge(new Date(sa.clientId[0].dob));
      const gender = sa.clientId[0].gender || 'male';
      console.log('formattedData[ad]', formattedData[ad], age);
      formattedData[ad] = {
        newBorn: {
          female:
            age < 1 &&
            gender === 'female' &&
            (formattedData[ad]?.newBorn?.female + 1 || 1),
          male:
            age < 1 &&
            gender === 'male' &&
            (formattedData[ad]?.newBorn?.male + 1 || 1),
        },
        tween1: {
          female:
            age > 1 &&
            age < 5 &&
            gender === 'female' &&
            (formattedData[ad]?.tween1?.female + 1 || 1),
          male:
            age > 1 &&
            age < 5 &&
            gender === 'male' &&
            (formattedData[ad]?.tween1?.male + 1 || 1),
        },
        tween2: {
          female:
            age > 5 &&
            age < 10 &&
            gender === 'female' &&
            (formattedData[ad]?.tween2?.female + 1 || 1),
          male:
            age > 5 &&
            age < 10 &&
            gender === 'male' &&
            (formattedData[ad]?.tween2?.male + 1 || 1),
        },
        tween3: {
          female:
            age > 10 &&
            age < 15 &&
            gender === 'female' &&
            (formattedData[ad]?.tween3?.female + 1 || 1),
          male:
            age > 10 &&
            age < 15 &&
            gender === 'male' &&
            (formattedData[ad]?.tween3?.male + 1 || 1),
        },
        teen1: {
          female:
            age > 15 &&
            age < 20 &&
            gender === 'female' &&
            (formattedData[ad]?.teen1?.female + 1 || 1),
          male:
            age > 15 &&
            age < 20 &&
            gender === 'male' &&
            (formattedData[ad]?.teen1?.male + 1 || 1),
        },
        teen2: {
          female:
            age > 20 &&
            age < 25 &&
            gender === 'female' &&
            (formattedData[ad]?.teen2?.female + 1 || 1),
          male:
            age > 20 &&
            age < 25 &&
            gender === 'male' &&
            (formattedData[ad]?.teen2?.male + 1 || 1),
        },
        adult1: {
          female:
            age > 25 &&
            age < 45 &&
            gender === 'female' &&
            (formattedData[ad]?.adult1?.female + 1 || 1),
          male:
            age > 25 &&
            age < 45 &&
            gender === 'male' &&
            (formattedData[ad]?.adult1?.male + 1 || 1),
        },
        adults2: {
          female:
            age > 45 &&
            age < 65 &&
            gender === 'female' &&
            (formattedData[ad]?.adults2?.female + 1 || 1),
          male:
            age > 45 &&
            age < 65 &&
            gender === 'male' &&
            (formattedData[ad]?.adults2?.male + 1 || 1),
        },
        elderly: {
          female:
            age > 45 &&
            age < 65 &&
            gender === 'female' &&
            (formattedData[ad]?.elderly?.female + 1 || 1),
          male:
            age > 45 &&
            age < 65 &&
            gender === 'male' &&
            (formattedData[ad]?.elderly?.male + 1 || 1),
        },
      };
    });
  });

  return formattedData;
};

const emiratiPatientsFormat = (appointments) => {
  let formattedData = [];
  appointments.forEach((a) => {
    const uae_id = a.proofofidentities.filter(
      (p) => p.documentType === 'uae_id' && p.verified
    );
    const diagnosis = a.diagnoses.map((d) => d.code);
    formattedData.push({
      location: LOCATION[a.appointmentLocation],
      uae_id: uae_id.length > 0 ? 'Yes' : 'No',
      docRefNum: '',
      mobile: a.clientId[a.clientId.length - 1].phoneNumber,
      email: a.clientId[a.clientId.length - 1].email,
      dob: a.proofofidentities[0].dob,
      gender: a.clientId[0].gender,
      region: a.proofofidentities[0].nationality,
      nationality: a.proofofidentities[0].nationality,
      countryOfResidence: a.proofofidentities[0].nationality,
      addressInUAE: a.proofofidentities[0].nationality,
      admissionDate: moment(a.startTime).format('L'),
      dischargeDate: moment(a.startTime).format('L'),
      speciality: a.appointmentType[0]?.type,
      cpt1: '',
      cpt2: '',
      cpt3: '',
      cpt4: '',
      cpt5: '',
      cpt6: '',
      cpt7: '',
      cpt8: '',
      icd1: diagnosis.join(','),
      icd2: '',
      icd3: '',
      icd4: '',
      icd5: '',
      icd6: '',
      icd7: '',
      icd8: '',
      amount: a.billingId[0].amount,
    });
  });

  return formattedData;
};

const paymentReconciliationMonthlyFormat = (appointments) => {
  const formattedData = paymentReconciliationFormat(appointments);
  return formattedData;
};

const diagnosisReportFormat = (appointments) => {
  let formattedData = [];

  appointments.forEach((a, i) => {
    formattedData.push({
      SN: i,
      'Appointment Date': moment(a.startTime).format('L'),
      'Chart Number': a.clientId[a.clientId.length - 1].chartId,
      'Client Name': `${a.clientId[a.clientId.length - 1].firstName} ${
        a.clientId[a.clientId.length - 1].lastName
      }`,
      Clinic: a.address[0] && a.address[0].address1,
      'Clinician Name': `${a.clinicianId[0].firstName} ${a.clinicianId[0].lastName}`,
      'Diagnosis Added': moment(a.diagnoses[0].createdAt).format('L'),
      Age: calculateAge(new Date(a.clientId[a.clientId.length - 1].dob)),
      Nationality: '',
      Gender: a.clientId[a.clientId.length - 1].gender,
      'Diagnosis Code': a.diagnoses.map((d) => d.code).join(','),
      'Diagnosis Description': a.patientrecords[0].notes,
    });
  });
  return formattedData;
};

const clinicianNotesFormat = (clinicians) => {
  let allAppointments = [];

  let formattedData1 = [];
  let formattedData2 = [];

  clinicians.forEach((a) => {
    let totalCompletedAppointments = 0;
    let appointmentsWithNotes = 0;
    allAppointments = [...allAppointments, ...a.appointments];
    a.appointments.forEach((b) => {
      if (b.status === 'completed') {
        totalCompletedAppointments = totalCompletedAppointments + 1;
      }

      if (b.patientrecords.some((pr) => pr.notes?.trim())) {
        appointmentsWithNotes = appointmentsWithNotes + 1;
      }
    });

    formattedData1.push({
      Date: '',
      'Clinicin License Number': '',
      'Clinician Name': `${a.appointments[0].clinicianId[0].firstName} ${a.appointments[0].clinicianId[0].lastName}`,
      'Total Completed Session': totalCompletedAppointments,
      'Total Complete Notes': appointmentsWithNotes,
    });
  });

  allAppointments = allAppointments.sort(
    (a, b) => new Date(a.startTime) - new Date(b.startTime)
  );

  allAppointments.forEach((ap) => {
    formattedData2.push({
      'Appointment Date': moment(ap.startTime).format('L'),
      'Appointment Time': moment(ap.startTime).format('hh:mm a'),
      'Clinicin License Number': '',
      'Clinician Name': `${ap.clinicianId[0].firstName} ${ap.clinicianId[0].lastName}`,
      'Chart Number': ap.clientId[ap.clientId.length - 1].chartId,
      'Patient Name': `${ap.clientId[ap.clientId.length - 1].firstName} ${
        ap.clientId[ap.clientId.length - 1].lastName
      }`,
      'Appointment status': STATUSES[ap.status],
      'Appointment Type': ap.appointmentType[0]?.type,
      'Clinical Notes written': ap.patientrecords[0]?.notes?.trim()
        ? 'yes'
        : 'no',
      'Clinical Notes Qty': ap.patientrecords[0]?.notes?.trim() ? 1 : 0,
      'Clinical Notes Added': moment(ap.patientrecords[0]?.createdAt).format(
        'L'
      ),
      Time: moment(ap.patientrecords[0]?.createdAt).format('LTS'),
    });
  });

  return [formattedData1, formattedData2];
};

export {
  getInPersonReportFormat,
  pendingPaymentUpcomingFormat,
  paymentReconciliationFormat,
  newClientFormat,
  psychiatryAppointmentsFormat,
  incompleteDocUpcomingAppointmentsFormat,
  clientFlowFormat,
  numberOfClinicianSessionsFormat,
  paymentReconciliationWeeklyFormat,
  appointmentsWithPendingPaymentFormat,
  outPatientsFormat,
  emiratiPatientsFormat,
  paymentReconciliationMonthlyFormat,
  diagnosisReportFormat,
  clinicianNotesFormat,
};
