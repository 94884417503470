export const options = [
  { value: "indivisual-minor", label: "Indivisual-Minor" },
  { value: "indivisual-adult", label: "Indivisual-Adult" },
  { value: "couples", label: "Couples" },
  { value: "family", label: "Family" },
];

export const genderOption = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

export const residencyOption = [
  { value: "work", label: "Work" },
  { value: "home", label: "Home" },
];

export const stateOption = [
  { value: "Abu Dhabi", label: "Abu Dhabi" },
  { value: "Dubai", label: "Dubai" },
  { value: "Sharjah", label: "Sharjah" },
  { value: "Ajman", label: "Ajman" },
  { value: "Umm Al Quwain", label: "Umm Al Quwain" },
  { value: "Ras Al Khaima", label: "Ras Al Khaima" },
  { value: "Al Fujairah", label: "Al Fujairah" },
];

export const feeOption = [
  { value: "Packages", label: "Packages" },
  { value: "Teachers Discount", label: "Teachers Discount" },
  { value: "Special Discount", label: "Special Discount" },
];

export const clinicianOption = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Nationality", label: "Nationality" },
];

export const referenceOption = [
  { value: "Internal", label: "Internal" },
  { value: "External", label: "External" },
];

export const validIdOption = [
  {
    value: "UAE Emirates Identification Document (EID)",
    label: "UAE Emirates Identification Document (EID)",
  },
  { value: "Passport", label: "Passport" },
  {
    value: "Driving License ( issued in UAE)",
    label: "Driving License ( issued in UAE)",
  },
  { value: "GCC ID", label: "GCC ID" },
];

export const insuranceOption = [
  {
    value: "Print Invoice and Insurance",
    label: "Print Invoice and Insurance",
  },
  {
    value: "Email Invoice and Insurance",
    label: "Email Invoice and Insurance",
  },
];

export const callerOption = [
  { value: "Contacting for – Self", label: "Contacting for – Self" },
  { value: "Contacting for - Others", label: "Contacting for - Others" },
];

export const maritalOption = [
  { value: "Married", label: "Married" },
  { value: "Single", label: "Single" },
];
