/* eslint-disable indent */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-vars */
import React from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import PeopleIcon from "@material-ui/icons/People";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ListIcon from "@material-ui/icons/List";
import NotificationsIcon from "@material-ui/icons/Notifications";
import IconBarChart from "@material-ui/icons/BarChart";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import useStyles from "./Sidebar_Menu_style";


function SidebarMenu() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(!open);
  }

  return (
    <div className={classes.toolbar}>
      <List component="nav" className={classes.appMenu}>
        <Link to="/calender" className={classes.menuText}>
          <ListItem button className={classes.menuItem}>
            <ListItemIcon className={classes.menuItemIcon}>
              <CalendarTodayIcon />
            </ListItemIcon>
            <Typography className={classes.textcolor}>Calender</Typography>
          </ListItem>
        </Link>

        <Link to="/clients" className={classes.menuText}>
          <ListItem button className={classes.menuItem}>
            <ListItemIcon className={classes.menuItemIcon}>
              <PeopleIcon />
            </ListItemIcon>
            <Typography className={classes.textcolor}>Clients</Typography>
          </ListItem>
        </Link>

        <Link to="/billing" className={classes.menuText}>
          <ListItem button className={classes.menuItem}>
            <ListItemIcon className={classes.menuItemIcon}>
              <LibraryBooksIcon />
            </ListItemIcon>
            <Typography className={classes.textcolor}>Billing</Typography>
          </ListItem>
        </Link>

        <Link to="/reports" className={classes.menuText}>
          <ListItem button className={classes.menuItem}>
            <ListItemIcon className={classes.menuItemIcon}>
              <IconBarChart />
            </ListItemIcon>
            <Typography className={classes.textcolor}>Reports</Typography>
          </ListItem>
        </Link>

        <Link to="/account-activity" className={classes.menuText}>
          <ListItem button className={classes.menuItem}>
            <ListItemIcon className={classes.menuItemIcon}>
              <ListIcon />
            </ListItemIcon>
            <Typography className={classes.textcolor}>
              Account Activity
            </Typography>
          </ListItem>
        </Link>

        <Link to="/reminders" className={classes.menuText}>
          <ListItem button className={classes.menuItem}>
            <ListItemIcon className={classes.menuItemIcon}>
              <NotificationsIcon />
            </ListItemIcon>
            <Typography className={classes.textcolor}>Reminders</Typography>
          </ListItem>
        </Link>

        <Link to="/service" className={classes.menuText}>
          <ListItem button className={classes.menuItem}>
            <ListItemIcon className={classes.menuItemIcon}>
              <SettingsIcon />
            </ListItemIcon>
            <Typography className={classes.textcolor}>Services</Typography>
          </ListItem>
        </Link>
      </List>
    </div>
  );
}

export default SidebarMenu;
