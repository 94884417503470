import React, { useState, useEffect } from 'react';
import Layout from '../common/AdminLayout';
import useStyles from './serviceTypeVAT_style';
import { Grid, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import { Spinner } from '../../../components/atoms';
import axios from 'axios';
import { baseURL } from '../../../utils/axios';
import AlertMsg from '../../../utils/Alert';

export default function ServiceTypeVAT() {
  const classes = useStyles();
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    getServices();
  }, []);

  const getServices = () => {
    setLoading(true);
    axios
      .get(`${baseURL}/admin/service`, {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      })
      .then((res) => {
        if (res.data !== undefined) {
          setServices(res.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        if (e.response?.data?.reason) {
          setErrorMessage(e.response.data.reason);
        }
      });
  };

  const onChange = (e, i) => {
    const modifyingServices = [...services];
    const updatedService = { ...modifyingServices[i], vat: e.target.value };
    modifyingServices.splice(i, 1, updatedService);
    setServices(modifyingServices);
  };

  const update = () => {
    setLoading(true);
    axios
      .patch(
        `${baseURL}/admin/service`,
        { services },
        {
          headers: {
            Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        AlertMsg('success', 'Services updated!');
      })
      .catch((e) => {});
  };

  return (
    <div>
      {loading && <Spinner isOpen />}
      <>
        <Typography variant='h4' className={classes.headtxt}>
          Service Type VAT
        </Typography>
        <Grid container spacing={0} square>
          <Grid item xs={9}>
            <Paper
              elevation={0}
              square
              className={classes.gridLists}
              style={{
                textAlign: 'left',
                marginTop: '3px',
                backgroundColor: '#d1d9e5',
                color: '#003265',
              }}
            >
              <Typography
                variant='h6'
                style={{
                  textAlign: 'left',
                  fontWeight: 500,
                  marginTop: '-10px',
                  marginBottom: '-2px',
                  color: '#003265',
                }}
              >
                Service Type VAT
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper
              elevation={0}
              square
              className={classes.gridLists}
              style={{
                textAlign: 'center',
                marginTop: '3px',
                backgroundColor: '#d1d9e5',
                color: '#003265',
              }}
            >
              <Typography
                variant='h6'
                style={{
                  textAlign: 'center',
                  fontWeight: 500,
                  marginTop: '-10px',
                  marginBottom: '-2px',
                  color: '#003265',
                }}
              >
                VAT(%)
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {services &&
          services.map((service, i) => (
            <Grid container spacing={0}>
              <Grid item xs={9}>
                <Paper
                  elevation={0}
                  square
                  className={classes.gridLists}
                  style={{ color: '#003265', marginTop: '3px' }}
                >
                  <Typography
                    variant='h6'
                    style={{
                      textAlign: 'left',
                      fontWeight: 500,
                      marginTop: '-10px',
                      marginBottom: '-2px',
                      color: '#003265',
                    }}
                  >
                    {service.type}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  elevation={0}
                  square
                  className={classes.gridLists}
                  style={{ color: '#003265', marginTop: '3px' }}
                >
                  <OutlinedInput
                    className={classes.inputstyle}
                    style={{
                      align: 'center',
                      marginTop: '-15px',
                      marginBottom: '-20px',
                    }}
                    onChange={(e) => onChange(e, i)}
                    value={service.vat}
                  />
                </Paper>
              </Grid>
            </Grid>
          ))}
        <Button
          variant='contained'
          spacing={2}
          className={classes.btnn}
          style={{ marginTop: '20px', color: '#FFFFFF' }}
          onClick={update}
        >
          SAVE/UPDATE
        </Button>
      </>
    </div>
  );
}
