/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable import/order */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/state-in-constructor */
/* eslint-disable no-unreachable */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import {

  withStyles,
  FormControlLabel,
  Checkbox,
  Typography,
  Grid,
  Box,
  Divider,
  Paper,
  Button,
} from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
  getByIdClientAction,
  updateClientAction,
} from '../../../../utils/actions/client.action';
import DatePicker, { registerLocale } from 'react-datepicker';
// import en from 'date-fns/locale/en';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import Select from 'react-select';
import AlertMsg from '../../../../utils/Alert';
import Loader from '../../../../components/Loader/Loader';
import styles from './Client_Info_FormStyle';

// registerLocale('en', en);

const options = [
  { value: 'work', label: 'Work' },
  { value: 'home', label: 'Home' },
];

class ClientInfo extends React.Component {
  state = {
    checked: true,
    selectedOption: null,
    checkedA: true,
    checkedB: true,
    name: '',
    phone: '',
    email: '',
    gender: '',
    residencyType: '',
    dob: new Date(),
    state: '',
    nationality: '',
    emPhone: '',
    emName: '',
    emRelation: '',
    clientType: '',
    isLoaded: null,
  };

  componentDidMount() {
    this.props.getByIdClientAction(this.props.props.match.params.id);
  }

  componentDidUpdate(preProps) {
    const { getClientData, updateClientData } = this.props;
    if (updateClientData !== preProps.updateClientData) {
      if (updateClientData.updateClientData) {
        AlertMsg('success', 'Updated');
      }
      if (updateClientData.error) {
        AlertMsg('error', 'Failed to Update');
      }
    }
    if (getClientData !== preProps.getClientData) {
      this.setState({ isLoaded: getClientData.request });
      const data = getClientData.getByIdClientData;
      if (data) {
        this.setState({
          clientType: data.clientType,
          name: data.name,
          phone: data.phoneNumber,
          email: data.email,
          gender: data.gender,
          residencyType: data.residency,
          dob: new Date(data.dob),
          state: data.state,
          nationality: data.nationality,
          emPhone: data.emergencyContact && data.emergencyContact.phoneNumber,
          emName: data.emergencyContact && data.emergencyContact.name,
          emRelation:
            data.emergencyContact && data.emergencyContact.relationship,
        });
      }
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSortChange = (selectedSortOption) => {
    this.setState({
      selectedSortOption,
      residencyType: selectedSortOption.value,
    });
  };

  handleGenderChange = (selectedSortOption) => {
    this.setState({ selectedSortOption, gender: selectedSortOption.value });
  };

  handleSubmit = (e) => {
    const {
      selectedOption,
      clientType,
      name,
      phone,
      email,
      dob,
      residencyType,
      state,
      gender,
      nationality,
      emName,
      emPhone,
      emRelation,
    } = this.state;
    const data = {
      clientType,
      name,
      phoneNumber: phone,
      email,
      gender,
      nationality,
      dob,
      state,
      residency: residencyType,
      emergencyContact: {
        name: emName,
        phoneNumber: emPhone,
        relationship: emRelation,
      },
    };
    this.props.updateClientAction(this.props.props.match.params.id, data);
  };

  render() {
    const { classes, updateClientData, getClientData } = this.props;
    const {
      selectedOption,
      name,
      phone,
      email,
      dob,
      residencyType,
      state,
      gender,
      nationality,
      emName,
      emPhone,
      emRelation,
      isLoaded,
    } = this.state;
    const residencyValue = residencyType
      && `${residencyType[0].toUpperCase() + residencyType.slice(1)}`;
    const genderValue = gender && `${gender[0].toUpperCase() + gender.slice(1)}`;
    const sortoptions = [
      { value: 'work', label: 'Work' },
      { value: 'home', label: 'Home' },
    ];
    const genderoption = [
      { value: 'male', label: 'Male' },
      { value: 'female', label: 'Female' },
    ];
    return (
      <div className={classes.root}>
        {isLoaded ? (
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <div>
              <Grid container spacing={0}>
                <Grid item xs={8} sm={4}>
                  <Typography className={classes.title}>Name</Typography>
                  <input
                    name="name"
                    value={name}
                    onChange={this.handleChange}
                    className={classes.divstyle}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </div>

            <Divider className={classes.dividersty} />

            {/* ----------phone start----------- */}

            <div>
              <Typography className={classes.title}>Phone</Typography>
              <Typography className={classes.preferrednamesty}>
                If you want to send this client text or voice reminders, add
                their phone number(s).
              </Typography>

              <div className={classes.dividersty}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={3}>
                    <input
                      name="phone"
                      value={phone}
                      placeholder="Phone Number"
                      onChange={this.handleChange}
                      className={classes.divstyle}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </div>

              <div>
                <FormControlLabel
                  className={classes.checkedsty}
                  control={(
                    <Checkbox
                      checked={this.state.checkedA}
                      onChange={this.handleVoiceChange}
                      name="checkedA"
                      color="primary"
                    />
                  )}
                  label="Voice message ok"
                />
                {' '}
                <FormControlLabel
                  className={classes.checkedsty}
                  control={(
                    <Checkbox
                      checked={this.state.checkedB}
                      onChange={this.handleTextChange}
                      name="checkedB"
                      color="primary"
                    />
                  )}
                  label="Text message ok"
                />
              </div>

              <Button
                variant="outlined"
                size="small"
                color="primary"
                className={classes.button}
              >
                + Phone Number
              </Button>
            </div>

            <Divider className={classes.dividersty} />

            <div>
              <Checkbox
                disabled
                inputProps={{ 'aria-label': 'disabled checkbox' }}
                style={{ display: 'inline-block' }}
              />
              <Typography style={{ display: 'inline-block', color: '#003d79' }}>
                Send text/voice appointment reminders
              </Typography>
            </div>

            {/* -------phone end--------- */}

            <Divider className={classes.dividersty} />

            {/* ---------email start----------- */}

            <div>
              <Typography className={classes.title}>Email</Typography>
              <Typography className={classes.preferrednamesty}>
                If you want to send this client email reminders or billing
                documents, and to grant them Client Portal access, add their
                email address.
              </Typography>

              <div className={classes.dividersty}>
                <Grid container spacing={1}>
                  <Grid item xs={8} sm={8}>
                    <input
                      name="email"
                      value={email}
                      onChange={this.handleChange}
                      className={classes.divstyle}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            {/* -------email end----------  */}

            <Divider className={classes.dividersty} />

            {/* -----address start---------- */}
            <div className={classes.dividersty}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={2}>
                  <Typography className={classes.preferrednamesty}>
                    Date of Birth
                  </Typography>
                  <DatePicker
                    locale='en'
                    selected={dob}
                    className={classes.date}
                    onChange={(date) => this.setState({ dob: date })}
                    className={classes.date}
                  />
                </Grid>

                <Grid item xs={6} sm={2}>
                  <Typography className={classes.preferrednamesty}>
                    Gender
                  </Typography>
                  <Select
                    className={classes.select}
                    value={genderoption.filter(
                      (option) => option.label === `${genderValue}`,
                    )}
                    options={genderoption}
                    onChange={this.handleGenderChange}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={6} sm={2}>
                  <Typography className={classes.preferrednamesty}>
                    Residancy
                  </Typography>
                  <Select
                    className={classes.select}
                    value={sortoptions.filter(
                      (option) => option.label === `${residencyValue}`,
                    )}
                    options={sortoptions}
                    onChange={this.handleSortChange}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Typography className={classes.preferrednamesty}>
                    State
                  </Typography>
                  <input
                    name="state"
                    value={state}
                    onChange={this.handleChange}
                    style={{ width: '100%' }}
                    className={classes.divstyle}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Typography className={classes.preferrednamesty}>
                    Nationality
                  </Typography>
                  <input
                    name="nationality"
                    value={nationality}
                    style={{ width: '100%' }}
                    onChange={this.handleChange}
                    className={classes.divstyle}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </div>

            {/* ----address end------- */}

            <Divider className={classes.dividersty} />

            <div>
              <Typography className={classes.title}>
                Emergency Contact
              </Typography>
              <div className={classes.dividersty}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={4}>
                    <Typography className={classes.preferrednamesty}>
                      Name
                    </Typography>
                    <input
                      name="emName"
                      value={emName}
                      onChange={this.handleChange}
                      className={classes.divstyle}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography className={classes.preferrednamesty}>
                      Phone
                    </Typography>
                    <input
                      name="emPhone"
                      value={emPhone}
                      onChange={this.handleChange}
                      className={classes.divstyle}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography className={classes.preferrednamesty}>
                      Relationship
                    </Typography>
                    <input
                      name="emRelation"
                      value={emRelation}
                      onChange={this.handleChange}
                      className={classes.divstyle}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            <Button onClick={this.handleSubmit}>Update</Button>
            {/* -------about client start--------- */}
          </>
        )}
      </div>
    );
  }
}

const connected = connect(
  (state) => ({
    getClientData: state.getByIdClient,
    updateClientData: state.updateClient,
  }),
  {
    getByIdClientAction,
    updateClientAction,
  },
)(ClientInfo);

const formed = reduxForm({
  form: 'getClientById',
})(connected);
export default withStyles(styles)(formed);
