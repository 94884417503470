/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from "react";
import { createStyles, withStyles, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { EditOutlined, DeleteForeverOutlined } from "@material-ui/icons";
import Sidebar from "../common/Header";
import { CustomizedTables, StyledTableCell } from "../common/Table";
import {
  getRcadAction,
  addRcadAction,
  updateRcadAction,
  getByIdRcadAction,
  deleteRcadAction,
} from "../../../utils/actions/rcad.action";
import AddPopup from "../common/AddPopup";
import UpdatePopup from "../common/UpdatePopup";
import styles from "./Rcad.styles";
class Rcad extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAdd: false,
      isUpdate: false,
      title: "",
      id: "",
      status: "",
    };
  }

  componentDidMount() {
    this.props.getRcadAction();
  }

  componentDidUpdate(preProp) {
    if (this.props.addRcad !== preProp.addRcad) {
      if (this.props.addRcad.message === "success") {
        this.props.getRcadAction();
      }
    }
    if (this.props.updateData !== preProp.updateData) {
      if (this.props.updateData.message === "success") {
        this.props.getRcadAction();
      }
    }
    if (this.props.deleteData !== preProp.deleteData) {
      if (this.props.deleteData === "") {
        this.props.getRcadAction();
      }
    }
    if (this.props.getById !== preProp.getById) {
      const data = this.props.getById.getByIdRcadData;
      if (data) {
        this.setState({
          isUpdate: true,
          id: data._id,
          title: data.title,
          status: data.status,
        });
      }
    }
  }

  handleSubmit = () => {
    const { title, status, isAdd, isUpdate, id } = this.state;
    if (isAdd) {
      const data = {
        title,
      };
      this.props.addRcadAction(data);
      this.setState({
        isAdd: false,
        isUpdate: false,
        title: "",
        status: "",
        id: "",
      });
    }
    if (isUpdate) {
      const data = {
        title,
        status,
      };
      this.props.updateRcadAction(id, data);
      this.setState({
        isAdd: false,
        isUpdate: false,
        title: "",
        status: "",
        id: "",
      });
    }
  };

  render() {
    const { classes } = this.props;
    const header = ["Sr.No", "Title", "Status", "Action"];
    const rows = this.props.getAllRcad.map((val, i) => (
      <>
        <StyledTableCell align="left">{i + 1}</StyledTableCell>
        <StyledTableCell align="left">{val.title}</StyledTableCell>
        <StyledTableCell align="left">{val.status}</StyledTableCell>
        <StyledTableCell align="left">
          <EditOutlined
            style={{ cursor: "pointer" }}
            onClick={() => this.props.getByIdRcadAction(val._id)}
          />
          <DeleteForeverOutlined
            style={{ cursor: "pointer" }}
            onClick={() => this.props.deleteRcadAction(val._id)}
          />
        </StyledTableCell>
      </>
    ));
    const inputField = [{ lable: "Title", value: "title", fieldType: "input" }];
    const updateInputField = [
      {
        lable: "Title",
        name: "title",
        value: this.state.title,
        fieldType: "input",
      },
      {
        lable: "Status",
        name: "status",
        value: this.state.status,
        fieldType: "input",
      },
    ];
    return (
      <>
        <Sidebar />
        <div className={classes.root}>
          <div>
            {this.state.isUpdate && (
              <UpdatePopup
                headerTitle="Update RCAD"
                isOpen={this.state.isUpdate}
                inputField={updateInputField}
                onChangeInput={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
                onAdd={this.handleSubmit}
                onCancel={() => this.setState({ isUpdate: false })}
              />
            )}
            {this.state.isAdd || this.state.isUpdate ? (
              <>
                <AddPopup
                  headerTitle="Add Rcad"
                  isOpen={this.state.isAdd}
                  inputField={inputField}
                  onChangeInput={(e) =>
                    this.setState({ [e.target.name]: e.target.value })
                  }
                  onAdd={this.handleSubmit}
                  onCancel={() => this.setState({ isAdd: false })}
                />
                {/* <Typography>Add Title</Typography>
                <Input
                  placeholder="Title"
                  disableUnderline
                  value={this.state.title}
                  className={classes.input}
                  onChange={(e) => this.setState({ title: e.target.value })}
                />
                {this.state.isUpdate && (
                  <Input
                    placeholder="Status"
                    disableUnderline
                    value={this.state.status}
                    className={classes.input}
                    onChange={(e) => this.setState({ status: e.target.value })}
                  />
                )}
                <Button onClick={this.handleSubmit}>
                  {this.state.isUpdate ? "Update" : "Add"}
                </Button>
                <Button
                  onClick={() =>
                    this.setState({
                      isAdd: false,
                      isUpdate: false,
                      title: "",
                      status: "",
                      id: "",
                    })
                  }
                >
                  Cancel
                </Button> */}
              </>
            ) : (
              <Button
                className={classes.addButton}
                onClick={() => this.setState({ isAdd: true })}
              >
                Add Rcad
              </Button>
            )}
          </div>
          <CustomizedTables
            headers={header}
            rows={rows}
            loaded={this.props.loadData.request}
          />
        </div>
      </>
    );
  }
}

Rcad.defaultProps = {
  getAllRcad: [],
};

const connected = connect(
  (state) => ({
    getAllRcad: state.getRcad.getRcadData,
    loadData: state.getRcad,
    addRcad: state.addRcad.addRcadData,
    getById: state.getByIdRcad,
    updateData: state.updateRcad.updateRcadData,
    deleteData: state.deleteRcad.deleteRcadData,
  }),
  {
    getRcadAction,
    addRcadAction,
    getByIdRcadAction,
    updateRcadAction,
    deleteRcadAction,
  }
)(Rcad);

const formed = reduxForm({
  form: "rcad",
})(connected);



export default withStyles(styles)(formed);
