import React, { useRef, useState } from 'react'
import CommentsAttachmentInputView from "./CommentsAttachmentInputView";

const CommentsInputView = (props) => {
  const [note, setNote] = React.useState({});
  const [files, setFiles] = React.useState([])

  React.useEffect(() => {
    console.log(props)
    if (props.note) {
      setNote(props.note)
      setFiles(props.note?.files ? props.note?.files : [])
    }
  }, [props]);

  const handleBackImageUpload = (selectedFiles) => {
    const newFiles = []
    for (const key of Object.keys(selectedFiles)) {
      newFiles.push(selectedFiles[key])
    }
    setFiles([...files, ...newFiles])
  }

  var inputRef = useRef()

  return <div className="form p-6 bg-brand-gold-lightest">
    <div className="flex">
      <div className="inner-wrapper relative w-full">
        <input
          type="text"
          name="comment"
          value={note && note.note}
          onChange={(event) => {
            let data = { ...note };
            data.note = event.target.value;
            setNote(data);
          }}
          id
          size={5}
          className="flex-1 h-12 min-w-0 block w-full p-2 border-gray-200 sm:text-sm focus:border-gray-300 focus:ring-gray-300 focus:ring-0 pr-5"
        />
        <div className="absolute top-3 right-3 text-gray-300">
          {note?.note && <button className="cursor-pointer hover:text-brand-gold-dark pr-3"
            onClick={() => {
              setNote({note: ""})
              setFiles([])
              if (props?.onCancel) {
                props?.onCancel()
              }
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>}
          <input
            id="upfile"
            onChange={e => {
              handleBackImageUpload(e.target.files)
            }}
            type="file"
            multiple
            style={{ display: "none" }}
            ref={inputRef}
          />
          <button
            className="cursor-pointer hover:text-brand-gold-dark"
            onClick={() => {
              console.log("submit")
              inputRef.current.click()
            }}
          >
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
              />
            </svg>
          </button>
        </div>
      </div>
      <button onClick={() => {
        var formData = new FormData();
        for (const key of Object.keys(files)) {
          if (files[key] instanceof File) {
            formData.append('collection', files[key])
          }
        }
        formData.append('note', note?.note)
        formData.append('role', localStorage.getItem('__r'))
        formData.append('deletedFiles', note?.deletedFiles)
        props?.onSubmit({ ...note, formData })

        setNote({note: ""})
        setFiles([])

      }} className="px-4 py-2 text-brand-gold-dark bg-brand-gold-light text-sm border border-brand-gold-dark border-opacity-20 whitespace-nowrap hover:bg-brand-gold-dark hover:text-white">
        {note && note._id ? 'Edit Comment' : 'Add Comment'}
      </button>
    </div>
    <CommentsAttachmentInputView isEditing={true} files={files}
      onRemove={(file) => {
        if (note?._id) {
          if (!note.deletedFiles) {
            setNote({ ...note, deletedFiles: [file] })
          } else {
            setNote({ ...note, deletedFiles: [...note.deletedFiles, file] })
          }
          setFiles(files.filter(f => {
            console.log(f, file)
            return f != file;
          }))
        }
        else {
          setFiles(files.filter(f => {
            return f.name != file.name;
          }))
        }
      }}
    />
  </div>
};

export default CommentsInputView;
