/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
import React from "react";
import Sidebar from "../components/Sidebar";
import Home from "../sub_components/reports/Home";

class Reports extends React.Component {
  render() {
    return (
      <div>
        <Sidebar />
        <Home />
      </div>
    );
  }
}

export default Reports;
