/* eslint-disable react/no-unused-state */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import {

  withStyles,
  Typography,
  FormControlLabel,
  Radio,
  Divider,
  Checkbox,
  Grid,
  Button,
} from '@material-ui/core';
import Select from 'react-select';
import styles from './BillingInsuranceStyle';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

class ClientInfo extends React.Component {
  state = {
    checkedA: true,
    checkedB: true,
    selectedOption: null,
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  render() {
    const { classes } = this.props;
    const { selectedOption } = this.state;

    return (
      <div className={classes.root}>
        <div>
          <Typography className={classes.title}>Billing Type</Typography>
          <Typography className={classes.preferrednamesty}>
            How this client will typically be paying for their services.
          </Typography>
          <FormControlLabel
            value="selfpay"
            control={<Radio color="primary" />}
            label="Self-Pay"
            labelPlacement="selfpay"
            className={classes.radiosty}
          />
          <FormControlLabel
            value="insurance"
            control={<Radio color="primary" />}
            label="Insurance"
            labelPlacement="insurance"
            className={classes.radiosty}
          />
        </div>

        <Divider className={classes.dividersty} />

        <div>
          <Typography className={classes.title}>
            Autogenerate Billing Documents
          </Typography>
          <FormControlLabel
            style={{ color: '#003d79' }}
            control={(
              <Checkbox
                // checked={this.state.checkedA}
                // onChange={this.handleChangeA('checkedA')}
                value="checkedA"
                color="primary"
              />
            )}
            label="Automatically create monthly Statements for this client"
          />
          <br />
          <FormControlLabel
            style={{ color: '#003d79' }}
            control={(
              <Checkbox
                // checked={this.state.checkedB}
                // onChange={handleChangeB('checkedB')}
                value="checkedB"
                color="primary"
              />
            )}
            label="Automatically create monthly Superbills for this client"
          />
        </div>
        <Divider className={classes.dividersty} />

        <div style={{ marginBottom: 20 }}>
          <Typography className={classes.title}>
            Email Billing Notifications
          </Typography>
          <Grid container>
            <Grid item xs={6} sm={6}>
              <Select
                value={selectedOption}
                onChange={this.handleChange}
                options={options}
              />
            </Grid>
          </Grid>
        </div>

        <Divider className={classes.dividersty} />

        <div>
          <Typography className={classes.title}>
            Credit & Debit Cards
          </Typography>
          <Button variant="contained" className={classes.paymentButton}>
            Setup Stripe Payments
          </Button>
        </div>

        <Divider className={classes.dividersty} />

        <div>
          <Typography className={classes.title}>
            Insurance Information
          </Typography>
          <Button
            variant="outlined"
            size="small"
            className={classes.insuranceButton}
          >
            + Insurance info
          </Button>
        </div>

        <Divider className={classes.dividersty} />

        <div>
          <Typography className={classes.title}>
            Authorization tracking
          </Typography>
          <Button
            variant="outlined"
            size="small"
            className={classes.insuranceButton}
          >
            + Authorization number
          </Button>
        </div>

        <Divider className={classes.dividersty} />

        <div>
          <Typography className={classes.title}>
            Insurance Claim/CMS Fields
          </Typography>
          <FormControlLabel
            style={{ color: '#003d79' }}
            control={(
              <Checkbox
                // checked={this.state.checkedB}
                // onChange={handleChangeB('checkedB')}
                value="checkedB"
                color="primary"
              />
            )}
            label="Show additional claim fields"
          />
        </div>

        <Divider className={classes.dividersty} />

        <div>
          <Typography className={classes.title}>
            Client Default Services
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={10} sm={4}>
              <Typography className={classes.heading}>Service Code</Typography>
              <Select
                value={selectedOption}
                onChange={this.handleChange}
                options={options}
                className={classes.selectsty}
              />
            </Grid>

            <Grid item xs={10} sm={6}>
              <Typography className={classes.heading}>Rate Per Unit</Typography>
            </Grid>
          </Grid>
        </div>

        <Divider className={classes.dividersty} />

        <div>
          <Button
            variant="outlined"
            size="small"
            className={classes.cancelButton}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            size="small"
            className={classes.saveButton}
          >
            Save Client
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ClientInfo);
