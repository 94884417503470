import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { DropzoneArea } from 'material-ui-dropzone';
import profile from '../../../assets/imgs/ananyaBig.png';
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Typography,
  Input,
  Switch,
  withStyles,
  FormControlLabel,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import '../../../clinician/clinician.scss';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Axios from 'axios';
import { baseURL } from '../../../utils/axios';
import AlertMsg from '../../../utils/Alert';
import { DISCIPLINES, USER_ROLES } from '../../../utils/enums';
import CircularProgress from '@material-ui/core/CircularProgress';
import Layout from '../common/AdminLayout';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import Loader from '../../../components/Loader/Loader';
import useStyles from './AddStaffMember.styles';
import SelectClinicAddress from '../common/SelectClinicAddresses';
import { Dialog, ImageUploader, Spinner } from '../../../components/atoms';
import { useSelector } from 'react-redux';

export const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 36,
    height: 20,
    padding: 0,
    margin: theme.spacing(1),
    transform: 'scaleX(-1)',
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

let initState = {
  avatar:
    'https://lighthouse-media.s3.us-east-2.amazonaws.com/defaultAvatar.png',
  firstName: '',
  lastName: '',
  email: '',
  gender: '',
  userRole: '',
  role: '',
  bio: '',
  notes: '',
  phoneNumber: '',
  phoneType: 'mobile',
  password: '',
  confPassword: '',
  address: '',
  prefix: '',
  suffix: '',
};

export function AddStaffMember() {
  const classes = useStyles();
  const history = useHistory();
  const [details, setDetails] = useState({ ...initState });
  const [btnLoading, setBtnLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [activeSpecialities, setActiveSpecialities] = useState({});
  const [profileImage, setProfileImage] = useState(profile);
  const [specialityList, setSpecialityList] = useState([]);
  const [genralInfo, setGenralInfo] = useState(null);
  const [isClinicianRole, setIsClinicianRole] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [staffId, setStaffId] = useState('');
  const [openSuspendDialog, setOpenSuspendDialog] = useState(false);
  const [addressesList, setAddressesList] = useState([]);

  const getAddressesLists = () => {
    Axios.get(`${baseURL}/api/clinic_addresses`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          setAddressesList(res.data.data);
        }
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const readFile = (file) => {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();

      reader.onload = function (event) {
        var _event$target;
        resolve(
          event === null || event === void 0
            ? void 0
            : (_event$target = event.target) === null ||
              _event$target === void 0
              ? void 0
              : _event$target.result
        );
      };

      reader.onerror = function (event) {
        reader.abort();
        reject(event);
      };
      reader.readAsDataURL(file);
    });
  };

  const getRoles = () => {
    Axios.get(`${baseURL}/admin/role`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res && res.data.length) {
          let roles = res.data.map((item) => ({
            name: item.role,
            id: item._id,
          }));
          setRoleList(roles);
        }
      })
      .catch(() => { });
  };

  const getSpecialityMapList = () => {
    Axios.get(`${baseURL}/admin/speciality_map`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        setSpecialityList(res.data);
        let genInfo = res.data.filter((item) => item.title === 'GENERAL');

        setGenralInfo(genInfo.length > 0 ? genInfo[0] : null);
      })
      .catch(() => { });
  };

  useEffect(() => {
    var currentURL = window.location.href;
    var id = currentURL.substring(currentURL.lastIndexOf('/') + 1);
    if (id != 'add-staff') {
      setIsEdit(true);
      setLoading(true);
      getUserDetail(id);
      setStaffId(id);
    }
    getRoles();
    getSpecialityMapList();
    getAddressesLists();
  }, []);

  const getUserDetail = async (id) => {
    Axios.get(`${baseURL}/api/user/userDetail/${id}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        const data = res.data;
        delete data.password;
        data.address = data.address.map(address => address._id)
        setProfileImage(data.avatar ? data.avatar : profile);
        const result = { ...data, role: data.role ? data.role : '' };
        // checking for clinician
        localStorage.setItem(
          'accecpt_new',
          data.acceptNewPatients ? 'true' : 'false'
        );
        if (data.role == '5fc6975e834f74702c7e70fc') {
          setIsClinicianRole(true);
          getClinicianSpecialityMapping(data._id);
        } else {
          setIsClinicianRole(false);
        }
        setDetails(result);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleImageUpload = async (files) => {
    if (files.length > 0) {
      var result = await readFile(files[0]);
      console.log(files[0]);
      await documentUpload(files[0]);
      setProfileImage(result);
    }
  };

  const getFirstName = (name) => {
    const temp = name.split(' ');
    if (temp[0] == 'mr' || temp[0] == 'mrs') {
      return temp[1];
    } else {
      return temp[0];
    }
  };

  const handleSuspend = () => {
    let body = JSON.parse(JSON.stringify(details));
    body.active = !details.active;

    Axios.put(`${baseURL}/api/user/updateUser/${staffId}`, body, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          setBtnLoading(false);
          AlertMsg('success', 'Staff member suspended successfully.');
          history.push('/admin/staff-members');
        }
      })
      .catch((e) => {
        setBtnLoading(false);

        AlertMsg(
          'error',
          e.response && e.response.message
            ? e.response.message
            : 'Something went wrong!'
        );
      });
  };

  const handleChangeGenralInfo = (evt, name) => {
    if (name === 'ageRange') {
      // console.log("evt.val", evt.target.value);
      let value = evt.target.value;
      if (evt.target.name === 'minAge') {
        // console.log("minAge");
        setDetails((preState) => {
          let newState = { ...preState };
          let ageRange = newState.patientAgeGroup;
          let newAgeRange = newState.patientAgeGroup;
          if (ageRange) {
            ageRange = ageRange.split('-');
            newAgeRange = `${value}-${ageRange.length > 1 ? ageRange[1] : '0'}`;
          } else {
            newAgeRange = `${value}-${genralInfo.specialities[0].filter > 1
                ? genralInfo.specialities[0].filter.max
                : '0'
              }`;
          }
          newState.patientAgeGroup = newAgeRange;
          return newState;
        });
      } else if (evt.target.name === 'maxAge') {
        // console.log("minAge");
        setDetails((preState) => {
          let newState = { ...preState };
          let ageRange = newState.patientAgeGroup;
          let newAgeRange = newState.patientAgeGroup;
          if (ageRange) {
            ageRange = ageRange.split('-');
            newAgeRange = `${ageRange.length > 0 ? ageRange[0] : '0'}-${value}`;
          } else {
            newAgeRange = `${genralInfo.specialities[0].filter > 1
                ? genralInfo.specialities[0].filter.min
                : '0'
              }-${value}`;
          }
          newState.patientAgeGroup = newAgeRange;
          return newState;
        });
      }
    } else {
      setDetails({ ...details, [name]: evt.target.value });
    }
  };

  const handleInputChange = (event, spec) => {
    if (
      spec.filter &&
      event.target.value >= spec.filter.min &&
      event.target.value <= spec.filter.max
    ) {
      setActiveSpecialities({
        ...activeSpecialities,
        [spec._id]: event.target.value,
      });
    }
    return null;
  };

  const handleSwitchChange = (event, id) => {
    setActiveSpecialities({
      ...activeSpecialities,
      [id]: event.target.checked,
    });
  };

  const saveSpecialityDetails = (clinicianId) => {
    let body = { specialities: [] };
    for (const [key, value] of Object.entries(activeSpecialities)) {
      body.specialities.push({
        speciality: key,
        value: typeof value === 'boolean' ? (value ? '1' : '0') : value,
      });
    }
    Axios.post(`${baseURL}/api/clinician_speciality_map/` + clinicianId, body, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then(() => {
        setIsClinicianRole(false);
        setBtnLoading(false);
        AlertMsg('success', 'Clinician added successfully');
        history.push('/admin/staff-members');
      })
      .catch((e) => {
        setBtnLoading(false);

        AlertMsg(
          'error',
          e.response ? e.response.message : 'Something went wrong!'
        );
      });
  };

  const getClinicianSpecialityMapping = (id) => {
    Axios.get(`${baseURL}/api/clinician_speciality_map/clinician/` + id, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data.length) {
          let fieldSpec = { ...activeSpecialities };
          res.data.map((item) => {
            if (item.specialities && item.specialities.length) {
              for (let key in item.specialities) {
                if (item.specialities[key].speciality) {
                  let speciality = item.specialities[key].speciality;
                  if (
                    speciality.filter &&
                    speciality.filter.min === 0 &&
                    speciality.filter.max === 1
                  ) {
                    fieldSpec[speciality._id] =
                      item.specialities[key].value == '1' ? true : false;
                  } else {
                    fieldSpec[speciality._id] = item.specialities[key].value
                      ? parseFloat(item.specialities[key].value)
                      : '';
                  }
                }
              }
            }
          });
          setActiveSpecialities(fieldSpec);
        }
      })
      .catch(() => { });
  };

  const saveUserDetails = () => {
    let body = JSON.parse(JSON.stringify(details));
    body.verify = 'true';

    Axios.post(`${baseURL}/api/user/createUser`, body, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (isClinicianRole) {
          saveSpecialityDetails(res.data._id);
        } else {
          setBtnLoading(false);
          AlertMsg('success', 'Staff member added successfully.');
          history.push('/admin/staff-members');
        }
      })
      .catch((e) => {
        setBtnLoading(false);

        AlertMsg(
          'error',
          e.response && e.response.message
            ? e.response.message
            : 'Something went wrong!'
        );
      });
  };

  const updateUserDetails = (id) => {
    let body = JSON.parse(JSON.stringify(details));
    console.log('body 132', body);
    Axios.put(`${baseURL}/api/user/updateUser/${id}`, body, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (isClinicianRole) {
          saveSpecialityDetails(res.data._id);
        } else {
          setBtnLoading(false);
          AlertMsg('success', 'Staff member added successfully.');
          history.push('/admin/staff-members');
        }
      })
      .catch((e) => {
        setBtnLoading(false);

        AlertMsg(
          'error',
          e.response && e.response.message
            ? e.response.message
            : 'Something went wrong!'
        );
      });
  };

  const handleUpdateDetails = (id) => {
    if (
      !details.email ||
      !details.firstName.trim() ||
      !details.lastName.trim() ||
      !details.role ||
      !details.gender
    ) {
      AlertMsg('error', 'Please fill required fileds!');
      return false;
    }

    if (details.password !== details.confPassword) {
      AlertMsg('error', 'Password and confirm password must be same!');
      return false;
    }

    if (details.password == '') {
      delete details.password;
      delete details.confPassword;
    }

    // setBtnLoading(true);
    updateUserDetails(id);
  };

  const handleSaveDetails = () => {
    let fieldName;
    if (!details.email.trim()) {
      fieldName = 'Email';
    } else if (!details.firstName.trim()) {
      fieldName = 'First Name';
    } else if (!details.lastName.trim()) {
      fieldName = 'Last Name';
    } else if (!details.role) {
      fieldName = 'Role';
    } else if (!details.password) {
      fieldName = 'Password';
    } 
    // else if (details.address.trim() === '') {
    //   fieldName = 'Address';
    // } 
    else if (details.phoneNumber.trim() === '') {
      fieldName = 'Phone Number';
    }

    if (fieldName) {
      AlertMsg('error', `${fieldName} is required!`);
      return false;
    }

    if (!details.address) {
      AlertMsg('error', 'Select a address to continue!');
      return false;
    }

    if (details.password !== details.confPassword) {
      AlertMsg('error', 'Password and confirm password must be same!');
      return false;
    }

    setBtnLoading(true);
    saveUserDetails();
  };

  const handleInfoChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    const updatePath = name.split('.');
    if (updatePath.length > 1) {
      setDetails((prevState) => {
        let newState = { ...prevState };
        newState.address = { ...newState.address, [updatePath[1]]: value };
        return newState;
      });
    } else {
      setDetails({ ...details, [name]: value });
      if (name === 'role') {
        let selectedRole = roleList.filter((itm) => {
          if (itm.name === 'clinician' && value === itm.id) return itm;
        });
        if (selectedRole.length) {
          setIsClinicianRole(true);
        } else {
          setIsClinicianRole(false);
        }
      }
    }
  };

  const documentUpload = async (selectedFile) => {
    setBtnLoading(true);
    const formData = new FormData();
    formData.append('document', selectedFile);
    // console.log('details', details);
    // console.log('formData', formData);
    Axios.post(
      `${baseURL}/api/user/upload?type=profileImage&for=staff&staffId=${details._id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        setBtnLoading(false);
        setProfileImage(res.data && res.data.url && res.data.url);
      })
      .catch((e) => {
        setBtnLoading(false);
        AlertMsg(
          'success',
          e.response ? e.response.message : 'Something went wrong!'
        );
      });
  };

  const addAddress = (addressId) => {
    if (!details.address.includes(addressId)) {
      setDetails({ ...details, address: [...details.address, addressId] });
    } else if (details.address.includes(addressId)) {
      let updatedAddress = details.address.filter((ad) => ad !== addressId);
      setDetails({ ...details, address: updatedAddress });
    }
  };
  return (
    <>
      {btnLoading && <Spinner isOpen />}
      {loading ? (
        <Grid container justify='center'>
          <Loader />
        </Grid>
      ) : (
        <Grid>
          <Grid container spacing={3} className={classes.infoMain}>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Typography className={classes.inputText}>
                  Basic Information
                </Typography>
                <Typography className={classes.inputTitle}>
                  Profile Image*
                </Typography>
                <Grid
                  container
                  style={{ marginBottom: '15px', height: '150px', width: '150px' }}
                >
                  <ImageUploader
                    src={profileImage}
                    uploadedImage={handleImageUpload}
                  />
                </Grid>
                <Grid container style={{ marginBottom: '10px' }}>
                  <Grid item xs={4} style={{ paddingRight: '10px' }}>
                    <Typography className={classes.inputTitle}>
                      Prefix
                    </Typography>
                    <FormControl className={classes.inputsty}>
                      <Select
                        name='prefix'
                        value={details.prefix}
                        onChange={handleInfoChange}
                      >
                        <MenuItem value=''>Select</MenuItem>
                        <MenuItem value='Dr.'>Dr</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography className={classes.inputTitle}>
                      First Name*
                    </Typography>
                    <Input
                      className={classes.inputsty}
                      type='text'
                      value={
                        isEdit
                          ? getFirstName(details.firstName)
                          : details.firstName
                      }
                      onChange={handleInfoChange}
                      name='firstName'
                    />
                  </Grid>

                  <Grid item xs={8} style={{ paddingRight: '10px' }}>
                    <Typography className={classes.inputTitle}>
                      Last Name*
                    </Typography>
                    <Input
                      className={classes.inputsty}
                      type='text'
                      value={details.lastName}
                      onChange={handleInfoChange}
                      name='lastName'
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Typography className={classes.inputTitle}>
                      Suffix
                    </Typography>
                    <Input
                      className={classes.inputsty}
                      type='text'
                      value={details.suffix}
                      onChange={handleInfoChange}
                      name='suffix'
                    />
                  </Grid>
                  <Grid item xs={7} style={{ paddingRight: '10px' }}>
                    <Typography className={classes.inputTitle}>
                      Email
                    </Typography>
                    <Input
                      className={classes.inputsty}
                      type='email'
                      name='email'
                      onChange={handleInfoChange}
                      value={details.email}
                    />
                  </Grid>
                  <Grid item xs={5} style={{ paddingLeft: '10px' }}>
                    <Typography className={classes.inputTitle}>
                      Gender
                    </Typography>
                    <FormControl className={classes.inputsty}>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        name='gender'
                        value={details.gender}
                        onChange={handleInfoChange}
                      >
                        <MenuItem value={''}>Select</MenuItem>
                        <MenuItem value={'male'}>Male</MenuItem>
                        <MenuItem value={'female'}>Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} style={{ paddingRight: '10px' }}>
                    <Typography className={classes.inputTitle}>
                      Password*
                    </Typography>
                    <Input
                      className={classes.inputsty}
                      type='password'
                      placeholder={'Password'}
                      value={details.password ? details.password : ''}
                      onChange={handleInfoChange}
                      name='password'
                    />
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: '10px' }}>
                    <Typography className={classes.inputTitle}>
                      Confirm Password*
                    </Typography>
                    <Input
                      className={classes.inputsty}
                      type='password'
                      placeholder={'Confirm Password'}
                      value={details.confPassword ? details.confPassword : ''}
                      onChange={handleInfoChange}
                      name='confPassword'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.inputTitle}>
                      Role/Group
                    </Typography>
                    <FormControl className={classes.inputsty}>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        name='role'
                        value={details.role ? details.role : ''}
                        onChange={handleInfoChange}
                      >
                        <MenuItem value={null}>Select Role</MenuItem>
                        {roleList.length > 0 &&
                          roleList.map((item, i) => (
                            <MenuItem key={`lhId-${i}`} value={item.id}>
                              {USER_ROLES[item.name]}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Typography className={classes.inputText}>
                  Contact Information
                </Typography>
                <Grid container style={{ marginBottom: '15px' }}>
                  <Grid item xs={6} style={{ paddingRight: '10px' }}>
                    <Typography className={classes.inputTitle}>
                      Phone Number*
                    </Typography>
                    <input
                      className={classes.inputsty}
                      type='text'
                      name='phoneNumber'
                      value={details.phoneNumber}
                      onChange={handleInfoChange}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: '10px' }}>
                    <Typography className={classes.inputTitle}>
                      Type*
                    </Typography>
                    <FormControl className={classes.inputsty}>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        name='phoneType'
                        defaultValue='Mobile'
                        value={details.phoneType}
                        onChange={handleInfoChange}
                      >
                        <MenuItem value={'mobile'}>Mobile</MenuItem>
                        <MenuItem value={'phone'}>Phone</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid style={{ marginBottom: '15px' }}>
                  <Typography className={classes.inputTitle}>
                    Address*
                  </Typography>
                  {false && (
                    <SelectClinicAddress
                      name='address'
                      value={details.address}
                      onSelected={(data) => {
                        let temp = details;
                        temp.address = data;
                        setDetails(temp);
                      }}
                    />
                  )}

                  {addressesList.map((address) => (
                    <div>
                      <input
                        type='checkbox'
                        value={address._id}
                        onClick={() => addAddress(address._id)}
                        checked={details.address.includes(address._id)}
                      />
                      <label className='ml-2'>
                        {address.title ? address.title : address.address1}
                      </label>
                    </div>
                  ))}
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          {isClinicianRole ? (
            <>
              {genralInfo && (
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Typography className={classes.inputText}>
                      General Information
                    </Typography>
                    <Grid container>
                      <Grid item xs={6} style={{ padding: '0 15px 0 0' }}>
                        <Grid style={{ marginBottom: '15px' }}>
                          <Typography className={classes.inputTitle}>
                            Discipline*
                          </Typography>
                          <FormControl className={classes.inputsty}>
                            <Select
                              labelId='demo-simple-select-label'
                              value={details.discipline}
                              onChange={(evt) =>
                                handleChangeGenralInfo(evt, 'discipline')
                              }
                              id='demo-simple-selectq'
                            >
                              <MenuItem value={''}>Select</MenuItem>
                              {/* {genralInfo} */}
                              {Object.keys(DISCIPLINES).map((item, i) => (
                                <MenuItem key={`lhId-${i}`} value={item}>
                                  {DISCIPLINES[item]}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid style={{ marginBottom: '15px' }}>
                          <Typography className={classes.inputTitle}>
                            Description
                          </Typography>
                          <Input
                            className={classes.inputsty}
                            type='text'
                            name='disciplineDescription'
                            onChange={(evt) =>
                              handleChangeGenralInfo(
                                evt,
                                'disciplineDescription'
                              )
                            }
                            value={details.disciplineDescription}
                          />
                        </Grid>
                        <Grid style={{ marginBottom: '15px' }}>
                          <Typography className={classes.inputTitle}>
                            Patient Age Range
                          </Typography>
                          <Grid className={classes.rangeField}>
                            <FormControl style={{ width: '100%' }}>
                              <TextField
                                className={classes.inputsty}
                                type='text'
                                name='minAge'
                                // InputProps={{
                                //   min: parseInt(
                                //     genralInfo.specialities[0].filter.min
                                //   ),
                                //   max: parseInt(
                                //     genralInfo.specialities[0].filter.max
                                //   ),
                                // }}
                                value={
                                  details.patientAgeGroup &&
                                    details.patientAgeGroup.split('-')[0]
                                    ? details.patientAgeGroup.split('-')[0]
                                    : ''
                                }
                                onChange={(evt) =>
                                  handleChangeGenralInfo(evt, 'ageRange')
                                }
                              />
                            </FormControl>
                            <Typography component='span'>To</Typography>
                            <FormControl style={{ width: '100%' }}>
                              <TextField
                                className={classes.inputsty}
                                type='text'
                                name='maxAge'
                                // InputProps={{
                                //   min: genralInfo.specialities[0].filter.min,
                                //   max: genralInfo.specialities[0].filter.max,
                                // }}
                                value={
                                  details.patientAgeGroup
                                    ? details.patientAgeGroup.split('-')[1]
                                    : ''
                                }
                                onChange={(evt) =>
                                  handleChangeGenralInfo(evt, 'ageRange')
                                }
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} style={{ padding: '0 0 0 15px' }}>
                        <Grid style={{ marginBottom: '15px' }}>
                          <Typography className={classes.inputTitle}>
                            Notes
                          </Typography>
                          <FormControl style={{ width: '100%' }}>
                            <TextareaAutosize
                              value={details.notes}
                              rowsMin={7}
                              onChange={(evt) =>
                                handleChangeGenralInfo(evt, 'notes')
                              }
                              placeholder='Notes'
                              className={classes.textareaField}
                            />
                          </FormControl>
                        </Grid>
                        {false && (
                          <Grid style={{ marginBottom: '15px' }}>
                            <Typography className={classes.inputTitle}>
                              Personal Biography
                            </Typography>
                            <FormControl style={{ width: '100%' }}>
                              <TextareaAutosize
                                value={details.bio}
                                rowsMin={7}
                                onChange={(evt) =>
                                  handleChangeGenralInfo(evt, 'bio')
                                }
                                placeholder='Biography'
                                className={classes.textareaField}
                              />
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )}
              <Grid container style={{ marginTop: '20px' }}>
                {specialityList.length > 0 &&
                  specialityList.map((item, index) =>
                    item.title !== 'GENERAL' ? (
                      <Grid className={classes.cardOuter} key={index}>
                        <h4>{item.title}</h4>
                        {item.specialities &&
                          item.specialities.length > 0 &&
                          item.specialities.map((spec) => {
                            return (
                              <React.Fragment key={spec._id}>
                                <Grid
                                  className='innerCells'
                                  key={`inn_${spec._id}`}
                                >
                                  <h5>{spec.description}</h5>
                                  {(spec.filter &&
                                    spec.filter.min === 0 &&
                                    spec.filter.max === 1) ||
                                    !spec.filter ? (
                                    <span
                                      className={
                                        (classes.switches, classes.switchBtn)
                                      }
                                    >
                                      <Typography component='label'>
                                        Yes
                                      </Typography>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={
                                              activeSpecialities[spec._id]
                                                ? true
                                                : false
                                            }
                                            onChange={(evt) =>
                                              handleSwitchChange(evt, spec._id)
                                            }
                                          />
                                        }
                                      />
                                      <Typography component='label'>
                                        No
                                      </Typography>
                                    </span>
                                  ) : null}
                                  {spec.filter && spec.filter.max > 1 && (
                                    <span className={classes.switches}>
                                      <TextField
                                        id='outlined-basic'
                                        value={
                                          activeSpecialities[spec._id]
                                            ? activeSpecialities[spec._id]
                                            : ''
                                        }
                                        onChange={(evt) =>
                                          handleInputChange(evt, spec)
                                        }
                                        className={classes.inputs}
                                        variant='outlined'
                                        InputProps={{
                                          min: spec.filter.min,
                                          max: spec.filter.max,
                                        }}
                                      />
                                    </span>
                                  )}
                                </Grid>
                              </React.Fragment>
                            );
                          })}
                      </Grid>
                    ) : null
                  )}
              </Grid>
            </>
          ) : null}
          <Grid item xs={12}>
            {!isEdit ? (
              <Button
                disabled={btnLoading}
                style={{ float: 'right', marginRight: 35 }}
                variant='contained'
                color='primary'
                onClick={handleSaveDetails}
              >
                {btnLoading ? (
                  <CircularProgress size={20} color={'white'} />
                ) : (
                  'Submit'
                )}
              </Button>
            ) : (
              <div>
                <Button
                  disabled={btnLoading}
                  style={{ float: 'right', marginRight: 35 }}
                  variant='contained'
                  color='primary'
                  onClick={() => handleUpdateDetails(staffId)}
                >
                  {btnLoading ? (
                    <CircularProgress size={20} color={'white'} />
                  ) : (
                    'Update'
                  )}
                </Button>
                <Button
                  disabled={btnLoading}
                  style={{ float: 'right', marginRight: 35 }}
                  variant='contained'
                  color='primary'
                  onClick={() => setOpenSuspendDialog(true)}
                >
                  {btnLoading ? (
                    <CircularProgress size={20} color={'white'} />
                  ) : details.active ? (
                    'Suspend'
                  ) : (
                    'Activate'
                  )}
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      )}
      <Dialog
        open={openSuspendDialog}
        handleClose={() => setOpenSuspendDialog(false)}
      >
        <DialogTitle id='alert-dialog-title'>
          {details.active ? 'Suspend' : 'Activate' + ' this account?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSuspendDialog(false)} color='primary'>
            Disagree
          </Button>
          <Button onClick={handleSuspend} color='primary' autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
