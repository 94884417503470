import { createStyles} from "@material-ui/core";

export default (theme) => createStyles({
    container: {
      flex: 1,
      justifyContent: 'flex-start',
      padding: 40
    },
    boxes: {
      display: 'flex',
      marginTop: '22px',
    },
  });