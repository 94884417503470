/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
import {
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAILED,
  ADD_CLIENT_REQUEST,
  GET_CLIENT_REQUEST,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAILED,
  GET_BY_ID_CLIENT_FAILED,
  GET_BY_ID_CLIENT_SUCCESS,
  GET_BY_ID_CLIENT_REQUEST,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILED,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILED,
  ADD_CLIENT_NOTE_REQUEST,
  ADD_CLIENT_NOTE_SUCCESS,
  ADD_CLIENT_NOTE_FAILED,
  CLIENT_MAIL_REQUEST,
  CLIENT_MAIL_SUCCESS,
  CLIENT_MAIL_FAILED,
  ADD_CHILD_REQ,
  ADD_CHILD_SUCCESS,
  ADD_CHILD_FAILED,
  ADD_ADULT_REQ,
  ADD_ADULT_SUCCESS,
  ADD_ADULT_FAILED,
  GET_CHILD_REQ,
  GET_CHILD_SUCCESS,
  GET_CHILD_FAILED,
  GET_CHILD_BY_ID_REQ,
  GET_CHILD_BY_ID_SUCCESS,
  GET_CHILD_BY_ID_FAILED,
  UPDATE_CHILD_REQ,
  UPDATE_CHILD_SUCCESS,
  UPDATE_CHILD_FAILED,
  UPLOAD_CLIENT_IMAGE_REQUEST,
  UPLOAD_CLIENT_IMAGE_SUCCESS,
  UPLOAD_CLIENT_IMAGE_FAILED,
  GET_EMPLOYER_REQUEST,
  GET_EMPLOYER_SUCCESS,
  GET_EMPLOYER_FAILED,
  UPDATE_PHARMACY_REQUEST,
  UPDATE_PHARMACY_SUCCESS,
  UPDATE_PHARMACY_FAILED,
  GET_PHARMACY_REQUEST,
  GET_PHARMACY_SUCCESS,
  GET_PHARMACY_FAILED,
  SET_EMPLOYER_ID_REQUEST,
  SET_EMPLOYER_ID_SUCCESS,
  SET_EMPLOYER_ID_FAILED,
  UPLOAD_DOC_FILE_REQUEST,
  UPLOAD_DOC_FILE_SUCCESS,
  UPLOAD_DOC_FILE_FAILED,
  GET_CLIENT_DATA_REQUEST,
  GET_CLIENT_DATA_SUCCESS,
  GET_CLIENT_DATA_FAILED,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILED,
  SAVE_CLIENT_SIGNATURE_REQUEST,
  SAVE_CLIENT_SIGNATURE_SUCCESS,
  SAVE_CLIENT_SIGNATURE_FAILED,
  GET_BUNDLE_APPOINTMENT_REQUEST,
  GET_BUNDLE_APPOINTMENT_SUCCESS,
  GET_BUNDLE_APPOINTMENT_FAILED,
  CLIENT_CONSENT_FORMS_REQUEST,
  CLIENT_CONSENT_FORMS_SUCCESS,
  CLIENT_CONSENT_FORMS_FAILED,
  GET_CLIENTS_REPORT_REQUEST,
  GET_CLIENTS_REPORT_SUCCESS,
  GET_CLIENTS_REPORT_FAILED,
} from '../constant';

export const addClient = (state = [], action) => {
  switch (action.type) {
    case ADD_CLIENT_REQUEST:
      return {
        request: true,
      };
    case ADD_CLIENT_SUCCESS:
      return {
        request: false,
        addClientData: action.response,
      };
    case ADD_CLIENT_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getClient = (state = [], action) => {
  switch (action.type) {
    case GET_CLIENT_REQUEST:
      return {
        request: true,
      };
    case GET_CLIENT_SUCCESS:
      return {
        request: false,
        getClientData: action.response,
      };
    case GET_CLIENT_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getByIdClient = (state = [], action) => {
  switch (action.type) {
    case GET_BY_ID_CLIENT_REQUEST:
      return {
        request: true,
      };
    case GET_BY_ID_CLIENT_SUCCESS:
      return {
        request: false,
        getByIdClientData: action.response,
      };
    case GET_BY_ID_CLIENT_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const updateClient = (state = [], action) => {
  switch (action.type) {
    case UPDATE_CLIENT_REQUEST:
      return {
        request: true,
      };
    case UPDATE_CLIENT_SUCCESS:
      return {
        request: false,
        updateClientData: action.response,
      };
    case UPDATE_CLIENT_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const deleteClient = (state = [], action) => {
  switch (action.type) {
    case DELETE_CLIENT_REQUEST:
      return {
        request: true,
      };
    case DELETE_CLIENT_SUCCESS:
      return {
        request: false,
        deleteClientData: action.response,
      };
    case DELETE_CLIENT_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const addClientNote = (state = [], action) => {
  switch (action.type) {
    case ADD_CLIENT_NOTE_REQUEST:
      return {
        request: true,
      };
    case ADD_CLIENT_NOTE_SUCCESS:
      return {
        request: false,
        addClientNoteData: action.response,
      };
    case ADD_CLIENT_NOTE_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const sendMail = (state = [], action) => {
  switch (action.type) {
    case CLIENT_MAIL_REQUEST:
      return {
        request: true,
      };
    case CLIENT_MAIL_SUCCESS:
      return {
        request: false,
        clientMailData: action.response,
      };
    case CLIENT_MAIL_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const addChild = (state = [], action) => {
  switch (action.type) {
    case ADD_CHILD_REQ:
      return {
        request: true,
      };
    case ADD_CHILD_SUCCESS:
      return {
        request: false,
        addChildData: action.response,
      };
    case ADD_CHILD_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const addAdult = (state = [], action) => {
  switch (action.type) {
    case ADD_ADULT_REQ:
      return {
        request: true,
      };
    case ADD_ADULT_SUCCESS:
      return {
        request: false,
        addAdultData: action.response,
      };
    case ADD_ADULT_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getChild = (state = [], action) => {
  switch (action.type) {
    case GET_CHILD_REQ:
      return {
        request: true,
      };
    case GET_CHILD_SUCCESS:
      return {
        request: false,
        getChildData: action.response,
      };
    case GET_CHILD_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getChildById = (state = [], action) => {
  switch (action.type) {
    case GET_CHILD_BY_ID_REQ:
      return {
        request: true,
      };
    case GET_CHILD_BY_ID_SUCCESS:
      return {
        request: false,
        getChildByIdData: action.response,
      };
    case GET_CHILD_BY_ID_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const updateChild = (state = [], action) => {
  switch (action.type) {
    case UPDATE_CHILD_REQ:
      return {
        request: true,
      };
    case UPDATE_CHILD_SUCCESS:
      return {
        request: false,
        updateChildData: action.response,
      };
    case UPDATE_CHILD_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const uploadClientImage = (state = [], action) => {
  switch (action.type) {
    case UPLOAD_CLIENT_IMAGE_REQUEST:
      return {
        request: true,
      };
    case UPLOAD_CLIENT_IMAGE_SUCCESS:
      return {
        request: false,
        uploadClientImageData: action.response,
      };
    case UPLOAD_CLIENT_IMAGE_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getEmployer = (state = [], action) => {
  switch (action.type) {
    case GET_EMPLOYER_REQUEST:
      return {
        request: true,
      };
    case GET_EMPLOYER_SUCCESS:
      return {
        request: false,
        getEmployerData: action.response,
      };
    case GET_EMPLOYER_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const updatePharmacy = (state = [], action) => {
  switch (action.type) {
    case UPDATE_PHARMACY_REQUEST:
      return {
        request: true,
      };
    case UPDATE_PHARMACY_SUCCESS:
      return {
        request: false,
        getPharmacyData: action.response,
      };
    case UPDATE_PHARMACY_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getPharmacy = (state = [], action) => {
  switch (action.type) {
    case GET_PHARMACY_REQUEST:
      return {
        request: true,
      };
    case GET_PHARMACY_SUCCESS:
      return {
        request: false,
        pharmacyData: action.response,
      };
    case GET_PHARMACY_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getEmployerId = (state = [], action) => {
  switch (action.type) {
    case SET_EMPLOYER_ID_REQUEST:
      return {
        request: true,
      };
    case SET_EMPLOYER_ID_SUCCESS:
      return {
        request: false,
        getEmployerIdData: action.response,
      };
    case SET_EMPLOYER_ID_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const uploadDocFile = (state = [], action) => {
  switch (action.type) {
    case UPLOAD_DOC_FILE_REQUEST:
      return {
        request: true,
      };
    case UPLOAD_DOC_FILE_SUCCESS:
      return {
        request: false,
        uploadDocFileData: action.response,
      };
    case UPLOAD_DOC_FILE_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getClientDataById = (state = [], action) => {
  switch (action.type) {
    case GET_CLIENT_DATA_REQUEST:
      return {
        request: true,
      };
    case GET_CLIENT_DATA_SUCCESS:
      return {
        request: false,
        getClientDataByIdData: action.response,
      };
    case GET_CLIENT_DATA_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getClients = (state = [], action) => {
  switch (action.type) {
    case GET_CLIENTS_REQUEST:
      return {
        request: true,
      };
    case GET_CLIENTS_SUCCESS:
      return {
        request: false,
        clients: action.response.data[0].data,
        count: action.response.data[0].count[0].count,
      };
    case GET_CLIENTS_FAILED:
      return {
        request: false,
        error: true,
        clients: [],
        count: 0,
      };
    default:
      return state;
  }
};

export const getClientsReport = (state = [], action) => {
  switch (action.type) {
    case GET_CLIENTS_REPORT_REQUEST:
      return {
        request: true,
      };
    case GET_CLIENTS_REPORT_SUCCESS:
      return {
        request: false,
        clients: action.response.data[0].data,
      };
    case GET_CLIENTS_REPORT_FAILED:
      return {
        request: false,
        error: true,
        clients: [],
      };
    default:
      return state;
  }
};

export const saveClientSignature = (state = [], action) => {
  switch (action.type) {
    case SAVE_CLIENT_SIGNATURE_REQUEST:
      return {
        request: true,
      };
    case SAVE_CLIENT_SIGNATURE_SUCCESS:
      return {
        request: false,
        signatureSaved: true,
        updatedSignature: action.response.signature,
      };
    case SAVE_CLIENT_SIGNATURE_FAILED:
      return {
        request: false,
        signatureSaved: false,
        updatedSignature: null,
      };
    default:
      return state;
  }
};

export const getInactiveAppointmentsOfClientBundle = (state = [], action) => {
  switch (action.type) {
    case GET_BUNDLE_APPOINTMENT_REQUEST:
      return {
        request: true,
      };
    case GET_BUNDLE_APPOINTMENT_SUCCESS:
      return {
        request: false,
        appointments: action.response[0].data,
        count: action.response[0].count[0].count,
      };
    case GET_BUNDLE_APPOINTMENT_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getClientConsentForms = (state = [], action) => {
  switch (action.type) {
    case CLIENT_CONSENT_FORMS_REQUEST:
      return {
        request: true,
      };
    case CLIENT_CONSENT_FORMS_SUCCESS:
      return {
        request: false,
        data: action.response,
      };
    case CLIENT_CONSENT_FORMS_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};
