import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  root: {
    padding: '20px',
    position: 'static',
    marginLeft: 250,
    marginTop: 70,
  },
  heading: {
    textAlign: 'left',
    color: '#003d79',
    fontSize: 18,
    fontWeight: 400,
  },
  divstyle: {
    marginTop: 2,
    display: 'flex',
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(2),
    borderRadius: 5,
  },
  grid: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
  },

  righttextinput: {
    width: '50%',
    display: 'inline-block',
    textAlign: 'left',
  },
  typoghraphy: {
    display: 'inline-block',
    // paddingRight: theme.spacing(3),
    marginTop: theme.spacing(2),
    fontSize: 16,
    fontWeight: 400,
  },
  addServiceButton: {
    // float: 'left',
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: '2px',
    backgroundColor: '#003d79',
    color: '#fff',
    textTransform: 'capitalize',
    marginTop: '10px',
    marginBottom: '10px',
  },
  paper: {
    alignItems: 'center',
    padding: theme.spacing(5),
  },
  submit: {
    padding: '2px',
    marginRight: '5px',
    backgroundColor: '#003d79',
    color: '#ffffff',
    marginTop: '15px',
  },
  inputText: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#a29061',
    padding: '3px',
    fontWeight: 400,
  },
  marginSty: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#003d79',
    borderRadius: '5px',
    paddingRight: '5px',
    paddingLeft: '5px',
    height: '37px',
    color: '#a29061',
    backgroundColor: '#ffffff',
  },
  searchinput: {
    width: '90%',
    height: 38,
    borderRadius: 3,
    // borderColor: 'hsl(0,0%,10)',
    borderColor: '#cfd8dc',
    borderWidth: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#1976d2 !important',
  },
});
