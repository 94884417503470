/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from 'react';
import PropsTypes from 'prop-types';
import {

  withStyles,
  Grid,
  Paper,
  Typography,
  Button,
  Divider,
} from '@material-ui/core';
import {
  Clear,
  VerticalAlignBottom,
  PrintOutlined,
  MailOutline,
} from '@material-ui/icons';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { getSingleInvoiceAction } from '../../utils/actions/billing.action';
import Loader from '../../components/Loader/Loader';
import styles from './SingleInvoiceStyle';

class SingleInvoice extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      clientName: '',
      clientPhone: '',
      clientEmail: '',
      issueDate: new Date(),
      allService: [],
      amount: '',
      isLoaded: null,
      userName: '',
      userEmail: '',
    };
  }

  componentDidMount() {
    this.props.getSingleInvoiceAction(this.props.match.params.id);
  }

  componentDidUpdate(preProps) {
    const { getInvoiceData } = this.props;
    if (getInvoiceData !== preProps.getInvoiceData) {
      this.setState({ isLoaded: getInvoiceData.request });
      const data = getInvoiceData.getSingleInvoiceData;
      if (data) {
        this.setState({
          clientName: data.clientId && data.clientId.name,
          clientPhone: data.clientId && data.clientId.phoneNumber,
          clientEmail: data.clientId && data.clientId.email,
          issueDate: new Date(data.createdAt),
          allService: data.services,
          amount: data.amount,
          userName: data.user && `${data.user.firstName} ${data.user.lastName}`,
          userEmail: data.user && data.user.email,
        });
      }
    }
  }

  render() {
    const { classes } = this.props;
    const {
      clientName,
      clientPhone,
      clientEmail,
      issueDate,
      amount,
      allService,
      isLoaded,
      userName,
      userEmail,
    } = this.state;
    return (
      <div className={classes.root}>
        <Grid className={classes.header} container>
          <Grid item lg={1}>
            <Clear
              onClick={() => this.props.history.goBack()}
              style={{
                fontSize: '20px',
                lineHeight: '33px',
                color: '#777',
                cursor: 'pointer',
              }}
            />
          </Grid>
          <Grid item lg={6}>
            <Typography
              style={{
                color: '#4b5459',
                fontSize: '24px',
                lineHeight: '33px',
                float: 'left',
                fontWeight: 480,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              Invoice for
              {' '}
              {clientName}
            </Typography>
          </Grid>
          {isLoaded === false && (
            <>
              <Grid item lg={2}>
                <PrintOutlined
                  fontSize="medium"
                  className={classes.headerIcon}
                />
                <VerticalAlignBottom
                  fontSize="medium"
                  className={classes.headerIcon}
                />
                <MailOutline fontSize="medium" className={classes.headerIcon} />
              </Grid>
              <Grid item lg={3}>
                <Button
                  style={{ borderColor: 'red', color: 'red' }}
                  className={classes.headerButton}
                >
                  Delete
                </Button>
                <Button
                  style={{ borderColor: '#08c', color: '#08c' }}
                  className={classes.headerButton}
                >
                  Edit
                </Button>
                <Button
                  style={{
                    borderColor: '#0f5ca3',
                    color: '#fff',
                    backgroundColor: '#0f5ca3',
                  }}
                  className={classes.headerButton}
                >
                  Add Payment
                </Button>
              </Grid>
            </>
          )}
        </Grid>
        {isLoaded ? (
          <Loader />
        ) : (
          <div className={classes.invoiceArea}>
            <div style={{ padding: 50 }}>
              <Grid container>
                <Grid item lg={1}>
                  <Typography
                    style={{ color: '#b0b0b0' }}
                    className={classes.invoiceText}
                  >
                    From
                  </Typography>
                </Grid>
                <Grid item lg={11}>
                  <Typography className={classes.invoiceText}>
                    {userName}
                  </Typography>
                </Grid>
              </Grid>

              <div style={{ display: 'inline', alignItems: 'center' }}>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 450, marginTop: 50, marginBottom: 30 }}
                >
                  INVOICE
                </Typography>
              </div>

              <Grid container>
                <Grid item lg={1}>
                  <Typography
                    style={{ color: '#b0b0b0' }}
                    className={classes.invoiceText}
                  >
                    To
                  </Typography>
                </Grid>
                <Grid item lg={7}>
                  <Typography className={classes.invoiceText}>
                    {' '}
                    {clientName}
                  </Typography>
                </Grid>
                <Grid item lg={4}>
                  <Grid container>
                    <Grid item lg={5}>
                      <Typography className={classes.invoiceText}>
                        Invoice:
                        {' '}
                      </Typography>
                    </Grid>
                    <Grid item lg={7}>
                      <Typography className={classes.invoiceText}>
                        #
                        {dayjs(issueDate).format('DD')}
                      </Typography>
                    </Grid>
                    <Grid item lg={5}>
                      <Typography className={classes.invoiceText}>
                        Issue Date:
                        {' '}
                      </Typography>
                    </Grid>
                    <Grid item lg={7}>
                      <Typography className={classes.invoiceText}>
                        {dayjs(issueDate).format('DD/MM/YYYY')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container style={{ marginTop: 30 }} spacing={1}>
                <Grid item lg={1}>
                  <Typography className={classes.invoiceText}>
                    Client:
                  </Typography>
                </Grid>
                <Grid item lg={5}>
                  <Grid container>
                    <Grid item lg={12}>
                      <Typography className={classes.invoiceText}>
                        {clientName}
                      </Typography>
                    </Grid>
                    <Grid item lg={12}>
                      <Typography className={classes.invoiceText}>
                        {clientPhone}
                      </Typography>
                    </Grid>
                    <Grid item lg={12}>
                      <Typography className={classes.invoiceText}>
                        {clientEmail}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={2}>
                  <Typography className={classes.invoiceText}>
                    Provider:
                    {'  '}
                  </Typography>
                </Grid>
                <Grid item lg={4}>
                  <Grid container>
                    <Grid item lg={12}>
                      <Typography className={classes.invoiceText}>
                        {userName}
                      </Typography>
                    </Grid>
                    {/* <Grid item lg={12}>
                      <Typography className={classes.invoiceText}>
                        contact pending
                      </Typography>
                    </Grid> */}
                    <Grid item lg={12}>
                      <Typography className={classes.invoiceText}>
                        {userEmail}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                style={{
                  marginTop: 30,
                  backgroundColor: '#ebebeb',
                  padding: '10px',
                }}
              >
                <Grid item lg={2}>
                  <Typography className={classes.invoiceText}>Date</Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography className={classes.invoiceText}>
                    Description
                  </Typography>
                </Grid>
                <Grid item lg={2}>
                  <Typography className={classes.invoiceText}>
                    Amount
                  </Typography>
                </Grid>
              </Grid>

              {allService.map((data) => (
                <>
                  <Grid container style={{ padding: '7px' }}>
                    <Grid item lg={2}>
                      <Typography className={classes.invoiceText}>
                        {dayjs(data.createdAt).format('DD/MM/YYYY')}
                      </Typography>
                    </Grid>
                    <Grid item lg={8}>
                      <Typography className={classes.invoiceText}>
                        {data.description}
                      </Typography>
                    </Grid>
                    <Grid item lg={2}>
                      <Typography
                        className={classes.invoiceText}
                        style={{ float: 'right', marginRight: 10 }}
                      >
                        $
                        {data.rate}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </>
              ))}

              <Grid container style={{ padding: '15px' }}>
                <Grid item lg={7} />
                <Grid item lg={2}>
                  <Typography className={classes.invoiceText}>Total</Typography>
                </Grid>
                <Grid item lg={3}>
                  <Typography
                    className={classes.invoiceText}
                    style={{
                      float: 'right',
                      fontWeight: 'bold',
                      marginRight: 2,
                    }}
                  >
                    $
                    {amount}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item lg={7} />
                <Grid item lg={5}>
                  <Typography className={classes.invoiceText}>
                    Make payments to:
                    {' '}
                    {userName}
                  </Typography>
                </Grid>
                {/* <Grid item lg={2}><Typography className={classes.invoiceText} style={{float: 'right', fontWeight: 'bold', marginRight: 2}}>$ 1000</Typography></Grid> */}
              </Grid>
            </div>
          </div>
        )}
      </div>
    );
  }
}

SingleInvoice.propTypes = {
  classes: PropsTypes.object.isRequired,
};

const connected = connect(
  (state) => ({
    getInvoiceData: state.getSingleInvoice,
  }),
  {
    getSingleInvoiceAction,
  },
)(SingleInvoice);

const formed = reduxForm({
  form: 'getSingleInvoice',
})(connected);

export default withStyles(styles)(formed);
