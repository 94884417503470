/* eslint-disable import/prefer-default-export */
import {
  GET_UNPAID_APPOINTMENT_REQUEST,
  GET_UNPAID_APPOINTMENT_SUCCESS,
  GET_UNPAID_APPOINTMENT_FAILED,
  GET_UPCOMING_APPOINTMENT_REQUEST,
  GET_UPCOMING_APPOINTMENT_SUCCESS,
  GET_UPCOMING_APPOINTMENT_FAILED,
  GET_USER_NOTIFICATION_REQUEST,
  GET_USER_NOTIFICATION_SUCCESS,
  GET_USER_NOTIFICATION_FAILED,
  UPDATE_USER_NOTIFICATION_REQUEST,
  UPDATE_USER_NOTIFICATION_SUCCESS,
  UPDATE_USER_NOTIFICATION_FAILED,
} from "../constant";

export const getUnpaidAppointment = (state = [], action) => {
  switch (action.type) {
    case GET_UNPAID_APPOINTMENT_REQUEST:
      return {
        request: true,
      };
    case GET_UNPAID_APPOINTMENT_SUCCESS:
      return {
        request: false,
        getUnpaidAppointmentData: action.response,
      };
    case GET_UNPAID_APPOINTMENT_FAILED:
      return {
        request: false,
        error: true,
      };

    default:
      return state;
  }
};

export const getUpcomingAppointment = (state = [], action) => {
  switch (action.type) {
    case GET_UPCOMING_APPOINTMENT_REQUEST:
      return {
        request: true,
      };
    case GET_UPCOMING_APPOINTMENT_SUCCESS:
      return {
        request: false,
        getUpcomingAppointmentData: action.response,
      };
    case GET_UPCOMING_APPOINTMENT_FAILED:
      return {
        request: false,
        error: true,
      };

    default:
      return state;
  }
};

export const getNotification = (state = [], action) => {
  switch (action.type) {
    case GET_USER_NOTIFICATION_REQUEST:
      return {
        request: true,
      };
    case GET_USER_NOTIFICATION_SUCCESS:
      return {
        request: false,
        getNotificationData: action.response,
      };
    case GET_USER_NOTIFICATION_FAILED:
      return {
        request: false,
        error: true,
      };

    default:
      return state;
  }
};

export const UpdateNotification = (state = [], action) => {
  switch (action.type) {
    case UPDATE_USER_NOTIFICATION_REQUEST:
      return {
        request: true,
      };
    case UPDATE_USER_NOTIFICATION_SUCCESS:
      return {
        request: false,
        updateNotificationData: action.response,
      };
    case UPDATE_USER_NOTIFICATION_FAILED:
      return {
        request: false,
        error: true,
      };

    default:
      return state;
  }
};
