/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  titleFont: {
    fontFamily: "Lato",
    fontWeight: 400,
    color: "rgba(112,112,112,50%)",
    fontSize: "16px",
    textAlign: "left",
  },
  NameFont: {
    fontFamily: "Lato",
    fontWeight: 400,
    color: "#304659",
    fontSize: "18px",
    // width: "161px",
    // height: '100px',
    // whiteSpace: "nowrap",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // display: "inline",
    textAlign: "left",
    height: "60px",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  descFont: {
    fontFamily: "Lato",
    color: "#304659",
    fontWeight: 700,
    fontSize: 18,
    textAlign: "left",
  },
}));

export const EventTitleFonts = ({ ...props }) => {
  const classes = styles();
  return (
    <Typography className={classes.titleFont}>{props.children}</Typography>
  );
};

export const EventNameFonts = ({ ...props }) => {
  const classes = styles();
  return (
    <Typography className={classes.NameFont} {...props}>
      {props.children}
    </Typography>
  );
};

export const EventDescFonts = ({ ...props }) => {
  const classes = styles();
  return (
    <Typography className={classes.descFont} {...props}>
      {props.children}
    </Typography>
  );
};
