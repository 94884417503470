import React from 'react';
import { Img, Name, Count, AgeGroup } from './style';
import { Grid } from '@material-ui/core';
import { calculateAge } from '../../../utils/timUtils';

const MultipleProfilesDisplay = ({ profiles }) => {
  const ageGroup = (profile) =>
    calculateAge(new Date(profile.dob)) > 18 ? 'Adult' : 'Minor';
  return (
    <Grid spacing={2} wrap='nowrap' container>
      <Grid item>
        <Img
          imgProps={{ style: { marginLeft: '0px' } }}
          src={profiles[0].avatar}
          alt={profiles[0]._id}
        />
      </Grid>
      <Grid justify='center' direction='column' container>
        <Name wrap='nowrap' item container>
          {`${profiles[0].firstName} ${profiles[0].lastName}`}
        </Name>
        <Grid spacing={2} wrap='nowrap' item container>
          <AgeGroup item>{ageGroup(profiles[profiles.length - 1])}</AgeGroup>
          {profiles.length > 1 && <Count item>+{profiles.length - 1}</Count>}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default MultipleProfilesDisplay;
