import React from "react";

const InvoiceListItemAvatarMinor = (props) => (
  <div className="flex-shrink-0">
    <div className="h-12 w-12 rounded-full bg-indigo-600 text-white text-center">
      <span
        className="inline-block text-xl"
        style={{
          lineHeight: "3rem"
        }}
      >
        {props.children}
      </span>
    </div>
  </div>
);

export default InvoiceListItemAvatarMinor;
