import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  root: {
    padding: '20px',
    marginLeft: 250,
    marginTop: '70px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  gridsty: {
    paddingTop: theme.spacing(2),
  },
  gridLeft: {
    textAlign: 'left',
  },
  gridRight: {
    textAlign: 'right',
  },
  bordersty: {
    height: '60vh',
    backgroundColor: '#eceff1',
  },
  appointmentsty: {
    color: '#a29061',
    fontSize: '16px',
    marginLeft: '10px',
    lineHeight: 'normal',
    fontWeight: 600,
    textAlign: 'left',
    textTransform: 'capitalize',
  },
  serviceText: {
    color: '#003d79',
    fontSize: '12px',
    margin: '10px',
    lineHeight: 'normal',
    fontWeight: 500,
    textAlign: 'left',
    // textTransform: 'capitalize',
  },
  progressSty: {
    marginTop: '8px',
    cursor: 'pointer',
    fontWeight: 600,
    color: '#003d79',
    fontSize: '14px',
    lineHeight: 'normal',
    textAlign: 'left',
    textTransform: 'capitalize',
  },
  timeSty: {
    color: '#a29061',
    minWidth: '56px',
    textAlign: 'right',
    fontSize: '14px',
    lineHeight: 'normal',
  },
  dividersty: {
    width: '98%',
    marginTop: '15px',
    marginBottom: '15px',
    marginRight: '15px',
    // backgroundColor: '#a29061',
  },
  addbutton: {
    // backgroundColor: '#a29061',
    // background: '#a29061',
    height: '32px',
    color: '#003d79',
    fontSize: '14px',
    lineHeight: '9px',
    textTransform: 'capitalize',
    textAlign: 'center',
  },
  dateInput: {
    width: '100%',
    height: 38,
    borderRadius: 3,
    borderStyle: 'solid',
    borderColor: '#cccccc',
    color: '#003d79',
    borderWidth: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'inline-block',
    textAlign: 'center',
  },
  createButton: {
    paddingTop: '7px',
    paddingBottom: '7px',
    backgroundColor: '#003d79',
    borderColor: '#003d79',
    minWidth: '70px',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    padding: '5px 15px',
    border: '1px solid',
    cursor: 'pointer',
    color: '#ffffff',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  tabButton: {
    fontWeight: 600,
    padding: '0 7px 5px',
    marginBottom: '-1px',
    fontSize: '14px',
    textDecoration: 'none',
    lineHeight: '18px',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    textTransform: 'capitalize',
  },
  adButton: {
    fontWeight: 600,
    padding: '0 7px 5px',
    marginBottom: '-1px',
    fontSize: '14px',
    textDecoration: 'none',
    lineHeight: '18px',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    textTransform: 'capitalize',
    marginLeft: '480px',
    color: '#003d79',
  },
});
