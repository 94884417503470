/* eslint-disable max-len */
/* eslint-disable no-sequences */
/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from "react";
import {
  Container,
  
  withStyles,
  Typography,
  Button,
} from "@material-ui/core";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { deleteAppointmentAction } from "../../utils/actions/appointment.action";
import AppointmentCancelAlert from "./AppointmentCancelAlert";
import AlertMsg from "../../utils/Alert";
import styles from "./AppointmentReminderStyle";

class AppointmentReminder extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate(preProps) {
    if (this.props.deleteStatus !== preProps.deleteStatus) {
      const data = this.props.deleteStatus.deleteAppointmentData;
      if (data) {
        AlertMsg("success", data.status);
      }
    }
  }

  render() {
    const { classes } = this.props;
    console.log("deleye===========", this.props.deleteStatus);

    return (
      <Container maxWidth="sm">
        <div className={classes.containerSty}>
          <Typography className={classes.headingStyle}>
            AppointmentReminder
          </Typography>
          <div className={classes.subContainer}>
            <Button
              className={classes.buttonStyle}
              style={{ backgroundColor: "green" }}
            >
              Confirm
            </Button>
            <Button
              onClick={() =>
                this.props.history.push(
                  `/user/appointment/reschedule/${this.props.match.params.id}`
                )
              }
              className={classes.buttonStyle}
              style={{ backgroundColor: "#003d79" }}
            >
              Reschedule
            </Button>
            <Button
              // onClick={() =>
              //   this.props.deleteAppointmentAction(this.props.match.params.id)
              // }
              onClick={() => this.setState({ isCancel: true })}
              className={classes.buttonStyle}
              style={{ backgroundColor: "red" }}
            >
              Cancel
            </Button>
          </div>
        </div>
        {this.state.isCancel && (
          <AppointmentCancelAlert
            isOpen={this.state.isCancel}
            handleClose={() => this.setState({ isCancel: false })}
            handleSure={() => (
              this.setState({ isCancel: false }),
              this.props.deleteAppointmentAction(this.props.match.params.id)
            )}
          />
        )}
      </Container>
    );
  }
}

const connected = connect(
  (state) => ({
    deleteStatus: state.deleteAppointment,
  }),
  {
    deleteAppointmentAction,
  }
)(AppointmentReminder);

const formed = reduxForm({
  form: "deleteAppointment",
})(connected);



export default withStyles(styles)(formed);
