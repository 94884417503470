/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from "react";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import backArrow from "../../../assets/imgs/leftArrow.svg";
import rightArrow from "../../../assets/imgs/rightArrow.svg";
import styles from "./Toolbar_style";

export const Toolbar = (toolbar) => {
  const classes = styles();
  const goToBack = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() - 1);
    toolbar.onNavigate("prev");
  };

  const goToNext = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() + 1);
    toolbar.onNavigate("next");
  };

  const goToCurrent = () => {
    const now = new Date();
    toolbar.date.setMonth(now.getMonth());
    toolbar.date.setYear(now.getFullYear());
    toolbar.onNavigate("current");
  };

  const label = () => {
    const date = moment(toolbar.date);
    return (
      <Typography className={classes.text}>
        {`${date.format("MMMM")} ${date.format("YYYY")}`}
      </Typography>
      //   <span>
      //     <b>{date.format("MMMM")}</b>
      //     <span> {date.format("YYYY")}</span>
      //   </span>
    );
  };
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={classes.root}
    >
      <Grid
        container
        alignItems="center"
        justify="center"
        className={classes.arrowContainer}
        style={{ left: "85px", position: "absolute" }}
        onClick={() => goToBack()}
      >
        <img src={backArrow} />
      </Grid>
      {label()}
      <Grid
        container
        alignItems="center"
        justify="center"
        className={classes.arrowContainer}
        style={{ right: "85px", position: "absolute" }}
        onClick={() => goToNext()}
      >
        <img src={rightArrow} />
      </Grid>
    </Grid>
  );
};
