/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Grid, Paper, Button, withStyles } from '@material-ui/core';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import DrawerMenu from '../common/Header/DrawerMenu';
import { GridContainer, GridItem } from '../common/BodyContainer';
import { CustomTable } from '../common/Table';
import { TableTitleFont } from '../common/TableFont';
import logo from '../../assets/imgs/download.png';
import profile from '../../assets/imgs/download.png';
import logo1 from '../../assets/imgs/download.png';
import { CustomDrawer } from '../common/CustomDrawer';
import AppointmnetRequest from '../components/Appointment Request/AppointmnetRequest';
import UpcomingVisits from '../components/Appointment Request/UpcomingVisits';
import PreviousAppointment from '../components/Appointment Request/PreviousAppointment';
import { getPaymentAction } from '../../utils/actions/payment.action';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GetAppIcon from '@material-ui/icons/GetApp';
import PaymentIcon from '@material-ui/icons/Payment';
import Loader from '../../user/Loader';
import PaymentOption from '../components/PaymentOption';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import Drawer from '@material-ui/core/Drawer';
import { getUnpaidAppointmentAction } from '../../utils/actions/dashboard.action';
import { getAllInvoiceAction } from '../../utils/actions/billing.action';
import { dateFormatterDDMMYY } from '../../utils/validators';
import styles from './PaymentsHistory_style';
import { ButtonWithNoPadding } from '../../components/atoms';

const appReq = [];

class PaymentHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAppointmentRequest: false,
      isUpcomingVisitRequest: false,
      isPreviousAppointment: false,
      isOpen: false,
      billingList: [],
      isBillingLoaded: true,
      unpaidBillingList: [],
      isUnpaidLoaded: true,
      selectedDetails: {},
    };
  }

  componentDidMount() {
    this.props.getAllInvoiceAction(localStorage.getItem('clientId'));
  }

  componentDidUpdate(preProps) {
    if (this.props.getInvoiceData !== preProps.getInvoiceData) {
      const data = this.props.getInvoiceData;
      if (data) {
        console.log(data);
        var list = [];
        try {
          list = data.map((node) => ({
            invoice: node._id,
            appointmentId: node.appointmentId,
            firstName:
              node.user && node.user.firstName ? node.user.firstName : '',
            lastName: node.user & node.user.lastName ? node.user.lastName : '',
            avatar: node.user && node.user.avatar ? node.user.avatar : '',
            paymentStatus: node.status ? node.status : 'NA',
            amount: node.amount ? node.amount : 'NA',
            actionType: 'no',
          }));
        } catch (e) {
          console.log(e);
        }
        this.setState({
          billingList: list,
          isBillingLoaded: false,
        });
      }
    }
  }

  getDate(date) {
    var today = new Date(date);
    var d = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var m = today.getMonth() < 10 ? '0' + today.getMonth() : today.getMonth();
    return d + '/' + m + '/' + today.getFullYear();
  }

  handlePayment(env) {
    console.log(env);
    this.setState({ isOpen: true });
  }

  downloadInvoice(billingId) {
    const url = `/lh/invoice/${billingId}`;
    window.open(url, '_blank');
  }
  render() {
    // console.log(this.props.getPreviousAppointment);
    const { classes } = this.props;

    return (
      <div>
        <DrawerMenu {...this.props} />

        <GridContainer {...this.props} />
        {/* <Grid style={{ backgroundColor: this.state.isAppointmentRequest ? 'red' : '', zIndex: this.state.isAppointmentRequest ? 10000000 : '' }}> */}
        <GridItem
          {...this.props}
          style={{
            zIndex: this.state.isOpen ? 100 : '',
            backgroundColor: this.state.isOpen ? 'rgba(34,34,34,48%)' : '',
            bottom: 0,
          }}
        >
          <Grid style={{ marginBottom: '44px' }}>
            <Grid container style={{ padding: 10 }}>
              <Grid item xs={3} style={{ textAlign: 'left' }}>
                Invoice Number
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'left' }}>
                Date Issued / Clinician
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'left' }}>
                Amount / Status
              </Grid>
              <Grid item xs={1} style={{ textAlign: 'left' }}>
                Insurance
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'left' }}></Grid>
            </Grid>
            {this.state.isBillingLoaded && (
              <Grid container alignItems='center' justify='center'>
                <Loader />
              </Grid>
            )}
            {!this.state.isBillingLoaded &&
            this.state.billingList &&
            this.state.billingList.length > 0
              ? this.state.billingList.map((val) => {
                  return (
                    <Paper
                      key={val._id}
                      style={{ padding: 10, marginBottom: 15 }}
                    >
                      <Grid container>
                        <Grid item xs={3} style={{ textAlign: 'left' }}>
                          <div>
                            <PictureAsPdfIcon /> <span>{val.invoice}</span>
                          </div>
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'left' }}>
                          <div style={{ display: 'flex' }}>
                            <img
                              style={{
                                height: 50,
                                width: 50,
                                borderRadius: '50%',
                              }}
                              src={val.avatar ? val.avatar : profile}
                            />
                            <div style={{ marginLeft: 10 }}>
                              <p style={{ margin: 0 }}>
                                <b>
                                  {' '}
                                  {val.firstName} {val.lastName}{' '}
                                </b>
                              </p>
                              <p style={{ margin: 0, fontSize: 16 }}>
                                Appt #{' '}
                                <span style={{ color: 'blue' }}>
                                  {val.appointmentId && val.appointmentId._id
                                    ? val.appointmentId._id
                                    : 'NA'}
                                </span>{' '}
                              </p>
                              <p>
                                {val.appointmentId &&
                                val.appointmentId.startTime
                                  ? this.getDate(val.appointmentId.startTime)
                                  : 'NA'}
                              </p>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'left' }}>
                          <div
                            style={{
                              backgroundColor:
                                val.paymentStatus == 'paid'
                                  ? '#e5f8e5'
                                  : '#fce7e7',
                              color:
                                val.paymentStatus == 'paid'
                                  ? '#0e6911'
                                  : '#dc2121',
                              width: 100,
                              textAlign: 'center',
                              borderRadius: 50,
                              padding: 5,
                            }}
                          >
                            <b>{val.amount} AED</b>
                          </div>
                          {/* <div style={{backgroundColor:"#e5f8e5", color: "#0e6911",width:100,textAlign:'center',borderRadius:50,padding:5}} ><b>{val.amount} AED</b></div> */}
                        </Grid>
                        <Grid item xs={1} style={{ textAlign: 'left' }}>
                          <div>
                            <p style={{ margin: 0, fontSize: 18 }}>
                              <b>None</b>
                            </p>
                            <p style={{ margin: 0, fontSize: 16 }}>Self Paid</p>
                          </div>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                          <div>
                            {/* <Button style={{boxShadow:'0px 0px 2px 2px #ccc',height:40}} > */}
                            {val.paymentStatus != 'paid' ? (
                              <PaymentIcon
                                onClick={() => {
                                  let data = {};
                                  data.id = val.appointmentId._id;
                                  this.setState({
                                    isOpen: true,
                                    appointmentId: data,
                                  });
                                }}
                                style={{
                                  boxShadow: '0px 0px 2px 2px #ccc',
                                  height: 30,
                                  width: 30,
                                  cursor: 'pointer',
                                  borderRadius: 3,
                                  margin: 'auto',
                                  marginLeft: 10,
                                }}
                              />
                            ) : (
                              <div />
                            )}
                            {/* </Button> */}
                            <ButtonWithNoPadding
                              onClick={() => this.downloadInvoice(val.invoice)}
                            >
                              <GetAppIcon
                                style={{
                                  boxShadow: '0px 0px 2px 2px #ccc',
                                  height: 30,
                                  width: 30,
                                  cursor: 'pointer',
                                  borderRadius: 3,
                                  margin: 'auto',
                                  marginLeft: 10,
                                }}
                              />
                            </ButtonWithNoPadding>
                          </div>
                        </Grid>
                      </Grid>
                    </Paper>
                  );
                })
              : 'No Data Found'}
          </Grid>
        </GridItem>
        {/* </Grid> */}
        <Drawer
          anchor='right'
          open={this.state.isOpen}
          className={classes.Backdrop}
          onClose={() => this.setState({ isOpen: false })}
          {...this.props}
        >
          <div className={classes.drawerHeader}>
            <IconButton
              aria-label='close'
              onClick={(evt) => this.setState({ isOpen: false })}
            >
              <CancelIcon className={classes.closeIcon} />
            </IconButton>
          </div>
          <PaymentOption
            id={this.state.appointmentId}
            onCancel={() => (
              this.setState({ isOpen: false }),
              this.props.getAllInvoiceAction(localStorage.getItem('clientId'))
              //this.props.getUpcomingAppointmentAction()
            )}
            {...this.props}
          />
        </Drawer>
      </div>
    );
  }
}

const connected = connect(
  (state) => ({
    getInvoiceData: state.getAllInvoice.getAllInvoiceData,
  }),
  {
    getAllInvoiceAction,
  }
)(PaymentHistory);

const formed = reduxForm({
  form: 'appointment',
})(connected);

export default withStyles(styles)(formed);
