/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-undef */
import React, { PureComponent } from "react";
import {
  CssBaseline,
  Container,
  
  Typography,
  withStyles,
  Grid,
  FormControlLabel,
  Radio,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import querystring from "query-string";
import {
  getPaymentUrlAction,
  getPaymentResAction,
} from "../../utils/actions/payment.action";
import styles from "./PaymentStyle";

class Payment extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      payment: "credit",
      ipAddress: "",
      billingId: "",
    };
  }

  async componentDidMount() {
    await fetch("https://api.ipify.org/?format=json", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => this.setState({ ipAddress: data.ip }))
      .catch((err) => console.log(err));
    // this.paymentInterval = setInterval(async () => {
    //   console.log("hiiiiiiiiiiiiiiiiiiiiiii");

    //   await this.props.getPaymentResAction(this.state.billingId);
    // }, 5000);
  }

  componentDidUpdate(preProps) {
    if (this.props.getUrlData !== preProps.getUrlData) {
      window.location.replace(this.props.getUrlData.url);
      this.setState({ billingId: this.props.getUrlData.billingId });
    }
  }

  // componentWillUnmount() {
  //   clearInterval(this.paymentInterval);
  // }

  render() {
    const { classes } = this.props;
    console.log("=============payment res", this.props.getPaymentRes);
    const url = querystring.parse(this.props.location.search);
    return (
      <>
        <CssBaseline />
        <Container maxWidth="sm">
          <div className={classes.containerSty}>
            <Typography className={classes.headingStyle}>
              Payment Form
            </Typography>
            <div className={classes.subContainer}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Typography
                    onClick={() =>
                      this.props.getPaymentUrlAction(
                        this.props.match.params.id2,
                        { publicIp: this.state.ipAddress }
                      )
                    }
                    className={classes.subHeadingStyle}
                    style={{ cursor: "pointer" }}
                  >
                    Go For Payment
                  </Typography>
                  {/* <Grid container>
                    <Grid item lg={4} className={classes.optionStyle}>

                    </Grid> */}
                  {/* <Grid item lg={8} className={classes.optionStyle}>
                      <FormControlLabel
                        value="end"
                        style={{ color: '#a29061' }}
                        control={(
                          <Radio
                            color="primary"
                            checked={payment === 'cash'}
                            onChange={() => this.setState({ payment: 'cash' })}
                          />
                        )}
                        label="Cash"
                      />
                      <FormControlLabel
                        value="end"
                        style={{ color: '#a29061', marginLeft: 20 }}
                        control={(
                          <Radio
                            color="primary"
                            checked={payment === 'credit'}
                            onChange={() => this.setState({
                              payment: 'credit',
                            })}
                          />
                        )}
                        label="Credit Card"
                      />
                    </Grid> */}
                  {/* </Grid> */}
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignContent: "center",
                // marginTop: -20,
                marginBottom: 10,
              }}
            >
              <Link
                to={`/user/checklist/${this.props.match.params.id1}/appointment/${this.props.match.params.id2}`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{
                    backgroundColor: "#003d79",
                    color: "#ffffff",
                    // padding: 0,
                    textTransform: "capitalize",
                    width: 200,
                  }}
                >
                  Back
                </Button>
              </Link>
              {/* <Link
                to="/client/consent"
                style={{ textDecoration: 'none', marginLeft: '10px' }}
              >
                <Button
                  style={{
                    backgroundColor: '#003d79',
                    color: '#ffffff',
                    // padding: 0,
                    textTransform: 'capitalize',
                    width: 200,
                  }}
                >
                  Next
                </Button>
              </Link> */}
            </div>
          </div>
        </Container>
      </>
    );
  }
}

const connected = connect(
  (state) => ({
    getUrlData: state.getPaymentUrl.getPaymentUrlData,
    getPaymentRes: state.getPaymentRes.getPaymentResData,
  }),
  {
    getPaymentUrlAction,
    getPaymentResAction,
  }
)(Payment);

const formed = reduxForm({
  form: "getPaymentDetail",
})(connected);



export default withStyles(styles)(formed);
