import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Avatar, Grid, Icon } from '@material-ui/core';
import { Button, ButtonWithNoPadding } from '../../../../../components/atoms';
import { ServiceBundleForm } from '../';
import { Container, Heading, Row, BottomBtn, DrawerWindow } from '../style';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

const defaultBundle = {
  client: null,
  clinician: null,
  cancellingUser: null,
  appointmentType: '',
  expirationDate: new Date(),
  amount: 0,
  noOfSessions: 0,
  noOfUsedSessions: 0,
  discount: 0,
  status: '',
  reasonForCancelling: '',
};
const ShowBundle = ({ open, onClose, selectedBundle, clinicians, clients }) => {
  const [bundle, setBundle] = useState({ ...defaultBundle });

  useEffect(() => {
    setBundle(selectedBundle);
  }, [selectedBundle]);

  const onChange = () => {};
  const filterClients = () => {};
  const filterClinicians = () => {};
  return (
    <DrawerWindow anchor='right' open={open} onClose={onClose}>
      <Container>
        <Grid justify='flex-end' container>
          <ButtonWithNoPadding onClick={onClose}>
            <Icon component={CancelOutlinedIcon} />
          </ButtonWithNoPadding>
        </Grid>
        <Heading>Subscription</Heading>
        <ServiceBundleForm
          clinicianList={clinicians}
          clientList={clients}
          filterClients={filterClients}
          filterClinicians={filterClinicians}
          onChange={onChange}
          bundle={bundle}
          mode='view'
        />
      </Container>
    </DrawerWindow>
  );
};

export default ShowBundle;
