import { createStyles } from "@material-ui/core";
import { titleFont } from "../../../assets/fontSize";

export default (theme) =>
    createStyles({
        waitingList: {
            width: "179px",
            height: "34px",
            backgroundColor: "#314658",
            display: "flex",
            color: "#ffffff",
            "&:hover": {
                opacity: "100%",
                backgroundColor: "#314658",
                color: "#ffffff",
            },
            cursor: "pointer",
            [theme.breakpoints.up("xs")]: {
                marginLeft: "35px",
            },
            [theme.breakpoints.up("sm")]: {
                marginLeft: "0px",
            },
        },
        root: {
            position: "fixed",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            backgroundColor: "#cfd9df",
            opacity: "20%",
        },
        container: {
            position: "absolute",
            zIndex: 1,
            [theme.breakpoints.up("xs")]: {
                width: "300px",
                top: "150px",
            },
            [theme.breakpoints.up("sm")]: {
                width: "500px",
                top: "150px",
            },
            [theme.breakpoints.up("md")]: {
                width: "800px",
                top: "212px",
            },
        },
        title: {
            [theme.breakpoints.up("xs")]: {
                // fontSize: "25px",
                fontSize: titleFont,
                marginBottom: "10px",
            },
            [theme.breakpoints.up("sm")]: {
                fontSize: titleFont,
                // fontSize: "32px",
            },
            [theme.breakpoints.up("md")]: {
                // fontSize: '32px',
                fontSize: titleFont,
            },
            color: "#304659",
            fontFamily: "Lato",
            fontWeight: 700,
            //   width: '343px',
            textAlign: "left",
        },
        divider: {
            marginBottom: "25px",
            [theme.breakpoints.up("xs")]: {
                width: "300px",
            },
            [theme.breakpoints.up("sm")]: {
                width: "500px",
            },
            [theme.breakpoints.up("md")]: {
                width: "800px",
            },
        },
    });