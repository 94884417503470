import { makeStyles} from "@material-ui/core";

export default makeStyles((theme) => ({
    root: {
      backgroundColor: "rgba(192,208,222,15%)",
      marginBottom: "44px",
    },
    pointerNone:{
      pointerEvents:'none',
      backgroundColor: "#00bc07",
      borderRadius: "4px",
      color: "#fff"
    },
    boxContainer: {
      display: "flex",
      paddingLeft: "19px",
      paddingTop: "30px",
      paddingBottom: "24px",
      paddingRight: "25px",
      justify: "flex-start",
      flexDirection: "column",
      width: "731px",
      height: "215px",
    },
    clockicon: {
      // [theme.breakpoints.up("xs")]: {
      //   width: "15px",
      //   height: "15px",
      // },
      [theme.breakpoints.up("md")]: {
        width: "15px",
        height: "15px",
        marginLeft: "15px",
        marginTop: "5px",
      },
    },
    rbtn: {
      marginRight: "20px",
    },
    rescheduleButton: { marginRight: "20px" },
  }));
  