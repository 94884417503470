import { createStyles } from '@material-ui/core';
import { descFont, nameFont, titleFont } from '../assets/fontSize';

export default (theme) => createStyles({
  root: {
    backgroundColor: '#cfd9df',
    width: '100%',
    height: '100%',
    opacity: '20%',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  mainBody: {
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.up('xs')]: {
      marginTop: '150px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '186px',
    },
  },
  containerSty: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#003d79',
    border: 'solid',
    backgroundColor: '#F7F8F5',
  },
  subContainer: {
    margin: theme.spacing(5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffffff',
  },
  headingStyle: {
    marginTop: '20px',
    fontSize: '25px',
    fontWeight: 600,
    lineHeight: '30px',
    color: '#003d79',
  },
  buttonStyle: {
    padding: '2px',
    textTransform: 'capitalize',
    color: '#ffffff',
    width: '350px',
    marginTop: '10px',
    backgroundColor: '#003d79',
  },
  titleContainer: {
    // position: 'absolute',
    // top: '85px',
    marginTop: '85px',
  },
  title: {
    fontSize: titleFont,
    color: '#304659',
    fontFamily: 'Lato',
    fontWeight: 700,
    textAlign: 'center',
  },
  bodyContainer: {
    marginTop: '22px',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  list: {
    marginTop: '9px',
    marginBottom: '9px',
    cursor: 'pointer',
  },
  bodyListContainer: {
    display: 'flex',
    height: '80px',
    color: '#304659',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#304659',
      color: '#ffffff',
    },
    [theme.breakpoints.up('xs')]: {
      width: '300px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '420px',
    },
  },
  avatar: {
    width: '50px',
    height: '50px',
    borderRadius: '25px',
    border: '3px solid #ffffff',
  },
  avatarContainer: {
    // justifyContent: 'center',
    position: 'relative',
    left: '10px',
    top: '11px',
  },
  nameContainer: {
    position: 'relative',
    left: '25px',
    top: '10px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    [theme.breakpoints.up('xs')]: {
      width: '200px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '330px',
    },
  },
  name: {
    fontSize: nameFont,
    // fontSize: "25px",
    fontFamily: 'Lato',
    fontWeight: 700,
    textAlign: 'left',
  },
  subName: {
    fontSize: descFont,
    // fontSize: "15px",
    fontFamily: 'Lato',
    fontWeight: 400,
    position: 'relative',
    textAlign: 'left',
  },
  addIcon: {
    fontSize: '35px',
    color: '#ffffff',
    fontFamily: 'Lato',
    fontWeight: 900,
    textAlign: 'center',
    marginTop: '-3px',
  },
  footerContainer: {
    position: 'relative',
    top: '35px',
  },
  footerTitle: {
    fontSize: '20px',
    color: '#222222',
    fontFamily: 'Lato',
    fontWeight: 900,
    textAlign: 'center',
    opacity: '50%',
  },
  footerDescription: {
    fontSize: '15px',
    height: '138px',
    color: '#222222',
    fontFamily: 'Lato',
    fontWeight: 400,
    textAlign: 'center',
    opacity: '50%',
    [theme.breakpoints.up('xs')]: {
      width: '300px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '420px',
    },
  },
});
