/* eslint-disable no-unused-vars */

import {
  GET_CLINICIAN_REQUEST,
  GET_CLINICIAN_APPOINTMENT_REQUEST,
  GET_BY_ID_CLINICIAN_REQUEST,
  GET_BY_IDS_CLINICIANS_REQUEST,
  SAVE_CLINICIAN_SIGNATURE_REQUEST,
  LOGGEDIN_CLINICIAN_INFO_REQUEST,
} from '../constant';

export const getClinicianAction = (searchText) => {
  return {
    type: GET_CLINICIAN_REQUEST,
    endpoint: `/api/user/clinicians?term=${searchText ? searchText : ''}`,
  };
};

export const getClinicianAppointmentAction = (id, token) => ({
  type: GET_CLINICIAN_APPOINTMENT_REQUEST,
  endpoint: `/api/appointment/user/${id}`,
});

export const getByIdClinicianAction = (id) => ({
  type: GET_BY_ID_CLINICIAN_REQUEST,
  endpoint: `/api/user/${id}`,
});

export const getCliniciansByIds = (ids) => ({
  type: GET_BY_IDS_CLINICIANS_REQUEST,
  endpoint: `/api/user/fetchMultiple?ids=${ids}`,
});

export const saveClinicianSignature = (data) => {
  console.log(data);
  return {
    type: SAVE_CLINICIAN_SIGNATURE_REQUEST,
    endpoint: '/api/user/signature',
    body: data,
  };
};

export const loggedInUserInfo = () => ({
  type: LOGGEDIN_CLINICIAN_INFO_REQUEST,
  endpoint: '/api/user',
});
