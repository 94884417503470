import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    // paddingRight: theme.spacing(5),
  },
  checkedsty: {
    // marginTop: theme.spacing(2),
    textAlign: 'left',
    position: 'static',
    width: 'auto',
    fontWeight: 400,
    color: '#003d79',
    cursor: 'pointer',
    fontSize: 14,
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  title: {
    fontSize: 17,
    fontWeight: 600,
    color: '#003d79',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    marginBottom: 15,
    marginTop: 15,
  },
  formHeading: {
    textAlign: 'left',
    fontSize: 18,
    fontWeight: 400,
    color: '#13191c',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    marginTop: theme.spacing(2),
  },
  divstyle: {
    width: '80%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#eceff1',
    height: 40,
    borderRadius: 5,
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(8),
    fontSize: 16,
    color: '#555',
    cursor: 'text',
  },
  preferredSty: {
    width: '26.5%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#eceff1',
    height: 40,
    borderRadius: 5,
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(8),
    fontSize: 16,
    color: '#555',
    cursor: 'text',
  },
  preferrednamesty: {
    fontSize: 16,
    color: '#a29061',
    fontFamily: 'inherit',
    // fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  containersty: {
    maxWidth: 800,
    height: '5rem',
    backgroundColor: '#fff2d4',
    borderRight: 1,
    borderTop: 1,
    borderBottom: 1,
    borderColor: '#eb944d',
    position: 'relative',
    borderStyle: 'solid',
    zIndex: 1,
  },
  boxSty: {
    fontWeight: 600,
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: '#13191c',
    fontSize: 14,
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  dividersty: {
    width: '95%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  selectsty: {
    marginTop: theme.spacing(1.2),
  },
  button: {
    marginTop: theme.spacing(2),
    borderRadius: 5,
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 600,
    color: '#003d79',
    borderStyle: 'solid',
    borderColor: '#003d79',
    fontFamily: 'inherit',
  },
  deleteIconsty: {
    marginTop: theme.spacing(7),
  },
  date: {
    marginTop: theme.spacing(1.4),
    width: '100%',
    height: 38,
    borderRadius: 3,
    borderColor: '#cfd8dc',
    borderWidth: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'inline-block',
  },
  select: {
    marginTop: theme.spacing(1.2),
  },
});
