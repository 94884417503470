import { makeStyles} from "@material-ui/core";

export default makeStyles((theme) => ({
    root: {
      width: "160px",
      height: "30px",
      borderRadius: "15px",
      backgroundColor: "#db1919",
      // backgroundColor: '#c0d0de'
    },
    font: {
      fontSize: "15px",
      fontWeight: 700,
      fontFamily: "Lato",
      textAlign: "center",
    },
  }));