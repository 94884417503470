/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from "react";
import {
  withStyles,
  Grid,
  Typography,
  Input,
  Button,
  createStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import validator from "validator";
import querystring from "query-string";
import { updateUserAction } from "../../../../utils/actions/admin.user.action";
import AlertMsg from "../../../../utils/Alert";
import styles from "./VerifyUser.styles";

class VerifyUser extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      userName: "",
      password: "",
      cpassword: "",
    };
  }

  componentDidUpdate(preProps) {
    if (this.props.verifyUserData !== preProps.verifyUserData) {
      if (this.props.verifyUserData.message === "success") {
        this.props.history.push("/login");
      }
    }
  }

  handleVerify = () => {
    const fName = validator.isEmpty(this.state.firstName, {
      ignore_whitespace: true,
    });
    const lName = validator.isEmpty(this.state.lastName, {
      ignore_whitespace: true,
    });
    const uName = validator.isEmpty(this.state.userName, {
      ignore_whitespace: true,
    });
    const pass = validator.isEmpty(this.state.password, {
      ignore_whitespace: true,
    });
    const cPass = validator.isEmpty(this.state.cpassword, {
      ignore_whitespace: true,
    });
    const matchPass = validator.equals(
      this.state.password,
      this.state.cpassword
    );
    if (fName || lName || uName || pass || cPass) {
      AlertMsg("error", "All field must be filled !");
      return false;
    }
    if (!matchPass) {
      AlertMsg("error", "Password must be same !");
      return false;
    }
    return true;
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = () => {
    const { firstName, lastName, password, userName } = this.state;
    const url = querystring.parse(this.props.location.search);
    const isVerify = this.handleVerify();
    if (isVerify) {
      const data = {
        userName,
        password,
        firstName,
        lastName,
      };
      this.props.updateUserAction(url.token, data);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.inputField}>
          <Grid container spacing={2}>
            <Grid item lg={3}>
              <div className={classes.inputContainer}>
                <Typography className={classes.inputText}>
                  First Name
                </Typography>
                <Input
                  placeholder="First Name"
                  disableUnderline
                  value={this.state.firstName}
                  name="firstName"
                  className={classes.input}
                  onChange={this.handleChange}
                />
              </div>
            </Grid>
            <Grid item lg={3}>
              <div className={classes.inputContainer}>
                <Typography className={classes.inputText}>Last Name</Typography>
                <Input
                  placeholder="Last Name"
                  disableUnderline
                  value={this.state.lastName}
                  className={classes.input}
                  name="lastName"
                  onChange={this.handleChange}
                />
              </div>
            </Grid>
            <Grid item lg={3}>
              <div className={classes.inputContainer}>
                <Typography className={classes.inputText}>User Name</Typography>
                <Input
                  placeholder="User Name"
                  disableUnderline
                  value={this.state.userName}
                  className={classes.input}
                  name="userName"
                  onChange={this.handleChange}
                />
              </div>
            </Grid>
            <Grid item lg={3}>
              <div className={classes.inputContainer}>
                <Typography className={classes.inputText}>Password</Typography>
                <Input
                  placeholder="Password"
                  disableUnderline
                  value={this.state.password}
                  className={classes.input}
                  name="password"
                  onChange={this.handleChange}
                />
              </div>
            </Grid>
            <Grid item lg={3}>
              <div className={classes.inputContainer}>
                <Typography className={classes.inputText}>
                  {" "}
                  Confirm Password
                </Typography>
                <Input
                  placeholder="Confirm Password"
                  disableUnderline
                  value={this.state.cpassword}
                  className={classes.input}
                  name="cpassword"
                  onChange={this.handleChange}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <Button className={classes.createButton} onClick={this.handleSubmit}>
          Register
        </Button>
      </div>
    );
  }
}

const connected = connect(
  (state) => ({
    verifyUserData: state.updateUser.updateUserData,
  }),
  {
    updateUserAction,
  }
)(VerifyUser);

const formed = reduxForm({
  form: "verifyUser",
})(connected);



export default withStyles(styles)(formed);
