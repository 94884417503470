import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  root: {
    padding: '20px',
    marginLeft: 250,
    marginTop: 70,
  },
  flexStyle: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  title: {
    width: 'auto',
    float: 'left',
    fontSize: '20px',
    lineHeight: '37px',
    fontWeight: 400,
    overflow: 'hidden',
    textTransform: 'capitalize',
    color: '#003d79',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  divstyle: {
    marginTop: 2,
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(2),
    borderRadius: 5,
  },
  showSty: {
    fontSize: '13px',
    fontWeight: 400,
    margin: '0 0 9px',
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'flex-start',
    // marginRight: '35px',
  },
  checkboxsty: {
    // marginRight: '35px',
    width: 'auto',
    margin: '3px 0',
    lineHeight: 'normal',
    cursor: 'pointer',
    fontSize: '13px',
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'flex-start',
    borderRadius: '3px',
  },
  date: {
    width: '160px',
    height: 38,
    borderRadius: 3,
    borderColor: '#cfd8dc',
    borderWidth: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
    marginRight: '35px',
    marginLeft: '35px',
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '18px',
    marginTop: '10px',
  },
  selectsty: {
    width: '30%',
    height: 38,
    borderRadius: 3,
    borderColor: '#cfd8dc',
    borderWidth: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'left',
    marginRight: '26px',
    fontSize: '13px',
    marginTop: '10px',
  },
  margin: {
    backgroundColor: '#a29061',
    borderColor: '#a29061',
    minWidth: '70px',
    width: 'auto',
    maxHeight: '35px',
    cursur: 'pointer',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    color: '#ffffff',
    textAlign: 'center',
    padding: '5px 15px',
    border: '1px solid',
    marginTop: '10px',
    textTransform: 'capitalize',
  },
  tableHeading: {
    textTransform: 'uppercase',
    color: '#003d79',
    fontSize: '13px',
    lineHeight: '18px',
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '12px',
    marginLeft: '10px',
    textAlign: 'left',
    padding: '0 10px 7px 0',
  },
  gridsty: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  tableName: {
    cursor: 'pointer',
    fontSize: '13px',
    lineHeight: '18px',
    color: '#a29061',
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'flex-start',
    textAlign: 'left',
    // padding: '0 10px 7px 0',
    padding: '22px 10px 22px 0',
    marginLeft: '10px',
  },
  tablesubtext: {
    padding: '22px 10px 22px 0',
    fontSize: '13px',
    lineHeight: '18px',
    textAlign: 'left',
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'flex-start',
    color: '#003d79',
    marginLeft: '10px',
  },
  statusSty: {
    padding: '22px 10px 22px 0',
    fontSize: '13px',
    lineHeight: '18px',
    textAlign: 'left',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'flex-start',
    color: '#e45b59',
    marginLeft: '10px',
  },
});
