import { Grid, Button } from '@material-ui/core';
import styled from 'styled-components';

const Content = styled(Grid)`
  background-color: #fff;
  margin-top: 20px;
  padding: 15px;
`;

const PlusBtn = styled(Button)`
  background-color: #9c8657;
  padding: 0px;
  min-width: 20px;
  min-height: 20px;
  height: 20px;
  color: #fff;
`;

const LeftBtn = styled(Grid)`
  margin-right: 10px;
`;

const ActionGrid = styled(Grid)`
  margin-top: 10px;
`;

export { Content, PlusBtn, LeftBtn, ActionGrid };
