/* eslint-disable no-unused-vars */
/* eslint-disable quotes */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
import {
  ADD_ONBOARDING_REQUEST,
  ADD_ONBOARDING_SUCCESS,
  ADD_ONBOARDING_FAILED,
  GET_ONBOARDING_REQUEST,
  GET_ONBOARDING_SUCCESS,
  GET_ONBOARDING_FAILED,
  GET_BY_ID_ONBOARDING_REQUEST,
  GET_BY_ID_ONBOARDING_SUCCESS,
  GET_BY_ID_ONBOARDING_FAILED,
  UPDATE_ONBOARDING_REQUEST,
  UPDATE_ONBOARDING_SUCCESS,
  UPDATE_ONBOARDING_FAILED,
  DELETE_ONBOARDING_FAILED,
  DELETE_ONBOARDING_SUCCESS,
  DELETE_ONBOARDING_REQUEST,
} from "../AdminConstent";

const initialState = {
  request: true,
  error: false,
};

export const addOnboarding = (state = [], action) => {
  switch (action.type) {
    case ADD_ONBOARDING_REQUEST:
      return {
        request: true,
      };
    case ADD_ONBOARDING_SUCCESS:
      return {
        request: false,
        addOnboardingData: action.response,
      };
    case ADD_ONBOARDING_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getOnboarding = (state = [], action) => {
  switch (action.type) {
    case GET_ONBOARDING_REQUEST:
      return {
        request: true,
      };
    case GET_ONBOARDING_SUCCESS:
      return {
        request: false,
        getOnboardingData: action.response,
      };
    case GET_ONBOARDING_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getByIdOnboarding = (state = [], action) => {
  switch (action.type) {
    case GET_BY_ID_ONBOARDING_REQUEST:
      return {
        request: true,
      };
    case GET_BY_ID_ONBOARDING_SUCCESS:
      return {
        request: false,
        getByIdOnboardingData: action.response,
      };
    case GET_BY_ID_ONBOARDING_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const updateOnboarding = (state = [], action) => {
  switch (action.type) {
    case UPDATE_ONBOARDING_REQUEST:
      return {
        request: true,
      };
    case UPDATE_ONBOARDING_SUCCESS:
      return {
        request: false,
        updateOnboardingData: action.response,
      };
    case UPDATE_ONBOARDING_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const deleteOnboarding = (state = [], action) => {
  switch (action.type) {
    case DELETE_ONBOARDING_REQUEST:
      return {
        request: true,
      };
    case DELETE_ONBOARDING_SUCCESS:
      return {
        request: false,
        deleteOnboardingData: action.response,
      };
    case DELETE_ONBOARDING_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getOnboardingUser = (state = [], action) => {
  switch (action.type) {
    case GET_ONBOARDING_REQUEST:
      return {
        request: true,
      };
    case GET_ONBOARDING_SUCCESS:
      return {
        request: false,
        getOnboardingUserData: action.response,
      };
    case GET_ONBOARDING_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};
