import { createStyles} from "@material-ui/core";
import { titleFont, descFont } from '../../assets/fontSize';
export default (theme) =>
 createStyles({
    root: {
      backgroundColor: '#cfd9df',
      width: '100%',
      opacity: '20%',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    titleContainer: {
      position: 'absolute',
      zIndex: 1,
      [theme.breakpoints.up('xs')]: {
        width: '300px',
        top: '150px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '500px',
        top: '150px',
      },
      [theme.breakpoints.up('md')]: {
        width: '800px',
        top: '88px',
      },
    },
    container: {
      position: 'absolute',
      zIndex: 1,
      [theme.breakpoints.up('xs')]: {
        width: '300px',
        top: '80px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '500px',
        top: '90px',
      },
      [theme.breakpoints.up('md')]: {
        width: '1000px',
        top: '80px',
      },
    },
    bodyContainer: {
      marginTop: '36px',
      // justifyContent: "center",
      // alignSelf: "center",
      // alignItems: "center",
      // display: "flex",
      // flexDirection: "column",
      // [theme.breakpoints.up("xs")]: {
      //     width: "300px",
      // },
      // [theme.breakpoints.up("sm")]: {
      //     width: "500px",
      // },
      // [theme.breakpoints.up("md")]: {
      //     width: "800px",
      // },
    },
    personBox: {
      cursor: 'pointer',
      // padding:"16px",
      borderBottom: '1px solid #ddd',
    },
    imgTable: {
      width: '40px',
      height: '40px',
      objectFit: 'contain',
      borderRadius: '50px',
    },
    addBtn: {
      backgroundColor: '#304659',
      textTransform: 'capitalize',
    },
    paper: {
      // padding: theme.spacing(2),
      marginTop: '16px',
      textAlign: 'center',
      height: '300px',
      overflowY:"scroll",
    },
    title: {
      // fontSize: "32px",
      color: '#304659',
      fontFamily: 'Lato',
      fontWeight: '700',
      [theme.breakpoints.up('xs')]: {
        fontSize: titleFont,
        textAlign: 'center',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: titleFont,
        textAlign: 'center',
      },
    },
    arrowBox: {
      height: '25px',
      backgroundColor: '#fff',
      // display:"inline-block"
    },
    arrowRotateBox: {
      // display:"inline-block",
      height: '25px',
      backgroundColor: '#fff',
      transform: 'rotate(180deg)',
      marginTop: '20px',
    },
    nameTitle: {
      lineHeight: '2.5rem',
      marginLeft: '16px',
    },
    cancelButton: {
      marginTop: 48,
      marginBottom: 48,
      display: 'inline-block',
    },
    nextButton: {
      marginTop: 48,
      marginBottom: 48,
    },
    verticalMiddle: {
      position: 'absolute',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
    formBtn: {
      backgroundColor: '#30467e',
      color: '#fff',
      padding: '10px',
      borderRadius: '9px',
      width: '100%',
      '&:hover': {
        color: '#000',
      },
    },
    personBtn: {
      width: '100%',
      padding: '10px',
      justifyContent: 'left',
      textTransform: 'capitalize',
    },
    fieldContainer: {
      border: '1px solid #87ccf5',
      borderRadius: '6px',
    },
    dateInput: {
      // [theme.breakpoints.up("xs")]: {
      //   width: "180px",
      //   height: "30px",
      // },
      // [theme.breakpoints.up("sm")]: {
      //   width: "180px",
      //   height: "40px",
      // },
      // [theme.breakpoints.up("md")]: {
      //   width: "180px",
      //   height: "50px",
      // },
      height:"50px",
      backgroundColor: "#ffffff",
      fontSize: descFont,
      border: "1px",
      borderStyle: "solid",
      borderColor: "#87ccf5",
      borderRadius:"6px",
      cursor: "text",
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: "400",
      opacity: "100%",
      display: "inline-block",
      textAlign: "center",
      paddingLeft: "23px",
      paddingRight: "14px",
    },
    errorText: {
      color: "red",
      fontFamily: "Lato",
      fontWeight: 400,
      fontSize: 16,
    },
  });