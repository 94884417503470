import { Autocomplete } from '@material-ui/lab';
import styled from 'styled-components';

const CustomAutoComplete = styled(Autocomplete)`
  display: block;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  height: 45px;
`;

export { CustomAutoComplete };
