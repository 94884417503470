import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Badge, makeStyles } from '@material-ui/core';
import DatePicker, { registerLocale } from 'react-datepicker';
import ReCAPTCHA from 'react-google-recaptcha';
import { MarkRequired, FormGrid } from '../style';
import flag from "../../assets/img/icon-uae-flag.svg"
import moment from 'moment'
import CountrySelect from './CountrySelect';


const useStyles = makeStyles(() => ({
  root: { position: 'absolute', right: '30px' },
  customBadge: {
    backgroundColor: 'rgb(0, 58, 112);',
    color: 'white',
  },
}));

const FirstStep = ({
  attendNo,
  attendies,
  classes,
  eventData,
  isUserAgree,
  handleNext,
  parent,
  handleChange,
  handleAttane,
  onCaptchaChange,
  togglePolicyAgreement,
  addParent,
  removeParent,
  maxAge
}) => {
  const BadgeStyle = useStyles();

  const AdultCell = (data, index, isParent = false) => {
    return <div className="block p-4 mb-4 bg-brand-gold-light">
      <h3 className="pb-1 border-b border-gray-500 border-opacity-20 text-lg font-bold mb-2">
        {!eventData.isForChildren ? `Attendee #${index + 1}` : `Parent Details`}
      </h3>
      <div className="w-full flex flex-wrap justify-between gap-2 lg:gap-4">
        { }
        <div className="w-full flex-grow lg:w-5/12">
          <label className="text-sm text-gray-500" htmlFor>
            First name <span className="text-red-500">*</span>
          </label>
          <input
            className="w-full h-12 border border-gray-200 p-2 bg-white text-sm"
            value={data.firstName}
            onChange={(event) => handleChange(index, event.target, isParent)}
            type='text'
            name='firstName'
            placeholder="First Name"
            size={5}
          />
        </div>
        { }
        <div className="w-full flex-grow lg:w-5/12">
          <label className="text-sm text-gray-500" htmlFor>
            Last name <span className="text-red-500">*</span>
          </label>
          <input
            className="w-full h-12 border border-gray-200 p-2 bg-white text-sm"
            value={data.lastName}
            onChange={(event) => handleChange(index, event.target, isParent)}
            type='text'
            name='lastName'
            placeholder="Last Name"
            size={5}
            required
          />
        </div>
        { }
        <div className="w-full flex-grow lg:w-5/12">
          <label className="text-sm text-gray-500" htmlFor>
            Email address <span className="text-red-500">*</span>
          </label>
          <input
            className="w-full h-12 border border-gray-200 p-2 bg-white text-sm"
            value={data.email}
            onChange={(event) => handleChange(index, event.target, isParent)}
            type='email'
            name='email'
            placeholder="user@domain.com"
            size={5}
            required
          />
        </div>
        { }
        <div className="w-full flex-grow lg:w-5/12">
          <label className="text-sm text-gray-500" htmlFor>
            Confirm email <span className="text-red-500">*</span>
          </label>
          <input
            className="w-full h-12 border border-gray-200 p-2 bg-white text-sm"
            onChange={(event) => handleChange(index, event.target, isParent)}
            value={data.confirmemail}
            type='email'
            name='confirmemail'
            placeholder="user@domain.com"
            size={5}
            required
          />
        </div>
        {console.log(data.dob.type)}
        <div className="w-full flex-grow lg:w-5/12">
          <label className="text-sm text-gray-500" htmlFor>
            Date of birth <span className="text-red-500">*</span>
          </label>
          <input
            className="w-full h-12 border border-gray-200 p-2 bg-white text-sm"
            type="date"
            value={moment(new Date(data.dob)).format("yyyy-MM-DD")}
            name='dob'
            onChange={(event) => {
              console.log(event.target.value)
              handleChange(index, { name: 'dob', value: new Date(event.target.value) }, isParent)
            }}
            size={5}
            required
          />
        </div>
        { }
        <div className="w-full flex-grow lg:w-5/12">
          <label className="text-sm text-gray-500" htmlFor>
            Mobile number <span className="text-red-500">*</span>
          </label>
          <div className="flex">

            <CountrySelect initialValue={data.countryCode} handleChange={(value) => handleChange(index, { name: "countryCode", value: "+" + value.countryCallingCode }, isParent)} />
            <input
              value={data.phoneNumber}
              type='number'
              maxLength={10}
              name='phoneNumber'
              onChange={(event) => {
                handleChange(index, event.target, isParent)
              }}
              size={5}
              required
              className="flex-1 h-12 min-w-0 block w-full p-2 sm:text-sm border-gray-200"
            />
          </div>
        </div>
      </div>
    </div>
  }

  return (
    <div className="block px-4 pb-4">
      <h3 className="text-2xl text-brand-gold-dark uppercase mb-4">
        Register For An Event
      </h3>
      { }
      <div className="flex flex-row flex-wrap gap-4 mb-8 lg:flex-nowrap">
        { }
        <div className="w-full lg:w-2/4">
          <label className="text-sm text-gray-500" htmlFor>
            Number of attendees? <span className="text-red-500">*</span>
          </label>
          <input
            id
            className="w-full h-12 border border-gray-200 p-2 bg-white text-sm"
            type="number"
            name="attendees"
            defaultValue={1}
            value={attendNo}
            min={0}
            onChange={handleAttane}
            size={5}
            required
          />
        </div>
        { }
        {eventData.isParentsIncluded ?
          <div className="w-full lg:w-2/4">
            <label className="text-sm text-gray-500" htmlFor>
              Is parent attending? <span className="text-red-500">*</span>
            </label>
            <select
              name="services"
              id="services"
              className="w-full h-12 p-2 border-gray-200 text-sm"
              onChange={e => {
                switch (e.target.value) {
                  case "yes":
                    addParent()
                    break
                  default:
                    removeParent()
                    break
                }
                console.log(e.target.value)
              }}
              required
            >
              <option value>Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div> : <></>}
      </div>
      {parent != null ? AdultCell(parent, 0, true) : <></>}
      {attendies.map((data, index) => {
        return !eventData.isForChildren ?
          AdultCell(data, index) :
          <div className="block p-4 bg-brand-gold-light mb-4">
            <h3 className="pb-1 border-b border-gray-500 border-opacity-20 text-lg font-bold mb-2">
              Minor Details
            </h3>
            <div className="w-full flex flex-wrap justify-between gap-2 lg:gap-4">
              { }
              <div className="w-full flex-grow lg:w-5/12">
                <label className="text-sm text-gray-500" htmlFor>
                  First name <span className="text-red-500">*</span>
                </label>
                <input
                  id
                  className="w-full h-12 border border-gray-200 p-2 bg-white text-sm"
                  value={data.firstName}
                  onChange={(event) => handleChange(index, event.target)}
                  type='text'
                  name='firstName'
                  placeholder="First Name"
                  required
                />
              </div>
              { }
              <div className="w-full flex-grow lg:w-5/12">
                <label className="text-sm text-gray-500" htmlFor>
                  Last name <span className="text-red-500">*</span>
                </label>
                <input
                  id
                  className="w-full h-12 border border-gray-200 p-2 bg-white text-sm"
                  value={data.lastName}
                  onChange={(event) => handleChange(index, event.target)}
                  type='text'
                  name='lastName'
                  placeholder="Last Name"
                  size={5}
                  required
                />
              </div>
              { }
              <div className="w-full flex-grow lg:w-5/12">
                <label className="text-sm text-gray-500" htmlFor>
                  Date of birth <span className="text-red-500">*</span>
                </label>
                <input
                  id
                  className="w-full h-12 border border-gray-200 p-2 bg-white text-sm"
                  type="date"
                  value={moment(new Date(data.dob)).format("yyyy-MM-DD")}
                  name='dob'
                  onChange={(event) => {
                    console.log(event.target.value)
                    handleChange(index, { name: 'dob', value: new Date(event.target.value) })
                  }}
                  size={5}
                  required
                />
              </div>
            </div>
          </div>
      })}
      { }

      { }
      <div className="w-full mb-6">
        {/* <ReCAPTCHA
            sitekey={process.env.REACT_APP_CAPTCHA_KEY}
            onChange={onCaptchaChange}
          /> */}
      </div>
      <div className="agreement mb-6">
        <input
          type='checkbox'
          value={isUserAgree}
          onChange={togglePolicyAgreement}
        />
        <span className="text-sm ml-2">
          I have read and understand the{" "}
          <a
            href="https://www.lighthousearabia.com/experience/our-event-policy/"
            target="_blank"
            className="text-blue-700 hover:underline"
          >
            Event Cancellation and Refund Policy
          </a>
          .
        </span>
      </div>
      <button
        onClick={handleNext}
        className="block w-full p-4 bg-brand-blue text-white"
      >
        Next Step: Confirm Attendees & Checkout
      </button>
    </div>
  );
};

FirstStep.propTypes = {
  attendNo: PropTypes.number,
  attendies: PropTypes.array.isRequired,
  classes: PropTypes.shape({
    map: PropTypes.string,
    inputTitle: PropTypes.string.isRequired,
    formControl: PropTypes.string.isRequired,
    paper: PropTypes.string.isRequired,
    inputsty: PropTypes.string.isRequired,
    errorText: PropTypes.string.isRequired,
    button2: PropTypes.string.isRequired,
    button2Text: PropTypes.string.isRequired,
    smallBox: PropTypes.string.isRequired,
  }).isRequired,
  handleNext: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleAttane: PropTypes.func.isRequired,
};

export default FirstStep;
