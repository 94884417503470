/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React, { Children } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Toolbar } from "./Toolbar";
import { Header } from "./Header";
import { Events } from "./Events";
import "../../../assets/css/calendar.scss";
import styles from "./CustomCalendar_style";


const localizer = momentLocalizer(moment);

export const CustomCalendar = ({ onSelectEvent, ...props }) => {
  const classes = styles();
  const [isSlot, setSlot] = React.useState(false);  
  return (
    <>
      <Calendar
        className={classes.root}
        dateHeader ={() => {}}
        localizer={localizer}
        events={props.events}
        defaultView="month"
        startAccessor="start"
        endAccessor="end"
        components={{
          toolbar: Toolbar,
          header: Header,
          event: (event) => (
            <Events
              event={event}
              slot={isSlot}
              onSelectEvent={(_event) =>
                onSelectEvent(_event)
              }
            />
          ),
        }}
      />
    </>
  );
};

CustomCalendar.defaultProps = {
  onSelectEvent: () => {},
};
