/* eslint-disable import/named */
import {
  LOGIN_USER_REQUEST,
  SIGNUP_USER_REQUEST,
  CLINICAL_SIGNUP_REQUEST,
  USER_LOGIN,
  USER_SIGNUP,
  CLOSE_OTP_MODAL,
} from '../constant';

export const loginAction = (data, history) => ({
  type: LOGIN_USER_REQUEST,
  endpoint: '/api/user/login',
  body: data,
  history,
});

export const signupAction = (data, history) => ({
  type: SIGNUP_USER_REQUEST,
  endpoint: '/api/user/signup',
  body: data,
  history,
});

export const clinicalSignupAction = (data) => ({
  type: CLINICAL_SIGNUP_REQUEST,
  endpoint: '/api/user/signup',
  body: data,
});

export const userSignup = (data) => ({
  type: USER_SIGNUP,
  body: data,
});

export const userLogin = (data) => ({
  type: USER_LOGIN,
  body: data,
});

export const closeOTPModal = () => ({
  type: CLOSE_OTP_MODAL,
});
