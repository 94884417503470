import React from 'react';
import CheckoutAppointmentMeta from './CheckoutAppointmentMeta';
import userIcon from '../../../assets/imgs/download.png';

const CheckoutClientData = ({ invoice, services }) => (
  <div className='block bg-white mx-auto max-w-3xl p-8 border border-gray-200 mb-4'>
    <div className='flex flex-row flex-nowrap gap-4'>
      <div className='item flex-initial'>
        {console.log(invoice?.clients[0].avatar)}
        <img
          className='rounded-full'
          src={invoice?.clients[0].avatar ?? userIcon}
          width={70}
          height='auto'
          alt
        />
      </div>

      <CheckoutAppointmentMeta invoice={invoice} services={services} />
    </div>
  </div>
);

export default CheckoutClientData;
