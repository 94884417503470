import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  root: {
    padding: '20px',
    marginLeft: 250,
    marginTop: 70,
    position: 'static',
  },
  title: {
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '37px',
    color: '#003d79',
    textTransform: 'capitalize',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  initabcolor: {
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontSize: 13,
    color: '#003d79',
    fontWeight: 400,
    textTransform: 'capitalize',
  },
  panelsty: {
    textAlign: 'left',
  },
});
