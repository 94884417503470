import {
  ADD_APPOINTMENT_REQUEST,
  ADD_APPOINTMENT_SUCCESS,
  ADD_APPOINTMENT_FAILED,
  DELETE_APPOINTMENT_FAILED,
  DELETE_APPOINTMENT_SUCCESS,
  DELETE_APPOINTMENT_REQUEST,
  UPDATE_APPOINTMENT_FAILED,
  UPDATE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_REQUEST,
  GET_BY_ID_APPOINTMENT_FAILED,
  GET_BY_ID_APPOINTMENT_SUCCESS,
  GET_BY_ID_APPOINTMENT_REQUEST,
  GET_APPOINTMENT_FAILED,
  GET_APPOINTMENT_SUCCESS,
  GET_APPOINTMENT_REQUEST,
  GET_SERVICE_FAILED_USER,
  GET_SERVICE_SUCCESS_USER,
  GET_SERVICE_REQUEST_USER,
  GET_APPOINTMENT_BY_CLIENT_REQUEST,
  GET_APPOINTMENT_BY_CLIENT_SUCCESS,
  GET_APPOINTMENT_BY_CLIENT_FAILED,
  GET_APPOINTMENT_DETAIL_REQUEST,
  GET_APPOINTMENT_DETAIL_SUCCESS,
  GET_APPOINTMENT_DETAIL_FAILED,
  GET_PREVIOUS_APPOINTMENT_REQUEST,
  GET_PREVIOUS_APPOINTMENT_SUCCESS,
  GET_PREVIOUS_APPOINTMENT_FAILED,
  FILTERED_APPOINTMENTS_REQUEST,
  FILTERED_APPOINTMENTS_SUCCESS,
  FILTERED_APPOINTMENTS_FAILED,
  SEEN_STATUS_SUCCESS,
  SEEN_STATUS_FAILED,
  SEEN_STATUS_REQUEST,
  GET_CONSENT_INFO_REQUEST,
  GET_CONSENT_INFO_FAILED,
  GET_CONSENT_INFO_SUCCESS,
  SIGN_CONSENT_REQUEST,
  SIGN_CONSENT_SUCCESS,
  SIGN_CONSENT_FAILED,
  CLEAR_CREATED_APPOINTMENT,
  CLINICIAN_SIGN_CONSENT_REQUEST,
  CLINICIAN_SIGN_CONSENT_SUCCESS,
  CLINICIAN_SIGN_CONSENT_FAILED,
  APPOINTMENTS_OF_CLIENT_REQUEST,
  APPOINTMENTS_OF_CLIENT_SUCCESS,
  APPOINTMENTS_OF_CLIENT_FAILED,
  APPOINTMENTS_OF_CLIENT_REPORT_REQUEST,
  APPOINTMENTS_OF_CLIENT_REPORT_SUCCESS,
  APPOINTMENTS_OF_CLIENT_REPORT_FAILED,
} from '../constant';

export const consent = (state = [], action) => {
  switch (action.type) {
    case GET_CONSENT_INFO_REQUEST:
      return {
        request: true,
      };
    case GET_CONSENT_INFO_SUCCESS:
      return {
        request: false,
        info: action.response,
      };
    case GET_CONSENT_INFO_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const signConsent = (state = [], action) => {
  switch (action.type) {
    case SIGN_CONSENT_REQUEST:
      return {
        request: true,
      };
    case SIGN_CONSENT_SUCCESS:
      return {
        request: false,
        success: true,
      };
    case SIGN_CONSENT_FAILED:
      return {
        request: false,
        success: false,
      };
    default:
      return state;
  }
};

export const addAppointment = (state = [], action) => {
  switch (action.type) {
    case ADD_APPOINTMENT_REQUEST:
      return {
        request: true,
      };
    case ADD_APPOINTMENT_SUCCESS:
      return {
        request: false,
        addAppointmentData: action.response,
      };
    case ADD_APPOINTMENT_FAILED:
      return {
        request: false,
        error: true,
      };
    case CLEAR_CREATED_APPOINTMENT:
      return {
        request: false,
        addAppointmentData: null,
      };
    default:
      return state;
  }
};

export const getAppointment = (state = [], action) => {
  switch (action.type) {
    case GET_APPOINTMENT_REQUEST:
      return {
        request: true,
      };
    case GET_APPOINTMENT_SUCCESS:
      return {
        request: false,
        getAppointmentData: action.response,
      };
    case GET_APPOINTMENT_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getByIdAppointment = (state = [], action) => {
  switch (action.type) {
    case GET_BY_ID_APPOINTMENT_REQUEST:
      return {
        request: true,
      };
    case GET_BY_ID_APPOINTMENT_SUCCESS:
      return {
        request: false,
        getByIdAppointmentData: action.response,
      };
    case GET_BY_ID_APPOINTMENT_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const updateAppointment = (state = [], action) => {
  switch (action.type) {
    case UPDATE_APPOINTMENT_REQUEST:
      return {
        request: true,
      };
    case UPDATE_APPOINTMENT_SUCCESS:
      return {
        request: false,
        updateAppointmentData: action.response,
      };
    case UPDATE_APPOINTMENT_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const deleteAppointment = (state = [], action) => {
  switch (action.type) {
    case DELETE_APPOINTMENT_REQUEST:
      return {
        request: true,
      };
    case DELETE_APPOINTMENT_SUCCESS:
      return {
        request: false,
        deleteAppointmentData: action.response,
      };
    case DELETE_APPOINTMENT_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getServiceUser = (state = [], action) => {
  switch (action.type) {
    case GET_SERVICE_REQUEST_USER:
      return {
        request: true,
      };
    case GET_SERVICE_SUCCESS_USER:
      return {
        request: false,
        getServiceDataUser: action.response,
      };
    case GET_SERVICE_FAILED_USER:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getAppointmentByClientId = (state = [], action) => {
  switch (action.type) {
    case GET_APPOINTMENT_BY_CLIENT_REQUEST:
      return {
        request: true,
      };
    case GET_APPOINTMENT_BY_CLIENT_SUCCESS:
      return {
        request: false,
        getAppointmentByClientData: action.response,
      };
    case GET_APPOINTMENT_BY_CLIENT_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getAppointmentDetail = (state = [], action) => {
  switch (action.type) {
    case GET_APPOINTMENT_DETAIL_REQUEST:
      return {
        request: true,
      };
    case GET_APPOINTMENT_DETAIL_SUCCESS:
      return {
        request: false,
        getAppointmentDetailData: action.response,
      };
    case GET_APPOINTMENT_DETAIL_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getPreviousAppointment = (state = [], action) => {
  switch (action.type) {
    case GET_PREVIOUS_APPOINTMENT_REQUEST:
      return {
        request: true,
      };
    case GET_PREVIOUS_APPOINTMENT_SUCCESS:
      return {
        request: false,
        getPreviousAppointmentData: action.response,
      };
    case GET_PREVIOUS_APPOINTMENT_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const filterAppointments = (state = [], action) => {
  switch (action.type) {
    case FILTERED_APPOINTMENTS_REQUEST:
      return {
        applyingFilters: true,
        count: 0,
        appointments: [],
      };
    case FILTERED_APPOINTMENTS_SUCCESS:
      return {
        applyingFilters: false,
        count: action.response.count,
        appointments: action.response.data,
      };
    case FILTERED_APPOINTMENTS_FAILED:
      return {
        error: action.error,
        applyingFilters: false,
        count: 0,
        appointments: [],
      };
    default:
      return state;
  }
};

export const updateToSeen = (state = [], action) => {
  switch (action.type) {
    case SEEN_STATUS_REQUEST:
      return {
        loading: true,
        error: false,
        message: '',
      };
    case SEEN_STATUS_SUCCESS:
      return {
        loading: false,
        error: false,
        message: '',
      };
    case SEEN_STATUS_FAILED:
      return {
        loading: false,
        error: true,
        message: 'Some thing went wrong. Please try again!',
      };
    default:
      return state;
  }
};

export const clinicianSign = (state = { request: false }, action) => {
  switch (action.type) {
    case CLINICIAN_SIGN_CONSENT_REQUEST:
      return {
        request: true,
      };
    case CLINICIAN_SIGN_CONSENT_SUCCESS:
      return {
        request: false,
        success: true,
      };
    case CLINICIAN_SIGN_CONSENT_FAILED:
      return {
        request: false,
        success: false,
      };
    default:
      return state;
  }
};

export const getAllAppointmentByBilling = (state = [], action) => {
  switch (action.type) {
    case APPOINTMENTS_OF_CLIENT_REQUEST:
      return {
        request: true,
      };
    case APPOINTMENTS_OF_CLIENT_SUCCESS:
      return {
        request: false,
        data: action.response.data,
        count: action.response.count,
      };
    case APPOINTMENTS_OF_CLIENT_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const billingReportForAllAppointments = (state = [], action) => {
  switch (action.type) {
    case APPOINTMENTS_OF_CLIENT_REPORT_REQUEST:
      return {
        request: true,
      };
    case APPOINTMENTS_OF_CLIENT_REPORT_SUCCESS:
      return {
        request: false,
        data: action.response.data,
      };
    case APPOINTMENTS_OF_CLIENT_REPORT_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};
