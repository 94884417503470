import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({

   
      btnn: {
        height: "39px",
        borderRadius:'8px',
        backgroundColor: "#CEDBEB",
        "&:hover": { backgroundColor: "#003265" },
        cursor: "pointer",
        fontSize: "17px",
        color: "#fffff",
        lineHeight: "40px",
        fontFamily: "Lato",
        fontWeight: 700,
        textAlign: "center",
        marginTop: "-2px",
        marginTop:'25px',
        marginRight: '32px' ,
      },
      downbtn:{
        marginTop:'0px',
        backgroundColor: "#CEDBEB",
        "&:hover": { backgroundColor: "#003265" },
      },
      body:{
        fontFamily: "Lato",
        color: "#003265",
      },
      table: {
        minWidth: 650,
      },

    
}))