import moment from 'moment-timezone';

const slots = [
  {
    title: 'New-Patient',
    start: new Date(),
    end: new Date(),
    color: 'rgba(255,161,64, 0.3)',
  },
  {
    title: 'Current-Patient',
    start: new Date(),
    end: new Date(),
    color: 'rgba(0,207,195,0.3)',
  },
  {
    title: 'All-Patient',
    start: new Date(),
    end: new Date(),
    color: 'rgba(248,64,71, 0.3)',
  },
  {
    title: 'Blocked',
    start: new Date(),
    end: new Date(),
    color: 'rgba(0,0,0, 1)',
  },
  {
    title: 'Other',
    start: new Date(),
    end: new Date(),
    color: '#745C97',
  },
];

const slotsWithoutBlock = [
  {
    title: 'New-Patient',
    start: new Date(),
    end: new Date(),
    color: 'rgba(255,161,64, 0.3)',
  },
  {
    title: 'Current-Patient',
    start: new Date(),
    end: new Date(),
    color: 'rgba(0,207,195,0.3)',
  },
  {
    title: 'All-Patient',
    start: new Date(),
    end: new Date(),
    color: 'rgba(248,64,71, 0.3)',
  },
  {
    title: 'Other',
    start: new Date(),
    end: new Date(),
    color: '#745C97',
  },
];

const getEventColor = (type) => {
  let color = '#745C97';

  if (type === 'Current-Patient') {
    color = 'rgba(0,207,195,0.3)';
  } else if (type === 'New-Patient') {
    color = 'rgba(255,161,64, 0.3)';
  } else if (type === 'All-Patient') {
    color = 'rgba(248,64,71, 0.3)';
  } else if (type === 'Blocked') {
    color = 'rgba(0,0,0, 1)';
  } else if (type === 'Appointment') {
    color = '#9FDAFF';
  }

  return color;
};

const startEndTimeCalender = () => {
  var dubaiStart = moment.tz('2021-06-01 06:00', 'Asia/Dubai');
  var dubaiEnd = moment.tz('2021-06-01 22:00', 'Asia/Dubai');

  var tz = moment.tz.guess();

  // console.log(
  //   dubaiStart.tz(tz).format('HH:mm:ss'),
  //   dubaiEnd.tz(tz).format('HH:mm:ss'),
  //   tz
  // );
  return tz != 'Asia/Dubai'
    ? { start: '00:00:00', end: '24:00:00' }
    : { start: '06:00:00', end: '22:00:00' };
};

export { slots, getEventColor, startEndTimeCalender, slotsWithoutBlock };
