import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import styled from 'styled-components';

export default makeStyles({
  toolbar: {
    width: '100%',
    height: '100px',
    backgroundColor: '#003265',
  },
  root: {
    maxWidth: 255,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  inputstyle: {
    backgroundColor: '#fff',
  },
  table: {
    minWidth: 650,
  },
  imgTable: {
    width: '40px',
    height: '40px',
    objectFit: 'contain',
    borderRadius: '50px',
  },
  viewBtn: {
    borderRadius: '15px',
    width: '100px',
    padding: '4px',
    boxShadow: 'none',
    textTransform: 'capitalize',
  },
  btnNewApp: {
    backgroundColor: '#003265',
    color: '#fff',
    marginLeft: '50px',
    '&:hover': {
      backgroundColor: '#003265',
    },
  },
  paginationRoot: {
    marginTop: 15,
    float: 'right',
    '& > * + *': {
      marginTop: '16px',
    },
  },
});

const Required = styled.span`
  color: red;
`;

const CellBtn = styled(Button)`
  padding: 0px;
  min-width: 0px;
  font-weight: 400;
  &:hover {
    background-color: transparent;
  }
`;

const SendEmail = styled(Button)`
  text-transform: none;
  font-size: 12px;
  color: #FFFFFF;
  background: #3F51B5;
  padding: 8px;
  min-width: 0px;
  font-weight: 400;
  &:hover {
    background-color: transparent;
  }
`;

const CancelLink = styled(Button)`
  text-transform: none;
  font-size: 12px;
  color: #FFFFFF;
  background: #FF0000;
  padding: 8px;
  min-width: 0px;
  font-weight: 400;
  &:hover {
    background-color: transparent;
  }
`;

const Status = styled(Typography)`
  text-transform: capitalize;
`;
export { Required, CellBtn, CancelLink, Status, SendEmail };
