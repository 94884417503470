/* eslint-disable no-unused-vars */
/* eslint-disable quotes */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
import {
  ADD_ROLE_REQUEST,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAILED,
  GET_ROLE_REQUEST,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAILED,
  GET_ROLE_BY_ID_REQUEST,
  GET_ROLE_BY_ID_SUCCESS,
  GET_ROLE_BY_ID_FAILED,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILED,
  DELETE_ROLE_FAILED,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_REQUEST,
} from "../AdminConstent";

const initialState = {
  request: true,
  error: false,
};

export const addRole = (state = [], action) => {
  switch (action.type) {
    case ADD_ROLE_REQUEST:
      return {
        request: true,
      };
    case ADD_ROLE_SUCCESS:
      return {
        request: false,
        addRoleData: action.response,
      };
    case ADD_ROLE_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getRole = (state = [], action) => {
  switch (action.type) {
    case GET_ROLE_REQUEST:
      return {
        request: true,
      };
    case GET_ROLE_SUCCESS:
      return {
        request: false,
        getRoleData: action.response,
      };
    case GET_ROLE_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getByIdRole = (state = [], action) => {
  switch (action.type) {
    case GET_ROLE_BY_ID_REQUEST:
      return {
        request: true,
      };
    case GET_ROLE_BY_ID_SUCCESS:
      return {
        request: false,
        getByIdRoleData: action.response,
      };
    case GET_ROLE_BY_ID_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const updateRole = (state = [], action) => {
  switch (action.type) {
    case UPDATE_ROLE_REQUEST:
      return {
        request: true,
      };
    case UPDATE_ROLE_SUCCESS:
      return {
        request: false,
        updateRoleData: action.response,
      };
    case UPDATE_ROLE_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const deleteRole = (state = [], action) => {
  switch (action.type) {
    case DELETE_ROLE_REQUEST:
      return {
        request: true,
      };
    case DELETE_ROLE_SUCCESS:
      return {
        request: false,
        deleteRoleData: action.response,
      };
    case DELETE_ROLE_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};
