/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import { takeEvery, put } from "redux-saga/effects";
import { post, get, updatePatch, delet } from "../axios";
import {
  ADD_RCADS_SUCCESS,
  ADD_RCADS_FAILED,
  GET_RCADS_SUCCESS,
  GET_RCADS_FAILED,
  GET_RCADS_BY_ID_SUCCESS,
  GET_RCADS_BY_ID_FAILED,
  UPDATE_RCADS_SUCCESS,
  UPDATE_RCADS_FAILED,
  DELETE_RCADS_SUCCESS,
  DELETE_RCADS_FAILED,
  ADD_RCADS_REQUEST,
  GET_RCADS_BY_ID_REQUEST,
  DELETE_RCADS_REQUEST,
  UPDATE_RCADS_REQUEST,
  GET_RCADS_REQUEST,
} from "../AdminConstent";
import AlertMsg from "../Alert";
import {
  USER_RCAD_SUBMIT_SUCCESS,
  USER_RCAD_SUBMIT_FAILED,
  USER_RCAD_SUBMIT_REQUEST,
} from "../constant";

function* addRcad(action) {
  try {
    const { endpoint, body } = action;
    const response = yield post(endpoint, body);
    AlertMsg("success", "Added");
    yield put({ type: ADD_RCADS_SUCCESS, response });
  } catch (error) {
    AlertMsg("error", "Failed");
    yield put({ type: ADD_RCADS_FAILED, error });
  }
}

function* getRcadSaga(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_RCADS_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_RCADS_FAILED, error });
  }
}

function* getByIdRcad(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_RCADS_BY_ID_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_RCADS_BY_ID_FAILED, error });
  }
}

function* updateRcad(action) {
  try {
    const { endpoint, body } = action;
    AlertMsg("success", "Updated");
    const response = yield updatePatch(endpoint, body);
    yield put({ type: UPDATE_RCADS_SUCCESS, response });
  } catch (error) {
    AlertMsg("error", "Failed");
    yield put({ type: UPDATE_RCADS_FAILED, error });
  }
}

function* deleteRcad(action) {
  try {
    const { endpoint } = action;
    const response = yield delet(endpoint);
    AlertMsg("success", "Deleted");
    yield put({ type: DELETE_RCADS_SUCCESS, response });
  } catch (error) {
    AlertMsg("error", "Failed");
    yield put({ type: DELETE_RCADS_FAILED, error });
  }
}

function* submitUserRcad(action) {
  try {
    const { endpoint, body } = action;
    const response = yield post(endpoint, body);
    AlertMsg("success", "Added");
    yield put({ type: USER_RCAD_SUBMIT_SUCCESS, response });
  } catch (error) {
    AlertMsg("error", "Failed");
    yield put({ type: USER_RCAD_SUBMIT_FAILED, error });
  }
}

export function* rcadSaga() {
  yield takeEvery(ADD_RCADS_REQUEST, addRcad);
  yield takeEvery(GET_RCADS_REQUEST, getRcadSaga);
  yield takeEvery(GET_RCADS_BY_ID_REQUEST, getByIdRcad);
  yield takeEvery(UPDATE_RCADS_REQUEST, updateRcad);
  yield takeEvery(DELETE_RCADS_REQUEST, deleteRcad);
  yield takeEvery(USER_RCAD_SUBMIT_REQUEST, submitUserRcad);
}
