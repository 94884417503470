import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { DashboardHeader } from '../../../components/molecules';
import { Table, RectangledLogo } from '../../../components/atoms';
import { Content, PaginationGrid } from './style';
import { getCompaniesWithAssociations } from '../../../utils/actions/company.action';
import AlertMsg from '../../../utils/Alert';
import { getPageCount } from '../../../utils/MathUtil';
import { Pagination } from '@material-ui/lab';
import CalenderCreateApointment from '../../../common/CalenderCreateNewAppointments';

const Listing = ({
  getCompaniesWithAssociations,
  loading,
  companies,
  error,
  history,
  count,
}) => {
  const [corporations, setCorporations] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState('');
  const [searchText, setSearchText] = useState('');
  const [sortType, setSortType] = useState('desc');
  const [openCreateAppointment, setOpenCreateAppointment] = useState(false);

  useEffect(() => {
    setLoading(true);
    getCompaniesWithAssociations(page, sortType, sortBy, searchText);
  }, [page, sortType, sortBy, searchText]);

  useEffect(() => {
    if (companies && companies.length > 0) {
      let listing = [];

      companies.forEach((com) => {
        listing.push({
          ...com,
          createdAt: moment(new Date(com.createdAt)).format('LL'),
        });
      });
      setTotalPages(count);
      setCorporations(listing);
    }

    if (!loading) {
      setLoading(false);
    }
  }, [loading, companies, error]);

  const headers = {
    logo: {
      title: 'Logo',
      component: RectangledLogo,
      conditionalComponent: true,
      propFields: [{ src: 'logo' }],
    },
    name: { title: 'Company Name', isSortActive: true },
    createdAt: { title: 'Date Created', isSortActive: true },
    numberOfPatients: {
      title: '# of Patients',
      isLink: true,
      dynamicLink: true,
      url: '/admin/corporation/patients/:id',
      urlParams: { id: '_id' },
      isSortActive: true,
    },
    numberOfEvents: {
      title: '# of Events',
      isLink: true,
      dynamicLink: true,
      url: '/admin/corporation/events/:id',
      urlParams: { id: '_id' },
      isSortActive: true,
    },
  };

  const createNewCorporation = () => history.push('/admin/corporation/add-new');
  const searchByText = (e) => {
    setPage(1);
    setSearchText(e.target.value);
  };

  const handleFilterClick = () => console.log('handleFilterClick');

  const handleEditClick = (id) => history.push(`/admin/corporation/edit/${id}`);

  const handlePageChange = (e, value) => {
    setCorporations([]);
    window.scrollTo(0, 100);
    setPage(value);
  };

  const sort = (keyName) => {
    if (keyName === sortBy) {
      const toggledOrder = sortType === 'desc' ? 'asc' : 'desc';
      setSortType(toggledOrder);
    }

    setSortBy(keyName);
  };

  const createNewAppointment = () => setOpenCreateAppointment(true);

  const closeAppointmentDrawer = () => setOpenCreateAppointment(false);

  return (
    <Grid>
      <DashboardHeader
        searchByText={searchByText}
        value={searchText}
        createBtnText='New Corporate Client'
        createBtnText2='Create Appointment'
        onFilterClick={handleFilterClick}
        onClick={createNewCorporation}
        onClick2={createNewAppointment}
      />
      <Content>
        <Table
          isDataLoading={loading}
          data={corporations}
          headers={headers}
          showTableHeaders
          showActionBtns
          showViewBtn={false}
          onEditClick={handleEditClick}
          emptyTableMessage='No company added yet!'
          history={history}
          sort={sort}
        />
      </Content>
      <Grid>
        {corporations && corporations.length > 0 && (
          <PaginationGrid justify='flex-end' container>
            <Pagination
              count={getPageCount(totalPages)}
              page={page}
              onChange={handlePageChange}
              color='primary'
              shape='rounded'
            />
          </PaginationGrid>
        )}
      </Grid>
      <CalenderCreateApointment
        open={openCreateAppointment}
        handleClose={closeAppointmentDrawer}
        handleSubmit={closeAppointmentDrawer}
        isAdmin
        isCorporate
      />
    </Grid>
  );
};

Listing.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  getCompaniesWithAssociations: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  companies: PropTypes.array,
  error: PropTypes.any,
  count: PropTypes.number,
};

const mapStateToProps = (state) => ({
  loading: state.CompanyReducer.loading,
  companies: state.CompanyReducer.companies,
  error: state.CompanyReducer.error,
  count: state.CompanyReducer.count,
});

export default connect(mapStateToProps, { getCompaniesWithAssociations })(
  Listing
);
