import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  withStyles,
  Grid,
  Typography,
  Input,
  Button,
  createStyles,
  LinearProgress,
} from "@material-ui/core";
import { baseURL } from "../utils/axios";
import { uploadDocFileAction } from "../utils/actions/client.action";
import Axios from "axios";
import AlertMsg from "../utils/Alert";
import ProofOfAddressModel from './ProofOfAddressModel'
import { useSelector } from 'react-redux'

function DocumentsUploadsView(props) {
  const { classes } = props;
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const [openProofOfAddress, setOpenProofOfAddress] = useState(false)
  const [data, setData] = useState({})
  const clientData = useSelector(state => state.getClient.getClientData)


  useEffect(() => {
    console.log(clientData)
    if (clientData) {
      if (clientData.proofOfAddress) {
        console.log(clientData.proofOfAddress)
        // let val = clientData.proofOfAddress[0]
        // setData(val)
        // setUrl(`${val.address1} ${val.address2}, ${val.city}, ${val.state ? val.state.name : ""}, ${val.country.name}`)
      }
    }
  }, [clientData]);

  useEffect(() => {
    if (props.uploadDocFileData) {
      setIsLoading(false)
      if (props.uploadDocFileData.type == "proofOfAddress") {
        const postData = { ...data }
        delete postData.file
        postData.documentId = props.uploadDocFileData._id
        postData.clientId = localStorage.getItem('clientId')
        postProofOfAddress(postData)
      }
    }
  }, [props.uploadDocFileData]);

  useEffect(() => {
    if (props.url) {
      setUrl(props.url);
    }
  }, [props.url]);


  const postProofOfAddress = (data) => {
    setIsLoading(true)
    const token = localStorage.getItem("ACCESS_TOKEN_PATH");
    Axios.post(baseURL + "/api/client/proofOfAddress", data, {
      headers: {
        Authorization: token,
      },
    })
      .then((data) => {
        setIsLoading(false)
        if (data.data) {
          let val = data.data
          setUrl(`${val.address1} ${val.address2}, ${val.city}, ${val.state ? val.state.name : ""}, ${val.country.name}`)
        }
      })
      .catch((e) => {
        AlertMsg("error", e.message);
        setIsLoading(false)
      });
  }

  const docFileUpload = async (selectedFiles) => {
    const formData = new FormData();
    formData.append("document", selectedFiles);

    if (props.isUploading) {
      props.isUploading(true);
    }
    setIsLoading(true)
    const token = localStorage.getItem("ACCESS_TOKEN_PATH");
    if (props.isPatient) {
      props.uploadDocFileAction(formData, props.docType);
    } else {
      Axios.post(baseURL + "/api/user/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      })
        .then((data) => {
          if (props.isUploading) {
            props.isUploading(false);
          }
          if (props.onUpload) {
            props.onUpload(data.data);
          }
          setUrl(data.data.url);
          setIsLoading(false)
        })
        .catch((e) => {
          AlertMsg("error", e.message);
          setIsLoading(false)
        });
    }
  };

  return (
    <Grid item xs={12} style={{ marginTop: "32px", width: "100%" }}>
      {isLoading ? <LinearProgress /> : <> </>}
      <Typography className={classes.inputText}>
        {props.titleText ? props.titleText : "Document Uploads"}
      </Typography>
      <Grid
        container
        style={{ marginBottom: "8px" }}
        className={classes.upload}
      >
        <Grid item xs={12}>
          <Typography>
            {url ? url : 'Upload file'}
            <Button
              style={{ marginLeft: "16px" }}
              onClick={() =>
                setOpenProofOfAddress(true)
              }
              variant="contained"
            >
              {url ? "Change Address" : "upload"}
            </Button>
          </Typography>
        </Grid>
      </Grid>
      <ProofOfAddressModel
        titleText={props.titleText}
        open={openProofOfAddress}
        handleClose={() => setOpenProofOfAddress(false)}
        handleCreateAddress={(data) => {
          setData(data)
          if (data.file && data.file.size) {
            docFileUpload(data.file);
          } else if (data.documentId) {
            const postData = { ...data }
            delete postData.file
            postData.documentId = postData.documentId._id
            postData.clientId = localStorage.getItem('clientId')
            postProofOfAddress(postData)
          }
          setOpenProofOfAddress(false)
        }}
        editingAddress={data} />
    </Grid>
  );
}

const style = createStyles({
  inputText: {
    fontSize: "22px",
    lineHeight: "28px",
    marginBottom: "15px",
    color: "#003265",
    padding: "5px",
    paddingLeft: 0,
    textAlign: "left",
  },
  paper: {
    padding: "20px",
    height: "100%",
    boxShadow: "none",
    borderRadius: 0,
  },
  inputTitle: {
    textAlign: "left",
    fontSize: "13px",
    color: "grey",
    fontWeight: "500",
    marginBottom: "4px",
  },
  inputsty: {
    border: "1px solid #e5e5e5",
    borderRadius: "0",
    height: "45px",
    width: "100%",
  },
  imgBox: {
    backgroundColor: "#003265",
    padding: "25px",
    width: 170,
    height: 170,
  },
  img: {
    borderRadius: "50%",
    width: "125px",
    height: "125px",
    objectFit: "cover",
  },
  formControl: {
    minWidth: 120,
  },
  drag: {
    width: "90%",
    height: "100%",
    borderStyle: "dashed",
    borderWidth: 2,
    borderColor: "#ccc",
    textAlign: "center",
    marginLeft: 20,
    color: "#afc2df",
  },
  italicTitle: {
    fontStyle: "italic",
    fontSize: "13px",
    textAlign: "left",
    color: "#807c7c",
  },
  tile: {
    padding: "20px",
    backgroundColor: "#f1f4f8",
    textAlign: "left",
  },
  upload: {
    padding: "16px",
    display: "flex",
    border: "2px solid #C4C4C4",
    textAlign: "left",
  },
  errorText: {
    color: "red",
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: 16,
  },
});

DocumentsUploadsView.propTypes = {
  classes: PropTypes.shape({
    inputText: PropTypes.string.isRequired,
    upload: PropTypes.string.isRequired,
  }).isRequired,
  url: PropTypes.string,
  fileType: PropTypes.shape({
    includes: PropTypes.func,
  }),
  isUploading: PropTypes.bool,
  isPatient: PropTypes.bool,
  uploadDocFileAction: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  titleText: PropTypes.string,
  docType: PropTypes.string,
};

const mapStateToProps = state => ({
  uploadDocFileData: state.uploadDocFile.uploadDocFileData
});

export default connect(mapStateToProps, { uploadDocFileAction })(
  withStyles(style)(DocumentsUploadsView)
);
