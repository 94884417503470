import {
  Grid,
  Typography,
  Button,
  TableCell,
  TableRow,
} from '@material-ui/core';
import styled from 'styled-components';

const Heading = styled(Typography)`
  color: #9c8657;
  size: 14px;
  line-height: 27px;
  font-family: Lato;
  font-weight: 700;
`;

const PlusBtn = styled(Button)`
  background-color: #9c8657;
  padding: 0px;
  min-width: 20px;
  min-height: 20px;
  height: 20px;
  color: #fff;
`;

const ImgText = styled(Typography)`
  margin-left: 10px;
  white-space: nowrap;
`;

const FlexCell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const MessageGrid = styled(Grid)`
  size: 18px;
  line-height: 27px;
  font-family: Lato;
  font-weight: 700;
  margin-top: 20px;
  font-size: 20px;
  padding: 10px;
`;

const ActionBtn = styled(Button)`
  background-color: #f5f5f5;
  padding: 5px 17px;
  height: 20px;
  color: #304659;
  font-size: 12px;
  font-family: Lato;
  font-weight: 700;
  border-radius: 10px;
  border: solid 0.5px rgba(112, 112, 112, 0.2);
  white-space: nowrap;
`;

const TradActionBtn = styled(Button)`
  background-color: ${(props) => props.bgcolor};
  color: ${(props) => props.color};
  border-radius: 18px;
  height: 30px;
  width: 165px;
  font-size: 14px;
  font-family: Lato;
  font-weight: 700;
  border: solid 0.5px
    ${(props) =>
      props.variant === 'outlined' ? props.color : 'rgba(112, 112, 112, 0.2)'};
  white-space: nowrap;
  text-transform: none;
  &:hover {
    background-color: ${(props) => props.bgcolor};
    color: ${(props) => props.color};
  }
`;

const SortBtn = styled(Button)`
  text-transform: none;
  &:hover {
    background-color: transparent;
  }
`;

const HeadRow = styled(TableRow)`
  white-space: nowrap;
`;

const TradTableCell = styled(TableCell)`
  background-color: #888;
  color: #fff;
`;

const IconBtn = styled(Button)`
  padding: 0px;
  border-radius: 0px;
  border: none;
  &:hover {
    background-color: transparent;
  }
`;

const ZebraRow = styled(TableRow)`
  &:nth-of-type(even) {
    background-color: #eaedee;
  }
`;

export {
  Heading,
  PlusBtn,
  FlexCell,
  ImgText,
  MessageGrid,
  ActionBtn,
  SortBtn,
  HeadRow,
  IconBtn,
  TradTableCell,
  ZebraRow,
  TradActionBtn,
};
