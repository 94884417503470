/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import {

  withStyles,
  Grid,
  Typography,
  Divider,
  Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MobileOffIcon from '@material-ui/icons/MobileOff';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import styles from './ContactStyle';

class ClientInfo extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.boxsty}>
          <Grid container spacing={0}>
            <Grid item xs={10}>
              <Typography className={classes.heading}>
                Karen Appleseed
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.gridsty}>
              <Typography className={classes.manageSty}>Manage</Typography>
              <ExpandMoreIcon
                style={{ marginRight: '15px', marginTop: '15px' }}
              />
            </Grid>
          </Grid>

          <Divider />

          <div className={classes.divsty}>
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <Typography className={classes.text}>Relationship</Typography>

                <Typography className={classes.text}>Phone</Typography>

                <Typography className={classes.text}>Email</Typography>

                <Typography className={classes.text}>Address</Typography>

                <Typography className={classes.text}>
                  Appointment Reminders
                </Typography>

                <Typography className={classes.text}>Client Portal</Typography>

                <Typography className={classes.text}>
                  Billing & Payments
                </Typography>
              </Grid>

              <Grid item xs={9}>
                <Typography className={classes.contantsty}>
                  Family Member
                </Typography>

                <div style={{ marginTop: '13px' }}>
                  <Typography display="inline" className={classes.contantsty}>
                    Family Member
                    {' '}
                  </Typography>
                  <Typography display="inline" className={classes.subtext}>
                    Mobile
                  </Typography>
                  <CallOutlinedIcon
                    display="inline"
                    className={classes.iconSty}
                  />
                  <MobileOffIcon display="inline" className={classes.iconSty} />
                </div>

                <div style={{ marginTop: '13px' }}>
                  <Typography display="inline" className={classes.contantsty}>
                    kappleseed@htn.mmd
                    {' '}
                  </Typography>
                  <Button
                    className={classes.subtext}
                    endIcon={<MailOutlineIcon className={classes.iconSty} />}
                  >
                    Work
                  </Button>
                </div>

                <Typography className={classes.contantsty}>
                  No address listed
                </Typography>

                <Typography className={classes.contantsty}>
                  Will not receive appointment reminders
                </Typography>

                <Typography className={classes.contantsty}>
                  No Client Portal access
                </Typography>

                <Typography className={classes.contantsty}>
                  Not responsible for billing
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ClientInfo);
