/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Grid,
  Paper,
  Button,
  DialogActions,
  DialogContentText,
  FormControl,
  Select,
  TextField,
  LinearProgress,
  OutlinedInput,
  MenuItem,
  Typography,
  FormControlLabel,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { baseURL } from '../../../utils/axios';
import moment from 'moment';
import Axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Drawer from '@material-ui/core/Drawer';
import useStyles, { CancelLink, SendEmail, Status } from './Events.styles';
import { getPageCount } from '../../../utils/MathUtil';
import { DATE_FORMAT } from '../../../utils/timUtils';
import Pagination from '@material-ui/lab/Pagination';
import AlertMsg from '../../../utils/Alert';
import {
  ButtonWithNoPadding,
  Dialog,
  Button as ConfirmBtn,
  Spinner,
} from '../../../components/atoms';
import PatientDetailClinician from '../../../clinician/Patients/patientDetail';
import { cancelRSVP } from '../../../utils/actions/events.action';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const RSVP = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [rsvpList, setRspvList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [patientDrawer, setPatientDrawer] = useState(false);
  const [patientId, setPatientId] = useState('');

  useEffect(() => {
    if (props.open) {
      getRSVP();
    }
  }, [props.open]);

  useEffect(() => {
    setLoading(true);
    getRSVP();
  }, [page]);

  useEffect(() => {
    const { request, error, cancelledRSVP } = props.cancelRSVPResponse;

    if (!request) {
      if (cancelledRSVP) {
        const updatedRSVP = rsvpList.map((rsvp) => {
          if (rsvp._id === cancelledRSVP._id) {
            return {
              ...rsvp,
              status: cancelledRSVP.status,
            };
          } else {
            return { ...rsvp };
          }
        });

        closeCancelConfirmation();
        setRspvList(updatedRSVP);
      } else if (error) {
        closeCancelConfirmation();
        AlertMsg('error', 'Something went wrong. Please try again.');
      }
    }
  }, [props.cancelRSVPResponse]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    if (props.handleClose) {
      props.handleClose();
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getRSVP = () => {
    console.log(props?.event);
    if (!props?.event?._id) return;
    Axios.get(`${baseURL}/api/event/rsvp/${props?.event?._id}?page=${page}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        console.log('ers', res.data);
        setLoading(false);
        if (res.data) {
          setRspvList(res.data.events);
          setTotalCount(res.data.total);
        }
      })
      .catch((e) => {
        setLoading(false);
        AlertMsg('error', e.toString());
      });
  };

  const togglePatientDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setPatientDrawer(open);
  };

  const openPatientDetails = (clientId) => {
    if (clientId) {
      setPatientDrawer(true);
      setPatientId(clientId);
    }
  };

  const [cancellingRSVPId, setCancellingRSVPId] = useState('');
  const [openCancelConfirmation, setCancellingConfirmation] = useState(false);
  const [sendRegistrationEmail, setSendRegistrationEmail] = useState(false);

  const confirmCancel = (id) => {
    setCancellingRSVPId(id);
    setCancellingConfirmation(true);
  };

  const closeCancelConfirmation = () => {
    setCancellingRSVPId('');
    setCancellingConfirmation(false);
  };

  const downloadCSV = () => {
    Axios.get(`${baseURL}/api/event/rsvp/${props?.event?._id}?getall=true`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data.events) {
          console.log(res.data.events)
          download(res.data.events)
        }
      })
      .catch((e) => {
        setLoading(false);
        AlertMsg('error', e.toString());
      });
  };

  const sendEmail = () => {
    if (!selectedUserId) return
    Axios.post(`${baseURL}/api/event/sendRegistrationEmail/${selectedUserId}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        AlertMsg('success', "Email Sent!");
        setSendRegistrationEmail(false)
        setSelectedUserId(null)
      })
      .catch((e) => {
        setSendRegistrationEmail(false)
        setLoading(false);
        setSelectedUserId(null)
        AlertMsg('error', e.toString());
      });
  };

  const download = (data) => {
    let formattedData = [];
    try {
      data.map((item, i) => {
        let payment = item?.payment;
        formattedData.push({
          "Payment Reference ID": payment?.p_id,
          'EHR Billing ID': payment?.billingId,
          Date: payment?.metaData?.payment_result?.transaction_time
            ? moment(
              payment?.metaData?.payment_result?.transaction_time
            ).format('MMMM Do YYYY, h:mm a')
            : moment(item.createdAt).format('MMMM Do YYYY, h:mm a'),
          'Name of Attendee': item.firstName + ' ' + item.lastName,
          'Attendees Email': item.email,
          Event: props?.event?.title,
          'Payment Status': payment?.mode == 'card' ? payment?.metaData?.payment_result?.response_message : payment?.mode == 'paypal' ? "Authorised" : "",
          "Payment Method": payment?.amount != 0
            ? payment?.mode == 'card'
              ? 'Paytabs'
              : 'Paypal'
            : 'Free',
          'Phone No.': item.phoneNumber,
          'Bill Email Id': payment?.mode == 'card' ? payment?.metaData?.customer_details?.email : payment?.mode == 'paypal' ? payment?.metaData?.email : "",
          DOB: moment(item.dob).format(DATE_FORMAT),
          'Message to Payment Gateways': payment?.metaData?.cart_description,
          'Transaction Billing Address': formatAddress(
            payment?.metaData?.shipping_details
          ),
          'Transaction Amount': payment?.mode == 'card' ? payment?.metaData?.cart_amount : payment?.mode == 'paypal' ? payment?.amount : "",
          'Transaction Customer Name':
            payment?.metaData?.customer_details?.name || '',
          'Transaction Customer Email':
            payment?.metaData?.customer_details?.email || '',
          'Transaction Customer Phone':
            payment?.metaData?.customer_details?.phone || '',
        });
      });

      console.log('formattedData', formattedData);
      exportToCSV(formattedData, 'RSVP', 'RSVP');
    } catch (e) {
      AlertMsg('error', 'Something went wrong try again!');
    }
  }

  const formatAddress = (address) => {
    if (!address) {
      return '';
    }

    let formattedAddress = address.street1;
    if (address.city) {
      formattedAddress = formattedAddress + ', ' + address.city;
    }
    if (address.state) {
      formattedAddress = formattedAddress + ', ' + address.state;
    }
    if (address.country) {
      formattedAddress = formattedAddress + ', ' + address.country;
    }

    return formattedAddress;
  };

  const formatBillingAddress = (address) => {
    if (!address) {
      return '';
    }

    let formattedAddress = address.address1;
    if (address.city) {
      formattedAddress = formattedAddress + ', ' + address.city;
    }
    if (address?.state?.name) {
      formattedAddress = formattedAddress + ', ' + address?.state?.name;
    }
    if (address.country?.name) {
      formattedAddress = formattedAddress + ', ' + address.country?.name;
    }

    return formattedAddress;
  };

  const exportToCSV = (data, fileName, sheetName) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blobData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(blobData, fileName + fileExtension);
  };

  return (
    <Grid>
      <Spinner isOpen={props.cancelRSVPResponse.request} />
      <Drawer anchor={'right'} open={props.open} onClose={toggleDrawer(false)}>
        {errorMessage != '' ? (
          <Alert severity='error'>{errorMessage}</Alert>
        ) : (
          <></>
        )}
        <div
          style={{ marginLeft: '32px', marginRight: '32px' }}
          role='presentation'
        >
          {loading ?? <LinearProgress />}
          <Typography variant='h6' style={{ marginTop: '32px' }}>
            RSVP&apos;s
          </Typography>
          <Grid container style={{ marginTop: '16px' }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>DOB</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Cost</TableCell>
                    <TableCell>Payment Method</TableCell>
                    <TableCell>Gateway Reference ID</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rsvpList.length > 0 ? (
                    rsvpList.map((data, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell>
                            <ButtonWithNoPadding
                              onClick={() => openPatientDetails(data.clientId)}
                            >
                              <Typography
                                component={'p'}
                                className='topicDetail'
                              >
                                {data.firstName && data.lastName
                                  ? data.firstName + ' ' + data.lastName
                                  : '--'}
                              </Typography>
                            </ButtonWithNoPadding>
                          </TableCell>
                          <TableCell>
                            <Typography component={'p'} className='topicDetail'>
                              {data.email ? data.email : '--'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography component={'p'} className='topicDetail'>
                              {data.phoneNumber ? data.phoneNumber : '--'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography component={'p'} className='topicDetail'>
                              {data.dob
                                ? moment(data.dob).format(DATE_FORMAT)
                                : '--'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Status component={'p'} className='topicDetail'>
                              {data.status === 'cancelled'
                                ? 'cancelled'
                                : 'active'}
                            </Status>
                          </TableCell>
                          <TableCell>
                            <Typography component={'p'} className='topicDetail'>
                              {data?.payment?.metaData?.cart_amount}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography component={'p'} className='topicDetail'>
                              {data?.payment?.amount != 0
                                ? data?.payment?.mode == 'card'
                                  ? 'Paytabs'
                                  : 'Paypal'
                                : 'Free'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography component={'p'} className='topicDetail'>
                              {data?.payment?.p_id}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <SendEmail
                              onClick={() => {
                                setSendRegistrationEmail(true)
                                setSelectedUserId(data._id)
                              }}
                            >
                              Send Registration Email
                            </SendEmail>
                            <span>{"     "}</span>
                            {data.status !== 'cancelled' && (
                              <CancelLink
                                onClick={() => confirmCancel(data._id)}
                              >
                                Cancel RSVP
                              </CancelLink>
                            )}

                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow key={1}>
                      <TableCell
                        component='th'
                        style={{ textAlign: 'center' }}
                        colSpan={6}
                        scope='row'
                      >
                        No Data Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {!loading && rsvpList && rsvpList.length > 0 && (
            <div className='flex justify-between mt-4'>
              <button
                className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                onClick={downloadCSV}
              >
                Download
              </button>
              <Pagination
                count={getPageCount(totalCount)}
                page={page}
                onChange={handlePageChange}
                color='primary'
                shape='rounded'
              />
            </div>
          )}
        </div>
      </Drawer>
      <PatientDetailClinician
        togglePatientDrawer={togglePatientDrawer}
        patientDrawer={patientDrawer}
        patientId={patientId}
        showEventViewBtn={false}
      />
      <Dialog
        handleClose={closeCancelConfirmation}
        open={openCancelConfirmation}
      >
        <Grid
          spacing={3}
          style={{ padding: '50px' }}
          direction='column'
          container
        >
          <Grid item>
            <Typography>Are you sure, you want to cancel this RSVP?</Typography>
          </Grid>
          <Grid item>
            <Grid spacing={2} justify='flex-end' container>
              <Grid item>
                <Button onClick={closeCancelConfirmation}>Cancel</Button>
              </Grid>
              <Grid item>
                <ConfirmBtn
                  onClick={() => props.cancelRSVP(cancellingRSVPId)}
                  text='Confirm'
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        handleClose={() => setSendRegistrationEmail(false)}
        open={sendRegistrationEmail}
      >
        <Grid
          spacing={3}
          style={{ padding: '50px', overflow: "hidden" }}
          direction='column'
          container
        >
          <Grid item>
            <Typography>Are you sure, you want to send another registration email?</Typography>
          </Grid>
          <Grid item>
            <Grid spacing={2} justify='flex-end' container>
              <Grid item>
                <Button onClick={() => setSendRegistrationEmail(false)}>Cancel</Button>
              </Grid>
              <Grid item>
                <ConfirmBtn
                  onClick={() => sendEmail()}
                  text='Confirm'
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
};

const mapState = (state) => ({
  cancelRSVPResponse: state.cancelRSVP,
});

const mapDispatch = (dispatch) => ({
  cancelRSVP: (id) => dispatch(cancelRSVP(id)),
});
export default connect(mapState, mapDispatch)(RSVP);
