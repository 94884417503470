/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from 'react';
import {
  withStyles,
  Typography,
  Grid,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Dialog,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import querystring from 'query-string';
import dayjs from 'dayjs';
import { getClinicianAction } from '../../../utils/actions/clinician.action';
import { addAppointmentAction } from '../../../utils/actions/appointment.action';
import YourMatchedClinician from './YourMatchedClinician';
import clinician1 from '../../../assets/imgs/clinician1.png';
import DividerLine from '../../../assets/imgs/divider.svg';
import Header from '../../../common/Header';
import SearchClinician from './SearchClinician';

import { Loader } from '../../Loader';
import { dateFormatterHHMMAA } from '../../../utils/validators';
import moment from 'moment';
import styles from './ClinicianStyle';

class Clinicians extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      clinicianId: '',
      isUpdate: false,
      allClinician: [],
      confirmBook: false,
      searchSelect: false,
      nextAvailiblityText: 'tomorrow',
      clinicianLoader: true,
      selectedSlot: '',
      riskScore: 0,
      confBtnLoading: false,
      waitingListDialogOpen: false,
      waitlistConfirmDialog: false,
    };
  }

  componentDidMount() {
    const postAnsDataStr = localStorage.getItem('postAnsDataStr');
    const assessmentNoteStr = localStorage.getItem('assessmentNoteStr');
    const assessmentNote = JSON.parse(assessmentNoteStr);
    const postAnsData = JSON.parse(postAnsDataStr);
    this.setState({
      assessment: { answers: postAnsData, note: assessmentNote },
    });
    const query = new URLSearchParams(this.props.location.search);
    if (query.get('type') && query.get('type') === 'recommended') {
      if (
        this.props.location.state &&
        this.props.location.state.matchedClinicians
      ) {
        this.setState({
          allClinician: this.handleSlots(
            this.props.location.state.matchedClinicians
          ),
          clinicianLoader: false,
          preferredClinician: this.props.location.state.preferredClinician,
          riskScore: this.props.location.state.riskScore,
        });
      } else {
        this.props.history.push('/assessment-que');
      }
    } else {
      this.props.getClinicianAction();
      if (
        this.props.location.pathname ===
        `/user/appointment/reschedule/${this.props.match.params.id}`
      ) {
        this.setState({ isUpdate: true });
      }
    }
  }

  componentDidUpdate(preProps) {
    if (this.props.getAllUser !== preProps.getAllUser) {
      this.setState({
        allClinician: this.handleSlots(this.props.getAllUser),
        clinicianLoader: false,
      });
    }

    if (this.props.addAppointment !== preProps.addAppointment) {
      this.setState({ confBtnLoading: false });
      if (
        this.props.addAppointment.addAppointmentData &&
        this.props.addAppointment.addAppointmentData.status != 'waiting'
      ) {
        this.props.history.push(
          `/verifyinfo/${this.props.addAppointment.addAppointmentData._id}`
        );
      } else {
        localStorage.removeItem('postAnsDataStr');
        localStorage.removeItem('assessmentNoteStr');

        this.setState({ waitlistConfirmDialog: true });
      }
    }
  }

  handleSlots = (data) => {
    let clinicians = [];
    if (data && data) {
      for (let item of data) {
        let nextAvailable = null;
        let schedules = item.schedule;
        if (schedules && schedules.length) {
          schedules = schedules.map((sch) => {
            if (sch.slots.length) {
              sch.slots = sch.slots.map((s) => {
                let startTime = moment(new Date(s.startTime));
                let slot = startTime.hours() + ':' + startTime.minutes();
                let timeArr = slot.split(':');
                let hrs = '00',
                  min = '00';
                if (timeArr.length) {
                  hrs = timeArr[0];
                  min = timeArr[1];
                }
                if (timeArr[0].length === 1) {
                  hrs = `0${timeArr[0]}`;
                }
                if (timeArr[1].length === 1) {
                  min = `0${timeArr[1]}`;
                }
                let time = `${hrs}:${min}`;
                let lclDate = new Date(`${sch.date}T${time}:00`);
                let lclHrs = lclDate.getHours().toString();
                if (lclHrs.length === 1) {
                  lclHrs = `0${lclHrs}`;
                }
                return `${lclHrs}:${
                  lclDate.getMinutes() > 0 ? lclDate.getMinutes() : '00'
                }`;
              });
            }
            return sch;
          });
          // console.log("schedules", schedules);
          for (let sch of schedules) {
            if (sch.slots.length && sch.slots.length > 0) {
              nextAvailable = sch;
              break;
            }
          }
        }

        let tempObj = {
          id: item._id,
          name: `${item.firstName} ${item.lastName}`,
          study: '',
          speciality: item.bio,
          times: schedules,
          nextAvailable,
          img: item.avatar ? item.avatar : clinician1,
          cost: `${item.costPerUnit} Dhs / ${item.appointmentSlotUnit}min`,
          appointmenSlot: item.appointmentSlotUnit,
          amount: item.costPerUnit,
          selectTimeSlot: null,
          isAlternative: false,
          nextAvailiblityText: this.getNextAvailibility(schedules),
        };
        clinicians.push(tempObj);
      }
    }

    clinicians = clinicians.sort((a, b) => {
      return a.nextAvailable < b.nextAvailable ||
        a.times.length < b.times.length
        ? 1
        : -1;
    });

    return clinicians;
  };

  getNextAvailibility = (schedules) => {
    let result = '';
    if (schedules.length) {
      let day = new Date();
      day.setDate(day.getDate() + 1);
      let today = this.getDate(new Date());
      let nextDay = this.getDate(day);
      if (schedules[0].date == today) {
        result = 'Today';
      } else if (schedules[0].date == nextDay) {
        result = 'Tomorrow';
      } else {
        result = schedules[0].date;
      }
    } else {
      result = 'Not Available';
    }
    return result;
  };

  getDate = (date) => {
    var today = new Date(date);
    var d = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var m =
      today.getMonth() + 1 < 10
        ? '0' + (today.getMonth() + 1)
        : today.getMonth() + 1;
    var modifiedDate = today.getFullYear() + '-' + m + '-' + d;
    return modifiedDate;
  };

  handleTimeSelect = (index, value) => {
    const allClinician = [...this.state.allClinician];
    for (let i = 0; i < this.state.allClinician.length; i++) {
      if (i === index) {
        allClinician[index] = {
          ...this.state.allClinician[index],
          status: 'selected',
          slot: value,
          isAlternative: false,
        };
      } else {
        allClinician[i] = {
          ...this.state.allClinician[i],
          status: 'not selected',
          slot: null,
        };
      }
    }
    this.setState({ allClinician, confirmBook: true });
  };

  formatDateYMD = (x, y) => {
    var z = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds(),
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
      return ((v.length > 1 ? '0' : '') + eval('z.' + v.slice(-1))).slice(-2);
    });

    return y.replace(/(y+)/g, function (v) {
      return x.getFullYear().toString().slice(-v.length);
    });
  };

  handleCalendarTimeSelect = (index, value, date) => {
    const allClinician = [...this.state.allClinician];
    for (let i = 0; i < this.state.allClinician.length; i++) {
      if (i === index) {
        allClinician[index] = {
          ...this.state.allClinician[index],
          status: 'selected',
          slot: new Date(`${this.formatDateYMD(date)} ${value}`),
          isAlternative: false,
          nextAvailiblityText: dayjs(date).format('DD/MM/YYYY'),
        };
      } else {
        allClinician[i] = {
          ...this.state.allClinician[i],
          status: 'not selected',
          slot: null,
        };
      }
    }
    this.setState({ allClinician, confirmBook: true });
  };

  handleAppointmentCancel = (index) => {
    const allClinician = [...this.state.allClinician];
    allClinician[index] = {
      ...this.state.allClinician[index],
      status: 'not selected',
      slot: null,
    };
    this.setState({ allClinician });
  };

  handleSearchSelectHandle = (data) => {
    let d = { ...data, justAdded: true };
    let chipData = this.state.allClinician;

    chipData.push(d);
    this.setState({ allClinician: chipData });
  };

  handleSearchRemoveClinician = (index) => {
    const allClinician = [...this.state.allClinician];
    allClinician.splice(index, 1);
    this.setState({ allClinician });
  };

  handleAppointmentConfirm = (index) => {
    this.setState({ confBtnLoading: true });
    setTimeout((e) => {
      const { allClinician } = this.state;
      const data = {
        clinicianId: allClinician[index].id,
        startTime: allClinician[index].slot,
        duration: allClinician[index].appointmenSlot,
        amount: allClinician[index].amount,
        riskScore: this.state.riskScore,
        status: 'unconfirmed',
        preferredClinician: this.state.preferredClinician,
      };
      if (
        localStorage.getItem('appFor') === 'multiple' &&
        localStorage.getItem('multipleAdults')
      ) {
        let multipleAdults = JSON.parse(localStorage.getItem('multipleAdults'));
        let clientIds = multipleAdults.map((item) => {
          return item._id;
        });
        data.clientId = clientIds;
        data.groupType = 'adult';
      } else if (
        localStorage.getItem('appFor') === 'child' &&
        localStorage.getItem('appChildDetails')
      ) {
        let childData = JSON.parse(localStorage.getItem('appChildDetails'));
        data.clientId = [childData._id];
        data.groupType = 'child';
      }

      this.props.addAppointmentAction(localStorage.getItem('clientUid'), data);
    }, 2000);
  };

  addToWaitList = (index) => {
    let clinicianId;
    if (this.state.allClinician[index]) {
      clinicianId = { clinicianId: this.state.allClinician[index].id };
    }
    this.setState({
      waitingListDialogOpen: true,
      ...clinicianId,
    });
  };

  handleWaitList = () => {
    this.setState({
      confBtnLoading: true,
      waitingListDialogOpen: false,
    });

    setTimeout((e) => {
      const data = {
        status: 'waiting',
        riskScore: this.state.riskScore,
        clinicianId: this.state.clinicianId,
        recommendedClinicians: this.state.allClinician.map((c) => c.id),
        preferredClinician: this.state.preferredClinician,
      };

      if (
        localStorage.getItem('appFor') === 'multiple' &&
        localStorage.getItem('multipleAdults')
      ) {
        let multipleAdults = JSON.parse(localStorage.getItem('multipleAdults'));
        let clientIds = multipleAdults.map((item) => {
          return item._id;
        });
        data.clientId = clientIds;
        data.groupType = 'adult';
      } else if (
        localStorage.getItem('appFor') === 'child' &&
        localStorage.getItem('appChildDetails')
      ) {
        let childData = JSON.parse(localStorage.getItem('appChildDetails'));
        data.clientId = [childData._id];
        data.groupType = 'child';
      }

      data.creatorEmail = localStorage.getItem('clientEmail');

      data.assessments = this.state.assessment.answers;
      data.assessmentNote = this.state.assessment.note;
      console.log({ data });
      this.props.addAppointmentAction(localStorage.getItem('clientUid'), data);
    }, 2000);
  };

  handleAlternativeCalendar = (index) => {
    const allClinician = [...this.state.allClinician];
    allClinician[index] = {
      ...this.state.allClinician[index],
      isAlternative: !this.state.allClinician[index].isAlternative,
    };
    this.setState({ allClinician });
  };

  render() {
    const { classes } = this.props;
    const { confBtnLoading, allClinician } = this.state;
    const url = querystring.parse(this.props.location.search);
    console.log(this.state.preferredClinician);
    return (
      <>
        <Header props={this.props} />
        <div className={classes.root} />
        <Grid component='div' container justify='center' alignItems='center'>
          <Grid component='div' item className={classes.container}>
            <Typography className={classes.title}>
              Your Matched Clinicians
            </Typography>
            <img src={DividerLine} className={classes.divider} />

            {true && (
              <YourMatchedClinician
                props={this.props}
                clinicianList={this.state.allClinician}
                selectTimeSlot={this.handleTimeSelect}
                appointmentConfirmation={this.state.confirmBook}
                handleRemove={this.handleSearchRemoveClinician}
                appointmentCancel={this.handleAppointmentCancel}
                onAppointmentConfirm={this.handleAppointmentConfirm}
                onAlternativeCalendar={this.handleAlternativeCalendar}
                onSelectEvent={this.handleCalendarTimeSelect}
                addToWaitList={this.addToWaitList}
                confBtnLoading={confBtnLoading}
              />
            )}
            <div>
              <Typography
                style={{ marginBottom: 10, textAlign: 'left' }}
                variant='subtitle1'
              >
                We apologize, but there is long waiting list. Do you wish to
                continue?
              </Typography>
              <Button
                onClick={this.addToWaitList}
                className={classes.waitingList}
                style={{ color: 'white' }}
              >
                Add to waiting list
              </Button>
            </div>

            <br />
            <br />
            <br />
            <br />

            {false && (
              <SearchClinician
                props={this.props}
                onSelectClinician={this.handleSearchSelectHandle}
                selectedClinicians={allClinician}
              />
            )}
          </Grid>
        </Grid>
        <Dialog
          open={this.state.waitingListDialogOpen}
          onClose={() => {
            this.setState({ waitingListDialogOpen: false });
          }}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            {'Add yourself to the waitlist!'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Someone will reach out to
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  waitingListDialogOpen: false,
                  clinicianId: '',
                });
              }}
              color='primary'
            >
              Close
            </Button>
            <Button onClick={this.handleWaitList} color='primary' autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.waitlistConfirmDialog}
          onClose={() => {
            this.setState({ waitlistConfirmDialog: false });
          }}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            {'Add yourself to the waitlist!'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.props.history.push('/dashboard')}
              color='primary'
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

Clinicians.defaultProps = {
  getAllUser: [],
};

const connected = connect(
  (state) => ({
    getAllUser: state.getClinician.getClinicianData,
    addAppointment: state.addAppointment,
  }),
  {
    getClinicianAction,
    addAppointmentAction,
  }
)(Clinicians);

const formed = reduxForm({
  form: 'getUser',
})(connected);

export default withStyles(styles)(formed);
