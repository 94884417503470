/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import {
  withStyles,
  Typography,
  Grid,
  MenuItem,
  Menu,
  Popover,
} from '@material-ui/core';
import { AccountCircle, ExitToApp, List } from '@material-ui/icons';
import dayjs from 'dayjs';
import logo from '../../assets/imgs/Background.png';
import dropdownContainer from '../../assets/imgs/Path 53.svg';
import alertIcon from '../../assets/imgs/Shape.svg';
import alertIconBadge from '../../assets/imgs/Oval 2.png';
import avatar from '../../assets/imgs/Oval.png';
import dropdownIcon from '../../assets/imgs/Border.svg';
import { googleSignOut } from '../../utils/firebase';
import AlertMsg from '../../utils/Alert';
import { baseURL } from '../../utils/axios';
import style from './Header_style';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: null };
  }

  handleOpen = (e) => {
    this.setState({ isOpen: e.currentTarget });
  };

  handleClose = () => {
    this.setState({ isOpen: null });
  };

  handleLogoutWithGoogle = async () => {
    googleSignOut()
      .then(() => {
        localStorage.removeItem('avatar');
        localStorage.removeItem('name');
        localStorage.removeItem('ACCESS_TOKEN_PATH');
        localStorage.removeItem('createdAt');
        localStorage.removeItem('clientEmail');
        localStorage.removeItem('clientUid');
        localStorage.removeItem('clientId');
        localStorage.removeItem('appFor');
        localStorage.removeItem('childId');
        AlertMsg('success', 'You have logged out');
        this.props.history.push('/login');
      })
      .catch((err) => AlertMsg('error', 'There are some issue.'));
  };

  handleDelete = () => {
    fetch(`${baseURL}/api/client`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        this.handleLogoutWithGoogle();
      })
      .catch((err) => {
        AlertMsg('error', 'There are some issue.');
      });
  };

  render() {
    const { classes } = this.props;
    const open = Boolean(this.state.isOpen);
    const id = open ? 'simple-popover' : undefined;
    return (
      <Grid component='div' className={classes.root}>
        <Grid component='div'>
          <img
            style={{ cursor: 'pointer' }}
            onClick={() => this.props.history.push('/dashboard')}
            className={classes.image}
            src={logo}
          />
          <Grid component='div' className={classes.faqContainer}>
            <Typography className={classes.faq}>
              Frequent Asked Questions
            </Typography>
            <Grid component='div' className={classes.divider} />
            <Grid className={classes.faqDivider} />
            <Typography className={classes.faq}>Call 1.888.888.8888</Typography>
          </Grid>

          {/* DO NOT DELTE ITS COMMENTED OUT FOR LATER */}
          {/* <Grid component="div" className={classes.alertContainer}>
            <img src={alertIcon} className={classes.alert} />
            <img src={alertIconBadge} className={classes.alertBadge} />
          </Grid> */}

          <Grid component='div' className={classes.profileContainer}>
            {/* DO NOT DELTE ITS COMMENTED OUT FOR LATER */}
            {/* <img
              src={dropdownIcon}
              className={classes.dropDownIcon}
              onClick={this.handleOpen}
            /> */}
            <img
              src={this.props.avatar || localStorage.getItem('avatar')}
              className={classes.avatar}
            />
            <Grid component='div' className={classes.nameContainer}>
              <Typography className={classes.nameHeader}>
                {localStorage.getItem('name')}
              </Typography>
              <Typography className={classes.subNameTitle}>
                Member since{' '}
                {dayjs(localStorage.getItem('createdAt')).format('MMM, YYYY')}
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.mobileProfileContainer}>
            <img
              src={localStorage.getItem('avatar')}
              className={classes.avatarMobile}
              onClick={this.handleOpen}
            />
          </Grid>
        </Grid>

        {/* DO NOT DELTE ITS COMMENTED OUT FOR LATER */}
        {/* {this.state.isOpen && ( */}
        {/* <Popover
          id={id}
          open={open}
          anchorEl={this.state.isOpen}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: 330,
          }}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              marginRight: "-500px",

            },
          }}
        >
          <Grid style={{maxHeight:"150px"}}>
            <img src={dropdownContainer} />
            <Grid
              container
              alignItems="center"
              justify="center"
              style={{ position: "absolute", padding: "20px", top: 20 }}
            >
              <Grid className={classes.dropSubContainer}>
                <Typography className={classes.dropTitle}>
                  Personal Information
                </Typography>
                <Typography className={classes.dropDesc}>
                  Email
                  <b>&#183;</b> Password
                  <b>&#183;</b> Address
                  <b>&#183;</b> Phone
                </Typography>
              </Grid>

              <Grid className={classes.dropSubContainer}>
                <Typography className={classes.dropTitle}>Privacy</Typography>
                <Typography className={classes.dropDesc}>
                  Grant access to 3rd parties
                  <b>&#183;</b> Privacy policies
                </Typography>
              </Grid>

              <Grid className={classes.dropSubContainer}>
                <Typography className={classes.dropTitle}>
                  Payment & Benefits
                </Typography>
                <Typography className={classes.dropDesc}>
                  Payment methods
                  <b>&#183;</b> Insurance
                  <b>&#183;</b> Employer
                </Typography>
              </Grid>

              <Grid className={classes.dropSubContainer}>
                <Typography className={classes.dropTitle}>Support</Typography>
                <Typography className={classes.dropDesc}>
                  FAQ
                  <b>&#183;</b> Ticket system
                </Typography>
              </Grid>

              <Grid className={classes.dropSubContainer}>
                <Typography className={classes.dropTitle}>Legal</Typography>
                <Typography className={classes.dropDesc}>
                  Email
                  <b>&#183;</b> Password
                  <b>&#183;</b> Address
                  <b>&#183;</b> Phone
                </Typography>
              </Grid>

              <Grid
                style={{
                  backgroundColor: "#ffffff",
                  border: "1px solid rgba(112,112,112,100%)",
                  marginTop: "20px",
                  width: "282px",
                }}
              />

              <Grid container>
                <Grid
                  style={{
                    marginTop: "20px",
                    marginLeft: "40px",
                    fontSize: "18px",
                    color: "#ffffff",
                    fontWeight: 700,
                    fontFamily: "Lato",
                    textAlign: "left",
                    cursor: "pointer",
                  }}
                  onClick={this.handleLogoutWithGoogle}
                >
                  Sign Out
                </Grid>
                <Grid
                  style={{
                    marginTop: "20px",
                    marginLeft: "80px",
                    fontSize: "18px",
                    color: "#ffffff",
                    fontWeight: 700,
                    fontFamily: "Lato",
                    textAlign: "left",
                    cursor: "pointer",
                  }}
                  onClick={this.handleDelete}
                >
                  Delete
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Popover> */}

        {/* DO NOT DELTE ITS COMMENTED OUT FOR LATER */}
        {/* )} */}
        {/* <Menu
          // id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          // elevation={0}
          getContentAnchorEl={null}
          className={classes.popupContainer}
        >
          <Grid className={classes.drop} />
          <MenuItem style={{ width: "200px" }}>
            <AccountCircle className={classes.popupIcon} />
            <Typography className={classes.popupListText}>Profile</Typography>
          </MenuItem>
          <MenuItem onClick={this.handleLogoutWithGoogle}>
            <ExitToApp className={classes.popupIcon} />
            <Typography className={classes.popupListText}>Logout</Typography>
          </MenuItem>
        </Menu> */}
      </Grid>
    );
  }
}
export default withStyles(style)(Header);
