import moment from 'moment';

/* eslint-disable no-useless-escape */
export const characters = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
export const numbers = /^\d+$/;
export const floatAndDigits = /[+-]?([0-9]*[.])?[0-9]+/;
export const isEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{3,}))$/;

export const getAgeByDob = (dob) => {
  var today = new Date();
  var birthDate = new Date(dob);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const convertUTCToLocal = (time) => {
  let stillUtc = moment.utc(time).toDate();
  return stillUtc.getHours() + ':' + stillUtc.getMinutes();
};

export const dateFormatterHHMM = (input) => moment(input).format('HH:MM');

export const dateFormatterHHMMAA = (input) => {
  return moment(input).format('h:mmA');
};

export const dateFormatterDDMMYY = (input) =>
  moment(input).format('DD:MM:YYYY HH:MM');
export const dateFormatter1 = (input) => moment(input).format('MM-DD-YYYY');
export const convertUTCDateToLocalDate = (date, format) => {
  let local = moment.utc(date).local().format(format);
  return local;
};

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const isValidUrl = (url) => {
  const regEx = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm;
  return regEx.test(url);
};

export const fiveRandomChars = () => Math.random().toString(30).slice(8);

export const generateCompanyCode = () =>
  `${fiveRandomChars()}-${fiveRandomChars()}-${fiveRandomChars()}`;

export const uid = () =>
  `${[...Array(12)]
    .map((i) => (~~(Math.random() * 36)).toString(36))
    .join('')}`;
