import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  Avatar,
  Button,
} from '@material-ui/core';
import { getClientConsentForms } from '../../../utils/actions/client.action';
import {
  downloadConsetForm,
  clearPDF,
} from '../../../utils/actions/consent.action';
import moment from 'moment';
import { saveAs } from 'file-saver';

const PatientConsentForm = ({
  clientId,
  getConsentForms,
  forms,
  downloadConsetForm,
  consentPDF,
  clearPDF,
}) => {
  const [consentForms, setConsentForms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getConsentForms(clientId);
  }, []);

  useEffect(() => {
    if (forms) {
      setConsentForms(forms.data);
    }

    if (!forms.request) {
      setIsLoading(false);
    }
  }, [forms]);

  useEffect(() => {
    if (consentPDF) {
      const pdfBlob = new Blob([consentPDF], { type: 'application/pdf' });
      saveAs(pdfBlob, 'consent-form.pdf');
      clearPDF();
    }
  }, [consentPDF]);
  const formattedUser = (user) => (
    <Grid alignItems='center' spacing={2} container>
      <Grid item>
        <Avatar src={user.avatar} alt={user._id} />
      </Grid>
      <Grid item>{`${user.firstName} ${user.lastName}`}</Grid>
    </Grid>
  );

  const download = (clinicianId) => downloadConsetForm(clinicianId, clientId);

  return (
    <Grid container>
      <TableContainer style={{ maxHeight: 500 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Patient</TableCell>
              <TableCell>Clinician</TableCell>
              <TableCell>Date Signed</TableCell>
              <TableCell align='right'>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody component={Paper}>
            {consentForms &&
              consentForms.length > 0 &&
              consentForms.map((f) => (
                <TableRow key={`f-${f._id}`}>
                  <TableCell>{formattedUser(f.clientSign._id)}</TableCell>
                  <TableCell>{formattedUser(f.clinicianSign._id)}</TableCell>
                  <TableCell>
                    {moment(f.clientSign.signDate).format('LLL')}
                  </TableCell>
                  <TableCell align='right'>
                    <Button onClick={() => download(f.clinicianSign._id._id)}>
                      PDF
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

PatientConsentForm.propTypes = {
  getConsentForms: PropTypes.func.isRequired,
  forms: PropTypes.shape({
    data: PropTypes.array,
    request: PropTypes.bool,
  }),
  clientId: PropTypes.string.isRequired,
  downloadConsetForm: PropTypes.func.isRequired,
  consentPDF: PropTypes.string,
  clearPDF: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  forms: state.getClientConsentForms,
  consentPDF: state.downloadConsetForm.file,
});
const mapDispatchToProps = (dispatch) => ({
  getConsentForms: (clientId) => dispatch(getClientConsentForms(clientId)),
  downloadConsetForm: (clinicianId, clientId) =>
    dispatch(downloadConsetForm(clinicianId, clientId)),
  clearPDF: () => dispatch(clearPDF()),
});
export default connect(mapStateToProps, mapDispatchToProps)(PatientConsentForm);
