import React, { useEffect, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getConsentForms } from '../../utils/actions/consent.action';
import { UnWrappedCell, ActionBtn, Message } from './Consent.style';
import useStyles from '../../clinician/Patients/Patients.styles';
import {
  FormControl,
  Select,
  MenuItem,
  Grid,
  OutlinedInput,
  InputAdornment,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Button
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import userIcon from '../../assets/imgs/download.png';
import moment from 'moment';
import AppointmentDetailsView from '../../clinician/AppointmentDetailsView';
import { clinicianSignOnConsentForm } from '../../utils/actions/appointment.action';
import {
  loggedInUserInfo,
  saveClinicianSignature,
} from '../../utils/actions/clinician.action';
import { CanvasDialog, MultipleProfilesDisplay } from '../../components/atoms';
import AlertMsg from '../../utils/Alert';
import Loader from '../../components/Loader/Loader';
import styled from 'styled-components';

const RED = '#DA1A1A';
const GREEN = '#01BC05';

const SortBtn = styled(Button)`
  padding: 0px;
  text-transform: none;
  width: max-content;
  &:hover {
    background-color: transparent;
  }
`;

const ConsentForms = ({
  getConsentForms,
  consent,
  saveClinicianSignature,
  clinicianSignOnConsentForm,
  signatureSaved,
  updatedSignature,
  signedSuccessfully,
  loggedInUserInfo,
  loggedInUser,
}) => {
  const [searchText, setSearchText] = useState('');
  const classes = useStyles();
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [consentForms, setConsentForms] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [appointmentId, setAppointmentId] = useState(null);
  const [detailDrawer, setDetailDrawer] = useState(false);
  const [openCanvasDialog, setCanvasDialog] = useState(false);
  const [signature, setSignature] = useState('');
  const [sortType, setSortType] = useState('asc');
  const [sortBy, setSortBy] = useState('');

  let signaturePad = createRef();

  useEffect(() => {
    setIsLoading(true);
    loggedInUserInfo();
  }, []);

  useEffect(() => {
    if (consent.data) {
      setIsLoading(false);
      setConsentForms(consent.data);
      console.log('\n\n\n\nconsentForms\n', consent.data);
    }
  }, [consent]);

  useEffect(() => {
    setIsLoading(true);
    const clinicianId = localStorage.getItem('clinicianUID');
    console.log(sortType, sortBy)
    getConsentForms(clinicianId, { searchText, selectedFilter }, sortBy, sortType);
  }, [searchText, selectedFilter, sortBy, sortType]);

  useEffect(() => {
    if (signatureSaved) {
      updateSignature(updatedSignature);
    }
  }, [signatureSaved, updatedSignature]);

  const updateSignature = async (img) => {
    setSignature(await getBase64FromUrl(img));
  };

  useEffect(() => {
    if (signedSuccessfully) {
      // const updatedForms = consentForms.filter(
      //   (cf) => cf.appointmentId[0]._id !== appointmentId
      // );
      // setConsentForms(updatedForms);
      setIsLoading(true);
      const clinicianId = localStorage.getItem('clinicianUID');
      getConsentForms(clinicianId, { searchText, selectedFilter }, sortBy, sortType);
      setCanvasDialog(false);
      setAppointmentId(null);
    }
  }, [signedSuccessfully]);

  useEffect(() => {
    if (loggedInUser) {
      updateSignature(loggedInUser.signature);
    }
  }, [loggedInUser]);

  const setRef = (ref) => {
    if (ref && ref.fromDataURL) {
      ref.fromDataURL(signature);
      signaturePad = ref;
    }

    if (ref && ref.toDataURL) {
      ref.toDataURL();
      signaturePad = ref;
    }

    if (signature && ref && ref.off) {
      ref.off();
      signaturePad = ref;
    }

    if (ref && ref.clear) {
      ref.clear();
      signaturePad = ref;
    }
  };

  const sort = (key) => {
    const toggleType = sortType === 'asc' ? 'desc' : 'asc';
    console.log(toggleType, key)
    setSortType(toggleType);
    setSortBy(key);
  };

  const handleSearchChange = (e) => setSearchText(e.target.value);

  const handleChange = (e) => setSelectedFilter(e.target.value);

  const handleDetailDrawer = (appId) => {
    setAppointmentId(appId);
    setDetailDrawer(true);
  };

  const closeDetailDrawer = () => {
    setAppointmentId(null);
    setDetailDrawer(false);
  };

  const openSignatureCanvas = (appId, clinicianSignDate) => {
    if (!clinicianSignDate && loggedInUser?.role?.role !== 'clinician') {
      AlertMsg(
        'error',
        'Witness can only sign if clinician has signed already!'
      );
      return;
    }
    setAppointmentId(appId);
    setCanvasDialog(true);
  };

  const closeCanvas = () => {
    setAppointmentId(null);
    setCanvasDialog(false);
  };

  const handleSignatureUpdate = (modSignature) => {
    setSignature(modSignature);
    setCanvasDialog(false);
  };

  const clearSignature = () => {
    if (signaturePad) {
      signaturePad.clear();
    }
  };

  const modifyUserSignature = () => {
    if (signaturePad) {
      if (signaturePad.isEmpty()) {
        AlertMsg(
          'error',
          'If you plan to add signature later then click on dark space!'
        );
      } else {
        const base64Img = signaturePad.toDataURL();
        setSignature(base64Img);

        const formData = new FormData();
        formData.append('signature', dataURLtoBlob(base64Img));
        saveClinicianSignature(formData);
      }
    }
  };

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const dataURLtoBlob = (dataurl) => {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const signConsentForm = () => clinicianSignOnConsentForm(appointmentId);

  const renderSessionTime = (app) =>
    `${moment(app.startTime).format('LT')} - ${moment(app.startTime)
      .add(app.duration, 'minutes')
      .format('LT')}`;

  const checkBasicInfo = (c) =>
    c.phoneNumber &&
    c.phoneType &&
    c.maritalStatus &&
    c.gender &&
    c.address &&
    c.address?.address1 &&
    c.address?.country &&
    c.address?.city;

  const alreadySignedByLoggedInUser = (d) =>
    loggedInUser?.role?.role === 'clinician'
      ? d.clinicianSign.signDate
      : d.witnessSign;

  return (
    <Grid>
      <Grid xs={6} item>
        <OutlinedInput
          value={searchText}
          className={classes.inputstyle}
          style={{ float: 'left' }}
          placeholder='Search'
          onChange={handleSearchChange}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        <FormControl
          variant='outlined'
          className={classes.formControl}
          style={{
            width: '200px',
          }}
        >
          <Select
            className={`${classes.inputstyle} ${classes.selectEmpty}`}
            defaultValue={selectedFilter}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value='all'>All</MenuItem>
            <MenuItem value='unsigned'>Unsigned</MenuItem>
            <MenuItem value='signed'>Signed</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid container>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <UnWrappedCell><SortBtn onClick={() => sort('chartId')}>
                  Chart Number
                </SortBtn></UnWrappedCell>

                <UnWrappedCell><SortBtn onClick={() => sort('name')}>Patient Name</SortBtn></UnWrappedCell>
                <UnWrappedCell>Patient Signed</UnWrappedCell>
                <UnWrappedCell>Clinician Signed</UnWrappedCell>

                <UnWrappedCell><SortBtn onClick={() => sort('date')}>Date</SortBtn></UnWrappedCell>

                <UnWrappedCell>Prerequities</UnWrappedCell>

                <UnWrappedCell align='right'>Action</UnWrappedCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading &&
                consentForms.length > 0 &&
                consentForms.map((cf) => (
                  <TableRow key={`cf-${cf._id}`}>
                    <TableCell>
                      {cf.clientSign._id[0]?.chartId ||
                        cf.clients[cf.clients.length - 1].chartId}
                    </TableCell>

                    <TableCell>
                      {<MultipleProfilesDisplay profiles={cf.clients} />}
                    </TableCell>
                    <TableCell>
                      {cf.clientSign?.signDate ? 'Yes' : 'No'}
                    </TableCell>
                    <TableCell>
                      {cf.clinicianSign?.signDate ? 'Yes' : 'No'}
                    </TableCell>
                    <TableCell>
                      <Grid direction='column' container>
                        <Grid item>
                          {moment(cf.appointmentId[0].startTime).format('L')}
                        </Grid>
                        <Grid item>
                          {renderSessionTime(cf.appointmentId[0])}
                        </Grid>
                      </Grid>
                    </TableCell>

                    <TableCell>
                      <Grid spacing={1} container>
                        <Grid item>
                          <Tooltip placement='top' title='Consent Form'>
                            <ActionBtn
                              color={
                                loggedInUser && alreadySignedByLoggedInUser(cf)
                                  ? GREEN
                                  : RED
                              }
                              onClick={() => { }}
                            >
                              <i className='fas fa-file-contract'></i>
                            </ActionBtn>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Tooltip placement='top' title='Proof Of Identity'>
                            <ActionBtn
                              color={cf.clientSign.hasPOI ? GREEN : RED}
                              onClick={() => { }}
                            >
                              <i className='fas fa-id-card'></i>
                            </ActionBtn>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Tooltip placement='top' title='Patient Info'>
                            <ActionBtn
                              color={
                                checkBasicInfo(
                                  cf.clients[cf.clients.length - 1]
                                )
                                  ? GREEN
                                  : RED
                              }
                              onClick={() => { }}
                            >
                              <i className='fas fa-user'></i>
                            </ActionBtn>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </TableCell>

                    <TableCell align='right'>
                      <Grid spacing={3} justify='flex-end' container>
                        {!alreadySignedByLoggedInUser(cf) && (
                          <Grid item>
                            <Tooltip
                              placement='top'
                              title='Sign this consent form'
                            >
                              <ActionBtn
                                onClick={() =>
                                  openSignatureCanvas(
                                    cf.appointmentId[0]._id,
                                    cf.clinicianSign?.signDate
                                  )
                                }
                              >
                                <i className='fas fa-signature'></i>
                              </ActionBtn>
                            </Tooltip>
                          </Grid>
                        )}
                        <Grid item>
                          <ActionBtn
                            onClick={() =>
                              handleDetailDrawer(cf.appointmentId[0]._id)
                            }
                          >
                            <i className='fas fa-eye'></i>
                          </ActionBtn>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Message justify='center' container>
        {isLoading && <Loader />}
        {!isLoading &&
          consentForms.length === 0 &&
          `There are no ${selectedFilter} consent forms.`}
      </Message>

      {appointmentId && (
        <AppointmentDetailsView
          appointmentId={appointmentId}
          handleClose={closeDetailDrawer}
          open={detailDrawer}
          hideActions
        />
      )}
      <CanvasDialog
        open={openCanvasDialog}
        onClose={closeCanvas}
        ref={signaturePad}
        signature={signature}
        setRef={setRef}
        clear={clearSignature}
        onSignatureUpdate={handleSignatureUpdate}
        modifyUserSignature={modifyUserSignature}
        signConsentForm={signConsentForm}
      />
    </Grid>
  );
};

ConsentForms.propTypes = {
  getConsentForms: PropTypes.func.isRequired,
  saveClinicianSignature: PropTypes.func.isRequired,
  clinicianSignOnConsentForm: PropTypes.func.isRequired,
  consent: PropTypes.shape({
    data: PropTypes.array,
  }),
  signatureSaved: PropTypes.bool,
  signedSuccessfully: PropTypes.bool,
  updatedSignature: PropTypes.func,
  loggedInUserInfo: PropTypes.func,
  loggedInUser: PropTypes.shape({
    signature: PropTypes.string,
    role: PropTypes.shape({
      role: PropTypes.string,
    }),
  }),
};

const mapStateToProps = (state) => ({
  consent: state.getConsentForms,
  signatureSaved: state.saveClinicianSignature.signatureSaved,
  updatedSignature: state.saveClinicianSignature.updatedSignature,
  signedSuccessfully: state.clinicianSign.success,
  loggedInUser: state.loggedInUserInfo.user,
});

const mapDispatchToProps = (dispatch) => ({
  getConsentForms: (clinicianId, filters, sortBy, sortType) =>
    dispatch(getConsentForms(clinicianId, filters, sortBy, sortType)),
  saveClinicianSignature: (signature) =>
    dispatch(saveClinicianSignature(signature)),
  clinicianSignOnConsentForm: (appId) =>
    dispatch(clinicianSignOnConsentForm(appId)),
  loggedInUserInfo: () => dispatch(loggedInUserInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsentForms);
