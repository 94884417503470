import {
  SEND_SMS_REQUEST,
  SEND_SMS_SUCCESS,
  SEND_SMS_FAILED,
} from "../constant";
import { put, takeEvery } from "redux-saga/effects";
import { post } from "../axios";

function* sendSMS(action) {
  const { endpoint, body } = action;
  try {
    const response = yield post(endpoint, body);
    yield put({ type: SEND_SMS_SUCCESS, response });
  } catch (e) {
    yield put({ type: SEND_SMS_FAILED, e });
  }
}

export function* smsSaga() {
  yield takeEvery(SEND_SMS_REQUEST, sendSMS);
}
