import React, { useEffect } from 'react';
import clsx from 'clsx';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DatePicker from 'react-datepicker';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FilterImage from '../../assets/clinician_image//Group 351@2x.png';
import NativeSelect from '@material-ui/core/NativeSelect';
import Axios from 'axios';
import { baseURL } from '../../utils/axios';
import closeImg from '../../assets/imgs/close.jpeg';
import '../clinician.scss';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './FilterDrawer.styles';

export default function FilterDrawer({
  filterDrawer,
  toggleDrawer,
  handleSubmitRequest,
  applyFilters,
}) {
  const classes = useStyles();
  const [openModal, setOpenModal] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const [status, setStatus] = React.useState('');
  const [archivalRange, setArchivalRange] = React.useState('');
  const [clientIds, setClientIds] = React.useState([]);
  const [startTime, setStartTime] = React.useState('2017-01-01');
  const [endTime, setEndTime] = React.useState('2020-01-01');
  const [clientAgeGroup, setClientAgeGroup] = React.useState('');
  const [clientStatus, setClientStatus] = React.useState('');
  const [paymentStatus, setPaymentStatus] = React.useState('');
  const [appointmentType, setAppointmentType] = React.useState('');
  const [filterName, setFilterName] = React.useState('');
  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const [clientList, setClientList] = React.useState([]);
  const [clientValue, setClientValue] = React.useState('');

  useEffect(() => {
    getClient(clientValue);
  }, []);

  const getClient = (value) => {
    Axios.get(`${baseURL}/api/appointment/searchUser?q=${value}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        setClientList(res.data);
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const handleSubmit = () => {
    const params = {
      type: 'patient',
      status: status == 'upcoming' ? status : '',

      // archivalRange: status == "archived" ? archivalRange : '',
      clientIds: clientIds,
      // startTime: status == "date" ? startTime : '',
      // endTime: status == "date" ? endTime : '',

      clientAgeGroup: clientAgeGroup,
      clientStatus: clientStatus,
      paymentStatus: paymentStatus,
      filterName: filterName,
    };
    handleSubmitRequest(params);
  };

  const handleApplyFilters = () => {
    const params = {
      type: 'patient',
      clientAgeGroup: clientAgeGroup,
      clientStatus: clientStatus,
    };

    setClientAgeGroup('');
    setClientStatus('');
    applyFilters(params);
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role='presentation'
      style={{ width: '480px' }}
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <Grid container style={{ padding: 25 }}>
        <Grid container className={classes.topHeader}>
          <Grid xs={6}>
            {/* <Button variant="contained"  className={classes.btnNewApp} >
              <HorizontalSplitIcon />
            </Button> */}
            <img src={FilterImage} width='50' />
            <span className={classes.textColor}>Filters</span>
          </Grid>
          <Grid xs={6}>
            <div
              style={{ float: 'right', color: '#003265', cursor: 'pointer' }}
              onClick={toggleDrawer(false)}
            >
              <span style={{ float: 'left', marginRight: '5px' }}>Close</span>
              <img src={closeImg} />
            </div>
          </Grid>
        </Grid>

        <form style={{ width: '100%' }}>
          {/* <FormControl style={{width: "100%", marginBottom: 10}}>
              <label className={classes.labelText}>Name</label>
              <Autocomplete
                id="combo-box-demo"
                options={clientList}
                onChange={(event, newValue) => {
                  setClientIds([newValue._id]);
                  setBtnDisabled(false);
                }}
                onInputChange={(event, newInputValue) => {
                  getClient(newInputValue);
                }}
                getOptionLabel={(option) => option.firstName}
                style={{ width: "100%" }}
                renderInput={(params) => <TextField {...params} label="Search client name" variant="outlined" />}
              />
                {/* <OutlinedInput
                    className={classes.inputstyle}
                    placeholder="Search Client Name"
                    id="outlined-adornment-password"
                    label="Name"
                    style={{height: '52px', border: "1px solid #e0f1ff", borderRadius: "12px"}}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        >
                        <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                    }
                />
            </FormControl> */}

          {/* <label className={classes.labelText}>Dates</label><br/> */}
          {/* <FormControl component="fieldset" style={{marginTop: '-5px'}}>
              <RadioGroup aria-label="gender" onChange={(e)=>{setStatus(e.target.value);setBtnDisabled(false);}}> */}
          {/* <FormControlLabel value="archived" control={<Radio />} label="Archived" className={classes.labelText} /> */}
          {/* </RadioGroup>
            </FormControl> */}
          {/* <FormControl variant="outlined" className={classes.formControl + " customt-select"}>

            <NativeSelect
              value={archivalRange}
              className={classes.selectEmpty}
              onChange={(e)=> {setArchivalRange(e.target.value);setBtnDisabled(false);}}
              inputProps={{
                name: 'archivalRange',
                id: 'name-native-disabled',
              }}
            >
              <option value="">Select</option>
              <option value={"all"}>Archived - All</option>
              <option value={"1w"}>Archived - 1 week</option>
              <option value={"2w"}>Archived - 2 weeks</option>
              <option value={"3m"}>Archived - 3 months</option>
              <option value={"100m"}>Archived - Older than 3 months</option>
            </NativeSelect>
          </FormControl> */}
          {/* <FormControl component="fieldset" className="upcoming-radio" style={{marginBottom: '-8px', marginTop: '-5px'}}>
              <RadioGroup aria-label="upcoming" onChange={(e)=> setArchivalRange(e.target.value)}> */}
          {/* <FormControlLabel value="upcoming" control={<Radio />} label="Upcoming" className={classes.labelText} /> */}
          {/* </RadioGroup>
            </FormControl> */}
          {/* <br/> */}
          {/* <FormControl component="fieldset" style={{marginBottom: 5}}>
              <RadioGroup aria-label="date"> */}
          {/* <FormControlLabel value="date" control={<Radio />} label="Date Range" className={classes.labelText} /> */}
          {/* </RadioGroup>
            </FormControl> */}
          {/* <Grid container> */}
          {/* <Grid xs={5}>
                <TextField
                  id="date"
                  className="date-style-custom"
                  // label="Birthday"
                  type="date"
                  name="startTime"
                  value={startTime}
                  onChange={(e)=> setStartTime(e.target.value)}
                  defaultValue="2017-05-24"
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid> */}
          {/* <Grid xs={2}> */}
          {/* <div>-</div> */}
          {/* </Grid> */}
          {/* <Grid xs={5} style={{marginLeft: '-15px'}}>
                <TextField
                  id="date"
                  // label="Birthday"
                  type="date"
                  className="date-style-custom"
                  name="endTime"
                  value={endTime}
                  onChange={(e)=> setEndTime(e.target.value)}
                  defaultValue="2017-05-24"
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid> */}
          {/* </Grid> */}
          <div>
            {/* <InputLabel>Start Date</InputLabel>
            <Form.Field
              control={DatePicker} className='input-select input-space'

              dateFormat='dd/MM/yyyy' placeholderText='Date' name='start_date' autoComplete="off"
              onChange={(date) => handleChangeFourthDate(date, 'academic_details', 'education', 'start_date', i)}
             /> */}

            <label className={classes.labelText}>Client Status</label>
            <br />
            <FormControl
              variant='outlined'
              className={classes.formControl + ' customt-select'}
            >
              <NativeSelect
                displayEmpty
                label={'Select'}
                placeholder='Select'
                value={clientStatus}
                onChange={(e) => {
                  setClientStatus(e.target.value);
                  setBtnDisabled(false);
                }}
                className={classes.selectEmpty}
                inputProps={{
                  name: 'clientAgeGroup',
                  id: 'name-native-disabled',
                }}
              >
                <option value=''>Select</option>
                <option value='new'>New</option>
                <option value='current'>Current</option>
                <option value='old'>Old</option>
              </NativeSelect>
            </FormControl>

            <label className={classes.labelText}>Client Age Group</label>
            <br />

            <FormControl
              variant='outlined'
              className={classes.formControl + ' customt-select'}
            >
              <NativeSelect
                displayEmpty
                label={'Select'}
                placeholder='Select'
                value={clientAgeGroup}
                onChange={(e) => {
                  setClientAgeGroup(e.target.value);
                  setBtnDisabled(false);
                }}
                className={classes.selectEmpty}
                inputProps={{
                  name: 'clientAgeGroup',
                  id: 'name-native-disabled',
                }}
              >
                <option value=''>Select</option>
                <option value='adult'>Adult</option>
                <option value='child'>Child</option>
              </NativeSelect>
            </FormControl>

            {/* <label className={classes.labelText}>Appointment Type</label><br/>

          <FormControl variant="outlined" className={classes.formControl + " customt-select"}>
            <NativeSelect
              displayEmpty
              label={"Select"}
              placeholder="Select"
              value={appointmentType}
              onChange={(e)=> {setAppointmentType(e.target.value);setBtnDisabled(false);}}
              className={classes.selectEmpty}
              inputProps={{
                name: 'appoinmentType',
                id: 'name-native-disabled',
              }}
            >
              <option value="">Select</option>
              <option value="direct">Direct</option>
              <option value="referred">Referred</option>
            </NativeSelect>
          </FormControl> */}

            {/* <label className={classes.labelText}>Treatment Type</label><br/>

          <FormControl variant="outlined" className={classes.formControl + " customt-select"}>
            <Select
              displayEmpty
              label={"Select"}
              placeholder="Select"
              // className={classes.selectEmpty}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
              </MenuItem>
              <MenuItem value={""}>Select</MenuItem>
            </Select>
          </FormControl> */}

            {/* <label className={classes.labelText}>Current Risk Level</label><br/>

          <FormControl variant="outlined" className={classes.formControl + " customt-select"}>
            <Select
              displayEmpty
              label={"Select"}
              placeholder="Select"
              // className={classes.selectEmpty}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
              </MenuItem>
              <MenuItem value={""}>Select</MenuItem>
            </Select>
          </FormControl> */}

            {/* <label className={classes.labelText}>Diagnosis</label><br/>

          <FormControl variant="outlined" className={classes.formControl + " customt-select"}>
            <Select
              displayEmpty
              label={"Select"}
              placeholder="Select"
              // className={classes.selectEmpty}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
              </MenuItem>
              <MenuItem value={""}>Select</MenuItem>
            </Select>
          </FormControl> */}
          </div>
        </form>
        <Button
          variant='contained'
          disabled={btnDisabled}
          className={classes.btnSaveFilter}
          onClick={() => setOpenModal(true)}
        >
          Save Filter
        </Button>
        <Button
          variant='contained'
          disabled={btnDisabled}
          className={classes.btnSaveFilter}
          onClick={handleApplyFilters}
        >
          Apply Filter
        </Button>
      </Grid>
    </div>
  );

  return (
    <div>
      <>
        <SwipeableDrawer
          anchor={'right'}
          open={filterDrawer}
          onClose={toggleDrawer(false)}
        >
          {list('right')}
        </SwipeableDrawer>
      </>
      {/* {
        openModal
      } */}
      <div>
        <Dialog
          maxWidth={'xs'}
          fullWidth={'md'}
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby='form-dialog-title'
          style={{ borderRadius: '1px' }}
        >
          <DialogTitle
            id='form-dialog-title'
            style={{ textAlign: 'center', color: '#003265' }}
          >
            Save Filter
          </DialogTitle>
          <DialogContent dividers style={{ border: 'none' }}>
            {/* <TextField
                        value={message}
                        margin="dense"
                        id="outlined-basic"
                        label="Filter Name"
                        placeholder='Filter Name'
                        type="text"
                        fullWidth
                        variant="outlined"
                        rows={4}
                        // style={{height: "30px"}}
                        onChange={evt => setMessage(evt.target.value)}
                    /> */}
            <OutlinedInput
              className={classes.inputstyle}
              placeholder='Filter Name'
              label='Filter Name'
              style={{ width: '100%' }}
              id='outlined-adornment-password'
              onChange={(e) => setFilterName(e.target.value)}
              labelWidth={70}
            />
          </DialogContent>
          <DialogActions className='justify-content-center mb-5'>
            <Grid container justify='space-between' style={{ padding: 11 }}>
              <Grid xs={5}>
                <Button
                  variant={'contained'}
                  size={'medium'}
                  onClick={() => setOpenModal(false)}
                  color='secondary'
                  style={{ width: '100%', padding: 10 }}
                  className={`${classes.btnNewApp} ml-5`}
                  ref={(element) => {
                    if (element)
                      element.style.setProperty(
                        'margin-left',
                        '5px',
                        'important'
                      );
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid xs={5}>
                <Button
                  variant='contained'
                  size='medium'
                  onClick={() => {
                    setOpenModal(false);
                    handleSubmit();
                  }}
                  style={{ width: '100%', padding: 10 }}
                  className={classes.btnNewApp}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
