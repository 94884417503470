import { createStyles } from '@material-ui/core';

export default createStyles({
  imageContainer: {
    height: '375px',
    width: '100%',
    backgroundColor: 'rgba(34,34,34)',
    overflow: 'hidden',
    position: 'relative',
  },
  image: {
    opacity: '60%',
    width: '100%',
    objectFit: 'cover',
  },
  imageUpperContainer: {
    zIndex: 1,
    position: 'absolute',
    bottom: 0,
    height: '437px',
    width: '100%',
  },
  playContainer: {
    position: 'absolute',
    bottom: '47px',
    right: 24,
    backgroundColor: 'rgba(189,174,142)',
    height: '85px',
    width: '160px',
  },
  imageTextContainer: {
    position: 'absolute',
    left: '57px',
    top: '340px',
  },
  imageTitleText: {
    fontSize: '18px',
    color: '#ffffff',
    fontWeight: 700,
    fontFamily: 'Lato',
  },
  imageDescText: {
    fontSize: '22px',
    color: '#ffffff',
    fontWeight: 700,
    fontFamily: 'Lato',
  },
  overviewText: {
    fontSize: '18px',
    lineHeight: '20px',
    color: '#304659',
    fontWeight: 700,
    fontFamily: 'Lato',
    marginBottom: '20px',
  },
  overviewDescText: {
    fontSize: '16px',
    lineHeight: '18px',
    color: '#304659',
    fontWeight: 400,
    fontFamily: 'Lato',
  },
  drName: {
    fontSize: '18px',
    lineHeight: '22px',
    color: '#304659',
    fontWeight: 700,
    fontFamily: 'Lato',
  },
  drDesc: {
    fontSize: '16px',
    lineHeight: '18px',
    color: '#304659',
    fontWeight: 400,
    fontFamily: 'Lato',
  },
  drImageContainer: {
    width: '100px',
    height: '100px',
    borderRadius: '50px',
    border: '2px solid #ffffff',
  },
  participatingText: {
    fontSize: '16px',
    lineHeight: '20px',
    color: '#304659',
    fontWeight: 700,
    fontFamily: 'Lato',
    marginBottom: '5px',
  },
  buttonContainer: {
    width: '222px',
    height: '35px',
    borderRadius: '5px',
    backgroundColor: '#304659',
    fontSize: '18px',
    color: '#ffffff',
    fontWeight: '400',
    fontFamily: 'lato',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      opacity: '70%',
    },
  },
  textTitle: {
    fontSize: '16px',
    lineHeight: '20px',
    color: '#304659',
    fontWeight: 700,
    fontFamily: 'Lato',
  },
  textDesc: {
    fontSize: '16px',
    lineHeight: '20px',
    color: '#304659',
    fontWeight: 400,
    fontFamily: 'Lato',
  },
});
