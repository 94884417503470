export const ADD_ROLE_REQUEST = "ADD_ROLE_REQUEST";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_FAILED = "ADD_ROLE_FAILED";

export const GET_ROLE_REQUEST = "GET_ROLE_REQUEST";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const GET_ROLE_FAILED = "GET_ROLE_FAILED";

export const GET_ROLE_BY_ID_REQUEST = "GET_ROLE_BY_ID_REQUEST";
export const GET_ROLE_BY_ID_SUCCESS = "GET_ROLE_BY_ID_SUCCESS";
export const GET_ROLE_BY_ID_FAILED = "GET_ROLE_BY_ID_FAILED";

export const UPDATE_ROLE_REQUEST = "UPDATE_ROLE_REQUEST";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAILED = "UPDATE_ROLE_FAILED";

export const DELETE_ROLE_REQUEST = "DELETE_ROLE_REQUEST";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAILED = "DELETE_ROLE_FAILED";

export const ADD_PERMISSION_REQUEST = "ADD_PERMISSION_REQUEST";
export const ADD_PERMISSION_SUCCESS = "ADD_PERMISSION_SUCCESS";
export const ADD_PERMISSION_FAILED = "ADD_PERMISSION_FAILED";

export const GET_PERMISSION_REQUEST = "GET_PERMISSION_REQUEST";
export const GET_PERMISSION_SUCCESS = "GET_PERMISSION_SUCCESS";
export const GET_PERMISSION_FAILED = "GET_PERMISSION_FAILED";

export const GET_PERMISSION_BY_ROLE_REQUEST = "GET_PERMISSION_BY_ROLE_REQUEST";
export const GET_PERMISSION_BY_ROLE_SUCCESS = "GET_PERMISSION_BY_ROLE_SUCCESS";
export const GET_PERMISSION_BY_ROLE_FAILED = "GET_PERMISSION_BY_ROLE_FAILED";

export const UPDATE_PERMISSION_BY_ROLE_REQUEST =
  "UPDATE_PERMISSION_BY_ROLE_REQUEST";
export const UPDATE_PERMISSION_BY_ROLE_SUCCESS =
  "UPDATE_PERMISSION_BY_ROLE_SUCCESS";
export const UPDATE_PERMISSION_BY_ROLE_FAILED =
  "UPDATE_PERMISSION_BY_ROLE_FAILED";

export const GET_PERMISSION_BY_ID_REQUEST = "GET_PERMISSION_BY_ID_REQUEST";
export const GET_PERMISSION_BY_ID_SUCCESS = "GET_PERMISSION_BY_ID_SUCCESS";
export const GET_PERMISSION_BY_ID_FAILED = "GET_PERMISSION_BY_ID_FAILED";

export const UPDATE_PERMISSION_REQUEST = "UPDATE_PERMISSION_REQUEST";
export const UPDATE_PERMISSION_SUCCESS = "UPDATE_PERMISSION_SUCCESS";
export const UPDATE_PERMISSION_FAILED = "UPDATE_PERMISSION_FAILED";

export const DELETE_PERMISSION_REQUEST = "DELETE_PERMISSION_REQUEST";
export const DELETE_PERMISSION_SUCCESS = "DELETE_PERMISSION_SUCCESS";
export const DELETE_PERMISSION_FAILED = "DELETE_PERMISSION_FAILED";

export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILED = "ADD_USER_FAILED";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILED = "GET_USER_FAILED";

export const GET_USER_BY_ID_REQUEST = "GET_USER_BY_ID_REQUEST";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_FAILED = "GET_USER_BY_ID_FAILED";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

export const ADD_RCADS_REQUEST = "ADD_RCADS_REQUEST";
export const ADD_RCADS_SUCCESS = "ADD_RCADS_SUCCESS";
export const ADD_RCADS_FAILED = "ADD_RCADS_FAILED";

export const GET_RCADS_REQUEST = "GET_RCADS_REQUEST";
export const GET_RCADS_SUCCESS = "GET_RCADS_SUCCESS";
export const GET_RCADS_FAILED = "GET_RCADS_FAILED";

export const GET_RCADS_BY_ID_REQUEST = "GET_RCADS_BY_ID_REQUEST";
export const GET_RCADS_BY_ID_SUCCESS = "GET_RCADS_BY_ID_SUCCESS";
export const GET_RCADS_BY_ID_FAILED = "GET_RCADS_BY_ID_FAILED";

export const UPDATE_RCADS_REQUEST = "UPDATE_RCADS_REQUEST";
export const UPDATE_RCADS_SUCCESS = "UPDATE_RCADS_SUCCESS";
export const UPDATE_RCADS_FAILED = "UPDATE_RCADS_FAILED";

export const DELETE_RCADS_REQUEST = "DELETE_RCADS_REQUEST";
export const DELETE_RCADS_SUCCESS = "DELETE_RCADS_SUCCESS";
export const DELETE_RCADS_FAILED = "DELETE_RCADS_FAILED";

export const ADD_ONBOARDING_REQUEST = "ADD_ONBOARDING_REQUEST";
export const ADD_ONBOARDING_SUCCESS = "ADD_ONBOARDING_SUCCESS";
export const ADD_ONBOARDING_FAILED = "ADD_ONBOARDING_FAILED";

export const GET_ONBOARDING_REQUEST = "GET_ONBOARDING_REQUEST";
export const GET_ONBOARDING_SUCCESS = "GET_ONBOARDING_SUCCESS";
export const GET_ONBOARDING_FAILED = "GET_ONBOARDING_FAILED";

export const GET_BY_ID_ONBOARDING_REQUEST = "GET_BY_ID_ONBOARDING_REQUEST";
export const GET_BY_ID_ONBOARDING_SUCCESS = "GET_BY_ID_ONBOARDING_SUCCESS";
export const GET_BY_ID_ONBOARDING_FAILED = "GET_BY_ID_ONBOARDING_FAILED";

export const UPDATE_ONBOARDING_REQUEST = "UPDATE_ONBOARDING_REQUEST";
export const UPDATE_ONBOARDING_SUCCESS = "UPDATE_ONBOARDING_SUCCESS";
export const UPDATE_ONBOARDING_FAILED = "UPDATE_ONBOARDING_FAILED";

export const DELETE_ONBOARDING_REQUEST = "DELETE_ONBOARDING_REQUEST";
export const DELETE_ONBOARDING_SUCCESS = "DELETE_ONBOARDING_SUCCESS";
export const DELETE_ONBOARDING_FAILED = "DELETE_ONBOARDING_FAILED";

export const ADD_MHA_REQUEST = "ADD_MHA_REQUEST";
export const ADD_MHA_SUCCESS = "ADD_MHA_SUCCESS";
export const ADD_MHA_FAILED = "ADD_MHA_FAILED";

export const GET_MHA_REQUEST = "GET_MHA_REQUEST";
export const GET_MHA_SUCCESS = "GET_MHA_SUCCESS";
export const GET_MHA_FAILED = "GET_MHA_FAILED";

export const GET_BY_ID_MHA_REQUEST = "GET_BY_ID_MHA_REQUEST";
export const GET_BY_ID_MHA_SUCCESS = "GET_BY_ID_MHA_SUCCESS";
export const GET_BY_ID_MHA_FAILED = "GET_BY_ID_MHA_FAILED";

export const UPDATE_MHA_REQUEST = "UPDATE_MHA_REQUEST";
export const UPDATE_MHA_SUCCESS = "UPDATE_MHA_SUCCESS";
export const UPDATE_MHA_FAILED = "UPDATE_MHA_FAILED";

export const ADD_P1OF2_REQUEST = "ADD_P1OF2_REQUEST";
export const ADD_P1OF2_SUCCESS = "ADD_P1OF2_SUCCESS";
export const ADD_P1OF2_FAILED = "ADD_P1OF2_FAILED";

export const GET_P1OF2_REQUEST = "GET_P1OF2_REQUEST";
export const GET_P1OF2_SUCCESS = "GET_P1OF2_SUCCESS";
export const GET_P1OF2_FAILED = "GET_P1OF2_FAILED";

export const GET_BY_ID_P1OF2_REQUEST = "GET_BY_ID_P1OF2_REQUEST";
export const GET_BY_ID_P1OF2_SUCCESS = "GET_BY_ID_P1OF2_SUCCESS";
export const GET_BY_ID_P1OF2_FAILED = "GET_BY_ID_P1OF2_FAILED";

export const UPDATE_P1OF2_REQUEST = "UPDATE_P1OF2_REQUEST";
export const UPDATE_P1OF2_SUCCESS = "UPDATE_P1OF2_SUCCESS";
export const UPDATE_P1OF2_FAILED = "UPDATE_P1OF2_FAILED";
