/* eslint-disable no-undef */

import { put, takeEvery } from 'redux-saga/effects';
import { get, updatePatch } from '../axios';
import {
  GET_ALL_BILLING_DATA_SUCCESS,
  GET_ALL_INVOICE_BY_CLIENT_ID_SUCCESS,
  GET_ALL_INVOICE_BY_CLIENT_ID_FAILED,
  GET_SINGLE_INVOICE_SUCCESS,
  GET_SINGLE_INVOICE_FAILED,
  GET_ALL_BILLING_DATA_REQUEST,
  GET_SINGLE_INVOICE_REQUEST,
  GET_ALL_INVOICE_BY_CLIENT_ID_REQUEST,
  GET_ALL_BILLING_DATA_FAILED,
  GET_ALL_BILLING_FOR_ADMIN_REQUEST,
  GET_ALL_BILLING_FOR_ADMIN_SUCCESS,
  GET_ALL_BILLING_FOR_ADMIN_FAILED,
} from '../constant';

function* getAllBilling(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_ALL_BILLING_DATA_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_ALL_BILLING_DATA_FAILED, error });
  }
}

function* getAllInvoice(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_ALL_INVOICE_BY_CLIENT_ID_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_ALL_INVOICE_BY_CLIENT_ID_FAILED, error });
  }
}

function* getSingleInvoice(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_SINGLE_INVOICE_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_SINGLE_INVOICE_FAILED, error });
  }
}

function* getBillingsForAdmin(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_ALL_BILLING_FOR_ADMIN_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_ALL_BILLING_FOR_ADMIN_FAILED, error });
  }
}

export function* billingSaga() {
  yield takeEvery(GET_ALL_BILLING_DATA_REQUEST, getAllBilling);
  yield takeEvery(GET_ALL_INVOICE_BY_CLIENT_ID_REQUEST, getAllInvoice);
  yield takeEvery(GET_SINGLE_INVOICE_REQUEST, getSingleInvoice);
  yield takeEvery(GET_ALL_BILLING_FOR_ADMIN_REQUEST, getBillingsForAdmin);
}
