/* eslint-disable no-sequences */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import {
  withStyles,
  Grid,
  Paper,
  Typography,
  Divider,
  Button,

  ListItem,
  Menu,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import PhoneDisabledOutlinedIcon from '@material-ui/icons/PhoneDisabledOutlined';
import MobileOffIcon from '@material-ui/icons/MobileOff';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getClientAction,
  deleteClientAction,
} from '../../utils/actions/client.action';
import AlertMsg from '../../utils/Alert';
import Loader from '../../components/Loader/Loader';
import styles from './TableStyle';

class ClientTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      id: '',
      clientData: [],
      anchorEl: null,
      isLoaded: true,
    };
  }

  componentDidMount() {
    this.props.getClientAction();
  }

  componentDidUpdate(preProps) {
    const { getClientData, deleteClientData } = this.props;
    if (getClientData !== preProps.getClientData) {
      if (getClientData.getClientData) {
        this.setState({ isLoaded: false });
        this.setState({ clientData: getClientData.getClientData });
      }
    }
    if (deleteClientData !== preProps.deleteClientData) {
      if (deleteClientData.deleteClientData === '') {
        this.props.getClientAction();
        AlertMsg('success', 'Deleted');
      }
      if (deleteClientData.error) {
        AlertMsg('error', 'Failed to Delete');
      }
    }
  }

  handleClick = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };

  handleMenuClose = () => {
    this.setState(null);
  };

  render() {
    const { classes } = this.props;
    const { clientData, anchorEl } = this.state;
    return (
      <>
        {/* <CssBaseline /> */}
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={2} lg={2}>
              <Typography className={classes.title}>Name</Typography>
            </Grid>
            <Grid item xs={4} lg={4}>
              <Typography className={classes.title}>Contact Info</Typography>
            </Grid>
            <Grid item xs={3} lg={3}>
              <Typography className={classes.title}>Relationship</Typography>
            </Grid>
            <Grid item xs={3} lg={3}>
              <Typography className={classes.actiontitle}>Action</Typography>
            </Grid>
          </Grid>

          <Divider className={classes.dividersty} />
          {this.state.isLoaded ? (
            <Loader />
          ) : (
            clientData.map((client) => (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={2} lg={2}>
                    <Link
                      to={`/client_overview/${client._id}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography className={classes.namesty}>
                        {client.name}
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={4} lg={4} className={classes.gridSty}>
                    <>
                      <Typography display="inline">
                        {client.phoneNumber}
                        {' '}
                      </Typography>
                      <Typography
                        display="inline"
                        className={classes.mobileSty}
                      >
                        Mobile
                        {' '}
                      </Typography>
                      <PhoneDisabledOutlinedIcon
                        display="inline"
                        className={classes.iconSty}
                      />
                      <MobileOffIcon className={classes.iconSty} />
                    </>
                    <div className={classes.maildiv}>
                      <Typography display="inline" className={classes.mailsty}>
                        akshaymahajan28@yahoo.com
                      </Typography>
                      <Button
                        display="inline"
                        className={classes.button}
                        endIcon={<MailOutlineOutlinedIcon />}
                      >
                        Wrok
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={3} lg={3} className={classes.gridSty}>
                    <Typography
                      display="inline"
                      className={classes.relationSty}
                    >
                      Clinician:
                      {' '}
                    </Typography>
                    <Typography
                      display="inline"
                      className={classes.relationSty}
                    >
                      Akshay Mahajan
                    </Typography>
                  </Grid>
                  <Grid item xs={3} lg={3}>
                    <ListItem className={classes.listSty}>
                      <ExpandMore />
                      <Typography
                        className={classes.managesty}
                        onClick={this.handleClick}
                      >
                        Manage
                      </Typography>
                      <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => this.setState({ anchorEl: null })}
                        style={{ marginTop: '25px' }}
                      >
                        <Link
                          to={`/client_overview/${client._id}`}
                          className={classes.linkSty}
                        >
                          <MenuItem>View Client</MenuItem>
                        </Link>
                        <Link
                          to={`/edit_client/${client._id}`}
                          className={classes.linkSty}
                        >
                          <MenuItem>Edit Client Info</MenuItem>
                        </Link>
                        <Link className={classes.linkSty}>
                          <MenuItem>Merge with...</MenuItem>
                        </Link>
                        <Link
                          onClick={() => (
                            this.props.deleteClientAction(client._id),
                            this.setState({ anchorEl: null })
                          )}
                          className={classes.linkSty}
                        >
                          <MenuItem>Make inactive</MenuItem>
                        </Link>
                      </Menu>
                    </ListItem>
                  </Grid>
                </Grid>
                <Divider className={classes.dividersty} />
              </>
            ))
          )}
        </div>
      </>
    );
  }
}

const connected = connect(
  (state) => ({
    getClientData: state.getClient,
    deleteClientData: state.deleteClient,
  }),
  {
    getClientAction,
    deleteClientAction,
  },
)(ClientTable);

const formed = reduxForm({
  form: 'getClient',
})(connected);

export default withStyles(styles)(formed);
