import {
  GET_CLINICIAN_REQUEST,
  GET_CLINICIAN_SUCCESS,
  GET_CLINICIAN_FAILED,
  GET_CLINICIAN_APPOINTMENT_REQUEST,
  GET_CLINICIAN_APPOINTMENT_SUCCESS,
  GET_CLINICIAN_APPOINTMENT_FAILED,
  GET_BY_ID_CLINICIAN_REQUEST,
  GET_BY_ID_CLINICIAN_SUCCESS,
  GET_BY_ID_CLINICIAN_FAILED,
  GET_BY_IDS_CLINICIANS_SUCCESS,
  GET_BY_IDS_CLINICIANS_FAILED,
  GET_BY_IDS_CLINICIANS_REQUEST,
  SAVE_CLINICIAN_SIGNATURE_REQUEST,
  SAVE_CLINICIAN_SIGNATURE_SUCCESS,
  SAVE_CLINICIAN_SIGNATURE_FAILED,
  LOGGEDIN_CLINICIAN_INFO_REQUEST,
  LOGGEDIN_CLINICIAN_INFO_SUCCESS,
  LOGGEDIN_CLINICIAN_INFO_FAILED,
} from '../constant';

export const getClinician = (state = [], action) => {
  switch (action.type) {
    case GET_CLINICIAN_REQUEST:
      return {
        request: true,
      };
    case GET_CLINICIAN_SUCCESS:
      return {
        request: false,
        getClinicianData: action.response,
      };
    case GET_CLINICIAN_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getClinicianAppointment = (state = [], action) => {
  switch (action.type) {
    case GET_CLINICIAN_APPOINTMENT_REQUEST:
      return {
        request: true,
      };
    case GET_CLINICIAN_APPOINTMENT_SUCCESS:
      return {
        request: false,
        getClinicianAppointmentData: action.response,
      };
    case GET_CLINICIAN_APPOINTMENT_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getByIdClinician = (state = [], action) => {
  switch (action.type) {
    case GET_BY_ID_CLINICIAN_REQUEST:
      return {
        request: true,
      };
    case GET_BY_ID_CLINICIAN_SUCCESS:
      return {
        request: false,
        getByIdClinicianData: action.response,
      };
    case GET_BY_ID_CLINICIAN_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getByIdsClinicians = (state = [], action) => {
  switch (action.type) {
    case GET_BY_IDS_CLINICIANS_REQUEST:
      return {
        request: true,
      };
    case GET_BY_IDS_CLINICIANS_SUCCESS:
      return {
        request: false,
        clinicians: action.response,
      };
    case GET_BY_IDS_CLINICIANS_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const saveClinicianSignature = (state = '', action) => {
  switch (action.type) {
    case SAVE_CLINICIAN_SIGNATURE_REQUEST:
      return {
        request: true,
      };
    case SAVE_CLINICIAN_SIGNATURE_SUCCESS:
      return {
        request: false,
        signatureSaved: true,
        updatedSignature: action.response.signature,
      };
    case SAVE_CLINICIAN_SIGNATURE_FAILED:
      return {
        request: false,
        signatureSaved: false,
        updatedSignature: null,
      };
    default:
      return state;
  }
};

export const loggedInUserInfo = (state = [], action) => {
  switch (action.type) {
    case LOGGEDIN_CLINICIAN_INFO_REQUEST:
      return {
        request: true,
      };
    case LOGGEDIN_CLINICIAN_INFO_SUCCESS:
      return {
        request: false,
        user: action.response.user,
      };
    case LOGGEDIN_CLINICIAN_INFO_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};
