import React, { Component } from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import { Dialog, Typography, Grid } from '@material-ui/core';
import { DrawingArea, OutlinedBtn, ContainedBtn } from './style';

class CanvasDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    if (!this.props.signature && this.props.open) {
      this.clear();
    }
  }
  clear = () => {
    if (this.props && this.props.clear()) {
      this.props.clear();
    }
  };

  render() {
    return (
      <Dialog onClose={this.props.onClose} open={this.props.open}>
        <Grid style={{ padding: '25px' }} direction='column' container>
          <Grid item>
            <Typography variant='h6' align='center'>
              {this.props.usage && this.props.signature
                ? 'Use Signature'
                : 'Please draw your signature below'}
            </Typography>
          </Grid>
          <DrawingArea item>
            <SignaturePad
              ref={this.props.setRef}
              canvasProps={{ width: 600, height: 300 }}
            />
          </DrawingArea>

          {this.props.usage && this.props.signature ? (
            <Grid justify='flex-end' direction='row' spacing={2} item container>
              <Grid>
                <ContainedBtn
                  onClick={this.props.signConsentForm}
                  variant='contained'
                >
                  Use Signature
                </ContainedBtn>
              </Grid>
            </Grid>
          ) : (
            <Grid justify='flex-end' direction='row' spacing={2} item container>
              <Grid item>
                <OutlinedBtn onClick={this.clear} variant='outlined'>
                  Clear Signature
                </OutlinedBtn>
              </Grid>
              <Grid item>
                <ContainedBtn
                  onClick={() => this.props.modifyUserSignature()}
                  variant='contained'
                >
                  Adopt Signature
                </ContainedBtn>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Dialog>
    );
  }
}

export default CanvasDialog;
