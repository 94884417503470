import { createStyles} from "@material-ui/core";

export default (theme) =>
  createStyles({
    root: {
      backgroundColor: "#cfd9df",
      opacity: "20%",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    titleContainer: {
      position: "absolute",
      top: "202px",
      width: "800px",
      zIndex: 1,
    },
  });