/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { Grid, Typography, createStyles, withStyles } from "@material-ui/core";
import lineIcon from "../assets/imgs/Line.svg";
import EllipseIcon from "../assets/imgs/Ellipse.svg";
import styles from "./Stepper.styles";

class Stepper extends Component {
  render() {
    const { classes, tab } = this.props;
    return (
      <>
        <Grid style={{ display: "flex" }}>
          <Grid container alignItems="center" justify="center">
            <Grid>
              <Typography
                className={classes.text}
                style={{ opacity: tab === 0 ? "100%" : "50%" }}
              >
                Verify Information
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" justify="center">
            <Grid>
              <Typography
                style={{ opacity: tab === 1 ? "100%" : "50%" }}
                className={classes.text}
              >
                Consent Form
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" justify="center">
            <Grid>
              <Typography
                style={{ opacity: tab === 1 ? "100%" : "50%" }}
                className={classes.text}
              >
                Payment Information
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" justify="center">
            <Grid>
              <Typography
                style={{ opacity: tab === 2 ? "100%" : "50%" }}
                className={classes.text}
              >
                Appointment Confirmation
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <img
          src={lineIcon}
          style={{ marginTop: "10px" }}
          className={classes.image}
        />

        <Grid className={classes.dotsContainer}>
          <Grid
            container
            alignItems="center"
            justify="center"
            style={{ opacity: tab === 0 ? "100%" : "70%" }}
          >
            <img src={EllipseIcon} />
          </Grid>
          <Grid
            container
            alignItems="center"
            justify="center"
            style={{ opacity: tab === 1 ? "100%" : "70%" }}
          >
            <img src={EllipseIcon} />
          </Grid>
          <Grid
            container
            alignItems="center"
            justify="center"
            style={{ opacity: tab === 2 ? "100%" : "70%" }}
          >
            <img src={EllipseIcon} />
          </Grid>
          <Grid container alignItems="center" justify="center">
            <img
              src={EllipseIcon}
              style={{
                opacity: tab === 3 ? "100%" : "70%",
                marginLeft: "10px",
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

Stepper.defaultProps = {
  tab: 1,
};


export default withStyles(styles)(Stepper);
