import { createStyles} from "@material-ui/core";

export default (theme) =>
createStyles({
  root: {
    // position: 'absolute',
    // top: 0,
    // left: 0,
    // right: 0,
    // bottom: 0,
  },
  alertFont: {
    color: "DodgerBlue",
    textAlign: "left",
    marginTop: "-15px",
    fontFamily: "Lato",
    fontWeight: 400,
  },
  imageContainer: {
    height: "100%",
    width: "calc(100% - 35%)",
    position: "fixed",
    left: 0,
    top: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    // backgroundImage: `url(${sign_up_img})`,
    // backgroundRepeat: "no-repeat",
    // backgroundColor:
    //   theme.palette.type === "light"
    //     ? theme.palette.grey[50]
    //     : theme.palette.grey[900],
    // backgroundSize: "cover",
    // backgroundPosition: "center",
    // zIndex: -10,
    // // position: "fixed",
    // // height: '900px',
    // width: "900px",
  },
  formContainer: {
    height: "100%",
    width: "540px",
    backgroundColor: "#fffffff",
    justifyContent: "center",
    display: "flex",
    // border: '1px solid #707070',
    // paddingLeft: '114px',
    // paddingRight: '114px',
    paddingTop: "147px",
    paddingBottom: "14px",
  },
  bodyContainer: {
    width: "313px",
  },
  // flex: {
  //   display: "flex",
  //   justifyContent: "flex-start",
  //   alignItems: "center",
  // },
  spacious: {
    fontWeight: 800,
    // fontSize: '25px',
    color: "#304659",
    // width: "313px",
    // height: "30px",
    lineHeight: "30px",
    fontFamily: "Lato",
    [theme.breakpoints.up("xs")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      fontSize: "25px",
    },
  },
  avatar: {
    marginTop: "18px",
    width: "267",
    height: "19px",
    [theme.breakpoints.up("xs")]: {
      display: "inline",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      display: "flex",
    },
  },
  create: {
    // fontSize: '17px',
    color: "#3b5266",
    lineHeight: "25px",
    fontFamily: "Lato",
    fontWeight: "400",
    [theme.breakpoints.up("xs")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "17px",
    },
  },
  signsub: {
    // fontSize: '17px',
    color: "#3b5266",
    fontWeight: "bold",
    marginLeft: 5,
    textDecoration: "underline",
    fontFamily: "Lato",
    cursor: "pointer",
    [theme.breakpoints.up("xs")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "17px",
    },
  },
  google: {
    display: "flex",
    // height: '40px',
    // width: '313px',
    // width: "313px",
    borderRadius: 0,
    backgroundColor: "#1b63eb",
    // justifyContent: "flex-start",
    marginTop: "17px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#1b63eb",
    },
    [theme.breakpoints.up("xs")]: {
      width: "200px",
      height: "30px",
      fontSize: "15px",
      // display: "flex",
      // justifyContent: "flex-start",
      // textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      width: "325px",
      height: "40px",
    },
    [theme.breakpoints.up("md")]: {
      width: "300px",
      height: "40px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "325px",
      height: "40px",
    },
  },
  googleText: {
    textTransform: "none",
    color: "#ffffff",
    // fontSize: '17px',
    fontFamily: "Lato",
    fontWeight: "800",
    textAlign: "center",
    marginTop: "8px",
    [theme.breakpoints.up("xs")]: {
      fontSize: "12px",
      marginLeft: "20px",
      lineHeight: "15px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "15px",
      marginLeft: "50px",
      lineHeight: "25px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "17px",
      marginLeft: "70px",
      lineHeight: "25px",
    },
  },
  googleIcon: {
    // width: "21px",
    // height: "21px",
    textAlign: "start",
    justifyContent: "flex-start",
    display: "flex",
    // marginTop: "11px",
    paddingLeft: "11px",
    [theme.breakpoints.up("xs")]: {
      width: "15px",
      height: "15px",
      marginTop: "9px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "15px",
      height: "15px",
      marginTop: "13px",
    },
    [theme.breakpoints.up("md")]: {
      width: "21px",
      height: "21px",
      marginTop: "10px",
    },
  },
  apple: {
    display: "flex",
    // height: '40px',
    // width: '313px',
    borderRadius: 0,
    backgroundColor: "#222222",
    justifyContent: "flex-start",
    marginTop: "17px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#222222",
    },
    [theme.breakpoints.up("xs")]: {
      width: "200px",
      height: "30px",
      fontSize: "15px",
      display: "flex",
      justifyContent: "flex-start",
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      width: "325px",
      height: "40px",
    },
    [theme.breakpoints.up("md")]: {
      width: "300px",
      height: "40px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "325px",
      height: "40px",
    },
  },
  appleicon: {
    // width: '30px',
    // height: '30px',
    textAlign: "start",
    justifyContent: "flex-start",
    display: "flex",
    // marginTop: "11px",
    paddingLeft: "8px",
    paddingTop: "3px",
    color: "#ffffff",
    [theme.breakpoints.up("xs")]: {
      width: "18px",
      height: "18px",
      marginTop: "3px",
      marginRight: "5px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "20px",
      height: "20px",
      marginTop: "6px",
      marginRight: "5px",
    },
    [theme.breakpoints.up("md")]: {
      width: "30px",
      height: "30px",
      marginTop: "2px",
    },
  },
  divider: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
    flexDirection: "row",
    marginTop: "24px",
    [theme.breakpoints.up("xs")]: {
      width: "200px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "325px",
    },
    [theme.breakpoints.up("md")]: {
      width: "200px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "325px",
    },
  },
  divider_text: {
    margin: (0, 10),
    fontSize: "17px",
    fontWeight: "800",
    lineHeight: "25px",
    color: "#304659",
    alignItems: "center",
    fontFamily: "Lato",
    textTransform: "uppercase",
  },
  dividerImage: {
    [theme.breakpoints.up("xs")]: {
      width: "78px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "200px",
    },
    [theme.breakpoints.up("md")]: {
      width: "128px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "200px",
    },
  },
  title: {
    // textTransform: "inherit",
    lineHeight: "20px",
    fontFamily: "Lato",
    fontWeight: "400",
    color: "#3b5266",
    // width: '313px',
    // marginTop: "30px",
    textAlign: "left",
    [theme.breakpoints.up("xs")]: {
      width: "200px",
      fontSize: "13px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "200px",
      fontSize: "18px",
    },
    [theme.breakpoints.up("md")]: {
      width: "300px",
      fontSize: "18px",
    },
  },
  submit: {
    marginTop: "27px",
    marginBottom: "100px",
    backgroundColor: "#304659",
    // height: '40px',
    // width: '313px',
    borderRadius: 0,
    color: "#f5f9fc",
    fontWeight: "800",
    textTransform: "none",
    lineHeight: "25px",
    fontFamily: "Lato",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#304659",
    },
    [theme.breakpoints.up("xs")]: {
      width: "200px",
      height: "30px",
      lineHeight: "15px",
      fontSize: "13px",
      // marginRight: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "325px",
      height: "40px",
      fontSize: "17px",
    },
    [theme.breakpoints.up("md")]: {
      width: "300px",
      height: "40px",
      fontSize: "17px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "325px",
      height: "40px",
      fontSize: "17px",
    },
  },
  inputcontainer: {
    backgroundColor: "#e1e9f0",
    border: "none",
    // padding: ('12px', '15px'),
    // width: '280px',
    // maxWidth: '313px',
    // height: "40px",
    marginTop: "8px",
    marginBottom: "12px",
    textTransform: "inherit",
    outline: 0,
    fontSize: "14px",
    fontFamily: "Lato",
    fontWeight: 400,
    color: "#304659",
    [theme.breakpoints.up("xs")]: {
      width: "190px",
      height: "35px",
      paddingLeft: "5px",
      paddingRight: "5px",
      float: "left",
    },
    [theme.breakpoints.up("sm")]: {
      width: "313px",
      height: "41px",
      paddingLeft: "5px",
      paddingRight: "5px",
    },
    [theme.breakpoints.up("md")]: {
      width: "290px",
      height: "41px",
      // paddingLeft: '5px',
      // paddingRight: '5px',
    },
    [theme.breakpoints.up("lg")]: {
      width: "313px",
      height: "41px",
      // paddingLeft: '5px',
      // paddingRight: '5px',
    },
  },
  inputtext: {
    fontSize: "14px",
    fontFamily: "Lato",
    fontWeight: 400,
    color: "#304659",
  },
  bg_container: {
    position: "absolute",
    // margin: 0,
    // padding: 0,
    top: "71px",
    left: "100px",
  },
  bg_title: {
    fontSize: "70px",
    width: "653px",
    height: "234px",
    fontWeight: 800,
    filter: "drop-shadow(2.828px 2.828px 0.5px rgba(0,0,0,0.16))",
    color: "#ffffff",
    textAlign: "left",
    display: "flex",
    justifyContent: "left",
    lineHeight: "75px",
    fontFamily: "Lato",
    marginTop: "36px",
  },
  bg_divider: {
    backgroundColor: "#ffffff",
    textAlign: "left",
    marginTop: "43px",
    display: "flex",
  },
  bg_subtitle: {
    width: "360px",
    height: "75px",
    color: "#ffffff",
    fontSize: "17px",
    fontWeight: "400",
    fontFamily: "Lato",
    textAlign: "left",
    lineHeight: "25px",
    marginTop: "13px",
  },
  tagline: {
    width: "360px",
    height: "20px",
    fontSize: "13px",
    letterSpacing: "1px",
    lineHeight: "20px",
    textAlign: "left",
    fontWeight: "800",
    fontFamily: "Lato",
    color: "#ffffff",
    marginTop: "157px",
  },
  checkboxsty: {
    // width: '20px',
    // height: '20px',
    backgroundColor: "#e1e9f0",
    marginTop: "25px",
    [theme.breakpoints.up("xs")]: {
      width: "15px",
      height: "15px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "18px",
      height: "18px",
    },
    [theme.breakpoints.up("md")]: {
      width: "20px",
      height: "20px",
    },
  },
  checkedsty: {
    paddingLeft: "10px",
    // width: '283px',
    height: "40px",
    lineHeight: "20px",
    color: "#3b5266",
    fontFamily: "Lato",
    fontWeight: "400",
    marginTop: "24px",
    display: "flex-inline",
    textAlign: "left",
    cursor: "pointer",
    [theme.breakpoints.up("xs")]: {
      width: "200px",
      fontSize: "13px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "283px",
      fontSize: "15px",
    },
    [theme.breakpoints.up("md")]: {
      width: "283px",
      fontSize: "15px",
    },
  },
  lightlogo: {
    width: "30px",
    height: "40px",
    // width: "28px",
    // height: "37px",
    color: "#ffffff",
    display: "flex",
    textAlign: "start",
  },
  responsiveForm: {
    position: "absolute",
    right: 0,
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      top: 50,
      justify: "center",
      // marginRight: '10px',
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 65%)",
      top: 100,
    },
  },
  checkboxTextContainer: {
    display: "flex",
    [theme.breakpoints.up("xs")]: {
      width: "200px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "325px",
    },
    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "325px",
    },
  },
});
