import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import '../../../clinician/clinician.scss';
import LogoLightHouse from '../../../assets/imgs/Background.png';
import useStyles from "./Sidebar.styles";



const isToday = (someDate) => {
  const today = new Date()
  return someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
}

export default function Sidebar({ appointments }) {

  const classes = useStyles();
    
  return (
    <>
      <CssBaseline />
      <div className={classes.root}>
        <AppBar position="relative" className={classes.appBar} id="myHeader-stick" elevation={0}>
          <Toolbar>
            <div style={{ width: '17%', paddingRight: 24 }}>
              <img src={LogoLightHouse} style={{ width: '100%' }} />
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
}
