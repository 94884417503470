import React from "react";
import CommentsAttachmentInputView from "./CommentsAttachmentInputView";
import moment from "moment";
import DefaultAvatar from '../../../../assets/imgs/download.png';

const CommentsListItemView = (props) => {
  return <div key={props?._id} className="comments-display text-sm p-6 border-t">
    <div className="flex gap-2 justify-between">
      { }
      <div className="user-avatar w-12">
        <img
          src={props?.note?.createdBy?.avatar || DefaultAvatar }
          alt
          className="rounded-full border-2 border-white"
        />
      </div>
      { }
      <div className="comment-box w-full">
        <div className="flex justify-between">
          <div className="user-details">
            <h3 className="inline-block text-base text-brand-blue">
              {props?.note?.createdBy.firstName + ' ' + props?.note?.createdBy.lastName}
            </h3>
            <span
              className="inline-block px-2 -mt-0.5 mx-0.5 ml-1 rounded-full text-xs font-bold bg-brand-gold-light text-brand-gold-dark border border-brand-gold-dark uppercase"
              style={{
                fontSize: "0.5rem",
                verticalAlign: "middle"
              }}
            >
              Admin
            </span>
          </div>
          {props.note?.status != "deleted" ? <div className="edit-delete-options text-xs">
            <button
              onClick={() => props?.onEdit(props?.note)}
              className="ml-2 text-gray-400 hover:text-gray-500 hover:underline"
            >
              Edit
            </button>
            <button
              onClick={() => props?.onDelete(props?.note)}
              className="ml-2 text-gray-400 hover:text-gray-500 hover:underline"
            >
              Delete
            </button>
          </div> : <> </>}
        </div>
        { }
        <div className="comment-bubble p-4 mt-1 rounded-xl rounded-tl-none text-gray-500 bg-white border border-gray-200">
          {props.note?.status == "deleted" ? "Comment deleted." :  props?.note?.note}
          {props?.note?.files?.length > 0 && props.note?.status != "deleted" && <CommentsAttachmentInputView isEditing={false} files={props?.note?.files} />}
        </div>
        { }
        <div className="mt-2 text-xs text-gray-400">
          <div className="time-created ">
            <span className>Created:  </span>
            <span className>{moment(props?.note?.createdAt).format('DD/mm/YY, h:mmA')}</span>
          </div>
          {props.note && props.note.modifiedBy
            && <div className="time-modified">
              <span className>Modified:  </span>
              <span className>{moment(props?.note?.updatedAt).format('DD/mm/YY, h:mmA') +
                ' by ' +
                props?.note?.modifiedBy.firstName + " " +
                props?.note?.modifiedBy.lastName}</span>
            </div>}
          {props.note && props.note.status == "deleted"
            && <div className="time-modified">
              <span className>Deleted:  </span>
              <span className>{moment(props?.note?.updatedAt).format('DD/mm/YY, h:mmA') +
                ' by ' +
                props?.note?.modifiedBy.firstName + " " +
                props?.note?.modifiedBy.lastName}</span>
            </div>}
        </div>
      </div>
    </div>
  </div >
};

export default CommentsListItemView;
