import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

const Img = styled.img`
  width: 400px;
  height: 100px;
  border: 1px solid #ccc;
`;

const ImgGrid = styled(Grid)`
  margin-bottom: 20px;
`;

const Heading = styled(Typography)`
  font-size: 20px;
`;

const FilterGrid = styled(Grid)`
  margin-bottom: 0px;
`;

export { Img, ImgGrid, Heading, FilterGrid };
