/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable import/prefer-default-export */
import {
  ADD_MHA_REQUEST,
  GET_MHA_REQUEST,
  GET_BY_ID_MHA_REQUEST,
  UPDATE_MHA_REQUEST,
} from "../AdminConstent";

export const addMhaAction = (data) => ({
  type: ADD_MHA_REQUEST,
  endpoint: "/admin/health_assessment",
  body: data,
});

export const getMhaAction = () => ({
  type: GET_MHA_REQUEST,
  endpoint: "/admin/health_assessment/mental_health",
});

export const getByIdMhaAction = (id) => ({
  type: GET_BY_ID_MHA_REQUEST,
  endpoint: `/admin/health_assessment/${id}`,
});
export const updateMhaAction = (id, data) => ({
  type: UPDATE_MHA_REQUEST,
  endpoint: `/admin/health_assessment/${id}`,
  body: data,
});

export const getMhaUserAction = () => ({
  type: GET_MHA_REQUEST,
  // endpoint: '/api/health_assessment/complete_assessment',
  endpoint: "/api/health_assessment/mental_health",
});
