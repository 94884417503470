/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from "react";
import {
  createStyles,
  withStyles,
  CssBaseline,
  Container,
  Box,
} from "@material-ui/core";

import aata_logo from "../assets/banner_images/aata_logo-1.png";
import aca_logo from "../assets/banner_images/aca-logo.png";
import CALPCC_logo from "../assets/banner_images/CALPCC-Logo-1.png";
import gottman from "../assets/banner_images/gottman-institute.png";
import PsychologyToday from "../assets/banner_images/PsychologyToday-logo.png";
import thbi from "../assets/banner_images/thbi-04.png";
import styles from "./Institude_banner_style";

class InstitudeBanner extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div style={{ width: "100%" }}>
        <Box
          display="flex"
          justifyContent="flex-start"
          flexWrap="nowrap"
          // p={1}
          // m={1}
          bgcolor="background.paper"
          css={{ maxWidth: "100%" }}
        >
          <Box p={1} bgcolor="grey.300">
            <img src={aata_logo} className={classes.root} />
          </Box>
          <Box p={1} bgcolor="grey.300">
            <img src={aca_logo} className={classes.root} />
          </Box>
          <Box p={1} bgcolor="grey.300">
            <img src={CALPCC_logo} className={classes.root} />
          </Box>
          <Box p={1} bgcolor="grey.300">
            <img src={gottman} className={classes.root} />
          </Box>
          <Box p={1} bgcolor="grey.300">
            <img src={PsychologyToday} />
          </Box>
          <Box p={1} bgcolor="grey.300">
            <img src={thbi} className={classes.root} />
          </Box>
        </Box>
      </div>
    );
  }
}


export default withStyles(styles)(InstitudeBanner);
