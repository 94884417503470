import {
  GET_CONSENT_FORMS_REQUEST,
  GET_CONSENT_FORMS_SUCCESS,
  GET_CONSENT_FORMS_FAILED,
  DOWNLOAD_CONSENT_FAILED,
  DOWNLOAD_CONSENT_SUCCESS,
  DOWNLOAD_CONSENT_REQUEST,
  DOWNLOAD_CONSENT_CLEAR,
} from '../constant';

export const getConsentForms = (state = { request: false }, action) => {
  switch (action.type) {
    case GET_CONSENT_FORMS_REQUEST:
      return {
        request: true,
      };
    case GET_CONSENT_FORMS_SUCCESS:
      return {
        request: false,
        data: action.response[0].data,
        count: action.response[0].count[0]
          ? action.response[0].count[0].count
          : 0,
      };
    case GET_CONSENT_FORMS_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const downloadConsetForm = (state = { request: false }, action) => {
  switch (action.type) {
    case DOWNLOAD_CONSENT_REQUEST:
      return {
        request: true,
      };
    case DOWNLOAD_CONSENT_SUCCESS:
      return {
        request: false,
        file: action.response,
      };
    case DOWNLOAD_CONSENT_FAILED:
      return {
        request: false,
        error: true,
      };
    case DOWNLOAD_CONSENT_CLEAR:
      console.log('clerred');
      return {
        file: null,
      };
    default:
      return state;
  }
};
