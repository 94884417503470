import React, { useEffect, useState } from 'react';
import SidebarClinician from '../SidebarClinician';
import SidebarMenu from '../SidebarMenuClinician';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import userIcon from '../../assets/imgs/download.png';
import { Drawer, Grid, Typography, Button } from '@material-ui/core';
import '../clinician.scss';
import EventDetail from './../../Patient/components/Events/EventDetail';
import Axios from 'axios';
import { baseURL } from '../../utils/axios';
import Loader from '../../components/Loader/Loader';
import { EventImageContainer } from '../../Patient/components/Events/common/EventImageContainers';
import Avatar from '@material-ui/core/Avatar';
import { EventViewButton } from '../../Patient/components/Events/common/EventButtons';
import Layout from '../Layout';
import logo2 from '../../assets/imgs/Oval (2).png';
import useStyles from './events.styles';
import moment from 'moment';
import { toTitleCase } from '../../utils/validators';
import { Link } from 'react-router-dom';

export default function ClinicianEvents() {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [eventId, setEventId] = useState('');
  const [isLoaded, setIsloaded] = React.useState(false);
  const [eventLists, setEventLists] = useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  useEffect(() => {
    setIsloaded(true);
    getEventLists('');
  }, []);
  const getClinician = (clinician) => {
    var result = [];
    clinician &&
      clinician.length > 0 &&
      clinician.map((val) => {
        result.push({ img: logo2 });
      });
    return result;
  };

  const getDate = (date) => {
    var today = new Date(date);
    var d = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var m = today.getMonth() < 10 ? '0' + today.getMonth() : today.getMonth();
    var date = d + '/' + m + '/' + today.getFullYear();
    return date;
  };

  const getTime = (time) => {
    var date = new Date(time);
    var hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    var am_pm = date.getHours() >= 12 ? 'PM' : 'AM';
    hours = hours < 10 ? '0' + hours : hours;
    var minutes =
      date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    var seconds =
      date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    var time = hours + ':' + minutes + '' + am_pm;
    return time;
  };
  const getEventLists = (term) => {
    const clinicianId = localStorage.getItem('clinicianUID');
    console.log('clinicianId', clinicianId);
    Axios.get(
      `${baseURL}/api/event?page=${page}&term=${term}&status=upcoming&clinicianId=${clinicianId}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        //call
        setIsloaded(false);
        console.log('res', res.data.events);
        if (res.data) {
          setEventLists(res.data.events);
        }
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };
  return (
    <Layout>
      <div
        className='main-content'
        style={{ paddingLeft: '0', width: '100%', paddingTop: 0 }}
      >
        <Grid style={{ padding: '0px' }} className={'clinicianEvents'}>
          <h4 className='tabHeading'>Upcoming Events</h4>
          {isLoaded ? (
            <Grid container alignItems='center' justify='center'>
              <Loader />
            </Grid>
          ) : (
            // <EventCustomTable
            //   allData={this.state.eventsList}
            //   onClickEvent={() => onClickEvent()}
            //   {...this.props}
            // />
            <Grid className={'clinicianEvents'}>
              <Grid container className='patientDetailEventTable'>
                <TableContainer style={{ maxHeight: 500 }}>
                  <Table
                    className={classes.table}
                    stickyHeader
                    aria-label='sticky table'
                  >
                    <TableBody component={Paper}>
                      {eventLists.length > 0 ? (
                        eventLists.map((data, i) => {
                          console.log(data);
                          return (
                            <TableRow>
                              <TableCell>
                                <Typography
                                  component={'p'}
                                  className='headNames'
                                >
                                  Topic
                                </Typography>
                                <Typography
                                  component={'p'}
                                  className='topicDetail'
                                >
                                  {data.title}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  component={'p'}
                                  className='headNames'
                                >
                                  Date
                                </Typography>
                                <Typography component={'h4'}>
                                  {moment(data.date).format('D/MM/YYYY')}
                                </Typography>
                                <Typography component={'p'} className=''>
                                  {moment(data.startTime).format('h:mm a')} -{' '}
                                  {moment(data.endTime).format('h:mm a')}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  component={'p'}
                                  className='headNames'
                                >
                                  Venue
                                </Typography>
                                <Typography component={'h4'}>
                                  {data.location &&
                                  data.location.type == 'PHYSICAL'
                                    ? 'Physical'
                                    : 'Online'}
                                </Typography>
                                {data.location &&
                                data.location.type == 'PHYSICAL' ? (
                                  <Typography component={'p'} className=''>
                                    {data.location.address}
                                  </Typography>
                                ) : (
                                  <Link to={data.location.address}>
                                    {' '}
                                    <Typography component={'p'} className=''>
                                      {data.location.address}
                                    </Typography>
                                  </Link>
                                )}
                              </TableCell>
                              <TableCell>
                                <Typography
                                  component={'p'}
                                  className='headNames'
                                >
                                  Clinician(s)
                                </Typography>
                                <Grid className={'images'}>
                                  <img src={userIcon} />
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  component={'p'}
                                  className='headNames'
                                >
                                  Seats Available
                                </Typography>
                                <Typography
                                  component={'h4'}
                                  className={'seatsAvail'}
                                >
                                  {data.totalSeats} Seats
                                </Typography>
                                <Typography
                                  component={'p'}
                                  className={'seatsAvail'}
                                >
                                  {data.numberOfAttendees} RSVP's
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  component={'p'}
                                  className='headNames'
                                >
                                  Event Type
                                </Typography>
                                <Typography component={'h4'}>
                                  {toTitleCase(data.type)}
                                </Typography>
                                <Typography component={'p'} className=''>
                                  Support Group
                                </Typography>
                              </TableCell>
                              <TableCell align='right'>
                                <Button
                                  variant='contained'
                                  onClick={() => {
                                    setIsOpen(true);
                                    setEventId(data._id);
                                  }}
                                  className={classes.viewBtn}
                                  style={{ marginLeft: '16px' }}
                                >
                                  View
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow key={1}>
                          <TableCell
                            component='th'
                            style={{ textAlign: 'center' }}
                            colSpan={6}
                            scope='row'
                          >
                            No Data Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
      <Drawer
        anchor='right'
        open={isOpen}
        // {...this.props}
        onClose={() => setIsOpen(false)}
      >
        <EventDetail eventId={eventId} clinician={true} />
      </Drawer>
    </Layout>
  );
}
