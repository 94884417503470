/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from "react";
import { Typography } from "@material-ui/core";
import styles from "./DrawerFont_style";

export const DrawerTitleFont = ({ text, ...props }) => {
  const classes = styles();
  return (
    <Typography className={classes.drawerTitle} {...props}>
      {text}
    </Typography>
  );
};

export const DrawerReferredFont = ({ text, ...props }) => {
  const classes = styles();
  return (
    <Typography className={classes.drawerReferredFont} {...props}>
      {text}
    </Typography>
  );
};

export const DrawerReferredClinicianFont = ({ text, ...props }) => {
  const classes = styles();
  return (
    <Typography className={classes.refClinicianNameFont} {...props}>
      {text}
    </Typography>
  );
};

export const DrawerNameFont = ({ text, ...props }) => {
  const classes = styles();
  return (
    <Typography className={classes.nameFont} {...props}>
      {text}
    </Typography>
  );
};

export const DrawerSubtextFont = ({ text, ...props }) => {
  const classes = styles();
  return (
    <Typography className={classes.subtextFont} {...props}>
      {text}
    </Typography>
  );
};

export const DrawerTableBtnFont = ({ text, ...props }) => {
  const classes = styles();
  return (
    <Typography className={classes.buttonText} {...props}>
      {text}
    </Typography>
  );
};
