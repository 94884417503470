import React, { useState } from 'react';
import {
  Button,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Avatar,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment';
import Loader from '../../../components/Loader/Loader';
import { ActionBtn } from './Dashbord_style';

const RED = '#DA1A1A';
const GREEN = '#01BC05';

const UnverifiedAccount = ({
  classes,
  loading,
  clients,
  renderAge,
  checkBasicInfo,
  openPatientDetail,
  downloadUnverifiedClients,
  handleDetailDrawer,
}) => {
  return (
    <div>
      <TableContainer elevation={0}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead style={{ backgroundColor: '#F6F7FB' }}>
            <TableRow>
              <TableCell style={{ color: '#C7C7C9' }}>CLIENT</TableCell>
              <TableCell align='left' style={{ color: '#C7C7C9' }}>
                AGE GROUP
              </TableCell>
              <TableCell align='left' style={{ color: '#C7C7C9' }}>
                DATE CREATED
              </TableCell>
              <TableCell align='left' style={{ color: '#C7C7C9' }}>
                MISSING
              </TableCell>
              <TableCell align='left' style={{ color: '#C7C7C9' }}>
                APPOINTMENT DATE
              </TableCell>
              <TableCell align='right' style={{ color: '#C7C7C9' }}>
                ACTIONS
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody className={classes.body}>
            {loading ? (
              <Loader />
            ) : clients && clients.length > 0 ? (
              clients.map((c) => (
                <TableRow key={`unverified-${c._id}`}>
                  <TableCell className={classes.userImages}>
                    <Grid
                      container
                      spacing={2}
                      wrap='nowrap'
                      alignItems='center'
                    >
                      <Grid item>
                        <Avatar src={c.avatar} />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.blue}>
                          {`${c.firstName} ${c.lastName}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align='left'>
                    <Grid item>
                      <Typography className={classes.blue}>
                        {renderAge(c)}
                      </Typography>
                    </Grid>
                  </TableCell>
                  <TableCell align='left'>
                    <Grid item>
                      <Typography className={classes.blue}>
                        {moment(c.createdAt).format('DD-MM-YYYY')}
                      </Typography>
                    </Grid>
                  </TableCell>
                  <TableCell align='left'>
                    <Grid item>
                      <Tooltip placement='top' title='Proof Of Identity'>
                        <ActionBtn
                          color={
                            c.documents.some(
                              (d) => d.type === 'proofOfIdentity'
                            )
                              ? GREEN
                              : RED
                          }
                          onClick={() => {}}
                        >
                          <i className='fas fa-id-card'></i>
                        </ActionBtn>
                      </Tooltip>
                      <Tooltip placement='top' title='Patient Info'>
                        <ActionBtn
                          color={checkBasicInfo(c) ? GREEN : RED}
                          onClick={() => {}}
                          className='ml-2'
                        >
                          <i className='fas fa-user'></i>
                        </ActionBtn>
                      </Tooltip>
                    </Grid>
                  </TableCell>
                  <TableCell align='left'>
                    <Typography className={classes.blue}>
                      {moment(c.appointments[0]?.startTime).format(
                        'DD-MM-YYYY hh:mm a'
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align='right'>
                    <div className='flex'>
                      <Button onClick={() => openPatientDetail(c._id)}>
                        <VisibilityIcon />
                      </Button>

                      <Button
                        className='ml-2.5'
                        onClick={() =>
                          handleDetailDrawer(c.appointments[0]?._id)
                        }
                      >
                        <VisibilityIcon />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <Grid justify='center' container>
                <Grid item>No unverified clients available!</Grid>
              </Grid>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant='contained'
        className={classes.btn2}
        style={{
          marginTop: '20px',
          color: '#FFFFFF',
          backgroundColor: '#003265',
        }}
        onClick={downloadUnverifiedClients}
      >
        Download Report
      </Button>{' '}
    </div>
  );
};

export default UnverifiedAccount;
