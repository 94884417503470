import {createStyles} from "@material-ui/core";
export default (theme) =>
createStyles({
  root1: {
    //   width: "100%",
    paddingTop: "47px",
    paddingBottom: "47px",
    paddingLeft: "42px",
    paddingRight: "42px",
    backgroundColor: "#ffffff",
    marginBottom: "24px",
    // backgroundColor: "rgba(192,208,222,18%)",
  },
  userDetail:{
    width:'750px',
    padding:'25px',
    backgroundColor:'#f0f2ff',
    marginBottom:'20px'
  }
});