import { createStyles } from '@material-ui/core/styles';


export default createStyles({
    root: {
      width: "calc(100% - 260px)",
      height: "100%",
      margin: "auto",
      position: "absolute",
      top: "70px",
      left: "250px",
    },
    addButton: {
      float: "left",
      color: "#ffffff",
      margin: "10px",
      backgroundColor: "#003d79",
    },
  });