import React from "react";

const bgcolor = (status) => {
  let color = 'gray';

  if(status === 'paid') {
    color = 'green'
  } else if (status === 'unpaid') {
    color = 'red'
  } else if (status === 'pending') {
    color = 'yellow'
  } else if (status === 'voided') {
    color = 'red'
  } else if (status === 'partial-unpaid') {
    color = 'red'
  } else if (status === 'refunded') {
    color = 'red'
  }

  return color;
}
const InvoiceListItemStatus = ({status}) => (
  <div
    className={`inline-block px-2 mt-0 pb-4px rounded-full text-xs font-bold bg-${bgcolor(status)}-500 text-white whitespace-nowrap`}
    style={{
      fontSize: "0.5rem"
    }}
  >
    {status == "partial-unpaid" ? "UNPAID" : status.toUpperCase()}
  </div>
);

export default InvoiceListItemStatus;
