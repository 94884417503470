import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  root: {
    position: 'static',
    padding: '20px',
    marginLeft: 250,
  },
  title: {
    textTransform: 'uppercase',
    color: '#a29061',
    fontSize: '12px',
    lineHeight: 'normal',
    textAlign: 'left',
  },
  actiontitle: {
    // width: '25%',
    textTransform: 'uppercase',
    color: '#a29061',
    fontSize: '12px',
    lineHeight: 'normal',
    paddingRight: theme.spacing(10),
  },
  dividersty: {
    width: '98%',
    marginTop: '15px',
    marginBottom: '15px',
    marginRight: '15px',
    backgroundColor: '#e6e5e2',
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(2),
  },
  namesty: {
    fontSize: '16px',
    color: '#003d79',
    cursor: 'pointer',
    lineHeight: 'normal',
    textAlign: 'left',
    textTransform: 'capitalize',
  },
  mailsty: {
    color: '#003d79',
    cursor: 'pointer',
    lineHeight: 'normal',
    fontSize: '13px',
    textAlign: 'left',
    marginTop: '10px',
  },
  relationSty: {
    lineHeight: 'normal',
    fontSize: '13px',
    color: '#003d79',
    textTransform: 'capitalize',
    textAlign: 'left',
  },
  mobileSty: {
    color: '#7489a1',
    lineHeight: 'normal',
    fontSize: '13px',
    textTransform: 'capitalize',
    marginLeft: '10px',
  },
  iconSty: {
    width: '18px',
    color: '#ea644c',
    fontSize: '18px',
    lineHeight: 'normal',
    marginLeft: '10px',
    marginTop: '10px',
  },
  actionSty: {
    lineHeight: 'normal',
    color: '#444',
    marginLeft: '10px',
  },
  gridSty: {
    textAlign: 'left',
  },
  maildiv: {
    float: 'left',
    display: 'flex',
    flexWrap: 'wrap',
  },
  listSty: {
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingRight: '140px',
  },
  managesty: {
    color: '#444',
    fontWeight: 500,
    cursor: 'pointer',
    fontSize: '14px',
  },
  linkSty: {
    textDecoration: 'none',
    color: '#003d79',
  },
});
