import { makeStyles} from "@material-ui/core";

export default makeStyles((theme) => ({
    root: {
      backgroundColor: "#304659",
      height: "54px",
      // width: '143px',
      color: "#ffffff",
      fontSize: "16px",
      lineHeight: "19px",
      fontWeight: 700,
      fontFamily: "Lato",
      textAlign: "center",
      border: "1px solid #fff",
    },
  }));