import React, { useEffect, useState, createRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { DropzoneArea } from 'material-ui-dropzone';
import profile from '../../assets/imgs/ananyaBig.png';
import {
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Drawer,
  Grid,
  Typography,
  Input,
} from '@material-ui/core';
import '../clinician.scss';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Axios from 'axios';
import { baseURL } from '../../utils/axios';
import AlertMsg from '../../utils/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Layout from '../Layout';
import countries from '../../utils/countries_data';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Loader from '../../components/Loader/Loader';
import useStyles from './style';
import SelectClinicAddresses from '../../admin/components/common/SelectClinicAddresses';
import { DISCIPLINES } from '../../utils/enums';
import { CanvasDialog, Spinner, ImageUploader } from '../../components/atoms';
import { saveClinicianSignature } from '../../utils/actions/clinician.action';

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 36,
    height: 20,
    padding: 0,
    margin: theme.spacing(1),
    transform: 'scaleX(-1)',
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

let initState = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  phoneType: '',
  address: [],
  clinicianId: '',
  patientAgeGroup: '',
  discipline: '',
  bio: '',
  signature: '',
};

const SettingClinician = ({
  saveClinicianSignature,
  signatureSaved,
  updatedSignature,
}) => {
  const classes = useStyles();
  const [activeSpecialities, setActiveSpecialities] = useState({});
  const [profileImage, setProfileImage] = useState(profile);
  const [specialityList, setSpecialityList] = useState([]);
  const [details, setDetails] = useState({ ...initState });
  const [btnLoading, setBtnLoading] = useState(false);
  const [genralInfo, setGenralInfo] = useState(null);
  const [avatar, setAvatar] = useState('');
  const [pageLoader, setPageLoader] = useState(false);
  const [openCanvasDialog, setCanvasDialog] = useState(false);
  // const [draftAddress, setDraftAddress] = useState(null);
  const [addressesList, setAddressesList] = useState([]);
  let signaturePad = createRef();

  useEffect(() => {
    try {
      if (signatureSaved) {
        updateSignature();
        setCanvasDialog(false);
        setBtnLoading(false);
      }
    } catch (e) {
      setBtnLoading(false);
    }
  }, [signatureSaved, updatedSignature]);

  const updateSignature = async () => {
    setDetails({
      ...details,
      signature: await getBase64FromUrl(updatedSignature),
    });
  };

  const setRef = (ref) => {
    if (ref && ref.fromDataURL) {
      ref.fromDataURL(details.signature);
      signaturePad = ref;
    }

    if (ref && ref.toDataURL) {
      ref.toDataURL();
      signaturePad = ref;
    }

    if (ref && ref.clear) {
      ref.clear();
      signaturePad = ref;
    }
  };

  const openSignatureCanvas = (appId) => {
    setCanvasDialog(true);
  };

  const closeCanvas = () => {
    setCanvasDialog(false);
  };

  const clearSignature = () => {
    if (signaturePad) {
      signaturePad.clear();
    }
  };

  const modifyUserSignature = () => {
    if (signaturePad) {
      if (signaturePad.isEmpty()) {
        AlertMsg(
          'error',
          'If you plan to add signature later then click on dark space!'
        );
      } else {
        setBtnLoading(true);
        const base64Img = signaturePad.toDataURL();
        setDetails({ ...details, signature: base64Img });
        const formData = new FormData();
        formData.append('signature', dataURLtoBlob(base64Img));
        saveClinicianSignature(formData);
      }
    }
  };

  const handleSwitchChange = (event, id) => {
    setActiveSpecialities({
      ...activeSpecialities,
      [id]: event.target.checked,
    });
  };

  const readFile = (file) => {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();

      reader.onload = function (event) {
        var _event$target;
        resolve(
          event === null || event === void 0
            ? void 0
            : (_event$target = event.target) === null ||
              _event$target === void 0
            ? void 0
            : _event$target.result
        );
      };

      reader.onerror = function (event) {
        reader.abort();
        reject(event);
      };
      reader.readAsDataURL(file);
    });
  };

  const getSpecialityMapList = () => {
    Axios.get(`${baseURL}/admin/speciality_map`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        setSpecialityList(res.data);
        let genInfo = res.data.filter((item) => item.title === 'GENERAL');

        setGenralInfo(genInfo.length > 0 ? genInfo[0] : null);
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const getAddressesLists = () => {
    Axios.get(`${baseURL}/api/clinic_addresses`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          setAddressesList(res.data.data);
        }
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const getClinicianDetails = () => {
    Axios.get(`${baseURL}/api/user/details`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then(async (res) => {
        if (res.data) {
          const base64Signature = res.data.signature
            ? await getBase64FromUrl(res.data.signature)
            : '';
          setProfileImage(res.data.avatar ? res.data.avatar : profile);
          setDetails({
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            email: res.data.email,
            clinicianId: res.data._id,
            address: res.data.address,
            phoneNumber: res.data.phoneNumber,
            phoneType: res.data.phoneType,
            patientAgeGroup: res.data.patientAgeGroup
              ? res.data.patientAgeGroup
              : '',
            bio: res.data.bio ? res.data.bio : '',
            discipline: res.data.discipline ? res.data.discipline : '',
            disciplineDescription: res.data.disciplineDescription,
            signature: base64Signature || '',
            autoSign: res.data.autoSign || false,
            prefix: res.data.prefix || '',
            suffix: res.data.suffix || '',
          });
          console.log('res.data', res.data);
          setPageLoader(false);
        }
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const getClinicianSpecialityMapping = () => {
    Axios.get(
      `${baseURL}/api/clinician_speciality_map/clinician/` +
        localStorage.getItem('clinicianUID'),
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        if (res.data.length) {
          let fieldSpec = { ...activeSpecialities };
          res.data.map((item) => {
            if (item.specialities && item.specialities.length) {
              for (let key in item.specialities) {
                if (item.specialities[key].speciality) {
                  let speciality = item.specialities[key].speciality;
                  if (
                    speciality.filter &&
                    speciality.filter.min === 0 &&
                    speciality.filter.max === 1
                  ) {
                    fieldSpec[speciality._id] =
                      item.specialities[key].value == '1' ? true : false;
                  } else {
                    fieldSpec[speciality._id] = item.specialities[key].value
                      ? parseFloat(item.specialities[key].value)
                      : '';
                  }
                }
              }
            }
          });
          setActiveSpecialities(fieldSpec);
        }
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  useEffect(() => {
    setPageLoader(true);
    getSpecialityMapList();
    getClinicianSpecialityMapping();
    getClinicianDetails();
    getAddressesLists();
  }, []);

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const documentUpload = async (selectedFiles) => {
    const formData = new FormData();
    formData.append('document', selectedFiles);
    setBtnLoading(true);
    Axios.post(`${baseURL}/api/user/upload?type=profileImage`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        setBtnLoading(false);

        localStorage.setItem('avatar', profileImage);
      })
      .catch((e) => {
        setBtnLoading(false);
        AlertMsg(
          'success',
          e.response ? e.response.message : 'Something went wrong!'
        );
      });
  };

  const handleImageUpload = async (files) => {
    if (files.length > 0) {
      await documentUpload(files[0]);
      var result = await readFile(files[0]);
      setProfileImage(result);
    }
  };

  const handleChangeGenralInfo = (evt, name) => {
    if (name === 'ageRange') {
      // console.log("evt.val", evt.target.value);
      let value = evt.target.value;
      if (evt.target.name === 'minAge') {
        // console.log("minAge");
        setDetails((preState) => {
          let newState = { ...preState };
          let ageRange = newState.patientAgeGroup;
          let newAgeRange = newState.patientAgeGroup;
          if (ageRange) {
            ageRange = ageRange.split('-');
            newAgeRange = `${value}-${ageRange.length > 1 ? ageRange[1] : '0'}`;
          } else {
            newAgeRange = `${value}-${
              genralInfo.specialities[0].filter > 1
                ? genralInfo.specialities[0].filter.max
                : '0'
            }`;
          }
          newState.patientAgeGroup = newAgeRange;
          return newState;
        });
      } else if (evt.target.name === 'maxAge') {
        // console.log("minAge");
        setDetails((preState) => {
          let newState = { ...preState };
          let ageRange = newState.patientAgeGroup;
          let newAgeRange = newState.patientAgeGroup;
          if (ageRange) {
            ageRange = ageRange.split('-');
            newAgeRange = `${ageRange.length > 0 ? ageRange[0] : '0'}-${value}`;
          } else {
            newAgeRange = `${
              genralInfo.specialities[0].filter > 1
                ? genralInfo.specialities[0].filter.min
                : '0'
            }-${value}`;
          }
          newState.patientAgeGroup = newAgeRange;
          return newState;
        });
      }
    } else {
      setDetails({ ...details, [name]: evt.target.value });
    }
  };

  const handleInputChange = (event, spec) => {
    if (
      spec.filter &&
      event.target.value >= spec.filter.min &&
      event.target.value <= spec.filter.max
    ) {
      setActiveSpecialities({
        ...activeSpecialities,
        [spec._id]: event.target.value,
      });
    }
    return null;
  };

  const saveSpecialityDetails = () => {
    let body = { specialities: [] };
    for (const [key, value] of Object.entries(activeSpecialities)) {
      body.specialities.push({
        speciality: key,
        value: typeof value === 'boolean' ? (value ? '1' : '0') : value,
      });
    }
    Axios.post(
      `${baseURL}/api/clinician_speciality_map/` + details.clinicianId,
      body,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        saveClinicianInfo();
      })
      .catch((e) => {
        console.log('e.response: ', e.response);
        AlertMsg(
          'success',
          e.response ? e.response.message : 'Something went wrong!'
        );
      });
  };

  const saveClinicianInfo = () => {
    let body = {
      ...details,
    };

    if (avatar) {
      body.avatar = avatar;
    }

    delete body.clinicianId;

    Axios.put(`${baseURL}/api/user/`, body, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        localStorage.setItem('avatar', res.data.avatar);
        localStorage.setItem('fName', body.firstName);
        localStorage.setItem('lName', body.lastName);
        setBtnLoading(false);
        AlertMsg('success', 'Your record update successfully.');
        // TODO Handle data reload properly
        window.location.reload(false);
      })
      .catch((e) => {
        setBtnLoading(false);
        AlertMsg(
          'success',
          e.response ? e.response.message : 'Something went wrong!'
        );
      });
  };

  const handleSaveDetails = () => {
    setBtnLoading(true);
    saveSpecialityDetails();
  };

  const handleInfoChange = (event) => {
    const { value, name } = event.target;
    const updatePath = name.split('.');
    if (updatePath.length > 1) {
      setDetails((prevState) => {
        let newState = { ...prevState };
        newState.address = { ...newState.address, [updatePath[1]]: value };
        return newState;
      });
    } else if (name === 'autoSign') {
      setDetails({ ...details, [name]: !details.autoSign });
    } else {
      setDetails({ ...details, [name]: value });
    }
  };

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const dataURLtoBlob = (dataurl) => {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const addAddress = (addressId) => {
    if (!details.address.includes(addressId)) {
      setDetails({ ...details, address: [...details.address, addressId] });
    } else if (details.address.includes(addressId)) {
      let updatedAddress = details.address.filter((ad) => ad !== addressId);
      setDetails({ ...details, address: updatedAddress });
    }
  };

  return (
    <Layout updatedAvatar={profileImage}>
      {pageLoader ? (
        <Grid container style={{ paddingLeft: '45%', paddingTop: '10%' }}>
          <Loader />
        </Grid>
      ) : (
        <>
          <Grid container spacing={3} className={classes.infoMain}>
            <Spinner isOpen={btnLoading} />
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Typography className={classes.inputText}>
                  Basic Information
                </Typography>
                <Typography className={classes.inputTitle}>
                  Profile Image*
                </Typography>
                <Grid
                  container
                  style={{ marginBottom: '15px', width: '150px' }}
                >
                  <ImageUploader
                    src={profileImage}
                    uploadedImage={handleImageUpload}
                  />
                </Grid>
                <Grid container>
                  <Grid item xs={12} container>
                    <Grid item xs={4} style={{ paddingRight: '10px' }}>
                      <Typography className={classes.inputTitle}>
                        Prefix
                      </Typography>
                      <FormControl className={classes.inputsty}>
                        <Select
                          name='prefix'
                          value={details.prefix}
                          onChange={handleInfoChange}
                        >
                          <MenuItem value=''>Select</MenuItem>
                          <MenuItem value='Dr.'>Dr</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography className={classes.inputTitle}>
                        First Name*
                      </Typography>
                      <Input
                        className={classes.inputsty}
                        type='text'
                        value={details.firstName}
                        onChange={handleInfoChange}
                        name='firstName'
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container>
                    <Grid item xs={8}>
                      <Typography className={classes.inputTitle}>
                        Last Name*
                      </Typography>
                      <Input
                        className={classes.inputsty}
                        type='text'
                        value={details.lastName}
                        onChange={handleInfoChange}
                        name='lastName'
                      />
                    </Grid>
                    <Grid style={{ paddingLeft: '10px' }} item xs={4}>
                      <Typography className={classes.inputTitle}>
                        Suffix
                      </Typography>
                      <Input
                        className={classes.inputsty}
                        type='text'
                        value={details.suffix}
                        onChange={handleInfoChange}
                        name='suffix'
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography className={classes.inputTitle}>
                      Email
                    </Typography>
                    <Input
                      className={classes.inputsty}
                      type='email'
                      name='email'
                      value={details.email}
                      readOnly={true}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Typography className={classes.inputText}>
                  Contact Information
                </Typography>
                <Grid container style={{ marginBottom: '15px' }}>
                  <Grid item xs={6} style={{ paddingRight: '10px' }}>
                    <Typography className={classes.inputTitle}>
                      Phone Number*
                    </Typography>
                    <input
                      className={classes.inputsty}
                      type='text'
                      name='phoneNumber'
                      value={details.phoneNumber}
                      onChange={handleInfoChange}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: '10px' }}>
                    <Typography className={classes.inputTitle}>
                      Type*
                    </Typography>
                    <FormControl className={classes.inputsty}>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        name='phoneType'
                        value={details.phoneType}
                        onChange={handleInfoChange}
                      >
                        <MenuItem value={''}>Select</MenuItem>
                        <MenuItem value={'mobile'}>Mobile</MenuItem>
                        <MenuItem value={'phone'}>Phone</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid style={{ marginBottom: '15px' }}>
                  <Typography className={classes.inputTitle}>
                    Address*
                  </Typography>
                  {/*details.address &&
                    details.address?.map((addressId) => {

                      const address = addressesList.find(
                        (al) => al._id === addressId
                      );
                      return address ? <div>{address.title}</div> : '';
                    })*/}
                  {addressesList.map((address) => (
                    <div>
                      <input
                        type='checkbox'
                        value={address._id}
                        onClick={() => addAddress(address._id)}
                        checked={details.address.includes(address._id)}
                      />
                      <label className='ml-2'>
                        {address.title ? address.title : address.address1}
                      </label>
                    </div>
                  ))}
                </Grid>
              </Paper>
            </Grid>
            {genralInfo && (
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography className={classes.inputText}>
                    General Information
                  </Typography>
                  <Grid container>
                    <Grid item xs={6} style={{ padding: '0 15px 0 0' }}>
                      <Grid style={{ marginBottom: '15px' }}>
                        <Typography className={classes.inputTitle}>
                          Discipline*
                        </Typography>
                        <FormControl className={classes.inputsty}>
                          <Select
                            labelId='demo-simple-select-label'
                            value={details.discipline}
                            name='discipline'
                            onChange={(evt) => handleInfoChange(evt)}
                            id='demo-simple-selectq'
                          >
                            <MenuItem value={''}>Select</MenuItem>
                            {/* {genralInfo} */}
                            {Object.keys(DISCIPLINES).map((k) => (
                              <MenuItem key={k} value={k}>
                                {DISCIPLINES[k]}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid style={{ marginBottom: '15px' }}>
                        <Typography className={classes.inputTitle}>
                          Description
                        </Typography>
                        <Input
                          className={classes.inputsty}
                          type='text'
                          name='disciplineDescription'
                          onChange={handleInfoChange}
                          value={details.disciplineDescription}
                        />
                      </Grid>
                      <Grid style={{ marginBottom: '15px' }}>
                        <Typography className={classes.inputTitle}>
                          Patient Age Range
                        </Typography>
                        <Grid className={classes.rangeField}>
                          <FormControl style={{ width: '100%' }}>
                            <TextField
                              className={classes.inputsty}
                              type='text'
                              name='minAge'
                              // InputProps={{
                              //   min: parseInt(
                              //     genralInfo.specialities[0].filter.min
                              //   ),
                              //   max: parseInt(
                              //     genralInfo.specialities[0].filter.max
                              //   ),
                              // }}
                              // value={
                              //   details.patientAgeGroup &&
                              //   details.patientAgeGroup.split('-')[0]
                              //     ? details.patientAgeGroup.split('-')[0]
                              //     : ''
                              // }
                              onChange={(evt) =>
                                handleChangeGenralInfo(evt, 'ageRange')
                              }
                            />
                          </FormControl>
                          <Typography component='span'>To</Typography>
                          <FormControl style={{ width: '100%' }}>
                            <TextField
                              className={classes.inputsty}
                              type='text'
                              name='maxAge'
                              // InputProps={{
                              //   min: genralInfo.specialities[0].filter.min,
                              //   max: genralInfo.specialities[0].filter.max,
                              // }}
                              // value={
                              //   details.patientAgeGroup
                              //     ? details.patientAgeGroup.split('-')[1]
                              //     : ''
                              // }
                              onChange={(evt) =>
                                handleChangeGenralInfo(evt, 'ageRange')
                              }
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} style={{ marginBottom: '15px' }}>
                      <Paper className={classes.paper}>
                        <Typography className={classes.inputText}>
                          Your Digital Signature
                        </Typography>
                        <Grid
                          container
                          alignItems='flex-start'
                          direction='column'
                        >
                          <Grid style={{ marginBottom: '15px' }} item>
                            <Typography className={classes.inputTitle}>
                              Signature
                            </Typography>
                          </Grid>
                          <Grid
                            style={{
                              border: '1px solid #ccc',
                              padding: '20px',

                              height: '150px',
                            }}
                            justify='center'
                            alignItems='center'
                            container
                            item
                          >
                            {details.signature && (
                              <img
                                src={details.signature}
                                width='300px'
                                height='100px'
                              />
                            )}
                          </Grid>
                          <Grid
                            justify='space-between'
                            alignItems='center'
                            wrap='nowrap'
                            container
                            item
                          >
                            <Grid item>
                              <Button
                                onClick={() => setCanvasDialog(true)}
                                variant={
                                  details.signature ? 'outlined' : 'contained'
                                }
                                style={{
                                  backgroundColor: details.signature
                                    ? '#fff'
                                    : '#1964EB',
                                  color: details.signature ? '#1964EB' : '#fff',
                                  border: details.signature
                                    ? '1px solid #1964EB'
                                    : 'none',
                                  marginTop: '15px',
                                  whiteSpace: 'nowrap',
                                }}
                                color='primary'
                              >
                                {details.signature
                                  ? 'Edit Signature'
                                  : 'Create Signature'}
                              </Button>
                            </Grid>
                            <Grid
                              justify='flex-end'
                              alignItems='center'
                              spacing={2}
                              container
                              item
                            >
                              <Grid item>Auto-sign content forms?</Grid>
                              <Grid item>
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                      name='autoSign'
                                      checked={details.autoSign}
                                      onChange={handleInfoChange}
                                    />
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>

                    {false && (
                      <Grid item xs={6} style={{ padding: '0 0 0 15px' }}>
                        <Grid style={{ marginBottom: '15px' }}>
                          <Typography className={classes.inputTitle}>
                            Personal Biography
                          </Typography>
                          <FormControl style={{ width: '100%' }}>
                            <TextareaAutosize
                              name='bio'
                              rowsMin={7}
                              placeholder='Biography'
                              className={classes.textareaField}
                              value={details.bio}
                              onChange={handleInfoChange}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Grid>
          <Grid container style={{ marginTop: '20px' }}>
            {specialityList.length > 0 &&
              specialityList.map((item, index) =>
                item.title !== 'GENERAL' ? (
                  <Grid className={classes.cardOuter} key={index}>
                    <h4>{item.title}</h4>
                    {item.specialities &&
                      item.specialities.length > 0 &&
                      item.specialities.map((spec) => {
                        return (
                          <React.Fragment key={spec._id}>
                            <Grid
                              className='innerCells'
                              key={`inn_${spec._id}`}
                            >
                              <h5>{spec.description}</h5>
                              {(spec.filter &&
                                spec.filter.min === 0 &&
                                spec.filter.max === 1) ||
                              !spec.filter ? (
                                <span
                                  className={
                                    (classes.switches, classes.switchBtn)
                                  }
                                >
                                  <Typography component='label'>Yes</Typography>
                                  <FormControlLabel
                                    control={
                                      <IOSSwitch
                                        checked={
                                          activeSpecialities[spec._id]
                                            ? true
                                            : false
                                        }
                                        onChange={(evt) =>
                                          handleSwitchChange(evt, spec._id)
                                        }
                                      />
                                    }
                                  />
                                  <Typography component='label'>No</Typography>
                                </span>
                              ) : null}
                              {spec.filter && spec.filter.max > 1 && (
                                <span className={classes.switches}>
                                  <TextField
                                    id='outlined-basic'
                                    value={
                                      activeSpecialities[spec._id]
                                        ? activeSpecialities[spec._id]
                                        : ''
                                    }
                                    onChange={(evt) =>
                                      handleInputChange(evt, spec)
                                    }
                                    className={classes.inputs}
                                    variant='outlined'
                                    InputProps={{
                                      min: spec.filter.min,
                                      max: spec.filter.max,
                                    }}
                                  />
                                </span>
                              )}
                            </Grid>
                          </React.Fragment>
                        );
                      })}
                  </Grid>
                ) : null
              )}
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={btnLoading}
              style={{ float: 'right', marginRight: 35 }}
              variant='contained'
              color='primary'
              onClick={handleSaveDetails}
            >
              {btnLoading ? (
                <CircularProgress size={20} color={'white'} />
              ) : (
                'Submit'
              )}
            </Button>
          </Grid>
        </>
      )}

      <CanvasDialog
        open={openCanvasDialog}
        onClose={closeCanvas}
        ref={signaturePad}
        signature={details.signature}
        setRef={setRef}
        clear={clearSignature}
        modifyUserSignature={modifyUserSignature}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  signatureSaved: state.saveClinicianSignature.signatureSaved,
  updatedSignature: state.saveClinicianSignature.updatedSignature,
});

const mapDispatchToProps = (dispatch) => ({
  saveClinicianSignature: (signature) =>
    dispatch(saveClinicianSignature(signature)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingClinician);
