import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

const Container = styled(Grid)`
  padding: 20px;
`;

const Heading = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  font-family: Lato;
`;

const Row = styled(Grid)`
  margin-top: 10px;
`;

const BottomBtn = styled(Grid)`
  margin-top: 10px;
`;

export { Container, Heading, Row, BottomBtn };
