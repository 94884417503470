import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { DrawerBoxImg } from './DrawerImage';
import { DrawerNameFont, DrawerReferredFont } from './DrawerFont';
import date from '../../../assets/user_images/date.svg';
import clock from '../../../assets/user_images/time.svg';
import map from '../../../assets/user_images/map_icon.svg';
import { DrawerColorButtons, DrawerSimpleButtons } from './CustomButton';
import bigImage from '../../../assets/imgs/ananyaBig.png';
import dayjs from 'dayjs';
import logo1 from '../../../assets/imgs/download.png';
import styles from './DrawerBox_style';
import { customDateFormat } from '../../../utils/timUtils';
import { getClinicialAddressById } from '../../../utils/actions/clinician_address.action';

const DrawerBoxComponent = (props) => {
  const {
    data,
    handleCancelAppointment,
    handleRescheduleAppointment,
    completeAddress,
    getClinicialAddressById,
  } = props;

  useEffect(() => {
    if (data && data.clinicianId && data.clinicianId.address) {
      getClinicialAddressById(data.clinicianId.address);
    }
  }, [data]);

  const classes = styles();
  return (
    <Grid
      container
      className={classes.root}
      alignItems="center"
      justify="flex-start"
      {...props}
    >
      <Grid container className={classes.boxContainer}>
        <Grid container spacing={0} style={{ flex: 1, float: 'left' }}>
          <Grid item xs style={{ display: 'flex' }}>
            <DrawerBoxImg
              logo={
                data.clinicianId && data.clinicianId.avatar
                  ? data.clinicianId.avatar
                  : logo1
              }
            />
            <Grid component="div">
              <DrawerNameFont
                text={
                  data.clinicianId
                    ? `Dr ${data.clinicianId.firstName} ${data.clinicianId.lastName}`
                    : '--'
                }
                style={{
                  marginLeft: '15px',
                  width: '148px',
                  whiteSpace: 'nowrap',
                  display: 'inline',
                }}
              />
              <Grid style={{ display: 'flex' }}>
                <img
                  src={date}
                  display="inline"
                  className={classes.clockicon}
                />
                <DrawerReferredFont
                  display="inline"
                  text={dayjs(data.startTime).format('DD/MM/YYYY')}
                  style={{
                    width: '72px',
                    marginLeft: '11px',
                    marginTop: '5px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                />
              </Grid>
              <Grid style={{ display: 'flex' }}>
                <img
                  src={clock}
                  display="inline"
                  className={classes.clockicon}
                />
                <DrawerReferredFont
                  display="inline"
                  text={`${customDateFormat(new Date(data.startTime))} for ${
                    data.duration
                  } min.`}
                  style={{
                    width: '121px',
                    marginLeft: '11px',
                    marginTop: '5px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                />
              </Grid>
              <Grid style={{ display: 'flex' }}>
                <img src={map} display="inline" className={classes.clockicon} />
                <DrawerReferredFont
                  display="inline"
                  text={completeAddress ? completeAddress : '--'}
                  style={{
                    width: '212px',
                    marginLeft: '11px',
                    marginTop: '5px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs>
            <Grid component="div" />
          </Grid>

          <Grid item xs style={{ display: 'flex' }}>
            <DrawerBoxImg
              logo={
                data.clientId && data.clientId.length
                  ? data.clientId[0].avatar
                  : bigImage
              }
            />
            <Grid component="div">
              <DrawerNameFont
                text="You"
                style={{
                  marginLeft: '15px',
                  width: '148px',
                  whiteSpace: 'nowrap',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid component="div" style={{ paddingLeft: '20px', display: 'flex' }}>
          <Grid item xs>
            <DrawerColorButtons
              className={classes.pointerNone}
              width="136px"
              title="confirmed"
            />
          </Grid>
          <Grid item xs style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <DrawerSimpleButtons
              width="108px"
              title="Reschedule"
              style={classes.rescheduleButton}
              onClick={() => handleRescheduleAppointment(data._id)}
              {...props}
            />
            <DrawerSimpleButtons
              width="79px"
              title="cancel"
              onClick={() => handleCancelAppointment(data._id)}
              style={{ marginLeft: '20px' }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  completeAddress: state.ClinicianAddressReducer.address,
});

const DrawerBox = connect(mapStateToProps, { getClinicialAddressById })(
  DrawerBoxComponent
);

DrawerBoxComponent.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    clinicianId: PropTypes.shape({
      address: PropTypes.string,
      avatar: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }).isRequired,
    duration: PropTypes.number,
    startTime: PropTypes.string,
    clientId: PropTypes.array,
  }).isRequired,
  handleCancelAppointment: PropTypes.func.isRequired,
  handleRescheduleAppointment: PropTypes.func.isRequired,
  completeAddress: PropTypes.string.isRequired,
  getClinicialAddressById: PropTypes.func.isRequired,
};

export { DrawerBox };
