import styled from 'styled-components';

export default styled.div`
  table {
    width: ${(props) => props.width}px !important;
  }

  .fc-timegrid-body {
    width: ${(props) => props.width}px !important;
  }

  .fc .fc-view-harness-active > .fc-view {
    overflow: scroll !important;
  }

  table.fc-scrollgrid {
    width: 100%;
  }

  .fc-timegrid-event-harness-inset {
    inset-inline-start: 0% !important;
    inset-inline-end: 0% !important;
  }

  .fc-timegrid-event-harness {
    margin-right: 0px !important;
  }

  .tippy-content {
    padding: 0px;
  }

  .fc-view-harness{
    overflow: auto;
  }

`;
