import {
  CREATE_BUNDLE_REQUEST,
  CREATE_BUNDLE_FAILED,
  CREATE_BUNDLE_SUCCESS,
  GET_BUNDLES_REQUEST,
  GET_BUNDLES_FAILED,
  GET_BUNDLES_SUCCESS,
  UPDATE_BUNDLE_REQUEST,
  UPDATE_BUNDLE_FAILED,
  UPDATE_BUNDLE_SUCCESS,
  CANCEL_BUNDLE_REQUEST,
  CANCEL_BUNDLE_FAILED,
  CANCEL_BUNDLE_SUCCESS,
  GET_CLIENT_BUNDLES_REQUEST,
  GET_CLIENT_BUNDLES_FAILED,
  GET_CLIENT_BUNDLES_SUCCESS,
} from '../constant';

export const createBundle = (state = [], action) => {
  switch (action.type) {
    case CREATE_BUNDLE_REQUEST:
      return { request: true };
    case CREATE_BUNDLE_SUCCESS:
      return {
        request: false,
        createdSuccessfully: true,
        newlyCreated: action.response[0],
      };
    case CREATE_BUNDLE_FAILED:
      return { request: false, createdSuccessfully: false };
    default:
      return state;
  }
};

export const getBundles = (state = [], action) => {
  switch (action.type) {
    case GET_BUNDLES_REQUEST:
      return { request: true };
    case GET_BUNDLES_SUCCESS:
      return {
        request: false,
        bundles: action.response[0].data,
        count: action.response[0].count[0].count,
      };
    case GET_BUNDLES_FAILED:
      return { request: false, error: true, bundles: [] };
    default:
      return state;
  }
};

export const modifyBundles = (state = [], action) => {
  switch (action.type) {
    case UPDATE_BUNDLE_REQUEST:
      return { request: true };
    case UPDATE_BUNDLE_SUCCESS:
      return {
        request: false,
        updatedSuccessfully: true,
        modifiedBundle: action.response[0],
      };
    case UPDATE_BUNDLE_FAILED:
      return { request: false, updatedSuccessfully: false };
    default:
      return state;
  }
};

export const cancellingBundle = (state = [], action) => {
  switch (action.type) {
    case CANCEL_BUNDLE_REQUEST:
      return { request: true };
    case CANCEL_BUNDLE_SUCCESS:
      return {
        request: false,
        cancelledSuccessfully: true,
      };
    case CANCEL_BUNDLE_FAILED:
      return { request: false, cancelledSuccessfully: false };
    default:
      return state;
  }
};

export const getClientBundles = (state = [], action) => {
  switch (action.type) {
    case GET_CLIENT_BUNDLES_REQUEST:
      return { request: true };
    case GET_CLIENT_BUNDLES_SUCCESS:
      return {
        request: false,
        bundles: action.response[0].data,
        count: action.response[0].count[0].count,
      };
    case GET_CLIENT_BUNDLES_FAILED:
      return { request: false, error: true, bundles: [] };
    default:
      return state;
  }
};
