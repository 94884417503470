import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCompanyById } from '../../../utils/actions/company.action';
import { Events, EventFilters } from '../../../components/molecules';
import Axios from 'axios';
import { Grid } from '@material-ui/core';
import { baseURL } from '../../../utils/axios';
import AlertMsg from '../../../utils/Alert';
import { Img, ImgGrid, FilterGrid } from './style';

const CorporateEvents = (props) => {
  const [isLoaded, setIsloaded] = React.useState(false);
  const [eventLists, setEventLists] = useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [status, setStatus] = React.useState('upcoming');
  const [rspvOpen, setRspvOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [searchText, setSearchText] = useState('');
  const [sortType, setSortType] = useState('desc');
  const [sortBy, setSortBy] = useState('');
  const [corporationId, setCorporationId] = useState(null);

  useEffect(() => {
    setCorporationId(props.match.params.corporationId);
    props.getCompanyById(props.match.params.corporationId);
  }, []);

  useEffect(() => {
    setIsloaded(true);
    getEventLists();
  }, [page, sortType, sortBy, status, corporationId, searchText]);

  const handleChange = (event, value) => {
    let val = value.props.value;
    setStatus(val);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getEventLists = () => {
    Axios.get(
      `${baseURL}/api/event?corporationId=${corporationId}&page=${page}&term=${searchText}&status=${status}&sortType=${sortType}&sortBy=${sortBy}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        setIsloaded(false);
        if (res.data) {
          setEventLists(res.data.events);
          setTotalCount(res.data.total);
        }
      })
      .catch((e) => {
        AlertMsg('error', e.toString());
      });
  };

  const sort = (key) => {
    const toggleType = sortType === 'asc' ? 'desc' : 'asc';
    setSortType(toggleType);
    setSortBy(key);
  };

  const onTextSearch = (e) => setSearchText(e.target.value);

  return (
    <Grid direction='column' alignItems='flex-start' container>
      <FilterGrid container item>
        <EventFilters onTextSearch={onTextSearch} handleChange={handleChange} />
      </FilterGrid>
      <ImgGrid item>
        {props.fetchedCompany && <Img src={props.fetchedCompany.logo} />}
      </ImgGrid>
      <Grid container item>
        <Events
          {...props}
          handleChange={handleChange}
          sort={sort}
          handlePageChange={handlePageChange}
          eventLists={eventLists}
          totalCount={totalCount}
          isLoaded={isLoaded}
          onTextSearch={onTextSearch}
          page={page}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  fetchedCompany: state.CompanyReducer.company,
});

export default connect(mapStateToProps, { getCompanyById })(CorporateEvents);
