/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import { TextField, CircularProgress } from '@material-ui/core';
import { baseURL } from '../utils/axios';
import AlertMsg from '../utils/Alert';
import Axios from 'axios';
import styles, { AutoComplete } from './CreateNewAppointment.styles';

export default function ClinicianSearchView(props) {
  const classes = styles();
  const [searchText, setSearchText] = useState('');
  const [clinicians, setClinicians] = useState([]);
  const [selectedClinician, setSlectedClinician] = useState({});
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    filterClinicians();
  }, [searchText]);

  useEffect(() => {
    if (props.appointmentDetail && props.appointmentDetail.clinicianId) {
      setSlectedClinician(props.appointmentDetail.clinicianId);
    }
  }, [props.appointmentDetail.clinicianId]);

  useEffect(() => {
    if (props.clinicians) {
      if (
        (props.recommendedClinicians &&
          props.recommendedClinicians.length > 0) ||
        props.preferredClinician
      ) {
        const recommendedClinicianIds = props.recommendedClinicians.map(
          (p) => p._id
        );

        let recommendedClinicians = props.clinicians.filter((c) =>
          recommendedClinicianIds.includes(c._id)
        );

        if (props.displayOnlyRecommended) {
          setClinicians([props.preferredClinician, ...recommendedClinicians]);
        } else {
          console.log('recommendedClinicianIds', recommendedClinicianIds);
          let otherClinicians = props.clinicians.filter(
            (c) =>
              !recommendedClinicianIds.includes(c._id) &&
              c._id !== props.preferredClinician?._id
          );

          recommendedClinicians = recommendedClinicians.map((item) => {
            return { ...item, isRecommeded: true };
          });
          if (props.preferredClinician) {
            recommendedClinicians = [
              { ...props.preferredClinician, isPreferred: true },
              ...recommendedClinicians,
            ];
          }

          setClinicians([...recommendedClinicians, ...otherClinicians]);
        }
      } else {
        setClinicians(props.clinicians);
      }
    }
  }, [props.clinicians]);

  const filterClinicians = () => {};
  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const additionalFlag = (o) => {
    let flag = '';

    if (o.isRecommeded) {
      flag = ' - Matched';
    }

    if (o.isPreferred) {
      flag = ' - Preferred';
    }
    return flag;
  };
  return (
    <div style={{ width: '100%' }}>
      <AutoComplete
        style={{
          display: 'block',
          width: '100%',
        }}
        id='tags-standard'
        options={clinicians}
        onChange={(event, newValue) => {
          if (props.onChange) {
            props.onChange(newValue);
          }
          setSlectedClinician(newValue);
        }}
        getOptionLabel={(option) =>
          option.firstName != undefined
            ? option.firstName + ' ' + option.lastName + additionalFlag(option)
            : ''
        }
        getOptionSelected={(option, value) => option._id === value._id}
        value={selectedClinician}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={handleChange}
            placeholder='Select Doctors'
            variant='outlined'
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {!props.clinicians ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
}
