import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  root: {
    padding: '20px',
    marginLeft: 250,
    marginTop: 70,
  },
  flexStyle: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  title: {
    width: 'auto',
    float: 'left',
    fontSize: '20px',
    lineHeight: '37px',
    fontWeight: 400,
    textTransform: 'capitalize',
    color: '#003d79',
  },
  driveIcon: {
    width: '26px',
    height: '26px',
    fontWeight: 'normal !important',
    left: '8px',
    // top: '50%',
    color: '#999',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: '20px',
  },
  contentSty: {
    cursor: 'pointer',
    fontWeight: 'bold !important',
    lineHeight: '1.4em',
    fontSize: '13px',
    display: 'flex',
    justifyContent: 'flex-start',
    color: '#003d79',
    marginTop: '5px',
    textAlign: 'left',
  },
  progressSty: {
    textDecoration: 'none',
    cursor: 'pointer',
    lineHeight: '1.4em',
    fontSize: '13px',
    color: '#a29061',
    textAlign: 'left',
    paddingLeft: '110px',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  progSty: {
    textDecoration: 'none',
    cursor: 'pointer',
    lineHeight: '1.4em',
    fontSize: '13px',
    color: '#a29061',
    textAlign: 'left',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  textsty: {
    cursor: 'default',
    lineHeight: '1.4em',
    fontSize: '13px',
    color: '#003d79',
    textAlign: 'left',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  gridsty: {
    textAlign: 'left',
  },
  dateSty: {
    right: 0,
    font: ['11px Georgia, "Times New Roman", Serif'].join(','),
    textTransform: 'uppercase',
    justifyContent: 'flex-end',
    display: 'flex',
    textAlign: 'right',
    marginTop: '5px',
  },
  divider: {
    width: '98%',
    marginTop: '10px',
    marginBottom: '15px',
    marginRight: '15px',
    // backgroundColor: '#a29061',
  },
  subdivider: {
    width: '94%',
    marginTop: '10px',
    marginBottom: '15px',
    marginRight: '15px',
    // backgroundColor: '#a29061',
  },
});
