import React from 'react';

const InvoiceListPagination = ({
  totalPages,
  page,
  totalRecords,
  handlePageChange,
  limit = 10,
}) => (
  <nav className='bg-white px-4 py-3 flex items-center justify-between border border-r-0 border-gray-100' style={{
    height: "64px"
  }}>
    <div className='hidden sm:block'>
      <p className='text-xs text-gray-700'>
        Showing
        <span className='font-medium'> {page * limit + 1 - limit} </span>
        to
        <span className='font-medium'> {page * limit} </span>
        of
        <span className='font-medium'> {totalRecords} </span>
        results
      </p>
    </div>
    <div className='flex-1 flex justify-between sm:justify-end'>
      <button
        disabled={page === 1}
        onClick={() => handlePageChange(page - 1)}
        className='relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-brand-blue hover:text-white'
      >
        Previous
      </button>
      <button
        disabled={page === totalPages}
        onClick={() => handlePageChange(page + 1)}
        className='ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-brand-blue hover:text-white'
      >
        Next
      </button>
    </div>
  </nav>
);

export default InvoiceListPagination;
