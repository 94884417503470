/* eslint-disable max-len */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import {
  Grid,
  Typography,
  withStyles,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Collapse,
} from '@material-ui/core';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
// import en from 'date-fns/locale/en';
import 'react-datepicker/dist/react-datepicker.css';
import PhoneDisabledOutlinedIcon from '@material-ui/icons/PhoneDisabledOutlined';
import MobileOffIcon from '@material-ui/icons/MobileOff';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './OverviewBoxSectionStyle';
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

// registerLocale('en', en);

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

class OverViewBoxes extends Component {
  state = {
    selectedOption: null,
    startDate: new Date(),
    expandOpen: true,
    expandAddOpen: true,
    expandClientInfoOpen: true,
    expandContactEditOpen: true,
    expandUpcomingOpen: true,
    expandCommOpen: true,
    expandShareOpne: true,
    expandUploadOpen: true,
    expandInvoicesOpne: true,
    expandDocumentsOpen: true,
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleDateChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  render() {
    const { classes } = this.props;
    const { selectedOption } = this.state;

    return (
      <div>
        <div className={classes.boxsty}>
          <Grid container spacing={2}>
            <Grid item xs={10} className={classes.gridbox}>
              <Typography className={classes.boxheading}>
                Billing Overview
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {this.state.expandOpen ? (
                <ExpandLessIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({ expandOpen: !this.state.expandOpen })}
                />
              ) : (
                <ExpandMoreIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({ expandOpen: !this.state.expandOpen })}
                />
              )}
            </Grid>
          </Grid>

          <Collapse in={this.state.expandOpen} timeout="auto" unmountOnExit>
            <Grid container spacing={2} className={classes.gridsty}>
              <Grid item xs={9} className={classes.gridbox}>
                <Typography className={classes.balanceSty}>
                  Client Balance
                </Typography>
              </Grid>
              <Grid item xs={3} className={classes.buttonGrid}>
                <Typography className={classes.balanceNum}>$400</Typography>
              </Grid>

              <Grid item xs={9} className={classes.gridbox}>
                <Typography className={classes.textSty}>Uninvoiced</Typography>
              </Grid>
              <Grid item xs={3} className={classes.numsty}>
                <Typography>$0</Typography>
              </Grid>

              <Grid item xs={9} className={classes.gridbox}>
                <Typography className={classes.textSty}>
                  Client Payments
                </Typography>
              </Grid>
              <Grid item xs={3} className={classes.numsty}>
                <Typography>$100</Typography>
              </Grid>

              <Grid item xs={10} className={classes.gridbox}>
                <Typography className={classes.balanceSty}>
                  Appointment Status Report
                </Typography>
              </Grid>
            </Grid>
          </Collapse>
        </div>
        <br />

        {/* --------------add Payment section start--------------------*/}

        <div className={classes.boxsty}>
          <Grid container spacing={2} style={{ paddingBottom: '10px' }}>
            <Grid item xs={10} className={classes.gridbox}>
              <Typography className={classes.boxheading}>
                ADD PAYMENT
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {this.state.expandAddOpen ? (
                <ExpandLessIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({ expandAddOpen: !this.state.expandAddOpen })}
                />
              ) : (
                <ExpandMoreIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({ expandAddOpen: !this.state.expandAddOpen })}
                />
              )}
            </Grid>
            <Collapse
              in={this.state.expandAddOpen}
              timeout="auto"
              unmountOnExit
            >
              <RadioGroup style={{ paddingLeft: '10px' }}>
                <FormControlLabel
                  value="best"
                  control={<Radio color="primary" />}
                  label="$400: Balance"
                  style={{ color: '#003d79' }}
                />
                <FormControlLabel
                  value="worst"
                  control={<Radio color="primary" />}
                  label="Other Amount"
                  style={{ color: '#003d79' }}
                />
              </RadioGroup>

              <Grid container spacing={2}>
                <Grid item xs={5} className={classes.gridbox}>
                  <Select
                    value={selectedOption}
                    onChange={this.handleChange}
                    options={options}
                  />
                </Grid>
                <Grid item xs={7}>
                  <DatePicker
                    locale='en'
                    selected={this.state.startDate}
                    onChange={this.handleChange}
                    className={classes.dateInput}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button className={classes.addPaymentButton}>
                    Add Payment
                  </Button>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </div>
        <br />

        {/* --------------CLIENT INFO Edit start--------------------*/}

        <div className={classes.boxsty}>
          <Grid container spacing={4} style={{ paddingBottom: '10px' }}>
            <Grid item xs={10} className={classes.gridbox}>
              <Typography className={classes.boxheading}>
                CLIENT INFO Edit
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {this.state.expandClientInfoOpen ? (
                <ExpandLessIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({
                    expandClientInfoOpen: !this.state.expandClientInfoOpen,
                  })}
                />
              ) : (
                <ExpandMoreIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({
                    expandClientInfoOpen: !this.state.expandClientInfoOpen,
                  })}
                />
              )}
            </Grid>
          </Grid>

          <Collapse
            in={this.state.expandClientInfoOpen}
            timeout="auto"
            unmountOnExit
          >
            <Grid container spacing={2} className={classes.gridsty}>
              <Grid item xs={4} className={classes.gridbox}>
                <Typography className={classes.boxheading}>Phone</Typography>
              </Grid>
              <Grid item xs={8} className={classes.gridbox}>
                <Typography className={classes.phoneNum}>
                  (942) 460-1184
                </Typography>
                <Typography display="inline" className={classes.mobileSty}>
                  Mobile
                  {' '}
                </Typography>
                <PhoneDisabledOutlinedIcon
                  display="inline"
                  className={classes.iconSty}
                />
                <MobileOffIcon className={classes.iconSty} />
              </Grid>

              <Grid item xs={4} className={classes.gridbox}>
                <Typography className={classes.boxheading}>Email</Typography>
              </Grid>
              <Grid item xs={8} className={classes.gridbox}>
                <Typography numberOfLines={1} className={classes.phoneNum}>
                  akshaymahajan28@yahoo.com
                </Typography>
                <Typography display="inline" className={classes.mobileSty}>
                  Work
                  {' '}
                </Typography>
                <MailOutlineOutlinedIcon
                  display="inline"
                  className={classes.iconSty}
                />
              </Grid>

              <Grid item xs={4} className={classes.gridbox}>
                <Typography className={classes.boxheading}>addr.</Typography>
              </Grid>
              <Grid item xs={8} className={classes.gridbox}>
                <Typography className={classes.phoneNum}>
                  123 Main Street Anytown, CA 12345
                </Typography>
              </Grid>
            </Grid>
          </Collapse>
        </div>
        <br />

        {/* --------------CONTACTS Edit start--------------------*/}

        <div className={classes.boxsty}>
          <Grid container spacing={4} style={{ paddingBottom: '10px' }}>
            <Grid item xs={10} className={classes.gridbox}>
              <Typography className={classes.boxheading}>
                CONTACTS Edit
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {this.state.expandContactEditOpen ? (
                <ExpandLessIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({
                    expandContactEditOpen: !this.state.expandContactEditOpen,
                  })}
                />
              ) : (
                <ExpandMoreIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({
                    expandContactEditOpen: !this.state.expandContactEditOpen,
                  })}
                />
              )}
            </Grid>
          </Grid>
          <Collapse
            in={this.state.expandContactEditOpen}
            timeout="auto"
            unmountOnExit
          >
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.gridbox}>
                <Typography display="inline" className={classes.balanceSty}>
                  Karen Appleseed
                  {' '}
                </Typography>
                <Typography display="inline" className={classes.textSty}>
                  Family Member
                </Typography>
              </Grid>

              <Grid item xs={4} className={classes.gridbox}>
                <Typography className={classes.boxheading}>Phone</Typography>
              </Grid>
              <Grid item xs={8} className={classes.gridbox}>
                <Typography className={classes.phoneNum}>
                  (942) 460-1184
                </Typography>
                <Typography display="inline" className={classes.mobileSty}>
                  Mobile
                  {' '}
                </Typography>
                <PhoneDisabledOutlinedIcon
                  display="inline"
                  className={classes.iconSty}
                />
                <MobileOffIcon className={classes.iconSty} />
              </Grid>

              <Grid item xs={4} className={classes.gridbox}>
                <Typography className={classes.boxheading}>Email</Typography>
              </Grid>
              <Grid item xs={8} className={classes.gridbox}>
                <Typography numberOfLines={1} className={classes.phoneNum}>
                  akshaymahajan28@yahoo.com
                </Typography>
                <Typography display="inline" className={classes.mobileSty}>
                  Work
                  {' '}
                </Typography>
                <MailOutlineOutlinedIcon
                  display="inline"
                  className={classes.iconSty}
                />
              </Grid>
            </Grid>
          </Collapse>
        </div>
        <br />

        {/* --------------UPCOMING APPOINTMENTS start--------------------*/}

        <div className={classes.boxsty}>
          <Grid container spacing={4} style={{ paddingBottom: '10px' }}>
            <Grid item xs={10} className={classes.gridbox}>
              <Typography className={classes.boxheading}>
                UPCOMING APPOINTMENTS
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {this.state.expandUpcomingOpen ? (
                <ExpandLessIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({
                    expandUpcomingOpen: !this.state.expandUpcomingOpen,
                  })}
                />
              ) : (
                <ExpandMoreIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({
                    expandUpcomingOpen: !this.state.expandUpcomingOpen,
                  })}
                />
              )}
            </Grid>
          </Grid>
          <Collapse
            in={this.state.expandUpcomingOpen}
            timeout="auto"
            unmountOnExit
          >
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.gridbox}>
                <Typography display="inline" className={classes.textSty}>
                  None scheduled
                  {' '}
                </Typography>
                <Typography display="inline" className={classes.balanceSty}>
                  Schedule Now
                </Typography>
              </Grid>
            </Grid>
          </Collapse>
        </div>
        <br />

        {/* --------------COMMUNICATION SETTINGS start--------------------*/}

        <div className={classes.boxsty}>
          <Grid container spacing={4} style={{ paddingBottom: '10px' }}>
            <Grid item xs={10} className={classes.gridbox}>
              <Typography className={classes.boxheading}>
                COMMUNICATION SETTINGS
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {this.state.expandCommOpen ? (
                <ExpandLessIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({
                    expandCommOpen: !this.state.expandCommOpen,
                  })}
                />
              ) : (
                <ExpandMoreIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({
                    expandCommOpen: !this.state.expandCommOpen,
                  })}
                />
              )}
            </Grid>
          </Grid>

          <Collapse in={this.state.expandCommOpen} timeout="auto" unmountOnExit>
            <Grid item xs={12} className={classes.gridbox}>
              <Typography display="inline" className={classes.textSty}>
                Appointment Reminders
                {' '}
              </Typography>
              <Typography display="inline" className={classes.balanceSty}>
                Manage
              </Typography>
              <Typography component="li" className={classes.textSty}>
                Disabled
              </Typography>

              <Typography display="inline" className={classes.textSty}>
                Client Portal Access
                {' '}
              </Typography>
              <Typography display="inline" className={classes.balanceSty}>
                Manage
              </Typography>
              <Typography component="li" className={classes.textSty}>
                Disabled
              </Typography>
            </Grid>
          </Collapse>
        </div>
        <br />

        {/* --------------SHARED WITH CLIENT start--------------------*/}

        <div className={classes.boxsty}>
          <Grid container spacing={4} style={{ paddingBottom: '10px' }}>
            <Grid item xs={10} className={classes.gridbox}>
              <Typography className={classes.boxheading}>
                SHARED WITH CLIENT
                {/* <HelpOutlineIcon  /> */}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {this.state.expandShareOpne ? (
                <ExpandLessIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({
                    expandShareOpne: !this.state.expandShareOpne,
                  })}
                />
              ) : (
                <ExpandMoreIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({
                    expandShareOpne: !this.state.expandShareOpne,
                  })}
                />
              )}
            </Grid>
          </Grid>

          <Collapse
            in={this.state.expandShareOpne}
            timeout="auto"
            unmountOnExit
          >
            <Grid item xs={12} className={classes.gridbox}>
              <Typography display="inline" className={classes.textSty}>
                Nothing shared
                {' '}
              </Typography>
              <Typography display="inline" className={classes.balanceSty}>
                Share New
              </Typography>
            </Grid>
          </Collapse>
        </div>
        <br />

        {/* --------------UPLOADED FILES start--------------------*/}

        <div className={classes.boxsty}>
          <Grid container spacing={4} style={{ paddingBottom: '10px' }}>
            <Grid item xs={10} className={classes.gridbox}>
              <Typography className={classes.boxheading}>
                UPLOADED FILES
                {/* <HelpOutlineIcon  /> */}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {this.state.expandUploadOpen ? (
                <ExpandLessIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({
                    expandUploadOpen: !this.state.expandUploadOpen,
                  })}
                />
              ) : (
                <ExpandMoreIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({
                    expandUploadOpen: !this.state.expandUploadOpen,
                  })}
                />
              )}
            </Grid>
          </Grid>
          <Collapse
            in={this.state.expandUploadOpen}
            timeout="auto"
            unmountOnExit
          >
            <Grid item xs={12} className={classes.gridbox}>
              <Typography display="inline" className={classes.textSty}>
                Nothing uploaded
                {' '}
              </Typography>
              <Typography display="inline" className={classes.balanceSty}>
                Upload New
              </Typography>
            </Grid>
          </Collapse>
        </div>
        <br />

        {/* --------------INVOICES start--------------------*/}

        <div className={classes.boxsty}>
          <Grid container spacing={4} style={{ paddingBottom: '10px' }}>
            <Grid item xs={10} className={classes.gridbox}>
              <Typography className={classes.boxheading}>
                INVOICES
                {/* <HelpOutlineIcon  /> */}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {this.state.expandInvoicesOpne ? (
                <ExpandLessIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({
                    expandInvoicesOpne: !this.state.expandInvoicesOpne,
                  })}
                />
              ) : (
                <ExpandMoreIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({
                    expandInvoicesOpne: !this.state.expandInvoicesOpne,
                  })}
                />
              )}
            </Grid>
          </Grid>
          <Collapse
            in={this.state.expandInvoicesOpne}
            timeout="auto"
            unmountOnExit
          >
            <Grid item xs={12} className={classes.gridbox}>
              <Typography display="inline" className={classes.textSty}>
                No invoices
                {' '}
              </Typography>
              {/* <Typography display="inline" className={classes.balanceSty}>
                Upload New
              </Typography> */}
            </Grid>
          </Collapse>
        </div>
        <br />

        {/* --------------BILLING DOCUMENTS start--------------------*/}

        <div className={classes.boxsty}>
          <Grid container spacing={4} style={{ paddingBottom: '10px' }}>
            <Grid item xs={10} className={classes.gridbox}>
              <Typography className={classes.boxheading}>
                BILLING DOCUMENTS
                {/* <HelpOutlineIcon  /> */}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {this.state.expandDocumentsOpen ? (
                <ExpandLessIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({
                    expandDocumentsOpen: !this.state.expandDocumentsOpen,
                  })}
                />
              ) : (
                <ExpandMoreIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({
                    expandDocumentsOpen: !this.state.expandDocumentsOpen,
                  })}
                />
              )}
            </Grid>
          </Grid>
          <Collapse
            in={this.state.expandDocumentsOpen}
            timeout="auto"
            unmountOnExit
          >
            <Grid item xs={12} className={classes.gridbox}>
              <Typography display="inline" className={classes.textSty}>
                No documents
                {' '}
              </Typography>
              {/* <Typography display="inline" className={classes.balanceSty}>
                Upload New
              </Typography> */}
            </Grid>
          </Collapse>
        </div>
        <br />
      </div>
    );
  }
}

export default withStyles(styles)(OverViewBoxes);
