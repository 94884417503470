import { createStyles} from "@material-ui/core";
import { titleFont, descFont } from '../../../src/assets/fontSize';
export default (theme) =>
    createStyles({
        root: {
            backgroundColor: "#cfd9df",
            width: "100%",
            opacity: "20%",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        },
        titleContainer: {
            position: "absolute",
            zIndex: 1,
            [theme.breakpoints.up("xs")]: {
                width: "300px",
                top: "150px",
            },
            [theme.breakpoints.up("sm")]: {
                width: "500px",
                top: "150px",
            },
            [theme.breakpoints.up("md")]: {
                width: "800px",
                top: "88px",
            },
        },
        container: {
            position: "absolute",
            zIndex: 1,
            [theme.breakpoints.up("xs")]: {
                width: "300px",
                top: "80px",
            },
            [theme.breakpoints.up("sm")]: {
                width: "500px",
                top: "90px",
            },
            [theme.breakpoints.up("md")]: {
                width: "800px",
                top: "132px",
            },
        },
        bodyContainer: {
            marginTop: "36px",
            justifyContent: "center",
            alignSelf: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.up("xs")]: {
                width: "300px",
            },
            [theme.breakpoints.up("sm")]: {
                width: "500px",
            },
            [theme.breakpoints.up("md")]: {
                width: "800px",
            },
        },
        title: {
            // fontSize: "32px",
            color: "#304659",
            fontFamily: "Lato",
            fontWeight: "700",
            [theme.breakpoints.up("xs")]: {
                fontSize: titleFont,
                textAlign: "center",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: titleFont,
                textAlign: "center",
            },
        },
        fieldContainer: {
            [theme.breakpoints.up("xs")]: {
                display: "inline",
            },
            [theme.breakpoints.up("sm")]: {
                display: "inline",
            },
            [theme.breakpoints.up("md")]: {
                display: "flex",
            },
        },
        gridwhite: {
            height: "81px",
            // paddingRight: "32px",
            backgroundColor: "#ffffff",
            opacity: "100%",
            // justifyContent: "center",
            alignItems: "center",
            [theme.breakpoints.up("xs")]: {
                width: "300px",
                paddingLeft: "20px",
            },
            [theme.breakpoints.up("sm")]: {
                width: "500px",
                paddingLeft: "29px",
            },
            [theme.breakpoints.up("md")]: {
                width: "800px",
                paddingLeft: "29px",
            },
        },
        gridcolor: {
            height: "81px",
            // paddingRight: "32px",
            backgroundColor: "#fafbfb",
            opacity: "100%",
            // justifyContent: "center",
            alignItems: "center",
            [theme.breakpoints.up("xs")]: {
                width: "300px",
                display: "flex",
                paddingLeft: "20px",
            },
            [theme.breakpoints.up("sm")]: {
                width: "500px",
                paddingLeft: "29px",
            },
            [theme.breakpoints.up("md")]: {
                width: "800px",
                paddingLeft: "29px",
            },
        },
        dateInput: {
            [theme.breakpoints.up("xs")]: {
                width: "220px",
                height: "30px",
            },
            [theme.breakpoints.up("sm")]: {
                width: "211px",
                height: "40px",
            },
            [theme.breakpoints.up("md")]: {
                width: "211px",
                height: "50px",
            },
            backgroundColor: "#ffffff",
            fontSize: descFont,
            border: "1px",
            borderStyle: "solid",
            borderColor: "#707070",
            cursor: "text",
            color: "#304659",
            fontFamily: "Lato",
            fontWeight: "400",
            opacity: "100%",
            display: "inline-block",
            textAlign: "center",
            paddingLeft: "23px",
            paddingRight: "14px",
        },
        radio: {
            width: "15px",
            height: "15px",
            border: "10px solid #EFE5CE",
        },
        labletext: {
            fontSize: descFont,
            // fontSize: "20px",
            color: "#304659",
            fontFamily: "Lato",
            fontWeight: 400,
            textTransform: "capitalize",
        },
        cancelButton: {
            justifyContent: 'cenetr',
            alignItems: 'center'
        },
        nextButton: {
            marginLeft: 10
        },
        // cancelText: {
        //     position: "relative",
        //     // left: "-78px",
        // },
        customNationality: {
            [theme.breakpoints.up("xs")]: {
                display: "none",
            },
            [theme.breakpoints.up("md")]: {
                display: "inline",
                width: "505px",
            },
        },
    });