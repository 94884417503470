import React from 'react';
import {
  Typography,
  Button,
  List,
  ListItem,
  makeStyles,
  Card,
  CardContent,
  CardMedia,
} from '@material-ui/core';
import moment from 'moment';
import { CLIENT_DOCUMENT_TYPES } from '../utils/enums';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    padding: '16px',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 200,
  },
}));

export default function DocumentList(props) {
  const { data } = props;
  const classes = useStyles();

  return (
    <div>
      <List>
        {data && data.length ? (
          data.map((row, i) => {
            const { documentId, documentType, expiryDate, verified } = row;
            return (
              <ListItem style={{ width: '100%' }}>
                <Card className={classes.root}>
                  <CardMedia
                    className={classes.cover}
                    image={documentId && documentId.url ? documentId.url : null}
                  />
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Typography component='h2'>
                        <text style={{ fontWeight: 'bold' }}>
                          Document Type
                        </text>{' '}
                        {CLIENT_DOCUMENT_TYPES[documentType]}
                      </Typography>

                      {expiryDate ? (
                        <Typography component='h2'>
                          <text style={{ fontWeight: 'bold' }}>
                            Expiration Date
                          </text>{' '}
                          {moment(expiryDate).format('LL')}
                        </Typography>
                      ) : (
                        <> </>
                      )}

                      <Typography component='h2'>
                        <text style={{ fontWeight: 'bold' }}>
                          Verification Status
                        </text>{' '}
                        <text style={row.verified ? { color: 'green' } : {}}>
                          {verified ? 'Verified' : 'Unverified'}
                        </text>
                      </Typography>

                      {!verified ? (
                        <>
                          <Button
                            onClick={() => {
                              if (props.onEdit) props.onEdit(row);
                            }}
                            variant='contained'
                            style={{ marginTop: '16px' }}
                          >
                            Edit
                          </Button>
                          <Button
                            onClick={() => {
                              if (props.onDelete) props.onDelete(row);
                            }}
                            variant='contained'
                            style={{ marginTop: '16px', marginLeft: '8px' }}
                          >
                            Delete
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                    </CardContent>
                  </div>
                </Card>
              </ListItem>
            );
          })
        ) : (
          <div />
        )}
      </List>
    </div>
  );
}
