import { createStyles } from '@material-ui/core';

export default (theme) => ({
  boxsty: {
    border: '1px solid #d7d7d7',
    borderRadius: '3px',
    padding: theme.spacing(2),
  },
  boxheading: {
    letterSpacing: '1px',
    textTransform: 'uppercase',
    color: '#a29061',
    fontSize: '13px',
    lineHeight: 'normal',
    textAlign: 'left',
  },
  arrowsty: {
    fontSize: '16px',
    cursor: 'pointer',
    color: '#08c',
    textAlign: 'right',
  },
  gridbox: {
    textAlign: 'left',
  },
  gridsty: {
    marginTop: theme.spacing(2),
  },
  balanceSty: {
    color: '#003d79',
    textDecoration: 'none',
    fontSize: '13px',
    lineHeight: '18px',
    backgroundColor: 'transparent',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  balanceNum: {
    color: '#bf3604',
    overflow: 'hidden',
    textSize: '13px',
    lineHeight: '18px',
    textAlign: 'right',
  },
  textSty: {
    color: '#4b5459',
    textDecoration: 'none',
    fontSize: '13px',
    lineHeight: '18px',
    textAlign: 'left',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  numsty: {
    color: '#4b5459',
    overflow: 'hidden',
    textSize: '13px',
    lineHeight: '18px',
    textAlign: 'right',
  },
  dateInput: {
    width: '100%',
    height: 38,
    borderRadius: 3,
    borderStyle: 'solid',
    borderColor: '#cccccc',
    color: '#003d79',
    borderWidth: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'inline-block',
    textAlign: 'center',
  },
  addPaymentButton: {
    width: '100%',
    cursor: 'pointer',
    backgroundColor: '#003d79',
    borderColor: '#003d79',
    minwidth: '70px',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    padding: '8px 18px',
    border: '1px solid',
    color: '#ffffff',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  mobileSty: {
    color: '#7489a1',
    lineHeight: 'normal',
    fontSize: '13px',
    textTransform: 'capitalize',
  },
  phoneNum: {
    color: '#003d79',
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: '13px',
    lineHeight: '18px',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  iconSty: {
    width: '18px',
    color: '#ea644c',
    fontSize: '18px',
    lineHeight: 'normal',
  },
});
