import React from 'react';

const clinicAddress = (a) => {
  if (a.title) {
    return a.title;
  }
  return `${a.address1}, ${a.address2 ? a.address2 + ',' : ''} ${a.city}, ${
    a.country.name
  }`;
};
const CheckoutAppointmentAddress = ({ invoice }) => (
  <div className='pt-1'>
    <svg
      className='inline-block mr-1 w-4 h-4'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z'
      />
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M15 11a3 3 0 11-6 0 3 3 0 016 0z'
      />
    </svg>
    <span className='text-gray-900'>Address:</span>{' '}
    {clinicAddress(
      invoice.clinicAddress[0]
        ? invoice.clinicAddress[0]
        : invoice.appointmentClinicAddresses[0]
    )}
  </div>
);

export default CheckoutAppointmentAddress;
