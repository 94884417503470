import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { Drawer } from '@material-ui/core';
import { BasicContactForm } from '../../../../components/molecules';
import { Button, Select } from '../../../../components/atoms';
import { Container, Heading, Row, Error } from './style';
import { uid } from '../../../../utils/validators';

const AddContactForm = ({
  isOpen,
  toggleContactDrawer,
  addresses,
  addContact,
  updateContact,
  editingContact,
  removeContact,
}) => {
  const [contact, setContact] = useState({
    _id: uid(),
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    phoneNumber: '',
    phoneType: '',
    designation: '',
    addressId: '',
  });

  const [errors, setErrors] = useState({
    firstName: { title: 'First Name', message: '' },
    lastName: { title: 'Last Name', message: '' },
    email: { title: 'Email', message: '' },
    confirmEmail: { title: 'Confirm Email', message: '' },
    phoneNumber: { title: 'Phone Number', message: '' },
    phoneType: { title: 'Phone Type', message: '' },
    designation: { title: 'Designation', message: '' },
    addressId: { title: 'Address', message: '' },
  });

  useEffect(() => {
    if (isOpen && !editingContact) {
      setContact({
        _id: uid(),
        firstName: '',
        lastName: '',
        email: '',
        confirmEmail: '',
        phoneNumber: '',
        phoneType: '',
        designation: '',
        addressId: '',
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (editingContact) {
      setContact({
        ...editingContact,
        confirmEmail: '',
      });
    }
  }, [editingContact]);

  const formattedAddressOptions = (rawAddresses) => {
    return rawAddresses.map((address) => ({
      key: address._id,
      value: `${address.address1} - ${address.state} - ${address.country}`,
    }));
  };

  const onChange = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
    if (errors[e.target.name]) {
      setErrors({
        ...errors,
        [e.target.name]: {
          ...errors[e.target.name],
          message: '',
        },
      });
    }
  };
  const resetContact = () =>
    setContact({
      firstName: '',
      lastName: '',
      email: '',
      confirmEmail: '',
      phoneNumber: '',
      phoneType: '',
      designation: '',
      addressId: '',
    });

  const handleRemove = () => {
    resetContact();
    removeContact(contact._id);
  };

  const handleAddContact = () => {
    console.log(contact);
    let err = {};
    Object.keys(errors).forEach((f) => {
      let message;
      let invalidEmail;

      if (f === 'email' && contact.email.trim()) {
        const isEmail = validator.isEmail(contact.email.trim());
        if (!isEmail) {
          invalidEmail = true;
          message = 'Email is not valid!';
        }
      } else if (f === 'email' || f === 'confirmEmail') {
        if (contact.email.trim() !== contact.confirmEmail.trim()) {
          invalidEmail = true;
          message = 'Emails do not match!';
        }
      }

      err = {
        ...err,
        [f]: {
          ...errors[f],
          message:
            !contact[f] || !contact[f].trim() || invalidEmail
              ? message
                ? message
                : `${errors[f]['title']} can not be empty!`
              : '',
        },
      };
    });

    setErrors({ ...err });
    const isError = Object.keys(err).some((e) => err[e].message);
    if (isError) {
      return;
    }

    resetContact();
    editingContact ? updateContact(contact) : addContact(contact);
  };

  return (
    <Drawer anchor='right' open={isOpen} onClose={toggleContactDrawer}>
      <Container>
        <Heading> Add New Contact</Heading>
        <BasicContactForm
          onChange={onChange}
          contact={contact}
          errors={errors}
        />
        <Row direction='row' container>
          <Select
            label='Office Address'
            options={formattedAddressOptions(addresses)}
            name='addressId'
            onChange={onChange}
            value={contact.addressId}
            isRequired
          />
          <Error>{errors.addressId && errors.addressId.message}</Error>
        </Row>

        <Row direction='column' container item>
          {editingContact ? (
            <Button text='Update Contact' onClick={handleAddContact} />
          ) : (
            <Button text='Add New Contact' onClick={handleAddContact} />
          )}
          <Row container>
            <Button
              text='Remove Contact'
              bgcolor='#db1919'
              onClick={handleRemove}
            />
          </Row>
        </Row>
      </Container>
    </Drawer>
  );
};

AddContactForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleContactDrawer: PropTypes.func.isRequired,
  addContact: PropTypes.func.isRequired,
  addresses: PropTypes.array,
  updateContact: PropTypes.func.isRequired,
  removeContact: PropTypes.func.isRequired,
  editingContact: PropTypes.object,
};

export default AddContactForm;
