/* eslint-disable import/prefer-default-export */
import {
    GET_ALL_PRESCRIPTIONS_REQUEST,
    GET_ALL_PRESCRIPTIONS_SUCCESS,
    GET_ALL_PRESCRIPTIONS_FAILED,
    GET_ALL_PRESCRIPTIONS_BY_ID_REQUEST,
    GET_ALL_PRESCRIPTIONS_BY_ID_SUCCESS,
    GET_ALL_PRESCRIPTIONS_BY_ID_FAILED,
  } from '../constant';
  
  export const getAllPrescriptions = (state = [], action) => {
    switch (action.type) {
      case GET_ALL_PRESCRIPTIONS_REQUEST:
        return {
          request: true,
        };
      case GET_ALL_PRESCRIPTIONS_SUCCESS:
        return {
          request: false,
          getAllPrescriptionsData: action.response,
        };
      case GET_ALL_PRESCRIPTIONS_FAILED:
        return {
          request: false,
          error: true,
        };
      default:
        return state;
    }
  };
  
  export const getAllPrescriptionsById = (state = [], action) => {
    switch (action.type) {
      case GET_ALL_PRESCRIPTIONS_BY_ID_REQUEST:
        return {
          request: true,
        };
      case GET_ALL_PRESCRIPTIONS_BY_ID_SUCCESS:
        return {
          request: false,
          getAllPrescriptionsByIdData: action.response,
        };
      case GET_ALL_PRESCRIPTIONS_BY_ID_FAILED:
        return {
          request: false,
          error: true,
        };
      default:
        return state;
    }
  };
  

  