/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable import/prefer-default-export */
import {
  ADD_RCADS_REQUEST,
  GET_RCADS_REQUEST,
  GET_RCADS_BY_ID_REQUEST,
  UPDATE_RCADS_REQUEST,
  DELETE_RCADS_REQUEST,
} from "../AdminConstent";
import { USER_RCAD_SUBMIT_REQUEST } from "../constant";

export const addRcadAction = (data) => ({
  type: ADD_RCADS_REQUEST,
  endpoint: "/admin/assessment",
  // endpoint: "/admin/rcad_qus",
  body: data,
});

export const getRcadAction = () => ({
  type: GET_RCADS_REQUEST,
  endpoint: "/api/assessment",
});

export const getByIdRcadAction = (id) => ({
  type: GET_RCADS_BY_ID_REQUEST,
  endpoint: `/admin/assessment/${id}`,
});

export const updateRcadAction = (id, data) => ({
  type: UPDATE_RCADS_REQUEST,
  endpoint: `/admin/assessment/${id}`,
  body: data,
});

export const deleteRcadAction = (id) => ({
  type: DELETE_RCADS_REQUEST,
  endpoint: `/admin/assessment/${id}`,
});

export const submitRcadUserAction = (data, id) => ({
  type: USER_RCAD_SUBMIT_REQUEST,
  // endpoint: `/api/rcads/${id}`,
  endpoint: `/api/client/recommend_clinician/${id}`,
  body: data,
});

export const getRcadUserAction = () => ({
  type: GET_RCADS_REQUEST,
  endpoint: "/api/assessment",
});
