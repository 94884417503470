import { put, takeEvery } from 'redux-saga/effects';
import { post, get, updatePatchForm, postFormData } from '../axios';
import {
  CREATE_COMPANY_REQUEST,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAILED,
  GET_COMPANIES_WITH_ASSOCIATIONS_REQUEST,
  GET_COMPANIES_WITH_ASSOCIATIONS_SUCCESS,
  GET_COMPANIES_WITH_ASSOCIATIONS_FAILED,
  GET_ALL_COMPANIES_REQUEST,
  GET_ALL_COMPANIES_SUCCESS,
  GET_ALL_COMPANIES_FAILED,
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAILED,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILED,
} from '../constant';

function* createCompany(action) {
  try {
    const { endpoint, body } = action;
    const response = yield postFormData(endpoint, body);
    yield put({ type: CREATE_COMPANY_SUCCESS, response });
  } catch (error) {
    yield put({ type: CREATE_COMPANY_FAILED, error });
  }
}

function* updateCompany(action) {
  try {
    const { endpoint, body } = action;
    const response = yield updatePatchForm(endpoint, body);
    yield put({ type: UPDATE_COMPANY_SUCCESS, response });
  } catch (error) {
    yield put({ type: UPDATE_COMPANY_FAILED, error });
  }
}

function* getCompaniesWithAssociations(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_COMPANIES_WITH_ASSOCIATIONS_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_COMPANIES_WITH_ASSOCIATIONS_FAILED, error });
  }
}

function* getCompanyById(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_COMPANY_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_COMPANY_FAILED, error });
  }
}

function* getAllCompanies(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_ALL_COMPANIES_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_ALL_COMPANIES_FAILED, error });
  }
}

export function* companySaga() {
  yield takeEvery(CREATE_COMPANY_REQUEST, createCompany);
  yield takeEvery(UPDATE_COMPANY_REQUEST, updateCompany);
  yield takeEvery(
    GET_COMPANIES_WITH_ASSOCIATIONS_REQUEST,
    getCompaniesWithAssociations
  );
  yield takeEvery(GET_COMPANY_REQUEST, getCompanyById);
  yield takeEvery(GET_ALL_COMPANIES_REQUEST, getAllCompanies);
}
