import { all } from 'redux-saga/effects';
import { sagaMiddleware } from '../store/index';
import { authSaga } from './auth.saga';
import { clientSaga } from './client.saga';
import { appointmentSaga } from './appointment.saga';
import { serviceSaga } from './service.saga';
import { billingSaga } from './billing.saga';
import { roleSaga } from './role.saga';
import { permissionSaga } from './permission.saga';
import { userSaga } from './admin.user.saga';
import { rcadSaga } from './rcad.saga';
import { clinicianSaga } from './clinician.saga';
import { paymentSaga } from './payment.saga';
import { onboardingSaga } from './admin.onboarding.saga';
import { mhaSaga } from './admin.mha.saga';
import { P1of2Saga } from './admin.p1of2.saga';
import { dashbordSaga } from './dashbord.saga';
import { eventsSaga } from './events.saga';
import { prescriptionSaga } from './prescription.saga';
import { clinicianAddressSaga } from './clinician_address.saga';
import { smsSaga } from './sms.saga';
import { companySaga } from './company.saga';
import { bundleSaga } from './bundle.saga';
import { consentSaga } from './consent.saga';

function* rootSaga() {
  yield all([
    authSaga(),
    clientSaga(),
    appointmentSaga(),
    serviceSaga(),
    billingSaga(),
    roleSaga(),
    permissionSaga(),
    userSaga(),
    rcadSaga(),
    clinicianSaga(),
    paymentSaga(),
    onboardingSaga(),
    mhaSaga(),
    P1of2Saga(),
    dashbordSaga(),
    eventsSaga(),
    prescriptionSaga(),
    clinicianAddressSaga(),
    smsSaga(),
    companySaga(),
    bundleSaga(),
    consentSaga(),
  ]);
}

export const runSaga = () => {
  sagaMiddleware.run(rootSaga);
};

export default runSaga;
