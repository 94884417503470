import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
    paddingLeft: theme.spacing(35),
  },
  title: {
    textAlign: 'left',
    fontSize: 20,
    fontWeight: 400,
    color: '#003d79',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  namesty: {
    color: '#a29061',
    textDecoration: 'none',
    fontSize: 20,
    fontWeight: 400,
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  initabcolor: {
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontSize: 13,
    color: '#003d79',
    fontWeight: 400,
    textTransform: 'capitalize',
  },
  panelsty: {
    textAlign: 'left',
  },
  dividersty: {
    width: '95%',
  },
});
