import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import { Grid, MenuItem, Select } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

LocationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    inputstyle: PropTypes.string.isRequired,
    selectEmpty: PropTypes.string.isRequired,
  }).isRequired,
};
export default function LocationModal({
  open,
  handleClose,
  handleSubmit,
  classes,
}) {
  const [location, setLocation] = useState('online');
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'md'}
        aria-labelledby='max-width-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Location</DialogTitle>
        <DialogContent>
          <Grid style={{ marginBottom: 20 }}>
            <DialogContentText>Select appointment location</DialogContentText>
            <Select
              className={`${classes.inputstyle} ${classes.selectEmpty}`}
              defaultValue={location}
              onChange={(e) => setLocation(e.target.value)}
              inputProps={{ 'aria-label': 'Without label' }}
              style={{ width: '100%' }}
            >
              <MenuItem value='online'>Online</MenuItem>
              <MenuItem value='clinic'>Clinic</MenuItem>
              <MenuItem value='school'>School visit</MenuItem>
              <MenuItem value='home'>Home visit</MenuItem>
            </Select>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose} variant='contained' color='default'>
            Cancel
          </Button>
          <Button
            disabled={!location}
            variant='contained'
            onClick={(evt) => handleSubmit(location)}
            color='primary'
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
