import {
  SEND_SMS_REQUEST,
  SEND_SMS_SUCCESS,
  SEND_SMS_FAILED,
} from '../constant';

const initialState = {
  request: false,
  message: '',
  error: false,
};

const SMSReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_SMS_REQUEST:
      return {
        ...state,
        request: true,
      };
    case SEND_SMS_SUCCESS:
      return {
        ...state,
        request: false,
        message: 'Message sent successfully!',
      };
    case SEND_SMS_FAILED:
      return {
        request: false,
        error: true,
        message: 'Message sending failed. Please try again!',
      };
    default:
      return state;
  }
};

export default SMSReducer;
