/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import * as React from "react";
import {
    makeStyles, Grid, Paper, Button, Dialog, DialogActions,
    DialogContentText, DialogContent, DialogTitle, TextField
} from "@material-ui/core";
import styles from "./NewAppointment.styles";


export default function ScheduleModal({ clinicianDetails, open, handleClose, handleCalendarTimeSelect, handleCancelAppointment, handleAppointmentConfirm, handleTimeSelect, handleAlternativeCalendar, confirmBtnLoading , setCost, setDuration }) {
    const classes = styles();
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Book Appointment"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Select a Date time and duartion
        </DialogContentText>
                    <Grid
                        // container
                        //   alignItems="center"
                        justify="center"
                        spacing={3}
                        className={classes.root}
                    >
                        <Grid item xs={5}>
                            <TextField
                                id="datetime-local"
                                label="Next appointment"
                                type="datetime-local"
                                defaultValue={new Date()}
                                onChange={handleTimeSelect}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                id="standard-number"
                                label="Duration"
                                type="number"
                                style={{marginTop:"8px"}}
                                defaultValue={30}
                                onChange={env => {
                                    setDuration(env.target.value)
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                id="standard-number"
                                label="Cost"
                                type="number"
                                style={{marginTop:"8px"}}
                                defaultValue={200}
                                onChange={env => {
                                    setCost(env.target.value)
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
        </Button>
                    <Button onClick={handleAppointmentConfirm} color="primary" autoFocus>
                        Submit
        </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

