/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from "react";
import {
  
  withStyles,
  Typography,
  Paper,
  Button,
} from "@material-ui/core";
import querystring from "query-string";
import { Link } from "react-router-dom";
import styles from "./PaymentSuccessStyle";

class PaymentSuccess extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      status: false,
    };
  }

  componentDidMount() {
    const url = querystring.parse(this.props.location.search);
    if (url.status === "success") {
      this.setState({ status: true });
    } else {
      this.setState({ status: false });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div
        style={{
          backgroundColor: "#F8F8F8",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
        }}
      >
        <Paper className={classes.root}>
          {this.state.status ? (
            <Typography className={classes.heading} style={{ color: "green" }}>
              Payment Successful !
            </Typography>
          ) : (
            <Typography className={classes.heading} style={{ color: "red" }}>
              Payment Failed !
            </Typography>
          )}
          {this.state.status ? (
            <Typography className={classes.subHeading}>
              We are delighted to inform you that your appointment is
              successfully booked...
            </Typography>
          ) : (
            <Typography className={classes.subHeading}>
              Your payment is failed due to some reason, Please try again...
            </Typography>
          )}
          {this.state.status ? null : (
            // <Link to={this.props.history.goBack()}>
            <Button variant="contained" className={classes.button}>
              Back
            </Button>
            // </Link>
          )}
        </Paper>
      </div>
    );
  }
}


export default withStyles(styles)(PaymentSuccess);
