import {
  GET_ALL_BILLING_DATA_REQUEST,
  GET_ALL_BILLING_DATA_SUCCESS,
  GET_ALL_BILLING_DATA_FAILED,
  GET_ALL_INVOICE_BY_CLIENT_ID_REQUEST,
  GET_ALL_INVOICE_BY_CLIENT_ID_SUCCESS,
  GET_ALL_INVOICE_BY_CLIENT_ID_FAILED,
  GET_SINGLE_INVOICE_REQUEST,
  GET_SINGLE_INVOICE_SUCCESS,
  GET_SINGLE_INVOICE_FAILED,
  GET_ALL_BILLING_FOR_ADMIN_REQUEST,
  GET_ALL_BILLING_FOR_ADMIN_SUCCESS,
  GET_ALL_BILLING_FOR_ADMIN_FAILED,
  CLEAR_SINGLE_INVOICE,
} from '../constant';

export const getAllBilling = (state = [], action) => {
  switch (action.type) {
    case GET_ALL_BILLING_DATA_REQUEST:
      return {
        request: true,
      };
    case GET_ALL_BILLING_DATA_SUCCESS:
      return {
        request: false,
        getAllBillingData: action.response,
      };
    case GET_ALL_BILLING_DATA_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getAllInvoice = (state = [], action) => {
  switch (action.type) {
    case GET_ALL_INVOICE_BY_CLIENT_ID_REQUEST:
      return {
        request: true,
      };
    case GET_ALL_INVOICE_BY_CLIENT_ID_SUCCESS:
      return {
        request: false,
        getAllInvoiceData: action.response,
      };
    case GET_ALL_INVOICE_BY_CLIENT_ID_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getSingleInvoice = (state = [], action) => {
  switch (action.type) {
    case GET_SINGLE_INVOICE_REQUEST:
      return {
        request: true,
      };
    case GET_SINGLE_INVOICE_SUCCESS:
      return {
        request: false,
        getSingleInvoiceData: action.response,
      };
    case CLEAR_SINGLE_INVOICE:
      console.log('clearing');
      return {
        getSingleInvoiceData: null,
      };
    case GET_SINGLE_INVOICE_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

const initState = { request: false, billings: [], count: 0, error: false };

export const getBillingsForAdmin = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_BILLING_FOR_ADMIN_REQUEST:
      return {
        ...initState,
        request: true,
      };
    case GET_ALL_BILLING_FOR_ADMIN_SUCCESS:
      return {
        ...initState,
        request: false,
        billings: action.response[0].data,
        count: action.response[0].count[0].count,
      };
    case GET_ALL_BILLING_FOR_ADMIN_FAILED:
      return {
        ...initState,
        error: true,
      };
    default:
      return state;
  }
};
