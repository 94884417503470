import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Content, Row, Cell } from './style';
import {
  TextField,
  Textarea,
  Clipboard,
  Select,
} from '../../../../components/atoms';
import { ImageUploader } from '../../../../components/atoms';
import { createCompany } from '../../../../utils/actions/company.action';

const OPTIONS = [
  { key: 'corporation', value: 'Corporation' },
  { key: 'ngo', value: 'NGO' },
  { key: 'school', value: 'School' },
  { key: 'government', value: 'Government' },
];

const CompanyInfoForm = ({
  company,
  onChange,
  handleImageUpload,
  companyLogo,
}) => {
  return (
    <Content container>
      <Row container>
        <div className='h-32 w-32'>
          <ImageUploader
            key={+new Date()}
            uploadedImage={handleImageUpload}
            src={companyLogo}
          />
        </div>
      </Row>
      <Row justify='space-between' container>
        <Cell item>
          <TextField
            value={company.name}
            onChange={onChange}
            name='name'
            label='Company Name'
            error=''
            isRequired
          />
        </Cell>
        <Cell justify='flex-end' item container>
          <Select
            label='Company Type'
            options={OPTIONS}
            name='companyType'
            placeholder='Select (Corporation/NGO/School/Government)'
            onChange={onChange}
            value={company.companyType}
            isRequired
          />
        </Cell>
      </Row>
      <Row justify='space-between' container>
        <Cell item>
          <TextField
            value={company.email}
            onChange={onChange}
            name='email'
            label='Email'
            error=''
            isRequired
          />
        </Cell>
        <Cell justify='flex-end' item container>
          <TextField
            value={company.phoneNumber}
            onChange={onChange}
            name='phoneNumber'
            label='Phone Number'
            error=''
            type='number'
            isRequired
          />
        </Cell>
      </Row>

      <Row justify='space-between' container>
        <Cell item>
          <Textarea
            value={company.description}
            onChange={onChange}
            name='description'
            label='Company Description'
            error=''
          />
        </Cell>
        <Cell item>
          <Clipboard label='Unique Corporate ID' value={company.code} />
        </Cell>
      </Row>
    </Content>
  );
};

CompanyInfoForm.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    companyType: PropTypes.string.isRequired,
    logo: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  handleImageUpload: PropTypes.func.isRequired,
};

export default connect(null, { createCompany })(CompanyInfoForm);
