import {
  GET_ADDRESS_REQUEST,
  ADD_ADDRESS_REQUEST,
  UPDATE_ADDRESS_REQUEST,
} from "../constant";

export const getClinicialAddressById = (addressId) => ({
  type: GET_ADDRESS_REQUEST,
  endpoint: `/api/clinic_addresses/${addressId}`,
});

export const addAddress = (address) => ({
  type: ADD_ADDRESS_REQUEST,
  endpoint: "/api/clinic_addresses",
  body: address,
});

export const updateAddress = (address, id) => ({
  type: UPDATE_ADDRESS_REQUEST,
  endpoint: `/api/clinic_addresses/${id}`,
  body: address,
});
