import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
    height: '40px',
    borderRadius: '5px',
    backgroundColor: '#304659',
    marginTop: '50px',
    marginBottom: '40px',
    cursor: 'pointer',
  },
  buttonOtp: {
    height: '40px',
    borderRadius: '5px',
    backgroundColor: '#304659',
    marginTop: '40px',
    marginBottom: '30px',
    cursor: 'pointer',
  },
  buttonText: {
    fontSize: '18px',
    color: '#ffffff',
    fontFamily: 'Lato',
    fontWeight: 700,
    textAlign: 'center',
  },
  headTxt: {
    textAlign: 'center',
    color: '#304659',
    fontSize: '25px',
    fontWeight: 'bold',
    marginTop: '25px',
  },
  otpTxt: {
    textAlign: 'center',
    color: '#304659',
    fontSize: '30px',
    fontWeight: 'bold',
  },
  bigTxt: {
    textAlign: 'center',
    color: '#304659',
    fontSize: '30px',
    marginTop: '20px',
    fontFamily: 'Lato',
  },
  txt: {
    fontSize: '15px',
    color: '#c0c8cf',
    textAlign: 'center',
    marginTop: '10px',
  },
  inputTxt: {
    textAlign: 'center',
    marginTop: '20px',
  },
  txt2: {
    color: '#304659',
    textAlign: 'center',
    fontSize: '15px',
    marginTop: '20px',
  },
  icongrid: {
    textAlign: 'center',
    marginTop: '50px',
  },
  icon: {
    fontSize: '100px',
    color: '#304659',
  },
});

export default useStyles
