import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { CheckCircleOutlineRounded } from '@material-ui/icons';
import { EVENT_CATEGORIES } from '../../utils/enums';
import moment from 'moment';

const FourthStep = ({ classes, isFree, eventData, attendies, failed }) => {

  let active = attendies.filter(data => data.hasSeat)
  return (<div className='text-left p-10' style={{ color: 'rgb(0, 58, 112)' }}>
    <p className='text-2xl'>Review Your Registration</p>
    <p className='text-lg mt-3 uppercase'>
      Dear {`${attendies[0]?.firstName} ${attendies[0]?.lastName}`}
    </p>
    <p className='mt-4'>
      {failed && failed
        ?
        <p className="text-base tracking-normal">Your attempt to register for the <span className="font-medium capitalize">{eventData.title}</span> on <span className="font-medium text-brand-gold-dark">{moment(eventData.date).tz('Asia/Dubai').format('dddd, MMMM Do YYYY')}</span>, taking place from <span className="font-medium text-brand-gold-dark">{moment(eventData.startTime).tz('Asia/Dubai').format('hh:mmA')} - {moment(eventData.endTime).tz('Asia/Dubai').format('hh:mmA')}</span> was <span className="">unsuccessful</span>.<span className="block mt-2">Please try again or contact us on <a href="tel:+97143802088" className="font-medium">+971 (0)4 380 2088</a> for support or inquiries.</span></p>
        :
        <p className="text-base tracking-normal">Thank you for registering for <span className="font-medium capitalize">{eventData.title}</span> on <span className="font-medium text-brand-gold-dark">{moment(eventData.date).tz('Asia/Dubai').format('dddd, MMMM Do YYYY')}</span>, taking place from <span className="font-medium text-brand-gold-dark">{moment(eventData.startTime).tz('Asia/Dubai').format('hh:mmA')} - {moment(eventData.endTime).tz('Asia/Dubai').format('hh:mmA')}</span>.</p>
      }
    </p>
    <div className='mt-10 text-left flex flex-col'>
      <div className='w-full'>
        <div className='w-6/12 font-bold float-left'>Number of Seats Reserved</div>
        <div className='w-6/12 float-left'>{active.length}</div>
      </div>

      {attendies.map((attendie) => (
        <div className='w-full leading-loose'>
          {attendie.email && <div className='w-full'>
            <div className='w-6/12 font-bold float-left'>Email Address</div>
            <div className='w-6/12 float-left'>{attendie.email}</div>
          </div>
          }
          {attendie.phoneNumber && <div className='w-full'>
            <div className='w-6/12 font-bold float-left'>Phone Number</div>
            <div className='w-6/12 float-left'>{attendie.phoneNumber}</div>
          </div>}
        </div>
      ))}

      {/* <div className='w-full'>
        <div className='w-6/12 font-bold float-left'>Message</div>
        <div className='w-6/12 float-left'>{eventData.message}</div>
      </div> */}
    </div>
  </div>)
};

FourthStep.propTypes = {
  classes: PropTypes.shape({
    thankYou: PropTypes.string.isRequired,
  }).isRequired,
};
export default FourthStep;
