import { createStyles} from "@material-ui/core";
import { titleFont } from "../../assets/fontSize";
export default (theme) =>
  createStyles({
    root: {
      backgroundColor: "#cfd9df",
      width: "100%",
      height: "100%",
      opacity: "20%",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    container: {
      position: "absolute",
      zIndex: 1,
      [theme.breakpoints.up("xs")]: {
        width: "300px",
        top: "150px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "500px",
        top: "150px",
      },
      [theme.breakpoints.up("md")]: {
        width: "800px",
        top: "186px",
      },
    },
    title: {
      fontSize: titleFont,
      // fontSize: "32px",
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: 700,
      textAlign: "center",
    },
    bodyContainer: {
      marginTop: "28px",
      borderRadius: "10px",
      backgroundColor: "#ffffff",
      border: "1px solid #707070",
    },
    bodySubContainer: {
      width: "730px",
      // height: "240px",
      // height: "146px",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      [theme.breakpoints.up("xs")]: {
        width: "250px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "450px",
      },
      [theme.breakpoints.up("md")]: {
        width: "730px",
      },
    },
    bodyText: {
      fontSize: "15px",
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: 400,
      textAlign: "left",
      [theme.breakpoints.up("xs")]: {
        padding: "10px",
      },
      [theme.breakpoints.up("sm")]: {
        padding: "15px",
      },
      [theme.breakpoints.up("md")]: {
        padding: "40px",
      },
    },
    cancelButton: {
      // opacity: "25%",
      width: "140px",
    },
    nextButton: {
      marginLeft: 10,
      width: "140px",
    },
    cancelText: {
      position: "relative",
      left: "-78px",
    },
  });