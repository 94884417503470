import React, { useEffect, useState } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Axios from 'axios';
import { baseURL } from '../utils/axios';
import appointment from '../assets/imgs/db-image/appointment.png';
import consult from '../assets/imgs/db-image/consultations.png';
import cancellation from '../assets/imgs/db-image/cancellation.png';
import urgent from '../assets/imgs/db-image/urgent.png';
import { connect } from 'react-redux';
import Layout from './Layout';
import FilterModalBox from './FilterModalBox'
import { convertUTCDateToLocalDate, dateFormatter1 } from '../utils/validators';
import './clinician.scss';
import PatientDetailClinician from './Patients/patientDetail';
import NewPatientsReferral from './components/NewPatientsReferralModal';
import ScheduleModal from './components/ScheduleModal';
import AlertMsg from '../utils/Alert';
import clinician1 from '../assets/imgs/clinician1.png';
import moment from 'moment'
import useStyles from './DashboardClinician.styles';
import { getConsentForms } from '../utils/actions/consent.action';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import Loader from '../components/Loader/Loader';
import AppointmentDetailsView from './AppointmentDetailsView';



const DashboardClinician = ({ getConsentForms, consent }) => {
  const classes = useStyles();
  const [appointmentList, setAppointmentList] = useState(null);
  const [detailDrawer, setDetailDrawer] = useState(false)
  const [appointmentId, setAppointmentId] = useState('');
  const [patientDrawer, setPatientDrawer] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [newReferralModalOpen, setNewReferralModalOpen] = useState(false);
  const [modalActionType, setModalActionType] = useState('');
  const [patientList, setPatientList] = useState([]);
  const [clinicianDetails, setClinicianDetails] = useState(null);
  const [open, setOpen] = useState(false);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const loading = open && patientList.length === 0;
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [confirmBtnLoading, setConfirmBtnLoading] = useState(false);
  const [consentForms, setConsentForms] = useState(null);
  const [incompleteClinitcianNotes, setIncompleteClinitcianNotes] = useState(null);
  const [openCanvasDialog, setCanvasDialog] = useState(false);
  const [detailsOnPatient, setDetailsOnPatient] = useState(false);
  const [scheduleData, setScheduleData] = useState(null);
  const [combinedList, setCombinedList] = useState(null);
  const [openStartSessionConfirmation, setStartSessionConfirmation] = useState(false);
  const [appointmentType, setAppointmentType] = useState('');
  const [openCreateAppointment, setOpenCreateAppointment] = useState(false);

  const history = useHistory()


  useEffect(() => {
    if (consent && consent.data) {
      setConsentForms(consent.data);
    }
  }, [consent]);


  useEffect(() => {
    var date = new Date();

    date.setHours(0, 0, 0, 0);

    var timezoneOffset = date.getTimezoneOffset();
    Axios.get(`${baseURL}/api/appointment/dashboard?timezoneOffset=${timezoneOffset}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    }).then((res) => {
      setAppointmentList(res.data);
    })
      .catch((e) => {
        AlertMsg('error', e.reason)
      });
  }, []);

  useEffect(() => {
    const clinicianId = localStorage.getItem('clinicianUID');
    getConsentForms(clinicianId, {});
  }, [])

  useEffect(() => {
    Axios.get(
      `${baseURL}/api/user/getIncompleteClinitcianNotes`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    ).then((res) => {
      setIncompleteClinitcianNotes(res.data);
    })
      .catch((e) => {
        AlertMsg('error', e.reason)
      });

  }, [])

  useEffect(() => {
    var date = new Date();
    date.setHours(0, 0, 0, 0);
    var timezoneOffset = date.getTimezoneOffset();
    Axios.get(
      `${baseURL}/api/schedule?timezoneOffset=${timezoneOffset}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    ).then((res) => {
      console.log(res.data)
      let respScheduleData = res.data
      let filterDate = new Date()
      filterDate.setHours(0, 0, 0, 0)
      let filterForSchedule = ['New-Patient', 'Current-Patient', 'All-Patient', "Blocked"]
      respScheduleData = respScheduleData.filter(schedule => new Date(schedule.startTime).getTime() > filterDate)
      respScheduleData = respScheduleData.filter(schedule => !filterForSchedule.includes(schedule.type))
      console.log("scheduleData post filter", respScheduleData)
      setScheduleData(respScheduleData)
    })
      .catch((e) => {
        AlertMsg('error', e.reason)
      });

  }, [])

  useEffect(() => {

    if (appointmentList && scheduleData) {
      console.log("\n\n Appointment List \n", appointmentList)
      console.log("\n\n scheduleData List \n", scheduleData)
      let combinedList = scheduleData.concat(appointmentList)
      combinedList.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
      setCombinedList(combinedList)
    }

  }, [scheduleData, appointmentList])

  const getAllClinicians = () => {
    Axios.get(`${baseURL}/api/user/clinicians/`, {
      headers: {
        'Authorization': localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    }).then(res => {
      let clinicians = res.data.filter(item => {
        if (item._id !== localStorage.getItem('clinicianUID')) {
          let tmpObj = item;
          tmpObj.id = item._id;
          tmpObj.name = item.firstName + ' ' + item.lastName
          return tmpObj;
        }
      });
      setPatientList(clinicians);
    }).catch(e => {
      setPatientList([]);
      AlertMsg('error', e.reason)
    });
  }

  const handleDetailDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDetailDrawer(open);
    // setState({ ...state, ["ri"]: open });
  };

  const togglePatientDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setPatientDrawer(open)
    // setState({ ...state, ["ri"]: open });
  };

  const openPatientDrawer = (id) => {
    setPatientDrawer(true);
    setPatientId(id);
  }

  const handleSubmitRequest = (params) => {
  }

  const getDate = (date1) => {
    let today = date1 ? new Date(date1) : new Date();
    let d = (today.getDate() < 10) ? '0' + today.getDate() : today.getDate();
    let m = (today.getMonth() < 10) ? '0' + today.getMonth() : today.getMonth();
    date1 = d + '/' + m + '/' + today.getFullYear();
    return date1;
  }

  const getNextAvailibility = (schedules) => {
    let result = '';
    if (schedules.length) {
      let day = new Date();
      day.setDate(day.getDate() + 1);
      let nextDay = getDate(day);
      if (schedules[0].date == nextDay) {
        result = 'Tomorrow';
      } else if (new Date(schedules[0].date) == day) {
        result = 'Today';
      } else {
        result = schedules[0].date;
      }
    } else {
      result = 'Not Available'
    }
    return result;
  }

  const getFormatedClinicianData = (uData) => {
    let nextAvailable = null;
    let clinician = uData;
    let schedules = uData.schedule ? uData.schedule : [];
    if (schedules && schedules.length) {
      schedules = schedules.map(sch => {
        if (sch.slots.length) {
          sch.slots = sch.slots.map(s => {
            if (s) {
              let timeArr = s.split(':');
              let hrs = '00', min = '00';
              if (timeArr.length) {
                hrs = timeArr[0];
                min = timeArr[1];
              }
              if (timeArr[0].length === 1) {
                hrs = `0${timeArr[0]}`;
              }
              if (timeArr[1].length === 1) {
                min = `0${timeArr[1]}`;
              }
              let time = `${hrs}:${min}`;
              let lclDate = new Date(`${sch.date}T${time}:00Z`);
              let lclHrs = lclDate.getHours().toString();
              if (lclHrs.length === 1) {
                lclHrs = `0${lclHrs}`;
              }
              return `${lclHrs}:${lclDate.getMinutes() > 0 ? lclDate.getMinutes() : '00'}`;
            } else {
              return s;
            }
          });
        }
        return sch;
      });
      for (let sch of schedules) {
        if (sch.slots.length) {
          nextAvailable = sch;
          break;
        }
      }
    }
    const data = {
      id: clinician._id,
      name: `Dr. ${clinician.firstName} ${clinician.lastName}`,
      study: '',
      speciality: 'Specialist Psychiatrist',
      times: schedules,
      img: clinician.avatar ? clinician.avatar : clinician1,
      cost: null,
      appointmenSlot: clinician.appointmentSlotUnit,
      amount: clinician.costPerUnit,
      selectTimeSlot: null,
      isAlternative: false,
      nextAvailable,
      nextAvailiblityText: getNextAvailibility(schedules),
    };
    if (clinician._id !== localStorage.getItem('clinicianUID')) {
      data.cost = `${clinician.costPerUnit} Dhs / ${clinician.appointmentSlotUnit}min`;
    }
    return data;
  }

  const handleAlternativeCalendar = () => {
    setClinicianDetails({ ...clinicianDetails, isAlternative: !clinicianDetails.isAlternative });
  };

  const handleTimeSelect = (value) => {
    setClinicianDetails({
      ...clinicianDetails,
      status: 'selected',
      selectTimeSlot: value,
      isAlternative: false,
    });
  };

  const formatDateYMD = (x, y) => {
    var z = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds(),
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
      return ((v.length > 1 ? '0' : '') + eval('z.' + v.slice(-1))).slice(-2)
    });

    return y.replace(/(y+)/g, function (v) {
      return x.getFullYear().toString().slice(-v.length)
    });
  }

  const handleCalendarTimeSelect = (times, value, date) => {
    let newDate = formatDateYMD(date, 'yyyy-MM-dd');
    setClinicianDetails({
      ...clinicianDetails,
      status: 'selected',
      selectTimeSlot: new Date(`${newDate} ${value}`),
      isAlternative: false,
    });
  }

  const handleNewORreferralClick = (pData) => {
    if (pData) {
      setOpenScheduleModal(true);
      if (modalActionType === 'init_referral') {
        let clinicianData = getFormatedClinicianData(pData);
        setClinicianDetails(clinicianData);
      }
    }
  }

  const handleCloseSchedule = () => {
    setOpenScheduleModal(false);
  }

  const handleCancelAppointment = (index) => {
    let tmpClinicianDetails = { ...clinicianDetails };
    tmpClinicianDetails = {
      ...tmpClinicianDetails,
      selectTimeSlot: null,
    };
    setClinicianDetails({ ...tmpClinicianDetails });
  };

  const handleAppointmentConfirm = () => {
    if (appointmentDetails) {
      setConfirmBtnLoading(true);
      let clientIds = appointmentDetails.clientId.map(item => {
        return item._id;
      });
      let data = {
        clinicianId: clinicianDetails.id,
        startTime: clinicianDetails.selectTimeSlot,
        duration: clinicianDetails.appointmenSlot,
        amount: clinicianDetails.amount,
        bookedBy: localStorage.getItem('clinicianUID'),
        clientId: clientIds,
      };
      Axios.post(`${baseURL}/api/appointment/followUpAppointment`, data, {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      })
        .then(res => {
          if (res.data) {
            setClinicianDetails(null);
            setOpenScheduleModal(false);
            setNewReferralModalOpen(false);
            setModalActionType('');
            setConfirmBtnLoading(false);
            AlertMsg('success', 'Appointment successfully referred.');
          }
        })
        .catch((e) => {
          AlertMsg('error', e.reason)
          setConfirmBtnLoading(false);
        });
    } else {
      return null;
    }
  };

  const handleInitReferralClick = () => {

    getAllClinicians();
    setModalActionType('init_referral');
    setNewReferralModalOpen(true);
  }

  const handleEditRecord = (id) => {
    history.push(`/clinician/appointments/editPatientRecord/${id}`);
  }

  const handleReschedule = () => {
    Axios.get(`${baseURL}/api/appointment/dashboard`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {

        setAppointmentList(res.data);
      })
      .catch((e) => {
        AlertMsg('error', e.reason)
      });
  }

  const renderSessionTime = (app) =>
    `${moment(app.startTime).format('LT')} - ${moment(app.startTime)
      .add(app.duration, 'minutes')
      .format('LT')}`;

  const openSignatureCanvas = (appId, clinicianSignDate) => {
    if (!clinicianSignDate) {
      AlertMsg(
        'error',
        'Witness can only sign if clinician has signed already!'
      );
      return;
    }
    setAppointmentId(appId);
    setCanvasDialog(true);
  };

  const updateToCheckIn = (row) => {
    if (row.status === 'confirmed') {
      handleCheckIn(row._id);
    } else if (row.status === 'checked-in') {
      setStartSessionConfirmation(true);
      setAppointmentId(row._id);
    } else if (
      row.status === 'waiting' ||
      row.status === 'reffered-waiting' ||
      row.status === 'reffered' ||
      row.status === 'unconfirmed'
    ) {
      handleBook(row);
    }
  };


  const handleCheckIn = (id) => {
    let data = {
      checkedIn: true,
    };

    Axios.patch(`${baseURL}/api/appointment/checkin/${id}`, data, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res) {
          const { status, checkedIn } = res.data;
          const newList = appointmentList.map((item) => {
            if (item._id === id) {
              return {
                ...item,
                status,
                checkedIn,
              };
            } else {
              return item;
            }
          });
          setAppointmentList(newList);
          if (status === 'checked-in') {
            AlertMsg('success', 'Succesfully checked in Appointment');
          } else {
            AlertMsg('success', 'Succesfully reverted checked in status');
          }
        }
      })
      .catch((e) => {
        AlertMsg('error', 'Something went wrong!');
        console.log('eeeeeeee', e);
      });
  };

  const handleAppointmentBooking = (data) => {
    handleBook(data);
  };

  const handleBook = (data) => {
    setAppointmentDetails(data);
    setAppointmentType({
      clinicianId: localStorage.getItem('clinicianUID'),
    });
    setModalActionType('patients');
    setOpenCreateAppointment(true);
  };


  return (
    <Layout appointments={appointmentList}>
      <div className="flex h-screen text-left">
        <div className="w-1/2 h-full overflow-y-scroll px-4">
          <p className="font-medium text-xl">Today <span className="text-sm text-gray-500 font-base mx-2">({combinedList ? combinedList.length : 0} appointments)</span></p>
          <div className="list-of-appointments mt-4">
            {combinedList && combinedList.length > 0 ?
              combinedList.map((item, index) => {
                return (
                  <>
                    {!item.appointmentType ?
                      <div className="flex justify-between bg-blue-100 my-2 px-8 py-4" key={index}>

                        <div className="">
                          <p className="font-medium text-blue-900">{item.type}</p>
                          <p className="text-gray-500">Block</p>
                        </div>

                        <div className="">
                          <p className="font-medium text-blue-900">{`${convertUTCDateToLocalDate(item.startTime, 'h:mma')} - ${convertUTCDateToLocalDate(item.endTime, 'h:mma')}`}</p>
                          <p className="text-gray-500">{item.appointmentDuration} minutes</p>
                        </div>

                      </div>
                      :

                      <div className="flex items-center bg-white justify-between my-2 px-8 py-2 list-of-appointments__item" key={index}>

                        <div className="flex items-center">
                          <div className="profile-image h-12 w-12 rounded-full">
                            <img className="bg-cover rounded-full" src={item.clientId.length > 0 && item.clientId[0].avatar ? item.clientId[0].avatar : clinician1} alt="" />
                          </div>
                          <div className="name-and-type mt-2 ml-8">
                            <p className="font-bold text-lg">{item.clientId.length > 0 && item.clientId[0].firstName + ' ' + item.clientId[0].lastName}</p>
                            <p className="text-gray-500">{item.groupType}</p>
                          </div>
                        </div>

                        <div className="timings">
                          <p className="">{convertUTCDateToLocalDate(item.startTime, 'h:mma')}</p>
                          <p className="text-gray-500">{item.duration} minutes</p>
                        </div>
                        <div className="flex action-icons">
                          <div className="bg-blue-200 cursor-pointer p-2 mx-3 rounded-full" onClick={() => updateToCheckIn(item)}>
                            <i className="fas fa-door-open"></i>
                          </div>
                          <div className="bg-blue-200 cursor-pointer p-2 mx-3 rounded-full">
                            <i className='fas fa-eye' onClick={() => {
                              setDetailsOnPatient(false);
                              setDetailDrawer(true);
                              setAppointmentId(item._id);
                              setAppointmentDetails(item);
                            }}> </i>
                          </div>
                        </div>
                      </div>
                    }
                  </>
                );
              }) :
              <>{combinedList ?
                <p>No Appointments today</p>
                :
                <Loader />}
              </>

            }
          </div>
        </div>

        <div className="flex flex-col h-full mx-4 w-1/2">


          <div className="h-1/2 px-4">
            <p className="font-medium text-xl">Incomplete Clinician Notes</p>
            <div className="my-4 overflow-y-scroll h-full">
              {incompleteClinitcianNotes && incompleteClinitcianNotes.length > 0 ?
                incompleteClinitcianNotes.map((item, index) => {
                  return (
                    <div className="flex items-center bg-white justify-between my-2 px-8 py-2 list-of-appointments__item" key={index}>

                      <div className="flex items-center">
                        <div className="profile-image h-12 w-12 rounded-full">
                          <img className="bg-cover rounded-full" src={item.clientId.length > 0 && item.clientId[0].avatar ? item.clientId[0].avatar : clinician1} alt="" />
                        </div>
                        <div className="name-and-type mt-2 ml-8">
                          <p className="font-bold text-lg">{item.clientId.length > 0 && item.clientId[0].firstName + ' ' + item.clientId[0].lastName}</p>
                          <p className="text-gray-500">{item.groupType}</p>
                        </div>
                      </div>

                      <div className="timings">
                        <p className="">{convertUTCDateToLocalDate(item.startTime, 'h:mma')}</p>
                        <p className="text-gray-500">{item.duration} minutes</p>
                      </div>
                      <div className="flex action-icons">
                        <div className="bg-blue-200 cursor-pointer p-2 mx-3 rounded-full">
                          <i className='fas fa-eye'> </i>
                        </div>
                      </div>
                    </div>
                  );
                }) :
                <>{incompleteClinitcianNotes ? <p>There are no pending clinical notes</p> : <Loader />} </>
              }
            </div>
          </div>


          <div className="h-1/2 px-4 mt-8">
            <p className="font-medium text-xl">Unsigned Consent Forms</p>
            <div className="my-4 overflow-y-scroll h-full">
              {consentForms && consentForms.length > 0 ?
                consentForms.map((item, index) => {
                  return (
                    <div className="flex items-center bg-white justify-between my-2 px-8 py-2 list-of-appointments__item" key={index}>
                      <div className="flex items-center">
                        <div className="profile-image h-12 w-12 rounded-full">
                          <img className="bg-cover rounded-full" src={item.clientSign._id.length > 0 && item.clientSign._id.avatar ? item.clientSign._id.avatar : clinician1} alt="" />
                        </div>
                        <div className="name-and-type mt-2 ml-8">
                          <p className="font-bold text-lg">{item.clientSign._id.length > 0 && item.clientSign._id[0].firstName + ' ' + item.clientSign._id[0].lastName}</p>
                          <p className="text-gray-500">minor</p>
                        </div>
                      </div>

                      <div className="timings">
                        <p> {moment(item.appointmentId[0].startTime).format('L')}</p>
                        <p> {renderSessionTime(item.appointmentId[0])} </p>
                      </div>
                      <div className="flex action-icons">

                        <div className="bg-blue-200 cursor-pointer p-2 mx-3 rounded-full">
                          <i className="fas fa-eye" onClick={() =>
                            handleDetailDrawer(item.appointmentId[0]._id)
                          }></i>
                        </div>

                        <div className="bg-blue-200 cursor-pointer p-2 mx-3 rounded-full">
                          <i className='fas fa-signature' onClick={() =>
                            openSignatureCanvas(
                              item.appointmentId[0]._id,
                              item.clinicianSign?.signDate
                            )
                          }> </i>
                        </div>

                      </div>

                    </div>
                  );
                })
                :
                <>{consentForms ? <p>There are no pending consent forms</p> : <Loader />} </>
              }
            </div>
          </div>
        </div>

      </div>
      <div className=""></div>
      <div className=""></div>
      {/* <FilterModalBox handleInitReferralClick={handleInitReferralClick} handleEditRecord={handleEditRecord} handleDetailDrawer={handleDetailDrawer} appointmentId={appointmentId} detailDrawer={detailDrawer} handleSubmitRequest={handleSubmitRequest} setDetailDrawer={setDetailDrawer} openPatientDrawer={openPatientDrawer} refresh={handleReschedule} /> */}
      <AppointmentDetailsView
        appointmentId={appointmentId}
        handleClose={() => setDetailDrawer(false)}
        open={detailDrawer}
        handleInitReferralClick={() => { }}
        handleEditRecord={() => { }}
        refresh={() => appointmentList}
      />
      <PatientDetailClinician togglePatientDrawer={togglePatientDrawer} patientDrawer={patientDrawer} patientId={patientId} />
      {modalActionType &&
        <NewPatientsReferral actionType={modalActionType} open={newReferralModalOpen} handleClose={evt => {
          setModalActionType('');
          setNewReferralModalOpen(false);
          setPatientList([]);
          setClinicianDetails(null);
        }} handleSubmit={handleNewORreferralClick} userList={patientList} loading={loading} openAutoComp={open} setOpenAutoComp={setOpen} />
      }
      <ScheduleModal clinicianDetails={clinicianDetails} open={openScheduleModal} handleClose={handleCloseSchedule} handleCalendarTimeSelect={handleCalendarTimeSelect} handleCancelAppointment={handleCancelAppointment} handleAppointmentConfirm={handleAppointmentConfirm} handleTimeSelect={handleTimeSelect} handleAlternativeCalendar={handleAlternativeCalendar} confirmBtnLoading={confirmBtnLoading} />
    </Layout>
  );
}

DashboardClinician.propTypes = {
  getConsentForms: PropTypes.func.isRequired,
  consent: PropTypes.shape({
    data: PropTypes.array,
  }),
}

const mapStateToProps = state => ({
  consentForms: state.getConsentForms,
  consent: state.getConsentForms,

});

const mapDispatchToProps = (dispatch) => ({
  getConsentForms: (clinicianId, filters) => dispatch(getConsentForms(clinicianId, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardClinician);
