/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import styles from "./ProgressBar.styles";



const ProgressBar = ({ queIndex, questions, ...props }) => {
  const classes = styles();
  return (
    <Grid
      container
      component="div"
      className={classes.progressBarContainer}
      {...props}
    >
      <Grid component="div" className={classes.progressOuter} />
      <Grid
        component="div"
        style={{
          float: "left",
          backgroundColor: "#21aa00",
          width: `calc(${(queIndex / questions.length) * 190}px)`,
          maxWidth: "190px",
          position: "absolute",
          // left: 0,
          height: "10px",
          borderRadius: "25px",
          margin: "6px",
        }}
      />
      {/* <Typography className={classes.progressFont}>
        {`${queIndex}/${questions.length}`}
      </Typography> */}
    </Grid>
  );
};

ProgressBar.defaultProps = {
  questions: [],
  queIndex: 1,
};

export default ProgressBar;
