/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import styles from './HomeBoxes_style';
import Axios from 'axios';
import { baseURL } from '../../utils/axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export const HomeBoxes = ({ data, ...props }) => {
  const classes = styles();
  const [openDialog, setOpenDialog] = React.useState(false);

  const checkWaiting = () => {
    console.log('asdsa das dsa');
    Axios.get(`${baseURL}/api/appointment/canBook`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data && res.data.canBook) {
          props.history.push('/appointment-for');
        } else {
          setOpenDialog(true);
        }
      })
      .catch((e) => {});
  };

  return (
    <>
      <Grid
        style={{ display: 'flex' }}
        container
        alignItems='flex-start'
        justify='space-between'
      >
        {data.map((node) => (
          <Grid
            container
            className={classes.root}
            alignItems='center'
            justify='center'
            {...props}
          >
            <Grid container alignItems='center' justify='center'>
              <Typography className={classes.title}>{node.title}</Typography>
              <Typography className={classes.subTitle}>
                {node.subtitle}
              </Typography>
            </Grid>
            <Grid
              container
              alignItems='center'
              justify='center'
              className={classes.body}
            >
              <Typography
                className={classes.bodyTitle}
                style={{ marginBottom: '25px' }}
              >
                {node.bodyTitle}
              </Typography>
              <Typography className={classes.bodyTitle}>
                {node.bodyDesc}
              </Typography>
            </Grid>

            <Grid
              className={classes.button2}
              container
              alignItems='center'
              justify='center'
              onClick={() =>
                props.history.push({
                  pathname: '/appointment-for',
                  search: '?type=user',
                  // search: `?type=user&appointmentid=${this.props.addAppointment._id}`,
                })
              }
            >
              <Typography className={classes.button2Text}>
                Schedule an appointment
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Can not book a new appointment!'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            We see that you already are in waitlist, please wait for us to
            review the request.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            color='primary'
            autoFocus
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
