/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { createStyles, withStyles, Grid, Typography } from '@material-ui/core';
import rightIcon from '../../../assets/imgs/right_icon.svg';
import DividerLine from '../../../assets/imgs/divider.svg';
import downArrow from '../../../assets/imgs/Border.svg';
import { CustomNextButtons } from '../../../common/CustomButtons';

import { CustomCalendar } from '../../../Patient/components/Calendar/CustomCalendar';
import arrowUp from '../../../assets/imgs/Border (4).svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './YourMatchedClinicianStyle';

class YourMatchedClinician extends Component {
  getEvents = (schedule) => {
    var temArr1 = [];
    if (schedule && schedule.length) {
      // var keys = Object.keys(schedule)
      schedule.map((item, i) => {
        let times = [];
        if (item.slots) {
          item.slots.map((val) => {
            times.push({ time: val });
          });
        }
        let start = new Date(item.date);
        var tomorrow = new Date(item.date);
        tomorrow.setDate(tomorrow.getDate() + 1);

        temArr1.push({
          title: `My event ${i}`,
          times: times,
          allDay: false,
          start: Date.parse(item.date + 'T00:00:00'), // 10.00 AM
          end: Date.parse(item.date + 'T00:00:00'), // 2.00 PM
          col: '#ee3559',
        });
      });
    }
    return temArr1;
  };

  render() {
    const {
      classes,
      clinicianList,
      selectTimeSlot,
      appointmentConfirmation,
      onAppointmentConfirm,
      props,
      onAlternativeCalendar,
      onSelectEvent,
      confBtnLoading,
      addToWaitList,
    } = this.props;

    return (
      <>
        {clinicianList.map((data, index) => (
          <>
            {appointmentConfirmation && data.status === 'selected' && (
              <>
                <Grid
                  className={
                    data.status === 'selected'
                      ? classes.selectedContainer
                      : null
                  }
                />
                <Grid container>
                  <Grid
                    container
                    justify='center'
                    alignItems='center'
                    onClick={() => this.props.appointmentCancel(index)}
                    className={classes.appointmentCancelButton}
                  >
                    <Typography
                      style={{
                        color: 'red',
                        fontFamily: 'Lato',
                        fontWeight: 700,
                        fontSize: '17px',
                      }}
                    >
                      X
                    </Typography>
                  </Grid>
                  {confBtnLoading ? (
                    <Grid
                      container
                      alignItems='center'
                      justify='center'
                      className={classes.confirmAppointmentContainer}
                    >
                      <CircularProgress size={20} color={'white'} />
                    </Grid>
                  ) : (
                    <Grid
                      container
                      alignItems='center'
                      justify='center'
                      className={classes.confirmAppointmentContainer}
                      onClick={() => onAppointmentConfirm(index)}
                    >
                      <Typography className={classes.confirmAppointmentText}>
                        Confirm your appointment
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
            <Grid
              key={index}
              container
              alignItems='center'
              justify='center'
              component='div'
              className={classes.doctorProfileContainer}
            >
              <Grid className={classes.doctorProfileSubContainer}>
                <Grid style={{ display: 'flex' }}>
                  <Grid xs={4}>
                    <Grid className={classes.nameContainer}>
                      <Typography className={classes.doctorName}>
                        {data.name}
                        {data.study && ','}
                      </Typography>
                      {data.study && (
                        <Typography className={classes.studyText}>
                          &nbsp;
                          {data.study}
                        </Typography>
                      )}
                    </Grid>
                    <Typography className={classes.doctorSpecialityText}>
                      {data.speciality}
                    </Typography>
                  </Grid>
                  <Grid className={classes.profileImageContainer} xs={4}>
                    <img src={data.img} className={classes.profileImage} />
                    {data.status === 'selected' && (
                      <Grid
                        container
                        justify='center'
                        alignItems='center'
                        className={classes.clinicianSelectIcon}
                      >
                        <img
                          src={rightIcon}
                          className={classes.rightIconText}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <img src={DividerLine} className={classes.dividerLine} />
                <Grid
                  component='div'
                  className={classes.availablityCostContainer}
                >
                  <Typography className={classes.nextAvailabilityText}>
                    {data.availabilityTime === 'not available'
                      ? 'No Availability'
                      : `Next Availability (${data.nextAvailiblityText})`}
                  </Typography>
                  {/* <Grid style={{ position: "absolute", right: 40 }}>
                    <CustomNextButtons
                      title={`Cost: ${data.cost}`}
                      width="229px"
                      style={classes.costContainer}
                    />
                  </Grid> */}
                </Grid>
                <Grid
                  component='div'
                  className={classes.availablityCostContainerMobile}
                >
                  {/* <Grid style={{ marginTop: "10px" }}>
                    <CustomNextButtons
                      title={`Cost: ${data.cost}`}
                      width="229px"
                      style={classes.costContainer}
                    />
                  </Grid> */}
                  <Typography className={classes.nextAvailabilityText}>
                    {data.availabilityTime === 'not available'
                      ? 'No Availability'
                      : `Next Availability (${data.nextAvailiblityText})`}
                  </Typography>
                </Grid>
                <Grid className={classes.timeListContainer}>
                  {data.nextAvailable && data.nextAvailable.slots.length > 0 ? (
                    data.nextAvailable.slots.map((time, i) => {
                      return (
                        <Grid
                          key={i}
                          container
                          justify='center'
                          alignItems='center'
                          className={classes.timeContainer}
                          onClick={() =>
                            selectTimeSlot(
                              index,
                              new Date(`${data.nextAvailable.date} ${time}`)
                            )
                          }
                          style={{
                            backgroundColor:
                              data.slot ===
                              new Date(`${data.nextAvailable.date} ${time}`)
                                ? '#304659'
                                : '',
                          }}
                        >
                          <Typography
                            className={classes.timeText}
                            style={{
                              color:
                                data.slot ===
                                new Date(`${data.nextAvailable.date} ${time}`)
                                  ? '#ffffff'
                                  : '',
                            }}
                          >
                            {time}
                          </Typography>
                        </Grid>
                      );
                    })
                  ) : (
                    <div />
                  )}
                </Grid>
                {!data.nextAvailable ? (
                  <Grid xs={6} style={{ textAlign: 'left' }}>
                    We apologize, but this clinician has a long waiting list. Do
                    you wish to continue?
                  </Grid>
                ) : (
                  <div />
                )}
                {!data.nextAvailable ? (
                  <div>
                    <Grid style={{ marginTop: '30px' }}>
                      <Grid
                        container
                        alignItems='center'
                        justify='center'
                        className={classes.waitingList}
                        onClick={() => this.props.addToWaitList(index)}
                      >
                        <Typography style={{ color: '#fff' }}>
                          Add to Waiting list
                        </Typography>
                      </Grid>
                    </Grid>
                    {data.justAdded != null && data.justAdded ? (
                      <Grid style={{ marginTop: '16px' }}>
                        <Grid
                          container
                          alignItems='center'
                          justify='center'
                          className={classes.cancelButtonContainer}
                          onClick={() => this.props.handleRemove(index)}
                        >
                          <Typography className={classes.cancelText}>
                            Remove Clinician
                          </Typography>
                          <Grid
                            container
                            alignItems='center'
                            justify='center'
                            style={{
                              width: '19px',
                              height: '20px',
                              backgroundColor: '#ffffff',
                            }}
                          >
                            <Typography className={classes.xIcon}>x</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <div />
                    )}
                    <br />
                  </div>
                ) : (
                  <Grid className={classes.alternativeTextContainer}>
                    {data.nextAvailable &&
                    data.nextAvailable.slots.length > 0 &&
                    data.isAlternative ? (
                      <>
                        <Typography
                          className={classes.alternativeText}
                          onClick={() => onAlternativeCalendar(index)}
                        >
                          Hide calendar
                        </Typography>
                        <img
                          src={arrowUp}
                          className={classes.downArrowIcon}
                          onClick={() => onAlternativeCalendar(index)}
                        />
                      </>
                    ) : (
                      <>
                        <Typography
                          className={classes.alternativeText}
                          onClick={() => onAlternativeCalendar(index)}
                        >
                          Alternative slots
                        </Typography>
                        <img
                          src={downArrow}
                          className={classes.downArrowIcon}
                          onClick={() => onAlternativeCalendar(index)}
                        />
                      </>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
            {data.isAlternative ? (
              <CustomCalendar
                events={this.getEvents(data.times)}
                onSelectEvent={(times, time, date) =>
                  onSelectEvent(index, time, date)
                }
              />
            ) : null}
          </>
        ))}
        {/* </Grid>
        </Grid> */}
      </>
    );
  }
}

YourMatchedClinician.defaultProps = {
  clinicianList: [],
  selectTimeSlot: () => {},
  onAlternativeCalendar: () => {},
  onSelectEvent: () => {},
};

export default withStyles(styles)(YourMatchedClinician);
