/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from "react";
import {
  
  withStyles,
  Typography,
  Divider,
  Grid,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import headerLogo from "../../assets/imgs/Background.png";
import styles from "./ConsentFormStyle";

class ConsentForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div style={{ display: "flex" }}>
          <img src={headerLogo} style={{ height: "117px", width: "490px" }} />
        </div>
        <div className={classes.container}>
          <Typography component="p" className={classes.heading}>
            TREATMENT POLICY & INFORMED CONSENT
          </Typography>
          <Divider
            style={{
              backgroundColor: "#000000",
              border: "0.5px solid #a39161",
              width: "360px",
            }}
          />
          <Typography component="p" className={classes.subHeading}>
            OCCUPATIONAL THERAPY (OT) AND SPEECH & LANGUAGE THERAPY (SLT)
          </Typography>
          <Typography component="p" className={classes.firstParaStyle}>
            Welcome to The LightHouse Arabia – Center for Wellbeing. This
            document contains important information about our Professional
            Services and business practices. Please read it carefully and jot
            down any questions you might have so that we can discuss
          </Typography>
          <Typography className={classes.secParaStyle}>
            This contract is a reciprocal agreement with corresponding rights
            and responsibilities on both sides.{" "}
          </Typography>
          <Typography className={classes.subHeadingText}>
            THE THERAPEUTIC RELATIONSHIP
          </Typography>
          <Typography className={classes.mainParaStyle}>
            All clinicians are required to adhere to the code of ethics and
            standards of practice as put forth by the Dubai Health Authority
            (DHA) and Community Development Authority (CDA). This code precludes
            dual relationships (any relationship other than that of a client and
            t herapist) in order to protect the rights of the clients and
            maintain the objectivity and professional judgment of the provider
            of services. In the event that a relationship outside the
            therapeutic relationship is unavoidable, the provider of services
            will discuss the situation with the client and resolve the issue
            with the client professionally and in a manner most suitable to the
            client’s needs.
          </Typography>
          <Typography className={classes.subHeadingText}>
            SERVICES OFFERED
          </Typography>
          <div className={classes.listContainer}>
            <Typography component="ul">
              <Typography component="li" className={classes.listPoint}>
                <Typography component="p" className={classes.mainParaStyle}>
                  PSYCHOLOGICAL & PSYCHIATRIC SERVICES
                </Typography>
              </Typography>
              <Typography component="li" className={classes.listPoint}>
                <Typography component="p" className={classes.mainParaStyle}>
                  SPEECH & LANGUAGE THERAPY SERVICES
                </Typography>
              </Typography>
              <Typography component="li" className={classes.listPoint}>
                <Typography component="p" className={classes.mainParaStyle}>
                  OCCUPATIONAL THERAPY SERVICES
                </Typography>
              </Typography>
            </Typography>
          </div>
          <Typography className={classes.subHeadingText}>
            OCCUPATIONAL THERAPY AND SPEECH & LANGUAGE THERAPY
          </Typography>
          <div>
            <Typography paragraph className={classes.mainParaStyle}>
              The initial session is referred to as the intake meeting. During
              this session the clinician will gather a history of the presenting
              concerns and other background information.
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              Following the intake meeting, the evaluation may take more than
              one session depending on the client presenting concerns. By the
              end of the evaluation, the clinician will be able to offer initial
              impressions of the services required and the treatment plan.
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              The clinician will provide recommendations on the length and
              frequency of sessions. Sessions may be based in the center or the
              school depending on clinician recommendations, availability and
              collaboration agreements.
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              The clinician will provide weekly feedback either verbally or
              written as agreed with the client/client’s caregivers.
            </Typography>
          </div>
          <Typography className={classes.subHeadingText}>
            CONFIDENTIALITY & LIMITS OF CONFIDENTIALITY
          </Typography>
          <>
            <Typography paragraph className={classes.mainParaStyle}>
              With the exception of specific limits of confidentiality described
              below, clients have the absolute right to confidentiality of their
              information. Clients are assured that all personal information
              gathered by the clinicians during the provision of the
              Occupational Therapy service and the Speech & Language Therapy
              Service will remain confidential and secure.
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              If the clinician needs to obtain/release information from other
              individuals, doctors, and/or schools – a written release of
              information will be signed by the client to authorize such
              activities. Clinicians may occasionally find it necessary to
              consult other professionals about client treatment for the
              purposes of supervision, or clinical peer review. During a
              professional consultation, no identifying information about
              clients will be revealed. The consultant will also be bound to
              keep all information confidential.
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              For Occupational Therapy Services and Speech & Language Therapy
              Services, photographic and video recording may be required/
              collected for diagnostic and/or therapeutic purposes. Please note
              that this content may only be shared with the clinical team
              involved and the client/client’s caregivers.
            </Typography>
          </>
          <div className={classes.boxContainer}>
            <Typography className={classes.subHeadingText}>
              Limits of Confidentiality
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              While clients have rights to privacy, there are limitations. The
              limits of confidentiality are:
            </Typography>
            <div className={classes.listContainer}>
              <Typography component="ul">
                <Typography component="li" className={classes.listPoint}>
                  <Typography component="p" className={classes.mainParaStyle}>
                    When the client waives their right to privacy and gives
                    written consent (clients over the age of 21).
                  </Typography>
                </Typography>
                <Typography component="li" className={classes.listPoint}>
                  <Typography component="p" className={classes.mainParaStyle}>
                    When, based on sound clinical judgment, disclosure is
                    required to prevent clear and imminent danger to the client
                    or others around you and the client is unwilling or unable
                    to contract for safety.
                  </Typography>
                </Typography>
                <Typography component="li" className={classes.listPoint}>
                  <Typography component="p" className={classes.mainParaStyle}>
                    In matters affecting the welfare or abuse of children or the
                    elderly.
                  </Typography>
                </Typography>
                <Typography component="li" className={classes.listPoint}>
                  <Typography component="p" className={classes.mainParaStyle}>
                    When ordered by an official of the court as required by law.
                  </Typography>
                </Typography>
              </Typography>
            </div>
          </div>
          <Typography className={classes.subHeadingText}>
            WORKING WITH MINORS
          </Typography>
          <>
            <Typography paragraph className={classes.mainParaStyle}>
              When treating minors (individuals under the age of 21), parents
              have rights to access records. It is our policy to request an
              agreement from parents that they agree to give up access to the
              records to encourage trust and protect the therapeutic alliance.
              If parents agree, they will be provided with only general
              information of the work together, unless the clinician feels that
              there is a high risk that the minor will seriously harm themselves
              or someone else. In this case, clinicians will notify parents of
              their concern. Clinicians will also provide parents with a verbal
              summary of the work frequently and upon treatment completion on a
              planned basis (and will be charged for).
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              Children of divorced or separated parents must have consent from
              both parents, and/or the primary custodian (with court-attested
              documentation). Your child’s therapist will not communicate with
              attorneys for either parent or guardian. Any information provided
              by one parent may be shared with the other parent by the child’s
              therapist if custody is shared
            </Typography>
          </>
          <Typography className={classes.subHeadingText}>DIAGNOSIS</Typography>
          <>
            <Typography paragraph className={classes.mainParaStyle}>
              If you are claiming your payment with an insurance company/ or
              place of employment, clinicians are normally required to give a
              diagnosis to that third party in order to be paid. Diagnoses are
              technical terms that describe the nature of your problems and
              something about whether they are short-term or long-term problems.
              If the clinicians do use a diagnosis, they will discuss it with
              you. All of the diagnoses come from clinical manual titled the
              ICD-10.
            </Typography>
            <Grid container>
              <Grid item lg={1} />
              <Grid item lg={1}>
                <Typography
                  component="p"
                  style={{ textAlign: "left" }}
                  className={classes.tableText}
                >
                  821 Al Wasl Rd Al Safa 2 P.O. Box 33427 Dubai, UAE
                </Typography>
              </Grid>
              <Grid item lg={1} />
              <Grid item lg={3} style={{ float: "left" }}>
                <Typography
                  component="span"
                  style={{ textAlign: "left" }}
                  className={classes.tableText}
                >
                  <Typography
                    component="label"
                    className={classes.tableText}
                    style={{ color: "#a39161" }}
                  >
                    T.
                  </Typography>
                  +971(0)43802088{" "}
                </Typography>
                <Typography
                  component="span"
                  style={{ textAlign: "left" }}
                  className={classes.tableText}
                >
                  <Typography
                    component="label"
                    className={classes.tableText}
                    style={{ color: "#a39161" }}
                  >
                    E.
                  </Typography>
                  info@lighthousearabia.com
                </Typography>
                <Typography
                  component="p"
                  style={{ textAlign: "left", color: "#a39161" }}
                  className={classes.tableText}
                >
                  lighthousearabia.com
                </Typography>
              </Grid>
            </Grid>
          </>
          <Typography className={classes.subHeadingText}>
            OCCUPATIONAL THERAPY AND SPEECH & LANGUAGE THERAPY APPOINTMENTS
          </Typography>
          <>
            <Typography paragraph className={classes.mainParaStyle}>
              Appointment duration, times, and frequency will be determined
              based on the individual needs of the client. Generally,
              appointments will last for 60 minutes (50 minutes are typically
              spent directly with the client). Please note that the clinician
              may take 5-10 minutes (depending on session length) at the end of
              the session to discuss feedback with caregivers and complete
              related administrative tasks such as clinical contact notes or
              update the clients care plan, as required by the Dubai Healthcare
              Authority. All phone conversations, emails, or reports that
              require are more than 15 minutes are deemed to constitute a
              therapy session and will be billed at the standard applicable
              hourly rate (pro-rated, as necessary, for the actual amount of
              time spent).
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              Treatment frequency and duration will vary from individual to
              individual and the specific problem(s) targeted. An estimate of
              treatment duration can be provided, but clients are reminded that
              it is only an estimate. Treatment duration will vary depending on
              what clients/caregivers hope to accomplish. Shorter-term
              treatments routinely target symptom relief and a return to
              baseline functioning. Enduring changes in longstanding patterns of
              behavior or chronic problems will naturally require longer-term
              care.
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              The clinician will in conjunction with the client decide when to
              terminate therapy based on the client’s needs. In the event the
              clinician feels he/she is unable to assist client, when it is
              clear that the client is no longer benefiting, when services are
              no longer meeting the needs of the client, or the client is not
              willing to pay the agreed upon fees for services, services will be
              terminated. Clients have the right to withdraw from treatment at
              any time and if they are dissatisfied with the nature of treatment
              progress, an alternative referral will be provided.
            </Typography>
          </>
          <Typography className={classes.subHeadingText}>
            FEES, BILLING & PAYMENT
          </Typography>
          <>
            <Typography paragraph className={classes.mainParaStyle}>
              The standard fee for a 60-minute Occupational Therapy session or a
              Speech & Language Therapy session is AED 550, however, discounted
              rates may apply for school-based services. If clients/caregivers
              wish to purchase a package of 10 sessions, they will receive a
              discount of 5% and, if they wish to purchase a package of 20
              sessions, they will receive a discount of 10%. There is an AED 50
              registration fee collected at the first session.
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              These fees are not inclusive of reports or letters requested. If
              an assessment report is requested, the fees commence at AED 1,500
              for a summary report and AED 3,000 for a comprehensive report. The
              fees are collected at the beginning of the month and payment is
              expected in full. Clients will be notified of any changes in fees
              30 days prior to effective date. In the event of cancellations or
              school events, sessions will be rescheduled in the same week or
              carried forward to the subsequent month.
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              Consultations over the phone that are over 15 minutes will be
              charged in 15-minute increments prorated on the clinician’s hourly
              rate. Written reports for schools, companies, and insurance
              companies will also be charged.
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              Clients can make payments using cash/credit/and debit cards.
              Advanced payment of 1-month is required for school-based clients
              so as to secure a regular schedule timing. Services may be
              terminated if payments are in arrears.
            </Typography>
            <Typography
              component="span"
              className={classes.mainParaStyle}
              style={{ fontWeight: "bold" }}
            >
              Insurance:
              <Typography
                paragraph
                component="text"
                className={classes.mainParaStyle}
              >
                {" "}
                The LightHouse Arabia does not direct bill to insurance
                companies. Clients are responsible for filing all insurance
                claims. If clients choose to file an insurance claim, the
                clinician will fill in the necessary information on the claim
                form and a receipt will be provided in a timely manner. Please
                be aware that clinicians may need to meet the client on more
                than one occasion to make a formal diagnosis. It is recommended
                that you contact your insurance provider prior to attending the
                first session to see if psychological services are covered.
              </Typography>
            </Typography>
            <Grid container>
              <Grid item lg={1} />
              <Grid item lg={1}>
                <Typography
                  component="p"
                  style={{ textAlign: "left" }}
                  className={classes.tableText}
                >
                  821 Al Wasl Rd Al Safa 2 P.O. Box 33427 Dubai, UAE
                </Typography>
              </Grid>
              <Grid item lg={1} />
              <Grid item lg={3} style={{ float: "left" }}>
                <Typography
                  component="span"
                  style={{ textAlign: "left" }}
                  className={classes.tableText}
                >
                  <Typography
                    component="label"
                    className={classes.tableText}
                    style={{ color: "#a39161" }}
                  >
                    T.
                  </Typography>
                  +971(0)43802088{" "}
                </Typography>
                <Typography
                  component="span"
                  style={{ textAlign: "left" }}
                  className={classes.tableText}
                >
                  <Typography
                    component="label"
                    className={classes.tableText}
                    style={{ color: "#a39161" }}
                  >
                    E.
                  </Typography>
                  info@lighthousearabia.com
                </Typography>
                <Typography
                  component="p"
                  style={{ textAlign: "left", color: "#a39161" }}
                  className={classes.tableText}
                >
                  lighthousearabia.com
                </Typography>
              </Grid>
            </Grid>
          </>
          <Typography className={classes.subHeadingText}>
            CANCELLATION AND NON-ATTENDANCE OF SESSIONS
          </Typography>
          <Typography paragraph className={classes.mainParaStyle}>
            If for any reason a client cannot attend a scheduled appointment,
            they are requested to contact The LightHouse Arabia as soon as
            possible. Center based appointments should always be cancelled by
            12.00pm noon the day before your appointment and before 8am the same
            day for school-based clients (direct email to clinician). Please
            note that it is our clinic policy that missed appointments or
            appointments cancelled after the above-mentioned timings will be
            charged at the full rate for adults and 50% of the full fee for
            individuals under 18 years.
          </Typography>

          <Typography className={classes.subHeadingText}>
            OFF-SITE CHARGES OR OUTPATIENT SERVICES CHARGE
          </Typography>
          <Typography paragraph className={classes.mainParaStyle}>
            Clients with a request for their clinician to provide off site,
            out-of-clinic, therapeutic support will be charged in accordance
            with the respective clinician’s hourly charges. In addition, there
            will be a onehour minimum charge for travel time to and from the
            site. Please note that this fee does not apply to school-based
            clients due to external collaboration agreements
          </Typography>

          <Typography className={classes.subHeadingText}>
            SKYPE SESSIONS
          </Typography>
          <>
            <Typography paragraph className={classes.mainParaStyle}>
              If the client chooses to engage in sessions via Skype, he/she
              understands that communicating via the Internet is not 100%
              secure. You agree to read what Skype™ writes about its level of
              security and encryption{" "}
              <Typography
                href="https://support.skype.com/en/skype/all/privacy-security/"
                component="a"
              >
                (https://support.skype.com/en/skype/all/privacy-security/)
              </Typography>
              . The LightHouse Arabia should not be held responsible in the
              event that any outside party passes and overrides Skype’s™
              security and discovers your personal or confidential information.
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              The LightHouse Arabia will not offer skype consultations until and
              unless a face to face assessment/ evaluation session has already
              taken place at The Lighthouse premises. This acts as a safeguard
              for the client
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              Distant clients understand that if they are experiencing an
              emergency, seriously considering harming themselves (suicide), or
              considering harming someone else that they should immediately go
              to a mental health hospital or facility, or call their national
              helpline. All Skype™ sessions will be paid prior to the session
              through PayPal or bank transfer.
            </Typography>
          </>
          <Typography className={classes.subHeadingText}>
            CONTACTING CLINICIANS
          </Typography>
          <>
            <Typography paragraph className={classes.mainParaStyle}>
              The LightHouse Arabia operates Saturday – Thursday, 8am-8pm.
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              If clients need to contact their clinicians out of the therapy
              appointments times, they may call or email
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              Be aware that clinicians may not be available or be able to
              respond immediately. Every effort will be made to return client
              calls within 24 hrs. It is important to note that The LightHouse
              Arabia clinicians do not provide crisis services. Should clients
              require after-hours services due to mental or emotional distress
              they must contact the emergency room at the nearest hospital. If
              the clinician is unavailable for an extended time, clients will be
              provided with the name of a trusted clinician/colleague whom they
              can contact, if necessary. In the event that your clinician is
              suddenly unable to meet with you on your appointed times due to a
              medical or personal emergency, the session will be rescheduled for
              the next available appointment.
            </Typography>
            <Grid container>
              <Grid item lg={1} />
              <Grid item lg={1}>
                <Typography
                  component="p"
                  style={{ textAlign: "left" }}
                  className={classes.tableText}
                >
                  821 Al Wasl Rd Al Safa 2 P.O. Box 33427 Dubai, UAE
                </Typography>
              </Grid>
              <Grid item lg={1} />
              <Grid item lg={3} style={{ float: "left" }}>
                <Typography
                  component="span"
                  style={{ textAlign: "left" }}
                  className={classes.tableText}
                >
                  <Typography
                    component="label"
                    className={classes.tableText}
                    style={{ color: "#a39161" }}
                  >
                    T.
                  </Typography>
                  +971(0)43802088{" "}
                </Typography>
                <Typography
                  component="span"
                  style={{ textAlign: "left" }}
                  className={classes.tableText}
                >
                  <Typography
                    component="label"
                    className={classes.tableText}
                    style={{ color: "#a39161" }}
                  >
                    E.
                  </Typography>
                  info@lighthousearabia.com
                </Typography>
                <Typography
                  component="p"
                  style={{ textAlign: "left", color: "#a39161" }}
                  className={classes.tableText}
                >
                  lighthousearabia.com
                </Typography>
              </Grid>
            </Grid>
          </>

          <Typography className={classes.subHeadingText}>
            ENDING THERAPY
          </Typography>
          <Typography paragraph className={classes.mainParaStyle}>
            Ending therapy may occur at any time and be indicated by either the
            client/caregiver or the clinician. If clients/caregiver has concerns
            about their therapy experience, The LightHouse Arabia encourages
            them to share their concerns and perhaps changes can be made to make
            therapy more helpful. It is not unusual for an individual to meet
            with more than one clinician before they find the “best fit.”
            Clients/caregivers are encouraged to share their preferences and the
            clinician may be able to help in finding a therapist who may be a
            better match. Generally, therapy ends when clients have accomplished
            their goals established at the beginning of therapy. If clients stop
            attending sessions, the clinician generally does not call out of
            respect for their choice. This should not be interpreted as the
            clinician not caring. If clients/caregivers decide at a later date
            that they are ready to become involved in therapy again, please feel
            free to call The LightHouse Arabia and ask to resume therapy. The
            clinician understands that sometimes it is just not the right time
            to devote the energy necessary for successful therapy
          </Typography>

          <Typography className={classes.subHeadingText}>
            CLIENT RIGHTS
          </Typography>
          <>
            <Typography paragraph className={classes.mainParaStyle}>
              Clients/caregivers have the right to be informed about the goals,
              purposes, techniques, procedures, limitations, possible risks, and
              the benefits of services to be performed. Goals of treatment and
              procedures to be used will be agreed upon by the client/
              caregivers and the clinician.
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              Clients/caregivers have the right to expect that their clinician
              is punctual, meets with as frequently as agreed upon, and responds
              to your communications in a timely manner. If your clinician needs
              to postpone a meeting, clients/caregivers will be provided with an
              appropriate cancellation notice.
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              Clients/caregivers have the right to terminate therapy at any time
              for any reason
            </Typography>
          </>

          <Typography className={classes.subHeadingText}>
            REGISTRATION DETAILS
          </Typography>
          <>
            <Grid container>
              <Grid
                item
                lg={12}
                className={classes.tableHeader}
                style={{ marginRight: "20px" }}
              >
                <Typography className={classes.tableHeaderText}>
                  CLIENT INFORMATION
                </Typography>
              </Grid>

              <Grid
                item
                lg={12}
                style={{
                  borderBottom: "0.5px solid #001f5f",
                  marginRight: "20px",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    lg={6}
                    style={{
                      borderRight: "0.5px solid #001f5f",
                      height: "60px",
                    }}
                  >
                    <Typography className={classes.tableSubText}>
                      First Name:
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography className={classes.tableSubText}>
                      Last Name(as seen as paasport):
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                style={{
                  borderBottom: "0.5px solid #001f5f",
                  marginRight: "20px",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    lg={6}
                    style={{
                      borderRight: "0.5px solid #001f5f",
                      height: "60px",
                    }}
                  >
                    <Typography className={classes.tableSubText}>
                      Date of Birth:
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    style={{ borderRight: "0.5px solid #001f5f" }}
                  >
                    <Typography className={classes.tableSubText}>
                      Age:
                    </Typography>
                  </Grid>
                  <Grid item lg={3}>
                    <Typography className={classes.tableSubText}>
                      Gender:
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                className={classes.tableHeader}
                style={{ marginRight: "20px" }}
              >
                <Grid container>
                  <Grid
                    item
                    lg={6}
                    style={{ borderRight: "0.5px solid #001f5f" }}
                  >
                    <Typography className={classes.tableHeaderText}>
                      MOTHER'S INFORMATION
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography className={classes.tableSubText}>
                      FATHER'S INFORMATION
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                style={{
                  borderBottom: "0.5px solid #001f5f",
                  marginRight: "20px",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    lg={6}
                    style={{
                      borderRight: "0.5px solid #001f5f",
                      height: "60px",
                    }}
                  >
                    <Typography className={classes.tableSubText}>
                      Mother's Name(as seen in passport):
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography className={classes.tableSubText}>
                      Father's Name(as seen as paasport):
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                style={{
                  borderBottom: "0.5px solid #001f5f",
                  marginRight: "20px",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    lg={6}
                    style={{
                      borderRight: "0.5px solid #001f5f",
                      height: "60px",
                    }}
                  >
                    <Typography className={classes.tableSubText}>
                      Mobile:
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography className={classes.tableSubText}>
                      Mobile:
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                style={{
                  borderBottom: "0.5px solid #001f5f",
                  marginRight: "20px",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    lg={6}
                    style={{
                      borderRight: "0.5px solid #001f5f",
                      height: "60px",
                    }}
                  >
                    <Typography className={classes.tableSubText}>
                      Home/Office Tel:
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography className={classes.tableSubText}>
                      Home/Office Tel:
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                style={{
                  borderBottom: "0.5px solid #001f5f",
                  marginRight: "20px",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    lg={6}
                    style={{
                      borderRight: "0.5px solid #001f5f",
                      height: "60px",
                    }}
                  >
                    <Typography className={classes.tableSubText}>
                      Email:
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography className={classes.tableSubText}>
                      Email:
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                style={{
                  borderBottom: "0.5px solid #001f5f",
                  marginRight: "20px",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    lg={6}
                    style={{
                      borderRight: "0.5px solid #001f5f",
                      height: "60px",
                    }}
                  >
                    <Typography className={classes.tableSubText}>
                      Address:
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography className={classes.tableSubText}>
                      Address:
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                style={{
                  borderBottom: "0.5px solid #001f5f",
                  marginRight: "20px",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    lg={6}
                    style={{
                      borderRight: "0.5px solid #001f5f",
                      height: "60px",
                    }}
                  >
                    <Typography className={classes.tableSubText}>
                      Nationality:
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography className={classes.tableSubText}>
                      Nationality:
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                style={{
                  borderBottom: "0.5px solid #001f5f",
                  marginRight: "20px",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    lg={6}
                    style={{
                      borderRight: "0.5px solid #001f5f",
                      height: "60px",
                    }}
                  >
                    <Typography className={classes.tableSubText}>
                      Occupation:
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography className={classes.tableSubText}>
                      Occupation:
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                className={classes.tableHeader}
                style={{ marginRight: "20px" }}
              >
                <Typography className={classes.tableHeaderText}>
                  EMERGENCY CONTACT
                </Typography>
              </Grid>

              <Grid
                item
                lg={12}
                style={{
                  borderBottom: "0.5px solid #001f5f",
                  marginRight: "20px",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    lg={6}
                    style={{
                      borderRight: "0.5px solid #001f5f",
                      height: "60px",
                    }}
                  >
                    <Typography className={classes.tableSubText}>
                      Name:
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography className={classes.tableSubText}>
                      Relationship to Child:
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                style={{
                  borderBottom: "0.5px solid #001f5f",
                  marginRight: "20px",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    lg={6}
                    style={{
                      borderRight: "0.5px solid #001f5f",
                      height: "60px",
                    }}
                  >
                    <Typography className={classes.tableSubText}>
                      Mobile:
                    </Typography>
                  </Grid>
                  <Grid item lg={6}>
                    {/* <Typography className={classes.tableSubText}>Relationship to Child:</Typography> */}
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                className={classes.tableHeader}
                style={{ marginRight: "20px" }}
              >
                <Typography className={classes.tableHeaderText}>
                  ALLERGIES
                </Typography>
              </Grid>

              <Grid
                item
                lg={12}
                style={{
                  borderBottom: "0.5px solid #001f5f",
                  marginRight: "20px",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    lg={4}
                    style={{
                      borderRight: "0.5px solid #001f5f",
                      height: "100px",
                    }}
                  >
                    <Typography className={classes.tableSubText}>
                      Medication(s): YES/NO
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    style={{
                      borderRight: "0.5px solid #001f5f",
                      height: "100px",
                    }}
                  >
                    <Typography className={classes.tableSubText}>
                      Food: YES/NO
                    </Typography>
                  </Grid>
                  <Grid item lg={4}>
                    <Typography className={classes.tableSubText}>
                      Environmental: YES/NO
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>

          <Typography className={classes.subHeadingText}>
            CONSENT FOR CARE
          </Typography>
          <>
            <Typography paragraph className={classes.mainParaStyle}>
              I hereby grant permission for _______________________________
              (clinician) to provide evaluation and treatment services as may be
              deemed necessary or advisable for the diagnosis and/or care of
              ____________________________________(name of client).
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              I understand that all information gathered in the course of the
              treatment is confidential; however, information may be released in
              cases of harm to the client or someone else, medical emergency,
              abuse or neglect, court order, (pertaining to minor children)
              parent request and where otherwise legally required.
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              I agree to participate in the treatment planning process to the
              best of my ability. I understand that there is no guarantee that
              those treatment services will prove beneficial to me/my child. I
              further understand that actively participating in my treatment
              goals and objectives from therapy is more likely to result in
              favorable outcomes in the therapy process.
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              I acknowledge that I have read, understand and agree to be bound
              by the content, terms, and conditions of The LightHouse Arabia’s
              Treatment Policy and Informed Consent. I have been given the
              opportunity to ask questions and have been informed of the rights
              of confidentiality and my rights as a client/caregiver.
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              Client/Caregiver Signature __________________________ Date
              _______________________
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              Print Name and Relationship to Client
              _________________________________
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              Clinician Signature ________________________________ Date
              ________________________
            </Typography>
            <Grid container>
              <Grid item lg={1} />
              <Grid item lg={1}>
                <Typography
                  component="p"
                  style={{ textAlign: "left" }}
                  className={classes.tableText}
                >
                  821 Al Wasl Rd Al Safa 2 P.O. Box 33427 Dubai, UAE
                </Typography>
              </Grid>
              <Grid item lg={1} />
              <Grid item lg={3} style={{ float: "left" }}>
                <Typography
                  component="span"
                  style={{ textAlign: "left" }}
                  className={classes.tableText}
                >
                  <Typography
                    component="label"
                    className={classes.tableText}
                    style={{ color: "#a39161" }}
                  >
                    T.
                  </Typography>
                  +971(0)43802088{" "}
                </Typography>
                <Typography
                  component="span"
                  style={{ textAlign: "left" }}
                  className={classes.tableText}
                >
                  <Typography
                    component="label"
                    className={classes.tableText}
                    style={{ color: "#a39161" }}
                  >
                    E.
                  </Typography>
                  info@lighthousearabia.com
                </Typography>
                <Typography
                  component="p"
                  style={{ textAlign: "left", color: "#a39161" }}
                  className={classes.tableText}
                >
                  lighthousearabia.com
                </Typography>
              </Grid>
            </Grid>
          </>

          <Typography className={classes.subHeadingText}>
            ADDITIONAL CONSENT FOR SCHOOL BASED SERVICES
          </Typography>
          <>
            <Typography paragraph className={classes.mainParaStyle}>
              I _____________________________ (guardian/parent’s name) as the
              legal guardian/parent agree to allow my child
              ____________________________ (name of child) to receive
              Occupational Therapy services or Speech & Language Therapy
              Services at __________________________ (school’s name) under the
              supervision of The Light House Arabia.
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              I understand that information such as treatment plans, program
              goals, assessment results and summary reports will be shared
              between the clinician and the school team
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              I understand that the school will share relevant assessments
              including my child’s IEP,with the clinician from The LightHouse
              Arabia and any informal reports from the past two years can be
              shared in order to develop an integrated and coordinated program.
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              I understand that I am responsible for making the payment for
              services rendered by The LightHouse Arabia in full, at the
              beginning of each month and that the fee for cancelled sessions
              will be adjusted to the next month.
            </Typography>
            <Typography paragraph className={classes.mainParaStyle}>
              If your child will not be attending school for any reason
              (illness, vacation, school trip) please inform your clinician or
              the reception at The LightHouse Arabia via phone prior to 8am on
              the day of the appointment or email your clinician the day before
              the appointment. Cancellations after 8am will be charged at 50% of
              the therapy rate.
            </Typography>
            <Grid container>
              <Grid item lg={1} />
              <Grid item lg={1}>
                <Typography
                  component="p"
                  style={{ textAlign: "left" }}
                  className={classes.tableText}
                >
                  821 Al Wasl Rd Al Safa 2 P.O. Box 33427 Dubai, UAE
                </Typography>
              </Grid>
              <Grid item lg={1} />
              <Grid item lg={3} style={{ float: "left" }}>
                <Typography
                  component="span"
                  style={{ textAlign: "left" }}
                  className={classes.tableText}
                >
                  <Typography
                    component="label"
                    className={classes.tableText}
                    style={{ color: "#a39161" }}
                  >
                    T.
                  </Typography>
                  +971(0)43802088{" "}
                </Typography>
                <Typography
                  component="span"
                  style={{ textAlign: "left" }}
                  className={classes.tableText}
                >
                  <Typography
                    component="label"
                    className={classes.tableText}
                    style={{ color: "#a39161" }}
                  >
                    E.
                  </Typography>
                  info@lighthousearabia.com
                </Typography>
                <Typography
                  component="p"
                  style={{ textAlign: "left", color: "#a39161" }}
                  className={classes.tableText}
                >
                  lighthousearabia.com
                </Typography>
              </Grid>
            </Grid>
          </>

          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignContent: "center",
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            <Link to="/client/payment" style={{ textDecoration: "none" }}>
              <Button
                style={{
                  backgroundColor: "#003d79",
                  color: "#ffffff",
                  // padding: 0,
                  textTransform: "capitalize",
                  width: 200,
                }}
              >
                Back
              </Button>
            </Link>
            <Link
              to="/client/confirm"
              style={{ textDecoration: "none", marginLeft: "10px" }}
            >
              <Button
                style={{
                  backgroundColor: "#003d79",
                  color: "#ffffff",
                  // padding: 0,
                  textTransform: "capitalize",
                  width: 200,
                }}
              >
                Next
              </Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}



export default withStyles(styles)(ConsentForm);
