import React from 'react';
import InvoiceListItemAvatarAdult from './InvoiceListItemAvatarAdult';
import InvoiceListItemStatus from './InvoiceListItemStatus';
import { DATE_FORMAT } from '../../../../utils/timUtils';
import moment from 'moment';
import userIcon from '../../../../assets/imgs/download.png';
import InvoiceListItemAvatarMinor from './InvoiceListItemAvatarMinor';
import { calculateAge } from '../../../../utils/timUtils';

const ageGroup = (option) =>
  calculateAge(new Date(option.dob)) > 18 ? <InvoiceListItemAvatarAdult
    src={
      option?.avatar ||
      userIcon
    }
  /> : <InvoiceListItemAvatarMinor >{option?.firstName[0].toUpperCase() + option?.lastName[0].toUpperCase()}</InvoiceListItemAvatarMinor>;

const InvoiceListItem = ({ invoice, onClick, selectedInvoice, total }) => {
  return (
    invoice && (
      <li className='border border-r-0 border-gray-100 bg-white'>
        <a
          onClick={() => onClick(invoice._id)}
          className={`block hover:bg-gray-50 ${selectedInvoice?._id == invoice._id ? "bg-gray-200" : "bg-white"}`}
          style={{ cursor: 'pointer' }}
        >
          <div className='flex items-center px-4 py-4'>
            <div className='min-w-0 flex-1 flex items-center'>
              {!invoice.sponsorEmail ?
                (invoice.clients &&
                  invoice.clients.length > 0 && ageGroup(invoice.clients[0]))
                :
                <InvoiceListItemAvatarMinor >S</InvoiceListItemAvatarMinor>}
              <div className='min-w-0 flex-1 flex flex-nowrap px-4 gap'>
                <div className='w-4/6'>
                  <p className='font-bold text-gray-900 truncate'>
                    {!invoice.sponsorEmail ? invoice.clients?.length > 0 &&
                      `${invoice.clients[0].firstName} ${invoice.clients[0].lastName}` : `Sponsor`}
                  </p>
                  <p className='mt-1 text-xs'>
                    <span className='whitespace-nowrap text-xs'>
                      {`${invoice.chartId}`}
                    </span>
                    <span className='whitespace-nowrap text-gray-400 ml-0.5'>
                      |{' '}
                      {moment(
                        invoice.createdAt
                      ).format(DATE_FORMAT)}
                    </span>
                  </p>
                </div>
                <div className='w-2/6 text-right'>
                  <div>
                    <div className='block text-sm text-gray-900'>
                      {total} AED
                    </div>
                    <InvoiceListItemStatus status={invoice.status} />
                  </div>
                </div>
              </div>
            </div>
            <div>
              { }
              <svg
                className='h-5 w-5 text-gray-400'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'
                aria-hidden='true'
              >
                <path
                  fillRule='evenodd'
                  d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                  clipRule='evenodd'
                />
              </svg>
            </div>
          </div>
        </a>
      </li>
    )
  );
};

export default InvoiceListItem;
