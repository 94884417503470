import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    maxWidth: 255,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  inputstyle: {
    backgroundColor: '#fff',
  },
  table: {
    minWidth: 650,
  },
  imgTable: {
    width: '40px',
    height: '40px',
    borderRadius: '50px',
  },
  viewBtn: {
    borderRadius: '15px',
    width: '100px',
    padding: '4px',
    boxShadow: 'none',
    textTransform: 'capitalize',
    // '&:hover': {
    //   backgroundColor: '#003265',
    // },
  },
  checkedInBtn: {
    borderRadius: '15px',
    width: '100px',
    padding: '4px',
    boxShadow: 'none',
    color: '#fff',
    textTransform: 'capitalize',
    backgroundColor: '#01BC05',
    '&:hover': {
      backgroundColor: '#01BC05',
    },
  },
  seenBtn: {
    borderRadius: '15px',
    width: '100px',
    padding: '4px',
    boxShadow: 'none',
    textTransform: 'capitalize',
    backgroundColor: '#FFAB0B',
    '&:hover': {
      backgroundColor: '#FFAB0B',
    },
  },
  btnNewApp: {
    backgroundColor: '#003265',
    color: '#fff',
    marginLeft: '50px',
    '&:hover': {
      backgroundColor: '#003265',
    },
  },
  userImages: {
    '& img': {
      marginLeft: '-10px',
      position: 'relative',
      '& firstchild': {
        marginLeft: 0,
      },
    },
  },
  paginationRoot: {
    marginTop: 15,
    float: 'right',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));
