import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import styled from 'styled-components';

const ActionBtn = styled(Button)`
  background-color: ${(props) => (props.color ? props.color : '#003264')};
  color: white;
  padding: 0px;
  min-width: 0px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  &:hover {
    background-color: ${(props) => (props.color ? props.color : '#003264')};
  }
`;

const DrawerDiv = styled(Grid)`
  min-width: 400px;
`;
export { ActionBtn, DrawerDiv };

export default makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  root1: {
    flexGrow: 1,
    marginTop: '10%',
    backgroundColor: 'transparent',
    // indicatorColor:"#003265"
  },
  table: {
    minWidth: 650,
  },
  imgTable: {
    width: '40px',
    height: '40px',
    borderRadius: '50px',
  },
  btn2: {
    height: '33px',
    padding: '0px 30px',
    borderRadius: '9px',
    justifyContent: 'center',
    cursor: 'pointer',
    textTransform: 'capitalize',
    fontFamily: 'Lato',
    fontWeight: 600,
    float: 'right',
  },
  blue: {
    color: '#003265',
  },
  body: {
    fontFamily: 'Lato',
    color: '#003265',
  },
  btnn: {
    width: '100px',
    height: '50px',
    borderRadius: '8px',
    // backgroundColor: "#003265",
    // "&:hover": { backgroundColor: "#003265" },
    justifyContent: 'center',
    cursor: 'pointer',
    fontSize: '15px',
    color: '#FFFFFF',
    lineHeight: '40px',
    fontFamily: 'Lato',
    fontWeight: 600,
    textAlign: 'center',
    float: 'right',
  },
  viewBtn: {
    border: '1px solid black',
    borderRadius: '15px',
    width: '100px',
    padding: '4px',
    backgroundColor: '#f0f0f5',
    boxShadow: 'none',
    textTransform: 'capitalize',
    borderColor: '#C7C7C9',
    // '&:hover': {
    //   backgroundColor: '#003265',
    // },
  },
  payBtn: {
    border: '1px solid black',
    borderRadius: '15px',
    width: '100px',
    padding: '4px',
    backgroundColor: '#fccacc',
    boxShadow: 'none',
    textTransform: 'capitalize',
    borderColor: '#f73138',
    // '&:hover': {
    //   backgroundColor: '#003265',
    // },
  },
  light: {
    color: '#9C8657',
  },
}));
