import { createStyles } from "@material-ui/core";
import { titleFont } from "../../../assets/fontSize";

export default (theme) =>
  createStyles({
    root: {
      backgroundColor: "#ffffff",
    },
    container: {
      [theme.breakpoints.up("xs")]: {
        width: "250px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "417px",
      },
    },
    title: {
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: 700,
      textAlign: "left",
      marginTop: "40px  ",
      [theme.breakpoints.up("xs")]: {
        fontSize: titleFont,
        // fontSize: "25px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: titleFont,
        // fontSize: "25px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: titleFont,
        // fontSize: "32px",
      },
    },
    searchInput: {
      height: "50px",
      backgroundColor: "#f5f6f7",
      border: "1px solid #707070",
      width: "710px",
      fontSize: "18px",
      fontFamily: "Lato",
      fontWeight: 400,
      color: "#222222",
      opacity: "50%",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    autoSuggestListContainer: {
      display: "flex",
      alignContent: "center",
      justifyItems: "center",
      position: "relative",
      color: "rgba(34,34,34,0.5)",
      "&:hover": {
        backgroundColor: "#304659",
        color: "#fff",
        opacity: "100%",
        zIndex: 1,
      },
    },
    suggestionImage: {
      width: "46px",
      height: "46px",
      // width: "30px",
      // height: "30px",
      borderRadius: "23px",
      border: "2px solid #c0d0de",
      marginLeft: "14px",
      marginTop: "5px",
      zIndex: 2,
      position: "relative",
    },
    suggestionText: {
      fontSize: "18px",
      fontFamily: "Lato",
      fontWeight: 400,
      zIndex: 2,
      marginLeft: "10px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      [theme.breakpoints.up("xs")]: {
        width: "160px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "300px",
      },
      [theme.breakpoints.up("md")]: {
        width: "auto",
        textAlign: "left",
      },
    },
  });
