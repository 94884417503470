import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(() => ({
    toolbar: {
      flexGrow: 1,
      height: '100%',
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      overflow: 'auto'
    },
    appMenu: {
      position: 'relative',
      zIndex: 1,
      marginTop: '128px',
      paddingLeft: '24px',
      paddingRight: '29px',
    },
    bodyListContainer: {
      display: 'flex',
      height: '60px',
      width: '262px',
      borderRadius: '5px',
      paddingLeft: '26px',
      marginTop: '9px',
      marginBottom: '9px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,20%)',
      },
    },
    active: {
      backgroundColor: 'rgba(255,255,255,20%)',
    },
    title: {
      fontSize: '20px',
      fontFamily: 'Lato',
      fontWeight: 400,
      textAlign: 'left',
      color: '#ffffff',
      textTransform: 'capitalize',
      marginLeft: '10px',
    },
    homeIcon: {
      position: 'absolute',
      marginLeft: '-3px',
    },
    appoinmentIcon: {
      position: 'absolute',
      marginTop: '5px',
      marginLeft: '4px',
    },
    appoinmentIcon1: {
      position: 'absolute',
      marginTop: '2px',
      marginLeft: '2px',
    },
    appoinmentIcon2: {
      // zIndex:2,
      position: 'absolute',
      height: '7px',
      width: '7px',
      marginTop: '7px',
      marginLeft: '6px',
    },
    appoinmentIcon3: {
      zIndex: 1,
      position: 'absolute',
      marginTop: '3px',
      marginLeft: '8px',
    },
    event1: {
      // position: 'absolute'
    },
    event2: {
      position: 'absolute',
      marginLeft: '-17px',
    },
    event3: {
      position: 'absolute',
      marginLeft: '-8px',
    },
    event4: {
      position: 'absolute',
      marginLeft: '-20px',
      marginTop: '6px',
    },
    event5: {
      position: 'absolute',
      marginLeft: '-16px',
      marginTop: '8px',
    },
    event6: {
      position: 'absolute',
      marginLeft: '-11px',
      marginTop: '8px',
    },
  }));