import React from "react";

const CommentsAttachmentInputView = (props) => (
  <div className="files-explorer-temp text-xs my-4">
    {props?.files?.length > 0 && props?.isEditing && <span className="font-bold">Selected files:</span>}
    <ul className="mt-2">
      {props?.files?.map(file => <li key={file?.name ?? file} >
        <span className="inline-block text-red-500 mr-0.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 -mb-1"
            width={16}
            height={16}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            />
          </svg>
        </span>
        <button onClick={() => {
          if (!file?.name) {
            window.open(file, "_blank")
          }
        }} className>{file?.name ?? file.split("/")[file.split("/").length - 1]}</button>
        {
          props?.isEditing
          &&
          <button
            onClick={() => props?.onRemove(file)}
            className="inline-block ml-1 text-red-500 hover:underline"
          >
            [remove]
          </button>
        }
      </li>)}
    </ul>
  </div>
);

export default CommentsAttachmentInputView;
