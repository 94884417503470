import React, { useEffect } from 'react';
import clsx from 'clsx';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import defaultSideImage from '../assets/imgs/clinician1.png';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import arrow from '../assets/imgs/Border.png';
import { CustomCalendar } from '../Patient/components/Calendar/CustomCalendar';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { withStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import { baseURL } from '../utils/axios';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SliderPointLine from '../assets/imgs/slider verticle line.svg';
import SliderPointIcon from '../assets/imgs/slider point.svg';
import Slider from 'react-slick';
import Loader from '../components/Loader/Loader';
import clinician1 from '../assets/imgs/clinician1.png';
import AlertMsg from '../utils/Alert';
import CreateNewAppointment from '../common/CreateNewAppointment';

import { useHistory } from 'react-router-dom';

import './clinician.scss';
import { Grid, Typography, MenuItem } from '@material-ui/core';
import AlertDialog from '../common/AlertDialog';

import useStyles from './FilterModalBox.styles';
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function FilterModalBox({
  handleBook,
  detailDrawer,
  handleDetailDrawer,
  appointmentId,
  setDetailDrawer,
  openPatientDrawer,
  handleEditRecord,
  detailsOnPatient,
  refresh,
}) {
  const slides = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const classes = useStyles();
  const [appointmentDetails, setAppointmentDetails] = React.useState([]);
  const [patientRecords, setPatientRecords] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoaded, setIsloaded] = React.useState(true);
  const [sheduleOpen, setSheduleOpen] = React.useState(false);
  const [clinicianLoader, setClinicianLoader] = React.useState(false);
  const [clinicianDetails, setClinicianDetails] = React.useState(null);
  const [patientId, setPatientId] = React.useState(null);
  const [openCancel, setOpenCancel] = React.useState(false);
  const [locationKeys, setLocationKeys] = React.useState([]);
  const [createAppointmentType, setCreateAppointmentType] = React.useState([]);
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key]);
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);

          // Handle back event
        }
      }
    });
  }, [locationKeys]);

  useEffect(() => {
    if (appointmentId) {
      setIsloaded(true);
      const uid = appointmentId;
      Axios.get(`${baseURL}/api/appointment/detail/${uid}`, {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      })
        .then((res) => {
          setIsloaded(false);
          if (res.data) {
            let childIds = res.data.Appointment.clientId;
            childIds.splice(-1, 1);
            const removeParent = {
              ...res.data.Appointment,
              clientId: childIds,
            };
            setAppointmentDetails(removeParent);
            setPatientRecords(res.data.patientRecord);
            setPatientId(
              res.data.Appointment.clientId &&
                res.data.Appointment.clientId.length > 0
                ? res.data.Appointment.clientId[0]._id
                : ''
            );
          }
        })
        .catch((e) => {
          console.log('eeeeeeee', e);
        });
    }
  }, [appointmentId]);

  const handleSheduleOpen = () => {
    setSheduleOpen(true);
  };

  const getAge = (dob) => {
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const getDate = (date) => {
    var today = new Date(date);
    var d = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var m = today.getMonth() < 10 ? '0' + today.getMonth() : today.getMonth();
    var date = d + '/' + m + '/' + today.getFullYear();
    return date;
  };

  const getTime = (date) => {
    var date_ob = new Date(date);
    var hours = ('0' + date_ob.getHours()).slice(-2);
    // minutes as 2 digits (mm)
    var minutes = ('0' + date_ob.getMinutes()).slice(-2);
    var hours2 = date_ob.getHours();
    var hours2 = (hours + 24 - 2) % 24;
    var mid = 'AM';
    if (hours2 == 0) {
      //At 00 hours2 we need to show 12 am
      hours2 = 12;
    } else if (hours2 > 12) {
      hours2 = hours2 % 12;
      mid = 'PM';
    }
    var time = hours + ':' + minutes + ' ' + mid;
    return time;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancelAppointment = () => {
    let newData = {
      status: 'cancelled',
    };
    Axios.patch(
      `${baseURL}/api/appointment/${appointmentDetails._id}`,
      newData,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        if (res.data) {
          setOpenCancel(false);
          setSheduleOpen(false);
          setAnchorEl(null);
          refresh();
        }
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role='presentation'
      style={{ width: '900px' }}
      onClick={handleDetailDrawer(anchor, false)}
      onKeyDown={handleDetailDrawer(anchor, false)}
    >
      {isLoaded ? (
        <Grid container style={{ paddingLeft: '45%', paddingTop: '10%' }}>
          <Loader />
        </Grid>
      ) : (
        <Grid container>
          <Grid xs={12}>
            <Grid container>
              <Grid xs={6}>
                <Typography variant='h5' style={{ color: '#222222' }}>
                  ID: {appointmentDetails._id}
                  <CheckIcon
                    className={classes.CheckIconCustom}
                    fontSize='small'
                  />
                </Typography>
                <Typography variant='h6' style={{ color: '#003265' }}>
                  <CalendarTodayIcon
                    fontSize='small'
                    style={{ fontSize: 15, marginRight: 3 }}
                  />
                  <span style={{ fontSize: 15 }}>
                    {appointmentDetails.startTime
                      ? getDate(appointmentDetails.startTime)
                      : 'NA'}
                  </span>
                </Typography>
                <Typography variant='subtitle2' style={{ color: '#003265' }}>
                  <WatchLaterOutlinedIcon
                    fontSize='small'
                    style={{ fontSize: 15, marginRight: 3 }}
                  />
                  <span style={{ fontSize: 15 }}>
                    {appointmentDetails.startTime
                      ? getTime(appointmentDetails.startTime)
                      : 'NA'}
                    ,{' '}
                    {appointmentDetails.duration
                      ? appointmentDetails.duration + ' mins'
                      : ''}
                  </span>
                </Typography>
                <Typography variant='subtitle2' style={{ color: '#003265' }}>
                  <LocationOnOutlinedIcon
                    fontSize='small'
                    style={{ fontSize: 15, marginRight: 3 }}
                  />
                  <span style={{ fontSize: 15 }}>N/A</span>
                </Typography>
              </Grid>
              <Grid xs={6}>
                <div style={{ float: 'right' }}>
                  <Button
                    aria-controls='customized-menu'
                    aria-haspopup='true'
                    variant='contained'
                    style={{ backgroundColor: '#003265' }}
                    color='primary'
                    onClick={handleClick}
                    endIcon={<ExpandMoreIcon />}
                  >
                    Action
                  </Button>
                  <StyledMenu
                    id='customized-menu'
                    className='drop-down-custom-detail'
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {appointmentDetails.status === 'confirmed' ? (
                      <StyledMenuItem
                        onClick={() => {
                          handleSheduleOpen();
                          setCreateAppointmentType('reschedule');
                        }}
                      >
                        <ListItemIcon>
                          <EditIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText
                          className='text-drop-down'
                          primary='Reschedule'
                        />
                      </StyledMenuItem>
                    ) : (
                      <div />
                    )}

                    {appointmentDetails.status == 'waiting' ? (
                      <StyledMenuItem
                        onClick={() => handleBook(appointmentDetails)}
                      >
                        <ListItemIcon>
                          <EditIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText
                          className='text-drop-down'
                          primary='Book Appointment'
                        />
                      </StyledMenuItem>
                    ) : (
                      <div />
                    )}

                    <StyledMenuItem
                      onClick={() => {
                        setOpenCancel(true);
                      }}
                    >
                      <ListItemIcon>
                        <EditIcon fontSize='small' />
                      </ListItemIcon>
                      <ListItemText
                        className='text-drop-down'
                        primary='Cancel'
                      />
                    </StyledMenuItem>

                    {appointmentDetails.status == 'completed' ? (
                      <StyledMenuItem
                        onClick={(evt) => handleEditRecord(appointmentId)}
                      >
                        <ListItemIcon>
                          <EditIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText
                          className='text-drop-down'
                          primary='Edit/Update'
                        />
                      </StyledMenuItem>
                    ) : (
                      <div />
                    )}
                    {appointmentDetails.status == 'completed' ? (
                      <StyledMenuItem
                        onClick={() => {
                          handleSheduleOpen();
                          setCreateAppointmentType('follow-up');
                        }}
                      >
                        <ListItemIcon>
                          <CalendarTodayIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText
                          className='text-drop-down'
                          primary='Schedule Follow-up'
                        />
                      </StyledMenuItem>
                    ) : (
                      <div />
                    )}

                    {appointmentDetails.status == 'completed' ? (
                      <StyledMenuItem
                        onClick={() => {
                          handleSheduleOpen();
                          setCreateAppointmentType('init-refferal');
                        }}
                      >
                        <ListItemIcon>
                          <RemoveRedEyeOutlinedIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText
                          className='text-drop-down'
                          primary='Initiate Referral'
                        />
                      </StyledMenuItem>
                    ) : (
                      <div />
                    )}
                  </StyledMenu>
                </div>
              </Grid>
            </Grid>

            <div style={{ marginTop: 30 }}>
              <Slider {...slides} className='detailSlides'>
                {appointmentDetails.clientId &&
                  appointmentDetails.clientId.map((val, i) => {
                    return (
                      <Card className={classes.rootCard}>
                        <CardHeader
                          avatar={
                            <Avatar
                              src={val.avatar}
                              aria-label='recipe'
                              className={classes.avatar}
                            ></Avatar>
                          }
                          title={`${val.firstName ? val.firstName : '--'}  ${
                            val.lastName ? val.lastName : '--'
                          }`}
                          subheader={`${val.gender ? val.gender : '--'} : ${
                            val.dob ? getAge(val.dob) : '--'
                          }`}
                        />
                        <CardContent>
                          <Grid container>
                            <Grid xs={6} style={{ marginTop: 10 }}>
                              <Typography
                                style={{
                                  fontSize: '10px',
                                  color: '#222222',
                                }}
                              >
                                0
                              </Typography>
                            </Grid>
                            <Grid xs={6} style={{ marginTop: 10 }}>
                              <Typography
                                style={{
                                  float: 'right',
                                  fontSize: '10px',
                                  color: '#222222',
                                }}
                              >
                                33
                              </Typography>
                            </Grid>
                          </Grid>
                          <div className={classes.sliderContainer}>
                            <div
                              className={classes.sliderPointContainer2}
                              style={{
                                marginLeft:
                                  ((val.riskScore / 33) * 87).toString() + '%',
                              }}
                            >
                              <img
                                src={SliderPointIcon}
                                style={{ height: 30 }}
                                className={classes.sliderPoint}
                              />
                              <Typography className={classes.sliderPointText}>
                                {val.riskScore ? val.riskScore : 0}
                              </Typography>
                              <img
                                src={SliderPointLine}
                                className={classes.sliderPointLine}
                              />
                            </div>
                          </div>
                          <Typography
                            variant='body2'
                            color='textSecondary'
                            component='p'
                            style={{ color: '#222222' }}
                          >
                            {/* TODO: // */}
                          </Typography>
                        </CardContent>
                        <CardActions disableSpacing>
                          {!detailsOnPatient && (
                            <Button
                              className={classes.btnViewPatientRecord}
                              onClick={() => {
                                openPatientDrawer(val._id);
                              }}
                            >
                              View Patient Record
                            </Button>
                          )}
                        </CardActions>
                      </Card>
                    );
                  })}
              </Slider>
            </div>
            <Typography variant='h6' className={classes.inSession}>
              In-session Assessments Taken
            </Typography>
            <div className={classes.assesmentType}>
              <Grid container>
                <Grid xs={12}>
                  <Grid container>
                    <Grid xs={6}>
                      <Typography
                        variant='h6'
                        style={{ marginTop: 5, color: '#304659' }}
                      >
                        RISK & COMPLEXITY
                      </Typography>
                    </Grid>
                    <Grid xs={6}>
                      <Grid container>
                        {appointmentDetails.clientId &&
                          appointmentDetails.clientId.map((val, i) => (
                            <Grid xs={6}>
                              <Grid container>
                                <Grid xs={4}>
                                  <Avatar
                                    alt='Remy Sharp'
                                    src={
                                      val.avatar ? val.avatar : defaultSideImage
                                    }
                                    className={classes.imgCardAvtar}
                                  />
                                </Grid>
                                <Grid xs={8}>
                                  <Typography
                                    variant='subtitle'
                                    className={classes.scoreText}
                                  >
                                    Score
                                  </Typography>
                                  <Typography
                                    variant='subtitle1'
                                    className={classes.marksText}
                                  >
                                    {appointmentDetails.riskScore}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Grid container>
              <Grid xs={12}>
                {patientRecords &&
                  patientRecords.map((val, i) => {
                    return (
                      <>
                        <Grid container>
                          <Grid xs={2}>
                            <div
                              style={{
                                borderRight: '2px solid #7070701a',
                                height: '100%',
                                marginRight: 15,
                              }}
                            >
                              <Typography
                                variant='h6'
                                style={{ color: '#003265', lineHeight: 1 }}
                              >
                                {val.clientId.firstName
                                  ? val.clientId.firstName
                                  : ''}{' '}
                                {val.clientId.lastName
                                  ? val.clientId.lastName
                                  : ''}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={10}>
                            <div className={classes.styleContentBottom}>
                              <Typography
                                variant='h6'
                                className={classes.textDetail}
                              >
                                DIAGNOSIS:
                              </Typography>
                              {val.diagnosis &&
                                val.diagnosis.map((value, i) => {
                                  return (
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ marginTop: '16px' }}
                                    >
                                      {' '}
                                      <Typography variant='subtitle'>
                                        {value.name}
                                      </Typography>
                                    </Grid>
                                  );
                                })}
                            </div>
                            <div className={classes.styleContentBottom}>
                              <Typography
                                variant='h6'
                                className={classes.textDetail}
                              >
                                INTERNAL REFERRAL?{' '}
                              </Typography>
                              <Typography variant='subtitle'>N/A</Typography>
                            </div>
                            <div className={classes.styleContentBottom}>
                              <Typography
                                variant='h6'
                                className={classes.textDetail}
                              >
                                REFERRAL REASON:{' '}
                              </Typography>
                              <Typography variant='subtitle'>N/A</Typography>
                            </div>
                            <div className={classes.styleContentBottom}>
                              <Typography
                                variant='h6'
                                className={classes.textDetail}
                              >
                                {' '}
                                REQUEST FOLLOW-UP AFTER REFERRAL?{' '}
                              </Typography>
                              <Typography variant='subtitle'>N/A</Typography>
                            </div>
                            <div className={classes.styleContentBottom}>
                              <Typography
                                variant='h6'
                                className={classes.textDetail}
                              >
                                NOTES:{' '}
                              </Typography>
                              <Typography
                                style={{ marginTop: '8px' }}
                                variant='subtitle'
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: val.notes,
                                  }}
                                />
                              </Typography>
                            </div>
                            <div className={classes.styleContentBottom}>
                              <Typography
                                variant='h6'
                                className={classes.textDetail}
                              >
                                MEDICATIONS:
                              </Typography>
                              {val.prescriptions &&
                                val.prescriptions.map((value, i) => {
                                  return (
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ marginTop: '16px' }}
                                    >
                                      <Typography variant='subtitle'>{`${value.packName} - ${value.manufacturer} (${value.activeIngredient}), ${value.strength}  ${value.strengthUnit}, ${value.quantityBase}`}</Typography>
                                    </Grid>
                                  );
                                })}
                            </div>
                          </Grid>
                        </Grid>
                        <div className={classes.borderBottomCustom}></div>
                      </>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );

  // const handleCancelAppointment = (index) => {
  //   let tmpClinicianDetails = { ...clinicianDetails };
  //   tmpClinicianDetails = {
  //     ...tmpClinicianDetails,
  //     selectTimeSlot: null,
  //   };
  //   setConfLoading(false);
  //   setClinicianDetails({ ...tmpClinicianDetails });
  // };

  const handleAppointmentConfirm = () => {};

  return (
    <div>
      <>
        <SwipeableDrawer
          anchor={'right'}
          open={detailDrawer}
          onClose={handleDetailDrawer(false)}
          onOpen={handleDetailDrawer(true)}
        >
          {list('right')}
        </SwipeableDrawer>
      </>
      {sheduleOpen && (
        <CreateNewAppointment
          open={sheduleOpen}
          handleClose={() => {
            setSheduleOpen(false);
          }}
          handleSubmit={() => {
            setSheduleOpen(false);
            setAnchorEl(null);
            refresh();
          }}
          appointmentDetails={{
            ...appointmentDetails,
            type: createAppointmentType,
          }}
        />
      )}
      <AlertDialog
        open={openCancel}
        title='Cancel Appointment'
        description={'You are about to cancel appontment'}
        handleClose={() => setOpenCancel(false)}
        handleSubmit={() => handleCancelAppointment()}
      />
    </div>
  );
}

export default FilterModalBox;

//
