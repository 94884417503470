import React from 'react';
import { TradActionBtn } from './style';
import PropTypes from 'prop-types';

const TradActionButton = ({ text, onClick, variant, bgcolor, color }) => (
  <TradActionBtn
    onClick={onClick}
    variant={variant}
    bgcolor={bgcolor}
    color={color}
  >
    {text}
  </TradActionBtn>
);

TradActionButton.defaultProps = {
  onClick: () => {},
  variant: 'contained',
  bgcolor: '#003264',
  color: '#fff',
  text: 'No action needed',
};

TradActionButton.propTypes = {
  color: PropTypes.oneOf(['#fff', '#607d9f']),
  bgcolor: PropTypes.oneOf(['#fff', '#003264', '#da1a18']),
  variant: PropTypes.oneOf(['contained', 'outlined']),
};

export default TradActionButton;
