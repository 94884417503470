/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import BackArrow from '../assets/imgs/back_arrow.svg';
import Header from '../common/Header';
import {
  getRcadUserAction,
  submitRcadUserAction,
} from '../utils/actions/rcad.action';
import { getClinicianAction } from '../utils/actions/clinician.action';

import { getByIdClientAction } from '../utils/actions/client.action';
import ProgressBar from '../common/ProgressBar';
// import { CustomNextButtons } from "../common/CustomButtons";
import AlertMsg from '../utils/Alert';
import { Loader } from '../user/Loader';
import AssessmentEntryPage from './AssessmentEntryPage';
import AssessmentResult from './AssessmentResult';
import { CircularProgress, Button } from '@material-ui/core';
import { getAgeByDob } from '../utils/validators';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import styles from './Rcads_style';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';
import SearchClinician from '../user/pages/clinician/SearchClinician';

class RcardComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      lines: [],
      selectedOption: '',
      allRcad: [],
      queIndex: 1,
      allQuestions: [],
      getDataLoader: true,
      activeIndex: 0,
      assessmentAns: {},
      assessmentIndex: 0,
      assessments: [],
      assEntryPage: false,
      assCompleted: false,
      assessmentResultData: {},
      btnLoading: false,
      clientAge: null,
      backHistory: {}, // @imran Naqvi this backHistory state is being set but does not seem to be used at any place
      backQuestionQue: [],
      noteEditor: false,
      assessmentNote: '',
      preferredClinicianScreen: false,
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    this.props.getClinicianAction();
    if (
      query.get('type') === 'child' &&
      localStorage.getItem('appChildDetails')
    ) {
      let childDetails = JSON.parse(localStorage.getItem('appChildDetails'));
      this.setState({ clientAge: getAgeByDob(childDetails.dob) });
      this.props.getRcadUserAction();
    } else if (
      query.get('type') === 'multiple' &&
      localStorage.getItem('appChildDetails')
    ) {
      this.props.getRcadUserAction();
    } else {
      this.props.getByIdClientAction();
    }
  }

  componentDidUpdate(preProps) {
    if (preProps.clientInfo !== this.props.clientInfo) {
      if (
        this.props.clientInfo &&
        this.props.clientInfo.getByIdClientData &&
        this.props.clientInfo.getByIdClientData.dob
      ) {
        this.setState({
          clientAge: getAgeByDob(this.props.clientInfo.getByIdClientData.dob),
        });
        this.props.getRcadUserAction();
      }
    }

    if (this.props.getAllRcad !== preProps.getAllRcad) {
      if (this.props.getAllRcad.getRcadData) {
        if (this.props.getAllRcad.getRcadData.length) {
          let assessmentList = [];
          if (
            this.props.getAllRcad.getRcadData &&
            this.props.getAllRcad.getRcadData.length
          ) {
            for (let item of this.props.getAllRcad.getRcadData) {
              let tmpObj = item;
              if (item.instructions && item.instructions.length) {
                if (!item.instructions[0].filter) {
                  tmpObj.instruction = item.instructions[0].text;
                } else {
                  item.instructions.map((ins) => {
                    let max = ins.filter.max
                      ? ins.filter.max
                      : this.state.clientAge;
                    if (
                      this.state.clientAge >= ins.filter.min &&
                      this.state.clientAge <= max
                    ) {
                      tmpObj.instruction = ins.text;
                    }
                  });
                }
              }

              if (item.questions && item.questions.length > 0) {
                let assQusArray = [];
                for (let qus of item.questions) {
                  let qusObj = qus;
                  if (qus.answers && qus.answers.length) {
                    qusObj.answers = qus.answers.filter((ans) => {
                      if (ans.filter) {
                        let max = ans.filter.max
                          ? ans.filter.max
                          : this.state.clientAge;
                        if (
                          this.state.clientAge >= ans.filter.min &&
                          this.state.clientAge <= max
                        ) {
                          return ans;
                        }
                      } else {
                        return ans;
                      }
                    });
                  }
                  assQusArray.push(qusObj);
                  // if(qusObj.answers.length)
                }
              }
              assessmentList = [...assessmentList, tmpObj];
            }
          }
          this.setState({
            assessments: assessmentList,
            allQuestions: assessmentList[0].questions,
            getDataLoader: false,
            assEntryPage: assessmentList[0].entryPage,
          });
          const url = new URLSearchParams(window.location.search);
          if (url.get('type') && url.get('type') === 'multiple') {
            this.setState({ activeIndex: 2 });
          } else if (url.get('type') && url.get('type') === 'child') {
            this.setState({ activeIndex: 1 });
          } else {
            this.setState({ activeIndex: 0 });
          }
        }
      }
    }

    if (this.props.submitRcad !== preProps.submitRcad) {
      const data = this.props.submitRcad.submitUserRcadData;

      if (data) {
        this.setState((prevState) => ({
          assCompleted: true,
          assessmentResultData: {
            ...data,
            preferredClinician: prevState.preferredClinician,
          },
          btnLoading: false,
        }));
      }
    }
  }

  handleOptionChange = (value) => {
    const index = this.state.activeIndex;
    const assessmentIndex = this.state.assessmentIndex;
    if (!this.state.assessments[assessmentIndex]) {
      return null;
    }

    const allQuestions = [...this.state.allQuestions];
    allQuestions[index] = {
      ...this.state.allQuestions[index],
      selectedAns: value,
    };

    this.setState({ allQuestions });
    if (value) {
      if (
        this.props.getAllRcad.getRcadData &&
        this.props.getAllRcad.getRcadData.length > 0
      ) {
        let assId = this.state.assessments[assessmentIndex]._id;
        let assessmentAns = {};
        if (this.state.assessmentAns && this.state.assessmentAns[assId]) {
          assessmentAns = {
            ...this.state.assessmentAns,
            [assId]: [...this.state.assessmentAns[assId], value._id],
          };
        } else {
          assessmentAns = { ...this.state.assessmentAns, [assId]: [value._id] };
        }
        this.setState({ assessmentAns: assessmentAns });
      }
    }
  };

  handleNextStep = () => {
    let allQuestions = this.state.allQuestions;

    if (allQuestions[this.state.activeIndex].selectedAns) {
      let selectedAns = allQuestions[this.state.activeIndex].selectedAns;

      if (this.state.allQuestions.length > this.state.activeIndex) {
        const currQuestion = allQuestions[this.state.activeIndex];

        let nextQusIndex = allQuestions.filter((item) => {
          if (item.index === selectedAns.nextQuestion) {
            return item;
          }
        });

        if (
          this.state.assessments[this.state.assessmentIndex] &&
          this.state.assessments[this.state.assessmentIndex]._id
        ) {
          let backHistory = this.state.backHistory[
            this.state.assessments[this.state.assessmentIndex]._id
          ]
            ? this.state.backHistory[
                this.state.assessments[this.state.assessmentIndex]._id
              ]
            : [];
        }

        this.setState((prevState) => ({
          backQuestionQue: [
            ...prevState.backQuestionQue,
            {
              assessmentId: currQuestion.assessmentId,
              questionId: currQuestion._id,
              selectedAns: currQuestion.selectedAns,
            },
          ],
        }));

        if (nextQusIndex.length) {
          let index = allQuestions.indexOf(nextQusIndex[0]);
          this.setState({ activeIndex: index });
        } else if (this.state.assessments.length > this.state.assessmentIndex) {
          this.handleNextAssesment();
        } else {
          this.handleSubmit();
        }
      } else {
        this.handleSubmit();
        // this.props.history.push("/user/appointment/part1of2complete");
      }
    } else {
      AlertMsg('warning', 'Please select one option!');
    }
  };

  handleNextAssesment = () => {
    let indx = this.state.assessmentIndex;
    let assessments = this.state.assessments;

    if (
      assessments.length > indx &&
      assessments[indx + 1] &&
      assessments[indx + 1].questions
    ) {
      this.setState({
        assessmentIndex: indx + 1,
        activeIndex: 0,
        allQuestions: assessments[indx + 1].questions,
        assEntryPage: assessments[indx + 1].entryPage,
      });
    } else if (!this.state.preferredClinicianScreen) {
      this.setState({ preferredClinicianScreen: true });
    } else if (!this.state.noteEditor) {
      this.setState({
        noteEditor: true,
      });
    } else {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    const { assessmentAns, assessmentNote } = this.state;

    let postAnsData = [];
    if (assessmentAns) {
      for (let [key, value] of Object.entries(assessmentAns)) {
        let assObj = {
          clientId: localStorage.getItem('clientId'),
          answers: value,
          assessmentId: key,
        };
        postAnsData.push(assObj);
      }

      this.setState({ btnLoading: true });

      this.props.submitRcadUserAction(
        postAnsData,
        localStorage.getItem('clientId')
      );
    }

    const assessmentNoteStr = JSON.stringify(assessmentNote);
    const postAnsDataStr = JSON.stringify(postAnsData);

    localStorage.setItem('postAnsDataStr', postAnsDataStr);
    localStorage.setItem('assessmentNoteStr', assessmentNoteStr);
  };

  handleEntryPageNext = () => {
    if (this.state.assessments[this.state.assessmentIndex]) {
      this.setState({ assEntryPage: false });
    }
  };

  handleBackQus = () => {
    const backQuestionQue = this.state.backQuestionQue;
    if (backQuestionQue.length === 0) {
      this.props.history.push('/dashboard');
      return;
    }

    const lastEntryInQueue = backQuestionQue[backQuestionQue.length - 1];

    const prevAssessmentIndex = this.state.assessments.findIndex(
      (a) => a._id === lastEntryInQueue.assessmentId
    );

    const prevQuestionIndex = this.state.assessments[
      prevAssessmentIndex
    ].questions.findIndex((a) => a._id === lastEntryInQueue.questionId);

    const prevQuestion =
      this.state.assessments[prevAssessmentIndex].questions[prevQuestionIndex];

    const allQuestions = [...this.state.allQuestions];
    const selectedAnswersArr = [
      ...this.state.assessmentAns[lastEntryInQueue.assessmentId],
    ];

    let updatedAnswers = [];

    const allQuestionsWithRemovedAnswer = allQuestions.map((q) => {
      if (q._id === prevQuestion._id && q['selectedAns']) {
        updatedAnswers = selectedAnswersArr.filter(
          (sa) => sa !== q['selectedAns']._id
        );

        delete q['selectedAns'];

        return { ...q };
      } else {
        return q;
      }
    });

    backQuestionQue.pop();
    this.setState((prevState) => ({
      activeIndex: prevQuestionIndex,
      allQuestions: prevState.assessments[prevAssessmentIndex].questions,
      assessmentIndex: prevAssessmentIndex,
      assessmentAns: {
        ...prevState.assessmentAns,
        [lastEntryInQueue.assessmentId]: [...updatedAnswers],
      },
      backQuestionQue,
    }));
  };

  renderClinicianSelectionOrNotes() {
    if (this.state.preferredClinicianScreen && !this.state.noteEditor) {
      return (
        <Grid justify='flex-start' direction='column' container>
          <SearchClinician
            props={this.props}
            onSelectClinician={(clinician) =>
              this.setState({ preferredClinician: clinician._id })
            }
            selectedClinician={this.state.preferredClinician}
          />
        </Grid>
      );
    } else if (this.state.noteEditor) {
      return (
        <Grid justify='flex-start' direction='column' container>
          <Typography align='left'>Description:</Typography>
          <CKEditor
            editor={ClassicEditor}
            name='assessmentNote'
            data={this.state.assessmentNote}
            onChange={(event, editor) => {
              const data = editor.getData();
              this.setState({ assessmentNote: data });
            }}
          />
        </Grid>
      );
    }
  }
  render() {
    const { classes, clinicians } = this.props;

    const {
      allQuestions,
      activeIndex,
      assessmentIndex,
      assessments,
      assEntryPage,
      assCompleted,
      assessmentResultData,
      btnLoading,
      clientAge,
      noteEditor,
      assessmentNote,
      preferredClinicianScreen,
    } = this.state;
    return (
      <div>
        <div className={classes.root} />
        <Header props={this.props} />
        <Grid container justify='center' alignItems='center'>
          <Grid item component='div' className={classes.container}>
            {assCompleted ? (
              <AssessmentResult
                data={assessmentResultData}
                history={this.props.history}
              />
            ) : assEntryPage ? (
              <AssessmentEntryPage
                assessment={assessments[assessmentIndex]}
                handleEntryPageNext={this.handleEntryPageNext}
                history={this.props.history}
              />
            ) : (
              <React.Fragment>
                {clientAge && assessments[assessmentIndex] && (
                  <React.Fragment>
                    <Grid component='div' style={{ display: 'flex' }}>
                      <img src={BackArrow} className={classes.backArrow} />
                      <Typography
                        className={classes.back}
                        onClick={() => this.handleBackQus()}
                      >
                        Back
                      </Typography>
                      <ProgressBar
                        queIndex={activeIndex}
                        questions={this.state.allQuestions}
                      />
                    </Grid>
                    <Grid component='div' style={{ marginTop: '40px' }}>
                      <Typography className={classes.headerText}>
                        {!this.state.preferredClinicianScreen &&
                          !this.state.noteEditor &&
                          assessments[assessmentIndex].instruction}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                )}
                <Grid
                  component='div'
                  container
                  alignItems='center'
                  justify='center'
                  className={classes.boxContainer}
                >
                  {this.state.getDataLoader ? (
                    <Grid style={{ margin: 25 }}>
                      <Loader />
                    </Grid>
                  ) : (
                    <Grid className={classes.mobileListContainer}>
                      {clientAge &&
                      allQuestions &&
                      allQuestions.length > 0 &&
                      !noteEditor &&
                      !preferredClinicianScreen &&
                      allQuestions[activeIndex] ? (
                        <>
                          <Grid
                            style={{
                              display: allQuestions[activeIndex].question
                                ? 'block'
                                : 'none',
                              width: '45%',
                              float: 'left',
                              minWidth: '300px',
                            }}
                          >
                            <Typography className={classes.queText}>
                              {allQuestions[activeIndex].question}
                            </Typography>
                          </Grid>
                          <Grid
                            style={{
                              width: allQuestions[activeIndex].question
                                ? '45%'
                                : '100%',
                              float: 'right',
                            }}
                          >
                            {allQuestions[activeIndex].answers &&
                              allQuestions[activeIndex].answers.length > 0 &&
                              allQuestions[activeIndex].answers.map(
                                (ans, i) => {
                                  return (
                                    <Grid
                                      key={i}
                                      className={classes.notAtAllBox}
                                      onClick={() =>
                                        this.handleOptionChange(ans)
                                      }
                                      style={{
                                        marginBottom: '20px',
                                        width: '100%',
                                        backgroundColor:
                                          allQuestions[activeIndex]
                                            .selectedAns &&
                                          allQuestions[activeIndex].selectedAns
                                            ._id === ans._id
                                            ? '#304659'
                                            : '',
                                      }}
                                    >
                                      <Typography
                                        className={classes.notAtAllText}
                                        style={{
                                          color:
                                            allQuestions[activeIndex]
                                              .selectedAns &&
                                            allQuestions[activeIndex]
                                              .selectedAns._id === ans._id
                                              ? '#ffffff'
                                              : '#304659',
                                        }}
                                      >
                                        {ans.text}
                                      </Typography>
                                      {ans.description && (
                                        <Tooltip
                                          TransitionComponent={Zoom}
                                          enterDelay={500}
                                          leaveDelay={200}
                                          style={{
                                            position: 'absolute',
                                            right: 16,
                                            top: 8,
                                          }}
                                          disableFocusListener
                                          title={
                                            <p
                                              style={{
                                                fontSize: '16px',
                                                lineHeight: '20px',
                                              }}
                                            >
                                              {ans.description
                                                ? ans.description
                                                : ''}
                                            </p>
                                          }
                                        >
                                          <HelpOutlineIcon onClick />
                                        </Tooltip>
                                      )}
                                    </Grid>
                                  );
                                }
                              )}
                          </Grid>
                        </>
                      ) : (
                        this.renderClinicianSelectionOrNotes()
                      )}
                    </Grid>
                  )}
                </Grid>
                <Grid
                  style={{
                    marginTop: '30px',
                    float: 'right',
                    cursor: 'pointer',
                    marginBottom: '100px',
                  }}
                >
                  <Button
                    style={{ marginRight: '32px' }}
                    onClick={() => {
                      this.props.history.push({
                        pathname: '/dashboard',
                        state: { matchedClinicians: [], riskScore: 0 },
                      });
                    }}
                  >
                    Go to Dashboard
                  </Button>
                  {false && (
                    <Button
                      style={{ marginRight: '32px' }}
                      onClick={() => {
                        this.props.history.push({
                          pathname: '/clinician',
                          search: '?type=recommended',
                          state: { matchedClinicians: [], riskScore: 0 },
                        });
                      }}
                    >
                      Skip Assessment
                    </Button>
                  )}
                  {allQuestions &&
                    allQuestions.length > 0 &&
                    allQuestions[activeIndex] && (
                      <Button
                        className={classes.nextButtonStyle}
                        onClick={this.handleNextStep}
                        disabled={btnLoading}
                      >
                        {btnLoading && (
                          <CircularProgress
                            size={25}
                            style={{ color: '#ffffff' }}
                          />
                        )}
                        {!btnLoading && 'Next'}
                      </Button>
                    )}
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

RcardComponent.defaultProps = {
  getAllRcad: {},
  getDataLoader: true,
  clientInfo: {},
};

const connected = connect(
  (state) => ({
    clientInfo: state.getByIdClient,
    getAllRcad: state.getRcad,
    submitRcad: state.submitUserRcad,
    getDataLoader: state.getRcad.request,
    clinicians: state.getClinician.getClinicianData,
  }),
  {
    getRcadUserAction,
    submitRcadUserAction,
    getByIdClientAction,
    getClinicianAction,
  }
)(RcardComponent);

const formed = reduxForm({
  form: 'rcad',
})(connected);

export default withStyles(styles)(formed);
