/* eslint-disable quotes */
/* eslint-disable semi */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable operator-linebreak */
/* eslint-disable no-trailing-spaces */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-quotes */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...option }) => (
  <Route
    {...option}
    exact
    render={(props) =>
      localStorage.getItem("__r") &&
      localStorage.getItem("ACCESS_TOKEN_PATH") ? (
        <Component {...props} />
      ) : (
        <Redirect push to="/admin/login" />
      )
    }
  />
);

export default PrivateRoute;
