import React, { useEffect, useState, createRef } from 'react';
import { connect } from 'react-redux';
import { saveClinicianSignature } from '../../../utils/actions/clinician.action';
import Paper from '@material-ui/core/Paper';
import { DropzoneArea } from 'material-ui-dropzone';
import profile from '../../../assets/imgs/ananyaBig.png';
import {
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Drawer,
  Grid,
  Typography,
  Input,
} from '@material-ui/core';
import '../../../clinician/clinician.scss';
import Axios from 'axios';
import { baseURL } from '../../../utils/axios';
import AlertMsg from '../../../utils/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Layout from '../common/AdminLayout';
import Loader from '../../../components/Loader/Loader';
import countries from '../../../utils/countries_data';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useStyles from './setting.styles';
import SelectClinicAddress from '../../../admin/components/common/SelectClinicAddresses';
import {
  CanvasDialog,
  Spinner,
  ImageUploader,
} from '../../../components/atoms';

let initState = {
  firstName: '',
  lastName: '',
  email: '',
  avatar: '',
  phoneNumber: '',
  phoneType: '',
  address: {
    city: '',
    pinCode: '',
    country: '',
    addressLine1: '',
    addressLine2: '',
  },
  clinicianId: '',
  patientAgeGroup: '',
  discipline: '',
  signature: '',
};

const SettingAdmin = ({
  saveClinicianSignature,
  signatureSaved,
  updatedSignature,
}) => {
  const classes = useStyles();
  const [profileImage, setProfileImage] = React.useState(profile);
  const [details, setDetails] = useState({ ...initState });
  const [btnLoading, setBtnLoading] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [pageLoader, setPageLoader] = useState(false);
  const [openCanvasDialog, setCanvasDialog] = useState(false);
  let signaturePad = createRef();

  useEffect(() => {
    try {
      if (signatureSaved) {
        updateSignature();
        setCanvasDialog(false);
        setBtnLoading(false);
      }
    } catch (e) {
      setBtnLoading(false);
    }
  }, [signatureSaved, updatedSignature]);

  const updateSignature = async () => {
    setDetails({
      ...details,
      signature: await getBase64FromUrl(updatedSignature),
    });
  };
  const setRef = (ref) => {
    if (ref && ref.fromDataURL) {
      ref.fromDataURL(details.signature);
      signaturePad = ref;
    }

    if (ref && ref.toDataURL) {
      ref.toDataURL();
      signaturePad = ref;
    }

    if (ref && ref.clear) {
      ref.clear();
      signaturePad = ref;
    }
  };

  const openSignatureCanvas = (appId) => {
    setCanvasDialog(true);
  };

  const closeCanvas = () => {
    setCanvasDialog(false);
  };

  const clearSignature = () => {
    if (signaturePad) {
      signaturePad.clear();
    }
  };

  const dataURLtoBlob = (dataurl) => {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const modifyUserSignature = () => {
    if (signaturePad) {
      if (signaturePad.isEmpty()) {
        AlertMsg(
          'error',
          'If you plan to add signature later then click on dark space!'
        );
      } else {
        setBtnLoading(true);

        const base64Img = signaturePad.toDataURL();
        setDetails({ ...details, signature: base64Img });
        const formData = new FormData();
        formData.append('signature', dataURLtoBlob(base64Img));
        saveClinicianSignature(formData);
      }
    }
  };

  const readFile = (file) => {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();
      reader.onload = function (event) {
        var _event$target;
        resolve(
          event === null || event === void 0
            ? void 0
            : (_event$target = event.target) === null ||
              _event$target === void 0
            ? void 0
            : _event$target.result
        );
      };
      reader.onerror = function (event) {
        reader.abort();
        reject(event);
      };
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    setPageLoader(true);
    getUserDetails();
  }, []);

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };
  const getUserDetails = () => {
    Axios.get(`${baseURL}/api/user/details`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then(async (res) => {
        if (res.data) {
          const base64Signature = res.data.signature
            ? await getBase64FromUrl(res.data.signature)
            : '';
          setDetails({
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            email: res.data.email,
            clinicianId: res.data._id,
            address: res.data.address,
            phoneNumber: res.data.phoneNumber,
            phoneType: res.data.phoneType,
            patientAgeGroup: res.data.patientAgeGroup
              ? res.data.patientAgeGroup
              : '',
            bio: res.data.bio ? res.data.bio : '',
            discipline: res.data.discipline ? res.data.discipline : '',
            signature: base64Signature || '',
          });
          setProfileImage(res.data.avatar ? res.data.avatar : profile);
          setPageLoader(false);
        }
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const documentUpload = async (selectedFiles) => {
    setBtnLoading(true);
    const formData = new FormData();
    formData.append('document', selectedFiles);
    console.log('formData', formData);
    Axios.post(`${baseURL}/api/user/upload?type=profileImage`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        setBtnLoading(false);
        localStorage.setItem('avatar', profileImage);
      })
      .catch((e) => {
        setBtnLoading(false);
        AlertMsg(
          'success',
          e.response ? e.response.message : 'Something went wrong!'
        );
      });
  };

  const handleImageUpload = async (files) => {
    if (files.length > 0) {
      var result = await readFile(files[0]);
      // await documentUpload(result);
      await documentUpload(files[0]);
      setProfileImage(result);
    }
  };

  const saveClinicianInfo = () => {
    let body = {
      ...details,
    };

    if (avatar) {
      body.avatar = avatar;
    }

    if (!details.password || details.password.trim() === '') {
      AlertMsg('error', 'Please enter your password to update!');
      setBtnLoading(false);
      return false;
    }

    if (details.password != '' && details.password !== details.confPassword) {
      AlertMsg('error', 'Password and confirm password must be same!');
      setBtnLoading(false);
      return false;
    }

    if (details.password == '') {
      delete details.password;
      delete details.confPassword;
    }

    Axios.put(`${baseURL}/api/user/`, body, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        localStorage.setItem('avatar', res.data.avatar);
        localStorage.setItem('fName', body.firstName);
        localStorage.setItem('lName', body.lastName);
        setBtnLoading(false);
        AlertMsg('success', 'Your record update successfully.');
        // TODO Handle data reload properly
        window.location.reload(false);
        // console.log("Update res: ", res.data);
        // setBtnLoading(false);
        // AlertMsg("success", "Your record update successfully.");
      })
      .catch((e) => {
        setBtnLoading(false);
        AlertMsg(
          'success',
          e.response ? e.response.message : 'Something went wrong!'
        );
      });
  };

  const handleSaveDetails = () => {
    setBtnLoading(true);
    saveClinicianInfo();
  };

  const handleInfoChange = (event) => {
    const { value, name } = event.target;
    const updatePath = name.split('.');
    if (updatePath.length > 1) {
      setDetails((prevState) => {
        let newState = { ...prevState };
        newState.address = { ...newState.address, [updatePath[1]]: value };
        return newState;
      });
    } else {
      setDetails({ ...details, [name]: value });
    }
  };

  // const handleContactInfoChange = (event) => {
  //   let newData = {...details};
  //   newData.address[event.target.name] = event.target.value;
  //   setDetails({...newData});
  // }

  return (
    <Layout updatedAvatar={profileImage}>
      {pageLoader ? (
        <Grid container style={{ paddingLeft: '45%', paddingTop: '10%' }}>
          <Loader />
        </Grid>
      ) : (
        <>
          <Grid container spacing={3} className={classes.infoMain}>
            <Spinner isOpen={btnLoading} />
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Typography className={classes.inputText}>
                  Basic Information
                </Typography>
                <Typography className={classes.inputTitle}>
                  Profile Image*
                </Typography>
                <Grid
                  container
                  style={{ marginBottom: '15px', width: '150px' }}
                >
                  <ImageUploader
                    src={profileImage}
                    uploadedImage={handleImageUpload}
                  />
                </Grid>
                <Grid container style={{ marginBottom: '15px' }}>
                  <Grid item xs={12}>
                    <Typography className={classes.inputTitle}>
                      First Name*
                    </Typography>
                    <Input
                      className={classes.inputsty}
                      type='text'
                      value={details.firstName}
                      onChange={handleInfoChange}
                      name='firstName'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.inputTitle}>
                      Last Name*
                    </Typography>
                    <Input
                      className={classes.inputsty}
                      type='text'
                      value={details.lastName}
                      onChange={handleInfoChange}
                      name='lastName'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.inputTitle}>
                      Email
                    </Typography>
                    <Input
                      className={classes.inputsty}
                      type='email'
                      name='email'
                      value={details.email}
                      readOnly={true}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ paddingRight: '10px' }}>
                    <Typography className={classes.inputTitle}>
                      Password*
                    </Typography>
                    <Input
                      className={classes.inputsty}
                      type='password'
                      placeholder={'Password'}
                      value={details.password ? details.password : ''}
                      onChange={handleInfoChange}
                      name='password'
                    />
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: '10px' }}>
                    <Typography className={classes.inputTitle}>
                      Confirm Password*
                    </Typography>
                    <Input
                      className={classes.inputsty}
                      type='password'
                      placeholder={'Confirm Password'}
                      value={details.confPassword ? details.confPassword : ''}
                      onChange={handleInfoChange}
                      name='confPassword'
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Typography className={classes.inputText}>
                  Contact Information
                </Typography>
                <Grid container style={{ marginBottom: '15px' }}>
                  <Grid item xs={6} style={{ paddingRight: '10px' }}>
                    <Typography className={classes.inputTitle}>
                      Phone Number*
                    </Typography>
                    <input
                      className={classes.inputsty}
                      type='text'
                      name='phoneNumber'
                      value={details.phoneNumber}
                      onChange={handleInfoChange}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: '10px' }}>
                    <Typography className={classes.inputTitle}>
                      Type*
                    </Typography>
                    <FormControl className={classes.inputsty}>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        name='phoneType'
                        value={details.phoneType}
                        onChange={handleInfoChange}
                      >
                        <MenuItem value={''}>Select</MenuItem>
                        <MenuItem value={'mobile'}>Mobile</MenuItem>
                        <MenuItem value={'phone'}>Phone</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid style={{ marginBottom: '15px' }}>
                  <Typography className={classes.inputTitle}>
                    Address*
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <SelectClinicAddress
                    name='address'
                    value={details.address}
                    onSelected={(data) => {
                      let temp = details;
                      temp.address = data;
                      setDetails(temp);
                    }}
                  />
                </Grid>

                <Grid container style={{ marginTop: '15px' }}>
                  <Grid container alignItems='flex-start' direction='column'>
                    <Grid style={{ marginBottom: '15px' }} item>
                      <Typography className={classes.inputTitle}>
                        Signature
                      </Typography>
                    </Grid>
                    <Grid
                      style={{
                        border: '1px solid #ccc',
                        padding: '20px',

                        height: '150px',
                      }}
                      justify='center'
                      alignItems='center'
                      container
                      item
                    >
                      {details.signature && (
                        <img
                          src={details.signature}
                          width='300px'
                          height='100px'
                        />
                      )}
                    </Grid>
                    <Grid
                      justify='space-between'
                      alignItems='center'
                      wrap='nowrap'
                      container
                      item
                    >
                      <Grid item>
                        <Button
                          onClick={() => setCanvasDialog(true)}
                          variant={details.signature ? 'outlined' : 'contained'}
                          style={{
                            backgroundColor: details.signature
                              ? '#fff'
                              : '#1964EB',
                            color: details.signature ? '#1964EB' : '#fff',
                            border: details.signature
                              ? '1px solid #1964EB'
                              : 'none',
                            marginTop: '15px',
                            whiteSpace: 'nowrap',
                          }}
                          color='primary'
                        >
                          {details.signature
                            ? 'Edit Signature'
                            : 'Create Signature'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={btnLoading}
              style={{ float: 'right', marginRight: 35 }}
              variant='contained'
              color='primary'
              onClick={handleSaveDetails}
            >
              {btnLoading ? (
                <CircularProgress size={20} color={'white'} />
              ) : (
                'Submit'
              )}
            </Button>
          </Grid>
        </>
      )}
      <CanvasDialog
        open={openCanvasDialog}
        onClose={closeCanvas}
        ref={signaturePad}
        signature={details.signature}
        setRef={setRef}
        clear={clearSignature}
        modifyUserSignature={modifyUserSignature}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  signatureSaved: state.saveClinicianSignature.signatureSaved,
  updatedSignature: state.saveClinicianSignature.updatedSignature,
});

const mapDispatchToProps = (dispatch) => ({
  saveClinicianSignature: (signature) =>
    dispatch(saveClinicianSignature(signature)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingAdmin);
