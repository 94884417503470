import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import dayGridPlugin from '@fullcalendar/daygrid';
import list from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import Layout from '../common/AdminLayout';
import Loader from '../../../components/Loader/Loader';
import LinearProgress from '@material-ui/core/LinearProgress';

import { Container, Avatar, Badge } from '@material-ui/core';
import { baseURL } from '../../../utils/axios';
import { getTimezoneOffset } from '../../../utils/timUtils';
import Axios from 'axios';
import moment from 'moment';
import AppointmentDetailsView from '../../../clinician/AppointmentDetailsView';
import CalenderCreateApointment from '../../../common/CalenderCreateNewAppointments';
import CancelIcon from '@material-ui/icons/Cancel';
import DoctorSearchView from '../../../common/DoctorSearchView';
import { Link } from 'react-router-dom';
import { useLocation, Switch } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import tippy, { followCursor } from 'tippy.js';
import ReactDOMServer from 'react-dom/server';
import userIcon from '../../../assets/imgs/download.png';
import 'tippy.js/themes/light.css';
import styled from 'styled-components';
import './style.css';
import { getEventColor } from '../../../common/calendar/slots';
import Popover from './Popover';

const CalendarWrapper = styled.div`
  .fc-timegrid-event-harness-inset {
    inset-inline-start: 0% !important;
    inset-inline-end: 0% !important;
  }

  .fc-timegrid-event-harness {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
`;
function SingleClinicianCalender() {
  const calendarComponentRef = React.createRef();
  const [loader, setLoader] = useState(false);
  const [resources, setResources] = useState([]);
  const [events, setEvents] = useState([]);
  const [appointmentId, setAppointmentId] = useState('');
  const [openAppointmentDetails, setOpenAppointmentDetails] = useState(false);
  const [openCreateAppointment, setOpenCreateAppointment] = useState(false);
  const [selectedTime, setSelectedTime] = useState({});
  const [selectedDoctor, setDelectedDoctor] = useState();
  const [appointmentType, setAppointmentType] = useState('');
  const location = useLocation();

  useEffect(() => {
    getShedules();
  }, [selectedDoctor]);

  useEffect(() => {
    var currentURL = window.location.href;
    var id = currentURL.substring(currentURL.lastIndexOf('/') + 1);
    setDelectedDoctor(id);
  }, []);

  const getShedules = () => {
    setLoader(true);
    Axios.get(
      `${baseURL}/api/schedule/get?id=${[
        selectedDoctor,
      ]}&timezoneOffset=${getTimezoneOffset()}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        if (res.data && res.data.length) {
          const resourcesList = [];
          let eventsList = [];
          res.data.forEach((item, itemIndex) => {
            const resource = {
              id: itemIndex,
              title: item.firstName + ' ' + item.lastName,
              imageUrl: item.avatar,
              clinicianId: item._id,
            };
            item.events.forEach((event, index) => {
              let evtData = {
                resourceId: itemIndex,
                title: event.title,
                start: event.start,
                end: event.end,
                index: `evt-${index}`,
                clinicianId: item._id,
                id: event._id,
                color: getEventColor(event.type),
                textColor:
                  event.type === 'Appointment' || event.type === 'Blocked'
                    ? '#fff'
                    : '#000',
                type: event.type,
                client: event.client,
              };
              eventsList.push(evtData);
            });
            resourcesList.push(resource);
          });
          setResources(resourcesList);
          setEvents(eventsList);
          // this.setState({ calendarEvents: scheduleList, newEvents: scheduleList });
        }
        setLoader(false);
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const eventClicked = (eventClickInfo, event) => {
    setAppointmentType({
      type: eventClickInfo.event._def.extendedProps.type,
      clinicianId: eventClickInfo.event._def.extendedProps.clinicianId,
      calenderSlotId: eventClickInfo.event.id,
    });
    setDelectedDoctor(eventClickInfo.event._def.extendedProps.clinicianId);
    setSelectedTime({
      start: eventClickInfo.event.start,
      end: eventClickInfo.event.end,
    });

    if (
      eventClickInfo.event.title == 'Current-Patient' ||
      eventClickInfo.event.title == 'New-Patient' ||
      eventClickInfo.event.title == 'All-Patient'
    ) {
      setOpenCreateAppointment(true);
    }

    if (eventClickInfo.event._def.extendedProps.type == 'Appointment') {
      console.log(eventClickInfo.event.id);
      setOpenAppointmentDetails(true);
      setAppointmentId(eventClickInfo.event.id);
    }
  };

  const handleCreateClose = () => {
    setOpenCreateAppointment(false);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div>
        <b>{eventInfo.timeText}</b>
        <br />
        <i>{eventInfo.event.title}</i>
      </div>
    );
  };

  var instance = null;
  const handleMouseEnter = (arg) => {
    if (arg.event._def.extendedProps.type == 'Appointment') {
      if (instance) {
        instance.destroy();
      }

      instance = tippy(arg.el, {
        content: ReactDOMServer.renderToStaticMarkup(
          <Popover
            {...arg.event._def.extendedProps}
            start={arg.event.start}
            end={arg.event.end}
          />
        ),
        strategy: 'fixed',
        allowHTML: true,
        placement: 'left',
        interactive: true,
        theme: 'light',
        zIndex: 999999999,
        followCursor: true,
        plugins: [followCursor],
      });
    }
  };

  return (
    <>
      {loader && <LinearProgress />}
      <br />
      <Typography variant='h5'>
        {resources && resources.length ? resources[0].title : ''}
      </Typography>
      <CalendarWrapper>
        <FullCalendar
          schedulerLicenseKey='0258088407-fcs-1613958667'
          // eventResize={this.handleeventResize}
          // eventDrop={this.handleeventDrop}
          // select={this.handleSelect}
          // eventReceive={this.handleeventRecieve}
          // eventsSet={this.handleEvents}
          defaultView={'timeGridWeek'}
          initialView={'timeGridWeek'}
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          dateIncrement={(date) => console.log(date)}
          rerenderDelay={0}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            list,
            interactionPlugin,
            timelinePlugin,
            resourceTimeGridPlugin,
          ]}
          events={events}
          ref={calendarComponentRef}
          eventAdd={(event) => console.log(event)}
          disableResizing={true}
          draggable={false}
          disableDragging={true}
          selectable
          eventOverlap={false}
          eventClick={eventClicked}
          draggable
          droppable
          allDaySlot={false}
          nowIndicator
          eventMouseEnter={handleMouseEnter}
          slotMinTime='06:00:00'
          slotMaxTime='22:00:00'
          contentHeight='auto'
        />
      </CalendarWrapper>
      <AppointmentDetailsView
        appointmentId={appointmentId}
        handleClose={() => setOpenAppointmentDetails(false)}
        open={openAppointmentDetails}
        // handleSubmitRequest={() => {}}
        // setDetailDrawer={() => {}}
        // detailsOnPatient={false}
        // openPatientDrawer={openAppointmentDetails}
        handleInitReferralClick={() => {}}
        handleEditRecord={() => {}}
        refresh={() => {}}
      />
      <CalenderCreateApointment
        selectedTime={selectedTime}
        open={openCreateAppointment}
        key={new Date().getTime()}
        appointmentDetail={appointmentType}
        handleClose={handleCreateClose}
        handleSubmit={() => {
          handleCreateClose();
          getShedules();
        }}
      />
    </>
  );
}

export default SingleClinicianCalender;
