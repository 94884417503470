/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Grid } from '@material-ui/core';
import { KeyBindingUtil } from 'draft-js';
import { CustomImage, CustomDots } from './TableImageContainer';
import { TableNameFont, TableDescFont } from './TableFont';
import { TableButtons } from './TableButtons';
import { Loader } from '../../user/Loader';
import styles from './TableBody_style';
import userIcon from '../../assets/imgs/download.png';
import TableCell from '@material-ui/core/TableCell';

export const TableBody = ({ data, onRowSelect, isLoader, ...props }) => {
  const classes = styles();
  return (
    <>
      {isLoader ? (
        <Grid
          container
          alignItems='center'
          justify='center'
          style={{ marginTop: '10px' }}
        >
          <Loader />
        </Grid>
      ) : !data.length > 0 ? (
        <Grid
          container
          alignItems='center'
          justify='space-around'
          className={classes.root}
        >
          There is no data
        </Grid>
      ) : (
        data.map((node, i) => {
          let zIndexCnt =
            node.viewDetails &&
            node.viewDetails.clientId &&
            node.viewDetails.clientId.length
              ? node.viewDetails.clientId.length
              : 0;
          return (
            <Grid
              key={i}
              container
              alignItems='center'
              justify='space-around'
              className={classes.root}
              // onClick={() => onRowSelect(true)}
              {...props}
              wrap='nowrap'
            >
              <Grid className={classes.container} style={{ width: '15%' }}>
                <TableDescFont
                  text={node.billingId && node.billingId._id}
                  style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                />
              </Grid>

              <Grid className={classes.container} style={{ width: '15%' }}>
                <TableDescFont text={node.date ? node.date : '--'} />
              </Grid>
              <Grid
                className={classes.container}
                alignItems='center'
                justify='center'
                style={{ width: '15%' }}
              >
                {node.refClinicianImage ? (
                  <>
                    <CustomImage logo={node.refClinicianImage} />
                    <TableNameFont
                      style={{
                        marginLeft: '11px',
                        width: '111px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        display: 'inline',
                        textAlign: 'left',
                      }}
                      text={node.refClinician}
                    />
                  </>
                ) : (
                  <TableDescFont
                    text={node.referralBy}
                    style={{ marginLeft: '-20px' }}
                  />
                )}
              </Grid>
              {/* <Grid className={classes.userImages} style={{ width: "5%" }}>
                {console.log(node)}
                { node.viewDetails && console.log( node.viewDetails, node.viewDetails.clientId,)}
                { node.viewDetails && node.viewDetails.groupType === "adult" && node.viewDetails.clientId && node.viewDetails.clientId.length ?
                          node.viewDetails.clientId.map(clnt => {
                            console.log(clnt.avatar)
                            let imgTag = <img key={clnt._id} src={(clnt.avatar) ? clnt.avatar : userIcon} className={classes.imgTable} style={{zIndex: zIndexCnt}} />;
                            zIndexCnt--;
                            return imgTag;
                          }) : <CustomImage logo={node.patient} />
                        }
              </Grid> */}
              <Grid
                className={classes.container}
                style={{ width: '10%' }}
                justify='flex-start'
                container
              >
                {node.viewDetails &&
                node.viewDetails.groupType === 'adult' &&
                node.viewDetails.clientId &&
                node.viewDetails.clientId.length ? (
                  node.viewDetails.clientId.map((clnt) => {
                    let imgTag = (
                      <img
                        key={clnt._id}
                        src={clnt.avatar ? clnt.avatar : userIcon}
                        className={classes.imgTable}
                        style={{ zIndex: zIndexCnt }}
                      />
                    );
                    zIndexCnt--;
                    return imgTag;
                  })
                ) : (
                  <CustomImage logo={node.patient} />
                )}
              </Grid>
              <Grid
                className={classes.container}
                style={{ width: '15%' }}
                justify='flex-start'
                container
              >
                <TableDescFont text={node.type} />
              </Grid>
              <Grid
                className={classes.container}
                style={{ width: '12%' }}
                justify='flex-start'
                container
              >
                <TableDescFont text={node.location} />
              </Grid>
              <Grid
                className={classes.container}
                alignItems='center'
                justify='flex-start'
                style={{ width: '11%' }}
              >
                <CustomDots
                  bgColor={
                    node.paymentStatus === 'Paid' ? '#00bc07' : '#db1919'
                  }
                />
                <TableDescFont
                  text={node.paymentStatus}
                  style={{ marginLeft: '11px' }}
                />
              </Grid>
              <Grid className={classes.container} style={{ minWidth: '15%' }}>
                <TableButtons
                  onClick={() => onRowSelect(node)}
                  style={{
                    cursor: 'pointer',
                    minWidth: '160px',
                    width: 'unset',
                    backgroundColor:
                      node.actionType === 'blue'
                        ? '#304659'
                        : node.actionType === 'no'
                        ? '#db1919'
                        : '#c0d0de',
                  }}
                  title={node.action}
                  // bgColor={
                  //   node.actionType === "blue"
                  //     ? "#304659"
                  //     : node.actionType === "no"
                  //     ? "#db1919"
                  //     : "#c0d0de"
                  // }
                  textColor={
                    node.actionType === 'blue'
                      ? '#ffffff'
                      : node.actionType === 'no'
                      ? '#ffffff'
                      : 'rgba(48, 70, 89, 50%)'
                  }
                  {...props}
                />
              </Grid>
            </Grid>
          );
        })
      )}
    </>
  );
};

TableBody.defaultProps = {
  onRowSelect: () => {},
  isLoader: true,
};
