import {
  GET_ALL_BILLING_DATA_REQUEST,
  GET_ALL_INVOICE_BY_CLIENT_ID_REQUEST,
  GET_SINGLE_INVOICE_REQUEST,
  GET_ALL_BILLING_FOR_ADMIN_REQUEST,
  CLEAR_SINGLE_INVOICE,
} from '../constant';

export const getAllBillingDataAction = () => ({
  type: GET_ALL_BILLING_DATA_REQUEST,
  endpoint: '/api/billing',
});

export const getAllInvoiceAction = (id) => ({
  type: GET_ALL_INVOICE_BY_CLIENT_ID_REQUEST,
  endpoint: `/api/billing/${id}/client`,
});

export const getSingleInvoiceAction = (id) => ({
  type: GET_SINGLE_INVOICE_REQUEST,
  endpoint: `/api/billing/${id}`,
});

export const getAllBillings = (selectedFilter, page) => ({
  type: GET_ALL_BILLING_FOR_ADMIN_REQUEST,
  endpoint: `/api/billing/getAll?page=${page}&status=${selectedFilter}`,
});

export const clearInvoice = () => ({
  type: CLEAR_SINGLE_INVOICE,
});
