/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
import React, { PureComponent } from "react";
import { Typography,  withStyles, Grid } from "@material-ui/core";
// import SearchIcon from '@material-ui/icons/Search';
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import logo from "../../../assets/user_images/Group 74.svg";
// import search from '../../../assets/user_images/search.svg';
import { CustomNextButtons } from "../../../common/CustomButtons";
import insurance_divider from "../../../assets/user_images/insurance_divider.svg";
import Header from "../../../common/Header";
import Stepper from "../../../common/Stepper";
import styles from "./InsuranceInfoStyle";

class InsuranceInformation extends PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header props={this.props} />
        <div className={classes.root} />
        <Grid container justify="center" alignItems="center">
          <Grid className={classes.titleContainer} component="div">
            <Stepper tab={1} />
            <Grid
              container
              component="div"
              alignItems="center"
              justify="center"
            >
              <Grid item component="div" className={classes.container}>
                <Grid component="div">
                  <img className={classes.logosty} src={logo} />
                  <Typography className={classes.title}>
                    We take health insurance
                  </Typography>
                  <Typography className={classes.subtitle}>
                    Search for your insurance provider to see if you are
                    covered.
                  </Typography>
                  <input
                    type="search"
                    placeholder="Search"
                    className={classes.search}
                  />
                  <Typography className={classes.exmple}>
                    e.g. Dubai Health Insurance, AETNA, etc.
                  </Typography>
                  <Grid
                    container
                    justify="center"
                    style={{ marginTop: "30px" }}
                  >
                    <CustomNextButtons
                      width="167px"
                      title="Next"
                      onPressButton={() =>
                        this.props.history.push(
                          `/paymentInfo/${this.props.match.params.id}`
                        )
                      }
                    />
                  </Grid>

                  <Grid
                    container
                    justify="center"
                    style={{ marginTop: "35px" }}
                  >
                    <img src={insurance_divider} className={classes.divider} />
                  </Grid>

                  <Grid container justify="center">
                    <Typography className={classes.text}>
                      Don’t have insurance?
                    </Typography>

                    <Typography className={classes.subtext}>
                      You can see a doctor without insurance
                    </Typography>
                  </Grid>

                  <Grid
                    component="div"
                    style={{
                      marginBottom: "115px",
                    }}
                  >
                    <Typography
                      display="inline"
                      className={classes.bottomtext}
                      onClick={() =>
                        this.props.history.push(
                          `/paymentInfo/${this.props.match.params.id}`
                        )
                      }
                    >
                      Skip Insurance
                    </Typography>
                    <Typography
                      display="inline"
                      className={classes.bottomtext}
                      style={{ textDecoration: "none" }}
                    >
                      {" "}
                      &gt;
                    </Typography>
                    {/* <ArrowForwardIosIcon
                      display="inline"
                      className={classes.arrow}
                    /> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}



export default withStyles(styles)(InsuranceInformation);
