import React from 'react';

const CheckoutPaymentMethods = ({
  handlePayment,
  handleNext,
  paymentMethod,
  transInfo,
  onInfoChange,
  onDateChange,
  history
}) => (
  <div className='block mx-auto max-w-3xl mb-4'>
    <fieldset>
      <label htmlFor className='block text-lg font-bold mb-2'>
        Select Payment Method
      </label>
      <div className='bg-white rounded-md -space-y-px'>
        <label className='relative border p-4 flex cursor-pointer focus:outline-none'>
          <input
            type='radio'
            name='checkout'
            value='card'
            className='payment-method h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500'
            aria-labelledby='checkout-0-label'
            aria-describedby='checkout-0-description'
            onClick={handlePayment}
          />
          <div className='ml-3 flex flex-col'>
            <span id='checkout-0-label' className='block text-sm font-bold'>
              Credit Card
            </span>

            <span
              id='checkout-0-description'
              className='block text-sm text-gray-400'
            >
              Visa, Master Card, Amex. Payment will be processed by PayTabs.
            </span>
          </div>
        </label>

        <label className='relative border p-4 flex cursor-pointer focus:outline-none'>
          <input
            type='radio'
            name='checkout'
            value='pos'
            className='payment-method h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500'
            aria-labelledby='checkout-1-label'
            aria-describedby='checkout-1-description'
            onClick={handlePayment}
          />
          <div className='ml-3 flex flex-col'>
            <span id='checkout-1-label' className='block text-sm font-bold'>
              POS System
            </span>

            <span
              id='checkout-1-description'
              className='block text-sm text-gray-400'
            >
              Acquire transaction details from POS System and enter them below.
            </span>
          </div>
        </label>

        <label className='relative border p-4 flex cursor-pointer focus:outline-none'>
          <input
            type='radio'
            name='checkout'
            value='banktranser'
            className='payment-method h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500'
            aria-labelledby='checkout-2-label'
            aria-describedby='checkout-2-description'
            onClick={handlePayment}
          />
          <div className='ml-3 flex flex-col'>
            <span id='checkout-2-label' className='block text-sm font-bold'>
              Bank Transfer
            </span>

            <span
              id='privacy-setting-2-description'
              className='block text-sm text-gray-400'
            >
              Acquire bank transfer details from client and enter them below.
            </span>
          </div>
        </label>

        <label className='relative border p-4 flex cursor-pointer focus:outline-none'>
          <input
            type='radio'
            name='checkout'
            value='cheque'
            className='payment-method h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500'
            aria-labelledby='checkout-3-label'
            aria-describedby='checkout-3-description'
            onClick={handlePayment}
          />
          <div className='ml-3 flex flex-col'>
            <span id='checkout-3-label' className='block text-sm font-bold'>
              Cheque
            </span>

            <span
              id='privacy-setting-3-description'
              className='block text-sm text-gray-400'
            >
              Acquire cheque details from client and enter them below.
            </span>
          </div>
        </label>

        <label className='relative border p-4 flex cursor-pointer focus:outline-none'>
          <input
            type='radio'
            name='checkout'
            value='cash'
            className='payment-method h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500'
            aria-labelledby='checkout-4-label'
            aria-describedby='checkout-4-description'
            onClick={handlePayment}
          />
          <div className='ml-3 flex flex-col'>
            <span id='checkout-4-label' className='block text-sm font-bold'>
              Cash
            </span>

            <span
              id='privacy-setting-4-description'
              className='block text-sm text-gray-400'
            >
              paid in person in cash. List value below.
            </span>
          </div>
        </label>
      </div>
    </fieldset>

    <div
      id='pos_container'
      className={`bg-indigo-50 border border-indigo-200 p-7 ${paymentMethod === 'pos' ? '' : 'hidden'
        }`}
    >
      <div className='flex flex-nowrap'>
        <div className='item w-3/6 pr-2'>
          <p className='text-sm mb-2'>Enter POS Transaction Reference Number</p>
          <input
            type='text'
            className='w-full p-3 text-xs focus:outline-none'
            value={transInfo.transactionId}
            name='transactionId'
            onChange={onInfoChange}
          />
        </div>
        <div className='item w-3/6'>
          <p className='text-sm mb-2'>Confirm Reference Number</p>
          <input
            type='text'
            className='w-full p-3 text-xs focus:outline-none'
            value={transInfo.posReferenceNumberConfirm}
            name='posReferenceNumberConfirm'
            onChange={onInfoChange}
          />
        </div>
      </div>
    </div>

    <div
      id='bt_container'
      className={`bg-indigo-50 border border-indigo-200 p-7 ${paymentMethod === 'banktranser' ? '' : 'hidden'
        }`}
    >
      <div className='flex flex-nowrap'>
        <div className='item w-3/6 pr-2'>
          <p className='text-sm mb-2'>Transfer Number</p>
          <input
            type='text'
            className='w-full p-3 text-xs focus:outline-none'
            value={transInfo.transactionId}
            name='transactionId'
            onChange={onInfoChange}
          />
        </div>
        <div className='item w-3/6'>
          <p className='text-sm mb-2'>Transfer Date</p>
          <input
            type='date'
            className='w-full p-3 text-xs focus:outline-none'
            onChange={(date) => onDateChange(date, 'transactionDate')}
            autocomplete={+new Date()}
            value={transInfo.transactionDate}
          />
        </div>
      </div>
    </div>

    <div
      id='cheque_container'
      className={`bg-indigo-50 border border-indigo-200 p-7 ${paymentMethod === 'cheque' ? '' : 'hidden'
        }`}
    >
      <div className='flex flex-wrap'>
        <div className='item w-3/6 pr-2 mb-4'>
          <p className='text-sm mb-2'>Account Holder Name</p>
          <input
            type='text'
            className='w-full p-3 text-xs focus:outline-none'
            value={transInfo.accountTitle}
            name='accountTitle'
            onChange={onInfoChange}
          />
        </div>
        <div className='item w-3/6 mb-4'>
          <p className='text-sm mb-2'>Cheque Number</p>
          <input
            type='text'
            className='w-full p-3 text-xs focus:outline-none'
            value={transInfo.transactionId}
            name='transactionId'
            onChange={onInfoChange}
          />
        </div>
        <div className='item w-3/6 pr-2 mb-4'>
          <p className='text-sm mb-2'>Issuing Bank</p>
          <input
            type='text'
            className='w-full p-3 text-xs focus:outline-none'
            value={transInfo.bankName}
            name='bankName'
            onChange={onInfoChange}
          />
        </div>
        <div className='item w-3/6'>
          <p className='text-sm mb-2'>Check Date</p>
          <input
            type='date'
            className='w-full p-3 text-xs focus:outline-none'
            onChange={(e) => onDateChange(e, 'checkDate')}
            autocomplete={+new Date()}
            value={transInfo.checkDate}
          />
        </div>
        <div className='item w-3/6 pr-2 mb-4'>
          <p className='text-sm mb-2'>IBAN Number</p>
          <input
            type='text'
            className='w-full p-3 text-xs focus:outline-none'
            value={transInfo.iban}
            name='iban'
            onChange={onInfoChange}
          />
        </div>
        <div className='item w-3/6 mb-4'>
          <p className='text-sm mb-2'>Account Number</p>
          <input
            type='text'
            className='w-full p-3 text-xs focus:outline-none'
            value={transInfo.accountNumber}
            name='accountNumber'
            onChange={onInfoChange}
          />
        </div>
      </div>
    </div>

    <div className='block text-center mt-10 mb-20'>
      <button className='btn_cancel rounded-md border border-brand-blue text-brand-blue p-2 px-4 mr-2' onClick={() => history.push(`/admin/billing`)}>
        Cancel
      </button>
      <button
        className={`btn_next rounded-md border border-brand-blue text-white p-2 px-6 ${paymentMethod === '' ? "bg-gray-400" : "bg-brand-blue"}`}
        onClick={handleNext}
        disabled={paymentMethod === ''}
      >
        Next
      </button>
    </div>
  </div>
);

export default CheckoutPaymentMethods;
