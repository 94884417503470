import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import AppMainNavigation from './Navigation/AppMainNavigation';
import Events from '../../admin/components/Events';
import EventsCategories from "../../admin/components/Events/EventsCategories"
import EventsTriggers from '../../admin/components/Events/EventsTriggers';

const EventUser = (props) => {
    const ROUTES = [
        { path: '/event/events', component: Events },
        { path: '/event/categories', component: EventsCategories },
        { path: '/event/triggers', component: EventsTriggers },
    ];

    return (
        <Router>
            {console.log("asd asd as d")}
            <AppMainNavigation />
            <div className="p-8">
                <Switch>
                    {ROUTES.map((route, i) => (
                        <Route
                            key={`corporation-route-${i}`}
                            path={route.path}
                            render={(routerProps) =>
                                React.createElement(route.component, {
                                    ...props,
                                    ...routerProps,
                                })
                            }
                            exact
                        />
                    ))}
                </Switch>
            </div>
        </Router>
    );
};

export default EventUser;
