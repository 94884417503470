import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Grid, Button } from '@material-ui/core';
import '../../../clinician/clinician.scss';
import userIcon from '../../../assets/imgs/download.png';
import Layout from '../common/AdminLayout';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { baseURL } from '../../../utils/axios';
import Loader from '../../../components/Loader/Loader';
import Pagination from '@material-ui/lab/Pagination';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Link } from 'react-router-dom';
import useStyles from './Staff.styles';

export default function StaffMembers() {
  const classes = useStyles();
  const history = useHistory();
  const [isLoaded, setIsloaded] = useState(false);
  const [staffLists, setStaffLists] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [filterByRole, setFilterByRole] = useState('');
  const [roleList, setRoleList] = useState([]);
  const [searchText, setSearchTxt] = useState('');
  const [sortType, setSortType] = useState('desc');

  useEffect(() => {
    setIsloaded(true);
    getStaffLists('', '', '');
    getRoles();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [searchText]);

  const getStaffLists = (role, sortBy, sortType) => {
    let url;
    if (role) {
      url = `${baseURL}/api/user/getAll?page=${page}&roleId=${role}&term=${searchText}&orderBy=${sortBy}&sortType=${sortType}`;
    } else {
      url = `${baseURL}/api/user/getAll?page=${page}&term=${searchText}&orderBy=${sortBy}&sortType=${sortType}`;
    }
    Axios.get(url, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          setStaffLists(res.data.data);
          setTotalCount(res.data.count);
        }
        // if (res.data.data && res.data.data.length > 0) {
        // }
        // setTotalCount(res.data.count);
        setIsloaded(false);
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const getRoles = () => {
    Axios.get(`${baseURL}/admin/role`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res && res.data.length) {
          let roles = res.data.map((item) => ({
            name: item.role,
            id: item._id,
          }));
          setRoleList(roles);
        }
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const getDate = (date) => {
    const event = new Date(date);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return event.toLocaleDateString('en-US', options);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    setIsloaded(true);
  };

  const handleFilterByRole = (event) => {
    setIsloaded(true);
    setPage(1);
    setFilterByRole(event.target.value);
    getStaffLists(event.target.value, '', '');
  };

  function handleSearch(event) {
    setPage(1);
    getStaffLists(filterByRole, '', '');
    setIsloaded(true);
  }

  useEffect(() => {
    // action on update of movies
    getStaffLists(filterByRole, '', '');
  }, [page]);

  const sort = (key) => {
    const toggleType = sortType === 'asc' ? 'desc' : 'asc';
    setSortType(toggleType);
    getStaffLists(filterByRole, key, toggleType);
  };

  return (
    <>
      <Grid container>
        <Grid xs={6}>
          <OutlinedInput
            className={classes.inputstyle}
            style={{ float: 'left' }}
            placeholder='Search'
            id='outlined-adornment-password'
            onChange={(evt) => {
              setSearchTxt(evt.target.value);
            }}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton aria-label='toggle password visibility'>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          <FormControl variant='outlined' className={classes.formControl}>
            <InputLabel id='demo-simple-select-outlined-label'>
              Fliter By Role
            </InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              value={filterByRole}
              onChange={handleFilterByRole}
            >
              <MenuItem value={''}>All</MenuItem>
              {roleList.length > 0 &&
                roleList.map((item, i) => (
                  <MenuItem key={`lh-${i}`} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={6}>
          <div style={{ float: 'right' }}>
            {/* <Link to="/admin/staff/add-staff"> */}
            <Button
              variant='contained'
              onClick={(evt) => {
                history.push('/admin/staff/add-staff');
              }}
              className={classes.btnNewApp}
              style={{ height: '49px' }}
            >
              <AddCircleOutlineIcon style={{ marginRight: 5 }} />
              Create New Staff
            </Button>
            {/* </Link> */}
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <TableContainer>
          <Table className={classes.table} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>ID Number</TableCell>
                <TableCell>User Avatar</TableCell>
                <TableCell>
                  <Button
                    onClick={() => sort('name')}
                    style={{ textTransform: 'none' }}
                  >
                    Name
                  </Button>
                </TableCell>
                <TableCell>Email ID</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>
                  <Button
                    onClick={() => sort('date')}
                    style={{ textTransform: 'none' }}
                  >
                    Date Created
                  </Button>
                </TableCell>
                {/* <TableCell>Status</TableCell> */}
                <TableCell align='center'>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody component={Paper}>
              {isLoaded && (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                    <Loader />
                  </TableCell>
                </TableRow>
              )}
              {!isLoaded &&
                staffLists.length > 0 &&
                staffLists.map((val, i) => (
                  <TableRow key={1}>
                    <TableCell component='th' scope='row'>
                      {val._id}
                    </TableCell>
                    <TableCell>
                      <img
                        src={val.avatar ? val.avatar : userIcon}
                        style={{ borderRadius: '50%' }}
                        width='50px'
                        height='50px'
                      />
                    </TableCell>
                    <TableCell>{`${val.prefix ? val.prefix : ''} ${
                      val.firstName
                    } ${val.lastName} ${
                      val.suffix ? ', ' + val.suffix : ''
                    } `}</TableCell>
                    <TableCell>{val.email ? val.email : '-'}</TableCell>
                    <TableCell>
                      {val.phoneNumber ? val.phoneNumber : '-'}
                    </TableCell>
                    <TableCell>
                      {val.createdAt ? getDate(val.createdAt) : '-'}
                    </TableCell>
                    <TableCell align='right'>
                      {/* <Button variant="contained" className={classes.viewBtn}>
                                            Edit
                                        </Button> */}
                      <Link to={`/admin/staff/edit-staff/${val._id}`}>
                        <Button variant='contained' className={classes.viewBtn}>
                          Edit
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              {!isLoaded
                ? staffLists.length === 0 && (
                    <TableRow key={1}>
                      <TableCell
                        component='th'
                        style={{ textAlign: 'center' }}
                        colSpan={7}
                        scope='row'
                      >
                        No Data Found
                      </TableCell>
                    </TableRow>
                  )
                : null}
            </TableBody>
          </Table>
          {!isLoaded && staffLists && staffLists.length > 0 && (
            <div className={classes.paginationRoot}>
              <Pagination
                count={Math.ceil(totalCount / 10)}
                page={page}
                onChange={handlePageChange}
                color='primary'
                shape='rounded'
              />
            </div>
          )}
        </TableContainer>
      </Grid>
    </>
  );
}
