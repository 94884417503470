/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import styles from "./CustomButtons.styles";



export const CustomNextButtons = ({
  onPressButton,
  width,
  title,
  style,
  textStyles,
  ...props
}) => {
  const classes = styles();
  return (
    <Grid
      style={{ width }}
      className={[classes.nextButton, style]}
      onClick={() => onPressButton()}
      {...props}
    >
      <Typography className={[classes.buttonText, textStyles]}>
        {title}
      </Typography>
    </Grid>
  );
};

export const CustomCancelButtons = ({
  onPressButton,
  width,
  title,
  style,
  textStyles,
  ...props
}) => {
  const classes = styles();
  return (
    <Grid
      style={{ width }}
      className={[classes.cancelbutton, style]}
      onClick={() => onPressButton()}
      {...props}

      // component="div"
      // alignItems="center"
      // justify="center"
      // style={{ display: "flex" }}
      // onClick={() => onPressButton()}
      // {...props}
    >
      {/* <Grid style={{ width }} className={[classes.nextButton, style]} /> */}
      <Typography className={[classes.cancelButtonText, textStyles]}>
        {title}
      </Typography>
    </Grid>
  );
};

export const CustomAdultButtons = ({
  onPressButton,
  width,
  title,
  style,
  textStyles,
  ...props
}) => {
  const classes = styles();
  return (
    <Grid
      style={{ width }}
      className={[classes.adultbutton, style]}
      onClick={() => onPressButton()}
      {...props}
    >
      <Typography className={[classes.adulttext, textStyles]}>
        {title}
      </Typography>
    </Grid>
  );
};

CustomNextButtons.defaultProps = {
  onPressButton: () => { },
  width: "100px",
  title: "Click",
};

CustomCancelButtons.defaultProps = {
  onPressButton: () => { },
  width: "100px",
  title: "Click",
};

CustomAdultButtons.defaultProps = {
  onPressButton: () => { },
  width: "100px",
  title: "Click",
}

export default { CustomNextButtons };
