/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import "../assets/css/loader.css";

export const Loader = () => <div className="loader" />;
export const ButtonLoader = () => (
  <div
    className="loader"
    style={{ borderColor: "#ffffff", height: "20px", width: "20px" }}
  />
);

export default Loader;
