import { createStyles} from "@material-ui/core";
export default (theme) =>
createStyles({
  root: {
    height: "100px",
    width: "100%",
    backgroundColor: "#ffffff",
    position: "fixed",
    top: 0,
  },
  image: {
    position: "absolute",
    top: "15px",
    height: "70px",
    [theme.breakpoints.down("xs")]: {
      width: "200px",
      left: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "200px",
      left: "10px",
    },
    [theme.breakpoints.up("md")]: {
      width: "250px",
      left: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "350px",
      left: "50px",
    },
  },
  faqContainer: {
    top: "35px",
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      left: "250px",
      top: "26px",
      display: "inline",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      position: "absolute",
      top: "35px",
      left: "280px",
    },
    [theme.breakpoints.up("lg")]: {
      position: "absolute",
      left: "500px",
    },
  },
  faq: {
    fontSize: "16px",
    color: "#003265",
    fontFamily: "Lato",
    fontWeight: 700,
    [theme.breakpoints.up("sm")]: {
      marginLeft: "5px",
      marginRight: "5px",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
      marginRight: "10px",
    },
  },
  faqDivider: {
    borderBottom: "1px solid #222222",
    opacity: "30%",
    marginTop: "3px",
    [theme.breakpoints.up("sm")]: {
      display: "static",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  divider: {
    borderRight: "1px solid #707070",
  },
  alertContainer: {
    display: "flex",
    cursor: "pointer",
    position: "absolute",
    [theme.breakpoints.up("xs")]: {
      right: "85px",
      marginTop: "5px",
    },
    [theme.breakpoints.up("md")]: {
      right: "350px",
    },
  },
  alert: {
    width: "18px",
    height: "21px",
    position: "absolute",
    top: "35px",
  },
  alertBadge: {
    position: "absolute",
    top: "37px",
    left: "12px",
  },
  profileContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    position: "absolute",
    right: "12px",
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  mobileProfileContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    position: "absolute",
    right: "12px",
    [theme.breakpoints.up("xs")]: {
      display: "flex",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  dropDownIcon: {
    marginTop: "20px",
    marginRight: "20px",
    cursor: "pointer",
  },
  avatar: {
    marginTop: "19px",
    marginRight: "9px",
    width: "50px",
    height: "50px",
    borderRadius: "25px",
    border: "3px solid #ffffff",
    filter: "drop-shadow(0 0 6px rgba(207, 217, 223, 0.5))",
  },
  avatarMobile: {
    marginTop: "19px",
    marginRight: "9px",
    width: "50px",
    height: "50px",
    borderRadius: "25px",
    border: "3px solid #ffffff",
    filter: "drop-shadow(0 0 6px rgba(207, 217, 223, 0.5))",
    [theme.breakpoints.up("xs")]: {
      marginRight: "2px",
      marginTop: "30px",
      width: "35px",
      height: "35px",
    },
  },
  nameContainer: {
    marginRight: "10px",
  },
  nameHeader: {
    marginTop: "25px",
    fontSize: "20px",
    color: "#304659",
    fontFamily: "Lato",
    fontWeight: 700,
    textAlign: "right",
    width: "165px",
    textOverflow: "ellipsis",
    // display: 'inline',
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  subNameTitle: {
    marginTop: "3px",
    fontSize: "12px",
    color: "#304659",
    fontFamily: "Lato",
    fontWeight: 400,
    textAlign: "right",
  },
  popupContainer: {
    filter: "drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16))",
    zIndex: 1000,
  },
  popupListText: {
    fontSize: "17px",
    color: "#304659",
    fontWeight: 500,
    fontFamily: "Lato",
    lineHeight: "30px",
  },
  popupIcon: {
    height: "16px",
    width: "16px",
    marginRight: "5px",
    color: "#304659",
  },
  drop: {
    width: "340px",
    height: "426px",
    borderRadius: "14px",
    // filter: 'drop - shadow(-2.828px 2.828px 3.5px rgba(0, 0, 0, 0.16))',
    backgroundColor: "#003265",
    borderWidth: "0 10px 10px 10px",
  },
  dropTitle: {
    fontSize: "18px",
    color: "#ffffff",
    fontWeight: 700,
    fontFamily: "Lato",
    marginTop: "10px",
    textAlign: "left",
  },
  dropDesc: {
    fontSize: "12px",
    color: "#ffffff",
    opacity: "49%",
    fontWeight: 400,
    fontFamily: "Lato",
    textAlign: "left",
  },
  dropSubContainer: {
    display: "inline",
    height: "60px",
    width: "300px",
    borderRadius: "10px",
    backgroundColor: "#003265",
    // border: "1px solid #707070",
    paddingLeft: "21px",
    color: "#304659",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,20%)",
      color: "#ffffff",
    },
  },
});