import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import moment from 'moment';

const getDate = (date) => {
  var today = new Date(date);
  var d = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
  var m =
    today.getMonth() + 1 < 10
      ? '0' + (today.getMonth() + 1)
      : today.getMonth() + 1;
  const modDate = today.getFullYear() + '-' + m + '-' + d;
  return modDate;
};

const getNextAvailibility = (schedules) => {
  let result = '';
  if (schedules.length) {
    let day = new Date();
    day.setDate(day.getDate() + 1);
    let today = getDate(new Date());
    let nextDay = getDate(day);
    if (schedules[0].date == today) {
      result = 'Today';
    } else if (schedules[0].date == nextDay) {
      result = 'Tomorrow';
    } else {
      result = schedules[0].date;
    }
  } else {
    result = 'Not Available';
  }
  return result;
};

const formatClinician = (clinician) => {
  let nextAvailable = null;
  let schedules = clinician.schedule;

  schedules = schedules.map((sch) => {
    if (sch.slots.length) {
      sch.slots = sch.slots.map((s) => {
        let startTime = moment(new Date(s.startTime));
        let slot = startTime.hours() + ':' + startTime.minutes();
        let timeArr = slot.split(':');
        let hrs = '00',
          min = '00';
        if (timeArr.length) {
          hrs = timeArr[0];
          min = timeArr[1];
        }
        if (timeArr[0].length === 1) {
          hrs = `0${timeArr[0]}`;
        }
        if (timeArr[1].length === 1) {
          min = `0${timeArr[1]}`;
        }
        let time = `${hrs}:${min}`;
        let lclDate = new Date(`${sch.date}T${time}:00`);
        let lclHrs = lclDate.getHours().toString();
        if (lclHrs.length === 1) {
          lclHrs = `0${lclHrs}`;
        }
        return `${lclHrs}:${
          lclDate.getMinutes() > 0 ? lclDate.getMinutes() : '00'
        }`;
      });
    }
    return sch;
  });

  for (let sch of schedules) {
    if (sch.slots.length && sch.slots.length > 0) {
      nextAvailable = sch;
      break;
    }
  }

  return {
    id: clinician._id,
    name: `${clinician.firstName} ${clinician.lastName}`,
    study: '',
    speciality: clinician.bio,
    times: schedules,
    nextAvailable,
    img: clinician.avatar,
    cost: `${clinician.costPerUnit} Dhs / ${clinician.appointmentSlotUnit}min`,
    appointmenSlot: clinician.appointmentSlotUnit,
    amount: clinician.costPerUnit,
    selectTimeSlot: null,
    isAlternative: false,
    nextAvailiblityText: getNextAvailibility(clinician.schedule),
  };
};

const SlotGrid = styled(Grid)`
  width: 80px;
  height: 36px;
  background-color: #ffffff;
  border: 1px solid #304659;
  font-size: 16px;
  color: #304659;
  font-weight: 400;
  font-family: Lato;
  cursor: pointer;
  margin: 4px;
  &:hover {
    background-color: #304659;
    color: #ffffff;
  }
`;

const DialogContent = styled(Grid)`
  padding: 40px;
`;

const DialogHeader = styled(Grid)`
  border-bottom: 2px solid #ccc;
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

const Name = styled(Typography)`
  font-size: 16px;
  font-weight: bold;
`;

const AlternateSlotsGrid = styled(Grid)`
  margin-top: 20px;
`;
export {
  formatClinician,
  SlotGrid,
  DialogContent,
  DialogHeader,
  Name,
  AlternateSlotsGrid,
};
