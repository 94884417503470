import React from 'react';

const Emirati = ({ data }) => {
  return (
    <table id='emirati-patients'>
      <tr>
        <td style={{ width: '200px', backgroundColor: 'yellow' }}>
          Source Name:
        </td>
        <td>THE LIGHTHOUSE ARABIA.</td>
      </tr>

      <tr>
        <td>Report Name:</td>
        <td colSpan='6'>
          02 A - Out- Patients New Visits by Nationality, Sex, Age Groups &
          Diagnosis
        </td>
      </tr>

      <tr />

      <tr>
        <td>Data Write:</td>
        <td>Append to Previous Data</td>
      </tr>

      <tr>
        <td>Download Date:</td>
        <td>19/04/2021 13:16</td>
      </tr>

      <tr>
        <td>SR Id:</td>
        <td>99931</td>
      </tr>

      <tr>
        <td>Data Period:</td>
        <td> 2021/3</td>
      </tr>

      <tr>
        <td>Report Period: </td>
        <td>Monthly </td>
      </tr>

      <tr>
        <td>UAE-Expat 571:</td>
        <td>Emirati</td>
      </tr>
      <tr></tr>
      <tr></tr>
      <tr>
        <td>
          <table>
            <tr>
              <td>Type of visit *</td>
              <td>Proof of identity (Document type) *</td>
              <td>Document reference number *</td>
              <td>Contact number</td>
              <td>Email</td>
              <td>DOB *</td>
              <td>Gender *</td>
              <td>Continent / Region *</td>
              <td>Nationality *</td>
              <td>Country of permanent residence *</td>
              <td>Address of stay in the UAE *</td>
              <td>Date of admission *</td>
              <td>Date of discharge *</td>
              <td>Specialty *</td>
              <td>Procedure (CPT code)</td>
              <td>CPT Code 2</td>
              <td>CPT Code 3</td>
              <td>CPT Code 4</td>
              <td>CPT Code 5</td>
              <td>CPT Code 6</td>
              <td>CPT Code 7</td>
              <td>CPT Code 8</td>
              <td>Primary diagnosis (ICD code) *</td>
              <td>ICD code 2</td>
              <td>ICD code 3</td>
              <td>ICD code 4</td>
              <td>ICD code 5</td>
              <td>ICD code 6</td>
              <td>ICD code 7</td>
              <td>ICD code 8</td>
              <td> Total medical spend in AED </td>
            </tr>
            {data &&
              data.map((d) => (
                <tr>
                  <td>{d.location}</td>
                  <td>{d.uae_id}</td>
                  <td>{d.docRefNum}</td>
                  <td>{d.mobile}</td>
                  <td>{d.email}</td>
                  <td>{d.dob}</td>
                  <td>{d.gender}</td>
                  <td>{d.region}</td>
                  <td>{d.nationality}</td>
                  <td>{d.countryOfResidence}</td>
                  <td>{d.addressInUAE}</td>
                  <td>{d.admissionDate}</td>
                  <td>{d.dischargeDate}</td>
                  <td>{d.speciality}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{d.icd1}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{d.amount}</td>
                </tr>
              ))}
          </table>
        </td>
      </tr>
    </table>
  );
};

export default Emirati;
