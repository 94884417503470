/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import * as React from "react";
import { Grid } from "@material-ui/core";
import styles from "./BodyContainer_style";



export const GridContainer = (props) => {
  const classes = styles();
  return (
    <Grid
      // container
      //   alignItems="center"
      justify="center"
      className={classes.root}
    >
      {props.children}
      {/* <Grid item></Grid> */}
    </Grid>
  );
};

export const GridItem = ({ ...props }) => {
  const classes = styles();
  return (
    <Grid
      // container
      //   alignItems="center"
      justify="center"
      className={classes.item}
      {...props}
    >
      {props.children}
      {/* <Grid item></Grid> */}
    </Grid>
  );
};

GridContainer.defaultProps = {};
