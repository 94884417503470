import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Axios from 'axios';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';
import Loader from '../components/Loader/Loader';
import AlertMsg from '../utils/Alert';
import { Drawer, Grid, Typography, Button } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import moment from 'moment';
import SliderPointLine from '../assets/imgs/slider verticle line.svg';
import SliderPointIcon from '../assets/imgs/slider point.svg';
import SidebarMenu from './SidebarMenuClinician';
import { baseURL } from '../utils/axios';
import FilterModalBox from './FilterModalBox';
import FilterDrawer from './FilterDrawer';
import SidebarClinician from './SidebarClinician';
import './clinician.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import PatientDetailClinician from './Patients/patientDetail';
import DiagnosisSearchView from '../common/DiagnosisSearchView';
import { connect } from 'react-redux';
import { getByIdClinicianAction } from '../utils/actions/clinician.action';
import { Dialog, Button as ConfirmBtn } from '../components/atoms';
import userIcon from '../assets/imgs/download.png';
import { calculateAge } from '../utils/timUtils';
import styled from 'styled-components';
import ReactQuill from 'react-quill';

const Capitalize = styled(Typography)`
  text-transform: capitalize;
`;

const useStyles = makeStyles({
  toolbar: {
    width: '100%',
    height: '100px',
    backgroundColor: '#003265',
  },
  prescriptionCard: {
    backgroundColor: '#F9F8F5',
    boxShadow: 'none',
  },
  customBtn: {
    backgroundColor: '#8c9cf7',
    color: '#fff',
    borderRadius: '25px',
    width: '75%',
    padding: '10px',
    textTransform: 'capitalize',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  customBtn2: {
    backgroundColor: '#003265',
    color: '#fff',
    borderRadius: '25px',
    width: '25%',
    padding: '10px',
    textTransform: 'capitalize',
    marginTop: '24px',
  },
  root: {
    maxWidth: 255,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  inputstyle: {
    backgroundColor: '#fff',
  },
  table: {
    minWidth: 650,
  },
  imgTable: {
    width: '40px',
    height: '40px',
    objectFit: 'contain',
    borderRadius: '50px',
  },
  viewBtn: {
    borderRadius: '15px',
    width: '100px',
    padding: '4px',
    boxShadow: 'none',
    textTransform: 'capitalize',
    // '&:hover': {
    //   backgroundColor: '#003265',
    // },
  },
  btnViewPatientRecord: {
    backgroundColor: '#003265',
    color: '#fff',
    padding: '10px',
    marginTop: '15px',
    marginBottom: 15,
    fontWeight: 400,
    margin: '0 auto',
    textTransform: 'capitalize',
    width: '90%',
    '&:hover': {
      backgroundColor: '#003265',
    },
  },
  sliderContainer: {
    height: '15px',
    backgroundImage:
      'linear-gradient(90deg, #26a4ba 0%, #85c169 25%, #cbcb2f 50%, #d0aa21 75%, #da7910 100%)',
  },
  sliderPointContainer2: {
    position: 'absolute',
    marginTop: '-18px',
    marginLeft: '260px',
  },
  sliderPointText: {
    fontSize: '12px',
    color: '#ffffff',
    fontFamily: 'Lato',
    fontWeight: 700,
    textAlign: 'center',
    marginTop: '-27px',
    marginLeft: '0px',
  },
  sliderPointLine: {
    height: '16px',
    marginLeft: '1px',
  },
  sliderPoint: {
    width: '18px',
    marginLeft: '1px',
  },
  customSelect: {
    width: '100%',
    textAlign: 'left',
    border: '1px solid #c4c4c4',
    padding: '10px',
    borderRadius: '3px',
    backgroundColor: '#fff',
  },
  btnNewApp: {
    backgroundColor: '#003265',
    color: '#fff',
    marginLeft: '50px',
    '&:hover': {
      backgroundColor: '#003265',
    },
  },
  clinicians: {
    borderStyle: 'solid',
    borderColor: '#E4E8E3',
    width: '100%',
    background: '#fff',
  },
  title: {
    color: '#003265',
    marginLeft: '10px',
    fontSize: '17px',
    fontWeight: 500,
    marginTop: '15px',
    marginBottom: '15px',
    textAlign: 'left',
  },
  labelText: {
    color: '#003265',
    fontSize: '15px',
    fontWeight: 500,
    textAlign: 'left',
    marginLeft: '15px',
  },
  formControl: {
    width: '30%',
    marginTop: 10,
  },
  input: {
    width: '30%',
    marginLeft: '15px',
    marginTop: '10px',
  },
  img3: {
    width: '60px',
    height: '60px',
    float: 'left',
    marginLeft: '25px',
    marginRight: '25px',
    marginTop: '30px',
    borderRadius: '50%',
  },
  txt3: {
    textAlign: 'left',
    marginTop: '30px',
  },
  pname: {
    color: '#000',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  page: {
    fontSize: '15px',
    color: '#9da19c',
  },
  btn3: {
    backgroundColor: '#ab9267',
    color: '#fff',
    marginBottom: '20px',
    marginTop: '30px',
    textAlign: 'center',
    width: '100%',
    '&:hover': {
      backgroundColor: '#ab9267',
    },
  },
});
const names = ['Medicine Name', 'Medicine'];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const AppointmentUserSession = (props) => {
  const classes = useStyles();
  const [appointmentId, setAppointmentId] = React.useState('');
  const [notes, setNotes] = React.useState('');
  const [clientId, setClientId] = useState('');
  const [appointmentForm, setAppointmentForm] = useState([]);

  //   const [seconds, setSeconds] = useState('');
  const [duration, setDuration] = useState(0);
  const [appointment, setAppointment] = useState('');
  const [assessment, setAssessment] = useState('');
  const [medicines, setMedicines] = useState([]);
  const [selectedMedicines, setSelectedMedicines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const [patientDrawer, setPatientDrawer] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [seconds, setSeconds] = useState(60);
  const [min, setMin] = useState(0);
  const [time, setTime] = useState(new Date().toLocaleTimeString());
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [initialFormData, setInitialFormData] = useState([]);
  const [openConfirmation, setConfirmation] = useState(false);
  const [completionCurrVal, setCompletionCurrVal] = useState(null);
  const [completionOrigVal, setCompletionOriginalVal] = useState('');
  const theme = useTheme();

  useEffect(() => {
    const clinicianId = localStorage.getItem('clinicianUID');
    if (clinicianId) {
      props.getByIdClinicianAction(clinicianId);
    }
  }, []);

  useEffect(() => {
    if (props.user) {
      setLoggedInUser(props.user);
    }
  }, [props.user]);
  const togglePatientDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setPatientDrawer(open);
  };

  const getMedicines = () => {
    setLoading(true);
    Axios.get(`${baseURL}/api/client/medication`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        setLoading(false);
        setMedicines(res.data);
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  useEffect(() => {
    setInitialFormData(props.initialFormData);

    getMedicines();
  }, [props.initialFormData]);

  const getAge = (dob) => {
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  function handleMedicine(event, value, id) {
    var tempData = initialFormData;

    tempData.forEach((val, i) => {
      if (val.clientId == id) {
        var temp = [];
        var medication = [];

        value &&
          value.map((x, i) => {
            var data = {
              appointmentId: val.appointmentId,
              medicationId: x._id,
              clientId: val.clientId,
              medication: x.packName,
              refillRequest: false,
            };

            const oldPres = val.prescription.filter(
              (f) => f.medicationId === x._id
            );

            if (oldPres.length > 0) {
              temp.push(oldPres[0]);
            } else {
              temp.push(data);
            }
          });

        const t = {
          ...val,
          prescription: temp,
        };
        tempData[i] = t;
      }
    });

    setInitialFormData(tempData);
    props.updateInitialFormData(tempData);
    forceUpdate();
  }

  function handlePrescription(evt, index, id) {
    var tempData = initialFormData;
    let { name, value } = evt.target;
    value =
      name === 'refillRequest' ? (value === 'false' ? false : true) : value;
    tempData = tempData.map((val, i) => {
      if (val.clientId === id) {
        val.prescription[index][name] = value;
        return val;
      }

      return val;
    });

    setInitialFormData(tempData);
    props.updateInitialFormData(tempData);
    forceUpdate();
  }

  const handleInputChange = (name, value, id) => {
    var tempData = initialFormData;
    tempData.map((val, i) => {
      if (val.clientId == id) {
        const t = {
          ...val,
          [name]: value,
        };
        tempData[i] = t;
      }
    });
    setInitialFormData(tempData);
    props.updateInitialFormData(tempData);
    forceUpdate();
  };

  const updateCompletion = (currVal, oCompVal, clientId) => {
    if (oCompVal === 'yes') {
      AlertMsg('error', 'Session is already marked as complete');
      return;
    }

    handleInputChange('sessionCompleted', currVal, clientId);
  };

  const changeCost = (e, oCompVal, clientId) => {
    if (oCompVal === 'yes') {
      AlertMsg(
        'error',
        'Cost can not be changed because Session is already marked as complete'
      );
      return;
    }

    handleInputChange('cost', e.target.value, clientId);
  };

  const closeCompleteConfirmation = () => {
    setConfirmation(false);

    handleInputChange('sessionCompleted', 'no', clientId);
  };

  const markComplete = () => {
    setConfirmation(false);
    setCompletionCurrVal(null);
    updateCompletion(completionCurrVal, completionOrigVal, clientId);
  };

  const AppointmentInput = (val, i) => {
    console.log(val, i, initialFormData)
    return (
      <Grid
        xs={initialFormData.length == 1 ? 12 : 6}
        style={{ padding: '12px' }}
      >
        {val.client && (
          <Grid
            container
            key={`${val._id}`}
            style={{
              marginTop: '20px',
              marginBottom: '20px',
              background: 'rgba(156, 134, 87, 0.2)',
            }}
          >
            <Grid xs={12}>
              <Avatar
                className={classes.img3}
                src={val.client.avatar || userIcon}
              />

              <Grid className={classes.txt3}>
                <Typography className={classes.pname}>
                  {`${val.client.firstName} ${val.client.lastName}`}
                </Typography>
                <Capitalize className={classes.page}>
                  {val.client.gender ? val.client.gender : 'age'}:{' '}
                  {calculateAge(new Date(val.client.dob))}
                </Capitalize>
              </Grid>
            </Grid>

            <Grid container style={{ marginLeft: '15px', marginRight: '15px' }}>
              <Grid xs={12}>
                <Button
                  onClick={(evt) => {
                    setPatientId(val.clientId);
                    setPatientDrawer(true);
                  }}
                  className={classes.btn3}
                >
                  View Patient Record
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}

        <div style={{ marginBottom: '24px' }}>
          <Typography style={{ color: '#003265', textAlign: 'left' }}>
            Notes
          </Typography>
          <ReactQuill
            name="editor"
            id="editor"
            className="mt-2 bg-white"
            bounds={'.app'}
            value={initialFormData[i] && initialFormData[i]?.notes
              ? initialFormData[i]?.notes
              : ''}
            onChange={(value) => {
              handleInputChange('notes', value, val.clientId);
              setClientId(val.clientId);
            }}
          />
        </div>


        <div style={{ marginBottom: '24px' }}>
          <Typography style={{ color: '#003265', textAlign: 'left' }}>
            Diagnosis
          </Typography>
          <DiagnosisSearchView
            onChange={(data) => {
              initialFormData[i].diagnosis = data;
              props.updateInitialFormData(initialFormData);
            }}
            selectedDiagnosis={val.diagnosis}
          />
        </div>
        {/* <div style={{ marginBottom: '24px' }}>
    <Typography style={{ color: '#003265', textAlign: 'left' }}>Assessment(s)</Typography>
    <Card className={classes.rootCard}>
        <Grid container style={{ width: '90%', margin: '12px auto' }}>
        <Grid xs={6}>
            <Typography style={{ color: '#003265', textAlign: 'left' }}>Take assessment?</Typography>
        </Grid>
        <Grid xs={6}>
            <NativeSelect
            className={classes.customSelect}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            placeholder="Select"
            value={assessment}
            onChange={(e) => setAssessment(e.target.value)}
            >
            <option value="yes">Yes</option>
            <option value="no">No</option>
            </NativeSelect>
        </Grid>
        </Grid>
        <Grid container style={{ width: '90%', margin: '12px auto' }}>
        <Grid xs={6}>
            <Typography style={{ color: '#003265', textAlign: 'left' }}>Type?</Typography>
        </Grid>
        <Grid xs={6}>
            <NativeSelect
                className={classes.customSelect}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                placeholder="Select"
                >
                <option value="">Select</option>
            </NativeSelect>
        </Grid>
        </Grid>
        <Button className={classes.btnViewPatientRecord}>
        Start Assessment
        </Button>
    </Card>
    </div> */}
        {loggedInUser && ['PSY', 'CONSPSY'].includes(loggedInUser.discipline) && (
          <div style={{ marginBottom: '24px' }}>
            <Typography style={{ color: '#003265', textAlign: 'left' }}>
              Prescription(s)
            </Typography>
            <Card className={classes.rootCard}>
              <Grid container style={{ margin: '12px auto' }}>
                <Autocomplete
                  multiple
                  style={{ display: 'block', width: '100%' }}
                  id='tags-standard'
                  options={medicines}
                  onChange={(event, value) =>
                    handleMedicine(event, value, val.clientId)
                  }
                  getOptionLabel={(option) =>
                    `${option.packName} - ${option.manufacturer} (${option.activeIngredient}), ${option.strength}  ${option.strengthUnit}, ${option.quantityBase}`
                  }
                  getOptionSelected={(option, value) =>
                    option._id === value._id
                  }
                  // getOptionSelected={ e => {console.log(e)} }
                  defaultValue={val.medication}
                  //value={val.medication}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='standard'
                      label='Medicines'
                      placeholder='Medicines'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color='inherit' size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                {val.prescription &&
                  val.prescription.map((y, j) => {
                    // xs={val.prescription.length == 1 ? 12 : 6}
                    return (
                      <Grid style={{ width: '100%' }} key={y.medicationId}>
                        <Typography
                          style={{
                            color: '#003265',
                            textAlign: 'left',
                            fontSize: '13px',
                            marginTop: '15px',
                          }}
                        >
                          Perception #{j + 1} ({y.medication})
                        </Typography>
                        <Card
                          className={classes.prescriptionCard}
                          style={{
                            padding: '15px',
                            display: 'block',
                            width: '100%',
                          }}
                        >
                          <Typography
                            style={{
                              color: '#003265',
                              textAlign: 'left',
                              marginTop: '0px',
                              fontSize: '13px',
                            }}
                          >
                            Dosage & Directions
                          </Typography>
                          <TextareaAutosize
                            style={{
                              width: '100%',
                              display: 'block',
                              marginBottom: '15px',
                              border: '1px solid #C4C4C4',
                              marginTop: '8px',
                            }}
                            aria-label='minimum height'
                            value={y.direction ? y.direction : ''}
                            name='direction'
                            onChange={(e) =>
                              handlePrescription(e, j, val.clientId)
                            }
                            rowsMin={3}
                            placeholder='Enter dosage directions...'
                          />
                          <Grid container>
                            <Grid xs={4} style={{ paddingRight: '10px' }}>
                              <Typography
                                style={{
                                  color: '#003265',
                                  textAlign: 'left',
                                  marginTop: '0px',
                                  fontSize: '13px',
                                }}
                              >
                                Refills
                              </Typography>
                              <NativeSelect
                                style={{ marginTop: '8px' }}
                                labelId='demo-simple-select-label'
                                id='demo-simple-select'
                                className={classes.customSelect}
                                value={y.refillRequest}
                                placeholder='Select'
                                name='refillRequest'
                                onChange={(e) =>
                                  handlePrescription(e, j, val.clientId)
                                }
                              >
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </NativeSelect>
                            </Grid>
                            {y.refillRequest !== false ? (
                              <Grid xs={4} style={{ paddingLeft: '10px' }}>
                                <Typography
                                  style={{
                                    color: '#003265',
                                    textAlign: 'left',
                                    marginTop: '0px',
                                    fontSize: '13px',
                                  }}
                                >
                                  Number of Refills
                                </Typography>
                                <input
                                  style={{ height: 53, marginTop: '8px' }}
                                  value={y.refillAllowed}
                                  className={classes.customSelect}
                                  type='number'
                                  name='refillAllowed'
                                  onChange={(e) =>
                                    handlePrescription(e, j, val.clientId)
                                  }
                                />
                              </Grid>
                            ) : (
                              <> </>
                            )}
                            <Grid xs={4} style={{ paddingLeft: '10px' }}>
                              <Typography
                                style={{
                                  color: '#003265',
                                  textAlign: 'left',
                                  marginTop: '0px',
                                  fontSize: '13px',
                                }}
                              >
                                Number of Dosage
                              </Typography>
                              <input
                                style={{ height: 53, marginTop: '8px' }}
                                type='number'
                                value={y.dose}
                                className={classes.customSelect}
                                name='dose'
                                onChange={(e) =>
                                  handlePrescription(e, j, val.clientId)
                                }
                              />
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            </Card>
          </div>
        )}

        <Grid container>
          <Grid>
            <Typography style={{ color: '#003265', textAlign: 'left' }}>
              Update Appointment details
            </Typography>
          </Grid>
          <Grid container className={classes.clinicians}>
            <Grid xs={12} style={{ margin: '20px' }}>
              <Grid>
                <Typography className={classes.labelText}>
                  {' '}
                  Session Cost(AED)
                </Typography>
              </Grid>
              <Grid container style={{ marginBottom: '15px' }}>
                <OutlinedInput
                  className={classes.input}
                  placeholder='200'
                  style={{ width: '30%' }}
                  value={val.cost}
                  onChange={(e) =>
                    changeCost(e, val.originallyComVal, val.clientId)
                  }
                />
              </Grid>
              <Grid>
                <Typography className={classes.labelText}>
                  Mark session as complete? Must have a note.
                </Typography>
              </Grid>
              <Grid container style={{ marginLeft: '15px' }}>
                <FormControl
                  variant='outlined'
                  className={classes.formControl + ' customt-select'}
                >
                  <NativeSelect
                    displayEmpty
                    label={'Select'}
                    placeholder='Select(Yes/No)'
                    className={classes.selectEmpty}
                    value={val.sessionCompleted}
                    onChange={(e) => {
                      if (e.target.value === 'yes') {
                        setCompletionCurrVal(e.target.value);
                        setCompletionOriginalVal(val.originallyComVal);
                        setClientId(val.clientId);
                        setConfirmation(true);
                      } else {
                        updateCompletion(
                          e.target.value,
                          val.originallyComVal,
                          val.clientId
                        );
                      }
                    }}
                  >
                    <option value=''>Select</option>
                    <option value='yes'>Yes</option>
                    <option value='no'>No</option>
                  </NativeSelect>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <PatientDetailClinician
          togglePatientDrawer={togglePatientDrawer}
          patientDrawer={patientDrawer}
          patientId={patientId}
        />
        <Dialog handleClose={closeCompleteConfirmation} open={openConfirmation}>
          <Grid
            spacing={3}
            style={{ padding: '50px' }}
            direction='column'
            container
          >
            <Grid item>
              <Typography>
                Are you sure, you want to mark session as complete and archive
                it? This action <span style={{ color: 'red' }}>CANNOT</span> be
                undone.
              </Typography>
            </Grid>
            <Grid item>
              <Grid spacing={2} justify='flex-end' container>
                <Grid item>
                  <Button onClick={closeCompleteConfirmation}>Cancel</Button>
                </Grid>
                <Grid item>
                  <ConfirmBtn onClick={markComplete} text='Confirm' />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      </Grid>
    );
  };

  return (
    <>
      <Grid style={{ width: '100%', padding: '12px' }}>
        <Grid container>
          {initialFormData &&
            initialFormData.length > 0 &&
            initialFormData.map((val, i) => {
              return AppointmentInput(val, i);
            })}
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.getByIdClinician.getByIdClinicianData,
});

export default connect(mapStateToProps, { getByIdClinicianAction })(
  AppointmentUserSession
);
