/* eslint-disable max-len */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from 'react';
import {

  withStyles,
  Typography,
  Grid,
  Divider,
  Collapse,
} from '@material-ui/core';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import styles from './HomeStyle';

class Home extends PureComponent {
  state = {
    expandOpen: true,
    expandClientOpen: true,
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.flexStyle}>
          <Typography className={classes.title}>Reminders</Typography>
        </div>
        <div style={{ marginTop: '20px' }}>
          {/* ============================================= */}
          <Grid container spacing={0}>
            <Grid item xs={1} sm={1}>
              <InsertDriveFileOutlinedIcon className={classes.driveIcon} />
            </Grid>
            <Grid item xs={10} sm={10}>
              <Typography className={classes.contentSty}>
                You have 32 appointments with missing notes
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1}>
              {this.state.expandOpen ? (
                <ExpandLessIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({ expandOpen: !this.state.expandOpen })}
                />
              ) : (
                <ExpandMoreIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({ expandOpen: !this.state.expandOpen })}
                />
              )}
            </Grid>
          </Grid>
          <Divider variant="middle" className={classes.divider} />
          <Collapse in={this.state.expandOpen} timeout="auto" unmountOnExit>
            <Grid container spacing={0}>
              <Grid item xs={9} sm={9} className={classes.gridsty}>
                <Typography display="inline" className={classes.progressSty}>
                  Create a Progress Note
                  {' '}
                </Typography>
                <Typography display="inline" className={classes.textsty}>
                  for appointment on 04/23 for Ajay Patidar
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2}>
                <Typography className={classes.dateSty}>2020-04-24</Typography>
              </Grid>
              <Grid item xs={1} sm={1}>
                <CloseIcon />
              </Grid>
            </Grid>
            <Divider variant="inset" className={classes.subdivider} />

            <Grid container spacing={0}>
              <Grid item xs={9} sm={9} className={classes.gridsty}>
                <Typography display="inline" className={classes.progressSty}>
                  Create a Progress Note
                  {' '}
                </Typography>
                <Typography display="inline" className={classes.textsty}>
                  for appointment on 04/23 for Ajay Patidar
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2}>
                <Typography className={classes.dateSty}>2020-04-24</Typography>
              </Grid>
              <Grid item xs={1} sm={1}>
                <CloseIcon />
              </Grid>
            </Grid>
            <Divider variant="inset" className={classes.subdivider} />

            <Grid container spacing={0}>
              <Grid item xs={9} sm={9} className={classes.gridsty}>
                <Typography display="inline" className={classes.progressSty}>
                  Create a Progress Note
                  {' '}
                </Typography>
                <Typography display="inline" className={classes.textsty}>
                  for appointment on 04/23 for Ajay Patidar
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2}>
                <Typography className={classes.dateSty}>2020-04-24</Typography>
              </Grid>
              <Grid item xs={1} sm={1}>
                <CloseIcon />
              </Grid>
            </Grid>
            <Divider variant="inset" className={classes.subdivider} />
          </Collapse>
          {/* ========================================================= */}

          <Grid container spacing={0}>
            <Grid item xs={1} sm={1}>
              <AttachMoneyOutlinedIcon className={classes.driveIcon} />
            </Grid>
            <Grid item xs={10} sm={10}>
              <Typography className={classes.contentSty}>
                You have 6 clients with a balance due
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1}>
              {this.state.expandClientOpen ? (
                <ExpandLessIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({
                    expandClientOpen: !this.state.expandClientOpen,
                  })}
                />
              ) : (
                <ExpandMoreIcon
                  display="inline"
                  className={classes.arrowsty}
                  onClick={() => this.setState({
                    expandClientOpen: !this.state.expandClientOpen,
                  })}
                />
              )}
            </Grid>
          </Grid>
          <Divider variant="middle" className={classes.divider} />

          <Collapse
            in={this.state.expandClientOpen}
            timeout="auto"
            unmountOnExit
          >
            <Grid container spacing={0}>
              <Grid item xs={9} sm={9} className={classes.gridsty}>
                <Typography display="inline" className={classes.progressSty}>
                  Client Ajay Patidar
                  {' '}
                </Typography>
                {/* <Typography display="inline" className={classes.progSty}>
                    Ajay Patidar
                    {' '}
                  </Typography> */}
                <Typography display="inline" className={classes.textsty}>
                  has a balance due of $500.
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2}>
                <Typography className={classes.dateSty}>2020-04-24</Typography>
              </Grid>
              <Grid item xs={1} sm={1}>
                <CloseIcon />
              </Grid>
            </Grid>
            <Divider variant="inset" className={classes.subdivider} />

            <Grid container spacing={0}>
              <Grid item xs={9} sm={9} className={classes.gridsty}>
                <Typography display="inline" className={classes.progressSty}>
                  Client Ajay Patidar
                  {' '}
                </Typography>
                {/* <Typography display="inline" className={classes.progSty}>
                    Ajay Patidar
                    {' '}
                  </Typography> */}
                <Typography display="inline" className={classes.textsty}>
                  has a balance due of $500.
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2}>
                <Typography className={classes.dateSty}>2020-04-24</Typography>
              </Grid>
              <Grid item xs={1} sm={1}>
                <CloseIcon />
              </Grid>
            </Grid>
            <Divider variant="inset" className={classes.subdivider} />

            <Grid container spacing={0}>
              <Grid item xs={9} sm={9} className={classes.gridsty}>
                <Typography display="inline" className={classes.progressSty}>
                  Client Ajay Patidar
                  {' '}
                </Typography>
                {/* <Typography display="inline" className={classes.progSty}>
                    Ajay Patidar
                    {' '}
                  </Typography> */}
                <Typography display="inline" className={classes.textsty}>
                  has a balance due of $500.
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2}>
                <Typography className={classes.dateSty}>2020-04-24</Typography>
              </Grid>
              <Grid item xs={1} sm={1}>
                <CloseIcon />
              </Grid>
            </Grid>
            <Divider variant="inset" className={classes.subdivider} />
          </Collapse>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Home);
