/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import * as React from "react";
import {
    makeStyles, Grid, Typography, Button, Dialog, DialogActions,
    DialogContentText, DialogContent, DialogTitle, TextField, Container
} from "@material-ui/core";
import Axios from "axios";
import { baseURL } from '../utils/axios'
import AlertDialog from "./AlertDialog";
import styles from "./ForgotPassword.styles";



export default function ForgotPasswordModel(props) {
    const classes = styles();
    const [buttonText, setButtonText] = React.useState("Get OTP");
    const [showOTP, setShowOTP] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [emailError, setEmailError] = React.useState(false);
    const [passWordError, setPasswordError] = React.useState(false);
    const [otpError, setOtpError] = React.useState(false);
    const [otp, setOtp] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");

    const handleChange = (e) => {
        if (e.target.name == 'email') {
            if (validateEmail(e.target.value)) {
                setEmail(e.target.value)
                setEmailError(false)
            } else {
                setEmailError(true)
            }
        }

        if (e.target.name === 'otp') {
            setOtp(false)
            setOtp(e.target.value)
        }

        if (e.target.name === 'confirm-password') {
            setPassword(e.target.value)
        }

        if (e.target.name === 'password') {
            setConfirmPassword(e.target.value)
        }

        setPasswordError(password.length >= 6 && password === confirmPassword)
    }

    const sendOTP = () => {
        if (!email || email === "") {
            setEmailError(true)
            return
        }

        let body = {
            email: email
        }
        Axios.post(`${baseURL}/api/client/resetPasswordOtp`, body).then(res => {
            setShowOTP(true)
            setButtonText('Reset Password')
        }).catch(error => {
            console.log(error)
        })
    }

    const handleReset = () => {

        if (!otp || otp === '') {
            setOtpError(true)
            AlertDialog("Enter OTP")
            return
        }

        if (password != confirmPassword) {
            setPasswordError(true)
            AlertDialog("Password doesnt match")
            return
        }

        if (password.length < 6) {
            setPasswordError(true)
            AlertDialog("Password must be at least 6 characters. ")
            return
        }


        let body = {
            email: email,
            otp: otp,
            password: password
        }
        Axios.post(`${baseURL}/api/client/resetPassword`, body, {
            headers: {
                'Authorization': localStorage.getItem('ACCESS_TOKEN_PATH')
            }
        }).then(res => {
            AlertDialog("Password Updated")
            props.history.push(`/login`);
        }).catch(error => {
            AlertDialog("Something went wrong")
        })
    }

    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    return (
        <Container maxWidth='sm'>
            <Typography style={{ alignContent: 'left' }} style={{ marginTop: "64px" }} variant="h2" gutterBottom>
                Reset Password
      </Typography>
            <p >
                Enter the email associated with your account and we'll send an email with one time pin to reset your password
      </p>
            <Grid
                // container
                //   alignItems="center"
                justify="center"
                spacing={3}
                className={classes.root}
            >


                    <div>
                        <br />
                        <br />
                        <TextField
                            name="email"
                            error={emailError}
                            id="outlined-error-helper-text"
                            label="email"
                            onChange={handleChange}
                            helperText={emailError ? "Bad Email format" : ""}
                            variant="outlined"
                        />
                        <br />
                    </div>


                    {showOTP ? <div><br />
                        <TextField
                            name="otp"
                            error={otpError}
                            id="outlined-error-helper-text"
                            label="OTP"
                            onChange={handleChange}
                            variant="outlined"
                            helperText={otpError ? "Enter Otp" : ""}
                        />
                        <br /></div> : <div/>}

                <Button style={{marginTop:"32px"}} color="secondary" variant="contained" onClick={sendOTP}>{showOTP ? "Get another OTP" : "Get OTP"}</Button>
                <div></div>
                {!showOTP ? <Button style={{marginTop:"32px"}} color="secondary" variant="contained" onClick={() => setShowOTP(true)}>Already have an OTP</Button>
                : <div/>}

                {showOTP ?
                    <div>
                       <br />
                        <TextField
                            name="password"
                            error={passWordError}
                            id="standard-password-input"
                            type="password"
                            label="password"
                            onChange={handleChange}
                            variant="outlined"
                            helperText={passWordError ? "Password must be at least 6 characters." : ""}
                        />
                        <br />
                        <br />
                        <TextField
                            name="confirm-password"
                            error={passWordError}
                            id="standard-password-input"
                            label="confirm-password"
                            type="password"
                            onChange={handleChange}
                            variant="outlined"
                            helperText={passWordError ? "Password must be at least 6 characters." : ""}
                        />
                        <br />

                        <Button style={{marginTop:"32px"}} color="secondary" variant="contained" onClick={handleReset}>
                            Reset Password</Button>

                    </div> :
                    <div />}
            </Grid>
        </Container>
    );
};
