/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    width: '50px',
    height: '50px',
    borderRadius: '25px',
    border: '2px solid #ffffff',
    display: 'flex',
    flexDirection: 'row',
  },
  participateImage: {
    width: '50px',
    height: '50px',
    borderRadius: '25px',
    border: '2px solid #ffffff',
  },
  img: {
    width: '25px',
    height: '25px',
    borderRadius: '25px',
  },
}));

export const EventImageContainer = ({ ...props }) => {
  const classes = styles();
  return <Grid className={classes.root}>{props.children}</Grid>;
};

export const EventDetailParticipateImageContainer = ({ ...props }) => {
  const classes = styles();
  return (
    <Grid className={classes.participateImage} spacing={3} container>
      {props.children}
    </Grid>
  );
};
