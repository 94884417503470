/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-extra-semi */
/* eslint-disable lines-between-class-members */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-unused-vars */
import React from 'react';
import Axios from 'axios';
import { BrowserRouter, Route } from 'react-router-dom';
import Routes from './components/Routes';
import './assets/css/alert.css';
import InsuranceForm from './user/patientToDoBeforeFirstAppointment/InsuranceForm';
import Payment from './user/patientToDoBeforeFirstAppointment/CheckList';
import ConsentForm from './user/patientToDoBeforeFirstAppointment/ConsentForm';
import PaymentSuccess from './user/pages/PaymentSuccess';
import firebase, { googleSignOut } from './utils/firebase';
import { baseURL } from './utils/axios';
import createHistory from 'history/createBrowserHistory';

class App extends React.Component {
  componentDidUpdate() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(async (id) => {
            const res = await Axios.get(
              `${baseURL}/api/client/user_by_uid/${user.uid}`,
              {
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                  Authorization: id,
                },
              }
            );
            localStorage.setItem('ACCESS_TOKEN_PATH', id);
            if (res.data) {
              if (res.data.dob) {
                if (res.data.verify) {
                  console.log(res.data.dob);
                  this.props.history.push('/dashboard');
                } else {
                  googleSignOut().then(() => {
                    localStorage.removeItem('avatar');
                    localStorage.removeItem('name');
                    localStorage.removeItem('ACCESS_TOKEN_PATH');
                    localStorage.removeItem('createdAt');
                    localStorage.removeItem('clientEmail');
                    localStorage.removeItem('clientUid');
                    localStorage.removeItem('clientId');
                    localStorage.removeItem('appFor');
                    localStorage.removeItem('childId');
                  });
                }
              }
            }
          });
      } else {
        console.log('errpr');
      }
    });
    if (localStorage.getItem('ACCESS_TOKEN_PATH') === null || undefined || '') {
      localStorage.clear();
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <div className='App'>
        <div id='alertid' className='accounting' />
        <BrowserRouter>
          <Route path='/client/insurance' component={InsuranceForm} />
          <Route path='/client/checklist/:id' component={Payment} />
          <Route path='/client/consent' component={ConsentForm} />
          <Route path='/user/payment/success' component={PaymentSuccess} />
          <Routes />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
