/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import {
  withStyles,
  Grid,
  Typography,
  FormControl,
  TextField,
  MenuItem,
  Select,
  Chip,
  Button,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Avatar from '@material-ui/core/Avatar';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import profile from '../../../assets/imgs/ananyaBig.png';
import AlertMsg from '../../../utils/Alert';
import {
  characters,
  numbers,
  floatAndDigits,
  isEmail,
  isValidUrl,
} from '../../../utils/validators';
import { Loader } from '../../../user/Loader';
import '../../../Patient/pages/main.css';
import {
  getClientAction,
  updateClientAction,
  uploadClientImageAction,
  getEmployerAction,
  uploadPharmacyAction,
  getPharmacyAction,
  getEmployerIdAction,
  uploadDocFileAction,
} from '../../../utils/actions/client.action';
import { AutoComplete, List } from '../../../components/atoms';
import countries from '../../../utils/countries_data';
import CircularProgress from '@material-ui/core/CircularProgress';
import logo from '../../../assets/imgs/download.png';
// import DocumentsUpload from '../../../common/documentsUpload';
import style from '../../../Patient/pages/Setting_style';
import Layout from '../common/AdminLayout';
import { IOSSwitch } from '../Staff/AddStaffMember';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import SearchClinician from '../../../user/pages/clinician/SearchClinician';
import { useEffect, useState } from 'react';
import moment from 'moment';
import Axios from 'axios';
import { baseURL } from '../../../utils/axios';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';
import DocumentsUploads from '../../../common/documentsUploads';
import { withRouter } from 'react-router';
import { Required } from './Events.styles';
import { getAllCompanies } from '../../../utils/actions/company.action';
import { EVENT_CATEGORIES, EVENTS_AGE_CATEOGORIES } from '../../../utils/enums';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class AddEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      companiesList: [],
      title: '',
      metaKeywords: '',
      metaDescription: '',
      subtitle: '',
      registrationStillOpen: false,
      isFree: false,
      advancedPayment: 0,
      ageRange: {
        min: 0,
        max: 99,
      },
      age: 'Empty',
      category: 'Empty',
      fee: 0,
      totalSeats: 0,
      numberOfAttendees: 0,
      isForChildren: false,
      isParentsIncluded: false,
      isWebinar: false,
      webinarLink: '',
      displayAddress: true,
      address: '',
      campaignLink: '',
      description: '',
      poster: '',
      flyerImage: '',
      generalPDF: '',
      detailsOverviewPDF: '',
      eventFaqPDF: '',
      video: '',
      date: new Date(),
      startTime: new Date(),
      endTime: new Date(),
      clinicians: [],
      creator: '',
      corporationId: '',
      type: 'Empty',
      isUploading: false,
      location: {
        type: 'PHYSICAL', // physical, online, etc
        address: '',
      },
      errors: {
        title: '',
        subtitle: '',
        advancedPayment: '',
        category: '',
        fee: '',
        address: '',
        totalSeats: '',
        webinarLink: '',
        campaignLink: '',
        description: '',
        poster: '',
        flyerImage: '',
        generalPDF: '',
        detailsOverviewPDF: '',
        eventFaqPDF: '',
        video: '',
        clinicians: '',
        creator: '',
        corporationId: '',
        type: '',
        location: {
          type: '',
          address: '',
        },
      },
      categories: []
    };
  }

  getCategories = (page) => {
    Axios.get(`${baseURL}/api/event/category`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          console.log(res.data)
          this.setState({ categories: res.data })
        }
      })
      .catch((e) => {

      });
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.getAllCompanies(1, '');
    this.getCategories()

    if (id) {
      Axios.get(`${baseURL}/api/event/internal/${id}`, {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      })
        .then((res) => {
          console.log('res.data', res.data);
          if (res.data) {
            this.setState({
              ...res.data,
              corporationId: res.data?.corporationId?._id,
            });
          }
        })
        .catch((e) => {
          console.log('res.data', e.message);

          AlertMsg('error', e.message);
        });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    const { name, value } = e.target;
    const { errors } = this.state;

    switch (name) {
      case 'fee':
        errors.fee = floatAndDigits.test(value)
          ? value.length === 0
            ? 'Please Enter Fee'
            : ''
          : 'Please Enter a number';
        break;

      case 'advancedPayment':
        errors.advancedPayment = floatAndDigits.test(value)
          ? value.length === 0
            ? 'Please Enter Advanced Payment'
            : ''
          : 'Please Enter a number';
        break;
      case 'totalSeats':
        errors.totalSeats = numbers.test(value)
          ? value.length === 0
            ? 'Please Enter Total Seats'
            : ''
          : 'Please Enter a number';
        break;
      case 'webinarLink':
        errors.webinarLink =
          value.length > 0 ? null : 'Webinar Link is required!';
        break;

      case 'address':
        errors.address = value ? null : 'Address is required!';
        break;

      case 'campaignLink':
        errors.campaignLink =
          value.length > 0 ? null : 'CampaignLink is required!';
        break;

      case 'title':
        errors.title = value.length > 0 ? null : 'title is required!';
        break;

      case 'category':
        errors.category = value.length > 0 ? null : 'category is required!';
        break;

      case 'corporationId':
        errors.corporationId =
          value.length > 0 ? null : 'corporationId is required!';
        break;

      default:
        break;
    }

    this.setState({ errors });
  };

  readFile(file) {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();

      reader.onload = function (event) {
        var _event$target;

        resolve(
          event === null || event === void 0
            ? void 0
            : (_event$target = event.target) === null ||
              _event$target === void 0
              ? void 0
              : _event$target.result
        );
      };

      reader.onerror = function (event) {
        reader.abort();
        reject(event);
      };

      reader.readAsDataURL(file);
    });
  }

  async documentUpload(selectedFiles) {
    const formData = new FormData();
    formData.append('document', selectedFiles);
    this.setState({ btnLoading: true });
    await this.props.uploadClientImageAction(formData, 'profileImage');
  }

  async docFileUpload(selectedFiles, type) {
    const formData = new FormData();
    formData.append('document', selectedFiles);

    await this.props.uploadDocFileAction(formData, type);
  }

  async handleImageUpload(files) {
    if (files.length > 0) {
      var result = await this.readFile(files[0]);
      await this.documentUpload(files[0]);
      this.setState({
        // profileImage: URL.createObjectURL(file),
        profileImage: result,
      });
    }
  }

  async handleEmployerId() {
    var eId = this.state.eId;
    await this.props.getEmployerIdAction(eId);
  }

  async handleDocUpload(e, type) {
    await this.docFileUpload(e.target.files[0], type);
  }

  triggerFile(name) {
    document.getElementsByName(name)[0].click();
  }

  handleSubmit = async (e) => {
    this.setState({ btnLoading: true });
    let { erorrs } = this.state;
    const data = { ...this.state };
    let addressType = data.isWebinar ? 'Webinar link' : 'Physical address';

    delete data.errors;

    data.clinicians = data.clinicians.map((c) => c._id);
    data.creator = localStorage.getItem('clinicianUID');
    let start = moment(data.startTime);
    let end = moment(data.endTime);
    data.startTime = moment(data.date).set({
      hour: start.get('hour'),
      minute: start.get('minutes'),
    });
    data.endTime = moment(data.date).set({
      hour: end.get('hour'),
      minute: end.get('minutes'),
    });

    if (data.isWebinar) {
      data.location.type = 'ONLINE';
      data.location.address = data.webinarLink;
    } else {
      data.location.type = 'PHYSICAL';
    }

    if (data.startTime > data.endTime) {
      AlertMsg('error', 'Start time can not be greater than end date!');
      this.setState({ btnLoading: false });
      return;
    }

    if (!data.isFree && data.fee < 0.1) {
      AlertMsg('error', 'Fee is required!');
      this.setState({ btnLoading: false });
      return;
    } else if (!data.totalSeats || Number(data.totalSeats) === 0) {
      AlertMsg('error', 'Number of seats are required!');
      this.setState({ btnLoading: false });
      return;
    } else if (this.state.location.address.trim() === '' && !data.isWebinar) {
      AlertMsg('error', 'Physical address is required!');
      this.setState({ btnLoading: false });
      return;
    } else if (data.location.address.trim() === '' && data.isWebinar) {
      AlertMsg('error', 'Webinar Link is required!');
      this.setState({ btnLoading: false });
      return;
    }

    if (this.state.title.trim() === '') {
      AlertMsg('error', "Please enter event's title");
      this.setState({ btnLoading: false });
      return;
    }

    if (this.state.category === 'Empty') {
      AlertMsg('error', 'Select Category Type');
      this.setState({ btnLoading: false });
      return;
    }
    console.log(
      'this.state.corporationId',
      typeof this.state.corporationId,
      this.state.corporationId
    );
    if (this.state.corporationId.trim() === '') {
      AlertMsg('error', "Please enter Organizer's name");
      this.setState({ btnLoading: false });
      return;
    }

    if (this.state.type === 'Empty') {
      AlertMsg('error', 'Select Event Type');
      this.setState({ btnLoading: false });
      return;
    }

    if (this.state.clinicians.length === 0) {
      AlertMsg('error', 'Please select at least one clinician');
      this.setState({ btnLoading: false });
      return;
    }

    if (data.isWebinar && data.webinarLink && !isValidUrl(data.webinarLink)) {
      AlertMsg('error', 'Webinar Link is not valid!');
      this.setState({ btnLoading: false });
      return;
    }

    let axiosConfig = {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    };

    Axios.post(`${baseURL}/api/event`, data, axiosConfig)
      .then((res) => {
        AlertMsg('success', 'Created/Updated Event');
        this.props.history.push('/admin/events');
      })
      .catch((e) => {
        AlertMsg('error', e.message);
      });
  };

  handleDelete = (chipToDelete) => () => {
    this.setState({
      clinicians: this.state.clinicians.filter(
        (chip) => chip.name !== chipToDelete.name
      ),
    });
  };

  handleSearchSelectHandle = (data) => {
    let d = { ...data, justAdded: true };
    let chipData = this.state.clinicians;

    chipData.push(d);
    this.setState({ clinicians: chipData });
  };

  handleDateChange = (name, date) => {
    let mDate = moment(date);
    mDate.set({ second: 0, millisecond: 0 });
    this.setState({
      [name]: mDate,
    });
  };

  onCompanySelect = (val) => {
    console.log('val', val);
    if (val) {
      this.setState({
        corporationId: val._id,
      });
    }
  };

  toggleMenu = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleDescription = (value) => {
    // console.log('value', value);
    this.setState({ description: value });
  };

  render() {
    const { classes, companies } = this.props;
    const { btnLoading, errors, clinicians, categories } = this.state;
    // console.log('this.state.corporationId', this.state);
    return (
      <div>
        <>
          <div>
            <Grid container spacing={3} style={{ background: '#ffffff' }}>
              <Grid item sm={12} lg={6} style={{ marginBottom: '15px' }}>
                <Paper className={classes.paper}>
                  <Typography
                    style={{ marginBottom: '10px' }}
                    className={classes.inputText}
                  >
                    Event Registration
                  </Typography>

                  <Grid item xs={12} style={{ paddingTop: '16px' }}>
                    <Typography className={classes.inputTitle}>
                      Is event registration still open? <Required>*</Required>
                      <Grid xs={12} style={{ textAlign: 'left' }}>
                        <Typography component='label'>Yes</Typography>
                        <IOSSwitch
                          checked={this.state.registrationStillOpen}
                          onChange={(evt) =>
                            this.handleChange({
                              target: {
                                value: evt.target.checked,
                                name: 'registrationStillOpen',
                              },
                            })
                          }
                        />
                        <Typography component='label'>No</Typography>
                      </Grid>
                    </Typography>
                  </Grid>

                  <Grid item xs={12} style={{ paddingTop: '16px' }}>
                    <Grid xs={4} item className={classes.fieldContainer}>
                      <Typography className={classes.inputTitle}>
                        Event Date <Required>*</Required>
                      </Typography>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          style={{ width: '100%' }}
                          disableToolbar
                          variant='inline'
                          format='MM/dd/yyyy'
                          margin='normal'
                          id='date-picker-inline'
                          minDate={new Date()}
                          value={this.state.date}
                          onChange={(e) => this.handleDateChange('date', e)}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />{' '}
                      </MuiPickersUtilsProvider>
                    </Grid>
                    {/* <span className={classes.errorText}>
                            {errors.emName && errors.emName}
                        </span> */}
                  </Grid>

                  <Grid xs={4} style={{ paddingTop: '16px' }}>
                    <Grid className={classes.fieldContainer}>
                      <Typography className={classes.inputTitle}>
                        Start Time
                      </Typography>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                          margin='normal'
                          style={{ width: '100%' }}
                          id='time-picker'
                          value={this.state.startTime}
                          onChange={(e) =>
                            this.handleDateChange('startTime', e)
                          }
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    {/* <span className={classes.errorText}>
                            {errors.emEmail && errors.emEmail}
                        </span> */}
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    component='div'
                    style={{ paddingTop: '16px' }}
                  >
                    <Grid className={classes.fieldContainer}>
                      <Typography className={classes.inputTitle}>
                        End Time
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardTimePicker
                            margin='normal'
                            id='time-picker'
                            style={{ width: '100%' }}
                            value={this.state.endTime}
                            onChange={(e) =>
                              this.handleDateChange('endTime', e)
                            }
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Typography>
                    </Grid>
                    {/* <span className={classes.errorText}>
                            {errors.emPhoneNumber && errors.emPhoneNumber}
                        </span> */}
                  </Grid>
                </Paper>
              </Grid>

              <Grid item sm={12} lg={6} style={{ marginBottom: '15px' }}>
                <Paper className={classes.paper}>
                  <Grid style={{ marginTop: '65px' }}>
                    <Typography className={classes.inputTitle}>
                      Is the event FREE? <Required>*</Required>
                      <Grid style={{ textAlign: 'left' }}>
                        <Typography component='label'>Yes</Typography>
                        <IOSSwitch
                          checked={this.state.isFree}
                          onChange={(evt) =>
                            this.handleChange({
                              target: {
                                value: evt.target.checked,
                                name: 'isFree',
                              },
                            })
                          }
                        />
                        <Typography component='label'>No</Typography>
                      </Grid>
                    </Typography>
                  </Grid>

                  {!this.state.isFree ? (
                    <div>
                      <Grid style={{ marginTop: '19px' }}>
                        <Typography className={classes.inputTitle}>
                          Registration Fee <Required>*</Required>
                        </Typography>
                        <input
                          className={classes.inputsty}
                          // onChange={e => this.setState({fee: e.target.value})}
                          onChange={this.handleChange}
                          value={this.state.fee}
                          name='fee'
                        />
                        <span className={classes.errorText}>
                          {this.state.errors.fee && this.state.errors.fee}
                        </span>
                      </Grid>

                      <Grid style={{ marginTop: '16px' }}>
                        <Typography className={classes.inputTitle}>
                          Advanced payment
                        </Typography>
                        <input
                          className={classes.inputsty}
                          onChange={this.handleChange}
                          value={this.state.advancedPayment}
                          type='text'
                          name='advancedPayment'
                        />
                        {/* <span className={classes.errorText}>
                        {errors.address1 && errors.address1}
                    </span> */}
                      </Grid>
                    </div>
                  ) : (
                    <> </>
                  )}

                  <Grid style={{ marginTop: '16px' }}>
                    <Typography className={classes.inputTitle}>
                      Total seats available <Required>*</Required>
                    </Typography>
                    <TextField
                      className={classes.inputsty}
                      onChange={this.handleChange}
                      value={this.state.totalSeats}
                      type='number'
                      name='totalSeats'
                    />
                    {/* <span className={classes.errorText}>
                        {errors.address2 && errors.address2}
                    </span> */}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              style={{ background: '#ffffff', marginTop: '48px' }}
            >
              <Grid item sm={12} lg={6} style={{ marginBottom: '15px' }}>
                <Paper className={classes.paper}>
                  <Typography
                    style={{ marginBottom: '10px' }}
                    className={classes.inputText}
                  >
                    Venue & Demographics
                  </Typography>

                  <Grid style={{ paddingTop: '16px' }}>
                    <Typography className={classes.inputTitle}>
                      Is the event a webinar? <Required>*</Required>
                      <Grid style={{ textAlign: 'left' }}>
                        <Typography component='label'>Yes</Typography>
                        <IOSSwitch
                          checked={this.state.isWebinar}
                          onChange={(evt) => {
                            this.setState({
                              location: {
                                ...this.state.location,
                                type: evt.target.checked
                                  ? 'ONLINE'
                                  : 'PHYSICAL',
                              },
                            });
                            this.handleChange({
                              target: {
                                value: evt.target.checked,
                                name: 'isWebinar',
                              },
                            });
                          }}
                        />
                        <Typography component='label'>No</Typography>
                      </Grid>
                    </Typography>
                  </Grid>

                  {this.state.isWebinar ? (
                    <Grid
                      item
                      xs={12}
                      component='div'
                      style={{ paddingTop: '16px' }}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.inputTitle}>
                          Webinar link <Required>*</Required>
                        </Typography>
                        <input
                          type='text'
                          name='webinarLink'
                          value={this.state.webinarLink}
                          onChange={this.handleChange}
                          className={classes.inputsty}
                        />
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.webinarLink && errors.webinarLink}
                      </span>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      component='div'
                      style={{ paddingTop: '16px' }}
                    >
                      <Grid className={classes.fieldContainer}>
                        <Typography className={classes.inputTitle}>
                          Physical Location <Required>*</Required>
                        </Typography>
                        <input
                          type='text'
                          name='address'
                          value={this.state.location.address}
                          onChange={(e) =>
                            this.setState({
                              location: {
                                address: e.target.value,
                              },
                            })
                          }
                          className={classes.inputsty}
                        />
                      </Grid>
                      <span className={classes.errorText}>
                        {errors.address && errors.address}
                      </span>
                    </Grid>
                  )}

                  <Grid item xs={12} component='div'>
                    <Grid style={{ marginTop: '15px' }}>
                      <Typography className={classes.inputTitle}>
                        {this.state.isWebinar
                          ? 'Display Webinar Link'
                          : 'Display Physical Address'}{' '}
                        <Required>*</Required>
                        <Grid style={{ textAlign: 'left' }}>
                          <Typography component='label'>Yes</Typography>
                          <IOSSwitch
                            checked={this.state.displayAddress}
                            onChange={(evt) =>
                              this.handleChange({
                                target: {
                                  value: evt.target.checked,
                                  name: 'displayAddress',
                                },
                              })
                            }
                          />
                          <Typography component='label'>No</Typography>
                        </Grid>
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item sm={12} lg={6} style={{ marginBottom: '15px' }}>
                <Paper className={classes.paper}>
                  <Grid>
                    <Typography className={classes.inputTitle}>
                      Is the event for children? <Required>*</Required>
                      <Grid style={{ textAlign: 'left' }}>
                        <Typography component='label'>Yes</Typography>
                        <IOSSwitch
                          checked={this.state.isForChildren}
                          onChange={(evt) =>
                            this.handleChange({
                              target: {
                                value: evt.target.checked,
                                name: 'isForChildren',
                              },
                            })
                          }
                        />

                        <Typography component='label'>No</Typography>
                      </Grid>
                    </Typography>
                  </Grid>

                  <Grid style={{ marginTop: '19px' }}>
                    <Typography className={classes.inputTitle}>
                      Parents included?
                      <Grid style={{ textAlign: 'left' }}>
                        <Typography component='label'>Yes</Typography>
                        <IOSSwitch
                          checked={this.state.isParentsIncluded}
                          onChange={(evt) =>
                            this.handleChange({
                              target: {
                                value: evt.target.checked,
                                name: 'isParentsIncluded',
                              },
                            })
                          }
                        />
                        <Typography component='label'>No</Typography>
                      </Grid>
                    </Typography>
                  </Grid>

                  <Grid container>
                    <Grid style={{ marginBottom: '15px' }}>
                      <Grid
                        sm={12}
                        style={{ marginTop: '19px' }}
                        className={classes.rangeField}
                      >
                        <Typography className={classes.inputTitle}>
                          Patient Age Range
                        </Typography>
                        <FormControl className={classes.inputsty}>
                          <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            name='age'
                            value={
                              this.state.ageRange.min +
                              '-' +
                              this.state.ageRange.max
                            }
                            style={{ width: '200px' }}
                            onChange={(e) => {
                              let splits = e.target.value.split('-');
                              if (splits.length < 2) {
                                return;
                              }
                              this.setState({
                                ageRange: {
                                  min: splits[0],
                                  max: splits[1],
                                },
                              });
                            }}
                          >
                            {Object.keys(EVENTS_AGE_CATEOGORIES).map((eac) => (
                              <MenuItem key={`eac-${eac}`} value={eac}>
                                {EVENTS_AGE_CATEOGORIES[eac]}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              style={{ background: '#ffffff', marginTop: '48px' }}
            >
              <Grid item sm={12} lg={12} style={{ marginBottom: '15px' }}>
                <Paper className={classes.paper}>
                  <Typography
                    style={{ marginBottom: '10px' }}
                    className={classes.inputText}
                  >
                    Event Details
                  </Typography>

                  <Grid
                    item
                    xs={12}
                    component='div'
                    style={{ paddingTop: '16px' }}
                  >
                    <Grid className={classes.fieldContainer}>
                      <Typography className={classes.inputTitle}>
                        Event Title <Required>*</Required>
                      </Typography>
                      <input
                        type='text'
                        name='title'
                        value={this.state.title}
                        onChange={this.handleChange}
                        className={classes.inputsty}
                      />
                    </Grid>
                    <span className={classes.errorText}>
                      {errors.title && errors.title}
                    </span>
                  </Grid>

                  <Grid style={{ paddingTop: '16px' }}>
                    <Grid className={classes.fieldContainer}>
                      <Typography className={classes.inputTitle}>
                        Event Subtitle
                        <input
                          type=''
                          name='subtitle'
                          value={this.state.subtitle}
                          onChange={this.handleChange}
                          className={classes.inputsty}
                        />
                      </Typography>
                    </Grid>
                    <span className={classes.errorText}>
                      {errors.subtitle && errors.subtitle}
                    </span>
                  </Grid>

                  <Grid item xs={12} container style={{ paddingTop: '16px' }}>
                    <Grid className={classes.fieldContainer}>
                      <Typography className={classes.inputTitle}>
                        Event Category <Required>*</Required>
                      </Typography>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        name='category'
                        style={{ width: '200px' }}
                        value={`${this.state.category._id}`}
                        onChange={(e) => {
                          this.setState({ category: categories.find(cat => cat._id === e.target.value) })
                        }
                        }
                      >
                        <MenuItem value={'Empty'}>Select</MenuItem>

                        {categories.map((ec) => (
                          <MenuItem value={ec._id} key={ec._id}>
                            {ec.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <span className={classes.errorText}>
                      {errors.category && errors.category}
                    </span>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    component='div'
                    style={{ paddingTop: '16px' }}
                  >
                    <Typography className={classes.inputTitle}>
                      Event Description
                    </Typography>
                    <ReactQuill
                      value={this.state.description}
                      onChange={this.handleDescription}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    component='div'
                    style={{ paddingTop: '16px' }}
                  >
                    <Grid className={classes.fieldContainer}>
                      <Typography className={classes.inputTitle}>
                        Meta Description
                      </Typography>
                      <input
                        type='text'
                        name='metaDescription'
                        value={this.state.metaDescription}
                        onChange={this.handleChange}
                        className={classes.inputsty}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    component='div'
                    style={{ paddingTop: '16px' }}
                  >
                    <Grid className={classes.fieldContainer}>
                      <Typography className={classes.inputTitle}>
                        Meta Keywords
                      </Typography>
                      <input
                        type='text'
                        name='metaKeywords'
                        value={this.state.metaKeywords}
                        onChange={this.handleChange}
                        className={classes.inputsty}
                      />
                    </Grid>
                  </Grid>

                  <DocumentsUploads
                    titleText='General PDF'
                    fileType='application/pdf'
                    url={this.state.generalPDF}
                    isUploading={(isUploading) => {
                      this.setState({ isUploading });
                    }}
                    onUpload={(document) => {
                      this.setState({ generalPDF: document.url });
                    }}
                    model='event'
                    instanceId={this.state._id}
                    modelKey='generalPDF'
                  />
                  <DocumentsUploads
                    titleText='Details Overview PDF'
                    fileType='application/pdf'
                    url={this.state.detailsOverviewPDF}
                    isUploading={(isUploading) => {
                      this.setState({ isUploading });
                    }}
                    onUpload={(document) => {
                      this.setState({ detailsOverviewPDF: document.url });
                    }}
                    model='event'
                    instanceId={this.state._id}
                    modelKey='detailsOverviewPDF'
                  />
                  <DocumentsUploads
                    titleText='Event FAQ PDF'
                    fileType='application/pdf'
                    url={this.state.eventFaqPDF}
                    isUploading={(isUploading) => {
                      this.setState({ isUploading });
                    }}
                    onUpload={(document) => {
                      this.setState({ eventFaqPDF: document.url });
                    }}
                    model='event'
                    instanceId={this.state._id}
                    modelKey='eventFaqPDF'
                  />

                  <DocumentsUploads
                    url={this.state.flyerImage}
                    titleText='Event flyer JPG/PNG *'
                    fileType='image/png, image/jpeg'
                    isUploading={(isUploading) => {
                      this.setState({ isUploading });
                    }}
                    onUpload={(document) => {
                      this.setState({ flyerImage: document.url });
                    }}
                    model='event'
                    instanceId={this.state._id}
                    modelKey='flyerImage'
                  />
                  <DocumentsUploads
                    url={this.state.poster}
                    titleText='Event header image *'
                    fileType='image/png, image/jpeg'
                    isUploading={(isUploading) => {
                      this.setState({ isUploading });
                    }}
                    onUpload={(document) => {
                      this.setState({ poster: document.url });
                    }}
                    model='event'
                    instanceId={this.state._id}
                    modelKey='poster'
                  />
                </Paper>
              </Grid>
            </Grid>

            <Grid
              container
              style={{ background: '#ffffff', marginTop: '48px' }}
            >
              <Grid item sm={12} lg={6} style={{ marginBottom: '15px' }}>
                <Paper className={classes.paper}>
                  <Typography
                    style={{ marginBottom: '10px' }}
                    className={classes.inputText}
                  >
                    Organizers & Clinicians
                  </Typography>

                  <Grid
                    item
                    xs={12}
                    component='div'
                    style={{ paddingTop: '16px' }}
                  >
                    <Grid className={classes.fieldContainer}>
                      <Typography className={classes.inputTitle}>
                        Organizer Name <Required>*</Required>
                      </Typography>
                      <Typography
                        align='left'
                        className={classes.dropdownDescription}
                      >
                        This field gets its data from the list of Corporate
                        Clients
                      </Typography>
                      <List
                        toggleMenu={this.toggleMenu}
                        isOpen={this.state.isOpen}
                        onChange={this.onCompanySelect}
                        options={companies}
                        value={this.state.corporationId}
                        placeholder='Select a company'
                      />
                    </Grid>
                    <span className={classes.errorText}>
                      {errors.corporationId && errors.corporationId}
                    </span>
                  </Grid>

                  <Grid item xs={12} style={{ paddingTop: '16px' }}>
                    <Typography className={classes.inputTitle}>
                      Type <Required>*</Required>
                    </Typography>
                    <FormControl className={classes.inputsty}>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        name='type'
                        value={this.state.type}
                        onChange={(e) =>
                          this.setState({ type: e.target.value })
                        }
                      >
                        <MenuItem value={'Empty'}>Select</MenuItem>
                        <MenuItem value={'public'}>Public</MenuItem>
                        <MenuItem value={'corporate'}>Corporate</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Paper>
              </Grid>

              <Grid container sm={12} lg={6} style={{ marginBottom: '15px' }}>
                <Paper className={classes.paper}>
                  <Grid item xs={12} style={{ paddingTop: '16px' }}>
                    <SearchClinician
                      props={this.props}
                      onSelectClinician={this.handleSearchSelectHandle}
                      selectedClinicians={clinicians}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: '16px' }}
                    justify='flex-start'
                    container
                  >
                    {clinicians.map((data, i) => {
                      let icon;
                      icon = <Avatar src={data.avatar}></Avatar>;
                      return (
                        <Chip
                          key={`lh-${i}`}
                          icon={icon}
                          label={data.name}
                          onDelete={
                            data.label === 'React'
                              ? undefined
                              : this.handleDelete(data)
                          }
                          style={{ height: '32px', marginTop: '15px' }}
                        />
                      );
                    })}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ marginTop: '16px', textAlign: 'right' }}
            >
              <Button
                onClick={this.handleSubmit}
                variant='contained'
                style={{
                  backgroundColor: '#1b63eb',
                  fontWeight: 100,
                  fontSize: 16,
                  marginTop: '12px',
                  color: '#fff',
                  textTransform: 'capitalize',
                }}
                disabled={btnLoading || !companies || companies.length === 0}
              >
                {btnLoading ? (
                  <CircularProgress size={20} color={'white'} />
                ) : (
                  'Save Event'
                )}
              </Button>
            </Grid>
          </div>
        </>
      </div>
    );
  }
}

AddEvents.defaultProps = {
  getClient: {},
};

const mapStateToProps = (state) => ({
  companies: state.CompanyReducer.companies,
  loadingCompanies: state.CompanyReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCompanies: (page, text) => dispatch(getAllCompanies(page, text)),
});

export default withRouter(
  withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(AddEvents))
);
