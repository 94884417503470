/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import * as React from "react";
import { Grid, Typography } from "@material-ui/core";
import styles from "../../common/TableHeader_style";


export const TableHeader = ({ ...props }) => {
  const classes = styles();
  return (
    <Grid
      // component="div"
      // container
      // alignItems="center"
      // justify="space-around"
      className={classes.root}
      {...props}
    >
      <Grid style={{paddingLeft:'7px', width: "20%" }} container justify="flex-start">
        <Typography className={classes.font}>Prescribed Rx</Typography>
      </Grid>
      <Grid style={{paddingLeft:'7px', width: "15%" }} container justify="flex-start">
        <Typography className={classes.font}>Date Written</Typography>
      </Grid>
      <Grid style={{paddingLeft:'7px', width: "15%" }} container justify="flex-start">
        <Typography className={classes.font}>Clinician</Typography>
      </Grid>
      <Grid style={{paddingLeft:'7px', width: "15%" }} container justify="flex-start">
        <Typography className={classes.font}>Dosage</Typography>
      </Grid>
      <Grid style={{paddingLeft:'7px', width: "15%" }} container justify="flex-start">
        <Typography className={classes.font}>Insurance</Typography>
      </Grid>
      <Grid style={{paddingLeft:'7px', width: "20%" }} container justify="flex-start">
        <Typography className={classes.font}></Typography>
      </Grid>
    </Grid>
  );
};
