/* eslint-disable no-sequences */
/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  CssBaseline,
  Container,
  Modal,

  withStyles,
} from '@material-ui/core';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import ClientAdd from './DialogMenu';
import EventShow from './ShowEventDetail';
import { getAppointmentAction } from '../../utils/actions/appointment.action';
import styles from './CalenderStyle';

const localizer = momentLocalizer(moment);

class Calendarcomponets extends React.Component {
  constructor() {
    super();
    this.state = {
      name: 'React',
      selectedDate: new Date(),
      isClicked: false,
      isEventClicked: false,
      eventData: [],
    };
  }

  async componentDidMount() {
    await this.props.getAppointmentAction();
  }

  componentDidUpdate(preProps) {
    const { getAppointmentData } = this.props;
    if (getAppointmentData !== preProps.getAppointmentData) {
      if (getAppointmentData.getAppointmentData) {
        this.setState({ eventData: getAppointmentData.getAppointmentData });
      }
    }
  }

  handleClick = (date) => {
    this.setState({ selectedDate: date.start, isClicked: true });
  };

  render() {
    const { classes } = this.props;
    console.log('claender=========', this.state.eventData);
    const events = this.state.eventData.map((item, index) => ({
      id: item._id,
      title: item.clientId && item.clientId.name,
      allDay: true,
      start: item.startTime,
      end: item.startTime,
    }));
    return (
      <>
        <div className={classes.root}>
          <Calendar
            events={events}
            // startAccessor="start"
            // endAccessor="end"
            defaultDate={this.state.selectedDate}
            defaultView="month"
            // defaultDate={moment().toDate()}
            localizer={localizer}
            className={classes.calenderSty}
            // eslint-disable-next-line no-alert
            onSelectSlot={this.handleClick}
            selectable
            onSelectEvent={(id) => this.setState({ isEventClicked: true, eventId: id.id })}
            eventPropGetter={(event, start, end, isSelected) => {
              const style = {
                backgroundColor:
                  new Date(start) > new Date() ? '#bbdcf9' : '#c0f2d6',
                color: '#000',
              };
              return {
                style,
              };
            }}
          />
        </div>
        {this.state.isClicked && (
          <ClientAdd
            props={this.props}
            selectedDate={this.state.selectedDate}
            openDialog={this.state.isClicked}
            closeDialog={() => (
              this.props.getAppointmentAction(),
              this.setState({ isClicked: false })
            )}
          />
        )}
        {this.state.isEventClicked && (
          <EventShow
            id={this.state.eventId}
            selectedDate={this.state.selectedDate}
            openDialog={this.state.isEventClicked}
            closeDialog={() => (
              this.props.getAppointmentAction(),
              this.setState({ isEventClicked: false })
            )}
          />
        )}
      </>
    );
  }
}
Calendarcomponets.propTypes = {
  getAppointmentData: PropTypes.array,
};
const connected = connect(
  (state) => ({
    getAppointmentData: state.getAppointment,
  }),
  {
    getAppointmentAction,
  },
)(Calendarcomponets);
const formed = reduxForm({
  form: 'getEvent',
})(connected);

export default withStyles(styles)(formed);
