import React, { useState } from 'react';
import { Dialog, Grid } from '@material-ui/core';
import { Container, Heading, Text } from '../style';
import { Button, Textarea } from '../../../../../components/atoms';

export default function CustomizedDialogs({ handleClose, open, onCancel }) {
  const [reasonForCancelling, setReason] = useState('');

  const onChange = (e) => setReason(e.target.value);
  const close = () => {
    setReason('');
    handleClose();
  };

  const handleCancel = () => {
    if (reasonForCancelling.trim()) {
      onCancel(reasonForCancelling.trim());
    } else {
      //show some message
    }
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <Container direction='column' justify='center' spacing={2} container>
        <Grid item>
          <Heading align='center'>Cancel Active Subscription?</Heading>
        </Grid>

        <Grid direction='column' item container>
          <Grid item>
            <Text align='center'>
              You are about to end an active subscription. This action will
              refund the client account for unused sessions.
            </Text>
          </Grid>
          <Grid item>
            <Text align='center'>
              Do you wish to continue? Enter reason before cancelling.
            </Text>
          </Grid>
        </Grid>
        <Grid item>
          <Textarea
            value={reasonForCancelling}
            onChange={onChange}
            name='reasonForCancelling'
            placeholder='Reason for cancelling'
            error=''
          />
        </Grid>
        <Grid item>
          <Grid justify='space-around' container>
            <Grid xs={5} item>
              <Button text='Abort' bgcolor='#003265' onClick={close} />
            </Grid>
            <Grid xs={5} item>
              <Button
                text='Cancel Subscription'
                bgcolor='#db1919'
                onClick={handleCancel}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
}
