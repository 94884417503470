import React from 'react';
import InvoiceLeftColFilter from './InvoiceLeftColFilter';
import { Dropdown } from '../../../../components/atoms';

const InvoiceLeftColHeader = ({
  createPersonalInvoice,
  createBundleInvoice,
  corporateClientInvoice,
  corporateInvoice,
  filterInvoices,
}) => {
  const INVOICE_ACTIONS = [
    { label: 'Personal Invoice', onClick: createPersonalInvoice },
    { label: 'Bundle Invoice', onClick: createBundleInvoice, disabled: true},
    { label: 'Corporate Client Invoice', onClick: corporateClientInvoice, disabled: true },
    { label: 'Corporate Invoice', onClick: corporateInvoice, disabled: true },
  ];

  return (
    <div
      className='item col-header flex-grow-0 flex-shrink-0'
      style={{
        height: '66px',
      }}
    >
      <div className='bg-white px-4 py-3 border border-r-0 border-gray-100 leading-10 flex justify-between'>
        <InvoiceLeftColFilter onChange={filterInvoices} />
        <div className='relative'>
          <Dropdown actions={INVOICE_ACTIONS} placeholder='New Invoice' />
        </div>
      </div>
    </div>
  );
};

export default InvoiceLeftColHeader;
