import { createStyles } from '@material-ui/core/styles';


export default createStyles({
    root: {
      width: "calc(100% - 260px)",
      height: "100%",
      margin: "auto",
      position: "absolute",
      top: "70px",
      left: "250px",
    },
    inputField: {
      alignContent: "left",
      textAlign: "left",
    },
    inputContainer: {
      padding: "5px",
      textAlign: "left",
    },
    input: {
      borderStyle: "solid",
      width: "100%",
      borderWidth: 1,
      // width: '30%',
      borderColor: "#003d79",
      borderRadius: 5,
      paddingLeft: 5,
      paddingRight: 5,
    },
    inputText: {
      fontSize: "14px",
      lineHeight: "24px",
      color: "#a29061",
      padding: "3px",
    },
    createButton: {
      float: "left",
      color: "#ffffff",
      margin: "10px",
      backgroundColor: "#003d79",
    },
  });
  