/* eslint quotes: 0, comma-dangle: 0, quote-props: 0 */
// https://www.iso.org/obp/ui/#search/code/
// altName from http://www.nationsonline.org/oneworld/country_code_list.htm
// shortName from https://countrycode.org
export default [
  {
    "code2": "AF",
    "code3": "AFG",
    "name": "Afghanistan",
    "capital": "Kabul",
    "region": "Asia",
    "subregion": "Southern Asia",
    "states": [
      {
          "name": "Badakhshan",
          "code": "BDS"
      },
      {
          "name": "Badghis",
          "code": "BDG"
      },
      {
          "name": "Baghlan",
          "code": "BGL"
      },
      {
          "name": "Balkh",
          "code": "BAL"
      },
      {
          "name": "Bamyan",
          "code": "BAM"
      },
      {
          "name": "Daykundi",
          "code": "DAY"
      },
      {
          "name": "Farah",
          "code": "FRA"
      },
      {
          "name": "Faryab",
          "code": "FYB"
      },
      {
          "name": "Ghazni",
          "code": "GHA"
      },
      {
          "name": "Ghōr",
          "code": "GHO"
      },
      {
          "name": "Helmand",
          "code": "HEL"
      },
      {
          "name": "Herat",
          "code": "HER"
      },
      {
          "name": "Jowzjan",
          "code": "JOW"
      },
      {
          "name": "Kabul",
          "code": "KAB"
      },
      {
          "name": "Kandahar",
          "code": "KAN"
      },
      {
          "name": "Kapisa",
          "code": "KAP"
      },
      {
          "name": "Khost",
          "code": "KHO"
      },
      {
          "name": "Kunar",
          "code": "KNR"
      },
      {
          "name": "Kunduz Province",
          "code": "KDZ"
      },
      {
          "name": "Laghman",
          "code": "LAG"
      },
      {
          "name": "Logar",
          "code": "LOG"
      },
      {
          "name": "Nangarhar",
          "code": "NAN"
      },
      {
          "name": "Nimruz",
          "code": "NIM"
      },
      {
          "name": "Nuristan",
          "code": "NUR"
      },
      {
          "name": "Paktia",
          "code": "PIA"
      },
      {
          "name": "Paktika",
          "code": "PKA"
      },
      {
          "name": "Panjshir",
          "code": "PAN"
      },
      {
          "name": "Parwan",
          "code": "PAR"
      },
      {
          "name": "Samangan",
          "code": "SAM"
      },
      {
          "name": "Sar-e Pol",
          "code": "SAR"
      },
      {
          "name": "Takhar",
          "code": "TAK"
      },
      {
          "name": "Urozgan",
          "code": "URU"
      },
      {
          "name": "Zabul",
          "code": "ZAB"
      }
    ]
  },
  {
    "code2": "AX",
    "code3": "ALA",
    "name": "Åland Islands",
    "capital": "Mariehamn",
    "region": "Europe",
    "subregion": "Northern Europe",
    "states": []
  },
  {
    "code2": "AL",
    "code3": "ALB",
    "name": "Albania",
    "capital": "Tirana",
    "region": "Europe",
    "subregion": "Southern Europe",
    "states": [
      {
          "name": "Berat County",
          "code": "01"
      },
      {
          "name": "Berat District",
          "code": "BR"
      },
      {
          "name": "Bulqizë District",
          "code": "BU"
      },
      {
          "name": "Delvinë District",
          "code": "DL"
      },
      {
          "name": "Devoll District",
          "code": "DV"
      },
      {
          "name": "Dibër County",
          "code": "09"
      },
      {
          "name": "Dibër District",
          "code": "DI"
      },
      {
          "name": "Durrës County",
          "code": "02"
      },
      {
          "name": "Durrës District",
          "code": "DR"
      },
      {
          "name": "Elbasan County",
          "code": "03"
      },
      {
          "name": "Fier County",
          "code": "04"
      },
      {
          "name": "Fier District",
          "code": "FR"
      },
      {
          "name": "Gjirokastër County",
          "code": "05"
      },
      {
          "name": "Gjirokastër District",
          "code": "GJ"
      },
      {
          "name": "Gramsh District",
          "code": "GR"
      },
      {
          "name": "Has District",
          "code": "HA"
      },
      {
          "name": "Kavajë District",
          "code": "KA"
      },
      {
          "name": "Kolonjë District",
          "code": "ER"
      },
      {
          "name": "Korçë County",
          "code": "06"
      },
      {
          "name": "Korçë District",
          "code": "KO"
      },
      {
          "name": "Krujë District",
          "code": "KR"
      },
      {
          "name": "Kuçovë District",
          "code": "KC"
      },
      {
          "name": "Kukës County",
          "code": "07"
      },
      {
          "name": "Kukës District",
          "code": "KU"
      },
      {
          "name": "Kurbin District",
          "code": "KB"
      },
      {
          "name": "Lezhë County",
          "code": "08"
      },
      {
          "name": "Lezhë District",
          "code": "LE"
      },
      {
          "name": "Librazhd District",
          "code": "LB"
      },
      {
          "name": "Lushnjë District",
          "code": "LU"
      },
      {
          "name": "Malësi e Madhe District",
          "code": "MM"
      },
      {
          "name": "Mallakastër District",
          "code": "MK"
      },
      {
          "name": "Mat District",
          "code": "MT"
      },
      {
          "name": "Mirditë District",
          "code": "MR"
      },
      {
          "name": "Peqin District",
          "code": "PQ"
      },
      {
          "name": "Përmet District",
          "code": "PR"
      },
      {
          "name": "Pogradec District",
          "code": "PG"
      },
      {
          "name": "Pukë District",
          "code": "PU"
      },
      {
          "name": "Sarandë District",
          "code": "SR"
      },
      {
          "name": "Shkodër County",
          "code": "10"
      },
      {
          "name": "Shkodër District",
          "code": "SH"
      },
      {
          "name": "Skrapar District",
          "code": "SK"
      },
      {
          "name": "Tepelenë District",
          "code": "TE"
      },
      {
          "name": "Tirana County",
          "code": "11"
      },
      {
          "name": "Tirana District",
          "code": "TR"
      },
      {
          "name": "Tropojë District",
          "code": "TP"
      },
      {
          "name": "Vlorë County",
          "code": "12"
      },
      {
          "name": "Vlorë District",
          "code": "VL"
      }
    ]
  },
  {
    "code2": "DZ",
    "code3": "DZA",
    "name": "Algeria",
    "capital": "Algiers",
    "region": "Africa",
    "subregion": "Northern Africa",
    "states": [
      {
          "name": "Adrar",
          "code": "01"
      },
      {
          "name": "Aïn Defla",
          "code": "44"
      },
      {
          "name": "Aïn Témouchent",
          "code": "46"
      },
      {
          "name": "Algiers",
          "code": "16"
      },
      {
          "name": "Annaba",
          "code": "23"
      },
      {
          "name": "Batna",
          "code": "05"
      },
      {
          "name": "Béchar",
          "code": "08"
      },
      {
          "name": "Béjaïa",
          "code": "06"
      },
      {
          "name": "Béni Abbès",
          "code": "53"
      },
      {
          "name": "Biskra",
          "code": "07"
      },
      {
          "name": "Blida",
          "code": "09"
      },
      {
          "name": "Bordj Baji Mokhtar",
          "code": "52"
      },
      {
          "name": "Bordj Bou Arréridj",
          "code": "34"
      },
      {
          "name": "Bouïra",
          "code": "10"
      },
      {
          "name": "Boumerdès",
          "code": "35"
      },
      {
          "name": "Chlef",
          "code": "02"
      },
      {
          "name": "Constantine",
          "code": "25"
      },
      {
          "name": "Djanet",
          "code": "56"
      },
      {
          "name": "Djelfa",
          "code": "17"
      },
      {
          "name": "El Bayadh",
          "code": "32"
      },
      {
          "name": "El M'ghair",
          "code": "49"
      },
      {
          "name": "El Menia",
          "code": "50"
      },
      {
          "name": "El Oued",
          "code": "39"
      },
      {
          "name": "El Tarf",
          "code": "36"
      },
      {
          "name": "Ghardaïa",
          "code": "47"
      },
      {
          "name": "Guelma",
          "code": "24"
      },
      {
          "name": "Illizi",
          "code": "33"
      },
      {
          "name": "In Guezzam",
          "code": "58"
      },
      {
          "name": "In Salah",
          "code": "57"
      },
      {
          "name": "Jijel",
          "code": "18"
      },
      {
          "name": "Khenchela",
          "code": "40"
      },
      {
          "name": "Laghouat",
          "code": "03"
      },
      {
          "name": "M'Sila",
          "code": "28"
      },
      {
          "name": "Mascara",
          "code": "29"
      },
      {
          "name": "Médéa",
          "code": "26"
      },
      {
          "name": "Mila",
          "code": "43"
      },
      {
          "name": "Mostaganem",
          "code": "27"
      },
      {
          "name": "Naama",
          "code": "45"
      },
      {
          "name": "Oran",
          "code": "31"
      },
      {
          "name": "Ouargla",
          "code": "30"
      },
      {
          "name": "Ouled Djellal",
          "code": "51"
      },
      {
          "name": "Oum El Bouaghi",
          "code": "04"
      },
      {
          "name": "Relizane",
          "code": "48"
      },
      {
          "name": "Saïda",
          "code": "20"
      },
      {
          "name": "Sétif",
          "code": "19"
      },
      {
          "name": "Sidi Bel Abbès",
          "code": "22"
      },
      {
          "name": "Skikda",
          "code": "21"
      },
      {
          "name": "Souk Ahras",
          "code": "41"
      },
      {
          "name": "Tamanghasset",
          "code": "11"
      },
      {
          "name": "Tébessa",
          "code": "12"
      },
      {
          "name": "Tiaret",
          "code": "14"
      },
      {
          "name": "Timimoun",
          "code": "54"
      },
      {
          "name": "Tindouf",
          "code": "37"
      },
      {
          "name": "Tipasa",
          "code": "42"
      },
      {
          "name": "Tissemsilt",
          "code": "38"
      },
      {
          "name": "Tizi Ouzou",
          "code": "15"
      },
      {
          "name": "Tlemcen",
          "code": "13"
      },
      {
          "name": "Touggourt",
          "code": "55"
      }
    ]
  },
  {
    "code2": "AS",
    "code3": "ASM",
    "name": "American Samoa",
    "capital": "Pago Pago",
    "region": "Oceania",
    "subregion": "Polynesia",
    "states": []
  },
  {
    "code2": "AD",
    "code3": "AND",
    "name": "Andorra",
    "capital": "Andorra la Vella",
    "region": "Europe",
    "subregion": "Southern Europe",
    "states": [
      {
          "name": "Andorra la Vella",
          "code": "07"
      },
      {
          "name": "Canillo",
          "code": "02"
      },
      {
          "name": "Encamp",
          "code": "03"
      },
      {
          "name": "Escaldes-Engordany",
          "code": "08"
      },
      {
          "name": "La Massana",
          "code": "04"
      },
      {
          "name": "Ordino",
          "code": "05"
      },
      {
          "name": "Sant Julià de Lòria",
          "code": "06"
      }
    ]
  },
  {
    "code2": "AO",
    "code3": "AGO",
    "name": "Angola",
    "capital": "Luanda",
    "region": "Africa",
    "subregion": "Middle Africa",
    "states": [
      {
          "name": "Bengo Province",
          "code": "BGO"
      },
      {
          "name": "Benguela Province",
          "code": "BGU"
      },
      {
          "name": "Bié Province",
          "code": "BIE"
      },
      {
          "name": "Cabinda Province",
          "code": "CAB"
      },
      {
          "name": "Cuando Cubango Province",
          "code": "CCU"
      },
      {
          "name": "Cuanza Norte Province",
          "code": "CNO"
      },
      {
          "name": "Cuanza Sul",
          "code": "CUS"
      },
      {
          "name": "Cunene Province",
          "code": "CNN"
      },
      {
          "name": "Huambo Province",
          "code": "HUA"
      },
      {
          "name": "Huíla Province",
          "code": "HUI"
      },
      {
          "name": "Luanda Province",
          "code": "LUA"
      },
      {
          "name": "Lunda Norte Province",
          "code": "LNO"
      },
      {
          "name": "Lunda Sul Province",
          "code": "LSU"
      },
      {
          "name": "Malanje Province",
          "code": "MAL"
      },
      {
          "name": "Moxico Province",
          "code": "MOX"
      },
      {
          "name": "Uíge Province",
          "code": "UIG"
      },
      {
          "name": "Zaire Province",
          "code": "ZAI"
      }
    ]
  },
  {
    "code2": "AI",
    "code3": "AIA",
    "name": "Anguilla",
    "capital": "The Valley",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": []
  },
  {
    "code2": "AQ",
    "code3": "ATA",
    "name": "Antarctica",
    "capital": "",
    "region": "Polar",
    "subregion": "",
    "states": []
  },
  {
    "code2": "AG",
    "code3": "ATG",
    "name": "Antigua and Barbuda",
    "capital": "Saint John's",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": [
      {
          "name": "Barbuda",
          "code": "10"
      },
      {
          "name": "Redonda",
          "code": "11"
      },
      {
          "name": "Saint George Parish",
          "code": "03"
      },
      {
          "name": "Saint John Parish",
          "code": "04"
      },
      {
          "name": "Saint Mary Parish",
          "code": "05"
      },
      {
          "name": "Saint Paul Parish",
          "code": "06"
      },
      {
          "name": "Saint Peter Parish",
          "code": "07"
      },
      {
          "name": "Saint Philip Parish",
          "code": "08"
      }
    ]
  },
  {
    "code2": "AR",
    "code3": "ARG",
    "name": "Argentina",
    "capital": "Buenos Aires",
    "region": "Americas",
    "subregion": "South America",
    "states": [
      {
          "name": "Autonomous City Of Buenos Aires",
          "code": "C"
      },
      {
          "name": "Buenos Aires Province",
          "code": "B"
      },
      {
          "name": "Catamarca Province",
          "code": "K"
      },
      {
          "name": "Chaco Province",
          "code": "H"
      },
      {
          "name": "Chubut Province",
          "code": "U"
      },
      {
          "name": "Córdoba Province",
          "code": "X"
      },
      {
          "name": "Corrientes",
          "code": "W"
      },
      {
          "name": "Entre Ríos Province",
          "code": "E"
      },
      {
          "name": "Formosa Province",
          "code": "P"
      },
      {
          "name": "Jujuy Province",
          "code": "Y"
      },
      {
          "name": "La Pampa",
          "code": "L"
      },
      {
          "name": "La Rioja Province",
          "code": "F"
      },
      {
          "name": "Mendoza",
          "code": "M"
      },
      {
          "name": "Misiones Province",
          "code": "N"
      },
      {
          "name": "Neuquén Province",
          "code": "Q"
      },
      {
          "name": "Río Negro Province",
          "code": "R"
      },
      {
          "name": "Salta Province",
          "code": "A"
      },
      {
          "name": "San Juan Province",
          "code": "J"
      },
      {
          "name": "San Luis Province",
          "code": "D"
      },
      {
          "name": "Santa Cruz Province",
          "code": "Z"
      },
      {
          "name": "Santa Fe Province",
          "code": "S"
      },
      {
          "name": "Santiago del Estero Province",
          "code": "G"
      },
      {
          "name": "Tierra del Fuego Province",
          "code": "V"
      },
      {
          "name": "Tucumán Province",
          "code": "T"
      }
    ]
  },
  {
    "code2": "AM",
    "code3": "ARM",
    "name": "Armenia",
    "capital": "Yerevan",
    "region": "Asia",
    "subregion": "Western Asia",
    "states": [
      {
          "name": "Aragatsotn Region",
          "code": "AG"
      },
      {
          "name": "Ararat Province",
          "code": "AR"
      },
      {
          "name": "Armavir Region",
          "code": "AV"
      },
      {
          "name": "Gegharkunik Province",
          "code": "GR"
      },
      {
          "name": "Kotayk Region",
          "code": "KT"
      },
      {
          "name": "Lori Region",
          "code": "LO"
      },
      {
          "name": "Shirak Region",
          "code": "SH"
      },
      {
          "name": "Syunik Province",
          "code": "SU"
      },
      {
          "name": "Tavush Region",
          "code": "TV"
      },
      {
          "name": "Vayots Dzor Region",
          "code": "VD"
      },
      {
          "name": "Yerevan",
          "code": "ER"
      }
    ]
  },
  {
    "code2": "AW",
    "code3": "ABW",
    "name": "Aruba",
    "capital": "Oranjestad",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": []
  },
  {
    "code2": "AU",
    "code3": "AUS",
    "name": "Australia",
    "capital": "Canberra",
    "region": "Oceania",
    "subregion": "Australia and New Zealand",
    "states": [
      {
          "name": "Australian Capital Territory",
          "code": "ACT"
      },
      {
          "name": "New South Wales",
          "code": "NSW"
      },
      {
          "name": "Northern Territory",
          "code": "NT"
      },
      {
          "name": "Queensland",
          "code": "QLD"
      },
      {
          "name": "South Australia",
          "code": "SA"
      },
      {
          "name": "Tasmania",
          "code": "TAS"
      },
      {
          "name": "Victoria",
          "code": "VIC"
      },
      {
          "name": "Western Australia",
          "code": "WA"
      }
    ]
  },
  {
    "code2": "AT",
    "code3": "AUT",
    "name": "Austria",
    "capital": "Vienna",
    "region": "Europe",
    "subregion": "Western Europe",
    "states": [
      {
          "name": "Burgenland",
          "code": "1"
      },
      {
          "name": "Carinthia",
          "code": "2"
      },
      {
          "name": "Lower Austria",
          "code": "3"
      },
      {
          "name": "Salzburg",
          "code": "5"
      },
      {
          "name": "Styria",
          "code": "6"
      },
      {
          "name": "Tyrol",
          "code": "7"
      },
      {
          "name": "Upper Austria",
          "code": "4"
      },
      {
          "name": "Vienna",
          "code": "9"
      },
      {
          "name": "Vorarlberg",
          "code": "8"
      }
    ]
  },
  {
    "code2": "AZ",
    "code3": "AZE",
    "name": "Azerbaijan",
    "capital": "Baku",
    "region": "Asia",
    "subregion": "Western Asia",
    "states": [
      {
          "name": "Absheron District",
          "code": "ABS"
      },
      {
          "name": "Agdam District",
          "code": "AGM"
      },
      {
          "name": "Agdash District",
          "code": "AGS"
      },
      {
          "name": "Aghjabadi District",
          "code": "AGC"
      },
      {
          "name": "Agstafa District",
          "code": "AGA"
      },
      {
          "name": "Agsu District",
          "code": "AGU"
      },
      {
          "name": "Astara District",
          "code": "AST"
      },
      {
          "name": "Babek District",
          "code": "BAB"
      },
      {
          "name": "Baku",
          "code": "BA"
      },
      {
          "name": "Balakan District",
          "code": "BAL"
      },
      {
          "name": "Barda District",
          "code": "BAR"
      },
      {
          "name": "Beylagan District",
          "code": "BEY"
      },
      {
          "name": "Bilasuvar District",
          "code": "BIL"
      },
      {
          "name": "Dashkasan District",
          "code": "DAS"
      },
      {
          "name": "Fizuli District",
          "code": "FUZ"
      },
      {
          "name": "Ganja",
          "code": "GA"
      },
      {
          "name": "Gədəbəy",
          "code": "GAD"
      },
      {
          "name": "Gobustan District",
          "code": "QOB"
      },
      {
          "name": "Goranboy District",
          "code": "GOR"
      },
      {
          "name": "Goychay",
          "code": "GOY"
      },
      {
          "name": "Goygol District",
          "code": "GYG"
      },
      {
          "name": "Hajigabul District",
          "code": "HAC"
      },
      {
          "name": "Imishli District",
          "code": "IMI"
      },
      {
          "name": "Ismailli District",
          "code": "ISM"
      },
      {
          "name": "Jabrayil District",
          "code": "CAB"
      },
      {
          "name": "Jalilabad District",
          "code": "CAL"
      },
      {
          "name": "Julfa District",
          "code": "CUL"
      },
      {
          "name": "Kalbajar District",
          "code": "KAL"
      },
      {
          "name": "Kangarli District",
          "code": "KAN"
      },
      {
          "name": "Khachmaz District",
          "code": "XAC"
      },
      {
          "name": "Khizi District",
          "code": "XIZ"
      },
      {
          "name": "Khojali District",
          "code": "XCI"
      },
      {
          "name": "Kurdamir District",
          "code": "KUR"
      },
      {
          "name": "Lachin District",
          "code": "LAC"
      },
      {
          "name": "Lankaran",
          "code": "LAN"
      },
      {
          "name": "Lankaran District",
          "code": "LA"
      },
      {
          "name": "Lerik District",
          "code": "LER"
      },
      {
          "name": "Martuni",
          "code": "XVD"
      },
      {
          "name": "Masally District",
          "code": "MAS"
      },
      {
          "name": "Mingachevir",
          "code": "MI"
      },
      {
          "name": "Nakhchivan Autonomous Republic",
          "code": "NX"
      },
      {
          "name": "Neftchala District",
          "code": "NEF"
      },
      {
          "name": "Oghuz District",
          "code": "OGU"
      },
      {
          "name": "Ordubad District",
          "code": "ORD"
      },
      {
          "name": "Qabala District",
          "code": "QAB"
      },
      {
          "name": "Qakh District",
          "code": "QAX"
      },
      {
          "name": "Qazakh District",
          "code": "QAZ"
      },
      {
          "name": "Quba District",
          "code": "QBA"
      },
      {
          "name": "Qubadli District",
          "code": "QBI"
      },
      {
          "name": "Qusar District",
          "code": "QUS"
      },
      {
          "name": "Saatly District",
          "code": "SAT"
      },
      {
          "name": "Sabirabad District",
          "code": "SAB"
      },
      {
          "name": "Sadarak District",
          "code": "SAD"
      },
      {
          "name": "Salyan District",
          "code": "SAL"
      },
      {
          "name": "Samukh District",
          "code": "SMX"
      },
      {
          "name": "Shabran District",
          "code": "SBN"
      },
      {
          "name": "Shahbuz District",
          "code": "SAH"
      },
      {
          "name": "Shaki",
          "code": "SA"
      },
      {
          "name": "Shaki District",
          "code": "SAK"
      },
      {
          "name": "Shamakhi District",
          "code": "SMI"
      },
      {
          "name": "Shamkir District",
          "code": "SKR"
      },
      {
          "name": "Sharur District",
          "code": "SAR"
      },
      {
          "name": "Shirvan",
          "code": "SR"
      },
      {
          "name": "Shusha District",
          "code": "SUS"
      },
      {
          "name": "Siazan District",
          "code": "SIY"
      },
      {
          "name": "Sumqayit",
          "code": "SM"
      },
      {
          "name": "Tartar District",
          "code": "TAR"
      },
      {
          "name": "Tovuz District",
          "code": "TOV"
      },
      {
          "name": "Ujar District",
          "code": "UCA"
      },
      {
          "name": "Yardymli District",
          "code": "YAR"
      },
      {
          "name": "Yevlakh",
          "code": "YE"
      },
      {
          "name": "Yevlakh District",
          "code": "YEV"
      },
      {
          "name": "Zangilan District",
          "code": "ZAN"
      },
      {
          "name": "Zaqatala District",
          "code": "ZAQ"
      },
      {
          "name": "Zardab District",
          "code": "ZAR"
      }
    ]
  },
  {
    "code2": "BS",
    "code3": "BHS",
    "name": "Bahamas The",
    "capital": "Nassau",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": [
      {
          "name": "Acklins",
          "code": "AK"
      },
      {
          "name": "Acklins and Crooked Islands",
          "code": "AC"
      },
      {
          "name": "Berry Islands",
          "code": "BY"
      },
      {
          "name": "Bimini",
          "code": "BI"
      },
      {
          "name": "Black Point",
          "code": "BP"
      },
      {
          "name": "Cat Island",
          "code": "CI"
      },
      {
          "name": "Central Abaco",
          "code": "CO"
      },
      {
          "name": "Central Andros",
          "code": "CS"
      },
      {
          "name": "Central Eleuthera",
          "code": "CE"
      },
      {
          "name": "Crooked Island",
          "code": "CK"
      },
      {
          "name": "East Grand Bahama",
          "code": "EG"
      },
      {
          "name": "Exuma",
          "code": "EX"
      },
      {
          "name": "Freeport",
          "code": "FP"
      },
      {
          "name": "Fresh Creek",
          "code": "FC"
      },
      {
          "name": "Governor's Harbour",
          "code": "GH"
      },
      {
          "name": "Grand Cay",
          "code": "GC"
      },
      {
          "name": "Green Turtle Cay",
          "code": "GT"
      },
      {
          "name": "Harbour Island",
          "code": "HI"
      },
      {
          "name": "High Rock",
          "code": "HR"
      },
      {
          "name": "Hope Town",
          "code": "HT"
      },
      {
          "name": "Inagua",
          "code": "IN"
      },
      {
          "name": "Kemps Bay",
          "code": "KB"
      },
      {
          "name": "Long Island",
          "code": "LI"
      },
      {
          "name": "Mangrove Cay",
          "code": "MC"
      },
      {
          "name": "Marsh Harbour",
          "code": "MH"
      },
      {
          "name": "Mayaguana District",
          "code": "MG"
      },
      {
          "name": "New Providence",
          "code": "NP"
      },
      {
          "name": "Nichollstown and Berry Islands",
          "code": "NB"
      },
      {
          "name": "North Abaco",
          "code": "NO"
      },
      {
          "name": "North Andros",
          "code": "NS"
      },
      {
          "name": "North Eleuthera",
          "code": "NE"
      },
      {
          "name": "Ragged Island",
          "code": "RI"
      },
      {
          "name": "Rock Sound",
          "code": "RS"
      },
      {
          "name": "Rum Cay District",
          "code": "RC"
      },
      {
          "name": "San Salvador and Rum Cay",
          "code": "SR"
      },
      {
          "name": "San Salvador Island",
          "code": "SS"
      },
      {
          "name": "Sandy Point",
          "code": "SP"
      },
      {
          "name": "South Abaco",
          "code": "SO"
      },
      {
          "name": "South Andros",
          "code": "SA"
      },
      {
          "name": "South Eleuthera",
          "code": "SE"
      },
      {
          "name": "Spanish Wells",
          "code": "SW"
      },
      {
          "name": "West Grand Bahama",
          "code": "WG"
      }
    ]
  },
  {
    "code2": "BH",
    "code3": "BHR",
    "name": "Bahrain",
    "capital": "Manama",
    "region": "Asia",
    "subregion": "Western Asia",
    "states": [
      {
          "name": "Capital Governorate",
          "code": "13"
      },
      {
          "name": "Central Governorate",
          "code": "16"
      },
      {
          "name": "Muharraq Governorate",
          "code": "15"
      },
      {
          "name": "Northern Governorate",
          "code": "17"
      },
      {
          "name": "Southern Governorate",
          "code": "14"
      }
    ]
  },
  {
    "code2": "BD",
    "code3": "BGD",
    "name": "Bangladesh",
    "capital": "Dhaka",
    "region": "Asia",
    "subregion": "Southern Asia",
    "states": [
      {
          "name": "Bagerhat District",
          "code": "05"
      },
      {
          "name": "Bahadia",
          "code": "33"
      },
      {
          "name": "Bandarban District",
          "code": "01"
      },
      {
          "name": "Barguna District",
          "code": "02"
      },
      {
          "name": "Barisal District",
          "code": "06"
      },
      {
          "name": "Barisal Division",
          "code": "A"
      },
      {
          "name": "Bhola District",
          "code": "07"
      },
      {
          "name": "Bogra District",
          "code": "03"
      },
      {
          "name": "Brahmanbaria District",
          "code": "04"
      },
      {
          "name": "Chandpur District",
          "code": "09"
      },
      {
          "name": "Chapai Nawabganj District",
          "code": "45"
      },
      {
          "name": "Chittagong District",
          "code": "10"
      },
      {
          "name": "Chittagong Division",
          "code": "B"
      },
      {
          "name": "Chuadanga District",
          "code": "12"
      },
      {
          "name": "Comilla District",
          "code": "08"
      },
      {
          "name": "Cox's Bazar District",
          "code": "11"
      },
      {
          "name": "Dhaka District",
          "code": "13"
      },
      {
          "name": "Dhaka Division",
          "code": "C"
      },
      {
          "name": "Dinajpur District",
          "code": "14"
      },
      {
          "name": "Faridpur District",
          "code": "15"
      },
      {
          "name": "Feni District",
          "code": "16"
      },
      {
          "name": "Gaibandha District",
          "code": "19"
      },
      {
          "name": "Gazipur District",
          "code": "18"
      },
      {
          "name": "Gopalganj District",
          "code": "17"
      },
      {
          "name": "Habiganj District",
          "code": "20"
      },
      {
          "name": "Jamalpur District",
          "code": "21"
      },
      {
          "name": "Jessore District",
          "code": "22"
      },
      {
          "name": "Jhalokati District",
          "code": "25"
      },
      {
          "name": "Jhenaidah District",
          "code": "23"
      },
      {
          "name": "Joypurhat District",
          "code": "24"
      },
      {
          "name": "Khagrachari District",
          "code": "29"
      },
      {
          "name": "Khulna District",
          "code": "27"
      },
      {
          "name": "Khulna Division",
          "code": "D"
      },
      {
          "name": "Kishoreganj District",
          "code": "26"
      },
      {
          "name": "Kurigram District",
          "code": "28"
      },
      {
          "name": "Kushtia District",
          "code": "30"
      },
      {
          "name": "Lakshmipur District",
          "code": "31"
      },
      {
          "name": "Lalmonirhat District",
          "code": "32"
      },
      {
          "name": "Madaripur District",
          "code": "36"
      },
      {
          "name": "Meherpur District",
          "code": "39"
      },
      {
          "name": "Moulvibazar District",
          "code": "38"
      },
      {
          "name": "Munshiganj District",
          "code": "35"
      },
      {
          "name": "Mymensingh District",
          "code": "34"
      },
      {
          "name": "Mymensingh Division",
          "code": "H"
      },
      {
          "name": "Naogaon District",
          "code": "48"
      },
      {
          "name": "Narail District",
          "code": "43"
      },
      {
          "name": "Narayanganj District",
          "code": "40"
      },
      {
          "name": "Natore District",
          "code": "44"
      },
      {
          "name": "Netrokona District",
          "code": "41"
      },
      {
          "name": "Nilphamari District",
          "code": "46"
      },
      {
          "name": "Noakhali District",
          "code": "47"
      },
      {
          "name": "Pabna District",
          "code": "49"
      },
      {
          "name": "Panchagarh District",
          "code": "52"
      },
      {
          "name": "Patuakhali District",
          "code": "51"
      },
      {
          "name": "Pirojpur District",
          "code": "50"
      },
      {
          "name": "Rajbari District",
          "code": "53"
      },
      {
          "name": "Rajshahi District",
          "code": "54"
      },
      {
          "name": "Rajshahi Division",
          "code": "E"
      },
      {
          "name": "Rangamati Hill District",
          "code": "56"
      },
      {
          "name": "Rangpur District",
          "code": "55"
      },
      {
          "name": "Rangpur Division",
          "code": "F"
      },
      {
          "name": "Satkhira District",
          "code": "58"
      },
      {
          "name": "Shariatpur District",
          "code": "62"
      },
      {
          "name": "Sherpur District",
          "code": "57"
      },
      {
          "name": "Sirajganj District",
          "code": "59"
      },
      {
          "name": "Sunamganj District",
          "code": "61"
      },
      {
          "name": "Sylhet District",
          "code": "60"
      },
      {
          "name": "Sylhet Division",
          "code": "G"
      },
      {
          "name": "Tangail District",
          "code": "63"
      },
      {
          "name": "Thakurgaon District",
          "code": "64"
      }
    ]
  },
  {
    "code2": "BB",
    "code3": "BRB",
    "name": "Barbados",
    "capital": "Bridgetown",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": [
      {
          "name": "Christ Church",
          "code": "01"
      },
      {
          "name": "Saint Andrew",
          "code": "02"
      },
      {
          "name": "Saint George",
          "code": "03"
      },
      {
          "name": "Saint James",
          "code": "04"
      },
      {
          "name": "Saint John",
          "code": "05"
      },
      {
          "name": "Saint Joseph",
          "code": "06"
      },
      {
          "name": "Saint Lucy",
          "code": "07"
      },
      {
          "name": "Saint Michael",
          "code": "08"
      },
      {
          "name": "Saint Peter",
          "code": "09"
      },
      {
          "name": "Saint Philip",
          "code": "10"
      },
      {
          "name": "Saint Thomas",
          "code": "11"
      }
    ]
  },
  {
    "code2": "BY",
    "code3": "BLR",
    "name": "Belarus",
    "capital": "Minsk",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "states": [
      {
          "name": "Brest Region",
          "code": "BR"
      },
      {
          "name": "Gomel Region",
          "code": "HO"
      },
      {
          "name": "Grodno Region",
          "code": "HR"
      },
      {
          "name": "Minsk",
          "code": "HM"
      },
      {
          "name": "Minsk Region",
          "code": "MI"
      },
      {
          "name": "Mogilev Region",
          "code": "MA"
      },
      {
          "name": "Vitebsk Region",
          "code": "VI"
      }
    ]
  },
  {
    "code2": "BE",
    "code3": "BEL",
    "name": "Belgium",
    "capital": "Brussels",
    "region": "Europe",
    "subregion": "Western Europe",
    "states": [
      {
          "name": "Antwerp",
          "code": "VAN"
      },
      {
          "name": "Brussels-Capital Region",
          "code": "BRU"
      },
      {
          "name": "East Flanders",
          "code": "VOV"
      },
      {
          "name": "Flanders",
          "code": "VLG"
      },
      {
          "name": "Flemish Brabant",
          "code": "VBR"
      },
      {
          "name": "Hainaut",
          "code": "WHT"
      },
      {
          "name": "Liège",
          "code": "WLG"
      },
      {
          "name": "Limburg",
          "code": "VLI"
      },
      {
          "name": "Luxembourg",
          "code": "WLX"
      },
      {
          "name": "Namur",
          "code": "WNA"
      },
      {
          "name": "Wallonia",
          "code": "WAL"
      },
      {
          "name": "Walloon Brabant",
          "code": "WBR"
      },
      {
          "name": "West Flanders",
          "code": "VWV"
      }
    ]
  },
  {
    "code2": "BZ",
    "code3": "BLZ",
    "name": "Belize",
    "capital": "Belmopan",
    "region": "Americas",
    "subregion": "Central America",
    "states": [
      {
          "name": "Belize District",
          "code": "BZ"
      },
      {
          "name": "Cayo District",
          "code": "CY"
      },
      {
          "name": "Corozal District",
          "code": "CZL"
      },
      {
          "name": "Orange Walk District",
          "code": "OW"
      },
      {
          "name": "Stann Creek District",
          "code": "SC"
      },
      {
          "name": "Toledo District",
          "code": "TOL"
      }
    ]
  },
  {
    "code2": "BJ",
    "code3": "BEN",
    "name": "Benin",
    "capital": "Porto-Novo",
    "region": "Africa",
    "subregion": "Western Africa",
    "states": [
      {
          "name": "Alibori Department",
          "code": "AL"
      },
      {
          "name": "Atakora Department",
          "code": "AK"
      },
      {
          "name": "Atlantique Department",
          "code": "AQ"
      },
      {
          "name": "Borgou Department",
          "code": "BO"
      },
      {
          "name": "Collines Department",
          "code": "CO"
      },
      {
          "name": "Donga Department",
          "code": "DO"
      },
      {
          "name": "Kouffo Department",
          "code": "KO"
      },
      {
          "name": "Littoral Department",
          "code": "LI"
      },
      {
          "name": "Mono Department",
          "code": "MO"
      },
      {
          "name": "Ouémé Department",
          "code": "OU"
      },
      {
          "name": "Plateau Department",
          "code": "PL"
      },
      {
          "name": "Zou Department",
          "code": "ZO"
      }
    ]
  },
  {
    "code2": "BM",
    "code3": "BMU",
    "name": "Bermuda",
    "capital": "Hamilton",
    "region": "Americas",
    "subregion": "Northern America",
    "states": [
      {
          "name": "Devonshire Parish",
          "code": "DEV"
      },
      {
          "name": "Hamilton Municipality",
          "code": "HAM"
      },
      {
          "name": "Hamilton Parish",
          "code": "HA"
      },
      {
          "name": "Paget Parish",
          "code": "PAG"
      },
      {
          "name": "Pembroke Parish",
          "code": "PEM"
      },
      {
          "name": "Saint George's Municipality",
          "code": "SG"
      },
      {
          "name": "Saint George's Parish",
          "code": "SGE"
      },
      {
          "name": "Sandys Parish",
          "code": "SAN"
      },
      {
          "name": "Smith's Parish,",
          "code": "SMI"
      },
      {
          "name": "Southampton Parish",
          "code": "SOU"
      },
      {
          "name": "Warwick Parish",
          "code": "WAR"
      }
    ]
  },
  {
    "code2": "BT",
    "code3": "BTN",
    "name": "Bhutan",
    "capital": "Thimphu",
    "region": "Asia",
    "subregion": "Southern Asia",
    "states": [
      {
          "name": "Bumthang District",
          "code": "33"
      },
      {
          "name": "Chukha District",
          "code": "12"
      },
      {
          "name": "Dagana District",
          "code": "22"
      },
      {
          "name": "Gasa District",
          "code": "GA"
      },
      {
          "name": "Haa District",
          "code": "13"
      },
      {
          "name": "Lhuntse District",
          "code": "44"
      },
      {
          "name": "Mongar District",
          "code": "42"
      },
      {
          "name": "Paro District",
          "code": "11"
      },
      {
          "name": "Pemagatshel District",
          "code": "43"
      },
      {
          "name": "Punakha District",
          "code": "23"
      },
      {
          "name": "Samdrup Jongkhar District",
          "code": "45"
      },
      {
          "name": "Samtse District",
          "code": "14"
      },
      {
          "name": "Sarpang District",
          "code": "31"
      },
      {
          "name": "Thimphu District",
          "code": "15"
      },
      {
          "name": "Trashigang District",
          "code": "41"
      },
      {
          "name": "Trongsa District",
          "code": "32"
      },
      {
          "name": "Tsirang District",
          "code": "21"
      },
      {
          "name": "Wangdue Phodrang District",
          "code": "24"
      },
      {
          "name": "Zhemgang District",
          "code": "34"
      }
    ]
  },
  {
    "code2": "BO",
    "code3": "BOL",
    "name": "Bolivia",
    "capital": "Sucre",
    "region": "Americas",
    "subregion": "South America",
    "states": [
      {
          "name": "Beni Department",
          "code": "B"
      },
      {
          "name": "Chuquisaca Department",
          "code": "H"
      },
      {
          "name": "Cochabamba Department",
          "code": "C"
      },
      {
          "name": "La Paz Department",
          "code": "L"
      },
      {
          "name": "Oruro Department",
          "code": "O"
      },
      {
          "name": "Pando Department",
          "code": "N"
      },
      {
          "name": "Potosí Department",
          "code": "P"
      },
      {
          "name": "Santa Cruz Department",
          "code": "S"
      },
      {
          "name": "Tarija Department",
          "code": "T"
      }
    ]
  },
  {
    "code2": "BQ",
    "code3": "BES",
    "name": "Bonaire",
    "capital": "Kralendijk",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": []
  },
  {
    "code2": "BA",
    "code3": "BIH",
    "name": "Bosnia and Herzegovina",
    "capital": "Sarajevo",
    "region": "Europe",
    "subregion": "Southern Europe",
    "states": [
      {
          "name": "Bosnian Podrinje Canton",
          "code": "05"
      },
      {
          "name": "Brčko District",
          "code": "BRC"
      },
      {
          "name": "Canton 10",
          "code": "10"
      },
      {
          "name": "Central Bosnia Canton",
          "code": "06"
      },
      {
          "name": "Federation of Bosnia and Herzegovina",
          "code": "BIH"
      },
      {
          "name": "Herzegovina-Neretva Canton",
          "code": "07"
      },
      {
          "name": "Posavina Canton",
          "code": "02"
      },
      {
          "name": "Republika Srpska",
          "code": "SRP"
      },
      {
          "name": "Sarajevo Canton",
          "code": "09"
      },
      {
          "name": "Tuzla Canton",
          "code": "03"
      },
      {
          "name": "Una-Sana Canton",
          "code": "01"
      },
      {
          "name": "West Herzegovina Canton",
          "code": "08"
      },
      {
          "name": "Zenica-Doboj Canton",
          "code": "04"
      }
    ]
  },
  {
    "code2": "BW",
    "code3": "BWA",
    "name": "Botswana",
    "capital": "Gaborone",
    "region": "Africa",
    "subregion": "Southern Africa",
    "states": [
      {
          "name": "Central District",
          "code": "CE"
      },
      {
          "name": "Ghanzi District",
          "code": "GH"
      },
      {
          "name": "Kgalagadi District",
          "code": "KG"
      },
      {
          "name": "Kgatleng District",
          "code": "KL"
      },
      {
          "name": "Kweneng District",
          "code": "KW"
      },
      {
          "name": "Ngamiland",
          "code": "NG"
      },
      {
          "name": "North-East District",
          "code": "NE"
      },
      {
          "name": "North-West District",
          "code": "NW"
      },
      {
          "name": "South-East District",
          "code": "SE"
      },
      {
          "name": "Southern District",
          "code": "SO"
      }
    ]
  },
  {
    "code2": "BV",
    "code3": "BVT",
    "name": "Bouvet Island",
    "capital": "",
    "region": "",
    "subregion": "",
    "states": []
  },
  {
    "code2": "BR",
    "code3": "BRA",
    "name": "Brazil",
    "capital": "Brasília",
    "region": "Americas",
    "subregion": "South America",
    "states": [
      {
          "name": "Acre",
          "code": "AC"
      },
      {
          "name": "Alagoas",
          "code": "AL"
      },
      {
          "name": "Amapá",
          "code": "AP"
      },
      {
          "name": "Amazonas",
          "code": "AM"
      },
      {
          "name": "Bahia",
          "code": "BA"
      },
      {
          "name": "Ceará",
          "code": "CE"
      },
      {
          "name": "Espírito Santo",
          "code": "ES"
      },
      {
          "name": "Federal District",
          "code": "DF"
      },
      {
          "name": "Goiás",
          "code": "GO"
      },
      {
          "name": "Maranhão",
          "code": "MA"
      },
      {
          "name": "Mato Grosso",
          "code": "MT"
      },
      {
          "name": "Mato Grosso do Sul",
          "code": "MS"
      },
      {
          "name": "Minas Gerais",
          "code": "MG"
      },
      {
          "name": "Pará",
          "code": "PA"
      },
      {
          "name": "Paraíba",
          "code": "PB"
      },
      {
          "name": "Paraná",
          "code": "PR"
      },
      {
          "name": "Pernambuco",
          "code": "PE"
      },
      {
          "name": "Piauí",
          "code": "PI"
      },
      {
          "name": "Rio de Janeiro",
          "code": "RJ"
      },
      {
          "name": "Rio Grande do Norte",
          "code": "RN"
      },
      {
          "name": "Rio Grande do Sul",
          "code": "RS"
      },
      {
          "name": "Rondônia",
          "code": "RO"
      },
      {
          "name": "Roraima",
          "code": "RR"
      },
      {
          "name": "Santa Catarina",
          "code": "SC"
      },
      {
          "name": "São Paulo",
          "code": "SP"
      },
      {
          "name": "Sergipe",
          "code": "SE"
      },
      {
          "name": "Tocantins",
          "code": "TO"
      }
    ]
  },
  {
    "code2": "IO",
    "code3": "IOT",
    "name": "British Indian Ocean Territory",
    "capital": "Diego Garcia",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "states": []
  },
  {
    "code2": "BN",
    "code3": "BRN",
    "name": "Brunei",
    "capital": "Diego Garcia",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "states": [
      {
          "name": "Belait District",
          "code": "BE"
      },
      {
          "name": "Brunei-Muara District",
          "code": "BM"
      },
      {
          "name": "Temburong District",
          "code": "TE"
      },
      {
          "name": "Tutong District",
          "code": "TU"
      }
  ]
  },
  {
    "code2": "BG",
    "code3": "BGR",
    "name": "Bulgaria",
    "capital": "Sofia",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "states": [
      {
          "name": "Blagoevgrad Province",
          "code": "01"
      },
      {
          "name": "Burgas Province",
          "code": "02"
      },
      {
          "name": "Dobrich Province",
          "code": "08"
      },
      {
          "name": "Gabrovo Province",
          "code": "07"
      },
      {
          "name": "Haskovo Province",
          "code": "26"
      },
      {
          "name": "Kardzhali Province",
          "code": "09"
      },
      {
          "name": "Kyustendil Province",
          "code": "10"
      },
      {
          "name": "Lovech Province",
          "code": "11"
      },
      {
          "name": "Montana Province",
          "code": "12"
      },
      {
          "name": "Pazardzhik Province",
          "code": "13"
      },
      {
          "name": "Pernik Province",
          "code": "14"
      },
      {
          "name": "Pleven Province",
          "code": "15"
      },
      {
          "name": "Plovdiv Province",
          "code": "16"
      },
      {
          "name": "Razgrad Province",
          "code": "17"
      },
      {
          "name": "Ruse Province",
          "code": "18"
      },
      {
          "name": "Shumen",
          "code": "27"
      },
      {
          "name": "Silistra Province",
          "code": "19"
      },
      {
          "name": "Sliven Province",
          "code": "20"
      },
      {
          "name": "Smolyan Province",
          "code": "21"
      },
      {
          "name": "Sofia City Province",
          "code": "22"
      },
      {
          "name": "Sofia Province",
          "code": "23"
      },
      {
          "name": "Stara Zagora Province",
          "code": "24"
      },
      {
          "name": "Targovishte Province",
          "code": "25"
      },
      {
          "name": "Varna Province",
          "code": "03"
      },
      {
          "name": "Veliko Tarnovo Province",
          "code": "04"
      },
      {
          "name": "Vidin Province",
          "code": "05"
      },
      {
          "name": "Vratsa Province",
          "code": "06"
      },
      {
          "name": "Yambol Province",
          "code": "28"
      }
    ]
  },
  {
    "code2": "BF",
    "code3": "BFA",
    "name": "Burkina Faso",
    "capital": "Ouagadougou",
    "region": "Africa",
    "subregion": "Western Africa",
    "states": [
      {
          "name": "Balé Province",
          "code": "BAL"
      },
      {
          "name": "Bam Province",
          "code": "BAM"
      },
      {
          "name": "Banwa Province",
          "code": "BAN"
      },
      {
          "name": "Bazèga Province",
          "code": "BAZ"
      },
      {
          "name": "Boucle du Mouhoun Region",
          "code": "01"
      },
      {
          "name": "Bougouriba Province",
          "code": "BGR"
      },
      {
          "name": "Boulgou",
          "code": "BLG"
      },
      {
          "name": "Cascades Region",
          "code": "02"
      },
      {
          "name": "Centre",
          "code": "03"
      },
      {
          "name": "Centre-Est Region",
          "code": "04"
      },
      {
          "name": "Centre-Nord Region",
          "code": "05"
      },
      {
          "name": "Centre-Ouest Region",
          "code": "06"
      },
      {
          "name": "Centre-Sud Region",
          "code": "07"
      },
      {
          "name": "Comoé Province",
          "code": "COM"
      },
      {
          "name": "Est Region",
          "code": "08"
      },
      {
          "name": "Ganzourgou Province",
          "code": "GAN"
      },
      {
          "name": "Gnagna Province",
          "code": "GNA"
      },
      {
          "name": "Gourma Province",
          "code": "GOU"
      },
      {
          "name": "Hauts-Bassins Region",
          "code": "09"
      },
      {
          "name": "Houet Province",
          "code": "HOU"
      },
      {
          "name": "Ioba Province",
          "code": "IOB"
      },
      {
          "name": "Kadiogo Province",
          "code": "KAD"
      },
      {
          "name": "Kénédougou Province",
          "code": "KEN"
      },
      {
          "name": "Komondjari Province",
          "code": "KMD"
      },
      {
          "name": "Kompienga Province",
          "code": "KMP"
      },
      {
          "name": "Kossi Province",
          "code": "KOS"
      },
      {
          "name": "Koulpélogo Province",
          "code": "KOP"
      },
      {
          "name": "Kouritenga Province",
          "code": "KOT"
      },
      {
          "name": "Kourwéogo Province",
          "code": "KOW"
      },
      {
          "name": "Léraba Province",
          "code": "LER"
      },
      {
          "name": "Loroum Province",
          "code": "LOR"
      },
      {
          "name": "Mouhoun",
          "code": "MOU"
      },
      {
          "name": "Nahouri Province",
          "code": "NAO"
      },
      {
          "name": "Namentenga Province",
          "code": "NAM"
      },
      {
          "name": "Nayala Province",
          "code": "NAY"
      },
      {
          "name": "Nord Region, Burkina Faso",
          "code": "10"
      },
      {
          "name": "Noumbiel Province",
          "code": "NOU"
      },
      {
          "name": "Oubritenga Province",
          "code": "OUB"
      },
      {
          "name": "Oudalan Province",
          "code": "OUD"
      },
      {
          "name": "Passoré Province",
          "code": "PAS"
      },
      {
          "name": "Plateau-Central Region",
          "code": "11"
      },
      {
          "name": "Poni Province",
          "code": "PON"
      },
      {
          "name": "Sahel Region",
          "code": "12"
      },
      {
          "name": "Sanguié Province",
          "code": "SNG"
      },
      {
          "name": "Sanmatenga Province",
          "code": "SMT"
      },
      {
          "name": "Séno Province",
          "code": "SEN"
      },
      {
          "name": "Sissili Province",
          "code": "SIS"
      },
      {
          "name": "Soum Province",
          "code": "SOM"
      },
      {
          "name": "Sourou Province",
          "code": "SOR"
      },
      {
          "name": "Sud-Ouest Region",
          "code": "13"
      },
      {
          "name": "Tapoa Province",
          "code": "TAP"
      },
      {
          "name": "Tuy Province",
          "code": "TUI"
      },
      {
          "name": "Yagha Province",
          "code": "YAG"
      },
      {
          "name": "Yatenga Province",
          "code": "YAT"
      },
      {
          "name": "Ziro Province",
          "code": "ZIR"
      },
      {
          "name": "Zondoma Province",
          "code": "ZON"
      },
      {
          "name": "Zoundwéogo Province",
          "code": "ZOU"
      }
    ]
  },
  {
    "code2": "UM",
    "code3": "UMI",
    "name": "United States Minor Outlying Islands",
    "capital": "",
    "region": "Americas",
    "subregion": "Northern America",
    "states": [
      {
        "code": "81",
        "name": "Baker Island",
        "subdivision": null
      },
      {
        "code": "84",
        "name": "Howland Island",
        "subdivision": null
      },
      {
        "code": "86",
        "name": "Jarvis Island",
        "subdivision": null
      },
      {
        "code": "67",
        "name": "Johnston Atoll",
        "subdivision": null
      },
      {
        "code": "89",
        "name": "Kingman Reef",
        "subdivision": null
      },
      {
        "code": "71",
        "name": "Midway Islands",
        "subdivision": null
      },
      {
        "code": "76",
        "name": "Navassa Island",
        "subdivision": null
      },
      {
        "code": "95",
        "name": "Palmyra Atoll",
        "subdivision": null
      },
      {
        "code": "79",
        "name": "Wake Island",
        "subdivision": null
      }
    ]
  },
  {
    "code2": "VG",
    "code3": "VGB",
    "name": "Virgin Islands (British)",
    "capital": "Road Town",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": []
  },
  {
    "code2": "VI",
    "code3": "VIR",
    "name": "Virgin Islands (U.S.)",
    "capital": "Charlotte Amalie",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": []
  },

  {
    "code2": "BI",
    "code3": "BDI",
    "name": "Burundi",
    "capital": "Bujumbura",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "states": [
      {
          "name": "Bubanza Province",
          "code": "BB"
      },
      {
          "name": "Bujumbura Mairie Province",
          "code": "BM"
      },
      {
          "name": "Bujumbura Rural Province",
          "code": "BL"
      },
      {
          "name": "Bururi Province",
          "code": "BR"
      },
      {
          "name": "Cankuzo Province",
          "code": "CA"
      },
      {
          "name": "Cibitoke Province",
          "code": "CI"
      },
      {
          "name": "Gitega Province",
          "code": "GI"
      },
      {
          "name": "Karuzi Province",
          "code": "KR"
      },
      {
          "name": "Kayanza Province",
          "code": "KY"
      },
      {
          "name": "Kirundo Province",
          "code": "KI"
      },
      {
          "name": "Makamba Province",
          "code": "MA"
      },
      {
          "name": "Muramvya Province",
          "code": "MU"
      },
      {
          "name": "Muyinga Province",
          "code": "MY"
      },
      {
          "name": "Mwaro Province",
          "code": "MW"
      },
      {
          "name": "Ngozi Province",
          "code": "NG"
      },
      {
          "name": "Rumonge Province",
          "code": "RM"
      },
      {
          "name": "Rutana Province",
          "code": "RT"
      },
      {
          "name": "Ruyigi Province",
          "code": "RY"
      }
    ]
  },
  {
    "code2": "KH",
    "code3": "KHM",
    "name": "Cambodia",
    "capital": "Phnom Penh",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "states": [
      {
          "name": "Banteay Meanchey Province",
          "code": "1"
      },
      {
          "name": "Battambang Province",
          "code": "2"
      },
      {
          "name": "Kampong Cham Province",
          "code": "3"
      },
      {
          "name": "Kampong Chhnang Province",
          "code": "4"
      },
      {
          "name": "Kampong Speu Province",
          "code": "5"
      },
      {
          "name": "Kampot Province",
          "code": "7"
      },
      {
          "name": "Kandal Province",
          "code": "8"
      },
      {
          "name": "Kep Province",
          "code": "23"
      },
      {
          "name": "Koh Kong Province",
          "code": "9"
      },
      {
          "name": "Kratié Province",
          "code": "10"
      },
      {
          "name": "Mondulkiri Province",
          "code": "11"
      },
      {
          "name": "Oddar Meanchey Province",
          "code": "22"
      },
      {
          "name": "Pailin Province",
          "code": "24"
      },
      {
          "name": "Phnom Penh",
          "code": "12"
      },
      {
          "name": "Preah Vihear Province",
          "code": "13"
      },
      {
          "name": "Prey Veng Province",
          "code": "14"
      },
      {
          "name": "Pursat Province",
          "code": "15"
      },
      {
          "name": "Ratanakiri Province",
          "code": "16"
      },
      {
          "name": "Siem Reap Province",
          "code": "17"
      },
      {
          "name": "Sihanoukville Province",
          "code": "18"
      },
      {
          "name": "Stung Treng Province",
          "code": "19"
      },
      {
          "name": "Svay Rieng Province",
          "code": "20"
      },
      {
          "name": "Takéo Province",
          "code": "21"
      }
    ]
  },
  {
    "code2": "CM",
    "code3": "CMR",
    "name": "Cameroon",
    "capital": "Yaoundé",
    "region": "Africa",
    "subregion": "Middle Africa",
    "states": [
      {
          "name": "Adamawa",
          "code": "AD"
      },
      {
          "name": "Centre",
          "code": "CE"
      },
      {
          "name": "East",
          "code": "ES"
      },
      {
          "name": "Far North",
          "code": "EN"
      },
      {
          "name": "Littoral",
          "code": "LT"
      },
      {
          "name": "North",
          "code": "NO"
      },
      {
          "name": "Northwest",
          "code": "NW"
      },
      {
          "name": "South",
          "code": "SU"
      },
      {
          "name": "Southwest",
          "code": "SW"
      },
      {
          "name": "West",
          "code": "OU"
      }
    ]
  },
  {
    "code2": "CA",
    "code3": "CAN",
    "name": "Canada",
    "capital": "Ottawa",
    "region": "Americas",
    "subregion": "Northern America",
    "states": [
      {
          "name": "Alberta",
          "code": "AB"
      },
      {
          "name": "British Columbia",
          "code": "BC"
      },
      {
          "name": "Manitoba",
          "code": "MB"
      },
      {
          "name": "New Brunswick",
          "code": "NB"
      },
      {
          "name": "Newfoundland and Labrador",
          "code": "NL"
      },
      {
          "name": "Northwest Territories",
          "code": "NT"
      },
      {
          "name": "Nova Scotia",
          "code": "NS"
      },
      {
          "name": "Nunavut",
          "code": "NU"
      },
      {
          "name": "Ontario",
          "code": "ON"
      },
      {
          "name": "Prince Edward Island",
          "code": "PE"
      },
      {
          "name": "Quebec",
          "code": "QC"
      },
      {
          "name": "Saskatchewan",
          "code": "SK"
      },
      {
          "name": "Yukon",
          "code": "YT"
      }
    ]
  },
  {
    "code2": "CV",
    "code3": "CPV",
    "name": "Cape Verde",
    "capital": "Praia",
    "region": "Africa",
    "subregion": "Western Africa",
    "states": [
      {
          "name": "Barlavento Islands",
          "code": "B"
      },
      {
          "name": "Boa Vista",
          "code": "BV"
      },
      {
          "name": "Brava",
          "code": "BR"
      },
      {
          "name": "Maio Municipality",
          "code": "MA"
      },
      {
          "name": "Mosteiros",
          "code": "MO"
      },
      {
          "name": "Paul",
          "code": "PA"
      },
      {
          "name": "Porto Novo",
          "code": "PN"
      },
      {
          "name": "Praia",
          "code": "PR"
      },
      {
          "name": "Ribeira Brava Municipality",
          "code": "RB"
      },
      {
          "name": "Ribeira Grande",
          "code": "RG"
      },
      {
          "name": "Ribeira Grande de Santiago",
          "code": "RS"
      },
      {
          "name": "Sal",
          "code": "SL"
      },
      {
          "name": "Santa Catarina",
          "code": "CA"
      },
      {
          "name": "Santa Catarina do Fogo",
          "code": "CF"
      },
      {
          "name": "Santa Cruz",
          "code": "CR"
      },
      {
          "name": "São Domingos",
          "code": "SD"
      },
      {
          "name": "São Filipe",
          "code": "SF"
      },
      {
          "name": "São Lourenço dos Órgãos",
          "code": "SO"
      },
      {
          "name": "São Miguel",
          "code": "SM"
      },
      {
          "name": "São Vicente",
          "code": "SV"
      },
      {
          "name": "Sotavento Islands",
          "code": "S"
      },
      {
          "name": "Tarrafal",
          "code": "TA"
      },
      {
          "name": "Tarrafal de São Nicolau",
          "code": "TS"
      }
    ]
  },
  {
    "code2": "KY",
    "code3": "CYM",
    "name": "Cayman Islands",
    "capital": "George Town",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": []
  },
  {
    "code2": "CF",
    "code3": "CAF",
    "name": "Central African Republic",
    "capital": "Bangui",
    "region": "Africa",
    "subregion": "Middle Africa",
    "states": [
      {
          "name": "Bamingui-Bangoran Prefecture",
          "code": "BB"
      },
      {
          "name": "Bangui",
          "code": "BGF"
      },
      {
          "name": "Basse-Kotto Prefecture",
          "code": "BK"
      },
      {
          "name": "Haut-Mbomou Prefecture",
          "code": "HM"
      },
      {
          "name": "Haute-Kotto Prefecture",
          "code": "HK"
      },
      {
          "name": "Kémo Prefecture",
          "code": "KG"
      },
      {
          "name": "Lobaye Prefecture",
          "code": "LB"
      },
      {
          "name": "Mambéré-Kadéï",
          "code": "HS"
      },
      {
          "name": "Mbomou Prefecture",
          "code": "MB"
      },
      {
          "name": "Nana-Grébizi Economic Prefecture",
          "code": "KB"
      },
      {
          "name": "Nana-Mambéré Prefecture",
          "code": "NM"
      },
      {
          "name": "Ombella-M'Poko Prefecture",
          "code": "MP"
      },
      {
          "name": "Ouaka Prefecture",
          "code": "UK"
      },
      {
          "name": "Ouham Prefecture",
          "code": "AC"
      },
      {
          "name": "Ouham-Pendé Prefecture",
          "code": "OP"
      },
      {
          "name": "Sangha-Mbaéré",
          "code": "SE"
      },
      {
          "name": "Vakaga Prefecture",
          "code": "VK"
      }
    ]
  },
  {
    "code2": "TD",
    "code3": "TCD",
    "name": "Chad",
    "capital": "N'Djamena",
    "region": "Africa",
    "subregion": "Middle Africa",
    "states": [
      {
          "name": "Bahr el Gazel",
          "code": "BG"
      },
      {
          "name": "Batha Region",
          "code": "BA"
      },
      {
          "name": "Borkou",
          "code": "BO"
      },
      {
          "name": "Ennedi Region",
          "code": "EN"
      },
      {
          "name": "Ennedi-Est",
          "code": "EE"
      },
      {
          "name": "Ennedi-Ouest",
          "code": "EO"
      },
      {
          "name": "Guéra Region",
          "code": "GR"
      },
      {
          "name": "Hadjer-Lamis",
          "code": "HL"
      },
      {
          "name": "Kanem Region",
          "code": "KA"
      },
      {
          "name": "Lac Region",
          "code": "LC"
      },
      {
          "name": "Logone Occidental Region",
          "code": "LO"
      },
      {
          "name": "Logone Oriental Region",
          "code": "LR"
      },
      {
          "name": "Mandoul Region",
          "code": "MA"
      },
      {
          "name": "Mayo-Kebbi Est Region",
          "code": "ME"
      },
      {
          "name": "Mayo-Kebbi Ouest Region",
          "code": "MO"
      },
      {
          "name": "Moyen-Chari Region",
          "code": "MC"
      },
      {
          "name": "N'Djamena",
          "code": "ND"
      },
      {
          "name": "Ouaddaï Region",
          "code": "OD"
      },
      {
          "name": "Salamat Region",
          "code": "SA"
      },
      {
          "name": "Sila Region",
          "code": "SI"
      },
      {
          "name": "Tandjilé Region",
          "code": "TA"
      },
      {
          "name": "Tibesti Region",
          "code": "TI"
      },
      {
          "name": "Wadi Fira Region",
          "code": "WF"
      }
    ]
  },
  {
    "code2": "CL",
    "code3": "CHL",
    "name": "Chile",
    "capital": "Santiago",
    "region": "Americas",
    "subregion": "South America",
    "states": [
      {
          "name": "Antofagasta Region",
          "code": "AN"
      },
      {
          "name": "Araucanía Region",
          "code": "AR"
      },
      {
          "name": "Arica y Parinacota Region",
          "code": "AP"
      },
      {
          "name": "Atacama Region",
          "code": "AT"
      },
      {
          "name": "Aysén Region",
          "code": "AI"
      },
      {
          "name": "Bío Bío Region",
          "code": "BI"
      },
      {
          "name": "Coquimbo Region",
          "code": "CO"
      },
      {
          "name": "Los Lagos Region",
          "code": "LL"
      },
      {
          "name": "Los Ríos Region",
          "code": "LR"
      },
      {
          "name": "Magellan and the Chilean Antarctic Region",
          "code": "MA"
      },
      {
          "name": "Maule Region",
          "code": "ML"
      },
      {
          "name": "Ñuble Region",
          "code": "NB"
      },
      {
          "name": "O'Higgins",
          "code": "LI"
      },
      {
          "name": "Santiago Metropolitan Region",
          "code": "RM"
      },
      {
          "name": "Tarapacá Region",
          "code": "TA"
      },
      {
          "name": "Valparaíso",
          "code": "VS"
      }
    ]
  },
  {
    "code2": "CN",
    "code3": "CHN",
    "name": "China",
    "capital": "Beijing",
    "region": "Asia",
    "subregion": "Eastern Asia",
    "states": [
      {
          "name": "Anhui",
          "code": "AH"
      },
      {
          "name": "Beijing",
          "code": "BJ"
      },
      {
          "name": "Chongqing",
          "code": "CQ"
      },
      {
          "name": "Fujian",
          "code": "FJ"
      },
      {
          "name": "Gansu",
          "code": "GS"
      },
      {
          "name": "Guangdong",
          "code": "GD"
      },
      {
          "name": "Guangxi Zhuang Autonomous Region",
          "code": "GX"
      },
      {
          "name": "Guizhou",
          "code": "GZ"
      },
      {
          "name": "Hainan",
          "code": "HI"
      },
      {
          "name": "Hebei",
          "code": "HE"
      },
      {
          "name": "Heilongjiang",
          "code": "HL"
      },
      {
          "name": "Henan",
          "code": "HA"
      },
      {
          "name": "Hong Kong",
          "code": "HK"
      },
      {
          "name": "Hubei",
          "code": "HB"
      },
      {
          "name": "Hunan",
          "code": "HN"
      },
      {
          "name": "Inner Mongolia",
          "code": "NM"
      },
      {
          "name": "Jiangsu",
          "code": "JS"
      },
      {
          "name": "Jiangxi",
          "code": "JX"
      },
      {
          "name": "Jilin",
          "code": "JL"
      },
      {
          "name": "Keelung",
          "code": "TW-KEE"
      },
      {
          "name": "Liaoning",
          "code": "LN"
      },
      {
          "name": "Macau",
          "code": "MO"
      },
      {
          "name": "Ningxia Hui Autonomous Region",
          "code": "NX"
      },
      {
          "name": "Qinghai",
          "code": "QH"
      },
      {
          "name": "Shaanxi",
          "code": "SN"
      },
      {
          "name": "Shandong",
          "code": "SD"
      },
      {
          "name": "Shanghai",
          "code": "SH"
      },
      {
          "name": "Shanxi",
          "code": "SX"
      },
      {
          "name": "Sichuan",
          "code": "SC"
      },
      {
          "name": "Taiwan Province, People's Republic of China",
          "code": "TW"
      },
      {
          "name": "Tibet Autonomous Region",
          "code": "XZ"
      },
      {
          "name": "Xinjiang",
          "code": "XJ"
      },
      {
          "name": "Yunnan",
          "code": "YN"
      },
      {
          "name": "Zhejiang",
          "code": "ZJ"
      }
    ]
  },
  {
    "code2": "CX",
    "code3": "CXR",
    "name": "Christmas Island",
    "capital": "Flying Fish Cove",
    "region": "Oceania",
    "subregion": "Australia and New Zealand",
    "states": []
  },
  {
    "code2": "CC",
    "code3": "CCK",
    "name": "Cocos (Keeling) Islands",
    "capital": "West Island",
    "region": "Oceania",
    "subregion": "Australia and New Zealand",
    "states": []
  },
  {
    "code2": "CO",
    "code3": "COL",
    "name": "Colombia",
    "capital": "Bogotá",
    "region": "Americas",
    "subregion": "South America",
    "states": [
      {
          "name": "Amazonas Department",
          "code": "AMA"
      },
      {
          "name": "Antioquia Department",
          "code": "ANT"
      },
      {
          "name": "Arauca Department",
          "code": "ARA"
      },
      {
          "name": "Archipelago of Saint Andréws, Providence and Saint Catalina",
          "code": "SAP"
      },
      {
          "name": "Atlántico Department",
          "code": "ATL"
      },
      {
          "name": "Bolívar Department",
          "code": "BOL"
      },
      {
          "name": "Boyacá Department",
          "code": "BOY"
      },
      {
          "name": "Caldas Department",
          "code": "CAL"
      },
      {
          "name": "Caquetá Department",
          "code": "CAQ"
      },
      {
          "name": "Casanare Department",
          "code": "CAS"
      },
      {
          "name": "Cauca Department",
          "code": "CAU"
      },
      {
          "name": "Cesar Department",
          "code": "CES"
      },
      {
          "name": "Chocó Department",
          "code": "CHO"
      },
      {
          "name": "Córdoba Department",
          "code": "COR"
      },
      {
          "name": "Cundinamarca Department",
          "code": "CUN"
      },
      {
          "name": "Guainía Department",
          "code": "GUA"
      },
      {
          "name": "Guaviare Department",
          "code": "GUV"
      },
      {
          "name": "Huila Department",
          "code": "HUI"
      },
      {
          "name": "La Guajira Department",
          "code": "LAG"
      },
      {
          "name": "Magdalena Department",
          "code": "MAG"
      },
      {
          "name": "Meta",
          "code": "MET"
      },
      {
          "name": "Nariño Department",
          "code": "NAR"
      },
      {
          "name": "Norte de Santander Department",
          "code": "NSA"
      },
      {
          "name": "Putumayo Department",
          "code": "PUT"
      },
      {
          "name": "Quindío Department",
          "code": "QUI"
      },
      {
          "name": "Risaralda Department",
          "code": "RIS"
      },
      {
          "name": "Santander Department",
          "code": "SAN"
      },
      {
          "name": "Sucre Department",
          "code": "SUC"
      },
      {
          "name": "Tolima Department",
          "code": "TOL"
      },
      {
          "name": "Valle del Cauca Department",
          "code": "VAC"
      },
      {
          "name": "Vaupés Department",
          "code": "VAU"
      },
      {
          "name": "Vichada Department",
          "code": "VID"
      }
    ]
  },
  {
    "code2": "KM",
    "code3": "COM",
    "name": "Comoros",
    "capital": "Moroni",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "states": [
      {
          "name": "Anjouan",
          "code": "A"
      },
      {
          "name": "Grande Comore",
          "code": "G"
      },
      {
          "name": "Mohéli",
          "code": "M"
      }
    ]
  },
  {
    "code2": "CG",
    "code3": "COG",
    "name": "Republic of the Congo",
    "capital": "Brazzaville",
    "region": "Africa",
    "subregion": "Middle Africa",
    "states": [
      {
          "name": "Bouenza Department",
          "code": "11"
      },
      {
          "name": "Brazzaville",
          "code": "BZV"
      },
      {
          "name": "Cuvette Department",
          "code": "8"
      },
      {
          "name": "Cuvette-Ouest Department",
          "code": "15"
      },
      {
          "name": "Kouilou Department",
          "code": "5"
      },
      {
          "name": "Lékoumou Department",
          "code": "2"
      },
      {
          "name": "Likouala Department",
          "code": "7"
      },
      {
          "name": "Niari Department",
          "code": "9"
      },
      {
          "name": "Plateaux Department",
          "code": "14"
      },
      {
          "name": "Pointe-Noire",
          "code": "16"
      },
      {
          "name": "Pool Department",
          "code": "12"
      },
      {
          "name": "Sangha Department",
          "code": "13"
      }
    ]
  },
  {
    "code2": "CD",
    "code3": "COD",
    "name": "Democratic Republic of the Congo",
    "capital": "Kinshasa",
    "region": "Africa",
    "subregion": "Middle Africa",
    "states": [
      {
          "name": "Bandundu Province",
          "code": "BN"
      },
      {
          "name": "Bas-Congo province",
          "code": "BC"
      },
      {
          "name": "Bas-Uele",
          "code": "BU"
      },
      {
          "name": "Équateur",
          "code": "EQ"
      },
      {
          "name": "Haut-Katanga Province",
          "code": "HK"
      },
      {
          "name": "Haut-Lomami District",
          "code": "HL"
      },
      {
          "name": "Haut-Uele",
          "code": "HU"
      },
      {
          "name": "Ituri Interim Administration",
          "code": "IT"
      },
      {
          "name": "Kasaï District",
          "code": "KS"
      },
      {
          "name": "Kasaï-Occidental",
          "code": "KW"
      },
      {
          "name": "Kasaï-Oriental",
          "code": "KE"
      },
      {
          "name": "Katanga Province",
          "code": "KA"
      },
      {
          "name": "Kinshasa",
          "code": "KN"
      },
      {
          "name": "Kwango District",
          "code": "KG"
      },
      {
          "name": "Kwilu District",
          "code": "KL"
      },
      {
          "name": "Lomami Province",
          "code": "LO"
      },
      {
          "name": "Mai-Ndombe Province",
          "code": "MN"
      },
      {
          "name": "Maniema",
          "code": "MA"
      },
      {
          "name": "Mongala District",
          "code": "MO"
      },
      {
          "name": "Nord-Ubangi District",
          "code": "NU"
      },
      {
          "name": "North Kivu",
          "code": "NK"
      },
      {
          "name": "Orientale Province",
          "code": "OR"
      },
      {
          "name": "Sankuru District",
          "code": "SA"
      },
      {
          "name": "South Kivu",
          "code": "SK"
      },
      {
          "name": "Sud-Ubangi",
          "code": "SU"
      },
      {
          "name": "Tanganyika Province",
          "code": "TA"
      },
      {
          "name": "Tshopo District",
          "code": "TO"
      },
      {
          "name": "Tshuapa District",
          "code": "TU"
      }
    ]
  },
  {
    "code2": "CK",
    "code3": "COK",
    "name": "Cook Islands",
    "capital": "Avarua",
    "region": "Oceania",
    "subregion": "Polynesia",
    "states": []
  },
  {
    "code2": "CR",
    "code3": "CRI",
    "name": "Costa Rica",
    "capital": "San José",
    "region": "Americas",
    "subregion": "Central America",
    "states": [
      {
          "name": "Alajuela Province",
          "code": "A"
      },
      {
          "name": "Guanacaste Province",
          "code": "G"
      },
      {
          "name": "Heredia Province",
          "code": "H"
      },
      {
          "name": "Limón Province",
          "code": "L"
      },
      {
          "name": "Provincia de Cartago",
          "code": "C"
      },
      {
          "name": "Puntarenas Province",
          "code": "P"
      },
      {
          "name": "San José Province",
          "code": "SJ"
      }
    ]
  },
  {
    "code2": "CI",
    "code3": "CIV",
    "name": "Cote D'Ivoire (Ivory Coast)",
    "capital": "Yamoussoukro",
    "region": "Africa",
    "subregion": "Western Africa",
    "states": [
      {
          "name": "Abidjan",
          "code": "AB"
      },
      {
          "name": "Agnéby",
          "code": "16"
      },
      {
          "name": "Bafing Region",
          "code": "17"
      },
      {
          "name": "Bas-Sassandra District",
          "code": "BS"
      },
      {
          "name": "Bas-Sassandra Region",
          "code": "09"
      },
      {
          "name": "Comoé District",
          "code": "CM"
      },
      {
          "name": "Denguélé District",
          "code": "DN"
      },
      {
          "name": "Denguélé Region",
          "code": "10"
      },
      {
          "name": "Dix-Huit Montagnes",
          "code": "06"
      },
      {
          "name": "Fromager",
          "code": "18"
      },
      {
          "name": "Gôh-Djiboua District",
          "code": "GD"
      },
      {
          "name": "Haut-Sassandra",
          "code": "02"
      },
      {
          "name": "Lacs District",
          "code": "LC"
      },
      {
          "name": "Lacs Region",
          "code": "07"
      },
      {
          "name": "Lagunes District",
          "code": "LG"
      },
      {
          "name": "Lagunes region",
          "code": "01"
      },
      {
          "name": "Marahoué Region",
          "code": "12"
      },
      {
          "name": "Montagnes District",
          "code": "MG"
      },
      {
          "name": "Moyen-Cavally",
          "code": "19"
      },
      {
          "name": "Moyen-Comoé",
          "code": "05"
      },
      {
          "name": "N'zi-Comoé",
          "code": "11"
      },
      {
          "name": "Sassandra-Marahoué District",
          "code": "SM"
      },
      {
          "name": "Savanes Region",
          "code": "03"
      },
      {
          "name": "Sud-Bandama",
          "code": "15"
      },
      {
          "name": "Sud-Comoé",
          "code": "13"
      },
      {
          "name": "Vallée du Bandama District",
          "code": "VB"
      },
      {
          "name": "Vallée du Bandama Region",
          "code": "04"
      },
      {
          "name": "Woroba District",
          "code": "WR"
      },
      {
          "name": "Worodougou",
          "code": "14"
      },
      {
          "name": "Yamoussoukro",
          "code": "YM"
      },
      {
          "name": "Zanzan Region",
          "code": "ZZ"
      }
    ]
  },
  {
    "code2": "HR",
    "code3": "HRV",
    "name": "Croatia",
    "capital": "Zagreb",
    "region": "Europe",
    "subregion": "Southern Europe",
    "states": [
      {
          "name": "Bjelovar-Bilogora County",
          "code": "07"
      },
      {
          "name": "Brod-Posavina County",
          "code": "12"
      },
      {
          "name": "Dubrovnik-Neretva County",
          "code": "19"
      },
      {
          "name": "Istria County",
          "code": "18"
      },
      {
          "name": "Koprivnica-Križevci County",
          "code": "06"
      },
      {
          "name": "Krapina-Zagorje County",
          "code": "02"
      },
      {
          "name": "Lika-Senj County",
          "code": "09"
      },
      {
          "name": "Međimurje County",
          "code": "20"
      },
      {
          "name": "Osijek-Baranja County",
          "code": "14"
      },
      {
          "name": "Požega-Slavonia County",
          "code": "11"
      },
      {
          "name": "Primorje-Gorski Kotar County",
          "code": "08"
      },
      {
          "name": "Šibenik-Knin County",
          "code": "15"
      },
      {
          "name": "Sisak-Moslavina County",
          "code": "03"
      },
      {
          "name": "Split-Dalmatia County",
          "code": "17"
      },
      {
          "name": "Varaždin County",
          "code": "05"
      },
      {
          "name": "Virovitica-Podravina County",
          "code": "10"
      },
      {
          "name": "Vukovar-Syrmia County",
          "code": "16"
      },
      {
          "name": "Zadar County",
          "code": "13"
      },
      {
          "name": "Zagreb",
          "code": "21"
      },
      {
          "name": "Zagreb County",
          "code": "01"
      }
    ]
  },
  {
    "code2": "CU",
    "code3": "CUB",
    "name": "Cuba",
    "capital": "Havana",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": [
      {
          "name": "Artemisa Province",
          "code": "15"
      },
      {
          "name": "Camagüey Province",
          "code": "09"
      },
      {
          "name": "Ciego de Ávila Province",
          "code": "08"
      },
      {
          "name": "Cienfuegos Province",
          "code": "06"
      },
      {
          "name": "Granma Province",
          "code": "12"
      },
      {
          "name": "Guantánamo Province",
          "code": "14"
      },
      {
          "name": "Havana Province",
          "code": "03"
      },
      {
          "name": "Holguín Province",
          "code": "11"
      },
      {
          "name": "Isla de la Juventud",
          "code": "99"
      },
      {
          "name": "Las Tunas Province",
          "code": "10"
      },
      {
          "name": "Matanzas Province",
          "code": "04"
      },
      {
          "name": "Mayabeque Province",
          "code": "16"
      },
      {
          "name": "Pinar del Río Province",
          "code": "01"
      },
      {
          "name": "Sancti Spíritus Province",
          "code": "07"
      },
      {
          "name": "Santiago de Cuba Province",
          "code": "13"
      },
      {
          "name": "Villa Clara Province",
          "code": "05"
      }
    ]
  },
  {
    "code2": "CW",
    "code3": "CUW",
    "name": "Curaçao",
    "capital": "Willemstad",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": []
  },
  {
    "code2": "CY",
    "code3": "CYP",
    "name": "Cyprus",
    "capital": "Nicosia",
    "region": "Europe",
    "subregion": "Southern Europe",
    "states": [
      {
          "name": "Famagusta District",
          "code": "04"
      },
      {
          "name": "Kyrenia District",
          "code": "06"
      },
      {
          "name": "Larnaca District",
          "code": "03"
      },
      {
          "name": "Limassol District",
          "code": "02"
      },
      {
          "name": "Nicosia District",
          "code": "01"
      },
      {
          "name": "Paphos District",
          "code": "05"
      }
    ]
  },
  {
    "code2": "CZ",
    "code3": "CZE",
    "name": "Czech Republic",
    "capital": "Prague",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "states": [
      {
          "name": "Benešov District",
          "code": "201"
      },
      {
          "name": "Beroun District",
          "code": "202"
      },
      {
          "name": "Blansko District",
          "code": "641"
      },
      {
          "name": "Břeclav District",
          "code": "644"
      },
      {
          "name": "Brno-City District",
          "code": "642"
      },
      {
          "name": "Brno-Country District",
          "code": "643"
      },
      {
          "name": "Bruntál District",
          "code": "801"
      },
      {
          "name": "Central Bohemian Region",
          "code": "20"
      },
      {
          "name": "Česká Lípa District",
          "code": "511"
      },
      {
          "name": "České Budějovice District",
          "code": "311"
      },
      {
          "name": "Český Krumlov District",
          "code": "312"
      },
      {
          "name": "Cheb District",
          "code": "411"
      },
      {
          "name": "Chomutov District",
          "code": "422"
      },
      {
          "name": "Chrudim District",
          "code": "531"
      },
      {
          "name": "Děčín District",
          "code": "421"
      },
      {
          "name": "Domažlice District",
          "code": "321"
      },
      {
          "name": "Frýdek-Místek District",
          "code": "802"
      },
      {
          "name": "Havlíčkův Brod District",
          "code": "631"
      },
      {
          "name": "Hodonín District",
          "code": "645"
      },
      {
          "name": "Horní Počernice",
          "code": "120"
      },
      {
          "name": "Hradec Králové District",
          "code": "521"
      },
      {
          "name": "Hradec Králové Region",
          "code": "52"
      },
      {
          "name": "Jablonec nad Nisou District",
          "code": "512"
      },
      {
          "name": "Jeseník District",
          "code": "711"
      },
      {
          "name": "Jičín District",
          "code": "522"
      },
      {
          "name": "Jihlava District",
          "code": "632"
      },
      {
          "name": "Jindřichův Hradec District",
          "code": "313"
      },
      {
          "name": "Karlovy Vary District",
          "code": "412"
      },
      {
          "name": "Karlovy Vary Region",
          "code": "41"
      },
      {
          "name": "Karviná District",
          "code": "803"
      },
      {
          "name": "Kladno District",
          "code": "203"
      },
      {
          "name": "Klatovy District",
          "code": "322"
      },
      {
          "name": "Kolín District",
          "code": "204"
      },
      {
          "name": "Kroměříž District",
          "code": "721"
      },
      {
          "name": "Liberec District",
          "code": "513"
      },
      {
          "name": "Liberec Region",
          "code": "51"
      },
      {
          "name": "Litoměřice District",
          "code": "423"
      },
      {
          "name": "Louny District",
          "code": "424"
      },
      {
          "name": "Mělník District",
          "code": "206"
      },
      {
          "name": "Mladá Boleslav District",
          "code": "207"
      },
      {
          "name": "Moravian-Silesian Region",
          "code": "80"
      },
      {
          "name": "Most District",
          "code": "425"
      },
      {
          "name": "Náchod District",
          "code": "523"
      },
      {
          "name": "Nový Jičín District",
          "code": "804"
      },
      {
          "name": "Nymburk District",
          "code": "208"
      },
      {
          "name": "Olomouc District",
          "code": "712"
      },
      {
          "name": "Olomouc Region",
          "code": "71"
      },
      {
          "name": "Opava District",
          "code": "805"
      },
      {
          "name": "Ostrava-City District",
          "code": "806"
      },
      {
          "name": "Pardubice District",
          "code": "532"
      },
      {
          "name": "Pardubice Region",
          "code": "53"
      },
      {
          "name": "Pelhřimov District",
          "code": "633"
      },
      {
          "name": "Písek District",
          "code": "314"
      },
      {
          "name": "Plzeň Region",
          "code": "32"
      },
      {
          "name": "Plzeň-City District",
          "code": "323"
      },
      {
          "name": "Plzeň-North District",
          "code": "325"
      },
      {
          "name": "Plzeň-South District",
          "code": "324"
      },
      {
          "name": "Prachatice District",
          "code": "315"
      },
      {
          "name": "Prague",
          "code": "10"
      },
      {
          "name": "Prague 1",
          "code": "101"
      },
      {
          "name": "Prague 10",
          "code": "110"
      },
      {
          "name": "Prague 11",
          "code": "111"
      },
      {
          "name": "Prague 12",
          "code": "112"
      },
      {
          "name": "Prague 13",
          "code": "113"
      },
      {
          "name": "Prague 14",
          "code": "114"
      },
      {
          "name": "Prague 15",
          "code": "115"
      },
      {
          "name": "Prague 16",
          "code": "116"
      },
      {
          "name": "Prague 2",
          "code": "102"
      },
      {
          "name": "Prague 21",
          "code": "121"
      },
      {
          "name": "Prague 3",
          "code": "103"
      },
      {
          "name": "Prague 4",
          "code": "104"
      },
      {
          "name": "Prague 5",
          "code": "105"
      },
      {
          "name": "Prague 6",
          "code": "106"
      },
      {
          "name": "Prague 7",
          "code": "107"
      },
      {
          "name": "Prague 8",
          "code": "108"
      },
      {
          "name": "Prague 9",
          "code": "109"
      },
      {
          "name": "Prague-East District",
          "code": "209"
      },
      {
          "name": "Prague-West District",
          "code": "20A"
      },
      {
          "name": "Přerov District",
          "code": "714"
      },
      {
          "name": "Příbram District",
          "code": "20B"
      },
      {
          "name": "Prostějov District",
          "code": "713"
      },
      {
          "name": "Rakovník District",
          "code": "20C"
      },
      {
          "name": "Rokycany District",
          "code": "326"
      },
      {
          "name": "Rychnov nad Kněžnou District",
          "code": "524"
      },
      {
          "name": "Semily District",
          "code": "514"
      },
      {
          "name": "Sokolov District",
          "code": "413"
      },
      {
          "name": "South Bohemian Region",
          "code": "31"
      },
      {
          "name": "South Moravian Region",
          "code": "64"
      },
      {
          "name": "Strakonice District",
          "code": "316"
      },
      {
          "name": "Šumperk District",
          "code": "715"
      },
      {
          "name": "Svitavy District",
          "code": "533"
      },
      {
          "name": "Tábor District",
          "code": "317"
      },
      {
          "name": "Tachov District",
          "code": "327"
      },
      {
          "name": "Teplice District",
          "code": "426"
      },
      {
          "name": "Třebíč District",
          "code": "634"
      },
      {
          "name": "Trutnov District",
          "code": "525"
      },
      {
          "name": "Uherské Hradiště District",
          "code": "722"
      },
      {
          "name": "Ústí nad Labem District",
          "code": "427"
      },
      {
          "name": "Ústí nad Labem Region",
          "code": "42"
      },
      {
          "name": "Ústí nad Orlicí District",
          "code": "534"
      },
      {
          "name": "Vsetín District",
          "code": "723"
      },
      {
          "name": "Vyškov District",
          "code": "646"
      },
      {
          "name": "Vysočina Region",
          "code": "63"
      },
      {
          "name": "Žďár nad Sázavou District",
          "code": "635"
      },
      {
          "name": "Zlín District",
          "code": "724"
      },
      {
          "name": "Zlín Region",
          "code": "72"
      },
      {
          "name": "Znojmo District",
          "code": "647"
      }
    ]
  },
  {
    "code2": "DK",
    "code3": "DNK",
    "name": "Denmark",
    "capital": "Copenhagen",
    "region": "Europe",
    "subregion": "Northern Europe",
    "states": [
      {
          "name": "Capital Region of Denmark",
          "code": "84"
      },
      {
          "name": "Central Denmark Region",
          "code": "82"
      },
      {
          "name": "North Denmark Region",
          "code": "81"
      },
      {
          "name": "Region of Southern Denmark",
          "code": "83"
      },
      {
          "name": "Region Zealand",
          "code": "85"
      }
    ]
  },
  {
    "code2": "DJ",
    "code3": "DJI",
    "name": "Djibouti",
    "capital": "Djibouti",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "states": [
      {
          "name": "Ali Sabieh Region",
          "code": "AS"
      },
      {
          "name": "Arta Region",
          "code": "AR"
      },
      {
          "name": "Dikhil Region",
          "code": "DI"
      },
      {
          "name": "Djibouti",
          "code": "DJ"
      },
      {
          "name": "Obock Region",
          "code": "OB"
      },
      {
          "name": "Tadjourah Region",
          "code": "TA"
      }
    ]
  },
  {
    "code2": "DM",
    "code3": "DMA",
    "name": "Dominica",
    "capital": "Roseau",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": [
      {
          "name": "Saint Andrew Parish",
          "code": "02"
      },
      {
          "name": "Saint David Parish",
          "code": "03"
      },
      {
          "name": "Saint George Parish",
          "code": "04"
      },
      {
          "name": "Saint John Parish",
          "code": "05"
      },
      {
          "name": "Saint Joseph Parish",
          "code": "06"
      },
      {
          "name": "Saint Luke Parish",
          "code": "07"
      },
      {
          "name": "Saint Mark Parish",
          "code": "08"
      },
      {
          "name": "Saint Patrick Parish",
          "code": "09"
      },
      {
          "name": "Saint Paul Parish",
          "code": "10"
      },
      {
          "name": "Saint Peter Parish",
          "code": "11"
      }
    ]
  },
  {
    "code2": "DO",
    "code3": "DOM",
    "name": "Dominican Republic",
    "capital": "Santo Domingo",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": [
      {
          "name": "Azua Province",
          "code": "02"
      },
      {
          "name": "Baoruco Province",
          "code": "03"
      },
      {
          "name": "Barahona Province",
          "code": "04"
      },
      {
          "name": "Dajabón Province",
          "code": "05"
      },
      {
          "name": "Distrito Nacional",
          "code": "01"
      },
      {
          "name": "Duarte Province",
          "code": "06"
      },
      {
          "name": "El Seibo Province",
          "code": "08"
      },
      {
          "name": "Espaillat Province",
          "code": "09"
      },
      {
          "name": "Hato Mayor Province",
          "code": "30"
      },
      {
          "name": "Hermanas Mirabal Province",
          "code": "19"
      },
      {
          "name": "Independencia",
          "code": "10"
      },
      {
          "name": "La Altagracia Province",
          "code": "11"
      },
      {
          "name": "La Romana Province",
          "code": "12"
      },
      {
          "name": "La Vega Province",
          "code": "13"
      },
      {
          "name": "María Trinidad Sánchez Province",
          "code": "14"
      },
      {
          "name": "Monseñor Nouel Province",
          "code": "28"
      },
      {
          "name": "Monte Cristi Province",
          "code": "15"
      },
      {
          "name": "Monte Plata Province",
          "code": "29"
      },
      {
          "name": "Pedernales Province",
          "code": "16"
      },
      {
          "name": "Peravia Province",
          "code": "17"
      },
      {
          "name": "Puerto Plata Province",
          "code": "18"
      },
      {
          "name": "Samaná Province",
          "code": "20"
      },
      {
          "name": "San Cristóbal Province",
          "code": "21"
      },
      {
          "name": "San José de Ocoa Province",
          "code": "31"
      },
      {
          "name": "San Juan Province",
          "code": "22"
      },
      {
          "name": "San Pedro de Macorís",
          "code": "23"
      },
      {
          "name": "Sánchez Ramírez Province",
          "code": "24"
      },
      {
          "name": "Santiago Province",
          "code": "25"
      },
      {
          "name": "Santiago Rodríguez Province",
          "code": "26"
      },
      {
          "name": "Santo Domingo Province",
          "code": "32"
      },
      {
          "name": "Valverde Province",
          "code": "27"
      }
    ]
  },
  {
    "code2": "TL",
    "code3": "TLS",
    "name": "East Timor",
    "capital": "Dili",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "states": [
      {
          "name": "Aileu municipality",
          "code": "AL"
      },
      {
          "name": "Ainaro Municipality",
          "code": "AN"
      },
      {
          "name": "Baucau Municipality",
          "code": "BA"
      },
      {
          "name": "Bobonaro Municipality",
          "code": "BO"
      },
      {
          "name": "Cova Lima Municipality",
          "code": "CO"
      },
      {
          "name": "Dili municipality",
          "code": "DI"
      },
      {
          "name": "Ermera District",
          "code": "ER"
      },
      {
          "name": "Lautém Municipality",
          "code": "LA"
      },
      {
          "name": "Liquiçá Municipality",
          "code": "LI"
      },
      {
          "name": "Manatuto District",
          "code": "MT"
      },
      {
          "name": "Manufahi Municipality",
          "code": "MF"
      },
      {
          "name": "Viqueque Municipality",
          "code": "VI"
      }
    ]
  },
  {
    "code2": "EC",
    "code3": "ECU",
    "name": "Ecuador",
    "capital": "Quito",
    "region": "Americas",
    "subregion": "South America",
    "states": [
      {
          "name": "Azuay Province",
          "code": "A"
      },
      {
          "name": "Bolívar Province",
          "code": "B"
      },
      {
          "name": "Cañar Province",
          "code": "F"
      },
      {
          "name": "Carchi Province",
          "code": "C"
      },
      {
          "name": "Chimborazo Province",
          "code": "H"
      },
      {
          "name": "Cotopaxi Province",
          "code": "X"
      },
      {
          "name": "El Oro Province",
          "code": "O"
      },
      {
          "name": "Esmeraldas",
          "code": "E"
      },
      {
          "name": "Galápagos Province",
          "code": "W"
      },
      {
          "name": "Guayas Province",
          "code": "G"
      },
      {
          "name": "Imbabura Province",
          "code": "I"
      },
      {
          "name": "Los Ríos Province",
          "code": "R"
      },
      {
          "name": "Manabí Province",
          "code": "M"
      },
      {
          "name": "Morona-Santiago Province",
          "code": "S"
      },
      {
          "name": "Napo Province",
          "code": "N"
      },
      {
          "name": "Orellana Province",
          "code": "D"
      },
      {
          "name": "Pastaza Province",
          "code": "Y"
      },
      {
          "name": "Pichincha Province",
          "code": "P"
      },
      {
          "name": "Santa Elena Province",
          "code": "SE"
      },
      {
          "name": "Santo Domingo de los Tsáchilas Province",
          "code": "SD"
      },
      {
          "name": "Sucumbíos Province",
          "code": "U"
      },
      {
          "name": "Tungurahua Province",
          "code": "T"
      },
      {
          "name": "Zamora-Chinchipe Province",
          "code": "Z"
      }
    ]
  },
  {
    "code2": "EG",
    "code3": "EGY",
    "name": "Egypt",
    "capital": "Cairo",
    "region": "Africa",
    "subregion": "Northern Africa",
    "states": [
      {
          "name": "Alexandria Governorate",
          "code": "ALX"
      },
      {
          "name": "Aswan Governorate",
          "code": "ASN"
      },
      {
          "name": "Asyut Governorate",
          "code": "AST"
      },
      {
          "name": "Beheira Governorate",
          "code": "BH"
      },
      {
          "name": "Beni Suef Governorate",
          "code": "BNS"
      },
      {
          "name": "Cairo Governorate",
          "code": "C"
      },
      {
          "name": "Dakahlia Governorate",
          "code": "DK"
      },
      {
          "name": "Damietta Governorate",
          "code": "DT"
      },
      {
          "name": "Faiyum Governorate",
          "code": "FYM"
      },
      {
          "name": "Gharbia Governorate",
          "code": "GH"
      },
      {
          "name": "Giza Governorate",
          "code": "GZ"
      },
      {
          "name": "Ismailia Governorate",
          "code": "IS"
      },
      {
          "name": "Kafr el-Sheikh Governorate",
          "code": "KFS"
      },
      {
          "name": "Luxor Governorate",
          "code": "LX"
      },
      {
          "name": "Matrouh Governorate",
          "code": "MT"
      },
      {
          "name": "Minya Governorate",
          "code": "MN"
      },
      {
          "name": "Monufia Governorate",
          "code": "MNF"
      },
      {
          "name": "New Valley Governorate",
          "code": "WAD"
      },
      {
          "name": "North Sinai Governorate",
          "code": "SIN"
      },
      {
          "name": "Port Said Governorate",
          "code": "PTS"
      },
      {
          "name": "Qalyubia Governorate",
          "code": "KB"
      },
      {
          "name": "Qena Governorate",
          "code": "KN"
      },
      {
          "name": "Red Sea Governorate",
          "code": "BA"
      },
      {
          "name": "Sohag Governorate",
          "code": "SHG"
      },
      {
          "name": "South Sinai Governorate",
          "code": "JS"
      },
      {
          "name": "Suez Governorate",
          "code": "SUZ"
      }
    ]
  },
  {
    "code2": "SV",
    "code3": "SLV",
    "name": "El Salvador",
    "capital": "San Salvador",
    "region": "Americas",
    "subregion": "Central America",
    "states": [
      {
          "name": "Ahuachapán Department",
          "code": "AH"
      },
      {
          "name": "Cabañas Department",
          "code": "CA"
      },
      {
          "name": "Chalatenango Department",
          "code": "CH"
      },
      {
          "name": "Cuscatlán Department",
          "code": "CU"
      },
      {
          "name": "La Libertad Department",
          "code": "LI"
      },
      {
          "name": "La Paz Department",
          "code": "PA"
      },
      {
          "name": "La Unión Department",
          "code": "UN"
      },
      {
          "name": "Morazán Department",
          "code": "MO"
      },
      {
          "name": "San Miguel Department",
          "code": "SM"
      },
      {
          "name": "San Salvador Department",
          "code": "SS"
      },
      {
          "name": "San Vicente Department",
          "code": "SV"
      },
      {
          "name": "Santa Ana Department",
          "code": "SA"
      },
      {
          "name": "Sonsonate Department",
          "code": "SO"
      },
      {
          "name": "Usulután Department",
          "code": "US"
      }
    ]
  },
  {
    "code2": "GQ",
    "code3": "GNQ",
    "name": "Equatorial Guinea",
    "capital": "Malabo",
    "region": "Africa",
    "subregion": "Middle Africa",
    "states": [
      {
          "name": "Annobón Province",
          "code": "AN"
      },
      {
          "name": "Bioko Norte Province",
          "code": "BN"
      },
      {
          "name": "Bioko Sur Province",
          "code": "BS"
      },
      {
          "name": "Centro Sur Province",
          "code": "CS"
      },
      {
          "name": "Insular Region",
          "code": "I"
      },
      {
          "name": "Kié-Ntem Province",
          "code": "KN"
      },
      {
          "name": "Litoral Province",
          "code": "LI"
      },
      {
          "name": "Río Muni",
          "code": "C"
      },
      {
          "name": "Wele-Nzas Province",
          "code": "WN"
      }
    ]
  },
  {
    "code2": "ER",
    "code3": "ERI",
    "name": "Eritrea",
    "capital": "Asmara",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "states": [
      {
          "name": "Anseba Region",
          "code": "AN"
      },
      {
          "name": "Debub Region",
          "code": "DU"
      },
      {
          "name": "Gash-Barka Region",
          "code": "GB"
      },
      {
          "name": "Maekel Region",
          "code": "MA"
      },
      {
          "name": "Northern Red Sea Region",
          "code": "SK"
      },
      {
          "name": "Southern Red Sea Region",
          "code": "DK"
      }
    ]
  },
  {
    "code2": "EE",
    "code3": "EST",
    "name": "Estonia",
    "capital": "Tallinn",
    "region": "Europe",
    "subregion": "Northern Europe",
    "states": [
      {
          "name": "Harju County",
          "code": "37"
      },
      {
          "name": "Hiiu County",
          "code": "39"
      },
      {
          "name": "Ida-Viru County",
          "code": "44"
      },
      {
          "name": "Järva County",
          "code": "51"
      },
      {
          "name": "Jõgeva County",
          "code": "49"
      },
      {
          "name": "Lääne County",
          "code": "57"
      },
      {
          "name": "Lääne-Viru County",
          "code": "59"
      },
      {
          "name": "Pärnu County",
          "code": "67"
      },
      {
          "name": "Põlva County",
          "code": "65"
      },
      {
          "name": "Rapla County",
          "code": "70"
      },
      {
          "name": "Saare County",
          "code": "74"
      },
      {
          "name": "Tartu County",
          "code": "78"
      },
      {
          "name": "Valga County",
          "code": "82"
      },
      {
          "name": "Viljandi County",
          "code": "84"
      },
      {
          "name": "Võru County",
          "code": "86"
      }
    ]
  },
  {
    "code2": "ET",
    "code3": "ETH",
    "name": "Ethiopia",
    "capital": "Addis Ababa",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "states": [
      {
          "name": "Addis Ababa",
          "code": "AA"
      },
      {
          "name": "Afar Region",
          "code": "AF"
      },
      {
          "name": "Amhara Region",
          "code": "AM"
      },
      {
          "name": "Benishangul-Gumuz Region",
          "code": "BE"
      },
      {
          "name": "Dire Dawa",
          "code": "DD"
      },
      {
          "name": "Gambela Region",
          "code": "GA"
      },
      {
          "name": "Harari Region",
          "code": "HA"
      },
      {
          "name": "Oromia Region",
          "code": "OR"
      },
      {
          "name": "Somali Region",
          "code": "SO"
      },
      {
          "name": "Southern Nations, Nationalities, and Peoples' Region",
          "code": "SN"
      },
      {
          "name": "Tigray Region",
          "code": "TI"
      }
    ]
  },
  {
    "code2": "FK",
    "code3": "FLK",
    "name": "Falkland Islands",
    "capital": "Stanley",
    "region": "Americas",
    "subregion": "South America",
    "states": []
  },
  {
    "code2": "FO",
    "code3": "FRO",
    "name": "Faroe Islands",
    "capital": "Tórshavn",
    "region": "Europe",
    "subregion": "Northern Europe",
    "states": []
  },
  {
    "code2": "FJ",
    "code3": "FJI",
    "name": "Fiji",
    "capital": "Suva",
    "region": "Oceania",
    "subregion": "Melanesia",
    "states": [
      {
          "name": "Ba",
          "code": "01"
      },
      {
          "name": "Bua",
          "code": "02"
      },
      {
          "name": "Cakaudrove",
          "code": "03"
      },
      {
          "name": "Central Division",
          "code": "C"
      },
      {
          "name": "Eastern Division",
          "code": "E"
      },
      {
          "name": "Kadavu",
          "code": "04"
      },
      {
          "name": "Lau",
          "code": "05"
      },
      {
          "name": "Lomaiviti",
          "code": "06"
      },
      {
          "name": "Macuata",
          "code": "07"
      },
      {
          "name": "Nadroga-Navosa",
          "code": "08"
      },
      {
          "name": "Naitasiri",
          "code": "09"
      },
      {
          "name": "Namosi",
          "code": "10"
      },
      {
          "name": "Northern Division",
          "code": "N"
      },
      {
          "name": "Ra",
          "code": "11"
      },
      {
          "name": "Rewa",
          "code": "12"
      },
      {
          "name": "Rotuma",
          "code": "R"
      },
      {
          "name": "Serua",
          "code": "13"
      },
      {
          "name": "Tailevu",
          "code": "14"
      },
      {
          "name": "Western Division",
          "code": "W"
      }
    ]
  },
  {
    "code2": "FI",
    "code3": "FIN",
    "name": "Finland",
    "capital": "Helsinki",
    "region": "Europe",
    "subregion": "Northern Europe",
    "states": [
      {
          "name": "Åland Islands",
          "code": "01"
      },
      {
          "name": "Central Finland",
          "code": "08"
      },
      {
          "name": "Central Ostrobothnia",
          "code": "07"
      },
      {
          "name": "Eastern Finland Province",
          "code": "IS"
      },
      {
          "name": "Finland Proper",
          "code": "19"
      },
      {
          "name": "Kainuu",
          "code": "05"
      },
      {
          "name": "Kymenlaakso",
          "code": "09"
      },
      {
          "name": "Lapland",
          "code": "LL"
      },
      {
          "name": "North Karelia",
          "code": "13"
      },
      {
          "name": "Northern Ostrobothnia",
          "code": "14"
      },
      {
          "name": "Northern Savonia",
          "code": "15"
      },
      {
          "name": "Ostrobothnia",
          "code": "12"
      },
      {
          "name": "Oulu Province",
          "code": "OL"
      },
      {
          "name": "Päijänne Tavastia",
          "code": "16"
      },
      {
          "name": "Pirkanmaa",
          "code": "11"
      },
      {
          "name": "Satakunta",
          "code": "17"
      },
      {
          "name": "South Karelia",
          "code": "02"
      },
      {
          "name": "Southern Ostrobothnia",
          "code": "03"
      },
      {
          "name": "Southern Savonia",
          "code": "04"
      },
      {
          "name": "Tavastia Proper",
          "code": "06"
      },
      {
          "name": "Uusimaa",
          "code": "18"
      }
    ]
  },
  {
    "code2": "FR",
    "code3": "FRA",
    "name": "France",
    "capital": "Paris",
    "region": "Europe",
    "subregion": "Western Europe",
    "states": [
      {
          "name": "Alo",
          "code": "WF-AL"
      },
      {
          "name": "Alsace",
          "code": "A"
      },
      {
          "name": "Aquitaine",
          "code": "B"
      },
      {
          "name": "Auvergne",
          "code": "C"
      },
      {
          "name": "Auvergne-Rhône-Alpes",
          "code": "ARA"
      },
      {
          "name": "Bourgogne-Franche-Comté",
          "code": "BFC"
      },
      {
          "name": "Brittany",
          "code": "BRE"
      },
      {
          "name": "Burgundy",
          "code": "D"
      },
      {
          "name": "Centre-Val de Loire",
          "code": "CVL"
      },
      {
          "name": "Champagne-Ardenne",
          "code": "G"
      },
      {
          "name": "Corsica",
          "code": "COR"
      },
      {
          "name": "Franche-Comté",
          "code": "I"
      },
      {
          "name": "French Guiana",
          "code": "GF"
      },
      {
          "name": "French Polynesia",
          "code": "PF"
      },
      {
          "name": "Grand Est",
          "code": "GES"
      },
      {
          "name": "Guadeloupe",
          "code": "GP"
      },
      {
          "name": "Hauts-de-France",
          "code": "HDF"
      },
      {
          "name": "Île-de-France",
          "code": "IDF"
      },
      {
          "name": "Languedoc-Roussillon",
          "code": "K"
      },
      {
          "name": "Limousin",
          "code": "L"
      },
      {
          "name": "Lorraine",
          "code": "M"
      },
      {
          "name": "Lower Normandy",
          "code": "P"
      },
      {
          "name": "Martinique",
          "code": "MQ"
      },
      {
          "name": "Mayotte",
          "code": "YT"
      },
      {
          "name": "Nord-Pas-de-Calais",
          "code": "O"
      },
      {
          "name": "Normandy",
          "code": "NOR"
      },
      {
          "name": "Nouvelle-Aquitaine",
          "code": "NAQ"
      },
      {
          "name": "Occitania",
          "code": "OCC"
      },
      {
          "name": "Paris",
          "code": "75"
      },
      {
          "name": "Pays de la Loire",
          "code": "PDL"
      },
      {
          "name": "Picardy",
          "code": "S"
      },
      {
          "name": "Poitou-Charentes",
          "code": "T"
      },
      {
          "name": "Provence-Alpes-Côte d'Azur",
          "code": "PAC"
      },
      {
          "name": "Réunion",
          "code": "RE"
      },
      {
          "name": "Rhône-Alpes",
          "code": "V"
      },
      {
          "name": "Saint Barthélemy",
          "code": "BL"
      },
      {
          "name": "Saint Martin",
          "code": "MF"
      },
      {
          "name": "Saint Pierre and Miquelon",
          "code": "PM"
      },
      {
          "name": "Sigave",
          "code": "WF-SG"
      },
      {
          "name": "Upper Normandy",
          "code": "Q"
      },
      {
          "name": "Uvea",
          "code": "WF-UV"
      },
      {
          "name": "Wallis and Futuna",
          "code": "WF"
      }
    ]
  },
  {
    "code2": "GF",
    "code3": "GUF",
    "name": "French Guiana",
    "capital": "Cayenne",
    "region": "Americas",
    "subregion": "South America",
    "states": []
  },
  {
    "code2": "PF",
    "code3": "PYF",
    "name": "French Polynesia",
    "capital": "Papeetē",
    "region": "Oceania",
    "subregion": "Polynesia",
    "states": []
  },
  {
    "code2": "TF",
    "code3": "ATF",
    "name": "French Southern and Antarctic Lands",
    "capital": "Port-aux-Français",
    "region": "Africa",
    "subregion": "Southern Africa",
    "states": []
  },
  {
    "code2": "GA",
    "code3": "GAB",
    "name": "Gabon",
    "capital": "Libreville",
    "region": "Africa",
    "subregion": "Middle Africa",
    "states": [
      {
          "name": "Estuaire Province",
          "code": "1"
      },
      {
          "name": "Haut-Ogooué Province",
          "code": "2"
      },
      {
          "name": "Moyen-Ogooué Province",
          "code": "3"
      },
      {
          "name": "Ngounié Province",
          "code": "4"
      },
      {
          "name": "Nyanga Province",
          "code": "5"
      },
      {
          "name": "Ogooué-Ivindo Province",
          "code": "6"
      },
      {
          "name": "Ogooué-Lolo Province",
          "code": "7"
      },
      {
          "name": "Ogooué-Maritime Province",
          "code": "8"
      },
      {
          "name": "Woleu-Ntem Province",
          "code": "9"
      }
    ]
  },
  {
    "code2": "GM",
    "code3": "GMB",
    "name": "The Gambia",
    "capital": "Banjul",
    "region": "Africa",
    "subregion": "Western Africa",
    "states": [
      {
          "name": "Banjul",
          "code": "B"
      },
      {
          "name": "Central River Division",
          "code": "M"
      },
      {
          "name": "Lower River Division",
          "code": "L"
      },
      {
          "name": "North Bank Division",
          "code": "N"
      },
      {
          "name": "Upper River Division",
          "code": "U"
      },
      {
          "name": "West Coast Division",
          "code": "W"
      }
    ]
  },
  {
    "code2": "GE",
    "code3": "GEO",
    "name": "Georgia",
    "capital": "Tbilisi",
    "region": "Asia",
    "subregion": "Western Asia",
    "states": [
      {
          "name": "Adjara",
          "code": "AJ"
      },
      {
          "name": "Autonomous Republic of Abkhazia",
          "code": "AB"
      },
      {
          "name": "Guria",
          "code": "GU"
      },
      {
          "name": "Imereti",
          "code": "IM"
      },
      {
          "name": "Kakheti",
          "code": "KA"
      },
      {
          "name": "Khelvachauri Municipality",
          "code": "29"
      },
      {
          "name": "Kvemo Kartli",
          "code": "KK"
      },
      {
          "name": "Mtskheta-Mtianeti",
          "code": "MM"
      },
      {
          "name": "Racha-Lechkhumi and Kvemo Svaneti",
          "code": "RL"
      },
      {
          "name": "Samegrelo-Zemo Svaneti",
          "code": "SZ"
      },
      {
          "name": "Samtskhe-Javakheti",
          "code": "SJ"
      },
      {
          "name": "Senaki Municipality",
          "code": "50"
      },
      {
          "name": "Shida Kartli",
          "code": "SK"
      },
      {
          "name": "Tbilisi",
          "code": "TB"
      }
    ]
  },
  {
    "code2": "DE",
    "code3": "DEU",
    "name": "Germany",
    "capital": "Berlin",
    "region": "Europe",
    "subregion": "Western Europe",
    "states": [
      {
          "name": "Baden-Württemberg",
          "code": "BW"
      },
      {
          "name": "Bavaria",
          "code": "BY"
      },
      {
          "name": "Berlin",
          "code": "BE"
      },
      {
          "name": "Brandenburg",
          "code": "BB"
      },
      {
          "name": "Bremen",
          "code": "HB"
      },
      {
          "name": "Hamburg",
          "code": "HH"
      },
      {
          "name": "Hesse",
          "code": "HE"
      },
      {
          "name": "Lower Saxony",
          "code": "NI"
      },
      {
          "name": "Mecklenburg-Vorpommern",
          "code": "MV"
      },
      {
          "name": "North Rhine-Westphalia",
          "code": "NW"
      },
      {
          "name": "Rhineland-Palatinate",
          "code": "RP"
      },
      {
          "name": "Saarland",
          "code": "SL"
      },
      {
          "name": "Saxony",
          "code": "SN"
      },
      {
          "name": "Saxony-Anhalt",
          "code": "ST"
      },
      {
          "name": "Schleswig-Holstein",
          "code": "SH"
      },
      {
          "name": "Thuringia",
          "code": "TH"
      }
    ]
  },
  {
    "code2": "GH",
    "code3": "GHA",
    "name": "Ghana",
    "capital": "Accra",
    "region": "Africa",
    "subregion": "Western Africa",
    "states": [
      {
          "name": "Ashanti Region",
          "code": "AH"
      },
      {
          "name": "Brong-Ahafo Region",
          "code": "BA"
      },
      {
          "name": "Central Region",
          "code": "CP"
      },
      {
          "name": "Eastern Region",
          "code": "EP"
      },
      {
          "name": "Greater Accra Region",
          "code": "AA"
      },
      {
          "name": "Northern Region",
          "code": "NP"
      },
      {
          "name": "Upper East Region",
          "code": "UE"
      },
      {
          "name": "Upper West Region",
          "code": "UW"
      },
      {
          "name": "Volta Region",
          "code": "TV"
      },
      {
          "name": "Western Region",
          "code": "WP"
      }
    ]
  },
  {
    "code2": "GI",
    "code3": "GIB",
    "name": "Gibraltar",
    "capital": "Gibraltar",
    "region": "Europe",
    "subregion": "Southern Europe",
    "states": []
  },
  {
    "code2": "GR",
    "code3": "GRC",
    "name": "Greece",
    "capital": "Athens",
    "region": "Europe",
    "subregion": "Southern Europe",
    "states": [
      {
          "name": "Achaea Regional Unit",
          "code": "13"
      },
      {
          "name": "Aetolia-Acarnania Regional Unit",
          "code": "01"
      },
      {
          "name": "Arcadia Prefecture",
          "code": "12"
      },
      {
          "name": "Argolis Regional Unit",
          "code": "11"
      },
      {
          "name": "Attica Region",
          "code": "I"
      },
      {
          "name": "Boeotia Regional Unit",
          "code": "03"
      },
      {
          "name": "Central Greece Region",
          "code": "H"
      },
      {
          "name": "Central Macedonia",
          "code": "B"
      },
      {
          "name": "Chania Regional Unit",
          "code": "94"
      },
      {
          "name": "Corfu Prefecture",
          "code": "22"
      },
      {
          "name": "Corinthia Regional Unit",
          "code": "15"
      },
      {
          "name": "Crete Region",
          "code": "M"
      },
      {
          "name": "Drama Regional Unit",
          "code": "52"
      },
      {
          "name": "East Attica Regional Unit",
          "code": "A2"
      },
      {
          "name": "East Macedonia and Thrace",
          "code": "A"
      },
      {
          "name": "Epirus Region",
          "code": "D"
      },
      {
          "name": "Euboea",
          "code": "04"
      },
      {
          "name": "Grevena Prefecture",
          "code": "51"
      },
      {
          "name": "Imathia Regional Unit",
          "code": "53"
      },
      {
          "name": "Ioannina Regional Unit",
          "code": "33"
      },
      {
          "name": "Ionian Islands Region",
          "code": "F"
      },
      {
          "name": "Karditsa Regional Unit",
          "code": "41"
      },
      {
          "name": "Kastoria Regional Unit",
          "code": "56"
      },
      {
          "name": "Kefalonia Prefecture",
          "code": "23"
      },
      {
          "name": "Kilkis Regional Unit",
          "code": "57"
      },
      {
          "name": "Kozani Prefecture",
          "code": "58"
      },
      {
          "name": "Laconia",
          "code": "16"
      },
      {
          "name": "Larissa Prefecture",
          "code": "42"
      },
      {
          "name": "Lefkada Regional Unit",
          "code": "24"
      },
      {
          "name": "Pella Regional Unit",
          "code": "59"
      },
      {
          "name": "Peloponnese Region",
          "code": "J"
      },
      {
          "name": "Phthiotis Prefecture",
          "code": "06"
      },
      {
          "name": "Preveza Prefecture",
          "code": "34"
      },
      {
          "name": "Serres Prefecture",
          "code": "62"
      },
      {
          "name": "South Aegean",
          "code": "L"
      },
      {
          "name": "Thessaloniki Regional Unit",
          "code": "54"
      },
      {
          "name": "West Greece Region",
          "code": "G"
      },
      {
          "name": "West Macedonia Region",
          "code": "C"
      }
    ]
  },
  {
    "code2": "GL",
    "code3": "GRL",
    "name": "Greenland",
    "capital": "Nuuk",
    "region": "Americas",
    "subregion": "Northern America",
    "states": [
      {
        "code": "KU",
        "name": "Kommune Kujalleq",
        "subdivision": null
      },
      {
        "code": "SM",
        "name": "Kommuneqarfik Sermersooq",
        "subdivision": null
      },
      {
        "code": "QA",
        "name": "Qaasuitsup Kommunia",
        "subdivision": null
      },
      {
        "code": "QE",
        "name": "Qeqqata Kommunia",
        "subdivision": null
      }
    ]
  },
  {
    "code2": "GD",
    "code3": "GRD",
    "name": "Grenada",
    "capital": "St. George's",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": [
      {
          "name": "Carriacou and Petite Martinique",
          "code": "10"
      },
      {
          "name": "Saint Andrew Parish",
          "code": "01"
      },
      {
          "name": "Saint David Parish",
          "code": "02"
      },
      {
          "name": "Saint George Parish",
          "code": "03"
      },
      {
          "name": "Saint John Parish",
          "code": "04"
      },
      {
          "name": "Saint Mark Parish",
          "code": "05"
      },
      {
          "name": "Saint Patrick Parish",
          "code": "06"
      }
    ]
  },
  {
    "code2": "GP",
    "code3": "GLP",
    "name": "Guadeloupe",
    "capital": "Basse-Terre",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": []
  },
  {
    "code2": "GU",
    "code3": "GUM",
    "name": "Guam",
    "capital": "Hagåtña",
    "region": "Oceania",
    "subregion": "Micronesia",
    "states": []
  },
  {
    "code2": "GT",
    "code3": "GTM",
    "name": "Guatemala",
    "capital": "Guatemala City",
    "region": "Americas",
    "subregion": "Central America",
    "states": [
      {
          "name": "Alta Verapaz Department",
          "code": "AV"
      },
      {
          "name": "Baja Verapaz Department",
          "code": "BV"
      },
      {
          "name": "Chimaltenango Department",
          "code": "CM"
      },
      {
          "name": "Chiquimula Department",
          "code": "CQ"
      },
      {
          "name": "El Progreso Department",
          "code": "PR"
      },
      {
          "name": "Escuintla Department",
          "code": "ES"
      },
      {
          "name": "Guatemala Department",
          "code": "GU"
      },
      {
          "name": "Huehuetenango Department",
          "code": "HU"
      },
      {
          "name": "Izabal Department",
          "code": "IZ"
      },
      {
          "name": "Jalapa Department",
          "code": "JA"
      },
      {
          "name": "Jutiapa Department",
          "code": "JU"
      },
      {
          "name": "Petén Department",
          "code": "PE"
      },
      {
          "name": "Quetzaltenango Department",
          "code": "QZ"
      },
      {
          "name": "Quiché Department",
          "code": "QC"
      },
      {
          "name": "Retalhuleu Department",
          "code": "RE"
      },
      {
          "name": "Sacatepéquez Department",
          "code": "SA"
      },
      {
          "name": "San Marcos Department",
          "code": "SM"
      },
      {
          "name": "Santa Rosa Department",
          "code": "SR"
      },
      {
          "name": "Sololá Department",
          "code": "SO"
      },
      {
          "name": "Suchitepéquez Department",
          "code": "SU"
      },
      {
          "name": "Totonicapán Department",
          "code": "TO"
      }
    ]
  },
  {
    "code2": "GG",
    "code3": "GGY",
    "name": "Guernsey",
    "capital": "St. Peter Port",
    "region": "Europe",
    "subregion": "Northern Europe",
    "states": []
  },
  {
    "code2": "GN",
    "code3": "GIN",
    "name": "Guinea",
    "capital": "Conakry",
    "region": "Africa",
    "subregion": "Western Africa",
    "states": [
      {
          "name": "Beyla Prefecture",
          "code": "BE"
      },
      {
          "name": "Boffa Prefecture",
          "code": "BF"
      },
      {
          "name": "Boké Prefecture",
          "code": "BK"
      },
      {
          "name": "Boké Region",
          "code": "B"
      },
      {
          "name": "Conakry",
          "code": "C"
      },
      {
          "name": "Coyah Prefecture",
          "code": "CO"
      },
      {
          "name": "Dabola Prefecture",
          "code": "DB"
      },
      {
          "name": "Dalaba Prefecture",
          "code": "DL"
      },
      {
          "name": "Dinguiraye Prefecture",
          "code": "DI"
      },
      {
          "name": "Dubréka Prefecture",
          "code": "DU"
      },
      {
          "name": "Faranah Prefecture",
          "code": "FA"
      },
      {
          "name": "Forécariah Prefecture",
          "code": "FO"
      },
      {
          "name": "Fria Prefecture",
          "code": "FR"
      },
      {
          "name": "Gaoual Prefecture",
          "code": "GA"
      },
      {
          "name": "Guéckédou Prefecture",
          "code": "GU"
      },
      {
          "name": "Kankan Prefecture",
          "code": "KA"
      },
      {
          "name": "Kankan Region",
          "code": "K"
      },
      {
          "name": "Kérouané Prefecture",
          "code": "KE"
      },
      {
          "name": "Kindia Prefecture",
          "code": "KD"
      },
      {
          "name": "Kindia Region",
          "code": "D"
      },
      {
          "name": "Kissidougou Prefecture",
          "code": "KS"
      },
      {
          "name": "Koubia Prefecture",
          "code": "KB"
      },
      {
          "name": "Koundara Prefecture",
          "code": "KN"
      },
      {
          "name": "Kouroussa Prefecture",
          "code": "KO"
      },
      {
          "name": "Labé Prefecture",
          "code": "LA"
      },
      {
          "name": "Labé Region",
          "code": "L"
      },
      {
          "name": "Lélouma Prefecture",
          "code": "LE"
      },
      {
          "name": "Lola Prefecture",
          "code": "LO"
      },
      {
          "name": "Macenta Prefecture",
          "code": "MC"
      },
      {
          "name": "Mali Prefecture",
          "code": "ML"
      },
      {
          "name": "Mamou Prefecture",
          "code": "MM"
      },
      {
          "name": "Mamou Region",
          "code": "M"
      },
      {
          "name": "Mandiana Prefecture",
          "code": "MD"
      },
      {
          "name": "Nzérékoré Prefecture",
          "code": "NZ"
      },
      {
          "name": "Nzérékoré Region",
          "code": "N"
      },
      {
          "name": "Pita Prefecture",
          "code": "PI"
      },
      {
          "name": "Siguiri Prefecture",
          "code": "SI"
      },
      {
          "name": "Télimélé Prefecture",
          "code": "TE"
      },
      {
          "name": "Tougué Prefecture",
          "code": "TO"
      },
      {
          "name": "Yomou Prefecture",
          "code": "YO"
      }
    ]
  },
  {
    "code2": "GW",
    "code3": "GNB",
    "name": "Guinea-Bissau",
    "capital": "Bissau",
    "region": "Africa",
    "subregion": "Western Africa",
    "states": [
      {
          "name": "Bafatá",
          "code": "BA"
      },
      {
          "name": "Biombo Region",
          "code": "BM"
      },
      {
          "name": "Bolama Region",
          "code": "BL"
      },
      {
          "name": "Cacheu Region",
          "code": "CA"
      },
      {
          "name": "Gabú Region",
          "code": "GA"
      },
      {
          "name": "Leste Province",
          "code": "L"
      },
      {
          "name": "Norte Province",
          "code": "N"
      },
      {
          "name": "Oio Region",
          "code": "OI"
      },
      {
          "name": "Quinara Region",
          "code": "QU"
      },
      {
          "name": "Sul Province",
          "code": "S"
      },
      {
          "name": "Tombali Region",
          "code": "TO"
      }
    ]
  },
  {
    "code2": "GY",
    "code3": "GUY",
    "name": "Guyana",
    "capital": "Georgetown",
    "region": "Americas",
    "subregion": "South America",
    "states": [
      {
          "name": "Barima-Waini",
          "code": "BA"
      },
      {
          "name": "Cuyuni-Mazaruni",
          "code": "CU"
      },
      {
          "name": "Demerara-Mahaica",
          "code": "DE"
      },
      {
          "name": "East Berbice-Corentyne",
          "code": "EB"
      },
      {
          "name": "Essequibo Islands-West Demerara",
          "code": "ES"
      },
      {
          "name": "Mahaica-Berbice",
          "code": "MA"
      },
      {
          "name": "Pomeroon-Supenaam",
          "code": "PM"
      },
      {
          "name": "Potaro-Siparuni",
          "code": "PT"
      },
      {
          "name": "Upper Demerara-Berbice",
          "code": "UD"
      },
      {
          "name": "Upper Takutu-Upper Essequibo",
          "code": "UT"
      }
    ]
  },
  {
    "code2": "HT",
    "code3": "HTI",
    "name": "Haiti",
    "capital": "Port-au-Prince",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": [
      {
          "name": "Artibonite",
          "code": "AR"
      },
      {
          "name": "Centre",
          "code": "CE"
      },
      {
          "name": "Grand'Anse",
          "code": "GA"
      },
      {
          "name": "Nippes",
          "code": "NI"
      },
      {
          "name": "Nord",
          "code": "ND"
      },
      {
          "name": "Nord-Est",
          "code": "NE"
      },
      {
          "name": "Nord-Ouest",
          "code": "NO"
      },
      {
          "name": "Ouest",
          "code": "OU"
      },
      {
          "name": "Sud",
          "code": "SD"
      },
      {
          "name": "Sud-Est",
          "code": "SE"
      }
    ]
  },
  {
    "code2": "HM",
    "code3": "HMD",
    "name": "Heard Island and McDonald Islands",
    "capital": "",
    "region": "",
    "subregion": "",
    "states": []
  },
  {
    "code2": "VA",
    "code3": "VAT",
    "name": "Holy See",
    "capital": "Rome",
    "region": "Europe",
    "subregion": "Southern Europe",
    "states": []
  },
  {
    "code2": "HN",
    "code3": "HND",
    "name": "Honduras",
    "capital": "Tegucigalpa",
    "region": "Americas",
    "subregion": "Central America",
    "states": [
      {
          "name": "Atlántida Department",
          "code": "AT"
      },
      {
          "name": "Bay Islands Department",
          "code": "IB"
      },
      {
          "name": "Choluteca Department",
          "code": "CH"
      },
      {
          "name": "Colón Department",
          "code": "CL"
      },
      {
          "name": "Comayagua Department",
          "code": "CM"
      },
      {
          "name": "Copán Department",
          "code": "CP"
      },
      {
          "name": "Cortés Department",
          "code": "CR"
      },
      {
          "name": "El Paraíso Department",
          "code": "EP"
      },
      {
          "name": "Francisco Morazán Department",
          "code": "FM"
      },
      {
          "name": "Gracias a Dios Department",
          "code": "GD"
      },
      {
          "name": "Intibucá Department",
          "code": "IN"
      },
      {
          "name": "La Paz Department",
          "code": "LP"
      },
      {
          "name": "Lempira Department",
          "code": "LE"
      },
      {
          "name": "Ocotepeque Department",
          "code": "OC"
      },
      {
          "name": "Olancho Department",
          "code": "OL"
      },
      {
          "name": "Santa Bárbara Department",
          "code": "SB"
      },
      {
          "name": "Valle Department",
          "code": "VA"
      },
      {
          "name": "Yoro Department",
          "code": "YO"
      }
    ]
  },
  {
    "code2": "HK",
    "code3": "HKG",
    "name": "Hong Kong",
    "capital": "City of Victoria",
    "region": "Asia",
    "subregion": "Eastern Asia",
    "states": [
      {
          "name": "Central and Western District",
          "code": "HCW"
      },
      {
          "name": "Eastern",
          "code": "HEA"
      },
      {
          "name": "Islands District",
          "code": "NIS"
      },
      {
          "name": "Kowloon City",
          "code": "KKC"
      },
      {
          "name": "Kwai Tsing",
          "code": "NKT"
      },
      {
          "name": "Kwun Tong",
          "code": "KKT"
      },
      {
          "name": "North",
          "code": "NNO"
      },
      {
          "name": "Sai Kung District",
          "code": "NSK"
      },
      {
          "name": "Sha Tin",
          "code": "NST"
      },
      {
          "name": "Sham Shui Po",
          "code": "KSS"
      },
      {
          "name": "Southern",
          "code": "HSO"
      },
      {
          "name": "Tai Po District",
          "code": "NTP"
      },
      {
          "name": "Tsuen Wan District",
          "code": "NTW"
      },
      {
          "name": "Tuen Mun",
          "code": "NTM"
      },
      {
          "name": "Wan Chai",
          "code": "HWC"
      },
      {
          "name": "Wong Tai Sin",
          "code": "KWT"
      },
      {
          "name": "Yau Tsim Mong",
          "code": "KYT"
      },
      {
          "name": "Yuen Long District",
          "code": "NYL"
      }
    ]
  },
  {
    "code2": "HU",
    "code3": "HUN",
    "name": "Hungary",
    "capital": "Budapest",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "states": [
      {
          "name": "Bács-Kiskun County",
          "code": "BK"
      },
      {
          "name": "Baranya County",
          "code": "BA"
      },
      {
          "name": "Békés County",
          "code": "BE"
      },
      {
          "name": "Békéscsaba",
          "code": "BC"
      },
      {
          "name": "Borsod-Abaúj-Zemplén County",
          "code": "BZ"
      },
      {
          "name": "Budapest",
          "code": "BU"
      },
      {
          "name": "Csongrád County",
          "code": "CS"
      },
      {
          "name": "Debrecen",
          "code": "DE"
      },
      {
          "name": "Dunaújváros",
          "code": "DU"
      },
      {
          "name": "Eger",
          "code": "EG"
      },
      {
          "name": "Érd",
          "code": "ER"
      },
      {
          "name": "Fejér County",
          "code": "FE"
      },
      {
          "name": "Győr",
          "code": "GY"
      },
      {
          "name": "Győr-Moson-Sopron County",
          "code": "GS"
      },
      {
          "name": "Hajdú-Bihar County",
          "code": "HB"
      },
      {
          "name": "Heves County",
          "code": "HE"
      },
      {
          "name": "Hódmezővásárhely",
          "code": "HV"
      },
      {
          "name": "Jász-Nagykun-Szolnok County",
          "code": "JN"
      },
      {
          "name": "Kaposvár",
          "code": "KV"
      },
      {
          "name": "Kecskemét",
          "code": "KM"
      },
      {
          "name": "Miskolc",
          "code": "MI"
      },
      {
          "name": "Nagykanizsa",
          "code": "NK"
      },
      {
          "name": "Nógrád County",
          "code": "NO"
      },
      {
          "name": "Nyíregyháza",
          "code": "NY"
      },
      {
          "name": "Pécs",
          "code": "PS"
      },
      {
          "name": "Pest County",
          "code": "PE"
      },
      {
          "name": "Salgótarján",
          "code": "ST"
      },
      {
          "name": "Somogy County",
          "code": "SO"
      },
      {
          "name": "Sopron",
          "code": "SN"
      },
      {
          "name": "Szabolcs-Szatmár-Bereg County",
          "code": "SZ"
      },
      {
          "name": "Szeged",
          "code": "SD"
      },
      {
          "name": "Székesfehérvár",
          "code": "SF"
      },
      {
          "name": "Szekszárd",
          "code": "SS"
      },
      {
          "name": "Szolnok",
          "code": "SK"
      },
      {
          "name": "Szombathely",
          "code": "SH"
      },
      {
          "name": "Tatabánya",
          "code": "TB"
      },
      {
          "name": "Tolna County",
          "code": "TO"
      },
      {
          "name": "Vas County",
          "code": "VA"
      },
      {
          "name": "Veszprém",
          "code": "VM"
      },
      {
          "name": "Veszprém County",
          "code": "VE"
      },
      {
          "name": "Zala County",
          "code": "ZA"
      },
      {
          "name": "Zalaegerszeg",
          "code": "ZE"
      }
    ]
  },
  {
    "code2": "IS",
    "code3": "ISL",
    "name": "Iceland",
    "capital": "Reykjavík",
    "region": "Europe",
    "subregion": "Northern Europe",
    "states": [
      {
          "name": "Capital Region",
          "code": "1"
      },
      {
          "name": "Eastern Region",
          "code": "7"
      },
      {
          "name": "Northeastern Region",
          "code": "6"
      },
      {
          "name": "Northwestern Region",
          "code": "5"
      },
      {
          "name": "Southern Peninsula Region",
          "code": "2"
      },
      {
          "name": "Southern Region",
          "code": "8"
      },
      {
          "name": "Western Region",
          "code": "3"
      },
      {
          "name": "Westfjords",
          "code": "4"
      }
    ]
  },
  {
    "code2": "IN",
    "code3": "IND",
    "name": "India",
    "capital": "New Delhi",
    "region": "Asia",
    "subregion": "Southern Asia",
    "states": [
      {
          "name": "Andaman and Nicobar Islands",
          "code": "AN"
      },
      {
          "name": "Andhra Pradesh",
          "code": "AP"
      },
      {
          "name": "Arunachal Pradesh",
          "code": "AR"
      },
      {
          "name": "Assam",
          "code": "AS"
      },
      {
          "name": "Bihar",
          "code": "BR"
      },
      {
          "name": "Chandigarh",
          "code": "CH"
      },
      {
          "name": "Chhattisgarh",
          "code": "CT"
      },
      {
          "name": "Dadra and Nagar Haveli",
          "code": "DN"
      },
      {
          "name": "Daman and Diu",
          "code": "DD"
      },
      {
          "name": "Delhi",
          "code": "DL"
      },
      {
          "name": "Goa",
          "code": "GA"
      },
      {
          "name": "Gujarat",
          "code": "GJ"
      },
      {
          "name": "Haryana",
          "code": "HR"
      },
      {
          "name": "Himachal Pradesh",
          "code": "HP"
      },
      {
          "name": "Jammu and Kashmir",
          "code": "JK"
      },
      {
          "name": "Jharkhand",
          "code": "JH"
      },
      {
          "name": "Karnataka",
          "code": "KA"
      },
      {
          "name": "Kerala",
          "code": "KL"
      },
      {
          "name": "Ladakh",
          "code": "LA"
      },
      {
          "name": "Lakshadweep",
          "code": "LD"
      },
      {
          "name": "Madhya Pradesh",
          "code": "MP"
      },
      {
          "name": "Maharashtra",
          "code": "MH"
      },
      {
          "name": "Manipur",
          "code": "MN"
      },
      {
          "name": "Meghalaya",
          "code": "ML"
      },
      {
          "name": "Mizoram",
          "code": "MZ"
      },
      {
          "name": "Nagaland",
          "code": "NL"
      },
      {
          "name": "Odisha",
          "code": "OR"
      },
      {
          "name": "Puducherry",
          "code": "PY"
      },
      {
          "name": "Punjab",
          "code": "PB"
      },
      {
          "name": "Rajasthan",
          "code": "RJ"
      },
      {
          "name": "Sikkim",
          "code": "SK"
      },
      {
          "name": "Tamil Nadu",
          "code": "TN"
      },
      {
          "name": "Telangana",
          "code": "TG"
      },
      {
          "name": "Tripura",
          "code": "TR"
      },
      {
          "name": "Uttar Pradesh",
          "code": "UP"
      },
      {
          "name": "Uttarakhand",
          "code": "UT"
      },
      {
          "name": "West Bengal",
          "code": "WB"
      }
    ]
  },
  {
    "code2": "ID",
    "code3": "IDN",
    "name": "Indonesia",
    "capital": "Jakarta",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "states": [
      {
          "name": "Aceh",
          "code": "AC"
      },
      {
          "name": "Bali",
          "code": "BA"
      },
      {
          "name": "Bangka Belitung Islands",
          "code": "BB"
      },
      {
          "name": "Banten",
          "code": "BT"
      },
      {
          "name": "Bengkulu",
          "code": "BE"
      },
      {
          "name": "Central Java",
          "code": "JT"
      },
      {
          "name": "Central Kalimantan",
          "code": "KT"
      },
      {
          "name": "Central Sulawesi",
          "code": "ST"
      },
      {
          "name": "East Java",
          "code": "JI"
      },
      {
          "name": "East Kalimantan",
          "code": "KI"
      },
      {
          "name": "East Nusa Tenggara",
          "code": "NT"
      },
      {
          "name": "Gorontalo",
          "code": "GO"
      },
      {
          "name": "Jakarta",
          "code": "JK"
      },
      {
          "name": "Jambi",
          "code": "JA"
      },
      {
          "name": "Kalimantan",
          "code": "KA"
      },
      {
          "name": "Lampung",
          "code": "LA"
      },
      {
          "name": "Lesser Sunda Islands",
          "code": "NU"
      },
      {
          "name": "Maluku",
          "code": "MA"
      },
      {
          "name": "Maluku Islands",
          "code": "ML"
      },
      {
          "name": "North Kalimantan",
          "code": "KU"
      },
      {
          "name": "North Maluku",
          "code": "MU"
      },
      {
          "name": "North Sulawesi",
          "code": "SA"
      },
      {
          "name": "North Sumatra",
          "code": "SU"
      },
      {
          "name": "Papua",
          "code": "PA"
      },
      {
          "name": "Riau",
          "code": "RI"
      },
      {
          "name": "Riau Islands",
          "code": "KR"
      },
      {
          "name": "South Kalimantan",
          "code": "KS"
      },
      {
          "name": "South Sulawesi",
          "code": "SN"
      },
      {
          "name": "South Sumatra",
          "code": "SS"
      },
      {
          "name": "Southeast Sulawesi",
          "code": "SG"
      },
      {
          "name": "Special Region of Yogyakarta",
          "code": "YO"
      },
      {
          "name": "Sulawesi",
          "code": "SL"
      },
      {
          "name": "Sumatra",
          "code": "SM"
      },
      {
          "name": "West Java",
          "code": "JB"
      },
      {
          "name": "West Nusa Tenggara",
          "code": "NB"
      },
      {
          "name": "West Papua",
          "code": "PB"
      },
      {
          "name": "West Sulawesi",
          "code": "SR"
      },
      {
          "name": "West Sumatra",
          "code": "SB"
      }
    ]
  },
  {
    "code2": "CI",
    "code3": "CIV",
    "name": "Ivory Coast",
    "capital": "Yamoussoukro",
    "region": "Africa",
    "subregion": "Western Africa",
    "states": [
      {
        "code": "06",
        "name": "18 Montagnes",
        "subdivision": null
      },
      {
        "code": "16",
        "name": "Agnébi",
        "subdivision": null
      },
      {
        "code": "17",
        "name": "Bafing",
        "subdivision": null
      },
      {
        "code": "09",
        "name": "Bas-Sassandra",
        "subdivision": null
      },
      {
        "code": "10",
        "name": "Denguélé",
        "subdivision": null
      },
      {
        "code": "18",
        "name": "Fromager",
        "subdivision": null
      },
      {
        "code": "02",
        "name": "Haut-Sassandra",
        "subdivision": null
      },
      {
        "code": "07",
        "name": "Lacs",
        "subdivision": null
      },
      {
        "code": "01",
        "name": "Lagunes",
        "subdivision": null
      },
      {
        "code": "12",
        "name": "Marahoué",
        "subdivision": null
      },
      {
        "code": "19",
        "name": "Moyen-Cavally",
        "subdivision": null
      },
      {
        "code": "05",
        "name": "Moyen-Comoé",
        "subdivision": null
      },
      {
        "code": "11",
        "name": "Nzi-Comoé",
        "subdivision": null
      },
      {
        "code": "03",
        "name": "Savanes",
        "subdivision": null
      },
      {
        "code": "15",
        "name": "Sud-Bandama",
        "subdivision": null
      },
      {
        "code": "13",
        "name": "Sud-Comoé",
        "subdivision": null
      },
      {
        "code": "04",
        "name": "Vallée du Bandama",
        "subdivision": null
      },
      {
        "code": "14",
        "name": "Worodougou",
        "subdivision": null
      },
      {
        "code": "08",
        "name": "Zanzan",
        "subdivision": null
      }
    ]
  },
  {
    "code2": "IR",
    "code3": "IRN",
    "name": "Iran",
    "capital": "Tehran",
    "region": "Asia",
    "subregion": "Southern Asia",
    "states": [
      {
          "name": "Alborz Province",
          "code": "32"
      },
      {
          "name": "Ardabil Province",
          "code": "03"
      },
      {
          "name": "Bushehr Province",
          "code": "06"
      },
      {
          "name": "Chaharmahal and Bakhtiari Province",
          "code": "08"
      },
      {
          "name": "East Azerbaijan Province",
          "code": "01"
      },
      {
          "name": "Fars Province",
          "code": "14"
      },
      {
          "name": "Gilan Province",
          "code": "19"
      },
      {
          "name": "Golestan Province",
          "code": "27"
      },
      {
          "name": "Hormozgan Province",
          "code": "23"
      },
      {
          "name": "Ilam Province",
          "code": "05"
      },
      {
          "name": "Isfahan Province",
          "code": "04"
      },
      {
          "name": "Kerman Province",
          "code": "15"
      },
      {
          "name": "Kermanshah Province",
          "code": "17"
      },
      {
          "name": "Khuzestan Province",
          "code": "10"
      },
      {
          "name": "Kohgiluyeh and Boyer-Ahmad Province",
          "code": "18"
      },
      {
          "name": "Kurdistan Province",
          "code": "16"
      },
      {
          "name": "Lorestan Province",
          "code": "20"
      },
      {
          "name": "Markazi Province",
          "code": "22"
      },
      {
          "name": "Mazandaran Province",
          "code": "21"
      },
      {
          "name": "North Khorasan Province",
          "code": "31"
      },
      {
          "name": "Qazvin Province",
          "code": "28"
      },
      {
          "name": "Qom Province",
          "code": "26"
      },
      {
          "name": "Razavi Khorasan Province",
          "code": "30"
      },
      {
          "name": "Semnan Province",
          "code": "12"
      },
      {
          "name": "Sistan and Baluchestan",
          "code": "13"
      },
      {
          "name": "South Khorasan Province",
          "code": "29"
      },
      {
          "name": "Tehran Province",
          "code": "07"
      },
      {
          "name": "West Azarbaijan Province",
          "code": "02"
      },
      {
          "name": "Yazd Province",
          "code": "25"
      },
      {
          "name": "Zanjan Province",
          "code": "11"
      }
    ]
  },
  {
    "code2": "IQ",
    "code3": "IRQ",
    "name": "Iraq",
    "capital": "Baghdad",
    "region": "Asia",
    "subregion": "Western Asia",
    "states": [
      {
          "name": "Al Anbar Governorate",
          "code": "AN"
      },
      {
          "name": "Al Muthanna Governorate",
          "code": "MU"
      },
      {
          "name": "Al-Qādisiyyah Governorate",
          "code": "QA"
      },
      {
          "name": "Babylon Governorate",
          "code": "BB"
      },
      {
          "name": "Baghdad Governorate",
          "code": "BG"
      },
      {
          "name": "Basra Governorate",
          "code": "BA"
      },
      {
          "name": "Dhi Qar Governorate",
          "code": "DQ"
      },
      {
          "name": "Diyala Governorate",
          "code": "DI"
      },
      {
          "name": "Dohuk Governorate",
          "code": "DA"
      },
      {
          "name": "Erbil Governorate",
          "code": "AR"
      },
      {
          "name": "Karbala Governorate",
          "code": "KA"
      },
      {
          "name": "Kirkuk Governorate",
          "code": "KI"
      },
      {
          "name": "Maysan Governorate",
          "code": "MA"
      },
      {
          "name": "Najaf Governorate",
          "code": "NA"
      },
      {
          "name": "Nineveh Governorate",
          "code": "NI"
      },
      {
          "name": "Saladin Governorate",
          "code": "SD"
      },
      {
          "name": "Sulaymaniyah Governorate",
          "code": "SU"
      },
      {
          "name": "Wasit Governorate",
          "code": "WA"
      }
    ]
  },
  {
    "code2": "IE",
    "code3": "IRL",
    "name": "Republic of Ireland",
    "capital": "Dublin",
    "region": "Europe",
    "subregion": "Northern Europe",
    "states": [
      {
          "name": "Connacht",
          "code": "C"
      },
      {
          "name": "County Carlow",
          "code": "CW"
      },
      {
          "name": "County Cavan",
          "code": "CN"
      },
      {
          "name": "County Clare",
          "code": "CE"
      },
      {
          "name": "County Cork",
          "code": "CO"
      },
      {
          "name": "County Donegal",
          "code": "DL"
      },
      {
          "name": "County Dublin",
          "code": "D"
      },
      {
          "name": "County Galway",
          "code": "G"
      },
      {
          "name": "County Kerry",
          "code": "KY"
      },
      {
          "name": "County Kildare",
          "code": "KE"
      },
      {
          "name": "County Kilkenny",
          "code": "KK"
      },
      {
          "name": "County Laois",
          "code": "LS"
      },
      {
          "name": "County Limerick",
          "code": "LK"
      },
      {
          "name": "County Longford",
          "code": "LD"
      },
      {
          "name": "County Louth",
          "code": "LH"
      },
      {
          "name": "County Mayo",
          "code": "MO"
      },
      {
          "name": "County Meath",
          "code": "MH"
      },
      {
          "name": "County Monaghan",
          "code": "MN"
      },
      {
          "name": "County Offaly",
          "code": "OY"
      },
      {
          "name": "County Roscommon",
          "code": "RN"
      },
      {
          "name": "County Sligo",
          "code": "SO"
      },
      {
          "name": "County Tipperary",
          "code": "TA"
      },
      {
          "name": "County Waterford",
          "code": "WD"
      },
      {
          "name": "County Westmeath",
          "code": "WH"
      },
      {
          "name": "County Wexford",
          "code": "WX"
      },
      {
          "name": "County Wicklow",
          "code": "WW"
      },
      {
          "name": "Leinster",
          "code": "L"
      },
      {
          "name": "Munster",
          "code": "M"
      },
      {
          "name": "Ulster",
          "code": "U"
      }
    ]
  },
  {
    "code2": "IM",
    "code3": "IMN",
    "name": "Isle of Man",
    "capital": "Douglas",
    "region": "Europe",
    "subregion": "Northern Europe",
    "states": []
  },
  {
    "code2": "IL",
    "code3": "ISR",
    "name": "Israel",
    "capital": "Jerusalem",
    "region": "Asia",
    "subregion": "Western Asia",
    "states": [
      {
          "name": "Central District",
          "code": "M"
      },
      {
          "name": "Haifa District",
          "code": "HA"
      },
      {
          "name": "Jerusalem District",
          "code": "JM"
      },
      {
          "name": "Northern District",
          "code": "Z"
      },
      {
          "name": "Southern District",
          "code": "D"
      },
      {
          "name": "Tel Aviv District",
          "code": "TA"
      }
    ]
  },
  {
    "code2": "IT",
    "code3": "ITA",
    "name": "Italy",
    "capital": "Rome",
    "region": "Europe",
    "subregion": "Southern Europe",
    "states": [
      {
          "name": "Abruzzo",
          "code": "65"
      },
      {
          "name": "Aosta Valley",
          "code": "23"
      },
      {
          "name": "Apulia",
          "code": "75"
      },
      {
          "name": "Basilicata",
          "code": "77"
      },
      {
          "name": "Benevento Province",
          "code": "BN"
      },
      {
          "name": "Calabria",
          "code": "78"
      },
      {
          "name": "Campania",
          "code": "72"
      },
      {
          "name": "Emilia-Romagna",
          "code": "45"
      },
      {
          "name": "Friuli–Venezia Giulia",
          "code": "36"
      },
      {
          "name": "Lazio",
          "code": "62"
      },
      {
          "name": "Libero consorzio comunale di Agrigento",
          "code": "AG"
      },
      {
          "name": "Libero consorzio comunale di Caltanissetta",
          "code": "CL"
      },
      {
          "name": "Libero consorzio comunale di Enna",
          "code": "EN"
      },
      {
          "name": "Libero consorzio comunale di Ragusa",
          "code": "RG"
      },
      {
          "name": "Libero consorzio comunale di Siracusa",
          "code": "SR"
      },
      {
          "name": "Libero consorzio comunale di Trapani",
          "code": "TP"
      },
      {
          "name": "Liguria",
          "code": "42"
      },
      {
          "name": "Lombardy",
          "code": "25"
      },
      {
          "name": "Marche",
          "code": "57"
      },
      {
          "name": "Metropolitan City of Bari",
          "code": "BA"
      },
      {
          "name": "Metropolitan City of Bologna",
          "code": "BO"
      },
      {
          "name": "Metropolitan City of Cagliari",
          "code": "CA"
      },
      {
          "name": "Metropolitan City of Catania",
          "code": "CT"
      },
      {
          "name": "Metropolitan City of Florence",
          "code": "FI"
      },
      {
          "name": "Metropolitan City of Genoa",
          "code": "GE"
      },
      {
          "name": "Metropolitan City of Messina",
          "code": "ME"
      },
      {
          "name": "Metropolitan City of Milan",
          "code": "MI"
      },
      {
          "name": "Metropolitan City of Naples",
          "code": "NA"
      },
      {
          "name": "Metropolitan City of Palermo",
          "code": "PA"
      },
      {
          "name": "Metropolitan City of Reggio Calabria",
          "code": "RC"
      },
      {
          "name": "Metropolitan City of Rome",
          "code": "RM"
      },
      {
          "name": "Metropolitan City of Turin",
          "code": "TO"
      },
      {
          "name": "Metropolitan City of Venice",
          "code": "VE"
      },
      {
          "name": "Molise",
          "code": "67"
      },
      {
          "name": "Pesaro and Urbino Province",
          "code": "PU"
      },
      {
          "name": "Piedmont",
          "code": "21"
      },
      {
          "name": "Province of Alessandria",
          "code": "AL"
      },
      {
          "name": "Province of Ancona",
          "code": "AN"
      },
      {
          "name": "Province of Ascoli Piceno",
          "code": "AP"
      },
      {
          "name": "Province of Asti",
          "code": "AT"
      },
      {
          "name": "Province of Avellino",
          "code": "AV"
      },
      {
          "name": "Province of Barletta-Andria-Trani",
          "code": "BT"
      },
      {
          "name": "Province of Belluno",
          "code": "BL"
      },
      {
          "name": "Province of Bergamo",
          "code": "BG"
      },
      {
          "name": "Province of Biella",
          "code": "BI"
      },
      {
          "name": "Province of Brescia",
          "code": "BS"
      },
      {
          "name": "Province of Brindisi",
          "code": "BR"
      },
      {
          "name": "Province of Campobasso",
          "code": "CB"
      },
      {
          "name": "Province of Carbonia-Iglesias",
          "code": "CI"
      },
      {
          "name": "Province of Caserta",
          "code": "CE"
      },
      {
          "name": "Province of Catanzaro",
          "code": "CZ"
      },
      {
          "name": "Province of Chieti",
          "code": "CH"
      },
      {
          "name": "Province of Como",
          "code": "CO"
      },
      {
          "name": "Province of Cosenza",
          "code": "CS"
      },
      {
          "name": "Province of Cremona",
          "code": "CR"
      },
      {
          "name": "Province of Crotone",
          "code": "KR"
      },
      {
          "name": "Province of Cuneo",
          "code": "CN"
      },
      {
          "name": "Province of Fermo",
          "code": "FM"
      },
      {
          "name": "Province of Ferrara",
          "code": "FE"
      },
      {
          "name": "Province of Foggia",
          "code": "FG"
      },
      {
          "name": "Province of Forlì-Cesena",
          "code": "FC"
      },
      {
          "name": "Province of Frosinone",
          "code": "FR"
      },
      {
          "name": "Province of Gorizia",
          "code": "GO"
      },
      {
          "name": "Province of Grosseto",
          "code": "GR"
      },
      {
          "name": "Province of Imperia",
          "code": "IM"
      },
      {
          "name": "Province of Isernia",
          "code": "IS"
      },
      {
          "name": "Province of L'Aquila",
          "code": "AQ"
      },
      {
          "name": "Province of La Spezia",
          "code": "SP"
      },
      {
          "name": "Province of Latina",
          "code": "LT"
      },
      {
          "name": "Province of Lecce",
          "code": "LE"
      },
      {
          "name": "Province of Lecco",
          "code": "LC"
      },
      {
          "name": "Province of Livorno",
          "code": "LI"
      },
      {
          "name": "Province of Lodi",
          "code": "LO"
      },
      {
          "name": "Province of Lucca",
          "code": "LU"
      },
      {
          "name": "Province of Macerata",
          "code": "MC"
      },
      {
          "name": "Province of Mantua",
          "code": "MN"
      },
      {
          "name": "Province of Massa and Carrara",
          "code": "MS"
      },
      {
          "name": "Province of Matera",
          "code": "MT"
      },
      {
          "name": "Province of Medio Campidano",
          "code": "VS"
      },
      {
          "name": "Province of Modena",
          "code": "MO"
      },
      {
          "name": "Province of Monza and Brianza",
          "code": "MB"
      },
      {
          "name": "Province of Novara",
          "code": "NO"
      },
      {
          "name": "Province of Nuoro",
          "code": "NU"
      },
      {
          "name": "Province of Ogliastra",
          "code": "OG"
      },
      {
          "name": "Province of Olbia-Tempio",
          "code": "OT"
      },
      {
          "name": "Province of Oristano",
          "code": "OR"
      },
      {
          "name": "Province of Padua",
          "code": "PD"
      },
      {
          "name": "Province of Parma",
          "code": "PR"
      },
      {
          "name": "Province of Pavia",
          "code": "PV"
      },
      {
          "name": "Province of Perugia",
          "code": "PG"
      },
      {
          "name": "Province of Pescara",
          "code": "PE"
      },
      {
          "name": "Province of Piacenza",
          "code": "PC"
      },
      {
          "name": "Province of Pisa",
          "code": "PI"
      },
      {
          "name": "Province of Pistoia",
          "code": "PT"
      },
      {
          "name": "Province of Pordenone",
          "code": "PN"
      },
      {
          "name": "Province of Potenza",
          "code": "PZ"
      },
      {
          "name": "Province of Prato",
          "code": "PO"
      },
      {
          "name": "Province of Ravenna",
          "code": "RA"
      },
      {
          "name": "Province of Reggio Emilia",
          "code": "RE"
      },
      {
          "name": "Province of Rieti",
          "code": "RI"
      },
      {
          "name": "Province of Rimini",
          "code": "RN"
      },
      {
          "name": "Province of Rovigo",
          "code": "RO"
      },
      {
          "name": "Province of Salerno",
          "code": "SA"
      },
      {
          "name": "Province of Sassari",
          "code": "SS"
      },
      {
          "name": "Province of Savona",
          "code": "SV"
      },
      {
          "name": "Province of Siena",
          "code": "SI"
      },
      {
          "name": "Province of Sondrio",
          "code": "SO"
      },
      {
          "name": "Province of Taranto",
          "code": "TA"
      },
      {
          "name": "Province of Teramo",
          "code": "TE"
      },
      {
          "name": "Province of Terni",
          "code": "TR"
      },
      {
          "name": "Province of Treviso",
          "code": "TV"
      },
      {
          "name": "Province of Trieste",
          "code": "TS"
      },
      {
          "name": "Province of Udine",
          "code": "UD"
      },
      {
          "name": "Province of Varese",
          "code": "VA"
      },
      {
          "name": "Province of Verbano-Cusio-Ossola",
          "code": "VB"
      },
      {
          "name": "Province of Vercelli",
          "code": "VC"
      },
      {
          "name": "Province of Verona",
          "code": "VR"
      },
      {
          "name": "Province of Vibo Valentia",
          "code": "VV"
      },
      {
          "name": "Province of Vicenza",
          "code": "VI"
      },
      {
          "name": "Province of Viterbo",
          "code": "VT"
      },
      {
          "name": "Sardinia",
          "code": "88"
      },
      {
          "name": "Sicily",
          "code": "82"
      },
      {
          "name": "South Tyrol",
          "code": "BZ"
      },
      {
          "name": "Trentino",
          "code": "TN"
      },
      {
          "name": "Trentino-South Tyrol",
          "code": "32"
      },
      {
          "name": "Tuscany",
          "code": "52"
      },
      {
          "name": "Umbria",
          "code": "55"
      },
      {
          "name": "Veneto",
          "code": "34"
      }
    ]
  },
  {
    "code2": "JM",
    "code3": "JAM",
    "name": "Jamaica",
    "capital": "Kingston",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": [
      {
          "name": "Clarendon Parish",
          "code": "13"
      },
      {
          "name": "Hanover Parish",
          "code": "09"
      },
      {
          "name": "Kingston Parish",
          "code": "01"
      },
      {
          "name": "Manchester Parish",
          "code": "12"
      },
      {
          "name": "Portland Parish",
          "code": "04"
      },
      {
          "name": "Saint Andrew",
          "code": "02"
      },
      {
          "name": "Saint Ann Parish",
          "code": "06"
      },
      {
          "name": "Saint Catherine Parish",
          "code": "14"
      },
      {
          "name": "Saint Elizabeth Parish",
          "code": "11"
      },
      {
          "name": "Saint James Parish",
          "code": "08"
      },
      {
          "name": "Saint Mary Parish",
          "code": "05"
      },
      {
          "name": "Saint Thomas Parish",
          "code": "03"
      },
      {
          "name": "Trelawny Parish",
          "code": "07"
      },
      {
          "name": "Westmoreland Parish",
          "code": "10"
      }
    ]
  },
  {
    "code2": "JP",
    "code3": "JPN",
    "name": "Japan",
    "capital": "Tokyo",
    "region": "Asia",
    "subregion": "Eastern Asia",
    "states": [
      {
          "name": "Aichi Prefecture",
          "code": "23"
      },
      {
          "name": "Akita Prefecture",
          "code": "05"
      },
      {
          "name": "Aomori Prefecture",
          "code": "02"
      },
      {
          "name": "Chiba Prefecture",
          "code": "12"
      },
      {
          "name": "Ehime Prefecture",
          "code": "38"
      },
      {
          "name": "Fukui Prefecture",
          "code": "18"
      },
      {
          "name": "Fukuoka Prefecture",
          "code": "40"
      },
      {
          "name": "Fukushima Prefecture",
          "code": "07"
      },
      {
          "name": "Gifu Prefecture",
          "code": "21"
      },
      {
          "name": "Gunma Prefecture",
          "code": "10"
      },
      {
          "name": "Hiroshima Prefecture",
          "code": "34"
      },
      {
          "name": "Hokkaidō Prefecture",
          "code": "01"
      },
      {
          "name": "Hyōgo Prefecture",
          "code": "28"
      },
      {
          "name": "Ibaraki Prefecture",
          "code": "08"
      },
      {
          "name": "Ishikawa Prefecture",
          "code": "17"
      },
      {
          "name": "Iwate Prefecture",
          "code": "03"
      },
      {
          "name": "Kagawa Prefecture",
          "code": "37"
      },
      {
          "name": "Kagoshima Prefecture",
          "code": "46"
      },
      {
          "name": "Kanagawa Prefecture",
          "code": "14"
      },
      {
          "name": "Kumamoto Prefecture",
          "code": "43"
      },
      {
          "name": "Kyōto Prefecture",
          "code": "26"
      },
      {
          "name": "Mie Prefecture",
          "code": "24"
      },
      {
          "name": "Miyagi Prefecture",
          "code": "04"
      },
      {
          "name": "Miyazaki Prefecture",
          "code": "45"
      },
      {
          "name": "Nagano Prefecture",
          "code": "20"
      },
      {
          "name": "Nagasaki Prefecture",
          "code": "42"
      },
      {
          "name": "Nara Prefecture",
          "code": "29"
      },
      {
          "name": "Niigata Prefecture",
          "code": "15"
      },
      {
          "name": "Ōita Prefecture",
          "code": "44"
      },
      {
          "name": "Okayama Prefecture",
          "code": "33"
      },
      {
          "name": "Okinawa Prefecture",
          "code": "47"
      },
      {
          "name": "Ōsaka Prefecture",
          "code": "27"
      },
      {
          "name": "Saga Prefecture",
          "code": "41"
      },
      {
          "name": "Saitama Prefecture",
          "code": "11"
      },
      {
          "name": "Shiga Prefecture",
          "code": "25"
      },
      {
          "name": "Shimane Prefecture",
          "code": "32"
      },
      {
          "name": "Shizuoka Prefecture",
          "code": "22"
      },
      {
          "name": "Tochigi Prefecture",
          "code": "09"
      },
      {
          "name": "Tokushima Prefecture",
          "code": "36"
      },
      {
          "name": "Tokyo",
          "code": "13"
      },
      {
          "name": "Tottori Prefecture",
          "code": "31"
      },
      {
          "name": "Toyama Prefecture",
          "code": "16"
      },
      {
          "name": "Wakayama Prefecture",
          "code": "30"
      },
      {
          "name": "Yamagata Prefecture",
          "code": "06"
      },
      {
          "name": "Yamaguchi Prefecture",
          "code": "35"
      },
      {
          "name": "Yamanashi Prefecture",
          "code": "19"
      }
    ]
  },
  {
    "code2": "JE",
    "code3": "JEY",
    "name": "Jersey",
    "capital": "Saint Helier",
    "region": "Europe",
    "subregion": "Northern Europe",
    "states": []
  },
  {
    "code2": "JO",
    "code3": "JOR",
    "name": "Jordan",
    "capital": "Amman",
    "region": "Asia",
    "subregion": "Western Asia",
    "states": [
      {
          "name": "Ajloun Governorate",
          "code": "AJ"
      },
      {
          "name": "Amman Governorate",
          "code": "AM"
      },
      {
          "name": "Aqaba Governorate",
          "code": "AQ"
      },
      {
          "name": "Balqa Governorate",
          "code": "BA"
      },
      {
          "name": "Irbid Governorate",
          "code": "IR"
      },
      {
          "name": "Jerash Governorate",
          "code": "JA"
      },
      {
          "name": "Karak Governorate",
          "code": "KA"
      },
      {
          "name": "Ma'an Governorate",
          "code": "MN"
      },
      {
          "name": "Madaba Governorate",
          "code": "MD"
      },
      {
          "name": "Mafraq Governorate",
          "code": "MA"
      },
      {
          "name": "Tafilah Governorate",
          "code": "AT"
      },
      {
          "name": "Zarqa Governorate",
          "code": "AZ"
      }
    ]
  },
  {
    "code2": "KZ",
    "code3": "KAZ",
    "name": "Kazakhstan",
    "capital": "Astana",
    "region": "Asia",
    "subregion": "Central Asia",
    "states": [
      {
          "name": "Akmola Region",
          "code": "AKM"
      },
      {
          "name": "Aktobe Region",
          "code": "AKT"
      },
      {
          "name": "Almaty",
          "code": "ALA"
      },
      {
          "name": "Almaty Region",
          "code": "ALM"
      },
      {
          "name": "Atyrau Region",
          "code": "ATY"
      },
      {
          "name": "Baikonur",
          "code": "BAY"
      },
      {
          "name": "East Kazakhstan Region",
          "code": "VOS"
      },
      {
          "name": "Jambyl Region",
          "code": "ZHA"
      },
      {
          "name": "Karaganda Region",
          "code": "KAR"
      },
      {
          "name": "Kostanay Region",
          "code": "KUS"
      },
      {
          "name": "Kyzylorda Region",
          "code": "KZY"
      },
      {
          "name": "Mangystau Region",
          "code": "MAN"
      },
      {
          "name": "North Kazakhstan Region",
          "code": "SEV"
      },
      {
          "name": "Nur-Sultan",
          "code": "AST"
      },
      {
          "name": "Pavlodar Region",
          "code": "PAV"
      },
      {
          "name": "Turkestan Region",
          "code": "YUZ"
      },
      {
          "name": "West Kazakhstan Province",
          "code": "ZAP"
      }
    ]
  },
  {
    "code2": "KE",
    "code3": "KEN",
    "name": "Kenya",
    "capital": "Nairobi",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "states": [
      {
          "name": "Baringo County",
          "code": "01"
      },
      {
          "name": "Bomet County",
          "code": "02"
      },
      {
          "name": "Bungoma County",
          "code": "03"
      },
      {
          "name": "Busia County",
          "code": "04"
      },
      {
          "name": "Central Province",
          "code": "200"
      },
      {
          "name": "Coast Province",
          "code": "300"
      },
      {
          "name": "Eastern Province",
          "code": "400"
      },
      {
          "name": "Elgeyo-Marakwet County",
          "code": "05"
      },
      {
          "name": "Embu County",
          "code": "06"
      },
      {
          "name": "Garissa County",
          "code": "07"
      },
      {
          "name": "Homa Bay County",
          "code": "08"
      },
      {
          "name": "Isiolo County",
          "code": "09"
      },
      {
          "name": "Kajiado County",
          "code": "10"
      },
      {
          "name": "Kakamega County",
          "code": "11"
      },
      {
          "name": "Kericho County",
          "code": "12"
      },
      {
          "name": "Kiambu County",
          "code": "13"
      },
      {
          "name": "Kilifi County",
          "code": "14"
      },
      {
          "name": "Kirinyaga County",
          "code": "15"
      },
      {
          "name": "Kisii County",
          "code": "16"
      },
      {
          "name": "Kisumu County",
          "code": "17"
      },
      {
          "name": "Kitui County",
          "code": "18"
      },
      {
          "name": "Kwale County",
          "code": "19"
      },
      {
          "name": "Laikipia County",
          "code": "20"
      },
      {
          "name": "Lamu County",
          "code": "21"
      },
      {
          "name": "Machakos County",
          "code": "22"
      },
      {
          "name": "Makueni County",
          "code": "23"
      },
      {
          "name": "Mandera County",
          "code": "24"
      },
      {
          "name": "Marsabit County",
          "code": "25"
      },
      {
          "name": "Meru County",
          "code": "26"
      },
      {
          "name": "Migori County",
          "code": "27"
      },
      {
          "name": "Mombasa County",
          "code": "28"
      },
      {
          "name": "Muranga County",
          "code": "29"
      },
      {
          "name": "Nairobi",
          "code": "110"
      },
      {
          "name": "Nakuru District",
          "code": "31"
      },
      {
          "name": "Nandi District",
          "code": "32"
      },
      {
          "name": "Narok County",
          "code": "33"
      },
      {
          "name": "North Eastern Province",
          "code": "500"
      },
      {
          "name": "Nyamira District",
          "code": "34"
      },
      {
          "name": "Nyandarua County",
          "code": "35"
      },
      {
          "name": "Nyanza Province",
          "code": "600"
      },
      {
          "name": "Nyeri County",
          "code": "36"
      },
      {
          "name": "Rift Valley Province",
          "code": "700"
      },
      {
          "name": "Samburu County",
          "code": "37"
      },
      {
          "name": "Siaya County",
          "code": "38"
      },
      {
          "name": "Taita–Taveta County",
          "code": "39"
      },
      {
          "name": "Tana River County",
          "code": "40"
      },
      {
          "name": "Tharaka Nithi County",
          "code": "41"
      },
      {
          "name": "Trans-Nzoia District",
          "code": "42"
      },
      {
          "name": "Turkana County",
          "code": "43"
      },
      {
          "name": "Uasin Gishu District",
          "code": "44"
      },
      {
          "name": "Vihiga District",
          "code": "45"
      },
      {
          "name": "Wajir County",
          "code": "46"
      },
      {
          "name": "West Pokot County",
          "code": "47"
      },
      {
          "name": "Western Province",
          "code": "800"
      }
    ]
  },
  {
    "code2": "KI",
    "code3": "KIR",
    "name": "Kiribati",
    "capital": "Tarawa",
    "region": "Oceania",
    "subregion": "Micronesia",
    "states": [
      {
          "name": "Gilbert Islands",
          "code": "G"
      },
      {
          "name": "Line Islands",
          "code": "L"
      },
      {
          "name": "Phoenix Islands",
          "code": "P"
      }
    ]
  },
  {
    "code2": "KP",
    "code3": "PRK",
    "name": "Korea North",
    "capital": "Pyongyang",
    "region": "Asia",
    "subregion": "Eastern Asia",
    "states": [
        {
            "name": "Chagang Province",
            "code": "04"
        },
        {
            "name": "Kangwon Province",
            "code": "07"
        },
        {
            "name": "North Hamgyong Province",
            "code": "09"
        },
        {
            "name": "North Hwanghae Province",
            "code": "06"
        },
        {
            "name": "North Pyongan Province",
            "code": "03"
        },
        {
            "name": "Pyongyang",
            "code": "01"
        },
        {
            "name": "Rason",
            "code": "13"
        },
        {
            "name": "Ryanggang Province",
            "code": "10"
        },
        {
            "name": "South Hamgyong Province",
            "code": "08"
        },
        {
            "name": "South Hwanghae Province",
            "code": "05"
        },
        {
            "name": "South Pyongan Province",
            "code": "02"
        }
    ]
  },
  {
    "code2": "KR",
    "code3": "KOR",
    "name": "Korea South",
    "capital": "Seoul",
    "region": "Asia",
    "subregion": "Eastern Asia",
    "states": [
        {
            "name": "Busan",
            "code": "26"
        },
        {
            "name": "Daegu",
            "code": "27"
        },
        {
            "name": "Daejeon",
            "code": "30"
        },
        {
            "name": "Gangwon Province",
            "code": "42"
        },
        {
            "name": "Gwangju",
            "code": "29"
        },
        {
            "name": "Gyeonggi Province",
            "code": "41"
        },
        {
            "name": "Incheon",
            "code": "28"
        },
        {
            "name": "Jeju",
            "code": "49"
        },
        {
            "name": "North Chungcheong Province",
            "code": "43"
        },
        {
            "name": "North Gyeongsang Province",
            "code": "47"
        },
        {
            "name": "North Jeolla Province",
            "code": "45"
        },
        {
            "name": "Sejong City",
            "code": "50"
        },
        {
            "name": "Seoul",
            "code": "11"
        },
        {
            "name": "South Chungcheong Province",
            "code": "44"
        },
        {
            "name": "South Gyeongsang Province",
            "code": "48"
        },
        {
            "name": "South Jeolla Province",
            "code": "46"
        },
        {
            "name": "Ulsan",
            "code": "31"
        }
    ]
  },
  {
    "code2": "XK",
    "code3": "XKX",
    "name": "Kosovo",
    "capital": "Pristina",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "states": [
        {
            "name": "Đakovica District (Gjakove)",
            "code": "XDG"
        },
        {
            "name": "Gjilan District",
            "code": "XGJ"
        },
        {
            "name": "Kosovska Mitrovica District",
            "code": "XKM"
        },
        {
            "name": "Peć District",
            "code": "XPE"
        },
        {
            "name": "Pristina (Priştine)",
            "code": "XPI"
        },
        {
            "name": "Prizren District",
            "code": "XPR"
        },
        {
            "name": "Uroševac District (Ferizaj)",
            "code": "XUF"
        }
    ]
  },
  {
    "code2": "KW",
    "code3": "KWT",
    "name": "Kuwait",
    "capital": "Kuwait City",
    "region": "Asia",
    "subregion": "Western Asia",
    "states": [
        {
            "name": "Al Ahmadi Governorate",
            "code": "AH"
        },
        {
            "name": "Al Farwaniyah Governorate",
            "code": "FA"
        },
        {
            "name": "Al Jahra Governorate",
            "code": "JA"
        },
        {
            "name": "Capital Governorate",
            "code": "KU"
        },
        {
            "name": "Hawalli Governorate",
            "code": "HA"
        },
        {
            "name": "Mubarak Al-Kabeer Governorate",
            "code": "MU"
        }
    ]
  },
  {
    "code2": "KG",
    "code3": "KGZ",
    "name": "Kyrgyzstan",
    "capital": "Bishkek",
    "region": "Asia",
    "subregion": "Central Asia",
    "states": [
        {
            "name": "Batken Region",
            "code": "B"
        },
        {
            "name": "Bishkek",
            "code": "GB"
        },
        {
            "name": "Chuy Region",
            "code": "C"
        },
        {
            "name": "Issyk-Kul Region",
            "code": "Y"
        },
        {
            "name": "Jalal-Abad Region",
            "code": "J"
        },
        {
            "name": "Naryn Region",
            "code": "N"
        },
        {
            "name": "Osh",
            "code": "GO"
        },
        {
            "name": "Osh Region",
            "code": "O"
        },
        {
            "name": "Talas Region",
            "code": "T"
        }
    ]
  },
  {
    "code2": "LA",
    "code3": "LAO",
    "name": "Laos",
    "capital": "Vientiane",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "states": [
        {
            "name": "Attapeu Province",
            "code": "AT"
        },
        {
            "name": "Bokeo Province",
            "code": "BK"
        },
        {
            "name": "Bolikhamsai Province",
            "code": "BL"
        },
        {
            "name": "Champasak Province",
            "code": "CH"
        },
        {
            "name": "Houaphanh Province",
            "code": "HO"
        },
        {
            "name": "Khammouane Province",
            "code": "KH"
        },
        {
            "name": "Luang Namtha Province",
            "code": "LM"
        },
        {
            "name": "Luang Prabang Province",
            "code": "LP"
        },
        {
            "name": "Oudomxay Province",
            "code": "OU"
        },
        {
            "name": "Phongsaly Province",
            "code": "PH"
        },
        {
            "name": "Sainyabuli Province",
            "code": "XA"
        },
        {
            "name": "Salavan Province",
            "code": "SL"
        },
        {
            "name": "Savannakhet Province",
            "code": "SV"
        },
        {
            "name": "Sekong Province",
            "code": "XE"
        },
        {
            "name": "Vientiane Prefecture",
            "code": "VT"
        },
        {
            "name": "Vientiane Province",
            "code": "VI"
        },
        {
            "name": "Xaisomboun",
            "code": "XN"
        },
        {
            "name": "Xaisomboun Province",
            "code": "XS"
        },
        {
            "name": "Xiangkhouang Province",
            "code": "XI"
        }
    ]
  },
  {
    "code2": "LV",
    "code3": "LVA",
    "name": "Latvia",
    "capital": "Riga",
    "region": "Europe",
    "subregion": "Northern Europe",
    "states": [
        {
            "name": "Aglona Municipality",
            "code": "001"
        },
        {
            "name": "Aizkraukle Municipality",
            "code": "002"
        },
        {
            "name": "Aizpute Municipality",
            "code": "003"
        },
        {
            "name": "Aknīste Municipality",
            "code": "004"
        },
        {
            "name": "Aloja Municipality",
            "code": "005"
        },
        {
            "name": "Alsunga Municipality",
            "code": "006"
        },
        {
            "name": "Alūksne Municipality",
            "code": "007"
        },
        {
            "name": "Amata Municipality",
            "code": "008"
        },
        {
            "name": "Ape Municipality",
            "code": "009"
        },
        {
            "name": "Auce Municipality",
            "code": "010"
        },
        {
            "name": "Babīte Municipality",
            "code": "012"
        },
        {
            "name": "Baldone Municipality",
            "code": "013"
        },
        {
            "name": "Baltinava Municipality",
            "code": "014"
        },
        {
            "name": "Balvi Municipality",
            "code": "015"
        },
        {
            "name": "Bauska Municipality",
            "code": "016"
        },
        {
            "name": "Beverīna Municipality",
            "code": "017"
        },
        {
            "name": "Brocēni Municipality",
            "code": "018"
        },
        {
            "name": "Burtnieki Municipality",
            "code": "019"
        },
        {
            "name": "Carnikava Municipality",
            "code": "020"
        },
        {
            "name": "Cēsis Municipality",
            "code": "022"
        },
        {
            "name": "Cesvaine Municipality",
            "code": "021"
        },
        {
            "name": "Cibla Municipality",
            "code": "023"
        },
        {
            "name": "Dagda Municipality",
            "code": "024"
        },
        {
            "name": "Daugavpils",
            "code": "DGV"
        },
        {
            "name": "Daugavpils Municipality",
            "code": "025"
        },
        {
            "name": "Dobele Municipality",
            "code": "026"
        },
        {
            "name": "Dundaga Municipality",
            "code": "027"
        },
        {
            "name": "Durbe Municipality",
            "code": "028"
        },
        {
            "name": "Engure Municipality",
            "code": "029"
        },
        {
            "name": "Ērgļi Municipality",
            "code": "030"
        },
        {
            "name": "Garkalne Municipality",
            "code": "031"
        },
        {
            "name": "Grobiņa Municipality",
            "code": "032"
        },
        {
            "name": "Gulbene Municipality",
            "code": "033"
        },
        {
            "name": "Iecava Municipality",
            "code": "034"
        },
        {
            "name": "Ikšķile Municipality",
            "code": "035"
        },
        {
            "name": "Ilūkste Municipality",
            "code": "036"
        },
        {
            "name": "Inčukalns Municipality",
            "code": "037"
        },
        {
            "name": "Jaunjelgava Municipality",
            "code": "038"
        },
        {
            "name": "Jaunpiebalga Municipality",
            "code": "039"
        },
        {
            "name": "Jaunpils Municipality",
            "code": "040"
        },
        {
            "name": "Jēkabpils",
            "code": "JKB"
        },
        {
            "name": "Jēkabpils Municipality",
            "code": "042"
        },
        {
            "name": "Jelgava",
            "code": "JEL"
        },
        {
            "name": "Jelgava Municipality",
            "code": "041"
        },
        {
            "name": "Jūrmala",
            "code": "JUR"
        },
        {
            "name": "Kandava Municipality",
            "code": "043"
        },
        {
            "name": "Kārsava Municipality",
            "code": "044"
        },
        {
            "name": "Ķegums Municipality",
            "code": "051"
        },
        {
            "name": "Ķekava Municipality",
            "code": "052"
        },
        {
            "name": "Kocēni Municipality",
            "code": "045"
        },
        {
            "name": "Koknese Municipality",
            "code": "046"
        },
        {
            "name": "Krāslava Municipality",
            "code": "047"
        },
        {
            "name": "Krimulda Municipality",
            "code": "048"
        },
        {
            "name": "Krustpils Municipality",
            "code": "049"
        },
        {
            "name": "Kuldīga Municipality",
            "code": "050"
        },
        {
            "name": "Lielvārde Municipality",
            "code": "053"
        },
        {
            "name": "Liepāja",
            "code": "LPX"
        },
        {
            "name": "Līgatne Municipality",
            "code": "055"
        },
        {
            "name": "Limbaži Municipality",
            "code": "054"
        },
        {
            "name": "Līvāni Municipality",
            "code": "056"
        },
        {
            "name": "Lubāna Municipality",
            "code": "057"
        },
        {
            "name": "Ludza Municipality",
            "code": "058"
        },
        {
            "name": "Madona Municipality",
            "code": "059"
        },
        {
            "name": "Mālpils Municipality",
            "code": "061"
        },
        {
            "name": "Mārupe Municipality",
            "code": "062"
        },
        {
            "name": "Mazsalaca Municipality",
            "code": "060"
        },
        {
            "name": "Mērsrags Municipality",
            "code": "063"
        },
        {
            "name": "Naukšēni Municipality",
            "code": "064"
        },
        {
            "name": "Nereta Municipality",
            "code": "065"
        },
        {
            "name": "Nīca Municipality",
            "code": "066"
        },
        {
            "name": "Ogre Municipality",
            "code": "067"
        },
        {
            "name": "Olaine Municipality",
            "code": "068"
        },
        {
            "name": "Ozolnieki Municipality",
            "code": "069"
        },
        {
            "name": "Pārgauja Municipality",
            "code": "070"
        },
        {
            "name": "Pāvilosta Municipality",
            "code": "071"
        },
        {
            "name": "Pļaviņas Municipality",
            "code": "072"
        },
        {
            "name": "Preiļi Municipality",
            "code": "073"
        },
        {
            "name": "Priekule Municipality",
            "code": "074"
        },
        {
            "name": "Priekuļi Municipality",
            "code": "075"
        },
        {
            "name": "Rauna Municipality",
            "code": "076"
        },
        {
            "name": "Rēzekne",
            "code": "REZ"
        },
        {
            "name": "Rēzekne Municipality",
            "code": "077"
        },
        {
            "name": "Riebiņi Municipality",
            "code": "078"
        },
        {
            "name": "Riga",
            "code": "RIX"
        },
        {
            "name": "Roja Municipality",
            "code": "079"
        },
        {
            "name": "Ropaži Municipality",
            "code": "080"
        },
        {
            "name": "Rucava Municipality",
            "code": "081"
        },
        {
            "name": "Rugāji Municipality",
            "code": "082"
        },
        {
            "name": "Rūjiena Municipality",
            "code": "084"
        },
        {
            "name": "Rundāle Municipality",
            "code": "083"
        },
        {
            "name": "Sala Municipality",
            "code": "085"
        },
        {
            "name": "Salacgrīva Municipality",
            "code": "086"
        },
        {
            "name": "Salaspils Municipality",
            "code": "087"
        },
        {
            "name": "Saldus Municipality",
            "code": "088"
        },
        {
            "name": "Saulkrasti Municipality",
            "code": "089"
        },
        {
            "name": "Sēja Municipality",
            "code": "090"
        },
        {
            "name": "Sigulda Municipality",
            "code": "091"
        },
        {
            "name": "Skrīveri Municipality",
            "code": "092"
        },
        {
            "name": "Skrunda Municipality",
            "code": "093"
        },
        {
            "name": "Smiltene Municipality",
            "code": "094"
        },
        {
            "name": "Stopiņi Municipality",
            "code": "095"
        },
        {
            "name": "Strenči Municipality",
            "code": "096"
        },
        {
            "name": "Talsi Municipality",
            "code": "097"
        },
        {
            "name": "Tērvete Municipality",
            "code": "098"
        },
        {
            "name": "Tukums Municipality",
            "code": "099"
        },
        {
            "name": "Vaiņode Municipality",
            "code": "100"
        },
        {
            "name": "Valka Municipality",
            "code": "101"
        },
        {
            "name": "Valmiera",
            "code": "VMR"
        },
        {
            "name": "Varakļāni Municipality",
            "code": "102"
        },
        {
            "name": "Vārkava Municipality",
            "code": "103"
        },
        {
            "name": "Vecpiebalga Municipality",
            "code": "104"
        },
        {
            "name": "Vecumnieki Municipality",
            "code": "105"
        },
        {
            "name": "Ventspils",
            "code": "VEN"
        },
        {
            "name": "Ventspils Municipality",
            "code": "106"
        },
        {
            "name": "Viesīte Municipality",
            "code": "107"
        },
        {
            "name": "Viļaka Municipality",
            "code": "108"
        },
        {
            "name": "Viļāni Municipality",
            "code": "109"
        },
        {
            "name": "Zilupe Municipality",
            "code": "110"
        }
    ]
  },
  {
    "code2": "LB",
    "code3": "LBN",
    "name": "Lebanon",
    "capital": "Beirut",
    "region": "Asia",
    "subregion": "Western Asia",
    "states": [
        {
            "name": "Akkar Governorate",
            "code": "AK"
        },
        {
            "name": "Baalbek-Hermel Governorate",
            "code": "BH"
        },
        {
            "name": "Beirut Governorate",
            "code": "BA"
        },
        {
            "name": "Beqaa Governorate",
            "code": "BI"
        },
        {
            "name": "Mount Lebanon Governorate",
            "code": "JL"
        },
        {
            "name": "Nabatieh Governorate",
            "code": "NA"
        },
        {
            "name": "North Governorate",
            "code": "AS"
        },
        {
            "name": "South Governorate",
            "code": "JA"
        }
    ]
  },
  {
    "code2": "LS",
    "code3": "LSO",
    "name": "Lesotho",
    "capital": "Maseru",
    "region": "Africa",
    "subregion": "Southern Africa",
    "states": [
        {
            "name": "Berea District",
            "code": "D"
        },
        {
            "name": "Butha-Buthe District",
            "code": "B"
        },
        {
            "name": "Leribe District",
            "code": "C"
        },
        {
            "name": "Mafeteng District",
            "code": "E"
        },
        {
            "name": "Maseru District",
            "code": "A"
        },
        {
            "name": "Mohale's Hoek District",
            "code": "F"
        },
        {
            "name": "Mokhotlong District",
            "code": "J"
        },
        {
            "name": "Qacha's Nek District",
            "code": "H"
        },
        {
            "name": "Quthing District",
            "code": "G"
        },
        {
            "name": "Thaba-Tseka District",
            "code": "K"
        }
    ]
  },
  {
    "code2": "LR",
    "code3": "LBR",
    "name": "Liberia",
    "capital": "Monrovia",
    "region": "Africa",
    "subregion": "Western Africa",
    "states": [
        {
            "name": "Bomi County",
            "code": "BM"
        },
        {
            "name": "Bong County",
            "code": "BG"
        },
        {
            "name": "Gbarpolu County",
            "code": "GP"
        },
        {
            "name": "Grand Bassa County",
            "code": "GB"
        },
        {
            "name": "Grand Cape Mount County",
            "code": "CM"
        },
        {
            "name": "Grand Gedeh County",
            "code": "GG"
        },
        {
            "name": "Grand Kru County",
            "code": "GK"
        },
        {
            "name": "Lofa County",
            "code": "LO"
        },
        {
            "name": "Margibi County",
            "code": "MG"
        },
        {
            "name": "Maryland County",
            "code": "MY"
        },
        {
            "name": "Montserrado County",
            "code": "MO"
        },
        {
            "name": "Nimba",
            "code": "NI"
        },
        {
            "name": "River Cess County",
            "code": "RI"
        },
        {
            "name": "River Gee County",
            "code": "RG"
        },
        {
            "name": "Sinoe County",
            "code": "SI"
        }
    ]
  },
  {
    "code2": "LY",
    "code3": "LBY",
    "name": "Libya",
    "capital": "Tripoli",
    "region": "Africa",
    "subregion": "Northern Africa",
    "states": [
        {
            "name": "Al Wahat District",
            "code": "WA"
        },
        {
            "name": "Benghazi",
            "code": "BA"
        },
        {
            "name": "Derna District",
            "code": "DR"
        },
        {
            "name": "Ghat District",
            "code": "GT"
        },
        {
            "name": "Jabal al Akhdar",
            "code": "JA"
        },
        {
            "name": "Jabal al Gharbi District",
            "code": "JG"
        },
        {
            "name": "Jafara",
            "code": "JI"
        },
        {
            "name": "Jufra",
            "code": "JU"
        },
        {
            "name": "Kufra District",
            "code": "KF"
        },
        {
            "name": "Marj District",
            "code": "MJ"
        },
        {
            "name": "Misrata District",
            "code": "MI"
        },
        {
            "name": "Murqub",
            "code": "MB"
        },
        {
            "name": "Murzuq District",
            "code": "MQ"
        },
        {
            "name": "Nalut District",
            "code": "NL"
        },
        {
            "name": "Nuqat al Khams",
            "code": "NQ"
        },
        {
            "name": "Sabha District",
            "code": "SB"
        },
        {
            "name": "Sirte District",
            "code": "SR"
        },
        {
            "name": "Tripoli District",
            "code": "TB"
        },
        {
            "name": "Wadi al Hayaa District",
            "code": "WD"
        },
        {
            "name": "Wadi al Shatii District",
            "code": "WS"
        },
        {
            "name": "Zawiya District",
            "code": "ZA"
        }
    ]
  },
  {
    "code2": "LI",
    "code3": "LIE",
    "name": "Liechtenstein",
    "capital": "Vaduz",
    "region": "Europe",
    "subregion": "Western Europe",
    "states": [
        {
            "name": "Balzers",
            "code": "01"
        },
        {
            "name": "Eschen",
            "code": "02"
        },
        {
            "name": "Gamprin",
            "code": "03"
        },
        {
            "name": "Mauren",
            "code": "04"
        },
        {
            "name": "Planken",
            "code": "05"
        },
        {
            "name": "Ruggell",
            "code": "06"
        },
        {
            "name": "Schaan",
            "code": "07"
        },
        {
            "name": "Schellenberg",
            "code": "08"
        },
        {
            "name": "Triesen",
            "code": "09"
        },
        {
            "name": "Triesenberg",
            "code": "10"
        },
        {
            "name": "Vaduz",
            "code": "11"
        }
    ]
  },
  {
    "code2": "LT",
    "code3": "LTU",
    "name": "Lithuania",
    "capital": "Vilnius",
    "region": "Europe",
    "subregion": "Northern Europe",
    "states": [
        {
            "name": "Balzers",
            "code": "01"
        },
        {
            "name": "Eschen",
            "code": "02"
        },
        {
            "name": "Gamprin",
            "code": "03"
        },
        {
            "name": "Mauren",
            "code": "04"
        },
        {
            "name": "Planken",
            "code": "05"
        },
        {
            "name": "Ruggell",
            "code": "06"
        },
        {
            "name": "Schaan",
            "code": "07"
        },
        {
            "name": "Schellenberg",
            "code": "08"
        },
        {
            "name": "Triesen",
            "code": "09"
        },
        {
            "name": "Triesenberg",
            "code": "10"
        },
        {
            "name": "Vaduz",
            "code": "11"
        }
    ]
  },
  {
    "code2": "LU",
    "code3": "LUX",
    "name": "Luxembourg",
    "capital": "Luxembourg",
    "region": "Europe",
    "subregion": "Western Europe",
    "states": [
        {
            "name": "Canton of Capellen",
            "code": "CA"
        },
        {
            "name": "Canton of Clervaux",
            "code": "CL"
        },
        {
            "name": "Canton of Diekirch",
            "code": "DI"
        },
        {
            "name": "Canton of Echternach",
            "code": "EC"
        },
        {
            "name": "Canton of Esch-sur-Alzette",
            "code": "ES"
        },
        {
            "name": "Canton of Grevenmacher",
            "code": "GR"
        },
        {
            "name": "Canton of Luxembourg",
            "code": "LU"
        },
        {
            "name": "Canton of Mersch",
            "code": "ME"
        },
        {
            "name": "Canton of Redange",
            "code": "RD"
        },
        {
            "name": "Canton of Remich",
            "code": "RM"
        },
        {
            "name": "Canton of Vianden",
            "code": "VD"
        },
        {
            "name": "Canton of Wiltz",
            "code": "WI"
        },
        {
            "name": "Diekirch District",
            "code": "D"
        },
        {
            "name": "Grevenmacher District",
            "code": "G"
        },
        {
            "name": "Luxembourg District",
            "code": "L"
        }
    ]
  },
  {
    "code2": "MK",
    "code3": "MKD",
    "name": "Macedonia",
    "capital": "Skopje",
    "region": "Europe",
    "subregion": "Southern Europe",
    "states": [
        {
            "name": "Aerodrom Municipality",
            "code": "01"
        },
        {
            "name": "Aračinovo Municipality",
            "code": "02"
        },
        {
            "name": "Berovo Municipality",
            "code": "03"
        },
        {
            "name": "Bitola Municipality",
            "code": "04"
        },
        {
            "name": "Bogdanci Municipality",
            "code": "05"
        },
        {
            "name": "Bogovinje Municipality",
            "code": "06"
        },
        {
            "name": "Bosilovo Municipality",
            "code": "07"
        },
        {
            "name": "Brvenica Municipality",
            "code": "08"
        },
        {
            "name": "Butel Municipality",
            "code": "09"
        },
        {
            "name": "Čair Municipality",
            "code": "79"
        },
        {
            "name": "Čaška Municipality",
            "code": "80"
        },
        {
            "name": "Centar Municipality",
            "code": "77"
        },
        {
            "name": "Centar Župa Municipality",
            "code": "78"
        },
        {
            "name": "Češinovo-Obleševo Municipality",
            "code": "81"
        },
        {
            "name": "Čučer-Sandevo Municipality",
            "code": "82"
        },
        {
            "name": "Debarca Municipality",
            "code": "22"
        },
        {
            "name": "Delčevo Municipality",
            "code": "23"
        },
        {
            "name": "Demir Hisar Municipality",
            "code": "25"
        },
        {
            "name": "Demir Kapija Municipality",
            "code": "24"
        },
        {
            "name": "Dojran Municipality",
            "code": "26"
        },
        {
            "name": "Dolneni Municipality",
            "code": "27"
        },
        {
            "name": "Drugovo Municipality",
            "code": "28"
        },
        {
            "name": "Gazi Baba Municipality",
            "code": "17"
        },
        {
            "name": "Gevgelija Municipality",
            "code": "18"
        },
        {
            "name": "Gjorče Petrov Municipality",
            "code": "29"
        },
        {
            "name": "Gostivar Municipality",
            "code": "19"
        },
        {
            "name": "Gradsko Municipality",
            "code": "20"
        },
        {
            "name": "Greater Skopje",
            "code": "85"
        },
        {
            "name": "Ilinden Municipality",
            "code": "34"
        },
        {
            "name": "Jegunovce Municipality",
            "code": "35"
        },
        {
            "name": "Karbinci",
            "code": "37"
        },
        {
            "name": "Karpoš Municipality",
            "code": "38"
        },
        {
            "name": "Kavadarci Municipality",
            "code": "36"
        },
        {
            "name": "Kičevo Municipality",
            "code": "40"
        },
        {
            "name": "Kisela Voda Municipality",
            "code": "39"
        },
        {
            "name": "Kočani Municipality",
            "code": "42"
        },
        {
            "name": "Konče Municipality",
            "code": "41"
        },
        {
            "name": "Kratovo Municipality",
            "code": "43"
        },
        {
            "name": "Kriva Palanka Municipality",
            "code": "44"
        },
        {
            "name": "Krivogaštani Municipality",
            "code": "45"
        },
        {
            "name": "Kruševo Municipality",
            "code": "46"
        },
        {
            "name": "Kumanovo Municipality",
            "code": "47"
        },
        {
            "name": "Lipkovo Municipality",
            "code": "48"
        },
        {
            "name": "Lozovo Municipality",
            "code": "49"
        },
        {
            "name": "Makedonska Kamenica Municipality",
            "code": "51"
        },
        {
            "name": "Makedonski Brod Municipality",
            "code": "52"
        },
        {
            "name": "Mavrovo and Rostuša Municipality",
            "code": "50"
        },
        {
            "name": "Mogila Municipality",
            "code": "53"
        },
        {
            "name": "Negotino Municipality",
            "code": "54"
        },
        {
            "name": "Novaci Municipality",
            "code": "55"
        },
        {
            "name": "Novo Selo Municipality",
            "code": "56"
        },
        {
            "name": "Ohrid Municipality",
            "code": "58"
        },
        {
            "name": "Oslomej Municipality",
            "code": "57"
        },
        {
            "name": "Pehčevo Municipality",
            "code": "60"
        },
        {
            "name": "Petrovec Municipality",
            "code": "59"
        },
        {
            "name": "Plasnica Municipality",
            "code": "61"
        },
        {
            "name": "Prilep Municipality",
            "code": "62"
        },
        {
            "name": "Probištip Municipality",
            "code": "63"
        },
        {
            "name": "Radoviš Municipality",
            "code": "64"
        },
        {
            "name": "Rankovce Municipality",
            "code": "65"
        },
        {
            "name": "Resen Municipality",
            "code": "66"
        },
        {
            "name": "Rosoman Municipality",
            "code": "67"
        },
        {
            "name": "Saraj Municipality",
            "code": "68"
        },
        {
            "name": "Sopište Municipality",
            "code": "70"
        },
        {
            "name": "Staro Nagoričane Municipality",
            "code": "71"
        },
        {
            "name": "Štip Municipality",
            "code": "83"
        },
        {
            "name": "Struga Municipality",
            "code": "72"
        },
        {
            "name": "Strumica Municipality",
            "code": "73"
        },
        {
            "name": "Studeničani Municipality",
            "code": "74"
        },
        {
            "name": "Šuto Orizari Municipality",
            "code": "84"
        },
        {
            "name": "Sveti Nikole Municipality",
            "code": "69"
        },
        {
            "name": "Tearce Municipality",
            "code": "75"
        },
        {
            "name": "Tetovo Municipality",
            "code": "76"
        },
        {
            "name": "Valandovo Municipality",
            "code": "10"
        },
        {
            "name": "Vasilevo Municipality",
            "code": "11"
        },
        {
            "name": "Veles Municipality",
            "code": "13"
        },
        {
            "name": "Vevčani Municipality",
            "code": "12"
        },
        {
            "name": "Vinica Municipality",
            "code": "14"
        },
        {
            "name": "Vraneštica Municipality",
            "code": "15"
        },
        {
            "name": "Vrapčište Municipality",
            "code": "16"
        },
        {
            "name": "Zajas Municipality",
            "code": "31"
        },
        {
            "name": "Zelenikovo Municipality",
            "code": "32"
        },
        {
            "name": "Želino Municipality",
            "code": "30"
        },
        {
            "name": "Zrnovci Municipality",
            "code": "33"
        }
    ]
  },
  {
    "code2": "MG",
    "code3": "MDG",
    "name": "Madagascar",
    "capital": "Antananarivo",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "states": [
        {
            "name": "Antananarivo Province",
            "code": "T"
        },
        {
            "name": "Antsiranana Province",
            "code": "D"
        },
        {
            "name": "Fianarantsoa Province",
            "code": "F"
        },
        {
            "name": "Mahajanga Province",
            "code": "M"
        },
        {
            "name": "Toamasina Province",
            "code": "A"
        },
        {
            "name": "Toliara Province",
            "code": "U"
        }
    ]
  },
  {
    "code2": "MW",
    "code3": "MWI",
    "name": "Malawi",
    "capital": "Lilongwe",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "states": [
        {
            "name": "Balaka District",
            "code": "BA"
        },
        {
            "name": "Blantyre District",
            "code": "BL"
        },
        {
            "name": "Central Region",
            "code": "C"
        },
        {
            "name": "Chikwawa District",
            "code": "CK"
        },
        {
            "name": "Chiradzulu District",
            "code": "CR"
        },
        {
            "name": "Chitipa district",
            "code": "CT"
        },
        {
            "name": "Dedza District",
            "code": "DE"
        },
        {
            "name": "Dowa District",
            "code": "DO"
        },
        {
            "name": "Karonga District",
            "code": "KR"
        },
        {
            "name": "Kasungu District",
            "code": "KS"
        },
        {
            "name": "Likoma District",
            "code": "LK"
        },
        {
            "name": "Lilongwe District",
            "code": "LI"
        },
        {
            "name": "Machinga District",
            "code": "MH"
        },
        {
            "name": "Mangochi District",
            "code": "MG"
        },
        {
            "name": "Mchinji District",
            "code": "MC"
        },
        {
            "name": "Mulanje District",
            "code": "MU"
        },
        {
            "name": "Mwanza District",
            "code": "MW"
        },
        {
            "name": "Mzimba District",
            "code": "MZ"
        },
        {
            "name": "Nkhata Bay District",
            "code": "NB"
        },
        {
            "name": "Nkhotakota District",
            "code": "NK"
        },
        {
            "name": "Northern Region",
            "code": "N"
        },
        {
            "name": "Nsanje District",
            "code": "NS"
        },
        {
            "name": "Ntcheu District",
            "code": "NU"
        },
        {
            "name": "Ntchisi District",
            "code": "NI"
        },
        {
            "name": "Phalombe District",
            "code": "PH"
        },
        {
            "name": "Rumphi District",
            "code": "RU"
        },
        {
            "name": "Salima District",
            "code": "SA"
        },
        {
            "name": "Southern Region",
            "code": "S"
        },
        {
            "name": "Thyolo District",
            "code": "TH"
        },
        {
            "name": "Zomba District",
            "code": "ZO"
        }
    ]
  },
  {
    "code2": "MY",
    "code3": "MYS",
    "name": "Malaysia",
    "capital": "Kuala Lumpur",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "states": [
        {
            "name": "Johor",
            "code": "01"
        },
        {
            "name": "Kedah",
            "code": "02"
        },
        {
            "name": "Kelantan",
            "code": "03"
        },
        {
            "name": "Kuala Lumpur",
            "code": "14"
        },
        {
            "name": "Labuan",
            "code": "15"
        },
        {
            "name": "Malacca",
            "code": "04"
        },
        {
            "name": "Negeri Sembilan",
            "code": "05"
        },
        {
            "name": "Pahang",
            "code": "06"
        },
        {
            "name": "Penang",
            "code": "07"
        },
        {
            "name": "Perak",
            "code": "08"
        },
        {
            "name": "Perlis",
            "code": "09"
        },
        {
            "name": "Putrajaya",
            "code": "16"
        },
        {
            "name": "Sabah",
            "code": "12"
        },
        {
            "name": "Sarawak",
            "code": "13"
        },
        {
            "name": "Selangor",
            "code": "10"
        },
        {
            "name": "Terengganu",
            "code": "11"
        }
    ]
  },
  {
    "code2": "MV",
    "code3": "MDV",
    "name": "Maldives",
    "capital": "Malé",
    "region": "Asia",
    "subregion": "Southern Asia",
    "states": [
        {
            "name": "Addu Atoll",
            "code": "01"
        },
        {
            "name": "Alif Alif Atoll",
            "code": "02"
        },
        {
            "name": "Alif Dhaal Atoll",
            "code": "00"
        },
        {
            "name": "Central Province",
            "code": "CE"
        },
        {
            "name": "Dhaalu Atoll",
            "code": "17"
        },
        {
            "name": "Faafu Atoll",
            "code": "14"
        },
        {
            "name": "Gaafu Alif Atoll",
            "code": "27"
        },
        {
            "name": "Gaafu Dhaalu Atoll",
            "code": "28"
        },
        {
            "name": "Gnaviyani Atoll",
            "code": "29"
        },
        {
            "name": "Haa Alif Atoll",
            "code": "07"
        },
        {
            "name": "Haa Dhaalu Atoll",
            "code": "23"
        },
        {
            "name": "Kaafu Atoll",
            "code": "26"
        },
        {
            "name": "Laamu Atoll",
            "code": "05"
        },
        {
            "name": "Lhaviyani Atoll",
            "code": "03"
        },
        {
            "name": "Malé",
            "code": "MLE"
        },
        {
            "name": "Meemu Atoll",
            "code": "12"
        },
        {
            "name": "Noonu Atoll",
            "code": "25"
        },
        {
            "name": "North Central Province",
            "code": "NC"
        },
        {
            "name": "North Province",
            "code": "NO"
        },
        {
            "name": "Raa Atoll",
            "code": "13"
        },
        {
            "name": "Shaviyani Atoll",
            "code": "24"
        },
        {
            "name": "South Central Province",
            "code": "SC"
        },
        {
            "name": "South Province",
            "code": "SU"
        },
        {
            "name": "Thaa Atoll",
            "code": "08"
        },
        {
            "name": "Upper South Province",
            "code": "US"
        },
        {
            "name": "Vaavu Atoll",
            "code": "04"
        }
    ]
  },
  {
    "code2": "ML",
    "code3": "MLI",
    "name": "Mali",
    "capital": "Bamako",
    "region": "Africa",
    "subregion": "Western Africa",
    "states": [
        {
            "name": "Bamako",
            "code": "BKO"
        },
        {
            "name": "Gao Region",
            "code": "7"
        },
        {
            "name": "Kayes Region",
            "code": "1"
        },
        {
            "name": "Kidal Region",
            "code": "8"
        },
        {
            "name": "Koulikoro Region",
            "code": "2"
        },
        {
            "name": "Ménaka Region",
            "code": "9"
        },
        {
            "name": "Mopti Region",
            "code": "5"
        },
        {
            "name": "Ségou Region",
            "code": "4"
        },
        {
            "name": "Sikasso Region",
            "code": "3"
        },
        {
            "name": "Taoudénit Region",
            "code": "10"
        },
        {
            "name": "Tombouctou Region",
            "code": "6"
        }
    ]
  },
  {
    "code2": "MT",
    "code3": "MLT",
    "name": "Malta",
    "capital": "Valletta",
    "region": "Europe",
    "subregion": "Southern Europe",
    "states": [
        {
            "name": "Attard",
            "code": "01"
        },
        {
            "name": "Balzan",
            "code": "02"
        },
        {
            "name": "Birgu",
            "code": "03"
        },
        {
            "name": "Birkirkara",
            "code": "04"
        },
        {
            "name": "Birżebbuġa",
            "code": "05"
        },
        {
            "name": "Cospicua",
            "code": "06"
        },
        {
            "name": "Dingli",
            "code": "07"
        },
        {
            "name": "Fgura",
            "code": "08"
        },
        {
            "name": "Floriana",
            "code": "09"
        },
        {
            "name": "Fontana",
            "code": "10"
        },
        {
            "name": "Għajnsielem",
            "code": "13"
        },
        {
            "name": "Għarb",
            "code": "14"
        },
        {
            "name": "Għargħur",
            "code": "15"
        },
        {
            "name": "Għasri",
            "code": "16"
        },
        {
            "name": "Għaxaq",
            "code": "17"
        },
        {
            "name": "Gudja",
            "code": "11"
        },
        {
            "name": "Gżira",
            "code": "12"
        },
        {
            "name": "Ħamrun",
            "code": "18"
        },
        {
            "name": "Iklin",
            "code": "19"
        },
        {
            "name": "Kalkara",
            "code": "21"
        },
        {
            "name": "Kerċem",
            "code": "22"
        },
        {
            "name": "Kirkop",
            "code": "23"
        },
        {
            "name": "Lija",
            "code": "24"
        },
        {
            "name": "Luqa",
            "code": "25"
        },
        {
            "name": "Marsa",
            "code": "26"
        },
        {
            "name": "Marsaskala",
            "code": "27"
        },
        {
            "name": "Marsaxlokk",
            "code": "28"
        },
        {
            "name": "Mdina",
            "code": "29"
        },
        {
            "name": "Mellieħa",
            "code": "30"
        },
        {
            "name": "Mġarr",
            "code": "31"
        },
        {
            "name": "Mosta",
            "code": "32"
        },
        {
            "name": "Mqabba",
            "code": "33"
        },
        {
            "name": "Msida",
            "code": "34"
        },
        {
            "name": "Mtarfa",
            "code": "35"
        },
        {
            "name": "Munxar",
            "code": "36"
        },
        {
            "name": "Nadur",
            "code": "37"
        },
        {
            "name": "Naxxar",
            "code": "38"
        },
        {
            "name": "Paola",
            "code": "39"
        },
        {
            "name": "Pembroke",
            "code": "40"
        },
        {
            "name": "Pietà",
            "code": "41"
        },
        {
            "name": "Qala",
            "code": "42"
        },
        {
            "name": "Qormi",
            "code": "43"
        },
        {
            "name": "Qrendi",
            "code": "44"
        },
        {
            "name": "Rabat",
            "code": "46"
        },
        {
            "name": "Saint Lawrence",
            "code": "50"
        },
        {
            "name": "San Ġwann",
            "code": "49"
        },
        {
            "name": "Sannat",
            "code": "52"
        },
        {
            "name": "Santa Luċija",
            "code": "53"
        },
        {
            "name": "Santa Venera",
            "code": "54"
        },
        {
            "name": "Senglea",
            "code": "20"
        },
        {
            "name": "Siġġiewi",
            "code": "55"
        },
        {
            "name": "Sliema",
            "code": "56"
        },
        {
            "name": "St. Julian's",
            "code": "48"
        },
        {
            "name": "St. Paul's Bay",
            "code": "51"
        },
        {
            "name": "Swieqi",
            "code": "57"
        },
        {
            "name": "Ta' Xbiex",
            "code": "58"
        },
        {
            "name": "Tarxien",
            "code": "59"
        },
        {
            "name": "Valletta",
            "code": "60"
        },
        {
            "name": "Victoria",
            "code": "45"
        },
        {
            "name": "Xagħra",
            "code": "61"
        },
        {
            "name": "Xewkija",
            "code": "62"
        },
        {
            "name": "Xgħajra",
            "code": "63"
        },
        {
            "name": "Żabbar",
            "code": "64"
        },
        {
            "name": "Żebbuġ Gozo",
            "code": "65"
        },
        {
            "name": "Żebbuġ Malta",
            "code": "66"
        },
        {
            "name": "Żejtun",
            "code": "67"
        },
        {
            "name": "Żurrieq",
            "code": "68"
        }
    ]
  },
  {
    "code2": "IM",
    "code3": "IMN",
    "name": "Man (Isle of)",
    "capital": "Douglas, Isle of Man",
    "region": "Europe",
    "subregion": "Northern Europe",
    "states": []
  },
  {
    "code2": "MH",
    "code3": "MHL",
    "name": "Marshall Islands",
    "capital": "Majuro",
    "region": "Oceania",
    "subregion": "Micronesia",
    "states": [
        {
            "name": "Ralik Chain",
            "code": "L"
        },
        {
            "name": "Ratak Chain",
            "code": "T"
        }
    ]
  },
 
  {
    "code2": "MQ",
    "code3": "MTQ",
    "name": "Martinique",
    "capital": "Fort-de-France",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": []
  },
  {
    "code2": "MR",
    "code3": "MRT",
    "name": "Mauritania",
    "capital": "Nouakchott",
    "region": "Africa",
    "subregion": "Western Africa",
    "states": [
        {
            "name": "Adrar Region",
            "code": "07"
        },
        {
            "name": "Assaba Region",
            "code": "03"
        },
        {
            "name": "Brakna Region",
            "code": "05"
        },
        {
            "name": "Dakhlet Nouadhibou",
            "code": "08"
        },
        {
            "name": "Gorgol Region",
            "code": "04"
        },
        {
            "name": "Guidimaka Region",
            "code": "10"
        },
        {
            "name": "Hodh Ech Chargui Region",
            "code": "01"
        },
        {
            "name": "Hodh El Gharbi Region",
            "code": "02"
        },
        {
            "name": "Inchiri Region",
            "code": "12"
        },
        {
            "name": "Nouakchott-Nord Region",
            "code": "14"
        },
        {
            "name": "Nouakchott-Ouest Region",
            "code": "13"
        },
        {
            "name": "Nouakchott-Sud Region",
            "code": "15"
        },
        {
            "name": "Tagant Region",
            "code": "09"
        },
        {
            "name": "Tiris Zemmour Region",
            "code": "11"
        },
        {
            "name": "Trarza Region",
            "code": "06"
        }
    ]
  },
  {
    "code2": "MU",
    "code3": "MUS",
    "name": "Mauritius",
    "capital": "Port Louis",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "states": [
        {
            "name": "Agaléga",
            "code": "AG"
        },
        {
            "name": "Beau Bassin-Rose Hill",
            "code": "BR"
        },
        {
            "name": "Cargados Carajos",
            "code": "CC"
        },
        {
            "name": "Curepipe",
            "code": "CU"
        },
        {
            "name": "Flacq District",
            "code": "FL"
        },
        {
            "name": "Grand Port District",
            "code": "GP"
        },
        {
            "name": "Moka District",
            "code": "MO"
        },
        {
            "name": "Pamplemousses District",
            "code": "PA"
        },
        {
            "name": "Plaines Wilhems District",
            "code": "PW"
        },
        {
            "name": "Port Louis",
            "code": "PU"
        },
        {
            "name": "Port Louis District",
            "code": "PL"
        },
        {
            "name": "Quatre Bornes",
            "code": "QB"
        },
        {
            "name": "Rivière du Rempart District",
            "code": "RR"
        },
        {
            "name": "Rivière Noire District",
            "code": "BL"
        },
        {
            "name": "Rodrigues",
            "code": "RO"
        },
        {
            "name": "Savanne District",
            "code": "SA"
        },
        {
            "name": "Vacoas-Phoenix",
            "code": "VP"
        }
    ]
  },
  {
    "code2": "YT",
    "code3": "MYT",
    "name": "Mayotte",
    "capital": "Mamoudzou",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "states": []
  },
  {
    "code2": "MX",
    "code3": "MEX",
    "name": "Mexico",
    "capital": "Mexico City",
    "region": "Americas",
    "subregion": "Central America",
    "states": [
        {
            "name": "Aguascalientes",
            "code": "AGU"
        },
        {
            "name": "Baja California",
            "code": "BCN"
        },
        {
            "name": "Baja California Sur",
            "code": "BCS"
        },
        {
            "name": "Campeche",
            "code": "CAM"
        },
        {
            "name": "Chiapas",
            "code": "CHP"
        },
        {
            "name": "Chihuahua",
            "code": "CHH"
        },
        {
            "name": "Coahuila",
            "code": "COA"
        },
        {
            "name": "Colima",
            "code": "COL"
        },
        {
            "name": "Durango",
            "code": "DUR"
        },
        {
            "name": "Guanajuato",
            "code": "GUA"
        },
        {
            "name": "Guerrero",
            "code": "GRO"
        },
        {
            "name": "Hidalgo",
            "code": "HID"
        },
        {
            "name": "Jalisco",
            "code": "JAL"
        },
        {
            "name": "México",
            "code": "MEX"
        },
        {
            "name": "Mexico City",
            "code": "CMX"
        },
        {
            "name": "Michoacán",
            "code": "MIC"
        },
        {
            "name": "Morelos",
            "code": "MOR"
        },
        {
            "name": "Nayarit",
            "code": "NAY"
        },
        {
            "name": "Nuevo León",
            "code": "NLE"
        },
        {
            "name": "Oaxaca",
            "code": "OAX"
        },
        {
            "name": "Puebla",
            "code": "PUE"
        },
        {
            "name": "Querétaro",
            "code": "QUE"
        },
        {
            "name": "Quintana Roo",
            "code": "ROO"
        },
        {
            "name": "San Luis Potosí",
            "code": "SLP"
        },
        {
            "name": "Sinaloa",
            "code": "SIN"
        },
        {
            "name": "Sonora",
            "code": "SON"
        },
        {
            "name": "Tabasco",
            "code": "TAB"
        },
        {
            "name": "Tamaulipas",
            "code": "TAM"
        },
        {
            "name": "Tlaxcala",
            "code": "TLA"
        },
        {
            "name": "Veracruz",
            "code": "VER"
        },
        {
            "name": "Yucatán",
            "code": "YUC"
        },
        {
            "name": "Zacatecas",
            "code": "ZAC"
        }
    ]
  },
  {
    "code2": "FM",
    "code3": "FSM",
    "name": "Federated States of Micronesia",
    "capital": "Palikir",
    "region": "Oceania",
    "subregion": "Micronesia",
    "states": [
        {
            "name": "Chuuk State",
            "code": "TRK"
        },
        {
            "name": "Kosrae State",
            "code": "KSA"
        },
        {
            "name": "Pohnpei State",
            "code": "PNI"
        },
        {
            "name": "Yap State",
            "code": "YAP"
        }
    ]
  },
  {
    "code2": "MD",
    "code3": "MDA",
    "name": "Moldova",
    "capital": "Chișinău",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "states": [
        {
            "name": "Anenii Noi District",
            "code": "AN"
        },
        {
            "name": "Bălți Municipality",
            "code": "BA"
        },
        {
            "name": "Basarabeasca District",
            "code": "BS"
        },
        {
            "name": "Bender Municipality",
            "code": "BD"
        },
        {
            "name": "Briceni District",
            "code": "BR"
        },
        {
            "name": "Cahul District",
            "code": "CA"
        },
        {
            "name": "Călărași District",
            "code": "CL"
        },
        {
            "name": "Cantemir District",
            "code": "CT"
        },
        {
            "name": "Căușeni District",
            "code": "CS"
        },
        {
            "name": "Chișinău Municipality",
            "code": "CU"
        },
        {
            "name": "Cimișlia District",
            "code": "CM"
        },
        {
            "name": "Criuleni District",
            "code": "CR"
        },
        {
            "name": "Dondușeni District",
            "code": "DO"
        },
        {
            "name": "Drochia District",
            "code": "DR"
        },
        {
            "name": "Dubăsari District",
            "code": "DU"
        },
        {
            "name": "Edineț District",
            "code": "ED"
        },
        {
            "name": "Fălești District",
            "code": "FA"
        },
        {
            "name": "Florești District",
            "code": "FL"
        },
        {
            "name": "Gagauzia",
            "code": "GA"
        },
        {
            "name": "Glodeni District",
            "code": "GL"
        },
        {
            "name": "Hîncești District",
            "code": "HI"
        },
        {
            "name": "Ialoveni District",
            "code": "IA"
        },
        {
            "name": "Nisporeni District",
            "code": "NI"
        },
        {
            "name": "Ocnița District",
            "code": "OC"
        },
        {
            "name": "Orhei District",
            "code": "OR"
        },
        {
            "name": "Rezina District",
            "code": "RE"
        },
        {
            "name": "Rîșcani District",
            "code": "RI"
        },
        {
            "name": "Sîngerei District",
            "code": "SI"
        },
        {
            "name": "Șoldănești District",
            "code": "SD"
        },
        {
            "name": "Soroca District",
            "code": "SO"
        },
        {
            "name": "Ștefan Vodă District",
            "code": "SV"
        },
        {
            "name": "Strășeni District",
            "code": "ST"
        },
        {
            "name": "Taraclia District",
            "code": "TA"
        },
        {
            "name": "Telenești District",
            "code": "TE"
        },
        {
            "name": "Transnistria autonomous territorial unit",
            "code": "SN"
        },
        {
            "name": "Ungheni District",
            "code": "UN"
        }
    ]
  },
  {
    "code2": "MC",
    "code3": "MCO",
    "name": "Monaco",
    "capital": "Monaco",
    "region": "Europe",
    "subregion": "Western Europe",
    "states": [
      {
        "code": "FO",
        "name": "Fontvieille",
        "subdivision": null
      },
      {
        "code": "JE",
        "name": "Jardin Exotique",
        "subdivision": null
      },
      {
        "code": "CL",
        "name": "La Colle",
        "subdivision": null
      },
      {
        "code": "CO",
        "name": "La Condamine",
        "subdivision": null
      },
      {
        "code": "GA",
        "name": "La Gare",
        "subdivision": null
      },
      {
        "code": "SO",
        "name": "La Source",
        "subdivision": null
      },
      {
        "code": "LA",
        "name": "Larvotto",
        "subdivision": null
      },
      {
        "code": "MA",
        "name": "Malbousquet",
        "subdivision": null
      },
      {
        "code": "MO",
        "name": "Monaco-Ville",
        "subdivision": null
      },
      {
        "code": "MG",
        "name": "Moneghetti",
        "subdivision": null
      },
      {
        "code": "MC",
        "name": "Monte-Carlo",
        "subdivision": null
      },
      {
        "code": "MU",
        "name": "Moulins",
        "subdivision": null
      },
      {
        "code": "PH",
        "name": "Port-Hercule",
        "subdivision": null
      },
      {
        "code": "SR",
        "name": "Saint-Roman",
        "subdivision": null
      },
      {
        "code": "SD",
        "name": "Sainte-Dévote",
        "subdivision": null
      },
      {
        "code": "SP",
        "name": "Spélugues",
        "subdivision": null
      },
      {
        "code": "VR",
        "name": "Vallon de la Rousse",
        "subdivision": null
      }
    ]
  },
  {
    "code2": "MN",
    "code3": "MNG",
    "name": "Mongolia",
    "capital": "Ulan Bator",
    "region": "Asia",
    "subregion": "Eastern Asia",
    "states": [
        {
            "name": "Arkhangai Province",
            "code": "073"
        },
        {
            "name": "Bayan-Ölgii Province",
            "code": "071"
        },
        {
            "name": "Bayankhongor Province",
            "code": "069"
        },
        {
            "name": "Bulgan Province",
            "code": "067"
        },
        {
            "name": "Darkhan-Uul Province",
            "code": "037"
        },
        {
            "name": "Dornod Province",
            "code": "061"
        },
        {
            "name": "Dornogovi Province",
            "code": "063"
        },
        {
            "name": "Dundgovi Province",
            "code": "059"
        },
        {
            "name": "Govi-Altai Province",
            "code": "065"
        },
        {
            "name": "Govisümber Province",
            "code": "064"
        },
        {
            "name": "Khentii Province",
            "code": "039"
        },
        {
            "name": "Khovd Province",
            "code": "043"
        },
        {
            "name": "Khövsgöl Province",
            "code": "041"
        },
        {
            "name": "Ömnögovi Province",
            "code": "053"
        },
        {
            "name": "Orkhon Province",
            "code": "035"
        },
        {
            "name": "Övörkhangai Province",
            "code": "055"
        },
        {
            "name": "Selenge Province",
            "code": "049"
        },
        {
            "name": "Sükhbaatar Province",
            "code": "051"
        },
        {
            "name": "Töv Province",
            "code": "047"
        },
        {
            "name": "Uvs Province",
            "code": "046"
        },
        {
            "name": "Zavkhan Province",
            "code": "057"
        }
    ]
  },
  {
    "code2": "ME",
    "code3": "MNE",
    "name": "Montenegro",
    "capital": "Podgorica",
    "region": "Europe",
    "subregion": "Southern Europe",
    "states": [
        {
            "name": "Andrijevica Municipality",
            "code": "01"
        },
        {
            "name": "Bar Municipality",
            "code": "02"
        },
        {
            "name": "Berane Municipality",
            "code": "03"
        },
        {
            "name": "Bijelo Polje Municipality",
            "code": "04"
        },
        {
            "name": "Budva Municipality",
            "code": "05"
        },
        {
            "name": "Danilovgrad Municipality",
            "code": "07"
        },
        {
            "name": "Gusinje Municipality",
            "code": "22"
        },
        {
            "id": 31,
            "name": "Kolašin Municipality",
            "code": "09"
        },
        {
            "name": "Kotor Municipality",
            "code": "10"
        },
        {
            "name": "Mojkovac Municipality",
            "code": "11"
        },
        {
            "name": "Nikšić Municipality",
            "code": "12"
        },
        {
            "name": "Old Royal Capital Cetinje",
            "code": "06"
        },
        {
            "name": "Petnjica Municipality",
            "code": "23"
        },
        {
            "name": "Plav Municipality",
            "code": "13"
        },
        {
            "name": "Pljevlja Municipality",
            "code": "14"
        },
        {
            "name": "Plužine Municipality",
            "code": "15"
        },
        {
            "name": "Podgorica Municipality",
            "code": "16"
        },
        {
            "name": "Rožaje Municipality",
            "code": "17"
        },
        {
            "name": "Šavnik Municipality",
            "code": "18"
        },
        {
            "name": "Tivat Municipality",
            "code": "19"
        },
        {
            "name": "Ulcinj Municipality",
            "code": "20"
        },
        {
            "name": "Žabljak Municipality",
            "code": "21"
        }
    ]
  },
  {
    "code2": "MS",
    "code3": "MSR",
    "name": "Montserrat",
    "capital": "Plymouth",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": []
  },
  {
    "code2": "MA",
    "code3": "MAR",
    "name": "Morocco",
    "capital": "Rabat",
    "region": "Africa",
    "subregion": "Northern Africa",
    "states": [
        {
            "name": "Al Haouz Province",
            "code": "HAO"
        },
        {
            "name": "Al Hoceïma Province",
            "code": "HOC"
        },
        {
            "name": "Aousserd Province",
            "code": "AOU"
        },
        {
            "name": "Assa-Zag Province",
            "code": "ASZ"
        },
        {
            "name": "Azilal Province",
            "code": "AZI"
        },
        {
            "name": "Ben Slimane Province",
            "code": "BES"
        },
        {
            "name": "Béni Mellal-Khénifra",
            "code": "05"
        },
        {
            "name": "Béni-Mellal Province",
            "code": "BEM"
        },
        {
            "name": "Berkane Province",
            "code": "BER"
        },
        {
            "name": "Boujdour Province",
            "code": "BOD"
        },
        {
            "name": "Boulemane Province",
            "code": "BOM"
        },
        {
            "name": "Casablanca-Settat",
            "code": "06"
        },
        {
            "name": "Chefchaouen Province",
            "code": "CHE"
        },
        {
            "name": "Chichaoua Province",
            "code": "CHI"
        },
        {
            "name": "Dakhla-Oued Ed-Dahab",
            "code": "12"
        },
        {
            "name": "Drâa-Tafilalet",
            "code": "08"
        },
        {
            "name": "El Hajeb Province",
            "code": "HAJ"
        },
        {
            "name": "El Jadida Province",
            "code": "JDI"
        },
        {
            "name": "Errachidia Province",
            "code": "ERR"
        },
        {
            "name": "Es Semara Province",
            "code": "ESM"
        },
        {
            "name": "Essaouira Province",
            "code": "ESI"
        },
        {
            "name": "Fahs Anjra Province",
            "code": "FAH"
        },
        {
            "name": "Fès-Meknès",
            "code": "03"
        },
        {
            "name": "Figuig Province",
            "code": "FIG"
        },
        {
            "name": "Guelmim Province",
            "code": "GUE"
        },
        {
            "name": "Guelmim-Oued Noun",
            "code": "10"
        },
        {
            "name": "Ifrane Province",
            "code": "IFR"
        },
        {
            "id": 3294,
            "name": "Inezgane-Aït Melloul Prefecture",
            "code": "INE"
        },
        {
            "name": "Jerada Province",
            "code": "JRA"
        },
        {
            "name": "Kelaat Sraghna Province",
            "code": "KES"
        },
        {
            "name": "Kénitra Province",
            "code": "KEN"
        },
        {
            "name": "Khémisset Province",
            "code": "KHE"
        },
        {
            "name": "Khénifra Province",
            "code": "KHN"
        },
        {
            "name": "Khouribga Province",
            "code": "KHO"
        },
        {
            "name": "Laâyoune Province",
            "code": "LAA"
        },
        {
            "name": "Laâyoune-Sakia El Hamra",
            "code": "11"
        },
        {
            "name": "Larache Province",
            "code": "LAR"
        },
        {
            "name": "Marrakesh-Safi",
            "code": "07"
        },
        {
            "name": "Mediouna Province",
            "code": "MED"
        },
        {
            "name": "Moulay Yacoub Province",
            "code": "MOU"
        },
        {
            "name": "Nador Province",
            "code": "NAD"
        },
        {
            "name": "Nouaceur Province",
            "code": "NOU"
        },
        {
            "name": "Oriental",
            "code": "02"
        },
        {
            "name": "Ouarzazate Province",
            "code": "OUA"
        },
        {
            "name": "Oued Ed-Dahab Province",
            "code": "OUD"
        },
        {
            "name": "Safi Province",
            "code": "SAF"
        },
        {
            "name": "Sefrou Province",
            "code": "SEF"
        },
        {
            "name": "Settat Province",
            "code": "SET"
        },
        {
            "name": "Shtouka Ait Baha Province",
            "code": "CHT"
        },
        {
            "name": "Sidi Kacem Province",
            "code": "SIK"
        },
        {
            "name": "Sidi Youssef Ben Ali",
            "code": "SYB"
        },
        {
            "name": "Souss-Massa",
            "code": "09"
        },
        {
            "name": "Tan-Tan Province",
            "code": "TNT"
        },
        {
            "name": "Tanger-Tétouan-Al Hoceïma",
            "code": "01"
        },
        {
            "name": "Taounate Province",
            "code": "TAO"
        },
        {
            "name": "Taourirt Province",
            "code": "TAI"
        },
        {
            "name": "Taroudant Province",
            "code": "TAR"
        },
        {
            "name": "Tata Province",
            "code": "TAT"
        },
        {
            "name": "Taza Province",
            "code": "TAZ"
        },
        {
            "name": "Tétouan Province",
            "code": "TET"
        },
        {
            "name": "Tiznit Province",
            "code": "TIZ"
        },
        {
            "name": "Zagora Province",
            "code": "ZAG"
        }
    ]
  },
  {
    "code2": "MZ",
    "code3": "MOZ",
    "name": "Mozambique",
    "capital": "Maputo",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "states": [
        {
            "name": "Cabo Delgado Province",
            "code": "P"
        },
        {
            "name": "Gaza Province",
            "code": "G"
        },
        {
            "name": "Inhambane Province",
            "code": "I"
        },
        {
            "name": "Manica Province",
            "code": "B"
        },
        {
            "name": "Maputo",
            "code": "MPM"
        },
        {
            "name": "Maputo Province",
            "code": "L"
        },
        {
            "name": "Nampula Province",
            "code": "N"
        },
        {
            "name": "Niassa Province",
            "code": "A"
        },
        {
            "name": "Sofala Province",
            "code": "S"
        },
        {
            "name": "Tete Province",
            "code": "T"
        },
        {
            "name": "Zambezia Province",
            "code": "Q"
        }
    ]
  },
  {
    "code2": "MM",
    "code3": "MMR",
    "name": "Myanmar",
    "capital": "Naypyidaw",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "states": [
        {
            "name": "Ayeyarwady Region",
            "code": "07"
        },
        {
            "name": "Bago",
            "code": "02"
        },
        {
            "name": "Chin State",
            "code": "14"
        },
        {
            "name": "Kachin State",
            "code": "11"
        },
        {
            "name": "Kayah State",
            "code": "12"
        },
        {
            "name": "Kayin State",
            "code": "13"
        },
        {
            "name": "Magway Region",
            "code": "03"
        },
        {
            "name": "Mandalay Region",
            "code": "04"
        },
        {
            "name": "Mon State",
            "code": "15"
        },
        {
            "name": "Naypyidaw Union Territory",
            "code": "18"
        },
        {
            "name": "Rakhine State",
            "code": "16"
        },
        {
            "name": "Sagaing Region",
            "code": "01"
        },
        {
            "name": "Shan State",
            "code": "17"
        },
        {
            "name": "Tanintharyi Region",
            "code": "05"
        },
        {
            "name": "Yangon Region",
            "code": "06"
        }
    ]
  },
  {
    "code2": "NA",
    "code3": "NAM",
    "name": "Namibia",
    "capital": "Windhoek",
    "region": "Africa",
    "subregion": "Southern Africa",
    "states": [
        {
            "name": "Erongo Region",
            "code": "ER"
        },
        {
            "name": "Hardap Region",
            "code": "HA"
        },
        {
            "name": "Karas Region",
            "code": "KA"
        },
        {
            "name": "Kavango East Region",
            "code": "KE"
        },
        {
            "name": "Kavango West Region",
            "code": "KW"
        },
        {
            "name": "Khomas Region",
            "code": "KH"
        },
        {
            "name": "Kunene Region",
            "code": "KU"
        },
        {
            "name": "Ohangwena Region",
            "code": "OW"
        },
        {
            "name": "Omaheke Region",
            "code": "OH"
        },
        {
            "name": "Omusati Region",
            "code": "OS"
        },
        {
            "name": "Oshana Region",
            "code": "ON"
        },
        {
            "name": "Oshikoto Region",
            "code": "OT"
        },
        {
            "name": "Otjozondjupa Region",
            "code": "OD"
        },
        {
            "name": "Zambezi Region",
            "code": "CA"
        }
    ]
  },
  {
    "code2": "NR",
    "code3": "NRU",
    "name": "Nauru",
    "capital": "Yaren",
    "region": "Oceania",
    "subregion": "Micronesia",
    "states": [
        {
            "name": "Aiwo District",
            "code": "01"
        },
        {
            "name": "Anabar District",
            "code": "02"
        },
        {
            "name": "Anetan District",
            "code": "03"
        },
        {
            "name": "Anibare District",
            "code": "04"
        },
        {
            "name": "Baiti District",
            "code": "05"
        },
        {
            "name": "Boe District",
            "code": "06"
        },
        {
            "name": "Buada District",
            "code": "07"
        },
        {
            "name": "Denigomodu District",
            "code": "08"
        },
        {
            "name": "Ewa District",
            "code": "09"
        },
        {
            "name": "Ijuw District",
            "code": "10"
        },
        {
            "name": "Meneng District",
            "code": "11"
        },
        {
            "name": "Nibok District",
            "code": "12"
        },
        {
            "name": "Uaboe District",
            "code": "13"
        },
        {
            "name": "Yaren District",
            "code": "14"
        }
    ]
  },
  {
    "code2": "NP",
    "code3": "NPL",
    "name": "Nepal",
    "capital": "Kathmandu",
    "region": "Asia",
    "subregion": "Southern Asia",
    "states": [
        {
            "name": "Bagmati Zone",
            "code": "BA"
        },
        {
            "name": "Bheri Zone",
            "code": "BH"
        },
        {
            "name": "Central Region",
            "code": "1"
        },
        {
            "name": "Dhaulagiri Zone",
            "code": "DH"
        },
        {
            "name": "Eastern Development Region",
            "code": "4"
        },
        {
            "name": "Far-Western Development Region",
            "code": "5"
        },
        {
            "name": "Gandaki Zone",
            "code": "GA"
        },
        {
            "name": "Janakpur Zone",
            "code": "JA"
        },
        {
            "name": "Karnali Zone",
            "code": "KA"
        },
        {
            "name": "Kosi Zone",
            "code": "KO"
        },
        {
            "name": "Lumbini Zone",
            "code": "LU"
        },
        {
            "name": "Mahakali Zone",
            "code": "MA"
        },
        {
            "name": "Mechi Zone",
            "code": "ME"
        },
        {
            "name": "Mid-Western Region",
            "code": "2"
        },
        {
            "name": "Narayani Zone",
            "code": "NA"
        },
        {
            "name": "Rapti Zone",
            "code": "RA"
        },
        {
            "name": "Sagarmatha Zone",
            "code": "SA"
        },
        {
            "name": "Seti Zone",
            "code": "SE"
        },
        {
            "name": "Western Region",
            "code": "3"
        }
    ]
  },
  {
    "code2": "NL",
    "code3": "NLD",
    "name": "Netherlands The",
    "capital": "Amsterdam",
    "region": "Europe",
    "subregion": "Western Europe",
    "states": [
        {
            "name": "Bonaire",
            "code": "BQ1"
        },
        {
            "name": "Drenthe",
            "code": "DR"
        },
        {
            "name": "Flevoland",
            "code": "FL"
        },
        {
            "name": "Friesland",
            "code": "FR"
        },
        {
            "name": "Gelderland",
            "code": "GE"
        },
        {
            "name": "Groningen",
            "code": "GR"
        },
        {
            "name": "Limburg",
            "code": "LI"
        },
        {
            "name": "North Brabant",
            "code": "NB"
        },
        {
            "name": "North Holland",
            "code": "NH"
        },
        {
            "name": "Overijssel",
            "code": "OV"
        },
        {
            "name": "Saba",
            "code": "BQ2"
        },
        {
            "name": "Sint Eustatius",
            "code": "BQ3"
        },
        {
            "name": "South Holland",
            "code": "ZH"
        },
        {
            "name": "Utrecht",
            "code": "UT"
        },
        {
            "name": "Zeeland",
            "code": "ZE"
        }
    ]
  },
  {
    "code2": "NC",
    "code3": "NCL",
    "name": "New Caledonia",
    "capital": "Nouméa",
    "region": "Oceania",
    "subregion": "Melanesia",
    "states": []
  },
  {
    "code2": "NZ",
    "code3": "NZL",
    "name": "New Zealand",
    "capital": "Wellington",
    "region": "Oceania",
    "subregion": "Australia and New Zealand",
    "states": [
        {
            "name": "Auckland Region",
            "code": "AUK"
        },
        {
            "name": "Bay of Plenty Region",
            "code": "BOP"
        },
        {
            "name": "Canterbury Region",
            "code": "CAN"
        },
        {
            "name": "Chatham Islands",
            "code": "CIT"
        },
        {
            "name": "Gisborne District",
            "code": "GIS"
        },
        {
            "name": "Hawke's Bay Region",
            "code": "HKB"
        },
        {
            "name": "Manawatu-Wanganui Region",
            "code": "MWT"
        },
        {
            "name": "Marlborough Region",
            "code": "MBH"
        },
        {
            "name": "Nelson Region",
            "code": "NSN"
        },
        {
            "name": "Northland Region",
            "code": "NTL"
        },
        {
            "name": "Otago Region",
            "code": "OTA"
        },
        {
            "name": "Southland Region",
            "code": "STL"
        },
        {
            "name": "Taranaki Region",
            "code": "TKI"
        },
        {
            "name": "Tasman District",
            "code": "TAS"
        },
        {
            "name": "Waikato Region",
            "code": "WKO"
        },
        {
            "name": "Wellington Region",
            "code": "WGN"
        },
        {
            "name": "West Coast Region",
            "code": "WTC"
        }
    ]
  },
  {
    "code2": "NI",
    "code3": "NIC",
    "name": "Nicaragua",
    "capital": "Managua",
    "region": "Americas",
    "subregion": "Central America",
    "states": [
        {
            "name": "Boaco Department",
            "code": "BO"
        },
        {
            "name": "Carazo Department",
            "code": "CA"
        },
        {
            "name": "Chinandega Department",
            "code": "CI"
        },
        {
            "name": "Chontales Department",
            "code": "CO"
        },
        {
            "name": "Estelí Department",
            "code": "ES"
        },
        {
            "name": "Granada Department",
            "code": "GR"
        },
        {
            "name": "Jinotega Department",
            "code": "JI"
        },
        {
            "name": "León Department",
            "code": "LE"
        },
        {
            "name": "Madriz Department",
            "code": "MD"
        },
        {
            "name": "Managua Department",
            "code": "MN"
        },
        {
            "name": "Masaya Department",
            "code": "MS"
        },
        {
            "name": "Matagalpa Department",
            "code": "MT"
        },
        {
            "name": "North Caribbean Coast Autonomous Region",
            "code": "AN"
        },
        {
            "name": "Río San Juan Department",
            "code": "SJ"
        },
        {
            "name": "Rivas Department",
            "code": "RI"
        },
        {
            "name": "South Caribbean Coast Autonomous Region",
            "code": "AS"
        }
    ]
  },
  {
    "code2": "NE",
    "code3": "NER",
    "name": "Niger",
    "capital": "Niamey",
    "region": "Africa",
    "subregion": "Western Africa",
    "states": [
        {
            "name": "Agadez Region",
            "code": "1"
        },
        {
            "name": "Diffa Region",
            "code": "2"
        },
        {
            "name": "Dosso Region",
            "code": "3"
        },
        {
            "name": "Maradi Region",
            "code": "4"
        },
        {
            "name": "Tahoua Region",
            "code": "5"
        },
        {
            "name": "Tillabéri Region",
            "code": "6"
        },
        {
            "name": "Zinder Region",
            "code": "7"
        }
    ]
  },
  {
    "code2": "NG",
    "code3": "NGA",
    "name": "Nigeria",
    "capital": "Abuja",
    "region": "Africa",
    "subregion": "Western Africa",
    "states": [
        {
            "name": "Abia State",
            "code": "AB"
        },
        {
            "name": "Adamawa State",
            "code": "AD"
        },
        {
            "name": "Akwa Ibom State",
            "code": "AK"
        },
        {
            "name": "Anambra State",
            "code": "AN"
        },
        {
            "name": "Bauchi State",
            "code": "BA"
        },
        {
            "name": "Bayelsa State",
            "code": "BY"
        },
        {
            "name": "Benue State",
            "code": "BE"
        },
        {
            "name": "Borno State",
            "code": "BO"
        },
        {
            "name": "Cross River State",
            "code": "CR"
        },
        {
            "name": "Delta State",
            "code": "DE"
        },
        {
            "name": "Ebonyi State",
            "code": "EB"
        },
        {
            "name": "Edo State",
            "code": "ED"
        },
        {
            "name": "Ekiti State",
            "code": "EK"
        },
        {
            "name": "Enugu State",
            "code": "EN"
        },
        {
            "name": "Federal Capital Territory",
            "code": "FC"
        },
        {
            "name": "Gombe State",
            "code": "GO"
        },
        {
            "name": "Imo State",
            "code": "IM"
        },
        {
            "name": "Jigawa State",
            "code": "JI"
        },
        {
            "name": "Kaduna State",
            "code": "KD"
        },
        {
            "name": "Kano State",
            "code": "KN"
        },
        {
            "name": "Katsina State",
            "code": "KT"
        },
        {
            "name": "Kebbi State",
            "code": "KE"
        },
        {
            "name": "Kogi State",
            "code": "KO"
        },
        {
            "name": "Kwara State",
            "code": "KW"
        },
        {
            "name": "Lagos",
            "code": "LA"
        },
        {
            "name": "Nasarawa State",
            "code": "NA"
        },
        {
            "name": "Niger State",
            "code": "NI"
        },
        {
            "name": "Ogun State",
            "code": "OG"
        },
        {
            "name": "Ondo State",
            "code": "ON"
        },
        {
            "name": "Osun State",
            "code": "OS"
        },
        {
            "name": "Oyo State",
            "code": "OY"
        },
        {
            "name": "Plateau State",
            "code": "PL"
        },
        {
            "name": "Sokoto State",
            "code": "SO"
        },
        {
            "name": "Taraba State",
            "code": "TA"
        },
        {
            "name": "Yobe State",
            "code": "YO"
        },
        {
            "name": "Zamfara State",
            "code": "ZA"
        }
    ]
  },
  {
    "code2": "NU",
    "code3": "NIU",
    "name": "Niue",
    "capital": "Alofi",
    "region": "Oceania",
    "subregion": "Polynesia",
    "states": []
  },
  {
    "code2": "NF",
    "code3": "NFK",
    "name": "Norfolk Island",
    "capital": "Kingston",
    "region": "Oceania",
    "subregion": "Australia and New Zealand",
    "states": []
  },
  {
    "code2": "MP",
    "code3": "MNP",
    "name": "Northern Mariana Islands",
    "capital": "Saipan",
    "region": "Oceania",
    "subregion": "Micronesia",
    "states": []
  },
  {
    "code2": "NO",
    "code3": "NOR",
    "name": "Norway",
    "capital": "Oslo",
    "region": "Europe",
    "subregion": "Northern Europe",
    "states": [
        {
            "name": "Akershus",
            "code": "02"
        },
        {
            "name": "Buskerud",
            "code": "06"
        },
        {
            "name": "Finnmark",
            "code": "20"
        },
        {
            "name": "Hedmark",
            "code": "04"
        },
        {
            "name": "Hordaland",
            "code": "12"
        },
        {
            "name": "Jan Mayen",
            "code": "22"
        },
        {
            "name": "Møre og Romsdal",
            "code": "15"
        },
        {
            "name": "Nord-Trøndelag",
            "code": "17"
        },
        {
            "name": "Nordland",
            "code": "18"
        },
        {
            "name": "Oppland",
            "code": "05"
        },
        {
            "name": "Oslo",
            "code": "03"
        },
        {
            "name": "Østfold",
            "code": "01"
        },
        {
            "name": "Rogaland",
            "code": "11"
        },
        {
            "name": "Sogn og Fjordane",
            "code": "14"
        },
        {
            "name": "Sør-Trøndelag",
            "code": "16"
        },
        {
            "name": "Svalbard",
            "code": "21"
        },
        {
            "name": "Telemark",
            "code": "08"
        },
        {
            "name": "Troms",
            "code": "19"
        },
        {
            "name": "Trøndelag",
            "code": "50"
        },
        {
            "name": "Vest-Agder",
            "code": "10"
        },
        {
            "name": "Vestfold",
            "code": "07"
        }
    ]
  },
  {
    "code2": "OM",
    "code3": "OMN",
    "name": "Oman",
    "capital": "Muscat",
    "region": "Asia",
    "subregion": "Western Asia",
    "states": [
        {
            "name": "Ad Dakhiliyah Governorate",
            "code": "DA"
        },
        {
            "name": "Ad Dhahirah Governorate",
            "code": "ZA"
        },
        {
            "name": "Al Batinah North Governorate",
            "code": "BS"
        },
        {
            "name": "Al Batinah Region",
            "code": "BA"
        },
        {
            "name": "Al Batinah South Governorate",
            "code": "BJ"
        },
        {
            "name": "Al Buraimi Governorate",
            "code": "BU"
        },
        {
            "name": "Al Wusta Governorate",
            "code": "WU"
        },
        {
            "name": "Ash Sharqiyah North Governorate",
            "code": "SS"
        },
        {
            "name": "Ash Sharqiyah Region",
            "code": "SH"
        },
        {
            "name": "Ash Sharqiyah South Governorate",
            "code": "SJ"
        },
        {
            "name": "Dhofar Governorate",
            "code": "ZU"
        },
        {
            "name": "Musandam Governorate",
            "code": "MU"
        },
        {
            "name": "Muscat Governorate",
            "code": "MA"
        }
    ]
  },
  {
    "code2": "PK",
    "code3": "PAK",
    "name": "Pakistan",
    "capital": "Islamabad",
    "region": "Asia",
    "subregion": "Southern Asia",
    "states": [
        {
            "name": "Azad Kashmir",
            "code": "JK"
        },
        {
            "name": "Balochistan",
            "code": "BA"
        },
        {
            "name": "Federally Administered Tribal Areas",
            "code": "TA"
        },
        {
            "name": "Gilgit-Baltistan",
            "code": "GB"
        },
        {
            "name": "Islamabad Capital Territory",
            "code": "IS"
        },
        {
            "name": "Khyber Pakhtunkhwa",
            "code": "KP"
        },
        {
            "name": "Punjab",
            "code": "PB"
        },
        {
            "name": "Sindh",
            "code": "SD"
        }
    ]
  },
  {
    "code2": "PW",
    "code3": "PLW",
    "name": "Palau",
    "capital": "Ngerulmud",
    "region": "Oceania",
    "subregion": "Micronesia",
    "states": [
        {
            "name": "Aimeliik",
            "code": "002"
        },
        {
            "name": "Airai",
            "code": "004"
        },
        {
            "name": "Angaur",
            "code": "010"
        },
        {
            "name": "Hatohobei",
            "code": "050"
        },
        {
            "name": "Kayangel",
            "code": "100"
        },
        {
            "name": "Koror",
            "code": "150"
        },
        {
            "name": "Melekeok",
            "code": "212"
        },
        {
            "name": "Ngaraard",
            "code": "214"
        },
        {
            "name": "Ngarchelong",
            "code": "218"
        },
        {
            "name": "Ngardmau",
            "code": "222"
        },
        {
            "name": "Ngatpang",
            "code": "224"
        },
        {
            "name": "Ngchesar",
            "code": "226"
        },
        {
            "name": "Ngeremlengui",
            "code": "227"
        },
        {
            "name": "Ngiwal",
            "code": "228"
        },
        {
            "name": "Peleliu",
            "code": "350"
        },
        {
            "name": "Sonsorol",
            "code": "370"
        }
    ]
  },
  {
    "code2": "PS",
    "code3": "PSE",
    "name": "Palestine",
    "capital": "Ramallah",
    "region": "Asia",
    "subregion": "Western Asia",
    "states": [
      {
        "code": "BTH",
        "name": "Bethlehem",
        "subdivision": null
      },
      {
        "code": "DEB",
        "name": "Deir El Balah",
        "subdivision": null
      },
      {
        "code": "GZA",
        "name": "Gaza",
        "subdivision": null
      },
      {
        "code": "HBN",
        "name": "Hebron",
        "subdivision": null
      },
      {
        "code": "JEN",
        "name": "Jenin",
        "subdivision": null
      },
      {
        "code": "JRH",
        "name": "Jericho – Al Aghwar",
        "subdivision": null
      },
      {
        "code": "JEM",
        "name": "Jerusalem",
        "subdivision": null
      },
      {
        "code": "KYS",
        "name": "Khan Yunis",
        "subdivision": null
      },
      {
        "code": "NBS",
        "name": "Nablus",
        "subdivision": null
      },
      {
        "code": "NGZ",
        "name": "North Gaza",
        "subdivision": null
      },
      {
        "code": "QQA",
        "name": "Qalqilya",
        "subdivision": null
      },
      {
        "code": "RFH",
        "name": "Rafah",
        "subdivision": null
      },
      {
        "code": "RBH",
        "name": "Ramallah",
        "subdivision": null
      },
      {
        "code": "SLT",
        "name": "Salfit",
        "subdivision": null
      },
      {
        "code": "TBS",
        "name": "Tubas",
        "subdivision": null
      },
      {
        "code": "TKM",
        "name": "Tulkarm",
        "subdivision": null
      }
    ]
  },
  {
    "code2": "PA",
    "code3": "PAN",
    "name": "Panama",
    "capital": "Panama City",
    "region": "Americas",
    "subregion": "Central America",
    "states": [
        {
            "name": "Bocas del Toro Province",
            "code": "1"
        },
        {
            "name": "Chiriquí Province",
            "code": "4"
        },
        {
            "name": "Coclé Province",
            "code": "2"
        },
        {
            "name": "Colón Province",
            "code": "3"
        },
        {
            "name": "Darién Province",
            "code": "5"
        },
        {
            "name": "Emberá-Wounaan Comarca",
            "code": "EM"
        },
        {
            "name": "Guna Yala",
            "code": "KY"
        },
        {
            "name": "Herrera Province",
            "code": "6"
        },
        {
            "name": "Los Santos Province",
            "code": "7"
        },
        {
            "name": "Ngöbe-Buglé Comarca",
            "code": "NB"
        },
        {
            "name": "Panamá Oeste Province",
            "code": "10"
        },
        {
            "name": "Panamá Province",
            "code": "8"
        },
        {
            "name": "Veraguas Province",
            "code": "9"
        }
    ]
  },
  {
    "code2": "PG",
    "code3": "PNG",
    "name": "Papua New Guinea",
    "capital": "Port Moresby",
    "region": "Oceania",
    "subregion": "Melanesia",
    "states": [
        {
            "name": "Bougainville",
            "code": "NSB"
        },
        {
            "name": "Central Province",
            "code": "CPM"
        },
        {
            "name": "Chimbu Province",
            "code": "CPK"
        },
        {
            "name": "East New Britain",
            "code": "EBR"
        },
        {
            "name": "Eastern Highlands Province",
            "code": "EHG"
        },
        {
            "name": "Enga Province",
            "code": "EPW"
        },
        {
            "name": "Gulf",
            "code": "GPK"
        },
        {
            "name": "Hela",
            "code": "HLA"
        },
        {
            "name": "Jiwaka Province",
            "code": "JWK"
        },
        {
            "name": "Madang Province",
            "code": "MPM"
        },
        {
            "name": "Manus Province",
            "code": "MRL"
        },
        {
            "name": "Milne Bay Province",
            "code": "MBA"
        },
        {
            "name": "Morobe Province",
            "code": "MPL"
        },
        {
            "name": "New Ireland Province",
            "code": "NIK"
        },
        {
            "name": "Oro Province",
            "code": "NPP"
        },
        {
            "name": "Port Moresby",
            "code": "NCD"
        },
        {
            "name": "Sandaun Province",
            "code": "SAN"
        },
        {
            "name": "Southern Highlands Province",
            "code": "SHM"
        },
        {
            "name": "West New Britain Province",
            "code": "WBK"
        },
        {
            "name": "Western Highlands Province",
            "code": "WHM"
        },
        {
            "name": "Western Province",
            "code": "WPD"
        }
    ]
  },
  {
    "code2": "PY",
    "code3": "PRY",
    "name": "Paraguay",
    "capital": "Asunción",
    "region": "Americas",
    "subregion": "South America",
    "states": [
        {
            "name": "Alto Paraguay Department",
            "code": "16"
        },
        {
            "name": "Alto Paraná Department",
            "code": "10"
        },
        {
            "name": "Amambay Department",
            "code": "13"
        },
        {
            "name": "Boquerón Department",
            "code": "19"
        },
        {
            "name": "Caaguazú",
            "code": "5"
        },
        {
            "name": "Caazapá",
            "code": "6"
        },
        {
            "name": "Canindeyú",
            "code": "14"
        },
        {
            "name": "Central Department",
            "code": "11"
        },
        {
            "name": "Concepción Department",
            "code": "1"
        },
        {
            "name": "Cordillera Department",
            "code": "3"
        },
        {
            "name": "Guairá Department",
            "code": "4"
        },
        {
            "name": "Itapúa",
            "code": "7"
        },
        {
            "name": "Misiones Department",
            "code": "8"
        },
        {
            "name": "Ñeembucú Department",
            "code": "12"
        },
        {
            "name": "Paraguarí Department",
            "code": "9"
        },
        {
            "name": "Presidente Hayes Department",
            "code": "15"
        },
        {
            "name": "San Pedro Department",
            "code": "2"
        }
    ]
  },
  {
    "code2": "PE",
    "code3": "PER",
    "name": "Peru",
    "capital": "Lima",
    "region": "Americas",
    "subregion": "South America",
    "states": [
        {
            "name": "Amazonas",
            "code": "AMA"
        },
        {
            "name": "Áncash",
            "code": "ANC"
        },
        {
            "name": "Apurímac",
            "code": "APU"
        },
        {
            "name": "Arequipa",
            "code": "ARE"
        },
        {
            "name": "Ayacucho",
            "code": "AYA"
        },
        {
            "name": "Cajamarca",
            "code": "CAJ"
        },
        {
            "name": "Callao",
            "code": "CAL"
        },
        {
            "name": "Cusco",
            "code": "CUS"
        },
        {
            "name": "Huancavelica",
            "code": "HUV"
        },
        {
            "name": "Huanuco",
            "code": "HUC"
        },
        {
            "name": "Ica",
            "code": "ICA"
        },
        {
            "name": "Junín",
            "code": "JUN"
        },
        {
            "name": "La Libertad",
            "code": "LAL"
        },
        {
            "name": "Lambayeque",
            "code": "LAM"
        },
        {
            "name": "Lima",
            "code": "LIM"
        },
        {
            "name": "Madre de Dios",
            "code": "MDD"
        },
        {
            "name": "Moquegua",
            "code": "MOQ"
        },
        {
            "name": "Pasco",
            "code": "PAS"
        },
        {
            "name": "Piura",
            "code": "PIU"
        },
        {
            "name": "Puno",
            "code": "PUN"
        },
        {
            "name": "San Martín",
            "code": "SAM"
        },
        {
            "name": "Tacna",
            "code": "TAC"
        },
        {
            "name": "Tumbes",
            "code": "TUM"
        },
        {
            "name": "Ucayali",
            "code": "UCA"
        }
    ]
  },
  {
    "code2": "PH",
    "code3": "PHL",
    "name": "Philippines",
    "capital": "Manila",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "states": [
        {
            "name": "Amazonas",
            "code": "AMA"
        },
        {
            "name": "Áncash",
            "code": "ANC"
        },
        {
            "name": "Apurímac",
            "code": "APU"
        },
        {
            "name": "Arequipa",
            "code": "ARE"
        },
        {
            "name": "Ayacucho",
            "code": "AYA"
        },
        {
            "name": "Cajamarca",
            "code": "CAJ"
        },
        {
            "name": "Callao",
            "code": "CAL"
        },
        {
            "name": "Cusco",
            "code": "CUS"
        },
        {
            "name": "Huancavelica",
            "code": "HUV"
        },
        {
            "name": "Huanuco",
            "code": "HUC"
        },
        {
            "name": "Ica",
            "code": "ICA"
        },
        {
            "name": "Junín",
            "code": "JUN"
        },
        {
            "name": "La Libertad",
            "code": "LAL"
        },
        {
            "name": "Lambayeque",
            "code": "LAM"
        },
        {
            "name": "Lima",
            "code": "LIM"
        },
        {
            "name": "Madre de Dios",
            "code": "MDD"
        },
        {
            "name": "Moquegua",
            "code": "MOQ"
        },
        {
            "name": "Pasco",
            "code": "PAS"
        },
        {
            "name": "Piura",
            "code": "PIU"
        },
        {
            "name": "Puno",
            "code": "PUN"
        },
        {
            "name": "San Martín",
            "code": "SAM"
        },
        {
            "name": "Tacna",
            "code": "TAC"
        },
        {
            "name": "Tumbes",
            "code": "TUM"
        },
        {
            "name": "Ucayali",
            "code": "UCA"
        }
    ]
  },
  {
    "code2": "PN",
    "code3": "PCN",
    "name": "Pitcairn Islands",
    "capital": "Adamstown",
    "region": "Oceania",
    "subregion": "Polynesia",
    "states": []
  },
  {
    "code2": "PL",
    "code3": "POL",
    "name": "Poland",
    "capital": "Warsaw",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "states": [
        {
            "name": "Greater Poland Voivodeship",
            "code": "WP"
        },
        {
            "name": "Kuyavian-Pomeranian Voivodeship",
            "code": "KP"
        },
        {
            "name": "Lesser Poland Voivodeship",
            "code": "MA"
        },
        {
            "name": "Lower Silesian Voivodeship",
            "code": "DS"
        },
        {
            "name": "Lublin Voivodeship",
            "code": "LU"
        },
        {
            "name": "Lubusz Voivodeship",
            "code": "LB"
        },
        {
            "name": "Łódź Voivodeship",
            "code": "LD"
        },
        {
            "name": "Masovian Voivodeship",
            "code": "MZ"
        },
        {
            "name": "Opole Voivodeship",
            "code": "OP"
        },
        {
            "name": "Podkarpackie Voivodeship",
            "code": "PK"
        },
        {
            "name": "Podlaskie Voivodeship",
            "code": "PD"
        },
        {
            "name": "Pomeranian Voivodeship",
            "code": "PM"
        },
        {
            "name": "Silesian Voivodeship",
            "code": "SL"
        },
        {
            "name": "Świętokrzyskie Voivodeship",
            "code": "SK"
        },
        {
            "name": "Warmian-Masurian Voivodeship",
            "code": "WN"
        },
        {
            "name": "West Pomeranian Voivodeship",
            "code": "ZP"
        }
    ]
  },
  {
    "code2": "PT",
    "code3": "PRT",
    "name": "Portugal",
    "capital": "Lisbon",
    "region": "Europe",
    "subregion": "Southern Europe",
    "states": [
        {
            "name": "Aveiro District",
            "code": "01"
        },
        {
            "name": "Azores",
            "code": "20"
        },
        {
            "name": "Beja District",
            "code": "02"
        },
        {
            "name": "Braga District",
            "code": "03"
        },
        {
            "name": "Bragança District",
            "code": "04"
        },
        {
            "name": "Castelo Branco District",
            "code": "05"
        },
        {
            "name": "Coimbra District",
            "code": "06"
        },
        {
            "name": "Évora District",
            "code": "07"
        },
        {
            "name": "Faro District",
            "code": "08"
        },
        {
            "name": "Guarda District",
            "code": "09"
        },
        {
            "name": "Leiria District",
            "code": "10"
        },
        {
            "name": "Lisbon District",
            "code": "11"
        },
        {
            "name": "Madeira",
            "code": "30"
        },
        {
            "name": "Portalegre District",
            "code": "12"
        },
        {
            "name": "Porto District",
            "code": "13"
        },
        {
            "name": "Santarém District",
            "code": "14"
        },
        {
            "name": "Setúbal District",
            "code": "15"
        },
        {
            "name": "Viana do Castelo District",
            "code": "16"
        },
        {
            "name": "Vila Real District",
            "code": "17"
        },
        {
            "name": "Viseu District",
            "code": "18"
        }
    ]
  },
  {
    "code2": "PR",
    "code3": "PRI",
    "name": "Puerto Rico",
    "capital": "San Juan",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": []
  },
  {
    "code2": "QA",
    "code3": "QAT",
    "name": "Qatar",
    "capital": "Doha",
    "region": "Asia",
    "subregion": "Western Asia",
    "states": [
        {
            "name": "Al Daayen",
            "code": "ZA"
        },
        {
            "name": "Al Khor",
            "code": "KH"
        },
        {
            "name": "Al Rayyan Municipality",
            "code": "RA"
        },
        {
            "name": "Al Wakrah",
            "code": "WA"
        },
        {
            "name": "Al-Shahaniya",
            "code": "SH"
        },
        {
            "name": "Doha",
            "code": "DA"
        },
        {
            "name": "Madinat ash Shamal",
            "code": "MS"
        },
        {
            "name": "Umm Salal Municipality",
            "code": "US"
        }
    ]
  },
  {
    "code2": "XK",
    "code3": "KOS",
    "name": "Republic of Kosovo",
    "capital": "Pristina",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "states": []
  },
  {
    "code2": "RE",
    "code3": "REU",
    "name": "Réunion",
    "capital": "Saint-Denis",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "states": []
  },
  {
    "code2": "RO",
    "code3": "ROU",
    "name": "Romania",
    "capital": "Bucharest",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "states": [
        {
            "name": "Alba",
            "code": "AB"
        },
        {
            "name": "Arad County",
            "code": "AR"
        },
        {
            "name": "Arges",
            "code": "AG"
        },
        {
            "name": "Bacău County",
            "code": "BC"
        },
        {
            "name": "Bihor County",
            "code": "BH"
        },
        {
            "name": "Bistrița-Năsăud County",
            "code": "BN"
        },
        {
            "name": "Botoșani County",
            "code": "BT"
        },
        {
            "name": "Braila",
            "code": "BR"
        },
        {
            "name": "Brașov County",
            "code": "BV"
        },
        {
            "name": "Bucharest",
            "code": "B"
        },
        {
            "name": "Buzău County",
            "code": "BZ"
        },
        {
            "name": "Călărași County",
            "code": "CL"
        },
        {
            "name": "Caraș-Severin County",
            "code": "CS"
        },
        {
            "name": "Cluj County",
            "code": "CJ"
        },
        {
            "name": "Constanța County",
            "code": "CT"
        },
        {
            "name": "Covasna County",
            "code": "CV"
        },
        {
            "name": "Dâmbovița County",
            "code": "DB"
        },
        {
            "name": "Dolj County",
            "code": "DJ"
        },
        {
            "name": "Galați County",
            "code": "GL"
        },
        {
            "name": "Giurgiu County",
            "code": "GR"
        },
        {
            "name": "Gorj County",
            "code": "GJ"
        },
        {
            "name": "Harghita County",
            "code": "HR"
        },
        {
            "name": "Hunedoara County",
            "code": "HD"
        },
        {
            "name": "Ialomița County",
            "code": "IL"
        },
        {
            "name": "Iași County",
            "code": "IS"
        },
        {
            "name": "Ilfov County",
            "code": "IF"
        },
        {
            "name": "Mehedinți County",
            "code": "MH"
        },
        {
            "name": "Mureș County",
            "code": "MM"
        },
        {
            "name": "Neamț County",
            "code": "NT"
        },
        {
            "name": "Olt County",
            "code": "OT"
        },
        {
            "name": "Prahova County",
            "code": "PH"
        },
        {
            "name": "Sălaj County",
            "code": "SJ"
        },
        {
            "name": "Satu Mare County",
            "code": "SM"
        },
        {
            "name": "Sibiu County",
            "code": "SB"
        },
        {
            "name": "Suceava County",
            "code": "SV"
        },
        {
            "name": "Teleorman County",
            "code": "TR"
        },
        {
            "name": "Timiș County",
            "code": "TM"
        },
        {
            "name": "Tulcea County",
            "code": "TL"
        },
        {
            "name": "Vâlcea County",
            "code": "VL"
        },
        {
            "name": "Vaslui County",
            "code": "VS"
        },
        {
            "name": "Vrancea County",
            "code": "VN"
        }
    ]
  },
  {
    "code2": "RU",
    "code3": "RUS",
    "name": "Russia",
    "capital": "Moscow",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "states": [
        {
            "name": "Altai Krai",
            "code": "ALT"
        },
        {
            "name": "Altai Republic",
            "code": "AL"
        },
        {
            "name": "Amur Oblast",
            "code": "AMU"
        },
        {
            "name": "Arkhangelsk",
            "code": "ARK"
        },
        {
            "name": "Astrakhan Oblast",
            "code": "AST"
        },
        {
            "name": "Belgorod Oblast",
            "code": "BEL"
        },
        {
            "name": "Bryansk Oblast",
            "code": "BRY"
        },
        {
            "name": "Chechen Republic",
            "code": "CE"
        },
        {
            "name": "Chelyabinsk Oblast",
            "code": "CHE"
        },
        {
            "name": "Chukotka Autonomous Okrug",
            "code": "CHU"
        },
        {
            "name": "Chuvash Republic",
            "code": "CU"
        },
        {
            "name": "Irkutsk",
            "code": "IRK"
        },
        {
            "name": "Ivanovo Oblast",
            "code": "IVA"
        },
        {
            "name": "Jewish Autonomous Oblast",
            "code": "YEV"
        },
        {
            "name": "Kabardino-Balkar Republic",
            "code": "KB"
        },
        {
            "name": "Kaliningrad",
            "code": "KGD"
        },
        {
            "name": "Kaluga Oblast",
            "code": "KLU"
        },
        {
            "name": "Kamchatka Krai",
            "code": "KAM"
        },
        {
            "name": "Karachay-Cherkess Republic",
            "code": "KC"
        },
        {
            "name": "Kemerovo Oblast",
            "code": "KEM"
        },
        {
            "name": "Khabarovsk Krai",
            "code": "KHA"
        },
        {
            "name": "Khanty-Mansi Autonomous Okrug",
            "code": "KHM"
        },
        {
            "name": "Kirov Oblast",
            "code": "KIR"
        },
        {
            "name": "Komi Republic",
            "code": "KO"
        },
        {
            "name": "Kostroma Oblast",
            "code": "KOS"
        },
        {
            "name": "Krasnodar Krai",
            "code": "KDA"
        },
        {
            "name": "Krasnoyarsk Krai",
            "code": "KYA"
        },
        {
            "name": "Kurgan Oblast",
            "code": "KGN"
        },
        {
            "name": "Kursk Oblast",
            "code": "KRS"
        },
        {
            "name": "Leningrad Oblast",
            "code": "LEN"
        },
        {
            "name": "Lipetsk Oblast",
            "code": "LIP"
        },
        {
            "name": "Magadan Oblast",
            "code": "MAG"
        },
        {
            "name": "Mari El Republic",
            "code": "ME"
        },
        {
            "name": "Moscow",
            "code": "MOW"
        },
        {
            "name": "Moscow Oblast",
            "code": "MOS"
        },
        {
            "name": "Murmansk Oblast",
            "code": "MUR"
        },
        {
            "name": "Nenets Autonomous Okrug",
            "code": "NEN"
        },
        {
            "name": "Nizhny Novgorod Oblast",
            "code": "NIZ"
        },
        {
            "name": "Novgorod Oblast",
            "code": "NGR"
        },
        {
            "name": "Novosibirsk",
            "code": "NVS"
        },
        {
            "name": "Omsk Oblast",
            "code": "OMS"
        },
        {
            "name": "Orenburg Oblast",
            "code": "ORE"
        },
        {
            "name": "Oryol Oblast",
            "code": "ORL"
        },
        {
            "name": "Penza Oblast",
            "code": "PNZ"
        },
        {
            "name": "Perm Krai",
            "code": "PER"
        },
        {
            "name": "Primorsky Krai",
            "code": "PRI"
        },
        {
            "name": "Pskov Oblast",
            "code": "PSK"
        },
        {
            "name": "Republic of Adygea",
            "code": "AD"
        },
        {
            "name": "Republic of Bashkortostan",
            "code": "BA"
        },
        {
            "name": "Republic of Buryatia",
            "code": "BU"
        },
        {
            "name": "Republic of Dagestan",
            "code": "DA"
        },
        {
            "name": "Republic of Ingushetia",
            "code": "IN"
        },
        {
            "name": "Republic of Kalmykia",
            "code": "KL"
        },
        {
            "name": "Republic of Karelia",
            "code": "KR"
        },
        {
            "name": "Republic of Khakassia",
            "code": "KK"
        },
        {
            "name": "Republic of Mordovia",
            "code": "MO"
        },
        {
            "name": "Republic of North Ossetia-Alania",
            "code": "SE"
        },
        {
            "name": "Republic of Tatarstan",
            "code": "TA"
        },
        {
            "name": "Rostov Oblast",
            "code": "ROS"
        },
        {
            "name": "Ryazan Oblast",
            "code": "RYA"
        },
        {
            "name": "Saint Petersburg",
            "code": "SPE"
        },
        {
            "name": "Sakha Republic",
            "code": "SA"
        },
        {
            "name": "Sakhalin",
            "code": "SAK"
        },
        {
            "name": "Samara Oblast",
            "code": "SAM"
        },
        {
            "name": "Saratov Oblast",
            "code": "SAR"
        },
        {
            "name": "Sevastopol",
            "code": "UA-40"
        },
        {
            "name": "Smolensk Oblast",
            "code": "SMO"
        },
        {
            "name": "Stavropol Krai",
            "code": "STA"
        },
        {
            "name": "Sverdlovsk",
            "code": "SVE"
        },
        {
            "name": "Tambov Oblast",
            "code": "TAM"
        },
        {
            "name": "Tomsk Oblast",
            "code": "TOM"
        },
        {
            "name": "Tula Oblast",
            "code": "TUL"
        },
        {
            "name": "Tuva Republic",
            "code": "TY"
        },
        {
            "name": "Tver Oblast",
            "code": "TVE"
        },
        {
            "name": "Tyumen Oblast",
            "code": "TYU"
        },
        {
            "name": "Udmurt Republic",
            "code": "UD"
        },
        {
            "name": "Ulyanovsk Oblast",
            "code": "ULY"
        },
        {
            "name": "Vladimir Oblast",
            "code": "VLA"
        },
        {
            "name": "Vologda Oblast",
            "code": "VLG"
        },
        {
            "name": "Voronezh Oblast",
            "code": "VOR"
        },
        {
            "name": "Yamalo-Nenets Autonomous Okrug",
            "code": "YAN"
        },
        {
            "name": "Yaroslavl Oblast",
            "code": "YAR"
        },
        {
            "name": "Zabaykalsky Krai",
            "code": "ZAB"
        }
    ]
  },
  {
    "code2": "RW",
    "code3": "RWA",
    "name": "Rwanda",
    "capital": "Kigali",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "states": [
        {
            "name": "Eastern Province",
            "code": "02"
        },
        {
            "name": "Kigali district",
            "code": "01"
        },
        {
            "name": "Northern Province",
            "code": "03"
        },
        {
            "name": "Southern Province",
            "code": "05"
        },
        {
            "name": "Western Province",
            "code": "04"
        }
    ]
  },
  {
    "code2": "BL",
    "code3": "BLM",
    "name": "Saint Barthélemy",
    "capital": "Gustavia",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": []
  },
  {
    "code2": "SH",
    "code3": "SHN",
    "name": "Saint Helena",
    "capital": "Jamestown",
    "region": "Africa",
    "subregion": "Western Africa",
    "states": [
      {
        "code": "AC",
        "name": "Ascension",
        "subdivision": null
      },
      {
        "code": "HL",
        "name": "Saint Helena",
        "subdivision": null
      },
      {
        "code": "TA",
        "name": "Tristan da Cunha",
        "subdivision": null
      }
    ]
  },
  {
    "code2": "KN",
    "code3": "KNA",
    "name": "Saint Kitts and Nevis",
    "capital": "Basseterre",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": [
        {
            "name": "Christ Church Nichola Town Parish",
            "code": "01"
        },
        {
            "name": "Nevis",
            "code": "N"
        },
        {
            "name": "Saint Anne Sandy Point Parish",
            "code": "02"
        },
        {
            "name": "Saint George Gingerland Parish",
            "code": "04"
        },
        {
            "name": "Saint James Windward Parish",
            "code": "05"
        },
        {
            "name": "Saint John Capisterre Parish",
            "code": "06"
        },
        {
            "name": "Saint John Figtree Parish",
            "code": "07"
        },
        {
            "name": "Saint Kitts",
            "code": "K"
        },
        {
            "name": "Saint Mary Cayon Parish",
            "code": "08"
        },
        {
            "name": "Saint Paul Capisterre Parish",
            "code": "09"
        },
        {
            "name": "Saint Paul Charlestown Parish",
            "code": "10"
        },
        {
            "name": "Saint Peter Basseterre Parish",
            "code": "11"
        },
        {
            "name": "Saint Thomas Lowland Parish",
            "code": "12"
        },
        {
            "name": "Saint Thomas Middle Island Parish",
            "code": "13"
        },
        {
            "name": "Trinity Palmetto Point Parish",
            "code": "15"
        }
    ]
  },
  {
    "code2": "LC",
    "code3": "LCA",
    "name": "Saint Lucia",
    "capital": "Castries",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": [
        {
            "name": "Anse la Raye Quarter",
            "code": "01"
        },
        {
            "name": "Canaries",
            "code": "12"
        },
        {
            "name": "Castries Quarter",
            "code": "02"
        },
        {
            "name": "Choiseul Quarter",
            "code": "03"
        },
        {
            "name": "Dauphin Quarter",
            "code": "04"
        },
        {
            "name": "Dennery Quarter",
            "code": "05"
        },
        {
            "name": "Gros Islet Quarter",
            "code": "06"
        },
        {
            "name": "Laborie Quarter",
            "code": "07"
        },
        {
            "name": "Micoud Quarter",
            "code": "08"
        },
        {
            "name": "Praslin Quarter",
            "code": "09"
        },
        {
            "name": "Soufrière Quarter",
            "code": "10"
        },
        {
            "name": "Vieux Fort Quarter",
            "code": "11"
        }
    ]
  },
  {
    "code2": "MF",
    "code3": "MAF",
    "name": "Saint Martin",
    "capital": "Marigot",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": []
  },
  {
    "code2": "PM",
    "code3": "SPM",
    "name": "Saint Pierre and Miquelon",
    "capital": "Saint-Pierre",
    "region": "Americas",
    "subregion": "Northern America",
    "states": []
  },
  {
    "code2": "VC",
    "code3": "VCT",
    "name": "Saint Vincent and the Grenadines",
    "capital": "Kingstown",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": [
        {
            "name": "Charlotte Parish",
            "code": "01"
        },
        {
            "name": "Grenadines Parish",
            "code": "06"
        },
        {
            "name": "Saint Andrew Parish",
            "code": "02"
        },
        {
            "name": "Saint David Parish",
            "code": "03"
        },
        {
            "name": "Saint George Parish",
            "code": "04"
        },
        {
            "name": "Saint Patrick Parish",
            "code": "05"
        }
    ]
  },
  {
    "code2": "WS",
    "code3": "WSM",
    "name": "Samoa",
    "capital": "Apia",
    "region": "Oceania",
    "subregion": "Polynesia",
    "states": [
        {
            "name": "A'ana",
            "code": "AA"
        },
        {
            "name": "Aiga-i-le-Tai",
            "code": "AL"
        },
        {
            "name": "Atua",
            "code": "AT"
        },
        {
            "name": "Fa'asaleleaga",
            "code": "FA"
        },
        {
            "name": "Gaga'emauga",
            "code": "GE"
        },
        {
            "name": "Gaga'ifomauga",
            "code": "GI"
        },
        {
            "name": "Palauli",
            "code": "PA"
        },
        {
            "name": "Satupa'itea",
            "code": "SA"
        },
        {
            "name": "Tuamasaga",
            "code": "TU"
        },
        {
            "name": "Va'a-o-Fonoti",
            "code": "VF"
        },
        {
            "name": "Vaisigano",
            "code": "VS"
        }
    ]
  },
  {
    "code2": "SM",
    "code3": "SMR",
    "name": "San Marino",
    "capital": "City of San Marino",
    "region": "Europe",
    "subregion": "Southern Europe",
    "states": [
        {
            "name": "Acquaviva",
            "code": "01"
        },
        {
            "name": "Borgo Maggiore",
            "code": "06"
        },
        {
            "name": "Chiesanuova",
            "code": "02"
        },
        {
            "name": "Domagnano",
            "code": "03"
        },
        {
            "name": "Faetano",
            "code": "04"
        },
        {
            "name": "Fiorentino",
            "code": "05"
        },
        {
            "name": "Montegiardino",
            "code": "08"
        },
        {
            "name": "San Marino",
            "code": "07"
        },
        {
            "name": "Serravalle",
            "code": "09"
        }
    ]
  },
  {
    "code2": "ST",
    "code3": "STP",
    "name": "São Tomé and Príncipe",
    "capital": "São Tomé",
    "region": "Africa",
    "subregion": "Middle Africa",
    "states": [
        {
            "name": "Príncipe Province",
            "code": "P"
        },
        {
            "name": "São Tomé Province",
            "code": "S"
        }
    ]
  },
  {
    "code2": "SA",
    "code3": "SAU",
    "name": "Saudi Arabia",
    "capital": "Riyadh",
    "region": "Asia",
    "subregion": "Western Asia",
    "states": [
        {
            "name": "'Asir Region",
            "code": "14"
        },
        {
            "name": "Al Bahah Region",
            "code": "11"
        },
        {
            "name": "Al Jawf Region",
            "code": "12"
        },
        {
            "name": "Al Madinah Region",
            "code": "03"
        },
        {
            "name": "Al-Qassim Region",
            "code": "05"
        },
        {
            "name": "Eastern Province",
            "code": "04"
        },
        {
            "name": "Ha'il Region",
            "code": "06"
        },
        {
            "name": "Jizan Region",
            "code": "09"
        },
        {
            "name": "Makkah Region",
            "code": "02"
        },
        {
            "name": "Najran Region",
            "code": "10"
        },
        {
            "name": "Northern Borders Region",
            "code": "08"
        },
        {
            "name": "Riyadh Region",
            "code": "01"
        },
        {
            "name": "Tabuk Region",
            "code": "07"
        }
    ]
  },
  {
    "code2": "SN",
    "code3": "SEN",
    "name": "Senegal",
    "capital": "Dakar",
    "region": "Africa",
    "subregion": "Western Africa",
    "states": [
        {
            "name": "Dakar",
            "code": "DK"
        },
        {
            "name": "Diourbel Region",
            "code": "DB"
        },
        {
            "name": "Fatick",
            "code": "FK"
        },
        {
            "name": "Kaffrine",
            "code": "KA"
        },
        {
            "name": "Kaolack",
            "code": "KL"
        },
        {
            "name": "Kédougou",
            "code": "KE"
        },
        {
            "name": "Kolda",
            "code": "KD"
        },
        {
            "name": "Louga",
            "code": "LG"
        },
        {
            "name": "Matam",
            "code": "MT"
        },
        {
            "name": "Saint-Louis",
            "code": "SL"
        },
        {
            "name": "Sédhiou",
            "code": "SE"
        },
        {
            "name": "Tambacounda Region",
            "code": "TC"
        },
        {
            "name": "Thiès Region",
            "code": "TH"
        },
        {
            "name": "Ziguinchor",
            "code": "ZG"
        }
    ]
  },
  {
    "code2": "RS",
    "code3": "SRB",
    "name": "Serbia",
    "capital": "Belgrade",
    "region": "Europe",
    "subregion": "Southern Europe",
    "states": [
        {
            "name": "Belgrade",
            "code": "00"
        },
        {
            "name": "Bor District",
            "code": "14"
        },
        {
            "name": "Braničevo District",
            "code": "11"
        },
        {
            "name": "Central Banat District",
            "code": "02"
        },
        {
            "name": "Jablanica District",
            "code": "23"
        },
        {
            "name": "Kolubara District",
            "code": "09"
        },
        {
            "name": "Mačva District",
            "code": "08"
        },
        {
            "name": "Moravica District",
            "code": "17"
        },
        {
            "name": "Nišava District",
            "code": "20"
        },
        {
            "name": "North Bačka District",
            "code": "01"
        },
        {
            "name": "North Banat District",
            "code": "03"
        },
        {
            "name": "Pčinja District",
            "code": "24"
        },
        {
            "name": "Pirot District",
            "code": "22"
        },
        {
            "name": "Podunavlje District",
            "code": "10"
        },
        {
            "name": "Pomoravlje District",
            "code": "13"
        },
        {
            "name": "Rasina District",
            "code": "19"
        },
        {
            "name": "Raška District",
            "code": "18"
        },
        {
            "name": "South Bačka District",
            "code": "06"
        },
        {
            "name": "South Banat District",
            "code": "04"
        },
        {
            "name": "Srem District",
            "code": "07"
        },
        {
            "name": "Šumadija District",
            "code": "12"
        },
        {
            "name": "Toplica District",
            "code": "21"
        },
        {
            "name": "Vojvodina",
            "code": "VO"
        },
        {
            "name": "West Bačka District",
            "code": "05"
        },
        {
            "name": "Zaječar District",
            "code": "15"
        },
        {
            "name": "Zlatibor District",
            "code": "16"
        }
    ]
  },
  {
    "code2": "SC",
    "code3": "SYC",
    "name": "Seychelles",
    "capital": "Victoria",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "states": [
        {
            "name": "Anse Boileau",
            "code": "02"
        },
        {
            "name": "Anse Royale",
            "code": "05"
        },
        {
            "name": "Anse-aux-Pins",
            "code": "01"
        },
        {
            "name": "Au Cap",
            "code": "04"
        },
        {
            "name": "Baie Lazare",
            "code": "06"
        },
        {
            "name": "Baie Sainte Anne",
            "code": "07"
        },
        {
            "name": "Beau Vallon",
            "code": "08"
        },
        {
            "name": "Bel Air",
            "code": "09"
        },
        {
            "name": "Bel Ombre",
            "code": "10"
        },
        {
            "name": "Cascade",
            "code": "11"
        },
        {
            "name": "Glacis",
            "code": "12"
        },
        {
            "name": "Grand'Anse Mahé",
            "code": "13"
        },
        {
            "name": "Grand'Anse Praslin",
            "code": "14"
        },
        {
            "name": "La Digue",
            "code": "15"
        },
        {
            "name": "La Rivière Anglaise",
            "code": "16"
        },
        {
            "name": "Les Mamelles",
            "code": "24"
        },
        {
            "name": "Mont Buxton",
            "code": "17"
        },
        {
            "name": "Mont Fleuri",
            "code": "18"
        },
        {
            "name": "Plaisance",
            "code": "19"
        },
        {
            "name": "Pointe La Rue",
            "code": "20"
        },
        {
            "name": "Port Glaud",
            "code": "21"
        },
        {
            "name": "Roche Caiman",
            "code": "25"
        },
        {
            "name": "Saint Louis",
            "code": "22"
        },
        {
            "name": "Takamaka",
            "code": "23"
        }
    ]
  },
  {
    "code2": "SL",
    "code3": "SLE",
    "name": "Sierra Leone",
    "capital": "Freetown",
    "region": "Africa",
    "subregion": "Western Africa",
    "states": [
        {
            "name": "Eastern Province",
            "code": "E"
        },
        {
            "name": "Northern Province",
            "code": "N"
        },
        {
            "name": "Southern Province",
            "code": "S"
        },
        {
            "name": "Western Area",
            "code": "W"
        }
    ]
  },
  {
    "code2": "SG",
    "code3": "SGP",
    "name": "Singapore",
    "capital": "Singapore",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "states": [
        {
            "name": "Central Singapore Community Development Council",
            "code": "01"
        },
        {
            "name": "North East Community Development Council",
            "code": "02"
        },
        {
            "name": "North West Community Development Council",
            "code": "03"
        },
        {
            "name": "South East Community Development Council",
            "code": "04"
        },
        {
            "name": "South West Community Development Council",
            "code": "05"
        }
    ]
  },
  {
    "code2": "SX",
    "code3": "SXM",
    "name": "Sint Maarten",
    "capital": "Philipsburg",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": []
  },
  {
    "code2": "SK",
    "code3": "SVK",
    "name": "Slovakia",
    "capital": "Bratislava",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "states": [
        {
            "name": "Banská Bystrica Region",
            "code": "BC"
        },
        {
            "name": "Bratislava Region",
            "code": "BL"
        },
        {
            "name": "Košice Region",
            "code": "KI"
        },
        {
            "name": "Nitra Region",
            "code": "NI"
        },
        {
            "name": "Prešov Region",
            "code": "PV"
        },
        {
            "name": "Trenčín Region",
            "code": "TC"
        },
        {
            "name": "Trnava Region",
            "code": "TA"
        },
        {
            "name": "Žilina Region",
            "code": "ZI"
        }
    ]
  },
  {
    "code2": "SI",
    "code3": "SVN",
    "name": "Slovenia",
    "capital": "Ljubljana",
    "region": "Europe",
    "subregion": "Southern Europe",
    "states": [
        {
            "name": "Ajdovščina Municipality",
            "code": "001"
        },
        {
            "name": "Ankaran Municipality",
            "code": "213"
        },
        {
            "name": "Beltinci Municipality",
            "code": "002"
        },
        {
            "name": "Benedikt Municipality",
            "code": "148"
        },
        {
            "name": "Bistrica ob Sotli Municipality",
            "code": "149"
        },
        {
            "name": "Bled Municipality",
            "code": "003"
        },
        {
            "name": "Bloke Municipality",
            "code": "150"
        },
        {
            "name": "Bohinj Municipality",
            "code": "004"
        },
        {
            "name": "Borovnica Municipality",
            "code": "005"
        },
        {
            "name": "Bovec Municipality",
            "code": "006"
        },
        {
            "name": "Braslovče Municipality",
            "code": "151"
        },
        {
            "name": "Brda Municipality",
            "code": "007"
        },
        {
            "name": "Brežice Municipality",
            "code": "009"
        },
        {
            "name": "Brezovica Municipality",
            "code": "008"
        },
        {
            "name": "Cankova Municipality",
            "code": "152"
        },
        {
            "name": "Cerklje na Gorenjskem Municipality",
            "code": "012"
        },
        {
            "name": "Cerknica Municipality",
            "code": "013"
        },
        {
            "name": "Cerkno Municipality",
            "code": "014"
        },
        {
            "name": "Cerkvenjak Municipality",
            "code": "153"
        },
        {
            "name": "City Municipality of Celje",
            "code": "011"
        },
        {
            "name": "City Municipality of Novo Mesto",
            "code": "085"
        },
        {
            "name": "Črenšovci Municipality",
            "code": "015"
        },
        {
            "name": "Črna na Koroškem Municipality",
            "code": "016"
        },
        {
            "name": "Črnomelj Municipality",
            "code": "017"
        },
        {
            "name": "Destrnik Municipality",
            "code": "018"
        },
        {
            "name": "Divača Municipality",
            "code": "019"
        },
        {
            "name": "Dobje Municipality",
            "code": "154"
        },
        {
            "name": "Dobrepolje Municipality",
            "code": "020"
        },
        {
            "name": "Dobrna Municipality",
            "code": "155"
        },
        {
            "name": "Dobrova–Polhov Gradec Municipality",
            "code": "021"
        },
        {
            "name": "Dobrovnik Municipality",
            "code": "156"
        },
        {
            "name": "Dol pri Ljubljani Municipality",
            "code": "022"
        },
        {
            "name": "Dolenjske Toplice Municipality",
            "code": "157"
        },
        {
            "name": "Domžale Municipality",
            "code": "023"
        },
        {
            "name": "Dornava Municipality",
            "code": "024"
        },
        {
            "name": "Dravograd Municipality",
            "code": "025"
        },
        {
            "name": "Duplek Municipality",
            "code": "026"
        },
        {
            "name": "Gorenja Vas–Poljane Municipality",
            "code": "027"
        },
        {
            "name": "Gorišnica Municipality",
            "code": "028"
        },
        {
            "name": "Gorje Municipality",
            "code": "207"
        },
        {
            "name": "Gornja Radgona Municipality",
            "code": "029"
        },
        {
            "name": "Gornji Grad Municipality",
            "code": "030"
        },
        {
            "name": "Gornji Petrovci Municipality",
            "code": "031"
        },
        {
            "name": "Grad Municipality",
            "code": "158"
        },
        {
            "name": "Grosuplje Municipality",
            "code": "032"
        },
        {
            "name": "Hajdina Municipality",
            "code": "159"
        },
        {
            "name": "Hoče–Slivnica Municipality",
            "code": "160"
        },
        {
            "name": "Hodoš Municipality",
            "code": "161"
        },
        {
            "name": "Horjul Municipality",
            "code": "162"
        },
        {
            "name": "Hrastnik Municipality",
            "code": "034"
        },
        {
            "name": "Hrpelje–Kozina Municipality",
            "code": "035"
        },
        {
            "name": "Idrija Municipality",
            "code": "036"
        },
        {
            "name": "Ig Municipality",
            "code": "037"
        },
        {
            "name": "Ivančna Gorica Municipality",
            "code": "039"
        },
        {
            "name": "Izola Municipality",
            "code": "040"
        },
        {
            "name": "Jesenice Municipality",
            "code": "041"
        },
        {
            "name": "Jezersko Municipality",
            "code": "163"
        },
        {
            "name": "Juršinci Municipality",
            "code": "042"
        },
        {
            "name": "Kamnik Municipality",
            "code": "043"
        },
        {
            "name": "Kanal ob Soči Municipality",
            "code": "044"
        },
        {
            "name": "Kidričevo Municipality",
            "code": "045"
        },
        {
            "name": "Kobarid Municipality",
            "code": "046"
        },
        {
            "name": "Kobilje Municipality",
            "code": "047"
        },
        {
            "name": "Kočevje Municipality",
            "code": "048"
        },
        {
            "name": "Komen Municipality",
            "code": "049"
        },
        {
            "name": "Komenda Municipality",
            "code": "164"
        },
        {
            "name": "Koper City Municipality",
            "code": "050"
        },
        {
            "name": "Kostanjevica na Krki Municipality",
            "code": "197"
        },
        {
            "name": "Kostel Municipality",
            "code": "165"
        },
        {
            "name": "Kozje Municipality",
            "code": "051"
        },
        {
            "name": "Kranj City Municipality",
            "code": "052"
        },
        {
            "name": "Kranjska Gora Municipality",
            "code": "053"
        },
        {
            "name": "Križevci Municipality",
            "code": "166"
        },
        {
            "name": "Kungota",
            "code": "055"
        },
        {
            "name": "Kuzma Municipality",
            "code": "056"
        },
        {
            "name": "Laško Municipality",
            "code": "057"
        },
        {
            "name": "Lenart Municipality",
            "code": "058"
        },
        {
            "name": "Lendava Municipality",
            "code": "059"
        },
        {
            "name": "Litija Municipality",
            "code": "060"
        },
        {
            "name": "Ljubljana City Municipality",
            "code": "061"
        },
        {
            "name": "Ljubno Municipality",
            "code": "062"
        },
        {
            "name": "Ljutomer Municipality",
            "code": "063"
        },
        {
            "name": "Log–Dragomer Municipality",
            "code": "208"
        },
        {
            "name": "Logatec Municipality",
            "code": "064"
        },
        {
            "name": "Loška Dolina Municipality",
            "code": "065"
        },
        {
            "name": "Loški Potok Municipality",
            "code": "066"
        },
        {
            "name": "Lovrenc na Pohorju Municipality",
            "code": "167"
        },
        {
            "name": "Luče Municipality",
            "code": "067"
        },
        {
            "name": "Lukovica Municipality",
            "code": "068"
        },
        {
            "name": "Majšperk Municipality",
            "code": "069"
        },
        {
            "name": "Makole Municipality",
            "code": "198"
        },
        {
            "name": "Maribor City Municipality",
            "code": "070"
        },
        {
            "name": "Markovci Municipality",
            "code": "168"
        },
        {
            "name": "Medvode Municipality",
            "code": "071"
        },
        {
            "name": "Mengeš Municipality",
            "code": "072"
        },
        {
            "name": "Metlika Municipality",
            "code": "073"
        },
        {
            "name": "Mežica Municipality",
            "code": "074"
        },
        {
            "name": "Miklavž na Dravskem Polju Municipality",
            "code": "169"
        },
        {
            "name": "Miren–Kostanjevica Municipality",
            "code": "075"
        },
        {
            "name": "Mirna Municipality",
            "code": "212"
        },
        {
            "name": "Mirna Peč Municipality",
            "code": "170"
        },
        {
            "name": "Mislinja Municipality",
            "code": "076"
        },
        {
            "name": "Mokronog–Trebelno Municipality",
            "code": "199"
        },
        {
            "name": "Moravče Municipality",
            "code": "077"
        },
        {
            "name": "Moravske Toplice Municipality",
            "code": "078"
        },
        {
            "name": "Mozirje Municipality",
            "code": "079"
        },
        {
            "name": "Municipality of Apače",
            "code": "195"
        },
        {
            "name": "Municipality of Cirkulane",
            "code": "196"
        },
        {
            "name": "Municipality of Ilirska Bistrica",
            "code": "038"
        },
        {
            "name": "Municipality of Krško",
            "code": "054"
        },
        {
            "name": "Municipality of Škofljica",
            "code": "123"
        },
        {
            "name": "Murska Sobota City Municipality",
            "code": "080"
        },
        {
            "name": "Muta Municipality",
            "code": "081"
        },
        {
            "name": "Naklo Municipality",
            "code": "082"
        },
        {
            "name": "Nazarje Municipality",
            "code": "083"
        },
        {
            "name": "Nova Gorica City Municipality",
            "code": "084"
        },
        {
            "name": "Odranci Municipality",
            "code": "086"
        },
        {
            "name": "Oplotnica",
            "code": "171"
        },
        {
            "name": "Ormož Municipality",
            "code": "087"
        },
        {
            "name": "Osilnica Municipality",
            "code": "088"
        },
        {
            "name": "Pesnica Municipality",
            "code": "089"
        },
        {
            "name": "Piran Municipality",
            "code": "090"
        },
        {
            "name": "Pivka Municipality",
            "code": "091"
        },
        {
            "name": "Podčetrtek Municipality",
            "code": "092"
        },
        {
            "name": "Podlehnik Municipality",
            "code": "172"
        },
        {
            "name": "Podvelka Municipality",
            "code": "093"
        },
        {
            "name": "Poljčane Municipality",
            "code": "200"
        },
        {
            "name": "Polzela Municipality",
            "code": "173"
        },
        {
            "name": "Postojna Municipality",
            "code": "094"
        },
        {
            "name": "Prebold Municipality",
            "code": "174"
        },
        {
            "name": "Preddvor Municipality",
            "code": "095"
        },
        {
            "name": "Prevalje Municipality",
            "code": "175"
        },
        {
            "name": "Ptuj City Municipality",
            "code": "096"
        },
        {
            "name": "Puconci Municipality",
            "code": "097"
        },
        {
            "name": "Rače–Fram Municipality",
            "code": "098"
        },
        {
            "name": "Radeče Municipality",
            "code": "099"
        },
        {
            "name": "Radenci Municipality",
            "code": "100"
        },
        {
            "name": "Radlje ob Dravi Municipality",
            "code": "101"
        },
        {
            "name": "Radovljica Municipality",
            "code": "102"
        },
        {
            "name": "Ravne na Koroškem Municipality",
            "code": "103"
        },
        {
            "name": "Razkrižje Municipality",
            "code": "176"
        },
        {
            "name": "Rečica ob Savinji Municipality",
            "code": "209"
        },
        {
            "name": "Renče–Vogrsko Municipality",
            "code": "201"
        },
        {
            "name": "Ribnica Municipality",
            "code": "104"
        },
        {
            "name": "Ribnica na Pohorju Municipality",
            "code": "177"
        },
        {
            "name": "Rogaška Slatina Municipality",
            "code": "106"
        },
        {
            "name": "Rogašovci Municipality",
            "code": "105"
        },
        {
            "name": "Rogatec Municipality",
            "code": "107"
        },
        {
            "name": "Ruše Municipality",
            "code": "108"
        },
        {
            "name": "Šalovci Municipality",
            "code": "033"
        },
        {
            "name": "Selnica ob Dravi Municipality",
            "code": "178"
        },
        {
            "name": "Semič Municipality",
            "code": "109"
        },
        {
            "name": "Šempeter–Vrtojba Municipality",
            "code": "183"
        },
        {
            "name": "Šenčur Municipality",
            "code": "117"
        },
        {
            "name": "Šentilj Municipality",
            "code": "118"
        },
        {
            "name": "Šentjernej Municipality",
            "code": "119"
        },
        {
            "name": "Šentjur Municipality",
            "code": "120"
        },
        {
            "name": "Šentrupert Municipality",
            "code": "211"
        },
        {
            "name": "Sevnica Municipality",
            "code": "110"
        },
        {
            "name": "Sežana Municipality",
            "code": "111"
        },
        {
            "name": "Škocjan Municipality",
            "code": "121"
        },
        {
            "name": "Škofja Loka Municipality",
            "code": "122"
        },
        {
            "name": "Slovenj Gradec City Municipality",
            "code": "112"
        },
        {
            "name": "Slovenska Bistrica Municipality",
            "code": "113"
        },
        {
            "name": "Slovenske Konjice Municipality",
            "code": "114"
        },
        {
            "name": "Šmarje pri Jelšah Municipality",
            "code": "124"
        },
        {
            "name": "Šmarješke Toplice Municipality",
            "code": "206"
        },
        {
            "name": "Šmartno ob Paki Municipality",
            "code": "125"
        },
        {
            "name": "Šmartno pri Litiji Municipality",
            "code": "194"
        },
        {
            "name": "Sodražica Municipality",
            "code": "179"
        },
        {
            "name": "Solčava Municipality",
            "code": "180"
        },
        {
            "name": "Šoštanj Municipality",
            "code": "126"
        },
        {
            "name": "Središče ob Dravi",
            "code": "202"
        },
        {
            "name": "Starše Municipality",
            "code": "115"
        },
        {
            "name": "Štore Municipality",
            "code": "127"
        },
        {
            "name": "Straža Municipality",
            "code": "203"
        },
        {
            "name": "Sveta Ana Municipality",
            "code": "181"
        },
        {
            "name": "Sveta Trojica v Slovenskih Goricah Municipality",
            "code": "204"
        },
        {
            "name": "Sveti Andraž v Slovenskih Goricah Municipality",
            "code": "182"
        },
        {
            "name": "Sveti Jurij ob Ščavnici Municipality",
            "code": "116"
        },
        {
            "name": "Sveti Jurij v Slovenskih Goricah Municipality",
            "code": "210"
        },
        {
            "name": "Sveti Tomaž Municipality",
            "code": "205"
        },
        {
            "name": "Tabor Municipality",
            "code": "184"
        },
        {
            "name": "Tišina Municipality",
            "code": "010"
        },
        {
            "name": "Tolmin Municipality",
            "code": "128"
        },
        {
            "name": "Trbovlje Municipality",
            "code": "129"
        },
        {
            "name": "Trebnje Municipality",
            "code": "130"
        },
        {
            "name": "Trnovska Vas Municipality",
            "code": "185"
        },
        {
            "name": "Tržič Municipality",
            "code": "131"
        },
        {
            "name": "Trzin Municipality",
            "code": "186"
        },
        {
            "name": "Turnišče Municipality",
            "code": "132"
        },
        {
            "name": "Velika Polana Municipality",
            "code": "187"
        },
        {
            "name": "Velike Lašče Municipality",
            "code": "134"
        },
        {
            "name": "Veržej Municipality",
            "code": "188"
        },
        {
            "name": "Videm Municipality",
            "code": "135"
        },
        {
            "name": "Vipava Municipality",
            "code": "136"
        },
        {
            "name": "Vitanje Municipality",
            "code": "137"
        },
        {
            "name": "Vodice Municipality",
            "code": "138"
        },
        {
            "name": "Vojnik Municipality",
            "code": "139"
        },
        {
            "name": "Vransko Municipality",
            "code": "189"
        },
        {
            "name": "Vrhnika Municipality",
            "code": "140"
        },
        {
            "name": "Vuzenica Municipality",
            "code": "141"
        },
        {
            "name": "Zagorje ob Savi Municipality",
            "code": "142"
        },
        {
            "name": "Žalec Municipality",
            "code": "190"
        },
        {
            "name": "Zavrč Municipality",
            "code": "143"
        },
        {
            "name": "Železniki Municipality",
            "code": "146"
        },
        {
            "name": "Žetale Municipality",
            "code": "191"
        },
        {
            "name": "Žiri Municipality",
            "code": "147"
        },
        {
            "name": "Žirovnica Municipality",
            "code": "192"
        },
        {
            "name": "Zreče Municipality",
            "code": "144"
        },
        {
            "name": "Žužemberk Municipality",
            "code": "193"
        }
    ]
  },
  {
    "code2": "SB",
    "code3": "SLB",
    "name": "Solomon Islands",
    "capital": "Honiara",
    "region": "Oceania",
    "subregion": "Melanesia",
    "states": [
        {
            "name": "Central Province",
            "code": "CE"
        },
        {
            "name": "Choiseul Province",
            "code": "CH"
        },
        {
            "name": "Guadalcanal Province",
            "code": "GU"
        },
        {
            "name": "Honiara",
            "code": "CT"
        },
        {
            "name": "Isabel Province",
            "code": "IS"
        },
        {
            "name": "Makira-Ulawa Province",
            "code": "MK"
        },
        {
            "name": "Malaita Province",
            "code": "ML"
        },
        {
            "name": "Rennell and Bellona Province",
            "code": "RB"
        },
        {
            "name": "Temotu Province",
            "code": "TE"
        },
        {
            "name": "Western Province",
            "code": "WE"
        }
    ]
  },
  {
    "code2": "SO",
    "code3": "SOM",
    "name": "Somalia",
    "capital": "Mogadishu",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "states": [
        {
            "name": "Awdal Region",
            "code": "AW"
        },
        {
            "name": "Bakool",
            "code": "BK"
        },
        {
            "name": "Banaadir",
            "code": "BN"
        },
        {
            "name": "Bari",
            "code": "BR"
        },
        {
            "name": "Bay",
            "code": "BY"
        },
        {
            "name": "Galguduud",
            "code": "GA"
        },
        {
            "name": "Gedo",
            "code": "GE"
        },
        {
            "name": "Hiran",
            "code": "HI"
        },
        {
            "name": "Lower Juba",
            "code": "JH"
        },
        {
            "name": "Lower Shebelle",
            "code": "SH"
        },
        {
            "name": "Middle Juba",
            "code": "JD"
        },
        {
            "name": "Middle Shebelle",
            "code": "SD"
        },
        {
            "name": "Mudug",
            "code": "MU"
        },
        {
            "name": "Nugal",
            "code": "NU"
        },
        {
            "name": "Sanaag Region",
            "code": "SA"
        },
        {
            "name": "Togdheer Region",
            "code": "TO"
        }
    ]
  },
  {
    "code2": "ZA",
    "code3": "ZAF",
    "name": "South Africa",
    "capital": "Pretoria",
    "region": "Africa",
    "subregion": "Southern Africa",
    "states": [
        {
            "name": "Eastern Cape",
            "code": "EC"
        },
        {
            "name": "Free State",
            "code": "FS"
        },
        {
            "name": "Gauteng",
            "code": "GP"
        },
        {
            "name": "KwaZulu-Natal",
            "code": "KZN"
        },
        {
            "name": "Limpopo",
            "code": "LP"
        },
        {
            "name": "Mpumalanga",
            "code": "MP"
        },
        {
            "name": "North West",
            "code": "NW"
        },
        {
            "name": "Northern Cape",
            "code": "NC"
        },
        {
            "name": "Western Cape",
            "code": "WC"
        }
    ]
  },
  {
    "code2": "GS",
    "code3": "SGS",
    "name": "South Georgia",
    "capital": "Grytviken",
    "region": "Americas",
    "subregion": "South America",
    "states": []
  },
   {
    "code2": "SS",
    "code3": "SSD",
    "name": "South Sudan",
    "capital": "Juba",
    "region": "Africa",
    "subregion": "Middle Africa",
    "states": [
        {
            "name": "Central Equatoria",
            "code": "EC"
        },
        {
            "name": "Eastern Equatoria",
            "code": "EE"
        },
        {
            "name": "Jonglei State",
            "code": "JG"
        },
        {
            "name": "Lakes",
            "code": "LK"
        },
        {
            "name": "Northern Bahr el Ghazal",
            "code": "BN"
        },
        {
            "name": "Unity",
            "code": "UY"
        },
        {
            "name": "Upper Nile",
            "code": "NU"
        },
        {
            "name": "Warrap",
            "code": "WR"
        },
        {
            "name": "Western Bahr el Ghazal",
            "code": "BW"
        },
        {
            "name": "Western Equatoria",
            "code": "EW"
        }
    ]
  },
  {
    "code2": "ES",
    "code3": "ESP",
    "name": "Spain",
    "capital": "Madrid",
    "region": "Europe",
    "subregion": "Southern Europe",
    "states": [
        {
            "name": "Andalusia",
            "code": "AN"
        },
        {
            "name": "Aragon",
            "code": "AR"
        },
        {
            "name": "Asturias",
            "code": "AS"
        },
        {
            "name": "Ávila",
            "code": "AV"
        },
        {
            "name": "Balearic Islands",
            "code": "PM"
        },
        {
            "name": "Basque Country",
            "code": "PV"
        },
        {
            "name": "Burgos Province",
            "code": "BU"
        },
        {
            "name": "Canary Islands",
            "code": "CN"
        },
        {
            "name": "Cantabria",
            "code": "CB"
        },
        {
            "name": "Castile and León",
            "code": "CL"
        },
        {
            "name": "Castilla La Mancha",
            "code": "CM"
        },
        {
            "name": "Catalonia",
            "code": "CT"
        },
        {
            "name": "Ceuta",
            "code": "CE"
        },
        {
            "name": "Extremadura",
            "code": "EX"
        },
        {
            "name": "Galicia",
            "code": "GA"
        },
        {
            "name": "La Rioja",
            "code": "RI"
        },
        {
            "name": "Léon",
            "code": "LE"
        },
        {
            "name": "Madrid",
            "code": "MD"
        },
        {
            "name": "Melilla",
            "code": "ML"
        },
        {
            "name": "Murcia",
            "code": "MC"
        },
        {
            "name": "Navarra",
            "code": "NC"
        },
        {
            "name": "Palencia Province",
            "code": "P"
        },
        {
            "name": "Salamanca Province",
            "code": "SA"
        },
        {
            "name": "Segovia Province",
            "code": "SG"
        },
        {
            "name": "Soria Province",
            "code": "SO"
        },
        {
            "name": "Valencia",
            "code": "VC"
        },
        {
            "name": "Valladolid Province",
            "code": "VA"
        },
        {
            "name": "Zamora Province",
            "code": "ZA"
        }
    ]
  },
  {
    "code2": "LK",
    "code3": "LKA",
    "name": "Sri Lanka",
    "capital": "Colombo",
    "region": "Asia",
    "subregion": "Southern Asia",
    "states": [
        {
            "name": "Ampara District",
            "code": "52"
        },
        {
            "name": "Anuradhapura District",
            "code": "71"
        },
        {
            "name": "Badulla District",
            "code": "81"
        },
        {
            "name": "Batticaloa District",
            "code": "51"
        },
        {
            "name": "Central Province",
            "code": "2"
        },
        {
            "name": "Colombo District",
            "code": "11"
        },
        {
            "name": "Eastern Province",
            "code": "5"
        },
        {
            "name": "Galle District",
            "code": "31"
        },
        {
            "name": "Gampaha District",
            "code": "12"
        },
        {
            "name": "Hambantota District",
            "code": "33"
        },
        {
            "name": "Jaffna District",
            "code": "41"
        },
        {
            "name": "Kalutara District",
            "code": "13"
        },
        {
            "name": "Kandy District",
            "code": "21"
        },
        {
            "name": "Kegalle District",
            "code": "92"
        },
        {
            "name": "Kilinochchi District",
            "code": "42"
        },
        {
            "name": "Mannar District",
            "code": "43"
        },
        {
            "name": "Matale District",
            "code": "22"
        },
        {
            "name": "Matara District",
            "code": "32"
        },
        {
            "name": "Monaragala District",
            "code": "82"
        },
        {
            "name": "Mullaitivu District",
            "code": "45"
        },
        {
            "name": "North Central Province",
            "code": "7"
        },
        {
            "name": "North Western Province",
            "code": "6"
        },
        {
            "name": "Northern Province",
            "code": "4"
        },
        {
            "name": "Nuwara Eliya District",
            "code": "23"
        },
        {
            "name": "Polonnaruwa District",
            "code": "72"
        },
        {
            "name": "Puttalam District",
            "code": "62"
        },
        {
            "name": "Ratnapura district",
            "code": "91"
        },
        {
            "name": "Sabaragamuwa Province",
            "code": "9"
        },
        {
            "name": "Southern Province",
            "code": "3"
        },
        {
            "name": "Trincomalee District",
            "code": "53"
        },
        {
            "name": "Uva Province",
            "code": "8"
        },
        {
            "name": "Vavuniya District",
            "code": "44"
        },
        {
            "name": "Western Province",
            "code": "1"
        }
    ]
  },
  {
    "code2": "SD",
    "code3": "SDN",
    "name": "Sudan",
    "capital": "Khartoum",
    "region": "Africa",
    "subregion": "Northern Africa",
    "states": [
        {
            "name": "Al Jazirah",
            "code": "GZ"
        },
        {
            "name": "Al Qadarif",
            "code": "GD"
        },
        {
            "name": "Blue Nile",
            "code": "NB"
        },
        {
            "name": "Central Darfur",
            "code": "DC"
        },
        {
            "name": "East Darfur",
            "code": "DE"
        },
        {
            "name": "Kassala",
            "code": "KA"
        },
        {
            "name": "Khartoum",
            "code": "KH"
        },
        {
            "name": "North Darfur",
            "code": "DN"
        },
        {
            "name": "North Kordofan",
            "code": "KN"
        },
        {
            "name": "Northern",
            "code": "NO"
        },
        {
            "name": "Red Sea",
            "code": "RS"
        },
        {
            "name": "River Nile",
            "code": "NR"
        },
        {
            "name": "Sennar",
            "code": "SI"
        },
        {
            "name": "South Darfur",
            "code": "DS"
        },
        {
            "name": "South Kordofan",
            "code": "KS"
        },
        {
            "name": "West Darfur",
            "code": "DW"
        },
        {
            "name": "West Kordofan",
            "code": "GK"
        },
        {
            "name": "White Nile",
            "code": "NW"
        }
    ]
  },
  {
    "code2": "SR",
    "code3": "SUR",
    "name": "Suriname",
    "capital": "Paramaribo",
    "region": "Americas",
    "subregion": "South America",
    "states": [
        {
            "name": "Brokopondo District",
            "code": "BR"
        },
        {
            "name": "Commewijne District",
            "code": "CM"
        },
        {
            "name": "Coronie District",
            "code": "CR"
        },
        {
            "name": "Marowijne District",
            "code": "MA"
        },
        {
            "name": "Nickerie District",
            "code": "NI"
        },
        {
            "name": "Para District",
            "code": "PR"
        },
        {
            "name": "Paramaribo District",
            "code": "PM"
        },
        {
            "name": "Saramacca District",
            "code": "SA"
        },
        {
            "name": "Sipaliwini District",
            "code": "SI"
        },
        {
            "name": "Wanica District",
            "code": "WA"
        }
    ]
  },
  {
    "code2": "SJ",
    "code3": "SJM",
    "name": "Svalbard and Jan Mayen",
    "capital": "Longyearbyen",
    "region": "Europe",
    "subregion": "Northern Europe",
    "states": []
  },
  {
    "code2": "SZ",
    "code3": "SWZ",
    "name": "Swaziland",
    "capital": "Lobamba",
    "region": "Africa",
    "subregion": "Southern Africa",
    "states": [
        {
            "name": "Hhohho District",
            "code": "HH"
        },
        {
            "name": "Lubombo District",
            "code": "LU"
        },
        {
            "name": "Manzini District",
            "code": "MA"
        },
        {
            "name": "Shiselweni District",
            "code": "SH"
        }
    ]
  },
  {
    "code2": "SE",
    "code3": "SWE",
    "name": "Sweden",
    "capital": "Stockholm",
    "region": "Europe",
    "subregion": "Northern Europe",
    "states": [
        {
            "name": "Blekinge",
            "code": "K"
        },
        {
            "name": "Dalarna County",
            "code": "W"
        },
        {
            "name": "Gävleborg County",
            "code": "X"
        },
        {
            "name": "Gotland County",
            "code": "I"
        },
        {
            "name": "Halland County",
            "code": "N"
        },
        {
            "name": "Jönköping County",
            "code": "F"
        },
        {
            "name": "Kalmar County",
            "code": "H"
        },
        {
            "name": "Kronoberg County",
            "code": "G"
        },
        {
            "name": "Norrbotten County",
            "code": "BD"
        },
        {
            "name": "Örebro County",
            "code": "T"
        },
        {
            "name": "Östergötland County",
            "code": "E"
        },
        {
            "name": "Skåne County",
            "code": "M"
        },
        {
            "name": "Södermanland County",
            "code": "D"
        },
        {
            "name": "Stockholm County",
            "code": "AB"
        },
        {
            "name": "Uppsala County",
            "code": "C"
        },
        {
            "name": "Värmland County",
            "code": "S"
        },
        {
            "name": "Västerbotten County",
            "code": "AC"
        },
        {
            "name": "Västernorrland County",
            "code": "Y"
        },
        {
            "name": "Västmanland County",
            "code": "U"
        },
        {
            "name": "Västra Götaland County",
            "code": "O"
        }
    ]
  },
  {
    "code2": "CH",
    "code3": "CHE",
    "name": "Switzerland",
    "capital": "Berne",
    "region": "Europe",
    "subregion": "Western Europe",
    "states": [
        {
            "name": "Aargau",
            "code": "AG"
        },
        {
            "name": "Appenzell Ausserrhoden",
            "code": "AR"
        },
        {
            "name": "Appenzell Innerrhoden",
            "code": "AI"
        },
        {
            "name": "Basel-Landschaft",
            "code": "BL"
        },
        {
            "name": "canton of Bern",
            "code": "BE"
        },
        {
            "name": "Canton of Fribourg",
            "code": "FR"
        },
        {
            "name": "Canton of Geneva",
            "code": "GE"
        },
        {
            "name": "Canton of Jura",
            "code": "JU"
        },
        {
            "name": "Canton of Lucerne",
            "code": "LU"
        },
        {
            "name": "Canton of Neuchâtel",
            "code": "NE"
        },
        {
            "name": "Canton of Schaffhausen",
            "code": "SH"
        },
        {
            "name": "Canton of Solothurn",
            "code": "SO"
        },
        {
            "name": "Canton of St. Gallen",
            "code": "SG"
        },
        {
            "name": "Canton of Valais",
            "code": "VS"
        },
        {
            "name": "Canton of Vaud",
            "code": "VD"
        },
        {
            "name": "Canton of Zug",
            "code": "ZG"
        },
        {
            "name": "canton of Zürich",
            "code": "ZH"
        },
        {
            "name": "Glarus",
            "code": "GL"
        },
        {
            "name": "Graubünden",
            "code": "GR"
        },
        {
            "name": "Nidwalden",
            "code": "NW"
        },
        {
            "name": "Obwalden",
            "code": "OW"
        },
        {
            "name": "Schwyz",
            "code": "SZ"
        },
        {
            "name": "Thurgau",
            "code": "TG"
        },
        {
            "name": "Ticino",
            "code": "TI"
        },
        {
            "name": "Uri",
            "code": "UR"
        }
    ]
  },
  {
    "code2": "SY",
    "code3": "SYR",
    "name": "Syria",
    "capital": "Damascus",
    "region": "Asia",
    "subregion": "Western Asia",
    "states": [
        {
            "name": "Al-Hasakah Governorate",
            "code": "HA"
        },
        {
            "name": "Al-Raqqah Governorate",
            "code": "RA"
        },
        {
            "name": "Aleppo Governorate",
            "code": "HL"
        },
        {
            "name": "As-Suwayda Governorate",
            "code": "SU"
        },
        {
            "name": "Damascus Governorate",
            "code": "DI"
        },
        {
            "name": "Daraa Governorate",
            "code": "DR"
        },
        {
            "name": "Deir ez-Zor Governorate",
            "code": "DY"
        },
        {
            "name": "Hama Governorate",
            "code": "HM"
        },
        {
            "name": "Homs Governorate",
            "code": "HI"
        },
        {
            "name": "Idlib Governorate",
            "code": "ID"
        },
        {
            "name": "Latakia Governorate",
            "code": "LA"
        },
        {
            "name": "Quneitra Governorate",
            "code": "QU"
        },
        {
            "name": "Rif Dimashq Governorate",
            "code": "RD"
        },
        {
            "name": "Tartus Governorate",
            "code": "TA"
        }
    ]
  },
  {
    "code2": "TW",
    "code3": "TWN",
    "name": "Taiwan",
    "capital": "Taipei",
    "region": "Asia",
    "subregion": "Eastern Asia",
    "states": [
        {
            "name": "Changhua County",
            "code": "CHA"
        },
        {
            "name": "Chiayi City",
            "code": "CYQ"
        },
        {
            "name": "Chiayi County",
            "code": "CYI"
        },
        {
            "name": "Hsinchu",
            "code": "HSZ"
        },
        {
            "name": "Hsinchu County",
            "code": "HSQ"
        },
        {
            "name": "Hualien County",
            "code": "HUA"
        },
        {
            "name": "Kaohsiung",
            "code": "KHH"
        },
        {
            "name": "Kaohsiung County",
            "code": "KHQ"
        },
        {
            "name": "Kinmen",
            "code": "KIN"
        },
        {
            "name": "Lienchiang County",
            "code": "LIE"
        },
        {
            "name": "Miaoli County",
            "code": "MIA"
        },
        {
            "name": "Nantou County",
            "code": "NAN"
        },
        {
            "name": "Penghu County",
            "code": "PEN"
        },
        {
            "name": "Pingtung County",
            "code": "PIF"
        },
        {
            "name": "Taichung",
            "code": "TXG"
        },
        {
            "name": "Taichung County",
            "code": "TXQ"
        },
        {
            "name": "Tainan",
            "code": "TNN"
        },
        {
            "name": "Tainan County",
            "code": "TNQ"
        },
        {
            "name": "Taipei",
            "code": "TPE"
        },
        {
            "name": "Taitung County",
            "code": "TTT"
        },
        {
            "name": "Taoyuan City",
            "code": "TAO"
        },
        {
            "name": "Yilan County",
            "code": "ILA"
        },
        {
            "name": "Yunlin County",
            "code": "YUN"
        }
    ]
  },
  {
    "code2": "TJ",
    "code3": "TJK",
    "name": "Tajikistan",
    "capital": "Dushanbe",
    "region": "Asia",
    "subregion": "Central Asia",
    "states": [
        {
            "name": "districts of Republican Subordination",
            "code": "RA"
        },
        {
            "name": "Gorno-Badakhshan Autonomous Province",
            "code": "GB"
        },
        {
            "name": "Khatlon Province",
            "code": "KT"
        },
        {
            "name": "Sughd Province",
            "code": "SU"
        }
    ]
  },
  {
    "code2": "TZ",
    "code3": "TZA",
    "name": "Tanzania",
    "capital": "Dodoma",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "states": [
        {
            "name": "Arusha Region",
            "code": "01"
        },
        {
            "name": "Dar es Salaam Region",
            "code": "02"
        },
        {
            "name": "Dodoma Region",
            "code": "03"
        },
        {
            "name": "Geita Region",
            "code": "27"
        },
        {
            "name": "Iringa Region",
            "code": "04"
        },
        {
            "name": "Kagera Region",
            "code": "05"
        },
        {
            "name": "Katavi Region",
            "code": "28"
        },
        {
            "name": "Kigoma Region",
            "code": "08"
        },
        {
            "name": "Kilimanjaro Region",
            "code": "09"
        },
        {
            "name": "Lindi Region",
            "code": "12"
        },
        {
            "name": "Manyara Region",
            "code": "26"
        },
        {
            "name": "Mara Region",
            "code": "13"
        },
        {
            "name": "Morogoro Region",
            "code": "16"
        },
        {
            "name": "Mtwara Region",
            "code": "17"
        },
        {
            "name": "Mwanza Region",
            "code": "18"
        },
        {
            "name": "Njombe Region",
            "code": "29"
        },
        {
            "name": "North Pemba Region",
            "code": "06"
        },
        {
            "name": "Pwani Region",
            "code": "19"
        },
        {
            "name": "Rukwa Region",
            "code": "20"
        },
        {
            "name": "Ruvuma Region",
            "code": "21"
        },
        {
            "name": "Shinyanga Region",
            "code": "22"
        },
        {
            "name": "Simiyu Region",
            "code": "30"
        },
        {
            "name": "Singida Region",
            "code": "23"
        },
        {
            "name": "South Pemba Region",
            "code": "10"
        },
        {
            "name": "Tabora Region",
            "code": "24"
        },
        {
            "name": "Tanga Region",
            "code": "25"
        },
        {
            "name": "Zanzibar Central\/South Region",
            "code": "11"
        },
        {
            "name": "Zanzibar North Region",
            "code": "07"
        },
        {
            "name": "Zanzibar Urban\/West Region",
            "code": "15"
        }
    ]
  },
  {
    "code2": "TH",
    "code3": "THA",
    "name": "Thailand",
    "capital": "Bangkok",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "states": [
        {
            "name": "Amnat Charoen",
            "code": "37"
        },
        {
            "name": "Ang Thong",
            "code": "15"
        },
        {
            "name": "Bangkok",
            "code": "10"
        },
        {
            "name": "Bueng Kan",
            "code": "38"
        },
        {
            "name": "Buri Ram",
            "code": "31"
        },
        {
            "name": "Chachoengsao",
            "code": "24"
        },
        {
            "name": "Chai Nat",
            "code": "18"
        },
        {
            "name": "Chanthaburi",
            "code": "22"
        },
        {
            "name": "Chiang Mai",
            "code": "50"
        },
        {
            "name": "Chiang Rai",
            "code": "57"
        },
        {
            "name": "Chon Buri",
            "code": "20"
        },
        {
            "name": "Chumphon",
            "code": "86"
        },
        {
            "name": "Kalasin",
            "code": "46"
        },
        {
            "name": "Kamphaeng Phet",
            "code": "62"
        },
        {
            "name": "Kanchanaburi",
            "code": "71"
        },
        {
            "name": "Khon Kaen",
            "code": "40"
        },
        {
            "name": "Krabi",
            "code": "81"
        },
        {
            "name": "Lampang",
            "code": "52"
        },
        {
            "name": "Lamphun",
            "code": "51"
        },
        {
            "name": "Loei",
            "code": "42"
        },
        {
            "name": "Lopburi",
            "code": "16"
        },
        {
            "name": "Mae Hong Son",
            "code": "58"
        },
        {
            "name": "Maha Sarakham",
            "code": "44"
        },
        {
            "name": "Mukdahan",
            "code": "49"
        },
        {
            "name": "Nakhon Nayok",
            "code": "26"
        },
        {
            "name": "Nakhon Pathom",
            "code": "73"
        },
        {
            "name": "Nakhon Phanom",
            "code": "48"
        },
        {
            "name": "Nakhon Ratchasima",
            "code": "30"
        },
        {
            "name": "Nakhon Sawan",
            "code": "60"
        },
        {
            "name": "Nakhon Si Thammarat",
            "code": "80"
        },
        {
            "name": "Nan",
            "code": "55"
        },
        {
            "name": "Narathiwat",
            "code": "96"
        },
        {
            "name": "Nong Bua Lam Phu",
            "code": "39"
        },
        {
            "name": "Nong Khai",
            "code": "43"
        },
        {
            "name": "Nonthaburi",
            "code": "12"
        },
        {
            "name": "Pathum Thani",
            "code": "13"
        },
        {
            "name": "Pattani",
            "code": "94"
        },
        {
            "name": "Pattaya",
            "code": "S"
        },
        {
            "name": "Phang Nga",
            "code": "82"
        },
        {
            "name": "Phatthalung",
            "code": "93"
        },
        {
            "name": "Phayao",
            "code": "56"
        },
        {
            "name": "Phetchabun",
            "code": "67"
        },
        {
            "name": "Phetchaburi",
            "code": "76"
        },
        {
            "name": "Phichit",
            "code": "66"
        },
        {
            "name": "Phitsanulok",
            "code": "65"
        },
        {
            "name": "Phra Nakhon Si Ayutthaya",
            "code": "14"
        },
        {
            "name": "Phrae",
            "code": "54"
        },
        {
            "name": "Phuket",
            "code": "83"
        },
        {
            "name": "Prachin Buri",
            "code": "25"
        },
        {
            "name": "Prachuap Khiri Khan",
            "code": "77"
        },
        {
            "name": "Ranong",
            "code": "85"
        },
        {
            "name": "Ratchaburi",
            "code": "70"
        },
        {
            "name": "Rayong",
            "code": "21"
        },
        {
            "name": "Roi Et",
            "code": "45"
        },
        {
            "name": "Sa Kaeo",
            "code": "27"
        },
        {
            "name": "Sakon Nakhon",
            "code": "47"
        },
        {
            "name": "Samut Prakan",
            "code": "11"
        },
        {
            "name": "Samut Sakhon",
            "code": "74"
        },
        {
            "name": "Samut Songkhram",
            "code": "75"
        },
        {
            "name": "Saraburi",
            "code": "19"
        },
        {
            "name": "Satun",
            "code": "91"
        },
        {
            "name": "Si Sa Ket",
            "code": "33"
        },
        {
            "name": "Sing Buri",
            "code": "17"
        },
        {
            "name": "Songkhla",
            "code": "90"
        },
        {
            "name": "Sukhothai",
            "code": "64"
        },
        {
            "name": "Suphanburi",
            "code": "72"
        },
        {
            "name": "Surat Thani",
            "code": "84"
        },
        {
            "name": "Surin",
            "code": "32"
        },
        {
            "name": "Tak",
            "code": "63"
        },
        {
            "name": "Trang",
            "code": "92"
        },
        {
            "name": "Trat",
            "code": "23"
        },
        {
            "name": "Ubon Ratchathani",
            "code": "34"
        },
        {
            "name": "Udon Thani",
            "code": "41"
        },
        {
            "name": "Uthai Thani",
            "code": "61"
        },
        {
            "name": "Uttaradit",
            "code": "53"
        },
        {
            "name": "Yala",
            "code": "95"
        },
        {
            "name": "Yasothon",
            "code": "35"
        }
    ]
  },
  {
    "code2": "TL",
    "code3": "TLS",
    "name": "East Timor",
    "capital": "Dili",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "states": [
      {
        "code": "AL",
        "name": "Aileu",
        "subdivision": null
      },
      {
        "code": "AN",
        "name": "Ainaro",
        "subdivision": null
      },
      {
        "code": "BA",
        "name": "Baucau",
        "subdivision": null
      },
      {
        "code": "BO",
        "name": "Bobonaro",
        "subdivision": null
      },
      {
        "code": "CO",
        "name": "Cova Lima",
        "subdivision": null
      },
      {
        "code": "DI",
        "name": "Díli",
        "subdivision": null
      },
      {
        "code": "ER",
        "name": "Ermera",
        "subdivision": null
      },
      {
        "code": "LA",
        "name": "Lautem",
        "subdivision": null
      },
      {
        "code": "LI",
        "name": "Liquiça",
        "subdivision": null
      },
      {
        "code": "MT",
        "name": "Manatuto",
        "subdivision": null
      },
      {
        "code": "MF",
        "name": "Manufahi",
        "subdivision": null
      },
      {
        "code": "OE",
        "name": "Oecussi",
        "subdivision": null
      },
      {
        "code": "VI",
        "name": "Viqueque",
        "subdivision": null
      }
    ]
  },
  {
    "code2": "TG",
    "code3": "TGO",
    "name": "Togo",
    "capital": "Lomé",
    "region": "Africa",
    "subregion": "Western Africa",
    "states": [
        {
            "name": "Centrale Region",
            "code": "C"
        },
        {
            "name": "Kara Region",
            "code": "K"
        },
        {
            "name": "Maritime",
            "code": "M"
        },
        {
            "name": "Plateaux Region",
            "code": "P"
        },
        {
            "name": "Savanes Region",
            "code": "S"
        }
    ]
  },
  {
    "code2": "TK",
    "code3": "TKL",
    "name": "Tokelau",
    "capital": "Fakaofo",
    "region": "Oceania",
    "subregion": "Polynesia",
    "states": []
  },
  {
    "code2": "TO",
    "code3": "TON",
    "name": "Tonga",
    "capital": "Nuku'alofa",
    "region": "Oceania",
    "subregion": "Polynesia",
    "states": [
        {
            "name": "Haʻapai",
            "code": "02"
        },
        {
            "name": "ʻEua",
            "code": "01"
        },
        {
            "name": "Niuas",
            "code": "03"
        },
        {
            "name": "Tongatapu",
            "code": "04"
        },
        {
            "name": "Vavaʻu",
            "code": "05"
        }
    ]
  },
  {
    "code2": "TT",
    "code3": "TTO",
    "name": "Trinidad and Tobago",
    "capital": "Port of Spain",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": [
        {
            "name": "Arima",
            "code": "ARI"
        },
        {
            "name": "Chaguanas",
            "code": "CHA"
        },
        {
            "name": "Couva-Tabaquite-Talparo Regional Corporation",
            "code": "CTT"
        },
        {
            "name": "Diego Martin Regional Corporation",
            "code": "DMN"
        },
        {
            "name": "Eastern Tobago",
            "code": "ETO"
        },
        {
            "name": "Penal-Debe Regional Corporation",
            "code": "PED"
        },
        {
            "name": "Point Fortin",
            "code": "PTF"
        },
        {
            "name": "Port of Spain",
            "code": "POS"
        },
        {
            "name": "Princes Town Regional Corporation",
            "code": "PRT"
        },
        {
            "name": "Rio Claro-Mayaro Regional Corporation",
            "code": "MRC"
        },
        {
            "name": "San Fernando",
            "code": "SFO"
        },
        {
            "name": "San Juan-Laventille Regional Corporation",
            "code": "SJL"
        },
        {
            "name": "Sangre Grande Regional Corporation",
            "code": "SGE"
        },
        {
            "name": "Siparia Regional Corporation",
            "code": "SIP"
        },
        {
            "name": "Tunapuna-Piarco Regional Corporation",
            "code": "TUP"
        },
        {
            "name": "Western Tobago",
            "code": "WTO"
        }
    ]
  },
  {
    "code2": "TN",
    "code3": "TUN",
    "name": "Tunisia",
    "capital": "Tunis",
    "region": "Africa",
    "subregion": "Northern Africa",
    "states": [
        {
            "name": "Ariana Governorate",
            "code": "12"
        },
        {
            "name": "Ben Arous Governorate",
            "code": "13"
        },
        {
            "name": "Bizerte Governorate",
            "code": "23"
        },
        {
            "name": "Gabès Governorate",
            "code": "81"
        },
        {
            "name": "Gafsa Governorate",
            "code": "71"
        },
        {
            "name": "Jendouba Governorate",
            "code": "32"
        },
        {
            "name": "Kairouan Governorate",
            "code": "41"
        },
        {
            "name": "Kasserine Governorate",
            "code": "42"
        },
        {
            "name": "Kassrine",
            "code": "31"
        },
        {
            "name": "Kebili Governorate",
            "code": "73"
        },
        {
            "name": "Kef Governorate",
            "code": "33"
        },
        {
            "name": "Mahdia Governorate",
            "code": "53"
        },
        {
            "name": "Manouba Governorate",
            "code": "14"
        },
        {
            "name": "Medenine Governorate",
            "code": "82"
        },
        {
            "name": "Monastir Governorate",
            "code": "52"
        },
        {
            "name": "Sfax Governorate",
            "code": "61"
        },
        {
            "name": "Sidi Bouzid Governorate",
            "code": "43"
        },
        {
            "name": "Siliana Governorate",
            "code": "34"
        },
        {
            "name": "Sousse Governorate",
            "code": "51"
        },
        {
            "name": "Tataouine Governorate",
            "code": "83"
        },
        {
            "name": "Tozeur Governorate",
            "code": "72"
        },
        {
            "name": "Tunis Governorate",
            "code": "11"
        },
        {
            "name": "Zaghouan Governorate",
            "code": "22"
        }
    ]
  },
  {
    "code2": "TR",
    "code3": "TUR",
    "name": "Turkey",
    "capital": "Ankara",
    "region": "Asia",
    "subregion": "Western Asia",
    "states": [
        {
            "name": "Adana Province",
            "code": "01"
        },
        {
            "name": "Adıyaman Province",
            "code": "02"
        },
        {
            "name": "Afyonkarahisar Province",
            "code": "03"
        },
        {
            "name": "Ağrı Province",
            "code": "04"
        },
        {
            "name": "Aksaray Province",
            "code": "68"
        },
        {
            "name": "Amasya Province",
            "code": "05"
        },
        {
            "name": "Ankara Province",
            "code": "06"
        },
        {
            "name": "Antalya Province",
            "code": "07"
        },
        {
            "name": "Ardahan Province",
            "code": "75"
        },
        {
            "name": "Artvin Province",
            "code": "08"
        },
        {
            "name": "Aydın Province",
            "code": "09"
        },
        {
            "name": "Balıkesir Province",
            "code": "10"
        },
        {
            "name": "Bartın Province",
            "code": "74"
        },
        {
            "name": "Batman Province",
            "code": "72"
        },
        {
            "name": "Bayburt Province",
            "code": "69"
        },
        {
            "name": "Bilecik Province",
            "code": "11"
        },
        {
            "name": "Bingöl Province",
            "code": "12"
        },
        {
            "name": "Bitlis Province",
            "code": "13"
        },
        {
            "name": "Bolu Province",
            "code": "14"
        },
        {
            "name": "Burdur Province",
            "code": "15"
        },
        {
            "name": "Bursa Province",
            "code": "16"
        },
        {
            "name": "Çanakkale Province",
            "code": "17"
        },
        {
            "name": "Çankırı Province",
            "code": "18"
        },
        {
            "name": "Çorum Province",
            "code": "19"
        },
        {
            "name": "Denizli Province",
            "code": "20"
        },
        {
            "name": "Diyarbakır Province",
            "code": "21"
        },
        {
            "name": "Düzce Province",
            "code": "81"
        },
        {
            "name": "Edirne Province",
            "code": "22"
        },
        {
            "name": "Elazığ Province",
            "code": "23"
        },
        {
            "name": "Erzincan Province",
            "code": "24"
        },
        {
            "name": "Erzurum Province",
            "code": "25"
        },
        {
            "name": "Eskişehir Province",
            "code": "26"
        },
        {
            "name": "Gaziantep Province",
            "code": "27"
        },
        {
            "name": "Giresun Province",
            "code": "28"
        },
        {
            "name": "Gümüşhane Province",
            "code": "29"
        },
        {
            "name": "Hakkâri Province",
            "code": "30"
        },
        {
            "name": "Hatay Province",
            "code": "31"
        },
        {
            "name": "Iğdır Province",
            "code": "76"
        },
        {
            "name": "Isparta Province",
            "code": "32"
        },
        {
            "name": "Istanbul Province",
            "code": "34"
        },
        {
            "name": "İzmir Province",
            "code": "35"
        },
        {
            "name": "Kahramanmaraş Province",
            "code": "46"
        },
        {
            "name": "Karabük Province",
            "code": "78"
        },
        {
            "name": "Karaman Province",
            "code": "70"
        },
        {
            "name": "Kars Province",
            "code": "36"
        },
        {
            "name": "Kastamonu Province",
            "code": "37"
        },
        {
            "name": "Kayseri Province",
            "code": "38"
        },
        {
            "name": "Kilis Province",
            "code": "79"
        },
        {
            "name": "Kırıkkale Province",
            "code": "71"
        },
        {
            "name": "Kırklareli Province",
            "code": "39"
        },
        {
            "name": "Kırşehir Province",
            "code": "40"
        },
        {
            "name": "Kocaeli Province",
            "code": "41"
        },
        {
            "name": "Konya Province",
            "code": "42"
        },
        {
            "name": "Kütahya Province",
            "code": "43"
        },
        {
            "name": "Malatya Province",
            "code": "44"
        },
        {
            "name": "Manisa Province",
            "code": "45"
        },
        {
            "name": "Mardin Province",
            "code": "47"
        },
        {
            "name": "Mersin Province",
            "code": "33"
        },
        {
            "name": "Muğla Province",
            "code": "48"
        },
        {
            "name": "Muş Province",
            "code": "49"
        },
        {
            "name": "Nevşehir Province",
            "code": "50"
        },
        {
            "name": "Niğde Province",
            "code": "51"
        },
        {
            "name": "Ordu Province",
            "code": "52"
        },
        {
            "name": "Osmaniye Province",
            "code": "80"
        },
        {
            "name": "Rize Province",
            "code": "53"
        },
        {
            "name": "Sakarya Province",
            "code": "54"
        },
        {
            "name": "Samsun Province",
            "code": "55"
        },
        {
            "name": "Şanlıurfa Province",
            "code": "63"
        },
        {
            "name": "Siirt Province",
            "code": "56"
        },
        {
            "name": "Sinop Province",
            "code": "57"
        },
        {
            "name": "Sivas Province",
            "code": "58"
        },
        {
            "name": "Şırnak Province",
            "code": "73"
        },
        {
            "name": "Tekirdağ Province",
            "code": "59"
        },
        {
            "name": "Tokat Province",
            "code": "60"
        },
        {
            "name": "Trabzon Province",
            "code": "61"
        },
        {
            "name": "Tunceli Province",
            "code": "62"
        },
        {
            "name": "Uşak Province",
            "code": "64"
        },
        {
            "name": "Van Province",
            "code": "65"
        },
        {
            "name": "Yalova Province",
            "code": "77"
        },
        {
            "name": "Yozgat Province",
            "code": "66"
        },
        {
            "name": "Zonguldak Province",
            "code": "67"
        }
    ]
  },
  {
    "code2": "TM",
    "code3": "TKM",
    "name": "Turkmenistan",
    "capital": "Ashgabat",
    "region": "Asia",
    "subregion": "Central Asia",
    "states": [
        {
            "name": "Ahal Region",
            "code": "A"
        },
        {
            "name": "Ashgabat",
            "code": "S"
        },
        {
            "name": "Balkan Region",
            "code": "B"
        },
        {
            "name": "Daşoguz Region",
            "code": "D"
        },
        {
            "name": "Lebap Region",
            "code": "L"
        },
        {
            "name": "Mary Region",
            "code": "M"
        }
    ]
  },
  {
    "code2": "TC",
    "code3": "TCA",
    "name": "Turks and Caicos Islands",
    "capital": "Cockburn Town",
    "region": "Americas",
    "subregion": "Caribbean",
    "states": []
  },
  {
    "code2": "TV",
    "code3": "TUV",
    "name": "Tuvalu",
    "capital": "Funafuti",
    "region": "Oceania",
    "subregion": "Polynesia",
    "states": [
        {
            "name": "Funafuti",
            "code": "FUN"
        },
        {
            "name": "Nanumanga",
            "code": "NMG"
        },
        {
            "name": "Nanumea",
            "code": "NMA"
        },
        {
            "name": "Niutao Island Council",
            "code": "NIT"
        },
        {
            "name": "Nui",
            "code": "NUI"
        },
        {
            "name": "Nukufetau",
            "code": "NKF"
        },
        {
            "name": "Nukulaelae",
            "code": "NKL"
        },
        {
            "name": "Vaitupu",
            "code": "VAI"
        }
    ]
  },
  {
    "code2": "UG",
    "code3": "UGA",
    "name": "Uganda",
    "capital": "Kampala",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "states": [
        {
            "name": "Abim District",
            "code": "314"
        },
        {
            "name": "Adjumani District",
            "code": "301"
        },
        {
            "name": "Agago District",
            "code": "322"
        },
        {
            "name": "Alebtong District",
            "code": "323"
        },
        {
            "name": "Amolatar District",
            "code": "315"
        },
        {
            "name": "Amudat District",
            "code": "324"
        },
        {
            "name": "Amuria District",
            "code": "216"
        },
        {
            "name": "Amuru District",
            "code": "316"
        },
        {
            "name": "Apac District",
            "code": "302"
        },
        {
            "name": "Arua District",
            "code": "303"
        },
        {
            "name": "Budaka District",
            "code": "217"
        },
        {
            "name": "Bududa District",
            "code": "218"
        },
        {
            "name": "Bugiri District",
            "code": "201"
        },
        {
            "name": "Buhweju District",
            "code": "420"
        },
        {
            "name": "Buikwe District",
            "code": "117"
        },
        {
            "name": "Bukedea District",
            "code": "219"
        },
        {
            "name": "Bukomansimbi District",
            "code": "118"
        },
        {
            "name": "Bukwo District",
            "code": "220"
        },
        {
            "name": "Bulambuli District",
            "code": "225"
        },
        {
            "name": "Buliisa District",
            "code": "416"
        },
        {
            "name": "Bundibugyo District",
            "code": "401"
        },
        {
            "name": "Bunyangabu District",
            "code": "430"
        },
        {
            "name": "Bushenyi District",
            "code": "402"
        },
        {
            "name": "Busia District",
            "code": "202"
        },
        {
            "name": "Butaleja District",
            "code": "221"
        },
        {
            "name": "Butambala District",
            "code": "119"
        },
        {
            "name": "Butebo District",
            "code": "233"
        },
        {
            "name": "Buvuma District",
            "code": "120"
        },
        {
            "name": "Buyende District",
            "code": "226"
        },
        {
            "name": "Central Region",
            "code": "C"
        },
        {
            "name": "Dokolo District",
            "code": "317"
        },
        {
            "name": "Eastern Region",
            "code": "E"
        },
        {
            "name": "Gomba District",
            "code": "121"
        },
        {
            "name": "Gulu District",
            "code": "304"
        },
        {
            "name": "Ibanda District",
            "code": "417"
        },
        {
            "name": "Iganga District",
            "code": "203"
        },
        {
            "name": "Isingiro District",
            "code": "418"
        },
        {
            "name": "Jinja District",
            "code": "204"
        },
        {
            "name": "Kaabong District",
            "code": "318"
        },
        {
            "name": "Kabale District",
            "code": "404"
        },
        {
            "name": "Kabarole District",
            "code": "405"
        },
        {
            "name": "Kaberamaido District",
            "code": "213"
        },
        {
            "name": "Kagadi District",
            "code": "427"
        },
        {
            "name": "Kakumiro District",
            "code": "428"
        },
        {
            "name": "Kalangala District",
            "code": "101"
        },
        {
            "name": "Kaliro District",
            "code": "222"
        },
        {
            "name": "Kalungu District",
            "code": "122"
        },
        {
            "name": "Kampala District",
            "code": "102"
        },
        {
            "name": "Kamuli District",
            "code": "205"
        },
        {
            "name": "Kamwenge District",
            "code": "413"
        },
        {
            "name": "Kanungu District",
            "code": "414"
        },
        {
            "name": "Kapchorwa District",
            "code": "206"
        },
        {
            "name": "Kasese District",
            "code": "406"
        },
        {
            "name": "Katakwi District",
            "code": "207"
        },
        {
            "name": "Kayunga District",
            "code": "112"
        },
        {
            "name": "Kibaale District",
            "code": "407"
        },
        {
            "name": "Kiboga District",
            "code": "103"
        },
        {
            "name": "Kibuku District",
            "code": "227"
        },
        {
            "name": "Kiruhura District",
            "code": "419"
        },
        {
            "name": "Kiryandongo District",
            "code": "421"
        },
        {
            "name": "Kisoro District",
            "code": "408"
        },
        {
            "name": "Kitgum District",
            "code": "305"
        },
        {
            "name": "Koboko District",
            "code": "319"
        },
        {
            "name": "Kole District",
            "code": "325"
        },
        {
            "name": "Kotido District",
            "code": "306"
        },
        {
            "name": "Kumi District",
            "code": "208"
        },
        {
            "name": "Kween District",
            "code": "228"
        },
        {
            "name": "Kyankwanzi District",
            "code": "123"
        },
        {
            "name": "Kyegegwa District",
            "code": "422"
        },
        {
            "name": "Kyenjojo District",
            "code": "415"
        },
        {
            "name": "Kyotera District",
            "code": "125"
        },
        {
            "name": "Lamwo District",
            "code": "326"
        },
        {
            "name": "Lira District",
            "code": "307"
        },
        {
            "name": "Luuka District",
            "code": "229"
        },
        {
            "name": "Luwero District",
            "code": "104"
        },
        {
            "name": "Lwengo District",
            "code": "124"
        },
        {
            "name": "Lyantonde District",
            "code": "114"
        },
        {
            "name": "Manafwa District",
            "code": "223"
        },
        {
            "name": "Maracha District",
            "code": "320"
        },
        {
            "name": "Masaka District",
            "code": "105"
        },
        {
            "name": "Masindi District",
            "code": "409"
        },
        {
            "name": "Mayuge District",
            "code": "214"
        },
        {
            "name": "Mbale District",
            "code": "209"
        },
        {
            "name": "Mbarara District",
            "code": "410"
        },
        {
            "name": "Mitooma District",
            "code": "423"
        },
        {
            "name": "Mityana District",
            "code": "115"
        },
        {
            "name": "Moroto District",
            "code": "308"
        },
        {
            "name": "Moyo District",
            "code": "309"
        },
        {
            "name": "Mpigi District",
            "code": "106"
        },
        {
            "name": "Mubende District",
            "code": "107"
        },
        {
            "name": "Mukono District",
            "code": "108"
        },
        {
            "name": "Nakapiripirit District",
            "code": "311"
        },
        {
            "name": "Nakaseke District",
            "code": "116"
        },
        {
            "name": "Nakasongola District",
            "code": "109"
        },
        {
            "name": "Namayingo District",
            "code": "230"
        },
        {
            "name": "Namisindwa District",
            "code": "234"
        },
        {
            "name": "Namutumba District",
            "code": "224"
        },
        {
            "name": "Napak District",
            "code": "327"
        },
        {
            "name": "Nebbi District",
            "code": "310"
        },
        {
            "name": "Ngora District",
            "code": "231"
        },
        {
            "name": "Northern Region",
            "code": "N"
        },
        {
            "name": "Ntoroko District",
            "code": "424"
        },
        {
            "name": "Ntungamo District",
            "code": "411"
        },
        {
            "name": "Nwoya District",
            "code": "328"
        },
        {
            "name": "Omoro District",
            "code": "331"
        },
        {
            "name": "Otuke District",
            "code": "329"
        },
        {
            "name": "Oyam District",
            "code": "321"
        },
        {
            "name": "Pader District",
            "code": "312"
        },
        {
            "name": "Pakwach District",
            "code": "332"
        },
        {
            "name": "Pallisa District",
            "code": "210"
        },
        {
            "name": "Rakai District",
            "code": "110"
        },
        {
            "name": "Rubanda District",
            "code": "429"
        },
        {
            "name": "Rubirizi District",
            "code": "425"
        },
        {
            "name": "Rukiga District",
            "code": "431"
        },
        {
            "name": "Rukungiri District",
            "code": "412"
        },
        {
            "name": "Sembabule District",
            "code": "111"
        },
        {
            "name": "Serere District",
            "code": "232"
        },
        {
            "name": "Sheema District",
            "code": "426"
        },
        {
            "name": "Sironko District",
            "code": "215"
        },
        {
            "name": "Soroti District",
            "code": "211"
        },
        {
            "name": "Tororo District",
            "code": "212"
        },
        {
            "name": "Wakiso District",
            "code": "113"
        },
        {
            "name": "Western Region",
            "code": "W"
        },
        {
            "name": "Yumbe District",
            "code": "313"
        },
        {
            "name": "Zombo District",
            "code": "330"
        }
    ]
  },
  {
    "code2": "UA",
    "code3": "UKR",
    "name": "Ukraine",
    "capital": "Kiev",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "states": [
        {
            "name": "Autonomous Republic of Crimea",
            "code": "43"
        },
        {
            "name": "Cherkasy Oblast",
            "code": "71"
        },
        {
            "name": "Chernihiv Oblast",
            "code": "74"
        },
        {
            "name": "Chernivtsi Oblast",
            "code": "77"
        },
        {
            "name": "Dnipropetrovsk Oblast",
            "code": "12"
        },
        {
            "name": "Donetsk Oblast",
            "code": "14"
        },
        {
            "name": "Ivano-Frankivsk Oblast",
            "code": "26"
        },
        {
            "name": "Kharkiv Oblast",
            "code": "63"
        },
        {
            "name": "Kherson Oblast",
            "code": "65"
        },
        {
            "name": "Khmelnytsky Oblast",
            "code": "68"
        },
        {
            "name": "Kiev",
            "code": "30"
        },
        {
            "name": "Kirovohrad Oblast",
            "code": "35"
        },
        {
            "name": "Kyiv Oblast",
            "code": "32"
        },
        {
            "name": "Luhansk Oblast",
            "code": "09"
        },
        {
            "name": "Lviv Oblast",
            "code": "46"
        },
        {
            "name": "Mykolaiv Oblast",
            "code": "48"
        },
        {
            "name": "Odessa Oblast",
            "code": "51"
        },
        {
            "name": "Rivne Oblast",
            "code": "56"
        },
        {
            "name": "Sumy Oblast",
            "code": "59"
        },
        {
            "name": "Ternopil Oblast",
            "code": "61"
        },
        {
            "name": "Vinnytsia Oblast",
            "code": "05"
        },
        {
            "name": "Volyn Oblast",
            "code": "07"
        },
        {
            "name": "Zakarpattia Oblast",
            "code": "21"
        },
        {
            "name": "Zaporizhzhya Oblast",
            "code": "23"
        },
        {
            "name": "Zhytomyr Oblast",
            "code": "18"
        }
    ]
  },
  {
    "code2": "AE",
    "code3": "ARE",
    "name": "United Arab Emirates",
    "capital": "Abu Dhabi",
    "region": "Asia",
    "subregion": "Western Asia",
    "states": [
        {
            "name": "Abu Dhabi Emirate",
            "code": "AZ"
        },
        {
            "name": "Ajman Emirate",
            "code": "AJ"
        },
        {
            "name": "Dubai",
            "code": "DU"
        },
        {
            "name": "Fujairah",
            "code": "FU"
        },
        {
            "name": "Ras al-Khaimah",
            "code": "RK"
        },
        {
            "name": "Sharjah Emirate",
            "code": "SH"
        },
        {
            "name": "Umm al-Quwain",
            "code": "UQ"
        }
    ]
  },
  {
    "code2": "GB",
    "code3": "GBR",
    "name": "United Kingdom",
    "capital": "London",
    "region": "Europe",
    "subregion": "Northern Europe",
    "states": [
      {
        "code": "BDG",
        "name": "Barking and Dagenham",
        "subdivision": "London borough"
      },
      {
        "code": "BNE",
        "name": "Barnet",
        "subdivision": "London borough"
      },
      {
        "code": "BEX",
        "name": "Bexley",
        "subdivision": "London borough"
      },
      {
        "code": "BEN",
        "name": "Brent",
        "subdivision": "London borough"
      },
      {
        "code": "BRY",
        "name": "Bromley",
        "subdivision": "London borough"
      },
      {
        "code": "CMD",
        "name": "Camden",
        "subdivision": "London borough"
      },
      {
        "code": "CRY",
        "name": "Croydon",
        "subdivision": "London borough"
      },
      {
        "code": "EAL",
        "name": "Ealing",
        "subdivision": "London borough"
      },
      {
        "code": "ENF",
        "name": "Enfield",
        "subdivision": "London borough"
      },
      {
        "code": "GRE",
        "name": "Greenwich",
        "subdivision": "London borough"
      },
      {
        "code": "HCK",
        "name": "Hackney",
        "subdivision": "London borough"
      },
      {
        "code": "HMF",
        "name": "Hammersmith and Fulham",
        "subdivision": "London borough"
      },
      {
        "code": "HRY",
        "name": "Haringey",
        "subdivision": "London borough"
      },
      {
        "code": "HRW",
        "name": "Harrow",
        "subdivision": "London borough"
      },
      {
        "code": "HAV",
        "name": "Havering",
        "subdivision": "London borough"
      },
      {
        "code": "HIL",
        "name": "Hillingdon",
        "subdivision": "London borough"
      },
      {
        "code": "HNS",
        "name": "Hounslow",
        "subdivision": "London borough"
      },
      {
        "code": "ISL",
        "name": "Islington",
        "subdivision": "London borough"
      },
      {
        "code": "KEC",
        "name": "Kensington and Chelsea",
        "subdivision": "London borough"
      },
      {
        "code": "KTT",
        "name": "Kingston upon Thames",
        "subdivision": "London borough"
      },
      {
        "code": "LBH",
        "name": "Lambeth",
        "subdivision": "London borough"
      },
      {
        "code": "LEW",
        "name": "Lewisham",
        "subdivision": "London borough"
      },
      {
        "code": "MRT",
        "name": "Merton",
        "subdivision": "London borough"
      },
      {
        "code": "NWM",
        "name": "Newham",
        "subdivision": "London borough"
      },
      {
        "code": "RDB",
        "name": "Redbridge",
        "subdivision": "London borough"
      },
      {
        "code": "RIC",
        "name": "Richmond upon Thames",
        "subdivision": "London borough"
      },
      {
        "code": "SWK",
        "name": "Southwark",
        "subdivision": "London borough"
      },
      {
        "code": "STN",
        "name": "Sutton",
        "subdivision": "London borough"
      },
      {
        "code": "TWH",
        "name": "Tower Hamlets",
        "subdivision": "London borough"
      },
      {
        "code": "WFT",
        "name": "Waltham Forest",
        "subdivision": "London borough"
      },
      {
        "code": "WND",
        "name": "Wandsworth",
        "subdivision": "London borough"
      },
      {
        "code": "WSM",
        "name": "Westminster",
        "subdivision": "London borough"
      },
      {
        "code": "EAW",
        "name": "England and Wales",
        "subdivision": "Nation"
      },
      {
        "code": "GBN",
        "name": "Great Britain",
        "subdivision": "Nation"
      },
      {
        "code": "UKM",
        "name": "United Kingdom",
        "subdivision": "Nation"
      },
      {
        "code": "LND",
        "name": "London, City of",
        "subdivision": "city corporation"
      },
      {
        "code": "ABE",
        "name": "Aberdeen City",
        "subdivision": "council area"
      },
      {
        "code": "ABD",
        "name": "Aberdeenshire",
        "subdivision": "council area"
      },
      {
        "code": "ANS",
        "name": "Angus",
        "subdivision": "council area"
      },
      {
        "code": "AGB",
        "name": "Argyll and Bute",
        "subdivision": "council area"
      },
      {
        "code": "CLK",
        "name": "Clackmannanshire",
        "subdivision": "council area"
      },
      {
        "code": "DGY",
        "name": "Dumfries and Galloway",
        "subdivision": "council area"
      },
      {
        "code": "DND",
        "name": "Dundee City",
        "subdivision": "council area"
      },
      {
        "code": "EAY",
        "name": "East Ayrshire",
        "subdivision": "council area"
      },
      {
        "code": "EDU",
        "name": "East Dunbartonshire",
        "subdivision": "council area"
      },
      {
        "code": "ELN",
        "name": "East Lothian",
        "subdivision": "council area"
      },
      {
        "code": "ERW",
        "name": "East Renfrewshire",
        "subdivision": "council area"
      },
      {
        "code": "EDH",
        "name": "Edinburgh, City of",
        "subdivision": "council area"
      },
      {
        "code": "ELS",
        "name": "Eilean Siar",
        "subdivision": "council area"
      },
      {
        "code": "FAL",
        "name": "Falkirk",
        "subdivision": "council area"
      },
      {
        "code": "FIF",
        "name": "Fife",
        "subdivision": "council area"
      },
      {
        "code": "GLG",
        "name": "Glasgow City",
        "subdivision": "council area"
      },
      {
        "code": "HLD",
        "name": "Highland",
        "subdivision": "council area"
      },
      {
        "code": "IVC",
        "name": "Inverclyde",
        "subdivision": "council area"
      },
      {
        "code": "MLN",
        "name": "Midlothian",
        "subdivision": "council area"
      },
      {
        "code": "MRY",
        "name": "Moray",
        "subdivision": "council area"
      },
      {
        "code": "NAY",
        "name": "North Ayrshire",
        "subdivision": "council area"
      },
      {
        "code": "NLK",
        "name": "North Lanarkshire",
        "subdivision": "council area"
      },
      {
        "code": "ORK",
        "name": "Orkney Islands",
        "subdivision": "council area"
      },
      {
        "code": "PKN",
        "name": "Perth and Kinross",
        "subdivision": "council area"
      },
      {
        "code": "RFW",
        "name": "Renfrewshire",
        "subdivision": "council area"
      },
      {
        "code": "SCB",
        "name": "Scottish Borders, The",
        "subdivision": "council area"
      },
      {
        "code": "ZET",
        "name": "Shetland Islands",
        "subdivision": "council area"
      },
      {
        "code": "SAY",
        "name": "South Ayrshire",
        "subdivision": "council area"
      },
      {
        "code": "SLK",
        "name": "South Lanarkshire",
        "subdivision": "council area"
      },
      {
        "code": "STG",
        "name": "Stirling",
        "subdivision": "council area"
      },
      {
        "code": "WDU",
        "name": "West Dunbartonshire",
        "subdivision": "council area"
      },
      {
        "code": "WLN",
        "name": "West Lothian",
        "subdivision": "council area"
      },
      {
        "code": "ENG",
        "name": "England",
        "subdivision": "country"
      },
      {
        "code": "SCT",
        "name": "Scotland",
        "subdivision": "country"
      },
      {
        "code": "WLS",
        "name": "Wales",
        "subdivision": "country"
      },
      {
        "code": "ANT",
        "name": "Antrim",
        "subdivision": "district council area"
      },
      {
        "code": "ARD",
        "name": "Ards",
        "subdivision": "district council area"
      },
      {
        "code": "ARM",
        "name": "Armagh",
        "subdivision": "district council area"
      },
      {
        "code": "BLA",
        "name": "Ballymena",
        "subdivision": "district council area"
      },
      {
        "code": "BLY",
        "name": "Ballymoney",
        "subdivision": "district council area"
      },
      {
        "code": "BNB",
        "name": "Banbridge",
        "subdivision": "district council area"
      },
      {
        "code": "BFS",
        "name": "Belfast",
        "subdivision": "district council area"
      },
      {
        "code": "CKF",
        "name": "Carrickfergus",
        "subdivision": "district council area"
      },
      {
        "code": "CSR",
        "name": "Castlereagh",
        "subdivision": "district council area"
      },
      {
        "code": "CLR",
        "name": "Coleraine",
        "subdivision": "district council area"
      },
      {
        "code": "CKT",
        "name": "Cookstown",
        "subdivision": "district council area"
      },
      {
        "code": "CGV",
        "name": "Craigavon",
        "subdivision": "district council area"
      },
      {
        "code": "DRY",
        "name": "Derry",
        "subdivision": "district council area"
      },
      {
        "code": "DOW",
        "name": "Down",
        "subdivision": "district council area"
      },
      {
        "code": "DGN",
        "name": "Dungannon and South Tyrone",
        "subdivision": "district council area"
      },
      {
        "code": "FER",
        "name": "Fermanagh",
        "subdivision": "district council area"
      },
      {
        "code": "LRN",
        "name": "Larne",
        "subdivision": "district council area"
      },
      {
        "code": "LMV",
        "name": "Limavady",
        "subdivision": "district council area"
      },
      {
        "code": "LSB",
        "name": "Lisburn",
        "subdivision": "district council area"
      },
      {
        "code": "MFT",
        "name": "Magherafelt",
        "subdivision": "district council area"
      },
      {
        "code": "MYL",
        "name": "Moyle",
        "subdivision": "district council area"
      },
      {
        "code": "NYM",
        "name": "Newry and Mourne District",
        "subdivision": "district council area"
      },
      {
        "code": "NTA",
        "name": "Newtownabbey",
        "subdivision": "district council area"
      },
      {
        "code": "NDN",
        "name": "North Down",
        "subdivision": "district council area"
      },
      {
        "code": "OMH",
        "name": "Omagh",
        "subdivision": "district council area"
      },
      {
        "code": "STB",
        "name": "Strabane",
        "subdivision": "district council area"
      },
      {
        "code": "BNS",
        "name": "Barnsley",
        "subdivision": "metropolitan district"
      },
      {
        "code": "BIR",
        "name": "Birmingham",
        "subdivision": "metropolitan district"
      },
      {
        "code": "BOL",
        "name": "Bolton",
        "subdivision": "metropolitan district"
      },
      {
        "code": "BRD",
        "name": "Bradford",
        "subdivision": "metropolitan district"
      },
      {
        "code": "BUR",
        "name": "Bury",
        "subdivision": "metropolitan district"
      },
      {
        "code": "CLD",
        "name": "Calderdale",
        "subdivision": "metropolitan district"
      },
      {
        "code": "COV",
        "name": "Coventry",
        "subdivision": "metropolitan district"
      },
      {
        "code": "DNC",
        "name": "Doncaster",
        "subdivision": "metropolitan district"
      },
      {
        "code": "DUD",
        "name": "Dudley",
        "subdivision": "metropolitan district"
      },
      {
        "code": "GAT",
        "name": "Gateshead",
        "subdivision": "metropolitan district"
      },
      {
        "code": "KIR",
        "name": "Kirklees",
        "subdivision": "metropolitan district"
      },
      {
        "code": "KWL",
        "name": "Knowsley",
        "subdivision": "metropolitan district"
      },
      {
        "code": "LDS",
        "name": "Leeds",
        "subdivision": "metropolitan district"
      },
      {
        "code": "LIV",
        "name": "Liverpool",
        "subdivision": "metropolitan district"
      },
      {
        "code": "MAN",
        "name": "Manchester",
        "subdivision": "metropolitan district"
      },
      {
        "code": "NET",
        "name": "Newcastle upon Tyne",
        "subdivision": "metropolitan district"
      },
      {
        "code": "NTY",
        "name": "North Tyneside",
        "subdivision": "metropolitan district"
      },
      {
        "code": "OLD",
        "name": "Oldham",
        "subdivision": "metropolitan district"
      },
      {
        "code": "RCH",
        "name": "Rochdale",
        "subdivision": "metropolitan district"
      },
      {
        "code": "ROT",
        "name": "Rotherham",
        "subdivision": "metropolitan district"
      },
      {
        "code": "SLF",
        "name": "Salford",
        "subdivision": "metropolitan district"
      },
      {
        "code": "SAW",
        "name": "Sandwell",
        "subdivision": "metropolitan district"
      },
      {
        "code": "SFT",
        "name": "Sefton",
        "subdivision": "metropolitan district"
      },
      {
        "code": "SHF",
        "name": "Sheffield",
        "subdivision": "metropolitan district"
      },
      {
        "code": "SOL",
        "name": "Solihull",
        "subdivision": "metropolitan district"
      },
      {
        "code": "STY",
        "name": "South Tyneside",
        "subdivision": "metropolitan district"
      },
      {
        "code": "SHN",
        "name": "St. Helens",
        "subdivision": "metropolitan district"
      },
      {
        "code": "SKP",
        "name": "Stockport",
        "subdivision": "metropolitan district"
      },
      {
        "code": "SND",
        "name": "Sunderland",
        "subdivision": "metropolitan district"
      },
      {
        "code": "TAM",
        "name": "Tameside",
        "subdivision": "metropolitan district"
      },
      {
        "code": "TRF",
        "name": "Trafford",
        "subdivision": "metropolitan district"
      },
      {
        "code": "WKF",
        "name": "Wakefield",
        "subdivision": "metropolitan district"
      },
      {
        "code": "WLL",
        "name": "Walsall",
        "subdivision": "metropolitan district"
      },
      {
        "code": "WGN",
        "name": "Wigan",
        "subdivision": "metropolitan district"
      },
      {
        "code": "WRL",
        "name": "Wirral",
        "subdivision": "metropolitan district"
      },
      {
        "code": "WLV",
        "name": "Wolverhampton",
        "subdivision": "metropolitan district"
      },
      {
        "code": "NIR",
        "name": "Northern Ireland",
        "subdivision": "province"
      },
      {
        "code": "BKM",
        "name": "Buckinghamshire",
        "subdivision": "two-tier county"
      },
      {
        "code": "CAM",
        "name": "Cambridgeshire",
        "subdivision": "two-tier county"
      },
      {
        "code": "CMA",
        "name": "Cumbria",
        "subdivision": "two-tier county"
      },
      {
        "code": "DBY",
        "name": "Derbyshire",
        "subdivision": "two-tier county"
      },
      {
        "code": "DEV",
        "name": "Devon",
        "subdivision": "two-tier county"
      },
      {
        "code": "DOR",
        "name": "Dorset",
        "subdivision": "two-tier county"
      },
      {
        "code": "ESX",
        "name": "East Sussex",
        "subdivision": "two-tier county"
      },
      {
        "code": "ESS",
        "name": "Essex",
        "subdivision": "two-tier county"
      },
      {
        "code": "GLS",
        "name": "Gloucestershire",
        "subdivision": "two-tier county"
      },
      {
        "code": "HAM",
        "name": "Hampshire",
        "subdivision": "two-tier county"
      },
      {
        "code": "HRT",
        "name": "Hertfordshire",
        "subdivision": "two-tier county"
      },
      {
        "code": "KEN",
        "name": "Kent",
        "subdivision": "two-tier county"
      },
      {
        "code": "LAN",
        "name": "Lancashire",
        "subdivision": "two-tier county"
      },
      {
        "code": "LEC",
        "name": "Leicestershire",
        "subdivision": "two-tier county"
      },
      {
        "code": "LIN",
        "name": "Lincolnshire",
        "subdivision": "two-tier county"
      },
      {
        "code": "NFK",
        "name": "Norfolk",
        "subdivision": "two-tier county"
      },
      {
        "code": "NYK",
        "name": "North Yorkshire",
        "subdivision": "two-tier county"
      },
      {
        "code": "NTH",
        "name": "Northamptonshire",
        "subdivision": "two-tier county"
      },
      {
        "code": "NTT",
        "name": "Nottinghamshire",
        "subdivision": "two-tier county"
      },
      {
        "code": "OXF",
        "name": "Oxfordshire",
        "subdivision": "two-tier county"
      },
      {
        "code": "SOM",
        "name": "Somerset",
        "subdivision": "two-tier county"
      },
      {
        "code": "STS",
        "name": "Staffordshire",
        "subdivision": "two-tier county"
      },
      {
        "code": "SFK",
        "name": "Suffolk",
        "subdivision": "two-tier county"
      },
      {
        "code": "SRY",
        "name": "Surrey",
        "subdivision": "two-tier county"
      },
      {
        "code": "WAR",
        "name": "Warwickshire",
        "subdivision": "two-tier county"
      },
      {
        "code": "WSX",
        "name": "West Sussex",
        "subdivision": "two-tier county"
      },
      {
        "code": "WOR",
        "name": "Worcestershire",
        "subdivision": "two-tier county"
      },
      {
        "code": "BAS",
        "name": "Bath and North East Somerset",
        "subdivision": "unitary authority"
      },
      {
        "code": "BDF",
        "name": "Bedford",
        "subdivision": "unitary authority"
      },
      {
        "code": "BBD",
        "name": "Blackburn with Darwen",
        "subdivision": "unitary authority"
      },
      {
        "code": "BPL",
        "name": "Blackpool",
        "subdivision": "unitary authority"
      },
      {
        "code": "BGW",
        "name": "Blaenau Gwent",
        "subdivision": "unitary authority"
      },
      {
        "code": "BMH",
        "name": "Bournemouth",
        "subdivision": "unitary authority"
      },
      {
        "code": "BRC",
        "name": "Bracknell Forest",
        "subdivision": "unitary authority"
      },
      {
        "code": "BGE",
        "name": "Bridgend",
        "subdivision": "unitary authority"
      },
      {
        "code": "BNH",
        "name": "Brighton and Hove",
        "subdivision": "unitary authority"
      },
      {
        "code": "BST",
        "name": "Bristol, City of",
        "subdivision": "unitary authority"
      },
      {
        "code": "CAY",
        "name": "Caerphilly",
        "subdivision": "unitary authority"
      },
      {
        "code": "CRF",
        "name": "Cardiff",
        "subdivision": "unitary authority"
      },
      {
        "code": "CMN",
        "name": "Carmarthenshire",
        "subdivision": "unitary authority"
      },
      {
        "code": "CBF",
        "name": "Central Bedfordshire",
        "subdivision": "unitary authority"
      },
      {
        "code": "CGN",
        "name": "Ceredigion",
        "subdivision": "unitary authority"
      },
      {
        "code": "CHE",
        "name": "Cheshire East",
        "subdivision": "unitary authority"
      },
      {
        "code": "CHW",
        "name": "Cheshire West and Chester",
        "subdivision": "unitary authority"
      },
      {
        "code": "CWY",
        "name": "Conwy",
        "subdivision": "unitary authority"
      },
      {
        "code": "CON",
        "name": "Cornwall",
        "subdivision": "unitary authority"
      },
      {
        "code": "DAL",
        "name": "Darlington",
        "subdivision": "unitary authority"
      },
      {
        "code": "DEN",
        "name": "Denbighshire",
        "subdivision": "unitary authority"
      },
      {
        "code": "DER",
        "name": "Derby",
        "subdivision": "unitary authority"
      },
      {
        "code": "DUR",
        "name": "Durham, County",
        "subdivision": "unitary authority"
      },
      {
        "code": "ERY",
        "name": "East Riding of Yorkshire",
        "subdivision": "unitary authority"
      },
      {
        "code": "FLN",
        "name": "Flintshire",
        "subdivision": "unitary authority"
      },
      {
        "code": "GWN",
        "name": "Gwynedd",
        "subdivision": "unitary authority"
      },
      {
        "code": "HAL",
        "name": "Halton",
        "subdivision": "unitary authority"
      },
      {
        "code": "HPL",
        "name": "Hartlepool",
        "subdivision": "unitary authority"
      },
      {
        "code": "HEF",
        "name": "Herefordshire",
        "subdivision": "unitary authority"
      },
      {
        "code": "AGY",
        "name": "Isle of Anglesey",
        "subdivision": "unitary authority"
      },
      {
        "code": "IOW",
        "name": "Isle of Wight",
        "subdivision": "unitary authority"
      },
      {
        "code": "IOS",
        "name": "Isles of Scilly",
        "subdivision": "unitary authority"
      },
      {
        "code": "KHL",
        "name": "Kingston upon Hull",
        "subdivision": "unitary authority"
      },
      {
        "code": "LCE",
        "name": "Leicester",
        "subdivision": "unitary authority"
      },
      {
        "code": "LUT",
        "name": "Luton",
        "subdivision": "unitary authority"
      },
      {
        "code": "MDW",
        "name": "Medway",
        "subdivision": "unitary authority"
      },
      {
        "code": "MTY",
        "name": "Merthyr Tydfil",
        "subdivision": "unitary authority"
      },
      {
        "code": "MDB",
        "name": "Middlesbrough",
        "subdivision": "unitary authority"
      },
      {
        "code": "MIK",
        "name": "Milton Keynes",
        "subdivision": "unitary authority"
      },
      {
        "code": "MON",
        "name": "Monmouthshire",
        "subdivision": "unitary authority"
      },
      {
        "code": "NTL",
        "name": "Neath Port Talbot",
        "subdivision": "unitary authority"
      },
      {
        "code": "NWP",
        "name": "Newport",
        "subdivision": "unitary authority"
      },
      {
        "code": "NEL",
        "name": "North East Lincolnshire",
        "subdivision": "unitary authority"
      },
      {
        "code": "NLN",
        "name": "North Lincolnshire",
        "subdivision": "unitary authority"
      },
      {
        "code": "NSM",
        "name": "North Somerset",
        "subdivision": "unitary authority"
      },
      {
        "code": "NBL",
        "name": "Northumberland",
        "subdivision": "unitary authority"
      },
      {
        "code": "NGM",
        "name": "Nottingham",
        "subdivision": "unitary authority"
      },
      {
        "code": "PEM",
        "name": "Pembrokeshire",
        "subdivision": "unitary authority"
      },
      {
        "code": "PTE",
        "name": "Peterborough",
        "subdivision": "unitary authority"
      },
      {
        "code": "PLY",
        "name": "Plymouth",
        "subdivision": "unitary authority"
      },
      {
        "code": "POL",
        "name": "Poole",
        "subdivision": "unitary authority"
      },
      {
        "code": "POR",
        "name": "Portsmouth",
        "subdivision": "unitary authority"
      },
      {
        "code": "POW",
        "name": "Powys",
        "subdivision": "unitary authority"
      },
      {
        "code": "RDG",
        "name": "Reading",
        "subdivision": "unitary authority"
      },
      {
        "code": "RCC",
        "name": "Redcar and Cleveland",
        "subdivision": "unitary authority"
      },
      {
        "code": "RCT",
        "name": "Rhondda, Cynon, Taff",
        "subdivision": "unitary authority"
      },
      {
        "code": "RUT",
        "name": "Rutland",
        "subdivision": "unitary authority"
      },
      {
        "code": "SHR",
        "name": "Shropshire",
        "subdivision": "unitary authority"
      },
      {
        "code": "SLG",
        "name": "Slough",
        "subdivision": "unitary authority"
      },
      {
        "code": "SGC",
        "name": "South Gloucestershire",
        "subdivision": "unitary authority"
      },
      {
        "code": "STH",
        "name": "Southampton",
        "subdivision": "unitary authority"
      },
      {
        "code": "SOS",
        "name": "Southend-on-Sea",
        "subdivision": "unitary authority"
      },
      {
        "code": "STT",
        "name": "Stockton-on-Tees",
        "subdivision": "unitary authority"
      },
      {
        "code": "STE",
        "name": "Stoke-on-Trent",
        "subdivision": "unitary authority"
      },
      {
        "code": "SWA",
        "name": "Swansea",
        "subdivision": "unitary authority"
      },
      {
        "code": "SWD",
        "name": "Swindon",
        "subdivision": "unitary authority"
      },
      {
        "code": "TFW",
        "name": "Telford and Wrekin",
        "subdivision": "unitary authority"
      },
      {
        "code": "THR",
        "name": "Thurrock",
        "subdivision": "unitary authority"
      },
      {
        "code": "TOB",
        "name": "Torbay",
        "subdivision": "unitary authority"
      },
      {
        "code": "TOF",
        "name": "Torfaen",
        "subdivision": "unitary authority"
      },
      {
        "code": "VGL",
        "name": "Vale of Glamorgan, The",
        "subdivision": "unitary authority"
      },
      {
        "code": "WRT",
        "name": "Warrington",
        "subdivision": "unitary authority"
      },
      {
        "code": "WBK",
        "name": "West Berkshire",
        "subdivision": "unitary authority"
      },
      {
        "code": "WIL",
        "name": "Wiltshire",
        "subdivision": "unitary authority"
      },
      {
        "code": "WNM",
        "name": "Windsor and Maidenhead",
        "subdivision": "unitary authority"
      },
      {
        "code": "WOK",
        "name": "Wokingham",
        "subdivision": "unitary authority"
      },
      {
        "code": "WRX",
        "name": "Wrexham",
        "subdivision": "unitary authority"
      },
      {
        "code": "YOR",
        "name": "York",
        "subdivision": "unitary authority"
      }
    ]
  },
  {
    "code2": "US",
    "code3": "USA",
    "name": "United States",
    "capital": "Washington, D.C.",
    "region": "Americas",
    "subregion": "Northern America",
    "states": [
      {
        "code": "DC",
        "name": "District of Columbia",
        "subdivision": "district"
      },
      {
        "code": "AS",
        "name": "American Samoa",
        "subdivision": "outlying territory"
      },
      {
        "code": "GU",
        "name": "Guam",
        "subdivision": "outlying territory"
      },
      {
        "code": "MP",
        "name": "Northern Mariana Islands",
        "subdivision": "outlying territory"
      },
      {
        "code": "PR",
        "name": "Puerto Rico",
        "subdivision": "outlying territory"
      },
      {
        "code": "UM",
        "name": "United States Minor Outlying Islands",
        "subdivision": "outlying territory"
      },
      {
        "code": "VI",
        "name": "Virgin Islands, U.S.",
        "subdivision": "outlying territory"
      },
      {
        "code": "AL",
        "name": "Alabama",
        "subdivision": "state"
      },
      {
        "code": "AK",
        "name": "Alaska",
        "subdivision": "state"
      },
      {
        "code": "AZ",
        "name": "Arizona",
        "subdivision": "state"
      },
      {
        "code": "AR",
        "name": "Arkansas",
        "subdivision": "state"
      },
      {
        "code": "CA",
        "name": "California",
        "subdivision": "state"
      },
      {
        "code": "CO",
        "name": "Colorado",
        "subdivision": "state"
      },
      {
        "code": "CT",
        "name": "Connecticut",
        "subdivision": "state"
      },
      {
        "code": "DE",
        "name": "Delaware",
        "subdivision": "state"
      },
      {
        "code": "FL",
        "name": "Florida",
        "subdivision": "state"
      },
      {
        "code": "GA",
        "name": "Georgia",
        "subdivision": "state"
      },
      {
        "code": "HI",
        "name": "Hawaii",
        "subdivision": "state"
      },
      {
        "code": "ID",
        "name": "Idaho",
        "subdivision": "state"
      },
      {
        "code": "IL",
        "name": "Illinois",
        "subdivision": "state"
      },
      {
        "code": "IN",
        "name": "Indiana",
        "subdivision": "state"
      },
      {
        "code": "IA",
        "name": "Iowa",
        "subdivision": "state"
      },
      {
        "code": "KS",
        "name": "Kansas",
        "subdivision": "state"
      },
      {
        "code": "KY",
        "name": "Kentucky",
        "subdivision": "state"
      },
      {
        "code": "LA",
        "name": "Louisiana",
        "subdivision": "state"
      },
      {
        "code": "ME",
        "name": "Maine",
        "subdivision": "state"
      },
      {
        "code": "MD",
        "name": "Maryland",
        "subdivision": "state"
      },
      {
        "code": "MA",
        "name": "Massachusetts",
        "subdivision": "state"
      },
      {
        "code": "MI",
        "name": "Michigan",
        "subdivision": "state"
      },
      {
        "code": "MN",
        "name": "Minnesota",
        "subdivision": "state"
      },
      {
        "code": "MS",
        "name": "Mississippi",
        "subdivision": "state"
      },
      {
        "code": "MO",
        "name": "Missouri",
        "subdivision": "state"
      },
      {
        "code": "MT",
        "name": "Montana",
        "subdivision": "state"
      },
      {
        "code": "NE",
        "name": "Nebraska",
        "subdivision": "state"
      },
      {
        "code": "NV",
        "name": "Nevada",
        "subdivision": "state"
      },
      {
        "code": "NH",
        "name": "New Hampshire",
        "subdivision": "state"
      },
      {
        "code": "NJ",
        "name": "New Jersey",
        "subdivision": "state"
      },
      {
        "code": "NM",
        "name": "New Mexico",
        "subdivision": "state"
      },
      {
        "code": "NY",
        "name": "New York",
        "subdivision": "state"
      },
      {
        "code": "NC",
        "name": "North Carolina",
        "subdivision": "state"
      },
      {
        "code": "ND",
        "name": "North Dakota",
        "subdivision": "state"
      },
      {
        "code": "OH",
        "name": "Ohio",
        "subdivision": "state"
      },
      {
        "code": "OK",
        "name": "Oklahoma",
        "subdivision": "state"
      },
      {
        "code": "OR",
        "name": "Oregon",
        "subdivision": "state"
      },
      {
        "code": "PA",
        "name": "Pennsylvania",
        "subdivision": "state"
      },
      {
        "code": "RI",
        "name": "Rhode Island",
        "subdivision": "state"
      },
      {
        "code": "SC",
        "name": "South Carolina",
        "subdivision": "state"
      },
      {
        "code": "SD",
        "name": "South Dakota",
        "subdivision": "state"
      },
      {
        "code": "TN",
        "name": "Tennessee",
        "subdivision": "state"
      },
      {
        "code": "TX",
        "name": "Texas",
        "subdivision": "state"
      },
      {
        "code": "UT",
        "name": "Utah",
        "subdivision": "state"
      },
      {
        "code": "VT",
        "name": "Vermont",
        "subdivision": "state"
      },
      {
        "code": "VA",
        "name": "Virginia",
        "subdivision": "state"
      },
      {
        "code": "WA",
        "name": "Washington",
        "subdivision": "state"
      },
      {
        "code": "WV",
        "name": "West Virginia",
        "subdivision": "state"
      },
      {
        "code": "WI",
        "name": "Wisconsin",
        "subdivision": "state"
      },
      {
        "code": "WY",
        "name": "Wyoming",
        "subdivision": "state"
      }
    ]
  },
  {
    "code2": "UY",
    "code3": "URY",
    "name": "Uruguay",
    "capital": "Montevideo",
    "region": "Americas",
    "subregion": "South America",
    "states": [
        {
            "name": "Artigas Department",
            "code": "AR"
        },
        {
            "name": "Canelones Department",
            "code": "CA"
        },
        {
            "name": "Cerro Largo Department",
            "code": "CL"
        },
        {
            "name": "Colonia Department",
            "code": "CO"
        },
        {
            "name": "Durazno Department",
            "code": "DU"
        },
        {
            "name": "Flores Department",
            "code": "FS"
        },
        {
            "name": "Florida Department",
            "code": "FD"
        },
        {
            "name": "Lavalleja Department",
            "code": "LA"
        },
        {
            "name": "Maldonado Department",
            "code": "MA"
        },
        {
            "name": "Montevideo Department",
            "code": "MO"
        },
        {
            "name": "Paysandú Department",
            "code": "PA"
        },
        {
            "name": "Río Negro Department",
            "code": "RN"
        },
        {
            "name": "Rivera Department",
            "code": "RV"
        },
        {
            "name": "Rocha Department",
            "code": "RO"
        },
        {
            "name": "Salto Department",
            "code": "SA"
        },
        {
            "name": "San José Department",
            "code": "SJ"
        },
        {
            "name": "Soriano Department",
            "code": "SO"
        },
        {
            "name": "Tacuarembó Department",
            "code": "TA"
        },
        {
            "name": "Treinta y Tres Department",
            "code": "TT"
        }
    ]
  },
  {
    "code2": "UZ",
    "code3": "UZB",
    "name": "Uzbekistan",
    "capital": "Tashkent",
    "region": "Asia",
    "subregion": "Central Asia",
    "states": [
        {
            "name": "Andijan Region",
            "code": "AN"
        },
        {
            "name": "Bukhara Region",
            "code": "BU"
        },
        {
            "name": "Fergana Region",
            "code": "FA"
        },
        {
            "name": "Jizzakh Region",
            "code": "JI"
        },
        {
            "name": "Karakalpakstan",
            "code": "QR"
        },
        {
            "name": "Namangan Region",
            "code": "NG"
        },
        {
            "name": "Navoiy Region",
            "code": "NW"
        },
        {
            "name": "Qashqadaryo Region",
            "code": "QA"
        },
        {
            "name": "Samarqand Region",
            "code": "SA"
        },
        {
            "name": "Sirdaryo Region",
            "code": "SI"
        },
        {
            "name": "Surxondaryo Region",
            "code": "SU"
        },
        {
            "name": "Tashkent",
            "code": "TK"
        },
        {
            "name": "Tashkent Region",
            "code": "TO"
        },
        {
            "name": "Xorazm Region",
            "code": "XO"
        }
    ]
  },
  {
    "code2": "VU",
    "code3": "VUT",
    "name": "Vanuatu",
    "capital": "Port Vila",
    "region": "Oceania",
    "subregion": "Melanesia",
    "states": [
        {
            "name": "Malampa",
            "code": "MAP"
        },
        {
            "name": "Penama",
            "code": "PAM"
        },
        {
            "name": "Sanma",
            "code": "SAM"
        },
        {
            "name": "Shefa",
            "code": "SEE"
        },
        {
            "name": "Tafea",
            "code": "TAE"
        },
        {
            "name": "Torba",
            "code": "TOB"
        }
    ]
  },
  {
    "code2": "VA",
    "code3": "VAT",
    "name": "Vatican City State (Holy See)",
    "capital": "Vatican City",
    "region": "Europe",
    "subregion": "Southern Europe",
    "states": []
  },
  {
    "code2": "VE",
    "code3": "VEN",
    "name": "Venezuela",
    "capital": "Caracas",
    "region": "Americas",
    "subregion": "South America",
    "states": [
        {
            "name": "Amazonas",
            "code": "Z"
        },
        {
            "name": "Anzoátegui",
            "code": "B"
        },
        {
            "name": "Apure",
            "code": "C"
        },
        {
            "name": "Aragua",
            "code": "D"
        },
        {
            "name": "Barinas",
            "code": "E"
        },
        {
            "name": "Bolívar",
            "code": "F"
        },
        {
            "name": "Capital District",
            "code": "A"
        },
        {
            "name": "Carabobo",
            "code": "G"
        },
        {
            "name": "Cojedes",
            "code": "H"
        },
        {
            "name": "Delta Amacuro",
            "code": "Y"
        },
        {
            "name": "Falcón",
            "code": "I"
        },
        {
            "name": "Federal Dependencies of Venezuela",
            "code": "W"
        },
        {
            "name": "Guárico",
            "code": "J"
        },
        {
            "name": "Lara",
            "code": "K"
        },
        {
            "name": "Mérida",
            "code": "L"
        },
        {
            "name": "Miranda",
            "code": "M"
        },
        {
            "name": "Monagas",
            "code": "N"
        },
        {
            "name": "Nueva Esparta",
            "code": "O"
        },
        {
            "name": "Portuguesa",
            "code": "P"
        },
        {
            "name": "Sucre",
            "code": "R"
        },
        {
            "name": "Táchira",
            "code": "S"
        },
        {
            "name": "Trujillo",
            "code": "T"
        },
        {
            "name": "Vargas",
            "code": "X"
        },
        {
            "name": "Yaracuy",
            "code": "U"
        },
        {
            "name": "Zulia",
            "code": "V"
        }
    ]
  },
  {
    "code2": "VN",
    "code3": "VNM",
    "name": "Vietnam",
    "capital": "Hanoi",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "states": [
        {
            "name": "An Giang",
            "code": "44"
        },
        {
            "name": "Bà Rịa-Vũng Tàu",
            "code": "43"
        },
        {
            "name": "Bắc Giang",
            "code": "54"
        },
        {
            "name": "Bắc Kạn",
            "code": "53"
        },
        {
            "name": "Bạc Liêu",
            "code": "55"
        },
        {
            "name": "Bắc Ninh",
            "code": "56"
        },
        {
            "name": "Bến Tre",
            "code": "50"
        },
        {
            "name": "Bình Dương",
            "code": "57"
        },
        {
            "name": "Bình Định",
            "code": "31"
        },
        {
            "name": "Bình Phước",
            "code": "58"
        },
        {
            "name": "Bình Thuận",
            "code": "40"
        },
        {
            "name": "Cà Mau",
            "code": "59"
        },
        {
            "name": "Cao Bằng",
            "code": "04"
        },
        {
            "name": "Da Nang",
            "code": "DN"
        },
        {
            "name": "Đắk Lắk",
            "code": "33"
        },
        {
            "name": "Đắk Nông",
            "code": "72"
        },
        {
            "name": "Điện Biên",
            "code": "71"
        },
        {
            "name": "Đồng Nai",
            "code": "39"
        },
        {
            "name": "Đồng Tháp",
            "code": "45"
        },
        {
            "name": "Gia Lai",
            "code": "30"
        },
        {
            "name": "Hà Giang",
            "code": "03"
        },
        {
            "name": "Hà Nam",
            "code": "63"
        },
        {
            "name": "Hà Tây",
            "code": "15"
        },
        {
            "name": "Hà Tĩnh",
            "code": "23"
        },
        {
            "name": "Hải Dương",
            "code": "61"
        },
        {
            "name": "Haiphong",
            "code": "HP"
        },
        {
            "name": "Hanoi",
            "code": "HN"
        },
        {
            "name": "Hậu Giang",
            "code": "73"
        },
        {
            "name": "Ho Chi Minh City",
            "code": "SG"
        },
        {
            "name": "Hòa Bình",
            "code": "14"
        },
        {
            "name": "Hưng Yên",
            "code": "66"
        },
        {
            "name": "Khánh Hòa",
            "code": "34"
        },
        {
            "name": "Kiên Giang",
            "code": "47"
        },
        {
            "name": "Kon Tum",
            "code": "28"
        },
        {
            "name": "Lai Châu",
            "code": "01"
        },
        {
            "name": "Lâm Đồng",
            "code": "35"
        },
        {
            "name": "Lạng Sơn",
            "code": "09"
        },
        {
            "name": "Lào Cai",
            "code": "02"
        },
        {
            "name": "Long An",
            "code": "41"
        },
        {
            "name": "Nam Định",
            "code": "67"
        },
        {
            "name": "Nghệ An",
            "code": "22"
        },
        {
            "name": "Ninh Bình",
            "code": "18"
        },
        {
            "name": "Ninh Thuận",
            "code": "36"
        },
        {
            "name": "Phú Thọ",
            "code": "68"
        },
        {
            "name": "Phú Yên",
            "code": "32"
        },
        {
            "name": "Quảng Bình",
            "code": "24"
        },
        {
            "name": "Quảng Nam",
            "code": "27"
        },
        {
            "name": "Quảng Ngãi",
            "code": "29"
        },
        {
            "name": "Quảng Ninh",
            "code": "13"
        },
        {
            "name": "Quảng Trị",
            "code": "25"
        },
        {
            "name": "Sóc Trăng",
            "code": "52"
        },
        {
            "name": "Sơn La",
            "code": "05"
        },
        {
            "name": "Tây Ninh",
            "code": "37"
        },
        {
            "name": "Thái Bình",
            "code": "20"
        },
        {
            "name": "Thái Nguyên",
            "code": "69"
        },
        {
            "name": "Thanh Hóa",
            "code": "21"
        },
        {
            "name": "Thừa Thiên-Huế",
            "code": "26"
        },
        {
            "name": "Tiền Giang",
            "code": "46"
        },
        {
            "name": "Trà Vinh",
            "code": "51"
        },
        {
            "name": "Tuyên Quang",
            "code": "07"
        },
        {
            "name": "Vĩnh Long",
            "code": "49"
        },
        {
            "name": "Vĩnh Phúc",
            "code": "70"
        },
        {
            "name": "Yên Bái",
            "code": "06"
        }
    ]
  },
  {
    "code2": "WF",
    "code3": "WLF",
    "name": "Wallis and Futuna",
    "capital": "Mata-Utu",
    "region": "Oceania",
    "subregion": "Polynesia",
    "states": []
  },
  {
    "code2": "EH",
    "code3": "ESH",
    "name": "Western Sahara",
    "capital": "El Aaiún",
    "region": "Africa",
    "subregion": "Northern Africa",
    "states": []
  },
  {
    "code2": "YE",
    "code3": "YEM",
    "name": "Yemen",
    "capital": "Sana'a",
    "region": "Asia",
    "subregion": "Western Asia",
    "states": [
        {
            "name": "'Adan Governorate",
            "code": "AD"
        },
        {
            "name": "'Amran Governorate",
            "code": "AM"
        },
        {
            "name": "Abyan Governorate",
            "code": "AB"
        },
        {
            "name": "Al Bayda' Governorate",
            "code": "BA"
        },
        {
            "name": "Al Hudaydah Governorate",
            "code": "HU"
        },
        {
            "name": "Al Jawf Governorate",
            "code": "JA"
        },
        {
            "id": 1251,
            "name": "Al Mahrah Governorate",
            "code": "MR"
        },
        {
            "name": "Al Mahwit Governorate",
            "code": "MW"
        },
        {
            "name": "Dhamar Governorate",
            "code": "DH"
        },
        {
            "name": "Hadhramaut Governorate",
            "code": "HD"
        },
        {
            "name": "Hajjah Governorate",
            "code": "HJ"
        },
        {
            "name": "Ibb Governorate",
            "code": "IB"
        },
        {
            "name": "Lahij Governorate",
            "code": "LA"
        },
        {
            "name": "Ma'rib Governorate",
            "code": "MA"
        },
        {
            "name": "Raymah Governorate",
            "code": "RA"
        },
        {
            "name": "Saada Governorate",
            "code": "SD"
        },
        {
            "name": "Sana'a",
            "code": "SA"
        },
        {
            "name": "Sana'a Governorate",
            "code": "SN"
        },
        {
            "name": "Shabwah Governorate",
            "code": "SH"
        },
        {
            "name": "Socotra Governorate",
            "code": "SU"
        },
        {
            "name": "Ta'izz Governorate",
            "code": "TA"
        }
    ]
  },
  {
    "code2": "ZM",
    "code3": "ZMB",
    "name": "Zambia",
    "capital": "Lusaka",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "states": [
        {
            "name": "Central Province",
            "code": "02"
        },
        {
            "name": "Copperbelt Province",
            "code": "08"
        },
        {
            "name": "Eastern Province",
            "code": "03"
        },
        {
            "name": "Luapula Province",
            "code": "04"
        },
        {
            "name": "Lusaka Province",
            "code": "09"
        },
        {
            "name": "Muchinga Province",
            "code": "10"
        },
        {
            "name": "Northern Province",
            "code": "05"
        },
        {
            "name": "Northwestern Province",
            "code": "06"
        },
        {
            "name": "Southern Province",
            "code": "07"
        },
        {
            "name": "Western Province",
            "code": "01"
        }
    ]
  },
  {
    "code2": "ZW",
    "code3": "ZWE",
    "name": "Zimbabwe",
    "capital": "Harare",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "states": [
        {
            "name": "Bulawayo Province",
            "code": "BU"
        },
        {
            "name": "Harare Province",
            "code": "HA"
        },
        {
            "name": "Manicaland",
            "code": "MA"
        },
        {
            "name": "Mashonaland Central Province",
            "code": "MC"
        },
        {
            "name": "Mashonaland East Province",
            "code": "ME"
        },
        {
            "name": "Mashonaland West Province",
            "code": "MW"
        },
        {
            "name": "Masvingo Province",
            "code": "MV"
        },
        {
            "name": "Matabeleland North Province",
            "code": "MN"
        },
        {
            "name": "Matabeleland South Province",
            "code": "MS"
        },
        {
            "name": "Midlands Province",
            "code": "MI"
        }
    ]
  }
]

