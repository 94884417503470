import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { StatusIndicator } from '../../../../components/atoms';
import { Text } from './style';

const PaymentStatusCell = ({ status }) => {
  return (
    <Grid alignItems='center' spacing={1} container>
      <Grid item>
        <StatusIndicator status={status === 'Paid' ? 'active' : 'inactive'} />
      </Grid>
      <Grid item>
        <Text>{status}</Text>
      </Grid>
    </Grid>
  );
};

export default PaymentStatusCell;
