import { Grid } from '@material-ui/core';
import styled from 'styled-components';

const ImgGrid = styled(Grid)`
  background-color: #003265;
  padding: 20px;
  width: 48%;
  max-width: 48%;
  height: 170px;
`;

const Img = styled.img`
  max-width: 100%;
  max-height: 130px;
  object-fit: cover;
`;

const Cell = styled(Grid)`
  width: 48%;
`;

export { ImgGrid, Img, Cell };
