/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { put, takeEvery } from 'redux-saga/effects';
import { get, updatePatch, updatePatchForm } from '../axios';
import {
  GET_CLINICIAN_SUCCESS,
  GET_CLINICIAN_FAILED,
  GET_CLINICIAN_REQUEST,
  GET_CLINICIAN_APPOINTMENT_SUCCESS,
  GET_CLINICIAN_APPOINTMENT_FAILED,
  GET_CLINICIAN_APPOINTMENT_REQUEST,
  GET_BY_ID_CLINICIAN_SUCCESS,
  GET_BY_ID_CLINICIAN_FAILED,
  GET_BY_ID_CLINICIAN_REQUEST,
  GET_BY_IDS_CLINICIANS_SUCCESS,
  GET_BY_IDS_CLINICIANS_FAILED,
  GET_BY_IDS_CLINICIANS_REQUEST,
  SAVE_CLINICIAN_SIGNATURE_REQUEST,
  SAVE_CLINICIAN_SIGNATURE_SUCCESS,
  SAVE_CLINICIAN_SIGNATURE_FAILED,
  LOGGEDIN_CLINICIAN_INFO_REQUEST,
  LOGGEDIN_CLINICIAN_INFO_SUCCESS,
  LOGGEDIN_CLINICIAN_INFO_FAILED,
} from '../constant';

function* getClinician(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_CLINICIAN_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_CLINICIAN_FAILED, error });
  }
}

function* getClinicianAppointment(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_CLINICIAN_APPOINTMENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_CLINICIAN_APPOINTMENT_FAILED, error });
  }
}

function* getByIdClinician(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_BY_ID_CLINICIAN_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_BY_ID_CLINICIAN_FAILED, error });
  }
}

function* getCliniciansByIds(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_BY_IDS_CLINICIANS_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_BY_IDS_CLINICIANS_FAILED, error });
  }
}

function* saveClinicianSignature(action) {
  try {
    const { endpoint, body } = action;
    console.log('body', body);
    const response = yield updatePatchForm(endpoint, body);
    yield put({ type: SAVE_CLINICIAN_SIGNATURE_SUCCESS, response });
  } catch (error) {
    yield put({ type: SAVE_CLINICIAN_SIGNATURE_FAILED, error });
  }
}

function* loggedInUserInfo(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: LOGGEDIN_CLINICIAN_INFO_SUCCESS, response });
  } catch (error) {
    yield put({ type: LOGGEDIN_CLINICIAN_INFO_FAILED, error });
  }
}

export function* clinicianSaga() {
  yield takeEvery(GET_CLINICIAN_REQUEST, getClinician);
  yield takeEvery(GET_BY_ID_CLINICIAN_REQUEST, getByIdClinician);
  yield takeEvery(GET_CLINICIAN_APPOINTMENT_REQUEST, getClinicianAppointment);
  yield takeEvery(GET_BY_IDS_CLINICIANS_REQUEST, getCliniciansByIds);
  yield takeEvery(SAVE_CLINICIAN_SIGNATURE_REQUEST, saveClinicianSignature);
  yield takeEvery(LOGGEDIN_CLINICIAN_INFO_REQUEST, loggedInUserInfo);
}
