import { createStyles} from "@material-ui/core";

export default (theme) =>
  createStyles({
    root: {
      //   width: "100%",
      paddingTop: "47px",
      paddingBottom: "47px",
      paddingLeft: "42px",
      paddingRight: "42px",
      backgroundColor: "rgba(192,208,222,18%)",
      marginBottom: "30px",
    },
    headerContainer: {
      marginBottom: "20px",
      height: "38px",
      display: "flex",
      flexDirection: "row",
    },
    headerSubContainer: {
      marginLeft: "10px",
      display: "inline",
    },
    bodyHeaderContainer: {
      marginBottom: "18px",
    },
    bodyImageContainer: {
      position: "absolute",
      right: "92px",
    },
    line: {
      border: "1px solid rgba(112,112,112,50%)",
      marginBottom: "34px",
      marginTop: "35px",
    },
    timeContainer: {
      width: "137px",
      height: "36px",
      backgroundColor: "#ffffff",
      border: "1px solid #304659",
      fontSize: "18px",
      color: "#304659",
      fontWeight: 400,
      fontFamily: "Lato",
      marginRight: "20px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#304659",
        color: "#ffffff",
      },
    },
    calenderEventContainer: {
      width: "500px",
    },
  });
