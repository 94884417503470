import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  formHeading: {
    // position: 'static',
    // width: 'auto',
    textAlign: 'left',
    fontSize: 18,
    fontWeight: 400,
    color: '#003d79',
    fontFamily: 'Open Sans,Helvetica,Arial,sans-serif',
    marginTop: theme.spacing(2),
  },
  divstyle: {
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#eceff1',
    height: 40,
    borderRadius: 5,
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(8),
    color: '#003d79',
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#1976d2 !important',
  },
  firstnameLink: {
    cursor: 'pointer',
    color: '#a29061',
    textDecoration: 'none',
    fontSize: 14,
    fontFamily: '"Open Sans",Helvetica,Arial,sans-serif',
  },
  bottomtext: {
    fontSize: 13,
    color: '#a29061',
  },
  righttextinput: {
    textAlign: 'left',
    paddingTop: theme.spacing(6),
  },
  checkedsty: {
    marginTop: theme.spacing(2),
    color: '#003d79',
  },
  button: {
    marginTop: theme.spacing(2),
    borderRadius: 5,
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 600,
    color: '#003d79',
    borderStyle: 'solid',
    borderColor: '#003d79',
    fontFamily: 'inherit',
  },
});
