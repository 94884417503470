import { makeStyles} from "@material-ui/core/styles";
export default makeStyles((theme) => ({
    root: {
      overflow: "hidden",
      display: "flex",
      position: "relative",
      width: "100%",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: "315px",
      flexShrink: 0,
    },
    drawerPaper: {
      width: "315px",
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: "315px",
      backgroundColor: "#003265",
      opacity: "100%",
    },
  }));