import { makeStyles, createStyles } from "@material-ui/core/styles";


const drawerWidth = '18%';

export default makeStyles((theme) =>
createStyles({
    appMenu: {
        flexGrow: 1,
        height: "100%",
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    navList: {
        width: drawerWidth,
    },
    menuItem: {
        '& .Mui-selected': {
            backgroundColor: "#304659"
        },
        borderRadius: 5,
        height: '100%',
        backgroundColor: "#fff",
        marginLeft: 20,
        marginRight: 20,
        p: {
            fontSize: "18px"
        }
    },
    menuItemIcon: {
        color: "#304659",
        paddingLeft: theme.spacing(2),
    },
    menuText: {
        color: "#304659",
        fontWeight: 600,
        marginBottom: 5,
        display: "flex",
        alignItems: "center",
        fontSize: 14,
        textDecoration: "none",
        height: "60px",
        borderRadius: 5,
        '&:hover': {
            color: "#304659",
            textDecoration: "none"
        },
        p: {
            fontSize: "18px"
        }
    },
    textcolor: {
        color: "#304659",
        fontWeight: 500,
        textTransform: "capitalize",
    },
    defaultimg: {
        width: '100px',
        height: '100px',
        objectFit: "contain",
        borderRadius: "50px"
    },
    primaryBtn: {
        display: 'inline-block',
        padding: '2px',
        borderRadius: '50px',
        width: '100px',
        backgroundColor: '#1b63eb',
        textTransform: 'capitalize',
        boxShadow: 'none',
        backgroundColor: "#1b63eb",
        color: "#ffffff",
    },
    sideTextName: {
        margin: '0px',
        color: "#304659",
        marginBottom: '5px',
    },
    onlineIcon: {
        position: 'absolute',
        right: '18px',
        top: '3px',
    },
    topSidebar: {
        position: 'relative',
        width: '100px',
        display: 'inline-block',
    },
    borderBottom: {
        height: '3px',
        backgroundColor: '#f6f7fb',
        marginLeft: '30px',
        marginTop: '15px',
        marginBottom: "10px",
        marginRight: '30px',
    }
})
);