import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import dayGridPlugin from '@fullcalendar/daygrid';
import list from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import Layout from '../common/AdminLayout';
import { baseURL } from '../../../utils/axios';
import AlertMsg from '../../../utils/Alert';
import { getTimezoneOffset } from '../../../utils/timUtils';
import Axios from 'axios';
import moment from 'moment';
import LinearProgress from '@material-ui/core/LinearProgress';
import ReplicateView from '../../../common/ReplicateView';
import { weekend } from '../../../utils/timUtils';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { slots, getEventColor } from '../../../common/calendar/slots';
import Popover from './Popover';
import tippy, { followCursor } from 'tippy.js';
import ReactDOMServer from 'react-dom/server';
import 'tippy.js/themes/light.css';

const CalendarWrapper = styled.div`
  .fc-timegrid-event-harness-inset {
    inset-inline-start: 0% !important;
    inset-inline-end: 0% !important;
  }

  .fc-timegrid-event-harness {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
`;
function App() {
  const calendarComponentRef = React.createRef();
  const [loader, setLoader] = useState(false);
  const [resources, setResources] = useState([]);
  const [events, setEvents] = useState([]);
  const [openCreateAppointment, setOpenCreateAppointment] = useState(false);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [selectedTime, setSelectedTime] = useState({});
  const [selectedDoctor, setDelectedDoctor] = useState();
  const [appointmentType, setAppointmentType] = useState('');
  const [openReplicate, setOpenReplicate] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState({});

  useEffect(() => {
    getShedules();
  }, [selectedDoctor]);

  useEffect(() => {
    var currentURL = window.location.href;
    var id = currentURL.substring(currentURL.lastIndexOf('/') + 1);
    setDelectedDoctor(id);
  }, []);

  const [legands, setLegands] = useState(slots);

  useEffect(() => {
    const draggableEl = document.getElementById('external-events');
    new Draggable(draggableEl, {
      itemSelector: '.fc-event1',
      eventData(eventEl) {
        const title = eventEl.getAttribute('title');
        const id = eventEl.getAttribute('data');
        return {
          title,
          id,
          color: getEventColor(title),
          type: title,
        };
      },
    });
  }, []);

  useEffect(() => {
    getShedules();
  }, [selectedDoctors]);

  const handleeventRecieve = (eventDropInfo) => {
    handleeventDrop(eventDropInfo);
  };

  const validateTime = (startTime, endTime) => {
    if (
      (moment(startTime).utcOffset(240) >= moment('00:00:00', 'hh:mm:ss') &&
        moment(startTime).utcOffset(240) < moment('06:00:00', 'hh:mm:ss')) ||
      (moment(startTime).utcOffset(240) >= moment('22:00:00', 'hh:mm:ss') &&
        moment(startTime).utcOffset(240) < moment('23:59:59', 'hh:mm:ss'))
    ) {
      return false;
    }

    if (
      moment(endTime).utcOffset(240) >= moment('22:00:00', 'hh:mm:ss') &&
      moment(endTime).utcOffset(240) < moment('23:59:59', 'hh:mm:ss')
    ) {
      return false;
    }

    return true;
  };

  const handleeventDrop = (eventDropInfo) => {
    var newEvents = [];

    const isTimeValid = validateTime(
      eventDropInfo.event.start,
      eventDropInfo.event.end
    );

    if (!isTimeValid) {
      AlertMsg(
        'error',
        'Start time can not lie between 12am - 6am and 10pm - 12am'
      );
      eventDropInfo.event.remove();
      return;
    }

    if (weekend.includes(moment(eventDropInfo.event.start).format('dddd'))) {
      AlertMsg('error', 'Cannot be booked for weekend');
      eventDropInfo.event.remove();
      return;
    }

    const eventInfo = {
      start: eventDropInfo.event.start,
      end: eventDropInfo.event.end,
      title: eventDropInfo.event.title,
      resourceId: parseInt(eventDropInfo.event._def.resourceIds[0]),
      type: eventDropInfo.event.title,
      id: eventDropInfo.event.id,
    };
    eventDropInfo.event.remove();
    const newEvent = Object.values(events).filter((event) => {
      if (event.id === eventInfo.id) {
        return event;
      }
    });
    console.log(newEvent);
    if (newEvent && newEvent.length === 0) {
      newEvents.push({ ...eventInfo, color: getEventColor(eventInfo.type) });
    } else {
      newEvents = newEvent.map((event) => {
        const evnt =
          event.id === eventInfo.id
            ? {
                ...event,
                start: eventInfo.start,
                end: eventInfo.end,
                id: eventInfo.id,
                type: eventInfo.type,
                color: getEventColor(eventInfo.type),
              }
            : event;
        return evnt;
      });
    }
    console.log(newEvents);
    addUpdateNewSchedules(
      parseInt(eventDropInfo.event._def.resourceIds[0]),
      newEvents
    );
  };

  const handleeventResize = (eventDropInfo) => {
    handleeventDrop(eventDropInfo);
  };

  const addUpdateNewSchedules = (resourceId, newEvents) => {
    let clinicianId = selectedDoctor;
    const data = newEvents.map((item) => {
      let end;
      if (item.end === null) {
        const today = new Date(item.start);
        today.setHours(today.getHours() + 1);
        end = new Date(today);
      } else {
        end = new Date(item.end);
      }
      return {
        type: item.type,
        color: getEventColor(item.type),
        startTime: new Date(item.start), // moment(item.start).tz("UTC").toISOString(true),
        endTime: end, // moment(item.end).tz("UTC").toISOString(true),
        appointmentDuration: 60,
        soloDate: new Date(item.start),
        id: item.id,
        clinicianId: clinicianId,
      };
    });
    setLoader(true);
    Axios.post(`${baseURL}/api/schedule/updateMultipleSchedules`, [...data], {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        // this.getShedules();
        let temp = { ...events };
        if (res.data && res.data.length) {
          for (const item of res.data) {
            const start = new Date(item.startTime);
            const end = new Date(item.endTime);

            // console.log("item.type ======", item.type);
            const obj = {
              start,
              end,
              title: item.type,
              type: item.type,
              id: item._id,
              color: item.type == 'New-Patient' ? '#FFAA39' : '#00D5CB',
              clinicianId: clinicianId,
              resourceId: resourceId,
              color: getEventColor(item.type),
            };

            const newEvent = Object.values(events).filter((event) => {
              if (event.id === obj.id) {
                return event;
              }
            });

            if (newEvent && newEvent.length === 0) {
              temp[obj.id] = obj;
            } else {
              newEvent.forEach((newEvent) => {
                temp[newEvent.id] = {
                  ...events[newEvent.id],
                  start: obj.start,
                  clinicianId: clinicianId,
                  end: obj.end,
                  id: obj.id,
                  type: obj.type,
                  resourceId: resourceId,
                  color: getEventColor(obj.type),
                  textColor:
                    obj.type === 'Appointment' || obj.type === 'Blocked'
                      ? '#fff'
                      : '#000',
                  client: obj.client,
                };
              });
            }
          }
          setEvents(temp);
          setLoader(false);
        }
      })
      .catch((e) => {
        setLoader(false);
        if (e.response.data.reason) {
          AlertMsg('error', e.response.data.reason);
        }
      });
  };

  const getCalendarData = async (
    fetchInfo,
    successCallback,
    failureCallback
  ) => {
    try {
      let start = moment(fetchInfo.start).format('MM/DD/YYYY');
      let end = moment(fetchInfo.end).format('MM/DD/YYYY');

      var url = `${baseURL}/api/schedule/get?id=${[
        selectedDoctor,
      ]}&timezoneOffset=${getTimezoneOffset()}`;

      if (start) {
        url = url + `&start=${start}`;
      }

      if (end) {
        url = url + `&end=${end}`;
      }

      let res = await Axios.get(url, {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      });

      if (!res.data) {
        failureCallback([]);
        return;
      }

      const sortedList = res.data.sort(function (a, b) {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
          return -1;
        }
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const resourcesList = [];
      let eventsList = [];
      sortedList.forEach((item, itemIndex) => {
        const resource = {
          id: itemIndex,
          title: item.firstName + ' ' + item.lastName,
          imageUrl: item.avatar,
          clinicianId: item._id,
        };
        item.events.forEach((event, index) => {
          let evtData = {
            resourceId: itemIndex,
            title: event.title,
            start: event.start,
            end: event.end,
            index: `evt-${index}`,
            clinicianId: item._id,
            id: event._id,
            color: getEventColor(event.type),
            type: event.type,
            textColor:
              event.type === 'Appointment' || event.type === 'Blocked'
                ? '#fff'
                : '#000',
            client: event.client,
          };
          eventsList.push(evtData);
        });
        resourcesList.push(resource);
      });

      successCallback(eventsList);
    } catch (error) {
      failureCallback([]);
    }
  };

  const getShedules = () => {
    setLoader(true);
    Axios.get(
      `${baseURL}/api/schedule/get?id=${[
        selectedDoctor,
      ]}&timezoneOffset=${getTimezoneOffset()}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        if (res.data && res.data.length) {
          const resourcesList = [];
          let eventsList = {};
          res.data.forEach((item, itemIndex) => {
            const resource = {
              id: itemIndex,
              title: item.firstName + ' ' + item.lastName,
              imageUrl: item.avatar,
              clinicianId: item._id,
            };
            item.events.forEach((event, index) => {
              let evtData = {
                resourceId: itemIndex,
                title: event.title,
                start: event.start,
                end: event.end,
                //index: `evt-${index}`,
                clinicianId: item._id,
                id: event._id,
                color: getEventColor(event.type),
                type: event.type,
                textColor:
                  event.type === 'Appointment' || event.type === 'Blocked'
                    ? '#fff'
                    : '#000',
                client: event.client,
              };
              // eventsList.push(evtData)
              eventsList[evtData.id] = evtData;
            });
            resourcesList.push(resource);
          });
          setResources(resourcesList);
          setEvents(eventsList);
          // this.setState({ calendarEvents: scheduleList, newEvents: scheduleList });
        }
        setLoader(false);
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const eventClicked = (eventClickInfo, event) => {
    // console.log(eventClickInfo.event, eventClickInfo.event._def.extendedProps.clinicianId, eventClickInfo.event._def.extendedProps.type)
    // setAppointmentType({ type: eventClickInfo.event._def.extendedProps.type, clinicianId: eventClickInfo.event._def.extendedProps.clinicianId, calenderSlotId: eventClickInfo.event.id })
    // setDelectedDoctor(eventClickInfo.event._def.extendedProps.clinicianId)
    // setSelectedTime({ start: eventClickInfo.event.start, end: eventClickInfo.event.end })

    // if (eventClickInfo.event.title == "Current-Patient" || eventClickInfo.event.title == "New-Patient" || eventClickInfo.event.title == "All-Patient") {
    //     setOpenCreateAppointment(true)
    // }

    // if (eventClickInfo.event._def.extendedProps.type == 'Appointment') {
    //     console.log(eventClickInfo.event, eventClickInfo.event._def.extendedProps.clinicianId)
    //     setAppointmentId(eventClickInfo.event.id)
    //     setOpenAppointmentDetails(true)
    // }

    if (eventClickInfo.event._def.extendedProps.type != 'Appointment') {
      console.log(
        eventClickInfo.event,
        eventClickInfo.event._def.extendedProps
      );
      // setAppointmentId(eventClickInfo.event.id)
      setSelectedBlock({
        appointmentType: eventClickInfo.event.id,
        clinicianId: eventClickInfo.event._def.extendedProps.clinicianId,
        startTime: eventClickInfo.event.start,
        endTime: eventClickInfo.event.end,
        type: eventClickInfo.event._def.extendedProps.type,
        calenderSlotId: eventClickInfo.event.id,
      });
      setOpenReplicate(true);
    }
  };

  const handleCreateClose = () => {
    setOpenCreateAppointment(false);
  };

  const renderEventContent = (info) => {
    return <div>{info.event.title}</div>;
  };

  var instance = null;
  const handleMouseEnter = (arg) => {
    if (arg.event._def.extendedProps.type == 'Appointment') {
      if (instance) {
        instance.destroy();
      }

      instance = tippy(arg.el, {
        content: ReactDOMServer.renderToStaticMarkup(
          <Popover
            {...arg.event._def.extendedProps}
            start={arg.event.start}
            end={arg.event.end}
          />
        ),
        strategy: 'fixed',
        allowHTML: true,
        placement: 'left',
        interactive: true,
        theme: 'light',
        zIndex: 999999999,
        followCursor: true,
        plugins: [followCursor],
      });
    }
  };

  return (
    <>
      {loader && <LinearProgress />}
      <Typography variant='h5'>
        {resources && resources.length ? resources[0].title : ''}
      </Typography>
      <br />
      <div className='calendarMain flex-col'>
        <div id='external-events' className='calendarBtns'>
          <div className='shadows legends'>
            <h5>Calendar Slot Legends</h5>
            <br />
            <ul>
              {legands.map((event) => (
                <li
                  className='fc-event1'
                  title={event.title}
                  data={event.id}
                  key={event.id}
                >
                  {event.title}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className='calendarOuter mt-4'>
          <CalendarWrapper className='demo-app-calendar'>
            <FullCalendar
              schedulerLicenseKey='0258088407-fcs-1613958667'
              eventResize={handleeventResize}
              eventReceive={handleeventRecieve}
              eventDrop={handleeventDrop}
              defaultView={'timeGridWeek'}
              initialView={'timeGridWeek'}
              headerToolbar={{
                left: 'prev,next',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay',
              }}
              rerenderDelay={0}
              eventDurationEditable
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                list,
                interactionPlugin,
                timelinePlugin,
                resourceTimeGridPlugin,
              ]}
              resourceLabelText={'Doctors'}
              events={getCalendarData}
              ref={calendarComponentRef}
              eventAdd={(event) => console.log(event)}
              eventClick={eventClicked}
              slotMinTime='06:00:00'
              slotMaxTime='22:00:00'
              contentHeight='auto'
              eventOverlap={false}
              eventMouseEnter={handleMouseEnter}
              editable
              selectable
              draggable
              droppable
              allDaySlot={false}
              nowIndicator
            />
          </CalendarWrapper>
        </div>
      </div>
      <ReplicateView
        handleSubmit={() => {
          setOpenReplicate(false);
          getShedules();
        }}
        selectedBlock={selectedBlock}
        open={openReplicate}
        close={() => {
          setOpenReplicate(false);
        }}
      />
    </>
  );
}

export default App;
