import { Button, TextField, Typography, FormControl, MenuItem, Select } from '@material-ui/core'
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import React from 'react'
import DateFnsUtils from '@date-io/date-fns';
import SimpleDialog from '../../components/atoms/Dialog/Dialog'
import moment from 'moment'
import { dateTime } from '../../utils/timUtils'
import { Alert } from '@material-ui/lab'

const types = [
  'Team Meeting',
  'Support Group',
  'Book Club',
  'Group Consultation',
  'Team Time',
  'Supervision',
  'Admin',
  'Other',
]

export default function CalendarSlotSelectionView(props) {

  const [start, setStart] = React.useState(null)
  const [end, setEnd] = React.useState(null)
  const [selectedSlot, setSelectedSlot] = React.useState(null)
  const [type, setType] = React.useState("select")
  const [errorMessage, setErrorMessage] = React.useState("")
  const [isOther, setIsOther] = React.useState(false)
  //flex-col

  React.useEffect(() => {
    console.log(props.selectedSlot)
    if (props.selectedSlot) {
      setSelectedSlot(props.selectedSlot)
      setStart(props.selectedSlot.start)
      setEnd(moment(props.selectedSlot.start).add(1, 'hour'))
    }
  }, [props])

  React.useEffect(() => {
    setType("select")
    setIsOther(false)
  }, [])


  const handleChange = (e) => {
    if (e.target.value == "Other") {
      setIsOther(true)
      return
    }

    setIsOther(false)
    setErrorMessage("")
    setType(e.target.value)
  }

  const handleSubmit = () => {
    if (type == "select" || type == "") {
      setErrorMessage("Please select a type")
      return
    }

    if (props.handleSubmit) {
      props.handleSubmit({ ...selectedSlot, start: start, end: end, type: type })
    }
  }

  return (
    <SimpleDialog
      open={props.open && props.open}
      handleClose={props.handleClose && props.handleClose}
    >
      {errorMessage != "" && <Alert severity='error'>{errorMessage}</Alert>}
      <div className='flex flex-col p-6 space-y-6 '>
        <div className="flex flex-row w-full text-lg font-bold">
          Type
        </div>
        <div className="flex w-full">
          <FormControl style={{ width: "100%" }}>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              name='type'
              value={types.includes(type) || type == "select" ? type : "Other"}
              onChange={handleChange}
            >
              <MenuItem value={'select'}>Select Type</MenuItem>
              {types.map(t => <MenuItem value={t}>{t}</MenuItem>)}
            </Select>
          </FormControl>
        </div>
        {isOther && <TextField
          id="type"
          type="text"
          onChange={(e) => {
            setType(e.target.value)
          }}
        />}
        <div className="space-x-6" >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
              value={start}
              clearable
              ampm={false}
              label="Start"
              onChange={(e) => setStart(e)}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
              value={end}
              clearable
              ampm={false}
              label="End"
              onChange={(e) => setEnd(e)}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
      <div className='flex flex-row p-6 space-x-6 float-right'>
        <button className="flex-grow p-2 rounded-lg bg-red-500 text-white	" onClick={props.handleClose && props.handleClose}> Cancel </button>
        <button className="flex-grow bg-green-500 p-2 rounded-lg text-white	" onClick={handleSubmit}> Create </button>
      </div>
    </SimpleDialog >
  )
}
