import React, { Component } from "react";
import HTMLParser from "html-react-parser";
import { withStyles, Grid, Typography } from "@material-ui/core";
import {
  DrawerTitleFont,
  DrawerNameFont,
  DrawerReferredFont,
  DrawerReferredClinicianFont,
} from "../../common/DrawerContent/DrawerFont";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getClinicialAddressById } from "../../../utils/actions/clinician_address.action";
import { DrawerBoxImg } from "../../common/DrawerContent/DrawerImage";
import logo1 from "../../../assets/imgs/ananyaBig.png";
import cal1 from "../../../assets/imgs/Path 84.svg";
import cal2 from "../../../assets/imgs/Rectangle 40.svg";
import cal3 from "../../../assets/imgs/Rectangle 42.svg";
import cal4 from "../../../assets/imgs/Rectangle 39.svg";
import watch1 from "../../../assets/imgs/Path 85.svg";
import watch2 from "../../../assets/imgs/Path 86.svg";
import location1 from "../../../assets/imgs/Path 87.svg";
import location2 from "../../../assets/imgs/Path 88.svg";
import schedule1 from "../../../assets/imgs/Path 112.svg";
import schedule2 from "../../../assets/imgs/Path 113.svg";
import schedule3 from "../../../assets/imgs/Path 126.svg";
import print1 from "../../../assets/imgs/Path 138.svg";
import print2 from "../../../assets/imgs/Path 137.svg";
import print3 from "../../../assets/imgs/Path 139.svg";
import print4 from "../../../assets/imgs/Path 140.svg";
import print5 from "../../../assets/imgs/Path 141.svg";
import recipt1 from "../../../assets/imgs/Path 128.svg";
import recipt2 from "../../../assets/imgs/Path 127.svg";
import recipt3 from "../../../assets/imgs/Path 129.svg";
import recipt4 from "../../../assets/imgs/Path 130.svg";
import recipt5 from "../../../assets/imgs/Path 131.svg";
import recipt6 from "../../../assets/imgs/Path 132.svg";
import arrowDown from "../../../assets/imgs/Border (2).svg";
import dayjs from "dayjs";
import Axios from "axios";
import { baseURL } from "../../../utils/axios";
import styles from "./PreviousAppointment_style";

class PreviousAppointment extends Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    const { _id } = data;
    this.state = { appointmentDetails: {} };
    Axios.get(`${baseURL}/api/appointment/detailbyClient/${_id}`, {
      headers: {
        Authorization: localStorage.getItem("ACCESS_TOKEN_PATH"),
      },
    })
      .then((res) => {
        if (res.data) {
          this.setState({ appointmentDetails: res.data });
        }
      })
      .catch(() => {});
  }

  componentDidMount() {
    const {
      data: {
        clinicianId: { address },
      },
    } = this.props;

    this.props.getClinicialAddressById(address);
  }

  render() {
    const { classes, data, address } = this.props;
    const { clinicianId, startTime, duration } = data;
    const { appointmentDetails } = this.state;

    return (
      <div style={{ padding: "50px" }}>
        <DrawerTitleFont
          text="Previous Appointment"
          style={{ marginBottom: "21px" }}
        />
        <Grid
          container
          alignItems="center"
          justify="flex-start"
          className={classes.root}
        >
          <Grid style={{ display: "flex", width: "50%" }}>
            <DrawerBoxImg logo={logo1} />
            <Grid style={{ marginLeft: "16px" }}>
              <DrawerNameFont
                text={
                  clinicianId
                    ? `Dr ${clinicianId.firstName} ${clinicianId.lastName}`
                    : "--"
                }
              />
              <Grid>
                <Grid
                  justify="flex-start"
                  alignItems="center"
                  container
                  style={{ marginTop: "14px" }}
                >
                  <Grid>
                    <img src={cal1} alt="cal1" />
                    <img src={cal2} className={classes.cal1} alt="cal2" />
                    <img src={cal3} className={classes.cal2} alt="cal3" />
                    <img src={cal4} className={classes.cal3} alt="cal4" />
                  </Grid>
                  <DrawerReferredFont
                    text={dayjs(startTime).format("DD/MM/YYYY")}
                    style={{ marginLeft: "5px" }}
                  />
                </Grid>

                <Grid justify="flex-start" alignItems="center" container>
                  <Grid>
                    <img src={watch1} alt="watch1" />
                    <img src={watch2} className={classes.watch1} alt="watch2" />
                  </Grid>
                  <DrawerReferredFont
                    text={`${dayjs(startTime).format(
                      "HH:MM"
                    )} for ${duration} min.`}
                    style={{ marginLeft: "5px" }}
                  />
                </Grid>

                <Grid
                  justify="flex-start"
                  alignItems="flex-start"
                  wrap="nowrap"
                  container
                >
                  <Grid>
                    <img src={location1} alt="location1" />
                    <img
                      src={location2}
                      className={classes.location1}
                      alt="location2"
                    />
                  </Grid>
                  <DrawerReferredFont
                    text={address}
                    style={{ marginLeft: "5px" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid style={{ display: "flex", width: "50%" }}>
            <DrawerBoxImg logo={logo1} />
            <Grid style={{ marginLeft: "16px" }}>
              <DrawerNameFont text="You" />
              <Grid>
                <Grid
                  justify="flex-start"
                  alignItems="center"
                  container
                  style={{ marginTop: "14px" }}
                >
                  <DrawerReferredFont
                    text="Follow-up Appointmnet"
                    style={{ marginLeft: "5px" }}
                  />
                </Grid>
                <Grid justify="flex-start" alignItems="center" container>
                  <DrawerReferredFont
                    text="Attended"
                    style={{ marginLeft: "5px" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          style={{ marginTop: "57px", marginBottom: "29px" }}
          wrap="nowrap"
          alignItems="flex-start"
          container
        >
          <Grid
            alignItems="flex-start"
            justify="flex-start"
            wrap="nowrap"
            style={{ cursor: "pointer", flexBasis: "45%" }}
            container
          >
            <Grid style={{ marginTop: "3px", marginRight: "7px" }}>
              <img src={schedule1} alt="schedule1" />
              <img
                src={schedule2}
                className={classes.schedule1}
                alt="schedule2"
              />
              <img
                src={schedule3}
                className={classes.schedule2}
                alt="schedule3"
              />
            </Grid>
            <DrawerReferredClinicianFont
              text="Schedule follow-up"
              style={{ color: "#003265" }}
            />
          </Grid>

          <Grid
            alignItems="center"
            justify="flex-start"
            container
            style={{ cursor: "pointer", flexBasis: "25%" }}
          >
            <Grid style={{ marginTop: "3px", marginRight: "7px" }}>
              <img src={print1} alt="print1" />
              <img src={print2} className={classes.print1} alt="print2" />
              <img src={print3} className={classes.print2} alt="print3" />
              <img src={print4} className={classes.print3} alt="print4" />
              <img src={print5} className={classes.print4} alt="print5" />
            </Grid>
            <DrawerReferredClinicianFont
              text="Print"
              style={{ color: "#003265" }}
            />
          </Grid>

          <Grid
            alignItems="center"
            justify="flex-start"
            container
            style={{ cursor: "pointer", flexBasis: "30%" }}
          >
            <Grid style={{ marginTop: "3px", marginRight: "7px" }}>
              <img src={recipt1} alt="recipt1" />
              <img src={recipt2} className={classes.print1} alt="recipt2" />
              <img src={recipt3} className={classes.reciept1} alt="recipt3" />
              <img src={recipt4} className={classes.reciept2} alt="recipt4" />
              <img src={recipt5} className={classes.reciept3} alt="recipt5" />
              <img src={recipt6} className={classes.reciept4} alt="recipt6" />
            </Grid>
            <DrawerReferredClinicianFont
              text="Get A Receipt"
              style={{ color: "#003265" }}
            />
          </Grid>
        </Grid>

        <Grid className={classes.line} />
        {appointmentDetails.patientRecord &&
          appointmentDetails.patientRecord.length && (
            <Grid style={{ marginTop: "26px", marginBottom: "26px" }}>
              <Typography className={classes.diagnoTitle}>
                PATIENT DIAGNOSIS
              </Typography>
              <Typography
                className={classes.diagnoTitle}
                style={{ fontWeight: 400 }}
              >
                {appointmentDetails.patientRecord &&
                appointmentDetails.patientRecord.length &&
                appointmentDetails.patientRecord[0].notes
                  ? HTMLParser(appointmentDetails.patientRecord[0].notes)
                  : "N/A"}
              </Typography>
              <Typography
                className={classes.diagnoTitle}
                style={{ fontWeight: 400 }}
              >
                Your diagnosis today is:{" "}
                {appointmentDetails.patientRecord &&
                appointmentDetails.patientRecord.length &&
                appointmentDetails.patientRecord[0].diagnosis
                  ? appointmentDetails.patientRecord[0].diagnosis
                  : "N/A"}
              </Typography>
              <Grid style={{ display: "flex" }}>
                <Typography
                  className={classes.diagnoTitle}
                  style={{ color: "#003265", cursor: "pointer" }}
                >
                  View complete visit notes
                </Typography>
                <img
                  src={arrowDown}
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                  alt="arrowDown"
                />
              </Grid>
            </Grid>
          )}
        <Grid className={classes.line} />
        {appointmentDetails.patientRecord &&
          appointmentDetails.patientRecord.length &&
          appointmentDetails.patientRecord[0].prescriptions[0].length && (
            <Grid style={{ marginTop: "26px", marginBottom: "26px" }}>
              <Typography className={classes.orderTitle}>
                Prescriptions ordered
              </Typography>
              <Typography
                className={classes.diagnoTitle}
                style={{ color: "rgba(34,34,34,75%)" }}
              >
                {appointmentDetails.patientRecord &&
                appointmentDetails.patientRecord.length &&
                appointmentDetails.patientRecord[0].prescriptions[0].length &&
                appointmentDetails.patientRecord[0].prescriptions[0][0]
                  .medication
                  ? appointmentDetails.patientRecord[0].prescriptions[0][0]
                      .medication
                  : "N/A"}
              </Typography>
              <Typography
                className={classes.diagnoTitle}
                style={{ color: "rgba(34,34,34,75%)", fontWeight: 400 }}
              >
                Quantity:{" "}
                {appointmentDetails.patientRecord &&
                appointmentDetails.patientRecord.length &&
                appointmentDetails.patientRecord[0].prescriptions &&
                appointmentDetails.patientRecord[0].prescriptions[0][0].dose
                  ? appointmentDetails.patientRecord[0].prescriptions[0][0].dose
                  : 0}
                , Refills:{" "}
                {appointmentDetails.patientRecord &&
                appointmentDetails.patientRecord.length &&
                appointmentDetails.patientRecord[0].prescriptions &&
                appointmentDetails.patientRecord[0].prescriptions[0][0]
                  .refillAllowed
                  ? appointmentDetails.patientRecord[0].prescriptions[0][0]
                      .refillAllowed
                  : 0}
              </Typography>
              <Typography
                className={classes.diagnoTitle}
                style={{ color: "rgba(34,34,34,75%)", fontWeight: 400 }}
              >
                Directions: 1 tablet each day for 30 days
              </Typography>
            </Grid>
          )}

        <Grid className={classes.line} />

        {appointmentDetails.pharmacy && appointmentDetails.pharmacy.length && (
          <Grid style={{ marginTop: "26px", marginBottom: "26px" }}>
            <Typography className={classes.orderTitle}>
              Selected Pharmacy
            </Typography>
            <Typography
              className={classes.diagnoTitle}
              style={{ color: "rgba(34,34,34,75%)" }}
            ></Typography>
            <Typography
              className={classes.diagnoTitle}
              style={{ color: "rgba(34,34,34,75%)", fontWeight: 400 }}
            ></Typography>
            <Typography
              className={classes.diagnoTitle}
              style={{ color: "rgba(34,34,34,75%)", fontWeight: 400 }}
            ></Typography>
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  address: state.ClinicianAddressReducer.address,
});

PreviousAppointment.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    cal1: PropTypes.string.isRequired,
    cal2: PropTypes.string.isRequired,
    cal3: PropTypes.string.isRequired,
    watch1: PropTypes.string.isRequired,
    watch2: PropTypes.string.isRequired,
    location1: PropTypes.string.isRequired,
    location2: PropTypes.string.isRequired,
    schedule1: PropTypes.string.isRequired,
    schedule2: PropTypes.string.isRequired,
    schedule3: PropTypes.string.isRequired,
    print1: PropTypes.string.isRequired,
    print2: PropTypes.string.isRequired,
    print3: PropTypes.string.isRequired,
    print4: PropTypes.string.isRequired,
    print5: PropTypes.string.isRequired,
    reciept1: PropTypes.string.isRequired,
    reciept2: PropTypes.string.isRequired,
    reciept3: PropTypes.string.isRequired,
    reciept4: PropTypes.string.isRequired,
    line: PropTypes.string.isRequired,
    orderTitle: PropTypes.string.isRequired,
    diagnoTitle: PropTypes.string.isRequired,
  }).isRequired,
  address: PropTypes.string.isRequired,
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    clinicianId: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
    }),
    startTime: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
  }).isRequired,
  getClinicialAddressById: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { getClinicialAddressById })(
  withStyles(styles)(PreviousAppointment)
);
