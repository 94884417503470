import { createStyles } from "@material-ui/core";

export default (theme) =>
  createStyles({
    root: {
      backgroundColor: "#cfd9df",
      width: "100%",
      height: "100%",
      opacity: "20%",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    titleContainer: {
      position: "absolute",
      // width: '800px',
      zIndex: 1,
      [theme.breakpoints.up("xs")]: {
        top: "150px",
      },
      [theme.breakpoints.up("md")]: {
        top: "202px",
      },
    },
    container: {
      position: "absolute",
      // width: '400px',
      zIndex: 1,
      [theme.breakpoints.up("xs")]: {
        width: "800px",
        top: "80px",
        bottom: "80px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "800px",
        top: "90px",
        bottom: "80px",

      },
      [theme.breakpoints.up("md")]: {
        width: "800px",
        top: "132px",
        bottom: "80px",

      },
    },
    bodyContainer: {
      marginTop: "22px",
      justifyContent: "center",
      alignSelf: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
    title: {
      height: "25px",
      // fontSize: '32px',
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: "700",
      marginTop: "30px",
      [theme.breakpoints.up("xs")]: {
        fontSize: "20px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "25px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "32px",
      },
    },
    subtitle: {
      height: "60px",
      // fontSize: '25px',
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: "400",
      textAlign: "center",
      justifyContent: "center",
      marginTop: "19px",
      [theme.breakpoints.up("xs")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "20px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "25px",
      },
    },
    searchConatiner: {
      display: "flex",
      justifyContent: "row",
      float: "left",
      position: "absolute",
    },
    search: {
      // width: '400px',
      height: "40px",
      backgroundColor: "#ffffff",
      border: "1px",
      borderStyle: "solid",
      borderColor: "#ffffff",
      cursor: "text",
      padding: ("10px", "13px"),
      fontSize: "13px",
      lineHeight: "20px",
      color: "#8298ab",
      fontFamily: "Lato",
      fontWeight: "400",
      marginTop: "32px",
      outline: 0,
      [theme.breakpoints.up("xs")]: {
        width: "300px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "300px",
      },
      [theme.breakpoints.up("md")]: {
        width: "400px",
      },
    },
    searchIcon: {
      width: "11px",
      height: "11px",
      borderRadius: "6px",
      marginTop: "50px",
    },
    exmple: {
      fontSize: "15px",
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: "400",
      textAlign: "start",
      opacity: "50%",
      height: "15px",
      marginTop: "10px",
    },
    text: {
      // fontSize: '25px',
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: "400",
      textAlign: "center",
      marginTop: "23px",
      cursor: "pointer",
      [theme.breakpoints.up("xs")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "20px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "25px",
      },
    },
    subtext: {
      // fontSize: '20px',
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: "400",
      textAlign: "center",
      opacity: "75%",
      [theme.breakpoints.up("xs")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "20px",
      },
    },
    bottomtext: {
      // fontSize: '20px',
      color: "#304659",
      fontFamily: "Lato",
      fontWeight: "700",
      textAlign: "center",
      textDecoration: "underline",
      marginTop: "39px",
      cursor: "pointer",
      [theme.breakpoints.up("xs")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "20px",
      },
    },
    arrow: {
      width: "13px",
      height: "13px",
      color: "#304659",
      marginLeft: "10px",
      marginTop: "39px",
    },
    divider: {
      [theme.breakpoints.up("xs")]: {
        width: "300px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "300px",
      },
      [theme.breakpoints.up("md")]: {
        width: "400px",
      },
    },
    logosty: {
      [theme.breakpoints.up("xs")]: {
        width: "80px",
        height: "100px",
      },
      [theme.breakpoints.up("md")]: {
        width: "142px",
        height: "165px",
      },
    },
  });