/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { KeyBindingUtil } from 'draft-js';
import { CustomImage, CustomDots } from './TableImageContainer';
import { TableNameFont, TableDescFont } from './TableFont';
import { TableButtons } from './TableButtons';
import { Loader } from '../user/Loader';
import styles from './TableBody.styles';
import moment from 'moment';
const customDateFormat = (date) => {
  const dateStr = `${`00${date.getDate()}`.slice(-2)}-${`00${
    date.getMonth() + 1
  }`.slice(-2)}-${date.getFullYear()} ${`00${date.getHours()}`.slice(
    -2
  )}:${`00${date.getMinutes()}`.slice(-2)}`;
  return dateStr;
};
const renderTime = (record) => {
  if (
    record.status === 'waiting' ||
    record.status === 'reffered' ||
    record.status === 'reffered-waiting'
  ) {
    return moment(record.createdAt).fromNow();
  } else {
    return moment(new Date(record.billingId.createdAt)).format('LLL');
  }
};
export const TableBody = ({ data, onRowSelect, isLoader, ...props }) => {
  const classes = styles();
  return (
    <>
      {isLoader ? (
        <Grid
          container
          alignItems='center'
          justify='flex-start'
          style={{ marginTop: '10px' }}
        >
          <Loader />
        </Grid>
      ) : !data.length > 0 ? (
        <Grid
          container
          alignItems='center'
          justify='flex-start'
          className={classes.root}
        >
          There is no data
        </Grid>
      ) : (
        data.map((node, i) => {
          return (
            <Grid
              key={i}
              container
              alignItems='center'
              justify='flex-start'
              className={classes.root}
              // onClick={() => onRowSelect(true)}
              {...props}
              wrap='nowrap'
            >
              <Grid
                className={classes.container}
                style={{ width: node.status === 'waiting' ? '14%' : '15%' }}
              >
                <TableDescFont
                  text={
                    node.status === 'waiting' ? node._id : node.billingId._id
                  }
                  style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                />
              </Grid>

              <Grid
                className={classes.container}
                alignItems='center'
                justify='flex-start'
                style={{ width: '10%' }}
              >
                {node.refClinicianImage ? (
                  <>
                    <CustomImage logo={node.refClinicianImage} />
                    <TableNameFont
                      style={{
                        marginLeft: '11px',
                        width: '111px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        display: 'inline',
                        textAlign: 'left',
                      }}
                      text={node.refClinician}
                    />
                  </>
                ) : (
                  <TableDescFont text='---' />
                )}
              </Grid>
              <Grid
                className={classes.container}
                style={{ width: node.status === 'waiting' ? '20%' : '10%' }}
                justify='flex-start'
                container
              >
                <CustomImage logo={node.patient} />
                <div className='ml-1'>{node.patientName}</div>
              </Grid>
              <Grid
                className={classes.container}
                style={{
                  width: '5%',
                  textTransform: 'capitalize',
                }}
              >
                <TableDescFont text={node.age} />
              </Grid>
              <Grid
                className={classes.container}
                style={{
                  width: node.status === 'waiting' ? '17%' : '14%',
                  textTransform: 'capitalize',
                }}
              >
                <TableDescFont text={renderTime(node)} />
              </Grid>
              {node.status !== 'waiting' && (
                <Grid
                  className={classes.container}
                  style={{ width: '15%' }}
                  justify='flex-start'
                  container
                >
                  <TableDescFont text={node.type} />
                </Grid>
              )}
              {node.status !== 'waiting' && (
                <Grid
                  className={classes.container}
                  style={{ width: '8%' }}
                  justify='flex-start'
                  container
                >
                  <TableDescFont text={node.location} />
                </Grid>
              )}

              {node.status !== 'waiting' && (
                <Grid
                  className={classes.container}
                  alignItems='center'
                  justify='flex-start'
                  style={{ width: '15%' }}
                >
                  <CustomDots
                    bgColor={
                      node.paymentStatus === 'Paid' ? '#00bc07' : '#db1919'
                    }
                  />
                  <TableDescFont
                    text={node.paymentStatus}
                    style={{ marginLeft: '11px' }}
                  />
                </Grid>
              )}

              <Grid
                className={classes.container}
                style={{ width: '15%' }}
                justify='flex-start'
                container
              >
                <TableDescFont text={node.riskScore} />
              </Grid>

              <Grid className={classes.container} style={{ width: '18%' }}>
                <TableButtons
                  onClick={() => onRowSelect(node)}
                  style={{
                    cursor: 'pointer',

                    backgroundColor:
                      node.actionType === 'blue'
                        ? '#304659'
                        : node.actionType === 'no'
                        ? '#db1919'
                        : '#c0d0de',
                  }}
                  title={node.action}
                  bgColor={
                    node.actionType === 'blue'
                      ? '#304659'
                      : node.actionType === 'no'
                      ? '#db1919'
                      : '#c0d0de'
                  }
                  textColor={
                    node.actionType === 'blue'
                      ? '#ffffff'
                      : node.actionType === 'no'
                      ? '#ffffff'
                      : 'rgba(48, 70, 89, 50%)'
                  }
                  {...props}
                />
              </Grid>
            </Grid>
          );
        })
      )}
    </>
  );
};

TableBody.defaultProps = {
  onRowSelect: () => {},
  isLoader: true,
};
