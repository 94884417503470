import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getEventsAction,
  getClientEvents,
} from '../../utils/actions/events.action';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import userIcon from '../../assets/imgs/download.png';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Drawer, Grid, Typography, Button } from '@material-ui/core';
import '../clinician.scss';
import addBtn from '../../assets/imgs/db-image/add.png';
import editBtn from '../../assets/imgs/db-image/edit.png';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Fab from '@material-ui/core/Fab';
import Axios from 'axios';
import { baseURL } from '../../utils/axios';
import Loader from '../../user/Loader';
import SliderPointLine from '../../assets/imgs/slider verticle line.svg';
import SliderPointIcon from '../../assets/imgs/slider point.svg';
import FilterModalBox from '../FilterModalBox';
import closeImg from '../../assets/imgs/close.jpeg';
import '../clinician.scss';
import EventDetail from './../../Patient/components/Events/EventDetail';
import useStyles from './patientDetails.styles';
import ProofOfAddressModel from '../../common/ProofOfAddressModel';
import AlertMsg from '../../utils/Alert';
import ProofOfIdentityModel from '../../common/ProofOfIdentityModel';
import { LocalConvenienceStoreOutlined } from '@material-ui/icons';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Spinner } from '../../components/atoms';
import SMSModalBox from '../../admin/components/SMS/SMSModalBox';
import Avatar from '@material-ui/core/Avatar';
import { LOCATION, STATUSES, APPOINTMENT_TYPES } from '../../utils/enums';
import { calculateAge } from '../../utils/timUtils';
import PatientConsentForm from './PatientConsentForm';
import NotesView from '../../common/Notes/NotesView';
import CommentsView from '../../containers/billing/billing_list/InvoicesDetails/CommentsView';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  tabValue: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const PatientDetailClinician = ({
  patientDrawer,
  patientId,
  togglePatientDrawer,
  getEventsAction,
  clientEvents,
  getClientEvents,
  isAdmin,
  uploadDocFileAction,
  showEventViewBtn,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [eventId, setEventId] = useState('');
  const classes = useStyles();
  const [tabValue, setValue] = React.useState(0);
  const [isLoaded, setIsloaded] = React.useState(true);
  const [patientDetails, setPatientDetails] = React.useState([]);
  const [appointmentDetails, setAppointmentDetails] = React.useState([]);
  const [documents, setDocuments] = React.useState([]);
  const [clinicianDetails, setClinicianData] = React.useState([]);
  const [detailDrawer, setDetailDrawer] = React.useState(false);
  const [smsDrawer, setSmsDrawer] = React.useState(false);
  const [appointmentId, setAppointmentId] = React.useState('');
  const [prevCount, setPrevCount] = React.useState(0);
  const [upcomingCount, setUpcomingCount] = React.useState(0);
  const [openProofOfAddress, setOpenProofOfAddress] = React.useState(false);
  const [selectedDocument, setSelectedDocument] = React.useState(null);
  const [openProofOfIdentity, setOpenProofOfIdentity] = React.useState(false);
  const [uploadedDocument, setUploadedDocument] = React.useState(null);
  const [creditData, setCreditData] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    var clientId = '';
    if (window.location.pathname) {
      clientId = window.location.pathname.substring(
        window.location.pathname.lastIndexOf('/') + 1
      );
    }

    if (patientId) {
      getPatientDetails(patientId);
      getClientEvents(patientId);
    }
  }, [patientId]);

  const getPatientDetails = (clientId) => {
    var uid = localStorage.getItem('clinicianUID');
    if (clientId) {
      setIsloaded(true);
      Axios.get(`${baseURL}/api/client/getPatientDetail?clientId=${clientId}`, {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      })
        .then((res) => {
          let data = res.data;
          if (res.data) {
            setDocuments(data.documents);
            setPatientDetails(data.clientData);
            setAppointmentDetails(data.Appointments);
            setPrevCount(data.prevCount);
            setUpcomingCount(data.upcomingCount);
            setCreditData(data.credit)
            setClinicianData(data.clinicianData ? data.clinicianData : []);
            setIsloaded(false);
          }
        })
        .catch((e) => {
          console.log('eeeeeeee', e);
        });
    }
  };

  const getClinicianDetails = (clientId) => {
    if (clientId) {
      setIsloaded(true);
      var uid = localStorage.getItem('clinicianUID');
      Axios.get(
        `${baseURL}/api/client/getPatient_DoctorList?clientId=${clientId}`,
        {
          headers: {
            Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
          },
        }
      )
        .then((res) => {
          setClinicianData(res.data ? res.data : []);
          setIsloaded(false);
        })
        .catch((e) => {
          console.log('eeeeeeee', e);
        });
    }
  };

  const handleSubmitRequest = (params) => {
    // console.log("vale", params)
    // let axiosConfig = {
    //     headers: {
    //         'Authorization': localStorage.getItem('ACCESS_TOKEN_PATH')
    //     }
    // };
    // Axios.post(`${baseURL}/api/filter`, params, axiosConfig).then(res => {
    //     setFilterDrawer(false);
    //     setFilterId(res.data._id);
    //     getAppointments(res.data._id);
    //     // setAppointmentList(res.data)
    // }).catch(e => {
    //     console.log("eeeeeeee", e)
    //   });
  };

  const GetFormattedDate = (date) => {
    var todayTime = new Date(date);
    var month = todayTime.getMonth() + 1;
    var day = todayTime.getDate();
    var year = todayTime.getFullYear();
    if (date === undefined) return '--';
    return month + '/' + day + '/' + year;
  };

  const handleCircle = (status) => {
    var color = 'yellow';
    if (status === 'confirmed') {
      color = 'green';
    } else if (status == 'pending') {
      color = 'red';
    }
    return (
      <div
        style={{
          width: '15px',
          height: '15px',
          backgroundColor: color,
          borderRadius: '50%',
        }}
      ></div>
    );
  };

  const handleSubmitAddress = (data) => {
    setOpenProofOfAddress(false);
    if (data.file) {
      docFileUpload({ ...data, type: 'proofOfAddress' });
    } else if (data._id) {
      const postData = { ...data };
      postData.documentId = postData.documentId._id;
      postData.clientId = patientId;
      delete postData.fileData;
      delete postData.errors;
      postProofOfAddress(postData);
      setSelectedDocument(null);
    }
  };

  const handleDeleteAddress = (data) => {};

  const postProofOfAddress = (data) => {
    setIsloaded(true);
    const token = localStorage.getItem('ACCESS_TOKEN_PATH');
    Axios.post(baseURL + '/api/user/proofOfAddress', data, {
      headers: {
        Authorization: token,
      },
    })
      .then((data) => {
        setIsloaded(false);
        if (data.data) {
          getPatientDetails(patientId);
        }
      })
      .catch((e) => {
        AlertMsg('error', e.message);
        setIsloaded(false);
      });
  };

  const handleSubmitIdentity = (data) => {
    setOpenProofOfIdentity(false);
    if (data.file || data.backsideImage) {
      const postData = { ...data };
      delete postData.fileData;
      delete postData.backsideImageBase64;
      delete postData.errors;

      docFileUpload({ ...postData, type: 'proofOfIdentity' });
    } else if (data._id) {
      const postData = { ...data };
      postData.documentId = postData.documentId._id;
      postData.clientId = patientId;
      delete postData.fileData;
      delete postData.backsideImageBase64;
      delete postData.errors;
      postProofOfIdentity(postData);
      setSelectedDocument(null);
    }
  };

  const handleDeleteIdentity = (data) => {};

  const postProofOfIdentity = (data) => {
    setIsloaded(true);
    const token = localStorage.getItem('ACCESS_TOKEN_PATH');
    const verifiedBy = localStorage.getItem('clinicianUID');

    Axios.post(
      baseURL + '/api/user/proofOfIdentity',
      { ...data, verifiedBy },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((data) => {
        setIsloaded(false);
        if (data.data) {
          getPatientDetails(patientId);
        }
      })
      .catch((e) => {
        AlertMsg('error', e.message);
        setIsloaded(false);
      });
  };

  const docFileUpload = (postData_) => {
    const formData = new FormData();

    formData.append('document', postData_.file);
    formData.append('backsideImage', postData_.backsideImage);
    setIsloaded(true);
    const docType =
      postData_.type === 'proofOfIdentity' ? 'proofOfIdentity' : 'document';
    const token = localStorage.getItem('ACCESS_TOKEN_PATH');
    Axios.post(
      `${baseURL}/api/user/upload?clientId=${patientId}&type=${docType}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: token,
        },
      }
    )
      .then((data) => {
        setUploadedDocument(data.data);
        setIsloaded(false);
        const postData = { ...postData_ };
        postData.documentId = data.data._id;
        postData.clientId = patientId;
        delete postData.fileData;
        delete postData.errors;
        delete postData.file;
        delete postData.backsideImage;

        if (postData.type == 'proofOfIdentity') {
          postProofOfIdentity(postData);
        } else if (postData.type == 'proofOfAddress') {
          postProofOfAddress(postData);
        }
        setSelectedDocument(null);
      })
      .catch((e) => {
        setIsloaded(false);
      });
  };

  const handleDetailDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDetailDrawer(open);
  };

  const handleSmsDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setSmsDrawer(open);
  };
  const getDate = (date) => {
    const event = new Date(date);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return event.toLocaleDateString('en-US', options);
  };

  const renderTime = (record) => {
    if (
      record.status === 'waiting' ||
      record.status === 'reffered' ||
      record.status === 'reffered-waiting'
    ) {
      return moment(new Date(record.createdAt)).format('LL');
    } else {
      return moment(new Date(record.startTime)).format('LLL');
    }
  };

  return (
    <>
      <div>
        <SwipeableDrawer
          className='custom-swipable-drawer'
          anchor={'right'}
          open={patientDrawer}
          onClose={togglePatientDrawer(false)}
        >
          <div>
            {isLoaded ? (
              <Spinner isOpen />
            ) : (
              <>
                <div className='patientDetailMain'>
                  <div className='leftCard'>
                    <img
                      src={
                        patientDetails.avatar ? patientDetails.avatar : userIcon
                      }
                    />
                    <h4 className='patientName'>
                      {patientDetails.firstName} {patientDetails.lastName}
                    </h4>
                    <a className='email'>{patientDetails.email}</a>
                    <div className='options'>
                      <img src={addBtn} />
                      <img src={editBtn} />
                    </div>
                    <div className='counts'>
                      <div>
                        <h4 className='count'>{prevCount}</h4>
                        <p className='label'>Past</p>
                      </div>
                      <div>
                        <h4 className='count'>{upcomingCount}</h4>
                        <p className='label'>Upcoming</p>
                      </div>
                    </div>
                    <button
                      style={{ marginRight: '15px' }}
                      className='sendBtn'
                      onClick={() => {
                        window.location.href = `mailto:${patientDetails.email}`;
                      }}
                    >
                      Email
                    </button>
                    <button
                      className='sendBtn'
                      onClick={() => {
                        setSmsDrawer(true);
                      }}
                    >
                      SMS
                    </button>
                  </div>
                  <div className='rightCard'>
                    {/* <span className="closePage" onClick={redirectBack}>CLOSE <i class="fas fa-window-close"></i></span> */}
                    <div className='moreInfo'>
                      <div>
                        <h4>Chart Number</h4>
                        <p>{patientDetails.chartId}</p>
                      </div>
                      <div>
                        <h4>Gender</h4>
                        <p>
                          {patientDetails.gender ? patientDetails.gender : '--'}
                        </p>
                      </div>
                      <div>
                        <h4>Birthday</h4>
                        <p>{getDate(patientDetails.dob)}</p>
                      </div>
                      <div>
                        <h4>Marital Status</h4>
                        <p>
                          {patientDetails.maritalStatus
                            ? patientDetails.maritalStatus
                            : '--'}
                        </p>
                      </div>
                      <div>
                        <h4>Phone Number</h4>
                        <p>{patientDetails.phoneNumber}</p>
                      </div>
                      <div className='address'>
                        <h4>Address</h4>
                        <p>
                          {patientDetails.address &&
                          patientDetails.address.address1 &&
                          patientDetails.address.address1
                            ? patientDetails.address.address1
                            : ''}{' '}
                          {patientDetails.address &&
                          patientDetails.address.address2 &&
                          patientDetails.address.address2
                            ? patientDetails.address.address2
                            : ''}
                          ,{' '}
                          {patientDetails.address &&
                          patientDetails.address.city &&
                          patientDetails.address.city
                            ? patientDetails.address.city
                            : ''}
                          ,{' '}
                          {patientDetails.address &&
                          patientDetails.address.state &&
                          patientDetails.address.state
                            ? patientDetails.address.state.name
                            : ''}
                          ,{' '}
                          {patientDetails.address &&
                          patientDetails.address.country &&
                          patientDetails.address.country
                            ? patientDetails.address.country
                            : ''}
                        </p>
                      </div>
                      <div>
                        <h4>Profile Status</h4>
                        <p>{patientDetails.status}</p>
                      </div>
                    </div>
                    <div>
                      Wallet: {Number(creditData?.amount).toFixed(2)} AED
                    </div>
                    {/* TODO: Hidden for later */}
                    {/* <div>
                      <Grid container>
                        <Grid xs={6} style={{ marginTop: 10 }}>
                          <Typography
                            style={{
                              fontSize: '10px',
                              color: '#222222',
                              textAlign: 'left',
                            }}
                          >
                            0
                          </Typography>
                        </Grid>
                        <Grid xs={6} style={{ marginTop: 10 }}>
                          <Typography
                            style={{
                              float: 'right',
                              fontSize: '10px',
                              color: '#222222',
                            }}
                          >
                            33
                          </Typography>
                        </Grid>
                      </Grid>
                      <div className={classes.sliderContainer}>
                        <div
                          className={classes.sliderPointContainer2}
                          style={{
                            marginLeft:
                              (
                                ((patientDetails.riskScore
                                  ? parseInt(patientDetails.riskScore)
                                  : 0) /
                                  33) *
                                99
                              ).toString() + '%',
                          }}
                        >
                          <img
                            src={SliderPointIcon}
                            className={classes.sliderPoint}
                          />
                          <Typography className={classes.sliderPointText}>
                            {patientDetails.riskScore
                              ? parseInt(patientDetails.riskScore)
                              : 0}
                          </Typography>
                          <img
                            src={SliderPointLine}
                            className={classes.sliderPointLine}
                          />
                        </div>
                      </div>
                      <div
                        className='cumulativeRisk'
                        style={{ marginTop: 10 }}
                      ></div>
                    </div> */}
                  </div>
                  <div
                    style={{
                      float: 'right',
                      color: '#003265',
                      cursor: 'pointer',
                    }}
                    onClick={togglePatientDrawer(false)}
                  >
                    <img src={closeImg} />
                  </div>
                </div>
                <div className='tabsMain'>
                  <AppBar position='static' color='default'>
                    <Tabs
                      value={tabValue}
                      onChange={handleChange}
                      indicatorColor='primary'
                      textColor='primary'
                      variant='scrollable'
                      scrollButtons='auto'
                      aria-label='scrollable auto tabs example'
                    >
                      <Tab label='Notes' {...a11yProps(0)} />
                      <Tab label='Appoinment' {...a11yProps(1)} />
                      <Tab label='Doctors' {...a11yProps(2)} />
                      <Tab label='Events' {...a11yProps(3)} />
                      <Tab label='Files/Docs' {...a11yProps(4)} />
                      <Tab label='Consent Forms' {...a11yProps(5)} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={tabValue} index={0}>
                    <CommentsView clientId={patientDetails._id} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <Grid container>
                      <TableContainer style={{ maxHeight: 500 }}>
                        <Table
                          className={classes.table}
                          stickyHeader
                          aria-label='sticky table'
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>APPOINTMENT ID</TableCell>
                              <TableCell>CLINICIAN(S)</TableCell>
                              <TableCell>AGE</TableCell>
                              <TableCell>DATE TIME</TableCell>
                              <TableCell>TYPE</TableCell>
                              <TableCell>STATUS</TableCell>
                              <TableCell>DURATION</TableCell>
                              <TableCell>LOCATION</TableCell>
                              <TableCell align='right'>ACTION</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody component={Paper}>
                            {appointmentDetails &&
                            appointmentDetails.length > 0 ? (
                              appointmentDetails.map((row, i) => (
                                <TableRow key={i}>
                                  <TableCell component='th' scope='row'>
                                    {row.appointmentId}
                                  </TableCell>
                                  <TableCell>
                                    {row.clinicianId ? (
                                      <Grid
                                        alignItems='center'
                                        spacing={1}
                                        container
                                      >
                                        <Grid item>
                                          <Avatar
                                            style={{
                                              width: '32px',
                                              height: '32px',
                                            }}
                                            src={row.clinicianId.avatar}
                                            alt={row.appointmentId}
                                          />
                                        </Grid>
                                        <Grid item>
                                          <Typography>{`${row.clinicianId.firstName} ${row.clinicianId.lastName}`}</Typography>
                                        </Grid>
                                      </Grid>
                                    ) : (
                                      ''
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {calculateAge(new Date(row.clientId.dob))}
                                  </TableCell>
                                  <TableCell>{renderTime(row)}</TableCell>
                                  <TableCell>
                                    {row.appointmentType?.type}
                                  </TableCell>
                                  <TableCell>{STATUSES[row.status]}</TableCell>
                                  <TableCell>
                                    {row.duration ? row.duration : '--'}
                                  </TableCell>
                                  <TableCell>
                                    {LOCATION[row.appointmentLocation]}
                                  </TableCell>
                                  <TableCell align='right'>
                                    <Button
                                      variant='contained'
                                      className={classes.viewBtn}
                                      onClick={() => {
                                        setDetailDrawer(true);
                                        setAppointmentId(row.appointmentId);
                                      }}
                                    >
                                      View
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow key={1}>
                                <TableCell
                                  component='th'
                                  style={{ textAlign: 'center' }}
                                  colSpan={6}
                                  scope='row'
                                >
                                  No Data Found
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tabValue} index={2}>
                    <Grid container spacing={3}>
                      {clinicianDetails && clinicianDetails.length > 0 ? (
                        clinicianDetails.map((doctor, i) => (
                          <Grid key={`testId-${i}`} sm={6} lg={3} item>
                            <Grid className='doctorCard'>
                              <img
                                src={doctor.avatar ? doctor.avatar : userIcon}
                              />
                              <h4>
                                {doctor.firstName} {doctor.lastName}
                              </h4>
                              <p>{doctor.email}</p>
                              <Fab
                                variant='extended'
                                size='small'
                                color='primary'
                                className={classes.margin}
                                style={{ pointerEvents: 'none' }}
                              >
                                Clinical Psychologist
                              </Fab>
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <p>No Data Found</p>
                      )}
                    </Grid>
                  </TabPanel>

                  <TabPanel value={tabValue} index={3}>
                    {clientEvents && clientEvents.length > 0 ? (
                      <Grid container className='patientDetailEventTable'>
                        <TableContainer style={{ maxHeight: 500 }}>
                          <Table
                            className={classes.table}
                            stickyHeader
                            aria-label='sticky table'
                          >
                            <TableBody component={Paper}>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    component={'p'}
                                    className='headNames'
                                  >
                                    Topic
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    component={'p'}
                                    className='headNames'
                                  >
                                    Date
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    component={'p'}
                                    className='headNames'
                                  >
                                    Venue
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    component={'p'}
                                    className='headNames'
                                  >
                                    Clinician(s)
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    component={'p'}
                                    className='headNames'
                                  >
                                    Seats Available
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    component={'p'}
                                    className='headNames'
                                  >
                                    Event Type
                                  </Typography>
                                </TableCell>
                                <TableCell></TableCell>
                              </TableRow>

                              {clientEvents.map((event, i) => (
                                <TableRow key={`testId-${i}`}>
                                  <TableCell>
                                    <Typography
                                      component={'p'}
                                      className='topicDetail'
                                    >
                                      {event.title}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography component={'h4'}>
                                      {moment(event.startTime).format(
                                        'MM/DD/YYYY'
                                      )}
                                    </Typography>
                                    <Typography component={'p'} className=''>
                                      {`${moment(event.startTime).format(
                                        'hh:mm a'
                                      )} - ${moment(event.endTime).format(
                                        'hh:mm a'
                                      )}`}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography component={'h4'}>
                                      {event.location.type}
                                    </Typography>
                                    <Typography component={'p'} className=''>
                                      {event.location.address}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Grid className={'images'}>
                                      <img
                                        src={
                                          event.clinicians &&
                                          event.clinicians[0] != undefined
                                            ? event.clinicians[0].avatar
                                            : userIcon
                                        }
                                      />
                                    </Grid>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      component={'h4'}
                                      className={'seatsAvail'}
                                    >
                                      {event.registrationStillOpen
                                        ? 'RSVP'
                                        : 'Seat Reserved'}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography component={'h4'}>
                                      {event.type}
                                    </Typography>
                                    <Typography component={'p'} className=''>
                                      {event.category}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align='right'>
                                    {showEventViewBtn && (
                                      <Button
                                        variant='contained'
                                        onClick={() => setIsOpen(true)}
                                        className={classes.viewBtn}
                                      >
                                        View
                                      </Button>
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    ) : (
                      <TableRow key={1}>
                        <TableCell
                          component='th'
                          style={{ textAlign: 'center' }}
                          colSpan={6}
                          scope='row'
                        >
                          No upcoming events are scheduled right now!
                        </TableCell>
                      </TableRow>
                    )}
                  </TabPanel>
                  <TabPanel value={tabValue} index={4}>
                    <Grid container>
                      <Grid xs={6}></Grid>
                      <Grid xs={6}>
                        <div style={{ float: 'right' }}>
                          {false && (
                            <Button
                              variant='contained'
                              className={classes.btnNewApp}
                              onClick={() => {
                                setSelectedDocument(null);
                                setOpenProofOfAddress(true);
                              }}
                            >
                              <AddCircleOutlineIcon
                                style={{ marginRight: 5 }}
                              />
                              Proof Of Address
                            </Button>
                          )}

                          <Button
                            variant='contained'
                            className={classes.btnNewApp}
                            onClick={() => {
                              setSelectedDocument(null);
                              setOpenProofOfIdentity(true);
                            }}
                            style={{ marginLeft: '16px' }}
                          >
                            <AddCircleOutlineIcon style={{ marginRight: 5 }} />
                            Proof Of Identity
                          </Button>
                        </div>
                      </Grid>
                      <TableContainer style={{ maxHeight: 500 }}>
                        <Table
                          className={classes.table}
                          stickyHeader
                          aria-label='sticky table'
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>ID</TableCell>
                              <TableCell>TYPE</TableCell>
                              <TableCell>LAST UPDATING DATE</TableCell>
                              <TableCell>VERIFIED BY</TableCell>
                              <TableCell>VERIFICATION DATE</TableCell>
                              <TableCell align='right'>ACTION</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody component={Paper}>
                            {documents && documents.length > 0 ? (
                              documents.map((row, i) => (
                                <TableRow key={i}>
                                  <TableCell component='th' scope='row'>
                                    {parseInt(i) + 1}
                                  </TableCell>
                                  <TableCell>{row.type}</TableCell>
                                  <TableCell>
                                    {GetFormattedDate(row.updatedAt)}
                                  </TableCell>
                                  <TableCell>
                                    {row.documentId.verifiedBy
                                      ? `${row.documentId.verifiedBy.firstName} ${row.documentId.verifiedBy.lastName} (${row.documentId.verifiedBy.email})`
                                      : ''}
                                  </TableCell>
                                  <TableCell>
                                    {row.documentId.verificationDate
                                      ? GetFormattedDate(
                                          row.documentId.verificationDate
                                        )
                                      : ''}
                                  </TableCell>
                                  <TableCell align='right'>
                                    {/* <Button
                                      variant='contained'
                                      className={classes.viewBtn}
                                      onClick={() => {
                                        // downoad
                                        download(row.url);
                                      }}
                                    >
                                      Edit
                                    </Button> */}
                                    <Button
                                      variant='contained'
                                      className={classes.viewBtn}
                                      style={
                                        row.verified
                                          ? {
                                              background: 'green',
                                              color: 'white',
                                            }
                                          : {}
                                      }
                                      onClick={() => {
                                        // downoad
                                        // download(row.url);
                                        if (row.type == 'proofOfAddress') {
                                          postProofOfAddress({
                                            ...row,
                                            verified: !row.verified,
                                          });
                                        } else if (
                                          row.type == 'proofOfIdentity'
                                        ) {
                                          postProofOfIdentity({
                                            ...row,
                                            verified: !row.verified,
                                          });
                                        }
                                      }}
                                    >
                                      Verify
                                    </Button>
                                    <Button
                                      variant='contained'
                                      className={classes.viewBtn}
                                      onClick={() => {
                                        if (row.type == 'proofOfAddress') {
                                          setSelectedDocument({
                                            ...row,
                                            type: 'proofOfAddress',
                                          });
                                          setOpenProofOfAddress(true);
                                        } else if (
                                          row.type == 'proofOfIdentity'
                                        ) {
                                          setOpenProofOfIdentity(true);
                                          setSelectedDocument({
                                            ...row,
                                            type: 'proofOfIdentity',
                                          });
                                        }
                                      }}
                                    >
                                      View/Edit
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow key={1}>
                                <TableCell
                                  component='th'
                                  style={{ textAlign: 'center' }}
                                  colSpan={6}
                                  scope='row'
                                >
                                  No Data Found
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tabValue} index={5}>
                    <PatientConsentForm clientId={patientDetails._id} />
                  </TabPanel>
                </div>
              </>
            )}
          </div>
        </SwipeableDrawer>
      </div>
      <Drawer anchor='right' open={isOpen} onClose={() => setIsOpen(false)}>
        <EventDetail eventId={eventId} />
      </Drawer>

      <ProofOfAddressModel
        //key={new Date().getTime()}
        open={openProofOfAddress}
        value={selectedDocument}
        handleClose={() => {
          setOpenProofOfAddress(false);
          console.log('close');
        }}
        handleSubmit={handleSubmitAddress}
      />

      <ProofOfIdentityModel
        key={new Date().getTime()}
        open={openProofOfIdentity}
        value={selectedDocument}
        handleClose={() => setOpenProofOfIdentity(false)}
        handleSubmit={handleSubmitIdentity}
      />

      <FilterModalBox
        handleDetailDrawer={handleDetailDrawer}
        appointmentId={appointmentId}
        detailDrawer={detailDrawer}
        handleSubmitRequest={handleSubmitRequest}
        setDetailDrawer={setDetailDrawer}
        detailsOnPatient={true}
      />

      {smsDrawer && (
        <SMSModalBox
          handleSmsDrawer={handleSmsDrawer}
          smsDrawer={smsDrawer}
          setSmsDrawer={setSmsDrawer}
          phoneNumber={patientDetails.phoneNumber}
        />
      )}
    </>
  );
};

PatientDetailClinician.defaultProps = {
  showEventViewBtn: true,
};

PatientDetailClinician.propTypes = {
  patientDrawer: PropTypes.bool,
  patientId: PropTypes.string.isRequired,
  togglePatientDrawer: PropTypes.func.isRequired,
  getEventsAction: PropTypes.func.isRequired,
  clientEvents: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  clientEvents: state.getClientEvents.clientEvents,
});

export default connect(mapStateToProps, { getEventsAction, getClientEvents })(
  PatientDetailClinician
);
