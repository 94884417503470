import {
  CREATE_BUNDLE_REQUEST,
  GET_BUNDLES_REQUEST,
  UPDATE_BUNDLE_REQUEST,
  CANCEL_BUNDLE_REQUEST,
  GET_CLIENT_BUNDLES_REQUEST,
} from '../constant';

export const addBundle = (body) => ({
  type: CREATE_BUNDLE_REQUEST,
  endpoint: '/admin/bundle',
  body,
});

export const getAllBundles = (page, sortType, sortBy, searchText, status) => ({
  type: GET_BUNDLES_REQUEST,
  endpoint: `/admin/bundle?page=${page}&sortType=${sortType}&sortBy=${sortBy}&searchText=${searchText}&status=${status}`,
});

export const modifyBundle = (body) => ({
  type: UPDATE_BUNDLE_REQUEST,
  endpoint: '/admin/bundle',
  body,
});

export const cancelSubscripton = (body) => ({
  type: CANCEL_BUNDLE_REQUEST,
  endpoint: '/admin/bundle/cancel-subscription',
  body,
});

export const getClientBundles = (page, sortType, sortBy) => ({
  type: GET_CLIENT_BUNDLES_REQUEST,
  endpoint: `/api/client/bundle?page=${page}&sortType=${sortType}&sortBy=${sortBy}`,
});
