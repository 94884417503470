import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

const Input = styled.input`
  border: 1px solid #e5e5e5;
  border-radius: 0px;
  height: 45px;
  width: 100%;
  background-color: ${(props) => props.bgcolor};
  color: ${(props) => props.color};
  padding-left: 5px;
`;

const Label = styled(Typography)`
  text-align: left;
  fontsize: 13px;
  color: grey;
  font-weight: 500;
  margin-bottom: 4px;
`;

const Error = styled.span`
  color: red;
  font-family: Lato;
  font-weight: 400;
  font-size: 16px;
`;

const Required = styled.span`
  color: red;
`;

export { Input, Label, Error, Required };
