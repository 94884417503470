import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import reducers from "../reducers/index";

export const sagaMiddleware = createSagaMiddleware();
const composeSetup =
  process.env.REACT_APP_ENV !== "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
export const configureStore = createStore(
  reducers,
  composeSetup(applyMiddleware(sagaMiddleware))
);

export default { sagaMiddleware, configureStore };
