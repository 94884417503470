import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import styled from 'styled-components';

const DatePicker = styled(KeyboardDatePicker)`
  width: 100%;
  height: 45px;
  border: 1px solid #e5e5e5;
  border-radius: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  & .MuiInput-root {
    padding: 6px;
  }
`;

export { DatePicker };
