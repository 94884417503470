/* eslint-disable no-unused-vars */
/* eslint-disable quotes */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
import {
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILED,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILED,
  GET_USER_BY_ID_REQUEST,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAILED,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCESS,
  DELETE_USER_REQUEST,
} from "../AdminConstent";

const initialState = {
  request: true,
  error: false,
};

export const addUser = (state = [], action) => {
  switch (action.type) {
    case ADD_USER_REQUEST:
      return {
        request: true,
      };
    case ADD_USER_SUCCESS:
      return {
        request: false,
        addUserData: action.response,
      };
    case ADD_USER_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getUser = (state = [], action) => {
  switch (action.type) {
    case GET_USER_REQUEST:
      return {
        request: true,
      };
    case GET_USER_SUCCESS:
      return {
        request: false,
        getUserData: action.response,
      };
    case GET_USER_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getByIdUser = (state = [], action) => {
  switch (action.type) {
    case GET_USER_BY_ID_REQUEST:
      return {
        request: true,
      };
    case GET_USER_BY_ID_SUCCESS:
      return {
        request: false,
        getByIdUserData: action.response,
      };
    case GET_USER_BY_ID_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const updateUser = (state = [], action) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      return {
        request: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        request: false,
        updateUserData: action.response,
      };
    case UPDATE_USER_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const deleteUser = (state = [], action) => {
  switch (action.type) {
    case DELETE_USER_REQUEST:
      return {
        request: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        request: false,
        deleteUserData: action.response,
      };
    case DELETE_USER_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};
