/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import * as React from "react";
import { Grid, Typography } from "@material-ui/core";
import styles from "./TableHeader_style";

export const TableHeader = ({ ...props }) => {
  const classes = styles();
  return (
    <Grid
      // component="div"
      // container
      // alignItems="center"
      // justify="space-around"
      className={classes.root}
      {...props}
    >
    <Grid style={{ width: "15%" }} container justify="flex-start">
      <Typography className={classes.font}>Invoices Number</Typography>
    </Grid>
    <Grid style={{ width: "15%" }} container justify="flex-start">
      <Typography className={classes.font}>Invoices Date</Typography>
    </Grid>

      <Grid style={{ width: "15%" }} container justify="flex-start">
        <Typography className={classes.font}>Clinician</Typography>
      </Grid>
      <Grid style={{ width: "10%" }} container justify="flex-start">
        <Typography className={classes.font}>Patient</Typography>
      </Grid>
      <Grid style={{ width: "15%" }} container justify="flex-start">
        <Typography className={classes.font}>Appointment Type</Typography>
      </Grid>
      <Grid style={{ width: "8%" }} container justify="flex-start">
        <Typography className={classes.font}>Location</Typography>
      </Grid>
      <Grid style={{ width: "15%" }} container justify="flex-start">
        <Typography className={classes.font}>Payment Status</Typography>
      </Grid>
      <Grid style={{ width: "18%" }} container justify="flex-start">
        <Typography className={classes.font}>Action</Typography>
      </Grid>
    </Grid>
  );
};
