/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable import/prefer-default-export */
import {
  ADD_USER_REQUEST,
  GET_USER_REQUEST,
  GET_USER_BY_ID_REQUEST,
  UPDATE_USER_REQUEST,
  DELETE_USER_REQUEST,
} from "../AdminConstent";

export const addUserAction = (data) => ({
  type: ADD_USER_REQUEST,
  endpoint: "/admin/user",
  body: data,
});

export const getUserAction = () => ({
  type: GET_USER_REQUEST,
  endpoint: "/admin/user",
});

export const getByIdUserAction = (id) => ({
  type: GET_USER_BY_ID_REQUEST,
  endpoint: `/admin/user/${id}`,
});

export const updateUserAction = (token, data) => ({
  type: UPDATE_USER_REQUEST,
  endpoint: `/admin/user?token=${token}`,
  body: data,
});

export const deleteUserAction = (id) => ({
  type: DELETE_USER_REQUEST,
  endpoint: `/admin/services/${id}`,
});
