/* eslint-disable no-trailing-spaces */
/* eslint-disable require-yield */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { takeEvery, put } from 'redux-saga/effects';
import Axios from 'axios';
import firebase from '../firebase';
import { baseURL } from '../axios';
import {
  LOGIN_USER_REQUEST,
  SIGNUP_USER_REQUEST,
  CLINICAL_SIGNUP_SUCCESS,
  CLINICAL_SIGNUP_FAILED,
  CLINICAL_SIGNUP_REQUEST,
  USER_LOGIN,
  USER_SIGNUP,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILED,
  OTP_NOT_VERIFIED,
} from '../constant';
import AlertMsg from '../Alert';
import logo from '../../assets/imgs/Oval (2).png';

function* login(action) {
  try {
    const { endpoint, body, history } = action;
    Axios.post(baseURL + endpoint, JSON.stringify(body), {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (response.data) {
          if (
            response.data.data &&
            response.data.data.user &&
            response.data.data.token
          ) {
            AlertMsg('success', 'Login Success');
            localStorage.setItem('ACCESS_TOKEN_PATH', response.data.data.token);
            localStorage.setItem('email', response.data.data.user.email);
            localStorage.setItem('avatar', response.data.data.user.avatar);
            localStorage.setItem('fName', response.data.data.user.firstName);
            localStorage.setItem('lName', response.data.data.user.lastName);
            localStorage.setItem(
              'prefix',
              response.data.data.user.prefix || ''
            );
            localStorage.setItem(
              'suffix',
              response.data.data.user.suffix || ''
            );

            if (response.data.data.user._id) {
              localStorage.setItem('uid', response.data.data.user._id);
            }
            switch (response.data.data.user.role) {
              case 'admin':
                localStorage.setItem('__r', 'admin');
                if (response.data.data.user._id) {
                  localStorage.setItem(
                    'clinicianUID',
                    response.data.data.user._id
                  );
                }
                history.push('/admin/dashboard');
                break;
              case 'clinician':
                localStorage.setItem(
                  'accecpt_new',
                  response.data.data.user.acceptNewPatients ? 'true' : 'false'
                );
                localStorage.setItem(
                  'clinicianUID',
                  response.data.data.user._id
                );
                localStorage.setItem('__r', 'clinician');
                history.push('/clinician/dashboard');
                break;
              case 'frontdesk':
                localStorage.setItem('__r', 'frontdesk');
                if (response.data.data.user._id) {
                  localStorage.setItem(
                    'clinicianUID',
                    response.data.data.user._id
                  );
                }
                history.push('/frontdesk/appointments');
                break;
              case 'finance':
                localStorage.setItem('__r', 'finance');
                if (response.data.data.user._id) {
                  localStorage.setItem(
                    'clinicianUID',
                    response.data.data.user._id
                  );
                }
                history.push('/finance/appointments');
                break;
              case 'event':
                localStorage.setItem('__r', 'event');
                if (response.data.data.user._id) {
                  localStorage.setItem(
                    'clinicianUID',
                    response.data.data.user._id
                  );
                }
                history.push('/event/events');
                break;
              default:
                console.log(response.data.data.user.role);
            }
          } else {
            AlertMsg('error', response.data.message);
          }
        }
      })
      .catch((error) => {
        AlertMsg('error', error.response.data.reason);
      });
  } catch (e) {
    AlertMsg('error', e.message);
  }
}

function* signup(action) {
  const { endpoint, body, history } = action;
  const response = yield Axios.post(baseURL + endpoint, JSON.stringify(body), {
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  });
  if (response.data) {
    AlertMsg('success', response.data.message);
    history.push('/admin/login');
  }
}

function* clinicalSignup(action) {
  const { endpoint, body } = action;
  console.log('Data------------', JSON.stringify(body));
  const response = yield Axios.post(baseURL + endpoint, JSON.stringify(body), {
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  });
  if (response.data) {
    AlertMsg('success', response.data.message);
    // history.push('/login');
  }
}

function* userSignup(action) {
  try {
    const {
      email,
      password,
      firstName,
      dob,
      lastName,
      id: uId,
      props,
    } = action.body;
    const { user } = yield firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    const res = yield firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(async (id) => {
        let createData = { firstName, dob, lastName };
        if (uId) {
          localStorage.setItem('ACCESS_TOKEN_PATH', id);
          createData.id = uId;
          createData.verify = true;
          createData.status = 'active';
        }
        return await Axios.post(
          `${baseURL}/api/client/create`,
          JSON.stringify(createData),
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: id,
            },
          }
        ).catch((e) => {
          console.log('error in create: ', e);
          return null;
        });
      });
    console.log('res.data', res.data);
    if (res && res.data) {
      if (uId) {
        localStorage.setItem('avatar', logo);
        localStorage.setItem(
          'name',
          res.data.firstName + ' ' + res.data.lastName
        );
        localStorage.setItem('createdAt', res.data.createdAt);
        localStorage.setItem('clientEmail', res.data.email);
        localStorage.setItem('clientUid', res.data.uid);
        localStorage.setItem('clientId', res.data._id);
        AlertMsg('success', 'You are logged in');
        localStorage.removeItem('userDetails');
        console.log(props);
        if (res.data.firstLogin) {
          props.history.push('/dashboard');
        } else {
          props.history.push('/onboarding');
        }
        yield put({ type: SIGNUP_USER_SUCCESS, response: res.data });
      } else {
        yield put({ type: SIGNUP_USER_SUCCESS, response: res.data });
      }
    }
  } catch (error) {
    console.log('error: ', error.message);

    yield put({ type: SIGNUP_USER_FAILED, response: '' });
    let msg = 'There is some issue occured';
    if (error.message) {
      msg = error.message;
    }
    AlertMsg('error', msg);
  }
}

function* userLogin(action) {
  try {
    const { email, password, props } = action.body;
    const { user } = yield firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    const res = yield firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(async (id) => {
        localStorage.setItem('ACCESS_TOKEN_PATH', id);
        return await Axios.post(
          `${baseURL}/api/client/sign_up`,
          JSON.stringify({ data: 'data' }),
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: id,
            },
          }
        );
      });
    if (res && res.data) {
      localStorage.setItem('avatar', res.data.avatar ? res.data.avatar : logo);
      localStorage.setItem(
        'name',
        res.data.firstName + ' ' + res.data.lastName
      );
      localStorage.setItem('createdAt', res.data.createdAt);
      localStorage.setItem('clientEmail', res.data.email);
      localStorage.setItem('clientUid', res.data.uid);
      localStorage.setItem('clientPhoneNumber', res.data.phoneNumber);
      localStorage.setItem(
        'phoneNumberVerified',
        res.data.phoneNumberVerified ? 'yes' : 'no'
      );
      localStorage.setItem('clientId', res.data._id);

      localStorage.removeItem('userDetails');

      if (res.data.verify) {
        AlertMsg('success', 'You are logged in');
        if (res.data.firstLogin) {
          props.history.push('/dashboard');
        } else {
          props.history.push('/onboarding');
        }
        const response = {};
        yield put({ type: LOGIN_USER_SUCCESS, response });
      } else {
        const response = res.data;
        yield put({ type: OTP_NOT_VERIFIED, response });
      }
    }
  } catch (error) {
    console.log('error ======= ', error);
    localStorage.removeItem('ACCESS_TOKEN_PATH');
    yield put({ type: LOGIN_USER_FAILED, error });
    let msg = 'There is some issue occured';
    if (error.message) {
      msg = error.message;
    }
    AlertMsg('error', msg);
  }
}

export function* authSaga() {
  yield takeEvery(LOGIN_USER_REQUEST, login);
  yield takeEvery(SIGNUP_USER_REQUEST, signup);
  yield takeEvery(CLINICAL_SIGNUP_REQUEST, clinicalSignup);
  yield takeEvery(USER_SIGNUP, userSignup);
  yield takeEvery(USER_LOGIN, userLogin);
}
