import React from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import moment from 'moment';
import {
  Img,
  Container,
  GridWithBottomBorder,
  HR,
  CapitalizedHeading,
  Row,
  Heading,
  CellDef,
  Cell,
  TotalGrid,
  BorderedCell,
  BorderedTD,
  TotalHeading,
  BoldHR,
  Footer,
  ThankYou,
  LoaderGrid,
  PDFButton,
  LogoGrid,
} from './style';
import Logo from '../../../assets/imgs/Background.png';
import { APPOINTMENT_TYPES } from '../../../utils/enums';

const Unpaid = ({ invoice, clinicAddress, clientAddress }) => {
  const vatAmount = () => {
    if (!invoice.appointmentId?.appointmentType?.vat) {
      return 0;
    }

    return Number(
      (
        (subTotal().toFixed(2) / 100) *
        invoice.appointmentId?.appointmentType?.vat
      ).toFixed(2)
    );
  };

  const discountAmount = () =>
    invoice.discount
      ? Number(((subTotal() / 100) * invoice.discount).toFixed(2))
      : 0;

  const total = () =>
    Number((subTotal() + vatAmount() - discountAmount()).toFixed(2));

  const subTotal = () => {
    let amount = Number(invoice.amount);

    const extraFeesAmount = invoice.extraFees.map((e) => Number(e.amount));
    return [amount, ...extraFeesAmount].reduce((sum, a) => sum + a);
  };

  console.log('invoce', invoice);
  return (
    <Container container>
      <Row container>
        <LogoGrid item>
          <Img src={Logo} alt='logo' />
        </LogoGrid>

        {invoice.user?.address && (
          <Grid container>
            <Grid item>{clinicAddress(invoice.user.address)}</Grid>
          </Grid>
        )}
      </Row>
      <HR />
      <Row container>
        <Grid justify='center' container>
          <Grid item>
            <GridWithBottomBorder item>Client Invoice</GridWithBottomBorder>
            <CapitalizedHeading item>TAX INVOICE</CapitalizedHeading>
          </Grid>
        </Grid>
      </Row>
      <Row container>
        <Grid xs={4} direction='column' alignItems='flex-start' container item>
          <Heading>Client Details:</Heading>
          <Grid>
            <table>
              <tr>
                <CellDef>Client Name: </CellDef>
                <Cell>
                  {`${invoice.clientId.firstName} ${invoice.clientId.lastName}`}
                </Cell>
              </tr>
              <tr>
                <CellDef>Chart Number: </CellDef>
                <Cell>{invoice.clientId.chartId} </Cell>
              </tr>
              <tr>
                <CellDef>Address: </CellDef>
                <Cell>{clientAddress(invoice.clientId.address)} </Cell>
              </tr>
              <tr>
                <CellDef>Phone Number: </CellDef>
                <Cell>{invoice.clientId.phoneNumber} </Cell>
              </tr>
              <tr>
                <CellDef>Email Address: </CellDef>
                <Cell>{invoice.clientId.email} </Cell>
              </tr>
            </table>
          </Grid>
        </Grid>

        <Grid xs={4} direction='column' alignItems='flex-start' container item>
          <Heading>Invoice Details:</Heading>
          <Grid>
            <table>
              <tr>
                <CellDef>Invoice Number: </CellDef>
                <Cell>
                  {`INV-${moment(invoice.createdAt).format('YY')}-${
                    invoice.chartId
                  }`}
                </Cell>
              </tr>
              <tr>
                <CellDef>Invoice Date: </CellDef>
                <Cell>{moment(invoice.createdAt).format('LL')} </Cell>
              </tr>
              <tr>
                <CellDef>Due Date: </CellDef>
                <Cell>invoice_due_date </Cell>
              </tr>
              <tr>
                <CellDef>VAT Number: </CellDef>
                <Cell>invoice_vat_number </Cell>
              </tr>
            </table>
          </Grid>
        </Grid>
      </Row>
      <Row container>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <BorderedTD>Description</BorderedTD>
                <BorderedTD>Date</BorderedTD>
                <BorderedTD>Unit Price</BorderedTD>
                <BorderedTD>Unit</BorderedTD>
                <BorderedTD>Charges</BorderedTD>
                <BorderedTD>Total</BorderedTD>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <BorderedCell>
                  {invoice.appointmentId.appointmentType?.type}
                </BorderedCell>
                <BorderedCell>
                  {moment(invoice.appointmentId.startTime).format('LL')}
                </BorderedCell>
                <BorderedCell align='right'>{invoice.amount} AED</BorderedCell>
                <BorderedCell>1</BorderedCell>
                <BorderedCell align='right'>{invoice.amount} AED</BorderedCell>
                <BorderedCell align='right'>{invoice.amount} AED</BorderedCell>
              </TableRow>
              {invoice.extraFees.map((ef, i) => (
                <TableRow key={`exFee-${i}`}>
                  <BorderedCell>{ef.type}</BorderedCell>
                  <BorderedCell>
                    {moment(invoice.appointmentId.startTime).format('LL')}
                  </BorderedCell>
                  <BorderedCell align='right'>{ef.amount} AED</BorderedCell>
                  <BorderedCell>1</BorderedCell>
                  <BorderedCell align='right'>{ef.amount} AED</BorderedCell>
                  <BorderedCell align='right'>{ef.amount} AED</BorderedCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Row>
      <Row justify='flex-end' container>
        <Grid xs={4} item>
          <Grid container>
            <TotalHeading container item>
              Total
            </TotalHeading>
            <BoldHR />
            <TotalGrid justify='space-between' container item>
              <Grid item>Total Charges Amount </Grid>
              <Grid item>{subTotal()} AED</Grid>
            </TotalGrid>
            <TotalGrid justify='space-between' container item>
              <Grid item>Total Discount ({invoice.discount}%)</Grid>
              <Grid item>{discountAmount()} AED</Grid>
            </TotalGrid>
            <TotalGrid justify='space-between' container item>
              <Grid item>
                VAT Amount ({invoice.appointmentId?.appointmentType?.vat || 0}%)
              </Grid>
              <Grid item>{vatAmount()} AED</Grid>
            </TotalGrid>
            <BoldHR />
            <TotalGrid justify='space-between' container item>
              <Grid item>Final Charge after Discount </Grid>
              <Grid item>{subTotal() - discountAmount()} AED</Grid>
            </TotalGrid>
            <TotalGrid justify='space-between' container item>
              <Grid item>Total Payments </Grid>
              <Grid item>{total()} AED</Grid>
            </TotalGrid>
            <BoldHR />
            <TotalGrid justify='space-between' container item>
              <Grid item>Invoice Balance </Grid>
              <Grid item>0.00 AED</Grid>
            </TotalGrid>
            <TotalGrid justify='space-between' container item>
              <Grid item>Outstanding Balance </Grid>
              <Grid item>{invoice.outstandingBalance} AED</Grid>
            </TotalGrid>
          </Grid>
        </Grid>
      </Row>

      <Row justify='center' container>
        <ThankYou>Thank You for Your Visit</ThankYou>
      </Row>

      <BoldHR />

      <Row justify='center' container>
        <Footer>
          +971 (4) 380-2088 |
          <a href='reception@lighthousearabia.com'>
            reception@lighthousearabia.com
          </a>
          | Villa 821, Al Wasl Rd, Al Safa 2 - Dubai - 334273 - United Arab
          Emirates
        </Footer>
      </Row>
    </Container>
  );
};

export default Unpaid;
