/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import { takeEvery, put } from "redux-saga/effects";
import { post, get, updatePatch } from "../axios";
import {
  ADD_P1OF2_SUCCESS,
  ADD_P1OF2_FAILED,
  GET_P1OF2_SUCCESS,
  GET_P1OF2_FAILED,
  GET_BY_ID_P1OF2_SUCCESS,
  GET_BY_ID_P1OF2_FAILED,
  UPDATE_P1OF2_SUCCESS,
  UPDATE_P1OF2_FAILED,
  ADD_P1OF2_REQUEST,
  GET_BY_ID_P1OF2_REQUEST,
  UPDATE_P1OF2_REQUEST,
  GET_P1OF2_REQUEST,
} from "../AdminConstent";
import AlertMsg from "../Alert";

function* addP1of2(action) {
  try {
    const { endpoint, body } = action;
    const response = yield post(endpoint, body);
    AlertMsg("success", "Added");
    yield put({ type: ADD_P1OF2_SUCCESS, response });
  } catch (error) {
    AlertMsg("error", "Failed");
    yield put({ type: ADD_P1OF2_FAILED, error });
  }
}

function* getP1of2Saga(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_P1OF2_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_P1OF2_FAILED, error });
  }
}

function* getByIdP1of2(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_BY_ID_P1OF2_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_BY_ID_P1OF2_FAILED, error });
  }
}

function* updateP1of2(action) {
  try {
    const { endpoint, body } = action;
    AlertMsg("success", "Updated");
    const response = yield updatePatch(endpoint, body);
    yield put({ type: UPDATE_P1OF2_SUCCESS, response });
  } catch (error) {
    AlertMsg("error", "Failed");
    yield put({ type: UPDATE_P1OF2_FAILED, error });
  }
}

function* getP1of2UserSaga(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_P1OF2_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_P1OF2_FAILED, error });
  }
}

export function* P1of2Saga() {
  yield takeEvery(ADD_P1OF2_REQUEST, addP1of2);
  yield takeEvery(GET_P1OF2_REQUEST, getP1of2Saga);
  yield takeEvery(GET_BY_ID_P1OF2_REQUEST, getByIdP1of2);
  yield takeEvery(UPDATE_P1OF2_REQUEST, updateP1of2);
  yield takeEvery(GET_P1OF2_REQUEST, getP1of2UserSaga);
}
