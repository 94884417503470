// Common Table View
import React from 'react';
import { Grid } from '@material-ui/core';
import { TableTitleFont } from './TableFont';
import { CustomTable } from './Table';
import Axios from 'axios';
import { baseURL } from '../utils/axios';
import AlertMsg from '../utils/Alert';
import { LOCATION } from '../utils/enums';
import { calculateAge } from '../utils/timUtils';
import logo from '../assets/imgs/download.png';
import logo1 from '../assets/imgs/download.png';

const customDateFormat = (date) => {
  const dateStr = `${`00${date.getDate()}`.slice(-2)}-${`00${
    date.getMonth() + 1
  }`.slice(-2)}-${date.getFullYear()} ${`00${date.getHours()}`.slice(
    -2
  )}:${`00${date.getMinutes()}`.slice(-2)}`;
  return dateStr;
};

export default function AppointmentClinician(props) {
  const [loading, isLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    isLoading(true);
    Axios.get(`${baseURL}${props.endpoint}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        isLoading(false);
        if (res) {
          console.log(res.data);
          const list = res.data.map((node) => ({
            clinicianId: node.clinicianId ? node.clinicianId._id : null,
            referralByImage:
              node.clinicianId && node.clinicianId.avatar
                ? node.clinicianId.avatar
                : logo,
            referralBy: node.clinicianId
              ? `${node.clinicianId.firstName} ${node.clinicianId.lastName}`
              : '',
            date: node.startTime
              ? customDateFormat(new Date(node.startTime))
              : customDateFormat(new Date(node.createdAt)),
            refClinicianImage: node.refferedBy ? node.refferedBy.avatar : '',
            refClinician: node.refferedBy
              ? `${node.refferedBy.firstName} ${node.refferedBy.lastName}`
              : '',
            patient:
              node.clientId && node.clientId.length && node.clientId[0].avatar
                ? node.clientId[0].avatar
                : logo1,
            paymentStatus:
              node.billingId && node.billingId.status === 'paid'
                ? 'Paid'
                : 'Unpaid',
            action:
              (node.billingId && node.billingId.status === 'paid') ||
              node.status === 'waiting'
                ? 'Cancel'
                : 'Submit Payment',
            actionType:
              (node.billingId && node.billingId.status === 'paid') ||
              node.status === 'waiting'
                ? 'yes'
                : 'no',
            type: node.type.substr(0, 1).toUpperCase() + node.type.substr(1),
            location: node.appointmentLocation
              ? LOCATION[node.appointmentLocation]
              : '',
            viewDetails: node,
            billingId: node.billingId,
            status: node.status,
            _id: node._id,
            createdAt: node.createdAt,
            riskScore: node.riskScore,
            age: calculateAge(
              node.clientId &&
                node.clientId[0] &&
                new Date(node.clientId[0].dob)
            ),
            patientName: node.clientId[0]
              ? `${node?.clientId[0].firstName} ${node.clientId[0].lastName}`
              : '',
          }));
          setData(list);
        }
      })
      .catch(() => {
        AlertMsg('error', 'Something went wrong!');
        isLoading(false);
      });
  };

  const handleActions = (action) => {
    if (action.action === 'Cancel') {
      Axios.put(
        `${baseURL}/api/appointment/${action.viewDetails._id}`,
        { status: 'cancelled' },
        {
          headers: {
            Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
          },
        }
      )
        .then((res) => {
          if (res.data) {
            getData();
            AlertMsg('success', 'Appointment successfully canceled.');
          }
        })
        .catch(() => {
          AlertMsg('success', 'Error canceling appointment try again!');
        });
    }
  };

  return (
    <div>
      <Grid style={{ marginBottom: '44px' }}>
        <TableTitleFont text={props.title ? props.title : 'Appointments'} />
        <CustomTable
          data={data}
          isLoader={false}
          onRowSelect={(item) => {
            handleActions(item);
          }}
          style={{ opacity: loading ? '40%' : '' }}
        />
      </Grid>
    </div>
  );
}
