import React, { useEffect, useState } from "react";
import Axios from 'axios';
import { baseURL } from '../../../utils/axios';
import AlertMsg from "../../../utils/Alert";
import Dialog from '../../../components/atoms/TailwindDialog';

const EventsCategories = () => {
  const [newCategory, setNewCategory] = useState("")
  const [editCategory, setEditCategory] = useState("")
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [suspendModel, setSuspendModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [activateModel, setActivateModel] = useState(false);

  useEffect(() => {
    getCategories()
  }, [])

  const getCategories = (page) => {
    Axios.get(`${baseURL}/api/event/category`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          console.log(res.data)
          setCategories(res.data)
        }
      })
      .catch((e) => {

      });
  }

  const createCategory = () => {

    if (!newCategory || newCategory === "") {
      AlertMsg("error", "Category name has to be provided..")
    }

    Axios.post(`${baseURL}/api/event/category`, { title: newCategory }, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          getCategories()
        }
      })
      .catch((e) => {

      });
  }

  const update = (category, data) => {
    Axios.patch(`${baseURL}/api/event/category/${category._id}`, data, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          getCategories()
        }
      })
      .catch((e) => {

      });
  }

  return (<div>
    <div className="flex flex-col flex-nowrap w-full h-full">
      { }
      <div
        className="item p-8 h-full"
        style={{
          maxHeight: "calc(100vh - 72px)"
        }}
      >
        <div className="bg-white w-full h-full p-6">
          <div className="form-wrapper mb-8">
            <h1 className="font-semibold text-md mb-2">Create new category:</h1>
            <input
              id="category-innput"
              className="mb-2 p-2.5 w-96 text-sm text-gray-600 border border-gray-200 focus:border-blue-500"
              type="text"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
            />
            <button onClick={createCategory} className="px-4 py-2.5 text-white bg-brand-blue text-sm border whitespace-nowrap hover:bg-brand-blue-mid">
              Add New
            </button>
            { }
          </div>
          <div className="categories-table">
            <table className="w-full">
              <thead className="text-sm text-left bg-brand-blue text-white">
                <tr>
                  <th className="py-4 px-2 font-bold">Category Title</th>
                  <th className="py-4 px-2 font-bold">Active Status</th>
                  <th className="py-4 px-2 font-bold">Number Of Events</th>
                  <th className="py-4 px-2 font-bold">Actions</th>
                </tr>
              </thead>
              <tbody className>
                {categories.map((category) => (
                  <tr key={category._id} className="text-sm text-left border-b">
                    {console.log(category)}
                    <td className="p-2">{category.title}</td>
                    <td className="p-2">
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${category.status == "active" ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"}`}>
                        {category.status == "active" ? "Active" : "Suspended"}
                      </span>
                    </td>
                    <td className="p-2">{category.numberOfEvents}</td>
                    <td className="p-2">
                      {category.status == "active" ?
                        <>
                          <button
                            onClick={() => {
                              setSelectedCategory(category)
                              setEditModel(true)
                            }
                            }
                            className="btn btn-edit inline-block p-3 text-yellow-600 bg-yellow-100 rounded-full hover:text-white hover:bg-yellow-600"
                            data-title="Edit"
                            data-placement="top"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              />
                            </svg>
                          </button>
                          <button
                            onClick={() => {
                              setSelectedCategory(category)
                              setSuspendModel(true)
                            }
                            }
                            className="btn btn-suspend ml-1 inline-block p-3 text-red-600 bg-red-100 rounded-full hover:text-white hover:bg-red-600"
                            data-title="Suspend"
                            data-placement="top"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </button>
                        </> :
                        <button
                          onClick={() => {
                            setSelectedCategory(category)
                            setActivateModel(true)
                          }
                          }
                          className="btn btn-activate ml-1 inline-block p-3 text-green-600 bg-green-100 rounded-full hover:text-white hover:bg-green-600"
                          data-title="Activate"
                          data-placement="top"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </button>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    { }
    <Dialog open={suspendModel}
      onClose={() => {
        setSuspendModel(false);
      }}>
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-0 sm:p-6">
            <div className="flex flex-col items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                { }
                <svg
                  className="h-6 w-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div className="mt-2 text-left">
                <h3
                  className="modal-title text-lg leading-6 font-medium text-gray-900 text-center sm:text-left"
                  id="modal-title"
                >
                  Suspend Category
                </h3>
                <div className="mt-2">
                  <p className="modal-message text-sm text-gray-500">
                    You are about to suspend this category.{" "}
                    <span className="font-bold">
                      All events under this category will not show in the EHR
                      and the API.
                    </span>{" "}
                    If you choose to proceed, you can reactivate this category
                    at any time.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={() => {
                setSuspendModel(false)
                update(selectedCategory, { status: "suspended" })
              }}
              id="btn-split-submit"
              type="submit"
              className="modal-action-btn w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Suspend
            </button>
            <button
              type="button"
              onClick={() => setSuspendModel(false)}
              className="btn-cancel-modal mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-blue sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Dialog >
    { }
    <Dialog open={editModel}
      onClose={() => {
        setEditModel(false);
      }}>
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-0 sm:p-6">
            <div className="flex flex-col items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                { }
                <svg
                  className="h-6 w-6 text-yellow-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div className="mt-2 text-left">
                <h3
                  className="modal-title text-lg leading-6 font-medium text-gray-900 text-center sm:text-left"
                  id="modal-title"
                >
                  Edit Category
                </h3>
                <div className="mt-2">
                  <p className="modal-message text-sm text-gray-500">
                    You are about to edit this category's title. Editing a title{" "}
                    <span className="font-bold">only affects</span> the title
                    display in the EHR, registration pages, and the API. Click
                    Edit to confirm this action, or cancel to abort.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white px-4 pt-6 pb-2 sm:px-6 sm:pt-0 sm:pb-4">
            <div className="form">
              <div className="text-xs mb-2">
                New category title: <span className="text-red-500">*</span>
              </div>
              <input
                id="category-title-edit"
                type="text"
                value={newCategory}
                onChange={(e) => setEditCategory(e.target.value)}
                size={5}
                required
                className="flex-1 h-10 min-w-0 block w-full border border-gray-200 p-2 bg-white text-sm"
              />
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={() => {
                setEditModel(false)
                update(selectedCategory, { title: editCategory })
                setEditCategory('')
              }}
              className="modal-action-btn w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium bg-yellow-600 text-white hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Edit
            </button>
            <button
              onClick={() => setEditModel(false)}
              className="btn-cancel-modal mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-blue sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Dialog >

    <Dialog open={activateModel}
      onClose={() => {
        setActivateModel(false);
      }}>
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-0 sm:p-6">
            <div className="flex flex-col items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                { }
                <svg
                  className="h-6 w-6 text-green-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div className="mt-2 text-left">
                <h3
                  className="modal-title text-lg leading-6 font-medium text-gray-900 text-center sm:text-left"
                  id="modal-title"
                >
                  Activate Category
                </h3>
                <div className="mt-2">
                  <p className="modal-message text-sm text-gray-500">
                    You are about to activate this category.{" "}
                    <span className="font-bold">
                      All events under this category will now display in the EHR
                      and the API.
                    </span>{" "}
                    Click activate to proceed, or cancel to abort.
                  </p>
                </div>
              </div>
            </div>
          </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                onClick={() => {
                  setActivateModel(false)
                  update(selectedCategory, { status: "active" })
                }}
                className="modal-action-btn w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium bg-green-600 text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Activate
              </button>
              <button
                onClick={() => { setActivateModel(false); }}
                className="btn-cancel-modal mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-blue sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
            </div>
        </div>
      </div>
    </Dialog>
  </div>)
};

export default EventsCategories;
