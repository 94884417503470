/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
import React from "react";
import Home from "../sub_components/clients/Home";
import ClientTable from "../sub_components/clients/Table";
import Sidebar from "../components/Sidebar";

class Clients extends React.Component {
  render() {
    return (
      <>
        <Sidebar />
        <Home props={this.props} />
        <ClientTable />
      </>
    );
  }
}

export default Clients;
