import React from 'react';
import PropTypes from 'prop-types';
import { OutlinedInput, InputAdornment, IconButton } from '@material-ui/core';
import { Search } from '@material-ui/icons';

const SearchBox = ({ name, placeholder, onChange, onClick, value }) => {
  return (
    <OutlinedInput
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      endAdornment={
        <InputAdornment position='end'>
          <IconButton onClick={onClick}>
            <Search />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

SearchBox.defaultProps = {
  placeholder: 'Search',
  value: '',
};

SearchBox.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default SearchBox;
