import React from 'react';
import PropTypes from 'prop-types';
import { ColoredBtn, IconImg } from './style';

const CustomBtn = ({
  id,
  text,
  icon,
  onClick,
  style,
  color,
  bgcolor,
  className,
}) => (
  <ColoredBtn
    id={id}
    variant='contained'
    onClick={onClick}
    bgcolor={bgcolor}
    style={style}
    color={color}
    className={className}
  >
    {icon && <IconImg>{icon}</IconImg>}
    {text}
  </ColoredBtn>
);

CustomBtn.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element,
  style: PropTypes.object,
  bgcolor: PropTypes.string,
};

export default CustomBtn;
