/* eslint-disable react/no-danger */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import {  withStyles, Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import Header from "../../common/Header";
import BackArrow from "../../assets/imgs/back_arrow.svg";
import RightIcon from "../../assets/imgs/right_icon.svg";
import { CustomNextButtons } from "../../common/CustomButtons";

import { getP1of2UserAction } from "../../utils/actions/admin.p1of2.action";
import { Loader } from "../Loader";
import styles from "./Part1Of2CompleteStyle";

class Part1Of2Complete extends Component {
  componentDidMount() {
    this.props.getP1of2UserAction();
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Header props={this.props} />
        <div className={classes.root} />
        <Grid container component="div" alignItems="center" justify="center">
          <Grid item component="div" className={classes.container}>
            <Grid component="div" style={{ display: "flex" }}>
              <img src={BackArrow} className={classes.backArrow} />
              <Typography
                className={classes.back}
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Typography>
            </Grid>
            <Grid justify="center" className={classes.headerContainer}>
              <Typography className={classes.headerText}>
                Part 1 of 2 Complete
              </Typography>
              <Grid className={classes.rightIconContainer}>
                <img src={RightIcon} className={classes.rightIconText} />
              </Grid>
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.bodyContainer}
            >
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.bodySubContainer}
              >
                <Typography component="p" className={classes.containerText}>
                  {!this.props.getDataLoader ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.props.getData.completeAssessment,
                      }}
                    />
                  ) : (
                    <Loader />
                  )}
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Phasellus iaculis volutpat enim vel elementum. Quisque vitae
                  rutrum ipsum. Vestibulum suscipit commodo diam a bibendum.
                  Nunc eleifend, lacus at ultricies tincidunt, nibh sapien
                  iaculis lacus, eget interdum magna eros et erat. Cras non
                  auctor enim, vitae semper velit. Vivamus aliquet fermentum
                  vestibulum. Sed hendrerit tristique ligula, ut imperdiet eros
                  blandit sit amet. Vestibulum id justo consequat, varius urna
                  eu, volutpat metus. Cras tincidunt mattis finibus. Cras at
                  sapien feugiat nisi dignissim pretium id vitae nulla. Nullam
                  aliquam, ex ac cursus sodales, lorem nulla porta orci, sit
                  amet dictum eros sapien in mi. */}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              style={{
                marginTop: "30px",
                float: "right",
                cursor: "pointer",
                display: "flex",
                marginBottom: "100px",
              }}
            >
              <Typography
                className={classes.skipText}
                onClick={() => this.props.history.push("/dashboard")}
              >
                Skip
              </Typography>
              <CustomNextButtons
                width="158px"
                title="Next"
                onPressButton={() => this.props.history.push("/your-result")}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

Part1Of2Complete.defaultProps = {
  getDataLoader: true,
  getData: {},
};

const connected = connect(
  (state) => ({
    getData: state.getP1of2User.getP1of2UserData,
    getDataLoader: state.getP1of2User.request,
  }),
  {
    getP1of2UserAction,
  }
)(Part1Of2Complete);

const formed = reduxForm({
  form: "p1of2",
})(connected);



export default withStyles(styles)(formed);
