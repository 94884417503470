import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  MenuItem,
  List,
  ListItemText,
  ListItem,
} from '@material-ui/core';

const CustomList = ({
  isOpen,
  onChange,
  options,
  toggleMenu,
  placeholder,
  value,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [displayText, setDisplayText] = useState('');
  const [selectedOption, setSelectedOption] = useState({});

  useEffect(() => {
    if (value && options) {
      const oldValue = options.filter((f) => f._id === value);
      if (oldValue[0]) {
        setSelectedOption(oldValue[0]._id);
        setDisplayText(oldValue[0].name);
      }
    }
  }, [value, options]);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
    toggleMenu();
  };

  const handleMenuItemClick = (event, index) => {
    onChange(options[index]);
    setSelectedOption(options[index]);
    setDisplayText(options[index].name);
    setAnchorEl(null);
    toggleMenu();
  };

  const handleCloseAndReset = () => {
    setAnchorEl(null);
    toggleMenu();
  };

  return (
    <div>
      <List
        aria-label='Device settings'
        style={{
          border: '1px solid #e5e5e5',
          paddingTop: '0px',
          paddingBottom: '0px',
        }}
      >
        <ListItem
          button
          aria-haspopup='true'
          aria-controls='lock-menu'
          aria-label='when device is locked'
          onClick={handleClickListItem}
        >
          <ListItemText
            primary={
              !options ? 'Wait while loadiing ...' : displayText || placeholder
            }
          />
        </ListItem>
      </List>
      <Menu
        keepMounted
        open={isOpen}
        onClose={handleCloseAndReset}
        anchorEl={anchorEl}
      >
        {options && options.length === 0 ? (
          <MenuItem disabled>No Data available!</MenuItem>
        ) : (
          options &&
          options.map((option, index) => (
            <MenuItem
              key={option._id}
              selected={option._id === selectedOption._id}
              onClick={(event) => handleMenuItemClick(event, index)}
            >
              {option.name}
            </MenuItem>
          ))
        )}
      </Menu>
    </div>
  );
};

CustomList.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  toggleMenu: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};
export default CustomList;
