import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Grid,
  Typography,
  Button,
  createStyles,
  List,
  ListItem,
  Paper,
  makeStyles,
  Card,
  CardContent,
  CardMedia,
} from '@material-ui/core';
import { uploadDocFileAction } from '../utils/actions/client.action';
import { baseURL } from '../utils/axios';
import Axios from 'axios';
import AlertMsg from '../utils/Alert';
import ProofOfAddressModel from './ProofOfAddressModel';
import moment from 'moment';
import { connect } from 'react-redux';
import DocumentList from './DocumentList';
import { Dialog, Button as DeleteBtn } from '../components/atoms';

function ProofOfAddress(props) {
  const { classes } = props;
  const [openProofOfAddress, setOpenProofOfAddress] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState(null);
  const [document, setDocument] = useState(null);
  const [data, setData] = useState(null);
  const [list, setList] = useState([]);
  const [openDeleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deletingData, setDeletingData] = useState(null);

  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    if (props.uploadDocFileData) {
      setIsLoading(false);
      if (props.uploadDocFileData.type == 'proofOfAddress') {
        const postData = { ...data };
        postData.documentId = props.uploadDocFileData._id;
        postData.clientId = props.clientId;
        delete postData.fileData;
        delete postData.errors;
        post(postData);
      }
    }
  }, [props.uploadDocFileData]);

  const handleSubmit = async (data) => {
    setOpenProofOfAddress(false);
    console.log(data);
    if (data.file) {
      docFileUpload(data.file);
    } else if (data._id) {
      const postData = { ...data };
      postData.documentId = postData.documentId._id;
      postData.clientId = props.clientId;
      delete postData.fileData;
      delete postData.errors;
      post(postData);
    }

    setData(data);
  };

  const get = () => {
    setIsLoading(true);
    const token = localStorage.getItem('ACCESS_TOKEN_PATH');
    Axios.get(baseURL + '/api/client/proofOfAddress', {
      headers: {
        Authorization: token,
      },
    })
      .then((data) => {
        setIsLoading(false);
        if (data.data) {
          setList(data.data);
        }
      })
      .catch((e) => {
        AlertMsg('error', e.message);
        setIsLoading(false);
      });
  };

  const edit = (data) => {
    setData(data);
    setOpenProofOfAddress(true);
  };

  const confirmDelete = (data) => {
    setDeletingData({ ...data });
    setDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => {
    setDeletingData(null);
    setDeleteConfirmation(false);
  };

  const handleDelete = () => {
    setIsLoading(true);
    console.log('deletingData,', deletingData);
    const token = localStorage.getItem('ACCESS_TOKEN_PATH');
    Axios.delete(baseURL + `/api/client/proofOfAddress/${deletingData._id}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.data.deleted) {
          closeDeleteConfirmation();
          get();
        }
      })
      .catch((e) => {
        AlertMsg('error', e.message);
        setIsLoading(false);
      });
  };

  const post = (data) => {
    setIsLoading(true);
    const token = localStorage.getItem('ACCESS_TOKEN_PATH');
    Axios.post(baseURL + '/api/client/proofOfAddress', data, {
      headers: {
        Authorization: token,
      },
    })
      .then((data) => {
        setIsLoading(false);
        if (data.data) {
          get();
        }
      })
      .catch((e) => {
        AlertMsg('error', e.message);
        setIsLoading(false);
      });
  };

  const docFileUpload = async (selectedFiles) => {
    const formData = new FormData();
    formData.append('document', selectedFiles);

    if (props.isUploading) {
      props.isUploading(true);
    }
    setIsLoading(true);

    const token = localStorage.getItem('ACCESS_TOKEN_PATH');
    if (props.isPatient) {
      props.uploadDocFileAction(formData, 'proofOfAddress');
    } else {
      Axios.post(baseURL + '/api/user/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: token,
        },
      })
        .then((data) => {
          setIsLoading(false);
          setDocument(data.data);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <Grid container>
        <Paper className={classes.paper}>
          <Grid
            container
            className={list.length == 0 ? classes.upload : classes.tile}
          >
            <Grid item xs={10}>
              <span
                style={{
                  marginRight: '20px',
                  fontSize: 20,
                }}
              >
                <b>Proof Of Address</b>
              </span>
              <p style={{ fontSize: 18 }}>
                Upload a valid,non-expired, government Id, a valid driver's
                license, or a valid passport copy.
              </p>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant='contained'
                onClick={() => setOpenProofOfAddress(true)}
                style={{
                  backgroundColor: '#1b63eb',
                  fontWeight: 100,
                  fontSize: 12,
                  marginTop: '12px',
                  marginLeft: '16px',
                  csolor: '#fff',
                  color: '#fff',
                  textTransform: 'capitalize',
                }}
              >
                {openProofOfAddress ? 'Change' : 'Upload'} Document
              </Button>
            </Grid>
          </Grid>
          <DocumentList data={list} onEdit={edit} onDelete={confirmDelete} />
        </Paper>
      </Grid>
      <ProofOfAddressModel
        key={new Date().getTime()}
        open={openProofOfAddress}
        value={data}
        handleClose={() => setOpenProofOfAddress(false)}
        handleSubmit={handleSubmit}
      ></ProofOfAddressModel>
      <Dialog
        handleClose={closeDeleteConfirmation}
        open={openDeleteConfirmation}
      >
        <Grid
          spacing={3}
          style={{ padding: '50px' }}
          direction='column'
          container
        >
          <Grid item>
            <Typography>
              Are you sure you want to delete this document?
            </Typography>
          </Grid>
          <Grid item>
            <Grid spacing={2} justify='flex-end' container>
              <Grid item>
                <Button onClick={closeDeleteConfirmation}>Cancel</Button>
              </Grid>
              <Grid item>
                <DeleteBtn onClick={handleDelete} text='Delete' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}

const style = createStyles({
  inputText: {
    fontSize: '22px',
    lineHeight: '28px',
    marginBottom: '15px',
    color: '#003265',
    textAlign: 'left',
  },
  paper: {
    height: '100%',
    boxShadow: 'none',
    width: '100%',
  },
  inputTitle: {
    textAlign: 'left',
    fontSize: '13px',
    color: 'grey',
    fontWeight: '500',
    marginBottom: '4px',
  },
  inputsty: {
    border: '1px solid #e5e5e5',
    borderRadius: '0',
    height: '45px',
    width: '100%',
  },
  imgBox: {
    backgroundColor: '#003265',
    padding: '25px',
    width: 170,
    height: 170,
  },
  img: {
    borderRadius: '50%',
    width: '125px',
    height: '125px',
    objectFit: 'cover',
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  drag: {
    width: '90%',
    height: '100%',
    borderStyle: 'dashed',
    borderWidth: 2,
    borderColor: '#ccc',
    textAlign: 'center',
    marginLeft: 20,
    color: '#afc2df',
  },
  italicTitle: {
    fontStyle: 'italic',
    fontSize: '13px',
    textAlign: 'left',
    color: '#807c7c',
  },
  tile: {
    padding: '20px',
    backgroundColor: '#f1f4f8',
    textAlign: 'left',
  },
  upload: {
    padding: '25px',
    backgroundColor: '#003265',
    color: '#fff',
    textAlign: 'left',
  },
  errorText: {
    color: 'red',
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: 16,
  },
});

const mapStateToProps = (state) => ({
  uploadDocFileData: state.uploadDocFile.uploadDocFileData,
});

export default connect(mapStateToProps, { uploadDocFileAction })(
  withStyles(style)(ProofOfAddress)
);
