import React from "react";
import { useLocation, useHistory, Route } from 'react-router';
import Logo from "../../../assets/img/logo-tla-left-light.svg";
import DefaultAvatar from '../../../assets/imgs/download.png';
import { checkIsActive } from '../../../utils/constant';
import "./style.css"
import { NavLink } from 'react-router-dom';


const AppMainNavigation = () => {
  const [loginData, setLoginData] = React.useState({
    firstName: localStorage.getItem('fName'),
    role: localStorage.getItem('__r'),
    lastName: localStorage.getItem('lName'),
    avatar: localStorage.getItem('avatar'),
  });

  let history = useHistory();
  const location = useLocation();

  const renderUserRole = () => {
    let role = 'Admin';
    if (loginData.role === 'frontdesk') {
      role = 'Frontdesk';
    } else if (loginData.role === 'event') {
      role = 'Events';
    }
    return role;
  };

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? "active" : "";
  };

  const ROUTE = {
    dashboard: `/${loginData.role}/dashboard`,
    appointment: `/${loginData.role}/appointments`,
    calendar: `/${loginData.role}/calendar`,
    users: `/${loginData.role}/patients`,
    invoices: `/${loginData.role}/billing`,
    timeSlots: `/${loginData.role}/time-slots`,
    clinicNotes: `/${loginData.role}/time-slots`,
    clinicNotesMissing: `/${loginData.role}/appointments?status=ended`,
    wailist: `/${loginData.role}/appointments?status=waiting`,
    archived: `/${loginData.role}/appointments?status=archived`,
    cancelled: `/${loginData.role}/appointments?status=cancelled`,
    noShow: `/${loginData.role}/appointments?status=missed`,
    sessionEnded: `/${loginData.role}/appointments?status=ended`,
    referred: `/${loginData.role}/appointments?status=reffered`,
    upcoming: `/${loginData.role}/appointments?status=upcoming`,
    staffMember: `/${loginData.role}/staff-members`,
    reports: `/${loginData.role}/reports`,
    serviceBundles: `/${loginData.role}/service-bundles`,
    corporation: `/${loginData.role}/corporation`,
    consentForm: `/${loginData.role}/consent-forms`,
    serviceType: `/${loginData.role}/service-type`,
    clinicAddresses: `/${loginData.role}/clinic-addresses`,
    sendSms: `/${loginData.role}/send-sms`,
    eventsUpcoming: `/${loginData.role}/events?status=upcoming`,
    eventsPrevious: `/${loginData.role}/events?status=previous`,
    eventCategories: `/${loginData.role}/categories`,
    eventTriggers: `/${loginData.role}/triggers`,
    siteWideTrigger: `/${loginData.role}/triggers`,
  }

  return (
    <div className="bg-brand-blue-light leading-normal">
      { }
      <nav className="relative h-17 w-full border shadow-sm bg-white xl:hidden">
        <div className="flex justify-between items-center">
          { }
          <div className="logo text-center ml-0.5 p-3">
            <img
              className="inline-block h-10"
              src={Logo}
              alt
              width="auto"
            />
          </div>
          { }
          <div className="mr-6">
            <button className="btn-open-primary-menu p-2 rounded border bg-gray-100 hover:bg-gray-50">
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </nav>
      { }
      <nav className="primary-menu-wrapper hidden absolute inset-0 bg-gray-700 bg-opacity-50 z-50 xl:relative xl:bg-transparent xl:block">
        { }
        <div className="relative flex flex-col w-72 h-screen bg-white border shadow-sm z-50 xl:w-full xl:h-full xl:flex-row xl:justify-between xl:items-center">
          { }
          <button className="btn-close-primary-menu absolute top-5 -right-10 xl:hidden">
            <svg
              className="h-6 w-6 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          { }
          <div className="logo text-center p-3">
            <img
              className="inline-block h-10"
              src={Logo}
              alt
              width="auto"
            />
          </div>
          { }
          {/* TODO: Fix hatiham */}
          <div className="menu-staff-profile">
            <div className="flex flex-col flex-nowrap xl:flex-row xl:justify-end xl:mr-6 xl:items-center">
              { }
              <div className="inline-block relative h-16 w-16 mb-2 self-center xl:order-2 xl:flex-shrink-0 xl:w-10 xl:h-10 xl:ml-2 xl:mb-0">
                <img
                  className="h-16 w-16 rounded-full border xl:w-10 xl:h-10"
                  src={loginData.avatar ? loginData.avatar : DefaultAvatar}
                  alt
                />
                <span className="absolute top-0 right-0 h-4 w-4 rounded-full ring-2 ring-white bg-green-400 xl:w-2 xl:h-2"></span>
              </div>
              { }
              <div className="inline-block text-center xl:order-1 xl:text-right">
                <span className="block text-base font-medium leading-1 xl:text-sm">
                  {loginData.firstName
                    ? loginData.firstName + ' ' + loginData.lastName
                    : ''}
                </span>
                <span className="user-role">
                  {renderUserRole()}
                </span>
              </div>
            </div>
          </div>
          <nav className="menu-top-level">
            { }
            <ul className="primary-menu xl:flex xl:justify-start xl:items-center xl:gap-1">

              <li class="menu-item-top-level active" data-component="events">
                <a href="javascript:void(0)">
                  <span class="menu-icon">
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </span>
                  <span>Events</span>
                  <span class="inline-block ml-auto xl:ml-1">
                    <svg class="icon-more h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </span>
                </a>

                <ul class="submenu">
                  <li className={`menu-item-top-level ${getMenuItemActive(ROUTE.eventsUpcoming)}`}>
                    <NavLink
                      exact
                      activeClassName="navbar__link--active"
                      className="navbar__link"
                      to={ROUTE.eventsUpcoming}
                    >
                      Upcoming Events</NavLink></li>
                  <li className={`menu-item-top-level ${getMenuItemActive(ROUTE.eventsPrevious)}`}><NavLink
                    exact
                    activeClassName="navbar__link--active"
                    className="navbar__link"
                    to={ROUTE.eventsPrevious}
                  >Previous Events</NavLink></li>
                  <li><hr class="my-2" /></li>
                  <li className={`menu-item-top-level ${getMenuItemActive(ROUTE.eventCategories)}`}><NavLink
                    exact
                    activeClassName="navbar__link--active"
                    className="navbar__link"
                    to={ROUTE.eventCategories}
                  >Events Categories</NavLink></li>
                  <li className={`menu-item-top-level ${getMenuItemActive(ROUTE.eventTriggers)}`}><NavLink
                    exact
                    activeClassName="navbar__link--active"
                    className="navbar__link"
                    to={ROUTE.eventTriggers}
                  >Triggers & Templates</NavLink></li>
                </ul>
              </li>

            </ul>
          </nav>
        </div>
      </nav>
    </div>
  );
}
export default AppMainNavigation;