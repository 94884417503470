/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from "react";
import {
  createStyles,
  withStyles,
  Input,
  Select,
  Typography,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import Sidebar from "../../common/Header";
import { getRoleAction } from "../../../../utils/actions/role.action";
import { getServiceAction } from "../../../../utils/actions/service.action";
import { addUserAction } from "../../../../utils/actions/admin.user.action";
import styles from "./AddUser.styles";


class AddUser extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      role: "",
      services: [],
    };
  }

  componentDidMount() {
    this.props.getRoleAction();
    this.props.getServiceAction();
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeService = (e) => {
    const services = [...this.state.services];
    services.push(e.target.value);
    this.setState({ services });
  };

  handleSubmit = () => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      role,
      services,
    } = this.state;
    const data = {
      firstName,
      lastName,
      email,
      phoneNumber,
      role,
      services,
    };
    this.props.addUserAction(data);
  };

  render() {
    const { classes } = this.props;
    

    return (
      <>
        <Sidebar />
        <div className={classes.root}>
          <div className={classes.inputField}>
            <Grid container spacing={2}>
              <Grid item lg={3}>
                <div className={classes.inputContainer}>
                  <Typography className={classes.inputText}>
                    First Name
                  </Typography>
                  <Input
                    placeholder="First Name"
                    disableUnderline
                    value={this.state.firstName}
                    name="firstName"
                    className={classes.input}
                    onChange={this.handleChange}
                  />
                </div>
              </Grid>
              <Grid item lg={3}>
                <div className={classes.inputContainer}>
                  <Typography className={classes.inputText}>
                    Last Name
                  </Typography>
                  <Input
                    placeholder="Last Name"
                    disableUnderline
                    value={this.state.lastName}
                    className={classes.input}
                    name="lastName"
                    onChange={this.handleChange}
                  />
                </div>
              </Grid>
              <Grid item lg={3}>
                <div className={classes.inputContainer}>
                  <Typography className={classes.inputText}>Email</Typography>
                  <Input
                    placeholder="Email"
                    disableUnderline
                    value={this.state.email}
                    className={classes.input}
                    name="email"
                    onChange={this.handleChange}
                  />
                </div>
              </Grid>
              <Grid item lg={3}>
                <div className={classes.inputContainer}>
                  <Typography className={classes.inputText}>Phone</Typography>
                  <Input
                    placeholder="Phone"
                    disableUnderline
                    value={this.state.phoneNumber}
                    className={classes.input}
                    name="phoneNumber"
                    onChange={this.handleChange}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={6}>
                <div className={classes.inputContainer}>
                  <Typography className={classes.inputText}>Role</Typography>
                  <Select
                    className={classes.input}
                    value={this.state.role}
                    name="role"
                    onChange={this.handleChange}
                  >
                    {this.props.getAllRole.map((val, i) => (
                      <MenuItem key={i} value={val._id}>
                        {val.role}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </Grid>
              <Grid item lg={6}>
                <div className={classes.inputContainer}>
                  <Typography className={classes.inputText}>Service</Typography>
                  <Select
                    className={classes.input}
                    value={this.state.services[0]}
                    name="services"
                    onChange={this.handleChangeService}
                  >
                    {this.props.getAllService.map((val, i) => (
                      <MenuItem key={i} value={val._id}>
                        {val.description}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </Grid>
            </Grid>
          </div>
          <Button className={classes.createButton} onClick={this.handleSubmit}>
            Create User
          </Button>
        </div>
      </>
    );
  }
}

AddUser.defaultProps = {
  getAllService: [],
  getAllRole: [],
};

const connected = connect(
  (state) => ({
    getAllRole: state.getRole.getRoleData,
    getAllService: state.getService.getServiceData,
    addUserData: state.addUser,
  }),
  {
    getRoleAction,
    getServiceAction,
    addUserAction,
  }
)(AddUser);

const formed = reduxForm({
  form: "addUser",
})(connected);


export default withStyles(styles)(formed);
