import { createStyles } from "@material-ui/core/styles";

export default (theme) => createStyles({
    boxes: {
        display: 'flex',
        marginTop: '40px',
    },
    Backdrop: {
        "& .MuiDrawer-paperAnchorRight": {
            width: "500px",
            paddingLeft: "35px",
            paddingRight: "35px",
        },
    },
    drawerHeader: {
        textAlign: "right"
    },
    closeIcon: {
        color: "#304659",
        fontSize: "28px"
    }
});