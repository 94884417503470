import React, { useEffect, useState } from "react";
import BtnAddRow from "./BtnAddRow";
import TriggersDrawer from "./TriggersDrawer";
import { baseURL } from '../../../utils/axios';
import AlertMsg from "../../../utils/Alert";
import Dialog from '../../../components/atoms/TailwindDialog';
import Axios from 'axios';

let types = {"waitlist-appointment": "Waitlist Appointment",
             "client-document-upload":"Client Document Upload",
            "client-document-verification":"Client Document Verification",
            "client-profile":"Client Profile",
            "user-registration":"User Registration",
            "appointment":"Appointment",
            "invoice":"Invoice",
            "user-registration-otp": "User Registration OTP"}

const EventsTriggers = () => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [trigers, setTriggers] = useState([])
  const [selectedTrigger, setSelectedTrigger] = useState(null)
  const [suspendModel, setSuspendModel] = useState(false);


  useEffect(() => {
    getTriggers()
  }, [])


  const getTriggers = (page) => {
    Axios.get(`${baseURL}/api/triggers?type=appointment`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          setTriggers(res.data)
        }
      })
      .catch((e) => {

      });
  }

  const update = (trigger, data) => {
    Axios.patch(`${baseURL}/api/triggers/${trigger._id}`, data, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          setSelectedTrigger(null)
          getTriggers()
        }
      })
      .catch((e) => {

      });
  }

  return <div>
    <div className="flex flex-col flex-nowrap w-full h-full">
      <div
        className="item p-8 h-full"
        style={{
          maxHeight: "calc(100vh - 72px)"
        }}
      >
        <div className="bg-white w-full h-full p-6">
          <div className="flex flex-row flex-nowrap justify-between items-center mb-4">
            <h1 className="font-semibold text-md mb-2">Triggers & Templates</h1>
            <button
              onClick={() => {
                setSelectedTrigger(null)
                setOpenDrawer(true)
              }}
              className="btn-add-new-trigger px-4 py-2.5 text-white bg-brand-blue text-sm border whitespace-nowrap hover:bg-brand-blue-mid">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline-block h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 4v16m8-8H4"
                />
              </svg>
              Create New Trigger
            </button>
          </div>
          <div className="categories-table">
            <table className="w-full">
              <thead className="text-sm text-left bg-brand-blue text-white">
                <tr>
                  <th className="py-4 px-2 font-bold">Trigger ID</th>
                  <th className="py-4 px-2 font-bold">Assigned Services</th>
                  <th className="py-4 px-2 font-bold">Active Status</th>
                  <th className="py-4 px-2 font-bold">Type</th>
                  <th className="py-4 px-2 font-bold">Triggers</th>
                  <th className="py-4 px-2 font-bold">Actions</th>
                </tr>
              </thead>
              <tbody className>
                {trigers.map(trigger =>
                  <tr key={trigger?._id} className="text-sm text-left border-b">
                    <td className="p-2">{trigger._id}</td>
                    <td className="p-2">
                      {trigger?.services?.map((category) => <p key={category?._id}>{category?.type}</p>)}
                    </td>
                    <td className="p-2">
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${trigger.active ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"}`}>
                        {trigger.active ? "Active" : "Disabled"}
                      </span>
                    </td>
                    <td className="p-2">
                      {types[trigger.type]}
                    </td>
                    <td className="p-2">
                      {trigger?.triggers?.map((trigger) =>
                        <p>
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-gray-800">
                            {trigger.deliveryType == "email" ? "Email" : "SMS"}
                          </span>
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-gray-800">
                            {trigger.eventType == "after" ? "After" : "Before"}
                          </span>
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-gray-800">
                            {trigger.schedule} hours
                          </span>
                        </p>
                      )}
                    </td>
                    <td className="p-2">
                      <button
                        onClick={() => {
                          setSelectedTrigger(trigger)
                          setOpenDrawer(true)
                        }}
                        className="btn btn-edit inline-block p-3 text-yellow-600 bg-yellow-100 rounded-full hover:text-white hover:bg-yellow-600"
                        data-title="Edit"
                        data-placement="top"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                          />
                        </svg>
                      </button>
                      <button
                        onClick={() => {
                          setSelectedTrigger(trigger)
                          setSuspendModel(true)
                        }}
                        className="btn btn-disable ml-1 inline-block p-3 text-red-600 bg-red-100 rounded-full hover:text-white hover:bg-red-600"
                        data-title="disable"
                        data-placement="top"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <Dialog open={suspendModel}
      onClose={() => {
        setSuspendModel(false);
      }}>
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-0 sm:p-6">
            <div className="flex flex-col items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                { }
                <svg
                  className="h-6 w-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div className="mt-2 text-left">
                <h3
                  className="modal-title text-lg leading-6 font-medium text-gray-900 text-center sm:text-left"
                  id="modal-title"
                >
                  Disable Trigger
                </h3>
                <div className="mt-2">
                  <p className="modal-message text-sm text-gray-500">
                    You are about to disable this trigger.{" "}
                    <span className="font-bold">
                      This action will stop the triggers from taking action in
                      all events under the associated category.
                    </span>{" "}
                    You can always reactivate this trigger at any time.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              id="btn-split-submit"
              onClick={() => {
                setSuspendModel(false)
                update(selectedTrigger, { active: false })
              }}
              className="modal-action-btn w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Disable Trigger
            </button>
            <button
              onClick={() => setSuspendModel(false)}
              className="btn-cancel-modal mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-blue sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Dialog>

    <TriggersDrawer trigger={selectedTrigger} open={openDrawer} setOpen={setOpenDrawer} refresh={getTriggers} />
  </div>
};

export default EventsTriggers;
