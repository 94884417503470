import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { DialogContent } from '@material-ui/core';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { baseURL } from '../../../../utils/axios';
import axios from 'axios';
import AlertMsg from '../../../../utils/Alert';
import { userLogin } from '../../../../utils/actions/auth.action';
import { Spinner } from '../../../../components/atoms';
import { connect } from 'react-redux';

function CongratulationDialog(props) {
  const { open, onClose, isReset, verifyOTP, userLogin, history, classes } =
    props;
  useEffect(() => {
    if (verifyOTP) {
      setTimeout(() => loginNewUser(), 3000);
    }
  }, [verifyOTP, open]);

  useEffect(() => {
    setTimeout(() => onClose(), 3000);
  }, [isReset, open]);

  const loginNewUser = () => {
    let loginDataString = localStorage.getItem('userDetails')
      ? localStorage.getItem('userDetails')
      : localStorage.getItem('userDetails-for-otp-redirect');

    if (userLogin) {
      if (loginDataString) {
        const loginData = JSON.parse(loginDataString);

        const data = {
          props: { history },
          email: loginData.email,
          password: loginData.password,
        };
        userLogin(data);
        localStorage.removeItem('userDetails-for-otp-redirect');
        localStorage.removeItem('userDetails');
      }
    }
  };
  return (
    <Dialog aria-labelledby='simple-dialog-title' open={open} onClose={onClose}>
      <DialogContent>
        <Grid style={{ width: '300px' }}>
          <Grid className={classes.icongrid}>
            <CheckCircleOutlineOutlinedIcon className={classes.icon} />
          </Grid>
          <Typography className={classes.bigTxt}>Congratulation!</Typography>
          <Grid style={{ marginBottom: '50px' }}>
            <Typography className={classes.txt}>
              {isReset
                ? 'You password has been reset successfully'
                : 'Your OTP has been verified. Thank you!'}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default CongratulationDialog;
