/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Button, createStyles, withStyles } from "@material-ui/core";
import { EditOutlined, DeleteForeverOutlined } from "@material-ui/icons";
import Sidebar from "../common/Header";
import {
  getRoleAction,
  addRoleAction,
  deleteRoleAction,
  getByIdRoleAction,
  updateRoleAction,
} from "../../../utils/actions/role.action";
import { CustomizedTables, StyledTableCell } from "../common/Table";
import AddPopup from "../common/AddPopup";
import UpdatePopup from "../common/UpdatePopup";
import styles from "./Role.styles";

class Role extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      role: "",
      isAdd: false,
      id: "",
      isUpdate: false,
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.props.getRoleAction();
    this.props.getRoleAction();
  }

  handleSubmit = () => {
    const { role, id, isUpdate, isAdd } = this.state;
    const data = {
      role,
    };
    if (isAdd) {
      this.props.addRoleAction(data);
      this.setState({
        isAdd: false,
        id: "",
        role: "",
      });
    }
    if (isUpdate) {
      this.props.updateRoleAction(id, data);
      this.setState({
        isUpdate: false,
        id: "",
        role: "",
      });
    }
  };

  componentDidUpdate(preProps) {
    if (this.props.addRole !== preProps.addRole) {
      if (this.props.addRole.addRoleData) {
        this.props.getRoleAction();
      }
    }
    if (this.props.updateRoleRes !== preProps.updateRoleRes) {
      if (this.props.updateRoleRes.updateRoleData) {
        this.props.getRoleAction();
      }
    }
    if (this.props.deleteRole !== preProps.deleteRole) {
      if (this.props.deleteRole.deleteRoleData === "") {
        this.props.getRoleAction();
      }
    }
    if (this.props.getByIdRoleData !== preProps.getByIdRoleData) {
      const data = this.props.getByIdRoleData.getByIdRoleData;
      if (data) {
        this.setState({
          isUpdate: true,
          isAdd: false,
          id: data._id,
          role: data.role,
        });
      }
    }
  }

  render() {
    const { classes } = this.props;
    
    const headers = ["Sr No.", "Role", "Status", "Action"];
    const rows = this.props.getAllRole.map((val, i) => (
      <>
        <StyledTableCell align="left">{i + 1}</StyledTableCell>
        <StyledTableCell align="left">{val.role}</StyledTableCell>
        <StyledTableCell align="left">{val.status}</StyledTableCell>
        <StyledTableCell align="left">
          <EditOutlined
            style={{ cursor: "pointer" }}
            onClick={() => this.props.getByIdRoleAction(val._id)}
          />
          <DeleteForeverOutlined
            style={{ cursor: "pointer" }}
            onClick={() => this.props.deleteRoleAction(val._id)}
          />
        </StyledTableCell>
      </>
    ));
    const inputField = [{ lable: "Role", value: "role", fieldType: "input" }];
    const updateInputField = [
      {
        lable: "Role",
        name: "role",
        value: this.state.role,
        fieldType: "input",
      },
    ];
    return (
      <>
        <Sidebar />
        <div className={classes.root}>
          <div>
            {this.state.isUpdate && (
              <UpdatePopup
                headerTitle="Update Role"
                isOpen={this.state.isUpdate}
                inputField={updateInputField}
                onChangeInput={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
                onAdd={this.handleSubmit}
                onCancel={() => this.setState({ isUpdate: false })}
              />
            )}
            {this.state.isAdd ? (
              <>
                <AddPopup
                  headerTitle="Add Role"
                  isOpen={this.state.isAdd}
                  inputField={inputField}
                  onChangeInput={(e) =>
                    this.setState({ [e.target.name]: e.target.value })
                  }
                  onAdd={this.handleSubmit}
                  onCancel={() => this.setState({ isAdd: false })}
                />
                {/* <Typography>Add Role</Typography>
                <Input
                  placeholder="Role"
                  disableUnderline
                  value={this.state.role}
                  className={classes.input}
                  onChange={(e) => this.setState({ role: e.target.value })}
                />
                <Button onClick={this.handleSubmit}>
                  {this.state.isUpdate ? "Update" : "Add"}
                </Button>
                <Button
                  onClick={() =>
                    this.setState({
                      isAdd: false,
                      isUpdate: false,
                      role: "",
                    })
                  }
                >
                  Cancel
                </Button> */}
              </>
            ) : (
              <Button
                className={classes.addButton}
                onClick={() => this.setState({ isAdd: true })}
              >
                Add Role
              </Button>
            )}
          </div>
          <CustomizedTables
            headers={headers}
            rows={rows}
            loaded={this.props.loadData.request}
          />
        </div>
      </>
    );
  }
}

const connected = connect(
  (state) => ({
    getAllRole: state.getRole.getRoleData,
    loadData: state.getRole,
    addRole: state.addRole,
    deleteRole: state.deleteRole,
    getByIdRoleData: state.getByIdRole,
    updateRoleRes: state.updateRole,
  }),
  {
    getRoleAction,
    addRoleAction,
    deleteRoleAction,
    getByIdRoleAction,
    updateRoleAction,
  }
)(Role);

const formed = reduxForm({
  form: "getAllRole",
})(connected);

Role.defaultProps = {
  getAllRole: [],
};



export default withStyles(styles)(formed);
