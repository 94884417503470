import { createStyles } from '@material-ui/core';

export default createStyles({
  root: {
    backgroundColor: '#f7fafc',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    overflow: 'auto',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  header: {
    borderBottom: '1px solid #ccd4de',
    boxShadow: '0 1px 7px 0 rgba(116,135,162,.14)',
    color: '#444',
    backgroundColor: '#fff',
    height: '79px',
    position: 'fixed',
    alignItems: 'center',
  },
  headerIcon: {
    marginLeft: '7px',
    color: '#08c',
    backgroundColor: '#fff',
    cursor: 'pointer',
    // fontWeight: 'bolder',
  },
  headerButton: {
    backgroundColor: '#fff',
    textTransform: 'capitalize',
    borderWidth: '1px',
    borderStyle: 'solid',
    padding: '2px',
    marginLeft: '10px',
  },
  invoiceArea: {
    backgroundColor: '#fff',
    marginBottom: '60px',
    minHeight: '1000px',
    zIndex: -1,
    position: 'absolute',
    top: '79px',
    left: '20%',
    right: '20%',
  },
  invoiceText: {
    float: 'left',
    color: '#000',
    fontSize: '14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});
