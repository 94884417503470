import {
  GET_UNPAID_APPOINTMENT_REQUEST,
  GET_UPCOMING_APPOINTMENT_REQUEST,
  GET_USER_NOTIFICATION_REQUEST,
  UPDATE_USER_NOTIFICATION_REQUEST,
} from "../constant";

export const getUnpaidAppointmentAction = () => ({
  type: GET_UNPAID_APPOINTMENT_REQUEST,
  endpoint: "/api/billing/unpaid",
});

export const getUpcomingAppointmentAction = () => ({
  type: GET_UPCOMING_APPOINTMENT_REQUEST,
  endpoint: "/api/appointment/upcomming",
});

export const getNotificationAction = () => ({
  type: GET_USER_NOTIFICATION_REQUEST,
  endpoint: "/api/notification",
});

export const updateNotificationAction = (id) => ({
  type: UPDATE_USER_NOTIFICATION_REQUEST,
  endpoint: `/api/notification/${id}`,
});
