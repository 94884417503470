/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { makeStyles, Grid } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  viewButton: {
    width: "97px",
    height: "30px",
    borderRadius: "25px",
    backgroundColor: "#f5f5f5",
    border: "1px solid #707070",
    color: "#304659",
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "Lato",
    textAlign: "center",
    cursor: "pointer",
    "&:hover": {
      opacity: "50%",
    },
  },
}));

export const EventViewButton = ({ onClickEvent, ...props }) => {
  const classes = styles();
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={classes.viewButton}
      onClick={() => onClickEvent()}
    >
      {" "}
      {props.children}{" "}
    </Grid>
  );
};

EventViewButton.defaultProps = {
  onClickEvent: () => {},
};
