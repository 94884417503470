import { Grid, Button } from '@material-ui/core';
import styled from 'styled-components';

const DrawingArea = styled(Grid)`
  background-color: #e0f1ff;
  margin-top: 20px;
  margin-bottom: 20px;
`;
const ContainedBtn = styled(Button)`
  background-color: #1964eb;
  color: #fff;
  text-transform: none;
`;
const OutlinedBtn = styled(Button)`
  color: #e3514f;
  border: 1px solid #e3514f;
  text-transform: none;
`;
export { DrawingArea, OutlinedBtn, ContainedBtn };
