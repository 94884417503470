import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {
    marginTop: "77px",
  },
  subContainer: {
    // position: 'absolute',
    // right: 50,
    // left: 0,
    padding: "40px",
    [theme.breakpoints.up("lg")]: {
      // width: "943px",
    },
    [theme.breakpoints.up("xl")]: {
      // width: "1700px",
    },
  },
  eventContainer: {
    color: "#304659",
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: 400,
    fontFamily: "Lato",
    textAlign: "center",
    backgroundColor: "transparent",
  },
  date: {
    fontSize: "22px",
    color: "rgba(34,34,34,50%)",
    fontWeight: 700,
    fontFamily: "Lato",
    marginBottom: "30px",
  },
  time: {
    width: "137px",
    height: "36px",
    backgroundColor: "#f2f5f8",
    border: "1px solid #304659",
    fontSize: "18px",
    color: "#304659",
    fontWeight: 400,
    fontFamily: "Lato",
    textAlign: "center",
    cursor: "pointer",
    marginBottom: "15px",
    marginRight: "25px",
    "&:hover": {
      backgroundColor: "#304659",
      color: "#ffffff",
    },
  },
}));