/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Typography, Checkbox } from "@material-ui/core";
import Loader from "../../../components/Loader/Loader";

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#003d79",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
});

function createData(sr, title, role, action) {
  return {
    sr,
    title,
    role,
    action,
  };
}

export function CustomizedTables({
  headers,
  rows,
  renderRows,
  loaded,
  comment,
  isSelect,
  onSelectAll,
  rowCount,
  numSelected,
}) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {headers.map((val, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <>
                {val === "Select All" ? (
                  <StyledTableCell>
                    <Checkbox
                      style={{ color: "#ffffff" }}
                      onChange={onSelectAll}
                      indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={rowCount > 0 && rowCount === numSelected}
                      inputProps={{ "aria-label": "select all" }}
                    />
                  </StyledTableCell>
                ) : (
                  <StyledTableCell key={`${val}-${i}`} align="left">
                    {val}
                  </StyledTableCell>
                )}
              </>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {comment !== "" ? (
            <p
              style={{
                width: "100%",
                height: "100%",
                margin: "auto",
                position: "absolute",
              }}
            >
              {comment}
            </p>
          ) : loaded ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                margin: "auto",
                position: "absolute",
              }}
            >
              <Loader />
            </div>
          ) : (
            rows.map((data, i) => (
              <StyledTableRow key={i}>{data}</StyledTableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CustomizedTables.defaultProps = {
  headers: [],
  rows: [],
  comment: "",
  renderRows: (val) => val,
};

export default { CustomizedTables, StyledTableCell };
