/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-dupe-keys */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React from "react";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import { Link, Redirect } from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import AddIcon from "@material-ui/icons/Add";
import {
  InputBase,
  IconButton,
  MenuItem,
  Menu,
  Button,
  Divider,
  Container,
} from "@material-ui/core";
import SidebarMenu from "./Sidebar_Menu";
import useStyles from "./Sidebar_style";


export default function ClippedDrawer() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [isLogout, setLogout] = React.useState(false);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    localStorage.removeItem("ACCESS_TOKEN_PATH");
    setLogout(true);
  };
  if (isLogout) {
    return <Redirect push to="/admin/login" />;
  }
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      style={{ marginTop: 45 }}
    >
      <MenuItem onClick={handleMenuClose}>Settings</MenuItem>
      <Divider />
      <MenuItem onClick={handleMenuClose}>Help Center</MenuItem>
      <Divider />
      <MenuItem onClick={handleMenuClose}>continuing Education</MenuItem>
      <Divider />
      <Divider />
      {/* <Link to="/login" style={{ textDecoration: "none", color: "#13191c"}} > */}
      <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
      {/* </Link> */}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <AddIcon />
        </IconButton>
        <p>create</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <ChatBubbleIcon />
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <SettingsIcon />
        </IconButton>
        <p>My Account</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <CssBaseline />
      <Container>
        <div className={classes.root}>
          <AppBar position="fixed" className={classes.appBar} elevation={0}>
            <Toolbar>
              {/* <img src="" /> */}
              <Typography variant="title" className={classes.title} noWrap>
                The Light House
              </Typography>

              {/* ------------------------ */}
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
              </div>
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                <Button
                  className={classes.button}
                  color="inherit"
                  startIcon={<AddIcon />}
                >
                  Create
                </Button>
                <Button
                  className={classes.button}
                  color="inherit"
                  startIcon={<ChatBubbleIcon />}
                >
                  Messages
                </Button>
                <Button
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                  className={classes.button}
                  startIcon={<SettingsIcon />}
                >
                  My Account
                </Button>
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          {renderMobileMenu}
          {renderMenu}
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.toolbar} />
            <SidebarMenu />
          </Drawer>
        </div>
      </Container>
    </>
  );
}
