import {makeStyles} from '@material-ui/core/styles';


export default makeStyles((theme) => ({
    root: {
      // width: "1125px",
      backgroundColor: "rgba(48,70,89, 0.05)",
      // height: '100%',
      position: "fixed",
      right: 0,
      top: "100px",
      bottom: "0px",
      padding: "50px",
      [theme.breakpoints.up("xs")]: {
        width: "calc(100% - 0px)",
      },
      [theme.breakpoints.up("sm")]: {
        width: "calc(100% - 0px)",
      },
      [theme.breakpoints.up("md")]: {
        width: "calc(100% - 415px)",
      },
    },
    item: {
      [theme.breakpoints.up("xs")]: {
        width: "calc(100% - 95px)",
      },
      [theme.breakpoints.up("sm")]: {
        width: "calc(100% - 95px)",
      },
      [theme.breakpoints.up("md")]: {
        width: "calc(100% - 415px)",
      },
      // backgroundColor: "rgba(48,70,89, 50%)",
      // height: '100%',
      position: "absolute",
      right: 0,
      top: "100px",
      // bottom: "0px",
      padding: "50px",
    },
  }));