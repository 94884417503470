/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { baseURL } from '../utils/axios';
import AlertMsg from '../utils/Alert';
import moment from 'moment';
import Axios from 'axios';
import { Grid } from '@material-ui/core';
import DoctorSearchView from './DoctorSearchView';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import Typography from '@material-ui/core/Typography';
import { weekend } from '../utils/timUtils';
import Alert from '@material-ui/lab/Alert';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const ReplicateView = (props) => {
  const getStartDate = (days) => {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + parseInt(days));
    return date;
  };

  const [open, setOpen] = React.useState(false);
  const [allClinician, setAllClinician] = React.useState(false);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [startDate, setStartDate] = useState(getStartDate(1));
  const [endDate, setEndDate] = useState(getStartDate(2));
  const [useRange, setUseRange] = React.useState(false);
  const [showLegends, setShowLegends] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // const forceUpdate = React.useCallback(() => updateState({}), []);
  const [openConfirmation, setConfirmationDialog] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (props.close) {
      props.close();
    }
  };

  const handleSubmit = () => {
    const data = [];
    let slot = props.selectedBlock;
    let now = moment(new Date());

    if (
      useRange &&
      (moment(startDate).startOf('day').isSame(now.startOf('day')) ||
        moment(startDate).startOf('day').isSame(moment(endDate).startOf('day')))
    ) {
      AlertMsg(
        'error',
        'Please select a start date greater then today since there is already a slot'
      );
      return;
    }

    selectedDoctors.forEach((id) => {
      if (slot) {
        delete slot.id;

        if (!useRange) {
          data.push({ ...slot, clinicianId: id });
        } else {
          let loopTil = moment(endDate);
          loopTil.add(1, 'days');
          for (
            var m = moment(startDate);
            m.isBefore(loopTil);
            m.add(1, 'days')
          ) {
            let startTime = moment(slot.startTime, 'HH:mm');
            let endTime = moment(slot.endTime, 'HH:mm');
            let newStart = m.clone();
            let newEnd = m.clone();

            newStart.set({
              hour: startTime.get('hour'),
              minute: startTime.get('minute'),
            });
            newEnd.set({
              hour: endTime.get('hour'),
              minute: endTime.get('minute'),
            });

            if (!weekend.includes(newStart.format('dddd'))) {
              data.push({
                ...slot,
                clinicianId: id,
                startTime: newStart,
                endTime: newEnd,
              });
            }
          }
        }
      }
    });
    console.log('data', data);
    Axios.post(`${baseURL}/api/schedule/updateMultipleSchedules`, [...data], {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          if (props.handleSubmit) {
            props.handleSubmit();
          }
        }
      })
      .catch((e) => {
        if (e.response.data.reason) {
          setErrorMessage(e.response.data.reason);
        }
      });
  };

  const handleDelete = () => {
    Axios.delete(
      `${baseURL}/api/schedule/${props.selectedBlock.calenderSlotId}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        closeDeleteConfirmation();
        props.close();
        props.getShedules();
      })
      .catch((e) => {
        closeDeleteConfirmation();
        props.close();
        if (e.response.data.reason) {
          AlertMsg('error', e.response.data.reason);
        }
      });
  };

  const closeDeleteConfirmation = () => setConfirmationDialog(false);

  return (
    <div>
      <Dialog
        open={props.open ?? props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {'Replicate Calender Slot'}
        </DialogTitle>
        {errorMessage != '' ? (
          <Alert severity='error'>{errorMessage}</Alert>
        ) : (
          <></>
        )}
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            <Grid container xs={12}>
              {!props.isClinician && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={allClinician}
                        onChange={(event) => {
                          setAllClinician(event.target.checked);
                        }}
                        name='checkedB'
                      />
                    }
                    label='Select All Clinician'
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <DoctorSearchView
                  selectAll={allClinician}
                  value={[]}
                  onChange={(data) => {
                    setSelectedDoctors(data);
                    setErrorMessage('');
                  }}
                  clinicianId={props.clinicianId}
                  isClinician={props.isClinician}
                />
              </Grid>
              <Grid style={{ marginTop: '32px' }} item xs={12}>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={useRange}
                      onChange={(event) => {
                        setUseRange(event.target.checked);
                      }}
                      name='checkedB'
                    />
                  }
                  label='Use Range for multiple days'
                />
              </Grid>
              {useRange ? (
                <Grid container xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={6}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant='inline'
                        minDate={getStartDate}
                        format='MM/dd/yyyy'
                        margin='normal'
                        id='date-picker-inline'
                        label='Start Date'
                        value={startDate}
                        onChange={(date) => {
                          if (date < endDate && getStartDate(1) < date) {
                            setStartDate(date);
                          } else {
                            AlertMsg(
                              'error',
                              'Start date should be smaller then End Date'
                            );
                          }
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant='inline'
                        format='MM/dd/yyyy'
                        margin='normal'
                        id='date-picker-inline'
                        label='End Date'
                        value={endDate}
                        onChange={(date) => {
                          if (startDate < date && getStartDate(2) < date) {
                            setEndDate(date);
                          } else {
                            AlertMsg(
                              'error',
                              'Start date should be smaller then End Date'
                            );
                          }
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
              ) : (
                <> </>
              )}

              {showLegends ? (
                <>
                  {' '}
                  <Typography
                    style={{ marginTop: '8px' }}
                    variant='h6'
                    gutterBottom
                  >
                    Calender Blocks
                  </Typography>
                  <Grid style={{ marginTop: '8px' }} item xs={12}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={allClinician}
                          onChange={(event) => {
                            setAllClinician(event.target.checked);
                          }}
                          name='checkedB'
                        />
                      }
                      label='Block'
                    />
                  </Grid>
                  <Grid style={{ marginTop: '8px' }} item xs={12}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={allClinician}
                          onChange={(event) => {
                            setAllClinician(event.target.checked);
                          }}
                          name='checkedB'
                        />
                      }
                      label='Current-Patient'
                    />
                  </Grid>
                  <Grid style={{ marginTop: '8px' }} item xs={12}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={allClinician}
                          onChange={(event) => {
                            setAllClinician(event.target.checked);
                          }}
                          name='checkedB'
                        />
                      }
                      label='All-Patient'
                    />
                  </Grid>
                  <Grid style={{ marginTop: '8px' }} item xs={12}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={allClinician}
                          onChange={(event) => {
                            setAllClinician(event.target.checked);
                          }}
                          name='checkedB'
                        />
                      }
                      label='New-Patient'
                    />
                  </Grid>{' '}
                </>
              ) : (
                <> </>
              )}
              <Grid style={{ marginTop: '32px' }} item xs={12}></Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={() => setConfirmationDialog(true)} color='primary'>
            Delete
          </Button>
          <Button onClick={handleSubmit} color='primary'>
            Replicate
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmation}
        onClose={closeDeleteConfirmation}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete the slot.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteConfirmation} color='primary'>
            Close
          </Button>
          <Button onClick={handleDelete} color='primary' autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ReplicateView;
