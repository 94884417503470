import { Avatar, Grid } from '@material-ui/core';
import styled from 'styled-components';

const Img = styled(Avatar)`
  margin-left: 0px;
`;

const Name = styled(Grid)`
  font-weight: bold;
  white-space: nowrap;
`;

const AgeGroup = styled(Grid)`
  color: #c2c2c3;
`;

const Count = styled(Grid)`
  background-color: #033264;
  color: #fff;
  font-size: 9px;
  padding: 2px 5px !important;
  width: max-content;
  height: fit-content;
  margin-top: 11px;
  border-radius: 4px;
`;

export { Img, Name, Count, AgeGroup };
