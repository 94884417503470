/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { TableNameFont } from "./TableFont";
import { EventNameFonts } from "../Events/common/EventFonts";
import { Loader } from "../../../user/Loader";
import Button from '@material-ui/core/Button';
import styles from "../../common/TableBody_style";

export const TableBody = ({ data, onRowSelect, isLoader, ...props }) => {
  const classes = styles();
  console.log(data)
  return (
    <>
      {isLoader ? (
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ marginTop: "10px" }}
        >
          <Loader />
        </Grid>
      ) : !data.length > 0 ? (
        <Grid
          container
          alignItems="center"
          justify="space-around"
          className={classes.root}
        >
          There is no data
        </Grid>
      ) : 
        data.map((node, i) => {
          return (
            <Grid
              key={i}
              container
              alignItems="center"
              justify="space-around"
              className={classes.root}
              // onClick={() => onRowSelect(true)}
              {...props}
            >
              <Grid
                className={classes.container}
                // alignItems="center"
                // justify="center"
                style={{ width: "20%" }}
              >
                {/* <EventNameFonts
                  text={node.prescribed}
                  style={{
                    width: "145px",
                    display: "inline",
                  }}
                  {...props}
                /> */}
              <EventNameFonts style={{fontSize:'13px', height:'40px',paddingLeft:'7px'}}>{node.prescribed}</EventNameFonts>
              </Grid>
              <Grid
                className={classes.container}
                // alignItems="center"
                // justify="center"
                style={{ width: "15%" }}
              >
                <TableNameFont
                  text={node.dateWritter}
                  style={{
                    marginLeft: "11px",
                    width: "100%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    display: "inline",
                    fontSize:'15px',
                    paddingLeft:'7px'
                  }}
                  {...props}
                />
              {/* <Typography variant='p' component='p'>Appt # C-156d51cd65</Typography> */}
              </Grid>
              <Grid
                className={classes.container}
                // alignItems="center"
                // justify="center"
                style={{ width: "15%" }}
              >
                <TableNameFont
                  text={node.clinician}
                  style={{
                    marginLeft: "11px",
                    width: "100%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    display: "inline",
                    fontSize:'15px',
                    paddingLeft:'7px'
                  }}
                  {...props}
                />
              </Grid>
              <Grid
                className={classes.container}
                // alignItems="center"
                // justify="center"
                style={{ width: "15%" }}
              >
                <TableNameFont
                  text={node.dosage}
                  style={{
                    marginLeft: "11px",
                    width: "100%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    display: "inline",
                    fontSize:'15px',
                    paddingLeft:'7px'
                  }}
                  sasas
                />
              </Grid>
              <Grid
                className={classes.container}
                // alignItems="center"
                // justify="center"
                style={{ width: "15%" }}
              >
                <TableNameFont
                  text={node.insurance}
                  style={{
                    marginLeft: "11px",
                    width: "100%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    display: "inline",
                    fontSize:'15px',
                    paddingLeft:'7px'
                  }}
                  {...props}
                />
              </Grid>
              <Grid className={classes.container} style={{ width: "20%" }}>
                <Button variant="contained" className={'prescriptionViewBtn'}
                  onClick={() => onRowSelect(node.id)}
                >View</Button>
              </Grid>
            </Grid>
          )
        })
      }
      
    </>
  );
};

TableBody.defaultProps = {
  onRowSelect: () => {},
  isLoader: false,
};
