/* eslint-disable no-await-in-loop */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-empty */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-console */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Button, createStyles, withStyles, Checkbox } from "@material-ui/core";
// import { EditOutlined, DeleteForeverOutlined } from '@material-ui/icons';
import Select from "react-select";
import Sidebar from "../common/Header";
import {
  getPermissionAction,
  addPermissionction,
  deletePermissionAction,
  getByIdPermissionAction,
  updatePermissionAction,
  updatePermissionByRoleAction,
} from "../../../utils/actions/permission.action";
import {
  getRoleAction,
  getByIdRoleAction,
} from "../../../utils/actions/role.action";
import { CustomizedTables, StyledTableCell } from "../common/Table";
import AddPopup from "../common/AddPopup";
import UpdatePopup from "../common/UpdatePopup";
import AlertMsg from "../../../utils/Alert";
import styles from "./Permission.styles";
class Permission extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      role: "",
      scope: "",
      title: "",
      isAdd: false,
      id: "",
      allPermission: "",
      isUpdate: false,
      selected: [],
      selectedPost: [],
      selectedGet: [],
      selectedUpdate: [],
      selectedDelete: [],
      urlLink: "",
      urlType: "",
      resource: "",
      description: "",
      rowData: [],
      allSelected: [],
      isGetByRole: false,
      postData: [],
      getData: [],
      updateData: [],
      deleteData: [],
    };
  }

  componentDidMount() {
    this.props.getPermissionAction();
    this.props.getRoleAction();
  }

  handleSubmit = () => {
    const {
      id,
      isUpdate,
      isAdd,
      urlType,
      urlLink,
      resource,
      description,
    } = this.state;
    const data = {
      resource,
      url: `${urlType}:${urlLink}`,
      description,
    };
    if (isAdd) {
      this.props.addPermissionction(data);
      this.setState({
        isAdd: false,
        scope: "",
        id: "",
        title: "",
        role: "",
      });
    }
    if (isUpdate) {
      this.props.updatePermissionAction(id, data);
      this.setState({
        isUpdate: false,
        scope: "",
        id: "",
        title: "",
        role: "",
      });
    }
  };

  componentDidUpdate(preProps) {
    if (this.props.saveWithRoleData !== preProps.saveWithRoleData) {
      if (this.props.saveWithRoleData.updatePermissionByRoleData) {
        this.setState({ isGetByRole: true });
        this.props.getByIdRoleAction(this.state.role);
      }
    }

    if (this.props.getAllPermissionByRole !== preProps.getAllPermissionByRole) {
      if (this.props.getAllPermissionByRole.length === 0) {
        this.setState({
          postData: [],
          getData: [],
          updateData: [],
          deleteData: [],
        });
      }
      for (let i = 0; i < this.props.getAllPermissionByRole.length; i++) {
        const urlType =
          this.props.getAllPermissionByRole &&
          this.props.getAllPermissionByRole[i].url.split(":");
        if (
          urlType[0] === "POST" &&
          (this.props.getAllPermissionByRole[i].resource === "services" ||
            "client" ||
            "appointment" ||
            "billing")
        ) {
          this.state.postData.push({
            resource: this.props.getAllPermissionByRole[i].resource,
            id: this.props.getAllPermissionByRole[i]._id,
          });
        }
      }
      for (let i = 0; i < this.props.getAllPermissionByRole.length; i++) {
        const urlType =
          this.props.getAllPermissionByRole &&
          this.props.getAllPermissionByRole[i].url.split(":");
        if (
          urlType[0] === "GET" &&
          (this.props.getAllPermissionByRole[i].resource === "services" ||
            "client" ||
            "appointment" ||
            "billing")
        ) {
          this.state.getData.push({
            resource: this.props.getAllPermissionByRole[i].resource,
            id: this.props.getAllPermissionByRole[i]._id,
          });
        }
      }
      for (let i = 0; i < this.props.getAllPermissionByRole.length; i++) {
        const urlType =
          this.props.getAllPermissionByRole &&
          this.props.getAllPermissionByRole[i].url.split(":");
        if (
          urlType[0] === "PATCH" &&
          (this.props.getAllPermissionByRole[i].resource === "services" ||
            "client" ||
            "appointment" ||
            "billing")
        ) {
          this.state.updateData.push({
            resource: this.props.getAllPermissionByRole[i].resource,
            id: this.props.getAllPermissionByRole[i]._id,
          });
        }
      }
      for (let i = 0; i < this.props.getAllPermissionByRole.length; i++) {
        const urlType =
          this.props.getAllPermissionByRole &&
          this.props.getAllPermissionByRole[i].url.split(":");
        if (
          urlType[0] === "DELETE" &&
          (this.props.getAllPermissionByRole[i].resource === "services" ||
            "client" ||
            "appointment" ||
            "billing")
        ) {
          this.state.deleteData.push({
            resource: this.props.getAllPermissionByRole[i].resource,
            id: this.props.getAllPermissionByRole[i]._id,
          });
        }
      }
    }

    if (
      this.props.getAllPermission !== preProps.getAllPermission ||
      this.props.getAllPermissionByRole !== preProps.getAllPermissionByRole
    ) {
      const rowData = Object.values(
        this.props.getAllPermission.reduce((r, c) => {
          r[c.resource] = c;
          return r;
        }, {})
      );
      this.setState({ rowData, isGetByRole: true });
      if (this.state.isGetByRole === false) {
        const rowData = Object.values(
          this.props.getAllPermission.reduce((r, c) => {
            r[c.resource] = c;
            return r;
          }, {})
        );
        this.setState({ rowData });
      }
    }
    if (this.props.addPermission !== preProps.addPermission) {
      if (this.props.addPermission._id) {
        this.props.getPermissionAction();
      }
    }
    if (this.props.updatePermissionRes !== preProps.updatePermissionRes) {
      if (this.props.updatePermissionRes.message === "success") {
        this.props.getPermissionAction();
      }
    }
    if (this.props.getAllPermission !== preProps.getAllPermission) {
      if (this.props.getAllPermission.length > 0) {
        this.setState({ allPermission: this.props.getAllPermission });
      }
    }
    if (this.props.getByIdPermissionData !== preProps.getByIdPermissionData) {
      const data = this.props.getByIdPermissionData.getByIdPermissionData;
      if (data) {
        this.setState({
          isUpdate: true,
          isAdd: false,
          id: data._id,
          title: data.title,
          scope: data.scope,
          role: data.role && data.role._id,
        });
      }
    }
  }

  handleRoleChange = async (role) => {
    this.setState({
      role: role._id,
      selectedPost: [],
      selectedGet: [],
      selectedUpdate: [],
      selectedDelete: [],
      postData: [],
      selected: [],
      allSelected: [],
    });
    this.props.getByIdRoleAction(role._id);
  };

  handleGetAllPermission = () => {
    this.setState({ isGetByRole: false });
    this.props.getPermissionAction();
  };

  handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = this.state.rowData.map((val) => val._id);
      const allSelect = this.state.isGetByRole
        ? this.props.getAllPermissionByRole.map((val) => val._id)
        : this.props.getAllPermission.map((val) => val._id);
      this.setState({ selected: newSelected, allSelected: allSelect });
      return;
    }
    this.setState({ selected: [] });
  };

  handleSelect = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handlePermissionChange = async () => {
    if (this.state.isGetByRole) {
      this.props.updatePermissionByRoleAction(this.state.role, {
        scopes: [].concat(
          this.state.postData.map((val) => val.id),
          this.state.getData.map((val) => val.id),
          this.state.updateData.map((val) => val.id),
          this.state.deleteData.map((val) => val.id),
          this.state.allSelected.map((val) => val.id)
        ),
      });
      await this.setState({
        postData: [],
        getData: [],
        updateData: [],
        deleteData: [],
        allSelected: [],
        selected: [],
      });
    }
  };

  handleSelectPost = async (event, id, index) => {
    const newData = [...this.state.rowData];
    newData[index] = {
      ...this.state.rowData[index],
      isTrue: event.target.checked,
    };
    this.setState({ rowData: newData });

    if (event.target.checked) {
      for (let i = 0; i < this.props.getAllPermission.length; i++) {
        const urlType =
          this.props.getAllPermission &&
          this.props.getAllPermission[i].url.split(":");
        if (
          urlType[0] === "POST" &&
          this.props.getAllPermission[i].resource === id
        ) {
          await this.setState({
            postData: [
              ...this.state.postData,
              {
                resource: this.props.getAllPermission[i].resource,
                id: this.props.getAllPermission[i]._id,
              },
            ],
          });
        }
      }
    } else {
      for (let i = 0; i < this.state.postData.length; i++) {
        if (this.state.postData[i].resource === id) {
          const newData = this.state.postData.filter(
            (val) => val.resource !== id
          );
          this.setState({ postData: newData });
        }
      }
    }
  };

  handleSelectGet = async (event, id, index) => {
    const newData = [...this.state.rowData];
    newData[index] = {
      ...this.state.rowData[index],
      isTrue: event.target.checked,
    };
    this.setState({ rowData: newData });
    if (event.target.checked) {
      for (let i = 0; i < this.props.getAllPermission.length; i++) {
        const urlType =
          this.props.getAllPermission &&
          this.props.getAllPermission[i].url.split(":");
        if (
          urlType[0] === "GET" &&
          this.props.getAllPermission[i].resource === id
        ) {
          await this.setState({
            getData: [
              ...this.state.getData,
              {
                resource: this.props.getAllPermission[i].resource,
                id: this.props.getAllPermission[i]._id,
              },
            ],
          });
        }
      }
    } else {
      for (let i = 0; i < this.state.getData.length; i++) {
        if (this.state.getData[i].resource === id) {
          const newData = this.state.getData.filter(
            (val) => val.resource !== id
          );
          this.setState({ getData: newData });
        }
      }
    }
  };

  handleSelectPatch = async (event, id, index) => {
    const newData = [...this.state.rowData];
    newData[index] = {
      ...this.state.rowData[index],
      isTrue: event.target.checked,
    };
    this.setState({ rowData: newData });
    if (event.target.checked) {
      for (let i = 0; i < this.props.getAllPermission.length; i++) {
        const urlType =
          this.props.getAllPermission &&
          this.props.getAllPermission[i].url.split(":");
        if (
          urlType[0] === "PATCH" &&
          this.props.getAllPermission[i].resource === id
        ) {
          await this.setState({
            updateData: [
              ...this.state.updateData,
              {
                resource: this.props.getAllPermission[i].resource,
                id: this.props.getAllPermission[i]._id,
              },
            ],
          });
        }
      }
    } else {
      for (let i = 0; i < this.state.updateData.length; i++) {
        if (this.state.updateData[i].resource === id) {
          const newData = this.state.updateData.filter(
            (val) => val.resource !== id
          );
          this.setState({ updateData: newData });
        }
      }
    }
  };

  handleSelectDelete = async (event, id, index) => {
    const newData = [...this.state.rowData];
    newData[index] = {
      ...this.state.rowData[index],
      isTrue: event.target.checked,
    };
    this.setState({ rowData: newData });
    if (event.target.checked) {
      for (let i = 0; i < this.props.getAllPermission.length; i++) {
        const urlType =
          this.props.getAllPermission &&
          this.props.getAllPermission[i].url.split(":");
        if (
          urlType[0] === "DELETE" &&
          this.props.getAllPermission[i].resource === id
        ) {
          await this.setState({
            deleteData: [
              ...this.state.deleteData,
              {
                resource: this.props.getAllPermission[i].resource,
                id: this.props.getAllPermission[i]._id,
              },
            ],
          });
        }
      }
    } else {
      for (let i = 0; i < this.state.deleteData.length; i++) {
        if (this.state.deleteData[i].resource === id) {
          const newData = this.state.deleteData.filter(
            (val) => val.resource !== id
          );
          this.setState({ deleteData: newData });
        }
      }
    }
  };

  render() {
    const { classes } = this.props;
    const headers = [
      "Sr No.",
      "Title",
      "Create",
      "Read",
      "Update",
      "Delete",
      "Select All",
    ];
    const rows = this.state.rowData.map((val, i) => (
      <>
        <StyledTableCell align="left">{i + 1}</StyledTableCell>
        <StyledTableCell align="left">{val.resource}</StyledTableCell>
        <StyledTableCell align="left">
          <Checkbox
            style={{ color: "#003d79" }}
            onChange={(event) => this.handleSelectPost(event, val.resource, i)}
            checked={this.state.postData.some(
              (data) => data.resource === val.resource
            )}
          />
        </StyledTableCell>
        <StyledTableCell align="left">
          <Checkbox
            style={{ color: "#003d79" }}
            onChange={(event) => this.handleSelectGet(event, val.resource, i)}
            checked={this.state.getData.some(
              (data) => data.resource === val.resource
            )}
          />
        </StyledTableCell>
        <StyledTableCell align="left">
          <Checkbox
            style={{ color: "#003d79" }}
            onChange={(event) => this.handleSelectPatch(event, val.resource, i)}
            checked={this.state.updateData.some(
              (data) => data.resource === val.resource
            )}
          />
        </StyledTableCell>
        <StyledTableCell align="left">
          <Checkbox
            style={{ color: "#003d79" }}
            onChange={(event) =>
              this.handleSelectDelete(event, val.resource, i)
            }
            checked={this.state.deleteData.some(
              (data) => data.resource === val.resource
            )}
          />
        </StyledTableCell>
        <StyledTableCell align="left">
          <Checkbox
            style={{ color: "#003d79" }}
            onChange={(event) => this.handleSelect(event, val._id)}
            checked={this.state.selected.indexOf(val._id) !== -1}
          />
        </StyledTableCell>
      </>
    ));

    const inputField = [
      {
        lable: "Resource",
        value: "resource",
        fieldType: "select",
        selectData: [
          { _id: "appointment", title: "Appointment" },
          { _id: "billing", title: "Billing" },
          { _id: "client", title: "Client" },
          { _id: "services", title: "Services" },
        ],
      },
      {
        lable: "Url Type",
        value: "urlType",
        fieldType: "select",
        selectData: [
          { _id: "POST", title: "Create" },
          { _id: "GET", title: "Read" },
          { _id: "PATCH", title: "Update" },
          { _id: "DELETE", title: "Delete" },
        ],
      },
      { lable: "Url Link", value: "urlLink", fieldType: "input" },
      { lable: "Description", value: "description", fieldType: "input" },
    ];
    const updateInputField = [
      {
        lable: "Title",
        name: "title",
        value: this.state.title,
        fieldType: "input",
      },
      {
        lable: "Scope",
        name: "scope",
        value: this.state.scope,
        fieldType: "input",
      },
      {
        lable: "Role",
        name: "role",
        value: this.state.role,
        fieldType: "select",
        selectData: this.props.getAllRole.map((val) => ({
          _id: val._id,
          title: val.role,
        })),
      },
    ];

    return (
      <>
        <Sidebar />
        <div className={classes.root}>
          <>
            {this.state.isUpdate && (
              <UpdatePopup
                headerTitle="Update Permission"
                isOpen={this.state.isUpdate}
                inputField={updateInputField}
                onChangeInput={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
                onAdd={this.handleSubmit}
                onCancel={() => this.setState({ isUpdate: false })}
              />
            )}
            {this.state.isAdd || this.state.isUpdate ? (
              <>
                <AddPopup
                  headerTitle="Add Permission"
                  isOpen={this.state.isAdd}
                  inputField={inputField}
                  onChangeInput={(e) =>
                    this.setState({ [e.target.name]: e.target.value })
                  }
                  onAdd={this.handleSubmit}
                  onCancel={() => this.setState({ isAdd: false })}
                />
              </>
            ) : (
              <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                <Select
                  placeholder="Select Role"
                  className={classes.selectRole}
                  options={this.props.getAllRole}
                  getOptionLabel={(option) => option.role}
                  getOptionValue={(option) => option._id}
                  onChange={this.handleRoleChange}
                />
              </div>
            )}
          </>
          <CustomizedTables
            numSelected={this.state.selected.length}
            rowCount={rows.length}
            onSelectAll={this.handleSelectAll}
            headers={headers}
            rows={rows}
            loaded={
              this.state.isGetByRole
                ? this.props.loadDataByRole.request
                : this.props.loadData.request
            }
          />
          <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
            <Button
              className={classes.addButton}
              onClick={() =>
                this.state.role
                  ? this.handlePermissionChange()
                  : AlertMsg("error", "Please select role")
              }
            >
              Save Changes
            </Button>
          </div>
        </div>
      </>
    );
  }
}

const connected = connect(
  (state) => ({
    getAllPermission: state.getPermission.getPermissionData,
    getAllPermissionByRole: state.getByIdRole.getByIdRoleData
      ? state.getByIdRole.getByIdRoleData.scopes
      : [],
    loadData: state.getPermission,
    loadDataByRole: state.getByIdRole,
    getAllRole: state.getRole.getRoleData,
    addPermission: state.addPermission.addPermissionData,
    deletePermission: state.deletePermission,
    getByIdPermissionData: state.getByIdPermission,
    updatePermissionRes: state.updatePermission.updatePermissionData,
    saveWithRoleData: state.updatePermissionByRole,
  }),
  {
    getPermissionAction,
    getRoleAction,
    addPermissionction,
    deletePermissionAction,
    getByIdPermissionAction,
    updatePermissionAction,
    updatePermissionByRoleAction,
    getByIdRoleAction,
  }
)(Permission);

const formed = reduxForm({
  form: "getAllPermission",
})(connected);

Permission.defaultProps = {
  getAllPermission: [],
  getAllRole: [],
};


export default withStyles(styles)(formed);
