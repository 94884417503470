import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import DrawerMenu from '../common/Header/DrawerMenu';
import { GridContainer, GridItem } from '../common/BodyContainer';
import { Events, EventFilters } from '../../components/molecules';
import { Img, ImgGrid, Heading, FilterGrid } from './Events.style';
import { baseURL } from '../../utils/axios';
import AlertMsg from '../../utils/Alert';
import Axios from 'axios';

const PatientEvents = (props) => {
  const [isLoading, setIsloading] = useState(false);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('upcoming');
  const [searchText, setSearchText] = useState('');
  const [sortType, setSortType] = useState('desc');
  const [sortBy, setSortBy] = useState('');
  const [publicEvents, setPublicEvents] = useState([]);
  const [publicEventsCount, setPublicEventsCount] = useState(0);

  useEffect(() => {
    setIsloading(true);
    getEventsExceptPatientCorporation();
  }, [status, searchText, page, sortType, sortBy]);

  const handleChange = (event, value) => {
    let val = value.props.value;
    setStatus(val);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getEventsExceptPatientCorporation = () => {
    const requestUrl = `${baseURL}/api/event/except-client-corporation?page=${page}&term=${searchText}&status=${status}&sortType=${sortType}&sortBy=${sortBy}`;

    Axios.get(requestUrl, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          const usersRegisteredEvents = res.data.rsvpedEvents[0];

          let formattedEvents = [];
          res.data.publicEvents.events.forEach((event) => {
            formattedEvents.push({
              ...event,
              isLoggedInUserRegistered: usersRegisteredEvents.some(
                (ur) => ur.eventId === event._id
              ),
            });
          });

          setPublicEvents(formattedEvents);
          setPublicEventsCount(res.data.publicEvents.count);
          setIsloading(false);
        }
      })
      .catch((e) => {
        setIsloading(false);
        AlertMsg('error', e.toString());
      });
  };

  const sort = (key) => {
    const toggleType = sortType === 'asc' ? 'desc' : 'asc';
    setSortType(toggleType);
    setSortBy(key);
  };

  const onTextSearch = (e) => setSearchText(e.target.value);
  return (
    <Grid>
      <DrawerMenu {...props} />
      <GridContainer />
      <GridItem
        {...props}
        style={{
          bottom: 0,
        }}
      >
        <FilterGrid>
          <EventFilters
            onTextSearch={onTextSearch}
            handleChange={handleChange}
          />
        </FilterGrid>

        <Grid justify='flex-start' container item>
          <Events
            {...props}
            sort={sort}
            handlePageChange={handlePageChange}
            page={page}
            eventLists={publicEvents}
            totalCount={publicEventsCount}
            isLoaded={isLoading}
            onTextSearch={onTextSearch}
            handleChange={handleChange}
            showEdit={false}
            showRSVP={false}
            isClinician={false}
          />
        </Grid>
      </GridItem>
    </Grid>
  );
};

export default PatientEvents;
