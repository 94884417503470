import React, { useState } from 'react';
import {
  Drawer,
  Grid,
  Typography,
  Button,
  OutlinedInput,
  InputAdornment,
  MenuItem,
  IconButton,
  FormControl,
  Select,
} from '@material-ui/core';
import useStyles from './Events.styles';
import SearchIcon from '@material-ui/icons/Search';
import FilterImage from '../../../assets/clinician_image/Group 351@2x.png';

const EventFilter = ({ onTextSearch, handleChange }) => {
  const [page, setPage] = React.useState(1);

  const classes = useStyles();
  return (
    <Grid container>
      <Grid xs={6}>
        <OutlinedInput
          className={classes.inputstyle}
          style={{ float: 'left' }}
          placeholder='Search'
          onChange={onTextSearch}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                onClick={() => {
                  setPage(1);
                }}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        <FormControl
          variant='outlined'
          className={classes.formControl}
          style={{
            width: '200px',
          }}
        >
          <Select
            className={classes.inputstyle}
            defaultValue='upcoming'
            onChange={handleChange}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value='upcoming'>Upcoming</MenuItem>
            <MenuItem value='previous'>Previous</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={6}>
        <div style={{ float: 'right' }}>
          <Button
            variant='contained'
            style={{
              height: '49px',
              overflow: 'hidden',
              maxWidth: '47px',
              minWidth: 'unset',
              marginLeft: '12px',
            }}
            onClick={() => {}}
            className={classes.btnNewApp}
          >
            <img src={FilterImage} width='50' />
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default EventFilter;
