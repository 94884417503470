import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { Input, Label, Error, Required } from './style';

const TextField = ({
  label,
  name,
  value,
  onChange,
  type,
  error,
  isRequired,
  bgcolor,
  color,
  disabled,
}) => {
  return (
    <Grid direction='column' alignItems='flex-start' container item>
      {label && (
        <Label>
          {label}
          {isRequired && <Required>*</Required>}
        </Label>
      )}
      <Input
        value={value}
        onChange={onChange}
        type={type}
        name={name}
        bgcolor={bgcolor}
        color={color}
        disabled={disabled}
      />
      {error && <Error>{error}</Error>}
    </Grid>
  );
};

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  isRequired: PropTypes.bool,
};

TextField.defaultProps = {
  type: 'text',
  value: '',
  bgcolor: 'transparent',
  disabled: false,
  color: '#000',
};

export default TextField;
