import React from 'react';
import '../../../clinician/clinician.scss';
import Sidebar from './Sidebar';
import Drawer from '@material-ui/core/Drawer';
import SidebarMenu from './SidebarMenu';
import useStyles from './AdminLayout.styles';
import SessionExpireAlert from '../../../user/SessionExpireAlert';

export default function Layout({ ...props }) {
  const {
    children,
    appointments,
    filterAppointment,
    filterId,
    filterPatients,
    patientFilterId,
    updatedAvatar,
  } = props;
  const classes = useStyles();
  return (
    <>
      {process.env.REACT_APP_ENV === 'production' && <SessionExpireAlert />}
      <Sidebar appointments={appointments} />
      <div className='main-content' style={{ minHeight: '100vh' }}>
        <Drawer
          className={classes.drawe}
          variant='permanent'
          classes={{
            paper: `${classes.drawerPaper} sideDrawer`,
          }}
          id='sideBar'
        >
          <SidebarMenu
            filterAppointment={filterAppointment}
            filterId={filterId}
            filterPatients={filterPatients}
            patientFilterId={patientFilterId}
            updatedAvatar={updatedAvatar}
          />
        </Drawer>
        {children}
      </div>
    </>
  );
}
