/* eslint-disable no-console */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import TextField from "@material-ui/core/TextField";
import { Typography, Container, CssBaseline, Button } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import validator from "validator";
import { Clear } from "@material-ui/icons";
import { sendMailAction } from "../utils/actions/client.action";
import AlertMsg from "../utils/Alert";
import styles from "./SendMail_style";

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  formVerification = () => {
    const user = validator.isEmpty(this.state.email, {
      ignore_whitespace: true,
    });
    if (user) {
      AlertMsg("error", "All field must be filled");
      return false;
    }
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { email } = this.state;
    const isVerify = this.formVerification();
    if (isVerify) {
      const data = {
        email,
      };
      const { history } = this.props;
      this.props.sendMailAction(data, history);
    }
  };

  render() {
    const { email } = this.state;
    const { classes } = this.props;
    console.log("mail=========", this.props.sendMail);

    return (
      <>
        <Clear
          onClick={() => this.props.history.goBack()}
          style={{
            fontSize: "20px",
            lineHeight: "33px",
            color: "#777",
            cursor: "pointer",
            margin: "15px",
            display: "flex",
            flexDirection: "row-reverse",
          }}
        />
        <Container component="main" maxWidth="sm">
          <CssBaseline />

          <Typography className={classes.heading}>
            Welcome back to Light House.
          </Typography>
          <div className={classes.paper}>
            <form className={classes.form}>
              <Typography className={classes.title}>Patient Email</Typography>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="email"
                value={email}
                onChange={this.handleChange}
                autoFocus
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
              {/* <Typography className={classes.title}>Password</Typography>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              type="password"
              value={password}
              onChange={this.handleChange}
              autoComplete="current-password"
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
            /> */}
              {/* <div style={{ textAlign: "left" }}>
              <FormControlLabel
                control={<Checkbox value="remember" color="#1976d2" />}
                label="Remember me"
              />
            </div> */}
              <Button
                fullWidth
                type="submit"
                onClick={this.handleSubmit}
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Send Mail
              </Button>
              {/* <Grid container>
              <Grid item xs style={{ textAlign: "left" }}>
                <Link href="#" variant="body2" className={classes.linksty}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link to="/sign-up" variant="body2" className={classes.linksty}>
                  Don't have an account? Sign Up
                </Link>
              </Grid>
            </Grid> */}
            </form>
          </div>
        </Container>
      </>
    );
  }
}

const connected = connect(
  (state) => ({
    sendMail: state.sendMail,
  }),
  { sendMailAction }
)(SignIn);
const formed = reduxForm({
  form: "sendMail",
})(connected);



export default withStyles(styles)(formed);
