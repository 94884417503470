/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from "react";
import { Grid, withStyles } from "@material-ui/core";
import {
  DrawerTitleFont,
  DrawerReferredFont,
  DrawerNameFont,
  DrawerReferredClinicianFont,
} from "../../common/DrawerContent/DrawerFont";
import { CustomImage } from "../../common/TableImageContainer";
import logo from "../../../assets/imgs/Ellipse 13.png";
import logo1 from "../../../assets/imgs/ananyaBig.png";
import arrow from "../../../assets/imgs/Border.png";
import { DrawerBoxClinicianImg } from "../../common/DrawerContent/DrawerImage";
import { ClinicianButton } from "../../common/DrawerContent/CustomButton";
import { CustomCalendar } from "../Calendar/Calendar";
import arrowUp from "../../../assets/imgs/Border (4).svg";
import styles from "./AppointmnetRequest_style";

class AppointmnetRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
    };
  }

  render() {
    const { classes, data } = this.props;
    return (
      <>
        <DrawerTitleFont
          text="Appointment Request"
          style={{ marginBottom: "26px" }}
        />
        <Grid
          container
          alignItems="center"
          justify="flex-start"
          className={classes.headerContainer}
        >
          <CustomImage logo={logo} />
          <Grid alignItems="center" className={classes.headerSubContainer}>
            <DrawerReferredFont text="Referred by" />
            <DrawerReferredClinicianFont text="Dr. Sheetal Kini, Ph.D." />
          </Grid>
        </Grid>
        <Grid className={classes.root}>
          <Grid
            container
            alignItems="center"
            className={classes.bodyHeaderContainer}
          >
            <Grid>
              <DrawerNameFont
                text="Dr. Roberta Fidelle"
                style={{ fontSize: "18px" }}
              />
              <DrawerReferredFont
                text="Specialist Psychiatrist"
                style={{ fontSize: "16px" }}
              />
            </Grid>
            <Grid
              container
              alignItems="center"
              justify="flex-end"
              className={classes.bodyImageContainer}
            >
              <DrawerBoxClinicianImg logo={logo1} />
            </Grid>
          </Grid>
          <Grid className={classes.line} />
          <Grid
            container
            alignItems="center"
            justify="space-between"
            style={{ marginBottom: "14px" }}
          >
            <DrawerNameFont
              text="Next Availability (07/15/2020)"
              style={{ fontSize: "18px", color: "rgba(34,34,34,50%)" }}
            />
            {/* <ClinicianButton
              text="Cost: 195 Dhs / 30min"
              style={{ position: "absolute", right: "92px" }}
            /> */}
          </Grid>

          <Grid style={{ display: "flex", marginBottom: "45px" }}>
            {["9:00 AM", "10:00 AM", "10:30 AM", "11:00 AM"].map(
              (node, index) => (
                <Grid
                  key={index}
                  container
                  alignItems="center"
                  justify="center"
                  className={classes.timeContainer}
                >
                  {node}
                </Grid>
              )
            )}
          </Grid>

          <Grid container alignItems="center" justify="flex-start">
            {this.state.isShow ? (
              <>
                <DrawerNameFont
                  onClick={() => this.setState({ isShow: !this.state.isShow })}
                  text="Hide calendar"
                  style={{ fontSize: "18px", cursor: "pointer" }}
                />
                <img
                  src={arrowUp}
                  onClick={() => this.setState({ isShow: !this.state.isShow })}
                  style={{ marginLeft: "30px", cursor: "pointer" }}
                />
              </>
            ) : (
              <>
                <DrawerNameFont
                  onClick={() => this.setState({ isShow: !this.state.isShow })}
                  text="Show calender"
                  style={{ fontSize: "18px", cursor: "pointer" }}
                />
                <img
                  src={arrow}
                  onClick={() => this.setState({ isShow: !this.state.isShow })}
                  style={{ marginLeft: "30px", cursor: "pointer" }}
                />
              </>
            )}
          </Grid>
        </Grid>
        {this.state.isShow && <CustomCalendar />}
      </>
    );
  }
}

export default withStyles(styles)(AppointmnetRequest);
