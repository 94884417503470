import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
    paddingLeft: theme.spacing(35),
  },
  title: {
    textAlign: 'left',
    fontSize: 18,
    fontWeight: 400,
    color: '#333',
    fontFamily: '"Open Sans",Helvetica,Arial,sans-serif',
  },
  namesty: {
    color: '#08c',
    textDecoration: 'none',
    fontSize: 18,
    fontWeight: 400,
    fontFamily: '"Open Sans",Helvetica,Arial,sans-serif',
  },
  heading: {
    fontWeight: 600,
    width: 85,
    fontSize: 12,
    color: '#333',
    fontFamily: '"Open Sans", sans-serif',
    textAlign: 'left',
  },
  subheading: {
    fontSize: 12,
    fontWeight: 'normal',
    color: '#333',
    fontFamily: '"Open Sans", sans-serif',
    textAlign: 'left',
  },
  gridsty: {
    marginTop: theme.spacing(3),
  },
  progressSty: {
    fontSize: 14,
    fontWeight: 600,
    color: '#000',
    fontFamily: 'inherit',
    textAlign: 'left',
    marginTop: theme.spacing(4),
  },
  selectSty: {
    // margin: theme.spacing(3),
    width: '80%',
    height: 40,
    textAlign: 'left',
  },
  editorSty: {
    border: '1px solid #ccc',
    borderWidth: '1px',
    borderColor: '#eceff1 !importan',
    borderStyle: 'solid',
    borderRadius: '3px',
    height: '50vh',
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
  editorText: {
    fontWeight: 600,
    color: '#777',
    fontSize: '11px',
  },
  cancelButton: {
    color: '#4b5459',
    borderColor: '#9b9fa1',
    backgroundColor: '#ffffff',
    fontSize: 12,
    fontWeight: 600,
    cursor: 'pointer',
    textAlign: 'center',
    fontFamily: '"Open Sans", sans-serif',
    marginRight: theme.spacing(3),
  },
  saveButton: {
    backgroundColor: '#1371c8',
    borderColor: '#1371c8',
    minWidth: '70px',
    width: 'auto',
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 600,
    color: '#ffffff',
    textAlign: 'center',
    fontFamily: '"Open Sans", sans-serif',
    marginRight: theme.spacing(3),
  },
  buttonDiv: {
    position: 'relative',
    zIndex: 1,
    padding: theme.spacing(2),
    border: '2px solid #f0f0f2',
    width: '67%',
    textAlign: 'right',
    backgroundColor: '#eceff1',
    borderRadius: 4,
  },
  dividerSty: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    width: '90%',
  },
  physubText: {
    color: '#999',
    fontSize: '12px !important',
    fontWeight: 600,
    textAlign: 'left',
  },
});
