import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  root: {
    padding: theme.spacing(4),
  },
  formControl: {
    // margin: theme.spacing(3),
    width: '80%',
    height: 40,
    borderRadius: 3,
    borderColor: '#cfd8dc',
    borderWidth: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'inline-block',
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#1976d2 !important',
  },
  button: {
    display: 'inline-block',
    padding: theme.spacing(0.9),
    // marginLeft: theme.spacing(4)
  },
  title: {
    fontSize: 17,
    fontWeight: 400,
    color: '#08c',
    fontFamily: 'inherit',
    textAlign: 'left',
  },
  textStyle: {
    fontSize: 15,
    fontWeight: 'bold',
    // color: "#08c",
    fontFamily: 'inherit',
    textAlign: 'left',
  },
  righttextinput: {
    textAlign: 'left',
    // paddingTop: theme.spacing(6),
  },
  dateInput: {
    width: '100%',
    height: 38,
    borderRadius: 3,
    borderStyle: 'solid',
    borderColor: '#cccccc',
    borderWidth: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'inline-block',
    textAlign: 'center',
    // marginLeft: theme.spacing(2),
  },
  dividersty: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  gridsty: {
    marginTop: theme.spacing(2),
  },
  alldaysty: {
    cursor: 'pointer',
    color: '#13191c',
    fontSize: 13,
    fontWeight: 400,
  },
  notesty: {
    fontWeight: 600,
    color: '#13191c',
    fontSize: 13,
  },
  divstyle: {
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#eceff1',
    height: 38,
    borderRadius: 5,
    // marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1),
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#1976d2 !important',
  },
});
