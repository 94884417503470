import React from 'react';
import Layout from '../Layout';
import ConsentForms from '../../containers/consent';

const Consent = () => {
  return (
    <Layout>
      <ConsentForms />
    </Layout>
  );
};

export default Consent;
