import {
  ADD_SERVICE_REQUEST,
  ADD_SERVICE_SUCCESS,
  ADD_SERVICE_FAILED,
  DELETE_SERVICE_FAILED,
  DELETE_SERVICE_SUCCESS,
  DELETE_SERVICE_REQUEST,
  UPDATE_SERVICE_FAILED,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_REQUEST,
  GET_BY_ID_SERVICE_FAILED,
  GET_BY_ID_SERVICE_SUCCESS,
  GET_BY_ID_SERVICE_REQUEST,
  GET_SERVICE_FAILED,
  GET_SERVICE_SUCCESS,
  GET_SERVICE_REQUEST,
} from "../constant";

/* eslint-disable import/prefer-default-export */
export const addService = (state = [], action) => {
  switch (action.type) {
    case ADD_SERVICE_REQUEST:
      return {
        request: true,
      };
    case ADD_SERVICE_SUCCESS:
      return {
        request: false,
        addServiceData: action.response,
      };
    case ADD_SERVICE_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getService = (state = [], action) => {
  switch (action.type) {
    case GET_SERVICE_REQUEST:
      return {
        request: true,
      };
    case GET_SERVICE_SUCCESS:
      return {
        request: false,
        getServiceData: action.response,
      };
    case GET_SERVICE_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getByIdService = (state = [], action) => {
  switch (action.type) {
    case GET_BY_ID_SERVICE_REQUEST:
      return {
        request: true,
      };
    case GET_BY_ID_SERVICE_SUCCESS:
      return {
        request: false,
        getByIdServiceData: action.response,
      };
    case GET_BY_ID_SERVICE_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const updateService = (state = [], action) => {
  switch (action.type) {
    case UPDATE_SERVICE_REQUEST:
      return {
        request: true,
      };
    case UPDATE_SERVICE_SUCCESS:
      return {
        request: false,
        updateServiceData: action.response,
      };
    case UPDATE_SERVICE_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const deleteService = (state = [], action) => {
  switch (action.type) {
    case DELETE_SERVICE_REQUEST:
      return {
        request: true,
      };
    case DELETE_SERVICE_SUCCESS:
      return {
        request: false,
        deleteServiceData: action.response,
      };
    case DELETE_SERVICE_FAILED:
      return {
        request: false,
        error: true,
      };
    default:
      return state;
  }
};
