import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import './clinician.scss';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import alertIcon from '../assets/imgs/db-image/alert-icon.png';
import LogoLightHouse from '../assets/imgs/Background.png';
import moment from 'moment';
import useStyles from './SidebarClinician.styles';
import { updateToSeen } from '../utils/actions/appointment.action';
import AlertMsg from '../utils/Alert';
import PropTypes from 'prop-types';
import { baseURL } from '../utils/axios';
import Axios from 'axios';

const isToday = (someDate) => {
  const today = new Date();
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  );
};

const SidebarClinician = ({ updateToSeen, loading, error, message }) => {
  const history = useHistory();
  const classes = useStyles();
  const [nextAppId, setNextAppId] = useState('');
  const [seconds, setSeconds] = useState(60);
  const [min, setMin] = useState(4);
  const [time, setTime] = useState(new Date().toLocaleTimeString());
  const [overTimeStatus, setOverTimeStatus] = useState(false);
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    var date = new Date();

    date.setHours(0, 0, 0, 0);

    var timezoneOffset = date.getTimezoneOffset();
    Axios.get(
      `${baseURL}/api/appointment/dashboard?timezoneOffset=${timezoneOffset}`,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        setAppointments(res.data);
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setNextAppId('');
      setOverTimeStatus(false);

      if (appointments && appointments.length) {
        let crntDate = new Date();
        setTime(crntDate.toISOString());
        for (let item of appointments) {
          // var startTime = new Date(item.startTime);
          let startTime = moment(item.startTime);
          if (item.status === 'checked-in') {
            let condMtch = false;
            let minutes = 0;
            let seconds = 0;
            let current = moment();

            const diff = startTime.diff(current);
            const diffDuration = moment.duration(diff);

            if (
              diff > 0 &&
              Math.abs(diffDuration.minutes()) < 5 &&
              diffDuration.hours() == 0
            ) {
              condMtch = true;
              setOverTimeStatus(false);

              minutes = Math.abs(diffDuration.minutes());
              seconds = Math.abs(diffDuration.seconds());
            } else if (
              diff < 0 &&
              Math.abs(diffDuration.minutes()) < 30 &&
              Math.abs(diffDuration.hours()) == 0
            ) {
              condMtch = true;
              setOverTimeStatus(true);
              minutes = -Math.abs(diffDuration.minutes());
              seconds = Math.abs(diffDuration.seconds());
            }

            if (condMtch) {
              setSeconds(seconds);
              setMin(minutes);
              setNextAppId(item._id);
              break;
            }
          }
        }
      }
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [time, appointments]);

  useEffect(() => {
    if (nextAppId) {
      if (!loading && !error) {
        history.push(
          '/clinician/appointments/appointment-details/' + nextAppId
        );
      } else {
        if (message) {
          AlertMsg('error', message);
        }
      }
    }
  }, [loading, error]);

  const handleProceedNow = () => {
    if (!nextAppId) return null;
    localStorage.setItem('startedSessionId', nextAppId);
    updateToSeen(nextAppId);
  };

  return (
    <>
      <CssBaseline />
      {nextAppId && (
        <div className={`proceed-alert ${overTimeStatus ? 'orange' : 'blue'}`}>
          <div className='alertDesc'>
            <div className='img'>
              <img src={alertIcon} alt='' />
              <span>Alert</span>
            </div>
            <p>
              {overTimeStatus
                ? 'Your next appointment has should have started already- '
                : 'Your next appointment has checked-in, and starts in'}
            </p>
            <h6>{`${min}min ${seconds}secs`}</h6>
          </div>
          <div className='proceed-btn'>
            <Button
              variant='contained'
              onClick={handleProceedNow}
              color='primary'
              className='button-custom'
            >
              Proceed Now
            </Button>
          </div>
        </div>
      )}
      <div className={classes.root}>
        <AppBar
          position='relative'
          className={classes.appBar}
          id='myHeader-stick'
          elevation={0}
        >
          <Toolbar>
            <div style={{ width: '17%', paddingRight: 24 }}>
              <img src={LogoLightHouse} style={{ width: '100%' }} />
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
};

SidebarClinician.propTypes = {
  appointments: PropTypes.array.isRequired,
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  message: PropTypes.string,
  updateToSeen: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  error: state.updateToSeen.error,
  loading: state.updateToSeen.loading,
  message: state.updateToSeen.message,
});

export default connect(mapStateToProps, { updateToSeen })(SidebarClinician);
