import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Countries, TextField } from '../../atoms';
import { Row, Error } from './style';

const AddressForm = ({
  onChange,
  address,
  statesList,
  CountryData,
  errors,
}) => {
  return (
    <Grid direction='column' container>
      <Row item>
        <Countries
          label='Country'
          name='country'
          options={CountryData}
          onChange={onChange}
          value={address.country}
          placeholder='Select a country'
          isRequired
        />
        <Error>{errors.country && errors.country.message}</Error>
      </Row>
      <Row item>
        <TextField
          value={address.address1}
          onChange={onChange}
          name='address1'
          label='Address'
          error=''
          isRequired
        />
        <Error>{errors.address1 && errors.address1.message}</Error>
      </Row>
      <Row item>
        <TextField
          value={address.address2}
          onChange={onChange}
          name='address2'
          label='Address (Optional)'
          error=''
        />
      </Row>
      <Row direction='row' spacing={2} item container>
        <Grid xs={6} item>
          <TextField
            value={address.city}
            onChange={onChange}
            placeholder='Select a state'
            name='city'
            label='City'
            error=''
          />
          <Error>{errors.city && errors.city.message}</Error>
        </Grid>
        <Grid xs={6} item>
          <TextField
            value={address.zip}
            onChange={onChange}
            name='zip'
            label='Zip'
            error=''
          />
        </Grid>
      </Row>

      <Row item>
        <Countries
          label='State'
          name='state'
          options={statesList}
          onChange={onChange}
          value={address.state}
          isState
        />
        <Error>{errors.state && errors.state.message}</Error>
      </Row>
      <Row item>
        <TextField
          value={address.phoneNumber}
          onChange={onChange}
          name='phoneNumber'
          label='Office Number'
          error=''
          type='number'
          isRequired
        />
        <Error>{errors.phoneNumber && errors.phoneNumber.message}</Error>
      </Row>
      <Row item>
        <TextField
          value={address.email}
          onChange={onChange}
          name='email'
          label='Email'
          placeholder='user@company.ae'
          error=''
          isRequired
        />
        <Error>{errors.email && errors.email.message}</Error>
      </Row>
    </Grid>
  );
};

AddressForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  address: PropTypes.shape({
    country: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    zip: PropTypes.any,
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  errors: PropTypes.shape({
    country: PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
    email: PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
    state: PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
    city: PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
    zip: PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
    address1: PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
    address2: PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
    phoneNumber: PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
    phoneType: PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
  }),
  statesList: PropTypes.array.isRequired,
  CountryData: PropTypes.array.isRequired,
};

export default AddressForm;
