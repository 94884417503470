import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { TextField, CircularProgress } from '@material-ui/core';
import { getCliniciansByIds } from '../../../utils/actions/clinician.action';
import AlertMsg from '../../../utils/Alert';
import { CustomAutoComplete } from './style';

const AutoComplete = ({
  onChange,
  renderingFunction,
  options,
  multiple,
  placeholder,
  name,
  loading,
  value,
  disabled,
}) => {
  const [searchText, setSearchText] = useState('');
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    if (searchText) {
      renderingFunction(searchText);
    }
  }, [searchText]);

  useEffect(() => {
    if (value) {
      setSelectedValue(value);
    }
  }, []);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: UserType) =>
      option.firstName +
      option.lastName +
      option.email +
      option.phoneNumber +
      option.name,
  });

  interface UserType {
    _id: number;
    firstName: string;
    lastName: string;
    name: string;
    email: string;
    phoneNumber: string;
  }

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleValueChange = (e, newValue) => {
    setSelectedValue(newValue);
    onChange(newValue);
  };

  return (
    <CustomAutoComplete
      name={name}
      multiple={multiple}
      options={options}
      onChange={(event, newValue) => handleValueChange(event, newValue)}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => option._id === value._id}
      filterOptions={filterOptions}
      value={selectedValue}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={handleChange}
          variant='standard'
          value={searchText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      clearOnBlur={false}
      disabled={disabled}
    />
  );
};

AutoComplete.propTypes = {
  onChange: PropTypes.func.isRequired,
};

AutoComplete.defaultProps = {
  disabled: false,
};
const mapStateToProps = (state) => ({
  oldCliniciansList: state.getByIdsClinicians.clinicians,
});

export default connect(mapStateToProps, { getCliniciansByIds })(AutoComplete);
