/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Grid } from '@material-ui/core';
import style from './confirmAppointment_style';

export const ConfirmAppointment = ({
  cancelAppointmment,
  confirmAppointment,
  fullWidth,
  ...props
}) => {
  const classes = style();
  return (
    <Grid
      alignItems='center'
      justify='center'
      container
      className={classes.root}
      style={{ width: fullWidth ? '100%' : 'auto' }}
      {...props}
    >
      <Grid
        justify='center'
        alignItems='center'
        container
        className={classes.appointmentCancelButton}
        onClick={() => cancelAppointmment()}
      >
        X
      </Grid>
      <Grid
        justify='center'
        alignItems='center'
        container
        className={classes.body}
        onClick={() => confirmAppointment()}
      >
        Confirm your appointment
      </Grid>
    </Grid>
  );
};
