import {
  GET_CONSENT_FORMS_REQUEST,
  DOWNLOAD_CONSENT_REQUEST,
  DOWNLOAD_CONSENT_CLEAR,
} from '../constant';

export const getConsentForms = (clinicianId, filters, sortBy, sortType) => {
  const searchText = filters.searchText || '';
  const selectedFilter = filters.selectedFilter || 'unsigned';
  const createdAt = filters.createdAt || '';
  console.log(sortBy, sortType)
  return {
    type: GET_CONSENT_FORMS_REQUEST,
    endpoint: `/api/consent/getall/${clinicianId}?searchText=${searchText}&selectedFilter=${selectedFilter}&createdAt=${createdAt}&sortBy=${sortBy}&sortType=${sortType}`,
  };
};

export const downloadConsetForm = (clinicianId, clientId) => ({
  type: DOWNLOAD_CONSENT_REQUEST,
  endpoint: `/api/consent/download-consent/${clinicianId}/${clientId}`,
});

export const clearPDF = () => ({
  type: DOWNLOAD_CONSENT_CLEAR,
});
