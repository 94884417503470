/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/state-in-constructor */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  fade,

  withStyles,
  CssBaseline,
  Container,
  Typography,
  Grid,
  Paper,
  Button,
  Dialog,
  TextField,
  Input,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Select from 'react-select';
import validator from 'validator';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { sendMailAction } from '../../utils/actions/client.action';
import AlertMsg from '../../utils/Alert';
import styles from './Homestyle';

const options = [
  { value: 'clients', label: 'Clients' },
  { value: 'contacts', label: 'Contacts' },
  { value: 'clients & contacts', label: 'Clients & Contacts' },
  { value: 'recently viewed clients', label: 'Recently Viewed Clients' },
  { value: 'inactive clients', label: 'Inactive Clients' },
];

const sortoptions = [
  { value: 'last name', label: 'Last Name' },
  { value: 'first name', label: 'First Name' },
];

class Home extends React.Component {
  state = {
    auth: true,
    anchorEl: null,
    // anchorOriginVertical: 'bottom',
    // anchorOriginHorizontal: 'right',
    // transformOriginVertical: 'top',
    // transformOriginHorizontal: 'right',
    anchorReference: 'anchorEl',
    selectedOption: null,
    selectedSortOption: null,
    isEmail: false,
    email: '',
  };

  componentDidUpdate(preProps) {
    if (this.props.sendMail !== preProps.sendMail) {
      if (this.props.sendMail.clientMailData || this.props.sendMail.error) {
        this.setState({ isEmail: false });
      }
    }
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    console.log('Option selected:', selectedOption);
  };

  handleSortChange = (selectedSortOption) => {
    this.setState({ selectedSortOption });
    console.log('Option selected:', selectedSortOption);
  };

  formVerification = () => {
    const user = validator.isEmpty(this.state.email, {
      ignore_whitespace: true,
    });
    if (user) {
      AlertMsg('error', 'All field must be filled');
      return false;
    }
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { email } = this.state;
    const isVerify = this.formVerification();
    if (isVerify) {
      const data = {
        email,
      };
      const { history } = this.props;
      this.props.sendMailAction(data, history);
    }
  };

  render() {
    const { classes } = this.props;
    const { selectedOption } = this.state;
    const { selectedSortOption } = this.state;

    const { auth, anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <>
        {/* <CssBaseline /> */}
        {/* <Container> */}
        <div className={classes.root}>
          <Button
            className={classes.addServiceButton}
            onClick={() => (localStorage.getItem('__r') === 'reception'
              ? this.setState({ isEmail: true })
              : AlertMsg('error', "Oops!, You don't have permission"))}
          >
            Add New Client
          </Button>
          <Dialog
            fullWidth
            open={this.state.isEmail}
            onClose={() => this.setState({ isEmail: false, email: '' })}
          >
            <div className={classes.paper}>
              <form className={classes.form}>
                <Grid item xs={12} sm={12}>
                  <Typography className={classes.inputText} component="span">
                    Patient Mail
                  </Typography>
                  <Input
                    name="email"
                    value={this.state.email}
                    disableUnderline
                    placeholder="Email"
                    autoFocus
                    fullWidth
                    onChange={(e) => this.setState({ email: e.target.value })}
                    className={classes.marginSty}
                  />
                </Grid>
                <div style={{ display: 'flex', flex: 1 }}>
                  <Button
                    fullWidth
                    type="button"
                    onClick={() => this.setState({ isEmail: false, email: '' })}
                    variant="contained"
                    className={classes.submit}
                  >
                    Cancel
                  </Button>
                  <Button
                    fullWidth
                    type="submit"
                    onClick={this.handleSubmit}
                    variant="contained"
                    className={classes.submit}
                  >
                    Send Mail
                  </Button>
                </div>
              </form>
            </div>
          </Dialog>
          <Typography className={classes.heading}>Client & Contacts</Typography>
          <div className={classes.divstyle}>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={4} className={classes.grid}>
                <form>
                  <input
                    placeholder="Search...."
                    value={this.state.query}
                    onChange={this.handleInputChange}
                    className={classes.searchinput}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </form>
              </Grid>
              <Grid item xs={12} sm={4} className={classes.grid}>
                <Select
                  // placeholder=""
                  value={selectedOption}
                  onChange={this.handleChange}
                  options={options}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography className={classes.typoghraphy}>Sort by</Typography>
                <Select
                  // placeholder=""
                  value={selectedSortOption}
                  onChange={this.handleSortChange}
                  options={sortoptions}
                  className={classes.righttextinput}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        {/* </Container> */}
      </>
    );
  }
}

const connected = connect(
  (state) => ({
    sendMail: state.sendMail,
  }),
  { sendMailAction },
)(Home);
const formed = reduxForm({
  form: 'sendMail',
})(connected);

export default withStyles(styles)(formed);
