
import { createStyles, withStyles } from "@material-ui/core/styles";
export default (theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderWidth: 1,
      borderColor: "#cfd8dc",
      border: "solid",
      padding: theme.spacing(5),
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(4, 0, 3),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      backgroundColor: "#1976d2",
    },
    heading: {
      marginTop: theme.spacing(10),
      color: "#13191c",
      textAlign: "center",
      fontSize: 25,
      fontFamily: "Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif",
    },
    notchedOutline: {
      borderWidth: "1px",
      borderColor: "#1976d2 !important",
      color: "#1976d2",
    },
    title: {
      color: "#13191c",
      fontSize: 16,
      fontWeight: 600,
      cursor: "default",
      fontFamily: "Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif",
      textAlign: "left",
    },
    linksty: {
      color: "#1976d2",
    },
  });