import {makeStyles, withStyles} from '@material-ui/core/styles';


export default makeStyles({
    root: {
        maxWidth: 255,
    },
    cardOuter:{
        backgroundColor:'#ffffff',
        padding:"20px",
        marginBottom:'20px',
        width:'100%',
        textAlign:'left',
        '& h4':{
            fontSize:"18px",
            color:'#5a636c'
        },   
        '& .innerCells':{
            padding:'13px 20px',
            height:"50px",
            backgroundColor:"#f3f3f3",
            display:"flex",
            alignItems:'center',
            justifyContent:'space-between',
            '& :evenchild':{
                backgroundColor:"#ffffff",
            },
            '& h5':{
                fontSize:"14px",
                color:"#5a636c",
                fontWeight:"500",
                marginBottom:'0'
            },
        }
    },
    switches:{
        display:'inline-block',
        width:"100px",
        textAlign:'center'
    },
    inputs:{
      width:'65px',
      '& input':{
        padding:'10px 5px !important' ,
        textAlign:"center",
        fontSize: '16px',
        backgroundColor:"#ffffff"   ,
        lineHeight: '18px',
        height: 'auto'
      }
    },
    inputText: {
      fontSize: '22px',
      lineHeight:"28px",
      marginBottom:"15px",
      color: '#003265',
      padding: '5px',
      paddingLeft: 0,
      textAlign: 'left',
    },
    paper: {
      padding: '20px',
      height: '100%',
      boxShadow:'none',
      borderRadius:0
    },
    inputTitle: {
      textAlign: 'left',
      fontSize:"13px",
      color:'grey',
      fontWeight:"500",
      marginBottom:"4px"
    },
    inputsty: {
      border: '1px solid #e5e5e5',
      borderRadius: '0',
      height: '45px',
      width: '100%',
      marginBottom:"15px",
      padding: "10px"
    },
    imgBox: {
      backgroundColor: '#003265',
      padding: '20px',
      width: 170,
      height: 170,
    },
    img: {
      borderRadius: '50%',
      width: '115px',
      height: '115px',
      // padding: '-20px',
      // alignItems: 'center',

      objectFit: 'cover'
    },
    formControl: {
      minWidth: 120,
    },
    drag: {
      width: '90%',
      height: '100%',
      borderStyle: 'dashed',
      borderWidth: 2,
      borderColor: '#ccc',
      textAlign: 'center',
      marginLeft: 20,
      color: '#afc2df',
    },
    italicTitle: {
      fontStyle: 'italic',
      fontSize: '13px',
      textAlign: 'left',
      color: '#807c7c',
    },
    tile: {
      padding: '20px',
      backgroundColor: '#e8f0fe',
      textAlign: 'left',
    },
    upload: {
      padding: '20px',
      backgroundColor: '#003265',
      color: '#fff',
      textAlign: 'left',
    },
    selectMenu:{
      '&: focus':{
          backgroundColor:"#ffffff"
      }
    },
    switchBtn:{
      display:'flex',
      alignItems:"center",
      '& .checked':{
          backgroundColor:'red'
      },
      '& label':{
          marginLeft:"0px",
          marginRight:"0px",
          marginBottom:'0',
          fontSize:"14px"
      }
    },
    textareaField:{
      borderColor:'#e5e5e5'
    },
    rangeField:{
      display:'flex',
      '& span':{
        display:'inline-flex',
        width:'100px',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:'#efefef',
        borderTop:'1px solid #e5e5e5',
        borderBottom:'1px solid #e5e5e5',
        height:'45px',
        color:'#a3a3a3'
      }
    },
    autoComCountry: {
      height: "20px",
      padding: 0,
      border: "1px solid #e5e5e5"
    }
  });