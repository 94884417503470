/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Axios from 'axios';
import { baseURL } from './utils/axios';

// eslint-disable-next-line react/prop-types
export const PrivateRoute = ({ component: Component, ...option }) => {
  return (
    <Route
      {...option}
      render={(props) =>
        localStorage.getItem('ACCESS_TOKEN_PATH') ? (
          <Component {...props} />
        ) : (
          <Redirect push to='/login' />
        )
      }
    />
  );
};

export const ClinicianPrivateRoute = ({ component: Component, ...option }) => {
  const [checkUser, setCheckUser] = React.useState(true);
  React.useEffect(() => {
    getUserDetais();
  }, []);

  const getUserDetais = () => {
    Axios.get(`${baseURL}/api/user/details`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        localStorage.setItem('weeklyLimit', res.data.weeklyLimit);
        setCheckUser(true);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setCheckUser(false);
        }
      });
  };
  if (!checkUser) {
    return (
      <Route
        {...option}
        render={(props) => <Redirect push to='/clinician/login' />}
      />
    );
  }
  return (
    <Route
      {...option}
      render={(props) =>
        localStorage.getItem('__r') === 'clinician' &&
        localStorage.getItem('ACCESS_TOKEN_PATH') ? (
          <Component {...props} />
        ) : (
          <Redirect push to='/clinician/login' />
        )
      }
    />
  );
};

export default { PrivateRoute };
