/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
import { put, takeEvery } from 'redux-saga/effects';
import { get } from '../axios';
import {
    GET_ALL_PRESCRIPTIONS_REQUEST,
    GET_ALL_PRESCRIPTIONS_SUCCESS,
    GET_ALL_PRESCRIPTIONS_FAILED,
    GET_ALL_PRESCRIPTIONS_BY_ID_REQUEST,
    GET_ALL_PRESCRIPTIONS_BY_ID_SUCCESS,
    GET_ALL_PRESCRIPTIONS_BY_ID_FAILED,
} from '../constant';

function* getAllPrescriptions(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_ALL_PRESCRIPTIONS_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_ALL_PRESCRIPTIONS_FAILED, error });
  }
}

function* getAllPrescriptionsById(action) {
  try {
    const { endpoint } = action;
    const response = yield get(endpoint);
    yield put({ type: GET_ALL_PRESCRIPTIONS_BY_ID_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_ALL_PRESCRIPTIONS_BY_ID_FAILED, error });
  }
}


export function* prescriptionSaga() {
  yield takeEvery(GET_ALL_PRESCRIPTIONS_REQUEST, getAllPrescriptions);
  yield takeEvery(GET_ALL_PRESCRIPTIONS_BY_ID_REQUEST, getAllPrescriptionsById);
}
