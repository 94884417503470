import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sendSMS } from '../../../utils/actions/sms.action';
import AlertMsg from '../../../utils/Alert';
import { PhoneNumber, Message, SendBtn } from './style';
import { Grid } from '@material-ui/core';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

const SMSModalBox = ({ phoneNumber,sendSMS, smsDrawer, handleSmsDrawer, error, responseMessage, request }) => {
  const [message, setMessage] = useState({ phoneNumber: '', body: '' });

  useEffect(() => {
    if (phoneNumber) {
      setMessage({ ...message, phoneNumber })
    }
  }, [])

  useEffect(() => {
    if (!request) {
      if (error) {
        AlertMsg('error', responseMessage);
      } else if (responseMessage) {
        setMessage({ phoneNumber: '', body: '' });
        AlertMsg('success', responseMessage);
      }
    }
  }, [request, error, responseMessage]);

  const handleSendMessage = () => {
    const { phoneNumber, body } = message;

    if (phoneNumber.trim() !== '' && body.trim() !== '') {
      const encryptedBody = { phoneNumber, body: encodeURI(body) };
      sendSMS(encryptedBody);
    } else if (phoneNumber.trim() === '') {
      AlertMsg('error', 'Phone number can not be empty!');
    } else if (body.trim() === '') {
      AlertMsg('error', 'Message body can not be empty!');
    }
  };

  const handleChange = (e) =>
    setMessage({ ...message, [e.target.name]: e.target.value });

  return (
  <>
    <div>
      <SwipeableDrawer
       className='mini-custom-swipable-drawer'
       anchor={'right'}
       open={smsDrawer}
       onClose={handleSmsDrawer(false)}
       onOpen={handleSmsDrawer(true)}
      >

      <Grid direction='column' style={{marginLeft:"20px"}} alignItems='flex-start' container>
        <PhoneNumber
          name='phoneNumber'
          label='Phone Number'
          defaultValue=''
          margin='normal'
          variant='outlined'
          placeholder='Ex. 973349887623'
          helperText='Enter Phone number without spaces'
          value={message.phoneNumber}
          onChange={handleChange}
        />

        <Message
          name='body'
          label='Message'
          defaultValue=''
          margin='normal'
          variant='outlined'
          placeholder='SMS Text'
          helperText='Enter some text to send'
          rowsMax={10}
          value={message.body}
          onChange={handleChange}
          multiline
        />
        <SendBtn onClick={handleSendMessage}> Send </SendBtn>
      </Grid>

      </SwipeableDrawer>
    </div>
    </>
  );
};

SMSModalBox.propTypes = {
  sendSMS: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  request: PropTypes.bool.isRequired,
  responseMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  error: state.SMSReducer.error,
  responseMessage: state.SMSReducer.message,
  request: state.SMSReducer.request,
});

export default connect(mapStateToProps, { sendSMS })(SMSModalBox);
