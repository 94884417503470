/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/order */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from 'react';
import {

  withStyles,
  Typography,
  CssBaseline,
  Grid,
  Button,
  Divider,
  Tab,
  Tabs,
  Box,
} from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import SidebarMenu from '../../components/Sidebar';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { addClientNoteAction } from '../../utils/actions/client.action';
import { getAppointmentByClientIdAction } from '../../utils/actions/appointment.action';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import draftToHtml from 'draftjs-to-html';
import AlertMsg from '../../utils/Alert';
import OverViewBoxes from './OverviewBoxSection';
import SingleClientHeader from './SingleClientHeader';
import DatePicker, { registerLocale } from 'react-datepicker';
// import en from 'date-fns/locale/en';
import 'react-datepicker/dist/react-datepicker.css';
import AddIcon from '@material-ui/icons/Add';
import Select from 'react-select';
import ClientBilling from './ClientBilling';
import dayjs from 'dayjs';
import Loader from '../../components/Loader/Loader';
import styles from './ClientOverviewStyle';

// registerLocale('en', en);

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

class ClientOverview extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
      selectedOption: null,
      startDate: new Date(),
      value: 0,
      allAppointment: [],
      isLoaded: null,
    };
  }

  componentDidMount() {
    this.props.getAppointmentByClientIdAction(this.props.match.params.id);
  }

  componentDidUpdate(preProps) {
    const { addNote, getAppointmentData } = this.props;
    if (getAppointmentData !== preProps.getAppointmentData) {
      this.setState({ isLoaded: getAppointmentData.request });
      if (getAppointmentData.getAppointmentByClientData) {
        console.log(getAppointmentData.getAppointmentByClientData);

        this.setState({
          allAppointment: getAppointmentData.getAppointmentByClientData,
        });
      }
    }
    if (addNote !== preProps.addNote) {
      if (addNote.addClientNoteData) {
        AlertMsg('success', 'Note Added');
      }
      if (addNote.error) {
        AlertMsg('error', 'Failed to Add');
      }
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  handleSubmit = () => {
    const data = {
      note: draftToHtml(
        convertToRaw(this.state.editorState.getCurrentContent()),
      ),
    };
    this.props.addClientNoteAction(this.props.match.params.id, data);
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleDateChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  handleTabChange = (e, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const {
      editorState,
      selectedOption,
      allAppointment,
      isLoaded,
    } = this.state;

    return (
      <>
        <CssBaseline />
        <div className={classes.root}>
          <SidebarMenu />
          <div className={classes.container}>
            <SingleClientHeader props={this.props} />
            <Tabs
              value={this.state.value}
              indicatorColor="primary"
              textColor="#444"
              onChange={this.handleTabChange}
              aria-label="disabled tabs example"
            >
              <Tab value={0} label="Overview" className={classes.tabButton} />
              <Tab value={1} label="Billing" className={classes.tabButton} />
              <Button
                size="small"
                className={classes.adButton}
                startIcon={<AddIcon />}
              >
                Administrative Note
              </Button>
            </Tabs>
            <Divider />
            <TabPanel value={this.state.value} index={0}>
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <div className={classes.bordersty}>
                    <Editor
                      editorState={editorState}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      onEditorStateChange={this.onEditorStateChange}
                      placeholder=" Add Chart Note: include notes from a call with a client or copy & paste the contents of a document or email."
                    />
                  </div>

                  <Grid container spacing={3}>
                    {/* <Grid item xs={10}>
                    <DatePicker
                      selected={this.state.startDate}
                      onChange={this.handleChange}
                      className={classes.dateInput}
                    />
                  </Grid> */}
                    <Grid item xs={2}>
                      <Button
                        className={classes.addbutton}
                        startIcon={<AddIcon />}
                      >
                        Add Notes
                      </Button>
                    </Grid>
                  </Grid>

                  <Divider className={classes.dividersty} />

                  <Grid container spacing={3} className={classes.gridsty}>
                    <Grid item xs={4}>
                      <Select
                        value={selectedOption}
                        onChange={this.handleChange}
                        options={options}
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.gridLeft}>
                      <DatePicker
                        locale='en'
                        selected={this.state.startDate}
                        onChange={this.handleDateChange}
                        className={classes.dateInput}
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.gridRight}>
                      <Button
                        variant="contained"
                        className={classes.createButton}
                        startIcon={<AddIcon />}
                      >
                        create new
                      </Button>
                    </Grid>
                  </Grid>

                  {isLoaded ? (
                    <Loader />
                  ) : (
                    allAppointment.map((appointment) => (
                      <>
                        <Grid container spacing={2} className={classes.gridsty}>
                          <Grid item xs={1} sm={1}>
                            <Button variant="contained" size="small" disabled>
                              {dayjs(appointment.createdAt).format('MMM DD')}
                            </Button>
                          </Grid>
                          <Grid item xs={8} sm={8}>
                            <Typography className={classes.appointmentsty}>
                              Appointment
                            </Typography>
                            <Typography className={classes.serviceText}>
                              CPT:
                              {' '}
                              {appointment.services.map(
                                (service, index) => `${service.serviceCode}, `,
                              )}
                            </Typography>
                            <Typography
                              onClick={() => this.props.history.push(
                                `/add_notes/${appointment._id}`,
                              )}
                              className={classes.progressSty}
                            >
                              + Progress Note
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            className={classes.gridRight}
                          >
                            <Typography className={classes.timeSty}>
                              {dayjs(appointment.startTime).format('hh:mm A')}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider
                          variant="inset"
                          className={classes.dividersty}
                        />
                      </>
                    ))
                  )}

                  {/* <Grid container spacing={2} className={classes.gridsty}>
                    <Grid item xs={1} sm={1}>
                      <Button variant="contained" size="small" disabled>
                        Apr 15
                      </Button>
                    </Grid>
                    <Grid item xs={8} sm={8}>
                      <Typography className={classes.appointmentsty}>
                        Appointment
                      </Typography>
                      <Typography className={classes.progressSty}>
                        + Progress Note
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} className={classes.gridRight}>
                      <Typography className={classes.timeSty}>
                        3:45–3:55AM
                      </Typography>
                    </Grid>
                  </Grid> */}
                </Grid>

                <Grid item xs={3}>
                  <OverViewBoxes />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={this.state.value} index={1}>
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <ClientBilling props={this.props} />
                </Grid>
                <Grid item xs={3}>
                  <OverViewBoxes />
                </Grid>
              </Grid>
            </TabPanel>
          </div>
        </div>
      </>
    );
  }
}

const connected = connect(
  (state) => ({
    addNote: state.addClientNote,
    getAppointmentData: state.getAppointmentByClientId,
  }),
  {
    addClientNoteAction,
    getAppointmentByClientIdAction,
  },
)(ClientOverview);

const formed = reduxForm({
  form: 'addNote',
})(connected);

export default withStyles(styles)(formed);
