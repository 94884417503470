/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import DrawerMenu from '../common/Header/DrawerMenu';
import { GridContainer, GridItem } from '../common/BodyContainer';
import { TableTitleFont } from '../common/TableFont';
import {
  DrawerNameFont,
  DrawerReferredFont,
} from '../common/DrawerContent/DrawerFont';
import { DrawerBoxClinicianImg } from '../common/DrawerContent/DrawerImage';
import { ClinicianButton } from '../common/DrawerContent/CustomButton';
import arrow from '../../assets/imgs/Border.png';
import arrowUp from '../../assets/imgs/Border (4).svg';
import { CustomCalendar } from '../components/Calendar/CustomCalendar';
import { ConfirmAppointment } from '../components/Appointment Request/confirmAppointment';
import { getClinicianAction } from '../../utils/actions/clinician.action';
import { addAppointmentAction } from '../../utils/actions/appointment.action';
import clinician1 from '../../assets/imgs/download.png';
import AlertMsg from '../../utils/Alert';
import Loader from '../../user/Loader';
import dayjs from 'dayjs';
import { dateFormatterHHMM, dateFormatter1 } from '../../utils/validators';
import moment from 'moment';
import Axios from 'axios';
import { baseURL } from '../../utils/axios';
import styles from './Doctor_style';

class Doctor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorList: [],
      availableSlots: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getClinicians();
    if (
      this.props.location.pathname ===
      `/user/appointment/reschedule/${this.props.match.params.id}`
    ) {
      this.setState({ isUpdate: true });
    }
  }

  componentDidUpdate(preProps) {
    if (this.props.addAppointment !== preProps.addAppointment) {
      if (this.props.addAppointment && this.props.addAppointment._id) {
        AlertMsg('success', 'Appointment Booked!');
        this.props.history.push(`/verifyinfo/${this.props.addAppointment._id}`);
      }
    }
  }

  getClinicians = () => {
    this.setState({ loading: true });
    Axios.get(`${baseURL}/api/client/clinicians`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        this.setState({ loading: false });
        const data = res.data.map((clinician) => {
          let nextAvailable = null;
          let schedules = clinician.schedule;
          // if(schedules && schedules.length){
          schedules = schedules.map((sch) => {
            if (sch.slots.length) {
              sch.slots = sch.slots.map((s) => {
                let startTime = moment(new Date(s.startTime));
                let slot = startTime.hours() + ':' + startTime.minutes();
                let timeArr = slot.split(':');
                let hrs = '00',
                  min = '00';
                if (timeArr.length) {
                  hrs = timeArr[0];
                  min = timeArr[1];
                }
                if (timeArr[0].length === 1) {
                  hrs = `0${timeArr[0]}`;
                }
                if (timeArr[1].length === 1) {
                  min = `0${timeArr[1]}`;
                }
                let time = `${hrs}:${min}`;
                let lclDate = new Date(`${sch.date}T${time}:00`);
                let lclHrs = lclDate.getHours().toString();
                if (lclHrs.length === 1) {
                  lclHrs = `0${lclHrs}`;
                }
                return `${lclHrs}:${
                  lclDate.getMinutes() > 0 ? lclDate.getMinutes() : '00'
                }`;
              });
            }
            return sch;
          });

          for (let sch of schedules) {
            if (sch.slots.length && sch.slots.length > 0) {
              nextAvailable = sch;
              break;
            }
          }

          // }
          return {
            id: clinician._id,
            name: `${clinician.firstName} ${clinician.lastName}`,
            study: '',
            speciality: clinician.bio,
            times: schedules,
            nextAvailable,
            img: clinician1,
            cost: `${clinician.costPerUnit} Dhs / ${clinician.appointmentSlotUnit}min`,
            appointmenSlot: clinician.appointmentSlotUnit,
            amount: clinician.costPerUnit,
            selectTimeSlot: null,
            isAlternative: false,
            nextAvailiblityText: this.getNextAvailibility(clinician.schedule),
          };
        });

        this.setState({ doctorList: data });
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  getNextAvailibility = (schedules) => {
    let result = '';
    if (schedules.length) {
      let day = new Date();
      day.setDate(day.getDate() + 1);
      let today = this.getDate(new Date());
      let nextDay = this.getDate(day);
      if (schedules[0].date == today) {
        result = 'Today';
      } else if (schedules[0].date == nextDay) {
        result = 'Tomorrow';
      } else {
        result = schedules[0].date;
      }
    } else {
      result = 'Not Available';
    }
    return result;
  };

  getDate = (date) => {
    var today = new Date(date);
    var d = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var m =
      today.getMonth() + 1 < 10
        ? '0' + (today.getMonth() + 1)
        : today.getMonth() + 1;
    var modDate = today.getFullYear() + '-' + m + '-' + d;
    return modDate;
  };

  getEvents = (schedule) => {
    var temArr1 = [];
    if (schedule && schedule.length) {
      // var keys = Object.keys(schedule)
      schedule.map((item, i) => {
        let times = [];
        if (item.slots) {
          item.slots.map((val) => {
            times.push({ time: val });
          });
        }
        let start = new Date(item.date);
        var tomorrow = new Date(item.date);
        tomorrow.setDate(tomorrow.getDate() + 1);
        temArr1.push({
          title: `My event ${i}`,
          times: times,
          allDay: false,
          start: Date.parse(item.date + 'T00:00:00'), // 10.00 AM
          end: Date.parse(item.date + 'T00:00:00'), // 2.00 PM
          col: '#ee3559',
        });
      });
    }
    return temArr1;
  };

  getFirstTimeSlot = (time) => {
    var result = [];
    if (time) {
      var keys = Object.keys(time);
      var values = Object.values(time);
      var day = new Date();
      day.setDate(day.getDate() + 1);
      var nextDay = this.getDate(day);
      result = values[0];
    }
    return result;
  };

  getTimeSlot = (time) => {
    var result = [];
    if (time && time.default) {
      var keys = Object.keys(time);
      var values = Object.values(time);
      var day = new Date();
      day.setDate(day.getDate() + 1);
      var nextDay = this.getDate(day);
      if (keys.length > 1 && keys.indexOf('default') != -1) {
        keys.map((key, index) => {
          if (key == nextDay) {
            values[0].map((val, i) => {
              if (values[index].indexOf(val) == -1) {
                result.push(val);
              }
            });
          } else if (key != 'default') {
            values[0].map((val, i) => {
              if (values[index].indexOf(val) == -1) {
                result.push(val);
              }
            });
          }
        });
      } else {
        result = time.default;
      }
    }
    const data = {
      availableSlots: result ? result : [],
      default: time.default ? time.default : [],
    };
    return data;
  };

  onChange = (index) => {
    const doctorList = [...this.state.doctorList];
    doctorList[index] = {
      ...this.state.doctorList[index],
      isAlternative: !this.state.doctorList[index].isAlternative,
    };
    this.setState({ doctorList });
  };

  handleSelectCalendarTime = (index, times, time, date) => {
    const doctorList = [...this.state.doctorList];
    for (let i = 0; i < this.state.doctorList.length; i++) {
      if (i === index) {
        doctorList[index] = {
          ...this.state.doctorList[index],
          selectTimeSlot: new Date(`${dateFormatter1(date)} ${time}`),
          nextAvailiblityText: dateFormatter1(date),
          isAlternative: false,
        };
      } else {
        doctorList[i] = {
          ...this.state.doctorList[i],
          selectTimeSlot: null,
        };
      }
    }
    this.setState({ doctorList });
  };

  handleSelectTime = (index, time) => {
    const doctorList = [...this.state.doctorList];
    for (let i = 0; i < this.state.doctorList.length; i++) {
      if (i === index) {
        doctorList[index] = {
          ...this.state.doctorList[index],
          selectTimeSlot: time,
          isAlternative: false,
        };
      } else {
        doctorList[i] = {
          ...this.state.doctorList[i],
          selectTimeSlot: null,
        };
      }
    }
    this.setState({ doctorList });
  };

  handleCancelAppointment = (index) => {
    const doctorList = [...this.state.doctorList];
    doctorList[index] = {
      ...this.state.doctorList[index],
      selectTimeSlot: null,
    };
    this.setState({ doctorList });
  };

  handleAppointmentConfirm = (index) => {
    const { doctorList } = this.state;
    const data = {
      clinicianId: doctorList[index].id,
      startTime: doctorList[index].selectTimeSlot,
      duration: 60,
      amount: doctorList[index].amount,
      status: 'unconfirmed',
    };
    data.creatorEmail = localStorage.getItem('clientEmail');
    this.props.addAppointmentAction(localStorage.getItem('clientUid'), data);
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <DrawerMenu {...this.props} />
        <GridContainer />
        <GridItem {...this.props}>
          <TableTitleFont text='Your Clinicians' style={{ fontSize: '22px' }} />
          {this.state.loading ? (
            <Grid container alignItems='center' justify='center'>
              <Loader />
            </Grid>
          ) : (
            this.state.doctorList.map((node, index) => (
              <>
                {node.selectTimeSlot !== null && (
                  <ConfirmAppointment
                    confirmAppointment={() =>
                      this.handleAppointmentConfirm(index)
                    }
                    cancelAppointmment={() =>
                      this.handleCancelAppointment(index)
                    }
                    {...this.props}
                    fullWidth
                  />
                )}
                <Grid key={index} className={classes.root1}>
                  <Grid
                    container
                    alignItems='center'
                    className={classes.bodyHeaderContainer}
                  >
                    <Grid>
                      <DrawerNameFont
                        text={node.name}
                        style={{ fontSize: '18px', textAlign: 'left' }}
                      />
                      <DrawerReferredFont
                        text={node.speciality}
                        style={{ fontSize: '16px', textAlign: 'left' }}
                      />
                    </Grid>
                    <Grid
                      container
                      alignItems='center'
                      justify='flex-end'
                      className={classes.bodyImageContainer}
                    >
                      <DrawerBoxClinicianImg logo={node.img} />
                    </Grid>
                  </Grid>
                  <Grid className={classes.line} />
                  <Grid
                    container
                    alignItems='center'
                    justify='flex-start'
                    style={{ marginBottom: '14px' }}
                  >
                    <div className={classes.nextAvailable}>
                      <DrawerNameFont
                        text={`Next Availability (${node.nextAvailiblityText})`}
                        style={{
                          fontSize: '18px',
                          color: 'rgba(34,34,34,50%)',
                        }}
                      />
                      {/* <ClinicianButton
                        text={`Cost: ${node.cost}`}
                        style={{ position: "absolute", right: "92px" }}
                      /> */}
                    </div>
                  </Grid>

                  <Grid
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginBottom: '40px',
                    }}
                  >
                    {node.nextAvailable &&
                    node.nextAvailable.slots.length > 0 ? (
                      node.nextAvailable.slots.map((time, i) => {
                        return (
                          <Grid
                            key={i}
                            container
                            alignItems='center'
                            justify='center'
                            style={{ marginBottom: '7px' }}
                            className={classes.timeContainer}
                            onClick={() => {
                              this.handleSelectTime(
                                index,
                                new Date(`${node.nextAvailable.date} ${time}`)
                              );
                            }}
                          >
                            {time}
                          </Grid>
                        );
                      })
                    ) : (
                      <Grid>Not Available</Grid>
                    )}
                  </Grid>
                  {node.nextAvailiblityText != 'N/A' ? (
                    <Grid
                      container
                      alignItems='center'
                      justify='flex-start'
                      onClick={() => this.onChange(index)}
                    >
                      {node.nextAvailable &&
                      node.nextAvailable.slots &&
                      node.isAlternative ? (
                        <>
                          <DrawerNameFont
                            text='Hide calendar'
                            style={{ fontSize: '18px', cursor: 'pointer' }}
                          />
                          <img
                            src={arrowUp}
                            style={{ marginLeft: '30px', cursor: 'pointer' }}
                          />
                        </>
                      ) : (
                        <>
                          <DrawerNameFont
                            text='Alternative slots'
                            style={{ fontSize: '18px', cursor: 'pointer' }}
                          />
                          <img
                            src={arrow}
                            style={{ marginLeft: '30px', cursor: 'pointer' }}
                          />
                        </>
                      )}
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
                {node.isAlternative ? (
                  <CustomCalendar
                    events={this.getEvents(node.times)}
                    onSelectEvent={(times, time, date) =>
                      this.handleSelectCalendarTime(index, times, time, date)
                    }
                  />
                ) : null}
              </>
            ))
          )}
        </GridItem>
      </div>
    );
  }
}

Doctor.defaultProps = {
  getAllUser: [],
  clinicianLoader: true,
};

const connected = connect(
  (state) => ({
    getAllUser: state.getClinician.getClinicianData,
    addAppointment: state.addAppointment.addAppointmentData,
    clinicianLoader: state.getClinician.request,
  }),
  {
    getClinicianAction,
    addAppointmentAction,
  }
)(Doctor);

const formed = reduxForm({
  form: 'getUser',
})(connected);

export default withStyles(styles)(formed);
