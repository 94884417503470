import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grid,
} from '@material-ui/core';
import { Title, Heading } from './style';

const AddressRelatedContactsModal = ({
  contacts,
  addressId,
  handleCancel,
  handleConfirm,
  open,
}) => (
  <Dialog open={open} onClose={handleCancel}>
    <Title>
      This address is associated with the following contacts. If you want to
      delete this address then you have to delete the associated contacts first!
    </Title>
    <DialogContent>
      <Heading>Contacts</Heading>
      {contacts.map((contact) => (
        <Grid key={contact._id} container>
          <Grid xs={4} item>
            {`${contact.firstName} ${contact.lastName}`}
          </Grid>
          <Grid xs={4} item>
            {contact.email}
          </Grid>
        </Grid>
      ))}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancel} color='primary'>
        OK
      </Button>
    </DialogActions>
  </Dialog>
);

export default AddressRelatedContactsModal;
