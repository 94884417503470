/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import {

  withStyles,
  Grid,
  Typography,
  Divider,
  Collapse,
} from '@material-ui/core';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
// import en from 'date-fns/locale/en';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './HistoryStyle';

// registerLocale('en', en);

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

class History extends React.Component {
  state = {
    selectedOption: null,
    startDate: new Date(),
    expandOpen: true,
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleDateChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  render() {
    const { classes } = this.props;
    const { selectedOption } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.divstyle}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Select
                value={selectedOption}
                onChange={this.handleChange}
                options={options}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                locale='en'
                selected={this.state.startDate}
                onChange={this.handleDateChange}
                className={classes.date}
              />
            </Grid>
          </Grid>
        </div>

        <div style={{ marginTop: '30px' }}>
          <Grid container spacing={2}>
            <Grid item xs={1} lg={1}>
              <Typography className={classes.title}>Date</Typography>
            </Grid>
            <Grid item xs={2} lg={2}>
              <Typography className={classes.title}>Time</Typography>
            </Grid>
            <Grid item xs={7} lg={7}>
              <Typography className={classes.title}>event</Typography>
            </Grid>
            <Grid item xs={2} lg={2}>
              {this.state.expandOpen ? (
                <Typography
                  display="inline"
                  className={classes.actiontitle}
                  onClick={() => this.setState({ expandOpen: !this.state.expandOpen })}
                >
                  Hide Details
                </Typography>
              ) : (
                <Typography
                  display="inline"
                  className={classes.actiontitle}
                  onClick={() => this.setState({ expandOpen: !this.state.expandOpen })}
                >
                  Show Details
                </Typography>
              )}
            </Grid>
          </Grid>

          <Divider className={classes.dividersty} />

          <Grid container spacing={3}>
            <Grid item xs={1} lg={1}>
              <Typography className={classes.namesty}>04/23/2020</Typography>
            </Grid>
            <Grid item xs={2} lg={2}>
              <Typography className={classes.namesty}>7:47 AM (PDT)</Typography>
            </Grid>
            <Grid item xs={9} lg={9}>
              <Typography display="inline" className={classes.namesty}>
                You deleted document 'APPOINTMENT WAITLIST - Screen Shot
                2020-03-30 at 11.50.29 PM' for client
                {' '}
              </Typography>
              <Typography display="inline" className={classes.relationSty}>
                Akshay Mahajan
              </Typography>
              <Collapse in={this.state.expandOpen} timeout="auto" unmountOnExit>
                <div>
                  <Typography display="inline" className={classes.subtext}>
                    IP Address 47.247.221.237
                    {' '}
                  </Typography>
                  <Typography
                    display="inline"
                    component="li"
                    className={classes.subtext}
                  >
                    Jabalpur, India
                  </Typography>
                </div>
              </Collapse>
            </Grid>
          </Grid>

          <Divider variant="inset" className={classes.dividersty} />

          <Grid container spacing={3}>
            <Grid item xs={2} lg={2} style={{ textAlign: 'right' }}>
              <Typography className={classes.namesty}>7:47 AM (PDT)</Typography>
            </Grid>
            <Grid item xs={10} lg={10} style={{ paddingLeft: '125px' }}>
              <Typography display="inline" className={classes.namesty}>
                You changed the for client,
                {' '}
              </Typography>
              <Typography display="inline" className={classes.relationSty}>
                Jamie D. Appleseed
              </Typography>
              <Collapse in={this.state.expandOpen} timeout="auto" unmountOnExit>
                <div>
                  <Typography display="inline" className={classes.subtext}>
                    IP Address 47.247.221.237
                    {' '}
                  </Typography>
                  <Typography
                    display="inline"
                    component="li"
                    className={classes.subtext}
                  >
                    Jabalpur, India
                  </Typography>
                </div>
              </Collapse>
            </Grid>
          </Grid>

          <Divider variant="inset" className={classes.dividersty} />

          <Grid container spacing={3}>
            <Grid item xs={2} lg={2} style={{ textAlign: 'right' }}>
              <Typography className={classes.namesty}>7:47 AM (PDT)</Typography>
            </Grid>
            <Grid item xs={10} lg={10} style={{ paddingLeft: '125px' }}>
              <Typography display="inline" className={classes.namesty}>
                You changed the for client,
                {' '}
              </Typography>
              <Typography display="inline" className={classes.relationSty}>
                Jamie D. Appleseed
              </Typography>
              <Collapse in={this.state.expandOpen} timeout="auto" unmountOnExit>
                <div>
                  <Typography display="inline" className={classes.subtext}>
                    IP Address 47.247.221.237
                    {' '}
                  </Typography>
                  <Typography
                    display="inline"
                    component="li"
                    className={classes.subtext}
                  >
                    Jabalpur, India
                  </Typography>
                </div>
              </Collapse>
            </Grid>
          </Grid>

          <Divider variant="inset" className={classes.dividersty} />

          <Grid container spacing={3}>
            <Grid item xs={2} lg={2} style={{ textAlign: 'right' }}>
              <Typography className={classes.namesty}>7:47 AM (PDT)</Typography>
            </Grid>
            <Grid item xs={10} lg={10} style={{ paddingLeft: '125px' }}>
              <Typography display="inline" className={classes.namesty}>
                You changed the for client,
                {' '}
              </Typography>
              <Typography display="inline" className={classes.relationSty}>
                Jamie D. Appleseed
              </Typography>
              <Collapse in={this.state.expandOpen} timeout="auto" unmountOnExit>
                <div>
                  <Typography display="inline" className={classes.subtext}>
                    IP Address 47.247.221.237
                    {' '}
                  </Typography>
                  <Typography
                    display="inline"
                    component="li"
                    className={classes.subtext}
                  >
                    Jabalpur, India
                  </Typography>
                </div>
              </Collapse>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(History);
