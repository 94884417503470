import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  divstyle: {
    marginTop: 2,
    display: 'flex',
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(2),
    borderRadius: 5,
    display: 'flex',
  },
  date: {
    width: '100%',
    height: 38,
    borderRadius: 3,
    borderColor: '#cfd8dc',
    borderWidth: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
  },
  title: {
    textTransform: 'uppercase',
    color: '#003d79',
    fontSize: '12px',
    lineHeight: 'normal',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  actiontitle: {
    // width: '25%',
    textTransform: 'uppercase',
    color: '#a29061',
    fontSize: '12px',
    lineHeight: 'normal',
    paddingRight: theme.spacing(10),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dividersty: {
    // width: '98%',
    marginTop: '15px',
    marginBottom: '15px',
    marginRight: '15px',
    backgroundColor: '#e6e5e2',
  },
  namesty: {
    fontSize: '14px',
    lineHeight: '18px',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    color: '#003d79',
  },
  subtext: {
    marginTop: '11px',
    fontSize: '13px',
    lineHeight: '1.5px',
    fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    color: '#777',
  },
});
