/* eslint-disable indent */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
// import DashboardIcon from '@material-ui/icons/Dashboard';
import ShareIcon from '@material-ui/icons/Share';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import './clinician.scss';
import defaultSideImage from '../assets/imgs/download.png';
import onlineIcon from '../assets/clinician_image/Ellipse 23.png';
import DashboardIcon from '../assets/clinician_image/Group 292.png';
import DashboardIconSelected from '../assets/clinician_image/dashboard.jpeg';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Axios from 'axios';
import { baseURL } from '../utils/axios';
import { Button } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router';
import { checkIsActive } from '../utils/constant';
import AlertMsg from '../utils/Alert';
import useStyles from './SidebarMenuClinician.styles';

// import { borderRadius } from "react-select/src/theme";

export default function SidebarMenu({
  filterAppointment,
  filterId,
  filterPatient,
  patientFilterId,
  updatedAvatar,
}) {
  const classes = useStyles();
  const location = useLocation();
  let history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [patientOpen, setPatientOpen] = React.useState(false);
  const [filterList, setFilterList] = React.useState([]);
  const [patientFilterList, setPatientFilterList] = React.useState([]);
  const [loginData, setLoginData] = React.useState({
    firstName: localStorage.getItem('fName'),
    role: localStorage.getItem('__r'),
    lastName: localStorage.getItem('lName'),
    avatar: localStorage.getItem('avatar'),
    prefix: localStorage.getItem('prefix'),
    suffix: localStorage.getItem('suffix'),
  });

  useEffect(() => {
    updateAvatar();
  }, [updatedAvatar]);

  const updateAvatar = () =>
    setLoginData({ ...loginData, avatar: updatedAvatar });
  const handleClick = () => {
    setOpen(!open);
  };
  const handlePatientClick = () => {
    setPatientOpen(!patientOpen);
  };
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url);
  };
  const handleLogout = () => {
    localStorage.clear();
  };
  useEffect(() => {
    getFilters();
    getPatientFilters();
  }, [filterId, patientFilterId]);

  const getFilters = () => {
    var uid = localStorage.getItem('clinicianUID');
    Axios.get(`${baseURL}/api/filter?type=appointment`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        setFilterList(res.data);
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const getPatientFilters = () => {
    var uid = localStorage.getItem('clinicianUID');
    Axios.get(`${baseURL}/api/filter?type=patient`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        setPatientFilterList(res.data);
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const handleFilterChange = (filter) => {
    history.push(
      '/clinician/appointments?name=' + filter.filterName + '&page=1'
    );
    filterAppointment(filter._id);
  };

  const handlePatientFilterChange = (filter) => {
    history.push('/clinician/patients?name=' + filter.filterName + '&page=1');
    filterPatient(filter._id);
  };

  const handleFilterDelete = (id) => {
    Axios.delete(`${baseURL}/api/filter/${id}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res) {
          getFilters();
          AlertMsg('success', 'Succesfully checked in Appointment');
        }
      })
      .catch((e) => {
        AlertMsg('error', 'Something went wrong!');
        console.log('eeeeeeee', e);
      });
  };

  useEffect(() => {
    setOpen(location.pathname.includes('appointments'));
    setPatientOpen(location.pathname.includes('patients'));
  }, []);

  const generateName = (user) => {
    const name =
      (user.prefix ? user.prefix + ' ' : '') +
      user.firstName +
      ' ' +
      user.lastName +
      (user.suffix ? ', ' + user.suffix : '');

    return name;
  };
  return (
    <div className={classes.toolbar}>
      <div style={{ marginTop: 20 }}>
        <div className={classes.topSidebar}>
          {/*  <img src={onlineIcon} className={classes.onlineIcon}/>*/}
          <img
            src={loginData.avatar || defaultSideImage}
            width='100'
            className={classes.defaultimg}
          />
        </div>
      </div>
      <h3
        style={{
          margin: 0,
          color: '#304659',
          fontSize: '16px',
          lineHeight: '22px',
          marginBottom: '7px',
        }}
      >
        {loginData.firstName ? generateName(loginData) : ''}
      </h3>
      {/* <p className={classes.sideTextName}>Clinician Psychologist</p>*/}
      <div>
        <span className={classes.primaryBtn}>
          {loginData.role === 'clinician' ? 'Clinician' : 'Admin'}
        </span>
      </div>
      {/* <Button variant="contained" color="primary" >
                Clinician
            </Button> */}
      <div className={classes.borderBottom}></div>
      <List component='nav' className={(classes.appMenu, 'menu-list-custom')}>
        {loginData.role === 'clinician' && (
          <Link to='/clinician/dashboard' className={classes.menuText}>
            <ListItem
              button
              className={classes.menuItem}
              selected={getMenuItemActive('/clinician/dashboard', false)}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <img
                  src={
                    getMenuItemActive('/clinician/dashboard', false)
                      ? DashboardIconSelected
                      : DashboardIcon
                  }
                />
              </ListItemIcon>
              <Typography className={(classes.textcolor, 'text-color-link')}>
                Dashboard
              </Typography>
            </ListItem>
          </Link>
        )}
        <Link to='/clinician/appointments' className={classes.menuText}>
          <ListItem
            button
            className={classes.menuItem}
            onClick={handleClick}
            selected={getMenuItemActive('/clinician/appointments', false)}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <AccessAlarmIcon />
            </ListItemIcon>
            <Typography className={(classes.textcolor, 'text-color-link')}>
              Appointments
            </Typography>
          </ListItem>
        </Link>
        <Link to='/clinician/calendar' className={classes.menuText}>
          <ListItem
            button
            className={classes.menuItem}
            onClick={handleClick}
            selected={getMenuItemActive('/clinician/calendar', false)}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <CalendarTodayIcon />
            </ListItemIcon>
            <Typography className={(classes.textcolor, 'text-color-link')}>
              Calendar
            </Typography>
          </ListItem>
        </Link>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List
            component='div'
            disablePadding
            style={{ maxHeight: '350px', overflowY: 'scroll' }}
          >
            {filterList.map((filter, i) => (
              <ListItem key={i} button className={classes.nested}>
                <Link onClick={() => handleFilterChange(filter)}>
                  <Typography
                    className={
                      (classes.textcolor, 'text-color-link filter-list')
                    }
                  >
                    {filter.filterName ? filter.filterName : 'NA'}
                  </Typography>
                </Link>
                <Button
                  variant='contained'
                  onClick={() => {
                    handleFilterDelete(filter._id);
                  }}
                >
                  X
                </Button>
              </ListItem>
            ))}
          </List>
        </Collapse>
        <Link to='/clinician/events' className={classes.menuText}>
          <ListItem
            button
            className={classes.menuItem}
            selected={getMenuItemActive('/clinician/events', false)}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <ShareIcon />
            </ListItemIcon>
            <Typography className={(classes.textcolor, 'text-color-link')}>
              Events
            </Typography>
          </ListItem>
        </Link>

        <Link to='/clinician/patients' className={classes.menuText}>
          <ListItem
            button
            className={classes.menuItem}
            onClick={handlePatientClick}
            selected={
              getMenuItemActive('/clinician/patients', false)
                ? getMenuItemActive('/clinician/patients', false)
                : getMenuItemActive('/clinician/patient-detail/', false)
            }
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <PeopleIcon />
            </ListItemIcon>
            <Typography className={(classes.textcolor, 'text-color-link')}>
              Patients
            </Typography>
          </ListItem>
        </Link>
        <Collapse in={patientOpen} timeout='auto' unmountOnExit>
          <List
            component='div'
            disablePadding
            style={{ maxHeight: '350px', overflowY: 'scroll' }}
          >
            {patientFilterList.map((filter, i) => (
              <ListItem key={i} button className={classes.nested}>
                <Link onClick={() => handlePatientFilterChange(filter)}>
                  <Typography
                    className={
                      (classes.textcolor, 'text-color-link filter-list')
                    }
                  >
                    {filter.filterName ? filter.filterName : 'NA'}
                  </Typography>
                </Link>
                <Button
                  variant='contained'
                  onClick={() => {
                    handleFilterDelete(filter._id);
                  }}
                >
                  X
                </Button>
              </ListItem>
            ))}
          </List>
        </Collapse>
        {loginData.role === 'clinician' && (
          <Link to='/clinician/time-slots' className={classes.menuText}>
            <ListItem
              button
              className={classes.menuItem}
              selected={getMenuItemActive('/clinician/time-slots', false)}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <CalendarTodayIcon />
              </ListItemIcon>
              <Typography className={(classes.textcolor, 'text-color-link')}>
                Time Slots
              </Typography>
            </ListItem>
          </Link>
        )}
        <Link to='/clinician/consent-forms' className={classes.menuText}>
          <ListItem
            button
            className={classes.menuItem}
            selected={getMenuItemActive('/clinician/consent-forms', false)}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <SettingsIcon />
            </ListItemIcon>
            <Typography className={(classes.textcolor, 'text-color-link')}>
              Consent Forms
            </Typography>
          </ListItem>
        </Link>
        {/* <Link to="#" className={classes.menuText}>
                    <ListItem button className={classes.menuItem}>
                        <ListItemIcon className={classes.menuItemIcon}>
                            <PersonIcon/>
                        </ListItemIcon>
                        <Typography className={classes.textcolor, 'text-color-link'}>Doctors</Typography>
                    </ListItem>
                </Link>
                <Link to="#" className={classes.menuText}>
                    <ListItem button className={classes.menuItem}>
                        <ListItemIcon className={classes.menuItemIcon}>
                            <DynamicFeedIcon/>
                        </ListItemIcon>
                        <Typography className={classes.textcolor, 'text-color-link'}>Finances</Typography>
                    </ListItem>
                </Link> */}
        <Link to='/clinician/settings' className={classes.menuText}>
          <ListItem
            button
            className={classes.menuItem}
            selected={getMenuItemActive('/clinician/settings', false)}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <SettingsIcon />
            </ListItemIcon>
            <Typography className={(classes.textcolor, 'text-color-link')}>
              Settings
            </Typography>
          </ListItem>
        </Link>
        <Link to='#' onClick={handleLogout} className={classes.menuText}>
          <ListItem button className={classes.menuItem}>
            <ListItemIcon className={classes.menuItemIcon}>
              <ExitToAppIcon />
            </ListItemIcon>
            <Typography className={(classes.textcolor, 'text-color-link')}>
              Logout
            </Typography>
          </ListItem>
        </Link>
      </List>
    </div>
  );
}
// export default SidebarMenu;
