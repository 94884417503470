/* eslint-disable react/no-unescaped-entities */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from "react";
import { Grid, Typography, withStyles } from "@material-ui/core";
import sign_up_img from "../../../assets/imgs/Background (1).png";
import signup_line from "../../../assets/user_images/signup_line.svg";
import logo from "../../../assets/user_images/logo.svg";
import styles from "./BackgroundImageStyle";

class BackgroundImage extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid component="div" className={classes.imageContainer}>
          <img src={sign_up_img} className={classes.bkgimg} />
          <Grid className={classes.bg_container}>
            <img src={logo} className={classes.lightlogo} />
            <Typography className={classes.tagline}>EHM System</Typography>
            <Typography className={classes.bg_title}>
              Welcome to Dubai's premier Wellness center.
            </Typography>
            <img src={signup_line} className={classes.bg_divider} />
            <Typography className={classes.bg_subtitle}>
              Sed non augue nisi. Suspendisse vitae vehicula enim. Maecenas
              fermentum vel augue sed cursus. Sed malesuada odio.
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}


export default withStyles(styles)(BackgroundImage);
