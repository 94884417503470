/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-sequences */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { GridContainer, GridItem } from '../common/BodyContainer';
import { CustomTable } from '../common/Table';
import { TableTitleFont } from '../common/TableFont';
import FilterImage from '../../assets/imgs/download.png';
import { HomeBoxes } from '../components/HomeBoxes';
import { HomeNotification } from '../components/HomeNotification';
import DrawerMenu from '../common/Header/DrawerMenu';
import UpcomingVisits from '../components/Appointment Request/UpcomingVisits';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  getUnpaidAppointmentAction,
  getUpcomingAppointmentAction,
  getNotificationAction,
  updateNotificationAction,
} from '../../utils/actions/dashboard.action';
import { getByIdClientAction } from '../../utils/actions/client.action';
import PaymentOption from '../components/PaymentOption';
import Drawer from '@material-ui/core/Drawer';
import Axios from 'axios';
import { baseURL } from '../../utils/axios';
import AlertMsg from '../../utils/Alert';
import ConfirmCancelAppointment from '../components/ConfirmCancelAppointment';
import ScheduleModal from '../../clinician/components/ScheduleModal';
import styles from './Home_style';
import moment from 'moment';

const boxes = [
  // {
  //   title: 'COvid-19',
  //   subTitle: ' Metntal health & wellbeing services',
  //   bodyTitle: 'Concerned about the Coronavirus?',
  //   bodyDesc:
  //     ' We have created an assessment based on CDC guidelines to help you prepare for COVID-19',
  // },
  {
    title: 'Mental health',
    subTitle: ' Adult & Children',
    bodyTitle: 'Concerned about the Coronavirus?',
    bodyDesc:
      ' We have created an assessment based on CDC guidelines to help you prepare for COVID-19',
  },
];

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unpaidList: [],
      upcomingList: [],
      notificationList: [],
      isUpcomingVisitRequest: false,
      isUnpaidLoaded: true,
      isUpcomingLoaded: true,
      selectedDetails: {},
      openAppConfirmModal: false,
      confirmMsg: '',
      userInfo: null,
      selectedAppointment: '',
      loadingConfApp: false,
      reScheduleAction: false,
      reScheduleConfBtnLoading: false,
      reSClinicianDetails: null,
    };
  }

  componentDidMount() {
    this.props.getUnpaidAppointmentAction();
    this.props.getUpcomingAppointmentAction();
    this.props.getNotificationAction();
    this.props.getByIdClientAction();
    localStorage.removeItem('appFor');
    localStorage.removeItem('appChildDetails');
    localStorage.removeItem('multipleAdults');
  }

  componentDidUpdate(preProps) {
    if (this.props.updateNotification !== preProps.updateNotification) {
      this.props.getNotificationAction();
    }

    if (this.props.getUnpaidData !== preProps.getUnpaidData) {
      const data = this.props.getUnpaidData;
      if (data) {
        const list = data.map((node) => ({
          _id: node.appointmentId._id,
          clinicianId: node.appointmentId.clinicianId
            ? node.appointmentId.clinicianId._id
            : null,
          referralByImage:
            node.appointmentId.clinicianId &&
            node.appointmentId.clinicianId.avatar
              ? node.appointmentId.clinicianId.avatar
              : FilterImage,
          referralBy:
            node.appointmentId.clinicianId &&
            node.appointmentId.clinicianId.firstName &&
            node.appointmentId.clinicianId.lastName
              ? `${node.appointmentId.clinicianId.firstName} ${node.appointmentId.clinicianId.lastName}`
              : '',
          date: node.appointmentId?.startTime
            ? this.customDateFormat(new Date(node.appointmentId.startTime))
            : '',
          refClinicianImage: null,
          refClinician: '--',
          patient:
            node.clientId && node.clientId.avatar
              ? node.clientId.avatar
              : FilterImage,
          paymentStatus: node.status === 'unpaid' && 'Unpaid',
          action: 'Submit Payment',
          actionType: 'no',
          billingId: node,
        }));
        this.setState({
          unpaidList: list,
          isUnpaidLoaded: false,
        });
      }
    }

    if (this.props.getUpcomingData !== preProps.getUpcomingData) {
      const data = this.props.getUpcomingData;
      if (data) {
        let upcomingApp = [];
        data.map((node) => {
          if (node.billingId && node.billingId.status === 'paid') {
            let obj = {
              clinicianId: node.clinicianId ? node.clinicianId._id : null,
              referralByImage:
                node.clinicianId && node.clinicianId.avatar
                  ? node.clinicianId.avatar
                  : FilterImage,
              referralBy: `${node.clinicianId.firstName} ${node.clinicianId.lastName}`,
              date: this.customDateFormat(new Date(node.startTime)),
              refClinicianImage: null,
              refClinician: '--',
              patient:
                node.clientId && node.clientId.length && node.clientId[0].avatar
                  ? node.clientId[0].avatar
                  : FilterImage,
              paymentStatus: 'Paid',
              action: 'View',
              actionType: 'yes',
              viewDetails: node,
              billingId: node.billingId,
            };
            upcomingApp.push(obj);
          }
        });
        this.setState({
          upcomingList: upcomingApp,
          isUpcomingLoaded: false,
        });
      }
    }

    if (this.props.getNotification !== preProps.getNotification) {
      const data = this.props.getNotification;
      if (data) {
        const list = data.map((node) => ({
          title: `${node.alert} Alert`,
          desc: node.description,
          url: node.url,
          id: node._id,
          status: node.status,
        }));
        this.setState({
          notificationList: list,
        });
      }
    }

    if (this.props.getClientData !== preProps.getClientData) {
      if (
        this.props.getClientData &&
        this.props.getClientData.getByIdClientData
      ) {
        this.setState({ userInfo: this.props.getClientData.getByIdClientData });
      }
    }
  }

  handleCancelAppointment = (id) => {
    if (id) {
      const { userInfo } = this.state;
      let msg = '';
      if (userInfo) {
        switch (userInfo.appointmentCancel) {
          case 0:
            msg = 'Are you sure? You want to cancel this appointment!';
            break;
          case 1:
            msg =
              'If you cancel this appointment you need to pay the half of amount! Are you agree?';
            break;
          case 2:
            msg =
              'If you cancel this appointment you need to pay the full amount! Are you agree?';
            break;
          default:
            msg =
              'If you cancel this appointment you will be blocked. Are you agree?';
        }
      }
      this.setState({
        openAppConfirmModal: true,
        selectedAppointment: id,
        confirmMsg: msg,
        reScheduleAction: false,
      });
    }
  };

  handleCloseConfAppointment = () => {
    this.setState({
      openAppConfirmModal: false,
      selectedAppointment: '',
      confirmMsg: '',
      reScheduleAction: false,
      loadingConfApp: false,
    });
  };

  cancelAppointment = () => {
    const { selectedAppointment, reScheduleAction } = this.state;
    if (selectedAppointment && reScheduleAction === false) {
      Axios.get(`${baseURL}/api/appointment/${selectedAppointment}/cancel`, {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      })
        .then((res) => {
          this.props.getUpcomingAppointmentAction();
          AlertMsg('success', 'Your appointment successfully cancelled.');
          this.handleCloseConfAppointment();
          this.setState({
            isUpcomingVisitRequest: false,
            isOpen: false,
            selectedDetails: {},
            loadingConfApp: false,
          });
          return null;
        })
        .catch((e) => {
          AlertMsg('success', 'Invalid request!');
          return null;
        });
    } else {
      return null;
    }
  };

  getFormatedClinicianData = (uData) => {
    const clinician = uData;
    let nextAvailable = null;
    let schedules = clinician.schedule;
    // if(schedules && schedules.length){
    schedules = schedules.map((sch) => {
      if (sch.slots.length) {
        sch.slots = sch.slots.map((s) => {
          let startTime = moment(new Date(s.startTime));
          let slot = startTime.hours() + ':' + startTime.minutes();
          let timeArr = slot.split(':');
          let hrs = '00',
            min = '00';
          if (timeArr.length) {
            hrs = timeArr[0];
            min = timeArr[1];
          }
          if (timeArr[0].length === 1) {
            hrs = `0${timeArr[0]}`;
          }
          if (timeArr[1].length === 1) {
            min = `0${timeArr[1]}`;
          }
          let time = `${hrs}:${min}`;
          let lclDate = new Date(`${sch.date}T${time}:00`);
          let lclHrs = lclDate.getHours().toString();
          if (lclHrs.length === 1) {
            lclHrs = `0${lclHrs}`;
          }
          return `${lclHrs}:${
            lclDate.getMinutes() > 0 ? lclDate.getMinutes() : '00'
          }`;
        });
      }
      return sch;
    });

    for (let sch of schedules) {
      if (sch.slots.length && sch.slots.length > 0) {
        nextAvailable = sch;
        break;
      }
    }

    // }
    let data = {
      id: clinician._id,
      name: `Dr. ${clinician.firstName} ${clinician.lastName}`,
      study: '',
      speciality: 'Specialist Psychiatrist',
      times: schedules,
      nextAvailable,
      img: clinician.avatar,
      cost: `${clinician.costPerUnit} Dhs / ${clinician.appointmentSlotUnit}min`,
      appointmenSlot: clinician.appointmentSlotUnit,
      amount: clinician.costPerUnit,
      selectTimeSlot: null,
      isAlternative: false,
      nextAvailiblityText: this.getNextAvailibility(clinician.schedule),
    };
    return data;
  };

  getDate = (date1) => {
    let today = date1 ? new Date(date1) : new Date();
    let d = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    let m = today.getMonth() < 10 ? '0' + today.getMonth() : today.getMonth();
    date1 = d + '/' + m + '/' + today.getFullYear();
    return date1;
  };

  getNextAvailibility = (schedules) => {
    let result = '';
    if (schedules.length) {
      let day = new Date();
      day.setDate(day.getDate() + 1);
      let nextDay = this.getDate(day);
      if (schedules[0].date == nextDay) {
        result = 'Tomorrow';
      } else if (new Date(schedules[0].date) == day) {
        result = 'Today';
      } else {
        result = schedules[0].date;
      }
    } else {
      result = 'Not Available';
    }
    return result;
  };

  getNewAppointmentSloats = () => {
    if (
      this.state.selectedDetails &&
      this.state.selectedDetails.clinicianId &&
      this.state.reScheduleAction
    ) {
      Axios.get(`${baseURL}/api/client/doctor`, {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      })
        .then((res) => {
          if (res.data && res.data.length) {
            let clinician = res.data.filter((item) => {
              if (
                item._id.toString() ===
                this.state.selectedDetails.clinicianId._id.toString()
              ) {
                return item;
              }
            });
            if (clinician.length > 0) {
              this.setState({
                reSClinicianDetails: this.getFormatedClinicianData(
                  clinician[0]
                ),
                openReScheduleModal: true,
              });
            } else {
              this.handleCloseConfAppointment();
              this.setState({
                isUpcomingVisitRequest: false,
                isOpen: false,
                selectedDetails: {},
                loadingConfApp: false,
              });
              AlertMsg('error', 'Doctor details not found!');
            }
          } else {
            this.handleCloseConfAppointment();
            this.setState({
              isUpcomingVisitRequest: false,
              isOpen: false,
              selectedDetails: {},
              loadingConfApp: false,
            });
            AlertMsg(
              'error',
              'Booking sloats not availabel for this appointment'
            );
          }
        })
        .catch((e) => {
          AlertMsg(
            'error',
            'Booking sloats not availabel for this appointment'
          );
        });
    }
  };

  handleCancelAppointmentConfirm = () => {
    const { selectedAppointment, reScheduleAction } = this.state;
    this.setState({ loadingConfApp: true });

    if (selectedAppointment) {
      if (reScheduleAction) {
        this.getNewAppointmentSloats();
      } else {
        this.cancelAppointment();
      }
    } else {
      return null;
    }
  };

  handleRescheduleAppointment = (id) => {
    if (id) {
      const { userInfo } = this.state;
      let msg = '';
      if (userInfo) {
        switch (userInfo.appointmentCancel) {
          case 0:
            msg = 'Are you sure? You want to reschedule this appointment!';
            break;
          case 1:
            msg =
              'If you reschedule this appointment you need to pay the half of amount! Are you agree?';
            break;
          case 2:
            msg =
              'If you reschedule this appointment you need to pay the full amount! Are you agree?';
            break;
          default:
            msg =
              'If you reschedule this appointment you will be blocked. Are you agree?';
        }
      }
      this.setState({
        openAppConfirmModal: true,
        selectedAppointment: id,
        confirmMsg: msg,
        reScheduleAction: true,
      });
    }
  };

  handleAlternativeCalendar = () => {
    const { reSClinicianDetails } = this.state;
    this.setState({
      reSClinicianDetails: {
        ...reSClinicianDetails,
        isAlternative: !reSClinicianDetails.isAlternative,
      },
    });
  };

  handleReScheduleTimeSelect = (value) => {
    const { reSClinicianDetails } = this.state;
    this.setState({
      reSClinicianDetails: {
        ...reSClinicianDetails,
        status: 'selected',
        selectTimeSlot: value,
        isAlternative: false,
      },
    });
  };

  formatDateYMD = (x, y) => {
    var z = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds(),
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
      return ((v.length > 1 ? '0' : '') + eval('z.' + v.slice(-1))).slice(-2);
    });

    return y.replace(/(y+)/g, function (v) {
      return x.getFullYear().toString().slice(-v.length);
    });
  };

  handleCalendarTimeSelect = (times, value, date) => {
    const { reSClinicianDetails } = this.state;
    let newDate = this.formatDateYMD(date, 'yyyy-MM-dd');
    this.setState({
      reSClinicianDetails: {
        ...reSClinicianDetails,
        status: 'selected',
        selectTimeSlot: new Date(`${newDate} ${value}`),
        isAlternative: false,
      },
    });
  };

  handleCloseReSchedule = () => {
    this.setState({
      openReScheduleModal: false,
      reScheduleConfBtnLoading: false,
    });
    this.handleCloseConfAppointment();
  };

  handleCancelReScheduleAppointment = (index) => {
    const { reSClinicianDetails } = this.state;
    let tmpClinicianDetails = { ...reSClinicianDetails };
    tmpClinicianDetails = {
      ...tmpClinicianDetails,
      selectTimeSlot: null,
    };
    this.setState({ reSClinicianDetails: tmpClinicianDetails });
  };

  handleReScheduleAppointmentConfirm = () => {
    this.setState({ reScheduleConfBtnLoading: true });
    let data = {
      startTime: this.state.reSClinicianDetails.selectTimeSlot,
    };
    Axios.put(
      `${baseURL}/api/appointment/${this.state.selectedAppointment}`,
      data,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        this.handleCloseReSchedule();
        this.handleCloseConfAppointment();
        this.getUpcomingAppointmentAction();
        this.setState({
          isUpcomingVisitRequest: false,
          isOpen: false,
          selectedDetails: {},
          loadingConfApp: false,
        });
        AlertMsg('success', 'Appointment rescheduled succesfully');
      })
      .catch((e) => {
        this.setState({ reScheduleConfBtnLoading: false });
        AlertMsg('error', 'Something went wrong!');
      });
  };

  customDateFormat = (date) => {
    var dateStr =
      ('00' + date.getDate()).slice(-2) +
      '-' +
      ('00' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      date.getFullYear() +
      ' ' +
      ('00' + date.getHours()).slice(-2) +
      ':' +
      ('00' + date.getMinutes()).slice(-2);
    return dateStr;
  };

  handleNotificationClick = (id, index) => {
    this.props.updateNotificationAction(id);
    const notificationList = [...this.state.notificationList];
    notificationList.splice(index, 1);
    this.setState({ notificationList });
  };

  handleUpcomingAppClick = (item) => {
    if (item.paymentStatus == 'Unpaid') {
      this.props.history.push(`/verifyinfo/${item._id}`);
    } else {
      this.setState({
        isOpen: true,
        appointmentId: item._id,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      openAppConfirmModal,
      confirmMsg,
      loadingConfApp,
      reScheduleConfBtnLoading,
      reSClinicianDetails,
      openReScheduleModal,
    } = this.state;
    return (
      <div>
        {/* <Header /> */}
        <DrawerMenu {...this.props} />
        <GridContainer />
        <GridItem
          {...this.props}
          style={{
            zIndex: this.state.isOpen ? 100 : '',
            backgroundColor: this.state.isOpen ? 'rgba(34,34,34,48%)' : '',
            bottom: 0,
          }}
        >
          <HomeNotification
            content={this.state.notificationList}
            onSelect={this.handleNotificationClick}
          />
          <HomeBoxes
            data={boxes}
            style={{
              opacity: this.state.isOpen ? '40%' : '',
            }}
            {...this.props}
          />

          <Grid style={{ marginBottom: '44px' }}>
            <TableTitleFont text='Unpaid Invoices' />
            <CustomTable
              data={this.state.unpaidList}
              isLoader={this.state.isUnpaidLoaded}
              onRowSelect={this.handleUpcomingAppClick}
              style={{ opacity: this.state.isOpen ? '40%' : '' }}
              {...this.props}
            />
          </Grid>
          <Grid style={{ marginBottom: '44px' }}>
            <TableTitleFont text='Your Upcoming Visits' />
            <CustomTable
              data={this.state.upcomingList ? this.state.upcomingList : []}
              isLoader={this.state.isUpcomingLoaded}
              style={{ opacity: this.state.isOpen ? '40%' : '' }}
              onRowSelect={(item) =>
                this.setState({
                  isUpcomingVisitRequest: true,
                  isOpen: true,
                  selectedDetails: item.viewDetails,
                })
              }
              {...this.props}
            />
          </Grid>
        </GridItem>
        <Drawer
          anchor='right'
          open={this.state.isOpen}
          className={classes.Backdrop}
          onClose={() => this.setState({ isOpen: false })}
          {...this.props}
        >
          <div className={classes.drawerHeader}>
            <IconButton
              aria-label='close'
              onClick={(evt) => this.setState({ isOpen: false })}
            >
              <CancelIcon className={classes.closeIcon} />
            </IconButton>
          </div>
        </Drawer>
        <Drawer
          anchor='right'
          onClose={() =>
            this.setState({
              isUpcomingVisitRequest: false,
              isOpen: false,
              selectedDetails: {},
            })
          }
          open={this.state.isUpcomingVisitRequest}
          {...this.props}
        >
          <div className={classes.drawerHeader} style={{ paddingRight: 35 }}>
            <IconButton
              aria-label='close'
              onClick={(evt) =>
                this.setState({
                  isUpcomingVisitRequest: false,
                  isOpen: false,
                  selectedDetails: {},
                })
              }
            >
              <CancelIcon className={classes.closeIcon} />
            </IconButton>
          </div>
          <UpcomingVisits
            handleCancelAppointment={this.handleCancelAppointment}
            handleRescheduleAppointment={this.handleRescheduleAppointment}
            data={this.state.selectedDetails}
          />
        </Drawer>
        <ConfirmCancelAppointment
          handleClose={this.handleCloseConfAppointment}
          open={openAppConfirmModal}
          handleCancelAppointmentConfirm={this.handleCancelAppointmentConfirm}
          message={confirmMsg}
          loadingConfApp={loadingConfApp}
        />
        {reSClinicianDetails && (
          <ScheduleModal
            clinicianDetails={reSClinicianDetails}
            open={openReScheduleModal}
            handleClose={this.handleCloseReSchedule}
            handleCalendarTimeSelect={this.handleCalendarTimeSelect}
            handleCancelAppointment={this.handleCancelReScheduleAppointment}
            handleAppointmentConfirm={this.handleReScheduleAppointmentConfirm}
            handleTimeSelect={this.handleReScheduleTimeSelect}
            handleAlternativeCalendar={this.handleAlternativeCalendar}
            confirmBtnLoading={reScheduleConfBtnLoading}
          />
        )}
      </div>
    );
  }
}

Home.defaultProps = {
  getClientData: {},
};

const connected = connect(
  (state) => ({
    getUnpaidData: state.getUnpaidAppointment.getUnpaidAppointmentData,
    getUpcomingData: state.getUpcomingAppointment.getUpcomingAppointmentData,
    isUnpaidLoaded: state.getUnpaidAppointment.request,
    isUpcomingLoaded: state.getUpcomingAppointment.request,
    getNotification: state.getNotification.getNotificationData,
    updateNotification: state.UpdateNotification,
    getClientData: state.getByIdClient,
  }),
  {
    getUnpaidAppointmentAction,
    getUpcomingAppointmentAction,
    getNotificationAction,
    updateNotificationAction,
    getByIdClientAction,
  }
)(Home);

const formed = reduxForm({
  form: 'getDashbord',
})(connected);

export default withStyles(styles)(formed);
