import { Fragment, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import countryData from "./countriesData"
import ReactCountryFlag from "react-country-flag"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Example(props) {
    const [justOnce, setJustOnce] = useState(true)
    const [data, setData] = useState([])
    const [value, setValue] = useState({
        countryCallingCode: "971"
    })

    useEffect(() => {
        setData(countryData.sort((a, b) => {
            if (a.countryNameEn > b.countryNameEn) return 1;
            if (a.countryNameEn < b.countryNameEn) return -1;

            return 0;
        }))
    }, [])

    useEffect(() => {
        if (props.handleChange && value) {
            props.handleChange(value)
        }
    }, [value])

    useEffect(() => {
        if (props.initialValue && justOnce) {
            setValue(data.filter(data => data.countryCallingCode == props.initialValue.replace("+", ""))[0])
            setJustOnce(false)
        }
    }, [props.initialValue])

    return (
        <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="inline-flex items-center h-12 p-2 border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                <ReactCountryFlag countryCode={value?.countryCode ?? "AE"} svg />
                <span className="inline-block ml-1">+{value?.countryCallingCode}</span>
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-left absolute left-0 mt-2 w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1 overflow-auto max-h-96">
                        {data?.map(value =>
                            <Menu.Item onClick={() => setValue(value)}>
                                {({ active }) => (
                                    <p
                                        className={"cursor-pointer	" + classNames(
                                            active ? 'bg-gray-300 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm'
                                        )}
                                    >
                                        <ReactCountryFlag countryCode={value.countryCode} svg /> <span className='ml-2'> {"" +value.countryNameEn + " +" + value.countryCallingCode} </span>
                                    </p>
                                )}
                            </Menu.Item>
                        )}

                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

