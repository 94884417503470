import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import countries from '../../utils/countries_data';
import styled from 'styled-components';
import AlertMsg from '../../utils/Alert';
import axios from 'axios';
import { baseURL } from '../../utils/axios';
import DatePicker from 'react-datepicker';
import './style.css';
import { Spinner } from '../../components/atoms';
import ReCAPTCHA from 'react-google-recaptcha';

const AutoComplete = styled(Autocomplete)`
  & .MuiInputBase-root {
    margin-top: 8px;
    padding: 4px 8px;
  }
  & .MuiInputBase-input {
    height: 0px;
    padding: 16px 0px !important;
  }
`;

const defaultForm = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  city: '',
  country: '',
  message: '',
};

const FormForFutureEvents = ({ history, eventId }) => {
  const [form, setForm] = useState(defaultForm);

  const [loading, setLoading] = useState(false);
  const [isUserAgree, setIsUserAgree] = useState(false);
  const [captchaVal, setCaptchaVal] = useState('');

  const selectCountry = (e, selectedCountry) => {
    e.persist();
    setForm({ ...form, country: selectedCountry });
  };

  const changeDOB = (value) => setForm({ ...form, dob: value });

  const onChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

  const validateAddress = () => {
    let errorMessage = '';
    if (!form.firstName.trim()) {
      errorMessage = 'First name can not be empty';
    } else if (!form.lastName.trim()) {
      errorMessage = 'Last name can not be empty';
    } else if (!form.phoneNumber.trim()) {
      errorMessage = 'Phone number can not be empty';
    } else if (!form.email.trim()) {
      errorMessage = 'Email can not be empty';
    } else if (!form.dob) {
      errorMessage = 'Date of birth can not be empty';
    } else if (!form.country) {
      errorMessage = 'Country can not be empty';
    } else if (!form.city.trim()) {
      errorMessage = 'City can not be empty';
    }

    if (errorMessage !== '') {
      AlertMsg('error', errorMessage);
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    const isValid = validateAddress();

    if (!isValid) {
      return;
    }

    if (!isUserAgree) {
      AlertMsg(
        'error',
        'User need to agree the terms & condition in order to proceed'
      );
      return;
    }

    if (!captchaVal) {
      AlertMsg('error', 'Please check the captcha in order to proceed');
      return;
    }
    setLoading(true);
    axios
      .post(`${baseURL}/api/event/info-for-future-interest`, { ...form, eventId })
      .then((res) => {
        setForm(defaultForm);
        setCaptchaVal('');
        setIsUserAgree(false);
        setLoading(false);
        AlertMsg('success', 'Information added for future updates.');
        setTimeout(() => window.location.reload(), 3000);
      })
      .catch((err) => {
        setLoading(false);
        AlertMsg('error', err.message);
      });
  };

  const togglePolicyAgreement = () => setIsUserAgree(!isUserAgree);

  const onCaptchaChange = (value) => setCaptchaVal(value);

  return (
    <div
      className='text-left p-10 flex flex-col'
      style={{ color: 'rgb(0, 58, 112)' }}
    >
      {loading && <Spinner isOpen />}
      <p className='text-3xl'>Register for an Event</p>
      <p className='mt-3'>
        This event is fully booked, but you may register your interest for
        upcoming dates. Our team will get in touch with you once new dates have
        been added to the event calendar.
      </p>
      <p className='text-xl mt-3 uppercase'>Register your interest</p>
      <input
        type='text'
        name='firstName'
        value={form.firstName}
        className='mt-2 h-10 rounded-sm py-1 px-2'
        style={{ border: '1px solid #ccc' }}
        placeholder='First Name'
        onChange={onChange}
      />

      <input
        type='text'
        name='lastName'
        value={form.lastName}
        className='mt-2 h-10 rounded-sm py-1 px-2'
        style={{ border: '1px solid #ccc' }}
        placeholder='Last Name'
        onChange={onChange}
      />

      <input
        type='text'
        name='phoneNumber'
        value={form.phoneNumber}
        className='mt-2 h-10 rounded-sm py-1 px-2'
        style={{ border: '1px solid #ccc' }}
        placeholder='Your Phone'
        onChange={onChange}
      />

      <input
        type='text'
        name='email'
        value={form.email}
        className='mt-2 h-10 rounded-sm py-1 px-2'
        style={{ border: '1px solid #ccc' }}
        placeholder='Your Email'
        onChange={onChange}
      />

      <div
        style={{ border: '1px solid #ccc' }}
        className='upcoming-events mt-2 h-10 rounded-sm py-1 px-2'
      >
        <DatePicker
          locale='en'
          dateFormat='MMMM d, yyyy'
          selected={form.dob}
          style={{ width: '100%' }}
          showYearDropdown
          name='dob'
          dropdownMode='select'
          showMonthDropdown
          onChange={changeDOB}
          maxDate={new Date()}
          placeholderText='Date of birth'
        />
      </div>

      <input
        type='text'
        name='city'
        value={form.city}
        className='mt-2 h-10 rounded-sm py-1 px-2'
        style={{ border: '1px solid #ccc' }}
        placeholder='City'
        onChange={onChange}
      />

      <AutoComplete
        value={form.country}
        options={countries}
        name='country'
        onChange={(e, value) => selectCountry(e, value)}
        getOptionSelected={(option, value) => option.value === value.value}
        getOptionLabel={(option) => option.name}
        renderOption={(option) => (
          <React.Fragment>{option.name}</React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Select a country'
            variant='outlined'
            inputProps={{
              ...params.inputProps,
              autoComplete: +new Date(),
            }}
          />
        )}
      />

      <input
        type='text'
        name='message'
        value={form.message}
        className='mt-2 h-10 rounded-sm py-1 px-2'
        style={{ border: '1px solid #ccc' }}
        placeholder='Message'
        onChange={onChange}
      />

      <div className='flex items-baseline'>
        <input
          type='checkbox'
          value={isUserAgree}
          onChange={togglePolicyAgreement}
        />
        <label className='ml-2 mt-3 text-sm	' htmlFor='policy'>
          I have read and understand the{' '}
          <a
            className='hover:no-underline text-blue'
            href='https://www.lighthousearabia.com/experience/our-event-policy/'
            target='_blank'
          >
            Event Cancellation and Refund policy
          </a>
          .
        </label>
      </div>

      <div className='mt-3'>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_CAPTCHA_KEY}
          onChange={onCaptchaChange}
        />
      </div>
      <button
        className='mt-3 bg-blue-500 text-white font-bold py-2 px-4 rounded-sm w-max'
        onClick={handleSubmit}
      >
        Submit
      </button>
    </div>
  );
};

export default FormForFutureEvents;
