import React, { useState, useEffect } from 'react';
import Layout from '../common/AdminLayout';
import { Button, Grid } from '@material-ui/core';
import useStyles from './Reports_style';
import { Link } from 'react-router-dom';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { baseURL } from '../../../utils/axios';
import moment from 'moment';
import {
  Spinner,
  Dialog,
  Button as ConfirmBtn,
} from '../../../components/atoms';
import WeekelyReport from './WeekelyReport';
import MonthlyReports from './monthlyReports';
import DailyReports from './DailyReports';
import {
  getInPersonReportFormat,
  clientFlowFormat,
  pendingPaymentUpcomingFormat,
  newClientFormat,
  psychiatryAppointmentsFormat,
  incompleteDocUpcomingAppointmentsFormat,
  paymentReconciliationFormat,
  numberOfClinicianSessionsFormat,
  paymentReconciliationWeeklyFormat,
  appointmentsWithPendingPaymentFormat,
  outPatientsFormat,
  emiratiPatientsFormat,
  paymentReconciliationMonthlyFormat,
  diagnosisReportFormat,
  clinicianNotesFormat,
} from './formats';
import { REPORTS_MASTER_PASSWORD } from '../../../utils/appConstants';
import AlertMsg from '../../../utils/Alert';
import { OutPatientReport, EmiratiReport } from './reportTables';

import './style.css';

const calculateColTotal = (list, index) => {
  let count = 0;
  for (var i = 0; i < list.length; i++) {
    count = count + list[i][index];
  }

  return count;
};

export default function ReportCard() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [reportToDownload, setReportToDownload] = useState('');
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [passwordVerified, setPasswordVerified] = useState(false);
  const [enteredPassword, setEnteredPassword] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [checkPassword, setCheckPassword] = useState(false);
  const [outPatientData, setOutPatientData] = useState([]);
  const [emiratiPatientsData, setEmiratiPatientsData] = useState([]);

  useEffect(() => {
    const protectedFile = [
      'paymentReconciliation',
      'paymentReconciliationWeekly',
      'paymentReconciliationMonthly',
      'pendingPaymentUpcoming',
      'appointmentsWithPendingPayment',
    ].includes(reportToDownload);

    if (protectedFile && !passwordVerified) {
      setOpenVerificationDialog(true);
    } else {
      proceedToDownload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportToDownload]);

  const proceedToDownload = () => {
    if (reportToDownload === 'getInPersonReport') {
      getInPersonReport();
    } else if (reportToDownload === 'clientFlow') {
      clientFlow();
    } else if (reportToDownload === 'pendingPaymentUpcoming') {
      pendingPaymentUpcoming();
    } else if (reportToDownload === 'newClient') {
      newClient();
    } else if (reportToDownload === 'psychiatryAppointments') {
      psychiatryAppointments();
    } else if (reportToDownload === 'incompleteDocUpcomingAppointments') {
      incompleteDocUpcomingAppointments();
    } else if (reportToDownload === 'paymentReconciliation') {
      paymentReconciliation();
    } else if (reportToDownload === 'numberOfClinicianSessions') {
      numberOfClinicianSessions();
    } else if (reportToDownload === 'paymentReconciliationWeekly') {
      paymentReconciliationWeekly();
    } else if (reportToDownload === 'appointmentsWithPendingPayment') {
      appointmentsWithPendingPayment();
    } else if (reportToDownload === 'paymentReconciliationMonthly') {
      paymentReconciliationMonthly();
    } else if (reportToDownload === 'outPatients') {
      outPatients();
    } else if (
      reportToDownload === 'emirati-patients' ||
      reportToDownload === 'emirati-residents' ||
      reportToDownload === 'international'
    ) {
      emiratiPatients();
    } else if (reportToDownload === 'diagnosisReport') {
      diagnosisReport();
    } else if (reportToDownload === 'clinicianNotes') {
      clinicianNotes();
    } else if (reportToDownload === 'creditNotes') {
      creditNotes();
    }
  };

  const getInPersonReport = async () => {
    const appointments = await getData('in-person', 'daily');
    const formattedData = getInPersonReportFormat(appointments);

    exportToCSV(formattedData, 'InPersonSession', 'Sheet1');
  };

  const clientFlow = async () => {
    const appointments = await getData('client-flow', 'daily');
    const [formattedDataSheet1, grandTotalArr, uniqAddresses, formattedData] =
      clientFlowFormat(appointments);

    exportToCSVRowwise(
      [...formattedDataSheet1, ['Grand Total', ...grandTotalArr]],
      ['Row Labels', ...uniqAddresses, 'Grand Total'],
      formattedData,
      'Client Flow',
      'Sheet1'
    );
  };

  const pendingPaymentUpcoming = async () => {
    const appointments = await getData('pending-payment-upcoming', 'daily');
    const formattedData = pendingPaymentUpcomingFormat(appointments);
    exportToCSV(formattedData, 'Pending Payments', 'Sheet1');
  };

  const newClient = async () => {
    const appointments = await getData('new-clients', 'New Clients', 'daily');
    const formattedData = newClientFormat(appointments);
    exportToCSV(formattedData, 'New Clients', 'Sheet1');
  };

  const psychiatryAppointments = async () => {
    const appointments = await getData('psychiatry', 'daily');

    const formattedData = psychiatryAppointmentsFormat(appointments);

    exportToCSV(formattedData, 'Psychiatry', 'Sheet1');
  };

  const creditNotes = async () => {
    exportToCSV([{formattedData: "asdsad"}], 'Credit Notes', 'Credit Notes')
  }

  const incompleteDocUpcomingAppointments = async () => {
    const appointments = await getData(
      'incompleteDocUpcomingAppointments',
      'daily'
    );
    const formattedData = incompleteDocUpcomingAppointmentsFormat(appointments);

    exportToCSV(
      formattedData,
      'Upcoming Appointments having incomplete docs',
      'Sheet1'
    );
  };

  const paymentReconciliation = async () => {
    const appointments = await getData('payment-reconciliation', 'daily');
    const formattedData = paymentReconciliationFormat(appointments);

    exportToCSV(formattedData, 'Payment Reconciliation', 'Sheet1');
  };

  const numberOfClinicianSessions = async () => {
    const monthName = moment().format('MMM');
    const firstOfTheWeek = moment().startOf('week');
    const firstPlus4Days = moment(firstOfTheWeek).add(4, 'days');
    const firstPlus5Days = moment(firstPlus4Days).add(1, 'day');
    const lastDayOfWeek = moment(firstPlus4Days).add(3, 'days');

    const clinicians = await getData('number-of-clinician-sessions', 'weekly');
    const formattedData = numberOfClinicianSessionsFormat(
      clinicians,
      firstPlus4Days
    );

    exportAOAToCSVRowwise(
      [
        ...formattedData,
        [
          'Grand Total',
          calculateColTotal(formattedData, 1),
          calculateColTotal(formattedData, 2),
          calculateColTotal(formattedData, 3),
          calculateColTotal(formattedData, 4),
          calculateColTotal(formattedData, 5),
        ],
      ],
      [
        'Clinicians',
        'Grand Total',
        `${monthName} ${firstOfTheWeek.format('D')} to ${firstPlus4Days.format(
          'D'
        )}`,
        `${monthName} ${firstPlus5Days.format('D')} to ${lastDayOfWeek.format(
          'D'
        )}`,
        'New Clients Attended',
        'New Clients missed/ cancelled',
        'GC including missed/ cancelled',
      ],
      'Number Of Clinician Sessions',
      'Number Of Clinician Sessions'
    );
  };

  const paymentReconciliationWeekly = async () => {
    const appointments = await getData('payment-reconciliation', 'weekly');
    const formattedData = paymentReconciliationWeeklyFormat(appointments);
    exportToCSV(formattedData, 'Payment Reconciliation', 'Sheet1');
  };

  const appointmentsWithPendingPayment = async () => {
    const appointments = await getData('pending-payment', 'weekly');
    const formattedData = appointmentsWithPendingPaymentFormat(appointments);
    console.log('fomrattedData', formattedData);
    exportToCSV(formattedData, 'Pending Payments', 'Sheet1');
  };

  const outPatients = async () => {
    const appointments = await getData('out-patients', 'monthly');
    const formattedData = outPatientsFormat(appointments);
    setOutPatientData(formattedData);
    setTimeout(
      () =>
        exportToCSVTableRowwise('out-patients', 'Out Patients', 'Out Patients'),
      2000
    );
  };

  const emiratiPatients = async () => {
    const appointments = await getData(reportToDownload, 'monthly');
    const formattedData = emiratiPatientsFormat(appointments);

    setEmiratiPatientsData(formattedData);
    const title =
      reportToDownload === 'emirati-patients'
        ? 'Emirati Patients'
        : reportToDownload === 'emirati-residents'
        ? 'Emirati Residents'
        : 'International';

    setTimeout(
      () => exportToCSVTableRowwise('emirati-patients', title, title),
      2000
    );
  };

  const diagnosisReport = async () => {
    const appointments = await getData('diagnosis-report', 'monthly');
    const formattedData = diagnosisReportFormat(appointments);
    exportToCSV(formattedData, 'Diagnosis Report', 'Diagnosis Report');
  };

  const paymentReconciliationMonthly = async () => {
    const appointments = await getData('payment-reconciliation', 'monthly');
    const formattedData = paymentReconciliationMonthlyFormat(appointments);
    exportToCSV(formattedData, 'Payment Reconciliation', 'Sheet1');
  };

  const clinicianNotes = async () => {
    const clinicians = await getData('clinician-notes', 'monthly');
    const [formattedData1, formattedData2] = clinicianNotesFormat(clinicians);

    exportToDoubleSheets(
      formattedData1,
      formattedData2,
      'Appointments Clinical Notes'
    );
  };

  const exportAOAToCSVRowwise = (data, headers, fileName, sheetName) => {
    const fileExtension = '.xlsx';

    var ws_data = [headers, ...data];
    var ws = XLSX.utils.aoa_to_sheet(ws_data);
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, fileName + fileExtension);
  };

  const exportToCSVRowwise = (data, headers, data2, fileName, sheetName) => {
    const fileExtension = '.xlsx';

    var ws_data = [headers, ...data];

    var ws = XLSX.utils.aoa_to_sheet(ws_data);
    var ws2 = XLSX.utils.json_to_sheet(data2);

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.utils.book_append_sheet(wb, ws2, 'SheetJS2');

    XLSX.writeFile(wb, fileName + fileExtension);
  };

  const exportToCSV = (data, fileName, sheetName) => {
    setPasswordVerified(false);
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blobData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(blobData, fileName + fileExtension);
  };

  const exportToCSVTableRowwise = (tableId, fileName, sheetName) => {
    const fileExtension = '.xlsx';
    var table = document.getElementById(tableId);
    var ws = XLSX.utils.table_to_sheet(table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, fileName + fileExtension);
  };

  const exportToDoubleSheets = (data, data2, fileName) => {
    const fileExtension = '.xlsx';

    var ws = XLSX.utils.json_to_sheet(data);
    var ws2 = XLSX.utils.json_to_sheet(data2);

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Clinical Note Summary');
    XLSX.utils.book_append_sheet(wb, ws2, 'Clinical Notes Detailed');

    XLSX.writeFile(wb, fileName + fileExtension);
  };

  const getData = async (reportType, duration) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${baseURL}/admin/report/${reportType}/${duration}`
      );
      if (response.status === 200) {
        setTimeout(() => setLoading(false), 2000);
        setReportToDownload('');
        return response.data;
      }
    } catch (e) {
      setLoading(false);
    }
  };
  const verifyPassword = () => {
    setCheckPassword(true);
    if (enteredPassword === REPORTS_MASTER_PASSWORD) {
      setPasswordVerified(true);
      closeVerificationDialog();
      setPasswordMatch(true);
      proceedToDownload();
      AlertMsg(
        'success',
        'Password verified Successfully. Report Downloading in progress'
      );
    } else {
      setPasswordMatch(false);
    }
  };

  const closeVerificationDialog = () => {
    setOpenVerificationDialog(false);
    setReportToDownload('');
    setEnteredPassword('');
  };
  return (
    <>
      {loading && <Spinner isOpen={true} />}
      <Grid container>
        <Grid xs={12}>
          <Grid style={{ marginTop: '20px', float: 'right' }}>
            <a href='#monthlyReports'>
              <Button
                variant='contained'
                className={classes.btnn}
                style={{
                  marginRight: '10px',
                  color: '#003265',
                  backgroundColor: '#D1D9E5',
                }}
              >
                Month
              </Button>
            </a>
            <a href='#WeekelyReport'>
              <Button
                variant='contained'
                className={classes.btnn}
                style={{
                  marginRight: '10px',
                  color: '#003265',
                  backgroundColor: '#D1D9E5',
                }}
              >
                Week
              </Button>
            </a>
            <Link to='/admin/Reports'>
              <Button
                variant='contained'
                className={classes.btnn}
                style={{
                  marginRight: '10px',
                  color: '#FFFFFF',
                  backgroundColor: '#003265',
                }}
              >
                Daily
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <DailyReports setReportToDownload={setReportToDownload} />
      </Grid>
      <Grid id='WeekelyReport' item>
        <WeekelyReport setReportToDownload={setReportToDownload} />
      </Grid>
      <Grid id='monthlyReports' item>
        <MonthlyReports setReportToDownload={setReportToDownload} />
      </Grid>

      <Dialog
        handleClose={closeVerificationDialog}
        open={openVerificationDialog}
      >
        <Grid
          spacing={3}
          style={{ padding: '50px' }}
          direction='column'
          container
        >
          <Grid style={{ width: 400 }} item>
            <div>
              <label
                className='block text-gray-700 text-sm font-bold mb-2'
                htmlFor='password'
              >
                Password
              </label>
              <input
                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                id='password'
                type='password'
                placeholder='Enter Password'
                value={enteredPassword}
                onChange={(e) => {
                  setEnteredPassword(e.target.value);
                  setPasswordMatch(false);
                  setCheckPassword(false);
                }}
              />
              {!passwordMatch && checkPassword && (
                <p className='text-red-500 text-xs mt-2'>
                  Password is not correct
                </p>
              )}
            </div>
          </Grid>
          <Grid item>
            <Grid spacing={2} justify='flex-end' container>
              <Grid item>
                <Button onClick={closeVerificationDialog}>Cancel</Button>
              </Grid>
              <Grid item>
                <ConfirmBtn onClick={verifyPassword} text='Confirm' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      <div style={{ display: 'none' }}>
        <OutPatientReport data={outPatientData} />
        <EmiratiReport data={emiratiPatientsData} />
      </div>
    </>
  );
}
