import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import DrawerMenu from '../../Patient/common/Header/DrawerMenu';
import { GridContainer, GridItem } from '../../Patient/common/BodyContainer';
import ServiceBundles from './service_bundles';

const ROUTES = [{ path: '/client/bundles', component: ServiceBundles }];

const Dashboard = (props) => {
  return (
    <Grid>
      <DrawerMenu {...props} />
      <GridContainer {...props} />

      <GridItem
        {...props}
        style={{
          bottom: 0,
        }}
      >
        <Switch>
          {ROUTES.map((route, i) => (
            <Route
              key={`client-route-${i}`}
              path={route.path}
              render={(routerProps) =>
                React.createElement(route.component, {
                  ...props,
                  ...routerProps,
                })
              }
              exact
            />
          ))}
        </Switch>
      </GridItem>
    </Grid>
  );
};

export default Dashboard;
