import { Grid } from '@material-ui/core';
import styled from 'styled-components';

const Container = styled(Grid)`
  background-color: #fff;
  padding: 50px 0px;
`;

const Content = styled(Grid)`
  padding: 40px;
`;

const Img = styled.img`
  width: 100%;
`;

const PaginationGrid = styled(Grid)`
  margin-top: 20px;
`;

export { Content, Container, Img, PaginationGrid };
