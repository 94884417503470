/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
 
  withStyles,
} from "@material-ui/core";
import styles from "./AppointmentCancelAlertStyle";

class AppointmentCancelAlert extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { isOpen, handleClose, handleSure, classes } = this.props;
    return (
      <Dialog open={isOpen} onClose={handleClose} fullWidth>
        <DialogTitle className={classes.heading}>
          Appointment Cancel Policy
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.subHeading}>
            1. On your First time Appointment cancel no charges will apply.
          </DialogContentText>
          <DialogContentText className={classes.subHeading}>
            2. On your Second time Appointment cancel 50% charges will apply.
          </DialogContentText>
          <DialogContentText className={classes.subHeading}>
            3. On your Third time Appointment cancel 100% charges will apply.
          </DialogContentText>
          <DialogContentText className={classes.subHeading}>
            4. After, three time cancellation of Appointment your Account will
            be Blocked.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.button}>
            Cancel
          </Button>
          <Button onClick={handleSure} className={classes.button}>
            Sure, cancel appointment
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


export default withStyles(styles)(AppointmentCancelAlert);
