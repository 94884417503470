/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// import styles from "./AddChildFormStyle";

import { descFont, nameFont } from "../../../../src/assets/fontSize";

const styles = makeStyles((theme) => ({
  inputsty: {
    [theme.breakpoints.up("xs")]: {
      width: "240px",
      height: "30px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "420px",
      height: "40px",
    },
    [theme.breakpoints.up("md")]: {
      width: "480px",
      height: "50px",
    },
    backgroundColor: "#ffffff",
    border: "1px",
    borderStyle: "solid",
    borderColor: "#707070",
    cursor: "text",
    fontSize: descFont,
    color: "#304659",
    fontFamily: "Lato",
    fontWeight: "400",
    opacity: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
    outline: 0,
  },
  title: {
    textAlign: "left",
    // fontSize: "17px",
    // fontSize: "25px",
    color: "#304659",
    fontFamily: "Lato",
    fontWeight: "700",
    textTransform: "capitalize",
    width: "250px",
    fontSize: nameFont,
    [theme.breakpoints.up("md")]: {
      // fontSize: "25px",
      marginTop: "5px",
    },
  },
}));


export const FormInput = ({
  name,
  inputType,
  placeholder,
  className,
  value,
  onChange,
  ...props
}) => {
  const classes = styles();
  return (
    <>
      <input
        id={name}
        type={inputType}
        value={value}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        className={classes.inputsty}
        {...props}
      />
    </>
  );
};

export const InputTitle = ({ text, ...props }) => {
  const classes = styles();
  return (
    <Typography className={classes.title} {...props}>
      {text}
    </Typography>
  );
};
