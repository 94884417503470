/* eslint-disable import/no-named-as-default */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { configureStore } from './utils/store/index';
import runSaga from './utils/sagas/index';
import firebase, { googleSignOut } from './utils/firebase';
import 'tippy.js/dist/tippy.css';
import './index.css';
require('dotenv').config();

const store = configureStore;
runSaga();

// if (localStorage.getItem('ACCESS_TOKEN_PATH') && localStorage.getItem('__r') == undefined) {
//   googleSignOut()
//       .then(() => {
//         localStorage.clear()
//         this.props.props.history.push("/login");
//       })
//       .catch(() => {});
// }

// let timeoutMinutes = 15 * 60 * 1000;

// var inactivityTime = function () {
//   var time;
//   window.onload = resetTimer;
//   // DOM Events
//   document.onmousemove = resetTimer;
//   document.onkeypress = resetTimer;
//   function resetTimer() {
//     clearTimeout(time);
//     time = setTimeout(() => {
//       localStorage.clear();
//     }, timeoutMinutes);
//   }
// };

// if (process.env.REACT_APP_ENV === 'production') {
//   inactivityTime();
// }
if (process.env.REACT_APP_ENV === 'production') {
  console.log = function () { };
}
// REMOVE LATER
firebase.auth().onAuthStateChanged(async (user) => {
  if (user && localStorage.getItem('__r') == undefined) {
    let token = await firebase.auth().currentUser.getIdToken(true);
    localStorage.setItem('ACCESS_TOKEN_PATH', token);
  }
});

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
