/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Grid, withStyles, Drawer } from '@material-ui/core';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import DrawerMenu from '../common/Header/DrawerMenu';
import { GridContainer, GridItem } from '../common/BodyContainer';
import { CustomTable } from '../common/Table';
import { TableTitleFont } from '../common/TableFont';
import logo from '../../assets/imgs/download.png';
import logo1 from '../../assets/imgs/download.png';
// import { Drawer } from "../common/Drawer";
import AppointmnetRequest from '../components/Appointment Request/AppointmnetRequest';
import UpcomingVisits from '../components/Appointment Request/UpcomingVisits';
import PreviousAppointment from '../components/Appointment Request/PreviousAppointment';
import { getPreviousAppointmnetAction } from '../../utils/actions/appointment.action';
import { getUpcomingAppointmentAction } from '../../utils/actions/dashboard.action';
import { getByIdClientAction } from '../../utils/actions/client.action';
import { baseURL } from '../../utils/axios';
import AlertMsg from '../../utils/Alert';
import ConfirmCancelAppointment from '../components/ConfirmCancelAppointment';
import ScheduleModal from '../../clinician/components/ScheduleModal';
import PaymentOption from '../components/PaymentOption';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import TableView from '../../common/TableView';
import styles from './Appointment.styles';
import Axios from 'axios';
import moment from 'moment';
import { LOCATION } from '../../utils/enums';

const appReq = [];
const customDateFormat = (date) => {
  const dateStr = `${`00${date.getDate()}`.slice(-2)}-${`00${
    date.getMonth() + 1
  }`.slice(-2)}-${date.getFullYear()} ${`00${date.getHours()}`.slice(
    -2
  )}:${`00${date.getMinutes()}`.slice(-2)}`;
  return dateStr;
};
class Appointments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAppointmentRequest: false,
      isUpcomingVisitRequest: false,
      isPreviousAppointment: false,
      isOpen: false,
      isPaymentOpen: false,
      previousAppointmentList: [],
      isPreviousLoaded: true,
      upcomingVisits: [],
      isUpcomingLoaded: true,
      selectedDetails: {},
      openAppConfirmModal: false,
      confirmMsg: '',
      userInfo: null,
      selectedAppointment: '',
      loadingConfApp: false,
      reScheduleAction: false,
      reScheduleConfBtnLoading: false,
      reSClinicianDetails: null,
    };
  }

  componentDidMount() {
    this.props.getPreviousAppointmnetAction();
    this.props.getUpcomingAppointmentAction();
    this.props.getByIdClientAction();
  }

  componentDidUpdate(preProps) {
    if (this.props.getPreviousAppointment !== preProps.getPreviousAppointment) {
      const data = this.props.getPreviousAppointment;

      if (data) {
        const previousAppointment = data.map((node) => ({
          referralByImage:
            node.clinicianId && node.clinicianId.avatar
              ? node.clinicianId.avatar
              : logo,
          referralBy: `${node.clinicianId.firstName} ${node.clinicianId.lastName}`,
          date: customDateFormat(new Date(node.startTime)),
          refClinicianImage: null,
          refClinician: '--',
          patient:
            node.clientId && node.clientId.length && node.clientId[0].avatar
              ? node.clientId[0].avatar
              : logo1,
          paymentStatus:
            node.billingId.status === 'unpaid'
              ? 'Unpaid'
              : node.billingId.status === 'failed'
              ? 'Unpaid'
              : 'Paid',
          action: 'View',
          actionType:
            node.billingId.status === 'unpaid'
              ? 'yes'
              : node.billingId.status === 'failed'
              ? 'yes'
              : 'yes',
          viewDetails: node,
          _id: node._id,
          billingId: node.billingId,
          type: node.type.substr(0, 1).toUpperCase() + node.type.substr(1),
          location: node.appointmentLocation
            ? LOCATION[node.appointmentLocation]
            : '',
        }));

        this.setState({
          previousAppointmentList: previousAppointment,
          isPreviousLoaded: false,
        });
      }
    }

    if (this.props.getUpcomingData !== preProps.getUpcomingData) {
      const data = this.props.getUpcomingData;
      if (data) {
        const list = data.map((node) => ({
          clinicianId: node.clinicianId ? node.clinicianId._id : null,
          referralByImage:
            node.clinicianId && node.clinicianId.avatar
              ? node.clinicianId.avatar
              : logo,
          referralBy: `${node.clinicianId.firstName} ${node.clinicianId.lastName}`,
          date: customDateFormat(new Date(node.startTime)),
          refClinicianImage: null,
          refClinician: '--',
          patient:
            node.clientId && node.clientId.length && node.clientId[0].avatar
              ? node.clientId[0].avatar
              : logo1,
          paymentStatus:
            node.billingId && node.billingId.status === 'paid'
              ? 'Paid'
              : 'Unpaid',
          action:
            node.billingId && node.billingId.status === 'paid'
              ? 'View'
              : 'Submit Payment',
          actionType:
            node.billingId && node.billingId.status === 'paid' ? 'yes' : 'no',
          viewDetails: node,
          billingId: node.billingId,
          type: node.type.substr(0, 1).toUpperCase() + node.type.substr(1),
          location: node.appointmentLocation
            ? LOCATION[node.appointmentLocation]
            : '',
          _id: node._id,
        }));
        this.setState({ upcomingVisits: list, isUpcomingLoaded: false });
      }
    }

    if (this.props.getClientData !== preProps.getClientData) {
      if (
        this.props.getClientData &&
        this.props.getClientData.getByIdClientData
      ) {
        this.setState({ userInfo: this.props.getClientData.getByIdClientData });
      }
    }
  }

  handleCancelAppointment = (id) => {
    if (id) {
      const { userInfo } = this.state;
      let msg = '';
      if (userInfo) {
        switch (userInfo.appointmentCancel) {
          case 0:
            msg = 'Are you sure? You want to cancel this appointment!';
            break;
          case 1:
            msg =
              'If you cancel this appointment you need to pay the half of amount! Are you agree?';
            break;
          case 2:
            msg =
              'If you cancel this appointment you need to pay the full amount! Are you agree?';
            break;
          default:
            msg =
              'If you cancel this appointment you will be blocked. Are you agree?';
        }
      }
      this.setState({
        openAppConfirmModal: true,
        selectedAppointment: id,
        confirmMsg: msg,
        reScheduleAction: false,
      });
    }
  };

  handleCloseConfAppointment = () => {
    this.setState({
      openAppConfirmModal: false,
      selectedAppointment: '',
      confirmMsg: '',
      reScheduleAction: false,
      loadingConfApp: false,
    });
  };

  cancelAppointment = () => {
    const { selectedAppointment, reScheduleAction } = this.state;
    if (selectedAppointment && reScheduleAction === false) {
      Axios.get(`${baseURL}/api/appointment/${selectedAppointment}/cancel`, {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      })
        .then((res) => {
          this.props.getUpcomingAppointmentAction();
          AlertMsg('success', 'Your appointment successfully cancelled.');
          this.handleCloseConfAppointment();
          this.setState({
            isUpcomingVisitRequest: false,
            isOpen: false,
            selectedDetails: {},
            loadingConfApp: false,
          });
          return null;
        })
        .catch((e) => {
          AlertMsg('success', 'Invalid request!');
          return null;
        });
    } else {
      return null;
    }
  };

  getFormatedClinicianData = (uData) => {
    let nextAvailable = null;
    const clinician = uData;
    let schedules = uData.schedule ? uData.schedule : [];
    if (schedules && schedules.length) {
      schedules = schedules.map((sch) => {
        if (sch.slots.length) {
          sch.slots = sch.slots.map((s) => {
            if (s) {
              const startTimeObj = moment(new Date(s.startTime));
              const startTimeStr =
                startTimeObj.hours() + ':' + startTimeObj.minutes();
              const timeArr = startTimeStr.split(':');
              let hrs = '00';
              let min = '00';
              if (timeArr.length) {
                hrs = timeArr[0];
                min = timeArr[1];
              }
              if (timeArr[0].length === 1) {
                hrs = `0${timeArr[0]}`;
              }
              if (timeArr[1].length === 1) {
                min = `0${timeArr[1]}`;
              }
              const time = `${hrs}:${min}`;
              const lclDate = new Date(`${sch.date}T${time}:00Z`);
              let lclHrs = lclDate.getHours().toString();
              if (lclHrs.length === 1) {
                lclHrs = `0${lclHrs}`;
              }
              return `${lclHrs}:${
                lclDate.getMinutes() > 0 ? lclDate.getMinutes() : '00'
              }`;
            }
            return s;
          });
        }
        return sch;
      });
      for (const sch of schedules) {
        if (sch.slots.length) {
          nextAvailable = sch;
          break;
        }
      }
    }
    const data = {
      id: clinician._id,
      name: `Dr. ${clinician.firstName} ${clinician.lastName}`,
      study: '',
      speciality: 'Specialist Psychiatrist',
      times: schedules,
      img: clinician.avatar ? clinician.avatar : logo,
      cost: null,
      appointmenSlot: clinician.appointmentSlotUnit,
      amount: clinician.costPerUnit,
      selectTimeSlot: null,
      isAlternative: false,
      nextAvailable,
      nextAvailiblityText: this.getNextAvailibility(schedules),
    };
    if (clinician._id !== localStorage.getItem('clinicianUID')) {
      data.cost = `${clinician.costPerUnit} Dhs / ${clinician.appointmentSlotUnit}min`;
    }
    return data;
  };

  getDate = (date1) => {
    const today = date1 ? new Date(date1) : new Date();
    const d = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();
    const m = today.getMonth() < 10 ? `0${today.getMonth()}` : today.getMonth();
    date1 = `${d}/${m}/${today.getFullYear()}`;
    return date1;
  };

  getNextAvailibility = (schedules) => {
    let result = '';
    if (schedules.length) {
      const day = new Date();
      day.setDate(day.getDate() + 1);
      const nextDay = this.getDate(day);
      if (schedules[0].date == nextDay) {
        result = 'Tomorrow';
      } else if (new Date(schedules[0].date) == day) {
        result = 'Today';
      } else {
        result = schedules[0].date;
      }
    } else {
      result = 'Not Available';
    }
    return result;
  };

  getNewAppointmentSloats = () => {
    if (
      this.state.selectedDetails &&
      this.state.selectedDetails.clinicianId &&
      this.state.reScheduleAction
    ) {
      Axios.get(`${baseURL}/api/client/doctor`, {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      })
        .then((res) => {
          if (res.data && res.data.length) {
            const clinician = res.data.filter((item) => {
              if (
                item._id.toString() ===
                this.state.selectedDetails.clinicianId._id.toString()
              ) {
                return item;
              }
            });
            if (clinician.length > 0) {
              this.setState({
                reSClinicianDetails: this.getFormatedClinicianData(
                  clinician[0]
                ),
                openReScheduleModal: true,
              });
            } else {
              this.handleCloseConfAppointment();
              this.setState({
                isUpcomingVisitRequest: false,
                isOpen: false,
                selectedDetails: {},
                loadingConfApp: false,
              });
              AlertMsg('error', 'Doctor details not found!');
            }
          } else {
            this.handleCloseConfAppointment();
            this.setState({
              isUpcomingVisitRequest: false,
              isOpen: false,
              selectedDetails: {},
              loadingConfApp: false,
            });
            AlertMsg(
              'error',
              'Booking sloats not availabel for this appointment'
            );
          }
        })
        .catch((e) => {
          AlertMsg(
            'error',
            'Booking sloats not availabel for this appointment'
          );
        });
    }
  };

  handleCancelAppointmentConfirm = () => {
    const { selectedAppointment, reScheduleAction } = this.state;
    this.setState({ loadingConfApp: true });
    if (selectedAppointment) {
      if (reScheduleAction) {
        this.getNewAppointmentSloats();
      } else {
        this.cancelAppointment();
      }
    } else {
      return null;
    }
  };

  handleRescheduleAppointment = (id) => {
    if (id) {
      const { userInfo } = this.state;
      let msg = '';
      if (userInfo) {
        switch (userInfo.appointmentCancel) {
          case 0:
            msg = 'Are you sure? You want to reschedule this appointment!';
            break;
          case 1:
            msg =
              'If you reschedule this appointment you need to pay the half of amount! Are you agree?';
            break;
          case 2:
            msg =
              'If you reschedule this appointment you need to pay the full amount! Are you agree?';
            break;
          default:
            msg =
              'If you reschedule this appointment you will be blocked. Are you agree?';
        }
      }
      this.setState({
        openAppConfirmModal: true,
        selectedAppointment: id,
        confirmMsg: msg,
        reScheduleAction: true,
      });
    }
  };

  handleAlternativeCalendar = () => {
    const { reSClinicianDetails } = this.state;
    this.setState({
      reSClinicianDetails: {
        ...reSClinicianDetails,
        isAlternative: !reSClinicianDetails.isAlternative,
      },
    });
  };

  handleReScheduleTimeSelect = (value) => {
    const { reSClinicianDetails } = this.state;
    this.setState({
      reSClinicianDetails: {
        ...reSClinicianDetails,
        status: 'selected',
        selectTimeSlot: value,
        isAlternative: false,
      },
    });
  };

  formatDateYMD = (x, y) => {
    const z = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds(),
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, (v) =>
      ((v.length > 1 ? '0' : '') + eval(`z.${v.slice(-1)}`)).slice(-2)
    );

    return y.replace(/(y+)/g, (v) =>
      x.getFullYear().toString().slice(-v.length)
    );
  };

  handleCalendarTimeSelect = (times, value, date) => {
    const { reSClinicianDetails } = this.state;
    const newDate = this.formatDateYMD(date, 'yyyy-MM-dd');
    this.setState({
      reSClinicianDetails: {
        ...reSClinicianDetails,
        status: 'selected',
        selectTimeSlot: new Date(`${newDate} ${value}`),
        isAlternative: false,
      },
    });
  };

  handleCloseReSchedule = () => {
    this.setState({
      openReScheduleModal: false,
      reScheduleConfBtnLoading: false,
    });
    this.handleCloseConfAppointment();
  };

  handleCancelReScheduleAppointment = (index) => {
    const { reSClinicianDetails } = this.state;
    let tmpClinicianDetails = { ...reSClinicianDetails };
    tmpClinicianDetails = {
      ...tmpClinicianDetails,
      selectTimeSlot: null,
    };
    this.setState({ reSClinicianDetails: tmpClinicianDetails });
  };

  handleReScheduleAppointmentConfirm = () => {
    this.setState({ reScheduleConfBtnLoading: true });
    const data = {
      startTime: this.state.reSClinicianDetails.selectTimeSlot,
    };
    Axios.put(
      `${baseURL}/api/appointment/${this.state.selectedAppointment}`,
      data,
      {
        headers: {
          Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
        },
      }
    )
      .then((res) => {
        this.handleCloseReSchedule();
        this.handleCloseConfAppointment();
        this.props.getUpcomingAppointmentAction();
        this.setState({
          isUpcomingVisitRequest: false,
          isOpen: false,
          selectedDetails: {},
          loadingConfApp: false,
        });
        AlertMsg('success', 'Appointment rescheduled succesfully');
      })
      .catch((e) => {
        this.setState({ reScheduleConfBtnLoading: false });
        AlertMsg('error', e.message);
      });
  };

  handleUpcomingAppClick = (item) => {
    if (item.paymentStatus == 'Unpaid') {
      this.props.history.push(`/verifyinfo/${item._id}`);
    } else {
      this.setState({
        isUpcomingVisitRequest: true,
        isOpen: true,
        selectedDetails: item.viewDetails,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      openAppConfirmModal,
      confirmMsg,
      loadingConfApp,
      reScheduleConfBtnLoading,
      reSClinicianDetails,
      openReScheduleModal,
    } = this.state;
    return (
      <div>
        <DrawerMenu {...this.props} />
        <GridContainer {...this.props} />
        {/* <Grid style={{ backgroundColor: this.state.isAppointmentRequest ? 'red' : '', zIndex: this.state.isAppointmentRequest ? 10000000 : '' }}> */}
        <GridItem
          {...this.props}
          style={{
            zIndex: this.state.isOpen ? 100 : '',
            backgroundColor: this.state.isOpen ? 'rgba(34,34,34,48%)' : '',
            // position: this.state.isOpen ? "fixed" : "absolute",
            bottom: 0,
          }}
        >
          {/* <Grid style={{ marginBottom: '44px' }}>
              <TableTitleFont text="Appointment Requests" />
              <CustomTable
                data={appReq}
                isLoader={false}
                onRowSelect={(item) => this.setState({
                  isAppointmentRequest: true,
                  isOpen: true,
                  selectedDetails: item.viewDetails,
                })}
                {...this.props}
                style={{ opacity: this.state.isOpen ? '40%' : '' }}
              />
            </Grid> */}
          <TableView
            endpoint='/api/appointment/reffered'
            title='Appointment Request'
          />

          <TableView endpoint='/api/appointment/waitlist' title='Wait-List' />

          <Grid style={{ marginBottom: '44px' }}>
            <TableTitleFont text='Upcoming Visits' />
            <CustomTable
              data={this.state.upcomingVisits}
              isLoader={this.state.isUpcomingLoaded}
              onRowSelect={this.handleUpcomingAppClick}
              style={{ opacity: this.state.isOpen ? '40%' : '' }}
              {...this.props}
            />
          </Grid>

          <Grid style={{ marginBottom: '44px' }}>
            <TableTitleFont text='Previous Visits' />
            <CustomTable
              data={this.state.previousAppointmentList}
              isLoader={this.state.isPreviousLoaded}
              {...this.props}
              onRowSelect={(item) =>
                this.setState({
                  isPreviousAppointment: true,
                  isOpen: true,
                  selectedDetails: item.viewDetails,
                })
              }
              style={{ opacity: this.state.isOpen ? '40%' : '' }}
            />
          </Grid>
        </GridItem>
        {/* </Grid> */}
        <Drawer
          anchor='right'
          open={this.state.isPreviousAppointment}
          onClose={() =>
            this.setState({
              isPreviousAppointment: false,
              isOpen: false,
              selectedDetails: {},
            })
          }
          {...this.props}
        >
          <PreviousAppointment data={this.state.selectedDetails} />
        </Drawer>
        <Drawer
          anchor='right'
          onClose={() =>
            this.setState({
              isAppointmentRequest: false,
              isOpen: false,
              selectedDetails: {},
            })
          }
          open={this.state.isAppointmentRequest}
          {...this.props}
        >
          <AppointmnetRequest data={this.state.selectedDetails} />
        </Drawer>
        <Drawer
          anchor='right'
          onClose={() =>
            this.setState({
              isUpcomingVisitRequest: false,
              isOpen: false,
              selectedDetails: {},
            })
          }
          open={this.state.isUpcomingVisitRequest}
          {...this.props}
        >
          <UpcomingVisits
            handleCancelAppointment={this.handleCancelAppointment}
            handleRescheduleAppointment={this.handleRescheduleAppointment}
            data={this.state.selectedDetails}
          />
        </Drawer>
        <ConfirmCancelAppointment
          handleClose={this.handleCloseConfAppointment}
          open={openAppConfirmModal}
          handleCancelAppointmentConfirm={this.handleCancelAppointmentConfirm}
          message={confirmMsg}
          loadingConfApp={loadingConfApp}
        />

        <ScheduleModal
          clinicianDetails={reSClinicianDetails}
          open={openReScheduleModal}
          handleClose={this.handleCloseReSchedule}
          handleCalendarTimeSelect={this.handleCalendarTimeSelect}
          handleCancelAppointment={this.handleCancelReScheduleAppointment}
          handleAppointmentConfirm={this.handleReScheduleAppointmentConfirm}
          handleTimeSelect={this.handleReScheduleTimeSelect}
          handleAlternativeCalendar={this.handleAlternativeCalendar}
          confirmBtnLoading={reScheduleConfBtnLoading}
        />
      </div>
    );
  }
}

Appointments.defaultProps = {
  getClientData: {},
};

const connected = connect(
  (state) => ({
    getPreviousAppointment:
      state.getPreviousAppointment.getPreviousAppointmentData,
    getUpcomingData: state.getUpcomingAppointment.getUpcomingAppointmentData,
    getClientData: state.getByIdClient,
  }),
  {
    getPreviousAppointmnetAction,
    getUpcomingAppointmentAction,
    getByIdClientAction,
  }
)(Appointments);

const formed = reduxForm({
  form: 'appointmentdgdk',
})(connected);

export default withStyles(styles)(formed);
