/* eslint-disable indent */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import SmsIcon from '@material-ui/icons/Sms';
import { Link } from 'react-router-dom';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import { Typography } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import ShareIcon from '@material-ui/icons/Share';
import PeopleIcon from '@material-ui/icons/People';
// import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import '../../../clinician/clinician.scss';
import defaultSideImage from '../../../assets/imgs/download.png';
import DashboardIcon from '../../../assets/clinician_image/Group 292.png';
import DashboardIconSelected from '../../../assets/clinician_image/dashboard.jpeg';
import Collapse from '@material-ui/core/Collapse';
import Axios from 'axios';
import { baseURL } from '../../../utils/axios';
import { useLocation, useHistory } from 'react-router';
import { checkIsActive } from '../../../utils/constant';
import { Button } from '@material-ui/core';
import AlertMsg from '../../../utils/Alert';
import useStyles from './SidebarMenu.styles';

export default function SidebarMenu({
  filterAppointment,
  filterPatients,
  filterId,
  updatedAvatar,
}) {
  const classes = useStyles();
  const location = useLocation();
  let history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [patientOpen, setPatientOpen] = React.useState(false);
  const [filterList, setFilterList] = React.useState([]);
  const [patientFilterList, setPatientFilterList] = React.useState([]);
  const [loginData, setLoginData] = React.useState({
    firstName: localStorage.getItem('fName'),
    role: localStorage.getItem('__r'),
    lastName: localStorage.getItem('lName'),
    avatar: localStorage.getItem('avatar'),
  });

  useEffect(() => {
    if (updatedAvatar) {
      setLoginData({ ...loginData, avatar: updatedAvatar });
    }
  }, [updatedAvatar]);

  const handleClick = () => {
    setOpen(!open);
  };

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url);
  };

  const handleLogout = () => {
    localStorage.clear();
  };

  useEffect(() => {
    getFilters();
    getPatientFilters();
  }, [filterId]);

  const getFilters = () => {
    var uid = localStorage.getItem('clinicianUID');
    Axios.get(`${baseURL}/api/filter`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        setFilterList(res.data);
      })
      .catch(() => {});
  };

  const getPatientFilters = () => {
    var uid = localStorage.getItem('clinicianUID');
    Axios.get(`${baseURL}/api/filter?type=patient`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        setPatientFilterList(res.data);
      })
      .catch(() => {});
  };

  const handleFilterChange = (filter) => {
    history.push('/admin/appointments?name=' + filter.filterName + '&page=1');
    filterAppointment(filter._id);
  };

  const handlePatientFilterChange = (filter) => {
    history.push('/clinician/patients?name=' + filter.filterName + '&page=1');
    filterPatients(filter._id);
  };

  const handlePatientClick = () => {
    setPatientOpen(!patientOpen);
  };

  useEffect(() => {
    setOpen(location.pathname.includes('appointments'));
    setPatientOpen(location.pathname.includes('patients'));
  }, []);

  const handleFilterDelete = (id) => {
    Axios.delete(`${baseURL}/api/filter/${id}`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res) {
          getFilters();
          AlertMsg('success', 'Succesfully checked in Appointment');
        }
      })
      .catch(() => {
        AlertMsg('error', 'Something went wrong!');
      });
  };

  const renderUserRole = () => {
    let role = 'Admin';
    if (loginData.role === 'frontdesk') {
      role = 'Frontdesk';
    } else if (loginData.role === 'event') {
      role = 'Events';
    }
    return role;
  };
  return (
    <div className={classes.toolbar}>
      <div style={{ marginTop: 20 }}>
        <div className={classes.topSidebar}>
          {/*  <img src={onlineIcon} className={classes.onlineIcon}/>*/}
          <img
            src={loginData.avatar ? loginData.avatar : defaultSideImage}
            width='100'
            className={classes.defaultimg}
          />
        </div>
      </div>
      <h3
        style={{
          margin: 0,
          color: '#304659',
          fontSize: '16px',
          lineHeight: '22px',
          marginBottom: '7px',
        }}
      >
        {loginData.firstName
          ? loginData.firstName + ' ' + loginData.lastName
          : ''}
      </h3>
      {/* <p className={classes.sideTextName}>Clinician Psychologist</p>*/}
      <div>
        <span className={classes.primaryBtn}>{renderUserRole()}</span>
      </div>
      {/* <Button variant="contained" color="primary" >
                Clinician
            </Button> */}
      <div className={classes.borderBottom}></div>
      <List component='nav' className={(classes.appMenu, 'menu-list-custom')}>
        {loginData.role !== 'event' && (
          <Link
            to={'/' + loginData.role + '/dashboard'}
            className={classes.menuText}
          >
            <ListItem
              button
              className={classes.menuItem}
              onClick={handleClick}
              selected={getMenuItemActive(
                '/' + loginData.role + '/dashboard',
                false
              )}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <img
                  src={
                    getMenuItemActive('/admin/dashboard', false)
                      ? DashboardIconSelected
                      : DashboardIcon
                  }
                />
              </ListItemIcon>
              <Typography className={(classes.textcolor, 'text-color-link')}>
                Dashboard
              </Typography>
            </ListItem>
          </Link>
        )}
        {loginData.role !== 'event' && loginData.role !== 'clinician' && (
          <Link to='/admin/calender' className={classes.menuText}>
            <ListItem
              button
              className={classes.menuItem}
              selected={getMenuItemActive('/admin/calender', false)}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <CalendarTodayIcon />
              </ListItemIcon>
              <Typography className={(classes.textcolor, 'text-color-link')}>
                Calender
              </Typography>
            </ListItem>
          </Link>
        )}

        {loginData.role !== 'event' && (
          <Link to={'/admin/appointments'} className={classes.menuText}>
            <ListItem
              button
              className={classes.menuItem}
              onClick={handleClick}
              selected={getMenuItemActive('/admin/appointments', false)}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <AccessAlarmIcon />
              </ListItemIcon>
              <Typography className={(classes.textcolor, 'text-color-link')}>
                Appointments
              </Typography>
            </ListItem>
          </Link>
        )}

        {loginData.role !== 'event' && (
          <Link to={'/admin/service-bundles'} className={classes.menuText}>
            <ListItem
              button
              className={classes.menuItem}
              onClick={handleClick}
              selected={getMenuItemActive('/admin/service-bundles', false)}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <FilterNoneIcon />
              </ListItemIcon>
              <Typography className={(classes.textcolor, 'text-color-link')}>
                Service Bundles
              </Typography>
            </ListItem>
          </Link>
        )}

        <Collapse in={open} timeout='auto' unmountOnExit>
          <List
            component='div'
            disablePadding
            style={{ maxHeight: '350px', overflowY: 'scroll' }}
          >
            {filterList.map((filter, i) => (
              <ListItem key={i} button className={classes.nested}>
                <Link onClick={() => handleFilterChange(filter)}>
                  <Typography
                    className={
                      (classes.textcolor, 'text-color-link filter-list')
                    }
                  >
                    {filter.filterName ? filter.filterName : 'NA'}
                  </Typography>
                </Link>
                <Button
                  variant='contained'
                  onClick={() => {
                    handleFilterDelete(filter._id);
                  }}
                >
                  X
                </Button>
              </ListItem>
            ))}
          </List>
        </Collapse>

        <Link to='/admin/events' className={classes.menuText}>
          <ListItem
            button
            className={classes.menuItem}
            selected={
              getMenuItemActive('/admin/events', false) ||
              getMenuItemActive('/event/events', false)
            }
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <ShareIcon />
            </ListItemIcon>
            <Typography className={(classes.textcolor, 'text-color-link')}>
              {loginData.role === 'event' ? 'Dashboard' : 'Events'}
            </Typography>
          </ListItem>
        </Link>

        <Link to={'/admin/corporation'} className={classes.menuText}>
          <ListItem
            button
            className={classes.menuItem}
            selected={getMenuItemActive('/admin/corporation', false)}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <PeopleIcon />
            </ListItemIcon>
            <Typography className={(classes.textcolor, 'text-color-link')}>
              Corporate Clients
            </Typography>
          </ListItem>
        </Link>

        {loginData.role === 'event' && (
          <Link
            to={'/' + loginData.role + '/reports'}
            className={classes.menuText}
          >
            <ListItem
              button
              className={classes.menuItem}
              onClick={handleClick}
              selected={getMenuItemActive(
                '/' + loginData.role + '/reports',
                false
              )}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <FilterNoneIcon />
              </ListItemIcon>
              <Typography className={(classes.textcolor, 'text-color-link')}>
                Reports
              </Typography>
            </ListItem>
          </Link>
        )}

        {loginData.role !== 'event' && (
          <Link to={'/admin/billing'} className={classes.menuText}>
            <ListItem
              button
              className={classes.menuItem}
              selected={getMenuItemActive('/admin/billing', false)}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <PeopleIcon />
              </ListItemIcon>
              <Typography className={(classes.textcolor, 'text-color-link')}>
                Invoices
              </Typography>
            </ListItem>
          </Link>
        )}

        {loginData.role !== 'event' && (
          <Link
            to={'/' + loginData.role + '/patients'}
            className={classes.menuText}
          >
            <ListItem
              button
              className={classes.menuItem}
              onClick={handlePatientClick}
              selected={
                getMenuItemActive('/' + loginData.role + '/patients', false)
                  ? getMenuItemActive('/' + loginData.role + '/patients', false)
                  : getMenuItemActive('/admin/patient-detail/', false)
              }
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <PeopleIcon />
              </ListItemIcon>
              <Typography className={(classes.textcolor, 'text-color-link')}>
                Patients
              </Typography>
            </ListItem>
          </Link>
        )}
        {loginData.role !== 'event' && (
          <Collapse in={patientOpen} timeout='auto' unmountOnExit>
            <List
              component='div'
              disablePadding
              style={{ maxHeight: '350px', overflowY: 'scroll' }}
            >
              {patientFilterList.map((filter, i) => (
                <ListItem key={i} button className={classes.nested}>
                  <Link onClick={() => handlePatientFilterChange(filter)}>
                    <Typography
                      className={
                        (classes.textcolor, 'text-color-link filter-list')
                      }
                    >
                      {filter.filterName ? filter.filterName : 'NA'}
                    </Typography>
                  </Link>
                  <Button
                    variant='contained'
                    onClick={() => {
                      handleFilterDelete(filter._id);
                    }}
                  >
                    X
                  </Button>
                </ListItem>
              ))}
            </List>
          </Collapse>
        )}
        {loginData.role === 'admin' && (
          <Link to='/admin/staff-members' className={classes.menuText}>
            <ListItem
              button
              className={classes.menuItem}
              selected={
                getMenuItemActive('/admin/staff-members', false)
                  ? getMenuItemActive('/admin/staff-members', false)
                  : getMenuItemActive('/admin/staff/add-staff', false)
              }
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <PeopleIcon />
              </ListItemIcon>
              <Typography className={(classes.textcolor, 'text-color-link')}>
                Staff Members
              </Typography>
            </ListItem>
          </Link>
        )}
        {loginData.role === 'admin' && (
          <Link to='/admin/serviceType' className={classes.menuText}>
            <ListItem
              button
              className={classes.menuItem}
              selected={
                getMenuItemActive('/admin/serviceType', false)
                  ? getMenuItemActive('/admin/serviceType', false)
                  : getMenuItemActive('/admin/serviceType', false)
              }
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <EnhancedEncryptionIcon />
              </ListItemIcon>
              <Typography className={(classes.textcolor, 'text-color-link')}>
                Service Type VAT
              </Typography>
            </ListItem>
          </Link>
        )}
        {loginData.role !== 'event' && (
          <Link
            to={'/' + loginData.role + '/Reports'}
            className={classes.menuText}
          >
            <ListItem
              button
              className={classes.menuItem}
              selected={getMenuItemActive(
                '/' + loginData.role + '/Reports',
                false
              )}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <EqualizerIcon />
              </ListItemIcon>
              <Typography className={(classes.textcolor, 'text-color-link')}>
                Reports
              </Typography>
            </ListItem>
          </Link>
        )}

        {loginData.role === 'admin' && (
          <Link
            to={'/' + loginData.role + '/clinicAddresses'}
            className={classes.menuText}
          >
            <ListItem
              button
              className={classes.menuItem}
              selected={getMenuItemActive(
                '/' + loginData.role + '/clinicAddresses',
                false
              )}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <SettingsIcon />
              </ListItemIcon>
              <Typography className={(classes.textcolor, 'text-color-link')}>
                Clinic Addresses
              </Typography>
            </ListItem>
          </Link>
        )}

        {loginData.role !== 'event' && (
          <Link
            to={'/' + loginData.role + '/send-sms'}
            className={classes.menuText}
          >
            <ListItem
              button
              className={classes.menuItem}
              selected={getMenuItemActive(
                '/' + loginData.role + '/send-sms',
                false
              )}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <SmsIcon />
              </ListItemIcon>
              <Typography className={(classes.textcolor, 'text-color-link')}>
                SMS
              </Typography>
            </ListItem>
          </Link>
        )}

        {loginData.role === 'admin' && (
          <Link to='/admin/time-slots' className={classes.menuText}>
            <ListItem
              button
              className={classes.menuItem}
              selected={getMenuItemActive('/admin/time-slots', false)}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <CalendarTodayIcon />
              </ListItemIcon>
              <Typography className={(classes.textcolor, 'text-color-link')}>
                Time-Slots
              </Typography>
            </ListItem>
          </Link>
        )}

        {loginData.role !== 'event' && (
          <Link to='/admin/consent-forms' className={classes.menuText}>
            <ListItem
              button
              className={classes.menuItem}
              selected={getMenuItemActive('/admin/consent-forms', false)}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <SettingsIcon />
              </ListItemIcon>
              <Typography className={(classes.textcolor, 'text-color-link')}>
                Consent Forms
              </Typography>
            </ListItem>
          </Link>
        )}
        <Link
          to={'/' + loginData.role + '/setting'}
          className={classes.menuText}
        >
          <ListItem
            button
            className={classes.menuItem}
            selected={getMenuItemActive(
              '/' + loginData.role + '/setting',
              false
            )}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <SettingsIcon />
            </ListItemIcon>
            <Typography className={(classes.textcolor, 'text-color-link')}>
              Settings
            </Typography>
          </ListItem>
        </Link>

        <Link to='#' onClick={handleLogout} className={classes.menuText}>
          <ListItem button className={classes.menuItem}>
            <ListItemIcon className={classes.menuItemIcon}>
              <ExitToAppIcon />
            </ListItemIcon>
            <Typography className={(classes.textcolor, 'text-color-link')}>
              Logout
            </Typography>
          </ListItem>
        </Link>
      </List>
    </div>
  );
}

SidebarMenu.propTypes = {
  filterAppointment: PropTypes.func,
  filterPatients: PropTypes.func,
  filterId: PropTypes.func,
};
