import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { Button, ClinicianAutoComplete } from '../../atoms';
import { LeftBtn } from './style';

const CliniciansModal = ({
  isOpen,
  toggleClinicianModal,
  sendClinicianList,
  clinicians,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [updatedClinicians, setClinicians] = useState([]);

  const onChange = (data) => setClinicians(data);
  const cancelSelection = () => {
    setClinicians([]);
    toggleClinicianModal();
  };

  return (
    <Grid container>
      <Dialog open={isOpen} onClose={toggleClinicianModal} fullWidth>
        <DialogTitle>Select Clinicians</DialogTitle>
        <DialogContent>
          <ClinicianAutoComplete
            onChange={onChange}
            selectAll={selectAll}
            clinicians={clinicians}
          />
        </DialogContent>
        <DialogActions>
          <Grid justify='flex-end' container>
            <LeftBtn xs={2} item>
              <Button
                onClick={cancelSelection}
                text='Cancel'
                bgcolor='#9c8657'
              />
            </LeftBtn>
            <Grid xs={2} item>
              <Button
                onClick={() => sendClinicianList(updatedClinicians)}
                text='Confirm'
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

CliniciansModal.propTypes = {
  toggleClinicianModal: PropTypes.func.isRequired,
  sendClinicianList: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  clinicians: PropTypes.array,
};

export default CliniciansModal;
