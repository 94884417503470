/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { Typography, Grid, withStyles } from '@material-ui/core';
// import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import dayjs from 'dayjs';
import clock from '../../../assets/user_images/time.svg';
import date from '../../../assets/user_images/date.svg';
import map from '../../../assets/user_images/map_icon.svg';
import people from '../../../assets/user_images/people.svg';
import { CustomNextButtons } from '../../../common/CustomButtons';
import Stepper from '../../../common/Stepper';
import Header from '../../../common/Header';
import { getAppointmentDetailAction } from '../../../utils/actions/appointment.action';
import { Loader } from '../../Loader';
import styles from './AppointmentConfirmationStyle';

class AppointmentConfirmation extends PureComponent {
  componentDidMount() {
    this.props.getAppointmentDetailAction(this.props.match.params.id);
  }

  generateAddress(clinician) {
    return clinician && clinician.address
      ? `${clinician.address.address1}, ${clinician.address.country.name}`
      : 'NA';
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header props={this.props} />
        <div className={classes.root} />
        <Grid container justify='center' alignItems='center' component='div'>
          <Grid className={classes.titleContainer} component='div'>
            <Stepper tab={3} />
            <Grid
              container
              component='div'
              alignItems='center'
              justify='center'
            >
              <Grid item component='div' className={classes.container}>
                <Grid component='div'>
                  <Typography className={classes.title}>
                    Your appointment is confirmed!
                  </Typography>
                </Grid>

                <Grid className={classes.boxContainer}>
                  <Grid
                    style={{
                      paddingLeft: '33px',
                      paddingRight: '43px',
                      paddingTop: '33px',
                      paddingBottom: '40px',
                    }}
                  >
                    {this.props.getAppointmentLoader ? (
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Loader />
                      </div>
                    ) : (
                      <>
                        <Grid style={{ display: 'flex' }}>
                          <Grid
                            container
                            alignItems='flex-start'
                            justify='flex-start'
                          >
                            <Grid className={classes.gridContainer}>
                              <img src={date} className={classes.dateicon} />
                              <Typography className={classes.datetext}>
                                {/* 05/15/2020 */}
                                {dayjs(
                                  this.props.getAppointmentData.startTime
                                ).format('DD/MM/YYYY')}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            alignItems='flex-start'
                            justify='flex-start'
                          >
                            <Grid className={classes.gridContainer}>
                              <img src={map} className={classes.dateicon} />
                              <Grid className={classes.maptext}>
                                <Typography
                                  display='inline'
                                  className={classes.datetext}
                                >
                                  {this.props.getAppointmentData.clinicianId &&
                                    this.generateAddress(
                                      this.props.getAppointmentData.clinicianId
                                    )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid style={{ display: 'flex', marginTop: '20px' }}>
                          <Grid
                            container
                            alignItems='flex-start'
                            justify='flex-start'
                          >
                            <Grid className={classes.gridContainer}>
                              <img
                                src={clock}
                                display='inline'
                                className={classes.clockicon}
                              />
                              <Typography
                                display='inline'
                                className={classes.clocktext}
                              >
                                {/* 10:30AM, for 30mins */}
                                {`${dayjs(
                                  this.props.getAppointmentData.startTime
                                ).format('hh:mmA')}, for ${
                                  this.props.getAppointmentData.duration
                                }mins`}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            alignItems='flex-start'
                            justify='flex-start'
                          >
                            <Grid className={classes.gridContainer}>
                              <img src={people} className={classes.dateicon} />
                              <Typography className={classes.datetext}>
                                {this.props.getAppointmentData.clinicianId &&
                                  `${this.props.getAppointmentData.clinicianId.firstName} ${this.props.getAppointmentData.clinicianId.lastName}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container className={classes.secondConatiner}>
                  <Typography className={classes.title}>Next steps…</Typography>
                </Grid>

                <Grid className={classes.bottomConatiner}>
                  <Typography component='p' className={classes.bottomtext}>
                    Thank you for choosing Lighthouse Arabia for your mental
                    health needs. We’ve sent a copy of this confirmation to your
                    email address{' '}
                    <b>
                      {this.props.getAppointmentData.clientId &&
                        this.props.getAppointmentData.clientId.email}
                      .
                    </b>{' '}
                    You can access all your appointment details in your{' '}
                    <b
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => this.props.history.push('/dashboard')}
                    >
                      dashboard
                    </b>
                    .
                  </Typography>
                  <Typography
                    component='p'
                    className={classes.bottomtext}
                    style={{ marginTop: '0px' }}
                  >
                    There are additional required documents for you to sign
                    before you can be seen by your doctor, and can be found
                    under <b> Appointments</b>
                    <b> &gt; Upcoming Visits </b> in your dashboard.
                  </Typography>
                </Grid>

                <Grid
                  container
                  className={classes.buttoncontainer}
                  justify='center'
                  style={{ marginTop: '50px', marginBottom: '115px' }}
                >
                  <CustomNextButtons
                    width='267px'
                    title='Go To Dashboard'
                    style={classes.nextButton}
                    onPressButton={() => this.props.history.push('/dashboard')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid item xs={6} className={classes.gridflex}>
                  <img
                    src={date}
                    display="inline"
                    className={classes.dateicon}
                  />
                  <Typography display="inline" className={classes.datetext}>
                    05/15/2020
                  </Typography>
                </Grid>

                <Grid item xs={6} className={classes.gridflex}>
                  <img src={map} display="inline" className={classes.mapicon} />
                  <Typography display="inline" className={classes.maptext}>
                    Villa 821, Al Wasl Rd, Al SafaAl Safa…
                  </Typography>
                </Grid>

                <Grid item xs={6} className={classes.gridflex}>
                  <img
                    src={clock}
                    display="inline"
                    className={classes.clockicon}
                  />
                  <Typography display="inline" className={classes.clocktext}>
                    10:30AM, for 30mins
                  </Typography>
                </Grid>

                <Grid item xs={6} className={classes.gridflex}>
                  <img
                    src={people}
                    display="inline"
                    className={classes.peopleicon}
                  />
                  <Typography display="inline" className={classes.peopletext}>
                    Dr. Juan Korkie
                  </Typography>
                </Grid> */}
      </div>
    );
  }
}

AppointmentConfirmation.defaultProps = {
  getAppointmentData: {},
  getAppointmentLoader: true,
};

const connected = connect(
  (state) => ({
    getAppointmentData: state.getAppointmentDetail.getAppointmentDetailData,
    getAppointmentLoader: state.getAppointmentDetail.request,
  }),
  {
    getAppointmentDetailAction,
  }
)(AppointmentConfirmation);

const formed = reduxForm({
  form: 'appointmentData',
})(connected);

export default withStyles(styles)(formed);
