import React, { useEffect, useState, createRef } from 'react';
import { Button, withStyles, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import {
  getAppointmentDetailAction,
  consentFormDetail,
  signTheConsentForm,
} from '../../../utils/actions/appointment.action';
import {
  getClientAction,
  saveUserSignature,
} from '../../../utils/actions/client.action';
import Header from '../../../common/Header';
import Stepper from '../../../common/Stepper';
import styles from './InsuranceInfoStyle';
import { CanvasDialog, TextField } from '../../../components/atoms';
import AlertMsg from '../../../utils/Alert';
import styled from 'styled-components';

const FormGrid = styled(Grid)`
  height: 300px;
  overflow: scroll;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px;
`;

const Content = styled(Grid)`
  width: 800px;
  margin-top: 32px;
  margin-bottom: 64px;
`;

const ConsentForm = ({
  classes,
  getAppointmentData,
  history,
  consentInfo,
  signTheConsentForm,
  signatureSaved,
  clientInfo,
  match,
  signConsentSuccessful,
  updatedSignature,
  getAppointmentDetailAction,
  consentFormDetail,
  getClientAction,
  saveUserSignature,
}) => {
  const [relation, setRelation] = useState('');
  const [openCanvasDialog, setCanvasDialog] = useState(false);
  const [signature, setSignature] = useState('');
  const [openWarning, setOpenWarning] = useState(false);

  useEffect(() => {
    if (signConsentSuccessful) {
      history.push(`/paymentInfo/${match.params.id}`);
    }
  }, [signConsentSuccessful]);

  useEffect(() => {
    if (signatureSaved) {
      AlertMsg('success', 'Signature Saved!');
      updateSignature(updatedSignature);
    }
  }, [updatedSignature]);

  useEffect(() => {
    if (clientInfo?.signature) {
      updateSignature(clientInfo.signature);
    }
  }, [clientInfo]);

  let signaturePad = createRef();
  useEffect(() => {
    getAppointmentDetailAction(match.params.id);
    consentFormDetail(match.params.id);
    getClientAction(true);
  }, []);

  const updateSignature = async (img) => {
    setSignature(await getBase64FromUrl(img));
  };
  const setRef = (ref) => {
    if (ref && ref.fromDataURL) {
      ref.fromDataURL(signature);
      signaturePad = ref;
    }

    if (ref && ref.toDataURL) {
      ref.toDataURL();
      signaturePad = ref;
    }

    if (signature && ref && ref.off) {
      ref.off();
      signaturePad = ref;
    }

    if (ref && ref.clear) {
      ref.clear();
      signaturePad = ref;
    }
  };

  const handleCancelConsent = () => {
    history.push('/dashboard');
  };

  const handleClose = () => setOpenWarning(false);

  const handleSubmit = () => {
    if (!consentInfo || !consentInfo.clientSign) {
      openSignatureCanvas();
    } else {
      history.push(`/paymentInfo/${match.params.id}`);
    }
  };

  const openSignatureCanvas = () => setCanvasDialog(true);

  const closeCanvas = () => setCanvasDialog(false);

  const clearSignature = () => {
    if (signaturePad) {
      signaturePad.clear();
    }
  };

  const modifyUserSignature = () => {
    if (signaturePad) {
      if (signaturePad.isEmpty()) {
        AlertMsg(
          'error',
          'If you plan to add signature later then click on dark space!'
        );
      } else {
        const base64Img = signaturePad.toDataURL();
        const formData = new FormData();
        formData.append('signature', dataURLtoBlob(base64Img));

        saveUserSignature(formData);
      }
    }
  };

  const dataURLtoBlob = (dataurl) => {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const handleCancel = () => {
    setCanvasDialog(true);
  };

  const signConsentForm = () => {
    signTheConsentForm(match.params.id, relation);
  };

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  return (
    <div>
      <Header />
      <div className={classes.root} />
      <Grid container justify='center' alignItems='center'>
        <Grid className={classes.titleContainer} component='div'>
          <Stepper tab={1} />
          <Content
            container
            direction='column'
            alignItems='center'
            justify='center'
          >
            <FormGrid
              item
              container
              style={{ height: '300px', overflow: 'scroll' }}
            >
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id
                justo non leo hendrerit vestibulum. Integer bibendum quis justo
                non luctus. Donec ullamcorper mattis arcu vitae facilisis.
                Pellentesque ut suscipit urna. Suspendisse at velit in ex
                pellentesque feugiat in a lorem. Maecenas ut enim lacinia,
                dictum tellus sit amet, semper leo. Nam finibus consequat
                volutpat. Aenean at turpis bibendum, vulputate velit ut, laoreet
                arcu.
              </p>
              <p>
                Maecenas ac pharetra risus. Morbi vulputate convallis lectus et
                faucibus. Sed ut nisl a metus imperdiet convallis. Nunc ut dui
                metus. Sed sed bibendum purus. Proin interdum elementum erat.
                Sed tincidunt tellus fringilla purus pulvinar, nec mattis mauris
                pellentesque. Cras pellentesque purus eget porttitor imperdiet.
                Vestibulum pharetra urna ante.
              </p>
              <p>
                Nullam quis tempor lectus. Quisque ut leo egestas, iaculis
                mauris vitae, molestie nunc. Duis dictum pulvinar risus in
                accumsan. Nulla congue nisl ante, et blandit mi sagittis quis.
                Class aptent taciti sociosqu ad litora torquent per conubia
                nostra, per inceptos himenaeos. Etiam id turpis tellus. Ut
                ultrices placerat dictum.
              </p>
              <p>
                Curabitur feugiat, justo sit amet dapibus venenatis, mauris diam
                gravida ex, ac auctor diam felis sed lacus. Praesent sed massa
                libero. Cras egestas urna sit amet lectus molestie, non ornare
                eros laoreet. Vivamus vel volutpat nulla. Nulla fringilla nisi
                id dolor posuere porttitor. Nullam rhoncus ligula ut neque
                dignissim consectetur. Morbi quis dapibus ex. Donec ac
                condimentum felis. In vulputate, dolor ut sollicitudin
                dignissim, diam nibh tempor orci, ut euismod metus orci id
                turpis.
              </p>
              <p>
                Aliquam erat volutpat. Morbi maximus id erat non facilisis.
                Nullam molestie accumsan consequat. Curabitur porta lectus sit
                amet hendrerit scelerisque. Phasellus rhoncus ultricies quam.
                Aliquam porttitor tellus erat, et venenatis ante rutrum vel.
                Pellentesque ac nisi condimentum, faucibus ex et, pharetra
                augue. Fusce laoreet tristique metus, in viverra enim suscipit
                quis. Integer ipsum nibh, fermentum sit amet eleifend nec,
                auctor at metus. Nulla in diam vestibulum, eleifend metus sit
                amet, lobortis tortor. Suspendisse mattis vulputate libero et
                porta. Etiam tincidunt dignissim libero, et congue odio laoreet
                pharetra.
              </p>
              <p>
                Aenean vel augue vulputate, hendrerit est ac, tempus est. Donec
                et justo molestie, auctor nisi id, condimentum diam. In felis
                mi, faucibus vel maximus quis, aliquet sed felis. Cras nec
                facilisis nisl. Proin sed nulla scelerisque, sollicitudin dui
                et, vestibulum eros. Vivamus fringilla risus eget tristique
                facilisis. Vivamus fringilla egestas ligula vitae lacinia. Fusce
                maximus, nulla vel molestie varius, enim massa rutrum urna, at
                tincidunt leo justo sed erat. Nullam sollicitudin consequat
                lacinia. Etiam posuere placerat justo id pharetra. Phasellus
                efficitur sem a porttitor fermentum. Suspendisse sed tortor
                porta, tristique nisi id, malesuada tellus. Sed eget fermentum
                ante. Sed maximus ut lacus ut suscipit.
              </p>
              <p>
                Nulla commodo rutrum quam, in accumsan risus convallis ac. Donec
                eu turpis vel felis consequat gravida ut non nisi. Nam iaculis,
                nulla sit amet bibendum maximus, quam massa malesuada risus, ut
                auctor nibh purus in risus. Interdum et malesuada fames ac ante
                ipsum primis in faucibus. Vestibulum ornare felis eget justo
                auctor, sit amet mattis mauris auctor. Nunc vel elementum eros.
                Donec lobortis metus et odio bibendum, nec posuere dolor semper.
                Quisque pretium elementum augue, vitae fringilla magna. Maecenas
                ut lacus pharetra, interdum magna at, feugiat tortor. Vestibulum
                ligula enim, tincidunt id faucibus id, sodales porttitor nulla.
              </p>
              <p>
                Maecenas a ullamcorper magna. Duis at mauris nec augue feugiat
                interdum. Aenean interdum nunc tellus, scelerisque ultrices
                tortor imperdiet vitae. Orci varius natoque penatibus et magnis
                dis parturient montes, nascetur ridiculus mus. Praesent nec
                metus luctus, placerat lectus et, interdum dui. Aliquam
                vulputate dignissim posuere. Cras dictum lacinia viverra.
                Vivamus luctus semper finibus. Vivamus dolor eros, condimentum a
                turpis in, varius commodo magna.
              </p>
              <p>
                Nullam laoreet, nibh vitae efficitur tempor, purus nibh pretium
                risus, vel iaculis felis velit in magna. Donec at enim in dui
                consectetur mollis in in ex. Morbi justo tellus, molestie nec
                tincidunt iaculis, sollicitudin vel nisl. Cras vitae nisi eu
                lorem placerat venenatis. Mauris vel purus eget nunc porttitor
                consequat vitae quis diam. Nulla posuere rutrum elementum.
                Aenean ac odio eget dolor sodales rutrum vel ut mauris.
                Vestibulum eleifend purus dolor, a feugiat quam consequat
                convallis. In id nulla molestie, lobortis mauris a, auctor eros.
                Aenean pulvinar velit vel libero suscipit molestie. Mauris
                tristique quam sit amet ipsum tristique, a bibendum odio
                venenatis. Interdum et malesuada fames ac ante ipsum primis in
                faucibus. Orci varius natoque penatibus et magnis dis parturient
                montes, nascetur ridiculus mus.
              </p>
              <p>
                Proin tempus aliquam arcu. Sed non luctus elit, ac sodales
                magna. Sed quis enim nec ex commodo facilisis. Nullam id metus
                tortor. Vestibulum ante ipsum primis in faucibus orci luctus et
                ultrices posuere cubilia curae; Duis tempor orci ut ligula
                elementum, eu lacinia ipsum consectetur. Aenean venenatis
                sagittis massa eget molestie. Quisque condimentum vehicula
                mauris nec placerat. Curabitur ipsum ex, iaculis quis est
                porttitor, condimentum viverra arcu. Maecenas sit amet faucibus
                augue. Praesent massa neque, suscipit quis nunc sit amet,
                condimentum semper ligula.
              </p>
            </FormGrid>
            {getAppointmentData?.clientId?.length > 1 && (
              <Grid style={{ marginTop: '30px' }} container>
                <Grid item>
                  <TextField
                    value={relation}
                    onChange={(e) => setRelation(e.target.value)}
                    name='relation'
                    error=''
                    bgcolor='#fff'
                    label='Relation'
                  />
                </Grid>
              </Grid>
            )}
            <Grid
              style={{ marginTop: '32px', marginBottom: '64px' }}
              container
              item
            >
              <Grid style={{ padding: '8px' }} item xs={6}>
                <Button
                  style={{ width: '100%' }}
                  onClick={handleCancel}
                  variant='contained'
                  color='default'
                >
                  Cancel
                </Button>
              </Grid>
              <Grid style={{ padding: '8px' }} item xs={6}>
                <Button
                  style={{ width: '100%' }}
                  variant='contained'
                  onClick={handleSubmit}
                  color='primary'
                >
                  Accept and Sign
                </Button>
              </Grid>
            </Grid>
          </Content>
        </Grid>
      </Grid>
      <Dialog
        open={openWarning}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Cancel'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Not accepting concent form will cancel the current appointment
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Disagree
          </Button>
          <Button onClick={handleCancelConsent} color='primary' autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <CanvasDialog
        open={openCanvasDialog}
        onClose={closeCanvas}
        ref={signaturePad}
        signature={signature}
        setRef={setRef}
        clear={clearSignature}
        modifyUserSignature={modifyUserSignature}
        signConsentForm={signConsentForm}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  getAppointmentData: state.getAppointmentDetail.getAppointmentDetailData,
  consentInfo: state.consent.info,
  clientInfo: state.getClient.getClientData,
  signConsentSuccessful: state.signConsent.success,
  signatureSaved: state.saveClientSignature.signatureSaved,
  updatedSignature: state.saveClientSignature.updatedSignature,
});

const mapDispatchToProps = (dispatch) => ({
  getAppointmentDetailAction: (id) => dispatch(getAppointmentDetailAction(id)),
  consentFormDetail: (id) => dispatch(consentFormDetail(id)),
  signTheConsentForm: (id, relation) =>
    dispatch(signTheConsentForm(id, relation)),
  getClientAction: (doc) => dispatch(getClientAction(doc)),
  saveUserSignature: (image) => dispatch(saveUserSignature(image)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ConsentForm));
