/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from "react";
// import PropTypes from 'prop-types';
import {
  Container,
  Typography,
  CssBaseline,
  
  withStyles,
  Grid,
  Divider,
  Button,
  Paper,
  Step,
  StepLabel,
  StepConnector,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import querystring from "query-string";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { getByIdClientAction } from "../../utils/actions/client.action";
import Header from "../../common/Header";
import VerifyInformation from "./profileInformations/VerifyInfo";
import InsuranceInformation from "./profileInformations/InsuranceInfo";
import PaymentInformation from "./profileInformations/PaymentInfo";
import AppointmentConfirmation from "./profileInformations/AppointmentConfirmation";
import Stepper from "../../common/Stepper";
import styles from "./ProfileListStyle";

const steps = [
  "Verify Information",
  // "Insurance Information",
  "Payment Information",
  "Appointment Confirmation",
];

class ProfileList extends PureComponent {
  // static propTypes = {}

  constructor(props) {
    super(props);

    this.state = {
      profileOption: [],
      activeStep: 0,
    };
  }

  componentDidMount() {
    const url = querystring.parse(this.props.location.search);
    this.props.getByIdClientAction(this.props.match.params.id1, url.token);
  }

  componentDidUpdate(preProps) {
    if (this.props.getClientData !== preProps.getClientData) {
      const data = this.props.getClientData;
      if (data) {
        this.setState({
          profileOption: [
            // { lable: "Client Type:", value: data.clientType },
            { lable: "Name:", value: data.name },
            { lable: "Email:", value: data.email },
            { lable: "Phone Number:", value: data.phoneNumber },
            // { lable: "Age:", value: data.age },
            { lable: "Date of Birth:", value: data.dob },
            // { lable: "Nationality:", value: data.nationality },
            { lable: "Gender:", value: data.gender },
            // { lable: "Marital Status:", value: data.maritalStatus },
            { lable: "State:", value: data.state },
            // { lable: "Residency:", value: data.residency },
            // { lable: "Fee:", value: data.fee },
            // {
            //   lable: "Clinician’s Preference:",
            //   value: data.cliniciansPreference,
            // },
            // { lable: "Referred by:", value: data.referredBy },
            // {
            //   lable: "Valid Photo Identification Document(ID):",
            //   value: data.validPhotoIdentification,
            // },
            // { lable: "Insurance", value: data.insurance },
            // { lable: "Caller Information", value: data.callerInformation },
            // { lable: "UAEResident:", value: data.UAEResident },
            // { lable: "Informed Consent:", value: data.informedConsent },
            // {
            //   lable: "Release of Information:",
            //   value: data.ReleaseOfInformation,
            // },
            // {
            //   lable: "Request for Psychical support (Wheel Chair):",
            //   value: data.RequestForPhysical,
            // },
            // { lable: "Risk Factor:", value: data.riskactor },
            // {
            //   lable: "Emergency name:",
            //   value: data.emergencyContact && data.emergencyContact.name,
            //   type: "em",
            // },
            // {
            //   lable: "Emergency Email:",
            //   value: data.emergencyContact && data.emergencyContact.email,
            //   type: "em",
            // },
            // {
            //   lable: "Emergency Phone number:",
            //   value: data.emergencyContact && data.emergencyContact.phoneNumber,
            //   type: "em",
            // },
            // {
            //   lable: "Emergency Relationship:",
            //   value:
            //     data.emergencyContact && data.emergencyContact.relationship,
            //   type: "em",
            // },
          ],
        });
      }
    }
  }

  render() {
    const { classes } = this.props;
    const url = querystring.parse(this.props.location.search);

    return (
      <>
        <Header />
        <div className={classes.root} />
        <Grid container justify="center" alignItems="center" component="div">
          <Grid className={classes.titleContainer} component="div">
            {/* <Stepper /> */}
            {/* <AppointmentConfirmation /> */}
          </Grid>
        </Grid>
      </>
    );
  }
}

const connected = connect(
  (state) => ({
    getClientData: state.getByIdClient.getByIdClientData,
  }),
  {
    getByIdClientAction,
  }
)(ProfileList);

const formed = reduxForm({
  form: "getClientDetail",
})(connected);



export default withStyles(styles)(formed);
