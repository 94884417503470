/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import * as React from "react";
import { Grid } from "@material-ui/core";
import styles from "./TableImageContainer_style";


export const CustomImage = ({ logo, ...props }) => {
  const classes = styles();
  return (
    <Grid>
      <img src={logo} className={classes.root} {...props} />
    </Grid>
  );
};

export const CustomDots = ({ bgColor,mL, ...props }) => {
  const classes = styles();
  return (
    <Grid
      className={classes.dots}
      style={{ backgroundColor: bgColor ,marginLeft:mL}}
      {...props}
    />
  );
};
