/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/jsx-filename-extension */
import React, { PureComponent } from 'react';
import {
  withStyles,
  Grid,
  Button,
  Typography,

  Divider,
} from '@material-ui/core';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
// import en from 'date-fns/locale/en';
import 'react-datepicker/dist/react-datepicker.css';
import AddIcon from '@material-ui/icons/Add';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { getAllInvoiceAction } from '../../utils/actions/billing.action';
import Loader from '../../components/Loader/Loader';
import styles from './ClientBillingStyle';

// registerLocale('en', en);

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

class ClientBilling extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      allInvoice: [],
      isLoaded: null,
    };
  }

  componentDidMount() {
    this.props.getAllInvoiceAction(this.props.props.match.params.id);
  }

  componentDidUpdate(preProps) {
    const { getInvoiceData } = this.props;
    if (getInvoiceData !== preProps.getInvoiceData) {
      this.setState({ isLoaded: getInvoiceData.request });
      if (getInvoiceData.getAllInvoiceData) {
        console.log(getInvoiceData.getAllInvoiceData);

        this.setState({ allInvoice: getInvoiceData.getAllInvoiceData });
      }
    }
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  render() {
    const { classes } = this.props;
    const { selectedOption, allInvoice, isLoaded } = this.state;

    return (
      <div className={classes.container}>
        <Grid container spacing={3} className={classes.gridsty}>
          <Grid item xs={4} sm={4}>
            <Select
              value={selectedOption}
              onChange={this.handleChange}
              options={options}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={classes.gridLeft}>
            <DatePicker
              locale='en'
              selected={this.state.startDate}
              onChange={this.handleDateChange}
              className={classes.dateInput}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={classes.gridRight}>
            <Button
              variant="contained"
              className={classes.createButton}
              startIcon={<AddIcon />}
            >
              create new
            </Button>
          </Grid>
        </Grid>

        {isLoaded ? (
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <Loader />
          </div>
        ) : (
          allInvoice.map((invoice) => (
            <>
              <Grid container spacing={2} className={classes.gridsty}>
                <Grid item xs={1} sm={1}>
                  <Button variant="contained" size="small" disabled>
                    {dayjs(invoice.createdAt).format('MMM DD')}
                  </Button>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography className={classes.appointmentsty}>
                      Invoice
                    </Typography>
                    <Button
                      variant="contained"
                      size="small"
                      className={classes.margin}
                    >
                      unpaid
                    </Button>
                  </div>
                  <Link
                    to={`/single_invoice/${invoice._id}`}
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                  >
                    <Typography className={classes.progressSty}>
                      INV: #
                      {dayjs(invoice.createdAt).format('DD')}
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={3} sm={3} className={classes.gridRight}>
                  <Typography className={classes.timeSty}>
                    $
                    {invoice.amount}
                  </Typography>
                </Grid>
              </Grid>

              <Divider variant="inset" className={classes.dividersty} />

              <Grid container spacing={2} className={classes.gridsty}>
                <Grid item xs={8} sm={8} style={{ paddingLeft: '85px' }}>
                  <Typography className={classes.appointmentsty}>
                    Appointment
                  </Typography>
                  <Typography className={classes.progressSty}>
                    Psychiatric Diagnostic Evaluation
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} className={classes.gridRight}>
                  <Typography className={classes.timeSty}>
                    $
                    {invoice.amount}
                  </Typography>
                </Grid>
              </Grid>

              <Divider variant="inset" className={classes.dividersty} />
            </>
          ))
        )}
      </div>
    );
  }
}

const connected = connect(
  (state) => ({
    getInvoiceData: state.getAllInvoice,
  }),
  {
    getAllInvoiceAction,
  },
)(ClientBilling);

const formed = reduxForm({
  form: 'getInvoices',
})(connected);

export default withStyles(styles)(formed);
