/* eslint-disable no-lone-blocks */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable radix */
/* eslint-disable import/order */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/sort-comp */
/* eslint-disable no-sequences */
/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-unreachable */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable quotes */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
import React from "react";
import PropsTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withStyles} from "@material-ui/core/styles";
import {
  FormControlLabel,
  Radio,
  Divider,
  Checkbox,
  SwipeableDrawer,
  Grid,
  Typography,
  Box,
  ButtonGroup,
} from "@material-ui/core";
import clsx from "clsx";
import Select from "react-select";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import DatePicker, { registerLocale } from 'react-datepicker';
// import en from 'date-fns/locale/en';
// import AddClients_Component from "./AddClients_Component";
import { getClientAction } from "../../utils/actions/client.action";
import {
  addAppointmentAction,
  getServiceAction,
  updateAppointmentAction,
} from "../../utils/actions/appointment.action";
import { getByIdClinicianAction } from "../../utils/actions/clinician.action";
import AlertMsg from "../../utils/Alert";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { Delete } from "@material-ui/icons";
import querystring from "query-string";
import validator from "validator";
import styles from "./AppointmentBookPopUpStyle";

// registerLocale('en', en);

class ClientAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: true,
      appointmentType: "client",
      right: false,
      clientName: "",
      clientId: "",
      allDay: false,
      dayStartDate: new Date(this.props.selectedDate),
      dayEndDate: new Date(this.props.selectedDate),
      allDayDate: new Date(this.props.selectedDate),
      dayHr: "",
      location: "",
      isIndivisual: "indivisual",
      coupleId: "",
      coupleName: "",
      selectedOption: null,
      repeatDay: [],
      repeateEndType: "",
      repeateEndHr: "",
      repeateEndDate: new Date(this.props.selectedDate),
      repeateDay: "",
      services: [{ id: "", amount: "" }],
      isRepeat: false,
      // serviceAmount: [],
      allClient: [],
      allService: [],
      isError: false,
      openDialog: false,
      clinicianRate: "",
      isUpdate: false,
    };
  }

  async componentDidMount() {
    this.setState({ openDialog: this.props.openDialog });
    await this.props.getClientAction();
    await this.props.getServiceAction();
    await this.props.getByIdClinicianAction(this.props.props.match.params.id2);
    if (
      this.props.props.location.pathname ===
      `/user/appointment/${this.props.props.match.params.id1}/clinician/${this.props.props.match.params.id2}`
    ) {
      this.setState({ isUpdate: true });
      console.log("=============hiii callled");
    }
  }

  async componentDidUpdate(preProps, preState) {
    const {
      addAppointmentData,
      getAllClient,
      getServiceData,
      getClinicianData,
      updateAppointmentStatus,
    } = this.props;
    if (getClinicianData !== preProps.getClinicianData) {
      if (getClinicianData.getByIdClinicianData) {
        this.setState({
          clinicianRate: getClinicianData.getByIdClinicianData.hourlyRate,
        });
      }
    }
    if (getServiceData !== preProps.getServiceData) {
      if (getServiceData.getServiceDataUser) {
        this.setState({ allService: getServiceData.getServiceDataUser });
      }
    }
    if (getAllClient !== preProps.getAllClient) {
      if (getAllClient.getClientData) {
        this.setState({ allClient: getAllClient.getClientData });
      }
      console.log(this.state.allClient, getAllClient);
    }
    if (addAppointmentData !== preProps.addAppointmentData) {
      if (addAppointmentData.addAppointmentData) {
        await this.props.closeDialog(addAppointmentData.addAppointmentData._id);
        AlertMsg("success", "Appointment Created");
      }
      if (addAppointmentData.error) {
        await this.props.cancelDialog();
        AlertMsg("error", " Failed");
      }
    }
    if (updateAppointmentStatus !== preProps.updateAppointmentStatus) {
      if (updateAppointmentStatus.updateAppointmentData === "") {
        await this.props.closeDialog();
        AlertMsg("success", "Appointment Updated");
      }
      if (addAppointmentData.error) {
        await this.props.cancelDialog();
        AlertMsg("error", " Failed");
      }
    }
  }

  validationForm = () => {
    const isLocation = validator.isEmpty(this.state.location, {
      ignore_whitespace: true,
    });
    if (isLocation) {
      AlertMsg("error", "Please select location");
      return false;
    }
    return true;
  };

  handleChange = (index) => (e) => {
    const services = [...this.state.services];
    services[index] = { ...this.state.services[index], amount: e.target.value };
    this.setState({ services });
  };

  handleSelectClient = (client) => {
    this.setState({ clientId: client._id, clientName: client.name });
  };

  handleSelectCouple = (couple) => {
    this.setState({ coupleId: couple._id, coupleName: couple.name });
  };

  handleSelectHr = (hr) => {
    this.setState({ dayHr: hr.value });
  };

  handleSelectLocation = (location) => {
    this.setState({ location: location.value });
  };

  handleSelectDay = (id, day) => {
    const repeatDay = [...this.state.repeatDay];
    repeatDay.push(day);
    this.setState({ repeatDay });
  };

  handleUnselectDay = (id) => {
    const repeatDay = [...this.state.repeatDay];
    const index = repeatDay.findIndex((i) => i === id);
    repeatDay.splice(index, 1);
    this.setState({ repeatDay });
  };

  handleSelectRepeatEnd = (selectedOption) => {
    this.setState({ repeateEndType: selectedOption.value });
  };

  handleSelectRepeatEndHr = (selectedOption) => {
    this.setState({ repeateEndHr: selectedOption.value });
  };

  handleSelectRepeateDay = (day) => {
    this.setState({ repeateDay: day.value });
  };

  handleSelectService = (index) => (service) => {
    const services = [...this.state.services];
    services[index] = {
      ...this.state.services[index],
      id: service._id,
      amount: service.rate,
    };
    this.setState({ services });
    console.log("sd-kjnkj-----------", service, index, this.state.services);
  };

  handleDeleteService = (index) => () => {
    const services = [...this.state.services];
    services.splice(index, 1);
    this.setState({ services });
    console.log("sd------------", index, this.state.services);
  };

  handleSubmit = (event) => {
    const {
      appointmentType,
      clientId,
      allDayDate,
      dayHr,
      location,
      isIndivisual,
      repeateDay,
      repeatDay,
      repeateEndType,
      repeateEndDate,
      repeateEndHr,
      services,
      isRepeat,
      dayEndDate,
      dayStartDate,
      clinicianRate,
      allDay,
    } = this.state;
    const isVerify = this.validationForm();
    const serviceId = services.map((item) => item.id);
    const serviceAmount = services.reduce(
      (cnt, i) => cnt + parseInt(i.amount),
      0
    );
    const totalTime = allDayDate;
    totalTime.setHours(
      totalTime.getHours(),
      totalTime.getMinutes() + dayHr,
      0,
      0
    );
    const totalDay =
      (dayEndDate.getTime() - dayStartDate.getTime()) / (1000 * 3600 * 24);
    const totalRate = !allDay
      ? totalTime.getMinutes() * (clinicianRate / 60) + (serviceAmount || 0)
      : totalDay * 24 * clinicianRate +
        (serviceAmount ? parseFloat(serviceAmount) : 0);
    if (isVerify) {
      const data = {
        type: appointmentType,
        clientId: this.props.props.match.params.id1,
        startTime: allDayDate,
        endTime: dayEndDate,
        duration: dayHr || 0,
        location,
        // recurring: isRepeat,
        // recurringInterval: repeateDay,
        // recurringDaysOfWeek: repeatDay,
        // recurringEndType: repeateEndType,
        // recurringEndFrequency: repeateEndHr,
        // // recurringEndDate: "",
        // recurringEndDate: repeateEndDate,
        // // services: serviceId,
        services: serviceId || [],
        // individual: true,
        amount: Math.round((totalRate * 100) / 100).toFixed(2),
        // amount: serviceAmount,
      };
      console.log(data);
      {
        this.state.isUpdate
          ? this.props.updateAppointmentAction(
              this.props.props.match.params.id1,
              data
            )
          : this.props.addAppointmentAction(
              this.props.props.match.params.id2,
              data
            );
      }
    }
  };

  toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ [anchor]: open });
  };

  render() {
    const { classes } = this.props;
    const { selectedOption, clinicianRate, dayHr } = this.state;
    console.log(
      "update===========data=========",
      this.props.updateAppointmentStatus
    );
    console.log("add===========data=========", this.props.addAppointmentData);

    const {
      allDay,
      dayEndDate,
      dayStartDate,
      allDayDate,
      isIndivisual,
      clientType,
      repeatDay,
      // serviceAmount,
      allClient,
      allService,
      services,
    } = this.state;
    const serviceAmount = services.reduce(
      (cnt, i) => cnt + parseInt(i.amount),
      0
    );

    const totalDay =
      (dayEndDate.getTime() - dayStartDate.getTime()) / (1000 * 3600 * 24);
    const totalRate = !allDay
      ? (dayHr && dayHr) * (clinicianRate / 60) + (serviceAmount || 0)
      : totalDay * 24 * clinicianRate +
        (serviceAmount ? parseFloat(serviceAmount) : 0);
    const hrOption = [
      { value: "5", label: "5 Min" },
      { value: "10", label: "10 Min" },
      { value: "15", label: "15 Min" },
      { value: "20", label: "20 Min" },
      { value: "25", label: "25 Min" },
    ];
    const location = [
      { value: "location:unassigned", label: "Location: unassigned" },
      { value: "teleHealth:videoOffice", label: "Telehealth: Video Office" },
    ];
    const options = [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6", label: "6" },
      { value: "7", label: "7" },
      { value: "8", label: "8" },
    ];
    const endData = [
      { value: "count", label: "After" },
      { value: "date", label: "On Date" },
    ];
    return (
      <div className={classes.root}>
        <Dialog
          fullWidth
          open={this.state.openDialog}
          onClose={this.props.cancelDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle className={classes.title}>New Appointment</DialogTitle>

          <DialogContent>
            {/* <div style={{ display: "inline-block" }}>
              <FormControlLabel
                name="client"
                value="end"
                className={classes.radiosty}
                control={
                  <Radio
                    color="primary"
                    checked={this.state.appointmentType === "client"}
                    onChange={() =>
                      this.setState({ appointmentType: "client" })
                    }
                  />
                }
                label="Client Appointment"
              />
              <FormControlLabel
                value="end"
                name="other"
                className={classes.radiosty}
                control={
                  <Radio
                    color="primary"
                    checked={this.state.appointmentType === "other"}
                    onChange={() => this.setState({ appointmentType: "other" })}
                  />
                }
                label="Other"
              />
            </div> */}

            {/* <div style={{ marginTop: 20 }}>
              <Grid container spacing={0}>
                <Grid item xs={10} sm={5}>
                  <Select
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option._id}
                    placeholder="Search Client"
                    onChange={this.handleSelectClient}
                    options={allClient}
                    className={classes.formControl}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={10} sm={5}>
                  <div>
                    {["right"].map((anchor) => (
                      <React.Fragment key={anchor}>
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          className={classes.button}
                          onClick={() => this.setState({ isOpen: true })}
                        >
                          + New Client
                        </Button>
                        {this.state.isOpen && (
                          <AddClients_Component
                            isOpen={this.state.isOpen}
                            onCancel={() => (
                              this.props.getClientAction(),
                              this.setState({ isOpen: false })
                            )}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </Grid>
              </Grid>
            </div> */}

            {/* <Divider className={classes.dividersty} /> */}

            <div>
              <FormControlLabel
                className={classes.alldaysty}
                control={
                  <Checkbox
                    checked={this.state.allDay}
                    onChange={() => this.setState({ allDay: !allDay })}
                    color="primary"
                  />
                }
                label="All-day"
              />

              <Grid container spacing={0}>
                {allDay ? (
                  <>
                    <Grid item xs={10} sm={3} className={classes.gridsty}>
                      <DatePicker
                        locale='en'
                        className={classes.dateInput}
                        dateFormat="MMMM d, yyyy "
                        selected={dayStartDate}
                        onChange={(date) =>
                          this.setState({ dayStartDate: date })
                        }
                      />
                    </Grid>
                    <Grid
                      className={classes.gridsty}
                      style={{
                        display: "flex",
                        flex: 1,
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                      item
                      xs={10}
                      sm={1}
                    >
                      <Typography
                        style={{ marginTop: 5 }}
                        className={classes.textStyle}
                      >
                        To
                      </Typography>
                    </Grid>
                    <Grid item xs={10} sm={3} className={classes.gridsty}>
                      <DatePicker
                        className={classes.dateInput}
                        dateFormat="MMMM d, yyyy "
                        selected={dayEndDate}
                        onChange={(date) => this.setState({ dayEndDate: date })}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={5} sm={5} className={classes.gridsty}>
                      <DatePicker
                        className={classes.dateInput}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        selected={allDayDate}
                        showTimeSelect
                        onChange={(date) => this.setState({ allDayDate: date })}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={3}
                      style={{ marginTop: 15, marginLeft: 30 }}
                    >
                      <Select
                        options={hrOption}
                        onChange={this.handleSelectHr}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              {/* <Grid style={{margingLeft: 20}} spacing= {3} container> */}
              <Grid item xs={12} sm={9} className={classes.gridsty}>
                <Select
                  options={location}
                  onChange={this.handleSelectLocation}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
              </Grid>
              {/* </Grid> */}
            </div>

            {/* <div style={{ marginTop: 20, marginBottom: 20 }}>
              <FormControlLabel
                className={classes.radiosty}
                control={(
                  <Checkbox
                    checked={this.state.isRepeat}
                    onChange={() => this.setState({ isRepeat: !this.state.isRepeat })}
                    name="checked"
                    color="primary"
                  />
                )}
                label="Repeats"
              />
              <br />
              {this.state.isRepeat ? (
                <div style={{ display: "inline-block" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>
                      <Typography className={classes.repeatsty}>
                        Every
                      </Typography>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <Select
                        onChange={this.handleSelectRepeateDay}
                        options={options}
                      />
                    </Grid>
                    <Grid item xs={10} sm={3}>
                      <Typography className={classes.repeatsty}>
                        week on:
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <ButtonGroup
                        color="primary"
                        aria-label="outlined primary button group"
                        className={classes.radiosty}
                      >
                        <Button
                          style={
                            repeatDay.includes(0)
                              ? { backgroundColor: "blue", color: "#fff" }
                              : { backgroundColor: "#fff" }
                          }
                          onClick={() => (repeatDay.includes(0)
                            ? this.handleUnselectDay(0)
                            : this.handleSelectDay(0, 0))}
                        >
                          S
                        </Button>
                        <Button
                          style={
                            repeatDay.includes(1)
                              ? { backgroundColor: "blue", color: "#fff" }
                              : { backgroundColor: "#fff" }
                          }
                          onClick={() => (repeatDay.includes(1)
                            ? this.handleUnselectDay(1)
                            : this.handleSelectDay(1, 1))}
                        >
                          M
                        </Button>
                        <Button
                          style={
                            repeatDay.includes(2)
                              ? { backgroundColor: "blue", color: "#fff" }
                              : { backgroundColor: "#fff" }
                          }
                          onClick={() => (repeatDay.includes(2)
                            ? this.handleUnselectDay(2)
                            : this.handleSelectDay(2, 2))}
                        >
                          T
                        </Button>
                        <Button
                          style={
                            repeatDay.includes(3)
                              ? { backgroundColor: "blue", color: "#fff" }
                              : { backgroundColor: "#fff" }
                          }
                          onClick={() => (repeatDay.includes(3)
                            ? this.handleUnselectDay(3)
                            : this.handleSelectDay(3, 3))}
                        >
                          W
                        </Button>
                        <Button
                          style={
                            repeatDay.includes(4)
                              ? { backgroundColor: "blue", color: "#fff" }
                              : { backgroundColor: "#fff" }
                          }
                          onClick={() => (repeatDay.includes(4)
                            ? this.handleUnselectDay(4)
                            : this.handleSelectDay(4, 4))}
                        >
                          T
                        </Button>
                        <Button
                          style={
                            repeatDay.includes(5)
                              ? { backgroundColor: "blue", color: "#fff" }
                              : { backgroundColor: "#fff" }
                          }
                          onClick={() => (repeatDay.includes(5)
                            ? this.handleUnselectDay(5)
                            : this.handleSelectDay(5, 5))}
                        >
                          F
                        </Button>
                        <Button
                          style={
                            repeatDay.includes(6)
                              ? { backgroundColor: "blue", color: "#fff" }
                              : { backgroundColor: "#fff" }
                          }
                          onClick={() => (repeatDay.includes(6)
                            ? this.handleUnselectDay(6)
                            : this.handleSelectDay(6, 6))}
                        >
                          S
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={10} sm={3}>
                      <Typography className={classes.repeatsty}>
                        Ends
                      </Typography>
                    </Grid>
                    <Grid item xs={10} sm={4} style={{ textAlign: "left" }}>
                      <Select
                        onChange={this.handleSelectRepeatEnd}
                        options={endData}
                      />
                    </Grid>
                    {this.state.repeateEndType === "count" ? (
                      <Grid item xs={12} sm={4}>
                        <Select
                          onChange={this.handleSelectRepeatEndHr}
                          options={options}
                        />
                      </Grid>
                    ) : (
                        <Grid item xs={12} sm={5}>
                          <DatePicker
                            dateFormat="MMMM d, yyyy "
                            className={classes.dateInput}
                            selected={this.state.repeateEndDate}
                            onChange={(date) => this.setState({ repeateEndDate: date })}
                          />
                        </Grid>
                      )}
                  </Grid>
                </div>
              ) : null}
            </div> */}
            <Divider className={classes.dividersty} />

            <Grid style={{ marginLeft: 10 }} container spacing={1}>
              {this.state.services.map((service, index) => (
                <>
                  <Grid item xs={7} sm={4} style={{ marginBottom: 3 }}>
                    <Select
                      value={
                        allService &&
                        allService.filter((option) => option._id === service.id)
                      }
                      getOptionLabel={(option) =>
                        `${option.serviceCode} ${option.description}`
                      }
                      getOptionValue={(option) => `${option.description}`}
                      onChange={this.handleSelectService(index)}
                      options={allService}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <input
                      name="amount"
                      value={service.amount}
                      onChange={this.handleChange(index)}
                      className={classes.divstyle}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <Delete
                      style={{
                        marginLeft: 20,
                        marginTop: 5,
                        color: "#a29061",
                        cursor: "pointer",
                      }}
                      onClick={this.handleDeleteService(index)}
                    />
                  </Grid>
                </>
              ))}
              <Button
                style={{ color: "#a29061" }}
                onClick={() => (
                  this.state.services.push(""), this.setState({})
                )}
              >
                Add Services
              </Button>
            </Grid>
            <Divider className={classes.dividersty} />

            <Grid style={{ marginLeft: 10 }} container spacing={1}>
              <Grid item xs={8} sm={8}>
                <Typography className={classes.textStyle}>
                  Billing Type
                </Typography>
              </Grid>
              <Grid item xs={4} sm={2}>
                <Typography style={{ color: "#a29061" }}>Self-Pay</Typography>
              </Grid>
              <Grid item xs={8} sm={8}>
                <Typography className={classes.textStyle}>
                  Appointment Total
                </Typography>
              </Grid>
              <Grid item xs={4} sm={2}>
                <Typography style={{ color: "#a29061" }}>
                  ${Math.round((totalRate * 100) / 100).toFixed(2) || 0}
                </Typography>
              </Grid>
            </Grid>

            <Divider className={classes.dividersty} />

            {/* <div style={{ display: "inline-block", marginTop: 20 }}>
              <FormControlLabel
                value="end"
                className={classes.radiosty}
                control={(
                  <Radio
                    color="primary"
                    checked={isIndivisual === "indivisual"}
                    onChange={() => this.setState({ isIndivisual: "indivisual" })}
                  />
                )}
                label="Individual"
              />
              <FormControlLabel
                value="end"
                className={classes.radiosty}
                control={(
                  <Radio
                    color="primary"
                    checked={isIndivisual === "couple"}
                    onChange={() => this.setState({ isIndivisual: "couple" })}
                  />
                )}
                label="Couple"
              />
            </div>
            {isIndivisual === "couple" && (
              <Grid item xs={12} sm={8}>
                <Select
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option._id}
                  placeholder="Search Couple"
                  onChange={this.handleSelectCouple}
                  options={allClient}
                  className={classes.formControl}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
              </Grid>
            )} */}
          </DialogContent>

          <div
            style={{
              paddingBottom: 20,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              autoFocus
              size="small"
              onClick={() => this.props.closeDialog()}
              style={{
                color: "#003d79",
                borderColor: "#003d79",
                marginRight: "20px",
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              size="small"
              onClick={() => this.handleSubmit()}
              style={{
                backgroundColor: "#003d79",
                color: "#ffffff",
                marginRight: "20px",
              }}
              variant="contained"
            >
              Done
            </Button>
          </div>
        </Dialog>
      </div>
    );
  }
}

ClientAdd.propsTypes = {
  getAllClient: PropsTypes.array,
  getServiceData: PropsTypes.array,
  // addAppointmentData: PropsTypes.bool,
};

const connected = connect(
  (state) => ({
    getAllClient: state.getClient,
    addAppointmentData: state.addAppointment,
    getServiceData: state.getServiceUser,
    getClinicianData: state.getByIdClinician,
    updateAppointmentStatus: state.updateAppointment,
  }),
  {
    getClientAction,
    getServiceAction,
    addAppointmentAction,
    getByIdClinicianAction,
    updateAppointmentAction,
  }
)(ClientAdd);

const formed = reduxForm({
  form: "addAppointment",
})(connected);


export default withStyles(styles)(connected);
