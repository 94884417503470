import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Backdrop } from '@material-ui/core';
import styled from 'styled-components';

const FullWidthBackdrop = styled(Backdrop)`
  z-index: 9999;
`;

const Spinner = ({ isOpen, handleClose }) => (
  <FullWidthBackdrop open={isOpen} onClick={handleClose}>
    <CircularProgress color='inherit' />
  </FullWidthBackdrop>
);

Spinner.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
};

export default Spinner;
