import React, { useEffect, useState } from 'react';
import { MenuItem, FormControl, Select } from '@material-ui/core';
import Axios from 'axios';
import PropTypes from 'prop-types';

import { baseURL } from '../../../utils/axios';

function SelectClinicAddresses(props) {
  const [selected, setSelected] = useState('');
  const [eventLists, setEventLists] = useState([]);

  useEffect(() => {
    getEventLists('');
  }, []);

  useEffect(() => {
    setSelected(props.value);
  }, [props.value]);

  const getEventLists = (term) => {
    Axios.get(`${baseURL}/api/clinic_addresses`, {
      headers: {
        Authorization: localStorage.getItem('ACCESS_TOKEN_PATH'),
      },
    })
      .then((res) => {
        if (res.data) {
          setEventLists(res.data.data);
        }
      })
      .catch((e) => {
        console.log('eeeeeeee', e);
      });
  };

  const handleChange = (event) => {
    if (props.onSelected) {
      setSelected(event.target.value);
      props.onSelected(event.target.value);
    }
  };

  return (
    <FormControl style={{ width: '100%', border: '2px solid #E5E5E5' }}>
      <Select
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        name='addressSelect'
        value={selected}
        defaultValue=''
        onChange={handleChange}
      >
        {eventLists.map((val) => {
          return (
            <MenuItem key={val._id} value={val._id}>
              {val.title}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

SelectClinicAddresses.propTypes = {
  value: PropTypes.object,
  onSelected: PropTypes.func.isRequired,
};

export default SelectClinicAddresses;
