/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React, { Children } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import { Toolbar } from "./Toolbar";
import { Header } from "./Header";
import { Events } from "./Events";
import "../../../assets/css/calendar.scss";
import styles from "./Calendar_style";


const localizer = momentLocalizer(moment);
const events = [
  {
    title: "My event 1",
    times: [
      { time: "09:00AM" },
      { time: "09:30AM" },
      { time: "10:00AM" },
      { time: "10:30AM" },
      { time: "11:00AM" },
    ],
    allDay: false,
    start: new Date("07/25/2020"), // 10.00 AM
    end: new Date("07/25/2020"), // 2.00 PM
    col: "#ee3559",
  },
  {
    title: "My event 2",
    times: [{ time: "09:00AM" }, { time: "09:30AM" }, { time: "10:00AM" }],
    allDay: false,
    start: new Date("07/20/2020"), // 10.00 AM
    end: new Date("07/20/2020"), // 2.00 PM6
    col: "#ee3559",
  },
  {
    title: "My event 3",
    times: [{ time: "09:00AM" }, { time: "09:30AM" }, { time: "10:00AM" }],
    allDay: false,
    start: new Date("07/23/2020"), // 10.00 AM
    end: new Date("07/23/2020"), // 2.00 PM6
    col: "#ee3559",
  },
];

const CURRENT_DATE = moment().toDate();

export const CustomCalendar = ({ onSelectEvent, ...props }) => {
  const classes = styles();
  const [isSlot, setSlot] = React.useState(false);
  const [isSelect, setSelect] = React.useState(false);

  return (
    <>
      <Calendar
        className={classes.root}
        localizer={localizer}
        events={events}
        defaultView="month"
        startAccessor="start"
        endAccessor="end"
        components={{
          toolbar: Toolbar,
          header: Header,
          event: (event) => (
            <Events
              event={event}
              slot={isSlot}
              onSelectEvent={(times, time, date) =>
                onSelectEvent(times, time, date)
              }
            />
          ),
        }}
        eventPropGetter={(event, start, end, isSelected) => {
          const style = {
            display: new Date(start) > new Date() ? "static" : "none",
            // color: "#000",
          };
          return {
            style,
          };
        }}
        // selectable
        slotPropGetter={(date) => {
          const style = {
            backgroundColor: "red",
          };
          return {
            className: "rbc-day-slot rbc-time-slot",
            style,
          };
        }}
      />
    </>
  );
};

CustomCalendar.defaultProps = {
  onSelectEvent: () => {},
};
